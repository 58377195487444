import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "../Assets/css/testimonials.css";

const Testimonial = () => {
  var settings = {
    dots: false,
    rows: 2,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 6000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className="container py-5">
        <div className="row">
          <Slider {...settings}>
            {Information.map((val, index) => {
              return (
                <div className="col-4">
                  <div className="cantiTestimonialMain" key={index}>
                    <div className="cantiTestimonialMainUpper">
                      <img
                        src={val.photo}
                        alt="avatar"
                        className="cantiTestimonialMainAvatar"
                      />
                      <div className="testiDetail">
                        <div className="cantiTestimonialMainName">
                          {val.name}
                        </div>
                        <img
                          src={val.companyphoto}
                          className="cantiTestimonialMainCompany"
                          alt="company name"
                        />
                      </div>
                    </div>
                    <div className="cantiTestimonialMainLower">{val.text}</div>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
    </>
  );
};

const Information = [
  {
    text: "The training did not only offer me data based reflection but also gave me an insight into improvement to the informative sessions by extremely qualified resource persons.",
    name: "AYUSHI SHARMA",
    photo:
      "https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644299266149_ayushi.png",
    companyphoto:
      "https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644303086354_flipkart123.png",
  },
  {
    text: "I was mentored by Cantilever Labs during our placement season at BITS. The Aptitude preparation really helped me. Case studies were amazing and so were guesstimates.",
    name: "ABHIJEET VICHARE",
    photo:
      "https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644299417276_Abhijeet%20Vichare.jpg",
    companyphoto:
      "https://www.merilytics.com/wp-content/uploads/2018/08/Merilytics.jpg",
  },
  {
    text: "I recently got placed in EXL analytics Gurgaon and it all happened because of Cantilever labs. They helped me a lot in resume building, puzzles and overall soft skills development.",
    name: "ANISHA ARORA",
    photo:
      "	https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644299765711_ANISHA%20ARORA.jpg",
    companyphoto:
      "https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644302975069_exl-logo.png",
  },
  {
    text: "I was one of the Students who was mentored by Cantilever Labs during our placement season at BITS Hyderabad. This training proved pivotal in helping me get placed in a fantastic company.",
    name: "AKASH PATIL",
    photo:
      "https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644299641286_AKASH%20PATIL.jpg",
    companyphoto:
      "https://upload.wikimedia.org/wikipedia/commons/0/0e/Nomura-logo_0.jpg",
  },
  {
    text: "The training sessions have been crisp and insightful. It covered aspects which are often overlooked or considered too obvious to be mentioned.",
    name: "MUSKAAN KOCHAR",
    photo:
      "https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644299920385_MUSKAAN%20KOCHAR.jpg",
    companyphoto:
      "https://upload.wikimedia.org/wikipedia/commons/a/a9/Amazon_logo.svg",
  },
  {
    text: "I attended the training programme by cantilever labs held on our campus and I am glad to get the opportunity to be mentored by them as they have an astute knowledge and experience of the corporate world. This training helped me in getting an excellent campus placement.",
    name: "Pranjal",
    photo:
      "https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644300995647_pranjal.png",
    companyphoto:
      "https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644302975069_exl-logo.png",
  },

  {
    text: "Cantilever Labs conducted around 1200 Mock PIs & 980 Mock GDs in aperiod of 15 days.All the interviewers are recent graduates of top institutions and also withperfect work experience to provide valuable feedback and guidance tostudents.",
    name: "ANUDEEP PEELA",
    photo:
      "https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644301104175_anupdeep.jpeg",
    companyphoto:
      "https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644303518344_IITBombayLogo.png",
  },
  {
    text: "All Sessions were well organized and got really good tips for placement preparation. The guidance provided in the interview was great and got to know things on which I need to work on. Overall the cantilever labs team has been very supportive!",
    name: "RIYA BAVISKAR",
    photo:
      "https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644302560767_riyabav.png",
    companyphoto:
      "https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644303518344_IITBombayLogo.png",
  },
  {
    text: "From the team's perspective, the process was convenient with Cantilever taking up a lot of the challenging tasks of scheduling events and personal interviews.Fudent's perspective, the entire process felt quite robust, exhaus-tive & aligned with the placement process.",
    name: "ASHISH BIJLANI",
    photo:
      "https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644302373770_asishb.png",
    companyphoto:
      "https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644303613690_iitmadras.png",
  },
  {
    text: "I just attended your session at YSF 2020. It was just amazing. Your session made attending YSF 2020 worth it. The way you spoke was literally goosebumps.",
    name: "THARUN NAIK",
    photo:
      "https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644302236101_tharun.png",
    companyphoto:
      "https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644303209335_iitkharagpur.png",
  },
  {
    text: "Your session made me (& everyone) see things from the perspective of the recruiter. I really liked your simple yet impactful style & how you presented the reasoning behind things,everything made sense.",
    name: "CHERRY TALY",
    photo:
      "https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644301323289_cherry.png",
    companyphoto:
      "https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644303209335_iitkharagpur.png",
  },
  {
    text: "Thank you. Will be grateful even if I don’t get oﬀered any role. These are essen-tials taught very crisply. Please continue doing this. Hope you grow more and more.",
    name: "SAI SRI HARSHA INDURTI",
    photo:
      "https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644301881211_SAISRIHARSHAINDURTI.jpeg",
    companyphoto:
      "https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644303420829_bitspilani.png",
  },
  {
    text: "Cantilever labs have some very great trainers with speciality in Consulting, Analytics and IT. The test series of Cantilever Labs proved to most useful for me because I encountered similar questions in the placement tests.",
    name: "SUGAM GANDHI",
    photo:
      "	https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644300805516_sugam.jpg",
    // "https://media-exp1.licdn.com/dms/image/C5603AQFjvldklg_CYQ/profile-displayphoto-shrink_400_400/0/1623603838953?e=1635379200&v=beta&t=ErqVZx5zsm9ILPrfGSNvou6qfRdia3_6GGKqHI0iyM4",
    companyphoto:
      "https://upload.wikimedia.org/wikipedia/commons/a/ac/Vedantulogo_orange.png",
  },
  {
    text: "Technical trainer was a professional trainer. His guidance was very much a different experience which not only made us learn new things but also made us to be specialised in the work we did.",
    name: "NEHA RAMYA",
    photo:
      "https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644300685005_NEHA%20RAMYA.jpg",
    // "https://media-exp1.licdn.com/dms/image/C5603AQG_JdESwM1erw/profile-displayphoto-shrink_400_400/0/1564594128915?e=1635379200&v=beta&t=OE-6SRRxR89pgGKlY2E19IH9FFqRyE3AGJAmIfdYG0U",
    companyphoto:
      "https://upload.wikimedia.org/wikipedia/commons/a/a9/Amazon_logo.svg",
  },
  {
    text: "Cantilever's Resume building session has helped me to a great extent as the resume I've built previously did not reflect my true abilities. As a student of EC department I would say it was exactly what I needed to get through.",
    name: "MANASA SAMA",
    photo:
      "	https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644300490605_manasa.png",
    companyphoto:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/9/9d/Capgemini_201x_logo.svg/2560px-Capgemini_201x_logo.svg.png",
  },
  {
    text: "The trainers are highly qualified. They taught us aptitude, core and verbal. I personally liked aptitude and core subjects. The trainers clarified each and every doubt. Daily assessments helped me a lot on my incremental improvement and helped in keeping an eye on the overall performance of students.",
    name: "SAI NITISH",
    photo:
      "https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644300175252_SAI%20NITISH.jpg",
    companyphoto:
      "https://upload.wikimedia.org/wikipedia/en/thumb/c/c1/Cognizant_logo.svg/1280px-Cognizant_logo.svg.png",
  },
  {
    text: "Cantilever Labs helps the students to get into the companies they dreamt of in a possible way. Material provided by them was of great help for my preparation. Trainers were friendly and supporting us by analysing our strengths and weaknesses. ",
    name: "PRAHARSHA SAI",
    photo:
      "https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644300357455_praharsha.png",
    companyphoto: "https://futuresfirst.com/wp-content/uploads/2019/12/ff.png",
  },
  {
    text: "Cantilever is remarkable for placement assistance, which can be helpful for your professional growth. It has got an exemplary interactive classroom training that can fine-tune your understanding about the corporate recruitment process.",
    name: "MANASVI SWARAJ BADHANI",
    photo:
      "https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644302859944_manasvi.png",
    companyphoto: "https://futuresfirst.com/wp-content/uploads/2019/12/ff.png",
  },
  {
    text: "First oﬀ I would like to thank you for having collaborated with us and imparting your knowledge to the students of our institute. I a m sure they have learnt a lot and have a better understanding of the dos and the don'ts than they had before.",
    name: "PRANJAL JAIN",
    photo:
      "https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644302660274_pranjaljain.jpeg",
    companyphoto:
      "https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644303613690_iitmadras.png",
  },
  {
    text: "Cantilever Labs has an unwavering commitment to high quality and honest desire to help the students. They went above and beyond what was promised initially. The group sessions were very engaging unlike the typical sessions we’ve seen.",
    name: "SANJEED",
    photo:
      "https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644302780169_sanjeed.jpeg",
    companyphoto:
      "https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644303613690_iitmadras.png",
  },
  {
    text: "Your session at Youth Speak Forum was truly engrossing & nice learning experience.Thank you so much for the information & advice you have shared. Looking forward to for more such insightful teachings from you & Cantilever Labs.",
    name: "SAMIKSHA SHARMA",
    photo:
      "https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644301181864_samiksha.png",
    companyphoto:
      "https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644303209335_iitkharagpur.png",
  },
  {
    text: "I have already been through the Career Cell internship process & was aware of some of the things. But I learned a lot of new things in your session. I admire how you communicated so clearly & precisely, & wished it were a longer session where we could do something hands-on",
    name: "TINA CHILLAR",
    photo:
      "https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644302147630_tinachillar.png",
    companyphoto:
      "https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644303209335_iitkharagpur.png",
  },
  {
    text: "Interviewer provided me with alot of important insights and answered all of my queries and questions. The interview felt like an actual interview and gave me a ﬂavour of what it it's like to be in an interview.",
    name: "AKHIL MAHAJAN",
    photo:
      "https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644302033576_akhilm.png",
    companyphoto:
      "https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644303420829_bitspilani.png",
  },
];

export default Testimonial;
export { Information };

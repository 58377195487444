let month = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export const GraphTrend = {
  "62d63c31bc0f60e6ac1694a6": {
    coding: {
      labels: month,
      datasets: [
        {
          label: "Coding Trend",
          data: [0, 0, 0, 56, 63, 61, 72, 0, 0, 0, 0, 0],
          backgroundColor: "#FF5858",
          borderColor: "#FF5858",
        },
      ],
    },
    quiz: {
      labels: month,
      datasets: [
        {
          label: "Quiz Trend",
          data: [0, 0, 0, 66, 69, 55, 62, 0, 0, 0, 0, 0],
          backgroundColor: "#24B47E",
          borderColor: "#24B47E",
        },
      ],
    },
    hybrid: {
      labels: month,
      datasets: [
        {
          label: "Hybrid Exam Trend",
          data: [0, 0, 10, 32, 65, 59, 33, 56, 0, 0, 0, 0],
          backgroundColor: "#24B47E",
          borderColor: "#24B47E",
        },
      ],
    },
  },
  "62d63901bc0f60e6ac1694a4": {
    coding: {
      labels: month,
      datasets: [
        {
          label: "Coding Trend",
          data: [0, 0, 0, 47, 55, 44, 58, 0, 0, 0, 0, 0],
          backgroundColor: "#FF5858",
          borderColor: "#FF5858",
        },
      ],
    },
    quiz: {
      labels: month,
      datasets: [
        {
          label: "Quiz Trend",
          data: [0, 0, 0, 51, 55, 46, 42, 0, 0, 0, 0, 0],
          backgroundColor: "#24B47E",
          borderColor: "#24B47E",
        },
      ],
    },
    hybrid: {
      labels: month,
      datasets: [
        {
          label: "Hybrid Trend",
          data: [0, 0, 32, 30, 58, 42, 60, 0, 0, 0, 0, 0],
          backgroundColor: "#24B47E",
          borderColor: "#24B47E",
        },
      ],
    },
  },
  "62d63c40bc0f60e6ac1694a7": {
    coding: {
      labels: month,
      datasets: [
        {
          label: "Coding Trend",
          data: [0, 0, 0, 65, 36, 72, 55, 0, 0, 0, 0, 0],
          backgroundColor: "#FF5858",
          borderColor: "#FF5858",
        },
      ],
    },
    quiz: {
      labels: month,
      datasets: [
        {
          label: "Quiz Trend",
          data: [0, 0, 0, 66, 69, 55, 62, 0, 0, 0, 0, 0],
          backgroundColor: "#24B47E",
          borderColor: "#24B47E",
        },
      ],
    },
    hybrid: {
      labels: month,
      datasets: [
        {
          label: "Hybrid Trend",
          data: [0, 0, 15, 30, 43, 50, 58, 0, 0, 0, 0, 0],
          backgroundColor: "#24B47E",
          borderColor: "#24B47E",
        },
      ],
    },
  },
  "62d63bb5bc0f60e6ac1694a5": {
    coding: {
      labels: month,
      datasets: [
        {
          label: "Coding Trend",
          data: [0, 0, 0, 47, 67, 52, 75, 0, 0, 0, 0, 0],
          backgroundColor: "#FF5858",
          borderColor: "#FF5858",
        },
      ],
    },
    quiz: {
      labels: month,
      datasets: [
        {
          label: "Quiz Trend",
          data: [0, 0, 0, 78, 81, 56, 66, 0, 0, 0, 0, 0],
          backgroundColor: "#24B47E",
          borderColor: "#24B47E",
        },
      ],
    },
    hybrid: {
      labels: month,
      datasets: [
        {
          label: "Hybrid Trend",
          data: [0, 0, 16, 30, 33, 59, 72, 0, 0, 0, 0, 0],
          backgroundColor: "#24B47E",
          borderColor: "#24B47E",
        },
      ],
    },
  },
};

export const PowerOverviewData = {
  "62d63c31bc0f60e6ac1694a6": {
    red: 55,
    blue: 44,
  },
  "62d63901bc0f60e6ac1694a4": {
    red: 62,
    blue: 71,
  },
  "62d63c40bc0f60e6ac1694a7": {
    red: 49,
    blue: 55,
  },
  "62d63bb5bc0f60e6ac1694a5": {
    red: 72,
    blue: 89,
  },
};

export const TestScoreData = {
  coding: {
    "62d63c31bc0f60e6ac1694a6": {
      datasets: [
        {
          label: "# of Votes",
          data: [4, 30, 66],
          backgroundColor: ["#FF5858", "#FFB636", "#24B47E"],
        },
      ],
    },
    "62d63901bc0f60e6ac1694a4": {
      datasets: [
        {
          label: "# of Votes",
          data: [14, 29, 57],
          backgroundColor: ["#FF5858", "#FFB636", "#24B47E"],
        },
      ],
    },
    "62d63c40bc0f60e6ac1694a7": {
      datasets: [
        {
          label: "# of Votes",
          data: [10, 30, 60],
          backgroundColor: ["#FF5858", "#FFB636", "#24B47E"],
        },
      ],
    },
    "62d63bb5bc0f60e6ac1694a5": {
      datasets: [
        {
          label: "# of Votes",
          data: [19, 15, 66],
          backgroundColor: ["#FF5858", "#FFB636", "#24B47E"],
        },
      ],
    },
  },
  quiz: {
    "62d63c31bc0f60e6ac1694a6": {
      datasets: [
        {
          label: "# of Votes",
          data: [10, 40, 50],
          backgroundColor: ["#FF5858", "#FFB636", "#24B47E"],
        },
      ],
    },
    "62d63901bc0f60e6ac1694a4": {
      datasets: [
        {
          label: "# of Votes",
          data: [14, 29, 57],
          backgroundColor: ["#FF5858", "#FFB636", "#24B47E"],
        },
      ],
    },
    "62d63c40bc0f60e6ac1694a7": {
      datasets: [
        {
          label: "# of Votes",
          data: [11, 20, 69],
          backgroundColor: ["#FF5858", "#FFB636", "#24B47E"],
        },
      ],
    },
    "62d63bb5bc0f60e6ac1694a5": {
      datasets: [
        {
          label: "# of Votes",
          data: [15, 15, 70],
          backgroundColor: ["#FF5858", "#FFB636", "#24B47E"],
        },
      ],
    },
  },
  hybrid: {
    "62d63c31bc0f60e6ac1694a6": {
      datasets: [
        {
          label: "# of Votes",
          data: [5, 25, 70],
          backgroundColor: ["#FF5858", "#FFB636", "#24B47E"],
        },
      ],
    },
    "62d63901bc0f60e6ac1694a4": {
      datasets: [
        {
          label: "# of Votes",
          data: [10, 32, 58],
          backgroundColor: ["#FF5858", "#FFB636", "#24B47E"],
        },
      ],
    },
    "62d63c40bc0f60e6ac1694a7": {
      datasets: [
        {
          label: "# of Votes",
          data: [22, 10, 68],
          backgroundColor: ["#FF5858", "#FFB636", "#24B47E"],
        },
      ],
    },
    "62d63bb5bc0f60e6ac1694a5": {
      datasets: [
        {
          label: "# of Votes",
          data: [30, 5, 65],
          backgroundColor: ["#FF5858", "#FFB636", "#24B47E"],
        },
      ],
    },
  },
};

export const TestTopStudents = {
  "62d63c31bc0f60e6ac1694a6": [
    ["Raghu Tamboli", 297],
    ["Sunder Chaudhri", 295],
    ["Sneha Gadhavi", 290],
    ["Nirav Mhasalkar", 289],
    ["Nirav Mhasalkar", 285],
    ["Harshal Misra", 374],
    ["Jagdish Devi", 366],
    ["Jagdish Devi", 366],
    ["Nithin Sharma", 365],
    ["Nithin Sharma", 365],
    ["Rajiv Jain", 200],
    ["Rajiv Jain", 200],
    ["Rajiv Jain", 200],
    ["Rajiv Jain", 200],
    ["Rajiv Jain", 200],
  ],
  "62d63901bc0f60e6ac1694a4": [
    ["Trishna Mishra", 200],
    ["Sanjit Das", 195],
    ["Riya Kulkarni", 193],
    ["Gauri Mishra", 192],
    ["Chandra Sharma", 190],
    ["Gopal Begam", 515],
    ["Bharat Patel", 511],
    ["Ranjeet Nagarkar", 505],
    ["Diksha Ahmed", 189],
    ["Sanjit Das", 195],
    ["Riya Kulkarni", 193],
    ["Gauri Mishra", 192],
    ["Chandra Sharma", 190],
    ["Gopal Begam", 515],
    ["Bharat Patel", 511],
  ],
  "62d63c40bc0f60e6ac1694a7": [
    ["Gowri Ahmed", 495],
    ["Punit Vemulakonda", 492],
    ["Shubham Das", 491],
    ["Chandana Kaur", 490],
    ["Amita Joshi", 485],
    ["Shresth Mhasalkar", 399],
    ["Raghu Kaur", 387],
    ["Archana Choudhary", 380],
    ["Pankaj Bachchan", 375],
  ],
  "62d63bb5bc0f60e6ac1694a5": [
    ["Amita Jain", 99],
    ["Jayanthi Das", 92],
    ["Vasuda Kumar", 89],
    ["Lina Chaudhary", 87],
    ["Siddhi Singh", 87],
    ["Niraj Patel", 917],
    ["Gurmeet Patil", 911],
    ["Rashmi Gupta", 906],
    ["Swait Begam", 905],
    ["Lina Chaudhary", 87],
    ["Siddhi Singh", 87],
    ["Niraj Patel", 917],
    ["Gurmeet Patil", 911],
    ["Rashmi Gupta", 906],
    ["Swait Begam", 905],
  ],
};

import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import style from "../Assets/css/newmasterclass.module.css";
import { BASE_URL } from "../env";

function MasterclassComp() {
  const [tab, setTab] = useState(1);
  const [webinarData, setWebinarData] = useState([]);
  useEffect(() => {
    fetch(BASE_URL + "/getAllOfflineMasterClass", {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((data) => {
        setWebinarData(data.message);
      });
  }, []);
  const TabData = () => {
    if (tab === 1) {
      return (
        <>
          <div className="row g-4">
            {webinarData.map((val, index) => {
              return (
                <EventCard
                  key={index}
                  imgsrc={val.image}
                  heading={val.subject}
                  desc={val.description}
                  link={val._id}
                />
              );
            })}
          </div>
        </>
      );
    } else if (tab === 2) {
      return (
        <>
          <div className={style.noEventFound}>No events found</div>
        </>
      );
    } else {
      return (
        <>
          <div className={style.noEventFound}>No events found</div>
        </>
      );
    }
  };

  return (
    <>
      <div className={`container-fluid ${style.layout}`}>
        <div className="row mt-5">
          <div className={`col-12 ${style.eventContainer}`}>
            <div className="row">
              <div className={`col-12 mb-0 ${style.eventHeader}`}>
                <ul>
                  <li
                    onClick={() => setTab(1)}
                    className={
                      tab === 1 ? style.eventActive : style.eventInactive
                    }
                  >
                    MasterClass
                  </li>
                  <li
                    onClick={() => setTab(2)}
                    className={
                      tab === 2 ? style.eventActive : style.eventInactive
                    }
                  >
                    Live Webinar
                  </li>
                  <li
                    onClick={() => setTab(3)}
                    className={
                      tab === 3 ? style.eventActive : style.eventInactive
                    }
                  >
                    TA Sessions
                  </li>
                </ul>
              </div>
              <div className={`col-12 mb-0 ${style.eventBody}`}>
                <TabData />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const EventCard = (props) => {
  return (
    <>
      <div className="col-lg-4 col-sm-6 mb-0">
        <div className={style.eventCard}>
          <img
            src={props.imgsrc}
            className={`img-fluid ${style.eventCardBanner}`}
            alt="course banner"
          />
          <div className={style.eventCardHeader}>{props.heading}</div>
          <div className={style.eventCardBody}>{props.desc}</div>
          <div className={style.eventCardFooter}>
            <NavLink
              to={`/masterclass-standalone/${props.link}`}
              className={style.eventExplorebtn}
              onClick={() => window.scroll(0, 0)}
            >
              Explore Now
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
};

export default MasterclassComp;

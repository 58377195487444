import { useEffect } from "react";
import "../Assets/scss/DashboardLoader.scss";
import { Skeleton } from "@mui/material";
import styles from "./DashboardLoader.module.css";

const DashboardLoader = ({ hidenavbar }) => {


    useEffect(() => {
        if (!hidenavbar) return;

        hidenavbar(true);
        return () => {
            hidenavbar(false);
        };
    }, [hidenavbar]);

    return (
        <div className={styles.dashboard}>
            <Skeleton variant="rectangular" width={"18vw"} height={"100vh"} sx={{
                display: {
                    xs: "none",
                    md: "block"
                },
            }} />
            <div className={styles.centerDashboard}>
                <div className={styles.mainCenter}>
                    <Skeleton variant="rectangular" width={"100%"} height={"100px"} sx={{
                        marginBottom: "20px"
                    }} />
                    <div className={styles.myCourseHeading}>My Course</div>
                    <Skeleton variant="rectangular" width={"100%"} height={"233px"} sx={{
                        marginTop: "10px",
                    }} />
                    <div className={styles.myCourseBlueHeading}>Statistics</div>
                    <Skeleton variant="rectangular" width={"100%"} sx={{
                        marginTop: "10px",
                        flexGrow: 1,
                        marginBottom: "2px"
                    }} />
                </div>
                <Skeleton variant="rounded" sx={{
                    height: "100vh",
                    flexGrow: 1,
                    marginLeft: "40px",
                    display: {
                        xs: "none",
                        md: "block"
                    },
                }} />
            </div>
        </div>
    );
};

export default DashboardLoader;
import { createContext, useState } from "react";

export const JobContext = createContext({});

function JobContextProvider(props) {
  const [totalLength, setTotalLength] = useState();
  const [newJobs, setNewjobs] = useState([
    { dateAdded: "10", dueDate: "10", loadingState: true },
  ]);
  const [roles, setRoles] = useState([]);
  const [filter, setFilter] = useState(false);
  const [fullTime, setFulltime] = useState("");
  const [priority, setPriority] = useState("all");
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState(newJobs);
  //   const [roles, setRoles] = useState([]);
  const [sortby, setsortby] = useState("-dateAdded");
  const [selected, setSelected] = useState("ALL");
  const [Onelocation, setOnelocation] = useState("ALL Location");
  const [jobPostings, setJobPostings] = useState([
    { dateAdded: "10", dueDate: "10", loadingState: true },
  ]);
  const [change, setchange] = useState(false);
  const [locations, setLocation] = useState([]);
  const [search, newSearch] = useState("");
  const [NEWroles, setNEWroles] = useState("all");
  const [label, setlabel] = useState("All");

  // useEffect(() => {
  //     alert(label)
  // },[label])

  return (
    <JobContext.Provider
      value={{
        label,
        setlabel,
        change,
        setsortby,
        setchange,
        locations,
        setLocation,
        setRoles,
        roles,
        setFilter,
        filter,
        setNewjobs,
        newJobs,
        totalLength,
        setTotalLength,
        fullTime,
        setFulltime,
        priority,
        setPriority,
        currentPage,
        setCurrentPage,
        data,
        setData,
        sortby,
        selected,
        setSelected,
        Onelocation,
        setOnelocation,
        jobPostings,
        setJobPostings,
        search,
        newSearch,
        NEWroles,
        setNEWroles,
      }}
    >
      {props.children}
    </JobContext.Provider>
  );
}

export default JobContextProvider;

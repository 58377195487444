import React, { useState } from "react";
import "../Interview/InterviewStyle/PreparePage.scss";
import { useHistory } from "react-router-dom";
import configAxios from "../../axios.config";
const PreparePage = () => {
  const [question, setQuestion] = useState([])
  let history = useHistory();
  function topicPage(){
    history.push("/topicform")
  }
  configAxios(localStorage.getItem("token"))
  .get(`/openai/get-interview-types/`)
  .then(({ data })=>{
    setQuestion(data.types[0].topics)
  })
  .catch((err)=>{
    console.log(err);
  })
  return (
    <>
      <div id="preparePage">
        <div className="preparePageBoxes">
          {question.map((val, i)=>{
            return(
              <div className="preparePageBox" key={i} onClick={topicPage}>
            <div className="prepareText">{val.topic}</div>
          </div>
            )
          })}
        </div>
      </div>
    </>
  );
};

export default PreparePage;

import { useEffect, useState } from "react";
import Editor from "./Components/Editor";
import SplitPane, { Pane } from "split-pane-react";
import "split-pane-react/esm/themes/default.css";
import "./Assets/styles/HTMLEditor.scss";

export default function HTMLEditor({ hidenavbar }) {
  const [html, setHtml] = useState<string>("");
  const [css, setCss] = useState<string>("");
  const [js, setJs] = useState<string>("");
  const [srcDoc, setSrcDoc] = useState<string>("");
  const [sizeHorizaontal, setSizeHorizaontal] = useState(["auto", "auto"]);
  const [activeTab, setActiveTab] = useState("");
  const [sizes, setSizes] = useState(["auto", "auto", "auto"]);
  let openedTabs = sizes;
  function handleOpenedTabs(e): void {
    let index = parseInt(e.target.name);
    let updatedTabs = [...openedTabs];
    updatedTabs[index] === "0%"
      ? (updatedTabs[index] = "auto")
      : (updatedTabs[index] = "0%");
    // console.log(updatedTabs, sizes);
    if (updatedTabs.includes("auto")) {
      setSizes(updatedTabs);
    }
  }

  let openedHorizontalTabs = sizeHorizaontal;
  function handleHorizaontalTabs(e): void {
    let index = parseInt(e);
    let updatedTabs = [...openedHorizontalTabs];
    updatedTabs[index] === "0%"
      ? (updatedTabs[index] = "auto")
      : (updatedTabs[index] = "0%");
    if (updatedTabs.includes("auto")) {
      setSizeHorizaontal(updatedTabs);
    }
  }

  useEffect(() => {
    if (!hidenavbar) return;

    hidenavbar(true);
    return () => {
      hidenavbar(false);
    };
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setSrcDoc(`
${html} 
<style>${css}</style> 
<script>${js}</script>
`);
    }, 250);

    return () => clearTimeout(timeout);
  }, [html, css, js]);

  return (
    <div className="web-dev-screen">
      <div className="tab-handle-buttons">
        <button
          className="toggle-btn"
          style={
            activeTab === "html"
              ? { backgroundColor: "white", border: "1px solid black" }
              : {}
          }
          onClick={(e) => {
            handleOpenedTabs(e);
            setActiveTab("");
          }}
          name="0"
        >
          HTML
        </button>
        <button
          className="toggle-btn"
          style={
            activeTab === "css"
              ? { backgroundColor: "white", border: "1px solid black" }
              : {}
          }
          onClick={(e) => {
            handleOpenedTabs(e);
            setActiveTab("");
          }}
          name="1"
        >
          CSS
        </button>
        <button
          className="toggle-btn"
          style={
            activeTab === "javascript"
              ? { backgroundColor: "white", border: "1px solid black" }
              : {}
          }
          onClick={(e) => {
            handleOpenedTabs(e);
            setActiveTab("");
          }}
          name="2"
        >
          JS
        </button>
        <div className="hide-show-toggle-btn">
          <button onClick={() => handleHorizaontalTabs("0")}>
            {sizeHorizaontal[0] === "auto" ? "Hide" : "Show"} Editor
            <img
              className={
                sizeHorizaontal[0] === "auto"
                  ? "active img-dropdown"
                  : "img-dropdown"
              }
              src="https://s2.svgbox.net/hero-solid.svg?ic=arrow-down&color=000"
              width="32"
              height="32"
            />
          </button>
          <button onClick={() => handleHorizaontalTabs("1")}>
            {sizeHorizaontal[1] === "auto" ? "Hide" : "Show"} Window
            <img
              className={
                sizeHorizaontal[1] === "auto"
                  ? "active img-dropdown"
                  : "img-dropdown"
              }
              src="https://s2.svgbox.net/hero-solid.svg?ic=arrow-down&color=000"
              width="32"
              height="32"
            />
          </button>
        </div>
      </div>
      <SplitPane
        split="horizontal"
        sizes={sizeHorizaontal}
        onChange={() => {}}
        sashRender={(index, active) => null}
      >
        <SplitPane
          split="vertical"
          sizes={sizes}
          onChange={() => {}}
          sashRender={(index, active) => null}
        >
          <Pane className="editor-pane">
            <Editor
              language="html"
              onChange={(e) => {
                setActiveTab("html");
                if (!e) setHtml("");
                else setHtml(e);
              }}
              val={html}
              selectedTab={activeTab}
              closeTab={handleOpenedTabs}
              tabIndex="0"
              setActiveTab={setActiveTab}
            />
          </Pane>
          <Pane className="editor-pane">
            <Editor
              language="css"
              onChange={(e) => {
                setActiveTab("css");
                if (!e) setCss("");
                else setCss(e);
              }}
              val={css}
              selectedTab={activeTab}
              closeTab={handleOpenedTabs}
              tabIndex="1"
              setActiveTab={setActiveTab}
            />
          </Pane>
          <Pane className="editor-pane">
            <Editor
              language="javascript"
              onChange={(e) => {
                setActiveTab("javascript");
                if (!e) setJs("");
                else setJs(e);
              }}
              val={js}
              selectedTab={activeTab}
              closeTab={handleOpenedTabs}
              tabIndex="2"
              setActiveTab={setActiveTab}
            />
          </Pane>
        </SplitPane>
        <Pane>
          <div className="iframe-screen">
            <iframe srcDoc={srcDoc} sandbox="allow-scripts"></iframe>
          </div>
        </Pane>
      </SplitPane>
    </div>
  );
}

import { useState } from "react";
import styles from "../Assets/css/signup.module.css";
import { useHistory } from "react-router-dom";
import HelmetComp from "../components/HelmetComp";
import configAxios from "../axios.config";

const BvritSignup = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [load, setLoad] = useState(false);
  const history = useHistory();

  function handleFirstName(event) {
    let e = event.target.value;
    setFirstName(e);
  }
  function handleLastName(event) {
    let e = event.target.value;
    setLastName(e);
  }
  function handleEmail(event) {
    let e = event.target.value;
    setEmail(e);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const email_obj = email.toLowerCase();
    const email_patter = /chitkara\.edu\.in$/;

    if (!email_patter.test(email_obj)) {
      alert("Use college email address to attempt the test.");
      return;
    }
    e.preventDefault();
    setLoad(true);
    configAxios(localStorage.getItem("token"))
      .post("/signup-and-enroll-to-course", {
        firstName,
        lastName,
        email: email_obj,
      })
      .then(({ data }) => {
        const urlParams = new URLSearchParams(window.location.search);
        localStorage.setItem("token", data.token);
        const myParam = urlParams.get("redirect");
        if (myParam === "" || !myParam) {
          history.push("/profile");
        } else {
          history.push(window.location.search.slice(10));
        }
      })
      .catch((e) => {
        alert("please try again");
      })
      .finally(() => setLoad(false));
  };

  return (
    <>
      <HelmetComp
        info={{
          title: "SignUp Page",
        }}
      />
      <div>
        <div
          className={`container-fluid ${styles.background}`}
          style={{ height: "100vh" }}
        >
          <br />
          <br />
          <br />

          <div class="row signup-form">
            <div
              class={`col-xl-7 col-lg-7 col-md-7 col-sm-6 ${styles.signupText}`}
            >
              <div class={styles.signupTextdiv}>
                <br />

                <img
                  src="https://www.freelogovectors.net/wp-content/uploads/2022/03/chitkara_university_logo_freelogovectors.net_.png"
                  alt="chitkara"
                  style={{
                    width: "400px",
                    height: "400px",
                    objectFit: "contain",
                  }}
                />
              </div>
            </div>
            <div class="col-xl-3 col-lg-4 col-md-4 col-sm-5">
              <form class={styles.formcontainer} onSubmit={handleSubmit}>
                <div class={styles.formgroup}>
                  <input
                    onChange={handleFirstName}
                    type="text"
                    class={styles.formcontrol}
                    id="first_name"
                    placeholder="First Name"
                    name="name"
                    required
                  />
                </div>
                <div class={styles.formgroup}>
                  <input
                    onChange={handleLastName}
                    type="text"
                    class={styles.formcontrol}
                    id="last_name"
                    placeholder="Last Name"
                    name="name"
                    required
                  />
                </div>
                <div class={styles.formgroup}>
                  <input
                    onChange={handleEmail}
                    type="email"
                    class={styles.formcontrol}
                    id="email"
                    placeholder="Email"
                    name="email"
                    required
                  />
                </div>

                <div>
                  <p
                    style={{
                      padding: "10px 0",
                      fontSize: "13px",
                      fontFamily: "sans-serif",
                    }}
                  >
                    {" "}
                    By clicking Register you agree to our
                  </p>{" "}
                  <a
                    href="/policies"
                    style={{
                      textDecoration: "underline",
                      color: "blue",
                      fontSize: "14px",
                      fontWeight: "bolder",
                    }}
                  >
                    Policy Terms
                  </a>
                </div>

                {load === false ? (
                  <button
                    style={{ marginTop: "15px" }}
                    type="submit"
                    class={styles.registerbtn}
                  >
                    Sign Up
                  </button>
                ) : (
                  <button
                    className={styles.registerbtn}
                    type="submit"
                    disabled={true}
                  >
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span className="sr-only">Loading...</span>
                  </button>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default BvritSignup;

import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import LoginTestimonial from "../components/LoginTestimonial";
import RegisterPopup from "../components/RegisterPopup";
import "../Assets/scss/Register.scss";
import axios from "axios";
import { BASE_URL } from "../env";
import GoogleLogin from "react-google-login";

const Register = ({ hidenavbar }) => {
  const [warning, setWarning] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [show, setshow] = useState(false);
  const [email, setemail] = useState("");
  // const history = useHistory();

  useEffect(() => {
    hidenavbar(true);
    return () => {
      hidenavbar(false);
    };
  }, []);

  const GenerateOtp = async () => {
    try {
      await axios({
        method: "Post",
        url: `${BASE_URL}/generateotp`,
        data: {
          email: email,
        },
        headers: {
          "Content-type": "application/json",
        },
      });
    } catch (error) {
      console.log("something went wrong");
    }
  };

  const CheckUserExitOrNot = async (e) => {
    setSpinner(true);
    e.preventDefault();
    try {
      await axios({
        method: "Post",
        url: `${BASE_URL}/checkuserexistsornotnew`,
        data: {
          email: email,
        },
        headers: {
          "Content-type": "application/json",
        },
      });
      GenerateOtp();
      setshow(true);
      setSpinner(false);
    } catch (error) {
      setWarning(true);
      setSpinner(false);
      setTimeout(() => {
        setWarning(false);
      }, 6000);
    }
  };

  // const SignUpUser = async (account) => {
  //   try {
  //     await axios({
  //       method: "Post",
  //       url: `${BASE_URL}/signupnew`,
  //       data: {
  //         typeofuser: "googleuser",
  //         email: account,
  //       },
  //       headers: {
  //         "Content-type": "application/json",
  //       },
  //     });
  //     history.push({
  //       pathname: "/login-post",
  //       search: "?query=abc",
  //       state: { email: account },
  //     });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const responseGoogle = (e) => {
    // let gmailId = e.profileObj.email;
    // SignUpUser(gmailId);
  };

  return (
    <>
      <div className="loginForm">
        <RegisterPopup show={show} setshow={setshow} email={email} />
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 mb-0 p-0 order-lg-1 order-2">
              <LoginTestimonial />
            </div>
            <div className="col-lg-6 mb-0 p-0 order-lg-2 order-1">
              <div className="form">
                <div className="title">
                  Sign Up <br />
                  <span>To continue with Cantilever Labs</span>
                </div>
                <div className="mid-Form">
                  <p className={warning ? "d-block" : "d-none"}>User already exist, please sign into your account.</p>
                  <form onSubmit={CheckUserExitOrNot}>
                    <div className="form-floating">
                      <input
                        onChange={(e) => {
                          setemail(e.target.value);
                        }}
                        value={email}
                        type="email"
                        className="form-control"
                        id="floatingInput"
                        placeholder="name@example.com"
                        required
                      />
                      <label for="floatingInput">Enter your email</label>
                    </div>
                    <button type="submit" className="submitBtn" disabled={spinner ? "disabled" : ""}>
                      {spinner ? <div className="spinner-border spinner-border-sm"></div> : "Next"}
                    </button>
                  </form>
                  <div className="divider">or</div>

                  <GoogleLogin
                    disabled={false}
                    clientId="671105516475-03k9jvsa3evmg1k4u5ge13alls8v9nfv.apps.googleusercontent.com"
                    render={(renderProps) => (
                      <div className="googleBtn" onClick={renderProps.onClick} disabled={renderProps.disabled}>
                        <img src="https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1645106072335_flat-color-icons_google.png" alt="google icon" className="googleIcon" />
                        Sign Up with Google
                      </div>
                    )}
                    buttonText="Login"
                    onSuccess={responseGoogle}
                    // onFailure={responseGoogle}
                    cookiePolicy={"single_host_origin"}
                  />
                  {/* <div className="googleBtn">
                    <img
                      src="https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1645106072335_flat-color-icons_google.png"
                      alt="google icon"
                      className="googleIcon"
                    />
                    Sign In with Google
                  </div> */}
                </div>
                <div className="text">
                  Already have an account?{" "}
                  <NavLink to="/login" className="link_text">
                    Sign In
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;

import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import styles from "../Assets/css/sitemap.module.css";

function Sitemap() {
    return (
        <div>
            {/* <Navbar /> */}
            <div className={styles.container}>
                <br /><h2>Sitemap</h2><br />

                <section className={styles.sec}>

                    <h2>General</h2>
                    <ul className="list-inline">
                        <li><a href="/">Home</a></li>
                        <li><a href="/aboutus">About us</a></li>
                        <li><a href="/jobs-for-freshers">Jobs/Internships</a></li>
                        <li><a href="/blog">Blog</a></li>
                    </ul>
                </section>

                <section className={styles.sec}>

                    <h2>Courses</h2>
                    <ul className="list-inline">
                        <li><a href="/placementcourse">Placement</a></li>
                        <li><a href="/tcs">TCS NQT Prepration Course</a></li>
                        <li><a href="/interview-preparation-bundle">Interview Prepration Course</a></li>
                        <li><a href="/quant_finance">Quant Finance</a></li>
                        <li><a href="/fasttrackcourse">Crash Courses</a></li>
                        <li><a href="/newcoursepage">Get Placement Ready</a></li>
                    </ul>
                </section>
                <section className={styles.sec}>

                    <h2>Free Resources</h2>
                    <ul className="list-inline">
                        <li><a href="/masterclass">Masterclass</a></li>
                        <li><a href="/iitbombay">Training @ IIT Bombay</a></li>
                        <li><a href="/company-specific/616690234d0ae87be0c5c062">Capgemini</a></li>
                        <li><a href="/company-specific/6179424a8b184861a7b937eb">Infosys</a></li>
                        <li><a href="/company-specific/61c598567a4a44b80a3e4cb9">Wipro</a></li>

                    </ul>
                </section>
                <section className={styles.sec}>

                    <h2>For Colleges</h2>
                    <ul className="list-inline">
                        <li><a href="/business">Business</a></li>

                    </ul>
                </section>

                <section className={styles.sec}>

                    <h2>Authentication</h2>
                    <ul className="list-inline">
                        <li><a href="/signin">Sign-In</a></li>
                        <li><a href="/signup">Sign-Up</a></li>
                        <li><a href="/forgotpassword">Forgot Password</a></li>

                    </ul>
                </section>


            </div>
            <Footer />
        </div>
    )
}

export default Sitemap;

import styles from "../Assets/css/newcoursepage.module.css";
import img1 from "../Assets/newcoursepage_images/slider/Group 8249.jpg";
import img2 from "../Assets/newcoursepage_images/slider/Group 8250.jpg";
import img3 from "../Assets/newcoursepage_images/slider/Group 8251.jpg";
import img4 from "../Assets/newcoursepage_images/slider/Group 8255.jpg";
import img5 from "../Assets/newcoursepage_images/slider/accenture.jpg";
import img6 from "../Assets/newcoursepage_images/slider/apple.jpg";
import img7 from "../Assets/newcoursepage_images/slider/cageminni.jpg";
import img8 from "../Assets/newcoursepage_images/slider/cognizant.jpg";
import img9 from "../Assets/newcoursepage_images/slider/deloitte.jpg";
import img10 from "../Assets/newcoursepage_images/slider/fb.jpg";
import img11 from "../Assets/newcoursepage_images/slider/hcl.jpg";
import img12 from "../Assets/newcoursepage_images/slider/infosys.jpg";
import img13 from "../Assets/newcoursepage_images/slider/l&t.jpg";
import img14 from "../Assets/newcoursepage_images/slider/netflix.jpg";
import img15 from "../Assets/newcoursepage_images/slider/tcs.jpg";
import img16 from "../Assets/newcoursepage_images/slider/techm.jpg";
import img17 from "../Assets/newcoursepage_images/slider/vir.jpg";
import img18 from "../Assets/newcoursepage_images/slider/wipro.jpg";

const image = [
  { img: img1 },
  { img: img2 },
  { img: img3 },
  { img: img4 },
  { img: img5 },
  { img: img6 },
  { img: img7 },
  { img: img8 },
  { img: img9 },
  
  
  
];
const image1=[
  { img: img10 },
  { img: img11 },
  { img: img12 },
  { img: img13 },
  { img: img14 },
  { img: img15 },
  { img: img16 },
  { img: img17 },
  { img: img18 },
  { img: img11 },
]

const Slider = () => {
  return (
    <div className={styles.slider} >
      <div className={styles.slidert} style={{margin:"3rem 0"}}>
        <div className={styles.slide}>
          {image.map((item, i) => {
            return (
              <img className={styles.slidImg}
                src={item.img}
                style={{ margin: "0 2vw" }}
                alt=""
              ></img>
            );
          })}
           {image.map((item, i) => {
            return (
              <img className={styles.slidImg}
                src={item.img}
                style={{ margin: "0 2vw" }}
                alt=""
              ></img>
            );
          })}
         
          
        </div>
      </div>
      <div className={styles.slidert} style={{margin:"3rem 0",animationDirection:"reverse"}}>
        <div className={styles.slide}>
         
          {image1.map((item, i) => {
            return (
              <img className={styles.slidImg}
                src={item.img}
                style={{ margin: "0 2vw" }}
                alt=""
              ></img>
            );
          })}
          {image1.map((item, i) => {
            return (
              <img className={styles.slidImg}
                src={item.img}
                style={{ margin: "0 2vw" }}
                alt=""
              ></img>
            );
          })}
          
        </div>
      </div>
    </div>
  );
};
export default Slider;

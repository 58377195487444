import React, {useState} from 'react';

export default function usePasswordToggle() {
    const [visible, setVisibility] = useState(false);

    // setVisibility(state);
    const Icon = <img src={!visible ? "https://s2.svgbox.net/hero-outline.svg?ic=eye&color=000" : "https://s2.svgbox.net/hero-outline.svg?ic=eye-off&color=000"} alt="iconP" width="30" height="30" onClick={(e)=> {
        setVisibility(!visible);
    }} style={{
                transform: "translateX(-100%)"
            }}/>;
    const InputType = visible ? "text" : "password";


    return [InputType, Icon];
}

import moment from "moment";
import React, { useEffect, useState } from "react";
import Confetti from "react-confetti";
import { Redirect } from "react-router-dom";
import PP from "../components/PP";

function PaymentPopup(props) {
	if (props.location.state) {
		var data = props.location.state.data;
		var initialAmount = props.location.state.amount;
		var couponCode = props.location.state.couponCode;
	}

	//

	// var name = data[3].split("%20").join(" ");
	const [mod, setMod] = useState(false);
	// const [data, setData] = useState(window.data);
	//
	//
	const [log, setLog] = useState(false);

	// useEffect(() => {
	//   fetch("https://api.cantileverlabs.com/getProfile", {
	//     headers: { authorization: "Bearer " + localStorage.getItem("token") },
	//   })
	//     .then((res) => res.json())
	//     .then((data) => {
	//       if (data.user) {
	//         setLog(true);
	//

	//         setUserEmail(data.user.email);
	//       } else {
	//         setLog(false);
	//       }
	//
	//
	//
	//     });
	// }, []);

	useEffect(() => {
		setMod(true);
	}, []);

	return (
		<div>
			<br />
			<br />
			<br />
			<br />
			{props.location.state ? (
				<PP
					date={data.date_purchased}
					cost={data.amount}
					initialAmount={initialAmount}
					name={data.name}
					email={data.email}
					order_id={data.id}
					couponCode={couponCode}
					show={mod}
					onClose={() => setMod(false)}
				/>
			) : (
				<Redirect to="/" />
			)}

			{/* <Confetti
        height={window.innerHeight - 10}
        width={window.innerWidth - 10}
      /> */}
			{/* {data ? (
        <PP
          date={data.date_purchased}
          cost={data.amount}
          initialAmount={initialAmount}
          name={data.name}
          email={data.email}
          order_id={data.id}
          couponCode={couponCode}
          show={mod}
          onClose={() => setMod(false)}
        />
      ) : (
        <Redirect to="/" />
      )} */}
		</div>
	);
}

export default PaymentPopup;

// import styles from "../Assets/css/newcoursepage.module.css";
import img1 from "../Assets/newcoursepage_images/mentor.svg";
import img2 from "../Assets/newcoursepage_images/training.svg";
import img3 from "../Assets/newcoursepage_images/mock test.svg";
import img4 from "../Assets/newcoursepage_images/interview.svg";
import img5 from "../Assets/newcoursepage_images/doubt solution.svg";
import img6 from "../Assets/newcoursepage_images/certificate.svg";

// import Tilt from 'react-tilt'
import Tilty from "react-tilty";

const CourseFeature = () => {
  return (
    <div>
      <div class="container">
        <div class="row d-flex justify-content-center">
          <Tilty
            axis="X"
            glare="false"
            max="45"
            perspective={1000}
            easing="cubic-bezier(.03,.98,.52,.99)"
            className="col-sm Tilt  p-2 shadow"
            style={{
              transformStyle: "preserve-3d",
              backgroundColor: "#FAFAFA",
              margin: "1vh 3vw",
              borderRadius: "1rem",
              padding: "0.5rem",
            }}
          >
            <div style={{ margin: "5%", transform: "translateZ(30px)" }}>
              <img
                src={img1}
                alt={img1}
                style={{
                  backgroundColor: "#E1E1E1",
                  padding: "2%",
                  borderRadius: "10px",
                  width: "70px",
                  height: "70px",
                }}
              />
              <br />
              <br />
              <h3 style={{ color: "#185ADB", fontWeight: "600" }}>
                Expert Mentors
              </h3>
              <br />
              <p>
                Get career guidance, mentorship, time strategy management from
                industry experts
              </p>
              <br />
            </div>
          </Tilty>
          <Tilty
            axis="X"
            glare="false"
            max="45"
            perspective={1000}
            easing="cubic-bezier(.03,.98,.52,.99)"
            className="col-sm Tilt  p-2 shadow"
            style={{
              transformStyle: "preserve-3d",
              backgroundColor: "#FAFAFA",
              margin: "1vh 3vw",
              borderRadius: "1rem",
              padding: "0.5rem",
            }}
          >
            <div style={{ margin: "5%", transform: "translateZ(30px)" }}>
              <img
                src={img2}
                alt={img2}
                style={{
                  backgroundColor: "#E1E1E1",
                  padding: "2%",
                  borderRadius: "10px",
                  width: "70px",
                  height: "70px",
                }}
              />
              <br />
              <br />
              <h3 style={{ color: "#185ADB", fontWeight: "600" }}>
                Lifetime Access
              </h3>
              <br />
              <p>Complete Access to the course with student support</p>
              <br />
            </div>
          </Tilty>
          <Tilty
            axis="X"
            glare="false"
            max="45"
            perspective={1000}
            easing="cubic-bezier(.03,.98,.52,.99)"
            className="col-sm Tilt  p-2 shadow"
            style={{
              transformStyle: "preserve-3d",
              backgroundColor: "#FAFAFA",
              margin: "1vh 3vw",
              borderRadius: "1rem",
              padding: "0.5rem",
            }}
          >
            <div style={{ margin: "5%", transform: "translateZ(30px)" }}>
              <img
                src={img3}
                alt={img3}
                style={{
                  backgroundColor: "#E1E1E1",
                  padding: "2%",
                  borderRadius: "10px",
                  width: "70px",
                  height: "70px",
                }}
              />
              <br />
              <br />
              <h3 style={{ color: "#185ADB", fontWeight: "600" }}>Mock Test</h3>
              <br />
              <p>
                Get Practice Cards & Weekly Mock Tests to Access you learning
                Progress with detailed performance report.
              </p>
              <br />
            </div>
          </Tilty>
        </div>

        <br />
        <br />
        <div class="row d-flex justify-content-center">
          <Tilty
            axis="X"
            glare="false"
            max="45"
            perspective={1000}
            easing="cubic-bezier(.03,.98,.52,.99)"
            className="col-sm Tilt  p-2 shadow"
            style={{
              transformStyle: "preserve-3d",
              backgroundColor: "#FAFAFA",
              margin: "1vh 3vw",
              borderRadius: "1rem",
              padding: "0.5rem",
            }}
          >
            <div style={{ margin: "5%", transform: "translateZ(30px)" }}>
              <img
                src={img4}
                alt={img4}
                style={{
                  backgroundColor: "#E1E1E1",
                  padding: "2%",
                  borderRadius: "10px",
                  width: "70px",
                  height: "70px",
                }}
              />
              <br />
              <br />
              <h3 style={{ color: "#185ADB", fontWeight: "600" }}>
                Mock Interviews
              </h3>
              <br />
              <p>
                Practice Mock Interviews with Industry experts and ace your
                interviews
              </p>
              <br />
            </div>
          </Tilty>
          <Tilty
            axis="X"
            glare="false"
            max="45"
            perspective={1000}
            easing="cubic-bezier(.03,.98,.52,.99)"
            className="col-sm Tilt  p-2 shadow"
            style={{
              transformStyle: "preserve-3d",
              backgroundColor: "#FAFAFA",
              margin: "1vh 3vw",
              borderRadius: "1rem",
              padding: "0.5rem",
            }}
          >
            <div style={{ margin: "5%", transform: "translateZ(30px)" }}>
              <img
                src={img5}
                alt={img5}
                style={{
                  backgroundColor: "#E1E1E1",
                  padding: "2%",
                  borderRadius: "10px",
                  width: "70px",
                  height: "70px",
                }}
              />
              <br />
              <br />
              <h3 style={{ color: "#185ADB", fontWeight: "600" }}>
                Doubt Support
              </h3>
              <br />
              <p>
                Get your doubts cleared by experts and have a smooth learning
              </p>
              <br />
            </div>
          </Tilty>
          <Tilty
            axis="X"
            glare="false"
            max="45"
            perspective={1000}
            easing="cubic-bezier(.03,.98,.52,.99)"
            className="col-sm Tilt  p-2 shadow"
            style={{
              transformStyle: "preserve-3d",
              backgroundColor: "#FAFAFA",
              margin: "1vh 3vw",
              borderRadius: "1rem",
              padding: "0.5rem",
            }}
          >
            <div style={{ margin: "5%", transform: "translateZ(30px)" }}>
              <img
                src={img6}
                alt={img6}
                style={{
                  backgroundColor: "#E1E1E1",
                  padding: "2%",
                  borderRadius: "10px",
                  width: "70px",
                  height: "70px",
                }}
              />
              <br />
              <br />
              <h3 style={{ color: "#185ADB", fontWeight: "600" }}>
                Certificates
              </h3>
              <br />
              <p>
                Get awarded with an industry recognized certificate after you
                finish your course
              </p>
              <br />
            </div>
          </Tilty>
        </div>
      </div>
    </div>
  );
};
export default CourseFeature;

import React, { useEffect, useState, useContext } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Testimonials from "../components/TestimonialsVersion2";

import style from "../Assets/css/newmasterclass.module.css";
import styles from "../Assets/css/tcs.module.css";
import BannerComp from "../components/BannerComp";
import useMedia from "../componentsofcourse/useMedia";
import { LastSection } from "./NewMasterClass";
import { Link, useParams, useLocation } from "react-router-dom";
import { AuthContext } from "../Context/AuthContext";
import { BASE_URL } from "../env";
import ReactPlayer from "react-player";

function MasterclassStandalone(props) {
  const { id } = useParams();
  // console.log("id of webianr", id);
  let loc = useLocation();
  const routePath = loc.pathname.split("/");
  // const routePath = "6145c328ff83ef78e9b674c3";
  //   console.log(routePath);
  const [allowed, setallowed] = useState(false);
  const [play, setPlay] = useState(false);
  const matches = useMedia("(min-width : 768px )");
  const { email } = useContext(AuthContext);
  const [MasterclassData, setMasterclassData] = useState([]);
  const [StudentData, setStudentData] = useState({});
  const [isReg, setisReg] = useState(false);
  const [regallowed, setregallowed] = useState(false);
  //test 1
  const regclick = () => {
    fetch(BASE_URL + "/addMasterClassInStudent/" + id, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((data) => {
        window.location.reload();
      });
  };
  useEffect(() => {
    setTimeout(() => {
      fetch(BASE_URL + "/getProfile", {
        headers: { authorization: "Bearer " + localStorage.getItem("token") },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.error) {
            // console.log(data.error);
          } else {
            setStudentData(data);
            console.log(data.student);
            if (data?.student?.masterclasses?.length) {
              setregallowed(true);
            }
            for (let key in data?.student?.masterclasses) {
              if (data?.student?.masterclasses[key]?._id === id) {
                setisReg(true);
                // alert("4 seconds ke baad" );
                return 0;
              }
            }
          }
        });
    }, 4000);
  }, []);
  useEffect(() => {
    fetch(BASE_URL + "/getProfile", {
      headers: { authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          // console.log(data.error);
        } else {
          setStudentData(data);
          console.log(data.student);
          if (data?.student?.masterclasses?.length) {
            setregallowed(true);
          }
          for (let key in data?.student?.masterclasses) {
            if (data?.student?.masterclasses[key]?._id === id) {
              setisReg(true);
              // alert("4 seconds ke baad" );
              return 0;
            }
          }
        }
      });
  }, []);

  useEffect(() => {
    // alert(isReg)
  }, [isReg]);

  useEffect(() => {
    // console.log(StudentData?.student.masterclasses.length)
    if (StudentData?.student?.masterclasses.length) {
      setregallowed(true);
    }
    for (let key in StudentData?.student?.masterclasses) {
      if (StudentData?.student?.masterclasses[key]._id === id) {
        setisReg(true);
        return 0;
      }
    }
    setisReg(false);
    return 0;
  }, [StudentData?.student]);
  useEffect(() => {
    // if (StudentData?.student?.masterclasses.length) {
    //   setregallowed(true);
    // }
    // for (let key in StudentData?.student?.masterclasses) {
    //   if (StudentData?.student?.masterclasses[key]._id === id) {
    //     setisReg(true);
    //     return 0;
    // 	}
    // }
    // setisReg(false);
    // alert("hello world")
  }, []);

  //test 2 finish
  useEffect(() => {
    fetch(BASE_URL + "/getOfflineMasterClass/" + id, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log("Webinar Data", data);
        setMasterclassData(data);
      });
  }, []);

  useEffect(() => {
    if (email !== "") {
      fetch(BASE_URL + "/getData/" + email, {
        method: "GET",
        headers: { "content-type": "application/json" },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.message == "Doesn't Exists!") {
            // console.log(data)
            setallowed(false);
          } else {
            // console.log(data)
            setallowed(true);
          }
        });
      return () => {};
    }
  }, [email]);
  return (
    <>
      {/* Navbar */}
      {/* <Navbar /> */}
      {/* Header Section */}
      <header className={style.headSection}>
        <div className={`container-fluid ${style.layout}`}>
          <div className="row">
            <div className="col-md-7 my-4 py-5 d-flex align-items-start flex-column">
              <h2 className={style.headHeading}>MasterClass</h2>
              <p className={style.headDescription}>
                These career acceleration videos will provide you an
                unprecedented opportunity to learn and become job ready. So, ace
                your next interview by mastering multiple trending topics in
                various industries.
              </p>
              {/* {console.log(regallowed)} */}
              {!isReg ? (
                regallowed ? (
                  <div className={style.registerBtn} onClick={regclick}>
                    Register by a Click
                  </div>
                ) : (
                  <Link
                    to={{
                      // pathname: "/OfflineMasterClass/" + id,
                      pathname: '/register'
                    }}
                    onClick={() => {
                      localStorage.setItem('userlocation', loc.pathname);
                    }}
                  >
                    {" "}
                    <div className={style.registerBtn}>Register Now</div>
                  </Link>
                )
              ) : (
                <div
                  className={style.registerBtn}
                  style={{ background: "#3CC13B", cursor: "default" }}
                >
                  Registered
                </div>
              )}
            </div>
            <div className="col-md-5 d-flex justify-content-center align-items-end mb-0"></div>
          </div>
        </div>
      </header>

      {/* Second Section */}
      <section className={`container-fluid ${style.layout}`}>
        <div className="row gx-5">
          <div className="col-md-6 my-5 order-md-1 order-2">
            <div className={style.secondSecHeading}>From Mentor’s Desk</div>
            <div
              className={style.secondSecText}
              dangerouslySetInnerHTML={{
                __html: MasterclassData?.message?.mentor_desk,
              }}
            >
              {/* {MasterclassData?.message?.mentor_desk} */}
            </div>
            <div className={`${style.secondSecHeading} mt-4`}>
              Why Cantilever Labs ?
            </div>
            <div className={style.secondSecText}>
              Cantilever Labs is an official training partner of IITs, IIMs,
              BITS, NITs, SYMBIOSIS, JNTUH, GITAM, SNIST, Mahindra University,
              among others. Based in IIIT Hyderabad Campus, we provide a 360°
              solution for students to upskill themselves and get placed in Top
              Companies.
            </div>
            <div style={{ height: "10px" }}></div>
            <div className={style.secondSecText}>
              Moreover, we have developed a set of course materials and study
              patterns that allow candidates to get exposure and real-time
              projects with the assistance of faculty members from IITs and
              IIMs.
            </div>
            <br />
            <div className={style.secondSecSubHeading}>
              One stop solution for all your placement needs:
            </div>
            <ul className={style.secondSecList}>
              <li>
                <span>Fastrack Course:</span>
                Aptitude Focus + Mocks
              </li>
              <li>
                <span>Standard Course:</span>
                Aptitude + Programming/DSA Focus + Mocks
              </li>
              <li>
                <span>Pro Course:</span>
                Aptitude + Programming/DSA Focus + Mocks + Resume + Softskills +
                Interview Tips + Linkedin Profile
              </li>
              <li>
                <span>Premium Course:</span>
                Aptitude + Programming/DSA Focus + Mocks Resume + Soft Skills +
                Interview Tips + Linkedin Profile + Hackathon Preparation +
                Guestimation + Projects
              </li>
              <li>
                <span>Mentor Connect:</span>
                Get 1 on 1 mentorship from industry veterans.
              </li>
              <li>
                <span>Crash Courses:</span>
                We extensively curated courses on Programming, Aptitude,
                Interview preparation and mock tests.
              </li>
              <li>
                <span>Job Portal:</span>
                Receive great opportunities for your Dream Career.
              </li>
            </ul>
          </div>
          <div className="col-md-6 my-3 order-md-2 order-1">
            <div className={style.secondSecRightSide}>
              <div className={style.secondSecRightSideHeading}>
                {MasterclassData?.message?.subject}
              </div>
              <div
                className={style.videoImg}
                style={{
                  backgroundImage: isReg
                    ? ""
                    : `url(${MasterclassData?.message?.image}) `,
                  cursor: "pointer",
                }}
              >
                {isReg ? (
                  <>
                    <ReactPlayer
                      width="100%"
                      height="100%"
                      playing={play}
                      pip
                      controls="true"
                      config={{ file: { forceHLS: true } }}
                      url={MasterclassData?.message?.video_link}
                    />
                  </>
                ) : regallowed ? (
                  <img
                    src={MasterclassData?.message?.image}
                    onClick={regclick}
                    style={{ height: "300px", width: "100%" }}
                    className="img-fluid"
                    alt="play icon"
                  />
                ) : (
                  <Link
                    to={{
                      pathname: "/OfflineMasterClass/" + id,
                      state: { offlineId: id },
                    }}
                  >
                    <i class={`fas fa-play ${style.playIcon}`}></i>
                  </Link>
                )}
              </div>
              <div className={style.secondSecRightSideSubHeading}>
                About the Master Class
              </div>
              <div
                className={style.secondSecText}
                dangerouslySetInnerHTML={{
                  __html: MasterclassData?.message?.about_the_webinar,
                }}
              ></div>
              <br />
              <div className={style.secondSecSubHeading}>
                Learning Outcomes:
              </div>
              <ul className={style.secondSecRightList}>
                {MasterclassData?.message?.learning_content.map((item) => (
                  <li>{item}</li>
                ))}
              </ul>

              <br />

              {!isReg ? (
                regallowed ? (
                  <div
                    className={style.secondSecRegisterBtn}
                    onClick={regclick}
                  >
                    Register by a Click
                  </div>
                ) : (
                  <Link
                    to={{
                      pathname: "/OfflineMasterClass/" + id,
                    }}
                  >
                    {" "}
                    <div className={style.secondSecRegisterBtn}>
                      Register Now
                    </div>
                  </Link>
                )
              ) : (
                <div
                  className={style.secondSecRegisterBtn}
                  style={{ background: "#3CC13B", cursor: "default" }}
                >
                  Registered
                </div>
              )}
            </div>
          </div>
        </div>
      </section>

      {/* Banner */}
      <BannerComp />
      <h2
        className="text-center mt-5 mb-5"
        style={{ fontSize: "44px", fontWeight: "bold" }}
      >
        What Our Students Say
      </h2>
      {/* Testimonial Section Start */}
      <div className="" style={{ backgroundColor: "#1877F2" }}>
        <br />
        <br />
        <div className="row" style={{ marginLeft: matches ? "12%" : "6%" }}>
          <div className="col-md-4 col-xs-12 col-12 col-12 justify-content-center pb-2">
            <img
              src="https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644055344268_Image%201.png"
              className="img-fluid"
              style={{ marginLeft: matches == false ? "3%" : null }}
            />
          </div>
          <div
            style={{ textAlign: "left" }}
            className="col-md-6 col-xs-12 col-12 col-12"
          >
            <div>
              <h4 style={{ color: "white" }}>SARVESH MEHTANI</h4>
            </div>
            <div>
              <h5 style={{ color: "white" }}>IIT Bombay - Computer Science</h5>
            </div>
            <br />
            <div>
              <p style={{ color: "white" }}>
                Overall, I loved the fact that the whole process was tailored as
                per the individual's interests and goals which made it very
                effective in pointing out our areas of strength and the areas
                that we need to work on.
              </p>
            </div>

            <br />
            <br />
            <div>
              <button className={styles.sarveshbtn}>
                A.I.R 1, JEE ADVANCED 2017
              </button>
            </div>
          </div>
        </div>
      </div>
      <div style={{ overflowX: "hidden" }}>
        <Testimonials />
      </div>

      {/* Last Section */}
      <section className={style.lastSection}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h4 className={style.lastSectionHeading}>
                Enjoying MasterClass Videos? We have more in line for you
              </h4>
            </div>
          </div>
          <LastSection />
        </div>
      </section>

      {/* Footer */}
      <Footer />
    </>
  );
}

export default MasterclassStandalone;

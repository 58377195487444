import React from "react";
import { useState } from "react";
import "../Assets/css/instad1.css";
import ReactPixel from 'react-facebook-pixel';
import { useEffect } from "react";
import useMedia from "./useMedia";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const InstaAdLanding1 = () => {
     const matches = useMedia("(min-width : 768px )");
    const advancedMatching = { }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
    const options = {
        autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
        debug: false, // enable logs
    };
    ReactPixel.init('337209321107283', advancedMatching, options);
    useEffect(()=>{

        ReactPixel.trackCustom("InstaADlanding");
    },[])
  const [email, setemail] = useState("");
  const [firstName, setFirstName] = useState("");
  function value() {
    if (email === "" && firstName == "") {
      alert("please enter the email and first name");
    }
    if (email === "" && firstName !== "") {
      alert("please enter the email");
    }
    if (email !== "" && firstName === "") {
      alert("please enter the first Name");
    }
    fetch("https://api.cantileverlabs.com/adInstaUpload", {
      method: "POST",
      headers: { "content-type": "application/json" },
    })
      .then((res) => res.json())
      .then((data) => {

          setemail("");
          setFirstName("");

      });
       ReactPixel.track("Leads");

  }
  return (
      <div>{ matches == false ?
        <div>
            {/* <Navbar /> */}
          <div className="container-fluid ">
              <div className="container grad p-4">
                  <div className="row">
                      <h1 className="text-dark my-5" style={{ fontWeight: "bold" }}>
                          Skills you already have to start working as a virtual assistance?
                      </h1>
                  </div>
                  <div className="row d-flex justify-content-center">
                      <p className="lead">
                          The Virtual Assistant Checklist & Starter kit is my step-by-step
                          blueprint for starting your virtual assistance business Fast.
                      </p>
                  </div>
                  <div className="row">
                      <input
                          value={email}
                          onChange={(e) => setemail(e.target.value)}
                          className="input p-3 m-2"
                          placeholder="Email Address"
                          type="text"
                          />{" "}
                      <br />
                      <input
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                          className="input p-3 m-2"
                          placeholder="First Name"
                          type="text"
                          />{" "}
                      <br />
                      <button
                          onClick={() => value()}
                          className="btn btn-dark p-2 m-2 mb-5 "
                          style={{
                              fontWeight: "300",
                              borderTopLeftRadius: "4px",
                              borderTopRightRadius: "4px",
                              borderBottomRightRadius: "4px",
                              borderBottomLeftRadius: "4px",
                            }}
                            >
                          DOWNLOAD NOW
                      </button>
                  </div>
              </div>

              <div className="container my-5" style={{ margin: "5px" }}>
                  <h5 className="text-dark">
                      {" "}
                      HERE'S WHAT YOU'LL GET INSIDE THE FREE CHECKLIST & STARTER KIT
                  </h5>

                  <p className="blockquote my-3">
                      <i class="fas fa-award"></i> My signature method for starting a
                      virtual assistant business all in a easy to follow blueprint
                  </p>
                  <p className="blockquote my-3">
                      <i class="fas fa-award"></i> My signature method for starting a
                      virtual assistant business all in a easy to follow blueprint
                  </p>
                  <p className="blockquote my-3">
                      <i class="fas fa-award"></i> My signature method for starting a
                      virtual assistant business all in a easy to follow blueprint
                  </p>
              </div>
          </div>
          <Footer />
          </div>
           : <div>
               {/* <Navbar /> */}
                <div className="grad" style={{position:"absolute"/*,top:0,transform:"translateY(-50%)"*/}}>
                <div className="container-fluid grad p-5" >
              <div className="row">
                      <div className="col">
                          <div className="row">
                      <h1 className="text-dark my-5" style={{ fontWeight: "bold" }}>
                          Skills you already have to start working as a virtual assistance?
                      </h1>
                  </div>
                  <div className="row d-flex justify-content-center">
                      <p className="lead">
                          The Virtual Assistant Checklist & Starter kit is my step-by-step
                          blueprint for starting your virtual assistance business Fast.
                      </p>
                          </div>
                          <div className="container my-5" style={{ margin: "5px" }}>
                  <h5 className="text-dark">
                      {" "}
                      HERE'S WHAT YOU'LL GET INSIDE THE FREE CHECKLIST & STARTER KIT
                  </h5>

                  <p className="blockquote my-3">
                      <i class="fas fa-award"></i> My signature method for starting a
                      virtual assistant business all in a easy to follow blueprint
                  </p>
                  <p className="blockquote my-3">
                      <i class="fas fa-award"></i> My signature method for starting a
                      virtual assistant business all in a easy to follow blueprint
                  </p>
                  <p className="blockquote my-3">
                      <i class="fas fa-award"></i> My signature method for starting a
                      virtual assistant business all in a easy to follow blueprint
                  </p>
              </div>



                      </div>

                      <div className="col " style={{marginTop:"12%"}}><div className="row">
                          <h1>Please enter your details and get a chance to win something</h1>

                      <input
                          value={email}
                          onChange={(e) => setemail(e.target.value)}
                          className="input p-3 m-2"
                          placeholder="Email Address"
                          type="text"
                          />{" "}
                      <br />
                      <input
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                          className="input p-3 m-2"
                          placeholder="First Name"
                          type="text"
                          />{" "}
                      <br />
                      <button
                          onClick={() => value()}
                          className="btn btn-dark p-2 m-2 mb-5 "
                          style={{
                              fontWeight: "300",
                              borderTopLeftRadius: "4px",
                              borderTopRightRadius: "4px",
                              borderBottomRightRadius: "4px",
                              borderBottomLeftRadius: "4px",
                            }}
                            >
                          DOWNLOAD NOW
                      </button>
                  </div></div>
              </div>
          </div>
          <Footer />
          </div>
          </div>
    }
    </div>
  );
};

export default InstaAdLanding1;

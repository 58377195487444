import React, { useState } from "react";
import { Carousel } from "react-bootstrap";
import quizBg from "../../Assets/img/misc/quiz-bg.png";
//import banner from "../../Assets/img/Masterclass/Frame 1.png";
import banner1 from "../../Assets/img/Masterclass/Banner1.png";
import banner2 from "../../Assets/img/Masterclass/Banner2.png";
import style from "../../Assets/css/b2bCarouselMain.module.css";

function B2BTest() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const styles = {
    text: {
      margin: "30%",
    },
  };

  return (
    <div>
      <Carousel
        fade
        // className={`${style["carousel-indicators"] ${style.active}`}
        className={`${style["carousel-indicators"]} ${style.active}`}
        style={{ zIndex: "0" }}
        controls={false}
        prevIcon={
          <span aria-hidden="false" className="carousel-control-prev-icon" />
        }
        activeIndex={index}
        onSelect={handleSelect}
        slide="true"
      >
        {/* <Carousel.Item interval={9000}> */}
          {/* <div  styles={{ backgroundImage:`url(${car})` }}> */}
{/* 
          <img
            style={{ height: "20vw" }}
            className="d-block w-100"
            src="https://cantileverlabs-userprofile-images.s3.ap-south-1.amazonaws.com/Banner1.png"

            alt="First slide"
          /> */}

          {/* <Carousel.Caption style={{ color: "white" }}> */}
            {/* <h3 style={styles.text}>First</h3> */}
            {/* <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
          {/* </Carousel.Caption> */}
        {/* </Carousel.Item> */}
        <Carousel.Item interval={9000}>
          <img
            style={{ height: "20vw" }}
            className="d-block w-100"
            src="https://cantileverlabs-userprofile-images.s3.ap-south-1.amazonaws.com/Banner2.png"
            // alt="Second slide"
          />

          <Carousel.Caption>
            {/* <h3 style={styles.text}>Second</h3> */}
            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={9000}>
          <img
            style={{ height: "20vw" }}
            className="d-block w-100"
            src="https://cantileverlabs-jobposting-images.s3.ap-south-1.amazonaws.com/Artboard+%E2%80%93+5.png"
            // src="holder.js/800x400?text=Third slide&bg=20232a"
            alt="Third slide"
          />

          <Carousel.Caption>
            {/* <h3 style={styles.text}>Third</h3> */}
            <p>
              {/* Praesent commodo cursus magna, vel scelerisque nisl consectetur. */}
            </p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>
  );
}

export default B2BTest;

export const queryCallbackFactory = (Factory) => {
  return new Promise((resolve, reject) => {
    Factory()
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};


/**
 *
 * @param {String} examType
 * @returns String
 */
export function getColor(examType) {
  switch (examType) {
    case "coding":
      return "#EBF6F8";

    case "quiz":
      return "#F7F5F4";
    
    case "hybrid":
      return "#DDECFE"
    default:
      return "";
  }
}

import React from 'react'
import './b2bsectionpartnew.css'
// import ourpartners from '../../../Assets/img/ourpartners.png'
const B2BSectionPartNew = () => {
    return (
        <div className="text-center container ">
            <img width="100%" height="auto" src="https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644086433254_ourpartners.png" alt="" />
            <br/> <br/> <br/>
        </div>
    )
}

export default B2BSectionPartNew

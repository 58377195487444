import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";

const ProgressOverview = (props) => {
  let { color, progress, title, text } = props;
  console.log(progress)
  return (
    <>
      <div className="progressOverview">
        <CircularProgressbar
          value={progress}
          text={`${progress}%`}
          strokeWidth="11"
          styles={buildStyles({
            textSize: "20",
            pathColor: `${color}`,
            textColor: "#000",
            trailColor: "#D9E1E7",
          })}
          className="powerbiCircularBar"
        />
        <div className="progressOverviewDetails">
          <div className="title">
            Total <span>{title}</span>
          </div>
          <div className="text">{text}</div>
        </div>
      </div>
    </>
  );
};

export default ProgressOverview;

import React from 'react'
import { useHistory } from 'react-router'

const AlreadydoneExam = () => {
    const history = useHistory()
    return (
        <div style={{background:"var(--outer-background-code)",height:"100vh"}}>
            <div className="d-grid place-content-center h-100">
                <h1 className="text-white">You have already finished this exam</h1>
                <button className="btn-outline-danger btn" onClick={()=>{history.push("/")}}>Go To Home Page</button>
            </div>
        </div>
    )
}

export default AlreadydoneExam

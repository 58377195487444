import { useState, useEffect } from "react";
import LoginTestimonial from "../components/LoginTestimonial";
import OtpLogin from "../components/OtpLogin";
import { useGoogleLogin } from "@react-oauth/google";
import { NavLink, useHistory } from "react-router-dom";
import axios from "axios";
import { BASE_URL, BASE_URL_MICRO } from "../env";
import "../Assets/scss/Register.scss";

const Login = ({ hidenavbar }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [warning, setWarning] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [show, setshow] = useState(false);
  const [step, setStep] = useState(1);
  const [timeLeftOtp, setTimeLeftOtp] = useState(120000);
  const [showPassword, setShowPassword] = useState(false);
  const history = useHistory();

  // console.log(location);

  const submitEmail = async (e) => {
    e.preventDefault();
    setSpinner(true);
    try {
      await axios({
        method: "post",
        url: `${BASE_URL}/checkuserexistsornotnew `,
        data: {
          email: email,
        },
        headers: {
          "Content-type": "application/json",
        },
      });
      setWarning(true);
      setSpinner(false);
      setTimeout(() => {
        setWarning(false);
      }, 6000);
    } catch (error) {
      setStep(2);
      setWarning(false);
      setSpinner(false);
    }
  };

  const submitPassword = async (e) => {
    e.preventDefault();
    setSpinner(true);
    try {
      const res = await axios({
        method: "post",
        url: `${BASE_URL_MICRO}/signinnew`,
        data: {
          email: email,
          password: password,
        },
        headers: {
          "Content-type": "application/json",
        },
      });
      localStorage.clear();
      localStorage.setItem("token", res.data.token);
      setSpinner(false);
      setWarning(false);
      const urlParams = new URLSearchParams(window.location.search);
      const myParam = urlParams.get("redirect");
      if (myParam === "" || !myParam) {
        history.push("/profile");
      } else {
        history.push(window.location.search.slice(10));
      }
    } catch (error) {
      setSpinner(false);
      setWarning(true);
      setTimeout(() => {
        setWarning(false);
      }, 6000);
    }
  };

  const googleSignIn = useGoogleLogin({
    onSuccess: async ({ access_token }) => {
      try {
        const userInfo = await axios
          .get("https://www.googleapis.com/oauth2/v3/userinfo", {
            headers: { Authorization: `Bearer ${access_token}` },
          })
          .then((res) => res.data);

        await SignInUser(userInfo.email);
      } catch (err) {}
    },
  });

  const SignInUser = async (account) => {
    try {
      setSpinner(true);
      const res = await axios({
        method: "Post",
        url: `${BASE_URL_MICRO}/signinnew`,
        data: {
          typeofuser: "googleuser",
          email: account,
          password: "",
        },
        headers: {
          "Content-type": "application/json",
        },
      });
      localStorage.clear();
      localStorage.setItem("token", res.data.token);
      if (localStorage.getItem("userlocation")) {
        window.location.href = localStorage.getItem("userlocation");
        localStorage.removeItem("userlocation");
      } else window.location.href = "/profile";
      // setTimeout(() => {
      //   window.reload();
      // }, 1000);
    } catch (error) {
      console.error(error);
    } finally {
      setSpinner(false);
    }
  };

  const GenerateOtp = async () => {
    try {
      await axios({
        method: "Post",
        url: `${BASE_URL}/generateotp`,
        data: {
          email: email,
        },
        headers: {
          "Content-type": "application/json",
        },
      });
    } catch (error) {
      console.log("something went wrong");
      var data = error.response.data;
      if (data.err.timeLeft) {
        console.log("OTP already sent");
        setTimeLeftOtp(data.err.timeLeft);
      }
    }
  };

  useEffect(() => {
    hidenavbar(true);
    return () => {
      hidenavbar(false);
    };
  }, [hidenavbar]);

  return (
    <>
      <div className="loginForm">
        <OtpLogin
          show={show}
          setshow={setshow}
          email={email}
          time={timeLeftOtp}
          setTimeLeftOtp={setTimeLeftOtp}
        />
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 mb-0 p-0 order-lg-1 order-2">
              <LoginTestimonial />
            </div>
            <div className="col-lg-6 mb-0 p-0 order-lg-2 order-1">
              <div className="form">
                <div className="title">
                  Sign In <br />
                  <span>To continue with Cantilever Labs</span>
                </div>
                <div className="mid-Form">
                  <p className={warning ? "d-block" : "d-none"}>
                    Please enter correct detail
                  </p>
                  {step === 1 ? (
                    <>
                      <form onSubmit={submitEmail}>
                        <div className="form-floating">
                          <input
                            type="email"
                            name="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="form-control"
                            id="InputEmail"
                            placeholder="name@example.com"
                            required
                          />
                          <label htmlFor="InputEmail">Enter your email</label>
                        </div>
                        <button type="submit" className="submitBtn">
                          {spinner ? (
                            <div className="spinner-border spinner-border-sm"></div>
                          ) : (
                            "Next"
                          )}
                        </button>
                      </form>
                    </>
                  ) : (
                    <>
                      <form onSubmit={submitPassword}>
                        <div
                          style={{
                            position: "relative",
                          }}
                        >
                          <div className="form-floating">
                            <input
                              type={!showPassword ? "password" : "text"}
                              name="password"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                              className="form-control"
                              id="InputPassword"
                              placeholder="Password"
                              required
                            />
                            <label htmlFor="InputPassword">
                              Enter your password
                            </label>
                          </div>
                          <div
                            style={{
                              position: "absolute",
                              top: "1rem",
                              right: "18px",
                            }}
                            onClick={() => {
                              // alert('hi')
                              setShowPassword((prev) => !prev);
                            }}
                          >
                            {!showPassword ? (
                              <svg
                                viewBox="0 0 24 24"
                                fill="none"
                                height="20"
                                width="20"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                <g
                                  id="SVGRepo_tracerCarrier"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></g>
                                <g id="SVGRepo_iconCarrier">
                                  {" "}
                                  <path
                                    d="M2.99902 3L20.999 21M9.8433 9.91364C9.32066 10.4536 8.99902 11.1892 8.99902 12C8.99902 13.6569 10.3422 15 11.999 15C12.8215 15 13.5667 14.669 14.1086 14.133M6.49902 6.64715C4.59972 7.90034 3.15305 9.78394 2.45703 12C3.73128 16.0571 7.52159 19 11.9992 19C13.9881 19 15.8414 18.4194 17.3988 17.4184M10.999 5.04939C11.328 5.01673 11.6617 5 11.9992 5C16.4769 5 20.2672 7.94291 21.5414 12C21.2607 12.894 20.8577 13.7338 20.3522 14.5"
                                    stroke="#000000"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  ></path>{" "}
                                </g>
                              </svg>
                            ) : (
                              <svg
                                viewBox="0 0 24 24"
                                height="20"
                                width="20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                <g
                                  id="SVGRepo_tracerCarrier"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></g>
                                <g id="SVGRepo_iconCarrier">
                                  {" "}
                                  <path
                                    d="M9 4.45962C9.91153 4.16968 10.9104 4 12 4C16.1819 4 19.028 6.49956 20.7251 8.70433C21.575 9.80853 22 10.3606 22 12C22 13.6394 21.575 14.1915 20.7251 15.2957C19.028 17.5004 16.1819 20 12 20C7.81811 20 4.97196 17.5004 3.27489 15.2957C2.42496 14.1915 2 13.6394 2 12C2 10.3606 2.42496 9.80853 3.27489 8.70433C3.75612 8.07914 4.32973 7.43025 5 6.82137"
                                    stroke="#1C274C"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                  ></path>{" "}
                                  <path
                                    d="M15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12Z"
                                    stroke="#1C274C"
                                    stroke-width="1.5"
                                  ></path>{" "}
                                </g>
                              </svg>
                            )}
                          </div>
                        </div>
                        <button type="submit" className="submitBtn">
                          {spinner ? (
                            <div className="spinner-border spinner-border-sm"></div>
                          ) : (
                            "Submit"
                          )}
                        </button>
                      </form>
                      <div
                        className="loginWithOtp"
                        onClick={() => {
                          setshow(true);
                          GenerateOtp();
                        }}
                      >
                        Login with OTP
                      </div>
                    </>
                  )}
                  <div className="divider">or</div>
                  {/* <GoogleLogin
                    onSuccess={responseGoogle}
                    onError={responseGoogle}
                  /> */}
                  <button className="googleBtn" onClick={googleSignIn}>
                    <img
                      src="https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1645106072335_flat-color-icons_google.png"
                      alt="google icon"
                      className="googleIcon"
                    />
                    Sign in with Google
                  </button>
                </div>
                <div className="text text-center">
                  <NavLink to="/forgotpassword" className="link_text">
                    Forgot Password?
                  </NavLink>
                  <br />
                  Create your FREE account{" "}
                  <NavLink to="/register" className="link_text">
                    Sign Up
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;

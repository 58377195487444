import React from "react";
import { Link } from "react-router-dom";
// import cpImg from "../Assets/img/course/cp-img.png";
// import pbImg from "../Assets/img/course/pb-img.png";
// import dsImg from "../Assets/img/course/ds-img.png";
// import wdImg from "../Assets/img/course/wd-img.png";
import useMedia from "../componentsofcourse/useMedia";
import "../Assets/css/course.css";
import ReactGA from "react-ga";
function Course() {
  const matches = useMedia("(min-width : 768px )");

  function ExploreMore() {
    ReactGA.initialize("UA-198180460-1");
    ReactGA.event({
      category: "HomePage",
      type: "Button",
      name: "Explore_More_Home_page",
      action: "The user has clicked the Explore button",
    });
  }
  return (
    <>
      <div className="container my-5">
        <div className="row gx-5 pt-5">
          {/* Course #1 */}
          <div class="col-lg-3 col-sm-6">
            <div className="courseWrapperBox">
              <div className="courseWrapperHeader">
                <img className="img-fluid courseWrapperImg" src={'https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644209631304_cp-img.png'} />
              </div>
              <div className="courseWrapperBody">
                <p className="courseWrapperHeading">
                  Competitive <br /> Programming
                </p>
                <Link
                  to="/fasttrackcourse"
                  className="courseWrapperExpBtn"
                  onClick={() => {
                    ExploreMore();
                    window.scroll(0, 0);
                  }}
                >
                  Explore
                </Link>
              </div>
            </div>
          </div>
          {/* Course #2 */}
          <div class="col-lg-3 col-sm-6">
            <div className="courseWrapperBox">
              <div className="courseWrapperHeader">
                <img className="img-fluid courseWrapperImg" src={'https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644209699304_pb-img.png'} />
              </div>
              <div className="courseWrapperBody">
                <p className="courseWrapperHeading">
                  Get <br /> Placement Ready
                </p>
                <Link
                  to="/newcoursepage"
                  className="courseWrapperExpBtn"
                  onClick={() => {
                    window.scroll(0, 0);
                  }}
                >
                  Explore
                </Link>
              </div>
            </div>
          </div>
          {/* Course #3 */}
          <div class="col-lg-3 col-sm-6">
            <div className="courseWrapperBox">
              <div className="courseWrapperHeader">
                <img className="img-fluid courseWrapperImg" src={'https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644209823778_wd-img.png'} />
              </div>
              <div className="courseWrapperBody">
                <p className="courseWrapperHeading">
                  Web <br /> Development
                </p>
                <div className="courseWrapperComingBtn">Coming Soon...</div>
              </div>
            </div>
          </div>
          {/* Course #4 */}
          <div class="col-lg-3 col-sm-6">
            <div className="courseWrapperBox">
              <div className="courseWrapperHeader">
                <img className="img-fluid courseWrapperImg" src={'https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644209770868_ds-img.png'} />
              </div>
              <div className="courseWrapperBody">
                <p className="courseWrapperHeading">
                  Data <br /> Science
                </p>
                <div className="courseWrapperComingBtn">Coming Soon...</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Course;

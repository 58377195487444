import React from "react";
import ReactPlayer from 'react-player'
import { Throttle } from 'react-throttle';
import { BASE_URL } from "../env";
import { useParams } from "react-router-dom";

const TempPopup = (props) => {

  console.log(props.video)

  const { id } = useParams();

  const handleProgress = (e, videoid, courseid) => {
  console.log('progress', e.playedSeconds);

  fetch(BASE_URL + `/changevideoprogress/${videoid}/${courseid}`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
    body: JSON.stringify({ progress: Math.floor(e.playedSeconds) })
  })

}
  const playerRef = React.useRef(null);
  return props.video ? (
    <div
      data-aos="zoom-in-up"
      data-duration="3000"
      className=" rounded py-3 bg danger"
      style={{
        position: "fixed",
        backdropFilter:"blur(10px)",
        // background: "#fff",
        bottom: "2%",
        height: "98%",
        width: "98%",
     
        zIndex: "30",
      }}
    >
      {" "}<span style={{transform:"translate(215px,-25px)",float:"right",borderRadius:"50%",transform:"scale(1.2)"}} className="close-icon btn btn-danger" onClick={props.handleClose}>
        X
      </span>

      {/*  <img width="100%" src={event_2} alt="kick start to crack product companies" /> */}
      <div className="d-flex justify-content-center" style={{ height: "100%" }}>
        <Throttle time='10000' handler="onProgress"> 
      <ReactPlayer
    url={props.video}
    controls
    onProgress={(e) => handleProgress(e, props.video._id, id)}
    width = "85%"
    height = "85%"
          />
          </Throttle>
      </div>
    </div>
  ) : (
    <div
      data-aos="zoom-in-up"
      data-duration="3000"
      className=" rounded py-3 "
      style={{
        position: "fixed",
        backdropFilter: "blur(10px)",
      
        bottom: "5%",
        height: "90%",
        width: "90%",
        left: "5%",
        zIndex: "30",
      }}
    >
<span style={{float:"right"}} className="close-icon btn btn-danger" onClick={props.handleClose}>
        X
      </span>      {/*  <img width="100%" src={event_2} alt="kick start to crack product companies" /> */}
      <div style={{backdropFilter:"blur(10px)",  height: "100%"}} className="d-flex justify-content-center">
        <iframe
          className="mx-4"
          style={{ width: "30%" }}
          src="https://tawk.to/chat/60feb128d6e7610a49ad0b71/1fdu387d4"
          title="doubts"
        ></iframe>
      </div>
    </div>
  );
};

export default TempPopup;

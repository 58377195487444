import React from "react";
import "../Assets/scss/NewHomePage.scss";
import HomePage from "../components/HomePage";
import cantileverlogoblack from "../Assets/img/cantileverlogoblack.png";
import algoexpert from "../Assets/img/algoexpert.jpeg";
import Rocket from "../Assets/img/rocket.svg";

function NewHomePage() {
  return (
    <>
      <div id="homepage-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-6">
              <div className="header-heading">
                Fast Forward your <br />
                Placement Journey
              </div>
              <div className="header-text">with Customised Courses</div>
              <div className="btn get-btn">Get Started</div>
              <img src={Rocket} className="rocket" alt="rocket" />
              <div className="waves">
                <div className="circle-one">
                  <div className="inner-circle"></div>
                </div>
                <div className="circle-two">
                  <div className="inner-circle"></div>
                </div>
              </div>
            </div>
            <div className="col-6">
              <lottie-player
                src="https://assets9.lottiefiles.com/packages/lf20_ocr4igj6.json"
                background="transparent"
                speed="1"
                loop
                autoplay
              ></lottie-player>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-5 d-grid place-content-center">
        <h1 className="text-center mx-auto balsamiq">
          Why{" "}
          <img
            style={{ width: "240px" }}
            src={cantileverlogoblack}
            alt="logo"
          />
          ?
        </h1>
        <HomePage />
      </div>

      <br />
      <br />

      <img src={algoexpert} style={{ width: "100%" }} alt="" />
      <div className="new-social-impact d-flex flex-row">
        <div className="image align-items-center"></div>
      </div>
    </>
  );
}

export default NewHomePage;

import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import ReactPixel from "react-facebook-pixel";

const SuccessPage = ({ hidenavbar }) => {
  useEffect(() => {
    hidenavbar(true);
    return () => {
      hidenavbar(false);
    };
  }, []);
  const history = useHistory();
  const queryParams = new URLSearchParams(window.location.search);
  const courseid = queryParams.get("courseid");
  const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
  const optionss = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: false, // enable logs
  };
  useEffect(() => {
    ReactPixel.init("337209321107283", advancedMatching, optionss);
    ReactPixel.trackCustom("TCS_NEW_PAID_THANK_YOU_PAGE");
  }, []);
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div
            className="col-12 mb-0 d-flex justify-content-center align-items-center"
            style={{ height: "50vh" }}
          >
            <lottie-player
              src="https://assets7.lottiefiles.com/packages/lf20_sqi4v74j.json"
              background="transparent"
              speed="1"
              loop
              autoplay
              style={{ width: "240px" }}
            ></lottie-player>
          </div>

          <div
            className="col-12 mb-0 d-flex justify-content-evenly align-items-center flex-column"
            style={{ height: "50vh", backgroundColor: "#009915" }}
          >
            <button
              className="btn"
              style={{
                fontFamily: "Nunito-ExtraBold",
                backgroundColor: "#fff",
                color: "#009915",
                display: "flex",
                padding: "10px 30px",
                fontSize: "18px",
              }}
              onClick={() => {
                history.push("/course-page/" + courseid);
              }}
            >
              Go to Course
            </button>
            <p
              style={{
                fontFamily: "Nunito-SemiBold",
                fontSize: "18px",
                color: "#fff",
              }}
            >
              <a
                className="text-decoration-underline"
                href={
                  courseid == "622c32f1f4f87bc3543a1c2f"
                    ? "https://chat.whatsapp.com/IuygZoojIhL84hOXmSaaQS"
                    : "https://chat.whatsapp.com/JBOxYhfENqMF4NmwI0QlXP"
                }
                style={{ fontFamily: "Nunito-ExtraBold", color: "#fff" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                Click here
              </a>{" "}
              to join our Whatsapp Group.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default SuccessPage;

import { useState, useEffect } from "react";
import LoginTestimonial from "../components/LoginTestimonial";
import OtpLogin from "../components/OtpLogin";
import GoogleLogin from "react-google-login";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { BASE_URL, BASE_URL_MICRO } from "../env";
import "../Assets/scss/Register.scss";

const LoginWithRedirect = ({ hidenavbar }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [warning, setWarning] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [show, setshow] = useState(false);
  const [step, setStep] = useState(1);

  const [timeLeftOtp, setTimeLeftOtp] = useState(120000);

  // get the redirect uri from the url
  
  const searchParams = new URLSearchParams(window.location.search);
  const path = searchParams.get('redirect_uri');
  // console.log(location);

  const submitEmail = async (e) => {
    e.preventDefault();
    setSpinner(true);
    try {
      await axios({
        method: "post",
        url: `${BASE_URL}/checkuserexistsornotnew `,
        data: {
          email: email,
        },
        headers: {
          "Content-type": "application/json",
        },
      });
      setWarning(true);
      setSpinner(false);
      setTimeout(() => {
        setWarning(false);
      }, 6000);
    } catch (error) {
      setStep(2);
      setWarning(false);
      setSpinner(false);
    }
  };

  const submitPassword = async (e) => {
    e.preventDefault();
    setSpinner(true);
    try {
      const res = await axios({
        method: "post",
        url: `${BASE_URL_MICRO}/signinnew`,
        data: {
          email: email,
          password: password,
        },
        headers: {
          "Content-type": "application/json",
        },
      });
      localStorage.clear();
      localStorage.setItem("token", res.data.token);
      if (localStorage.getItem("userlocation")) {
        window.location.href = localStorage.getItem("userlocation");
        localStorage.removeItem("userlocation");
      } else window.location.href = path;
      setSpinner(false);
      setWarning(false);
    } catch (error) {
      setSpinner(false);
      setWarning(true);
      setTimeout(() => {
        setWarning(false);
      }, 6000);
    }
  };

  const SignInUser = async (account) => {
    try {
      const res = await axios({
        method: "Post",
        url: `${BASE_URL_MICRO}/signinnew`,
        data: {
          typeofuser: "googleuser",
          email: account,
          password: "",
        },
        headers: {
          "Content-type": "application/json",
        },
      });
      localStorage.clear();
      localStorage.setItem("token", res.data.token);
      if (localStorage.getItem("userlocation")) {
        window.location.href = localStorage.getItem("userlocation");
        localStorage.removeItem("userlocation");
      } else window.location.href = "/profile";
      // setTimeout(() => {
      //   window.reload();
      // }, 1000);
    } catch (error) {
      console.error(error);
    }
  };

  const GenerateOtp = async () => {
    try {
      await axios({
        method: "Post",
        url: `${BASE_URL}/generateotp`,
        data: {
          email: email,
        },
        headers: {
          "Content-type": "application/json",
        },
      });
    } catch (error) {
      console.log("something went wrong");
      var data = error.response.data;
      if(data.err.timeLeft){
        console.log("OTP already sent");
        setTimeLeftOtp(data.err.timeLeft);
      }
    }
  };

  useEffect(() => {
    hidenavbar(true);
    return () => {
      hidenavbar(false);
    };
  }, [hidenavbar]);

  const responseGoogle = (e) => {
    let gmailId = e.profileObj.email;
    SignInUser(gmailId);
  };

  return (
    <>
      <div className="loginForm">
        <OtpLogin show={show} setshow={setshow} email={email} time={timeLeftOtp} setTimeLeftOtp={setTimeLeftOtp}/>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 mb-0 p-0 order-lg-1 order-2">
              <LoginTestimonial />
            </div>
            <div className="col-lg-6 mb-0 p-0 order-lg-2 order-1">
              <div className="form">
                <div className="title">
                  Sign In <br />
                  <span>To continue with Cantilever Labs</span>
                </div>
                <div className="mid-Form">
                  <p className={warning ? "d-block" : "d-none"}>
                    Please enter correct detail
                  </p>
                  {step === 1 ? (
                    <>
                      <form onSubmit={submitEmail}>
                        <div className="form-floating">
                          <input
                            type="email"
                            name="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="form-control"
                            id="InputEmail"
                            placeholder="name@example.com"
                            required
                          />
                          <label for="InputEmail">Enter your email</label>
                        </div>
                        <button type="submit" className="submitBtn">
                          {spinner ? (
                            <div className="spinner-border spinner-border-sm"></div>
                          ) : (
                            "Next"
                          )}
                        </button>
                      </form>
                    </>
                  ) : (
                    <>
                      <form onSubmit={submitPassword}>
                        <div className="form-floating">
                          <input
                            type="password"
                            name="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="form-control"
                            id="InputPassword"
                            placeholder="Password"
                            required
                          />
                          <label for="InputPassword">Enter your password</label>
                        </div>
                        <button type="submit" className="submitBtn">
                          {spinner ? (
                            <div className="spinner-border spinner-border-sm"></div>
                          ) : (
                            "Submit"
                          )}
                        </button>
                      </form>
                      <div
                        className="loginWithOtp"
                        onClick={() => {
                          setshow(true);
                          GenerateOtp();
                        }}
                      >
                        Login with OTP
                      </div>
                    </>
                  )}
                  <div className="divider">or</div>
                  <GoogleLogin
                    clientId="671105516475-03k9jvsa3evmg1k4u5ge13alls8v9nfv.apps.googleusercontent.com"
                    render={(renderProps) => (
                      <div
                        className="googleBtn"
                        onClick={renderProps.onClick}
                        disabled={renderProps.disabled}
                      >
                        <img
                          src="https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1645106072335_flat-color-icons_google.png"
                          alt="google icon"
                          className="googleIcon"
                        />
                        Sign in with Google
                      </div>
                    )}
                    buttonText="Login"
                    onSuccess={responseGoogle}
                    // onFailure={responseGoogle}
                    cookiePolicy={"single_host_origin"}
                  />
                  {/* <div className="googleBtn">
                    <img
                      src="https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1645106072335_flat-color-icons_google.png"
                      alt="google icon"
                      className="googleIcon"
                    />
                    Sign in with Google
                  </div> */}
                </div>
                <div className="text text-center">
                  <NavLink to="/forgotpassword" className="link_text">
                    Forgot Password?
                  </NavLink>
                  <br />
                  Create your FREE account{" "}
                  <NavLink to="/register" className="link_text">
                    Sign Up
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginWithRedirect;

import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
// import ReactQuill from "react-quill";
// import "react-quill/dist/quill.snow.css";
import { AuthContext } from "../../../Context/AuthContext";
import { Store } from "react-notifications-component";
import axios from "axios";
import styles from "../../../Assets/css/Editor.module.css";
import "../../../Assets/css/Editor.module.css";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { BASE_URL } from "../../../env";
import SunEditor from "suneditor-react";
// const BASE_URL = "http://localhost:5000";
import "suneditor/dist/css/suneditor.min.css";

const Editor = () => {
	const handleEditorChange = (content) => {
		console.log(content);
	};
	const history = useHistory();
	if (localStorage.token === "" || localStorage.token === undefined) {
		history.push("/login");
	}
	const { token } = useContext(AuthContext);
	const [text, setText] = useState("");
	const [invalid, setInvalid] = useState(false);
	const [loader, setLoader] = useState(false);
	const [_id, setUserID] = useState("");
	const [readingDuration, setReadingDuration] = useState("");
	// const []
	// const [value, setValue] = useState("");
	const [topic, setTopic] = useState("Choose the Topic of Blog");
	const handleSelectDrop = (e) => {
		// setValue(e);
		setTopic(e);
	};
	useEffect(() => {
		fetch(BASE_URL + "/getProfile", {
			method: "GET",
			headers: { "Content-Type": "application/json", authorization: token },
		})
			.then((res) => res.json())
			.then((data) => {
        if (data) { setUserID(data?.user?._id) };
				// console.log(data.user._id);
			});
	}, [token]);
	function handleChange(value) {
		setText(value);
		console.log(value);
	}
	const [id, setID] = useState("");
	async function handleSubmit(event) {
		if (id !== "") {
			event.preventDefault();
			setLoader(true);
			let imageFile;
			const title = document.querySelector("#title").value;
			const author = document.querySelector("#author").value;
			const image = document.querySelector("#upload-file");
			if (
				title === "" ||
				author === "" ||
				image.files.length === 0 ||
				text === "" ||
				topic === ""
			) {
				setInvalid(true);
				setLoader(false);
				return;
			}

			if (image.files.length !== 0) {
				imageFile = image.files[0];
			}

			const formData = new FormData();
			formData.append("title", title);
			formData.append("body", text);
			formData.append("author", author);
			formData.append("_id", _id);
			formData.append("blog_image", imageFile);
			formData.append("topic", topic);
			formData.append("newid", id);
			formData.append("duration",readingDuration)


			console.log(formData);
			axios
				.post(BASE_URL + "/addBlog", formData)
				.then((response) => {
					setLoader(false);
					notifyPopup();
					history.push("/blog");
				})
				.catch((err) => {
					alert("Error on Uploading blog");
					setLoader(false);
				});
		} else {
			alert("please enter a unique id");
		}
	}
	const notifyPopup = () => {
		Store.addNotification({
			title: "Success!",
			message: "The blog has been saved",
			type: "success",
			background: "pink",
			insert: "bottom",
			container: "bottom-right",
			animationIn: ["animate__animated", "animate__fadeIn"],
			animationOut: ["animate__animated", "animate__fadeOut"],
			dismiss: {
				duration: 3000,
				onScreen: true,
			},
		});
	};
	var toolbarOptions = [
		["bold", "italic", "underline", "strike"], // toggled buttons
		["blockquote", "code-block"],
		[{ header: 1 }, { header: 2 }], // custom button values
		[{ list: "ordered" }, { list: "bullet" }],
		[{ script: "sub" }, { script: "super" }], // superscript/subscript
		[{ indent: "-1" }, { indent: "+1" }], // outdent/indent
		[{ direction: "rtl" }], // text direction
		[{ header: [1, 2, 3, 4, 5, 6, false] }],
		[
			{
				color: [
					"#000000",
					"#e60000",
					"#ff9900",
					"#ffff00",
					"#008a00",
					"#0066cc",
					"#9933ff",
					"#ffffff",
					"#facccc",
					"#ffebcc",
					"#ffffcc",
					"#cce8cc",
					"#cce0f5",
					"#ebd6ff",
					"#bbbbbb",
					"#f06666",
					"#ffc266",
					"#ffff66",
					"#66b966",
					"#66a3e0",
					"#c285ff",
					"#888888",
					"#a10000",
					"#b26b00",
					"#b2b200",
					"#006100",
					"#0047b2",
					"#6b24b2",
					"#444444",
					"#5c0000",
					"#663d00",
					"#666600",
					"#003700",
					"#002966",
					"#3d1466",
					"custom-color",
				],
			},
		], // dropdown with defaults from theme
		["link", "image"],
		["clean"], // remove formatting button
	];
	const modules = {
		toolbar: toolbarOptions,
	};

	const formats = [
		"header",
		"bold",
		"italic",
		"underline",
		"strike",
		"blockquote",
		"list",
		"bullet",
		"indent",
		"link",
		"image",
		"color",
	];
	useEffect(() => {
		// console.log("Id form the add blog", _id);
	}, []);

	return (
		<div className={styles.editorContain}>
			<div style={{ marginLeft: "5%" }}>
				<div class={styles.blogText}>
					<br />
					<h1>Add Blog! </h1>
				</div>
				<div class={styles.formgroup}>
					<input
						type='text'
						class={styles.formcontrol}
						id='title'
						placeholder='Title'
						required
					/>
				</div>
				<div class={styles.formgroup}>
					<input
						type='text'
						class={styles.formcontrol}
						id='duration'
						value={readingDuration}
						onChange={(e)=>setReadingDuration(e.target.value)}
						placeholder='Enter the Reading duration for the blog'
						required
					/>
				</div>
				<div class={styles.formgroup}>
					<input
						type='text'
						class={styles.formcontrol}
						id='author'
						placeholder='Author'
						required
					/>
				</div>
				<DropdownButton
					style={{ margin: "1.5%" }}
					variant='warning'
					alignRight
					title={topic}
					// title="Choose the Topic of Blog"
					id='dropdown-menu-align-right'
					onSelect={handleSelectDrop}>
					<Dropdown.Item eventKey='Web Development'>
						Web Development
					</Dropdown.Item>
					<Dropdown.Item eventKey='Data Science'>Data Science</Dropdown.Item>
					<Dropdown.Item eventKey='Career/Placement'>
						Career/Placement
					</Dropdown.Item>
					<Dropdown.Item eventKey='Product Companies'>
						Product Companies
					</Dropdown.Item>
				</DropdownButton>

				{/* <h4>You selected {value}</h4> */}
				{/* <DragDrop /> */}
				<div className={styles.browse}>
					<label className='pl-3 pt-3' htmlFor='upload-photo'>
						Browse...
					</label>
					<input
						className='ml-3'
						style={{ backgroundColor: "#e2e6e9", width: "15em" }}
						type='file'
						name='photo'
						id='upload-file'
					/>
				</div>
				<div className='my-3 '>
					<label htmlFor='blogid'>Enter a unique id</label>
					<input
						style={{ width: "50%" }}
						placeholder='Enter a unique ID please'
						name='blogid'
						type='text'
						value={id}
						onChange={(e) => {
							setID(e.target.value);
						}}
						className='form-control m-0'
						required
					/>
				</div>
				<div>
					{/* <ReactQuill
            className={styles.editorBox}
            theme="snow"
            value={text}
            onChange={handleChange}
            modules={modules}
            formats={formats}
          /> */}

					<SunEditor
						// setContents="My contents"
						className={styles.editorBox}
						showToolbar={true}
						value={text}
						onChange={handleChange}
						// onChange={handleEditorChange}
						setDefaultStyle='height: auto font-size: 16px;'
						
						setOptions={{
							buttonList: [
								[
									"undo",
									"redo",
									"font",
									"fontSize",
									"formatBlock",
									"paragraphStyle",
									"blockquote",
									"bold",
									"underline",
									"italic",
									"strike",
									"subscript",
									"superscript",
									"fontColor",
									"hiliteColor",
									"textStyle",
									"removeFormat",
									"outdent",
									"indent",
									"align",
									"horizontalRule",
									"list",
									"lineHeight",
									"table",
									"link",
									"image",
									"video",
									"fullScreen",
									"showBlocks",
									"codeView",
								],
							],
						}}
					/>
				</div>
				{invalid === false ? null : (
					<p style={{ color: "red" }}>Required fields are missing</p>
				)}

				{loader === false ? (
					<button class={styles.registerbtn} onClick={handleSubmit}>
						Submit
					</button>
				) : (
					<button class={styles.registerbtn}>Loading...</button>
				)}
			</div>
		</div>
	);
};

export default Editor;

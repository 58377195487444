import React, { Component, useState, useRef } from "react";
import ReactDOM, {render} from "react-dom";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { Overlay, Tooltip, Button, Row, Col } from "react-bootstrap";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import '../Assets/css/coursecalendar.css'
const localizer = momentLocalizer(moment);

const IconStyle = {
  cursor: "pointer"
};

const TooltipContent = ({onClose, event}) => {
  return (
		<React.Fragment>
			<FontAwesomeIcon
				icon={faWindowClose}
				className='pull-right'
				style={IconStyle}
				onClick={onClose}
			/>
			<div>Events</div>
			{/* <div>Some other Info</div> */}
			<strong>LCM and HCF Masterclass</strong>
			{/* <Row> */}
			<br />
			<a href='https://zoom.us/webinar/register/WN_SdCPcd95QJmn6pUTY93_lg'>
				<Button className='btn-primary'>Join Event</Button>
			</a>
			<br />
			<strong>Time, Speed and Distance</strong>
			{/* <Row> */}
			<br />
			<a href='https://zoom.us/webinar/register/WN__LVnxiuHTa6PtVnwOgatYw '>
				<Button className='btn-primary'>Join Event</Button>
			</a>
		</React.Fragment>
	);
}

function Event(event) {
  const [showTooltip, setShowTooltip] = useState(false);
  const closeTooltip = () => {
    setShowTooltip(false);
  };
  const openTooltip = () => {
    setShowTooltip(true);
  }
  const ref = useRef(null);
  const getTarget = () => {
    return ReactDOM.findDOMNode(ref.current);
  };
  return (
    <div ref={ref}>
      <span onMouseOver={openTooltip}>{event.title}</span>
      <Overlay
        rootClose
        target={getTarget}
        show={showTooltip}
        placement="top"
        onHide={closeTooltip}
      >
        <Tooltip id="test">
          <TooltipContent event={event} onClose={closeTooltip} />
        </Tooltip>
      </Overlay>
    </div>
  );
}

class CourseCalendar extends Component {
  constructor() {
    super();
    const now = new Date(2021, 9, 14);
    const ankit = new Date("2021-10-16T14:00:00");
    const nimish = new Date("2021-10-16T19:30:00");
    const events = [
			{
				id: 0,
				title: "Event",
				// allDay: true,
				start: ankit,
				end: ankit,
			},
			{
				id: 1,
				title: "Event",
				// allDay: true,
				start: nimish,
				end: nimish,
			},
		];
    this.state = {
      name: "React",
      events
    };
  }

  render() {
    return (
      <div className="" style={{borderRadius:"10px"}}>
        <div className="" style={{ width:"24rem",height:"450px",borderRadius:"10px" }}>
          <Calendar
            components={{ event: Event }}
            events={this.state.events}
            startAccessor="start"
            endAccessor="end"
            localizer={localizer}
            style={{color: "#1c1c1c",zIndex:"2"}}
          />
        </div>
      </div>
    );
  }
}


export default CourseCalendar;
import { useEffect, useState } from "react";
import PowerBatch from "./components/PowerBatch";
// import StudentTest from "./components/StudentTest";
import StudentScore from "./components/StudentScore";
import ChartDetails from "./components/ChartDetails";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  PointElement,
  CategoryScale,
  LinearScale,
  LineElement,
  Title,
} from "chart.js";
import { Doughnut, Line } from "react-chartjs-2";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { useSelector } from "react-redux";
import configAxios from "../axios.config";
import {
  GraphTrend,
  TestScoreData,
  TestTopStudents,
} from "./PowerBI_test_data";
import { queryCallbackFactory, getColor } from "./helper";
import { useQuery } from "react-query";
import { ProgressData } from "./PowerBI_home_data";
import { useParams } from "react-router-dom";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  PointElement,
  CategoryScale,
  LinearScale,
  LineElement,
  Title
);

const progressDataState = {
  assignments: {
    total: 0,
    count: 0,
    percent: 0,
  },
  classes: {
    total: 0,
    count: 0,
    percent: 0,
  },
  tests: {
    total: 0,
    count: 0,
    percent: 0,
  },
};

const PowerTest = () => {
  const { id } = useParams();
  const selector = useSelector((reducers) => reducers.batchReducer);
  const examType = useSelector((reducers) => reducers.examReducer);

  const [testLink, setTestLink] = useState("");

  const [totalAssignments, setTotalAssignments] = useState({
    total: 0,
    percent: 0,
  });
  const [students, setStudents] = useState([]);
  // trends graph
  const [trendGraph, setTrendGraph] = useState({
    labels: ["January", "February", "March", "April", "May", "June", "July"],
    datasets: [
      {
        label: "Coding Trend",
        data: [],
        backgroundColor: "#FF5858",
        borderColor: "#FF5858",
      },
    ],
  });

  // test score graph
  const [testScore, setTestScore] = useState({
    datasets: [
      {
        label: "# of Votes",
        data: [],
        backgroundColor: ["#FF5858", "#FFB636", "#24B47E"],
      },
    ],
  });

  const { data: progressData } = useQuery(
    ["assignments-percentage-covered", selector],
    () => {
      return new Promise((resolve, reject) => {
        if (ProgressData[selector]) {
          resolve(ProgressData[selector]);
          return;
        }
        queryCallbackFactory(() => {
          let token = localStorage.getItem("token");
          return configAxios(token).get(
            `/powerbi/assignmentsPercentageCovered/${id}/${selector}`,
            {
              params: {
                type: examType,
              },
            }
          );
        }, selector)
          .then((res) => {
            console.log(res.data);
            resolve(res.data);
          })
          .catch((err) => reject(err));
      });
    },
    {
      initialData: () => progressDataState,
      enabled: !!selector && !!id,
    }
  );

  useEffect(() => {
    if (!selector) return;
    const controller = new AbortController();
    if (TestTopStudents[selector]) {
      setStudents(TestTopStudents[selector]);
      return;
    }
    configAxios(localStorage.getItem("token"))
      .get("/powerbi/getTopStudentsInQuiz/" + selector, {
        signal: controller.signal,
      })
      .then(({ data }) => {
        setStudents(data);
      });
    return () => controller.abort();
  }, [selector]);

  // trend graph
  useEffect(() => {
    console.log(examType);
    if (!selector || !examType) return;

    const DataSet = [];
    let month = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const controller = new AbortController();

    if (examType === "coding") {
      if (GraphTrend[selector]?.coding) {
        setTrendGraph(GraphTrend[selector].coding);
      } else {
        configAxios(localStorage.getItem("token"))
          .get("/powerbi/getCodingQuizTrends/" + selector, {
            signal: controller.signal,
          })
          .then(({ data }) => {
            for (var i = 0; i < month.length; i++) {
              DataSet.push(data.submissionFrequency[month[i]]);
            }
            console.log(DataSet);
            setTrendGraph({
              labels: month,
              datasets: [
                {
                  label: "Coding Trend",
                  data: DataSet,
                  backgroundColor: "#FF5858",
                  borderColor: "#FF5858",
                },
              ],
            });
          })
          .catch((err) => {
            console.log(err);
            if (err.response.status === 404) {
              setTrendGraph({
                labels: month,
                datasets: [
                  {
                    label: "Coding Trend",
                    data: [],
                    backgroundColor: "#FF5858",
                    borderColor: "#FF5858",
                  },
                ],
              });
            }
          });
      }
    } else if (examType === "quiz") {
      if (GraphTrend[selector]?.quiz) {
        setTrendGraph(GraphTrend[selector].quiz);
      } else {
        configAxios(localStorage.getItem("token"))
          .get("/powerbi/getQuizSubmissionTrends/" + selector, {
            signal: controller.signal,
          })
          .then(({ data }) => {
            for (var i = 0; i < month.length; i++) {
              DataSet.push(data.submissionFrequency[month[i]]);
            }
            console.log(DataSet);
            setTrendGraph({
              labels: month,
              datasets: [
                {
                  label: "Quiz Trend",
                  data: DataSet,
                  backgroundColor: "#24B47E",
                  borderColor: "#24B47E",
                },
              ],
            });
          })
          .catch(({ response }) => {
            if (response.status === 404) {
              setTrendGraph({
                labels: month,
                datasets: [
                  {
                    label: "Quiz Trend",
                    data: [],
                    backgroundColor: "#24B47E",
                    borderColor: "#24B47E",
                  },
                ],
              });
            }
          });
      }
    } else {
      setTrendGraph(GraphTrend[selector].hybrid);
    }
    return () => controller.abort();
  }, [selector, examType]);

  // const isBackground = true;
  // test score
  useEffect(() => {
    if (!selector) return;
    const controller = new AbortController();

    console.log(TestScoreData, selector);
    if (TestScoreData[examType][selector]) {
      console.log("executing ..");
      setTestScore(TestScoreData[examType][selector]);
      return;
    }

    configAxios(localStorage.getItem("token"))
      .get("/powerbi/testScoreInRange/" + selector, {
        signal: controller.signal,
        params: {
          type: "coding",
        },
      })
      .then(({ data }) => {
        setTestLink(data.test_link);
        setTestScore({
          datasets: [
            {
              label: "# of Votes",
              data: [data.green_mark, data.red_mark, data.yellow_mark],
              backgroundColor: ["#FF5858", "#FFB636", "#24B47E"],
            },
          ],
        });
      })
      .catch((err) => {
        console.log(err);
        // if (response.status === 404) {
        // setTestScore({
        //   datasets: [
        //     {
        //       label: "# of Votes",
        //       data: [],
        //       backgroundColor: ["#FF5858", "#FFB636", "#24B47E"],
        //     },
        //   ],
        // });
        // }
      });
    return () => controller.abort();
  }, [selector, examType]);

  return (
    <>
      <div className="powerTest">
        <div className="row">
          <div className="col-xl-5 order-xl-2 mb-xl-0">
            <PowerBatch />
            <div
              className="powerBiCard mt-4 pb-0"
              style={{ backgroundColor: getColor(examType) }}
            >
              <div className="powerBiHeading">Top Students in Test</div>
              <div className="row">
                {students.map((item, index) => {
                  if (index > 14) {
                    return <></>;
                  }
                  return (
                    <StudentScore key={index} text={item[0]} count={item[1]} />
                  );
                })}
              </div>
            </div>
          </div>
          <div className="col-xl-7 order-xl-1 mb-0">
            <div className="row">
              <div className="col-md-4 mb-0">
                <div
                  className="powerBiCard"
                  style={{ backgroundColor: getColor(examType) }}
                >
                  <div className="powerBiHeading">Total Test</div>
                  <div className="progressWrapper">
                    <CircularProgressbar
                      value={(
                        (progressData.tests.count /
                          (progressData.tests.total === 0
                            ? 1
                            : progressData.tests.total)) *
                        100
                      ).toFixed(1)}
                      text={(
                        (progressData.tests.count /
                          (progressData.tests.total === 0
                            ? 1
                            : progressData.tests.total)) *
                        100
                      ).toFixed(1)}
                      strokeWidth="10"
                      styles={buildStyles({
                        textSize: "20",
                        pathColor: "#FFB536",
                        textColor: "#000",
                        trailColor: "#fff",
                      })}
                      className="powerbiCircularBar"
                    />
                    {/* <ProgressOverview
                      color="#007AFF"
                      progress={(
                        (progressData.tests.count /
                          (progressData.tests.total === 0
                            ? 1
                            : progressData.tests.total)) *
                        100
                      ).toFixed(1)}
                      title="Tests"
                      text={progressData.tests.total}
                    /> */}
                  </div>
                </div>{" "}
                <div
                  className="powerBiCard mt-4 mb-md-0 mb-4"
                  style={{ backgroundColor: getColor(examType) }}
                >
                  <div className="powerBiHeading">Average Score</div>
                  <div className="progressWrapper">
                    <CircularProgressbar
                      value={totalAssignments.percent}
                      text={`${totalAssignments.percent}%`}
                      strokeWidth="10"
                      styles={buildStyles({
                        textSize: "20",
                        pathColor: "#217EFD",
                        textColor: "#000",
                        trailColor: "#fff",
                      })}
                      className="powerbiCircularBar"
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-8 mb-0">
                <div
                  className="powerBiCard"
                  style={{ backgroundColor: getColor(examType) }}
                >
                  <div className="powerBiHeading">Test Attempts Trend</div>
                  <Line
                    data={trendGraph}
                    options={{
                      responsive: true,
                    }}
                  />
                </div>
              </div>
            </div>

            <div
              className="powerBiCard mt-4"
              style={{ backgroundColor: getColor(examType) }}
            >
              <div className="powerBiHeading">Test Scores</div>
              <div className="row">
                <div className="col-md-5 mb-0">
                  <div className="testScore mb-5">
                    <Doughnut
                      data={testScore}
                      options={{
                        maintainAspectRatio: false,
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-7 mb-0">
                  <ChartDetails name="Test Score" link={testLink} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// const labels = ["January", "February", "March", "April", "May", "June", "July"];
// const Ldata = {
//   labels,
//   datasets: [
//     {
//       label: "Coding Trend",
//       data: [100, 200, 30, 300, 150, 10, 75],
//       backgroundColor: "#FF5858",
//       borderColor: "#FF5858",
//     },
//   ],
// };

export default PowerTest;

import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import "../Assets/css/references.css";
import TempPopup from "./TempPopup";
// import { InlineWidget } from "react-calendly";

const videoLink = [
"https://player.vimeo.com/video/612847639?h=58ee7cb80a&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
"https://vimeo.com/617006385",
];

export default function TADoubtSession() {
  const [video, setVideo] = useState(videoLink[0]);
  const [showModal, setShowModal] = useState(false);
  const videoArr = [
    {
      title: "PROGRAMMING",
      title2: "Revision Session",
      num: "SESSION 1",
      link: "https://player.vimeo.com/video/612847639?h=58ee7cb80a&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    },
    {
      title2: "Revision Session",
      title: "Master Aptitude Problem Solving",
      link: "https://vimeo.com/625097477",
    },
    {
      title2: "Revision Session",
      title: "PROGRAMMING",
      num: "SESSION 2",
      link: "https://vimeo.com/617006385",
    },
    {
      title2: "Revision Session",
      title: "LCM and HCF by Ankit Routray",
      link: "https://vimeo.com/638173335",
    },
    {
      title2: "Revision Session",
      title: "Number System by Ankit Routray",
      link: "https://vimeo.com/638173669",
    },

    {
      title2: "Revision Session",
      title: "Conditional statements and Loops",
      link: "https://vimeo.com/638174103",
    },
    {
      title2: "Revision Session",
      title:
        "Time speed and distance, Profit Loss, Blood Relations by Nimish Nama",
      link: "https://vimeo.com/638174595",
    },
    {
      title2: "Revision Session",
      title: "Stacks Implementation Using Arrays and Linked List",
      link: "https://vimeo.com/638673651",
    },
  ];
  return (
    <>
      {showModal ? (
        <TempPopup
          video={video}
          handleClose={() => {
            setShowModal(false);
          }}
        />
      ) : null}
      <div
        className="row  py-5 d-flex justify-content-start example"
        style={{ overflowX: "scroll" }}
      >
        {videoArr.map((videovalue, index) => {
          return (
            <>
              <div
                class="courses-container col-lg-4 col-md-4 col-sm-4 col-xs-4 py-2"
                style={{ transform: "scale(0.98)" }}
              >
                <div
                  class="course-dashboard my-3 py-4 shadow"
                  style={{
                    borderLeft: "5px solid #eea47fff",
                    minHeight: "150px",
                  }}
                >
                  <div
                    class="course-dashboard-preview"
                    style={{ padding: "0px" }}
                  ></div>
                  <div class="course-info-dashboard">
                    <div class="progress-container-dashboard">
                      <span class="progress-text-dashboard">
                        {/* {videovalue.title} */}
                      </span>
                    </div>
                    <h2 class="video-dashboard-title my-0">
                      {videovalue.title2}
                      <br />
                      {videovalue.title}
                    </h2>
                    <button
                      class="btn-course-dashboard btn btn-primary btn-sm"
                      onClick={() => {
                        setVideo(videovalue.link);
                        setShowModal(true);
                      }}
                      style={{ marginBottom: "-20px" }}
                    >
                      <i className="fas fa-play"></i> Play
                    </button>
                  </div>
                </div>
              </div>{" "}
            </>
          );
          {
            /*  }*/
          }
        })}
      </div>

      {/* <div
				className={`col-lg-4 my-4 col-md-4 col-sm-12  px-4 ${style.course_card}`}>
				<div className={style.card_background_blue + " p-2 py-3"}>
					<div className={`${style.middle_section}`}>
						<h5 className={`${style.course_name}`}>
							Revision Session <br />
							<br />
							PROGRAMMING
							<br />
							<br />
							SESSION 1
						</h5>

						<img
							className={`${style.play_btn}`}
							src={play_button}
							alt={"SESSION 1"}
							onClick={() => {
								setVideo(videoLink[0]);
								setShowModal(true);
							}}
						/>
					</div>
				</div>
			</div> */}
      {/* <div
				className={`col-lg-4 my-4 col-md-4 col-sm-12  px-4 ${style.course_card}`}>
				<div className={style.card_background_blue + " p-2 py-3"}>
					<div className={`${style.middle_section}`}>
						<h5 className={`${style.course_name}`}>
							Revision Session <br />
							<br />
							Master Aptitude <br />
							Problem Solving
							<br />
						</h5>

						<img
							className={`${style.play_btn}`}
							src={play_button}
							alt={"master aptitude problem solving"}
							onClick={() => {
								setVideo("https://vimeo.com/625097477 ");
								setShowModal(true);
							}}
						/>
					</div>
				</div>
			</div> */}
      {/* <div
				className={`col-lg-4 my-4 col-md-4 col-sm-12  px-4 ${style.course_card}`}>
				<div className={style.card_background_blue + " p-2 py-3"}>
					<div className={`${style.middle_section}`}>
						<h5 className={`${style.course_name}`}>
							Revision Session <br />
							<br />
							PROGRAMMING
							<br />
							<br />
							SESSION 2
						</h5>

						<img
							className={`${style.play_btn}`}
							src={play_button}
							alt={"SESSION 2"}
							onClick={() => {
								setVideo(videoLink[1]);
								setShowModal(true);
							}}
						/>
					</div>
				</div>
			</div> */}
      {/* <div
				className={`col-lg-4 my-4 col-md-4 col-sm-12  px-4 ${style.course_card}`}>
				<div className={style.card_background_blue + " p-2 py-3"}>
					<div className={`${style.middle_section}`}>
						<h5 className={`${style.course_name}`}>
							Revision Session <br />
							<br />
							LCM and HCF
							<br />
							<br />
							by Ankit Routray
						</h5>
						<img
							className={`${style.play_btn}`}
							src={play_button}
							alt={"SESSION 2"}
							onClick={() => {
								setVideo("https://vimeo.com/638173335");
								setShowModal(true);
							}}
						/>
					</div>
				</div>
			</div> */}
      {/* <div
				className={`col-lg-4 my-4 col-md-4 col-sm-12  px-4 ${style.course_card}`}>
				<div className={style.card_background_blue + " p-2 py-3"}>
					<div className={`${style.middle_section}`}>
						<h5 className={`${style.course_name}`}>
							Revision Session <br />
							<br />
							Number System
							<br />
							<br />
							by Ankit Routray
						</h5>
						<img
							className={`${style.play_btn}`}
							src={play_button}
							alt={"SESSION 2"}
							onClick={() => {
								setVideo("https://vimeo.com/638173669");
								setShowModal(true);
							}}
						/>
					</div>
				</div>
			</div> */}
      {/* <div
				className={`col-lg-4 my-4 col-md-4 col-sm-12  px-4 ${style.course_card}`}>
				<div className={style.card_background_blue + " p-2 py-3"}>
					<div className={`${style.middle_section}`}>
						<h5 className={`${style.course_name}`}>
							Revision Session <br />
							<br />
							Conditional statements and Loops
							<br />
							<br />
						</h5>
						<img
							className={`${style.play_btn}`}
							src={play_button}
							alt={"SESSION 2"}
							onClick={() => {
								setVideo("https://vimeo.com/638174103");
								setShowModal(true);
							}}
						/>
					</div>
				</div>
			</div> */}
      {/* <div
				className={`col-lg-4 my-4 col-md-4 col-sm-12  px-4 ${style.course_card}`}>
				<div className={style.card_background_blue + " p-2 py-3"}>
					<div className={`${style.middle_section}`}>
						<h5 className={`${style.course_name}`}>
							Revision Session <br />
							<br />
							Time speed and distance, Profit Loss, Blood Relations
							<br />
							<br />
							by Nimish Nama
						</h5>
						<img
							className={`${style.play_btn}`}
							src={play_button}
							alt={"SESSION 2"}
							onClick={() => {
								setVideo("https://vimeo.com/638174595");
								setShowModal(true);
							}}
						/>
					</div>
				</div>
			</div> */}
      {/* <div
				className={`col-lg-4 my-4 col-md-4 col-sm-12  px-4 ${style.course_card}`}>
				<div className={style.card_background_blue + " p-2 py-3"}>
					<div className={`${style.middle_section}`}>
						<h5 className={`${style.course_name}`}>
							Revision Session <br />
							<br />
							Stacks Implementation Using Arrays and Linked List
							<br />
							<br />
						</h5>
						<img
							className={`${style.play_btn}`}
							src={play_button}
							alt={"SESSION 2"}
							onClick={() => {
								setVideo("https://vimeo.com/638673651");
								setShowModal(true);
							}}
						/>
					</div>
				</div>
			</div> */}
    </>
  );
}

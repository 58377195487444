import React from "react";
import ContentLoader from "react-content-loader";
import useMedia from "../componentsofcourse/useMedia";
import "../Pages/NewBlogs/NewblogfrontPage.css";

const BlogSkeleton = () => {
  const matches = useMedia("(min-width : 968px )");
  const beforemobile = useMedia("(min-width : 568px )");

  return (
    <div>
      {matches ? (
        <>
          <ContentLoader
            speed={2}
            width={1360}
            height={900}
            viewBox="0 0 1360 900"
            backgroundColor="#f2f2f4"
            foregroundColor="#c0c0c0"
            style={{ justifyContent: "space-evenly" }}
          >
            <rect x="10vw" y="50" rx="8" ry="8" width="85vw" height="50vh" className="my-2" />
            <rect x="15vw" y="480" rx="0" ry="0" width="20vw" height="350" className="my-2" />
            <rect x="37vw" y="480" rx="0" ry="0" width="20vw" height="350" className="my-2" />
            <rect x="60vw" y="480" rx="0" ry="0" width="20vw" height="350" className="my-2" />

          </ContentLoader>
          <span className="sr-only">Loading...</span>
        </>
      ) : (
        <>
          <div className=" container-fluid  " style={{ marginTop: "10vw" }}>
            <div className="row">
              <div className="col-3" style={{marginRight:"100px"}}>
                <span
                  style={{
                    height: "120px",
                    // width: "400px",
                    background: "#f2f2f4",
                    color: "#f2f2f4",
                  }}
                >
                  WebDevelopmenttttttttt
                </span>
              </div>
              <div className="col-3">
                <span
                  style={{
                    height: "120px",
                    // width: "400px",
                    background: "#f2f2f4",
                    color: "#f2f2f4",
                  }}
                >
                  WebDevelopmenttttttttt
                </span>
              </div>
            </div>
            <div className="row">
              <div className="col-3" style={{marginRight:"100px"}}>
                <span
                  style={{
                    height: "120px",
                    width: "400px",
                    background: "#f2f2f4",
                    color: "#f2f2f4",
                  }}
                >
                  WebDevelopmentttttttt
                </span>
              </div>
              <div className="col-3">
                <span
                  style={{
                    height: "120px",
                    width: "400px",
                    background: "#f2f2f4",
                    color: "#f2f2f4",
                  }}
                >
                  WebDevelopmentttttttt
                </span>
              </div>
            </div>

            {!beforemobile ? (
              <div className="d-flex row flex-row justify-content-around align-items-center ">
                <div
                  style={{
                    height: "400px",
                    width: "200px",
                    background: "#f2f2f4",
                    color: "#f2f2f4",
                    margin:"5rem"
                  }}
                ></div>
              </div>
            ) : (
              <div className="d-flex row flex-row justify-content-around align-items-center ">
                <div
                  style={{
                    height: "400px",
                    width: "300px",
                    background: "#f2f2f4",
                    color: "#f2f2f4",
                    margin:"5rem"
                  }}
                ></div> 
                <div
                  style={{
                    height: "400px",
                    width: "300px",
                    background: "#f2f2f4",
                    color: "#f2f2f4",
                    margin:"5rem",
                  }}
                ></div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default BlogSkeleton;

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import "../Interview/InterviewStyle/AiGenrate.scss"
function AiGenrate(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className='Ai'>
        <p>
          Ai Generated response
        </p>
      </Modal.Body>
    </Modal>
  );
}

export default AiGenrate;
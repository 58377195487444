import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from './Context/AuthContext'

const CheckToken = () => {
  const { update_token } = useContext(AuthContext)

  function checkExistingToken(){
    let existingKeys = Object.keys(localStorage)
    if(existingKeys.find(element => element === 'token')){
      update_token(localStorage.token)
    }
  }

  useEffect(() => {
    checkExistingToken()
  }, [])



  return (
    <>

    </>
  )
}

export default CheckToken
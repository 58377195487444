import React, { useRef, useState, useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import User from "../Assets/img/Coupons/user.jpg";
import Group776 from "../Assets/img/Coupons/Group 7767.png";
import IconMaterial from "../Assets/img/Coupons/Icon material-today.png";
import IconMentro from "../Assets/img/Coupons/Icon metro-security.png";
import Rectangle from "../Assets/img/Coupons/Rectangle 1045.png";
import styles from "../Assets/css/Coupon.module.css";
import displayRazorpay from "../Razorpay";

function Coupons(props) {
	//
	const setData = props.setData;
	const setCoupon = props.setCoupon;
	const tierId = props.tierId;
	const setCouponCode = props.setCouponCode;
	// const amount = props.amount;
	const token = props.token;
	const setLoadT1 = props.setLoadT1;
	const setLoadT2 = props.setLoadT1;
	const setLoadT3 = props.setLoadT1;
	const handlePaymentPopup = props.handlePaymentPopup;
	const startAmount = props.amount;
	const setHandlePayment = props.setHandlePayment;
	let course = props.course;
	let tier = props.tier;
	// let discountCount = 0;
	const [couponApplied, setCouponApplied] = useState(false);
	let [amount, setAmount] = useState(startAmount);
	let [discountCount, setDiscountCount] = useState(0);

	//
	let [apply, setApply] = useState(false);
	let [applied, setApplied] = useState("Apply");
	let [msgApply, setMsgApply] = useState("Apply");
	// let [invalid, setInvalid] = useState(false);
	const [loader, setLoader] = useState(false);
	const code = useRef(null);
	const [handleOrder, setHandleOrder] = useState(false);
	var check;

	//   fetch("http://cantileverlabs-env.eba-bs34kpyp.ap-south-1.elasticbeanstalk.com/getFromRoute?route=+placementcourse")
	//   .then((response) => response.json())
	// .then((data)=>{

	// })

	function couponValidate(event) {
		event.preventDefault();

		let coupon_code = code.current.value;

		if (coupon_code !== "") {
			fetch("https://api.cantileverlabs.com/getAllCoupons")
				.then((response) => response.json())
				.then((data) => {
					const coupons = data.coupons;
					const initialAmount = startAmount;
					for (let coupon of coupons) {
						if (coupon_code === coupon.couponCode && coupon.numAllowed > 0) {
							let discount = coupon.percentage;
							// amount = ;
							// amount = Math.ceil(amount);
							// discountCount = discountCount + 1;
							setDiscountCount(discountCount + 1);
							setAmount(
								Math.ceil(initialAmount - (initialAmount * discount) / 100)
							);

							setMsgApply("Applied");

							setCouponApplied(true);
						} else if (coupon_code != coupon.couponCode) {
							setAmount(startAmount);

							setMsgApply("Not Valid");
						}
					}
				});
		} else {
			setAmount(startAmount);
			setApplied("Apply");
		}
	}

	function handlePay(event) {
		event.preventDefault();
		setLoader(true);

		let coupon_code;
		if (apply === false) {
			coupon_code = "";
		} else {
			if (code.current.value != "") {
				coupon_code = code.current.value;
			} else {
				coupon_code = "";
			}
		}

		//

		// check =

		// displayRazorpay(
		//   tierId,
		//   coupon_code,
		//   token,
		//   setLoadT1,
		//   setLoadT2,
		//   setLoadT3,
		//   setCoupon,
		//   handlePaymentPopup,
		//   setData,
		//   setHandlePayment
		// );

		displayRazorpay(
			tierId,
			coupon_code,
			token,
			setLoadT1,
			setLoadT2,
			setLoadT3,
			setCoupon,
			setHandlePayment,
			setHandleOrder
			// handlePaymentPopup
		).then((data) => {
			setData(data);
		});

		setCouponCode(coupon_code);
		// async function fetchData() {
		//   let data = JSON.stringify(check);
		//   data = JSON.parse(data);
		//   return data;
		// }

		// setData(check);
		//
		//

		// .then((response) => response.json())
		// .then((data) => {
		//
		// });

		//
		props.onClose();
	}

	const closeOnEscapeKeyDown = (e) => {
		if ((e.charCode || e.keyCode) === 27) {
			props.onClose();
		}
	};

	useEffect(() => {
		document.body.addEventListener("keydown", closeOnEscapeKeyDown);
		return function cleanup() {
			document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
		};
	}, []);

	return (
		<CSSTransition
			in={props.show}
			unmountOnExit
			timeout={{ enter: 0, exit: 300 }}
		>
			<div className={styles.modal} onClick={props.onClose}>
				<div className="modal-content" onClick={(e) => e.stopPropagation()}>
					<div className="container justify-content-center my-5">
						<div style={{}} className="container">
							<form
								onSubmit={(e) => {
									handlePay(e);
								}}
							>
								<div className={styles.paytophead} style={{ marginTop: "-8%" }}>
									<div class={styles.orderheading}>
										<h4>Order Summary</h4>
									</div>
									<div style={{ textAlign: "right" }}>
										{/* <img
                      src={User}
                      style={{
                        width: "10%",
                        position: "relative",
                        right: "0",
                      }}
                      alt=""
                    />
                    Regina */}
									</div>
								</div>
								<hr />
								<div className={styles.paycpcon}>
									<img
										style={{ width: "40%", height: "40%" }}
										src={Rectangle}
										alt=""
										className="img-fluid"
									/>

									<div
										style={{ position: "relative", top: "-8%" }}
										className="card-body"
									>
										<h5 className={`${styles.paycardtext} card-title`}>
											{/* Competitive Programming */}
											{course}
										</h5>
										<hr className="order-hr1" />
										<h5 className={styles.paycardtext}>
											<b>
												{tier}
												{/* Impact */}
											</b>
										</h5>
									</div>
								</div>
								<br />
								<hr />

								{!apply && (
									<h6
										onClick={() => {
											setApply(true);
										}}
										style={{
											// backgroundColor: "white",
											// border: "none",
											color: "red",
										}}
									>
										Apply Coupon Code
									</h6>
								)}
								{apply && (
									<div class={`${styles.formgroup} d-flex`}>
										<input
											type="text"
											class={styles.formcontrol}
											id="coupon-code"
											placeholder="Apply Coupon Code"
											name="coupon-code"
											ref={code}
											// value="Not Applied"
											style={{ height: "8px" }}
										/>
										<h6 onClick={() => {}} class={styles.cancellink}>
											<h6
												onClick={(e) => {
													couponValidate(e);
													// setMsgApply(true);
												}}
												style={{ marginTop: "0" }}
												class="cancel-link"
											>
												{msgApply}
											</h6>
											{/*  */}
											{/* {msgApply ? (
                        amount != startAmount ? (
                          <h6>✅ Applied</h6>
                        ) : (
                          <h6> Invalid</h6>
                        )
                      ) : null} */}
										</h6>
									</div>
								)}

								{/* {code.current == null && <h6>Applied</h6>} */}

								{apply && (
									<div class={styles.appliedlink}>
										<h6
											onClick={() => {
												setApply(false);
												setMsgApply("Apply");
												setAmount(startAmount);
											}}
										>
											Close
										</h6>
									</div>
								)}
								<div class={`d-flex ${styles.coursepricediv}`}>
									<div>
										<h3 class={styles.coursetotalhead}>Total</h3>
										<p class={styles.coursetotalheadgst}>Including GST</p>
									</div>
									<div class={styles.totalpriceamount}>
										<h4>
											{" "}
											{couponApplied === false ? (
												<b>{props.amount}/-</b>
											) : (
												<b>{amount}/-</b>
											)}
										</h4>
									</div>
								</div>

								<button
									type="submit"
									class={styles.proceedbtn}
									// onClick={() => {
									//

									// }}
								>
									PROCEED TO PAYMENT
								</button>

								<div
									class={`d-flex ${styles.ordericonsdiv}`}
									style={{ position: "relative", left: "4%", top: "7px" }}
								>
									{/* <div class="d-flex ">
                    <div>
                      <img
                        src={IconMaterial}
                        alt=""
                        class="img-fluid calender-icon"
                        style={{ width: "55%" }}
                      />
                    </div>
                    <div class={styles.iconstext}>
                      7 day Money back Guarantee
                    </div>
                  </div> */}
									<div class="d-flex">
										<div>
											<img
												src={IconMaterial}
												alt=""
												class="img-fluid calender-icon"
												style={{ width: "55%" }}
											/>
										</div>
										<div class={styles.icontext}>
											7 day Money back Guarantee
										</div>
										<div>
											<img
												src={IconMentro}
												alt=""
												class="img-fluid calender-icon"
												style={{ width: "55%" }}
											/>
										</div>
										<div class={styles.icontext}>
											100% Safe & Secure Payment
										</div>
									</div>
									<div class="d-flex">
										<div>
											<img
												src={Group776}
												alt=""
												class="img-fluid calender-icon"
												style={{ width: "55%" }}
											/>
										</div>
										<div class={styles.icontext}> Powered by RazorPay</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
			{/* </div> */}
		</CSSTransition>
	);
}

export default Coupons;

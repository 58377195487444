import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import HelmetComp from "../components/HelmetComp";
import Footer from "../components/Footer";
import style from "../Assets/css/mentorconnecthomepage.module.css";
import MentorConnectHomepageTxt from "../components/MentorConnectHomepageTxt";
import { LastSection } from "./NewMasterClass";
import useMedia from "../componentsofcourse/useMedia";
import Profile from "../Assets/img/expert-advice/profile-img.png";
import Star from "../Assets/img/expert-advice/star.png";
import Google from "../Assets/img/expert-advice/google.png";
import Instagram from "../Assets/img/expert-advice/instagram.png";
import LinkedIn from "../Assets/img/expert-advice/linkedin.png";
import CompanyLogo from "../Assets/img/expert-advice/company-logo.png";
import { NavLink } from "react-router-dom";
import { BASE_URL } from "../env";
import Arrow from "../Assets/img/MentorConnectHomepage/arrow.png";
import varshit from "../Assets/img/mentorconnect/varshit.png";
import ashish from "../Assets/img/mentorconnect/ashish.png";
import dhrumil from "../Assets/img/mentorconnect/dhrumil.png";
import rishabh from "../Assets/img/mentorconnect/rishabh.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../Assets/scss/MentorConnect.scss";

const MentorConnectHomepage = () => {
  const [mentors, setMentors] = useState([]);
  const [more, setmore] = useState(3);
  const slider = React.useRef(null);
  const matches = useMedia("(min-width : 768px )");

  useEffect(async () => {
    await fetch(BASE_URL + "/getMentors")
      .then((res) => res.json())
      .then((data) => {
        setMentors(data.mentors);
      });
  }, []);

  let settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      {/* <Navbar /> */}
      <HelmetComp
        info={{
          title: "Mentor Connect",
          siteName: "cantilever labs",
          pageUrl: "https://www.cantileverlabs.com/mentor-connect",
          imagUrl:
            "https://d8it4huxumps7.cloudfront.net/uploads/images/150x150/60c9c4b8da242_logo.jpg?d=110x110",
        }}
      />

      {/* Text Section */}
      <MentorConnectHomepageTxt />

      {/* VideoSection */}
      <div id="mentorActionSection">
        <div className="container py-5">
          <div className="row">
            <div className="col-12">
              <div className="heading">Our Mentor in Action</div>
            </div>
            <div className="col-12">
              <MentorActionCard />
            </div>
          </div>
        </div>
      </div>

      {/* MentorSection */}
      <div id="expertSection">
        <div className="container-fluid my-5">
          <div className="row">
            <div className="col-12">
              <div className="heading">Meet our Experts</div>
            </div>
          </div>
          {matches ? (
            <div className="row gx-0 justify-content-center">
              <div className="col-1 arrow">
                <i
                  class="fas fa-chevron-left"
                  onClick={() => slider?.current?.slickPrev()}
                ></i>
              </div>
              <div className="col-9">
                <Slider ref={slider} {...settings}>
                  {mentors.map((mentor) => {
                    return <ExpertCard data={mentor} />;
                  })}
                </Slider>
              </div>
              <div className="col-1 arrow">
                <i
                  class="fas fa-chevron-right"
                  onClick={() => slider?.current?.slickNext()}
                ></i>
              </div>
            </div>
          ) : (
            <div>
              {mentors
                .map((mentor) => {
                  return <ExpertCard data={mentor} view="mobile" />;
                })
                .slice(0, more)}
              <div style={{ display: "flex", justifyContent: "end" }}>
                <button
                  onClick={() => {
                    more < 4 ? setmore(mentors.length) : setmore(3);
                  }}
                  style={{
                    background: "#00599D",
                    color: "white",
                    border: "none",
                    padding: "10px 5px",
                    right: "0",
                  }}
                >
                  {more < 4 ? "Explore More" : "Read Less"}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* VideoSection */}
      <div id="mentorTestimonials">
        <div className="container py-5">
          <div className="row">
            <div className="col-12">
              <div className="heading">What Our Students Say</div>
            </div>
            <div className="col-12">
              <Testimonials />
            </div>
          </div>
        </div>
      </div>

      {/* Explore Section */}
      <section className={style.lastSection}>
        <div style={{ backgroundColor: "#EDF1F5" }}>
          <div className="row">
            <h4
              className={
                " mb-5 " + style.lastSectionHeading + " " + style.heading
              }
            >
              Explore more of our offerings
            </h4>
          </div>
          <LastSection />
        </div>
      </section>

      <Footer />
    </>
  );
};

const Testimonials = () => {
  const slider = React.useRef(null);
  let settings = {
    dots: false,
    centerMode: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <Slider {...settings} ref={slider}>
        {testimonialsArray.map((val) => {
          return (
            <>
              <div className="testimonialCard">
                <div className="head">
                  <i className="fas fa-quote-left"></i>
                </div>
                <div className="mid">
                  <div className="row">
                    <div className="col-3 mb-0">
                      <img
                        src={val.img}
                        className="img-fluid img"
                        alt="avatar"
                      />
                    </div>
                    <div className="col-9 mb-0">
                      <div className="name">{val.name}</div>
                      <div className="detail">{val.clg}</div>
                    </div>
                  </div>
                </div>
                <div className="foot">{val.text}</div>
              </div>
            </>
          );
        })}
      </Slider>
      <div className="row mt-4">
        <div className="col-12 mb-0 d-flex justify-content-center">
          <div className="arrow" onClick={() => slider?.current?.slickPrev()}>
            <i className="fas fa-long-arrow-alt-left"></i>
          </div>
          <div className="arrow" onClick={() => slider?.current?.slickNext()}>
            <i className="fas fa-long-arrow-alt-right"></i>
          </div>
        </div>
      </div>
    </>
  );
};

const MentorActionCard = () => {
  let settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <Slider {...settings}>
        {mentorActionData.map((val) => {
          return (
            <>
              <div className="MentorActionCard">
                <div className="header">
                  {/* <i className="far fa-play-circle"></i> */}
                  <iframe
                    className="video"
                    src={val.video}
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
                <div className="heading">{val.title}</div>
                <div className="desc">{val.body}</div>
              </div>
            </>
          );
        })}
      </Slider>
    </>
  );
};

const ExpertCard = (props) => {
  const [expertId, setexpertId] = useState("612766b88f1a9428081ef143");

  function showExpDetail(id) {
    expertId ? setexpertId("") : setexpertId(id);
  }
  return (
    <>
      <div className="expert-boxwrapper mx-md-3 my-md-0 my-3">
        {props.view === "mobile" ? (
          <div>
            <div className="row">
              <div className="col-2">
                <img
                  src={Profile}
                  className={style.expertProfileImg}
                  alt="mentor profile"
                />
              </div>
              <div className="col-5 my-auto mx-1">
                <div
                  className="name"
                  style={{ fontFamily: "Poppins-SemiBold" }}
                >
                  {props.data.fullName}
                </div>
                <div
                  className="post"
                  style={{ fontFamily: "Poppins-Regular", fontSize: "10px" }}
                >
                  {props.data.currentDesignation}
                </div>
              </div>
              <div className="col-3 my-auto">
                <div
                  className="company"
                  style={{ FontFamily: "Poppins-SemiBold" }}
                >
                  <span style={{ marginLeft: "5px" }}></span> 4.5
                  <img
                    src={Star}
                    clasName="img-fluid"
                    alt="group icon"
                    style={{ height: "15px" }}
                  />
                  <img
                    src={CompanyLogo}
                    className="img-fluid"
                    alt="company logo"
                  />
                </div>
              </div>
              <div className="col-1 my-auto">
                <img
                  src={Arrow}
                  onClick={() => showExpDetail(props.data._id)}
                />
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="banner" style={{ background: "white" }}>
              <img src={Profile} className="img-fluid" alt="experts profile" />
            </div>
            <div className="name mt-4">{props.data.fullName}</div>
            <div className="post">{props.data.currentDesignation}</div>
            <div className="company">
              <img
                src={Star}
                clasName="img-fluid"
                alt="group icon"
                style={{ height: "20px" }}
              />
              4.5
              <img src={CompanyLogo} className="img-fluid" alt="company logo" />
            </div>
          </>
        )}
        {expertId === props.data._id || props.view != "mobile" ? (
          <>
            <div className="expertise">Expertise</div>
            <ul>
              <li>resume building</li>
              <li>career planning</li>
              <li>entrance test</li>
              <li>domain knowledge</li>
              <li>cv building</li>
            </ul>
            <div className="helping">Helping you get placed in :</div>
            <div className="companies py-0" style={{ paddingBottom: "0px" }}>
              <div className="d-flex">
                <img
                  src={Google}
                  className="img-fluid mx-2 "
                  alt="company icon"
                  style={{ height: "25px", marginTop: "1px" }}
                />

                <img
                  src={Instagram}
                  className="img-fluid mx-2 "
                  alt="company icon"
                  style={{ height: "25px", marginTop: "2px" }}
                />
                <img
                  src={LinkedIn}
                  className="img-fluid mx-2"
                  alt="company icon"
                  style={{ height: "25px" }}
                />
              </div>
            </div>
            <hr />
            <div className="foot py-3 pb-4">
              <div className="left">
                <span
                  style={{
                    color: "#00599D",
                    fontFamily: "sans-serif",
                    fontSize: "15px",
                    padding: "10px 5px",
                    border: "0.624441px solid #7F7F7F",
                  }}
                >
                  ₹ 17 / minute
                </span>
              </div>
              <div className="right">
                <NavLink
                  to={`/expert-profile/${props.data._id}`}
                  className="connect"
                  onClick={() => {
                    window.scroll(0, 0);
                  }}
                >
                  Connect
                </NavLink>
              </div>
            </div>
          </>
        ) : null}
      </div>
    </>
  );
};
const mentorActionData = [
  {
    title: "Sneak Peek into the 1:1 Sessions",
    body: "Mentor Connect provides 1:1 Mentorship in resume preparation, career planning, etc. to fresh graduates and job aspirants. The Mentor Connect has so far helped students of VIT, BITS Pilani, IIT Bombay, SNIST in their placement preparation. Watch this video to catch a glimpse of their experiences.",
    video: "https://www.youtube.com/embed/IFlKDBpvN6Q",
  },
  {
    title: "Strategy Building for Consultants",
    body: "Watch the Mock Interview Session of  an aspirant job seeker Aman with our Industrial Expert Harshank. Aman is a fresher who has completed his  Internship from IDFC then Future First. Watch this full video to learn more from his experience.",
    video: "https://www.youtube.com/embed/FIRNVizYhlo",
  },
  {
    title: "Guidance on the Art of Pitching",
    body: "Watch the Mentor Session of Abhideep Tripathi of BITS Pilani from Mechanical branch with Mentor Ayushi. Watch the full  interview and see how he answers tricky questions like “Sell me a cup of Coffee” and learn from this interview on how to answer such tricky HR questions. ",
    video: "https://www.youtube.com/embed/6USfsrrCf48",
  },
];

const testimonialsArray = [
  {
    text: "This is a really helpful program offering great insights and I would love to do it again to assess my abilities.",
    name: "Rishabh Ranjan",
    clg: "IIT Bombay",
    img: rishabh,
    lines: true,
  },
  {
    text: "The mentor was really helpful and accommodating. He pointed out the possible challenges which I may face.",
    name: "Ashish Goyal",
    clg: "IIT Bombay",
    img: ashish,
    lines: false,
  },
  {
    text: "The session was a great learning experience for me it helped to identify my weakness and work on them.",
    name: "Dhrumil Raval",
    clg: " IIT Bombay",
    img: dhrumil,
    lines: true,
  },
  {
    text: "It was great to have a mock interview with someone who has great technical knowledge of the field.",
    name: "Varshit Areti",
    clg: "IIT Bombay",
    img: varshit,
    lines: true,
  },
];

export default MentorConnectHomepage;

import React, { useEffect, useState } from "react";
import { BASE_URL } from "../env";
import moment from "moment";
import LockIcon from "@mui/icons-material/Lock";
import ReactGA from "react-ga";
import { useParams, useHistory } from "react-router-dom";

const VerifyExam = ({hidenavbar}) => {
  const history = useHistory();
  const { stringRoute } = useParams();

  const [isLoading, setIsLoading] = useState(true);

  const [password, setPassword] = useState(null);

  const submitPassword = (e) => {
    e.preventDefault();
    fetch(BASE_URL + "/verify-exams/checkPassword", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("token"),
      },
      body: JSON.stringify({
        password: password,
        stringRoute: stringRoute
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.success) {
          console.log(data.exam.examId._id);
          history.push(`/quizinstructions?duration=${data.exam.examId.timer}&questions=${data.exam.examId.questions.length}&id=${data.exam.examId._id}`);
        }
        else{
          alert("Wrong Password");
          window.location.reload();
        }
      }
      )
      .catch((err) => {
        if (err.status === 401) {
          localStorage.clear();
          history.push("/loginWithRedirect/?redirect_uri=" + window.location.pathname);
        }
      }
      );
  };

  useEffect(() => {
    // check the status code of the response
    fetch(BASE_URL + "/verify-exams/getExamWithPassword/"+stringRoute, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => {
        if (res.status === 401) {
          localStorage.clear();
          history.push("/loginRedirect/?redirect_uri=" + window.location.pathname);
        }
        return res.json();
      })
      .then((data) => {
        if (data.success) {
          setIsLoading(false);
          if(data.exam.type==="coding"){
            history.push(`/codinginstructions?duration=${data.exam.timer}&questions=${data.exam.questions.length}&id=${data.exam._id}&testname=${data.exam.name}&route=${stringRoute}`);
          }
          else{
            history.push(`/quizinstructions?duration=${data.exam.timer}&questions=${data.exam.questions.length}&id=${data.exam._id}&route=${stringRoute}`);
          }
        }
      }
      )
      .catch((err) => {
        console.log(err.status);
        if (err.status === 401) {
          localStorage.clear();
          history.push("/login");
        }
      }
      );
  }, []);

// if(isLoading){
  return <div className="loader">Loading.....</div>
// }
// else{
//   // let password = null;
//   // while (password === null) {
//   //   password = prompt('Please enter password to continue');
//   // }
  
//   return (
//     <div style={{
//       height: "100vh",
//       display: "flex",
//       justifyContent: "center",
//       alignItems: "center",
//       backgroundColor: "#f5f5f5",
//     }}>
//       {/* <div className="container">
//         <div className="row" style={{
//         alignSelf: "center",
//         alignContent: "center",
//       }}> */}
//           <div className="col-md-6 col-sm-12 col-xs-12">
//             <div className="login-form">
//               <div className="login-form-header">
//                 {/* <LockIcon /> */}
//                 <h6>Enter Password</h6>
//               </div>
//               <form onSubmit={submitPassword}>
//                 <div className="form-group">
//                   <input
//                     type="password"
//                     className="form-control"
//                     placeholder="Password"
//                     style={{
//                       border: "1px solid #ced4da",
//                       borderRadius: "0.25rem",
//                       marginBottom: "1rem",
//                     }}
//                     onChange={(e) => setPassword(e.target.value)}
//                     required
//                   />
//                 </div>
//                 <button type="submit" className="btn btn-primary">
//                   Submit
//                 </button>
//               </form>
//             </div>
//           {/* </div>
//         </div> */}
//       </div>
//     </div>
//   );


// }

};

export default VerifyExam;

import React, { useState, useContext, useEffect } from "react";
import "../Assets/scss/EventBanner.scss";
import { Link } from "react-router-dom";

import {AuthContext} from "../Context/AuthContext";


function EventsBanner() {
const { token, _id} = useContext(AuthContext);
const [data, setData] = useState("");

  return (
    <>
      <section id="EventBanner">
        <div className="heading">Become a Champion, Join Canti-Quiz Now</div>
        <ul className="lists">
          <li>Learn Aptitude, Programming & DSA from Experts. </li>
          <li>Participate Daily Canti-Quiz & win Attractive Prizes. </li>
          <li>Practice Hand Picked Coding Questions in CL-Compiler.</li>
          <li>Best Mock Interviews from top Domains.</li>
        </ul>
        <div className="join-now-btn">
        {token == false ? (
                <Link to="/signup">
                  <span style={{ color: "red" }}>Start for FREE</span>
                </Link>
              ) : (
                <Link
                  to={{
                    pathname: `/user/${_id}`,
                    state: {
                      status: "dimple",
                      data: data,
                    },
                  }}
                >
                  <span style={{ color: "red" }}>Start for FREE</span>
                </Link>
        )}
        </div>
      </section>
    </>
  );
}

export default EventsBanner;

import React, { useState } from "react";
import { Carousel } from "react-bootstrap";
import style from "../../Assets/css/b2bClientCarousel.module.css";

import clientLogo from "../../Assets/img/b2b/Group 8545.png";
import clientLogo1 from "../../Assets/img/b2b/Group 8197.png";
import clientLogo2 from "../../Assets/img/b2b/Group 8548.png";
import clientLogo3 from "../../Assets/img/b2b/Group 8547.png";
function ClientCarousel() {
  const arr = [
    "Product Companies",
    "Consulting Companies",
    "Data Science Companies",
    "Finance Companies",
  ];
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  return (
    <div style={{ background: "#fff" }}>
      <div style={{ marginTop: "-10vh" }}>
        <br />
        <div style={{ margin: "5%", textAlign: "center" }}>
          <h2>
            <strong>Our Clients</strong>
          </h2>
          <p>
            <b>{arr[index]} </b>our Students are Placed In
          </p>
        </div>
        <Carousel
          className={style["carousel-indicators"]}
          controls={false}
          style={{ paddingBottom: "5%", zIndex: "0" }}
          // prevIcon={<span aria-hidden="false" className="carousel-control-prev-icon" />}
          activeIndex={index}
          onSelect={handleSelect}
        >
          <Carousel.Item className={style[("carousel-indicators", "active")]}>
            <div
            //   style={{
            //     display: "flex",
            //     marginTop: "4%",
            //     justifyContent: "center",
            //   }}
            >
              <img
                style={{
                  objectFit: "contain",
                  position: "relative",
                  width: "100%",
                }}
                src={clientLogo}
                alt=""
              />
            </div>

            <Carousel.Caption style={{ color: "black" }}></Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item className={style["active"]}>
            <div
            //   style={{
            //     display: "flex",
            //     marginTop: "4%",
            //     justifyContent: "center",
            //   }}
            >
              <img
                style={{
                  objectFit: "contain",
                  position: "relative",
                  width: "100%",
                }}
                src={clientLogo1}
                alt=""
              />
            </div>

            <Carousel.Caption style={{ color: "black" }}></Carousel.Caption>
          </Carousel.Item>{" "}
          <Carousel.Item className={style[("carousel-indicators", "active")]}>
            <div
            //   style={{
            //     display: "flex",
            //     marginTop: "4%",
            //     justifyContent: "center",
            //   }}
            >
              <img
                style={{
                  objectFit: "contain",
                  position: "relative",
                  width: "100%",
                }}
                src={clientLogo2}
                alt=""
              />
            </div>

            <Carousel.Caption style={{ color: "black" }}></Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item className={style[("carousel-indicators", "active")]}>
            <div
            //   style={{
            //     display: "flex",
            //     marginTop: "4%",
            //     justifyContent: "center",
            //   }}
            >
              <img
                style={{
                  objectFit: "contain",
                  position: "relative",
                  width: "100%",
                }}
                src={clientLogo3}
                alt=""
              />
            </div>

            <Carousel.Caption style={{ color: "black" }}></Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </div>
    </div>
  );
}

export default ClientCarousel;

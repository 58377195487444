import React from "react";
import Colleges from "../Assets/img/Home/Group 8578.svg";
import CollegesMob from "../Assets/img/Home/Group 8517.svg";
import Media from "react-media";

const CollegeCollabComp = () => {
  return (
    <>
      <div class="white-rect col-12 text-center">
        <Media query="(max-width: 650px)">
          {(matches) => {
            return matches ? (
              <img
                src={CollegesMob}
                class="img-responsive"
                style={{ width: "95%" }}
              ></img>
            ) : (
              <img
                src={Colleges}
                class="img-responsive"
                style={{ width: "75%" }}
              ></img>
            );
          }}
        </Media>
      </div>
    </>
  );
};

export default CollegeCollabComp;

import { useQuery } from "react-query";
import configAxios from "../axios.config";
import { AxiosResponse } from "axios";

interface PasswordResetRequestCount {
    count: number;
    lastRequestTime: string;
    _id: string;
}


interface SmallCourse {
    totalClasses: number;
    classesAttended: number;
    attendancePercentage: number;
    totalSessions: number;
    totalTime: number;
    sessionTime: number;
    name: string;
    courseId: string;
    progress: any[]; // Replace 'any' with appropriate type
    in_progress: any[]; // Replace 'any' with appropriate type
    bookmarkVideo: any[]; // Replace 'any' with appropriate type
    progressAssignments: any[]; // Replace 'any' with appropriate type
    compiler_array: any[]; // Replace 'any' with appropriate type
    _id: string;
    video_progress: any[]; // Replace 'any' with appropriate type
    not_started: any[]; // Replace 'any' with appropriate type
}

interface Student {
    _id: string;
    smallCourses: SmallCourse[];
}

interface User {
    _id: string;
    firstName: string;
    status: string;
    lastName: string;
    email: string;
    password: string;
    preparationlevel_q1: string;
    preparationlevel_q2: string[];
    preparationlevel_q3: string[];
    degree: string;
    isAdmin: boolean;
    privileges: any[]; // Replace 'any' with appropriate type
    isVerified: boolean;
    mobileNumber: string;
    yearOfStudy: string;
    department: string;
    profile_pic: string;
    city: string;
    Experience: string;
    referralTCS: string;
    signUp_date: string;
    additional_courses: string[];
    interestedCourse: boolean;
    iitbombaystudent: boolean;
    linkedin: string;
    otherLinks: string;
    github: string;
    pagesource: string;
    goPoints: number;
    batchAccess: any[]; // Replace 'any' with appropriate type
    roles: any[]; // Replace 'any' with appropriate type
    courseAccess: any[]; // Replace 'any' with appropriate type
    lastLoginDate: string;
    createdAt: string;
    updatedAt: string;
    __v: number;
    student: string;
    streakDate: string;
    passwordResetToken: string;
    passwordResetRequestCount: PasswordResetRequestCount;
    rollNo: string;
}

interface PasswordResetRequestCount {
    count: number;
    lastRequestTime: string;
    _id: string;
}

interface UserType {
    user: User;
    student: Student;
}


type TProfileProps = {
    onSucess?: (data: AxiosResponse<UserType, any>) => unknown;
    onError?: (error: any) => unknown;
}


export default function useProfile(props?: TProfileProps) {

    const data = useQuery(["profile", localStorage.getItem("token")], () => (
        configAxios(localStorage.getItem("token")).get<UserType>("/getProfile")
    ), {
        onSuccess: props?.onSucess,
        onError: props?.onError
    });

    return data;
}

import React from "react";
import Media from "react-media";
import useMedia from "../componentsofcourse/useMedia";
import amazon_logo from "../Assets/img/course/trans-amazon-logo.png";
import google_logo from "../Assets/img/course/trans-google-logo.png";
import facebook_logo from "../Assets/img/course/trans-facebook-logo.png";
import microsoft_logo from "../Assets/img/course/trans-microsoft-logo.png";
import linkedin_logo from "../Assets/img/course/linkedin.png";
import flipkart from "../Assets/img/course/flipkart.png";
import adobe from "../Assets/img/course/adobe.png"
import companies from "../Assets/img/course/companies.png";
import top_comp from "../Assets/img/course/Top Companies Hiringin this Field.png";
import top_arrow from "../Assets/img/course/Icon feather-arrow-up-circle.png";

function CpCompany() {
  const matches = useMedia('(min-width : 768px )' );
  return(
    <div className="container">
      <br/><br/><br/>
      {
        matches ?
      <div className="row">
        <img src={companies} />
      </div>
      :
      <div className="row">
      <div className="col-4">
          <img src={amazon_logo} className="img-fluid" />
        </div>
        <div className="col-4">
          <img src={google_logo} className="img-fluid" />
        </div>
        <div className="col-4">
          <img src={microsoft_logo} className="img-fluid" />
        </div>
        <div className="col-4">
          <img width="30%"  src={linkedin_logo} className="" />
        </div>
        <div className="col-4">
          <img  src={adobe} className="img-fluid" />
        </div>
        <div className="col-4">
          <img src={flipkart} className="img-fluid" />
        </div>
      </div>
      }
      <br/>
      <br/>
      <br/>
      <br/>
      <img src={top_arrow} className="mx-auto d-block img-fluid" /><br/>
      <h1 className="text-muted text-center" style={{fontWeight: "bold", fontSize: "30px"}}>Top Companies Hiring <br/> in this Field</h1>
      <br/>
      <br/><br/><br/>
    </div>
  );
};

export default CpCompany;

import React from "react";
import { NavLink } from "react-router-dom";
import style from "../../Assets/css/b2bTraining_sec.module.css";
// import img from "../../Assets/img/Group 8137.png";

const B2BTraining_Sec = () => {
  return (
    <>
      <div class="container-fluid" id="home">
        <div class="row">
          <div
            class={`col-xl-8 col-lg-7 col-md-6 col-12 ${style.training_text} `}
          >
            <div class={`${style.training_inner_div}`}>
              <h1 class={`${style.training_heading}`}>
                We Train &nbsp; | &nbsp; We Place
              </h1>
              <br />
              <p class={`${style.training_description_bold}`}>
                700+ Corporates | 20+ Incubators | 3+ International Accelerators
              </p>
              <p>
                Trust Cantilever Labs to fulfill your Students Dream Careers.
              </p>
              <div class="d-flex">
                <div>
                  <a href="#training">
                    <button class={`${style.training_prog_btn}`}>
                      Training Programs
                    </button>
                  </a>
                </div>
                <div class={`${style.btn_div}`}>
                  <NavLink to="/jobs-for-freshers">
                    <button class={`${style.opportunity_board_btn}`}>
                      Jobs & Internships
                    </button>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-lg-5 col-md-6 col-11">
            <div class={`${style.training_img_div}`}>
              <img src="https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644088834216_Group%208137.png" alt="" class="img-fluid" width="80%" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default B2BTraining_Sec;

import React from "react";
// import TAngel from "../Assets/img/Home/t_angel.png";
// import Microsoft from "../Assets/img/Home/microsoft.png";
// import Lab32 from "../Assets/img/Home/lab_32.png";
// import SmartUp from "../Assets/img/Home/smart_up.png";
import "../Assets/css/awards.css";
const Awards = () => {
  return (
    <div style={{ marginTop: "10%", marginBottom: "10%" }}>
      <h1 class="awards-collab-heading" style={{ textAlign: "center" }}>
        Awards and Recognition
      </h1>
      <div class="container-fluid" style={{ marginTop: "5%" }}>
        <div class="row justify-content-around">
          <div class="col-xl-6 col-lg-6 col-md-6 d-flex justify-content-around">
            <div class="awards-div4 awards-collab-div">
              <div class="awards-c-img awc-4">
                <img src={'https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644064030032_microsoft.png'} alt="" class="img-fluid img-3" />
              </div>
              <br />
              <div class="awards-c">
                <h5>Global Training Partner</h5>
                Exclusive access to <br />
                MS Certifications, GitHub, Outlook
              </div>
            </div>

            <div class="awards-div3 awards-collab-div">
              <div class="awards-c-img awc-3">
                <img src={'https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644064674193_smart_up.png'} alt="" class="img-fluid img-3" />
              </div>
              <br />
              <div class="awards-c">
                <h5>Winner of All India</h5>
                Received a sum of Rs0.33crore
                <br />
                in form of a grant.
              </div>
            </div>
          </div>
          <div class="col-xl-6 col-lg-6 col-md-6 d-flex justify-content-around">
            <div class="awards-collab-div my-auto">
              <div class="awards-c-img ">
                <img
                  src={'https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644063819704_t_angel.png'}
                  alt=""
                  class="img-fluid img-2"
                  style={{ width: "80%", height: "75%" }}
                />
              </div>

              <div class="awards-c mt-2">
                <h5>Part of T-Angel</h5>
                Investment readiness program
              </div>
            </div>
            <div class="awards-collab-div">
              <div class="awards-c-img">
                <img src={'https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644064505526_lab_32.png'} alt="" class="img-fluid img-1" />
              </div>
              <br />
              <div class="awards-c">
                <h5>Part of Lab-32</h5>
                Start-up Incubation
                <br />
                program
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Awards;

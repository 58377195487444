import React, { useState, useEffect } from "react";
import { BASE_URL, BASE_URL_MICRO } from "../env";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";

const OtpLogin = ({ show, setshow, email, time, setTimeLeftOtp }) => {
  const [otp, setotp] = useState("");
  const [warning, setWarning] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [timer, settimer] = useState(true);
  const [reset, setreset] = useState(false);
  const [times, settimes] = useState("");

  const recaptchaRef = React.createRef();
  const [captchaValue, setCaptchaValue] = useState(null);

  const onChange = (value) => {
    setCaptchaValue(value);
  };

  const GenerateOtp = async () => {
    setreset(!reset);
    settimer(true);
    try {
      await axios({
        method: "Post",
        url: `${BASE_URL}/generateotp`,
        data: {
          email: email,
        },
        headers: {
          "Content-type": "application/json",
        },
      });
    } catch (error) {
      console.log("something went wrong");
    }
  };

  const submitOtp = async (e) => {
    e.preventDefault();
    setSpinner(true);
    try {
      const res = await axios({
        method: "post",
        url: `${BASE_URL_MICRO}/signinnew`,
        data: {
          email: email,
          otp: otp,
          recaptchaToken: captchaValue,
        },
        headers: {
          "Content-type": "application/json",
        },
      });

      console.log(res);
      localStorage.setItem("token", res.data.token);
      if (localStorage.getItem("userlocation")) {
        window.location.href = localStorage.getItem("userlocation");
        localStorage.removeItem("userlocation");
      } else window.location.href = "/profile";
      setSpinner(false);
      setWarning(false);
    } catch (error) {
      setSpinner(false);
      setWarning(true);
      setTimeout(() => {
        setWarning(false);
      }, 6000);
    }
  };

  useEffect(() => {
    if (show) {
      let timerplus = time ? time : 60000 * 2;
      let timetostart = new Date(new Date().getTime() + timerplus).getTime();
      let timedifference = timetostart - new Date().getTime();
      var intervalnew = setInterval(() => {
        if (timedifference >= 0) {
          let minutes = Math.floor((timedifference / (1000 * 60)) % 60);
          let seconds = Math.floor((timedifference / 1000) % 60);
          let timeleft = minutes + ":" + seconds;
          settimes(timeleft);
          timedifference = timedifference - 1000;
        } else {
          settimer(false);
          setTimeLeftOtp(120000);
          clearInterval(intervalnew);
        }
      }, 1000);
    }
    return () => clearInterval(intervalnew);
  }, [show, reset, time]);

  return (
    <>
      {show ? (
        <>
          <div className="loginPopup">
            <div className="popupCard">
              <i class="fas fa-times" onClick={() => setshow(false)}></i>
              <div className="title">
                Verify Email <br />
                <span>We've sent an OTP to your email address</span>
              </div>
              <form onSubmit={submitOtp}>
                <p className={warning ? "d-block" : "d-none"}>Please Enter correct OTP</p>
                <div className="email">{email}</div>
                <div class="form-floating">
                  <input value={otp} onChange={(e) => setotp(e.target.value)} type="text" class="form-control" id="floatingInput" placeholder="0000" required />
                  <label for="floatingInput">Enter your OTP</label>
                </div>
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={process.env.REACT_APP_CAPTCHA_KEY}
                  onChange={onChange}
                  style={{
                    marginLeft: "10px",
                  }}
                />
                <button
                  type="sumit"
                  className="submitBtn"
                  style={{
                    backgroundColor: captchaValue === null && "#6a6c6e",
                    marginTop: "10px",
                  }}
                  disabled={captchaValue === null ? "disabled" : ""}
                >
                  {spinner ? <div className="spinner-border spinner-border-sm"></div> : "Submit"}
                </button>
              </form>
              {timer ? (
                <p>Resend OTP in {times}</p>
              ) : (
                <div className="resendLink" onClick={GenerateOtp}>
                  Resend OTP
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default OtpLogin;

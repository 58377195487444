import axios from "axios";
import { useEffect, useContext, useState } from "react";
import { Store } from "react-notifications-component";
import Footer from "./Footer";
import logo from "../Assets/img/Home/cantileverlogo-cut.png";
import defaultImage from "../Assets/img/Ellipse.png";
import personImage from "../Assets/img/Icon material-account-circle.png";
import courseImage from "../Assets/img/Icon material-library-books.png";
import homeImage from "../Assets/img/Icon metro-home.png";
import resource from "../Assets/img/Icon material-class.png";
import logout_img from "../Assets/img/Home/favicon.png";
import { useHistory, useLocation } from "react-router-dom";
import AuthContextProvider, { AuthContext } from "../Context/AuthContext";
import PopupComponent from "./PopupComponent";

import style from "../Assets/css/Coupons.module.css";
import useMedia from "../componentsofcourse/useMedia";
import { BASE_URL } from "../env";
import { Button } from "react-bootstrap";

function Edit(props) {
  const [popUp, setPopup] = useState(false);
  const location = useLocation();
  if (location.state) {
    var user = location.state.data.user;
    var student = location.state.data.student;
  }
  const history = useHistory(0);
  const matches = useMedia("(min-width : 768px )");
  const [profile, setProfile] = useState({
    pic: user ? user.profile_pic : null,
    _id: user?._id,
    newFirstName: user?.firstName,
    newLastName: user?.lastName,
    email: user?.email,
    phoneNumber: student?.phoneNumber,
    description: student?.description,
  });
  useEffect(() => {
    setProfile({
      pic: user ? user.profile_pic : null,
      _id: user?._id,
      newFirstName: user?.firstName,
      newLastName: user?.lastName,
      email: user?.email,
      phoneNumber: student?.phoneNumber,
      description: student?.description,
    });
  }, [user]);
  const { token, update_token, set_Id } = useContext(AuthContext);
  useEffect(() => {
    if (user) {
      document.getElementById("firstname").value = user.firstName;
      document.getElementById("lastname").value = user.lastName;
      document.getElementById("email").value = user.email;
      document.getElementById("phoneNumber").value = student.phoneNumber;
      // document.getElementById("description").value = student.description;
    }
  }, [user]);

  const onChangeInput = (e) => {
    const { name, value } = e.target;

    if (name === "newFirstName" && user) {
      profile.newFirstName = value;
    }
    if (name === "newLastName" && user) {
      profile.newLastName = value;
    }
    if (name === "email" && user) {
      profile.email = value;
    }
    if (name === "phoneNumber" && student) {
      if (profile.phoneNumber) {
        profile.phoneNumber = value;
      }
    }
    if (name === "description" && student) {
      if (profile.description) {
        profile.description = value;
      }
    }
  };
  const notifyPopup = (backend_message, type) => {
    Store.addNotification({
      title: backend_message,
      message: backend_message,
      type: type,
      background: "pink",
      insert: "bottom",
      container: "bottom-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true,
      },
    });
  };
  const logout = () => {
    localStorage.clear();
    update_token("");
    notifyPopup("You are Logged Out!!", "danger");
    history.push("/");
  };
  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      await axios
        .post(
          BASE_URL + "/editUser",
          { ...profile },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          //alert(res.data.message);
          notifyPopup(res.data.message, "success");
          window.location.href = `/user/${user._id}`;
        });
    } catch (err) {
      if (err.response && err.response.data) {
        //alert(err.response.data.error);
        notifyPopup();
      }
    }
  };

  return (
    <>
      <div style={{ overflowX: "hidden" }}>
        <div className="container-fluid p-5">
          <div className="row">
            <div
              className={
                matches
                  ? "col-lg-3 col-md-3 col-xl-3 col-sm-6 mx-auto d-flex flex-column  align-items-center"
                  : "col-lg-3 col-md-3 col-xl-3 col-sm-6 mx-auto d-flex flex-column  d-none align-items-center"
              }
            >
              <img src={logo} alt="cantileverlabs" className="img-fluid" />
              <div
                className="text-left text-secondary"
                style={{
                  marginTop: "10%",
                  fontWeight: "lighter",
                  fontSize: "24px",
                }}
              >
                <a href="/" className={`${style.items}`}>
                  <h6>
                    <img
                      src={homeImage}
                      alt="profile"
                      style={{
                        width: "20px",
                        margin: "15px 10px",
                      }}
                    />{" "}
                    Home
                  </h6>
                </a>
                <a href="/cpcourse">
                  <h6>
                    <img
                      src={courseImage}
                      alt="profile"
                      style={{
                        width: "20px",
                        margin: "15px 10px",
                      }}
                    />
                    Courses
                  </h6>
                </a>
                <a href="/blog">
                  <h6>
                    <img
                      src={resource}
                      alt="profile"
                      style={{
                        width: "20px",
                        margin: "15px 10px",
                      }}
                    />
                    Resource Center
                  </h6>
                </a>
                <a href={`/user/${profile._id}`}>
                  <h6>
                    <img
                      src={personImage}
                      alt="profile"
                      style={{
                        width: "20px",
                        margin: "15px 10px",
                      }}
                    />
                    Profile
                  </h6>
                </a>
                <div onClick={() => logout()}>
                  <h6>
                    <img
                      src={logout_img}
                      alt="profile"
                      style={{
                        width: "20px",
                        margin: "15px 10px",
                      }}
                    />
                    Logout
                  </h6>
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-md-9 col-xl-9 col-sm-6 mx-auto">
              <div style={{ marginLeft: "2%", marginBottom: "0" }}>
                <h3 className="my-2 text-primary">
                  <strong>Profile</strong>
                </h3>
                <p className="mx-auto text-primary ">Update Your Credentials</p>
              </div>

              <div
                class="container-fluid background"
                style={{
                  marginTop: "10px",
                  overflowX: "hidden",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div
                  className="container "
                  style={{
                    borderRadius: "20px",
                    border: "1px solid black",
                    padding: "5%",
                  }}
                >
                  <div className="row">
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 signup-text ">
                      <div>
                        <img
                          src={
                            user
                              ? user.profile_pic
                                ? user.profile_pic
                                : defaultImage
                              : null
                          }
                          alt="Profile Image"
                          style={{
                            height: "11vw",
							width: "11vw",
                            borderRadius: "100%",
                          }}
                          className="img-fluid"
                        />
                        <div>
                          <Button
                            variant="Light"
                            onClick={() => {
                              setPopup(true);
                            }}
                            style={{
                              postion: "relative",
                              marginTop: "-25%",
                              marginLeft: "48%",
                            }}
                          >
                            <i
                              class=" fa fa fa-plus"
                              style={{
                                fontSize: "20px",
                                backgroundColor: "blue",
                                borderRadius: "100%",
                                padding: "5px",
                              }}
                            ></i>
                          </Button>
                          <PopupComponent
                            trigger={popUp}
                            setTrigger={setPopup}
                            name="Close"
                          />
                        </div>
                      </div>
                      <div></div>
                    </div>
                    <div className="col-xl-9 col-lg-9 col-md-9 col-sm-6 signup-text">
                      <div>
                        <form onSubmit={submitHandler}>
                          <div
                            className="my-3"
                            style={{
                              borderBottom: "1.5px solid #185ADB ",
                            }}
                          >
                            <label for="firstname">
                              {" "}
                              <strong>First Name</strong>{" "}
                            </label>
                            <br />

                            <input
                              type="text"
                              name="newFirstName"
                              id="firstname"
                              placeholder="Jhon"
                              onChange={onChangeInput}
                              required
                              style={{
                                width: "100%",
                                border: "none",
                              }}
                              className={`${style.input_border}`}
                            />
                          </div>

                          <div
                            className="my-3"
                            style={{
                              borderBottom: "1.5px solid #185ADB ",
                            }}
                          >
                            <label for="lastname">
                              <strong>Last Name</strong>
                            </label>
                            <br />

                            <input
                              type="text"
                              name="newLastName"
                              id="lastname"
                              placeholder="Doe"
                              onChange={onChangeInput}
                              required
                              style={{
                                width: "100%",
                                border: "none",
                              }}
                              className={`${style.input_border}`}
                            />
                          </div>
                          <div
                            className="my-3"
                            style={{
                              borderBottom: "1.5px solid #185ADB ",
                            }}
                          >
                            <label for="email">
                              <strong>Email</strong>
                            </label>
                            <br />
                            <input
                              type="email"
                              name="email"
                              id="email"
                              placeholder="xyz@gmail.com"
                              onChange={onChangeInput}
                              required
                              style={{
                                width: "100%",
                                border: "none",
                              }}
                              className={`${style.input_border}`}
                            />
                          </div>
                          <div
                            className="my-3"
                            style={{
                              borderBottom: "1.5px solid #185ADB ",
                            }}
                          >
                            <label for="phoneNumber">
                              <strong>Phone Number</strong>
                            </label>
                            <br />
                            <input
                              type="number"
                              name="phoneNumber"
                              id="phoneNumber"
                              placeholder="Don't Enter Country Code"
                              onChange={onChangeInput}
                              style={{
                                width: "100%",
                                border: "none",
                              }}
                              className={`${style.input_border}`}
                            />
                          </div>
                          {/* <div
                            className="my-3"
                            style={{
                              borderBottom: "1.5px solid #185ADB ",
                            }}
                          >
                            <label for="description">
                              <strong>Description</strong>
                            </label>
                            <br />
                            <input
                              type="text"
                              name="description"
                              id="description"
                              placeholder="Tell Us About Yourself"
                              onChange={onChangeInput}
                              style={{
                                width: "100%",
                                border: "none",
                              }}
                              className={`${style.input_border}`}
                            />
                          </div> */}
                          <button
                            className="btn mt-5"
                            style={{
                              background: "#4285F4",
                              float: "right",
                              borderRadius: "8px",
                              padding: "5px 20px",
                            }}
                            type="submit"
                          >
                            Done
                          </button>
                        </form>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="col-lg-3 col-md-3 col-sm-12 mx-auto">
            <div className="">
              <img
                src={
                  user
                    ? user.profileImage
                      ? user.profileImage
                      : defaultImage
                    : null
                }
                alt="Profile Image"
                style={{ width: "180px", height: "180px", borderRadius: "50%" }}
              />
            </div>
          </div> */}
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Edit;

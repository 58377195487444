import axios from "axios";
import { BASE_URL, BASE_URL_MICRO } from "./env";
import { AxiosInstance } from "axios";

const JUDGE_COMPILER = "https://compiler.cantileverlabs.com";

/**
 *
 * @param {string | null} token
 * @return AxiosInstance
 */
const configAxios = (token = "") =>
  axios.create({
    baseURL: BASE_URL,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

/**
 *
 * @param {string | null} token
 * @return AxiosInstance
 */
export const configAxiosLambda = (token = "") => {
  const axios_instance = axios.create({
    baseURL: BASE_URL_MICRO,
    headers: {
      "Content-Type": "application/json",
      Authorization: token ? `Bearer ${token}` : "",
    },
  });
  axios_instance.defaults["retry"] = 3;
  axios_instance.defaults["retryDelay"] = 1000;

  axios_instance.interceptors.response.use(undefined, (error) => {
    const { config, message } = error;

    if (!config || !config.retry) {
      return Promise.reject(error);
    }

    config.__retryCount = config.__retryCount || 0;

    if (config.__retryCount >= config.retry) {
      return Promise.reject(error);
    }

    config.__retryCount += 1;

    if (message.includes("Network Error")) {
      return new Promise((resolve) => {
        setTimeout(
          () => resolve(axios_instance(config)),
          config.retryDelay || 1000
        );
      });
    }
    return Promise.reject(error);
  });

  return axios_instance;
};

export const compilerAxios = axios.create({
  baseURL: JUDGE_COMPILER,
  headers: {
    "Content-Type": "application/json",
    "X-CantiLeverLabs-Compiler": "wknuQcQ0W91h",
    // 'X-CantiLeverLabs-Compiler': '123456'
  },
});

const lambdaCompiler = axios.create({
  baseURL: BASE_URL_MICRO,
});

lambdaCompiler.defaults.retry = 3;
lambdaCompiler.defaults.retryDelay = 1000;

lambdaCompiler.interceptors.response.use(undefined, (error) => {
  const { config, message } = error;

  if (!config || !config.retry) {
    return Promise.reject(error);
  }

  config.__retryCount = config.__retryCount || 0;

  if (config.__retryCount >= config.retry) {
    return Promise.reject(error);
  }

  config.__retryCount += 1;

  if (message.includes("Network Error")) {
    return new Promise((resolve) => {
      setTimeout(
        () => resolve(lambdaCompiler(config)),
        config.retryDelay || 1000
      );
    });
  }
  return Promise.reject(error);
});

export default configAxios;
export { lambdaCompiler };

import { useState, useEffect } from "react";
import "../Assets/scss/UserDetails.scss";
import axios from "axios";
import Img1 from "../Assets/img/ProfilePage/List1.png";
import Img2 from "../Assets/img/ProfilePage/List2.png";
import Img3 from "../Assets/img/ProfilePage/List3.png";
import Img4 from "../Assets/img/ProfilePage/List4.png";
import Img5 from "../Assets/img/ProfilePage/List5.png";
import Img6 from "../Assets/img/ProfilePage/List6.png";
import Img7 from "../Assets/img/ProfilePage/List7.png";
import Img8 from "../Assets/img/ProfilePage/List8.png";
import Img9 from "../Assets/img/ProfilePage/List9.png";
import Img10 from "../Assets/img/ProfilePage/List10.png";
import Img11 from "../Assets/img/ProfilePage/List11.png";
import Img12 from "../Assets/img/ProfilePage/List12.png";
import Img13 from "../Assets/img/ProfilePage/List13.png";
import Img14 from "../Assets/img/ProfilePage/List14.png";
// import Img15 from "../Assets/img/ProfilePage/List15.png";
import Img16 from "../Assets/img/ProfilePage/List16.png";
import Img17 from "../Assets/img/ProfilePage/List17.png";
import Img18 from "../Assets/img/ProfilePage/List18.png";
import Img19 from "../Assets/img/ProfilePage/List19.png";
import Img20 from "../Assets/img/ProfilePage/List20.png";
import Img21 from "../Assets/img/ProfilePage/List21.png";
import Img22 from "../Assets/img/ProfilePage/List22.png";
import Img23 from "../Assets/img/ProfilePage/List23.png";
import Img24 from "../Assets/img/ProfilePage/List24.png";
import Img25 from "../Assets/img/ProfilePage/List25.png";
import Img26 from "../Assets/img/ProfilePage/List26.png";
import Img27 from "../Assets/img/ProfilePage/List27.png";
import Img28 from "../Assets/img/ProfilePage/List28.png";
import Img29 from "../Assets/img/ProfilePage/List29.png";
import Img30 from "../Assets/img/ProfilePage/List30.png";
import Img31 from "../Assets/img/ProfilePage/List31.png";
import Img32 from "../Assets/img/ProfilePage/List32.png";
import Img33 from "../Assets/img/ProfilePage/List33.png";
import Img34 from "../Assets/img/ProfilePage/List34.png";
import Img35 from "../Assets/img/ProfilePage/List35.png";
// import { BASE_URL } from "../env";
import configAxios from "../axios.config";
import { useHistory } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

function UserDetails() {
  const [currSection, setCurrSection] = useState(1);
  const [formMsg, setFormMsg] = useState("");
  const [userData, setUserData] = useState({});
  const history = useHistory();

  useEffect(() => {
    configAxios(localStorage.getItem("token"))
      .get("/getProfile")
      .then(({ data }) => setUserData(data.user));
  }, []);

  useEffect(() => {
    if (currSection === 1) {
      setFormMsg(
        "This information will help us → Suggest the Best Courses For you "
      );
    } else if (currSection === 2) {
      setFormMsg(
        "This information will help us → customize the courses according to your goal"
      );
    } else if (currSection === 3) {
      setFormMsg(
        "This information will help us → provide you personalized guidance from Industry Experts"
      );
    } else if (currSection === 4) {
      setFormMsg(
        "This information will help us → increase the chances of getting you Hired"
      );
    }
  }, [currSection]);

  function nextPage(data) {
    setCurrSection((prev) => ++prev);
    setUserData((prev) => {
      const temp_data = { ...prev, ...data };
      return { ...temp_data };
    });
    window.scroll(0, 0);
  }

  function submitData(data) {
    let temp_data = { ...userData, ...data };
    var submitData = new FormData();

    Object.keys(temp_data).forEach((key) => {
      submitData.append(key, temp_data[key]);
    });

    configAxios(localStorage.getItem("token"))
      .post("/editUser", submitData)
      .then(({ data }) => {
        history.push("/profile");
      })
      .catch(console.log);
  }

  function prevPage() {
    window.scroll(0, 0);
    setCurrSection((prev) => --prev);
  }

  useEffect(() => {
    console.log(userData);
  }, [userData]);

  const CurrentFormSection = () => {
    //   FORM SECTION #1
    if (currSection === 1) {
      return <Step1Form nextPage={nextPage} userData={userData} />;
    } else if (currSection === 2) {
      return (
        <AcademicDetailsForm
          nextPage={nextPage}
          userData={userData}
          prevPage={prevPage}
        />
      );
    } else if (currSection === 3) {
      return (
        <SkillDetails
          prevPage={prevPage}
          nextPage={nextPage}
          userData={userData}
        />
      );
    } else if (currSection === 4) {
      return (
        <PortfolioCreation
          prevPage={prevPage}
          userData={userData}
          submitData={submitData}
        />
      );
    }
  };

  return (
    <>
      <div className="profile-detail-page">
        <div className="profile-details">
          <div className="user-name">My Profile</div>
          <div className="user-subhead">{formMsg}</div>
          <div className="progress-bararea">
            <div className="straight-bar"></div>
            <div
              className="progress-count"
              style={{
                backgroundColor: currSection >= 1 ? "#133d6a" : "#F3F5F8",
                color: currSection >= 1 ? "#fff" : "#788085",
                border:
                  currSection >= 1 ? "2px solid #133d6a" : "2px solid #788085",
              }}
            >
              1
            </div>
            <div
              className="progress-count"
              style={{
                backgroundColor: currSection >= 2 ? "#133d6a" : "#F3F5F8",
                color: currSection >= 2 ? "#fff" : "#788085",
                border:
                  currSection >= 2 ? "2px solid #133d6a" : "2px solid #788085",
              }}
            >
              2
            </div>
            <div
              className="progress-count"
              style={{
                backgroundColor: currSection >= 3 ? "#133d6a" : "#F3F5F8",
                color: currSection >= 3 ? "#fff" : "#788085",
                border:
                  currSection >= 3 ? "2px solid #133d6a" : "2px solid #788085",
              }}
            >
              3
            </div>
            <div
              className="progress-count"
              style={{
                backgroundColor: currSection >= 4 ? "#133d6a" : "#F3F5F8",
                color: currSection >= 4 ? "#fff" : "#788085",
                border:
                  currSection >= 4 ? "2px solid #133d6a" : "2px solid #788085",
              }}
            >
              4
            </div>
          </div>
          <div className="progress-def">
            <div className="progress-defitem">Personal Details</div>
            <div className="progress-defitem">Academic Details</div>
            <div className="progress-defitem">Skill/Goal Details</div>
            <div className="progress-defitem">Portfolio creation</div>
          </div>
          <CurrentFormSection />
        </div>
      </div>
    </>
  );
}

function SelectBoxText({ inputType, value, selected, name }) {
  return (
    <label
      className="progress-count"
      style={{
        backgroundColor: selected ? "#133d6a" : "#fff",
        color: selected ? "#fff" : "#133d6a",
        border: selected ? "2px solid #133d6a" : "2px solid #f3f5f8",
      }}
    >
      <Field
        type={inputType}
        name={name}
        value={value}
        style={{
          display: "none",
        }}
      />
      {value}
    </label>
  );
}

function SelectBox({
  inputType,
  value,
  displayText,
  image,
  selected,
  name,
  className,
}) {
  return (
    <div className={`col-sm-4 col-6 mb-0${className ? " " + className : ""}`}>
      <label className={selected ? "form-card border-blue" : "form-card"}>
        <Field
          name={name}
          type={inputType}
          value={value}
          style={{
            display: "none",
          }}
        />
        <img src={image} alt="small icons" />
        <div
          className="card-title"
          dangerouslySetInnerHTML={{
            __html: displayText,
          }}
        ></div>
      </label>
    </div>
  );
}

const Step1FormSchema = Yup.object().shape({
  firstName: Yup.string().max("80", "Too Long!").required(),
  mobileNumber: Yup.number().positive().integer(),
  status: Yup.string()
    .oneOf([
      "Student(Undergraduate)",
      "Fresher(passed out recently)",
      "Working Professional",
    ])
    .optional(),
  areaOfInterest: Yup.array().max(9, "Array too long"),
  carrerGoals_q1: Yup.array().max(3, "More than 3 options selected"),
  carrerGoals_q2: Yup.string().optional(),
});

function Step1Form({ nextPage, userData }) {
  return (
    <>
      <Formik
        initialValues={{
          firstName: userData.firstName ? userData.firstName : "",
          mobileNumber: isNaN(parseInt(userData.mobileNumber))
            ? undefined
            : parseInt(userData.mobileNumber),
          status: userData.status,
          areaOfInterest: userData.areaOfInterest
            ? userData.areaOfInterest
            : [],
          carrerGoals_q1: userData.carrerGoals_q1
            ? userData.carrerGoals_q1
            : [],
          carrerGoals_q2: userData.carrerGoals_q2
            ? userData.carrerGoals_q2
            : "",
        }}
        validationSchema={Step1FormSchema}
      >
        {({ values, validateForm }) => (
          <Form className="profile-form">
            <div>
              <label htmlFor="firstName" fontSize="30" className="form-label">
                My Name
              </label>
              <Field
                name="firstName"
                type="text"
                className="form-control"
                placeholder="Enter Your Name"
              />
            </div>
            <div>
              <label
                htmlFor="mobileNumber"
                fontSize="30"
                className="form-label"
              >
                My Phone Number
              </label>
              <Field
                name="mobileNumber"
                type="number"
                className="form-control"
                placeholder="Enter Your Phone Number"
              />
            </div>
            <div>
              <label fontSize="30" className="form-label">
                I am
              </label>
              <div className="row g-sm-4 g-2">
                <SelectBox
                  inputType="radio"
                  value="Student(Undergraduate)"
                  displayText="Student <br/>(Undergraduate)"
                  selected={values.status === "Student(Undergraduate)"}
                  image={Img1}
                  name="status"
                />
                <SelectBox
                  inputType="radio"
                  value="Fresher(passed out recently)"
                  displayText="Fresher <br/>(passed out recently)"
                  name="status"
                  selected={values.status === "Fresher(passed out recently)"}
                  image={Img2}
                />
                <SelectBox
                  inputType="radio"
                  value="Working Professional"
                  displayText="Working <br/>Professional"
                  selected={values.status === "Working Professional"}
                  name="status"
                  image={Img3}
                />
              </div>
            </div>
            <div>
              <label fontSize="30" className="form-label">
                I am Looking for
              </label>
              <div className="row g-sm-4 g-2">
                <SelectBox
                  inputType="checkbox"
                  value="Complete Placement Preparation"
                  displayText="Complete Placement <br/>Preparation"
                  name="areaOfInterest"
                  selected={
                    values.areaOfInterest.filter(
                      (val) => val === "Complete Placement Preparation"
                    )[0]
                  }
                  image={Img4}
                />
                <SelectBox
                  inputType="checkbox"
                  value="Jobs"
                  displayText="Jobs"
                  name="areaOfInterest"
                  selected={
                    values.areaOfInterest.filter((val) => val === "Jobs")[0]
                  }
                  image={Img5}
                />
                <SelectBox
                  inputType="checkbox"
                  value="Internships"
                  displayText="Internships"
                  name="areaOfInterest"
                  selected={
                    values.areaOfInterest.filter(
                      (val) => val === "Internships"
                    )[0]
                  }
                  image={Img6}
                />
                <SelectBox
                  inputType="checkbox"
                  value="Skill development course"
                  displayText="Skill development <br/>course"
                  name="areaOfInterest"
                  selected={
                    values.areaOfInterest.filter(
                      (val) => val === "Skill development course"
                    )[0]
                  }
                  image={Img7}
                />
                <SelectBox
                  inputType="checkbox"
                  value="Personalised Career Guidance"
                  displayText="Personalised Career <br/>Guidance"
                  name="areaOfInterest"
                  selected={
                    values.areaOfInterest.filter(
                      (val) => val === "Personalised Career Guidance"
                    )[0]
                  }
                  image={Img8}
                />
                <SelectBox
                  inputType="checkbox"
                  value="Free Courses and toolkits"
                  displayText="Free Courses <br/>and toolkits"
                  name="areaOfInterest"
                  selected={
                    values.areaOfInterest.filter(
                      (val) => val === "Free Courses and toolkits"
                    )[0]
                  }
                  image={Img9}
                />
                <SelectBox
                  inputType="checkbox"
                  value="Cracking Screening test of companies"
                  displayText="Cracking Screening <br/> test of companies"
                  name="areaOfInterest"
                  selected={
                    values.areaOfInterest.filter(
                      (val) => val === "Cracking Screening test of companies"
                    )[0]
                  }
                  image={Img10}
                />
                <SelectBox
                  inputType="checkbox"
                  value="Recruting company related information"
                  displayText="Recruting company <br/>related information"
                  name="areaOfInterest"
                  selected={
                    values.areaOfInterest.filter(
                      (val) => val === "Recruting company related information"
                    )[0]
                  }
                  image={Img11}
                />
              </div>
            </div>
            <div>
              <label fontSize="30" className="form-label">
                I want to get placed into
              </label>
              <div className="row g-sm-4 g-2">
                <SelectBox
                  inputType={"checkbox"}
                  value="FAANG"
                  displayText={"FAANG"}
                  image={Img13}
                  name="carrerGoals_q1"
                  selected={
                    !!values.carrerGoals_q1.filter((val) => val === "FAANG")[0]
                  }
                />
                <SelectBox
                  inputType={"checkbox"}
                  value="Product Companies"
                  displayText={"Product Companies"}
                  image={Img14}
                  name="carrerGoals_q1"
                  selected={
                    !!values.carrerGoals_q1.filter(
                      (val) => val === "Product Companies"
                    )[0]
                  }
                />
                <SelectBox
                  inputType={"checkbox"}
                  value="Service Companies"
                  displayText={"Service Companies"}
                  image={Img13}
                  name="carrerGoals_q1"
                  selected={
                    !!values.carrerGoals_q1.filter(
                      (val) => val === "Service Companies"
                    )[0]
                  }
                />
              </div>
            </div>
            <label
              className="form-label"
              fontSize="30"
              htmlFor="carrerGoals_q2"
            >
              My Goal
            </label>
            <Field
              name="carrerGoals_q2"
              type="text"
              className="form-control"
              placeholder="Enter Your Goal"
            />
            <button
              className="btn form-submit-btn"
              type="button"
              onClick={() => {
                validateForm().then((res) => {
                  if (Object.keys(res).length === 0) {
                    nextPage(values);
                  } else {
                    console.log({ error: res });
                  }
                });
              }}
            >
              Continue
            </button>
          </Form>
        )}
      </Formik>
    </>
  );
}

const AcademicDetailsSchema = Yup.object().shape({
  degree: Yup.string().max("50", "Degree length too long"),
  department: Yup.string().max("50", "Department length too long"),
  yearOfPassout: Yup.string(),
  collegeName: Yup.string().matches(
    /^[A-Za-z0-9 -]*$/,
    "College name can only have characters, spaces and numbers"
  ),
  city: Yup.string().matches(
    /^[A-Za-z0-9 -]*$/,
    "City can only have characters and spaces"
  ),
});

const AcademicDetailsForm = ({ prevPage, nextPage, userData }) => {
  return (
    <>
      <Formik
        initialValues={{
          degree: userData.degree ? userData.degree : "",
          department: userData.department ? userData.department : "",
          yearOfPassout: "",
          collegeName: "",
          city: "",
        }}
        validationSchema={AcademicDetailsSchema}
      >
        {({ values, validateForm }) => (
          <Form className="profile-form">
            <div>
              <label className="form-label" fontSize="30">
                My Graduation Degree
              </label>
              <div className="row g-sm-4 g-2">
                <SelectBox
                  inputType={"radio"}
                  value="BTech/BE"
                  displayText={"BTech/BE"}
                  image={Img2}
                  selected={values.degree === "BTech/BE"}
                  name="degree"
                  className={`col-lg-3`}
                />
                <SelectBox
                  inputType={"radio"}
                  value="B.Sc"
                  displayText={"B.Sc"}
                  image={Img2}
                  selected={values.degree === "B.Sc"}
                  name="degree"
                  className={`col-lg-3`}
                />
                <SelectBox
                  inputType={"radio"}
                  value="BCA"
                  displayText={"BCA"}
                  image={Img2}
                  selected={values.degree === "BCA"}
                  name="degree"
                  className={`col-lg-3`}
                />
                <SelectBox
                  inputType={"radio"}
                  value="Others"
                  displayText={"Others"}
                  image={Img12}
                  selected={values.degree === "Others"}
                  name="degree"
                  className={`col-lg-3`}
                />
              </div>
            </div>
            <div>
              <label className="form-label">My Department</label>
              <div className="row g-sm-4 g-2">
                <SelectBox
                  inputType={"radio"}
                  value="CSE"
                  displayText={"CSE"}
                  image={Img16}
                  selected={values.department === "CSE"}
                  name="department"
                />
                <SelectBox
                  inputType={"radio"}
                  value="ECE"
                  displayText={"ECE"}
                  image={Img17}
                  selected={values.department === "ECE"}
                  name="department"
                />
                <SelectBox
                  inputType={"radio"}
                  value="IT"
                  displayText={"IT"}
                  image={Img18}
                  selected={values.department === "IT"}
                  name="department"
                />
                <SelectBox
                  inputType={"radio"}
                  value="EEE"
                  displayText={"EEE"}
                  image={Img19}
                  selected={values.department === "EEE"}
                  name="department"
                />
                <SelectBox
                  inputType={"radio"}
                  value="Mechanical"
                  displayText={"Mechanical"}
                  image={Img20}
                  selected={values.department === "Mechanical"}
                  name="department"
                />
                <SelectBox
                  inputType={"radio"}
                  value="Civil"
                  displayText={"Civil"}
                  image={Img21}
                  selected={values.department === "Civil"}
                  name="department"
                />
                <SelectBox
                  inputType={"radio"}
                  value="Others"
                  displayText={"Others"}
                  image={Img12}
                  selected={values.department === "Others"}
                  name="department"
                />
              </div>
            </div>
            <div>
              <label htmlFor="yearOfPassout" className="form-label">
                My Graduation Year
              </label>
              <Field
                type="month"
                className="form-control"
                name="yearOfPassout"
                placeholder="Select your graduation year"
              />
            </div>
            <div>
              <label className="form-label" htmlFor="collegeName">
                My College Name
              </label>
              <Field
                type="text"
                className="form-control"
                name="collegeName"
                placeholder="Enter your college name"
              />
            </div>
            <div className="button-nav">
              <button
                className="btn form-back-btn"
                onClick={prevPage}
                type="button"
              >
                Back
              </button>
              <button
                className="btn form-submit-btn"
                type="button"
                onClick={() => {
                  validateForm().then((res) => {
                    if (Object.keys(res).length === 0) {
                      nextPage(values);
                    } else {
                      window.scroll(0, 0);
                      console.log({ error: res });
                    }
                  });
                }}
              >
                Next
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

const SkillDetailsSchema = Yup.object().shape({
  skills: Yup.array().of(Yup.string()),
  Experience: Yup.string(),
  monthsOfExperience: Yup.string(),
  projects: Yup.string().matches(
    /^[A-Za-z0-9 -]*$/,
    "Projects name can only have characters, spaces and numbers"
  ),
  additional_courses: Yup.string().matches(
    /^[A-Za-z0-9 -]*$/,
    "Courses name can only have characters, spaces and numbers"
  ),
});

const SkillDetails = ({ prevPage, nextPage, userData }) => {
  return (
    <>
      <Formik
        initialValues={{
          skills: userData.skills ? userData.skills : [],
          Experience: userData.Experience ? userData.Experience : "",
          monthsOfExperience: userData.monthsOfExperience
            ? userData.monthsOfExperience
            : "",
          projects: userData.projects ? userData.projects : "",
          additional_courses: userData.additional_courses
            ? `${userData.additional_courses}`
            : "",
        }}
        validationSchema={SkillDetailsSchema}
      >
        {({ values, validateForm }) => (
          <Form className="profile-form">
            <div>
              <label className="form-label">My Skills</label>
              <div className="row g-sm-4 g-2">
                <SelectBox
                  className={`col-lg-3`}
                  inputType="checkbox"
                  value="C"
                  displayText="C"
                  image={Img24}
                  selected={values.skills.filter((val) => val === "C")[0]}
                  name="skills"
                />
                <SelectBox
                  className={`col-lg-3`}
                  inputType="checkbox"
                  value="C++"
                  displayText="C++"
                  image={Img25}
                  selected={values.skills.filter((val) => val === "C++")[0]}
                  name="skills"
                />
                <SelectBox
                  className={`col-lg-3`}
                  inputType="checkbox"
                  value="Competitive Coding"
                  displayText="Competitive Coding"
                  image={Img26}
                  selected={
                    values.skills.filter(
                      (val) => val === "Competitive Coding"
                    )[0]
                  }
                  name="skills"
                />
                <SelectBox
                  className={`col-lg-3`}
                  inputType="checkbox"
                  value="Python"
                  displayText="Python"
                  image={Img27}
                  selected={values.skills.filter((val) => val === "Python")[0]}
                  name="skills"
                />
                <SelectBox
                  className={`col-lg-3`}
                  inputType="checkbox"
                  value="UI/UX"
                  displayText="UI/UX"
                  image={Img28}
                  selected={values.skills.filter((val) => val === "UI/UX")[0]}
                  name="skills"
                />
                <SelectBox
                  className={`col-lg-3`}
                  inputType="checkbox"
                  value="Web Development(Front end)"
                  displayText="Web Development <br/>(Front end)"
                  image={Img29}
                  selected={
                    values.skills.filter(
                      (val) => val === "Web Development(Front end)"
                    )[0]
                  }
                  name="skills"
                />
                <SelectBox
                  className={`col-lg-3`}
                  inputType="checkbox"
                  value="Web Development(Full stack)"
                  displayText="Web Development <br/>(Full stack)"
                  image={Img30}
                  selected={
                    values.skills.filter(
                      (val) => val === "Web Development(Full stack)"
                    )[0]
                  }
                  name="skills"
                />
                <SelectBox
                  className={`col-lg-3`}
                  inputType="checkbox"
                  value="Content Writing"
                  displayText="Content Writing"
                  image={Img31}
                  selected={
                    values.skills.filter((val) => val === "Content Writing")[0]
                  }
                  name="skills"
                />
                <SelectBox
                  className={`col-lg-3`}
                  inputType="checkbox"
                  value="Product Management"
                  displayText="Product Management"
                  image={Img32}
                  selected={
                    values.skills.filter(
                      (val) => val === "Product Management"
                    )[0]
                  }
                  name="skills"
                />
                <SelectBox
                  className={`col-lg-3`}
                  inputType="checkbox"
                  value="Project Management"
                  displayText="Project Management"
                  image={Img30}
                  selected={
                    values.skills.filter(
                      (val) => val === "Project Management"
                    )[0]
                  }
                  name="skills"
                />
                <SelectBox
                  className={`col-lg-3`}
                  inputType="checkbox"
                  value="Software Development"
                  displayText="Software Development"
                  image={Img30}
                  selected={
                    values.skills.filter(
                      (val) => val === "Software Development"
                    )[0]
                  }
                  name="skills"
                />
                <SelectBox
                  className={`col-lg-3`}
                  inputType="checkbox"
                  value="Finance"
                  displayText="Finance"
                  image={Img30}
                  selected={values.skills.filter((val) => val === "Finance")[0]}
                  name="skills"
                />
              </div>
            </div>
            <div>
              <label className="form-label">My Experience</label>
              <div className="ExpSec">
                <SelectBoxText
                  inputType="radio"
                  name="Experience"
                  value="I don't have any"
                  selected={values.Experience === "I don't have any"}
                />
                <SelectBoxText
                  inputType="radio"
                  name="Experience"
                  value="Part time"
                  selected={values.Experience === "Part time"}
                />
                <SelectBoxText
                  inputType="radio"
                  name="Experience"
                  value="Full time"
                  selected={values.Experience === "Full time"}
                />
                <SelectBoxText
                  inputType="radio"
                  name="Experience"
                  value="Internship"
                  selected={values.Experience === "Internship"}
                />
                <SelectBoxText
                  inputType="radio"
                  name="Experience"
                  value="Freelancing"
                  selected={values.Experience === "Freelancing"}
                />
                <SelectBoxText
                  inputType="radio"
                  name="Experience"
                  value="Self learned"
                  selected={values.Experience === "Self learned"}
                />
              </div>
            </div>
            <div>
              <label htmlFor="monthsOfExperience" className="form-label">
                Months/Years of experience
              </label>
              <Field
                type="text"
                className="form-control"
                placeholder="Enter Your Experience"
                name="monthsOfExperience"
              />
            </div>
            <div>
              <label htmlFor="projects" className="form-label">
                My Projects
              </label>
              <Field
                type="text"
                className="form-control"
                placeholder="Enter Your Projects"
                name="projects"
              />
            </div>
            <div>
              <label className="form-label" htmlFor="additional_courses">
                Courses I have done
              </label>
              <Field
                type="text"
                className="form-control"
                placeholder="(ex - ML, AI, IOT etc.)"
                name="additional_courses"
              />
            </div>
            <div className="button-nav">
              <button
                className="btn form-back-btn"
                onClick={prevPage}
                type="button"
              >
                Back
              </button>
              <button
                className="btn form-submit-btn"
                onClick={() => {
                  validateForm().then((res) => {
                    if (Object.keys(res).length === 0) {
                      window.scroll(0, 0);
                      nextPage(values);
                    } else {
                      console.log({ error: res });
                    }
                  });
                }}
                type="button"
              >
                Continue
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

const ProfileCreationSchema = Yup.object().shape({
  profile_pic: Yup.mixed(),
  linkedin: Yup.string().matches(
    /^https:\/\/[a-z]{2,3}\.linkedin\.com\/[a-zA-Z0-9-]+\/?$/,
    'URL should be in the format "https://www.linkedin.com/in/<username>"'
  ),
  resume: Yup.mixed(),
  github: Yup.string(),
  website: Yup.string(),
});

const PortfolioCreation = ({ prevPage, userData, submitData }) => {
  const [profile_pic, set_profile_pic] = useState(null);
  const [resume, setResume] = useState(null);
  return (
    <Formik
      initialValues={{
        linkedin: "",
        github: "",
        website: "",
      }}
      validationSchema={ProfileCreationSchema}
    >
      {({ values, validateForm }) => (
        <Form>
          <div>
            <label className="form-label" htmlFor="profile_pic">
              Profile Photo
            </label>
            <div className="row mt-sm-3 mt-2 g-0">
              <div className="col-3">
                <img
                  src={Img22}
                  className="img-fluid"
                  alt="profile"
                  style={{
                    borderRadius: "50%",
                    maxHeight: "120px",
                  }}
                />
              </div>
              <div className="col-9">
                <div className="col-4 Buttons">
                  <i
                    className="fa fa-upload d-inline mx-2"
                    aria-hidden="true"
                  ></i>
                  <input
                    type="file"
                    className="custom-file-input"
                    accept="image/*"
                    name="profile_pic"
                    onChange={(e) => set_profile_pic(e.currentTarget.files[0])}
                  />
                </div>
                <div
                  className="col-4 Buttons"
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <i
                    className="fa fa-trash d-inline  mx-2"
                    aria-hidden="true"
                  ></i>
                  Delete
                </div>
              </div>
            </div>
          </div>
          <div>
            <label className="form-label" name="linkedin">
              Linkedin URL
            </label>
            <Field
              type="text"
              className="form-control"
              name="linkedin"
              placeholder="Enter your LinkedIn Profile URL"
            />
          </div>
          <div>
            <label className="form-label" htmlFor="resume">
              Upload Resume
            </label>
            <div className="row mt-sm-3 mt-2 g-0">
              <div className="col-3">
                <img src={Img23} className="img-fluid" alt="small icons" />
                <br />
              </div>
            </div>
            <div className="col-9">
              <div className="col-4 Buttons">
                <i
                  className="fa fa-upload d-inline mx-2"
                  aria-hidden="true"
                ></i>
                <input
                  type="file"
                  className="custom-file-input"
                  name="resume"
                  onChange={(e) => setResume(e.currentTarget.files[0])}
                />
              </div>
              <div className="col-4 Buttons" style={{ cursor: "pointer" }}>
                <i className="fa fa-trash d-inline mx-2" aria-hidden="true"></i>
                Delete
              </div>
            </div>
          </div>
          <div>
            <label className="form-label" htmlFor="github">
              Github
            </label>
            <Field
              type="text"
              name="github"
              className="form-control"
              placeholder="Your github profile link"
            />
          </div>
          <div>
            <label className="form-label" htmlFor="website">
              Website
            </label>
            <Field
              type="text"
              name="website"
              className="form-control"
              placeholder="Enter portfolio website URL"
            />
          </div>
          <div className="button-nav">
            <div>
              <button
                className="form-back-btn btn"
                type="button"
                onClick={prevPage}
              >
                Back
              </button>
            </div>
            <div>
              <button
                type="button"
                className="btn form-submit-btn"
                onClick={() => {
                  validateForm().then((res) => {
                    if (Object.keys(res).length === 0) {
                      submitData({ ...values, profile_pic, resume });
                    } else {
                      console.log({ error: res });
                    }
                  });
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default UserDetails;

import "../component/styled/newcompStyle/PDAssignmentPage.scss";
import starimgGreen from "../Assets/starimg_green.svg";
import starimgGrey from "../Assets/starimg_grey.svg";
import starimgWrong from "../Assets/starimg_wrong.svg";
import arrow from "../Assets/RightArrow.svg"
function PDAssignmentPage({
  popUpCardNext,
  assignData: assignTypes
}) {
  return (
    <div className="PDAssignmentpage">
      {assignTypes.map((ele) => {
        const { solved, unsolved, wrong } = ele;
        return (
          <>
            <div
              key={ele._id}
              className="col-md-6 col-sm-6 mb-md-4 mb-2 PDonline"
              // style={{ width: "43%" }}
            >
              <div
                className={`pdAssignCard ${
                  ele.cardName.toLowerCase().includes("class")
                    ? "pdAssignCardGrey"
                    : "pdAssignCardBlue"
                }`}
              >
                <div className="pdAssignHead">
                  <div className="pdAssignTopic" style={{ color: "#000" }}>
                    {ele.cardName}
                  </div>
                </div>
                <div className="pdAssignBody">
                  <ul className="pdStar">
                    <DisplayStar
                      solved={solved}
                      unsolved={unsolved}
                      wrong={wrong}
                    />
                  </ul>
                </div>
                <div className="pdassignbtn">
                  <div
                    className="pdassignButton"
                    onClick={() => {
                      popUpCardNext(ele.cardName, ele._id);
                    }}
                  >
                   {/* // Let's Go */}
                    <img className="arrowImg" height={50} src={arrow} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      })}
    </div>
  );
}

const DisplayStar = ({ solved, unsolved, wrong }) => {
  if (!solved || !unsolved || !wrong) {
    return (
      <>
        {[...Array(4)].map(() => (
          <li className="pdStarItem">
            <img className="pdStarImg" src={starimgGrey} alt="star" />
          </li>
        ))}
      </>
    );
  } else if (solved + unsolved + wrong !== 4) {
    return (
      <>
        {[...Array(4)].map(() => (
          <li className="pdStarItem">
            <img className="pdStarImg" src={starimgGrey} alt="star" />
          </li>
        ))}
      </>
    );
  }
  return (
    <>
      {[...Array(solved)].map(() => (
        <li className="pdStarItem">
          <img className="pdStarImg" src={starimgGreen} alt="star" />
        </li>
      ))}
      {[...Array(wrong)].map(() => (
        <li className="pdStarItem">
          <img className="pdStarImg" src={starimgWrong} alt="star" />
        </li>
      ))}
      {[...Array(unsolved)].map(() => (
        <li className="pdStarItem">
          <img className="pdStarImg" src={starimgGrey} alt="star" />
        </li>
      ))}
    </>
  );
};
export default PDAssignmentPage;

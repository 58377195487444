import React, { useEffect, useState } from "react";
import PowerBatch from "./components/PowerBatch";
import WaveRed from "../Assets/img/waveRed.svg";
import WaveBlue from "../Assets/img/waveBlue.svg";
import DownloadFileBtn from "./components/DownloadFileBtn";
import { useSelector } from "react-redux";
import configAxios from "../axios.config";
import { getColor } from "./helper";
import { PowerOverviewData } from "./PowerBI_test_data";
import { fontWeight, style } from "@mui/system";
const PowerOverview = () => {
  const [red, SetRed] = useState(0);
  const [blue, SetBlue] = useState(0);

  const selector = useSelector((reducers) => reducers.batchReducer);
  const examType = useSelector((reducers) => reducers.examReducer);
  useEffect(() => {
    if (!selector) return;
    const controller = new AbortController();
    if (PowerOverviewData[selector]) {
      SetRed(PowerOverviewData[selector].red);
      SetBlue(PowerOverviewData[selector].blue);
      return;
    }
    configAxios(localStorage.getItem("token"))
      .get("/powerbi/getOverallCodingResult/" + selector, {
        signal: controller.signal,
      })
      .then(({ data }) => {
        SetRed(parseFloat(data.percentage));
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selector]);

  // blue
  useEffect(() => {
    if (!selector) return;
    // const controller = new AbortController();
    // configAxios(localStorage.getItem("token"))
    //   .get("/powerbi/getOverallAptitudeResult/" + selector, {
    //     signal: controller.signal,
    //   })
    //   .then(({ data }) => {
    //     SetBlue(parseFloat(data.percentage));
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  }, [selector]);
  return (
    <>
      <div className='row'>
        <div className='col-12 mb-0'>
          <PowerBatch />
        </div>
        <div className='col-lg-6 mb-0'>
          <div className='powerBiCard mt-4 position-relative' style={{backgroundColor:getColor(examType)}}>
            <div className='powerBiHeading'>Coding Health Report</div>
            <div className='reportShowcase'>
              <div className='waveCircle'>
                <div className='wavePercentage'>{red}%</div>
                <div className='wave'>
                  <img
                    src={WaveRed}
                    className='animatedWave'
                    alt='animated wave'
                  />
                  <div
                    className='redColor'
                    style={{
                      height: 240 * (red / 100),
                    }}></div>
                </div>
              </div>
            </div>
            <div className='d-md-block d-flex justify-content-end' style={{backgroundColor:getColor(examType)}}>
              
              <DownloadFileBtn  name="Coding" />
            </div>
          </div>
        </div>
        <div className='col-lg-6 mb-0'>
          <div className='powerBiCard mt-4 position-relative' style={{backgroundColor:getColor(examType)}}>
            <div className='powerBiHeading'>Apptitude Health Report</div>
            <div className='reportShowcase'>
              <div
                className='waveCircle'
                style={{ border: "4px solid #165dff" }}>
                <div className='wavePercentage'>{blue}%</div>
                <div className='wave'>
                  <img
                    src={WaveBlue}
                    className='animatedWave'
                    alt='animated wave'
                  />
                  <div
                    className='blueColor'
                    style={{
                      height: 240 * (blue / 100),
                    }}></div>
                </div>
              </div>
            </div>
            <div className='d-md-block d-flex justify-content-end' >
             
              <DownloadFileBtn name='Apptitude' />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// PowerOverview.defaultProps = {
//   red: 0,
//   blue: 0
// }

export default PowerOverview;

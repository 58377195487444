import React, { useEffect, useState, useContext, useRef } from "react";
// import Media from "react-media";
import { Redirect, useHistory, Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import Comment from "../Assets/img/Blogs/comment.png";
import Fb_icon from "../Assets/img/Blogs/fb_icon.png";
import Insta_icon from "../Assets/img/Blogs/insta_icon.png";
import liked_blog from "../Assets/img/Blogs/liked_blog.png";
import like_blog from "../Assets/img/Blogs/like_blog.png";
import Save_icon from "../Assets/img/Blogs/save_icon.png";
import Link_icon from "../Assets/img/Blogs/link_icon.png";
// import Blog_img1 from "../Assets/img/Blogs/blog_img1.png";
import styles from "../Assets/css/blog.module.css";
// import Blogs from "../Assets/img/Blogs/blogs.png";
// import ReactQuill from "react-quill";
// import "suneditor/dist/css/suneditor.min.css";
import Footer from "../components/Footer";
import SendIcon from "../Assets/img/Blogs/send_icon.png";
import Google from "../Assets/img/Blogs/google.jpeg";
// import FacebookLogin from "react-facebook-login";
// import NewFacebookBlog from "../Assets/img/NewFacebookBlog.jpeg";
import UserImage from "../Assets/img/Blogs/Ellipse.png";
import GoogleLogin, { useGoogleLogin } from "react-google-login";
// import "react-quill/dist/quill.bubble.css";
// import "../components/Blogs/quill.bubble.css";
import ContentLoader from "react-content-loader";
import { AuthContext } from "../Context/AuthContext";
import "../Assets/css/blog-comment.css";
import moment from "moment";
import axios from "axios";
import { Button } from "react-bootstrap";
import { render } from "@testing-library/react";
import { Store } from "react-notifications-component";
import Navbar from "../components/Navbar";
import { BASE_URL } from "../env";
import ReactGA from "react-ga";
import { events, eventsMob } from "../components/Blogs/data";
import BlogCard from "../components/Blogs/Blog2";
import HelmetComp from "../components/HelmetComp";
import logo from "../Assets/img/cantileverlogoblack.png";
import "../Assets/css/blogpage.css";
import { SuperSEO } from "react-super-seo";

function BlogPage(props) {
  const history = useHistory();
  const blogImgBig = {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    // padding: "40px",
    // borderRadius:"10px",
    width: "90%",
  };

  // const BASE_URL="http://localhost:5000"

  const qlEditor = {
    boxSizing: "border-box",
    lineHeight: "1.42",
    height: "100%",
    outline: "none",
    overflowY: "auto",
    padding: " 12px 15px",
    tabSize: "4", // -moz-tab-size: "4",
    textAlign: "left",
    whiteSpace: "pre-wrap",
    wordWrap: "break-word",
  };
  const today = moment();

  // function CapitalizeEveryLetter(string) {
  //   string = string.toLowerCase();
  //   const arr = string.split(" ");

  //   //loop through each element of the array and capitalize the first letter.

  //   for (var i = 0; i < arr.length; i++) {
  //     arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  //   }

  //   //Join all the elements of the array back into a string
  //   //using a blankspace as a separator
  //   const str2 = arr.join(" ");
  //   return str2;
  // }
  var emails = "";
  var tok = "";
  var pass = "";
  var firstnames = "";
  var lastnames = "";
  // if (props.location.state) {
  //   var data = props.location.state.data;
  // }
  const [blog, setBlog] = useState([]);
  const [ip, setIp] = useState("");
  const [blogTitle, setBlogTitle] = useState("");
  const [date, setDate] = useState(null);
  const [comment, setComment] = useState([]);
  const [dat, setDat] = useState(false);
  const [modal, setModal] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [load, setLoad] = useState(false);
  const [render, setRender] = useState(false);
  const [error, setError] = useState(false);
  const [errmsg, setErrmsg] = useState("");
  const [route, setRoute] = useState("");
  const [msg, showMsg] = useState(" ");
  const [input1, setInput] = useState("");
  const [commentStatus, commentSet] = useState("");
  const { token, update_token, _id, set_Id } = useContext(AuthContext);
  const [status1, SETstatus] = useState();
  const [liked, setLiked] = useState(false);
  const [user_id, setUser_Id] = useState("");
  const [likes, setLikes] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [sideBlogs, setSideBlogs] = useState([]);
  const [ifAdmin, setIfAdmin] = useState(false);
  const [blogObjectId, setBlogObjectId] = useState("");
  // const [numLikes, setNumLikes] = useState([]);
  const blogID = props.match.params.newid;

  const { newid } = useParams();
  // console.log(props.match.params);

  var password_google = "";
  var email_google = "";
  var firstName_google = "";
  var lastname_google = "";

  const pwdRef = useRef();

  useEffect(() => {
    const body = document.querySelector("#root");
    body.scrollIntoView(
      {
        behavior: "smooth",
      },
      500
    );
    let token = localStorage.getItem("token");
    if (token === null) {
      return;
    } else {
      let token = localStorage.getItem("token");
      fetch(BASE_URL + "/getProfile", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: token,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setIfAdmin(data.user.isAdmin);

          setUser_Id(data._id);
        });
    }
  }, []);
  useEffect(() => {
    fetch(BASE_URL + "/blog/" + blogID)
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);

        setComment(data.blog[0].comments);
        setLikes(data.blog[0].likes);
        setBlog(data.blog[0]);
        // console.log('Sayan', data.blog[0].body);
        setBlogTitle(data.blog[0].title);
        console.log(data?.blog[0]?._id);

        setBlogObjectId(data?.blog[0]?._id);
        setLoaded(true);
        // console.log(data.blog[0]);
        data.blog[0].likes.map((item) => {
          if (item == user_id) {
            setLiked(true);
            return;
          } else {
            setLiked(false);
          }
        });
      })
      .catch((err) => {});
    const today = moment();
    let blogDate = moment(blog.date).format();
    //
    //
    setDate(today.diff(blogDate, "days"));
  }, [props, blogID]);

  useEffect(() => {
    fetch(BASE_URL + "/getAllBlogs")
      .then((response) => response.json())
      .then((data) => {
        // console.log("All Blog Data", data);
        setSideBlogs((prev) => {
          prev = [];
          data.blogs.map((item) => {
            prev.push(item);
          });
          return prev;
        });
        // setLoaded(true);
      });
  }, []);

  useEffect(async () => {
    await fetch(BASE_URL + "/getprofile", {
      headers: { authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then((response) => response.json())
      .then((respt) => {
        SETstatus(respt.user);
      })
      .catch((err) => {});
    let blogDate = moment(blog.date).format();
    //
    //
    setDate(today.diff(blogDate, "days"));
  }, [blogID]);

  const responseFacebook = (response) => {
    if (response.name) {
      let tik = response?.id;
      emails = response.email;
      const fullName = response.name;
      const fullNameArray = response?.name.split(" ");
      //
      tok = tik;
      firstnames = fullNameArray[0];
      lastnames = fullNameArray[fullNameArray.length - 1];

      //
      //
      googleSignIn();
    }
  };

  function notifyPopup(message, title, type) {
    Store.addNotification({
      title: title,
      message: message,
      type: type,
      background: "pink",
      insert: "bottom",
      container: "bottom-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true,
      },
    });
  }

  async function googleSignIn() {
    setLoad(true);
    await fetch(BASE_URL + "/signin", {
      method: "POST",
      body: JSON.stringify({
        id: 3,
        email: emails,
        password: tok,
        isGoogle: true,
      }),
      headers: { "content-Type": "application/json" },
    })
      .then((response) => response.json())
      .then((data) => {
        if (Object.keys(data).find((element) => element === "token")) {
          localStorage.setItem("token", data.token);
          update_token(data.token);

          if (
            history.location.state != undefined &&
            Object.keys(history.location.state).find(
              (element) => element === "prev"
            )
          ) {
            setRoute(history.location.state.prev);
          } else {
            setRoute("/");
          }

          setRender(true);

          notifyPopup("You are Logged In!", "Success!", "success");
        } else {
          setError(true);
          handleSignUp();
          setErrmsg(data.message);
          setLoad(true);
        }

        async function handleSignUp() {
          await fetch(BASE_URL + "/signup", {
            method: "POST",
            body: JSON.stringify({
              firstName: firstnames,
              lastName: lastnames,
              email: emails,
              password: tok,
              source: "blog",
            }),
            headers: { "content-Type": "application/json" },
          })
            .then((response) => response.json())
            .then((data) => {
              if (data.type == "success") {
                setRender(true);
                googleSignIn();
              } else {
                setError(true);
                setErrmsg(data.message);
                setLoad(false);
                pwdRef.current.value = "";
              }
            });

          setLoad(false);
          //pwdRef.current.value = "";
        }
      });
  }
  async function handleComment(e) {
    e.preventDefault();
    const token1 = localStorage.getItem("token");
    if (token1 === null) {
      commentSet("Please Login To comment on the Article! ");
    } else if (input1 === "") {
      commentSet("Please Type something to post! ");
    } else {
      const blogID = props.match.params.newid;

      const url4 = BASE_URL + `/commentBlog/${blogID}`;
      const wait = await fetch(url4, {
        method: "POST",
        headers: {
          authorization: localStorage.getItem("token"),
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({
          comment: input1,
        }),
      })
        .then((response) => response.json())
        .catch((err) => {
          setInput("");
          commentSet("sorry couldn't post the comment1");
        });

      if (wait === undefined) {
        setInput("");
        commentSet("sorry couldn't post the comment");
      } else if (wait.message.slice(0, 15) === "Comment posted!") {
        setInput("");
        return commentSet("sucessfully posted the comment!!");
      } else {
        setInput(" ");
        commentSet("sorry couldn't post the comment");
      }
    }
  }
  async function handleLikes() {
    if (liked) {
      return;
    } else {
      const token1 = localStorage.getItem("token");
      if (token1 === null) {
        notifyPopup(
          "Please Login To Like on the Article! ",
          "Please Login!",
          "warning"
        );
      } else {
        const blogID = props.match.params.newid;
        // console.log(blogID);
        // setLikes((prev) => prev++);
        const url4 = BASE_URL + `/like/${blogID}`;
        fetch(url4, {
          method: "put",
          headers: {
            authorization: localStorage.getItem("token"),
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        })
          .then((response) => response.json())
          .then(() => {
            setLiked(true);
            setLikes([...likes, user_id]);
          })
          .catch((err) => {
            notifyPopup("Some Error Occured!", "Error", "danger");
          });
      }
    }
  }
  //Google Sign in response Handler
  async function responsehandler(response) {
    password_google = response?.tokenId;
    let profile_info = response?.profileObj;

    email_google = profile_info.email;
    firstName_google = profile_info.givenName;
    lastname_google = profile_info.familyName;
    googleSignIn();
  }
  //this is the google Sign in button
  async function googleSignIn() {
    await fetch(BASE_URL + "/signin", {
      method: "POST",
      body: JSON.stringify({
        id: 3,
        email: email_google,
        password: password_google,
        isGoogle: true,
      }),
      headers: { "content-Type": "application/json" },
    })
      .then((response) => response.json())
      .then((data) => {
        if (Object.keys(data).find((element) => element === "token")) {
          localStorage.setItem("token", data.token);
          update_token(data.token);

          if (
            history.location.state != undefined &&
            Object.keys(history.location.state).find(
              (element) => element === "prev"
            )
          ) {
          } else {
          }
        } else {
          handleSignUp();
        }
        // this is the handle sign up button
        async function handleSignUp() {
          await fetch(BASE_URL + "/signup", {
            method: "POST",
            body: JSON.stringify({
              firstName: firstName_google,
              lastName: lastname_google,
              email: email_google,
              password: password_google,
              source: "blog",
            }),
            headers: { "content-Type": "application/json" },
          })
            .then((response) => response.json())
            .then((data) => {
              if (data.type == "success") {
                googleSignIn();
              } else {
              }
            });
        }
      });
  }

  useEffect(() => {
    // alert("hello")
    ReactGA.initialize("UA-198180460-1");
    ReactGA.modalview("/blogs/" + blog?.topic);
    ReactGA.modalview("/blogs/" + blog?.title);
    ReactGA.event({
      category: "Blog",
      action: "opened Blog",
      value: blog.title,
    });
    // console.log(blog.title);
  }, [blog]);
  async function handledelete(props2) {
    setDat(false);
    const blogID = props.match.params.id;
    const commentId = props2._id;
    const userComment = props2.user;

    const wait = await fetch(
      BASE_URL + `/commentDelete/${blogID}/${commentId}`,
      {
        method: "POST",
        headers: {
          authorization: localStorage.getItem("token"),
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({
          _id: userComment,
        }),
      }
    )
      .then((response) => response.json())
      .catch((err) => {
        setDat(true);
        setInput("");
        commentSet("sorry couldn't Delete the comment1");
      });

    if (wait === undefined) {
      setDat(true);
      setInput("");
      commentSet("sorry couldn't post the comment");
    } else if (wait.message.slice(0, 12) === "Blog deleted") {
      setDat(true);
      setInput("");
      return commentSet("sucessfully Deleted the comment!!");
    } else {
      setDat(true);
      setInput("");
      commentSet("Couldn't delete  may be you cant delete other comments");
    }
  }

  useEffect(async () => {
    await fetch("https://api.ipify.org?format=json")
      .then((res) => res.json())
      .then(async (data) => {
        await setIp(data.ip);
      })
      .catch((err) => {
        // console.log(err)
      });
    if (ip !== "" && blogTitle !== "") {
      // console.log("SUCCESS");
      await fetch(BASE_URL + "/blogTracker", {
        method: "POST",
        body: JSON.stringify({
          ip,
          blogTitle: blogTitle,
        }),
        headers: { "content-Type": "application/json" },
      })
        .then((response) => response.json())
        .then((data) => {
          // console.log("data sent");
          // console.log(data);
        })
        .catch((err) => {
          // console.log(err);
        });
    }
  }, [ip, blogTitle]);
  const handleDel = () => {
    fetch(BASE_URL + "/deleteBlog", {
      method: "POST",
      body: JSON.stringify({
        _id: blogObjectId,
      }),
      headers: {
        "content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        history.push("/blog");
      });
  };

  return (
    <>
      <SuperSEO
        title={blogTitle}
        description={blogTitle}
        lang="en"
        openGraph={{
          ogImage: {
            ogImage: blog.image,
            ogImageAlt: "Blog image",
            ogImageWidth: 1200,
            ogImageHeight: 630,
            ogImageType: "image/jpeg",
          },
        }}
        twitter={{
          twitterSummaryCard: {
            summaryCardImage: blog.image,
            summaryCardImageAlt: "Blog image",
            summaryCardSiteUsername: "Cantilever labs",
          },
        }}
      />
      {/* <Navbar /> */}
      <HelmetComp
        info={{
          title: blogTitle,
          description: blogTitle,
          siteName: "https://www.cantileverlabs.com/blog/" + newid,
          summary:
            "Get all the information regarding new job vacancies at top companies.",
          pageUrl: window.location.href,
          imagUrl: { logo },
        }}
      />
      {!loaded ? (
        <>
          <ContentLoader
            speed={2}
            width={1360}
            height={900}
            viewBox="0 0 1360 900"
            backgroundColor="#f2f2f4"
            foregroundColor="#c0c0c0"
            style={{ justifyContent: "space-evenly" }}
          >
            <rect
              x="10vw"
              y="50"
              rx="8"
              ry="8"
              width="85vw"
              height="100vh"
              className="my-2"
            />
          </ContentLoader>
          <span className="sr-only">Loading...</span>
        </>
      ) : (
        <>
          <div className="row">
            <div className="col-lg-9 col-md-9 col-sm-12 mx-auto">
              <div
                style={{ marginTop: "7%", overflow: "hidden" }}
                className="container"
              >
                <div className="row">
                  <div className="col-xl-10 col-lg-10 col-md-12 col-sm-12 blogpage-div-1">
                    {/* <span className={styles.blogtopic}>{blog.topic}</span> */}
                    {/* <div className={styles.hr}></div> */}
                    {/* <hr className='web-hr' /> */}
                    {/* <hr style={{marginBottom:"10px",marginTop:"0px"}} /> */}
                    <h2 className={styles.blogtitlenew}>{blog.title}</h2>
                    {ifAdmin && (
                      <>
                        <button
                          type="button"
                          class="btn btn-danger me-3"
                          onClick={handleDel}
                        >
                          Delete
                        </button>
                        <button
                          type="button"
                          class="btn btn-success"
                          onClick={() => {
                            history.push(`/editblog/${newid}`);
                          }}
                        >
                          Update
                        </button>
                      </>
                    )}
                    <br />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        objectFit: "contain",
                      }}
                      // className="d-flex blog-read"
                    >
                      <div className="d-flex justify-content-start ">
                        {/* <br /> */}
                        <h6
                          onClick={() => {}}
                          className={"tendays " + styles.publisheddate}
                        >
                          Published on
                          {date == 0 || date == null
                            ? moment(blog.date).format(" MMMM Do YYYY")
                            : ` ${date} Day ago.`}
                          {/* {date} Day ago. */}
                          {/* {moment(data.date).format("dddd, MMMM Do YYYY, h:mm a")} */}
                        </h6>
                        <h5 className={"tendays mx-2 " + styles.publisheddate}>
                          {blog.duration} read
                        </h5>
                      </div>
                      <div>
                        <a href="https://www.facebook.com/Cantilever-labs-102916474752041/">
                          <img
                            src={Fb_icon}
                            alt=""
                            className="img-fluid blog-il-img"
                          />
                        </a>
                        <a href="https://www.linkedin.com/company/cantileverlabs/">
                          <img
                            src={Link_icon}
                            alt=""
                            className="img-fluid blog-il-img"
                          />
                        </a>
                        <a href="https://www.instagram.com/cantilever_labs/?hl=en">
                          <img
                            src={Insta_icon}
                            alt=""
                            className="img-fluid blog-il-img"
                          />
                        </a>
                        <img
                          src={Save_icon}
                          alt=""
                          className="img-fluid blog-il-img"
                        />
                      </div>
                    </div>
                    <br />
                    <img
                      // src="https://cantilever-blog-images.s3.ap-south-1.amazonaws.com/Product%20Hiring%20Company.jpeg"
                      src={blog.image}
                      alt=""
                      className="img-fluid shadow "
                      style={blogImgBig}
                    />
                    <div className="photo-credits">
                      {/* <h6>photo credits</h6> */}
                    </div>
                    <br />
                    <h4 className="blog-para">
                      {/* <span className="w">W</span>hat is Lorem Ipsum? */}
                    </h4>
                    {/* <div className="noselect">
                  <ReactQuill
                    // style={qlEditor}
                    style={{ textJustify: "auto" }}
                    value={blog.body || ""}
                    readOnly={true}
                    theme={"bubble"}
                  />
                </div> */}
                    <div className={styles.blogSection}>
                      <div
                        className={styles.newblogsection}
                        dangerouslySetInnerHTML={{ __html: blog.body }}
                      ></div>
                    </div>
                    <br />
                    <div class="d-flex blog-read">
                      {liked ? (
                        <img
                          src={liked_blog}
                          style={{ marginLeft: "-40%", width: "30px" }}
                          alt="like post"
                          className=" img-fluid"
                        />
                      ) : (
                        <img
                          src={like_blog}
                          style={{
                            marginLeft: "-40%",
                            color: "red",
                            cursor: "pointer",
                            width: "30px",
                          }}
                          alt="like post"
                          className=" img-fluid"
                          onClick={() => {
                            handleLikes();
                          }}
                        />
                      )}

                      {likes?.length}

                      <img
                        style={{ marginLeft: "50%" }}
                        src={Comment}
                        alt=""
                        className="comment img-fluid"
                      />
                      {comment.length}
                    </div>
                    <br />
                    <div class="container-fluid">
                      <div class="row">
                        <div class="col-xl-11 col-lg-11 col-md-11 col-sm-11 col-11 comment-section">
                          <form onSubmit={handleComment}>
                            <div class="form-group">
                              <input
                                type="text"
                                onChange={(e) => setInput(e.target.value)}
                                class="form-control-lg"
                                id="comment"
                                value={input1}
                                placeholder="Join the discussion..."
                                name="comment"
                              />
                            </div>
                            <div
                              class="row comment-row-div"
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              {commentStatus.slice(7, 12) == "Login" ? (
                                <div class="col-xl-3 col-lg-4 col-md-5 col-sm-5  comment-section-div">
                                  <div class="d-flex comment-section-inner-div">
                                    <div className="col-lg-4">
                                      <GoogleLogin
                                        clientId="671105516475-03k9jvsa3evmg1k4u5ge13alls8v9nfv.apps.googleusercontent.com"
                                        onSuccess={responsehandler}
                                        cookiePolicy={"single_host_origin"}
                                        className="pl-5 pl-5"
                                        render={(renderProps) => {
                                          return (
                                            <>
                                              <button
                                                style={{
                                                  borderRadius: "50px",
                                                  padding: "0.2rem",
                                                  backgroundColor: "white",
                                                  borderStyle: "none",
                                                  boxShadow: "2px 2px #888888",
                                                }}
                                              >
                                                <img
                                                  style={{
                                                    borderRadius: "50px",
                                                    width: "35px",
                                                    height: "35px",
                                                  }}
                                                  onClick={renderProps.onClick}
                                                  disabled={
                                                    renderProps.disabled
                                                  }
                                                  src={Google}
                                                />{" "}
                                              </button>
                                            </>
                                          );
                                        }}
                                      />
                                    </div>
                                    <div>
                                      {" "}
                                      {/* <FacebookLogin
                                        cssClass={styles.btnFacebook}
                                        appId="1832196643618709"
                                        autoLoad={false}
                                        fields="name,email,picture"
                                        scope="public_profile,user_friends,email"
                                        callback={responseFacebook}
                                        icon={styles["fa-facebook"]}
                                        textButton=""
                                      >
                                        <img src={NewFacebookBlog} />
                                      </FacebookLogin> */}
                                    </div>
                                    <div
                                      class="comment-text"
                                      style={{
                                        marginLeft: "4%",
                                        marginRight: "3%",
                                      }}
                                    >
                                      or &nbsp;
                                    </div>
                                    <div class="comment-text">
                                      <Link to="/signup">
                                        <b>Signup</b>
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <span>{commentStatus}</span>
                              )}

                              <div
                                class="col-xl-9 col-lg-8 col-md-7 col-sm-7 btn-section-div"
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <button class="post-comment-btn" type="submit">
                                  Post Comment
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                      <h3 class="text-comment-b">
                        {comment.length}{" "}
                        {comment.length === 1 ? (
                          <span>Comment</span>
                        ) : (
                          <span>Comments</span>
                        )}
                      </h3>
                      <hr class="comment-hr" />
                      <div class="row">
                        <div
                          style={{
                            maxHeight: "400px",
                            overflowY: "auto",
                          }}
                          className="col-xl-11 col-lg-11 col-md-11 col-sm-11 col-11 comment-section-1"
                        >
                          {comment.map((item, index) => {
                            return (
                              <div
                                key={item._id}
                                className="d-flex"
                                style={{ marginBottom: "20px" }}
                              >
                                <div>
                                  <img
                                    src={item.Img ? item.Img : UserImage}
                                    alt=""
                                    className="ellipse-img"
                                  />
                                </div>
                                <div>
                                  <p className="comment-user-name">
                                    {item.userName}
                                  </p>
                                  <p>{item.review}</p>
                                  <p className="days-comment">
                                    {/* {item.date.slice(0, 10)} */}
                                    {today.diff(item.date, "days") === 0
                                      ? moment(item.date).fromNow()
                                      : today.diff(item.date, "days")}
                                  </p>
                                </div>
                                {status1 !== undefined &&
                                (item.user === status1._id ||
                                  status1.isAdmin === true) ? (
                                  <button
                                    onClick={() => handledelete(item)}
                                    style={{
                                      fontSize: "14px",
                                      position: "sticky",
                                      marginTop: "15px",
                                      left: "90%",
                                      borderStyle: "none",
                                      backgroundColor: "white",
                                    }}
                                  >
                                    delete
                                  </button>
                                ) : null}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="related-post-J my-2 mx-lg-5 mx-xl-5 mx-md-2 p-2">
          <h3>Related Posts</h3>
          <hr />
        </div> */}

                {/* <div className="container-fluid">
          <Media query="(max-width: 750px)">
            {(matches) => {
              return matches ? (
                <div className="row">
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6">
                    <img src={Blogs} className="img-fluid blogs-img" />
                    <div className="blogs-text-mobile">
                      <p>
                        <span style={{ fontSize: "10px", fontWeight: "900" }}>
                          Effects of New Vaccine Trails
                        </span>
                        <br />
                        <span style={{ fontSize: "8px" }}>
                          10 Days ago . 5min Read
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6">
                    <img src={Blogs} className="img-fluid blogs-img" />
                    <div className="blogs-text-mobile">
                      <p>
                        <span style={{ fontSize: "10px", fontWeight: "900" }}>
                          2nd Wave and Its strains n Mutents
                        </span>
                        <br />
                        <span style={{ fontSize: "8px" }}>
                          10 Days ago . 5min Read
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6">
                    <img src={Blogs} className="img-fluid blogs-img" />
                    <div className="blogs-text-mobile">
                      <p>
                        <span style={{ fontSize: "10px", fontWeight: "900" }}>
                          Impacts of Covid on Labours (May Day Spl)
                        </span>
                        <br />
                        <span style={{ fontSize: "8px" }}>
                          10 Days ago . 5min Read
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6">
                    <img src={Blogs} className="img-fluid blogs-img" />
                    <div className="blogs-text-mobile">
                      <p>
                        <span style={{ fontSize: "10px", fontWeight: "900" }}>
                          Effects of New Vaccine Trails
                        </span>
                        <br />
                        <span style={{ fontSize: "8px" }}>
                          10 Days ago . 5min Read
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6">
                    <img src={Blogs} className="img-fluid blogs-img" />
                    <div className="blogs-text-mobile">
                      <p>
                        <span style={{ fontSize: "10px", fontWeight: "900" }}>
                          2nd Wave and Its strains n Mutents
                        </span>
                        <br />
                        <span style={{ fontSize: "8px" }}>
                          10 Days ago . 5min Read
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6">
                    <img src={Blogs} className="img-fluid blogs-img" />
                    <div className="blogs-text-mobile">
                      <p>
                        <span style={{ fontSize: "10px", fontWeight: "900" }}>
                          Impacts of Covid on Labours (May Day Spl)
                        </span>
                        <br />
                        <span style={{ fontSize: "8px" }}>
                          10 Days ago . 5min Read
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6">
                    <img src={Blogs} className="img-fluid blogs-img" />
                    <div className="blogs-text">
                      <p>
                        <span style={{ fontSize: "12px", fontWeight: "900" }}>
                          Effects of New Vaccine Trails
                        </span>
                        <br />
                        <span style={{ fontSize: "10px" }}>
                          10 Days ago . 5min Read
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6">
                    <img src={Blogs} className="img-fluid blogs-img" />
                    <div className="blogs-text">
                      <p>
                        <span style={{ fontSize: "12px", fontWeight: "900" }}>
                          2nd Wave and Its strains n Mutents
                        </span>
                        <br />
                        <span style={{ fontSize: "10px" }}>
                          10 Days ago . 5min Read
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6">
                    <img src={Blogs} className="img-fluid blogs-img" />
                    <div className="blogs-text">
                      <p>
                        <span style={{ fontSize: "12px", fontWeight: "900" }}>
                          Impacts of Covid on Labours (May Day Spl)
                        </span>
                        <br />
                        <span style={{ fontSize: "10px" }}>
                          10 Days ago . 5min Read
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6">
                    <img src={Blogs} className="img-fluid blogs-img" />
                    <div className="blogs-text">
                      <p>
                        <span style={{ fontSize: "12px", fontWeight: "900" }}>
                          Effects of New Vaccine Trails
                        </span>
                        <br />
                        <span style={{ fontSize: "10px" }}>
                          10 Days ago . 5min Read
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6">
                    <img src={Blogs} className="img-fluid blogs-img" />
                    <div className="blogs-text">
                      <p>
                        <span style={{ fontSize: "12px", fontWeight: "900" }}>
                          2nd Wave and Its strains n Mutents
                        </span>
                        <br />
                        <span style={{ fontSize: "10px" }}>
                          10 Days ago . 5min Read
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6">
                    <img src={Blogs} className="img-fluid blogs-img" />
                    <div className="blogs-text">
                      <p>
                        <span style={{ fontSize: "12px", fontWeight: "900" }}>
                          Impacts of Covid on Labours (May Day Spl)
                        </span>
                        <br />
                        <span style={{ fontSize: "10px" }}>
                          10 Days ago . 5min Read
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              );
            }}
          </Media>
        </div> */}
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 mx-auto">{}</div>
          </div>
        </>
      )}
      <Footer />
    </>
  );
}
export default BlogPage;

import React from "react";
import styles from "../Assets/css/cpcourse.module.css";
function cpCourseCards(props) {
  let array = [
    {
      b: "100+",
      Name: " Doubts solved",
      secondName: " per hour",
    },
    {
      b: "98%",
      Name: "Doubt resolution with",
      secondName: "min 4+ star ratings",
    },
    {
      b: "30 min",
      Name: "Average time for doubt",
      secondName: "to get resolved",
    },
  ];
  let array1 = [
    {
      b: "5L",
      Name: " Expected Jobs",
      secondName: "By 2025",
    },
    {
      b: "75K",
      Name: "Current",
      secondName: "Openings",
    },
    {
      b: "15L",
      Name: "Avg.",
      secondName: "Salary/year",
    },
  ];
  let Array1 = props.set === 1 ? array : array1;
  return (
    <div>
      {props.set === 2 ? (
        <div>
          <br />
          <br />
          <h1 style={{ fontWeight: "bold", textAlign: "center" }}>
            {props.set1}
          </h1>
        </div>
      ) : null}
      <div
        className={`container-fluid ${styles.imagesdiv} justify-content-around`}
        style={{ marginTop: "80px", marginBottom: "80px" }}
      >
        <div
          className={styles.middlecardmob}
          // style={{
          //   display: "flex",
          //   flexDirection: "row",
          //   flexWrap: "wrap",
          //   textAlign: "center",
          //   justifyContent: "center",
          // }}
        >
          {Array1.map((item) => (
            <div
              //   style={{
              //     height: "130px",
              //     borderRadius: "10px",
              //     width: "19%",
              //     height: "180px",
              //     boxShadow: "0px 10px 20px #0000002B",
              //     margin: "5%",
              //   }}
              className={`card ${styles.cardCon} col-md-3 col-lg-3 p-2`}
            >
              <br />
              <h1 style={{ color: "#00A2C8", fontSize: "4.5vw" }}>
                <b>{item.b}</b>
              </h1>
              <p className="card-mid-text">
                <b>
                  {item.Name}
                  <br></br>
                  {item.secondName}
                </b>
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default cpCourseCards;

{
  /*<div
          style={{
            // height: "130px",
            borderRadius: "20px",
            width: "16%",
            height: "155px",
            boxShadow: "0px 10px 20px #0000002B",
            margin: "2%",
          }}
          className="card col-md-3 col-lg-3 p-2"
        >
          <br />
          <h1 style={{ color: "#00A2C8" }}>
            <b>100+</b>
          </h1>
          <p className="card-mid-text">
            <b>
              Doubts solved<br></br> per hour
            </b>
          </p>
        </div>
        <div className="col-md-1"></div>
        <div
          style={{
            height: "155px",
            alignItems: "left",
            borderRadius: "20px",
            width: "16%",
            boxShadow: "0px 10px 20px #0000002B",
            margin: "2%",
          }}
          className="card col-md-3  col-lg-3 p-2"
        >
          <br />
          <h1 style={{ color: "#00A2C8" }}>
            <b>98%</b>
          </h1>
          <p className="card-mid-text">
            <b>
              Doubt resultion with<br></br>min 4+ star ratings
            </b>
          </p>
        </div>
        <div className="col-md-1"></div>
        <div
          style={{
            height: "150px",
            borderRadius: "20px",
            width: "16%",
            boxShadow: "0px 10px 20px #0000002B",
            margin: "2%",
          }}
          className="card col-md-3 col-lg-3 p-2"
        >
          <br />
          <div>
            {" "}
            <h1 style={{ color: "#00A2C8", width: "100%" }}>
              <b>30 min</b>
            </h1>
            <p className="card-mid-text">
              <b>
                Average time for doubt<br></br>to get resolved
              </b>
            </p>
          </div>
        </div>
      </div>
    </div> 

<div
  className="container-fluid images-div justify-content-around"
  style={{ marginTop: "80px", marginBottom: "80px" }}
>
  <div
    className="middle-card-mob"
    // style={{
    //   display: "flex",
    //   flexDirection: "row",
    //   flexWrap: "wrap",
    //   textAlign: "center",
    //   justifyContent: "center",
    // }}
  >
    <div
      style={{
        // height: "130px",
        borderRadius: "20px",
        width: "16%",
        height: "155px",
        boxShadow: "0px 10px 20px #0000002B",
        margin: "2%",
      }}
      className="card col-md-3 col-lg-3 p-2"
    >
      <br />
      <h1 style={{ color: "#00A2C8" }}>
        <b>5L</b>
      </h1>
      <p className="card-mid-text">
        <b>
          Expected Jobs<br></br>By 2025
        </b>
      </p>
    </div>
    <div className="col-md-1"></div>
    <div
      style={{
        height: "155px",
        alignItems: "left",
        borderRadius: "20px",
        width: "16%",
        boxShadow: "0px 10px 20px #0000002B",
        margin: "2%",
      }}
      className="card col-md-3  col-lg-3 p-2"
    >
      <br />
      <h1 style={{ color: "#00A2C8" }}>
        <b>75K</b>
      </h1>
      <p className="card-mid-text">
        <b>
          Current<br></br>Openings
        </b>
      </p>
    </div>
    <div className="col-md-1"></div>
    <div
      style={{
        height: "150px",
        borderRadius: "20px",
        width: "16%",
        boxShadow: "0px 10px 20px #0000002B",
        margin: "2%",
      }}
      className="card col-md-3 col-lg-3 p-2"
    >
      <br />
      <div>
        {" "}
        <h1 style={{ color: "#00A2C8", width: "100%" }}>
          <b>₹15L</b>
        </h1>
        <p className="card-mid-text">
          <b>
            Avg.<br></br>Salary/year
          </b>
        </p>
      </div>
    </div>
  </div>
	</div>  */
}

import { useState, useEffect } from "react";
import CategorySection from "./component/CategorySection";
import configAxios from "../axios.config";
import DifficultyNav from "./component/DifficultyNav";
import DifficultySection from "./component/DifficultySection";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import useDashboardContext from "./context/useDashboardContext";
import CompaniesSection from "./component/CompaniesSection";

function Difficulty() {
  const { id } = useParams();
  const [question, setQuestion] = useState([]);
  const [easyquestions, setEasyquestions] = useState([]);
  const [mediumquestions, setMediumQuestions] = useState([]);
  const [hardquestions, setHardQuestions] = useState([]);
  const [categoryQuestions, setCategoryQuestions] = useState([]);
  const [companyQuestions, setCompanyQuestions] = useState([]);
  const searchString = useSelector(({ dashboard }) => dashboard.stringSearch);

  // Code to populate the side navbar
  const { setCourseId } = useDashboardContext();
  useEffect(() => {
    setCourseId(id);
  }, [id, setCourseId]);

  useEffect(() => {
    configAxios(localStorage.getItem("token"))
      .get("/GetAllQuestion")
      .then(({ data }) => {
        setQuestion(data.questions);
      })
      .catch((err) => console.log(err));
  }, []);


  useEffect(() => {
    if (searchString === "") {
      setEasyquestions(
        question.filter((value) => {
          return value.questionWeigh <= 2;
        })
      );
      setMediumQuestions(
        question.filter((value) => {
          return value.questionWeigh === 3;
        })
      );
      setHardQuestions(
        question.filter((value) => {
          return value.questionWeigh >= 4;
        })
      );
      setCategoryQuestions(
        question.filter((value) => value.category && value.category !== "")
      );
    } else {
      let regexPattern = new RegExp(searchString, "i");

      setEasyquestions((prev) => {
        let temp_array = prev.filter((val) => {
          return regexPattern.test(val.category) || regexPattern.test(val.name);
        });

        return [...temp_array];
      });

      setMediumQuestions((prev) => {
        let temp_array = prev.filter((val) => {
          return regexPattern.test(val.category) || regexPattern.test(val.name);
        });

        return [...temp_array];
      });

      setHardQuestions((prev) => {
        let temp_array = prev.filter((val) => {
          return regexPattern.test(val.category) || regexPattern.test(val.name);
        });

        return [...temp_array];
      });

      setCategoryQuestions(() => {
        let temp_array = question.filter((val) => {
          return (
            regexPattern.test(val.category) ||
            regexPattern.test(val.name) ||
            regexPattern.test(val.company.toString())
          );
        });

        return [...temp_array];
      });
    }
  }, [searchString, question]);
  useEffect(() => {
    setEasyquestions(
      question.filter((value) => {
        return value.questionWeigh <= 2;
      })
    );
    setMediumQuestions(
      question.filter((value) => {
        return value.questionWeigh === 3;
      })
    );
    setHardQuestions(
      question.filter((value) => {
        return value.questionWeigh >= 4;
      })
    );
    setCategoryQuestions(
      question.filter((value) => value.category && value.category !== "")
    );
    setCompanyQuestions(question);
  }, [question]);

  const [navTab, setNavTab] = useState(0);

  // set active tab
  const setActiveTab = (numb) => {
    setNavTab(numb);
  };


  const ActiveTab = () => {
    if (navTab === 0) {
      return (
        <DifficultySection
          easyquestions={easyquestions}
          mediumquestions={mediumquestions}
          hardquestions={hardquestions}
        />
      );
    } else if (navTab === 1) {
      return <CategorySection category={categoryQuestions} />;
    } else if (navTab === 2) {
      return <CompaniesSection category={companyQuestions} />;
    }
  };
  return (
    <div id="dashboardDiffculty">
      <div className="row">
        <div className="col-xl-10">
          <DifficultyNav tab={navTab} func={setActiveTab} />
          <ActiveTab />
        </div>
      </div>
    </div>
  );
}

export default Difficulty;

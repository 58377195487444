import { useState, useEffect, useRef } from "react";
import "../component/styled/newcompStyle/LearningDetails.scss";
import Accordion from "react-bootstrap/Accordion";
import { useParams, useHistory } from "react-router-dom";
import configAxios from "../../axios.config";
import useDashboardContext from "../context/useDashboardContext";
import BackIcon from "../../Assets/img/backButton.svg";
import { changeVideoId } from "../../app/newDashboard/actions/action";
import { useDispatch } from "react-redux";

function checkDemoLink(id) {
  return id === "62fb1b7397d9496abc0a04a3";
}
function LearningDetails({ topic, videos, name }) {
  const history = useHistory();
  const { id } = useParams();
  const { setTab } = useDashboardContext();
  const dispatch = useDispatch();
  const topicName = useRef(
    history.location.search?.split("?topic=")[1]
      ? decodeURIComponent(history.location.search?.split("?topic=")[1])
      : undefined
  );
  const [content, setContent] = useState([]);
  const [heading, setHeading] = useState([]);

  const backBtn = () => {
    dispatch(changeVideoId(null));
    history.push(`/dashboard/${id}`);
    setTab(2);
  };

  // useEffect(() => {
  //   setTab(2);
  // }, [setTab]);

  // new learning data
  useEffect(() => {
    const controller = new AbortController();
    configAxios(localStorage.getItem("token"))
      .get(`/get-learning-data/${id}`, {
        signal: controller.signal,
      })
      .then(({ data }) => {
        const [temp_content] = data.data.learning.filter(
          (val) => val.heading.toLowerCase() === topicName.current.toLowerCase()
        );
        if (temp_content) setContent(temp_content.content);

        const [temp_headning] = data.data.learning.filter(
          (val) => val.heading.toLowerCase() === topicName.current.toLowerCase()
        );
        if (temp_content) setHeading(temp_headning.heading);
      })
      .catch((err) => {
        console.log(err);
      });
    return () => controller.abort();
  }, [id]);

  return (
    <>
      <div className="learncardHeader">
        <img
          src={BackIcon}
          alt="back"
          onClick={backBtn}
          style={{ cursor: "pointer", margin: "0 20px 1px 0" }}
        />
        {name} / {heading.toString().toUpperCase()}
      </div>
      <Accordion className="Learningaccordion">
        {content.map((val, index) => {
          return (
            <Accordion.Item
              eventKey={`${index}`}
              key={val._id}
              className="item"
            >
              <Accordion.Header className="accordinHeader">
                <div className="detailsTitle">{val.name}</div>
                <div className="detailsDescreption">{val.description}</div>
              </Accordion.Header>
              <Accordion.Body style={{ padding: "2rem 1.25rem" }}>
                <div className="detailBody">
                  {!checkDemoLink(id) && (
                    <div className="detailBtn">
                      <div className="detailItem">
                        <img
                          className="detailImg"
                          src="https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1669904748904_vt1.png"
                          alt=""
                        />
                      </div>{" "}
                      <div className="detailText">
                        <a
                          className="detailTextAnchor"
                          href={val.foundation}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Foundation
                        </a>
                      </div>
                    </div>
                  )}
                  {!checkDemoLink(id) && (
                    <div className="detailBtn">
                      {" "}
                      <span className="detailItem">
                        <img
                          className="detailImg"
                          src="https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1669904748904_vt1.png"
                          alt=""
                        />
                      </span>{" "}
                      <a
                        className="detailTextAnchor"
                        href={val.prodoc}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Pro Doc
                      </a>
                    </div>
                  )}
                  {val.assignmentLink && (
                    <div
                      className="detailBtn"
                      onClick={() => window.open(val.assignmentLink, "_blank")}
                    >
                      <span className="detailItem">
                        <img
                          className="detailImg"
                          src="https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1669904787726_vt2.png                  "
                          alt=""
                        />
                      </span>{" "}
                      <span
                        className="detailTextAnchor"
                        // href={val.assignment}
                        // target="_blank"
                        // rel="noreferrer"
                      >
                        {checkDemoLink(id)
                          ? "Practise Assignment"
                          : "Assignment"}
                      </span>
                    </div>
                  )}
                  {checkDemoLink(id) && (
                    <div className="detailBtn">
                      <div className="detailItem">
                        <img
                          className="detailImg"
                          src="https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1669904748904_vt1.png"
                          alt=""
                        />
                      </div>{" "}
                      <div className="detailText">
                        <a
                          className="detailTextAnchor"
                          href={val.foundation}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Tips and Trciks
                        </a>
                      </div>
                    </div>
                  )}

                  {val.testLink && (
                    <div className="detailBtn">
                      <span className="detailItem">
                        <img
                          className="detailImg"
                          src="https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1669904812941_vt3.png                  "
                          alt=""
                        />
                      </span>{" "}
                      <a
                        className="detailTextAnchor"
                        href={val.testLink}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Mock Tests
                      </a>
                    </div>
                  )}
                </div>
              </Accordion.Body>
            </Accordion.Item>
          );
        })}
      </Accordion>
    </>
  );
}

export default LearningDetails;

import React, { useContext, useState, useEffect, useLayoutEffect } from 'react'

import Main from '../componentsofcourse/Main'
//import Middle1 from "../componentsofcourse/Middle1"
import Middle from '../componentsofcourse/middle'
import '../componentsofcourse/Main.css'
//import Graph from '../componentsofcourse/Graph';
import MeetPartners from '../componentsofcourse/MeetPartners'
//import Questions from '../componentsofcourse/Questions'
import Process from '../componentsofcourse/Process';
import Highlights from "../componentsofcourse/Highlights";
import Whyweb from '../componentsofcourse/whywebdev'
import ConfusedForm from '../componentsofcourse/ConfusedForm'
import PricingTable from '../componentsofcourse/PricingTable'
import CpCompany from '../componentsofcourse/CpCompany'
import { ModalContext } from '../Context/ModalContext'
import MoreQueries from '../components/MoreQueries'
import Internship from '../components/Internship'
import webImg from "../Assets/img/course/course_main.png";
import IconStar from "../Assets/img/course/icon_star.png";
import IconUsers from "../Assets/img/course/icon_users.png";
import IconCalendar from "../Assets/img/course/icon_calender.png";
import IconHands from "../Assets/img/course/icon_hands.png";

import NodeImg from "../Assets/img/course/node_icon.png";
import MongoImg from "../Assets/img/course/mongo_icon.png";
import GithubImg from "../Assets/img/course/github_icon.png";
import AwsImg from "../Assets/img/course/aws_icon.png";
import ReactImg from "../Assets/img/course/react_icon.png";
const Course = () => {
    let { open, update_status } = useContext(ModalContext)
    const [modal, setModal] = useState(false)

    useEffect(() => {
        update_status(!open)
        setModal()
    }, [modal])
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    const parameters = {
        header: (
            <div>
                Learn Web
                <br />
        Development
            </div>
        ),
        matter: (
            <div>
                Learn full stack HTML/CSS, MySQL,
                <br />
        PHP development and build an e-commerce website
                <br />
        from scratch!
            </div>
        ),
        mainImage: webImg,

        icons: {
            IconStar,
            IconUsers,
            IconCalendar,
            IconHands,
        },
        techImg: {
            0: NodeImg,
            1: MongoImg,
            2: GithubImg,
            3: AwsImg,
            4: ReactImg,
        },
        iconText: {
            0: "4.1",
            1: "5362",
            2: "3 Weeks",
            3: "100% internship guarantee",
        },
    };

    const highlightsParameters = {
        text: {
            0: (
                <div>
                    Learn at the comfort of <br /> your HOME
                </div>
            ),
            1: (
                <div>
                    Learn from <br /> Industry Experts
                </div>
            ),
            2: (
                <div>
                    {" "}
          Beginner
                    <br /> friendly
                </div>
            ),
            3: (
                <div>
                    {" "}
          Live online classes
                    <br /> to boost productivity
                </div>
            ),
            4: (
                <div>
                    {" "}
          Solve 50+
                    <br /> Questions
                </div>
            ),
            5: (
                <div>
                    Doubt
                    <br /> Assistance
                </div>
            ),
        },
        mobText: {
            0: "Learn at the comfort of <br /> your HOME",
            1: "Learn from Industry Experts",
            2: "Beginner Friendly",
            3: "Live online classes to boost productivity",
            4: "Solve 50+ Questions",
            5: "Doubt Assistance",
        },
    };

    return (

        <div style={{ overflowX: 'hidden' }}>
            <Main parameters={parameters} />
            <Highlights parameters={highlightsParameters} />
            <Internship />
            <Middle />
            <PricingTable />
            <ConfusedForm />
            <Whyweb />
            <CpCompany />
            <Process />
            <MoreQueries />
            {/*<Questions />*/}
        </div>

    )
}
export default Course;
import React, { useState, useEffect, useContext } from "react";
import { NavLink } from "react-router-dom";
import { BASE_URL } from "../env";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import style from "../Assets/css/newmasterclass.module.css";
import styles from "../Assets/css/tcs.module.css";
import Banner from "../Assets/img/new-master/head-banner.png";
import useMedia from "../componentsofcourse/useMedia";
import SM from "../Imgsofcourse/images/Image 1.png";
import Testimonials from "../components/TestimonialsVersion2";
import MasterLinkedIn from "../components/MasterLinkedIn";
import { useParams, Link } from "react-router-dom";
import { LastSection } from "./NewMasterClass";
import moment from "moment";
import MasterLinkedInlive from "../components/MasterLinkedInlive";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { AuthContext } from "../Context/AuthContext";
import BannerComp from "../components/BannerComp";
function NewMasterClassParticular() {
	const matches = useMedia("(min-width : 768px )");
	const { email } = useContext(AuthContext);
	const [allowed, setallowed] = useState(false);
	const [canRegister, setCanRegister] = useState(0);
	const [StudentData, setStudentData] = useState({});
	useEffect(() => {
		fetch(BASE_URL + "/getProfile", {
			headers: { authorization: "Bearer " + localStorage.getItem("token") },
		})
			.then((res) => res.json())
			.then((data) => {
				if (data.error) {
					// console.log(data.error);
				} else {
					setStudentData(data);
				}
			});
	}, []);
	useEffect(() => {
		// console.log(StudentData?.student?.masterclasses);
		if (StudentData?.student?.masterclasses.length) {
			setCanRegister(StudentData?.student?.masterclasses.length);
			for (let key in StudentData.student.masterclasses) {
				if (StudentData.student.masterclasses[key]._id == id) {
					setallowed(true);
					return;
				}
				setallowed(false);
			}
		}



	}, [StudentData]);



	const { id } = useParams();
	const [webinarData, setWebinarData] = useState([]);
	useEffect(() => {
		fetch(BASE_URL + "/getOfflineMasterClass/" + id, {
			method: "GET",
			headers: { "Content-Type": "application/json" },
		})
			.then((res) => res.json())
			.then((data) => {
				// console.log("WEbinar Data", data);
				setWebinarData(data);
			});
	}, []);
	function isNumber(str) {
		var pattern = /^\d+$/;
		return pattern.test(str);
	}

	return (
		<>
			{/* Navbar */}
			{/* <Navbar /> */}

			{/* Header */}
			<header className={style.headSection}>
				<div className='container'>
					<div className='row'>
						<div className='col-md-6 my-4 py-5 d-flex justify-content-center flex-column'>
							<h2 className={style.headHeading}>Master Class</h2>
							<p className={style.headDescription}>
								This career acceleration videos will provide you an
								unprecedented opportunity to learn and become job ready. So, ace
								your next interview by mastering multiple trending topics in
								various industries.
							</p>
						</div>
						<div className='col-md-6 d-flex justify-content-center align-items-end mb-0'>
							<img
								src={Banner}
								className={`img-fluid ${style.headBanner}`}
								alt='header banner'
							/>
						</div>
					</div>
				</div>
			</header>

			{/* Third Section*/}
			{isNumber(id) ? (
				<MasterLinkedInlive allowed={allowed} canRegister={canRegister} />
			) : (
				<MasterLinkedIn allowed={allowed} canRegister={canRegister} />
			)}
			{/* Fourth Section */}
			<div style={{ backgroundColor: "#5198fb41" }}>
				<section className='container my-5' style={{ paddingTop: "2rem" }}>
					<div className='row'>
						<div className='col-xl-6 d-flex align-items-center'>
							<h5 className={style.weekHeading} style={{ fontSize: "1.2rem" }}>
								More Masterclass Videos
							</h5>
							<span
								className={style.weekBadge}
								style={{
									width: "8rem",
									padding: " 0.4rem 0.6rem ",
									textAlign: "center",
									textTransform: "none",
								}}>
								<NavLink
									to='/newmasterclass'
									style={{
										textDecoration: "none",
										color: "white",
										textAlign: "center",
										fontStyle: "normal",
										fontWeight: "600",
									}}>
									Explore all
								</NavLink>
							</span>
						</div>
						<div className='col-xl-6'></div>
					</div>
					<div className='row justify-content-around pb-5'>
						<Card />
					</div>
				</section>
			</div>

			{/* Banner */}
			<div style={{ height: "60px" }}></div>
			<BannerComp />
			<div style={{ height: "20px" }}></div>

			{/* Testimonial Section Start */}
			<h1 style={{ fontWeight: "700", textAlign: "center", marginTop: "5rem" }}>
				What Our Students Say!!
			</h1>
			<div className='' style={{ backgroundColor: "#185adb" }}>
				<br />
				<br />
				<div className='row' style={{ marginLeft: matches ? "12%" : "6%" }}>
					<div className='col-md-4 col-xs-12 col-12 col-12 justify-content-center pb-2'>
						<img
							src={SM}
							className='img-fluid'
							style={{ marginLeft: matches == false ? "3%" : null }}
						/>
					</div>
					<div
						style={{ textAlign: "left" }}
						className='col-md-6 col-xs-12 col-12 col-12'>
						<div>
							<h4 style={{ color: "white" }}>SARVESH MEHTANI</h4>
						</div>
						<div>
							<h5 style={{ color: "white" }}>IIT Bombay - Computer Science</h5>
						</div>
						<br />
						<div>
							<p style={{ color: "white" }}>
								Overall, I loved the fact that the whole process was tailored as
								per the individual's interests and goals which made it very
								effective in pointing out our areas of strength and the areas
								that we need to work on.
							</p>
						</div>

						<br />
						<br />
						<div>
							<button className={styles.sarveshbtn}>
								A.I.R 1, JEE ADVANCED 2017
							</button>
						</div>
					</div>
				</div>
			</div>
			<div style={{ overflowX: "hidden" }}>
				<Testimonials />
			</div>

			{/* Last Section */}
			<section className={style.lastSection}>
				<div className='container'>
					<div className='row'>
						<div className='col-12'>
							<h4 className={style.lastSectionHeading}>
								Enjoying MasterClass Videos? We have more in line for you
							</h4>
						</div>
					</div>
					<LastSection />
				</div>
			</section>

			{/* Footer */}
			<Footer />
		</>
	);
}

const Card = () => {
	const [webinarData, setWebinarData] = useState([]);
	useEffect(() => {
		fetch(BASE_URL + "/getAllOfflineMasterClass", {
			method: "GET",
			headers: { "Content-Type": "application/json" },
		})
			.then((res) => res.json())
			.then((data) => {
				// console.log("WEbinar Data", data.message);
				setWebinarData(data.message);
			});
	}, []);
	var settings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 2,
		slidesToScroll: 1,
		swipeToSlide: false,
		responsive: [
			{
				breakpoint: 576,
				settings: {
					slidesToShow: 1,
				},
			},
		],
	};
	return (
		<>
			<Slider {...settings}>
				{webinarData.map((item) => {
					return (
						<div className=''>
							<div className={style.boxWrapper}>
								<img src={item.image} className={style.boxWrapperHeader} />
								<div className={style.boxWrapperBody}>
									<h4 className={style.boxWrapperHeading}>{item.subject}</h4>
									<p className={style.boxWrapperDesc}>{item.description}</p>
								</div>
								<div className={style.boxWrapperFooter}>
									<div className='row mt-3 justify-content-between'>
										<div className='col-sm-8 col-6 mb-0 d-sm-flex'>
											<p className={style.schedule}>
												<i className='far fa-calendar-alt'></i>
												{moment(item.startTime).format("DD") +
													" " +
													moment(item.startTime).format("MMMM") +
													" " +
													moment(item.startTime).format("YYYY")}
											</p>
											<p className={`mx-sm-3 ${style.schedule}`}>
												<i className='far fa-clock'></i>
												{/* {moment(item.startTime).format("hh A") +
                          " - " +
                          moment(item.endTime).format("hh A")} */}
												{item.duration}
											</p>
										</div>
										<div className='col-sm-4 col-6 mb-0'>
											<Link
												to={`/newmasterclassparticular/` + item._id}
												className={`btn ${style.boxWrapperRegister}`}>
												Watch Now
											</Link>
											<div className={style.boxWrapperEnrolled}>
												{/* 5673 Enrolled */}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					);
				})}
			</Slider>
		</>
	);
};

export default NewMasterClassParticular;

import React, { useState } from "react";
import { Link } from "react-router-dom";
// import useMedia from "../componentsofcourse/useMedia";
//import '../Assets/css/dropdown.css'
// import dashboard_img from "../Assets/img/Home/dashboard.png";
import styles from "../Assets/css/Dropdown.module.css";
function Submenu(props) {
  const { token, admin } = props;
  return (
    <>
      <div
        className={styles.dropdowns + " mt-2"}
        style={{
          position: "absolute",
          boxShadow:
            "0px 2px 5px rgba(30, 30, 40, 0.7), 5px 5px 10px rgba(30, 30, 40, 0.5)",
          right: props.value === "logged in" && "12.5%",
          top: props.value === "logged in" ? "80%" : "80%",
          borderRadius: "5px",
        }}
      >
        <ul
          className={
            styles.items2 + " " + styles.round + " container-fluid text-center "
          }
          style={{ paddingLeft: "7px", paddingRight: "7px", listStyle: "none" }}
        >
          {/* {props.value === "logged in" && (
            <div className={"row " + styles.back}>
              <Link
                className="col-lg-12"
                style={{
                  height: "50px",
                  padding: "0px 15px",
                  textAlign: "left",
                  width: "100%",
                }}
                to={`/profile`}
              >
                <li
                  className={styles.items2}
                  style={{
                    fontSize: "0.9rem",
                    width: "100px",
                    FontFamily: "sans-serif !important",
                  }}
                >
                  Classroom{" "}
                </li>
              </Link>
            </div>
          )} */}
          {admin === true && (
            <div className={"row " + styles.back}>
              <Link
                className="col-lg-12"
                style={{
                  height: "50px",
                  padding: "0px 15px",
                  textAlign: "left",
                  width: "100%",
                }}
                to="/addblog"
              >
                <li
                  className={styles.items2}
                  style={{
                    fontSize: "0.9rem",
                    width: "100%",
                    FontFamily: "sans-serif !important",
                  }}
                >
                  <i class="fas fa-blog"></i> Add Blog
                </li>
              </Link>
            </div>
          )}
          {props.value != "logged in" ? null : (
            <div
              className={"row " + styles.back}
              onClick={() => {
                props.LogoutMethd();
              }}
            >
              <Link
                className="col-lg-12"
                to="/placementcourse"
                onClick={props.logout}
                style={{
                  height: "50px",
                  padding: "0px 15px",
                  textAlign: "left",
                  width: "100%",
                }}
              >
                <li
                  style={{
                    fontSize: "0.9rem",
                    width: "100px",
                    FontFamily: "sans-serif !important",
                  }}
                  className={styles.items2}
                >
                  <i class="fas fa-sign-out-alt"></i> Log out
                </li>
              </Link>
            </div>
          )}
          {props.value !== "logged in" ? (
            <>
              <div className={"row " + styles.back}>
                <Link
                  className="col-lg-12"
                  style={{
                    height: "50px",
                    padding: "0px 15px",
                    textAlign: "left",
                    width: "100%",
                  }}
                  to="/target-service-company"
                >
                  <li
                    style={{
                      fontSize: "0.8rem",
                      width: "100%",
                      FontFamily: "sans-serif !important",
                      fontWeight: "200",
                    }}
                    className={styles.items2}
                  >
                    Target Service Companies
                  </li>
                </Link>
              </div>
              <div className={"row " + styles.back}>
                <Link
                  className="col-lg-12"
                  style={{
                    height: "50px",
                    padding: "0px 15px",
                    textAlign: "left",
                    width: "100%",
                  }}
                  to="/target-product-company"
                >
                  <li
                    style={{
                      fontSize: "0.8rem",
                      width: "100%",
                      FontFamily: "sans-serif !important",
                      fontWeight: "200",
                    }}
                    className={styles.items2}
                  >
                    Target Product Companies
                  </li>
                </Link>
              </div>
              <div className={"row " + styles.back}>
                <Link
                  className="col-lg-12"
                  style={{
                    height: "50px",
                    padding: "0px 15px",
                    textAlign: "left",
                    width: "100%",
                  }}
                  to="/tcs"
                >
                  <li
                    style={{
                      fontSize: "0.8rem",
                      width: "100%",
                      FontFamily: "sans-serif !important",
                      fontWeight: "200",
                    }}
                    className={styles.items2}
                  >
                    TCS NQT Preparation Course
                  </li>
                </Link>
              </div>
              <div className={"row " + styles.back}>
                <Link
                  to="/interview-preparation-bundle"
                  className="col-lg-12"
                  style={{
                    height: "50px",
                    padding: "0px 15px",
                    textAlign: "left",
                    width: "100%",
                  }}
                >
                  {" "}
                  <li
                    style={{
                      fontSize: "0.8rem",
                      width: "100%",
                      FontFamily: "sans-serif !important",
                      fontWeight: "200",
                    }}
                    className={styles.items2}
                  >
                    interview preparation course
                  </li>
                </Link>
              </div>
              <div className={"row " + styles.back}>
                <Link
                  className="col-lg-12"
                  style={{
                    height: "50px",
                    padding: "0px 15px",
                    textAlign: "left",
                    width: "100%",
                  }}
                  to="/quant_finance"
                >
                  <li
                    style={{
                      fontSize: "0.8rem",
                      width: "100%",
                      FontFamily: "sans-serif !important",
                      fontWeight: "200",
                    }}
                    className={styles.items2}
                  >
                    Quant Finance
                  </li>
                </Link>
              </div>
              <div className={"row " + styles.back}>
                <Link
                  className="col-lg-12"
                  style={{
                    height: "50px",
                    padding: "0px 15px",
                    textAlign: "left",
                    width: "100%",
                  }}
                  to="/fasttrackcourse"
                >
                  <li
                    style={{
                      fontSize: "0.8rem",
                      width: "100%",
                      FontFamily: "sans-serif !important",
                      fontWeight: "200",
                    }}
                    className={styles.items2}
                  >
                    Crash Courses
                  </li>
                </Link>
              </div>
              <div className={"row " + styles.back}>
                <Link
                  className="col-lg-12"
                  style={{
                    height: "50px",
                    padding: "0px 15px",
                    textAlign: "left",
                    width: "100%",
                  }}
                  to="/newcoursepage"
                >
                  <li
                    style={{
                      fontSize: "0.8rem",
                      width: "100%",
                      FontFamily: "sans-serif !important",
                      fontWeight: "200",
                    }}
                    className={styles.items2}
                  >
                    Get Placement Ready
                  </li>
                </Link>
              </div>
            </>
          ) : null}
        </ul>
      </div>
    </>
  );
}

function Submenu2(props) {
  const { token, admin } = props;
  return (
    <>
      <div
        className={styles.dropdowns + " mt-2"}
        style={{
          position: "absolute",
          boxShadow:
            "0px 2px 5px rgba(30, 30, 40, 0.7), 5px 5px 10px rgba(30, 30, 40, 0.5)",
          right: props.value === "logged in" && "12.5%",
          top: "80%",
          borderRadius: "5px",
          minWidth: "10rem",
        }}
      >
        <ul
          className={
            styles.items2 + " " + styles.round + " container-fluid text-center "
          }
          style={{ paddingLeft: "7px", paddingRight: "7px", listStyle: "none" }}
        >
          {/* <div className={"row " + styles.back}>
            <Link
              className="col-lg-12"
              style={{
                height: "50px",
                padding: "0px 15px",
                textAlign: "left",
                width: "100%",
              }}
              to="/tcs"
            >
              <li
                style={{
                  fontSize: "0.8rem",
                  width: "100%",
                  FontFamily: "sans-serif !important",
                  fontWeight: "200",
                }}
                className={styles.items2}
              >
                TCS NQT
              </li>
            </Link>
          </div> */}
          <div className={"row " + styles.back}>
            <Link
              className="col-lg-12"
              style={{
                height: "50px",
                padding: "0px 15px",
                textAlign: "left",
                width: "100%",
              }}
              to={{
                pathname: "/masterclass-standalone/6145c73b9467cb8afc652b0f",
                state: { id: "" },
              }}
            >
              <li className={styles.items2} style={{ textTransform: "none" }}>
                MasterClass
              </li>
            </Link>
          </div>
          <div className={"row " + styles.back}>
            <Link
              className="col-lg-12"
              style={{
                height: "50px",
                padding: "0px 15px",
                textAlign: "left",
                width: "100%",
              }}
              to="/iitbombay"
            >
              <li className={styles.items2} style={{ textTransform: "none" }}>
                Training @ IIT Bombay
              </li>
            </Link>
          </div>
          <div className={"row " + styles.back}>
            <Link
              className="col-lg-12"
              style={{
                height: "50px",
                padding: "0px 15px",
                textAlign: "left",
                width: "100%",
              }}
              to="/company-specific/61c598567a4a44b80a3e4cb9"
            >
              <li className={styles.items2} style={{ textTransform: "none" }}>
                Wipro
              </li>
            </Link>
          </div>
          <div className={"row " + styles.back}>
            <Link
              className="col-lg-12"
              style={{
                height: "50px",
                padding: "0px 15px",
                textAlign: "left",
                width: "100%",
              }}
              to="/company-specific/616690234d0ae87be0c5c062"
            >
              <li className={styles.items2} style={{ textTransform: "none" }}>
                Capgemini
              </li>
            </Link>
          </div>
          <div className={"row " + styles.back}>
            <Link
              className="col-lg-12"
              style={{
                height: "50px",
                padding: "0px 15px",
                textAlign: "left",
                width: "100%",
              }}
              to="/company-specific/6179424a8b184861a7b937eb"
            >
              <li className={styles.items2} style={{ textTransform: "none" }}>
                Infosys
              </li>
            </Link>
          </div>
        </ul>
      </div>
    </>
  );
}

function Submenu3(props) {
  const { token, admin, iitbs } = props;
  console.log(iitbs, "sub");
  return (
    <>
      <div
        className={styles.dropdowns + " mt-2"}
        style={{
          position: "absolute",
          boxShadow:
            "0px 2px 5px rgba(30, 30, 40, 0.7), 5px 5px 10px rgba(30, 30, 40, 0.5)",
          right: props.value === "logged in" && "12.5%",
          top: "80%",
          borderRadius: "5px",
          minWidth: "10rem",
        }}
      >
        <ul
          className={
            styles.items2 + " " + styles.round + " container-fluid text-center "
          }
          style={{ paddingLeft: "7px", paddingRight: "7px", listStyle: "none" }}
        >
          <div className={"row " + styles.back}>
            <Link
              className="col-lg-12"
              style={{
                height: "50px",
                padding: "0px 15px",
                textAlign: "left",
                width: "100%",
              }}
              to="/business"
            >
              <li
                style={{
                  fontSize: "0.8rem",
                  width: "100%",
                  FontFamily: "sans-serif !important",
                  fontWeight: "200",
                }}
                className={styles.items2}
              >
                business
              </li>
            </Link>
          </div>
          {iitbs === true ? (
            <div className={"row " + styles.back}>
              <Link
                className="col-lg-12"
                style={{
                  height: "50px",
                  padding: "0px 15px",
                  textAlign: "left",
                  width: "100%",
                }}
                to={{
                  pathname: "/mycourse/615241263e86f30705c35a2e/",
                }}
              >
                <li className={styles.items2} style={{ textTransform: "none" }}>
                  iitbombay course
                </li>
              </Link>
            </div>
          ) : (
            ""
          )}
        </ul>
      </div>
    </>
  );
}

export const DropdownComp = (props) => {
  const { token, admin } = props;

  return (
    <>
      <Submenu
        admin={admin}
        token={token}
        value={props.props}
        name={props.name}
        LogoutMethd={props.LogoutMethd}
        user_id={props.user_id}
      />
    </>
  );
};

export const DropdownComp2 = (props) => {
  const { token, admin } = props;

  return (
    <>
      <Submenu2
        admin={admin}
        token={token}
        value={props.props}
        name={props.name}
        LogoutMethd={props.LogoutMethd}
        user_id={props.user_id}
      />
    </>
  );
};
export const DropdownComp3 = (props) => {
  const { token, admin, iitbs } = props;

  return (
    <>
      <Submenu3
        iitbs={iitbs}
        admin={admin}
        token={token}
        value={props.props}
        name={props.name}
        LogoutMethd={props.LogoutMethd}
        user_id={props.user_id}
      />
    </>
  );
};

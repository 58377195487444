import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import LoginTestimonial from "../components/LoginTestimonial";
import "../Assets/scss/Register.scss";
import { BASE_URL, BASE_URL_MICRO } from "../env";
import axios from "axios";

const PostLogin = ({ hidenavbar }) => {
  const location = useLocation();
  const [spinner, setSpinner] = useState(false);
  const { email } = location.state;
  const [data, setData] = useState({
    fullname: "",
    phone: "",
    college: "",
    graduation: "",
    password: "",
  });

  useEffect(() => {
    hidenavbar(true);
    return () => {
      hidenavbar(false);
    };
  }, []);

  const inputEvent = (event) => {
    const { name, value } = event.target;
    setData({ ...data, [name]: value });
  };

  const submitEvent = async (e) => {
    e.preventDefault();
    setSpinner(true);
    try {
      await axios({
        method: "Post",
        url: `${BASE_URL}/secondformnewsignup`,
        data: {
          fullname: data.fullname,
          phone: data.phone,
          college: data.college,
          graduation: data.graduation,
          password: data.password,
          email: email,
        },
        headers: {
          "Content-type": "application/json",
        },
      });

      const res = await axios({
        method: "post",
        url: `${BASE_URL_MICRO}/signinnew`,
        data: {
          email: email,
          password: data.password,
        },
        headers: {
          "Content-type": "application/json",
        },
      });
      localStorage.setItem("token", res.data.token);
      if(localStorage.getItem('userlocation'))
      {
        window.location.href = localStorage.getItem('userlocation');
        localStorage.removeItem('userlocation');
      }
      else
      window.location.href = "/profile";
      setSpinner(false);
    } catch (error) {
      console.log(error);
    }

    setData({
      fullname: "",
      phone: "",
      college: "",
      graduation: "",
      password: "",
    });
  };

  return (
    <>
      <div className="loginForm">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 mb-0 p-0 order-lg-1 order-2">
              <LoginTestimonial />
            </div>
            <div className="col-lg-6 mb-0 p-0 order-lg-2 order-1">
              <div className="form">
                <div className="title">
                  Cantilevers Labs <br />
                  <span>Welcome you to the top growing platform</span>
                </div>
                <div className="mid-Form">
                  <form onSubmit={submitEvent}>
                    <div class="form-floating">
                      <input
                        name="fullname"
                        value={data.fullname}
                        onChange={inputEvent}
                        type="text"
                        class="form-control"
                        id="InputName"
                        placeholder="Cantilever Labs"
                        required
                      />
                      <label for="InputName">Enter your full name</label>
                    </div>
                    <div class="form-floating">
                      <input
                        name="phone"
                        value={data.phone}
                        onChange={inputEvent}
                        type="tel"
                        class="form-control"
                        id="InputNumber"
                        placeholder="00000 00000"
                        required
                      />
                      <label for="InputNumber">Enter your phone number</label>
                    </div>
                    <div class="form-floating">
                      <input
                        name="college"
                        value={data.college}
                        onChange={inputEvent}
                        type="text"
                        class="form-control"
                        id="InputCollege"
                        placeholder="IIT"
                        required
                      />
                      <label for="InputCollege">Enter your college name</label>
                    </div>
                    <div class="form-floating">
                      <input
                        name="graduation"
                        value={data.graduation}
                        onChange={inputEvent}
                        type="text"
                        class="form-control"
                        id="InputGraduation"
                        placeholder="2022"
                        required
                      />
                      <label for="InputGraduation">
                        Enter your graduation year
                      </label>
                    </div>
                    <div class="form-floating">
                      <input
                        name="password"
                        value={data.password}
                        onChange={inputEvent}
                        type="password"
                        class="form-control"
                        id="InputPassword"
                        placeholder="passowrd"
                        required
                      />
                      <label for="InputPassword">Enter your password</label>
                    </div>
                    <button type="submit" className="submitBtn">
                      {spinner ? (
                        <div className="spinner-border spinner-border-sm"></div>
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PostLogin;

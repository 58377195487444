import React, { 
  useContext, 
  useEffect, 
  useState
} from "react";
import { useHistory, useParams } from "react-router-dom";
import { Prompt } from "react-router-dom";
import { useTimer } from "react-timer-hook";
import { BASE_URL } from "../../env";
import "../../Assets/css/exam.css";
import { AnimatePresence, motion } from "framer-motion";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { AuthContext } from "../../Context/AuthContext";
import moment from "moment";
import NewHybridCompilerCoding from "./NewHybridCompilerCoding";
import { useLocation } from "react-router-dom";
import configAxios from "../../axios.config";
import { useSelector, useDispatch } from "react-redux";
import { changeSolvedIndices } from "../../app/hybridExam/actions/action";

const Exam = ({ expiryTimestamp, hidenavbar }) => {
  const [timing, setTiming] = useState(1);
  const [allData, setallData] = useState("");
  // const [showExamCompiler, setshowExamCompiler] = useState(false);
  const [showQuestions, setshowQuestions] = useState(true);
  const [showPopup, setshowPopup] = useState(false);
  const selectLanguage = useSelector(({ hybridCompiler }) => hybridCompiler.language);
  // const [currQuesId, setcurrQuesId] = useState("");
  const [prompt, setprompt] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [user_id, set_Id] = useState("");
  let { userid } = useContext(AuthContext);

  const location = useLocation();
  const dispatch = useDispatch();

  let id = location?.state?.id;
  const [code, setcode] = useState("");
  const [scoringArray, setscoringArray] = useState([]);
  // const [timeSpent, settimeSpent] = useState("");
  const [questionsolved, setquestionsolved] = useState([]);
  const history = useHistory();
  const [_id, setchoosenQuestion] = useState("");

  const { time, seconds, minutes, hours, restart } = useTimer({
    expiryTimestamp,
    onExpire: () => {
      FinishExam();
    },
  });
  useEffect(() => {
    hidenavbar(true);
    return () => {
      hidenavbar(false);
    };
  }, []);

  /**
   * * Checks if the user is logged in
   */
  useEffect(() => {
    configAxios(localStorage.getItem("token")).get('/getProfile')
      .then(({ data }) => {
        setName(data?.user?.firstName + " " + data?.user?.lastName);
        setEmail(data?.user?.email);

        set_Id(data?.user?._id);
        localStorage.setItem("emailID", data?.user?.email);
      })
      .catch(err => {
        console.error(err.message);
        history.push("/signup?ref=codingexamination");
      })
  }, [history]);

  useEffect(() => {
    window.addEventListener("beforeunload", alertUser);
    window.addEventListener("unload", handleEndConcert);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
      window.removeEventListener("unload", handleEndConcert);
      handleEndConcert();
    };
  }, []);

  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
    setprompt(true);
  };

  const handleEndConcert = async (e) => {};


  function FinishExam() {
    var travelTime_new = moment().unix();

    const newtimer = localStorage.getItem("timershybrid" + id) - travelTime_new;
    localStorage.removeItem("timer" + id);

    let intermediatevalue = timing * 60;

    let timeconsumed = intermediatevalue - newtimer;

    let newtimeconsumed = timeconsumed.toString();

    const submitData = {
      email: localStorage.getItem("emailID"),
      examId: id,
      scoring: scoringArray,
      timeTaken: newtimeconsumed,
      noOftabs: 0
    };

    // console.log(submitData)
    fetch(BASE_URL + "/uploadresult/code/" + id, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token"),
      },
      body: JSON.stringify({ coderesults: submitData }),
    })
      .then((response) => response.json())
      .then((result) => {
        localStorage.removeItem("timershybrid" + id);
        history.push(`/exam-result/${id}`);
      })
      .catch((error) => console.log("error", error));
  }

  function SetLevelOfExamination() {
    fetch(BASE_URL + "/setlevelofexam/" + id, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
      body: JSON.stringify({
        level: "5",
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log(data);
      });
  }

  function GetLevelOfExamination() {
    fetch(BASE_URL + "/getlevelofexam/" + id, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log(data);
        if (data.level == "0") {
          history.push("/");
        }
        if (data.level.level == "4") {
          SetLevelOfExamination();
        } else {
          // console.log(data);
          CheckExamLevelAndSendUser(data.level.level);
        }
        // console.log(data);
      });
  }

  function CheckExamLevelAndSendUser(level) {
    if (level === "0") {
    }
    if (level === "1") {
      return;
    }

    if (level === "2") {
      return;
    }

    if (level === "3") {
      return;
    }
    if (level === "4") {
      return;
    }
    if (level === "5") {
      return;
    }

    if (level === "6") {
      history.push(`/exam-result/${id}`);

    }
  }
  useEffect(() => {
    GetLevelOfExamination();
    configAxios(localStorage.getItem("token")).get(`/getparticularexamCode/${id}`)
      .then(({ data }) => {
        console.log("CourseId: ", data.exam.courseId);
        let courseId = data.exam.courseId;

        /**
         * * Hard coded courseIds for Chitkara and chitkara testing
         */
        switch(courseId){
          case "61740e05c6254ce7f05c8fa4":
            dispatch({type: "hybridcompiler/cpp"});
            break;

          case "62cd6fa3bf9d0766fb16ee6a":
            dispatch({type: "hybridcompiler/cpp"});
            break;

          case "62cd6f61bf9d0766fb16ee69":
            dispatch({type: "hybridcompiler/cpp"});
            break;

          case "62d27afc8b8da919e0ea2ee6":
            dispatch({type: "hybridcompiler/cpp"});
            break;

          case "62a9ba6a09d5cce99d2d4767":
            dispatch({type: "hybridcompiler/c"})
            break;

          case "62a9ba7e09d5cce99d2d4768":
            dispatch({type: "hybridcompiler/c"})
            break;

          case "62a9ba8709d5cce99d2d4769":
            dispatch({type: "hybridcompiler/c"})
            break;

          default:
        }
        setallData(data?.exam?.Codeexam);
        setTiming(data?.exam?.Codeexam?.timer);
        var travelTime = moment()
          .add(data.exam.Codeexam.timer, "minutes")
          .unix();
        if (localStorage.getItem("timershybrid" + id) === null) {
          localStorage.setItem("timershybrid" + id, travelTime);
        }

        setshowPopup(true);

      })
      .catch(err => {
        /**
         * TODO: Exception Handling
         */
        console.log(err);
        // alert("")
      })
  }, [id, history]);

  function startTimer() {
    if (localStorage.getItem("timershybrid" + id) === null) {
      const time = new Date();
      time.setSeconds(time.getSeconds() + 60 * timing);
      setchoosenQuestion(allData?.questions[0]?._id);
      restart(time);
      setchoosenQuestion(allData?.questions[0]?._id);
    } else {
      const time = new Date();
      var travelTime_new = moment().unix();

      const newtimer =
        localStorage.getItem("timershybrid" + id) - travelTime_new;

      const minutes_timer = newtimer / 60;

      // settimeSpent(Math.ceil(timing - minutes_timer));

      time.setSeconds(time.getSeconds() + 60 * minutes_timer);
      setchoosenQuestion(allData?.questions[0]?._id);
      restart(time);
      setchoosenQuestion(allData?.questions[0]?._id);
    }
  }

  function CheckSolvedorNot(id) {
    // if()
    for (var singleid of questionsolved) {
      if (singleid == id) {
        return true;
      }
    }
    return false;
  }

  function CheckExistorNot(id) {
    if (id == _id) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    if (minutes < 1 && seconds < 2 && seconds != 0) {
      // console.log("Done");
    }
  });

  function ChooseQuestion(id) {
    localStorage.setItem(_id + selectLanguage, code);

    // setsolvedIndices([]);
    dispatch(changeSolvedIndices([]));
    // setcurrQuesId(id);
    setchoosenQuestion(id);
  }

  return (
    <div className="maincontainer">
      <Prompt
        when={prompt}
        message={(action) => {
          alert("Are you sure you want to finish the exam?");
        }}
      />
      {showPopup && (
        <div
          style={{
            position: "absolute",
            zIndex: "20",
            backdropFilter: "brightness(0)",
            height: "200px",
            width: "500px",
            top: "50%",
            marginLeft: "-250px",
            marginTop: "-100px",
            left: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
          }}
        >
          Are you ready for the exam? &emsp;
          <button
            onClick={() => {
              setshowPopup(false);
              startTimer();
            }}
            className="btn btn-md btn-primary"
          >
            Yes, Let's Start
          </button>
        </div>
      )}

      <div
        className="d-flex flex-row flex-grow gx-1  "
        style={{ filter: showPopup === true && "blur(2px)" }}
      >
        <AnimatePresence>
          <motion.div
            className={
              showQuestions ? " w-50 trans-width  " : " trans-width w-10 "
            }
            style={{
              backgroundColor: "#02203c",
              paddingRight: "1px",
              marginBottom: "1px",
            }}
          >
            <motion.div
              className=""
              style={{ backgroundColor: "#001528", position: "relative" }}
            >
              <button
                style={{
                  float: "right",
                  top: "50vh",
                  border: "none",
                  background: "none",
                  fontSize: "25px",
                  marginLeft: "10px",
                  position: "relative",
                  // top: "50%",
                  left: "13px",
                  zIndex: "33",
                }}
                onClick={() => {
                  setshowQuestions(!showQuestions);
                }}
              >
                <i
                  className={
                    showQuestions
                      ? " animating-toggle-exam shadow fas fa-chevron-right text-white "
                      : "  animating-toggle-exam-closed shadow fas fa-chevron-right text-white  "
                  }
                ></i>
              </button>
            </motion.div>

            <div className="my-auto mt-4">
              {allData?.questions?.map((val, index) => {
                return (
                  <button
                    onClick={() => {
                      ChooseQuestion(val._id);
                    }}
                    className={
                      !CheckExistorNot(val._id)
                        ? " py-2 exam-question "
                        : " py-2 exam-question selected-exam-question "
                    }
                    style={{
                      backgroundColor: CheckSolvedorNot(val._id)
                        ? "#1c1c "
                        : null,
                      cursor: "pointer",
                      color: "white",
                      fontSize: "18px",
                    }}
                  >
                    {showQuestions ? (
                      <span>
                        {val.name}
                        {CheckSolvedorNot(val._id) && (
                          <div className="" style={{ float: "right" }}>
                            {" "}
                            <CheckCircleIcon />
                          </div>
                        )}
                      </span>
                    ) : (
                      <span>
                        {++index}{" "}
                        {CheckSolvedorNot(val._id) && (
                          <div className="" style={{ float: "right" }}>
                            {" "}
                            <CheckCircleIcon />
                          </div>
                        )}
                      </span>
                    )}
                  </button>
                );
              })}
            </div>
          </motion.div>
        </AnimatePresence>
        <div
          className={showQuestions ? "" : ""}
          style={{ marginBottom: "1px", paddingLeft: "0px" }}
        >
          <NewHybridCompilerCoding
            questionsolved={questionsolved}
            setquestionsolved={setquestionsolved}
            FinishExam={FinishExam}
            scoringArray={scoringArray}
            setscoringArray={setscoringArray}
            hours={hours}
            minutes={minutes}
            seconds={seconds}
            code={code}
            id={_id}
            selectLanguage={selectLanguage}
            setcode={setcode}
          />
        </div>
      </div>
    </div>
  );
};

export default Exam;

import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import { Container, Row, Col } from "react-bootstrap";
import useMedia from "../componentsofcourse/useMedia";
import ReactGA from "react-ga";
import style from "../Assets/css/iitbombay.module.css";
import { NavLink, Link, useLocation } from "react-router-dom";

const videoData = [
  {
    title: "Resume",
    text: "Resumes are HARD to write and HARDER to present! Irrespective of your industry, this course helps you write a professional resume, one that will catch the attention of hiring managers and makes sure you are interviewed.",
    videoLink:
      "https://player.vimeo.com/video/603693833?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;h=a3bd206232",
    src: `${"https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644305779810_resume_video.png"}`,
  },
  {
    title: "Guesstimation",
    text: "This free video is designed to redefine your approach and give the panel list a peek into your problem solving and thinking abilities.",
    videoLink:
      "https://player.vimeo.com/video/600822114?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;h=a3338269c0",
    src: `${"https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644323938779_guess_video.png"}`,
  },
  {
    title: "Interview Session",
    text: "This video provides the tools that will help you know the essentials of an interview to successfully land your dream job. ",
    videoLink:
      "https://player.vimeo.com/video/600821889?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;h=6688823571",
    src: `${"https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644323825566_inter_video.png"}`,
  },
  {
    title: "Case Study",
    text: "Master each element of the case interview with IIT B videos and receive the cutting-edge tips, tricks, and preparation guides from the industry leading mentors.",
    videoLink:
      "https://player.vimeo.com/video/603818104?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;h=5b07cc20e0",
    src: `${"https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644324068198_case_video.png"}`,
  },
  {
    title: "Business Development​: The Art of Pitching",
    text: "In this video, you will learn top quality interview googlies that you can face in your job interview. You'll also see what you need to know & understand while appearing for Business Development Interviews. ",
    videoLink: "https://www.youtube.com/watch?v=aqnhptjaHqY",
    src: `${"https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644324797118_mi3.png"}`,
  },
  {
    title: "Consulting: Strategy Building",
    text: "Becoming a consultant is all about developing effective business strategies and help companies scale.",
    videoLink: "https://www.youtube.com/watch?v=FIRNVizYhlo",
    src: `${"https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644324713206_mi2.png"}`,
  },
  {
    title: "Data Analytics: Skill of Playing with Data",
    text: "Data Analyst is one of the trending jobs of the 21st century. This video covers all the important aspects like MS Excel, SQL, Tableau, and Python that would help you crack a data analyst interview. ",
    videoLink: "https://www.youtube.com/watch?v=0nUCaLO8QgY",
    src: `${"	https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644324612339_mi1.png"}`,
  },
  {
    title: "Product Management: Understanding Pain Points",
    text: "This video will help you in knowing how PM interviews are conducted and what questions and skills you must possess before appearing for an interview.",
    videoLink: "https://www.youtube.com/watch?v=cpDc9sxqOiY",
    src: `${"https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644324847361_mi4.png"}`,
  },
];

function VerticallyCenteredModal(props) {
  ReactGA.initialize("UA-198180460-1");
  useEffect(() => {}, []);

  const location = useLocation();

  const queryParams = new URLSearchParams(window.location.search);


  const  club = queryParams.get("club");
  console.log(club)

  // const { clubs } = props;

  // console.log(props.club)
  // console.log(club)

  return props.show ? (
    <div
      style={{
        position: "fixed",
        zIndex: "99999",
        width: "100%",
        height: "100%",
        left: "0",
        top: "0",
        backdropFilter: "blur(30px)",
      }}
    >
      <div
        style={{
          width: "280px",
          height: "auto",
          borderRadius: "20px",
          textAlign: "center",
          padding: "1rem",
          backgroundColor: "white",
          marginLeft: "calc(50% - 140px)",
          marginTop: "15%",
        }}
        className="shadow"
      >
        <button
          onClick={props.hide}
          style={{
            height: "1rem",
            fontWeight: "bold",
            border: "none",
            backgroundColor: "transparent",
            transform: "translate(125px, -12px) scaleX(130%)",
          }}
        >
          X
        </button>
        <h3
          style={{
            width: "100%",
            color: "#ffa500",
            fontWeight: "bold",
            padding: "0.5rem",
          }}
        >
          Oops...
        </h3>
        <p style={{ width: "100%", textAlign: "center", color: "#ffa500" }}>
          Looks like you haven’t registered yet
        </p>
        <button className="btn btn-warning" style={{ color: "white" }}>
          <Link
            to={{
              pathname: "/register",
              state: { from: "iitbombay" },
            }}
            onClick={() => {
              ReactGA.event({
                category: "IITBombay Page",
                // type: "Button",
                // name: "IIT Bombay Unlock Now Button",
                action: "IIT Bombay Unlock Now Button",
              });
              localStorage.setItem('userlocation', location.pathname);
            }}
            style={{ color: "white" }}
          >
            Register
          </Link>
        </button>
      </div>
    </div>
  ) : (
    ""
  );
}

function VideoFrameCard(props) {
  const { allowed } = props;
  const matches = useMedia("(min-width : 768px )");

  return matches ? (
    <>
      <div
        onClick={() => {
          ReactGA.event({
            category: "IITBombay Page",
            type: "Video",
            name: props.data.title,
            action: props.data.text,
          });
        }}
        className={"mx-3 my-2 px-2 "}
        style={{
          borderBottomLeftRadius: "10px",
          borderBottomRightRadius: "10px",
          height: "500px",
          cursor: allowed ? "default" : "pointer",
          paddingBottom: matches ? "auto" : "1rem",
          width: "100%",
          background: "white",
        }}
        className="shadow"
      >
        {allowed ? (
          <ReactPlayer
            url={props.data.videoLink}
            controls
            width={"100%"}
            height={"320px"}
            className={style.iitbombayvideoframe}
          />
        ) : (
          <>
            <div style={{ position: "relative" }}>
              <img alt="img" src={props.data.src} width="100%" height="300px" />
              <img
                src="https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644324933216_play.png"
                className="img-fluid"
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  margin: "-20px 0 0 -20px",
                }}
                alt="play icon"
              />
            </div>
          </>
        )}
        <div
          className={
            allowed
              ? " px-3 mt-2 " + style.iitbombayvideoframetxt
              : " px-3 mt-4 " + style.iitbombayvideoframetxtal
          }
          // style={{ transform: allowed?'translateY(-35px)': 'translateY(5px)'}}
          style={{}}
        >
          <h4
            style={{
              color: "black",
              fontWeight: "bold",
              fontFamily: "mulish",
              fontSize: "1.5rem",
            }}
            className={props.vid === "mi" ? style.mivid : ""}
          >
            {props.data.title}
          </h4>
          <p
            style={{
              color: "black",
              fontFamily: "mulish",
              fontWeight: "bolder",
              fontSize: "0.95rem",
              overflow: "auto",
              maxHeight: "120px",
            }}
          >
            {props.data.text}
          </p>
        </div>
      </div>
    </>
  ) : (
    <>
      <div
        onClick={() => {
          ReactGA.event({
            category: "IITBombay Page",
            type: "Video",
            name: props.data.title,
            action: props.data.text,
          });
        }}
        className={"mx-1 my-1 px-0 "}
        style={{
          borderBottomLeftRadius: "10px",
          borderBottomRightRadius: "10px",
          height: "auto",
          cursor: "pointer",
          paddingBottom: matches ? "auto" : "1rem",
          width: "100%",
          background: "white",
        }}
        className="shadow"
      >
        {allowed ? (
          props.data.videoLink.split("watch?v=")[1] ? (
            <iframe
              width="100%"
              height="200"
              src={
                "https://www.youtube.com/embed/" +
                props.data.videoLink.split("watch?v=")[1]
              }
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          ) : (
            <iframe src={props.data.videoLink} width="100%" height="200" />
          )
        ) : (
          <>
            <div style={{ position: "relative" }}>
              <img alt="img" src={props.data.src} width="100%" />
              <img
                src="https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644324933216_play.png"
                className="img-fluid"
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  margin: "-20px 0 0 -20px",
                }}
                alt="play icon"
              />
            </div>
          </>
        )}
        <div
          className={" px-3 mt-2 "}
          // style={{ transform: allowed?'translateY(-15px)': 'translateY(5px)'}}
        >
          <h4
            style={{
              color: "black",
              fontWeight: "bold",
              fontFamily: "mulish",
              fontSize: "1.5rem",
            }}
          >
            {props.data.title}
          </h4>
          <p
            style={{
              color: "black",
              fontFamily: "mulish",
              fontWeight: "bolder",
              fontSize: "0.95rem",
            }}
          >
            {props.data.text}
          </p>
        </div>
      </div>
    </>
  );
}

export default function IITBVideocomp({ allowed }) {
  const [modalShow, setModalShow] = useState(false);
  const matches = useMedia("(min-width : 768px )");
  const [show, setshow] = useState(true);
  const borderChange = () => {
    setshow(!show);
  };
  return (
    <>
      <div
        style={{
          width: "100%",
          background: "#F5F5F5",
          paddingTop: "4rem",
          paddingBottom: "4rem",
          marginBottom: "5rem",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-6 text-center">
              <h3
                style={{
                  fontFamily: "Mulish-Bold",
                  fontSize: "26px",
                  borderBottom: show
                    ? "5px solid #185adb"
                    : "5px solid transparent",
                  padding: "0 0 20px 0",
                  cursor: "pointer",
                }}
                onClick={borderChange}
              >
                Our Placement Training Videos
              </h3>
            </div>
            <div className="col-md-6 text-center">
              <h3
                style={{
                  fontFamily: "Mulish-Bold",
                  fontSize: "26px",
                  borderBottom: show
                    ? "5px solid transparent"
                    : "5px solid #185adb",
                  padding: "0 0 20px 0",
                  cursor: "pointer",
                }}
                onClick={borderChange}
              >
                Mock Interviews
              </h3>
            </div>
          </div>
        </div>
        {show ? (
          <Container>
            <Row onClick={() => setModalShow(true)}>
              <Col md={{ span: 6 }} className={matches ? "p-5" : "py-3 px-4"}>
                <VideoFrameCard allowed={allowed} data={videoData[0]} />
              </Col>
              <Col md={{ span: 6 }} className={matches ? "p-5" : "py-3 px-4"}>
                <VideoFrameCard allowed={allowed} data={videoData[1]} />
              </Col>
            </Row>
            <Row onClick={() => setModalShow(true)}>
              <Col md={{ span: 6 }} className={matches ? "p-5" : "py-3 px-4"}>
                <VideoFrameCard allowed={allowed} data={videoData[2]} />
              </Col>
              <Col md={{ span: 6 }} className={matches ? "p-5" : "py-3 px-4"}>
                <VideoFrameCard allowed={allowed} data={videoData[3]} />
              </Col>
            </Row>
          </Container>
        ) : (
          <Container>
            <Row onClick={() => setModalShow(true)}>
              <Col md={{ span: 6 }} className={matches ? "p-5" : "py-3 px-4"}>
                <VideoFrameCard
                  vid="mi"
                  allowed={allowed}
                  data={videoData[4]}
                />
              </Col>
              <Col md={{ span: 6 }} className={matches ? "p-5" : "py-3 px-4"}>
                <VideoFrameCard
                  vid="mi"
                  allowed={allowed}
                  data={videoData[5]}
                />
              </Col>
            </Row>
            <Row onClick={() => setModalShow(true)}>
              <Col md={{ span: 6 }} className={matches ? "p-5" : "py-3 px-4"}>
                <VideoFrameCard
                  vid="mi"
                  allowed={allowed}
                  data={videoData[6]}
                />
              </Col>
              <Col md={{ span: 6 }} className={matches ? "p-5" : "py-3 px-4"}>
                <VideoFrameCard
                  vid="mi"
                  allowed={allowed}
                  data={videoData[7]}
                />
              </Col>
            </Row>
          </Container>
        )}
      </div>
      {allowed ? (
        ""
      ) : (
        <VerticallyCenteredModal
          show={modalShow}
          hide={() => setModalShow(false)}
        />
      )}
    </>
  );
}

export { VerticallyCenteredModal };

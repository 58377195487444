import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { BASE_URL } from "../../env";
import "../../Assets/scss/HybridExam.scss";
import Img1 from "../../Assets/img/hybridexam/pic1.png";
import Img2 from "../../Assets/img/hybridexam/pic2.png";
import axios from "axios";
import { decode } from "html-entities";

const FinalResult = ({ hidenavbar }) => {
  const [quizAttemptLength, setQuizAttemptLength] = useState(0);
  const [totalTestCases, setTotalTestCases] = useState(0);
  const [quizReviewLength, setQuizReviewLength] = useState(0);
  const [quizScoreArray, setQuizScoreArray] = useState([]);
  const [codeScoreArray, setCodeScoreArray] = useState([]);
  const [selectOption, setSelectOption] = useState("quiz");
  const [finalCodescore, setfinalscore] = useState(0);
  const [quizLength, setQuizLength] = useState(0);
  // const [codeLength, setCodeLength] = useState(0);
  const [quizScore, setQuizScore] = useState(0);
  const [question, setQuestion] = useState([]);
  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    hidenavbar(true);
    return () => {
      hidenavbar(false);
    };
  }, []);


  useEffect(() => {
    // GetLevelOfExamination();
    const Result = async () => {
      try {
        const res = await axios({
          method: "get",
          url: `${BASE_URL}/finalpage/${id}`,
          headers: {
            "Content-type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        });

        if (!res.data.result) {
          alert("Please attend the exam to check the result");
        }

        if (res.data.result.examID.displayResult === false) {
          window.close();
        }

        setQuizScore(res.data.result.quizresults.finalScore);
        setfinalscore(res.data.result.coderesults.totalScore);
        setQuizLength(res.data.result.examID.quiz_outOfScore);
        // setCodeLength(res.data.result.examID.Codeexam.questions.length);
        setTotalTestCases(res.data.result.examID.code_outOfScore);
        setQuizScoreArray(res.data.result.quizresults.score);
        setCodeScoreArray(res.data.result.coderesults.scoring);
        setQuizAttemptLength(
          res.data.result.quizresults.questions_Attempted.length
        );
        setQuizReviewLength(res.data.result.quizresults.review.length);
        setQuestion(res.data.result.examID.Quizexam.questions);
      } catch (error) {
        if (error.response.status === 401) {
          localStorage.clear();
          history.push(`/login?redirect=/exam-result/${id}`);
        }
      }
    };
    Result();
  }, [id, history]);

  // useEffect(() => {
  //   quizScoreArray.map((item) => {
  //     if (item.score == 1) {
  //       setQuizScore((preVal) => {
  //         return preVal + 1;
  //       });
  //     }
  //   });
  // }, [quizScoreArray]);

  // useEffect(() => {
  //   let scores = 0;
  //   codeScoreArray.map((item) => {
  //     scores += item.score;
  //   });
  //   setfinalscore(scores);
  // }, [codeScoreArray]);

  // useEffect(() => {
  //   totalTestCasesArr.map((val) => {
  //     let testLen = val.testcases[0][1].length;
  //     setTotalTestCases((preVal) => {
  //       return preVal + testLen;
  //     });
  //   });
  // }, [totalTestCasesArr]);

  const quizPercentage = (quizScore / quizLength) * 100;
  const codePercentage = (finalCodescore / totalTestCases) * 100;
  let QuizResult = "Suppp";
  if (quizPercentage >= 75) {
    QuizResult = "Distinction";
  } else if (quizPercentage >= 50) {
    QuizResult = "Good";
  } else if (quizPercentage >= 30) {
    QuizResult = "Can do better";
  } else {
    QuizResult = "Should improve";
  }

  let CodeResult = "Suppp";
  if (codePercentage >= 75) {
    CodeResult = "Distinction";
  } else if (codePercentage >= 50) {
    CodeResult = "Good";
  } else if (codePercentage >= 30) {
    CodeResult = "Can do better";
  } else {
    CodeResult = "Should improve";
  }

  function ShowCorrectAnswer(scorearray, answer, questionId) {
    // console.log(scorearray)
    const SelectedQuestionScores = scorearray.filter(
      (value) => value.questionId._id == questionId
    );
    const singleQuestion = question.filter((value) => value._id == questionId);
    const answers = singleQuestion[0].answers;
    const correctanswer = singleQuestion[0].correctAnswer;
    if (correctanswer == answer) {
      return true;
    } else {
      return false;
    }
  }
  function CheckusersAnswerForCorrectOne(scorearray, questionId, answer) {
    const usersAnswer = scorearray.filter(
      (value) => value.questionId._id == questionId
    );
    // to check if the user has attempted the answer or not
    if (usersAnswer.length == 1) {
      if (usersAnswer[0].option == answer) {
        return true;
      }
    } else {
      return false;
    }
  }

  function CheckusersAnswerforWrongOne(scorearray, questionId, answer) {
    const userAnswer = scorearray.filter(
      (value) => value.questionId._id == questionId
    );
    if (userAnswer.length == 1) {
      if (userAnswer[0].option == answer) {
        //option choosen by the user
        return true;
      } else {
        return false;
      }
    }
  }

  return (
    <>
      <div className="hybridExamResultPage">
        <div className="headerBanner">Your Final Result</div>
        <div className="subHeader">
          <div className="row gx-3">
            <div className="col-6 subHeadetTitle">Module 1 result</div>
            <div className="col-6 subHeadetTitle">Module 2 result</div>
            <SubCard
              imgsrc={Img1}
              title={
                isNaN(quizPercentage) ? 0 : `${Math.floor(quizPercentage)}%`
              }
              text="Your Score"
            />
            <SubCard imgsrc={Img2} title={QuizResult} text="Result" />
            <SubCard
              imgsrc={Img1}
              title={`${finalCodescore}/${totalTestCases}`}
              text="Your Score"
            />
            <SubCard imgsrc={Img2} title={CodeResult} text="Result" />
          </div>
        </div>
        <div className="report">
          <div className="title">Performance Report</div>
          <div className="table-responsive">
            <table className="table table-bordered table-hover table-striped">
              <thead className="thead">
                <tr>
                  <th>S no.</th>
                  <th>Exam</th>
                  <th>Total Questions</th>
                  <th>Correct</th>
                  <th>Incorrect</th>
                  <th>Unattempted</th>
                  <th>Mark for Review</th>
                </tr>
              </thead>
              <tbody className="tbody">
                <tr>
                  <td>1</td>
                  <td>Quiz Exam</td>
                  <td>{quizLength}</td>
                  <td>{quizScore}</td>
                  <td>{quizLength - quizScore}</td>
                  <td>{quizLength - quizAttemptLength}</td>
                  <td>{quizReviewLength}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="headerBanner mt-3 mb-1">
          Your answers
          <select
            onChange={(event) => {
              setSelectOption(event.target.value);
            }}
          >
            <option value="quiz">Module 1</option>
            <option value="coding">Module 2</option>
          </select>
        </div>
        {/* checkscore = check if the answer is attempted or not  */}
        {selectOption === "quiz"
          ? question.map((value, index) => {
              return (
                <div className="quesDetails">
                  <div className="questionHeading">Question {index + 1}</div>
                  <code className="codeQuestion">{value.question}</code>
                  {value.answers.map((value1, index1) => {
                    return (
                      <div className="quizAnswer">
                        <span className="quizAnswerNum">{index1 + 1}.</span>
                        <>
                          {ShowCorrectAnswer(
                            quizScoreArray,
                            value1,
                            value._id
                          ) ? (
                            <>
                              {CheckusersAnswerForCorrectOne(
                                quizScoreArray,
                                value._id,
                                value1
                              ) ? (
                                <>
                                  <span className="userCorrectAnswer">
                                    {value1} <i class="fas fa-check-double"></i>
                                  </span>
                                </>
                              ) : (
                                <>
                                  <span className="correctAnswer">
                                    {value1} <i class="fas fa-check"></i>
                                  </span>
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              {CheckusersAnswerforWrongOne(
                                quizScoreArray,
                                value._id,
                                value1
                              ) ? (
                                <>
                                  <span className="wrongAnswerChoose">
                                    {value1} <i class="fas fa-times"></i>
                                  </span>
                                </>
                              ) : (
                                <>{value1}</>
                              )}
                            </>
                          )}
                        </>
                      </div>
                    );
                  })}
                </div>
              );
            })
          : codeScoreArray.map((val, index) => {
              return (
                <>
                  <div className="codingDetails" key={index}>
                    <div className="codingDetailsTitle">
                      {val.questionId.name}
                    </div>
                    <div className="codingDetailsText">{decode(val.code)}</div>
                    <div className="codingDetailsDesc">
                      <div className="codingDetailsLeft">Test cases solved</div>
                      <div className="codingDetailsRight">
                        {val.testcasessolved}
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
      </div>
    </>
  );
};

const SubCard = (props) => {
  return (
    <>
      <div className="col-3 mb-0 subHeaderCards">
        <div className="subHeaderCard">
          <img src={props.imgsrc} className="img-fluid icon" alt="icon" />
          <div className="title">{props.title}</div>
          <div className="text">{props.text}</div>
        </div>
      </div>
    </>
  );
};

export default FinalResult;
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

import CollegeCollabComp from "../components/CollegeCollabComp";
import styles from "../Assets/css/newcoursepage.module.css";
import style from "../Assets/css/b2b_section_1.module.css";
import IterationPart2 from "../Pages/IterationPart2";
import Slider from "./Slider";
import useMedia from "./useMedia";
import NewConfusedForm from "./NewConfusedForm.js";
import Maintestimonial from "../Pages/Maintestimonial";
import Testimonials from "../components/TestimonialsVersion2";
import CourseFeature from "./courseFeature";
import Headingnewcoursepage from "./Headingnewcoursepage";
import Mentor from "./Mentor";

// import stenroll from "../Assets/newcoursepage_images/stenroll.png";
// import school from "../Assets/newcoursepage_images/material-school.png";
import Frequentques from "./Frequentques";
import HelmetComp from '../components/HelmetComp'
import ReactGA from "react-ga";
import { useEffect } from "react";

const Fasttrackcourse = () => {

	useEffect(() => {
		ReactGA.initialize("UA-198180460-1");
	},[])


  const matches = useMedia("(max-width : 575px )");
  return (
		<div style={{ overflowX: "hidden" }}>
			{/* <Navbar /> */}
			<HelmetComp
				info={{
					title: "Crash Course",
					// description: '',
					// siteName: '',
					// summary: '',
					// pageUrl: '',
					// imagUrl: ''
				}}
			/>
			{/* className={` ${styles.bgimg}`} */}
			<div>
				<div
					className={`container-fluid row justify-content-center align-items-center m-0 ${styles.bgimg}`}
					style={{
						height: "100%",
						overflowX: "hidden",
						background:
							"linear-gradient(105.19deg, #153D68 -5.61%, #0252A7 99.83%)",
					}}>
					<div className='container col-sm' style={{ margin: "0 8vw" }}>
						<h1 className={styles.heading} style={{ color: "white" }}>
							Crash Courses
						</h1>
						<br />
						<p className={styles.subheading} style={{ color: "white" }}>
							Certainly, cracking a job can be nerve-wracking. But with our
							extensively curated courses on Programming, Aptitude, Interview
							preparation, etc., you can get placed in leading tech giants like
							Microsoft, Google, TCS, Infosys, & many more...
						</p>

						<div className='row justify-content-start align-items-center '>
							<div className='col-sm' style={{ margin: "0" }}>
								<img src="https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644329547015_rating2.png" alt='no img'></img>

								<div
									className='col-sm'
									style={{ margin: "0 ", color: "white" }}>
									<div className='d-flex  my-4  justify-content-start align-items-center flex-wrap '>
										<div
											className={
												" d-flex justify-content-center  align-items-center  my-1"
											}
											style={{ margin: "0 2rem 0  0" }}>
											<div style={{ margin: "0 15px" }}>
												<img src="	https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644329622950_assignment2.png" />
											</div>
											<div>
												<b>500+</b> <br /> ASSIGNMENTS
											</div>
										</div>

										<div
											className={
												" d-flex justify-content-center  align-items-center my-1"
											}
											style={{ margin: "0 2rem 0  0" }}>
											<div style={{ margin: "0 15px" }}>
												<img src="https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644329693417_video2.png" />
											</div>
											<div>
												<b>200+</b> <br /> HOURS OF LECTURES
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className={`${styles.xtramargine}`} style={{ color: "white" }}>
							<a
								href='#scrl'
								onClick={() => {
									ReactGA.event({
										category: "Crash Course Page",
										type: "Crash Course Page",
										name: "Get Started Crash Course",
										action: "Get Started Crash Course",
									});
								}}
								className={`${styles.neonbutton} rounded-pill`}
								style={{
									border: "1px solid orange",
									backgroundColor: "orange",
									color: "white",
								}}>
								Get Started
							</a>
							{/* <a
                className={`btn btn-block shadow btn-lg round btn-dark text-light rounded-pill`}
                style={{
                  backgroundColor: "#ff7300",
                  color: "white",
                  border: "#ff7300",
                  width:"20rem"
                }}
                href="#scrl"
              >
                Get Started
              </a> */}
						</div>
					</div>
					<div
						className={`col-sm ${styles.dNone}`}
						style={{ margin: "4rem 0" }}>
						<div className='row container'>
							<img src="https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644329765110_bannerbg.png" />
						</div>
					</div>
				</div>
			</div>
			{/* </div> */}
			<br /> <br /> <br /> <br />
			<div className='container-fluid'>
				<div className={styles.HeadingCont}>
					<h1
						className={styles.heading}
						style={{ fontSize: `${matches ? "1.4rem" : ""}` }}>
						Our Alumni Placed At Leading Companies
					</h1>
					<br />

					<Slider />
				</div>
				{/* banner*/}
				<br />
				<div
					className={`container-fluid ${style.training_description_div} `}
					style={{
						backgroundColor: "#185ADB",
						margin: "2rem 0",
						background:
							"linear-gradient(105.19deg, #153D68 -5.61%, #0252A7 99.83%)",
					}}>
					<div className='row '>
						<div
							className={`col-xl-6 col-lg-8 col-md-10 col-sm-10 col-12 ${style.description_inner_div} `}>
							<div className={`d-flex ${style.description_divv} `}>
								<div className={`${style.center_div}`}>
									<img
										src="	https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644328973200_Group%208153.png"
										alt=''
										className={`img-fluid ${style.icons_imgs}`}
									/>
									<h1 className={`${style.des_no}`}>25,000+</h1>
									<h6 className={`${style.des_text}`}>Students Trained</h6>
								</div>
								<div className={`${style.section_vl}`}></div>
								<div className={`${style.center_div}`}>
									<img
										src="	https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644329101812_Group%208154.png"
										alt=''
										className={`img-fluid ${style.icons_imgs}`}
									/>
									<h1 className={`${style.des_no}`}>94%</h1>
									<h6 className={`${style.des_text}`}>Placements Achieved</h6>
								</div>
								<div className={`${style.section_vl}`}></div>
								<div className={`${style.center_div}`}>
									<img
										src="	https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644329167550_Group%208155.png"
										alt=''
										className={`img-fluid ${style.icons_imgs}`}
									/>
									<h1 className={`${style.des_no}`}>140+</h1>
									<h6 className={`${style.des_text}`}>Colleges Collaborated</h6>
								</div>
							</div>
						</div>
					</div>
				</div>
				<br />
				<br />
				{/* <Headingnewcoursepage heading="Course Features" />{" "} */}
				{/*subheading="why us" */}
				<CourseFeature />
				<br />
				<br />
				<br />
				<div className='container text-center my-3'>
					<br />
					<br />
					<h1
						className={styles.heading}
						style={{ fontSize: `${matches ? "1.4rem" : ""}` }}>
						Our Expert Mentors
					</h1>
					<p style={{ fontSize: `${matches ? "1rem" : "1.3rem"}` }}>
						Transforming Students Into Professionals
					</p>

					{/* <img src={img_8550} className="text-center" /> */}

					{/* subheading="Course instructors" */}
					<br />
					<Mentor />
					<br />
					<br />
					<br />
					<br />
					<br />
				</div>
				<div className={styles.HeadingCont}>
					<h1
						className={styles.heading}
						style={{ fontSize: `${matches ? "1.4rem" : ""}` }}>
						Our Success Stories
					</h1>
					<br />
				</div>
				{/* <Headingnewcoursepage heading="Our Success Stories" /> */}
				{/* subheading="what our students say about us" */}
				<Maintestimonial background='linear-gradient(105.19deg, #153D68 -5.61%, #0252A7 99.83%)' />
				<div style={{ overflowX: "hidden", marginTop: "100px" }}>
					<Testimonials />
				</div>
				<div className={styles.HeadingCont}>
					<h1
						className={styles.heading}
						style={{ fontSize: `${matches ? "1.3rem" : ""}` }}>
						Trusted By Top Colleges Across India
					</h1>
					<br />
					{/* <Headingnewcoursepage heading="Trusted By Top Colleges Across India" /> */}
					<CollegeCollabComp />
					<br />
					<br />
					<br />
					<br />
					<br />
				</div>
				<div className='container' id='scrl'>
					<div className={styles.HeadingCont}>
						<h1
							className={styles.heading}
							style={{
								fontSize: `${matches ? "1.4rem" : ""}`,
								marginBottom: "2%",
							}}>
							{/* Explore Our Courses */}
							Create Your Customized Learning Cart
						</h1>
						<br />
					</div>
					{/* <Headingnewcoursepage heading="Explore Our Courses" /> */}
					{/* subheading="course structure" */}
					<IterationPart2 />
				</div>
				<div className='container-fluid mx-auto'>
					<NewConfusedForm />
				</div>
				<br />
				<br /> <br />
				<div className={styles.HeadingCont}>
					<h1
						className={styles.heading}
						style={{ fontSize: `${matches ? "1.4rem" : ""}` }}>
						Roadmap To Be Followed
					</h1>
					<br />
				</div>
				{/* <Headingnewcoursepage heading="Roadmap To Be Followed" /> */}
				{/* subheading="Course journey" */}
				<div
					className='container-sm'
					style={{ height: "100%", overflow: "hidden" }}>
					<div className='d-flex justify-content-start flex-sm-column'>
						<div
							data-aos='fade-up-right'
							data-aos-duration='1500'
							style={{ width: "50%" }}>
							<img src="https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644329290695_1.png" style={{ width: "60%" }} />
							<p
								style={{
									fontWeight: "600",
									fontSize: "1.2em",
									width: "100%",
								}}>
								Attend Live sessions from Industry experts and get your doubts
								clarified from TA
							</p>
						</div>
					</div>
					<div className='d-flex justify-content-end'>
						<div
							data-aos='fade-up-left'
							data-aos-duration='1500'
							style={{ width: "50%" }}>
							<img src="https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644329362819_2.png" style={{ width: "60%", height: "auto" }} />
							<p
								style={{
									fontWeight: "600",
									fontSize: "1.2em",
								}}>
								Work on assignments and projects under guidance of experts
								community
							</p>
						</div>
					</div>
					<div className='d-flex justify-content-start'>
						<div
							data-aos='fade-up-right'
							data-aos-duration='1500'
							style={{ width: "50%" }}>
							<img src="	https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644329437297_3.png" style={{ width: "60%", height: "auto" }} />
							<p
								style={{
									fontWeight: "600",
									fontSize: "1.2em",
								}}>
								Get Ready with Resume and Experience Mock Interviews with
								Industry Experts
							</p>
						</div>
					</div>
					<div className='d-flex justify-content-end'>
						<div
							data-aos='fade-up-left'
							data-aos-duration='1500'
							style={{ width: "50%" }}>
							<img src="https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644329486350_4.png" style={{ width: "65%", height: "auto" }} />
							<p
								style={{
									fontWeight: "600",
									fontSize: "1.2em",
								}}>
								Get Placement support and appear for Company Interviews
							</p>
						</div>
					</div>
				</div>
				<br />
				<br />
				<br />
				<br />
				<div className={styles.HeadingCont}>
					<h1
						className={styles.heading}
						style={{ fontSize: `${matches ? "1.4rem" : ""}` }}>
						Frequently Asked Questions
					</h1>
					<br />
				</div>
				{/* <Headingnewcoursepage heading="Frequently Asked Questions" /> */}
				<div className='row m-0'>
					<div
						className={`${matches ? "col-11" : "col-7"} mx-auto ${
							styles.HeadingCont
						}`}>
						<Frequentques />
					</div>
					<br />
					<br />
					<br />
				</div>
				{/* <div className={styles.HeadingCont}>
          <div className={styles.moneyback}>
            GET 30 DAYS MONEY BACK GUARANTEE
          </div>
        </div> */}
				<br />
				<br />
			</div>
			<Footer />
		</div>
	);
};
export default Fasttrackcourse;

import React, { useState, useEffect, Suspense } from "react";
import PowerHeader from "./components/PowerHeader";
import PowerAssignment from "./PowerAssignment";
import PowerTest from "./PowerTest";
import PowerOverview from "./PowerOverview";
import { store } from "./app/store";
import { Provider } from "react-redux";
import "./powerbi.scss";

function lazyWithPreload(factory){
  const Component = React.lazy(factory);
  Component.preload = factory;

  return Component
}

const PowerAttendance = lazyWithPreload(() => import("./PowerAttendance"));
const PowerHome = lazyWithPreload(() => import("./PowerHome"));

const PowerBi = ({ hidenavbar }) => {
  const [tab, setTab] = useState(0);
 
  useEffect(() => {
    hidenavbar(true);
    return () => {
      hidenavbar(false);
    };
  }, [hidenavbar]);

  

  const setActiveTab = (numb) => {
    setTab(numb);
  };

  const ActiveTab = () => {
    if (tab === 0) {
      return (
        <Suspense fallback={<div>Loading ...</div>}>
          <PowerHome />
        </Suspense>
      );
    } else if (tab === 1) {
      return (
        <Suspense fallback={<div>Loading ...</div>}>
          <PowerAttendance />
        </Suspense>
      )
    } else if (tab === 2) {
      return <PowerAssignment />;
    } else if (tab === 3) {
      return <PowerTest />;
    } else {
      return <PowerOverview />;
    }
  };

  return (
    <Provider store={store}>
      <div className="powerBi">
        <PowerHeader tab={tab} func={setActiveTab} />
        <div className="powerBiBody">
          <ActiveTab />
        </div>
      </div>
    </Provider>
  );
};

export default PowerBi;

import React from "react";
import Footer from "../../Footer";
import Editor from "./Editor";

const CreateBlog = () => {
  return (
    <>
      <Editor />
      <Footer />
    </>
  );
};

export default CreateBlog;

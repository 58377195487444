import { useEffect, useState, lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import "./App.css";
import Navbar from "./components/Navbar";
import Home from "./Pages/Home";
import QuantFinance from "./Pages/QuantFinancePg";
import Aboutsus from "./Pages/Aboutus";
import Web from "./Pages/webCourse";
import CPcourse from "./Pages/CPcourse";
import DashboardContext from "./dashboard/context/DashboardContext";
import Newcoursepage from "./componentsofcourse/newcoursepage";
import Fasttrackcourse from "./componentsofcourse/FasttrackCourse";
import AllQuestion from "./componentsofcourse/allQuestion";
import SignUp from "./Pages/SignUp";
import SignIn from "./Pages/SignIn";
import Privacy from "./Pages/Privacy";
import ModalContextProvider from "./Context/ModalContext";
import CreateBlog from "./components/Admin/Editor/CreateBlog";
import EditBlog from "./components/Admin/Editor/EditBlog";
import PlacementCourse from "./Pages/PlacementCourse";
import AuthContextProvider from "./Context/AuthContext";
import Token from "./Token";
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";
import Coupons from "./components/Coupons";
import { ReactNotifications } from "react-notifications-component";
import HybridExamDashboard from "./components/Dashboard/HybridExamDashboard";
import PaymentPopup from "./Pages/PaymentPopup";
import BlogPage from "./Pages/BlogPage";
import ReactGA from "react-ga";
import Personalinformation from "./componentsofcourse/Personalinformation";
import Profile from "./components/Profile";
import Edit from "./components/Edit";
import Sitemap from "./Pages/Sitemap";
import InstaAdLanding1 from "./componentsofcourse/InstaAdLanding1";
import TCSForm from "./componentsofcourse/TCSForm";
import JobPostings from "./Pages/JobPostings";
// import JobPostingPage from "./Pages/JobPostingPage";
// import JobPostingData from "./Pages/JobPostingData";
import MasterClass from "./Pages/MasterClass";
import MasterClassPage from "./Pages/MasterClassPage";
import B2BHome from "./Pages/B2BHome";
import RedirectAndSetToken from "./Pages/RedirectAndSetToken";
import MyCourse from "./componentsofcourse/MyCourse";
import NewMasterClassForm from "./componentsofcourse/NewMasterclassForm";
import NewMasterClassLanding from "./Pages/NewMasterClassLanding";
import OnetoOnebooking from "./Pages/OnetoOnebooking";
import OfflineWebinarForm from "./Pages/OfflineWebinarForm";
import NewBlog from "./Pages/NewBlog";
import NewMentorForm from "./Pages/NewMentorForm";
import Experts from "./Pages/Experts";
import ExpertProfile from "./Pages/ExpertProfile";
import ContactForm from "./componentsofcourse/ContactForm";
import NewBlogFrontPage from "./Pages/NewBlogs/NewBlogFrontPage";
import B2BForms from "./components/b2bcomponents/B2BForms";
import Newstepform from "./Pages/Newstepform";
import NewMasterClass from "./Pages/NewMasterClass";
import IITBombay from "./Pages/IITBombay";
import RecieptDone from "./Pages/RecieptDone";
import NewMasterClassParticular from "./Pages/NewMasterClassParticular";
import IITform from "./components/IITform";
import OfflineMasterClass from "./components/OfflineMasterClass";
import MentorForm from "./Pages/MentorForm";
import Compiler from "./componentsofcourse/Compiler";
import Thanks from "./Pages/Thanks";
import MasterclassStandalone from "./Pages/MasterclassStandalone";
import JobContextProvider from "./Context/JobContext";
import MentorConnectHomepage from "./Pages/MentorConnectHomepage";
import CompanySpecific from "./Pages/CompanySpecific";
import Exam from "./componentsofcourse/Exam";
import Allexam from "./componentsofcourse/Allexam";
import Quizz from "./Pages/Quizz";
import QuizzQuestions from "./Pages/QuizzQuestions";
import ExtraPractiseQuiz from "./Pages/ExtraPractiseQuiz";
import ExamSubmitted from "./componentsofcourse/ExamSubmitted";
import AlreadydoneExam from "./componentsofcourse/AlreadydoneExam";
import ProfilePage from "./Pages/ProfilePage";
import UserDetails from "./Pages/UserDetails.jsx";
import NewHomePage from "./Pages/NewHomePage";
import HomePage from "./components/HomePage";
import SuccessPage from "./SuccessPage";
import CodingInstruction from "./componentsofcourse/CodingInstruction";
import HybridExam from "./Pages/HybridExam";
import QuizInstructions from "./components/QuizInstructions";
import QuizExamSubmitted from "./componentsofcourse/QuizExamSubmitted.tsx";
import IntermediatePage from "./Pages/NewHybridExam/IntermediatePage";
import CodingInstructionPage from "./Pages/NewHybridExam/CodingInstruction";
import CodingExam from "./Pages/NewHybridExam/CodingExam";
import FinalResult from "./Pages/NewHybridExam/FinalResult";
import AllHybridExaminationNew from "./Pages/NewHybridExam/AllHybridExaminationNew";
import NewCompiler from "./components/Dashboard/NewCompiler";
import Login from "./Pages/Login";
import Register from "./Pages/Register";
import PostLogin from "./Pages/PostLogin";
import Zoomverify from "./components/zoomverify";
import EventPage from "./Pages/EventPage";
import SpecificEventPage from "./Pages/SpecificEventPage";
import PowerBi from "./powerbi/PowerBi";
import { Provider } from "react-redux";
import { store } from "./app/store";
import Difficulty from "./dashboard/Difficulty";
import { QueryClient, QueryClientProvider } from "react-query";
import NewHybridExam from "./Pages/NewHybridExam_v2/NewHybridExam";
import LearningDetails from "./dashboard/NewComponent/LearningDetails";
import FlipCard from "./dashboard/NewComponent/FlipCard";
import DashboardInterview from "./dashboard/Interview/DashboardInterview";
import PreparePage from "./dashboard/Interview/PreparePage";
import TopicForm from "./dashboard/Interview/TopicForm";
import InterviewPractice from "./dashboard/Interview/InterviewPractice";
import AiGenrate from "./dashboard/Interview/AiGenrate";
import VerifyExam from "./componentsofcourse/VerifyExam";
import LoginWithRedirect from "./Pages/LoginWithRedirect";
import PDAssignmentPage from "./dashboard/NewComponent/PDAssignmentPage";
import RedirectView from "./RedirectUrls/RedirectView";
import InterviewPreparation from "./dashboard/InterviewPreparation";
import QuestionSolving from "./dashboard/QuestionSolving";
import QuestionsCommon from "./dashboard/QuestionsCommon";
import BvritSignup from "./Pages/BvritSignup";
import TextBasedExams from "./Pages/TextBasedExams";
import TextBasesResults from "./Pages/TextBasedExams/TextBasesResults";
import DashboardLoader from "./components/DashboardLoader";
import HTMLEditor from "./Pages/HTMLEditor";

const Dashboard = lazy(() => import("./dashboard/Dashboard"));
// const AptitudeExams = lazy(() => import("./dashboard/AptitudeExams"));
// const Coming = lazy(() => import("./dashboard/Coming"));

const queryClient = new QueryClient();

function App() {
  useEffect(() => {
    ReactGA.initialize("UA-198180460-1");
    ReactGA.pageview(window.location.pathname);
    localStorage.getItem("token");
  }, []);

  const [show, setshow] = useState(false);
  const [showNavbarOnExamVerify, setShowNavbarOnExamVerify] = useState(false);

  useEffect(() => {
    if (window.location.pathname.includes("exam-verify") || window.location.pathname.includes("redirect")) {
      setshow(true);
      setShowNavbarOnExamVerify(true);
    } else {
      // setshow(false);
      setShowNavbarOnExamVerify(true);
    }
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <div className="App">
          <AuthContextProvider>
            <ModalContextProvider>
              <JobContextProvider>
                <ReactNotifications />
                <Token />
                {show === false && showNavbarOnExamVerify && <Navbar />}
                <Switch>
                  <Route path="/showAllHybridExams">
                    <AllHybridExaminationNew />
                  </Route>
                  <Route path="/codinginstructions">
                    <CodingInstruction hidenavbar={setshow} />
                  </Route>
                  <Route path="/redirect-and-set">
                    <RedirectAndSetToken hidenavbar={setshow} />
                  </Route>
                  <Route path="/quizinstructions">
                    <QuizInstructions hidenavbar={setshow} />
                  </Route>
                  <Route path="/success-page">
                    <SuccessPage hidenavbar={setshow} />
                  </Route>
                  <Route path="/newhomepage">
                    <HomePage />
                  </Route>
                  <Route exact path="/examdone">
                    <AlreadydoneExam />
                  </Route>
                  <Route path="/event-page">
                    <EventPage />
                  </Route>
                  <Route path="/allexams/:id">
                    <Allexam />
                  </Route>
                  <Route path="/verify-exam/:stringRoute">
                    <VerifyExam hidenavbar={setshow} />
                  </Route>
                  <Route exact path="/codingexam/:id">
                    <Exam hidenavbar={setshow} />
                  </Route>
                  <Route exact path="/codingexam/examsubmitted/:id">
                    <ExamSubmitted hidenavbar={setshow} />
                  </Route>
                  <Route exact path="/quizexamsubmitted/:id">
                    <QuizExamSubmitted />
                  </Route>
                  <Route exact path="/hybridExam/:id">
                    <HybridExam hidenavbar={setshow} />
                  </Route>
                  <Route path="/hybridExamDashboard" component={HybridExamDashboard} exact />
                  <Route exact path="/company-specific/:id" component={CompanySpecific} />
                  <Route path="/compiler/:id/:courseid">
                    <Compiler hidenavbar={setshow} />
                  </Route>
                  <Route path="/pd-online-compiler/:id/:courseid">
                    <Compiler hidenavbar={setshow} />
                  </Route>
                  <Route path="/quiz">
                    <Quizz hidenavbar={setshow} />
                  </Route>
                  <Route path="/extraPractiseQuiz/:examId">
                    <ExtraPractiseQuiz hidenavbar={setshow} />
                  </Route>
                  <Route path="/quizPortal/:examId">
                    <QuizzQuestions hidenavbar={setshow} />
                  </Route>
                  <Route path="/getallQuestion">
                    <AllQuestion />
                  </Route>
                  <Route path="/b2bform">
                    <B2BForms />
                  </Route>
                  <Route path="/newblogpages">
                    <NewBlogFrontPage />
                  </Route>
                  <Route path="/fasttrackcourse">
                    <Fasttrackcourse />
                  </Route>
                  <Route path="/contactusform">
                    <ContactForm />
                  </Route>
                  <Route path="/offlinewebinar">
                    <OfflineWebinarForm />
                  </Route>
                  <Route path="/onetoonebooking">
                    <OnetoOnebooking />
                  </Route>
                  <Route path="/mycourse">
                    <MyCourse />
                  </Route>
                  <Route path="/newmasterclass">
                    <NewMasterClass />
                  </Route>
                  <Route path="/newmasterclassparticular/:id">
                    <NewMasterClassParticular />
                  </Route>
                  <Route path="/masterclasses-placement" component={NewMasterClassForm} />
                  <Route path="/masterclass" component={NewMasterClassLanding} />
                  <Route path="/createJobPosting">
                    <JobPostings />
                  </Route>
                  <Route path="/masterclasspage/:webinar_id">
                    <MasterClassPage />
                  </Route>
                  <Route exact path="/oldmasterclass" component={MasterClass} />
                  {/* <Route
                                        exact
                                        path="/jobs-for-freshers"
                                        component={JobPostingPage}
                                    />
                                    <Route
                                        exact
                                        path="/jobs-for-freshers/:id"
                                        component={JobPostingData}
                                    /> */}
                  {/* <Route exact path="/aggregations" component={AggregationPages} /> */}
                  <Route path="/tcsformsign">
                    <TCSForm />
                  </Route>
                  <Route path="/thanks">
                    <Thanks />
                  </Route>
                  <Route path="/reciept">
                    <RecieptDone />
                  </Route>
                  <Route path="/instalanding">
                    <InstaAdLanding1 />
                  </Route>

                  <Route path="/masterclass-standalone/:id">
                    <MasterclassStandalone />
                  </Route>
                  <Route exact path="/" component={Home} />
                  <Route path="/Aboutus">
                    <Aboutsus />
                  </Route>
                  <Route path="/webinarregister">
                    <Personalinformation List={["1000+ students already registered from JNTU and GITAM in last 7 days", "Free Tool kits", "Free DSA Videos", "High-level content exclusively for TCS Digital", "320+ students got placed @FAANG"]} Heading={"Crack TCS ,Infosys, Amazon with cantilever labs"} Date={"21st July (Wednesday)"} Time={"7:00 PM"} ByWhom={"Avinash Mundhra"} Who={"Founder & CEO of Cantilever Labs"} />
                  </Route>
                  {/* <Route path="/tcs" component={TcsInformation} /> */}
                  <Route path="/newcoursepage">
                    <Newcoursepage />
                  </Route>
                  <Route path="/webcourse">
                    <Web />
                  </Route>
                  <Route path="/cpcourse">
                    <CPcourse />
                  </Route>
                  <Route path="/placementcourse">
                    <PlacementCourse />
                  </Route>
                  <Route path="/signup">
                    <SignUp />
                  </Route>
                  <Route path="/chitkara-signup">
                    <BvritSignup />
                  </Route>
                  <Route path="/signin">
                    <SignIn />
                  </Route>
                  <Route path="/sitemap">
                    <Sitemap />
                  </Route>
                  <Route path="/policies">
                    <Privacy />
                  </Route>
                  <Route path="/masterclassform">
                    <Newstepform />
                  </Route>
                  <Route path="/iitbombayform/:club">
                    <IITform />
                  </Route>
                  <Route path="/iitbombay">
                    <IITBombay name={"IIT BOMBAY"} />
                  </Route>
                  <Route path="/iitMadras">
                    <IITBombay name={"IIT Madras "} />
                  </Route>
                  <Route path="/ism">
                    <IITBombay name={"ISM-IIT Dhanbad"} />
                  </Route>
                  <Route path="/iitKGP">
                    <IITBombay name={"IIT KGP"} />
                  </Route>
                  <Route path="/OfflineMasterClass/:id">
                    <OfflineMasterClass />
                  </Route>
                  <Route path="/mentorform">
                    <MentorForm />
                  </Route>
                  {/* <Route path="/user/:id">
                <Profile />
              </Route> */}
                  <Route exact path="/user/:id" component={Profile} />
                  <Route path="/edit">
                    <Edit />
                  </Route>
                  <Route path="/quant_finance">
                    <QuantFinance />
                  </Route>
                  <Route path="/blog">
                    {/* <Blogs /> */}
                    <NewBlogFrontPage />
                  </Route>
                  <Route path="/newblog">
                    <NewBlog />
                  </Route>
                  <Route path="/NewMentorForm">
                    <NewMentorForm />
                  </Route>
                  <Route path="/InterviewPreparation">
                    <InterviewPreparation />
                  </Route>
                  {/* <Route path="/KnowledgeSession">
                    <KnowledgeSession />
                  </Route> */}
                  <Route path="/QuestionSolving">
                    <QuestionSolving />
                  </Route>
                  <Route path="/questionsCommon">
                    <QuestionsCommon />
                  </Route>
                  <Route path="/business" component={B2BHome} />
                  <Route path="/order/:id" component={PaymentPopup} />
                  <Route exact path="/coupon" component={Coupons} />
                  <Route path="/blogs/:newid" component={BlogPage} />
                  <Route exact path="/addblog" component={CreateBlog} />
                  <Route exact path="/editblog/:Objectid" component={EditBlog} />
                  <Route exact path="/forgotpassword" component={ForgotPassword} />

                  <Route exact path="/resetpassword/:_id/:token" component={ResetPassword} />
                  <Route exact path="/mentor-connect" component={MentorConnectHomepage} />
                  <Route exact path="/expert-profile/:id" component={ExpertProfile} />
                  <Route exact path="/experts" component={Experts} />
                  <Route exact path="/profile" component={ProfilePage} />
                  <Route exact path="/profile-details" component={UserDetails} />
                  <Route exact path="/new-home-page" component={NewHomePage} />
                  <Route exact path="/intermediate" component={IntermediatePage} />
                  {/* <Route path="/coming">
                    <Coming />
                  </Route> */}
                  <Route exact path="/coding-instructions/:id">
                    <CodingInstructionPage hidenavbar={setshow} />
                  </Route>
                  <Route exact path="/coding-exam/:id">
                    <CodingExam hidenavbar={setshow} />
                  </Route>
                  <Route exact path="/:id/difficulty">
                    <Difficulty hidenavbar={setshow} />
                  </Route>
                  {/* <Route exact path="/newdashboard">
                   <NewDashboard />
                </Route> */}
                  <Route exact path="/pdassignmentpage">
                    <PDAssignmentPage hidenavbar={setshow} />
                  </Route>
                  <Route exact path="/exam-result/:id">
                    <FinalResult hidenavbar={setshow} />
                  </Route>
                  <Route exact path="/cl-compiler">
                    <NewCompiler hidenavbar={setshow} />
                  </Route>
                  <Route exact path="/login">
                    <Login hidenavbar={setshow} />
                  </Route>
                  <Route exact path="/loginRedirect">
                    <LoginWithRedirect hidenavbar={setshow} />
                  </Route>
                  <Route exact path="/register">
                    <Register hidenavbar={setshow} />
                  </Route>
                  <Route exact path="/login-post">
                    <PostLogin hidenavbar={setshow} />
                  </Route>
                  <Route path="/specific-eventpage">
                    <SpecificEventPage />
                  </Route>
                  <Route exact path="/powerbi/:id">
                    <PowerBi hidenavbar={setshow} />
                  </Route>
                  <Route exact path="/learningdetails">
                    <LearningDetails />
                  </Route>
                  <Route exact path="/flipcard/:gameId">
                    <FlipCard hidenavbar={setshow} />
                  </Route>
                  <Route exact path="/interview">
                    <DashboardInterview hidenavbar={setshow} />
                  </Route>
                  <Route exact path="/preparepage">
                    <PreparePage hidenavbar={setshow} />
                  </Route>
                  <Route exact path="/interviewpractice">
                    <InterviewPractice hidenavbar={setshow} />
                  </Route>
                  <Route exact path="/aigenrate">
                    <AiGenrate hidenavbar={setshow} />
                  </Route>
                  <Route path="/text-based-exams/:examId">
                    <TextBasedExams hidenavbar={setshow} />
                  </Route>
                  <Route path="/text-based-result/:examId">
                    <TextBasesResults hidenavbar={setshow} />
                  </Route>
                  <Route exact path="/topicform">
                    <TopicForm />
                  </Route>
                  <Route exact path="/zoomverify/verifyzoom.html">
                    <Zoomverify hidenavbar={setshow} />
                  </Route>
                  <Route path="/hybrid-exam/:id">
                    <NewHybridExam hidenavbar={setshow} />
                  </Route>
                  <Route path="/redirect/:shortUrlString">
                    <RedirectView />
                  </Route>
                  <Route path="/web-dev">
                    <HTMLEditor hidenavbar={setshow} />
                  </Route>
                  <Route exact path="/dashboard/*">
                    <Suspense fallback={<DashboardLoader hidenavbar={setshow} />}>
                      <DashboardContext>
                        <Dashboard hidenavbar={setshow} />
                      </DashboardContext>
                    </Suspense>
                    {/* <DashboardLoader hidenavbar={setshow} />
                     */}
                  </Route>
                </Switch>
              </JobContextProvider>
            </ModalContextProvider>
          </AuthContextProvider>
        </div>
      </Provider>
    </QueryClientProvider>
  );
}

export default App;

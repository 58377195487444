import styles from "../Assets/css/newcoursepage.module.css"

import useMedia from "./useMedia";

const Headingnewcoursepage=(props)=>{
    const matches = useMedia("(max-width : 575px )");
    // console.info(props.class)
    return(
        <div className={styles.HeadingCont} styles={{fontSize:`${matches=== true?"1rem":"2.5rem"}`}}>
        <h1  className={props.class?`${props.classs}`:`${styles.heading}`}>{props.heading}</h1>
        <br/>
       {props.subheading? <p className={styles.subheading}>{props.subheading}</p>:""}
       <br/>
    </div>
    )
}

export default Headingnewcoursepage
import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import style from "../Assets/css/MasterLinkedIn.module.css";
import { BASE_URL } from "../env";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import PlayBtn from "../Assets/img/IITBombay/play.png";

const MasterLinkedInlive = (props) => {
	const { id } = useParams();
	const [subject, setSubject] = useState("");
	const [description, setDescription] = useState("");
	const [video_link, setVideoLink] = useState("");
	const [startTime, setStartTime] = useState("");
	const [endTime, setEndTime] = useState("");
	useEffect(() => {
		fetch(BASE_URL + "/webinars/getAllSingleWebinar/" + id, {
			method: "GET",
			headers: { "Content-Type": "application/json" },
		})
			.then((res) => res.json())
			.then((data) => {
				// console.log("WEbinar Data particular", data);
				setSubject(data.response[0].subject);
				setDescription(data.response[0].description);
				setStartTime(data.response[0].startTime);
				setVideoLink(data.response[0].video_link);
				setEndTime(data.response[0].endTime);
			});
	});
	return (
		<div className={" container my-2 " + style.mainDiv}>
			<div className='row'>
				<div className='col-12'>
					<h1 className={style.heading}>{subject}</h1>
					<p className={style.timings}>
						Date:{" "}
						{moment(startTime).format("DD") +
							" " +
							moment(startTime).format("MMMM") +
							" " +
							moment(startTime).format("YYYY")}{" "}
						Time: {moment(startTime).format("hh A")} Duration: 1hr Price:Free
						Venue:Online
					</p>
				</div>
			</div>
			<div className='row'>
				<div className='col-md-6'>
					{props.allowed ? (
						<ReactPlayer url={video_link} controls width='100%' />
					) : (
						<div style={{ backgroundColor: "black" }}>
							<img alt='img' src={props.data.src} width='100%' />
							<Link
								to={{
									pathname: "/OfflineMasterClass",
									state: { offlineId: id },
								}}>
								<img
									src={PlayBtn}
									className='img-fluid'
									style={{
										position: "absolute",
										top: "50%",
										left: "50%",
										margin: "-20px 0 0 -20px",
									}}
									alt='play icon'
								/>
							</Link>
						</div>
					)}
				</div>
				<div className={"col-md-6" + style.rightdiv}>
					<h1 className={`${style.rightheading}`}>About the Master Class</h1>
					<p className={style.righttxt}>{description}</p>
					<Link
						to={{
							pathname: "/OfflineMasterClass",
							state: { offlineId: id },
						}}
						className={style.registerbtn}>
						Register Now
					</Link>
				</div>
			</div>
		</div>
	);
};

export default MasterLinkedInlive;

import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { BASE_URL } from "../env";
import { useState, useEffect } from "react";
import optionCities from "../cities";
import axios from "axios";
import PopupAd from "./PopupAd";
// import Creatable from "react-select/creatable";

import {
  BrowserRouter as Router,
  Route,
  Switch,
  useLocation,
  Redirect,
  useParams,
} from "react-router-dom";
import useMedia from "../componentsofcourse/useMedia";
// import useMedia from "../componentsofcourse/useMedia";
import "react-phone-number-input/style.css";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import { isValidPhoneNumber } from "react-phone-number-input";
import ReactPixel from "react-facebook-pixel";
// import useMedia from "../componentsofcourse/useMedia";
import AsyncSelect from "react-select/async";
import "../Assets/css/newstepform.css";
export default function Newstepform() {
  const matches = useMedia("(min-width : 786px )");

  let referral_ = "";

  let loc = useLocation();
  // console.log(loc.pathname.split("/masterclass/")[1]);

  // const referral_ =useParams();
  if (!matches) {
    referral_ = loc.pathname.split("/masterclass/")[1];
    if (referral_ === undefined) {
      referral_ = loc.pathname.split("/masterclassform/")[1];
    }
    // console.log("rr", referral_);
  } else {
    referral_ = loc.pathname.split("/masterclassform/")[1];

    if (referral_ === undefined) {
      referral_ = loc.pathname.split("/masterclass/")[1];
    }
    // console.log("rr", referral_);
  }

  let arr = loc.search.split("=");
  let referral = arr[1];
  const webinarID = loc.pathname.split("/masterclassform/")[1];
  console.log("webinarId", webinarID);
  const [desc, setDesc] = useState(null);

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors, isValid },
  } = useForm({ mode: "all" });
  const [collegeName, setCollegeName] = useState("");
  const [collegeData, setCollegeData] = useState([]);
  // const matches = useMedia("(min-width : 1198px )");

  const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
  const options = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: false, // enable logs
  };

  useEffect(() => {
    ReactPixel.init("337209321107283", advancedMatching, options);
    ReactPixel.trackCustom("SingleJobPostPage");
  }, []);
  useEffect(() => {
    fetch(BASE_URL + "/webinars/getAllSingleWebinar/" + webinarID, {
      method: "GET",
      headers: { "Content-Type": "application/json", webinar_id: webinarID },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("WEbinar Data", data?.response);
        setDesc(data?.response[0]?.description);
      });
  }, []);

  const [showInterested, setshowInterested] = useState(true);
  function CloseTheButton() {
    setshowInterested(false);
    // history.push("/mycourse/6114d578f6cd9f0e9e8a811d");
  }
  function PushTheUser() {
    setshowInterested(false);
    // history.push("/mycourse/6114d578f6cd9f0e9e8a811d");
  }
  useEffect(async () => {
    const data = await axios("https://api64.ipify.org?format=jsonp");
    // console.log(data.data);
  }, []);
  function InterestedUser() {
    fetch(BASE_URL + "/userInterested", {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: JSON.stringify({
        interestedCourse: true,
      }),
    })
      .then((response) => {
        // console.log(response);
        // signin("final");
        // PushTheUser();
      })
      .catch((err) => {
        // console.log(err);
      });
    PushTheUser();
  }
  useEffect(() => {
    // fetch(BASE_URL + `/getCollegeData?name=${collegeName}`, {
    // 	headers: { "Content-Type": "application/json" },
    // }).then((res) => res.json())
    // 	.then((data) => {
    // 		var dataArray = []
    // 		console.log(data)
    // 		for (let i = 0; i < data.length; i++){
    // 			dataArray.push({
    // 				label: data[i].CollegeName,
    // 				value: data[i].CollegeName,
    // 			});
    // 		}
    // 		setCollegeData(dataArray)
    // })
  }, [collegeName]);

  const retCollegeData = (inputvalue) => {
    fetch(BASE_URL + `/getCollegeData?name=${inputvalue}`, {
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((data) => {
        var dataArray = [];
        // console.log(data);
        for (let i = 0; i < data.length; i++) {
          dataArray.push({
            label:
              data[i].CollegeName +
              ", " +
              data[i].District +
              ", " +
              data[i].State,
            value:
              data[i].CollegeName +
              ", " +
              data[i].District +
              ", " +
              data[i].State,
          });
        }
        setCollegeData(dataArray);
      });
    return collegeData;
  };
  const loadOptions = (inputValue, callback) => {
    callback(retCollegeData(inputValue));
  };

  function changeStates(onefun, onefunValue, secondFun, secondfunvalue) {
    onefun(onefunValue);
    secondFun(secondfunvalue);
    // alert("hello");
  }
  const [value, setValue] = useState();
  const [firstForm, setFirstForm] = useState(true);
  const [secondForm, setSecondForm] = useState(false);
  const [thirdForm, setThirdForm] = useState(false);
  const [fourthForm, setFourthFrom] = useState(false);
  const [fifthForm, setfifthForm] = useState(false);
  const [thanks, setthanks] = useState(false);
  const [isOthers, setIsOthers] = useState(false);

  const customStyles = {
    option: (provided) => ({
      ...provided,
      textAlign: "left",
      fontSize: !matches && "0.7rem",
    }),
    container: (provided) => ({
      ...provided,
      border: "none",
      padding: "1px",
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: !matches && "0.7rem",
    }),
  };

  useEffect(() => {
    // console.log(collegeName);
  }, [collegeName]);
  useEffect(() => {
    const collegeData = watch("CollegeName");

    setCollegeName(collegeData);
    // console.log(collegeData)
  });

  const onSubmit = async () => {
    // console.log(watch());
    const data = watch();
    data.YearofGrad = data.YearofGrad.value.toString();
    data.CollegeName = data.CollegeName.value;
    let newData = { ...data, referral: referral_, webinarId: webinarID };
    console.log(JSON.stringify(newData));
    await fetch(BASE_URL + "/firstForm", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(newData),
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log("Response from firstForm", data);
      });
    // console.log("Data on Submit", data);
  };
  const onSubmitTotal = async () => {
    const data = watch();
    // console.log(data);
    data.City = data.City.value;
    data.CollegeName = data.CollegeName.value;
    data.Department = data.Department.value;
    data.GraduationDegree = data.GraduationDegree.label;
    data.YearofGrad = data.YearofGrad.label;
    data.placement_time = data.placement_time.label;
    data.targeted_companies = data.targeted_companies.label;

    var newArr = [];
    for (let i = 0; i < data.interests.length; i++) {
      newArr.push(data.interests[i].value);
    }

    data.interests = newArr;

    const newData = { ...data, webinarId: webinarID };
    await fetch(BASE_URL + "/firstForm", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(newData),
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log("Response from firstForm", data);
      });
    // console.log("Data on Submit", data);
    // console.log(data);
  };

  const CareerInterest = [
    { label: "Data Science", value: 1 },
    { label: "Software Development", value: 2 },
    { label: "Machine Learning", value: 3 },
    { label: "Artificial Intelligence", value: 4 },
    { label: "Product Development", value: 5 },
    { label: "Full Stack Development", value: 6 },
    { label: "Cyber Security", value: 7 },
  ];

  const primaryLookingfor = [
    { label: "Test Series", value: 1 },
    { label: "Competitive Coding", value: 2 },
    { label: "Interview Preparation", value: 3 },
    { label: "Problem Solving", value: 4 },
    { label: "Free Toolkits", value: 5 },
    { label: "Resume Building", value: 6 },
    { label: "Boosting Softskills", value: 7 },
    { label: "Industry Expert Webinars", value: 8 },
    { label: "Project Based Learning", value: 9 },
    { label: "Internships / Jobs", value: 10 },
    { label: "Certificates", value: 11 },
    { label: "Live session", value: 11 },
    { label: "Recorded session", value: 11 },
    { label: "All of the above", value: 12 },
  ];
  const DreamCompany = [
    { label: "TCS", value: 1 },
    { label: "Amazon", value: 2 },
    { label: "Google", value: 3 },
    { label: "Oracle", value: 4 },
    { label: "Accenture", value: 5 },
    { label: "Infosys", value: 6 },
    { label: "Wipro", value: 7 },
    { label: "Cognizant", value: 8 },
    { label: "FAANG", value: 9 },
  ];

  const graduation = [
    { label: "CSE", value: "CSE" },
    { label: "ECE", value: "ECE" },
    { label: "IT", value: "IT" },
    { label: "EEE", value: "EEE" },
    { label: "MECH", value: "MECH" },
    { label: "CIVIL", value: "CIVIL" },
    { label: "Others", value: "Others" },
  ];
  const targeted_companied_option = [
    {
      label: "FAANG (Facebook, Amazon, Apple, Netflix and Google)",
      value: "FAANG (Facebook, Amazon, Apple, Netflix and Google)",
    },
    {
      label: "Product based (Microsoft, Flipkart, etc)",
      value: "Product based (Microsoft, Flipkart, etc)",
    },
    {
      label: "Service based (TCS, Wipro, etc)",
      value: "Service based (TCS, Wipro, etc)",
    },
  ];
  const placement_time_option = [
    {
      label: "Less than 1 month",
      value: "Less than 1 month",
    },
    {
      label: "1-2 month",
      value: "1-2 month",
    },
    {
      label: "2-6 month",
      value: "2-6 month",
    },
    {
      label: "6+ months",
      value: "6+ months",
    },
  ];
  const interests_option = [
    {
      label: "Data Science / Analytics",
      value: "Data Science / Analytics",
    },
    {
      label: "Software Development",
      value: "Software Development",
    },
    {
      label: "Machine Learning / Artificial Intelligence",
      value: "Machine Learning / Artificial Intelligence",
    },
    {
      label: "Full Stack Development ",
      value: "Full Stack Development ",
    },
    {
      label: "Web/App  Design ",
      value: "Web/App  Design ",
    },
  ];
  const GraduationDegree = [
    { label: "BE/BTech", value: "Bachelor of Engg./tech" },
    { label: "Bsc", value: 1 },
    { label: "BCA", value: 1 },
    { label: "Others", value: 1 },
  ];
  const year = () => {
    let date = new Date().getFullYear();
    let arr = [];
    for (let i = date + 5; i > 2016; i--) {
      arr.push({ value: i, label: i });
    }
    return arr;
  };

  const Degree = [
    { label: "CSE" },
    { label: "ECE" },
    { label: "IT" },
    { label: "EEE" },
    { label: "MECH" },
  ];
  const preparationlevel = [
    {
      label: "I have no idea where I am.",
      value: "I have no idea where I am.",
    },
    {
      label: "I Haven't started any placement preparation yet!",
      value: "I Haven't started any placement preparation yet!",
    },
    {
      label: "I do have theoretical knowledge but no hands-on experience",
      value: "I do have theoretical knowledge but no hands-on experience",
    },
    {
      label:
        "I have done some projects but have zero knowledge of the interview process",
      value:
        "I have done some projects but have zero knowledge of the interview process",
    },
    {
      label:
        "I am confident about my Coding & Aptitude skills but lack in Soft skills",
      value:
        "I am confident about my Coding & Aptitude skills but lack in Soft skills",
    },
    {
      label: "I am confident about my Aptitude Skills",
      value: "I am confident about my Aptitude Skills",
    },
    {
      label: "I am confident about my Coding Skills",
      value: "I am confident about my Coding Skills",
    },
  ];
  const HelpingHand = [
    {
      label: "I need guidance with getting placed in FAANG companies",
      value: 1,
    },
    {
      label: "I need guidance with cracking Entrance test of Tech companies",
      value: 2,
    },
    {
      label: "I need help in getting practical exposure with good internships",
      value: 3,
    },
    { label: "I need help to prepare for aptitude skills", value: 4 },
    { label: "I need help in upskilling my softskills", value: 5 },
    { label: "I need help with my interview preparation", value: 6 },
    { label: "I need support in project based learning", value: 7 },
  ];
  const [message, setMessage] = useState(false);

  async function isEven(email) {
    // console.log(String(email));
    await fetch(BASE_URL + "/liveVerify", {
      method: "POST",
      body: JSON.stringify({ email: email }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data.message)
        //  data.message
        if (data.message === true) {
          setMessage(true);
          // return true
        } else {
          // console.log("return false")
          // return false

          setMessage(false);
        }
      })
      .catch((err) => {
        // console.log(err);
      });
    // return false
  }
  return (
    <div>
      <h1 className="display-5 d-flex justify-content-center mt-5">
        How to Crack Technical Interviews
      </h1>
      <div
        className={matches ? "App container row " : " App container row p-0"}
        style={{
          marginTop: "10px",
          textAlign: "center",
          fontFamily: "sans-serif",
        }}
      >
        {matches ? (
          <div className="col-lg-6 col-md-4 col-sm-12 d-flex justify-content-center my-auto">
            {/* <pre>{JSON.stringify(watch(), null, 2)}</pre>{" "} */}
            <lottie-player
              src="https://assets8.lottiefiles.com/packages/lf20_9rfdfjek.json"
              background="transparent"
              speed="1"
              // style="width: 300px; height: 300px;"
              style={{ width: "500px", height: "500px" }}
              loop
              autoplay
            ></lottie-player>
          </div>
        ) : (
          ""
        )}

        <div className="col-lg-6 col-md-4 col-sm-12 my-5">
          <section
            className="shadow p-4 rounded section_1 "
            style={{ display: firstForm === true ? "block" : "none" }}
          >
            <div className="form-group">
              <h2
                style={{
                  color: "#185adb",
                  fontWeight: "600",
                  letterSpacing: "0.2px",
                  fontSize: !matches && "1.3rem",
                }}
              >
                Personal Information{" "}
              </h2>
              <label
                htmlFor="FirstName"
                className="d-flex justify-content-start mt-3"
                style={{ textAlign: "left" }}
              >
                Full Name
              </label>
              <input
                id="FirstName"
                className="form-control border rounded"
                placeholder="Full Name"
                {...register("FullName", {
                  required: true,
                })}
              />
              {errors.FullName && (
                <span
                  className="text-danger d-flex justify-content-start"
                  style={{ fontSize: "0.8rem" }}
                >
                  Please enter your Full name
                </span>
              )}
            </div>
            <div>
              <label
                htmlFor="Email"
                className="d-flex justify-content-start mt-3"
                style={{ textAlign: "left" }}
              >
                Email address
              </label>
              <input
                id="Email"
                className="form-control border rounded"
                placeholder="Enter your Email Address"
                {...register("Email", {
                  required: true,
                  pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  validate: (value) => isEven(value),
                })}
              />
              {errors.Email && (
                <span
                  className="text-danger d-flex justify-content-start"
                  style={{ fontSize: "0.8rem" }}
                >
                  Please enter your Email Address properly
                </span>
              )}
              {message == false && (
                <span
                  className="my-0 text-danger d-flex justify-content-start"
                  style={{ fontSize: "0.8rem" }}
                >
                  Your email domain is invalid
                </span>
              )}
            </div>

            <div>
              <label
                htmlFor="Password"
                className="d-flex justify-content-start mt-3"
                style={{ textAlign: "left" }}
              >
                Password
              </label>
              <input
                type="password"
                className="form-control border rounded"
                placeholder="Password"
                {...register("password", {
                  required: true,
                })}
              />
              {errors.password && (
                <small
                  className="text-danger  d-flex justify-content-start"
                  style={{ fontSize: "0.8rem" }}
                >
                  Please enter your Password
                </small>
              )}
            </div>

            <div>
              <label
                className="d-flex justify-content-start mt-3"
                style={{ textAlign: "left" }}
              >
                Phone Number
              </label>
              <PhoneInputWithCountry
                name="PhoneNumber"
                control={control}
                defaultCountry="IN"
                placeholder="Enter your phone number"
                className="form-control border rounded"
                style={{ padding: "5px" }}
                rules={{ required: true, validate: isValidPhoneNumber }}
              />

              {errors.PhoneNumber && (
                <small
                  className="text-danger  d-flex justify-content-start"
                  style={{ fontSize: "0.8rem" }}
                >
                  Please enter your correct Phone Number
                </small>
              )}
            </div>
            <div>
              <label
                className="d-flex justify-content-start mt-3"
                style={{ textAlign: "left" }}
              >
                Enter your College Name
              </label>
              {/* <input
								type='Enter your college Name'
								className='form-control'
								placeholder='CollegeName'
								{...register("CollegeName", {
									required: true,
								})}
							/> */}
              <Controller
                name="CollegeName"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <AsyncSelect
                    isClearable
                    noOptionsMessage={() => "Start typing to find your college"}
                    cacheOptions
                    loadOptions={loadOptions}
                    placeholder="College Name"
                    className="form-control"
                    defaultOptions
                    styles={customStyles}
                    {...field}
                  />
                )}
              />
            </div>
            <div>
              <label
                htmlFor="YearofGrad"
                className="d-flex justify-content-start mt-3"
                style={{ textAlign: "left" }}
              >
                Graduation Year
              </label>
              <Controller
                name="YearofGrad"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    id="YearofGrad"
                    placeholder="Enter your year graduation"
                    className="form-control "
                    options={year()}
                    {...field}
                    styles={customStyles}
                  />
                )}
              />
              {errors.YearofGrad && (
                <small
                  className="text-danger  d-flex justify-content-start"
                  style={{ fontSize: "0.8rem" }}
                >
                  Please choose your Year of Graduation
                </small>
              )}
            </div>
            {/* <div>
              <label
                htmlFor="graduationdegree"
                className="d-flex justify-content-start mt-3"
                style={{ textAlign: "left" }}
              >
                Graduation Degree
              </label>
              <Controller
                name="GraduationDegree"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    id="YearofGrad"
                    placeholder="Enter your year graduation"
                    className="form-control "
                    options={GraduationDegree}
                    {...field}
                    styles={customStyles}
                  />
                )}
              />

              {errors.GraduationDegree && (
                <small
                  className="text-danger  d-flex justify-content-start"
                  style={{ fontSize: "0.8rem" }}
                >
                  Please choose your graduation degree
                </small>
              )}
            </div> */}
            <div class="form-check my-3">
              <input
                type="checkbox"
                defaultChecked={true}
                {...register("Evidence", {
                  required: true,
                })}
                class="form-check-input"
                id="exampleCheck1"
              />
              <label class="form-check-label  " for="exampleCheck1">
                I agree to Cantilever Labs Terms of Service and the use of
                personal data as explained in Cantilever Labs{" "}
                <a href="https://www.cantileverlabs.com/policies#privacy">
                  {" "}
                  terms and conditions{" "}
                </a>
              </label>
            </div>

            <button
              disabled={isValid !== true}
              className="btn btn-primary px-5 my-2"
              onClick={() => {
                changeStates(setFirstForm, false, setSecondForm, true);
                onSubmit();
              }}
            >
              Submit
            </button>
          </section>

          {secondForm === true && (
            <section
              className="shadow p-4 rounded section_2 mt-5"
              style={{ display: secondForm === true ? "block" : "none" }}
            >
              {/* <div>
                <label
                  htmlFor="GraduationDegree"
                  className="d-flex justify-content-start mt-3"
                  style={{ textAlign: "left" }}
                >
                  College Name
                </label>
                <input
                  id="GraduationDegree"
                  className="form-control "
                  placeholder="GraduationDegree"
                  {...register("GraduationDegree", {
                    required: true
                  })}
                />
                {errors.CollegeName && (
                  <small
                    className="text-danger  d-flex justify-content-start"
                    style={{ fontSize: "0.8rem" }}
                  >
                    Specify your College Name
                  </small>
                )}
              </div> */}
              <div>
                <h2
                  style={{
                    color: "#185adb",
                    fontWeight: "600",
                    letterSpacing: "0.2px",
                    fontSize: !matches && "1.3rem",
                  }}
                >
                  {" "}
                  College Details{" "}
                </h2>
                <label
                  htmlFor="graduationdegree"
                  className="d-flex justify-content-start mt-3"
                  style={{ textAlign: "left" }}
                >
                  Graduation Degree
                </label>
                <Controller
                  name="GraduationDegree"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      id="YearofGrad"
                      placeholder="Enter your Graduation degree"
                      className="form-control "
                      options={GraduationDegree}
                      {...field}
                      styles={customStyles}
                    />
                  )}
                />

                {errors.GraduationDegree && (
                  <small
                    className="text-danger  d-flex justify-content-start"
                    style={{ fontSize: "0.8rem" }}
                  >
                    Please choose your Graduation degree
                  </small>
                )}
              </div>
              <div>
                <label
                  htmlFor="Department"
                  className="d-flex justify-content-start mt-3"
                  style={{ textAlign: "left" }}
                >
                  Department
                </label>
                <Controller
                  name="Department"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      id="Department"
                      placeholder="Enter your Department"
                      className="form-control "
                      options={graduation}
                      {...field}
                      styles={customStyles}
                    />
                  )}
                />
                {errors.Department && (
                  <small
                    className="text-danger  d-flex justify-content-start"
                    style={{ fontSize: "0.8rem" }}
                  >
                    Please choose your Department
                  </small>
                )}
              </div>

              <div>
                <label
                  className="d-flex justify-content-start mt-3"
                  style={{ textAlign: "left" }}
                >
                  Enter your City
                </label>
                <Controller
                  name="City"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      isSearchable
                      id="City"
                      placeholder="City"
                      className="form-control"
                      options={optionCities}
                      {...field}
                      styles={customStyles}
                    />
                  )}
                />
              </div>
              <button
                disabled={isValid !== true}
                className="btn btn-primary px-5 my-2"
                onClick={() => {
                  changeStates(setSecondForm, false, setThirdForm, true);
                }}
              >
                Next
              </button>
            </section>
          )}
          {thirdForm === true && (
            <section
              className="shadow p-4 rounded section_3 mt-5"
              style={{ display: thirdForm === true ? "block" : "none" }}
            >
              <div>
                <h2
                  style={{
                    color: "#185adb",
                    fontWeight: "600",
                    letterSpacing: "0.2px",
                    fontSize: !matches && "1.3rem",
                  }}
                >
                  Career Details
                </h2>
                <label
                  className="d-flex  mt-3 mb-1"
                  style={{ textAlign: "left" }}
                >
                  In what areas do you need our help?
                </label>
                <div class="form-check ms-3">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value="Complete Placement Preparation"
                    name="In_what_areas_do_you_need_our_help"
                    id="check1"
                    {...register("In_what_areas_do_you_need_our_help", {
                      required: true,
                    })}
                  />
                  <label
                    class="form-check-label"
                    className="d-flex "
                    for="check1"
                    style={{ textAlign: "left" }}
                  >
                    Complete Placement Preparation
                  </label>
                </div>
                <div class="form-check ms-3">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value="Jobs"
                    id="check2"
                    {...register("In_what_areas_do_you_need_our_help")}
                    name="In_what_areas_do_you_need_our_help"
                  />
                  <label
                    class="form-check-label"
                    for="check2"
                    className="d-flex "
                  >
                    Jobs
                  </label>
                </div>
                <div class="form-check ms-3">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value="Internships"
                    id="check3"
                    {...register("In_what_areas_do_you_need_our_help")}
                    name="In_what_areas_do_you_need_our_help"
                  />
                  <label
                    class="form-check-label"
                    for="check3"
                    className="d-flex "
                  >
                    Internships
                  </label>
                </div>
                <div class="form-check ms-3">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value="Personalised Career Guidance"
                    id="check4"
                    {...register("In_what_areas_do_you_need_our_help")}
                    name="In_what_areas_do_you_need_our_help"
                  />
                  <label
                    class="form-check-label"
                    for="check4"
                    className="d-flex "
                  >
                    Personalised Career Guidance
                  </label>
                </div>
                <div class="form-check ms-3">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value="Free Courses and toolkits"
                    id="check5"
                    {...register("In_what_areas_do_you_need_our_help", {
                      register: true,
                    })}
                    name="In_what_areas_do_you_need_our_help"
                  />
                  <label
                    class="form-check-label"
                    for="check5"
                    className="d-flex "
                  >
                    Free Courses and toolkits
                  </label>
                </div>
                <div class="form-check ms-3">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value="Cracking Screening test of companies"
                    id="check6"
                    {...register("In_what_areas_do_you_need_our_help")}
                    name="In_what_areas_do_you_need_our_help"
                  />
                  <label
                    class="form-check-label"
                    for="check6"
                    className="d-flex "
                    style={{ textAlign: "leftpap" }}
                  >
                    Cracking Screening test of companies
                  </label>
                </div>
                <div class="form-check ms-3">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value="others"
                    id="check7"
                    {...register("In_what_areas_do_you_need_our_help")}
                    name="In_what_areas_do_you_need_our_help"
                    onChange={() => {
                      setIsOthers((prev) => !prev);
                    }}
                  />
                  <label
                    class="form-check-label"
                    for="check7"
                    className="d-flex "
                  >
                    Others
                  </label>
                </div>
                {isOthers && (
                  <>
                    <label
                      htmlFor="others_input"
                      className="d-flex justify-content-start mt-3"
                      style={{ textAlign: "left" }}
                    >
                      If others please mention
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="others_input"
                      placeholder="If others please mention"
                      {...register("Others")}
                    />
                  </>
                )}
                {errors.In_what_areas_do_you_need_our_help && (
                  <small
                    className="text-danger  d-flex justify-content-start"
                    style={{ fontSize: "0.8rem" }}
                  >
                    Please choose the areas where you need our help
                  </small>
                )}
                {/* {errors.Department && (
									<small
										className='text-danger  d-flex justify-content-start'
										style={{ fontSize: "0.8rem" }}>
										Please choose your Department
									</small>
								)} */}
                <label
                  htmlFor="targeted_companies"
                  className="d-flex justify-content-start mt-3"
                  style={{ textAlign: "left" }}
                >
                  Which companies are you targeting?
                </label>
                <Controller
                  name="targeted_companies"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      id="targeted_companies"
                      placeholder="Which companies are you targeting?"
                      className="form-control "
                      options={targeted_companied_option}
                      {...field}
                      styles={customStyles}
                    />
                  )}
                />
                {errors.targeted_companies && (
                  <small
                    className="text-danger  d-flex justify-content-start"
                    style={{ fontSize: "0.8rem" }}
                  >
                    Please choose your targeted Companies
                  </small>
                )}
                <label
                  htmlFor="placement_time"
                  className="d-flex justify-content-start mt-3"
                  style={{ textAlign: "left" }}
                >
                  How much time do you have for your On-campus/Off-campus
                  Placements?
                </label>
                <Controller
                  name="placement_time"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      id="placement_time"
                      placeholder="Choose from the options "
                      className="form-control "
                      options={placement_time_option}
                      {...field}
                      styles={customStyles}
                    />
                  )}
                />
                {errors.placement_time && (
                  <small
                    className="text-danger  d-flex justify-content-start"
                    style={{ fontSize: "0.8rem" }}
                  >
                    Please choose atleast one of the options
                  </small>
                )}
                <label
                  htmlFor="interests"
                  className="d-flex justify-content-start mt-3"
                  style={{ textAlign: "left" }}
                >
                  Tell us about your interests.
                </label>
                <Controller
                  name="interests"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <CreatableSelect
                      id="interests"
                      isMulti
                      placeholder="Choose from the options "
                      className="form-control "
                      options={interests_option}
                      {...field}
                      styles={customStyles}
                    />
                  )}
                />
                {errors.interests && (
                  <small
                    className="text-danger  d-flex justify-content-start"
                    style={{ fontSize: "0.8rem" }}
                  >
                    Please choose atleast one of the options
                  </small>
                )}
              </div>

              <button
                disabled={isValid == false}
                className="btn btn-primary px-5 my-2"
                onClick={() => {
                  changeStates(setThirdForm, false, setthanks, true);
                  onSubmitTotal();
                }}
              >
                Submit
              </button>
            </section>
          )}

          {thanks === true && (
            <section className=" congratulations shadow p-4 rounded mt-5 ">
              {showInterested === true && (
                <PopupAd
                  CloseTheButton={CloseTheButton}
                  PushTheUser={PushTheUser}
                  InterestedUser={InterestedUser}
                />
              )}
              <div className="d-flex justify-content-center">
                <lottie-player
                  src="https://assets1.lottiefiles.com/packages/lf20_oUXj84.json"
                  background="transparent"
                  speed="1"
                  style={{ width: "150px", height: "150px" }}
                  autoplay
                ></lottie-player>
              </div>
              <p> Thank you for registering with us </p>
            </section>
          )}
        </div>
      </div>
    </div>
  );
}

import React from 'react'
import styles from "../Assets/css/signup.module.css";
import style from "../Assets/css/TCSform.module.css";
import success_img from "../Assets/img/success_vector.svg";
import ReactGA from "react-ga";
const Thanks = () => {
  ReactGA.initialize("UA-198180460-1");
    return (
        <div>
        
            <div
              className="container-fluid p-5 my-auto mx-auto my-auto"
              style={{ borderRadius: "15px" }}
            >
              <div className="container d-flex flex-column justify-content-center align-items-center">
                {/* <img
                  src={success_img}
                  alt="success"
                  style={{ width: "200px" }}
                /> */}
                <script src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"></script>
<lottie-player src="https://assets8.lottiefiles.com/packages/lf20_Vwcw5D.json"  background="transparent"  speed="1"  style={{width: "300px",height: "300px"}}  loop autoplay></lottie-player>
                <h2 className="text-primary">You're Successfully Registered.</h2>
                <p
                  style={{ color: "gray", width: "10%", color: "#111" }}
                  className={styles.horizontalh + " my-3"}
                ></p>
                <p className="text-center">
                  <strong>
                    {/* Congrats
                     {firstName}
                    ! You’ve cracked the first step. <br />
                    Allow us to serve you better by filling in the rest. */}
                    Thank you for registering for the Cantilever Labs Mentor Connect Session!<br/>
                    We will reach out to you with the next steps within 24 hours.<br/>
                    In case of any queries, feel free to contact us at studentsupport@cantileverlabs.com
                  </strong>
                </p>
                <br/>
                <a
                  href='/'
                  className={`btn btn-primary ${style.next_btn}`}
                  onClick={() => {
                    // setSecondForm(true);
                    ReactGA.event({
                      category: "TCS Sign Up Form",
                      action: "First signup form filled",
                    });
                  }}
                  >
                  Explore More
                </a>
              </div>
            </div>
            {/* (
            <div
              className="container-fluid p-5 my-auto mx-auto "
              style={{ borderRadius: "15px" }}
            >
              <div className="container d-flex flex-column justify-content-center align-items-center">
                <img src={success_img} alt="success" />
                <h4 className="text-primary">You're Successfully Signed In.</h4>
                <p
                  style={{ color: "gray", width: "10%", color: "#111" }}
                  className={styles.horizontalh + " my-3"}
                ></p>
                <p className="text-center">
                  <strong>
                    Congrats {firstName}! You’ve cracked the first step. <br />
                    Allow us to serve you better by filling in the rest.
                  </strong>
                </p>
                <button
                  className={`${style.newbtn} btn btn-primary`}
                  onClick={() => {
                    // setSecondForm(true);
                    setThanks(false);
                  }}
                >
                  Next
                </button>
              </div>
            </div>
          ) */}
        </div>
    )
}

export default Thanks

import styles1 from "../Assets/css/cpcourse.module.css";
// import SM from "../Imgsofcourse/images/Image 1.png";
import useMedia from "../componentsofcourse/useMedia";
const Maintestimonial=(props)=>{
  const matches = useMedia("(min-width : 768px )");
  return(

 <div className="" style={{ backgroundColor: "#185ADB",background: `${props.background}` }}>
     
        <br />
        <div className="row" style={{ marginLeft: matches ? "12%" : "6%" }}>
          <div className="col-md-4 col-xs-12 col-12 col-12 justify-content-center pb-2">
            <img
              src={'https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644209505670_Image%201.png'}
              className="img-fluid"
              style={{ marginLeft: matches == false ? "3%" : null }}
            />
          </div>
          <div
            style={{ textAlign: "left" }}
            className="col-md-6 col-xs-12 col-12 col-12"
          >
            <div>
              <h4 style={{ color: "white" }}>SARVESH MEHTANI</h4>
            </div>
            <div>
              <h5 style={{ color: "white" }}>IIT Bombay - Computer Science</h5>
            </div>
            <br />
            <div>
              <p style={{ color: "white" }}>
                Overall, I loved the fact that the whole process was tailored as
                per the individual's interests and goals which made it very
                effective in pointing out our areas of strength and the areas
                that we need to work on.
              </p>
            </div>

            <br />
            <br />
            <div>
              <button className={styles1.sarveshbtn} style={{color:"#185ADB"}}>
                A.I.R 1, JEE ADVANCED 2017
              </button>
            </div>
          </div>
        </div>
      </div>
  )
}
export default Maintestimonial
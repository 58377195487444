import React from 'react'

const OnetoOnebooking = () => {
    return (
        <div>
            
<div class="calendly-inline-widget" data-url="https://calendly.com/iitb_mock1" style={{minWidth:"320px",height:"630px"}}></div>
<script type="text/javascript" src="https://assets.calendly.com/assets/external/widget.js" async></script>

        </div>
    )
}

export default OnetoOnebooking

import React from "react";
import styles from "../Assets/css/test.module.css";
import Assistance from "../Assets/img/Competitive/Assistance.png";
// import RectangleX from "../Assets/img/Home/Rectangle 1032.png";
// import RectangleY from "../Assets/img/course/Rectangle 1027.png";
function PlacementCourseImages() {
  return (
    <div className="container-fluid" style={{ backgroundColor: "#00a2c8" }}>
      <div className="row">
        <div className="col-md-1 col-xs-12 col-12 col-12"></div>
        <div className="col-md-4 col-xs-12 col-12 col-12">
          <img
            src={Assistance}
            className="img-fluid"
            //   className={`img-fluid ${styles.imagefull}`}
            style={{ marginTop: "-30px", marginBottom: "-20px" }}
          />
        </div>
        <div
          className="col-md-5 col-xs-12 col-12 col-12"
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <br />
          <br />
          <h1 style={{ color: "white", fontWeight: "600" }}>
            100% Job Assistance
          </h1>
          <br />
          <a
            href="#ptable"
            style={{
              fontSize: "20px",
              borderRadius: "25px",
              width: "40%",
              fontWeight: "bold",
            }}
            className="btn btn-warning text-white "
          >
            Enroll Now
          </a>
        </div>
        <div className="col-md-2 col-xs-12 col-12 col-12"></div>
      </div>
    </div>
  );
}

export default PlacementCourseImages;

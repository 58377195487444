import { createContext, useState } from "react";
// import configAxios from "../axios.config";

export const AuthContext = createContext();

function AuthContextProvider(props) {
  const [token, setToken] = useState("");
  const [_id, set_Id] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [savedJobs, setSavedJobs] = useState([]);
  const [appliedJobs, setAppliedJobs] = useState([]);
  const [show, setShow] = useState(true);
  const [coursesenrolled, setcoursesenrolled] = useState([]);
  const [userid, setuserid] = useState("");
  const [user, setuser] = useState("");
  const [userData, setUserData] = useState({});

  function update_token(tkn) {
    setToken(tkn);
  }
  function set_email(email) {
    setEmail(email);
  }
  function set_name(name) {
    setName(name);
  }
  function set_user(user_complete) {
    setuser(user_complete);
  }

  return (
    <AuthContext.Provider
      value={{
        update_token,
        token,
        _id,
        set_Id,
        set_email,
        email,
        name,
        set_name,
        appliedJobs,
        setAppliedJobs,
        savedJobs,
        setSavedJobs,
        show,
        setShow,
        userid,
        setuserid,
        setcoursesenrolled,
        coursesenrolled,
        user,
        set_user,
        userData,
        setUserData,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
}

export default AuthContextProvider;

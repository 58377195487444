
import React from 'react'
import styles1 from '../Assets/css/personalinfo.module.css'
import styles from '../Assets/css/masterclasssuccess.module.css';
import { Link} from 'react-router-dom';
import { useState } from 'react';
const MasterclassSuccess = (props) => {
  return (
    <div
      className={"container-fluid" + styles.body}
      style={{ marginLeft: "3%", marginRight: "3%", marginTop: "3%" }}
    >
      <div className="container-fluid  justify-content-center">
        <div className="container-fluid justify-content-center mb-3">
          <div className="row justify-content-center ">
            <div
              style={{
                borderRadius: "2%",
                backgroundColor: "white",
                boxShadow: "22px 40px 50px 43px rgba(120, 120, 120, 0.25)",
              }}
              className={
                "col-xl-12 col-lg-12 col-md-12 col-sm-12 " +
                styles1.form2J +
                styles1.secondpartformJ
              }
            >
              <div className="row">
                <div
                  className={
                    "col-xl-8 col-lg-10 col-md-11 col-sm-12 col-11 " +
                    styles.registration_confirm_div
                  }
                >
                  <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 130.2 130.2"
                    style={{width:"fit-content"}}
                  >
                    <circle
                      className="path circle"
                      fill="none"
                      stroke="#73AF55"
                      stroke-width="6"
                      stroke-miterlimit="10"
                      cx="65.1"
                      cy="65.1"
                      r="62.1"
                    />
                    <polyline
                      className="path check"
                      fill="none"
                      stroke="#73AF55"
                      stroke-width="6"
                      stroke-linecap="round"
                      stroke-miterlimit="10"
                      points="100.2,40.2 51.5,88.8 29.8,67.5 "
                    />
                  </svg>

	                <h1 class={styles.registration_confirm_text}>Thank you for registering with us.</h1>
									<hr class={styles.register_hr}/>
                <p class={styles.registration_confirm_text2} style={{fontWeight:"600"}} > 
                The classes will commence from 26th july 2021 (Monday). <br />
                 We will notify you over Email  </p>
                <div class="d-flex justify-content-center">
										<Link to="/#MOVEHERE"><button class={styles.explore_btn}>EXPLORE</button></Link>
                    <Link to="/"><button class={styles.done_btn}>HOME</button></Link>
                </div>
            </div>
        </div>



		</div>
            </div>
        </div>
    </div>
    </div>

	);
}
export default MasterclassSuccess;

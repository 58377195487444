import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { BASE_URL } from "../../env";
import { Store } from "react-notifications-component";
import Navbar from "../Navbar";
import Footer from "../Footer";
import { useLocation } from "react-router-dom";
import { set } from "react-ga";
import useMedia from "../../componentsofcourse/useMedia";

const B2BForms = (props) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm();

  const location = useLocation();

  // console.log(location)
  // console.log(props)
  const DesignationField = watch(["Designation"]);
  const [allOptions, setAlloptions] = useState();
  const watchAllfields = watch();
  const [showOther, setShowOther] = useState(false);
  	const matches = useMedia("(min-width : 768px )");


  useEffect(() => {
    // console.log(watchAllfields);
  }, [watch]);

  useEffect(() => {
    window.scroll(0,0)
  },[])
  useEffect(() => {
    // console.log(DesignationField);
    if (DesignationField == "Others") {
      setShowOther(true);
    } else {
      setShowOther(false);
    }
    return () => {};
  }, [DesignationField]);

  useEffect(() => {
    // console.log(location.state.checkBoxes);
    setAlloptions(location.state.checkBoxes);
  }, [location]);
  const onSubmit = (data) => {
    // console.log()

    const value = {
      ...data,
      allOptions,
    };
    // console.log(value)
    // console.log("Submit reponse", JSON.stringify(value));
    fetch(BASE_URL + "/b2bform", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(value),
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
      });

    reset();
    notifyPopup();
  };

  const notifyPopup = () => {
    Store.addNotification({
      title: "Success",
      message: "Your data has been sent",
      type: "danger",
      background: "pink",
      insert: "bottom",
      container: "bottom-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true,
      },
    });
  };
  return (
    <div style={{overflowX:"hidden"}}>
      {/* <Navbar /> */}
      <div
        className="container  row"
        style={{ marginTop: "80px", marginBottom: "80px" }}
      >
        <div className="animation col-lg-4 col-md-4 col-sm">
          <lottie-player
            src="https://assets2.lottiefiles.com/packages/lf20_hzfmxrr7.json"
            background="transparent"
            speed="1"
            style={{ width: matches ? "500px": "300px",  height:matches? "500px":"300px" }}
            autoplay
          ></lottie-player>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-5">
          <h3 className="d-flex justify-content-start mx-5">
            {" "}
            Book your Free Session{" "}
          </h3>
          <form
            className="shadow p-4"
            onSubmit={handleSubmit(onSubmit)}
            style={{ borderRadius: "15px" }}
          >
            <div class="form-group my-2 mx-5 ">
              <label for="InputName">Name</label>
              <input
                placeholder="Enter your Name"
                type="text"
                class="form-control"
                id="InputName"
                aria-describedby="emailHelp"
                {...register("Name", { required: true })}
              />
              {/* <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> */}
            </div>
            <div class="form-group my-2 mx-5">
              <label for="InputEmail">Email address</label>
              <input
                placeholder="Enter your Email address"
                type="email"
                class="form-control"
                id="InputEmail"
                aria-describedby="emailHelp"
                {...register("email", { required: true })}
              />
              {/* <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> */}
            </div>
            <div class="form-group my-2 mx-5">
              <label for="InputPhone">Phone Number</label>
              <input
                placeholder="Enter your phone number"
                type="number"
                class="form-control"
                id="InputPhone"
                aria-describedby="emailHelp"
                {...register("PhoneNumber", { required: true })}
              />
              {/* <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> */}
            </div>
            <div class="form-group my-2 mx-5">
              <label for="InputCollege">College Name</label>
              <input
                placeholder="Enter your College Name"
                type="text"
                class="form-control"
                id="InputCollege"
                aria-describedby="emailHelp"
                {...register("CollegeName", { required: true })}
              />
              {/* <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> */}
            </div>
            <div class="form-group my-2 mx-5">
              <label for="InputCity">City/Town</label>
              <input
                placeholder="Enter your city"
                type="text"
                class="form-control"
                id="InputCity"
                aria-describedby="emailHelp"
                {...register("City", { required: true })}
              />
              {/* <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> */}
            </div>
            <div class="form-group my-2 mx-5">
              <label for="exampleInputEmail1">Designation</label>

              {/* <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> */}

              <select
                placeholder="select one option"
                className="form-control"
                {...register("Designation")}
              >
                <option value="Director">Director</option>
                <option value="Dean">Dean</option>
                <option value="Principal">Principal</option>
                <option value="HOD">HOD</option>
                <option value="Training and placement head">
                  Training and placement head
                </option>
                <option value="Training and placement officer">
                  Training and placement officer
                </option>
                <option value="Professor">Professor</option>
                <option value="Professor">Professor</option>
                <option value="Student">Student</option>
                <option value="Others">Others</option>
              </select>
            </div>

            {showOther == true && (
              <>
                <div class="form-group my-2 mx-5">
                  <label for="InputDesignation">Mention your Designation</label>
                  <input
                    type="text"
                    class="form-control"
                    id="InputDesignation"
                    aria-describedby="emailHelp"
                    {...register("OtherDesignation")}
                  />
                  {/* <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> */}
                </div>
              </>
            )}

            <div className="form-group mx-5 my-2">
              <input
                type="submit"
                className="mx-2 btn btn-primary"
                value="SUBMIT"
              />
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default B2BForms;

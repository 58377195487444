import React, { useState } from "react";
import ash from "../Assets/img/person/ayushi.png";
import av from "../Assets/img/person/Abhijeet Vichare.jpg";
import ap from "../Assets/img/person/AKASH PATIL.jpg";
import aa from "../Assets/img/person/ANISHA ARORA.jpg";
import mk from "../Assets/img/person/MUSKAAN KOCHAR.jpg";
// import nr from "../Assets/img/person/NEHA RAMYA.jpg";
// import sn from "../Assets/img/person/SAI NITISH.jpg";
import left from "../Assets/img/Home/Left.png";
import right from "../Assets/img/Home/Right.png";
import styles from "../Assets/css/test.module.css";
// import useMedia from "./useMedia";
function Testimonials() {
  let [select, setSelected] = useState(2);
  let [leftIndex, setleftIndex] = useState(1);
  let [leftMostIndex, setleftMostIndex] = useState(0);
  let [rightIndex, setrightIndex] = useState(3);
  let [rightMostIndex, setrightMostIndex] = useState(4);

  //const pic =
  // "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500";

  const data = [
    {
      text:
        "The training did not only offer me data based reflection but also gave me an insight into improvement to the informative sessions by extremely qualified resource persons.",
      name: "AYUSHI SHARMA",
      role: "Associate business analyst, flipkart. BITS pilani",
      pic: ash,
    },
    {
      text:
        "I was mentored by Cantilever Labs during our placement season at BITS. The Aptitude preparation really helped me. Case studies were amazing and so were guesstimates.",
      name: "ABHIJEET VICHARE",
      role: "Data Scientist at MERILYTICS. BITS pilani",
      pic: av,
    },
    {
      text:
        "I recently got placed in EXL analytics Gurgaon and it all happened because of Cantilever labs. They helped me a lot in resume building, puzzles and overall soft skills development.",
      name: "ANISHA ARORA",
      role: "Bachelor of Engineering. Chitkara University",
      pic: aa,
    },
    {
      text:
        "I was one of the Students who was mentored by Cantilever Labs during our placement season at BITS Hyderabad. This training proved pivotal in helping me get placed in a fantastic company.",
      name: "AKASH PATIL",
      role: "Analyst at NOMURA. BITS pilani",
      pic: ap,
    },

    {
      text:
        "The training sessions have been crisp and insightful. It covered aspects which are often overlooked or considered too obvious to be mentioned.",
      name: "MUSKAAN KOCHAR",
      role: " IIT BOMBAY – Chemistry",
      pic: mk,
    },
  ];
  //

  let leftClick = () => {
    if (select === 0) {
      setSelected(4);
      setleftIndex(3);
      setleftMostIndex(leftMostIndex - 1);
      setrightIndex(rightIndex - 1);
      setrightMostIndex(rightMostIndex - 1);
    } else if (select === 1) {
      setSelected(0);
      setleftIndex(4);
      setleftMostIndex(leftMostIndex - 1);
      setrightIndex(rightIndex - 1);
      setrightMostIndex(rightMostIndex - 1);
    } else if (select === 2) {
      setSelected(select - 1);
      setleftIndex(leftIndex - 1);
      setleftMostIndex(4);
      setrightIndex(rightIndex - 1);
      setrightMostIndex(rightMostIndex - 1);
    } else if (select === 3) {
      setSelected(select - 1);
      setleftIndex(leftIndex - 1);
      setleftMostIndex(leftMostIndex - 1);
      setrightIndex(rightIndex - 1);
      setrightMostIndex(4);
    } else if (select === 4) {
      setSelected(select - 1);
      setleftIndex(leftIndex - 1);
      setleftMostIndex(leftMostIndex - 1);
      setrightIndex(4);
      setrightMostIndex(rightMostIndex - 1);
    }
  };

  let rightClick = () => {
    if (select === 4) {
      setSelected(0);
      setleftIndex(leftIndex + 1);
      setleftMostIndex(leftMostIndex + 1);
      setrightIndex(rightIndex + 1);
      setrightMostIndex(rightMostIndex + 1);
    } else if (select === 3) {
      setSelected(select + 1);
      setleftIndex(leftIndex + 1);
      setleftMostIndex(leftMostIndex + 1);
      setrightIndex(0);
      setrightMostIndex(rightMostIndex + 1);
    } else if (select === 2) {
      setSelected(select + 1);
      setleftIndex(leftIndex + 1);
      setleftMostIndex(leftMostIndex + 1);
      setrightIndex(rightIndex + 1);
      setrightMostIndex(0);
    } else if (select === 1) {
      setSelected(select + 1);
      setleftIndex(leftIndex + 1);
      setleftMostIndex(0);
      setrightIndex(rightIndex + 1);
      setrightMostIndex(rightMostIndex + 1);
    } else if (select === 0) {
      setSelected(select + 1);
      setleftIndex(0);
      setleftMostIndex(leftMostIndex + 1);
      setrightIndex(rightIndex + 1);
      setrightMostIndex(rightMostIndex + 1);
    }
  };

  return (
    <div style={{ marginTop: "100px", marginBottom: "100px" }}>
      <div className={styles.outercontainer}>
        {/* <div className="center-tesimonial-text">
          {data.map((d, index) => (
            <div>
              {index == select && (
                <div>

                  <div className="testimonial-text">
                    <p className="testimonial-word">

                       {data[index].text}

                    </p>
                    <div className="testimonial-word-name">
                      {data[index].name}
                    </div>
                    <div className="testimonial-role"> {data[index].role}</div>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div> */}
        {/* <hr
          style={{
            width: "70%",
            marginLeft: "17%",
            height: "2px",
            marginTop: "0",
          }}
        ></hr> */}

        <div className={styles.testimonialcontainer}>
          <div>
            {data.map((d, index) => (
              <div>
                {index === leftMostIndex && (
                  <div>
                    {" "}
                    {/* {data[index].text} */}
                    {/* {data[index].name} */}
                    {/* {data[index].role} */}
                    <img
                      style={{ marginLeft: "40px" }}
                      className={`${styles.imgresponsive} ${styles.side}`}
                      src={data[index].pic}
                      alt=""
                    />
                  </div>
                )}
              </div>
            ))}
          </div>
          <div>
            {data.map((d, index) => (
              <div>
                {index === leftIndex && (
                  <div>
                    {" "}
                    {/* {data[index].text} */}
                    {/* {data[index].name} */}
                    {/* {data[index].role} */}
                    <img
                      style={{ marginLeft: "40px" }}
                      className={`${styles.imgresponsive} ${styles.side}`}
                      src={data[index].pic}
                      alt="data"
                    />
                  </div>
                )}
              </div>
            ))}
          </div>

          <div className="center-tesimonial">
            {data.map((d, index) => (
              <div>
                {index === select && (
                  <div>
                    <img
                      style={{ marginLeft: "40px" }}
                      className={`${styles.imgresponsive} ${styles.centerimagetestimonial}`}
                      src={data[index].pic}
                      alt=""
                    />
                  </div>
                )}
              </div>
            ))}
          </div>
          <div>
            {data.map((d, index) => (
              <div>
                {index === rightIndex && (
                  <div>
                    {" "}
                    {/* {data[index].text} */}
                    {/* {data[index].name} */}
                    {/* {data[index].role} */}
                    <img
                      style={{ marginLeft: "40px" }}
                      className={`${styles.imgresponsive} ${styles.side}`}
                      src={data[index].pic}
                      alt=""
                    />
                  </div>
                )}
              </div>
            ))}
          </div>
          <div>
            {data.map((d, index) => (
              <div>
                {index === rightMostIndex && (
                  <div>
                    {" "}
                    {/* {data[index].text} */}
                    {/* {data[index].name} */}
                    {/* {data[index].role} */}
                    <img
                      style={{ marginLeft: "40px" }}
                      className={`${styles.imgresponsive} ${styles.side}`}
                      src={data[index].pic}
                      alt="dataphoto"
                    />
                  </div>
                )}
              </div>
            ))}
          </div>
          {/* <img
            className="right-testimonial"
            onClick={rightClick}
            src={right}
            style={{
              position: "relative",
              height: "20px",
              top: "11vw",
              left: "3vw",
            }}
          /> */}
        </div>
        <div style={{ display: "flex" }}>
          <img
            className={styles.lefttestimonial}
            onClick={leftClick}
            src={left}
            alt={left}
            style={{
              position: "relative",
              width: "1.5%",
              height: "0%",
              left: "15vw",
              // top: "255px",
              // right: "5vw",
              // height: "20px",
              // bottom: "5%",
              // top: "5vw",
              // right: "10vw",
            }}
          />

          <div
            className={styles.centertesimonialtext}
            // style={{ paddingRight: matches ? null : "35px" }}
          >
            {data.map((d, index) => (
              <div>
                {index === select && (
                  <div>
                    {" "}
                    <div className="testimonial-text">
                      <p className={styles.testimonialword}>
                        {" "}
                        {/* <blockquote> */} {data[index].text}
                        {/* </blockquote> */}
                      </p>{" "}
                      <div className={styles.testimonialwordname}>
                        {data[index].name}
                      </div>
                      <div className={styles.testimonialrole}>
                        {" "}
                        {data[index].role}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
          <img
            className={styles.righttestimonial}
            onClick={rightClick}
            src={right}
            alt="right"
            style={{
              position: "relative",
              width: "1.5%",
              height: "0%",
              left: "22vw",
              // height: "20px",
              // top: "11vw",
              // left: "3vw",
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default Testimonials;

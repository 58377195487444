export const BarData = {
  "62d63c31bc0f60e6ac1694a6": {
    labels: ["Average Scores"],
    datasets: [
      {
        label: "assignment score",
        data: [45],
        backgroundColor: "#FF5858",
        // borderColor: "#FF5858",
        borderWidth: 1,
        borderRadius: 20,
        topLeft: 5,
      },
      {
        label: "test score",
        data: [55],
        backgroundColor: "#FFB636",
        // borderColor: "#FFB636",
        borderWidth: 1,
        borderRadius: 20,
        topLeft: 5,
      },
      {
        label: "attendance score",
        data: [72],
        backgroundColor: "#24B47E",
        // borderColor: "#24B47E",
        borderWidth: 1,
        borderRadius: 20,
        topLeft: 5,
      },
    ],
  },
  "62d63901bc0f60e6ac1694a4": {
    labels: ["Average Scores"],
    datasets: [
      {
        label: "assignment score",
        data: [72],
        backgroundColor: "#FF5858",
        borderColor: "#FF5858",
        borderWidth: 1,
        borderRadius: 20,
        topLeft: 5,
      },
      {
        label: "test score",
        data: [81],
        backgroundColor: "#FFB636",
        borderColor: "#FFB636",
        borderWidth: 1,
        borderRadius: 20,
        topLeft: 5,
      },
      {
        label: "attendance score",
        data: [63],
        backgroundColor: "#24B47E",
        borderColor: "#24B47E",
        borderWidth: 1,
        borderRadius: 20,
        topLeft: 5,
      },
    ],
  },
  "62d63c40bc0f60e6ac1694a7": {
    labels: ["Average Scores"],
    datasets: [
      {
        label: "assignment score",
        data: [58],
        backgroundColor: "#FF5858",
        borderColor: "#FF5858",
        borderRedius: 20,
      },
      {
        label: "test score",
        data: [43],
        backgroundColor: "#FFB636",
        borderColor: "#FFB636",
        borderRedius: 20,
      },
      {
        label: "attendance score",
        data: [69],
        backgroundColor: "#24B47E",
        borderColor: "#24B47E",
        borderRedius: 20,
      },
    ],
  },
  "62d63bb5bc0f60e6ac1694a5": {
    labels: ["Average Scores"],
    datasets: [
      {
        label: "assignment score",
        data: [77],
        backgroundColor: "#FF5858",
        borderColor: "#FF5858",
        borderRedius: 20,
      },
      {
        label: "test score",
        data: [90],
        backgroundColor: "#FFB636",
        borderColor: "#FFB636",
        borderRedius: 20,
      },
      {
        label: "attendance score",
        data: [84],
        backgroundColor: "#24B47E",
        borderColor: "#24B47E",
        borderRedius: 20,
      },
    ],
  },
};

export const ProgressData = {
  "62d63c31bc0f60e6ac1694a6": {
    assignments: {
      total: 23,
      count: 10,
      percent: (10 / 23) * 100,
    },
    classes: {
      total: 29,
      count: 15,
      percent: (15 / 29) * 100,
    },
    tests: {
      total: 9,
      count: 3,
      percent: (3 / 9) * 100,
    },
  },
  "62d63901bc0f60e6ac1694a4": {
    assignments: {
      total: 23,
      count: 8,
      percent: (8 / 23) * 100,
    },
    classes: {
      total: 29,
      count: 13,
      percent: (13 / 29) * 100,
    },
    tests: {
      total: 9,
      count: 2,
      percent: (2 / 9) * 100,
    },
  },
  "62d63c40bc0f60e6ac1694a7": {
    assignments: {
      total: 23,
      count: 15,
      percent: (15 / 23) * 100,
    },
    classes: {
      total: 29,
      count: 18,
      percent: (18 / 29) * 100,
    },
    tests: {
      total: 9,
      count: 5,
      percent: (5 / 9) * 100,
    },
  },
  "62d63bb5bc0f60e6ac1694a5": {
    assignments: {
      total: 23,
      count: 6,
      percent: (6 / 23) * 100,
    },
    classes: {
      total: 29,
      count: 9,
      percent: (9 / 29) * 100,
    },
    tests: {
      total: 9,
      count: 1,
      percent: (1 / 9) * 100,
    },
  },
};

export const OverallTopStudents = {
  "62d63c31bc0f60e6ac1694a6": [
    ["Raghu Tamboli", 715],
    ["Sunder Chaudhri", 712],
    ["Sneha Gadhavi", 701],
    ["Nirav Mhasalkar", 697],
    ["Nirav Mhasalkar", 696],
  ],
  "62d63901bc0f60e6ac1694a4": [
    ["Trishna Mishra", 529],
    ["Sanjit Das", 525],
    ["Riya Kulkarni", 522],
    ["Gauri Mishra", 522],
    ["Chandra Sharma", 519],
  ],
  "62d63c40bc0f60e6ac1694a7": [
    ["Gowri Ahmed", 415],
    ["Punit Vemulakonda", 411],
    ["Shubham Das", 408],
    ["Chandana Kaur", 403],
    ["Amita Joshi", 399],
  ],
  "62d63bb5bc0f60e6ac1694a5": [
    ["Amita Jain", 935],
    ["Jayanthi Das", 929],
    ["Vasuda Kumar", 925],
    ["Lina Chaudhary", 920],
    ["Siddhi Singh", 919],
  ],
};

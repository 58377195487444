import React from "react";
import styles from "../Assets/css/personalinfo.module.css";
// import Navbar from "../components/Navbar";
import vector5 from "../Assets/img/vector5.png";
import cantileverlabslogo from "../Assets/img/cantileverlabslogo.png";
import arrow1 from "../Assets/img/arrow1.png";
import arrow2 from "../Assets/img/arrow2.png";
import youtubeMasterClass from "../Assets/img/YoutubeMasterClass.png";
import linkedinMasterClass from "../Assets/img/LinkedinMasterClass.png";
import facebookMasterClass from "../Assets/img/FacebookMasterclass.png";
import instaMasterClass from "../Assets/img/InstaMasterClass.png";
import { useState } from "react";
const CollegeDetails = (props) => {
  const {
    graduation,
    department,
    year,
    semester,
    CollegeName,
    City,
    HandleTheSubmit,
    setgraduation,
    setdepartment,
    setyear,
    setSemester,
    setCollegeName,
    setCity,
  } = props.values;

  const [error, seterror] = useState([]);
  const [yes, setyes] = useState(false);

  function HandleValidation() {
    seterror([[]]);
    if (graduation === "") {
      seterror((error) => [...error, ["please select the graduation"]]);
    }
    if (department === "") {
      seterror((error) => [...error, ["please select the department"]]);
    }
    if (yes === false) {
      if (year === "") {
        seterror((error) => [...error, ["please enter the year"]]);
      }

      if (year > 4) {
        seterror((error) => [...error, ["please enter a valid year"]]);
      }
    }
    if (semester === "") {
      seterror((error) => [...error, ["please enter the semester"]]);
    }
    if (CollegeName === "") {
      seterror((error) => [...error, ["please enter your college name"]]);
    }
    if (City === "") {
      seterror((error) => [...error, ["please enter the city name"]]);
    }
    if (yes === false) {
      if (
        (graduation !== "") &
          (department !== "") &
          (year !== "") &
          (semester !== "") &&
        CollegeName !== "" &&
        City !== ""
      ) {
        seterror("");
        return true;
      }
    } else {
      if (
        (graduation !== "") & (department !== "") &&
        CollegeName !== "" &&
        City !== ""
      ) {
        seterror("");

        return true;
      }
    }

    if (error === "") {
      return true;
    }
  }
  return (
    <div className="container-fluid  justify-content-center ">
      <div
        className="container-fluid  justify-content-center"
        style={{ paddingLeft: "0px", paddingRight: "0px" }}
      >
        <div
          className={styles.formhdJ + " d-flex justify-content-center my-5"}
          style={{ paddingTop: "5%" }}
        >
          <img className="mr-3" src={vector5} alt="" />
          <p style={{ marginLeft: "3%" }}> {props.Heading} </p>
        </div>

        <div className="container-fluid justify-content-center mb-3">
          <div className="row justify-content-center ">
            <div
              className={
                "col-xl-3 col-lg-3 col-md-5 col-sm-12 py-5 px-5 " +
                styles.form1J +
                " " +
                styles.purpleformJ
              }
            >
              <div className="row">
                <p
                  style={{ fontSize: "1.2rem" }}
                  className={styles.plaintextJ + " p-0"}
                >
                  {props.Heading}
                </p>
              </div>
              <div className="row">
                <div className="mb-0 p-0">
                  {/* 21st June, 2021  5:00PM - 6:00PM */}
                  {props.Date === undefined ? (
                    <>
                      {" "}
                      {props.List.map((item, i) => {
                        return (
                          <li
                            key={i}
                            style={{ fontSize: "0.8rem", display: "block" }}
                          >
                            {" "}
                            &#10004; {item}
                          </li>
                        );
                      })}{" "}
                    </>
                  ) : (
                    <>
                      <span> {props.Date} </span> <br />
                      <span> {props.Time} </span>
                      <hr style={{ height: "2px", width: "70%" }} />
                      <span>
                        By <b> {props.ByWhom} </b>
                      </span>
                      <br />
                      <span style={{ fontSize: "0.7rem" }}>{props.Who}</span>
                      <br />
                      <hr style={{ height: "2px", width: "70%" }} />
                    </>
                  )}
                </div>
              </div>
              {/* <div className="row">
								<p style={{ fontSize: "1.2rem" }} className={styles.plaintextJ}>
									Roadmap To Crack Top Product Companies
								</p>
							</div>
							<div className="row">
								<p>
									20 June 2021 <br /> 6:00PM{" "}
								</p>
							</div>
							<div className="row">
								<hr style={{ height: "2px", width: "70%" }} />
							</div>

							<div className="row">
								<p style={{ fontSize: "1rem" }}>by Vivek Jhaver</p>
								<p style={{ fontSize: "0.8rem" }}>SDE-2 Amazon</p>
							</div> */}
              {/* <hr style={{ height: "2px", width: "70%" }} /> */}

              <div className="row mb-2">
                <div
                  style={{
                    marginTop: "80%",
                    marginLeft: "0px",
                    padding: "0px",
                  }}
                  className={styles.formlogoJ + " justify-content-center"}
                >
                  <img src={cantileverlabslogo} alt="" />
                </div>
              </div>
              <br />
              <div className="row " style={{ width: "100%" }}>
                <div className="col-lg-3">
                  <a href="https://www.instagram.com/cantilever_labs/?hl=en">
                    <img src={instaMasterClass} alt="" />
                  </a>
                </div>
                <div className="col-lg-3">
                  <a href="https://www.facebook.com/Cantilever-labs-102916474752041/">
                    <img src={facebookMasterClass} alt="" />
                  </a>
                </div>
                <div className="col-lg-3">
                  <a href="https://www.linkedin.com/company/cantileverlabs/">
                    <img src={linkedinMasterClass} alt="" />
                  </a>
                </div>
                <div className="col-lg-3">
                  <a href="https://www.youtube.com/channel/UCHSggA4W_L0nSzWrf_lnZpw">
                    <img src={youtubeMasterClass} alt="" />
                  </a>
                </div>
              </div>
            </div>

            <div
              style={{
                borderTopRightRadius: "5%",
                borderBottomRightRadius: "5%",
                backgroundColor: "white",
                boxShadow: "22px 40px 50px 43px rgba(120, 120, 120, 0.25)",
              }}
              className={
                "col-xl-6 col-lg-6 col-md-8 col-sm-12" +
                styles.form2J +
                styles.secondpartformJ
              }
            >
              <div className="mx-5">
                <div className={styles.formhd2J + " mt-5"}>
                  <h2>College Details</h2>
                  <div
                    style={{
                      width: "40%",
                      backgroundColor: "black",
                      height: "2px",
                    }}
                  ></div>
                </div>

                <form className={styles.whiteformJ} onSubmit={HandleTheSubmit}>
                  <div>
                    <span className={styles.boldformTextJ}>
                      Graduation Degree
                    </span>
                    <br />

                    <div className="" data-role="fieldcontain">
                      <fieldset data-role="controlgroup" data-type="horizontal">
                        <div className="row">
                          <select
                            onChange={(e) => {
                              setgraduation(e.target.value);
                            }}
                          >
                            <option value="Bachelor of Engg./tech">
                              Bachelor of Engg./tech
                            </option>
                            <option value="Bachelor of Commerce">
                              Bachelor of Commerce
                            </option>
                            <option value="PGDM/MBA">PGDM/MBA</option>
                            <option value="BBA">BBA</option>
                            <option value="Others">Others</option>
                          </select>
                        </div>
                      </fieldset>
                    </div>
                  </div>
                  <br />
                  <div className="mt-1">
                    <span className={styles.boldformTextJ}>Department</span>
                    <br />
                    <div className="row">
                      <select
                        id="dropdown"
                        onChange={(e) => {
                          setdepartment(e.target.value);
                        }}
                      >
                        <option value="CSE">CSE</option>
                        <option value="ECE">ECE</option>
                        <option value="EEE">EEE</option>
                        <option value="IT">IT</option>
                        <option value="MECH">MECH</option>
                        <option value="CIVIL">CIVIL</option>
                        <option value="OTHERS">OTHERS</option>
                      </select>
                      {/* <div className="col-lg-2" style={{paddingLeft:"0px"}}>
                                                <input style={{ marginRight: "2%", width: "20px", height: "13px" }} type="radio" name="radio-1" id="radio-1"
                                                    value="CSE" checked={department == "CSE"}
                                                    onChange={(e) => setdepartment(e.target.value)} />
                                    <label style={{marginLeft:"2%",fontSize:"0.8rem"}}  className={styles.radiotextJ} for="radio-1" > CSE</label>
                                    </div>
                                    <div className="col-lg-2" style={{paddingLeft:"0px"}}>
                                    <input type = "radio" className="ml-2" name="radio-1"   id="radio-2" value="ECE" checked={department=="ECE"} onChange={function(e){
                                        setdepartment(e.target.value);
                                    }} />
                                    <label style={{marginLeft:"4%",fontSize:"0.8rem"}}  className={styles.radiotextJ} for="radio-2" > ECE</label>
                                    </div>
                                    <div className="col-lg-2" style={{paddingLeft:"0px"}}>
                                    <input type = "radio" className="ml-2" name="radio-1"   id="radio-3" value="EEE" checked={department=="EEE"}  onChange={function(e){
                                        setdepartment(e.target.value);
                                    }}/>
                                    <label style={{marginLeft:"4%",fontSize:"0.8rem"}}  className={styles.radiotextJ} for="radio-3" > EEE</label>
                                    </div>
                                    <div className="col-lg-2" style={{paddingLeft:"0px"}}>
                                    <input type = "radio" className="ml-2" name="radio-1"   id="radio-4" value="IT" checked={department=="IT"} onChange={function(e){
                                        setdepartment(e.target.value);
                                    }}/>
                                    <label style={{marginLeft:"4%",fontSize:"0.8rem"}}  className={styles.radiotextJ} for="radio-4" > IT</label>
                                    </div>
                                    <div className="col-lg-2" style={{paddingLeft:"0px"}}>
                                    <input type = "radio" className="ml-2" name="radio-1"   id="radio-5" value="MECH" checked={department=="MECH"} onChange={function(e){
                                        setdepartment(e.target.value);
                                    }} />
                                    <label style={{marginLeft:"4%",fontSize:"0.8rem"}}  className={styles.radiotextJ} for="radio-5" > MECH</label>
                                    </div>
                                    <div className="col-lg-2" style={{paddingLeft:"0px"}}>
                                    <input type ="radio" className="ml-2" name="radio-1"   id="radio-6" value="CIVIL" checked={department=="CIVIL"} onChange={function(e){
                                        setdepartment(e.target.value);
                                    }} />
                                    <label style={{marginLeft:"3%",fontSize:"0.8rem"}} className={styles.radiotextJ} for="radio-6" > CIVIL</label>
                                    </div>
                                    <div className="col-lg-2" style={{paddingLeft:"3px"}}>
                                    <input type ="radio" className="ml-3" name="radio-1" id="radio-7" value="OTHERS" checked={department=="OTHERS"} onChange={function(e){
                                        setdepartment(e.target.value);
                                    }} />
                                    <label style={{marginLeft:"4%",fontSize:"0.8rem"}}   className={styles.radiotextJ} for="radio-7" > OTHERS</label>
                                    </div> */}
                    </div>
                  </div>
                  <br />
                  <div className="mt-1">
                    <span className={styles.boldformTextJ}>Graduated</span>
                    <div className="row">
                      <div className="col">
                        <input
                          checked={yes === true}
                          type="radio"
                          name="yes"
                          id="yes"
                          onChange={() => setyes(!yes)}
                        />
                        <label
                          style={{ marginLeft: "4%" }}
                          className="ml-2"
                          for="yes"
                        >
                          Yes
                        </label>
                      </div>
                      <div className="col">
                        <input
                          type="radio"
                          name="bo"
                          id="no"
                          checked={yes === false}
                          onChange={() => setyes(!yes)}
                        />
                        <label
                          style={{ marginLeft: "4%" }}
                          className="ml-2"
                          for="no"
                        >
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                  <br />
                  <br />
                  {yes === false && (
                    <div className="">
                      <div className="row">
                        <div
                          className="col-lg-5"
                          style={{ paddingLeft: "0px" }}
                        >
                          <span className={styles.boldformTextJ}>
                            Year of study{" "}
                          </span>
                        </div>
                        <div
                          className="col-lg-5"
                          style={{ paddingLeft: "0px" }}
                        >
                          <span className={styles.boldformTextJ}>
                            Semester{" "}
                          </span>
                        </div>
                      </div>

                      <div className="row">
                        <div
                          className="col-lg-5"
                          style={{
                            float: "left",
                            marginLeft: "0px",
                            paddingLeft: "0px",
                          }}
                        >
                          <input
                            value={year}
                            onChange={(e) => setyear(e.target.value)}
                          
                            className={
                              "card shadow pr-1 pl-1 " + styles.numInput
                            }
                         
                            style={{
                            
                              width: "50%",
                                // float: "left",
                                
                              paddingLeft: "2%",
                              paddingTop: "2%",
                              paddingBottom: "2%",
                            }}
                            placeholder="Enter year"
                            type="number"
                            min="0"
                            max="4"
                            step="1"
                            name=""
                            id=""
                          />
                        </div>
                        <div
                          className="col-lg-5"
                          style={{
                            float: "left",
                            marginLeft: "0px",
                            paddingLeft: "0px",
                          }}
                        >
                          <input
                            value={semester}
                            onChange={(e) => setSemester(e.target.value)}
                            className={
                              "card shadow pr-1 pl-1 " + styles.numInput
                            }
                            placeholder="Enter Semester"
                            style={{
                              width: "50%",
                              paddingLeft: "2%",
                              paddingTop: "2%",
                              paddingBottom: "2%",
                            }}
                            type="number"
                            name=""
                            id=""
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  <br />
                  <div className="mt-2">
                    <div className="row">
                      <div
                        className="col-lg-5"
                        style={{ marginLeft: "0px", paddingLeft: "0px" }}
                      >
                        <span
                          className={styles.boldformTextJ + " col-lg "}
                          style={{ marginLeft: "0px", paddingLeft: "0px" }}
                        >
                          College Name
                        </span>
                      </div>
                      <div className="col-lg-5">
                        <span className={styles.boldformTextJ + " col-lg"}>
                          College City
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-5" style={{ paddingLeft: "0px" }}>
                        <input
                          value={CollegeName}
                          onChange={(e) => setCollegeName(e.target.value)}
                          className={styles.formcontrol1}
                          style={{
                            width: "70%",
                            marginLeft: "0px",
                            paddingLeft: "0px",
                          }}
                          type="text"
                          name="city"
                          placeholder="Enter your college name"
                        />
                      </div>
                      <div className="col-lg-5">
                        <input
                          value={City}
                          onChange={(e) => setCity(e.target.value)}
                          className={styles.formcontrol1}
                          style={{ width: "68%" }}
                          type="text"
                          name="city"
                          placeholder="Enter your college city"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="my-5">
                    {
                      <>
                        {error.length > 1 && (
                          <div className="alert alert-danger" role="alert">
                            {error.map((value, id) => {
                              return (
                                <>
                                  <p id={id}>{value}</p>
                                </>
                              );
                            })}{" "}
                          </div>
                        )}{" "}
                      </>
                    }
                  </div>

                  <div
                    style={{ marginBottom: "4%" }}
                    className={styles.leftformtextJ + " mt-5 mb-3"}
                  >
                    <div className="d-flex" style={{ marginBottom: "15%" }}>
                      <img
                        onClick={() => props.College_to_personal()}
                        className="mr-3"
                        src={arrow2}
                        alt=""
                      />
                      <img
                        onClick={() => {
                          if (HandleValidation() == true) {
                            return props.College_to_others();
                          }
                        }}
                        src={arrow1}
                        alt=""
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollegeDetails;

import React from 'react'
import "../Interview/InterviewStyle/InterviewPractice.scss";
import { useHistory, } from "react-router-dom";
function InterviewPractice() {
 
    let history = useHistory();
    function lastPage(){
        history.push('/preparepage')
      }
  return (
    <div id="InterviewPractice">
        <div className="PracticeInterview" onClick={lastPage}>
          <div className="PrepareTitle">Prepare</div>
        </div>
        <div className="PracticeInterview">
          <div className="PrcaticeTitle">Practice</div>
        </div>
      </div>
  )
}

export default InterviewPractice
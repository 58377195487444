import React from "react";
import Card from "react-bootstrap/Card";
import eh from "../Assets/img/event-head.png";
import "../Assets/css/eventpage.css";

const EventPCard = () => {
  return (
    <div style={{ flex: "30%" }}>
      <Card
        style={{
          width: "20rem",
          marginBottom: "80px",
          marginLeft: "20px",
        }}
        className="event-cardmain"
      >
        <Card.Img variant="top" src={eh} />
        <div class="top-right">57 Slots Remaining</div>
        <Card.Body>
          {/* <Card.Text>Growth Hacks of CEO</Card.Text> */}
          <Card.Title className="eventcard-title">
            Growth Hacks of CEO
          </Card.Title>
          <Card.Text>
            <button className="statusbtn">Free</button>
          </Card.Text>
        </Card.Body>
        <Card.Text className="eventpage-cardmore">
          <div>
            <button className="eventmorebutton">Know More</button>
          </div>
          <div className="event-cardfooter">2076 Students Registered</div>
        </Card.Text>
        {/* <Card.Text className="event-cardfooter">
          2076 Students Registered
        </Card.Text> */}
      </Card>
    </div>
  );
};

export default EventPCard;

import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

function Privacy() {
  return (
    <>
      {/* <Navbar /> */}
      <div style={{ width: "70%", position: "relative", left: "5%" }}>
        <h1
          id="privacy"
          style={{
            fontWeight: "bold",
            position: "relative",
            paddingTop: "100px",
          }}
        >
          Privacy Policy
        </h1>
        <h3>Introduction</h3>
        <p>
          Cantilever Labs functions as a service through "www.Cantileverlabs.com", functioning to build a communication of trust and of quality service with customers from all around the world. Our primary objective is to fulfil the desire of employment among students and employment-seekers. Our Privacy policy is intended to imbibe a sense of security in regards to the highest standards for secure transactions and customer information privacy. The following points will address the concern as such:
        </p>
        <p>
        This Privacy Policy document contains types of information that is collected and recorded by Cantilever labs and how we use it.
        </p>
        <p>
        If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.
        </p>
        <p>
        This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collected in Cantilever labs. This policy is not applicable to any information collected offline or via channels other than this website.
        </p>
        <br />
        <h3>Consent</h3>
        <p>
        Cantilever Labs functions as a service through "www.Cantileverlabs.com", functioning to build a communication of trust and of quality service with customers from all around the world. Our primary objective is to fulfil the desire of employment among students and employment-seekers. Our Privacy policy is intended to imbibe a sense of security in regards to the highest standards for secure transactions and customer information privacy. The following points will address the concern as such:
        </p>
        <br />
        <h3>Information we collect</h3>
        <p>
        The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.
        </p>
        <p>
        If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.
        </p>
        <p>
        When you register for an Account, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number.
        </p>
        <br />
        <h3>How we use your information</h3>
        <p>We use the information we collect in various ways, including to:</p>
        <p>Provide, operate, and maintain our website</p>
        <p>Improve, personalize, and expand our website</p>
        <p>Understand and analyze how you use our website</p>
        <p>Develop new products, services, features, and functionality</p>
        <p>
          Communicate with you, either directly or through one of our partners,
          including for customer service, to provide you with updates and other
          information relating to the website, and for marketing and promotional
          purposes
        </p>
        <p>Send you emails</p>
        <p>Find and prevent fraud</p>
        <br />
        <h3>Log Files</h3>
        <p>
        Cantilever labs follow a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this as part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information.
        </p>
        <br />
        <h3>Cookies and Web Beacons</h3>
        <p>
        Like any other website, cantileverlabs.com uses 'cookies'. These cookies are used to Store information including visitors' preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users' experience by customizing our web page content based on visitors' browser type and/or other information.
        </p>
        <p>
          For more general information on cookies, please read{" "}
          <a
            style={{ textDecoration: "underline" }}
            href="https://www.privacypolicyonline.com/what-are-cookies/"
          >
            "What Are Cookies" from Cookie Consent
          </a>
          .
        </p>
        <br />
        <h3>Our Advertising Partners</h3>
        <p>
        Some of the advertisers on our site may use cookies and web beacons. Our advertising partners are listed below. Each of our advertising partners has their own Privacy Policy for their policies on user data. For easier access, we hyperlinked to their Privacy Policies below.
        </p>
        <p><h3>Advertising Partners Privacy Policies</h3></p>
        <p>
          You may consult this list to find the Privacy Policy for each of the
          advertising partners of cantileverlabs.com.
        </p>
        <p>
        Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on cantileverlabs.com, which are sent directly to users' browsers. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.
        </p>
        <p>
        Note that cantileverlabs.com has no access to or control over these cookies that are used by third-party advertisers.
        </p>
        <h3>Third Party Privacy Policies</h3>
        <p>
        Cantilever Labs Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options.
        </p>
        <p>
        You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites.
        </p>
        <h3>CCPA Privacy Rights (Do Not Sell My Personal Information)</h3>
        <p>
          Under the CCPA, among other rights, California consumers have the
          right to:
        </p>
        <p>
          Request that a business that collects a consumer's personal data
          disclose the categories and specific pieces of personal data that a
          business has collected about consumers.
        </p>
        <p>
          Request that a business delete any personal data about the consumer
          that a business has collected.
        </p>
        <p>
          Request that a business that sells a consumer's personal data, not
          sell the consumer's personal data.
        </p>
        <p>
          If you make a request, we have one month to respond to you. If you
          would like to exercise any of these rights, please contact us.
        </p>
        <br />
        <h3>GDPR Data Protection Rights</h3>
        <p>
          We would like to make sure you are fully aware of all of your data
          protection rights. Every user is entitled to the following:
        </p>
        <p>
          The right to access – You have the right to request copies of your
          personal data. We may charge you a small fee for this service.
        </p>
        <p>
          The right to rectification – You have the right to request that we
          correct any information you believe is inaccurate. You also have the
          right to request that we complete the information you believe is
          incomplete.
        </p>
        <p>
          The right to erasure – You have the right to request that we erase
          your personal data, under certain conditions.
        </p>
        <p>
          The right to restrict processing – You have the right to request that
          we restrict the processing of your personal data, under certain
          conditions.
        </p>
        <p>
          The right to object to processing – You have the right to object to
          our processing of your personal data, under certain conditions.
        </p>
        <p>
          The right to data portability – You have the right to request that we
          transfer the data that we have collected to another organization, or
          directly to you, under certain conditions.
        </p>
        <p>
          If you make a request, we have one month to respond to you. If you
          would like to exercise any of these rights, please contact us.
        </p>
        <br />
        <h3>Children's Information</h3>
        <p>
        Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.
        </p>
        <p>
        Cantilever labs does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.
        </p>
        <br />
        <br />
        <h1
          id="terms"
          style={{
            fontWeight: "bold",
            position: "relative",
            paddingTop: "100px",
          }}
        >
          Terms & Conditions
        </h1>
        <p>Welcome to Cantilever Labs!</p>
        <p>
          These terms and conditions outline the rules and regulations for the
          use of Cantilever Labs's Website, located at{" "}
          <a href="www.cantileverlabs.com">cantileverlabs.com</a>
        </p>
        <br />
        <h3>Applicable Law</h3>
        <p>
          The domain name www.cantileverlabs.com (hereinafter referred to as
          “Website”) is created and administered by CantileverLabs under the
          brand name of Cantileverlabs.Com; as such the laws of India shall
          apply; courts in Hyderabad shall have jurisdiction in respect of all
          the terms, conditions and disclaimers.Cantileverlabs.Com reserves the
          right to make changes to the Website and the terms, conditions and
          disclaimers at anytime with or without prior notice.
        </p>
        <p>
          By accessing this website we assume you accept these terms and
          conditions. Do not continue to use Cantilever Labs if you do not agree
          to take all of the terms and conditions stated on this page.
        </p>
        <p>
          The following terminology applies to these Terms and Conditions,
          Privacy Statement and Disclaimer Notice and all Agreements: "Client",
          "You" and "Your" refers to you, the person log on this website and
          compliant to the Company’s terms and conditions. "The Company",
          "Ourselves", "We", "Our" and "Us", refers to our Company. "Party",
          "Parties", or "Us", refers to both the Client and ourselves. All terms
          refer to the offer, acceptance and consideration of payment necessary
          to undertake the process of our assistance to the Client in the most
          appropriate manner for the express purpose of meeting the Client’s
          needs in respect of provision of the Company’s stated services, in
          accordance with and subject to, prevailing law of Netherlands. Any use
          of the above terminology or other words in the singular, plural,
          capitalization and/or he/she or they, are taken as interchangeable and
          therefore as referring to same.
        </p>
        <br />
        <h3>Cookies</h3>
        <p>
          We employ the use of cookies. By accessing Cantilever Labs, you agreed
          to use cookies in agreement with the Cantilever Labs's Privacy Policy.
        </p>
        <p>
          Most interactive websites use cookies to let us retrieve the user’s
          details for each visit. Cookies are used by our website to enable the
          functionality of certain areas to make it easier for people visiting
          our website. Some of our affiliate/advertising partners may also use
          cookies.
        </p>
        <br />
        <h3>License</h3>
        <p>
          Unless otherwise stated, Cantilever Labs and/or its licensors own the
          intellectual property rights for all material on Cantilever Labs. All
          intellectual property rights are reserved. You may access this from
          Cantilever Labs for your own personal use subjected to restrictions
          set in these terms and conditions.
        </p>
        <br />
        <h3>You must not:</h3>
        <p>Republish material from Cantilever Labs</p>
        <p>Sell, rent or sub-license material from Cantilever Labs</p>
        <p>Reproduce, duplicate or copy material from Cantilever Labs</p>
        <p>Redistribute content from Cantilever Labs</p>
        <a
          href="https://www.termsandconditionsgenerator.com/"
          style={{ color: "blue" }}
        >
          This Agreement shall begin on the date hereof. Our Terms and
          Conditions were created with the help of the Terms And Conditions
          Generator and the Privacy Policy Generator.
        </a>
        <p>
          Parts of this website offer an opportunity for users to post and
          exchange opinions and information in certain areas of the website.
          Cantilever Labs does not filter, edit, publish or review Comments
          prior to their presence on the website. Comments do not reflect the
          views and opinions of Cantilever Labs,its agents and/or affiliates.
          Comments reflect the views and opinions of the person who post their
          views and opinions. To the extent permitted by applicable laws,
          Cantilever Labs shall not be liable for the Comments or for any
          liability, damages or expenses caused and/or suffered as a result of
          any use of and/or posting of and/or appearance of the Comments on this
          website.
        </p>
        <p>
          Cantilever Labs reserves the right to monitor all Comments and to
          remove any Comments which can be considered inappropriate, offensive
          or causes breach of these Terms and Conditions.
        </p>
        <h2></h2>
        <p>
          You are entitled to post the Comments on our website and have all
          necessary licenses and consents to do so;
        </p>
        <p>
          The Comments do not invade any intellectual property right, including
          without limitation copyright, patent or trademark of any third party;
        </p>
        <p>
          The Comments do not contain any defamatory, libelous, offensive,
          indecent or otherwise unlawful material which is an invasion of
          privacy
        </p>
        <p>
          The Comments will not be used to solicit or promote business or custom
          or present commercial activities or unlawful activity.
        </p>
        <p>
          You hereby grant Cantilever Labs a non-exclusive license to use,
          reproduce, edit and authorize others to use, reproduce and edit any of
          your Comments in any and all forms, formats or media.
        </p>
        <br />
        <h3>Hyperlinking to our Content</h3>
        <p>
          The following organizations may link to our Website without prior
          written approval:
        </p>
        <p>Government agencies;</p>
        <p>Search engines;</p>
        <p>News organizations;</p>
        <p>
          Online directory distributors may link to our Website in the same
          manner as they hyperlink to the Websites of other listed businesses;
          and
        </p>
        <p>
          System wide Accredited Businesses except soliciting non-profit
          organizations, charity shopping malls, and charity fundraising groups
          which may not hyperlink to our Web site.
        </p>
        <p>
          These organizations may link to our home page, to publications or to
          other Website information so long as the link: (a) is not in any way
          deceptive; (b) does not falsely imply sponsorship, endorsement or
          approval of the linking party and its products and/or services; and
          (c) fits within the context of the linking party’s site.
        </p>
        <p>
          We may consider and approve other link requests from the following
          types of organizations:{" "}
        </p>
        <p>commonly-known consumer and/or business information sources;</p>
        <p>dot.com community sites;</p>
        <p>associations or other groups representing charities;</p>
        <p>online directory distributors;</p>
        <p>internet portals;</p>
        <p>accounting, law and consulting firms; and</p>
        <p>educational institutions and trade associations.</p>
        <p>
          We will approve link requests from these organizations if we decide
          that: (a) the link would not make us look unfavorably to ourselves or
          to our accredited businesses; (b) the organization does not have any
          negative records with us; (c) the benefit to us from the visibility of
          the hyperlink compensates the absence of Cantilever Labs; and (d) the
          link is in the context of general resource information.
        </p>
        <p>
          These organizations may link to our home page so long as the link: (a)
          is not in any way deceptive; (b) does not falsely imply sponsorship,
          endorsement or approval of the linking party and its products or
          services; and (c) fits within the context of the linking party’s site.
        </p>
        <p>
          If you are one of the organizations listed in paragraph 2 above and
          are interested in linking to our website, you must inform us by
          sending an e-mail to Cantilever Labs. Please include your name, your
          organization name, contact information as well as the URL of your
          site, a list of any URLs from which you intend to link to our Website,
          and a list of the URLs on our site to which you would like to link.
          Wait 2-3 weeks for a response.
        </p>
        <h3>Approved organizations may hyperlink to our Website as follows:</h3>
        <p>By use of our corporate name; or</p>
        <p>By use of the uniform resource locator being linked to; or</p>
        <p>
          By use of any other description of our Website being linked to that
          makes sense within the context and format of content on the linking
          party’s site.
        </p>
        <br />
        <h3>iFrames</h3>
        <p>
          Without prior approval and written permission, you may not create
          frames around our Webpages that alter in any way the visual
          presentation or appearance of our Website.
        </p>
        <br />
        <h3>Content Liability</h3>
        <p>
          We shall not be hold responsible for any content that appears on your
          Website. You agree to protect and defend us against all claims that is
          rising on your Website. No link(s) should appear on any Website that
          may be interpreted as libelous, obscene or criminal, or which
          infringes, otherwise violates, or advocates the infringement or other
          violation of, any third party rights.
        </p>
        <br />
        <p>Your Privacy</p>
        <p>Please read Privacy Policy</p>
        <br />
        <p>Reservation of Rights</p>
        <p>
          We reserve the right to request that you remove all links or any
          particular link to our Website. You approve to immediately remove all
          links to our Website upon request. We also reserve the right to amen
          these terms and conditions and it’s linking policy at any time. By
          continuously linking to our Website, you agree to be bound to and
          follow these linking terms and conditions.
        </p>
        <br />
        <h3>Removal of links from our website</h3>
        <p>
          If you find any link on our Website that is offensive for any reason,
          you are free to contact and inform us any moment. We will consider
          requests to remove links but we are not obligated to or so or to
          respond to you directly.
        </p>
        <p>
          We do not ensure that the information on this website is correct, we
          do not warrant its completeness or accuracy; nor do we promise to
          ensure that the website remains available or that the material on the
          website is kept up to date.
        </p>
        <br />
        <h3>Disclaimer</h3>
        <p>
          To the maximum extent permitted by applicable law, we exclude all
          representations, warranties and conditions relating to our website and
          the use of this website. Nothing in this disclaimer will:
        </p>
        <p>
          limit or exclude our or your liability for death or personal injury;
        </p>
        <p>
          limit or exclude our or your liability for fraud or fraudulent
          misrepresentation;
        </p>
        <p>
          limit any of our or your liabilities in any way that is not permitted
          under applicable law; or
        </p>
        <p>
          exclude any of our or your liabilities that may not be excluded under
          applicable law.
        </p>
        <p>
          The limitations and prohibitions of liability set in this Section and
          elsewhere in this disclaimer: (a) are subject to the preceding
          paragraph; and (b) govern all liabilities arising under the
          disclaimer, including liabilities arising in contract, in tort and for
          breach of statutory duty.
        </p>
        <p>
          As long as the website and the information and services on the website
          are provided free of charge, we will not be liable for any loss or
          damage of any nature.
        </p>
        <br />
        <br />
        <h1
          id="pricing"
          style={{
            fontWeight: "bold",
            poisition: "relative",
            paddingTop: "100px",
          }}
        >
          Pricing and refund Policy
        </h1>
        <p>
          "The following comprises of our various policies that govern our
          website. The following shall be changed/ removed in part or whole at
          any time with or without prior notice by us.
        </p>{" "}
        <p>“Website” refers to Cantileverlabs.Com </p>
        <p>“Users” refers to person/s who formed the group and manage it.</p>
        <p>Pricing Policy </p>
        <p>
          Cantilever Labs has based the pricing as in-depth analysis of the
          subjects, mentors, services provided in this field based on the
          current market. And the pricing will not be varied based on
          preferential grounds, but can vary as per market requirements. All
          payments are done through Net Banking, UPI payments, E-bank and other
          online payment methods.
        </p>
        <h3>Products Policy</h3>
        <p>Our online test packages are delivered over the internet. </p>
        <p>
          All the test packages sold are meant only for the purpose of practice.
          Cantilever Labs doesn't guarantee the test pattern and questions to be
          same in the actual companies tests.
        </p>
        <p>
          All the tests are prepared based on the feedback from our users based
          on their past experience and by experts from the industry.
        </p>
        <h3>Cancellations / Refunds</h3>
        <p>Thank you for buying our program or course.</p>
        <p>
          We try to ensure that our students have a great experience while they
          discover, assess, and purchase our course(s) If a cancellation is done
          by the user with valid reasons within 7 days after successful payment
          is done , 100% of paid fee will be refunded to the user. No refund
          would be processed if cancellation is done after 7 days of payment.{" "}
        </p>
        <h3>How to initiate Refund request :</h3>
        <p>
          By dropping email to our customer support team
          “careercounselling@cantileverlabs.com”
        </p>
        <p>
          Note: All refunds will be processed within 30 days of receiving the
          refund request.
        </p>
        <p>
          Cancellation must be done with a valid reason, otherwise refund will
          not be issued.
        </p>
      </div>
        <Footer />
    </>
  );
}

export default Privacy;

import React, { useState } from "react";
import { Carousel } from "react-bootstrap";
// import quizBg from "../Assets/img/misc/quiz-bg.png";
// import masterBanner from "../Assets/img/Masterclass/blogs.png";
import style from "../../Assets/css/b2bClientCarousel.module.css";
import mentorLogo from "../../Assets/img/b2b/mentor_logo.png";
import Manish from "../../Assets/img/Competitive/Image_30.png";
import Vivek from "../../Assets/img/Competitive/Vivek.png";
import microsoftLogo from "../../Assets/img/b2b/microsoft_logo_mentor.png";
import GoogleLogo from "../../Assets/img/b2b/Google.png";
import AmazonLogo from "../../Assets/img/b2b/amazon.png";
import left from "../../Assets/img/Home/Left.png";
import right from "../../Assets/img/Home/Right.png";
function B2BCarousel2() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  return (
    <div style={{ background: "#F6F6EE" }}>
      <div>
        <br />
        <div style={{ marginTop: "5%", marginLeft: "5%" }}>
          <h2>Our Mentors</h2>
          <hr
            style={{
              width: "12%",
              height: "10px",
              color: "#185ADB",
              opacity: "1",
              borderRadius: "5px",
            }}
          />
        </div>
        <Carousel
          className={style["carousel-indicators"]}
          style={{ paddingBottom: "6%", zIndex: "0" }}
          // prevIcon={<span aria-hidden="false" className="carousel-control-prev-icon" />}
          activeIndex={index}
          onSelect={handleSelect}
        >
          <Carousel.Item>
            <div
              style={{
                display: "flex",
                marginTop: "4%",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  borderRadius: "10px",
                  margin: "3%",
                  width: "25rem",
                  height: "fit-content",
                  boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                }}
              >
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      marginLeft: "6%",
                      margin: "auto 10px",
                      width: "30%",
                      paddingBottom: "10px",
                    }}
                  >
                    <img
                      style={{ margin: "auto 0" }}
                      src={Manish}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      margin: "auto 0",
                      width: "55%",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "0.7vw",
                        fontFamily: "Poppins-Bold",
                        color: "black",
                        margin: "0",
                        marginTop: "20%",
                        textAlign: "justify",
                      }}
                    ></p>
                    {/* <hr
                      style={{
                        width: "25%",
                        height: "3px",
                        color: "#185ADB",
                        opacity: "1",
                        borderRadius: "5px",
                        margin: "8px",
                      }}
                    /> */}
                    <h5
                      style={{
                        fontSize: "0.8vw",
                        fontFamily: "Poppins-Bold",
                        color: "black",
                        marginTop: "5%",
                        textAlign: "left",
                        margin: "0",
                      }}
                    >
                      Manish Kankani
                    </h5>
                    <p
                      style={{
                        fontSize: "0.6vw",
                        fontFamily: "Poppins-Bold",
                        color: "black",
                        marginTop: "5%",
                        textAlign: "left",
                      }}
                    >
                      IIT, Roorkee | Tech Head
                    </p>
                    <img
                      style={{ width: "inherit", paddingBottom: "10px" }}
                      src={GoogleLogo}
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <div
                style={{
                  borderRadius: "10px",
                  margin: "3%",
                  width: "25rem",
                  height: "fit-content",
                  boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                }}
              >
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      marginLeft: "6%",
                      margin: "auto 10px",
                      width: "30%",
                      paddingBottom: "20px",
                    }}
                  >
                    <img
                      style={{ margin: "auto" }}
                      src={mentorLogo}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      margin: "auto",
                      width: "55%",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "0.7vw",
                        fontFamily: "Poppins-Bold",
                        color: "black",
                        margin: "0",
                        marginTop: "20%",
                        textAlign: "justify",
                      }}
                    ></p>
                    {/* <hr
                      style={{
                        width: "25%",
                        height: "3px",
                        color: "#185ADB",
                        opacity: "1",
                        borderRadius: "5px",
                        margin: "8px",
                      }}
                    /> */}
                    <h5
                      style={{
                        fontSize: "0.8vw",
                        fontFamily: "Poppins-Bold",
                        color: "black",
                        marginTop: "5%",
                        textAlign: "left",
                        margin: "0",
                      }}
                    >
                      Suresh Santhanam Mohan
                    </h5>
                    <p
                      style={{
                        fontSize: "0.6vw",
                        fontFamily: "Poppins-Bold",
                        color: "black",
                        marginTop: "5%",
                        textAlign: "left",
                      }}
                    >
                      IIIT Hyderabad | SDE at Microsoft
                    </p>
                    <img
                      style={{ width: "inherit", paddingBottom: "10px" }}
                      src={microsoftLogo}
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <div
                style={{
                  borderRadius: "10px",
                  margin: "3%",
                  width: "25rem",
                  height: "fit-content",
                  boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                }}
              >
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      marginLeft: "6%",
                      margin: "auto",
                      width: "30%",
                      paddingBottom: "15px",
                    }}
                  >
                    <img
                      style={{ margin: "auto" }}
                      src={Vivek}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      margin: "auto",
                      width: "55%",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "0.7vw",
                        fontFamily: "Poppins-Bold",
                        color: "black",
                        margin: "0",
                        marginTop: "20%",
                        textAlign: "justify",
                      }}
                    ></p>
                    {/* <hr
                      style={{
                        width: "25%",
                        height: "3px",
                        color: "#185ADB",
                        opacity: "1",
                        borderRadius: "5px",
                        margin: "8px",
                      }}
                    /> */}
                    <h5
                      style={{
                        fontSize: "0.8vw",
                        fontFamily: "Poppins-Bold",
                        color: "black",
                        marginTop: "5%",
                        textAlign: "left",
                        margin: "0",
                      }}
                    >
                      Vivek Jhaver
                    </h5>
                    <p
                      style={{
                        fontSize: "0.6vw",
                        fontFamily: "Poppins-Bold",
                        color: "black",
                        marginTop: "5%",
                        textAlign: "left",
                      }}
                    >
                      VNIT | SDE-2, Amazon
                    </p>
                    <img
                      style={{ width: "inherit", paddingBottom: "10px" }}
                      src={AmazonLogo}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>

            <Carousel.Caption style={{ color: "black" }}></Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item>
            <div
              style={{
                display: "flex",
                marginTop: "4%",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  borderRadius: "10px",
                  margin: "3%",
                  width: "25rem",
                  height: "fit-content",
                  boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                }}
              >
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      marginLeft: "6%",
                      margin: "auto 10px",
                      width: "30%",
                    }}
                  >
                    <img
                      style={{ margin: "auto" }}
                      src={Manish}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      margin: "auto",
                      width: "55%",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "0.7vw",
                        fontFamily: "Poppins-Bold",
                        color: "black",
                        margin: "0",
                        marginTop: "20%",
                        textAlign: "justify",
                      }}
                    ></p>
                    {/* <hr
                      style={{
                        width: "25%",
                        height: "3px",
                        color: "#185ADB",
                        opacity: "1",
                        borderRadius: "5px",
                        margin: "8px",
                      }}
                    /> */}
                    <h5
                      style={{
                        fontSize: "0.8vw",
                        fontFamily: "Poppins-Bold",
                        color: "black",
                        marginTop: "5%",
                        textAlign: "left",
                        margin: "0",
                      }}
                    >
                      Manish Kankani
                    </h5>
                    <p
                      style={{
                        fontSize: "0.6vw",
                        fontFamily: "Poppins-Bold",
                        color: "black",
                        marginTop: "5%",
                        textAlign: "left",
                      }}
                    >
                      IIT, Roorkee | Google Tech Head
                    </p>
                    <img
                      style={{ width: "inherit", paddingBottom: "10px" }}
                      src={GoogleLogo}
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <div
                style={{
                  borderRadius: "10px",
                  margin: "3%",
                  width: "25rem",
                  height: "fit-content",
                  boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                }}
              >
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      marginLeft: "6%",
                      margin: "auto 10px",
                      width: "30%",
                    }}
                  >
                    <img
                      style={{ margin: "auto" }}
                      src={mentorLogo}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      margin: "auto",
                      width: "55%",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "0.7vw",
                        fontFamily: "Poppins-Bold",
                        color: "black",
                        margin: "0",
                        marginTop: "20%",
                        textAlign: "justify",
                      }}
                    ></p>
                    {/* <hr
                      style={{
                        width: "25%",
                        height: "3px",
                        color: "#185ADB",
                        opacity: "1",
                        borderRadius: "5px",
                        margin: "8px",
                      }}
                    /> */}
                    <h5
                      style={{
                        fontSize: "0.8vw",
                        fontFamily: "Poppins-Bold",
                        color: "black",
                        marginTop: "5%",
                        textAlign: "left",
                        margin: "0",
                      }}
                    >
                      Suresh Santhanam Mohan
                    </h5>
                    <p
                      style={{
                        fontSize: "0.6vw",
                        fontFamily: "Poppins-Bold",
                        color: "black",
                        marginTop: "5%",
                        textAlign: "left",
                      }}
                    >
                      IIIT Hyderabad | Software Engineer at Microsoft
                    </p>
                    <img
                      style={{ width: "inherit", paddingBottom: "10px" }}
                      src={microsoftLogo}
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <div
                style={{
                  borderRadius: "10px",
                  margin: "3%",
                  width: "25rem",
                  height: "fit-content",
                  boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                }}
              >
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      marginLeft: "6%",
                      margin: "auto 10px",
                      width: "30%",
                    }}
                  >
                    <img
                      style={{ margin: "auto" }}
                      src={Vivek}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      margin: "auto",
                      width: "55%",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "0.7vw",
                        fontFamily: "Poppins-Bold",
                        color: "black",
                        margin: "0",
                        marginTop: "20%",
                        textAlign: "justify",
                      }}
                    ></p>
                    {/* <hr
                      style={{
                        width: "25%",
                        height: "3px",
                        color: "#185ADB",
                        opacity: "1",
                        borderRadius: "5px",
                        margin: "8px",
                      }}
                    /> */}
                    <h5
                      style={{
                        fontSize: "0.8vw",
                        fontFamily: "Poppins-Bold",
                        color: "black",
                        marginTop: "5%",
                        textAlign: "left",
                        margin: "0",
                      }}
                    >
                      Vivek Jhaver
                    </h5>
                    <p
                      style={{
                        fontSize: "0.6vw",
                        fontFamily: "Poppins-Bold",
                        color: "black",
                        marginTop: "5%",
                        textAlign: "left",
                      }}
                    >
                      VNIT | SDE-2, Amazon
                    </p>
                    <img
                      style={{ width: "inherit", paddingBottom: "10px" }}
                      src={AmazonLogo}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>

            <Carousel.Caption style={{ color: "black" }}></Carousel.Caption>
          </Carousel.Item>

          {/* <Carousel.Item>
            <div
              style={{
                display: "flex",
                marginTop: "4%",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  borderRadius: "10px",
                  margin: "3%",
                  width: "22vw",
                  height: "fit-content",
                  boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                }}
              >
                <div style={{ display: "flex" }}>
                  <div
                    style={{ marginLeft: "6%", margin: "auto 10px", width: "30%" }}
                  >
                    <img
                      style={{ margin: "auto" }}
                      src={mentorLogo}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      margin: "auto",
                      width: "55%",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "0.7vw",
                        fontFamily: "Poppins-Bold",
                        color: "black",
                        margin: "0",
                        marginTop: "20%",
                        textAlign: "justify",
                      }}
                    >
                      
                    </p>
                    <hr
                      style={{
                        width: "25%",
                        height: "3px",
                        color: "#185ADB",
                        opacity: "1",
                        borderRadius: "5px",
                        margin: "8px",
                      }}
                    />
                    <h5
                      style={{
                        fontSize: "0.8vw",
                        fontFamily: "Poppins-Bold",
                        color: "black",
                        marginTop: "5%",
                        textAlign: "left",
                        margin: "0",
                      }}
                    >
                      Suresh Santhanam Mohan
                    </h5>
                    <p
                      style={{
                        fontSize: "0.6vw",
                        fontFamily: "Poppins-Bold",
                        color: "black",
                        marginTop: "5%",
                        textAlign: "left",
                      }}
                    >
                      IIIT Hyderabad
                    </p>
                    <img
                      style={{ width: "inherit", paddingBottom: "10px" }}
                      src={microsoftLogo}
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <div
                style={{
                  borderRadius: "10px",
                  margin: "3%",
                  width: "22vw",
                  height: "fit-content",
                  boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                }}
              >
                <div style={{ display: "flex" }}>
                  <div
                    style={{ marginLeft: "6%", margin: "auto 10px", width: "30%" }}
                  >
                    <img
                      style={{ margin: "auto" }}
                      src={mentorLogo}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      margin: "auto",
                      width: "55%",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "0.7vw",
                        fontFamily: "Poppins-Bold",
                        color: "black",
                        margin: "0",
                        marginTop: "20%",
                        textAlign: "justify",
                      }}
                    >
                      
                    </p>
                    <hr
                      style={{
                        width: "25%",
                        height: "3px",
                        color: "#185ADB",
                        opacity: "1",
                        borderRadius: "5px",
                        margin: "8px",
                      }}
                    />
                    <h5
                      style={{
                        fontSize: "0.8vw",
                        fontFamily: "Poppins-Bold",
                        color: "black",
                        marginTop: "5%",
                        textAlign: "left",
                        margin: "0",
                      }}
                    >
                      Suresh Santhanam Mohan
                    </h5>
                    <p
                      style={{
                        fontSize: "0.6vw",
                        fontFamily: "Poppins-Bold",
                        color: "black",
                        marginTop: "5%",
                        textAlign: "left",
                      }}
                    >
                      IIIT Hyderabad
                    </p>
                    <img
                      style={{ width: "inherit", paddingBottom: "10px" }}
                      src={microsoftLogo}
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <div
                style={{
                  borderRadius: "10px",
                  margin: "3%",
                  width: "22vw",
                  height: "fit-content",
                  boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                }}
              >
                <div style={{ display: "flex" }}>
                  <div
                    style={{ marginLeft: "6%", margin: "auto 10px", width: "30%" }}
                  >
                    <img
                      style={{ margin: "auto" }}
                      src={mentorLogo}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      margin: "auto",
                      width: "55%",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "0.7vw",
                        fontFamily: "Poppins-Bold",
                        color: "black",
                        margin: "0",
                        marginTop: "20%",
                        textAlign: "justify",
                      }}
                    >
                      
                    </p>
                    <hr
                      style={{
                        width: "25%",
                        height: "3px",
                        color: "#185ADB",
                        opacity: "1",
                        borderRadius: "5px",
                        margin: "8px",
                      }}
                    />
                    <h5
                      style={{
                        fontSize: "0.8vw",
                        fontFamily: "Poppins-Bold",
                        color: "black",
                        marginTop: "5%",
                        textAlign: "left",
                        margin: "0",
                      }}
                    >
                      Suresh Santhanam Mohan
                    </h5>
                    <p
                      style={{
                        fontSize: "0.6vw",
                        fontFamily: "Poppins-Bold",
                        color: "black",
                        marginTop: "5%",
                        textAlign: "left",
                      }}
                    >
                      IIIT Hyderabad
                    </p>
                    <img
                      style={{ width: "inherit", paddingBottom: "10px" }}
                      src={microsoftLogo}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>

            <Carousel.Caption style={{ color: "black" }}></Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item>
            <div
              style={{
                display: "flex",
                marginTop: "4%",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  borderRadius: "10px",
                  margin: "3%",
                  width: "22vw",
                  height: "fit-content",
                  boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                }}
              >
                <div style={{ display: "flex" }}>
                  <div
                    style={{ marginLeft: "6%", margin: "auto 10px", width: "30%" }}
                  >
                    <img
                      style={{ margin: "auto" }}
                      src={mentorLogo}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      margin: "auto",
                      width: "55%",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "0.7vw",
                        fontFamily: "Poppins-Bold",
                        color: "black",
                        margin: "0",
                        marginTop: "20%",
                        textAlign: "justify",
                      }}
                    >
                      
                    </p>
                    <hr
                      style={{
                        width: "25%",
                        height: "3px",
                        color: "#185ADB",
                        opacity: "1",
                        borderRadius: "5px",
                        margin: "8px",
                      }}
                    />
                    <h5
                      style={{
                        fontSize: "0.8vw",
                        fontFamily: "Poppins-Bold",
                        color: "black",
                        marginTop: "5%",
                        textAlign: "left",
                        margin: "0",
                      }}
                    >
                      Suresh Santhanam Mohan
                    </h5>
                    <p
                      style={{
                        fontSize: "0.6vw",
                        fontFamily: "Poppins-Bold",
                        color: "black",
                        marginTop: "5%",
                        textAlign: "left",
                      }}
                    >
                      IIIT Hyderabad
                    </p>
                    <img
                      style={{ width: "inherit", paddingBottom: "10px" }}
                      src={microsoftLogo}
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <div
                style={{
                  borderRadius: "10px",
                  margin: "3%",
                  width: "22vw",
                  height: "fit-content",
                  boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                }}
              >
                <div style={{ display: "flex" }}>
                  <div
                    style={{ marginLeft: "6%", margin: "auto 10px", width: "30%" }}
                  >
                    <img
                      style={{ margin: "auto" }}
                      src={mentorLogo}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      margin: "auto",
                      width: "55%",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "0.7vw",
                        fontFamily: "Poppins-Bold",
                        color: "black",
                        margin: "0",
                        marginTop: "20%",
                        textAlign: "justify",
                      }}
                    >
                      
                    </p>
                    <hr
                      style={{
                        width: "25%",
                        height: "3px",
                        color: "#185ADB",
                        opacity: "1",
                        borderRadius: "5px",
                        margin: "8px",
                      }}
                    />
                    <h5
                      style={{
                        fontSize: "0.8vw",
                        fontFamily: "Poppins-Bold",
                        color: "black",
                        marginTop: "5%",
                        textAlign: "left",
                        margin: "0",
                      }}
                    >
                      Suresh Santhanam Mohan
                    </h5>
                    <p
                      style={{
                        fontSize: "0.6vw",
                        fontFamily: "Poppins-Bold",
                        color: "black",
                        marginTop: "5%",
                        textAlign: "left",
                      }}
                    >
                      IIIT Hyderabad
                    </p>
                    <img
                      style={{ width: "inherit", paddingBottom: "10px" }}
                      src={microsoftLogo}
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <div
                style={{
                  borderRadius: "10px",
                  margin: "3%",
                  width: "22vw",
                  height: "fit-content",
                  boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                }}
              >
                <div style={{ display: "flex" }}>
                  <div
                    style={{ marginLeft: "6%", margin: "auto 10px", width: "30%" }}
                  >
                    <img
                      style={{ margin: "auto" }}
                      src={mentorLogo}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      margin: "auto",
                      width: "55%",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "0.7vw",
                        fontFamily: "Poppins-Bold",
                        color: "black",
                        margin: "0",
                        marginTop: "20%",
                        textAlign: "justify",
                      }}
                    >
                      
                    </p>
                    <hr
                      style={{
                        width: "25%",
                        height: "3px",
                        color: "#185ADB",
                        opacity: "1",
                        borderRadius: "5px",
                        margin: "8px",
                      }}
                    />
                    <h5
                      style={{
                        fontSize: "0.8vw",
                        fontFamily: "Poppins-Bold",
                        color: "black",
                        marginTop: "5%",
                        textAlign: "left",
                        margin: "0",
                      }}
                    >
                      Suresh Santhanam Mohan
                    </h5>
                    <p
                      style={{
                        fontSize: "0.6vw",
                        fontFamily: "Poppins-Bold",
                        color: "black",
                        marginTop: "5%",
                        textAlign: "left",
                      }}
                    >
                      IIIT Hyderabad
                    </p>
                    <img
                      style={{ width: "inherit", paddingBottom: "10px" }}
                      src={microsoftLogo}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <Carousel.Caption style={{ color: "black" }}></Carousel.Caption>
          </Carousel.Item> */}
        </Carousel>
      </div>
    </div>
  );
}

export default B2BCarousel2;

import React, { useState, useContext, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
// import "react-quill/dist/quill.snow.css";
import { AuthContext } from "../../../Context/AuthContext";
import { store } from "react-notifications-component";
import styles from "../../../Assets/css/Editor.module.css";
import "../../../Assets/css/Editor.module.css";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { BASE_URL } from "../../../env";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";

const EditBlog = () => {
  let { Objectid } = useParams();
  const [html, sethtml] = useState("");
  const [author, setAuthor] = useState("");
  const [title, setTitle] = useState("");
  const [objectID, setObjectID] = useState("");
  const [readingduration, setreadingduration] = useState("");
  // const [i]
  useEffect(() => {
    fetch(BASE_URL + "/blog/" + Objectid, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((data) => {
        sethtml(data?.blog[0]?.body);
        console.log(data?.blog[0]?.body);
        console.log(data?.blog[0]?.newid);
        // setuniqueid(data?.blog[0]?.newid);
        setAuthor(data?.blog[0].author);
        setTitle(data?.blog[0]?.title);
        setID(data?.blog[0]?.newid);
        setObjectID(data?.blog[0]?._id);
        setreadingduration(data?.blog[0]?.duration);
      });
  }, []);

  // console.log(Objectid);

  useEffect(() => {
    console.log(html);
  }, [html]);

  const handleEditorChange = (content) => {
    console.log(content);
  };
  const history = useHistory();
  if (localStorage.token === "" || localStorage.token === undefined) {
    history.push("/signin");
  }
  const { token } = useContext(AuthContext);
  const [text, setText] = useState("");
  const [invalid, setInvalid] = useState(false);
  const [loader, setLoader] = useState(false);
  const [_id, setUserID] = useState("");
  // const [value, setValue] = useState("");
  const [topic, setTopic] = useState("Choose the Topic of Blog");
  const handleSelectDrop = (e) => {
    // setValue(e);
    setTopic(e);
  };
  useEffect(() => {
    fetch(BASE_URL + "/getProfile", {
      method: "GET",
      headers: { "Content-Type": "application/json", authorization: token },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          setUserID(data?.user?._id);
        }
        // console.log(data.user._id);
      });
  }, [token]);
  function handleChange(value) {
    sethtml(value);
    console.log(value);
  }
  const [id, setID] = useState("");
  async function handleSubmit(event) {
    if (id !== "") {
      event.preventDefault();
      setLoader(true);
      let imageFile;
      const title = document.querySelector("#title").value;
      const author = document.querySelector("#author").value;
      const image = document.querySelector("#upload-file");
      if (
        title === "" ||
        author === "" ||
        image.files.length === 0 ||
        html === "" ||
        topic === ""
      ) {
        setInvalid(true);
        setLoader(false);
        return;
      }

      if (image.files.length !== 0) {
        imageFile = image.files[0];
      }

      const formData = new FormData();
      formData.append("title", title);
      formData.append("body", html);
      formData.append("author", author);
      formData.append("_id", _id);
      formData.append("blog_image", imageFile);
      formData.append("topic", topic);
      formData.append("newid", id);

      console.log(title);
      console.log(html);
      console.log(author);

      setLoader(true);
      fetch(BASE_URL + "/editBlog/" + objectID, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        method: "PUT",
        body: JSON.stringify({
          title: title,
          body: html,
          author: author,
          duration: readingduration,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          notifyPopup();
          history.push("/blog");
          setLoader(false);
        });
    } else {
      alert("please enter a unique id");
    }
  }
  const notifyPopup = () => {
    store.addNotification({
      title: "Success!",
      message: "The blog has been saved",
      type: "success",
      background: "pink",
      insert: "bottom",
      container: "bottom-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true,
      },
    });
  };

  useEffect(() => {
    // console.log("Id form the add blog", _id);
  }, []);

  return (
    <div className={styles.editorContain}>
      <div style={{ marginLeft: "5%" }}>
        <div class={styles.blogText}>
          <br />
          <h1>Add Blog! </h1>
        </div>
        <div class={styles.formgroup}>
          <input
            type="text"
            class={styles.formcontrol}
            id="title"
            placeholder="Title"
            required
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        <div class={styles.formgroup}>
          <input
            type="text"
            class={styles.formcontrol}
            id="duration"
            placeholder="Title"
            required
            value={readingduration}
            onChange={(e) => setreadingduration(e.target.value)}
          />
        </div>
        <div class={styles.formgroup}>
          <input
            type="text"
            class={styles.formcontrol}
            id="author"
            placeholder="Author"
            required
            value={author}
            onChange={(e) => setAuthor(e.target.value)}
          />
        </div>
        <DropdownButton
          style={{ margin: "1.5%" }}
          variant="warning"
          alignRight
          title={topic}
          // title="Choose the Topic of Blog"
          id="dropdown-menu-align-right"
          onSelect={handleSelectDrop}
        >
          <Dropdown.Item eventKey="Web Development">
            Web Development
          </Dropdown.Item>
          <Dropdown.Item eventKey="Data Science">Data Science</Dropdown.Item>
          <Dropdown.Item eventKey="Career/Placement">
            Career/Placement
          </Dropdown.Item>
          <Dropdown.Item eventKey="Product Companies">
            Product Companies
          </Dropdown.Item>
        </DropdownButton>

        {/* <h4>You selected {value}</h4> */}
        {/* <DragDrop /> */}
        <div className={styles.browse}>
          <label className="pl-3 pt-3" htmlFor="upload-photo">
            Browse...
          </label>
          <input
            className="ml-3"
            style={{ backgroundColor: "#e2e6e9", width: "15em" }}
            type="file"
            name="photo"
            id="upload-file"
          />
        </div>
        <div className="my-3 ">
          <label htmlFor="blogid">Enter a unique id</label>
          <input
            style={{ width: "50%" }}
            placeholder="Enter a unique ID please"
            name="blogid"
            type="text"
            value={id}
            onChange={(e) => {
              setID(e.target.value);
            }}
            className="form-control m-0"
            required
          />
        </div>
        <div>
          <SunEditor
            // setContents="My contents"
            className={styles.editorBox}
            showToolbar={true}
            value={html}
            setContents={html}
            onChange={handleChange}
            // onChange={handleEditorChange}
            setDefaultStyle="height: auto"
            setOptions={{
              buttonList: [
                [
                  "undo",
                  "redo",
                  "font",
                  "fontSize",
                  "formatBlock",
                  "paragraphStyle",
                  "blockquote",
                  "bold",
                  "underline",
                  "italic",
                  "strike",
                  "subscript",
                  "superscript",
                  "fontColor",
                  "hiliteColor",
                  "textStyle",
                  "removeFormat",
                  "outdent",
                  "indent",
                  "align",
                  "horizontalRule",
                  "list",
                  "lineHeight",
                  "table",
                  "link",
                  "image",
                  "video",
                  "fullScreen",
                  "showBlocks",
                  "codeView",
                ],
              ],
            }}
          />
        </div>
        {invalid === false ? null : (
          <p style={{ color: "red" }}>Required fields are missing</p>
        )}

        {loader === false ? (
          <button class={styles.registerbtn} onClick={handleSubmit}>
            Submit
          </button>
        ) : (
          <button class={styles.registerbtn}>Loading...</button>
        )}
      </div>
    </div>
  );
};

export default EditBlog;

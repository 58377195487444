import {Helmet} from "react-helmet";

export default function HelmetComp(props) {
    return(
        <>
            <Helmet defaultTitle="Cantilever Labs">
                <title>{props.info.title}</title>
                <meta name="og_title" property="og:title" content={props.info.title} />
                <meta name="description" content={props.info.description} /> 
                <meta name="og_description" property="og:description" content={props.info.description} />
                <meta name="og_site_name" property="og:site_name" content={props.info.siteName} />
                {/* <meta name="og_url" property="og:url" content={props.info.pageUrl} />  */}
                <meta name="twitter:card" property="twitter:card" content={props.info.summary} />
                <meta name="twitter:url" property="twitter:url" content={props.info.pageUrl} />
                <meta name="twitter:site" property="twitter:site" content={ '@' + props.info.siteName} />
                <meta name="twitter:title" property="twitter:title" content={props.info.title} />
                <meta name="twitter:description" property="twitter:description" content={props.info.description} />
                <meta name="twitter:image" property="twitter:image" content={props.info.imageUrl} />

                {/* <meta name="og_type" property="og:type" content="website" />
                <meta name="og_site_name" property="og:site_name" content={props.info.siteName} />
           
                <meta name="og_image" property="og:image" content={props.info.imageUrl} />
                <meta name="msapplication-TileImage" content={props.info.imageUrl} meta property="og:image:type" content="image/jpeg" /> */}
            </Helmet>
        </>
    )
}
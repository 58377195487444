import { useEffect, useState, useContext } from "react";
import { NavLink } from "react-router-dom";
import Footer from "../components/Footer";
import { BASE_URL } from "../env";
import { useHistory } from "react-router-dom";
// import Goal from "../Assets/img/ProfilePage/goal.png";
import ProfileIcon from "../Assets/img/ProfilePage/List22.png";
import Img1 from "../Assets/img/ProfilePage/p1.png";
import Img2 from "../Assets/img/ProfilePage/p2.png";
import Img3 from "../Assets/img/ProfilePage/p3.png";
import Img4 from "../Assets/img/ProfilePage/p4.png";
import Img5 from "../Assets/img/ProfilePage/p5.png";
import HelmetComp from "../components/HelmetComp";
import { AuthContext } from "../Context/AuthContext";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import configAxios from "../axios.config";

import "../Assets/scss/ProfilePage.scss";

function ProfilePage() {
    const [allcourses, setallcourses] = useState([]);
    const [courseids, setcourseids] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [percentage, setpercentage] = useState(0);
    const history = useHistory();

    const { name, coursesenrolled, userData } = useContext(AuthContext);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [resetPassword, setResetPassword] = useState({
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
    });
    const onHandleChange = e => {
        const value = e.target.value;
        setResetPassword({
            ...resetPassword,
            [e.target.name]: value,
        });
    };

    const onHandleSubmit = e => {
        console.log("function runing ");
        e.preventDefault();
        const resetUserPassword = {
            oldPassword: resetPassword.oldPassword,
            newPassword: resetPassword.newPassword,
            confirmPassword: resetPassword.confirmPassword,
        };

        configAxios(localStorage.getItem("token"))
            .post("/reset-user-password", resetUserPassword)
            .then(response => {
                console.log(response.status);
                localStorage.clear();
                history.push("/login");
                console.log(response.data.token);
            })
            .catch(({ response }) => {
                console.log(response);
                switch (response.status) {
                    case 409:
                        alert("Passwords Don't Match");
                        break;
                    case 403:
                        alert("Old Password is Incorrect");
                        break;

                    case 401:
                        history.push("/login");
                        break;
                    default:
                        alert("Error in Updating Password");
                }
            });
    };

    useEffect(() => {
        if (userData._id) {
            setLoaded(true);
        }
        /**
         * TODO: Progress Bar Completion needs to be added
         */
    }, [userData]);

    useEffect(() => {
        fetch(BASE_URL + "/sendallcourses", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then(res => res.json())
            .then(data => {
                setallcourses(data.courses);
            });
    }, []);

    useEffect(() => {
        if (!coursesenrolled) return;
        const newarray = coursesenrolled?.map(value => value.courseId);
        setcourseids(newarray);
    }, [coursesenrolled]);

    return (
        <>
            <HelmetComp
                info={{
                    title: "Cantilever Labs || User Profile",
                }}
            />
            {/* <Navbar /> */}
            <div id="profilepage">
                <div className="container-fluid">
                    <div className="row gx-3 gy-sm-3 gy-2">
                        <div className="col-lg-3 mb-sm-3 mb-0">
                            {/* USER PROFILE CARD */}
                            <div className="user-profile">
                                <NavLink
                                    to={`/profile-details`}
                                    className="profile-edit"
                                >
                                    <i class="fas fa-pen"></i>
                                </NavLink>
                                <div className="user-detail">
                                    <img
                                        style={{ objectFit: "contain" }}
                                        src={
                                            userData.profile_pic
                                                ? userData.profile_pic
                                                : ProfileIcon
                                        }
                                        className="user-img"
                                        alt="profile pic"
                                    />
                                    <div className="user-name">{name}</div>
                                </div>
                                <div className="percentage-showcase">
                                    <div className="profile-complete">
                                        profile complete
                                    </div>
                                    <div className="profile-bar">
                                        <div
                                            className="bar-filler"
                                            style={{ width: `${percentage}%` }}
                                        ></div>
                                    </div>
                                </div>
                                <div className="profilBtn">
                                    <div className="completeNow">
                                        {percentage !== 100 && (
                                            <NavLink
                                                to={`/profile-details`}
                                                className="completion-btn"
                                            >
                                                Complete Now
                                            </NavLink>
                                        )}
                                    </div>
                                    <div className="resetPass">
                                        {userData._id ? (
                                            <>
                                                {" "}
                                                <button
                                                    variant="primary"
                                                    onClick={handleShow}
                                                    to="/reset-password"
                                                    className="reset-btn"
                                                >
                                                    {" "}
                                                    Reset Password{" "}
                                                </button>{" "}
                                            </>
                                        ) : (
                                            <> </>
                                        )}
                                    </div>
                                </div>

                                <div>
                                    <Modal show={show} onHide={handleClose}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>
                                                Reset Password
                                            </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <Form.Label>
                                                Old Password
                                            </Form.Label>
                                            <PasswordInput
                                                name="oldPassword"
                                                value={
                                                    resetPassword.oldPassword
                                                }
                                                onChange={onHandleChange}
                                                placeholder={"Old Password"}
                                            />
                                            <Form.Label>
                                                New Password
                                            </Form.Label>
                                            <PasswordInput
                                                name="newPassword"
                                                value={
                                                    resetPassword.newPassword
                                                }
                                                onChange={onHandleChange}
                                                placeholder="New Password"
                                            />
                                            <Form.Label>
                                                Confirm Password
                                            </Form.Label>
                                            <PasswordInput
                                                name="confirmPassword"
                                                value={
                                                    resetPassword.confirmPassword
                                                }
                                                onChange={onHandleChange}
                                                placeholder="Confirm Password"
                                            />
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button
                                                variant="secondary"
                                                onClick={handleClose}
                                            >
                                                Close
                                            </Button>
                                            <Button
                                                variant="primary"
                                                onClick={onHandleSubmit}
                                            >
                                                Reset Password
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-9 mb-sm-3 mb-0 d-lg-block d-none">
                            {/* HEADER BANNER */}
                            <div className="banner">
                                <ul className="banner-list">
                                    <li className="banner-item">
                                        <img
                                            src={Img1}
                                            className="img-fluid item1"
                                            alt="banner icon"
                                        />
                                        <br />
                                        Learn <br />
                                        concepts
                                    </li>
                                    <li className="banner-item">
                                        <img
                                            src={Img2}
                                            className="img-fluid item2"
                                            alt="banner icon"
                                        />
                                        <br />
                                        practice <br />
                                        coding
                                    </li>
                                    <li className="banner-item">
                                        <img
                                            src={Img3}
                                            className="img-fluid item3"
                                            alt="banner icon"
                                        />
                                        <br />
                                        daily <br />
                                        quiz
                                    </li>
                                    <li className="banner-item">
                                        <img
                                            src={Img4}
                                            className="img-fluid item4"
                                            alt="banner icon"
                                        />
                                        <br />
                                        mock <br />
                                        interview
                                    </li>
                                    <li className="banner-item">
                                        <img
                                            src={Img5}
                                            className="img-fluid item5"
                                            alt="banner icon"
                                        />
                                        <br />
                                        toolkits
                                    </li>
                                </ul>
                            </div>
                        </div>

                        {/* PROFILE COMPLERTION CARD */}
                        <div className="col-lg-3 mb-sm-3 mb-0"></div>
                        <div className="col-lg-9 course-section">
                            {loaded ? (
                                <>
                                    <div className="row">
                                        {coursesenrolled &&
                                            coursesenrolled
                                                .map(value => {
                                                    let enrollStud = "";
                                                    if (
                                                        value.name ===
                                                        "Newton Course"
                                                    ) {
                                                        enrollStud = "92";
                                                    } else if (
                                                        value.name ===
                                                        "Einstien Course"
                                                    ) {
                                                        enrollStud = "91";
                                                    } else if (
                                                        value.name ===
                                                        "Galilieo Course"
                                                    ) {
                                                        enrollStud = "100";
                                                    } else if (
                                                        value.name ===
                                                        "Bose Course"
                                                    ) {
                                                        enrollStud = "92";
                                                    } else if (
                                                        value.name ===
                                                        "Raman Course"
                                                    ) {
                                                        enrollStud = "92";
                                                    } else if (
                                                        value.name ===
                                                        "Tesla Course"
                                                    ) {
                                                        enrollStud = "91";
                                                    } else if (
                                                        value.name ===
                                                        "Faraday Course"
                                                    ) {
                                                        enrollStud = "80";
                                                    } else if (
                                                        value.name ===
                                                        "Hawking Course"
                                                    ) {
                                                        enrollStud = "68";
                                                    } else if (
                                                        value.name ===
                                                        "Kalam Course"
                                                    ) {
                                                        enrollStud = "80";
                                                    } else {
                                                        const rand = Math.floor(
                                                            Math.random(
                                                                1200,
                                                                2200,
                                                            ) * 10000,
                                                        );
                                                        enrollStud = rand;
                                                    }
                                                    return (
                                                        <div className="col-md-4">
                                                            <div
                                                                className="course-card"
                                                                onClick={() => {
                                                                    history.push(
                                                                        `/dashboard/${value.courseId}`,
                                                                    );
                                                                    window.scroll(
                                                                        0,
                                                                        0,
                                                                    );
                                                                }}
                                                            >
                                                                <div className="course-header">
                                                                    <div className="inner-style"></div>
                                                                </div>
                                                                <div className="course-body">
                                                                    <div className="course-title">
                                                                        {
                                                                            value?.name
                                                                        }
                                                                    </div>
                                                                    <hr />
                                                                    <div className="course-detail">
                                                                        <i class="fas fa-user-graduate"></i>
                                                                        <span
                                                                            style={{
                                                                                margin: "0 5px 0 0",
                                                                            }}
                                                                        >
                                                                            {
                                                                                enrollStud
                                                                            }
                                                                        </span>
                                                                        Students
                                                                        have
                                                                        enrolled
                                                                    </div>
                                                                    <div className="course-detail">
                                                                        <i class="far fa-star"></i>
                                                                        4.6 Avg
                                                                        Rating
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                                .reverse()}
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="row">
                                        <DummyCard />
                                        <DummyCard />
                                        <DummyCard />
                                        <DummyCard />
                                        <DummyCard />
                                        <DummyCard />
                                        <DummyCard />
                                        <DummyCard />
                                        <DummyCard />
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

const DummyCard = () => {
    return (
        <>
            <div className="col-md-4">
                <div className="dummyCourseCard">
                    <div className="dummyCourseCardHeader"></div>
                    <div className="dummyCourseCardBody">
                        <div className="dummyCourseCardBodyTitle"></div>
                        <hr />
                        <div className="dummyCourseCardBodyText"></div>
                        <div className="dummyCourseCardBodyText"></div>
                    </div>
                </div>
            </div>
        </>
    );
};

const PasswordInput = props => {
    const [password, setpassword] = useState("password");
    const [type, settype] = useState(false);
    const [eye, seteye] = useState(true);

    const Eye = () => {
        if (password === "password") {
            setpassword("text");
            seteye(false);
            settype(true);
        } else {
            setpassword("password");
            seteye(true);
            settype(false);
        }
    };

    return (
        <div className="input-text">
            <input
                type={password}
                className={`${type ? "type_password" : ""}`}
                {...props}
            />
            <i
                onClick={Eye}
                className={`fa ${eye ? "fa-eye-slash" : "fa-eye"}`}
            ></i>
        </div>
    );
};

export default ProfilePage;

import React from "react";
import { useHistory } from "react-router-dom";
import TCS from "./Assets/img/NewTab/TCS.NS.png";
import BULB from "./Assets/img/NewTab/light-bulb.png";
import WALK from "./Assets/img/NewTab/running (1).png";
import TOOLS from "./Assets/img//NewTab/toolkit.png";
import Test from "./Assets/img/NewTab/search.png";
import "./InterviewPreparation.css";

const InterviewPreparation = () => {
  // const a =[]
  const history = useHistory();
  const handleClick = () => {
    history.push("/KnowledgeSession");
  };

  const handleSolve = () => {
    history.push("/questionsCommon");
  };
  return (
    <div className="main">
      <div className="nav">
        <img className="tcs" src={TCS} alt="TCS" />
        <span id="Paragraphu">TCS Code VITA & TCS Code NINJA</span>
      </div>
      {/* <div className="nav-knowledge">
        <div>
          {" "}
          <img className="tcsk" src={TCS} alt="TCS" />
          <span id="Paragraphu">TCS Code VITA & TCS Code NINJA</span>
        </div>

        <span id="Paragraphu" style={{ color: "red", marginRight: "30px" }}>
          Previously Asked Questions{" "}
        </span>
      </div> */}
      <div className="main-sec">
        <div className="box">
          <img className="searching" src={BULB} alt="light" />
          <p style={{ marginTop: "-20px" }}>Masterclass </p>

          <span className="expert">
            expert <b>SESSIONS</b>
          </span>
          <div className="btn">
            <button className="Btn" onClick={handleClick}>
              JOIN NOW
            </button>
          </div>
        </div>
        <div className="box">
          <img className="searching" src={WALK} alt="light" />
          <p style={{ marginTop: "-20px" }}>Previously</p>
          <span className="expert">
            asked <b>Questions</b>
          </span>
          <div>
            <button onClick={handleSolve} className="Btn">
              LETS SOLVE
            </button>
          </div>
        </div>

        <div className="box">
          <img className="searching" src={Test} alt="light" />
          <p style={{ marginTop: "-20px" }}>Actual</p>
          <span className="expert">
            mock <b>TESTS</b>
          </span>
          <div>
            <button className="Btn">LETS CRACK</button>
          </div>
        </div>
      </div>

      <div className="footer">
        <img className="tools" src={TOOLS} alt="file" />
        <div className="v2"></div>
        <p>
          <b>TEST-PAPER questions</b> , <b>INTERVIEW</b> questions &{" "}
          <b> PRO-TIPS</b>
        </p>
        <div className="TCSCRACK">
          <button id="btn-crack">Let's crack TCS</button>
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
    </div>
  );
};

export default InterviewPreparation;

import styles from "../Assets/css/newcoursepage.module.css";
import { useLocation } from 'react-router-dom';
import mentr1 from "../Assets/newcoursepage_images/mentors/m1.svg";
import mentr2 from "../Assets/newcoursepage_images/mentors/m2.svg";
import mentr3 from "../Assets/newcoursepage_images/mentors/m3.svg";
import mentr4 from "../Assets/newcoursepage_images/mentors/m4.svg";
import mentr5 from "../Assets/newcoursepage_images/mentors/m5.svg";
import mentr6 from "../Assets/newcoursepage_images/mentors/m6.svg";
import ceo from "../Assets/newcoursepage_images/mentors/ceo1.svg";
import co1 from "../Assets/newcoursepage_images/mentors/c1.svg";
import ReactGA from "react-ga";
import co2 from "../Assets/newcoursepage_images/mentors/c2.svg";
import useMedia from "../componentsofcourse/useMedia";
import { useState, useEffect } from "react";
const Mentor = () => {



	useEffect(() => {
		ReactGA.initialize("UA-198180460-1");
	})

  const [show, setShow] = useState(false);
  const matches = useMedia("(max-width : 570px )");
  const matches1 = useMedia("(max-width : 1170rem )");
  const matches2 = useMedia("(min-width : 570px )");
const Location=useLocation()
  return (
		<div className='my-auto'>
			<div className='container row'>
				<div className='col-sm mx-2 shadow ' style={{ borderRadius: "20px" }}>
					<a
						href='https://www.linkedin.com/in/manishkankani/'
						target='_blank'
						onClick={() => {
							ReactGA.event({
								category: "Mentors",
								type: "Mentor Linkedin",
								name: "Manish Kankani LinkedIn Profile",
								action: "Manish Kankani LinkedIn Profile",
							});
						}}
						style={{ textDecoration: "none", color: "black" }}>
						<img height='95%' src={mentr1} width='100%' alt='no img' />
					</a>
				</div>
				<div className='col-sm mx-2 shadow ' style={{ borderRadius: "20px" }}>
					<a
						href='https://www.linkedin.com/in/vivek-jhaver-8853a261/'
						target='_blank'
						onClick={() => {
							ReactGA.event({
								category: "Mentors",
								type: "Mentor Linkedin",
								name: "Vivek Jhaver LinkedIn Profile",
								action: "Vivek Jhaver LinkedIn Profile",
							});
						}}
						style={{ textDecoration: "none", color: "black" }}>
						<img src={mentr2} width='100%' alt='no img' />
					</a>
				</div>
				<div className='col-sm mx-2 shadow ' style={{ borderRadius: "20px" }}>
					<a
						href='https://www.linkedin.com/in/sureshsan/'
						target='_blank'
						onClick={() => {
							ReactGA.event({
								category: "Mentors",
								type: "Mentor Linkedin",
								name: "Sureshsan  LinkedIn Profile",
								action: "Sureshan  LinkedIn Profile",
							});
						}}
						style={{ textDecoration: "none", color: "black" }}>
						<img src={mentr3} width='100%' alt='no img' />
					</a>
				</div>
			</div>
			<div className='container row'>
				<div className='col-sm mx-2 shadow ' style={{ borderRadius: "20px" }}>
					<a
						href='https://www.linkedin.com/in/vivek-jhunjhunwala/'
						target='_blank'
						onClick={() => {
							ReactGA.event({
								category: "Mentors",
								type: "Mentor Linkedin",
								name: "vivek jhunjhunwala LinkedIn Profile",
								action: "vivek jhunjhunwala LinkedIn Profile",
							});
						}}
						style={{ textDecoration: "none", color: "black" }}>
						<img
							style={{ height: "97%" }}
							src={mentr4}
							width='100%'
							alt='no img'
						/>
					</a>
				</div>
				<div className='col-sm mx-2 shadow ' style={{ borderRadius: "20px" }}>
					<a
						href='https://www.linkedin.com/in/sanjay-nair-/'
						target='_blank'
						onClick={() => {
							ReactGA.event({
								category: "Mentors",
								type: "Mentor Linkedin",
								name: "Sanjay Nair LinkedIn Profile",
								action: "Sanjay Nair LinkedIn Profile",
							});
						}}
						style={{ textDecoration: "none", color: "black" }}>
						<img src={mentr5} width='100%' alt='no img' />
					</a>
				</div>
				<div className='col-sm mx-2 shadow ' style={{ borderRadius: "20px" }}>
					<a
						href='https://www.linkedin.com/in/amitvaid/'
						target='_blank'
						onClick={() => {
							ReactGA.event({
								category: "Mentors",
								type: "Mentor Linkedin",
								name: "Amit Vaid LinkedIn Profile",
								action: "Amit Vaid LinkedIn Profile",
							});
						}}
						style={{ textDecoration: "none", color: "black" }}>
						<img src={mentr6} width='100%' alt='no img' />
					</a>
				</div>
			</div>
			{Location.pathname === "/" ? (
				<div className='container row'>
					<div className='col-sm mx-2 shadow ' style={{ borderRadius: "20px" }}>
						<a
							href='https://www.linkedin.com/in/avanish-mundhra-6a938848/?originalSubdomain=in'
							target='_blank'
							onClick={() => {
								ReactGA.event({
									category: "Mentors",
									type: "Mentor Linkedin",
									name: "Avanish  Mundra LinkedIn Profile",
									action: "Avanish Mundra LinkedIn Profile",
								});
							}}
							style={{ textDecoration: "none", color: "black" }}>
							<img src={ceo} width='100%' alt='no img' />
						</a>
					</div>
					<div className='col-sm mx-2 shadow ' style={{ borderRadius: "20px" }}>
						<a
							href='https://www.linkedin.com/in/rakeshpatel21/?originalSubdomain=in'
							target='_blank'
							onClick={() => {
								ReactGA.event({
									category: "Mentors",
									type: "Mentor Linkedin",
									name: "Rakesh Patel LinkedIn Profile",
									action: "Rakesh Patel LinkedIn Profile",
								});
							}}
							style={{ textDecoration: "none", color: "black" }}>
							<img src={co1} width='100%' alt='no img' />
						</a>
					</div>
					<div className='col-sm mx-2 shadow ' style={{ borderRadius: "20px" }}>
						<a
							href='https://www.linkedin.com/in/vijaykaushikcl/?originalSubdomain=in'
							target='_blank'
							onClick={() => {
								ReactGA.event({
									category: "Mentors",
									type: "Mentor Linkedin",
									name: "Vijay Kaushik LinkedIn Profile",
									action: "Vijay Kaushik LinkedIn Profile",
								});
							}}
							style={{ textDecoration: "none", color: "black" }}>
							<img src={co2} width='100%' alt='no img' />
						</a>
					</div>
				</div>
			) : (
				""
			)}
		</div>
	);
};

export default Mentor;

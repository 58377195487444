import "bootstrap/dist/css/bootstrap.min.css";
import { useContext, useEffect, useState } from "react";
import ReactPixel from "react-facebook-pixel";
import "react-phone-number-input/style.css";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import styles from "../Assets/css/signup.module.css";
import style from "../Assets/css/TCSform.module.css";
import form_sign_img from "../Assets/img/formsign_img.png";
import form_sign_img2 from "../Assets/img/formsign_img2.png";
import success_img from "../Assets/img/success_vector.svg";
import usePasswordToggle from "../components/usePasswordToggle";
import useMedia from "../componentsofcourse/useMedia";
import { AuthContext } from "../Context/AuthContext";
import { BASE_URL } from "../env";
import firebase from "../firebase";
import PopupAd from "../Pages/PopupAd";
import ThanksPage from "../Pages/ThanksPage";
import optionCities from "../Pages/cities";

// const BASE_URL = "http://localhost:5000"
// import { containerCSS } from "react-select/src/components/containers";

const NewMasterClassForm = () => {
  var db = firebase.database();
  const queryParams = new URLSearchParams(window.location.search);
  const refferal = queryParams.get("referral");
  // alert(refferal)
  // const webinar_id = queryParams.get("webinar");
  const history = useHistory();
  let { update_token } = useContext(AuthContext);
  const matches = useMedia("(min-width : 768px )");
  const [email, setEmail] = useState("");
  const [firstName, setfirstName] = useState("");
  const [phone, setPhone] = useState(0);
  const [collegeName, setCollegename] = useState("");
  const [password, setpassword] = useState("");
  const [city, setCity] = useState("");
  const [thanks, setThanks] = useState(false);
  const [firstForm, setFirstForm] = useState(true);
  const [secondForm, setSecondForm] = useState(false);
  const [secondForm_2, setSecondForm_2] = useState(false);
  const [thirdForm, setThirdForm] = useState(false);
  const [fourthForm, setFourthForm] = useState(false);
  const [completion, setCompletion] = useState(false);
  const [value, setValue] = useState();
  const [yearOfPassout, setYearOfPassout] = useState("");
  const [validationAlert, setValidationAlert] = useState("");
  const [passwordInputType, ToggleIcon] = usePasswordToggle();
  const token = localStorage.getItem("token");

  const gd = [
    { label: "BE/BTech", value: "Bachelor of Engg./tech" },
    { label: "Bsc", value: 1 },
    { label: "BCA", value: 1 },
    { label: "BBA", value: 1 },
    { label: "B.com", value: 1 },
    { label: "MBA", value: 1 },
    { label: "Others", value: 1 },
  ];

  const graduationyear = () => {
    let date = new Date().getFullYear();
    let arr = [];
    for (let i = date + 9; i > 2000; i--) {
      arr.push({ value: i, label: i });
    }
    return arr;
  };
  const sem = [
    { label: "1st", value: 1 },
    { label: "2nd", value: 2 },
    { label: "3rd", value: 3 },
    { label: "4th", value: 4 },
    { label: "5th", value: 5 },
    { label: "6th", value: 6 },
    { label: "7th", value: 7 },
    { label: "8th", value: 8 },
  ];
  const pl1 = [
    { label: "I have no idea where I am.", value: 1 },
    { label: "I Haven't started any placement preparation yet!", value: 2 },
    {
      label: "I do have theoretical knowledge but no hands-on experience",
      value: 4,
    },
    {
      label:
        "I have done some projects but have zero knowledge of the interview process",
      value: 5,
    },
    {
      label:
        "I am confident about my Coding & Aptitude skills but lack in Soft skills",
      value: 6,
    },
    { label: "I am confident about my Aptitude Skills", value: 7 },
    { label: "I am confident about my Coding Skills", value: 8 },
  ];

  const pl2 = [
    {
      label: "I need guidance with getting placed in FAANG companies",
      value: 1,
    },
    {
      label: "I need guidance with cracking Entrance test of Tech companies",
      value: 2,
    },
    {
      label: "I need help in getting practical exposure with good internships",
      value: 3,
    },
    { label: "I need help to prepare for aptitude skills", value: 4 },
    { label: "I need help in upskilling my softskills", value: 5 },
    { label: "I need help with my interview preparation", value: 6 },
    { label: "I need support in project based learning", value: 7 },
  ];

  const next_webinar_question = [
    {
      label: "How to get placed in FAANG companies.",
      value: 1,
    },
    {
      label: "How to crack Product companies interview?",
      value: 2,
    },
    {
      label: "How to win the game of placement?",
      value: 3,
    },
    { label: "Tips to make resume that gets you shortlisted", value: 4 },
    {
      label:
        "Secret hacks and tips to crack Quant section of any aptitude test",
      value: 5,
    },
    {
      label:
        " How to solve complex coding questions fast during placement tests",
      value: 6,
    },
    { label: " Tips and tricks to crack interview", value: 7 },
  ];

  const pl3 = [
    { label: "Test Series", value: 1 },
    { label: "Competitive Coding", value: 2 },
    { label: "Interview Preparation", value: 3 },
    { label: "Problem Solving", value: 4 },
    { label: "Free Toolkits", value: 5 },
    { label: "Resume Building", value: 6 },
    { label: "Boosting Softskills", value: 7 },
    { label: "Industry Expert Webinars", value: 8 },
    { label: "Project Based Learning", value: 9 },
    { label: "Internships / Jobs", value: 10 },
    { label: "Certificates", value: 11 },
    { label: "Live session", value: 11 },
    { label: "Recorded session", value: 11 },
    { label: "All of the above", value: 12 },
  ];

  const c1 = [
    { label: "TCS", value: 1 },
    { label: "Amazon", value: 2 },
    { label: "Google", value: 3 },
    { label: "Oracle", value: 4 },
    { label: "Accenture", value: 5 },
    { label: "Infosys", value: 6 },
    { label: "Wipro", value: 7 },
    { label: "Cognizant", value: 8 },
    { label: "FAANG", value: 9 },
  ];

  const c2 = [
    { label: "Data Science", value: 1 },
    { label: "Software Development", value: 2 },
    { label: "Machine Learning", value: 3 },
    { label: "Artificial Intelligence", value: 4 },
    { label: "Product Development", value: 5 },
    { label: "Full Stack Development", value: 6 },
    { label: "Cyber Security", value: 7 },
  ];

  const [department, setDepartment] = useState("");
  const [degree, setDegree] = useState("");
  const [preparationLevel, setPreparationLevel] = useState("");
  const [preparationHelp, setPreperationHelp] = useState([]);
  const [next_webinar, setNextWebinar] = useState([]);
  const [primaryInterest, setPrimaryInterest] = useState([]);
  const [dreamCompany, setDreamCompany] = useState([]);
  const [careerInterest, setCareerInterest] = useState([]);
  const [questions, setQuestions] = useState("");
  const [dep, setDep] = useState([]);
  const [checked, setChecked] = useState(true);
  function inputFocus(val) {
    let focus_input = document.querySelector(`.${val} input`);
    focus_input.focus();
    // console.log(focus_input);
    if (val === "college_name") {
      focus_input.style.border = "2px solid red";
      return;
    }
    let focus_style = document.querySelector(`.${val} > div`).style;
    focus_style.border = "2px solid red";
    focus_style.boxShadow = "none";
  }
  const customStyles = {
    option: (provided) => ({
      ...provided,
      textAlign: "left",
    }),
    container: (provided) => ({
      ...provided,
      border: "none",
      padding: "1px",
      width: "60%",
    }),
  };

  useEffect(() => {
    setDepartment("");
    setDep((prev) => {
      if (degree === "BE/BTech" || degree === "Bsc") {
        return setDep([
          { label: "CSE", value: 1 },
          { label: "ECE", value: 1 },
          { label: "IT", value: 1 },
          { label: "EEE", value: 1 },
          { label: "MECH", value: 1 },
          { label: "CIVIL", value: 1 },
          { label: "Others", value: 1 },
        ]);
      }
      return [];
    });
  }, [degree]);

  function handleDepChange(e) {
    setDepartment(e.label);
  }

  useEffect(() => {
    // console.log("Webinar ID: ", webinar_id);
  }, []);

  function responsehandler(e) {
    //google sign in code
    // console.log(e);
  }

  function sthanks() {
    setThanks(!thanks);
  }

  function csecondForm() {
    window.scroll(0, 0);
    setFirstForm(false);
    setSecondForm(true);
  }

  function cthirdForm() {
    window.scroll(0, 0);
    setSecondForm_2(false);
    setThirdForm(true);
  }

  function cfourthForm() {
    window.scroll(0, 0);
    setThirdForm(false);
    setFourthForm(true);
  }

  function ccompletion() {
    window.scroll(0, 0);

    setFourthForm(false);
    setCompletion(true);
  }

  const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
  const options = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: false, // enable logs
  };

  useEffect(() => {
    ReactPixel.init("337209321107283", advancedMatching, options);
    ReactPixel.trackCustom("TCSfreeenter");
  }, []);

  function SubmitHandler() {
    setValidationAlert("");
    // console.log("City", city);
    if (
      email == "" ||
      phone == "" ||
      password == "" ||
      phone.length !== 10 ||
      city === ""
    ) {
      if (email === "") setValidationAlert("Please enter a valid email");
      else if (phone === "")
        setValidationAlert("Please enter your mobile number");
      else if (password === "") setValidationAlert("Please enter a password");
      else if (city === "") setValidationAlert("Please Enter Correct City!");
      else setValidationAlert("Please enter a valid mobile number");
      return;
    }

    if (token) {
      setFirstForm(false);
      csecondForm();
      // console.log("Signed Up!");
      window.scroll(0, 0);
    } else {
      fetch(BASE_URL + "/signup", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          referral: "placement_webinar",
          firstName: firstName,
          password: password,
          collegeName: collegeName,
          mobileNumber: phone,
          yearOfPassout: "",
          referralTCS: refferal,
          source:"new master class",
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          // console.log(response);
          if (
            response.type == "success" ||
            response.message == "User already exist"
          ) {
            signin();
            csecondForm();
            // console.log("Signed Up!");

            setFirstForm(false);
            window.scroll(0, 0);
          } else {
            setValidationAlert("Enter valid data in the input fields");
          }
        })
        .catch((err) => {
          // console.log(err);
        });
    }
  }

  const [showInterested, setShowInterested] = useState(true);

  function CloseTheButton() {
    setShowInterested(false);
    // history.push("/mycourse/6114d578f6cd9f0e9e8a811d");
  }
  function PushTheUser() {
    setShowInterested(false);

    // history.push("/mycourse/6114d578f6cd9f0e9e8a811d");
  }
  function InterestedUser() {
    fetch(BASE_URL + "/userInterested", {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: JSON.stringify({
        interestedCourse: true,
      }),
    })
      .then((response) => {})
      .catch((err) => {
        // console.log(err);
      });
    PushTheUser();
  }
  function signin(val = "initial") {
    // console.log("hello there sign in");
    fetch(BASE_URL + "/signin", {
      method: "POST",
      body: JSON.stringify({
        // id: 3,
        email: email,
        password: password,
        isGoogle: false,
      }),
      headers: { "content-Type": "application/json" },
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log("hello there sign in");

        if (Object.keys(data).find((element) => element === "token")) {
          localStorage.setItem("token", data.token);
          update_token(data.token);
          // setSignedIn(true)
          if (
            history.location.state != undefined &&
            Object.keys(history.location.state).find(
              (element) => element === "prev"
            )
          ) {
            if (data.status == 403)
            //  console.log("error");
            // val==="final"? setThanks(false):setThanks(true);
            if (val !== "final") {
              //   setThanks(true);
              // console.log("Signed in");
              csecondForm();
            }

            // alert("move to blog")
            // setRoute(history.location.state.prev);
          } else {
            // history.push("/");
            // setRoute("/");
          }
        } else {
          // setError(true);
          // setErrmsg(data.message);
          // setLoad(false);
          // pwdRef.current.value = "";
        }
      });
  }

  const SubmitQuestion = () => {
    let data = {
      email,
      webinarId: "95111151023",
      firstName,
      mobileNumber: phone,
      currentCity: city,
      graduationDegree: degree,
      department: department,
      yearOfStudy: yearOfPassout,
      collegeName,
      careerInterest: careerInterest,
      dreamCompany: dreamCompany,
      next_webinar_questions: next_webinar,
      areaOfInterest: [
        preparationLevel,
        preparationLevel +
          " [" +
          primaryInterest +
          " ],[" +
          careerInterest +
          "],[" +
          preparationHelp +
          "]",
      ],
      referalCode: refferal,
      questions: questions,
      component: "tcs_wipro_Strategy_0709",
    };

    let firebase_data = {
      data_ref: "Masterclass form desktop",
      email,
      webinarId: "95111151023",
      firstName,
      mobileNumber: phone,
      currentCity: city,
      graduationDegree: degree,
      department: department,
      yearOfStudy: yearOfPassout,
      collegeName,
      careerInterest: careerInterest,
      dreamCompany: dreamCompany,
      next_webinar_questions: next_webinar,
      areaOfInterest: [
        preparationLevel,
        preparationLevel +
          " [" +
          primaryInterest +
          " ],[" +
          careerInterest +
          "]",
      ],
      referalCode: refferal,
      questions: questions,
      component: "tcs_wipro_Strategy_0709",
    };
    db.ref("Masterclass")
      .push(firebase_data)
      .then(() => {
        // console.log("Data Submitted");
      })
      .catch((err) => {
        // console.log(err);
      });

    // console.log(data);
    // console.log(JSON.stringify(data));
    ReactPixel.init("337209321107283", advancedMatching, options);
    ReactPixel.trackCustom("masterclass_placement_2908_completed");
    fetch(BASE_URL + "/webinars/Addregistration", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((response) => {
        // console.log(response);
        signin("final");
        // console.log("chala gaya data");
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  function chooseTCSForm() {
    fetch(BASE_URL + "/webinars/updatewebinar", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
      body: JSON.stringify({
        TCSOption: true,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        // console.log(response);
        // enrollTheUser()
      })
      .catch((err) => {
        // console.log(err);
      });
  }

  function enrollTheUser() {
    fetch(BASE_URL + "/enroll/61012f44eb1f466b74f1459d", {
      headers: { authorization: "Bearer " + localStorage.getItem("token") },
      method: "POST",
    })
      .then((res) => res.json())
      .then((data) => {
        // history.push("/mycourse/61012f44eb1f466b74f1459d/");

        // console.log(data);
        history.push("/");
        alert("choosen");
        // console.log(data)
      });
  }

  return (
    <div style={{ overflowX: "hidden" }}>
      {matches == true ? (
        <>
          {/* <Navbar /> */}
          {thanks ? (
            <div
              className="container-fluid p-5 my-auto mx-auto my-auto"
              style={{ borderRadius: "15px" }}
            >
              <div className="container d-flex flex-column justify-content-center align-items-center">
                <img
                  src={success_img}
                  alt="success"
                  style={{ width: "200px" }}
                />
                <h2 className="text-primary">You're Successfully Signed In.</h2>
                <p
                  style={{ color: "gray", width: "10%", color: "#111" }}
                  className={styles.horizontalh + " my-3"}
                ></p>
                <p className="text-center">
                  <strong>
                    Congrats {firstName}! You’ve cracked the first step. <br />
                    Allow us to serve you better by filling in the rest.
                  </strong>
                </p>
                <button
                  className={`btn btn-primary ${style.next_btn}`}
                  onClick={() => {
                    // setSecondForm(true);
                    csecondForm();
                    setThanks(false);
                  }}
                >
                  Next
                </button>
              </div>
            </div>
          ) : firstForm ? (
            <>
              <div
                className="text-center"
                style={{ marginTop: "60px", fontSize: "409px" }}
              >
                <h1 style={{ marginTop: "60px" }}>Master Class On</h1>
                <h1> Ace Aptitude Section of Company Hiring Tests</h1>
              </div>

              <div
                className={
                  "container-fluid row my-5 py-5 " + style.verticleCenter
                }
                style={{ left: "50%", overflow: "hidden" }}
              >
                <div
                  className={"col-sm-6  my-2 "}
                  style={{
                    paddingLeft: "15%",
                  }}
                >
                  <img
                    src={form_sign_img}
                    alt=""
                    className="img-fluid img-responsive"
                  />
                </div>
                <div className="col-sm-6  my-auto">
                  <label for="name">Name</label>
                  <br />
                  <input
                    className={style.newinput}
                    id="name"
                    type="text"
                    placeholder="Name"
                    value={firstName}
                    onChange={(e) => setfirstName(e.target.value)}
                  />
                  <br />
                  <label for="email">Email</label>
                  <br />
                  <input
                    className={style.newinput}
                    type="email"
                    id="email"
                    placeholder="E-mail"
                    onChange={(e) =>
                      e.target.validity.valid ? setEmail(e.target.value) : <></>
                    }
                  />
                  <br />
                  <label for="password">Password</label>
                  <br />
                  <input
                    id="password"
                    className={style.newinput}
                    type={passwordInputType}
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setpassword(e.target.value)}
                  />
                  <span>{ToggleIcon}</span>
                  <br />
                  {/* <label for="password">Phone Number</label><br/> */}
                  {/* <input
                    className={style.newinput}
                    id="phone"
                    type="tel"
                    placeholder="Phone Number"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  /> */}

                  <label for="mobileNumber">Mobile Number </label>
                  <br />
                  <input
                    type="number"
                    id="mobileNumber"
                    className={style.newinput}
                    placeholder="Mobile Number"
                    onChange={(e) => setPhone(e.target.value)}
                  />
                  <br />
                  <label for="city">City </label>
                  <br />

                  <Select
                    styles={customStyles}
                    isSearchable
                    id="City"
                    placeholder="Enter your City"
                    options={optionCities}
                    onChange={(e) => {
                      setCity(e.label);
                    }}
                  />
                  <br />
                  <span
                    className={"text-dark "}
                    style={{
                      textAlign: "justify",
                      fontSize: "0.7rem",
                      width: "20%",
                    }}
                  >
                    {" "}
                    <input
                      onChange={() => {
                        setChecked(!checked);
                      }}
                      type="checkbox"
                      name=""
                      id=""
                      checked={checked}
                    />{" "}
                    I agree to Cantilever Labs Terms of Service and the <br />{" "}
                    use of personal data as explained in <br /> Cantilever Labs{" "}
                    <a href="https://www.cantileverlabs.com/policies">
                      {" "}
                      terms and conditions{" "}
                    </a>{" "}
                  </span>
                  <br />

                  <button
                    disabled={checked == false}
                    onClick={() => {
                      SubmitHandler();
                    }}
                    className={`btn btn-primary ${style.newbtn}`}
                  >
                    Submit
                  </button>
                  {
                    <div className="mt-3" style={{ color: "red" }}>
                      {validationAlert}
                    </div>
                  }
                  {/* <p style={{ color: "gray",width:"60%" }} className={styles.horizontalh + " my-3"}>
											<span
												style={{ color: "gray",backgroundColor:"white" }}
												className={styles.horizontal}
											>
										
												or
											</span>
										</p> */}
                  {/* <GoogleLogin
											clientId="671105516475-03k9jvsa3evmg1k4u5ge13alls8v9nfv.apps.googleusercontent.com"
											render={(renderProps) => (
												// <button onClick={renderProps.onClick} disabled={renderProps.disabled}> <p> This is my custom Google button </p> </button>

												<button
													onClick={renderProps.onClick}
													// disabled={renderProps.disabled}
													disabled={false}
													style={{width:"60%",display:"block"}}
													class={styles.googlebutton}
												>
													<span
														style={{ right: matches ? "20px" : null }}
														class={styles.googlebuttonicon}
													>
														<svg
															viewBox="0 0 366 372"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																d="M125.9 10.2c40.2-13.9 85.3-13.6 125.3 1.1 22.2 8.2 42.5 21 59.9 37.1-5.8 6.3-12.1 12.2-18.1 18.3l-34.2 34.2c-11.3-10.8-25.1-19-40.1-23.6-17.6-5.3-36.6-6.1-54.6-2.2-21 4.5-40.5 15.5-55.6 30.9-12.2 12.3-21.4 27.5-27 43.9-20.3-15.8-40.6-31.5-61-47.3 21.5-43 60.1-76.9 105.4-92.4z"
																id="Shape"
																fill="#EA4335"
															/>
															<path
																d="M20.6 102.4c20.3 15.8 40.6 31.5 61 47.3-8 23.3-8 49.2 0 72.4-20.3 15.8-40.6 31.6-60.9 47.3C1.9 232.7-3.8 189.6 4.4 149.2c3.3-16.2 8.7-32 16.2-46.8z"
																id="Shape"
																fill="#FBBC05"
															/>
															<path
																d="M361.7 151.1c5.8 32.7 4.5 66.8-4.7 98.8-8.5 29.3-24.6 56.5-47.1 77.2l-59.1-45.9c19.5-13.1 33.3-34.3 37.2-57.5H186.6c.1-24.2.1-48.4.1-72.6h175z"
																id="Shape"
																fill="#4285F4"
															/>
															<path
																d="M81.4 222.2c7.8 22.9 22.8 43.2 42.6 57.1 12.4 8.7 26.6 14.9 41.4 17.9 14.6 3 29.7 2.6 44.4.1 14.6-2.6 28.7-7.9 41-16.2l59.1 45.9c-21.3 19.7-48 33.1-76.2 39.6-31.2 7.1-64.2 7.3-95.2-1-24.6-6.5-47.7-18.2-67.6-34.1-20.9-16.6-38.3-38-50.4-62 20.3-15.7 40.6-31.5 60.9-47.3z"
																fill="#34A853"
															/>
														</svg>
													</span>
													<span
														style={{ marginRight: matches ? "10%" : null }}
														class={styles.googlebuttontext}
													>
														LogIn with Google
													</span>
												</button>
											)}
											className="pl-5 pl-5"
											buttonText="Login with google"
											onSuccess={responsehandler}
											cookiePolicy={"single_host_origin"}
										/> */}
                </div>
              </div>
            </>
          ) : null}
          {secondForm ? (
            <>
              <div className="text-center" style={{ marginTop: "60px" }}>
                <h1 style={{ marginTop: "60px" }}>Master Class On</h1>
                <h1> Ace Aptitude Section of Company Hiring Tests</h1>
              </div>

              <div
                className={
                  "container-fluid row my-5 py-5 " + style.verticleCenter
                }
                style={{ left: "7%", paddingLeft: "15%" }}
              >
                <div className="col-lg-6 col-md-6 col-sm-12 mx-auto ">
                  <h2 style={{ color: "blue", marginBottom: "20px" }}>
                    Professional Details
                  </h2>
                  {/* College name  (dropdown using api key)*/}
                  College Name
                  <div className="row">
                    {/* <div className="col-md-1"></div> */}
                    <div className="col-md-8 p-0 mb-1 college_name">
                      <input
                        type="text"
                        className={style.newcollegeinput}
                        style={{
                          width: "100%",
                        }}
                        placeholder="College Name"
                        onChange={(e) => setCollegename(e.target.value)}
                      />
                    </div>
                  </div>
                  Graduation degree
                  <div className="row">
                    {/* <div className="col-md-1"></div> */}
                    <div className="col-md-8 p-0">
                      <Select
                        className="graduation_degree"
                        options={gd}
                        onChange={(e) => {
                          setDegree(e.label);
                        }}
                      />
                    </div>
                  </div>
                  <div className="mx-auto container">
                    <button
                      onClick={() => {
                        if (collegeName === "")
                          return inputFocus("college_name");
                        if (degree === "")
                          return inputFocus("graduation_degree");
                        window.scroll(0, 0);
                        setSecondForm(false);
                        setSecondForm_2(true);
                      }}
                      className={`btn btn-primary mx-auto`}
                      style={{
                        marginBottom: "160px",
                        width: "65%",
                      }}
                    >
                      Next
                    </button>
                  </div>
                </div>
                <div
                  className={"col-sm-6 col-lg-6 col-md-6 my-auto "}
                  style={{
                    paddingLeft: "1%",

                    overflow: "hidden",
                  }}
                >
                  <img
                    src={form_sign_img2}
                    style={{ marginBottom: "24%" }}
                    alt=""
                    className="img-fluid img-responsive"
                  />
                </div>
              </div>
            </>
          ) : null}
          {secondForm_2 ? (
            <>
              <>
                <div className="text-center" style={{ marginTop: "60px" }}>
                  <h1 style={{ marginTop: "60px" }}>Master Class On</h1>
                  <h1>
                    {" "}
                    Ace Aptitude Section of Company Hiring Tests
                  </h1>
                </div>

                <div
                  className={
                    "container-fluid row my-5 py-5 " + style.verticleCenter
                  }
                  style={{ left: "7%", paddingLeft: "15%" }}
                >
                  <div className="col-lg-6 col-md-6 col-sm-12 mx-auto ">
                    <h2 style={{ color: "blue", marginBottom: "20px" }}>
                      Professional Details
                    </h2>
                    Department
                    <div className="row">
                      {/* <div className="col-md-1"></div> */}
                      <div className="col-md-8 p-0">
                        <Select
                          menuShouldScrollIntoView={true}
                          className="department_name"
                          options={dep}
                          onChange={(e) => handleDepChange(e)}
                        />
                      </div>
                    </div>
                    Graduation Year:
                    <div className="row">
                      <div className="col-md-8 p-0">
                        <Select
                          className="year_of_passout"
                          menuShouldScrollIntoView={true}
                          options={graduationyear()}
                          onChange={(e) => setYearOfPassout(e.label)}
                        />
                      </div>
                    </div>
                    <div className="mx-auto container">
                      <button
                        onClick={() => {
                          let checkState =
                            degree === "BE/BTech" && department === "";
                          if (checkState) return inputFocus("department_name");
                          if (yearOfPassout === "")
                            return inputFocus("year_of_passout");
                          cthirdForm();
                        }}
                        className={`btn btn-primary mx-auto`}
                        style={{
                          marginBottom: "160px",
                          width: "65%",
                        }}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                  <div
                    className={"col-sm-6 col-lg-6 col-md-6 my-auto "}
                    style={{
                      paddingLeft: "1%",

                      overflow: "hidden",
                    }}
                  >
                    <img
                      src={form_sign_img2}
                      style={{ marginBottom: "24%" }}
                      alt=""
                      className="img-fluid img-responsive"
                    />
                  </div>
                </div>
              </>
            </>
          ) : null}
          {thirdForm ? (
            <>
              <div className="text-center" style={{ marginTop: "60px" }}>
                <h1 style={{ marginTop: "60px" }}>Master Class On</h1>
                <h1> Ace Aptitude Section of Company Hiring Tests</h1>
              </div>

              <div
                className={
                  "container-fluid row my-5 py-5 " + style.verticleCenter
                }
                style={{ left: "7%", paddingLeft: "15%" }}
              >
                <div className={"col-lg-6 col-md-6 col-sm-12 mx-auto"}>
                  <h2 style={{ color: "blue", marginBottom: "20px" }}>
                    Preparation Level
                  </h2>
                  <div>
                    1.What is your level of preparation?
                    <div className="container">
                      <div className="row">
                        {/* <div className="col-md-1"></div> */}
                        <div className="col-md-8 p-0">
                          <Select
                            className="plq_1"
                            menuShouldScrollIntoView={true}
                            options={pl1}
                            onChange={(e) => setPreparationLevel(e.label)}
                          />
                        </div>

                        {/* <div className="col-md-4"></div> */}
                      </div>
                    </div>
                    {/* 
        <input 
                  // className={style.newinput}
                  // onChange={(e) => setDegree(e.target.value)}
                  type="radio" 
                  id="css" 
                  className={style.form_radio_button}
                  name="prep" 
                  onclick="displayRadioValue()"
                  value="A"
                  // value={}
                  />
                <label for="A">A</label><br/>

                 */}
                    2.How can we become a helping hand in your preparation?
                    <div className="container">
                      <div className="row">
                        {/* <div className="col-md-1"></div> */}
                        <div className="col-md-8 p-0">
                          <Select
                            className="plq_2"
                            menuShouldScrollIntoView={true}
                            placeholder={"Select multiple values"}
                            options={pl2}
                            isMulti
                            onChange={async (e) => {
                              // console.log(e);
                              let arr = [];
                              e.map((val) => {
                                arr.push(val.label);
                              });
                              await setPreperationHelp(arr);
                            }}
                          />
                        </div>
                        {/* <div className="col-md-4"></div> */}
                      </div>
                    </div>
                    3.What are you primarily looking for ?
                    <div className="container">
                      <div className="row">
                        {/* <div className="col-md-1"></div> */}
                        <div className="col-md-8 p-0 ">
                          <Select
                            className="plq_3"
                            menuShouldScrollIntoView={true}
                            placeholder={"Select multiple values"}
                            options={pl3}
                            isMulti
                            onChange={async (e) => {
                              let arr = [];
                              e.map((val) => {
                                arr.push(val.label);
                              });
                              setPrimaryInterest(arr);
                            }}
                          />
                        </div>
                        {/* <div className="col-md-4"></div> */}
                      </div>
                    </div>{" "}
                    <div className="container mx-auto">
                      <button
                        onClick={() => {
                          if (preparationLevel === "")
                            return inputFocus("plq_1");
                          if (preparationHelp.length === 0)
                            return inputFocus("plq_2");
                          if (primaryInterest.length === 0)
                            return inputFocus("plq_3");
                          cfourthForm();
                        }}
                        className={`btn btn-primary mx-auto`}
                        style={{ width: "65%", margin: "0 auto" }}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>

                <div
                  className={"col-sm-6 col-lg-6 col-md-6 my-auto "}
                  style={{
                    paddingLeft: "5%",
                    paddingTop: "2%",
                    overflow: "hidden",
                  }}
                >
                  <img
                    src={form_sign_img2}
                    alt=""
                    className="img-fluid img-responsive"
                  />
                </div>
              </div>
            </>
          ) : null}
          {fourthForm ? (
            <>
              <div className="text-center " style={{ marginTop: "60px" }}>
                <h1 style={{ marginTop: "60px" }}>Master Class On</h1>
                <h1> Ace Aptitude Section of Company Hiring Tests</h1>
              </div>

              <div
                className={
                  "container-fluid row my-5 py-5 " + style.verticleCenter
                }
                style={{ left: "7%", paddingLeft: "15%" }}
              >
                <div className="col-lg-6 col-md-6 col-sm-12 mx-auto">
                  <h3 style={{ color: "blue", marginBottom: "20px" }}>
                    Career Goals{" "}
                  </h3>
                  <div>
                    1.Which is your Dream Company
                    <div className="container">
                      <div className="row">
                        {/* <div className="col-md-1"></div> */}
                        <div className="col-md-8 p-0">
                          <Select
                            placeholder={"Multiple Options"}
                            className="cgq_1"
                            options={c1}
                            isMulti
                            onChange={async (e) => {
                              let arr = [];
                              e.map((val) => {
                                arr.push(val.label);
                              });
                              await setDreamCompany(arr);
                            }}
                          />
                        </div>

                        {/* <div className="col-md-4"></div> */}
                      </div>
                    </div>
                    2.Career Interest
                    <div className="container">
                      <div className="row">
                        {/* <div className="col-md-1"></div> */}
                        <div className="col-md-8 p-0">
                          <Select
                            className="cgq_2"
                            placeholder={"Multiple Options"}
                            options={c2}
                            isMulti
                            onChange={async (e) => {
                              let arr = [];
                              e.map((val) => {
                                arr.push(val.label);
                              });
                              await setCareerInterest(arr);
                            }}
                          />
                        </div>

                        {/* <div className="col-md-4"></div> */}
                      </div>
                    </div>
                    3. Do you have any questions to the speaker?
                    <div className="container">
                      <div className="row">
                        {/* <div className="col-md-1"></div> */}
                        <div className="col-md-8 p-0 ">
                          <input
                            id="questions"
                            className={style.newinput}
                            style={{ width: "100%" }}
                            type="text"
                            placeholder="Do You Have Any Questions To The Speaker?"
                            onChange={(e) => setQuestions(e.target.value)}
                          />
                          <br />
                        </div>
                      </div>
                    </div>
                    4. On what topic do you want us to conduct next masterclass?
                    <div className="container">
                      <div className="row">
                        {/* <div className="col-md-1"></div> */}
                        <div className="col-md-8 p-0 ">
                          <Select
                            className="next_webinar_questions"
                            menuShouldScrollIntoView={true}
                            placeholder={"Select Multiple Options"}
                            options={next_webinar_question}
                            isMulti
                            onChange={async (e) => {
                              // console.log(e);
                              let arr = [];
                              e.map((val) => {
                                arr.push(val.label);
                              });
                              await setNextWebinar(arr);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="container">
                    <button
                      onClick={() => {
                        if (dreamCompany.length === 0)
                          return inputFocus("cgq_1");
                        if (careerInterest.length === 0)
                          return inputFocus("cgq_2");
                        SubmitQuestion();
                        ccompletion();
                      }}
                      className={`btn btn-primary `}
                      style={{ width: "65%", margin: "0 auto" }}
                    >
                      Done
                    </button>
                  </div>
                </div>

                <div
                  className={"col-sm-6 col-lg-6 col-md-6 my-auto "}
                  style={{
                    paddingLeft: "5%",
                    paddingTop: "2%",
                    overflow: "hidden",
                  }}
                >
                  <img
                    src={form_sign_img2}
                    alt=""
                    className="img-fluid img-responsive"
                  />
                </div>
              </div>
            </>
          ) : null}
          :
          {completion ? (
            <>
              {showInterested === true && (
                <PopupAd
                  CloseTheButton={CloseTheButton}
                  PushTheUser={PushTheUser}
                  InterestedUser={InterestedUser}
                />
              )}
              <ThanksPage type="master" chooseTCSForm={chooseTCSForm} />
            </>
          ) : null}
        </>
      ) : (
        <>
          {/* <Navbar /> */}
          {thanks ? (
            <div
              className="container-fluid p-5 my-auto mx-auto "
              style={{ borderRadius: "15px" }}
            >
              <div className="container d-flex flex-column justify-content-center align-items-center">
                <img src={success_img} alt="success" />
                <h4 className="text-primary">You're Successfully Signed In.</h4>
                <p
                  style={{ color: "gray", width: "10%", color: "#111" }}
                  className={styles.horizontalh + " my-3"}
                ></p>
                <p className="text-center">
                  <strong>
                    Congrats {firstName}! You’ve cracked the first step. <br />
                    Allow us to serve you better by filling in the rest.
                  </strong>
                </p>
                <button
                  className={`${style.newbtn} btn btn-primary`}
                  onClick={() => {
                    // setSecondForm(true);
                    setThanks(false);
                  }}
                >
                  Next
                </button>
              </div>
            </div>
          ) : firstForm ? (
            <>
              <div
                className="text-center"
                style={{ marginTop: "60px", fontSize: "1.4rem" }}
              >
                <h1 style={{ marginTop: "60px" }}>Master Class On</h1>
                <h1> Ace Aptitude Section of Company Hiring Tests</h1>
              </div>

              <div
                className={
                  "container-fluid row my-2 py-1 mx-auto" + style.verticleCenter
                }
                // style={{ overflow: "hidden" }}
              >
                <div
                  className={"col-sm-6  my-2 "}
                  style={{
                    paddingLeft: "15%",
                    overflow: "hidden",
                  }}
                ></div>
                <div className="col-sm-8 p-0" style={{ marginLeft: "24%" }}>
                  <label for="name">Name</label>
                  <br />
                  <input
                    className={style.newinput}
                    id="name"
                    type="text"
                    placeholder="Name"
                    value={firstName}
                    onChange={(e) => setfirstName(e.target.value)}
                  />
                  <br />
                  <label for="email">Email</label>
                  <br />
                  <input
                    className={style.newinput}
                    type="email"
                    id="email"
                    placeholder="E-mail"
                    onChange={(e) =>
                      e.target.validity.valid ? setEmail(e.target.value) : <></>
                    }
                  />
                  <br />
                  <label for="password">Password</label>
                  <br />
                  <input
                    id="password"
                    className={style.newinput}
                    type={passwordInputType}
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setpassword(e.target.value)}
                  />
                  <span>{ToggleIcon}</span>
                  <br />

                  {/* <label for="password">Phone Number</label><br/> */}
                  {/* <input
                    className={style.newinput}
                    id="phone"
                    type="tel"
                    placeholder="Phone Number"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  /> */}
                  <label for="mobileNumber_phone">Mobile Number</label>
                  <br />
                  <input
                    id="mobileNumber_phone"
                    className={style.newinput}
                    type="number"
                    placeholder="Mobile Number"
                    onChange={(e) => setPhone(e.target.value)}
                  />
                  <br />

                  {/* <PhoneInput
                    defaultCountry="IN"
                    placeholder="Phone number"
                    value={phone}
                    id="phone"
                    type="tel"
                    onChange={(e) => setPhone(e)}
                    className={style.newinput}
                    // onChange={setValue}
                  /> */}
                  <label for="city">City </label>
                  <br />
                  <input
                    type="text"
                    id="city"
                    className={style.newinput}
                    placeholder="City"
                    onChange={(e) => setCity(e.target.value)}
                  />
                  <br />

                  <button
                    onClick={() => {
                      SubmitHandler();
                    }}
                    className={`btn btn-primary ${style.newbtn}`}
                  >
                    Submit
                  </button>
                  {
                    <div className="mt-3" style={{ color: "red" }}>
                      {validationAlert}
                    </div>
                  }
                  {/* <p style={{ color: "gray",width:"60%" }} className={styles.horizontalh + " my-3"}>
											<span
												style={{ color: "gray",backgroundColor:"white" }}
												className={styles.horizontal}
											>
										
												or
											</span>
										</p> */}
                  {/* <GoogleLogin
											clientId="671105516475-03k9jvsa3evmg1k4u5ge13alls8v9nfv.apps.googleusercontent.com"
											render={(renderProps) => (
												// <button onClick={renderProps.onClick} disabled={renderProps.disabled}> <p> This is my custom Google button </p> </button>

												<button
													onClick={renderProps.onClick}
													// disabled={renderProps.disabled}
													disabled={false}
													style={{width:"60%",display:"block"}}
													class={styles.googlebutton}
												>
													<span
														style={{ right: matches ? "20px" : null }}
														class={styles.googlebuttonicon}
													>
														<svg
															viewBox="0 0 366 372"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																d="M125.9 10.2c40.2-13.9 85.3-13.6 125.3 1.1 22.2 8.2 42.5 21 59.9 37.1-5.8 6.3-12.1 12.2-18.1 18.3l-34.2 34.2c-11.3-10.8-25.1-19-40.1-23.6-17.6-5.3-36.6-6.1-54.6-2.2-21 4.5-40.5 15.5-55.6 30.9-12.2 12.3-21.4 27.5-27 43.9-20.3-15.8-40.6-31.5-61-47.3 21.5-43 60.1-76.9 105.4-92.4z"
																id="Shape"
																fill="#EA4335"
															/>
															<path
																d="M20.6 102.4c20.3 15.8 40.6 31.5 61 47.3-8 23.3-8 49.2 0 72.4-20.3 15.8-40.6 31.6-60.9 47.3C1.9 232.7-3.8 189.6 4.4 149.2c3.3-16.2 8.7-32 16.2-46.8z"
																id="Shape"
																fill="#FBBC05"
															/>
															<path
																d="M361.7 151.1c5.8 32.7 4.5 66.8-4.7 98.8-8.5 29.3-24.6 56.5-47.1 77.2l-59.1-45.9c19.5-13.1 33.3-34.3 37.2-57.5H186.6c.1-24.2.1-48.4.1-72.6h175z"
																id="Shape"
																fill="#4285F4"
															/>
															<path
																d="M81.4 222.2c7.8 22.9 22.8 43.2 42.6 57.1 12.4 8.7 26.6 14.9 41.4 17.9 14.6 3 29.7 2.6 44.4.1 14.6-2.6 28.7-7.9 41-16.2l59.1 45.9c-21.3 19.7-48 33.1-76.2 39.6-31.2 7.1-64.2 7.3-95.2-1-24.6-6.5-47.7-18.2-67.6-34.1-20.9-16.6-38.3-38-50.4-62 20.3-15.7 40.6-31.5 60.9-47.3z"
																fill="#34A853"
															/>
														</svg>
													</span>
													<span
														style={{ marginRight: matches ? "10%" : null }}
														class={styles.googlebuttontext}
													>
														LogIn with Google
													</span>
												</button>
											)}
											className="pl-5 pl-5"
											buttonText="Login with google"
											onSuccess={responsehandler}
											cookiePolicy={"single_host_origin"}
										/> */}
                </div>
              </div>
            </>
          ) : null}
          {secondForm ? (
            <>
              <div
                className="text-center"
                style={{ marginTop: "60px", fontSize: "1.3rem" }}
              >
                <h1 style={{ marginTop: "60px" }}>Master Class On</h1>
                <h1> Ace Aptitude Section of Company Hiring Tests</h1>
              </div>

              <div
                className={
                  "container-fluid row my-2 py-3 " + style.verticleCenter
                }
                // style={{ overflow: "hidden" }}
              >
                <div className="col-lg-6 col-md-6 col-sm-12 mx-auto">
                  <h2
                    style={{
                      color: "blue",
                      marginBottom: "20px",
                      fontSize: "1.3rem",
                    }}
                  >
                    Professional Details
                  </h2>
                  {/* College name  (dropdown using api key)*/}
                  College Name
                  <div className="row">
                    {/* <div className="col-md-1"></div> */}
                    <div className="col-md-8 college_name">
                      <input
                        type="text"
                        className={style.newcollegeinput}
                        style={{
                          width: "100%",
                          // padding: "0.1rem"
                        }}
                        onChange={(e) => setCollegename(e.target.value)}
                      />
                    </div>
                  </div>
                  Graduation degree
                  <div className="row">
                    {/* <div className="col-md-1"></div> */}
                    <div className="col-md-8">
                      <Select
                        //  placeholder={'custom placeholder component'}
                        className="graduation_degree"
                        options={gd}
                        onChange={(e) => {
                          setDegree(e.label);
                        }}
                      />
                    </div>
                  </div>
                  <div className="container mx-auto">
                    <button
                      onClick={() => {
                        if (collegeName === "")
                          return inputFocus("college_name");
                        if (degree === "")
                          return inputFocus("graduation_degree");
                        window.scroll(0, 0);
                        setSecondForm(false);
                        setSecondForm_2(true);
                      }}
                      className={`btn btn-primary ${style.newbtn}`}
                      style={{
                        marginBottom: "160px",
                        width: "100%",
                        margin: "0 auto",
                      }}
                    >
                      Next
                    </button>
                  </div>
                </div>
                <div
                  className={"col-sm-3 col-lg-6 col-md-6 my-auto order-first"}
                  style={{
                    paddingTop: "2%",
                    overflow: "hidden",
                  }}
                >
                  {/* <img
                    src={form_sign_img2}
                    style={{}}
                    alt=""
                    className="img-fluid img-responsive"
                  /> */}
                </div>
              </div>
            </>
          ) : null}
          {secondForm_2 ? (
            <>
              <div
                className="text-center"
                style={{ marginTop: "60px", fontSize: "1.3rem" }}
              >
                <h1 style={{ marginTop: "60px" }}>Master Class On</h1>
                <h1> Ace Aptitude Section of Company Hiring Tests</h1>
              </div>

              <div
                className={
                  "container-fluid row my-2 py-3 " + style.verticleCenter
                }
              >
                <div className="col-lg-6 col-md-6 col-sm-12 mx-auto">
                  <h2
                    style={{
                      color: "blue",
                      marginBottom: "20px",
                      fontSize: "1.3rem",
                    }}
                  >
                    Professional Details
                  </h2>
                  Department
                  <div className="row">
                    <div className="col-md-8">
                      <Select
                        className="department_name"
                        options={dep}
                        onChange={(e) => handleDepChange(e)}
                      />
                    </div>
                  </div>
                  Graduation Year:
                  <div className="row">
                    {/* <div className="col-md-1"></div> */}
                    <div className="col-md-8">
                      <Select
                        // placeholder={'custom placeholder component'}
                        className="year_of_passout"
                        options={graduationyear()}
                        onChange={(e) => setYearOfPassout(e.label)}
                      />
                    </div>
                  </div>
                  <div className="container mx-auto">
                    <button
                      onClick={() => {
                        let checkState =
                          degree === "BE/BTech" && department === "";
                        if (checkState) return inputFocus("department_name");
                        if (yearOfPassout === "")
                          return inputFocus("year_of_passout");
                        cthirdForm();
                      }}
                      className={`btn btn-primary ${style.newbtn}`}
                      style={{
                        marginBottom: "160px",
                        width: "100%",
                        margin: "0 auto",
                      }}
                    >
                      Next
                    </button>
                  </div>
                </div>
                <div
                  className={"col-sm-3 col-lg-6 col-md-6 my-auto order-first"}
                  style={{
                    paddingTop: "2%",
                    overflow: "hidden",
                  }}
                ></div>
              </div>
            </>
          ) : null}
          {thirdForm ? (
            <>
              <div className="text-center" style={{ marginTop: "60px" }}>
                <h1 style={{ marginTop: "60px" }}>Master Class On</h1>
                <h1> Ace Aptitude Section of Company Hiring Tests</h1>
              </div>

              <div
                className={
                  "container-fluid row my-2 py-3 " + style.verticleCenter
                }
                style={{
                  left: "7%",
                  // overflow: "hidden"
                }}
              >
                <div
                  className={"col-sm-6 col-lg-6 col-md-6 my-auto "}
                  style={{
                    paddingLeft: "5%",
                    paddingTop: "2%",
                    overflow: "hidden",
                  }}
                >
                  {/* <img
                    src={form_sign_img2}
                    alt=""
                    className="img-fluid img-responsive"
                  /> */}
                </div>
                <div className={"col-lg-6 col-md-6 col-sm-12 mx-auto"}>
                  <h2 style={{ color: "blue", marginBottom: "20px" }}>
                    Preparation Level
                  </h2>
                  <div>
                    {/* <div className="container"> */}
                    {/* <div className="row">
      <div className="col-md-4"></div>
      <div className="col-md-4">
        <Select options={ actions } />
      </div>
      <div className="col-md-4"></div>
    </div> */}
                    {/* </div> */}
                    <div className=" mx-auto">
                      1.What is your level of preparation?
                      <div className="container px-3">
                        <div className="row">
                          {/* <div className="col-md-1"></div> */}
                          <div className="col-md-8 p-0">
                            <Select
                              // placeholder={'custom placeholder component'}
                              className="plq_1"
                              options={pl1}
                              onChange={(e) => setPreparationLevel(e.label)}
                            />
                          </div>
                          <hr />

                          {/* <div className="col-md-4"></div> */}
                        </div>
                      </div>
                      {/* 
        <input 
                  // className={style.newinput}
                  // onChange={(e) => setDegree(e.target.value)}
                  type="radio" 
                  id="css" 
                  className={style.form_radio_button}
                  name="prep" 
                  onclick="displayRadioValue()"
                  value="A"
                  // value={}
                  />
                <label for="A">A</label><br/>

                 */}
                    </div>
                    2.How can we become a helping hand in your preparation?
                    <div className="container px-3">
                      <div className="row">
                        {/* <div className="col-md-1"></div> */}
                        <div className="col-md-8 p-0">
                          <Select
                            className="plq_2"
                            placeholder={"Select multiple values"}
                            options={pl2}
                            isMulti
                            onChange={async (e) => {
                              // console.log(e);
                              let arr = [];
                              e.map((val) => {
                                arr.push(val.label);
                              });
                              await setPreperationHelp(arr);
                            }}
                          />
                        </div>
                        <hr />

                        {/* <div className="col-md-4"></div> */}
                      </div>
                    </div>
                    3.What are you primarily looking for?
                    <div className="container px-3">
                      <div className="row">
                        {/* <div className="col-md-1"></div> */}
                        <div className="col-md-8 p-0">
                          <Select
                            className="plq_3"
                            placeholder={"Select multiple values"}
                            options={pl3}
                            isMulti
                            onChange={async (e) => {
                              let arr = [];
                              e.map((val) => {
                                arr.push(val.label);
                              });
                              setPrimaryInterest(arr);
                              // console.log(primaryInterest);
                            }}
                          />
                        </div>
                        <hr />

                        {/* <div className="col-md-4"></div> */}
                      </div>
                    </div>
                  </div>
                  <div className="container mx-auto">
                    <button
                      onClick={() => {
                        if (preparationLevel === "") return inputFocus("plq_1");
                        if (preparationHelp.length === 0)
                          return inputFocus("plq_2");
                        if (primaryInterest.length === 0)
                          return inputFocus("plq_3");
                        cfourthForm();
                      }}
                      className={`btn btn-primary ${style.newbtn}`}
                      style={{
                        marginBottom: "160px",
                        width: "100%",
                        margin: "0 auto",
                      }}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </>
          ) : null}
          {fourthForm ? (
            <>
              <div className="text-center " style={{ marginTop: "60px" }}>
                <h1 style={{ marginTop: "60px" }}>Master Class On</h1>
                <h1> Ace Aptitude Section of Company Hiring Tests</h1>
              </div>

              <div
                className={
                  "container-fluid row my-3 py-5 " + style.verticleCenter
                }
                style={{
                  left: "7%",
                  //  overflow: "hidden"
                }}
              >
                <div
                  className={"col-sm-6 col-lg-6 col-md-6 my-auto "}
                  style={{
                    paddingLeft: "5%",
                    paddingTop: "2%",
                    overflow: "hidden",
                  }}
                ></div>
                <div className="col-lg-6 col-md-6 col-sm-12 mx-auto">
                  <h3 style={{ color: "blue", marginBottom: "20px" }}>
                    Career Goals{" "}
                  </h3>
                  <div>
                    1.Which is your Dream Company
                    <div className="container">
                      <div className="row">
                        {/* <div className="col-md-1"></div> */}
                        <div className="col-md-8 p-0">
                          <Select
                            //  placeholder={'custom placeholder component'}
                            className="cgq_1"
                            options={c1}
                            isMulti
                            onChange={async (e) => {
                              let arr = [];
                              e.map((val) => {
                                arr.push(val.label);
                              });
                              await setDreamCompany(arr);
                            }}
                          />
                        </div>

                        {/* <div className="col-md-4"></div> */}
                      </div>
                    </div>
                    2.Career Interest
                    <div className="container">
                      <div className="row">
                        {/* <div className="col-md-1"></div> */}
                        <div className="col-md-8 p-0">
                          <Select
                            className="cgq_2"
                            options={c2}
                            isMulti
                            onChange={async (e) => {
                              let arr = [];
                              e.map((val) => {
                                arr.push(val.label);
                              });
                              await setCareerInterest(arr);
                            }}
                          />
                        </div>

                        {/* <div className="col-md-4"></div> */}
                      </div>
                    </div>
                    3. Do you have any questions to the speaker?
                    <div className="container">
                      <div className="row">
                        {/* <div className="col-md-1"></div> */}
                        <div className="col-md-8 p-0">
                          <input
                            id="questions"
                            className={style.newinput}
                            style={{ width: "100%" }}
                            type="text"
                            placeholder="Do You Have Any Questions To The Speaker?"
                            onChange={(e) => setQuestions(e.target.value)}
                          />
                          <br />
                        </div>
                      </div>
                    </div>
                    4. On what topic do you want us to conduct next masterclass?
                    <div className="container">
                      <div className="row">
                        {/* <div className="col-md-1"></div> */}
                        <div className="col-md-8 p-0">
                          <Select
                            className="next_webinar_questions"
                            menuShouldScrollIntoView={true}
                            placeholder={"Select Multiple Options"}
                            options={next_webinar_question}
                            isMulti
                            onChange={async (e) => {
                              // console.log(e);
                              let arr = [];
                              e.map((val) => {
                                arr.push(val.label);
                              });
                              await setNextWebinar(arr);
                            }}
                          />
                        </div>
                        {/* <div className="col-md-4"></div> */}
                      </div>
                    </div>
                  </div>
                  <div className="container mx-auto">
                    <button
                      onClick={() => {
                        if (dreamCompany.length === 0)
                          return inputFocus("cgq_1");
                        if (careerInterest.length === 0)
                          return inputFocus("cgq_2");
                        SubmitQuestion();
                        ccompletion();
                      }}
                      className={`btn btn-primary ${style.newbtn}`}
                      style={{
                        marginBottom: "160px",
                        width: "100%",
                        margin: "0 auto",
                      }}
                    >
                      Done
                    </button>
                  </div>
                </div>
              </div>
            </>
          ) : null}
          :{completion ? <ThanksPage /> : null}
          {/* {thanks ? (
            <div
              className="container-fluid p-5 my-auto mx-auto my-auto"
              style={{ borderRadius: "15px" }}
            >
              <div className="container d-flex flex-column justify-content-center align-items-center">
                <img src={success_img} alt="success" />
                <h2 className="text-primary">You're Successfully Signed In.</h2>
                <p
                  style={{ color: "gray", width: "10%", color: "#111" }}
                  className={styles.horizontalh + " my-3"}
                ></p>
                <p>Let's get Started</p>
                <button
                  className={`${style.newbtn} btn btn-primary`}
                  onClick={() => {
                    csecondForm();
                    setThanks(false);
                  }}
                >
                  Next
                </button>
              </div>
            </div>
          ) : null} */}
          {/* <Footer /> */}
        </>
      )}
      {/* {window.scroll(0, 0)} */}
    </div>
  );
};

export default NewMasterClassForm;

import React, { useEffect, useState, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import styles from "../../Assets/css/quizz.module.css";
import { BASE_URL } from "../../env";
import { AuthContext } from "../../Context/AuthContext";
import "../../Assets/css/quizznew.css";
import moment from "moment";

const HybridQuizQuestions = ({ quizExams, scoreArray, setScoreArray }) => {
  const { examId } = useParams();
  const [examArray, setExamsArray] = useState({});
  const [questionsArray, setQuestionsArray] = useState([]);
  const [index, setIndex] = useState(0);
  const [email, setEmail] = useState("");
  const [userId, setUserId] = useState("");
  const [userName, setuserName] = useState("");
  const [userDP, setuserDP] = useState("");
  // const [scoreArray, setScoreArray] = useState([]);
  const [questions_Attempted, setQuestionsAttempted] = useState([]);
  const [unquestions_Attempted, setUnQuestionsAttempted] = useState([]);
  const [questions_review, setQuestionsReview] = useState([]);
  const authContextData = useContext(AuthContext);
  const [selectedId, setselectedId] = useState(null);
  const { token } = authContextData;
  const [showpopup, setshowpopup] = useState(false);
  const [show, setshow] = useState(false);
  const [MultipleChoiceArray, setMultipleChoiceArray] = useState([]);
  const history = useHistory();

  useEffect(() => {
    if (scoreArray) {
      scoreArray.map((val) => {
        setQuestionsAttempted([...questions_Attempted, val.questionId]);
      });
    }
    setExamsArray(quizExams);
    // setQuestionsArray(quizExams.questions);
    setUnQuestionsAttempted(quizExams.questions);
    var travelTime = moment().add(quizExams.timer, "minutes").unix();
    if (localStorage.getItem("timer" + examId) === null) {
      localStorage.setItem("timer" + examId, travelTime);
    }
  }, []);

  useEffect(() => {
    fetch(BASE_URL + "/getProfile", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setEmail(data?.user?.email);
        setUserId(data?.user?._id);
        setuserDP(data?.user?.profile_pic);
        setuserName(data?.user?.firstName + " " + data?.user?.lastName);
      });
  }, []);
  useEffect(() => {
    fetch(BASE_URL + "/getQuizEXamById/" + quizExams._id, {
      headers: { "content-type": "application/json" },
    })
      .then((res) => res.json())
      .then((data) => {
        //  console.log(data.data.questions);
        setQuestionsArray(shuffleArray(data.data.questions));
      });
  }, []);
  const questions = questionsArray[index];
  const length = questionsArray.length;

  function Check(questionNos, options) {
    for (var singleValue of scoreArray) {
      if (
        singleValue.questionNo == questionNos &&
        singleValue.option == options
      ) {
        return true;
      }
    }

    return false;
  }

  function shuffleArray(array) {
    for (var i = array.length - 1; i > 0; i--) {
      var j = Math.floor(Math.random() * (i + 1));
      var temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    return array;
  }

  const checkMultipleChoices = async (e, correctAnswer, questionId, option) => {
    if (questions_Attempted.includes(questionId)) {
    } else {
      setQuestionsAttempted([...questions_Attempted, questionId]);
    }
    if (MultipleChoiceArray.includes(option)) {
      setMultipleChoiceArray([
        MultipleChoiceArray.filter((val) => {
          return val != option;
        }),
      ]);
    } else {
      setMultipleChoiceArray([...MultipleChoiceArray, option]);
    }

    // console.log("MultipleChoiceArray", MultipleChoiceArray);

    if (correctAnswer.includes(option)) {
      // console.log("hello")
      const newarray = scoreArray.filter((value) => {
        return value.questionId !== questionId;
      });
      // logic for created the score object
      const newData = [
        ...newarray,
        {
          questionNo: currQues,
          score: MultipleChoiceArray.length / correctAnswer.length,
          questionId: questionId,
          correct: true,
          option,
        },
      ];
      setScoreArray(newData);
    } else {
      // console.log("ye chalra");
      const newarray = scoreArray.filter((value) => {
        return value.questionId !== questionId;
      });
      // logic for created the score object
      const newData = [
        ...newarray,
        {
          score: 0,
          questionId: questionId,
          correct: false,
          option,
          questionNo: currQues,
        },
      ];
      setScoreArray(newData);
    }
  };
  async function changeclass(e, correctAnswer, questionId, option) {
    e.preventDefault();
    setshow(!show);
    // console.log("change class")
    setselectedId(option);
    if (questions_Attempted.includes(questionId)) {
    } else {
      setQuestionsAttempted([...questions_Attempted, questionId]);
    }
    // console.log(correctAnswer)
    // console.log(option)
    correctAnswer.map((item) => {
      if (item == option) {
        // console.log("hello")
        const newarray = scoreArray.filter((value) => {
          return value.questionId !== questionId;
        });
        // logic for created the score object
        const newData = [
          ...newarray,
          {
            questionNo: currQues,
            score: 1,
            questionId: questionId,
            correct: true,
            option,
          },
        ];
        setScoreArray(newData);
      } else {
        // console.log("ye chalra");
        const newarray = scoreArray.filter((value) => {
          return value.questionId !== questionId;
        });
        // logic for created the score object
        const newData = [
          ...newarray,
          {
            score: 0,
            questionId: questionId,
            correct: false,
            option,
            questionNo: currQues,
          },
        ];
        setScoreArray(newData);
      }
    });
  }
  const addToReview = (_id) => {
    if (questions_review.includes(_id)) {
      setQuestionsReview(
        questions_review.filter((val) => {
          return val != _id;
        })
      );

      return;
    } else {
      setQuestionsReview([...questions_review, _id]);
    }
  };

  const checkRegex = (ans) => {
    var urlRegex =
      /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
    if (urlRegex.test(ans)) {
      return true;
    } else {
      return false;
    }
  };

  const submithandler = () => {
    let finalScore = 0;
    for (let i = 0; i < scoreArray.length; i++) {
      finalScore += scoreArray[i].score;
    }
    const submitData = {
      email: email,
      user: userId,
      questions_Attempted: questions_Attempted,
      examId: examId,
      score: scoreArray,
      review: questions_review,
      finalScore
    };

    fetch(BASE_URL + "/makeResult", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(submitData),
    })
      .then((res) => res.json())
      .then((response) => {
        history.push("/quizexamsubmitted");
      });
  };
  const [currQues, setcurrQues] = useState(1);
  const [ShowMenu, setShowMenu] = useState(true);
  return (
    <div id="main">
      {showpopup == true && (
        <div
          className={styles.popup_submission}
          style={{ backdropFilter: "blur(10px)" }}
        >
          <div className="d-flex flex-column">
            <span className={styles.popup_text}>
              are you sure that you want to submit your Exam?
            </span>
            <div className="d-flex flex-row justify-content-start">
              <button
                onClick={() => {
                  setshowpopup(false);
                }}
                className={styles.submission_quiz_button}
                style={{
                  background: "transparent",
                  border: "1px solid white",
                  color: "#fff",
                }}
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  submithandler();
                }}
                className={styles.submission_quiz_button}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
      <div style={{ filter: showpopup ? "blur(10px)" : null }}>
        {showpopup == true && (
          <div
            className={styles.popup_submission}
            style={{ backdropFilter: "blur(10px)" }}
          >
            <div className="d-flex flex-column">
              <span className={styles.popup_text}>
                Are you sure, you want to submit your Exam?
              </span>
              <div className="d-flex flex-row justify-content-start">
                <button
                  onClick={() => {
                    setshowpopup(false);
                  }}
                  className={styles.submission_quiz_button}
                  style={{
                    background: "transparent",
                    border: "1px solid white",
                    color: "#fff",
                  }}
                >
                  No
                </button>
                <button
                  onClick={() => {
                    submithandler();
                  }}
                  className={styles.submission_quiz_button}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        )}
        <div style={{ filter: showpopup ? "blur(10px)" : null }}>
          {token != null ? (
            <div>
              <div
                className={
                  styles.exam_color_name +
                  " py-2 d-flex justify-content-between align-items-center px-4"
                }
              >
                <h4
                  className={
                    styles.font_exam_family + " text-center text-white "
                  }
                >
                  {examArray.examName}
                </h4>
              </div>
              <div className="row">
                <div
                  className={
                    ShowMenu
                      ? " col-lg-9 col-md-9 col-sm-12 shadow-sm mx-auto rounded mt-5"
                      : " col-12 shadow-sm mx-auto rounded mt-5"
                  }
                >
                  <h5
                    className="m-2 px-3"
                    style={{ fontFamily: "Mulish", fontWeight: "800" }}
                  >
                    Question No. {currQues}
                  </h5>
                  <hr />
                  <p className={styles.quizz_question} style={{ width: "80%" }}>
                    {questions?.question}
                  </p>
                  {checkRegex(questions?.questionPic) ? (
                    <img
                      src={questions?.questionPic}
                      alt={questions?.questionPic}
                      className={styles.quizz_question}
                      style={{
                        width: "400px",
                        height: "300px",
                      }}
                    />
                  ) : null}
                  <div id="options">
                    {questions?.answers?.map((option, index) => {
                      return (
                        <>
                          {questions.answerSelectionType == "single choice" && (
                            <div
                              key={index}
                              className={
                                Check(currQues, option)
                                  ? styles.ansOption + " shadow"
                                  : styles.ansContainer + " shadow"
                              }
                              onClick={(e) => {
                                changeclass(
                                  e,
                                  questions.correctAnswer,
                                  questions._id,
                                  option
                                );
                              }}
                            >
                              <input
                                className={
                                  styles.radios + " mx-4 green-radio-quizz"
                                }
                                type="radio"
                                value={option}
                                name="options"
                                id={index.toString()}
                                checked={Check(currQues, option)}
                              />
                              <label
                                className={styles.quiz_question_form}
                                htmlFor={index.toString()}
                              >
                                {/* {String.fromCharCode("A".charCodeAt(0) + index)}.{" "} */}
                                {checkRegex(option) ? (
                                  <img
                                    src={option}
                                    alt={option}
                                    style={{
                                      maxWidth: "50%",
                                      width: "400px",
                                      height: "100px",
                                    }}
                                  />
                                ) : (
                                  option
                                )}
                              </label>
                            </div>
                          )}
                        </>
                      );
                    })}
                  </div>
                  <div className="row">
                    <div
                      style={{
                        position: "relative",
                        bottom: "5px",
                        top: "100%",
                      }}
                    >
                      <hr />
                      {index == 0 ? null : (
                        <button
                          onClick={(e) => {
                            if (index == 0) {
                            } else {
                              setIndex(index - 1);
                              setselectedId(null);
                              setcurrQues(currQues - 1);
                              // let answeropt =
                              //   e.nativeEvent.path[2].childNodes[2].childNodes;
                              // console.log("answer opt", answeropt);
                              // for (let opt of answeropt) {
                              //   opt.className = styles.container;
                              // }
                              setMultipleChoiceArray([]);
                            }
                          }}
                          className={"previous-button-quizz"}
                        >
                          Previous
                        </button>
                      )}
                      {index == length - 1 ? null : (
                        <button
                          onClick={(e) => {
                            if (index == length - 1) {
                            } else {
                              setIndex(index + 1);
                              setselectedId(null);
                              setcurrQues(currQues + 1);
                              // let answeropt =
                              //   e.nativeEvent.path[2].childNodes[2].childNodes;
                              // for (let opt of answeropt) {
                              //   opt.className = styles.container;
                              // }
                              setMultipleChoiceArray([]);
                            }
                          }}
                          className={"next-button-quizz"}
                        >
                          Next
                        </button>
                      )}
                      {questions_Attempted?.includes(questions?._id) ? (
                        <button
                          onClick={() => {
                            addToReview(questions._id);
                          }}
                          className={"review-button-quizz"}
                        >
                          {questions_review.indexOf(questions._id) != "-1"
                            ? "Mark as Done"
                            : "Review"}
                        </button>
                      ) : (
                        <button className={"review-button-quizz disabled"}>
                          Review
                        </button>
                      )}

                      <button
                        className="btn btn-primary float-end"
                        onClick={() => {
                          setshowpopup(true);
                          // submithandler();
                        }}
                      >
                        Submit Exam
                      </button>
                    </div>
                  </div>
                </div>
                {!ShowMenu && (
                  <button
                    onClick={() => setShowMenu(!ShowMenu)}
                    className={styles.ClosebtnArrow}
                  >
                    {"<"}
                  </button>
                )}
                {ShowMenu && (
                  <div
                    className={`col-lg-3 right_quizz_div col-md-3 col-sm-12 mx-auto ${styles.left_grid}`}
                  >
                    <button
                      onClick={() => setShowMenu(!ShowMenu)}
                      className={styles.btnArrow}
                    >
                      {">"}
                    </button>
                    <div className="my-2">
                      <img
                        src={userDP}
                        className={styles.userDP + " mx-2"}
                        style={{ objectFit: "contain" }}
                      />
                      {userName}
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-6 mx-auto">
                        <div className="my-2">
                          <span
                            className={styles.marked}
                            style={{
                              background: "#13b91c",
                              // borderRadius: "50%",
                              padding: "2px 8px",
                            }}
                          >
                            {unquestions_Attempted.length}
                          </span>{" "}
                          <span className="quiz-notations"> Total Ques</span>
                        </div>
                        <div className="my-2">
                          <span
                            className={styles.marked + " bg-secondary mt-5 "}
                            style={{
                              background: "#13b91c",
                              // borderRadius: "50%",
                              padding: "2px 8px",
                            }}
                          >
                            {unquestions_Attempted.length -
                              questions_Attempted.length}
                          </span>
                          <span className={" my-5 quiz-notations "}>
                            {" "}
                            Unanswered
                          </span>
                        </div>
                      </div>
                      <div className="col-6 mx-auto">
                        <div className="my-2">
                          <span
                            className=""
                            style={{
                              background: "#13b91c",
                              borderRadius: "6px",
                              padding: "2px 8px",
                            }}
                          >
                            {questions_Attempted.length}
                          </span>{" "}
                          <span className="quiz-notations"> Answered</span>
                        </div>
                        <div className="my-2">
                          <span
                            className=""
                            style={{
                              background: "rgb(233, 141, 21)",
                              borderRadius: "6px",
                              padding: "2px 8px",
                            }}
                          >
                            {questions_review.length}
                          </span>{" "}
                          <span className="quiz-notations"> Review</span>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <h5
                      className="d-inline text-center "
                      style={{ fontFamily: "Mulish", fontWeight: "800" }}
                    >
                      All Questions
                    </h5>
                    <hr />
                    <div className="d-flex flex-row flex-wrap">
                      {questionsArray.map((item, pos) => {
                        return (
                          <>
                            <div
                              style={{
                                width: "40px",
                                height: "40px",
                                border: "1px solid grey",
                                // display: "inline",
                                padding: "5px 12px",
                                margin: "2px 4px",
                                borderRadius: "5px",
                                cursor: "pointer",
                              }}
                              className={
                                questions_review.includes(item._id)
                                  ? styles.review + " text-white"
                                  : questions_Attempted.includes(item._id)
                                  ? "bg-success text-white"
                                  : questions_Attempted.includes(item._id) &&
                                    questions_review.includes(item._id)
                                  ? styles.both
                                  : "bg-secondary"
                              }
                              onClick={() => {
                                setIndex(--pos);
                                setcurrQues(++pos);
                              }}
                            >
                              {++pos}
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            history.push("/")
          )}
        </div>
      </div>
    </div>
  );
};

export default HybridQuizQuestions;

import React from "react";
import B2BCarousel2 from "../components/b2bcomponents/B2BCarousel2";
import B2Bfounders from "../components/b2bcomponents/b2bfounders";
import B2BSection_1 from "../components/b2bcomponents/b2bSection_1";
import B2BTraining from "../components/b2bcomponents/B2BTraining";
import B2BTraining_Sec from "../components/b2bcomponents/B2BTraining_Sec";
import B2BTestimonials from "../components/b2bcomponents/b2bTestimonials";
import Footer from "../components/Footer";
import B2bNavbar from "../components/b2bcomponents/b2bNavbar";
import B2BTest from "../components/b2bcomponents/B2BTest";
import BookSessions from "../components/b2bcomponents/bookSessions";
import ClientCarousel from "../components/b2bcomponents/ClientCarousel";
import CollegeCollabComp from "../components/CollegeCollabComp";
import B2BMentorsNew from "../components/b2bcomponents/dist/B2BMentorsNew";
// import ourpartners from "../Assets/img/ourpartners.png";
import Navbar from "../components/Navbar";
import "../components/b2bcomponents/dist/B2B_newMentors.scss"
import B2BSectionPartNew from "../components/b2bcomponents/dist/B2BSectionPartNew";
import HelmetComp from "../components/HelmetComp";
import B2BNewMentors from "../components/b2bcomponents/dist/B2BNewMentors";
const b2bHome = () => {
  const mentors = [
    {
      linkdin:"https://www.linkedin.com/in/manishkankani/",
      mentorImg:"https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1679397069561_manish.jpeg",
      name: "Manish Kankani",
      description: "Google Tech Lead",
      role: "IIT Roorkee",
      companyImg: "https://cantileverlabs-gradient-images.s3.ap-south-1.amazonaws.com/company+logos/IMG-20220923-WA0007.jpg",
    },
    {
      linkdin:"https://www.linkedin.com/in/vivek-jhaver-8853a261/",
      mentorImg:"https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1679396258661_vivek.jpeg",
      name: "Vivek Jhaver",
      description: "SDE-2, Amazon",
      role: "VNIT Nagpur",
      companyImg: "https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1679404461526_Amazon_logo.svg.png",
    },
    {
      linkdin:"https://www.linkedin.com/in/sureshsan/",
      mentorImg:"https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1679396313039_suresh.jpeg",
      name: "Suresh Santhanam",
      description: "Software Eng.Microsoft",
      role: "IIIT Hyderabad",
      companyImg: "https://cantileverlabs-gradient-images.s3.ap-south-1.amazonaws.com/company+logos/IMG-20220923-WA0003.jpg",
    },
    {
      linkdin:"https://www.linkedin.com/in/vivek-jhunjhunwala/",
      mentorImg:"https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1679396370162_vivek1.jpeg",
      name: "Vivek",
      description: "Partner At Quadeye",
      role: "IIT Bombay",
      companyImg: "https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1679395536858_logos.jpg",
    },
    {
      linkdin:"https://www.linkedin.com/in/sanjay-nair-/",
      mentorImg:"https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1679396399927_sanjay.jpeg",
      name: "Sanjay Nair",
      description: "Associate McKinsey",
      role: "ISB Hyderabad",
      companyImg: "https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1679395848011_McKinsey-Logo.png",
    },
    {
      linkdin:"https://www.linkedin.com/in/amitvaid/",
      mentorImg:"https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1679396431218_amit.jpeg",
      name: "Amit Vaid",
      description: "voice President State Street",
      role: "VTU Bangalore",
      companyImg: "https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1679395702709_purepng.com-state-street-logologobrand-logoiconslogos-251519938342d6s4u.png",
    },
    {
      linkdin:"https://www.linkedin.com/in/avanish-mundhra-6a938848/?originalSubdomain=in",
      mentorImg:"https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1679396461896_avanish.jpeg",
      name: "Avanish Mundra",
      description: "CEO - Cantilever Labs",
      role: "VTU",
      companyImg: "https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1679394595457_1644088580619_cantileverlogoblack.png",
    },
    {
      linkdin:"https://www.linkedin.com/in/drishti-mamtani-572338159/",
      mentorImg:"https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1679396484799_drishti.jpeg",
      name: "Drishti Mamtani",
      description: "Software Engineer Google",
      role: "BITS'21",
      companyImg: "https://cantileverlabs-gradient-images.s3.ap-south-1.amazonaws.com/company+logos/IMG-20220923-WA0007.jpg",
    },
    {
      linkdin:"https://www.linkedin.com/in/farheen-bano/",
      mentorImg:"https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1679396502605_fareen.jpeg",
      name: "Fareen Bano",
      description: "SDE-2 PayPal",
      role: "IIIT Bombay",
      companyImg: "https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1679395406081_Paypal-logo.png",
    },
  ];
  return (
    <>
      {/* <B2bNavbar />
       */}
      {/* <Navbar /> */}
      <HelmetComp
        info={{
          title: "For Colleges",
          // description: '',
          // siteName: '',
          // summary: '',
          // pageUrl: '',
          // imagUrl: ''
        }}
      />
      <B2BTest />
      <B2BTraining_Sec />
      <div className='py-2' style={{ backgroundColor: "#f6f6ee" }}>
        <h1 className=' my-5 text-center' style={{ marginLeft: "5%" }}>
          <strong className='my-3'>Our College Collaborations</strong>
        </h1>
        <CollegeCollabComp />
      </div>
      <div className='py-5'>
        <h1
          className='text-dark text-center'
          style={{
            fontSize: "37px",
            fontFamily: "Poppins-Bold",

            marginBottom: "0px",
          }}>
          Our Mentors
        </h1>
        <br />
        {/* <B2BCarousel2 /> */}
        <div
          className='row mewMentor'>
          {/* <B2BMentorsNew /> */}
          {mentors.map((val, i) => {
            return (
              <>
                <B2BNewMentors
                mentorLinkdin={val.linkdin}
                mentorsLogo={val.mentorImg}
                  mentorName={val.name}
                  mentorDesc={val.description}
                  mentorCompany={val.role}
                  companyImage={val.companyImg}
                />
              </>
            );
          })}
        </div>
      </div>
      {/* <B2BSection_1 /> */}
      <B2BSectionPartNew />
      <ClientCarousel />
      <B2BTestimonials />
      <B2BTraining />
      {/* <B2Bfounders /> */}
      <BookSessions />
      <Footer />
    </>
  );
};

export default b2bHome;

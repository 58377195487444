import { useState } from "react";
import "../Assets/css/newherosection.css";
import Typed from "typed.js";
import { useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import useMedia from "../componentsofcourse/useMedia";
import ReactGA from "react-ga";

const NewHeroSection = () => {
  const location = useLocation();
  const [login, setlogin] = useState(false);
  const el = useRef(null);
  ReactGA.initialize("UA-198180460-1");
  const matches = useMedia("(min-width : 728px )");
  useEffect(() => {
    function CheckLoginOrNot() {
      if (localStorage.getItem("token") !== null) {
        // alert(localStorage.getItem("token"))
        return setlogin(true);
      } else {
        // return false;
        // alert(false);
        return setlogin(false);
      }
    }
    CheckLoginOrNot();
  }, []);

  useEffect(() => {
    const typed = new Typed(el.current, {
      strings: ["for Future", "Yourself", "", ""], // Strings to display
      // Speed settings, try diffrent values untill you get good results
      startDelay: 0,
      typeSpeed: 100,
      backSpeed: 100,
      backDelay: 100,
      smartBackspace: true,
      loop: true,
      showCursor: true,
      autoInsertCss: true,
      cursorChar: "|",
    });

    // Destropying
    return () => {
      typed.destroy();
    };
  }, []);
  return (
    <div
      className={
        matches
          ? "d-flex bg-123 clip-path-hero justify-content-around "
          : " clip-path-hero    bg-123 "
      }
    >
      <div className="row mx-5 ">
        <div
          className={
            matches
              ? "col-lg-7 col-md-7 col-sm-12 text-light d-flex align-items-center  "
              : " mt-5 col-lg-7 col-md-7 col-sm-12 text-light d-flex align-items-center  "
          }
        >
          <div className=" ">
            <span
              className="NEW-upskill"
              style={{ display: !matches ? "block" : null }}
            >
              {" "}
              UPSKILL{" "}
            </span>{" "}
            <span className="upskill" ref={el}></span>
            <span className="dreamjob">Grab your Dream Job</span>
            {matches ? (
              <span className="tagline ">
                Be Different & Make the Difference, Start your Journey Today
              </span>
            ) : (
              <span className="tagline">Start your Journey </span>
            )}
            <Link
              onClick={() => {
                ReactGA.event({
                  category: "HomePage",
                  type: "Button",
                  name: "Get_Started_Home_page",
                  action: "The user has clicked the Get started button",
                });
                
				        localStorage.setItem('userlocation', location.pathname);
              }}
              className={!matches && "d-flex justify-content-center"}
              to={login ? "/profile" : "/register"}
            >
              <button className="register-hero">GET STARTED</button>
            </Link>
          </div>
        </div>
        <div className="col-lg-5 col-md-5 col-sm-12 transform">
          <lottie-player
            src="https://assets4.lottiefiles.com/packages/lf20_itzqtq0r.json"
            autoplay
            background="transparent"
            speed="1"
            style={{
              width: matches ? "500px" : "300px",
              height: matches ? "500px" : "300px",
            }}
            loop
          ></lottie-player>
        </div>
      </div>
    </div>
  );
};

export default NewHeroSection;

//robot animation
//https://assets4.lottiefiles.com/packages/lf20_itzqtq0r.json
//laptop waala banda
//https://assets3.lottiefiles.com/packages/lf20_xtajsmrq.json

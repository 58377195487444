import React from "react";
import "../../Assets/css/compiler.css";
import AceEditor from "react-ace";
import "../../Assets/css/exam.css";
import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/mode-c_cpp";
import "ace-builds/src-noconflict/mode-csp";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/theme-merbivore_soft";
import "ace-builds/src-noconflict/theme-solarized_dark";
import "ace-builds/src-noconflict/theme-solarized_light";
import "ace-builds/src-noconflict/ext-language_tools";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
// import "ace-builds/src-noconflict/ext-language_tools"
import { useEffect, useState } from "react";
import { Resizable } from "re-resizable";
import { BASE_URL } from "../../env";
import cantilevertransparent from "../../Assets/img/cantilevertransparent.png";
import cantileverLight from "../../Assets/img/cantilever-logo.png";
import TestCases from "../../componentsofcourse/TestCases";
import { motion } from "framer-motion";

const HybridCodingCompiler = (props) => {
  const {
    solvedIndices,
    setsolvedIndices,
    id,
    code,
    setcode,
    selectLanguage,
    setSelectedLanguage,
    Languagevalues,
    examid,
    scoringArray,
    setscoringArray,
    questionsolved,
    setquestionsolved,
  } = props;

  const [newid, setnewid] = useState("");

  const [results, setresults] = useState([]);
  const [modes, setModes] = useState(true);
  const [resize, setResize] = useState(true);

  const [output, setOutput] = useState("");
  const [testcaseON, settestcaseON] = useState(false);
  const [rawOutput, setrawOutput] = useState(true);
  const [running, setrunning] = useState(true);
  const [rawoutputsol, setrawoutputsol] = useState([]);

  function onChange(newValue) {
    setcode(newValue);
  }
  const r = document.querySelector(":root");
  function myFunction_dark() {
    r.style.setProperty("--inside-color", "#F6F9FC");
    r.style.setProperty("--input-color-code", "#fff");
    r.style.setProperty("--border-color-code", "yellow");
    r.style.setProperty("--outer-background-code", "#E0E0E0");
    r.style.setProperty("--selected-option-code", "#E0E0E0");
    r.style.setProperty("--text-color", "#001528");
  }
  function myFunction_light() {
    r.style.setProperty("--inside-color", "#001528");
    r.style.setProperty("--input-color-code", "#15314b");
    r.style.setProperty("--border-color-code", "#02203c");
    r.style.setProperty("--outer-background-code", "#02203c");
    r.style.setProperty("--selected-option-code", "#e0e0e0");
    r.style.setProperty("--text-color", "#fff");
  }
  const [questionName, setquestionName] = useState();
  const [description, setDescription] = useState({
    __html: "First &middot; Second",
  });
  const [showrawoutputtestcases, setshowrawoutputtestcases] = useState(false);

  const [sampleInput, setSamepleInput] = useState();
  const [sampleOutput, setSampleOutput] = useState();
  const [SampleTestCases, setSampleTestCases] = useState([]);
  const [questionWeight, setquestionWeight] = useState();
  const [weightTimes, setweightTimes] = useState([]);
  const [questionCode, setquestionCode] = useState([]);
  // const [selectLanguage, setSelectedLanguage] = useState(Languagevalues[3]);
  const fontSizes = ["12px", "14px", "16px", "18px", "20px", "22px"];
  const [fontSize, setFontSize] = useState("16px");
  const [showlanguagedropdown, setshowlanguagedropdown] = useState(false);
  const [showfontsizedropdown, setfontsizedropdown] = useState(false);

  const [TestcaseCode, setTestcasecode] = useState("");
  const [bufferedoutput, setbufferedoutput] = useState({});
  // const [outputCode, setOutputCode] = useState();
  const [preCode, setpreCode] = useState();

  useEffect(() => {
    setnewid(id);
  }, [id]);

  // useEffect(() => {
  // effect
  // if (localStorage.getItem(id) !== undefined) {
  //     setcode(localStorage.getItem(id))
  // }
  // return () => {
  //     // cleanup
  //     // alert("hello world")
  //     // alert(code)
  //     localStorage.setItem(id,code)
  //     //unmount
  //     //
  // }
  // }, [scoringArray]);

  useEffect(() => {
    fetch(BASE_URL + "/GetOneQuestion/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setquestionName(data.questions.name);
        setDescription({ __html: data.questions.description });
        setSamepleInput(data.questions.sampleInput);
        setSampleOutput(data.questions.sampleOutput);
        setSampleTestCases(data.questions.testcases);
        setquestionWeight(data.questions.questionWeigh);
        setquestionCode(data.questions.questionCode);
        let array = [];
        for (var i = 0; i < data.questions.questionWeigh; i++) {
          array.push(i);
        }
        setweightTimes(array);
      });
  }, [newid]);
  useEffect(
    () => {
      fetch(BASE_URL + "/GetOneQuestion/" + id, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setquestionName(data.questions.name);
          setDescription({ __html: data.questions.description });
          setSamepleInput(data.questions.sampleInput);
          setSampleOutput(data.questions.sampleOutput);
          setSampleTestCases(data.questions.testcases);
          setquestionWeight(data.questions.questionWeigh);
          setquestionCode(data.questions.questionCode);
          let array = [];
          for (var i = 0; i < data.questions.questionWeigh; i++) {
            array.push(i);
          }
          setweightTimes(array);
        });
    },
    [],
    [id],
    [selectLanguage]
  );

  const [selectedLanguagetestcase, setselectedlanguagetestcase] = useState("");
  useEffect(() => {
    const array = SampleTestCases?.filter((value) => {
      if (value[0].language === selectLanguage.toLowerCase()) {
        return value;
      }
    });
    if (array?.length !== 0) {
      setselectedlanguagetestcase(array[0][1]);
    }
  }, [SampleTestCases, [selectLanguage]]);

  useEffect(() => {
    setrawoutputsol([...rawoutputsol, bufferedoutput]);
  }, [bufferedoutput]);
  function RestartTheCode() {
    let questionsCodes = questionCode.filter(
      (values) => values.language === selectLanguage.toLowerCase()
    );
    setcode(questionsCodes[0]?.code);
  }
  useEffect(() => {
    setTimeout(() => {
      let questionsCodes = questionCode.filter((values) => {
        return values.language === selectLanguage.toLowerCase();
      });
      if (localStorage.getItem(id + selectLanguage) === null) {
        setcode(questionsCodes[0]?.code);
      } else {
        setcode(localStorage.getItem(id + selectLanguage));
      }
    }, 1000);
  }, [questionCode, selectLanguage]);

  function ResetCode() {
    setTimeout(() => {
      let questionsCodes = questionCode.filter((values) => {
        return values.language === selectLanguage.toLowerCase();
      }, 1000);
      if (localStorage.getItem(id + selectLanguage) === null) {
        setcode(questionsCodes[0]?.code);
      } else {
        setcode(localStorage.getItem(id + selectLanguage));
      }
    }, 1000);
  }

  const themeMode = () => {
    setModes(!modes);
    if (modes === true) {
      myFunction_dark();
    } else {
      myFunction_light();
    }
  };
  function ChangeStatesofOutput(function1, function2, value1, value2) {
    function1(value1);
    function2(value2);
  }

  const style = {
    borderRadius: "15px",
  };
  function DropDownStyle({ value, selectLanguage, type }) {
    return (
      <div
        onClick={() => {
          if (type === "language") {
            setSelectedLanguage(value);
            setshowlanguagedropdown(false);
          } else {
            setFontSize(value);
            setfontsizedropdown(false);
          }
        }}
        className={
          selectLanguage !== value
            ? "options-value-dropdown"
            : "options-value-dropdown selected-language-active"
        }
      >
        <span className={"single_option_dropdown"}>{value}</span>
      </div>
    );
  }

  async function execute_Code(testcasecode, expected_output, index) {
    const lang =
      selectLanguage.toLowerCase() === "c++"
        ? "cpp"
        : selectLanguage.toLowerCase();
    setrunning(false);
    ChangeStatesofOutput(setrawOutput, settestcaseON, true, false);
    var data = JSON.stringify({
      data: {
        script: testcasecode,
        stdin: "",
        language: lang,
        versionIndex: "0",
      },
    });
    await fetch(BASE_URL + "/jdoodle", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: data,
    })
      .then((res) => res.json())
      .then((data) => {
        setbufferedoutput({
          testcaseNo: index++,
          output: data.output,
        });
        setOutput(data.output);
        CheckTestCase(data.output, expected_output, index);
        setrunning(true);
      })
      .catch((err) => {
        // console.log(err);
      });
  }
  const [runningTestCases, setrunningTestCases] = useState(false);
  async function execute_Code_run() {
    const lang =
      selectLanguage.toLowerCase() === "c++"
        ? "cpp"
        : selectLanguage.toLowerCase();
    const answerArray = [];
    setrunning(false);
    ChangeStatesofOutput(setrawOutput, settestcaseON, true, false);
    var data = JSON.stringify({
      data: {
        script: code,
        stdin: "",
        language: lang,
        versionIndex: "0",
      },
    });
    await fetch(BASE_URL + "/jdoodle", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: data,
    })
      .then((res) => res.json())
      .then((data) => {
        setOutput(data.output);
        // CheckTestCase(data.output,expected_output,index)
        setrunning(true);
      })
      .catch((err) => {
        // console.log(err);
      });
  }

  let newArray = [];
  function CheckTestCase(output, expected_output, index) {
    let testcasetrue = false;
    let foo = output.replace(/\r?\n|\r/g, "");

    let doo = String(expected_output).replace(/\r?\n|\r/g, "");

    let newstring = "";
    foo = foo.replace(/ /g, "");

    for (var i = 0; i < foo.length; i++) {
      if (foo[i] == undefined) {
        continue;
      }
      if (foo[i] == " ") {
        continue;
      } else {
        if (foo[i] == doo[i]) {
          testcasetrue = true;
        }
        newstring += foo[i];
      }
    }
    if (foo == doo) {
      newArray.push(index - 1);
    } else {
      let newstring1 = "";
      doo = doo.replace(/ /g, "");

      for (var i = 0; i < doo.length; i++) {
        if (doo[i] == undefined) {
          continue;
        }
        if (doo[i] == " ") {
          continue;
        } else {
          if (doo[i] == doo[i]) {
            testcasetrue = true;
          }
          newstring1 += doo[i];
        }
      }
      if (newstring1 == foo) {
        newArray.push(index - 1);
      }
    }

    setsolvedIndices(newArray);
    ChangeCompletedArrays(newArray);
  }

  function ChangeCompletedArrays(Array) {
    setsolvedIndices(newArray);
  }
  function RunningAllTestcases() {
    setrawoutputsol([]);
    setrunningTestCases(true);
    newArray = [];
    for (var i = 0; i < selectedLanguagetestcase.length; i++) {
      const testcasecode_new = code + selectedLanguagetestcase[i].code;

      execute_Code(testcasecode_new, selectedLanguagetestcase[i].output, i);
    }

    setshowrawoutputtestcases(true);
    setTimeout(() => {
      ChangeStatesofOutput(setrawOutput, settestcaseON, false, true);
      setrunningTestCases(false);
    }, 2000);
    // setrunningTestCases(false)
  }
  const [layout, setlayout] = useState(true);
  function changeLayout() {
    setlayout(!layout);
    setResize(!resize);
  }
  const [showPopUp, setshowPopUp] = useState(false);
  return (
    <div className="maincontainer">
      <div
        style={{ paddingLeft: "0px", filter: showPopUp ? "blur(2px)" : "" }}
        className={layout == true ? "compiler" : "compiler_two"}
      >
        <div className="options">
          <img
            src={modes ? cantilevertransparent : cantileverLight}
            width="auto"
            height="42px"
            alt="logo"
            className="pt-1"
          />
          <div className="mid d-flex align-items-center">
            <span className="new_font ">Language</span>
            <div class="select-language-code">
              <p
                className="selected-language-code "
                onClick={() => setshowlanguagedropdown(!showlanguagedropdown)}
                style={{ marginBottom: "0px", width: "135px" }}
              >
                {selectLanguage}
                <i
                  style={{
                    transform:
                      showlanguagedropdown == true
                        ? "rotate(180deg)"
                        : "rotate(0deg)",
                  }}
                  class="fas fa-caret-down animation"
                ></i>
              </p>
              {showlanguagedropdown == true && (
                <div className="dropdown-value">
                  {Languagevalues.map((value) => (
                    <DropDownStyle
                      value={value}
                      selectLanguage={selectLanguage}
                      type={"language"}
                    />
                  ))}
                </div>
              )}
            </div>

            <span className="new_font" style={{ marginLeft: "18px" }}>
              Font Size
            </span>
            <div class="select-language-code">
              <p
                className="selected-language-code "
                onClick={() => setfontsizedropdown(!showfontsizedropdown)}
                style={{ marginBottom: "0px" }}
              >
                {fontSize}{" "}
                <i
                  style={{
                    transform:
                      showfontsizedropdown == true
                        ? "rotate(180deg)"
                        : "rotate(0deg)",
                  }}
                  class="fas fa-caret-down animation"
                ></i>
              </p>
              {showfontsizedropdown == true && (
                <div className="dropdown-value">
                  {fontSizes.map((value) => (
                    <DropDownStyle
                      value={value}
                      selectLanguage={fontSize}
                      type={"font"}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="select-language-code px-4 mx-2" onClick={themeMode}>
            {modes ? <i class="fas fa-sun"></i> : <i class="fas fa-moon"></i>}
          </div>
        </div>

        {layout == true && (
          <Resizable
            className="question"
            handleClasses="handle"
            style={style}
            handleWrapperClass="handle"
            // handleComponent={<ResizableComponent/>}
            enable={{
              top: false,
              right: true,
              bottom: false,
              left: false,
              topRight: false,
              bottomRight: false,
              bottomLeft: false,
              topLeft: false,
            }}
          >
            <div>
              <h4 className="question_heading">
                {questionName}
                {weightTimes.map((index) => {
                  return (
                    <>
                      {" "}
                      <div className="stars">
                        <i
                          class="fas fa-star newvalue"
                          key={index}
                          style={{ color: "yellow" }}
                        ></i>
                      </div>{" "}
                    </>
                  );
                })}
              </h4>
              <p
                className="question_description"
                dangerouslySetInnerHTML={description}
              ></p>
              <div className="sample-input-question">
                <h4>Sample Input</h4>
                <div className="input-area-question">{sampleInput}</div>
                <h4>Sample Output</h4>
                <div className="input-area-question">{sampleOutput}</div>
              </div>
            </div>
          </Resizable>
        )}

        {/* this is the code */}
        <div className={"code"}>
          <div className="run-panel d-flex justify-content-between align-items-stretch">
            <p className="your-solution-code">Your solution</p>

            <div className="d-flex align-items-center">
              <div
                style={{
                  transform: "scale(1.2)",
                  marginRight: "8px",
                  cursor: "pointer",
                  color: modes ? "#fff" : "#001528",
                }}
                onClick={() => {
                  RestartTheCode();
                }}
              >
                <RotateLeftIcon />
              </div>
              {resize ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 469.333 469.333"
                  fill="currentColor"
                  class="svg-btn"
                  onClick={() => {
                    changeLayout();
                  }}
                >
                  <path d="M160 0H10.667A10.66 10.66 0 000 10.667V160a10.66 10.66 0 0010.667 10.667H32A10.66 10.66 0 0042.667 160V42.667H160A10.66 10.66 0 00170.667 32V10.667A10.66 10.66 0 00160 0zM458.667 0H309.333a10.66 10.66 0 00-10.667 10.667V32a10.66 10.66 0 0010.667 10.667h117.333V160a10.66 10.66 0 0010.667 10.667h21.333A10.66 10.66 0 00469.333 160V10.667A10.66 10.66 0 00458.667 0zM458.667 298.667h-21.333a10.66 10.66 0 00-10.667 10.667v117.333H309.333a10.66 10.66 0 00-10.667 10.667v21.333a10.66 10.66 0 0010.667 10.667h149.333a10.66 10.66 0 0010.667-10.667V309.333a10.66 10.66 0 00-10.666-10.666zM160 426.667H42.667V309.333A10.66 10.66 0 0032 298.666H10.667A10.662 10.662 0 000 309.333v149.333a10.66 10.66 0 0010.667 10.667H160a10.66 10.66 0 0010.667-10.667v-21.333A10.66 10.66 0 00160 426.667z"></path>
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 469.333 469.333"
                  fill="currentColor"
                  class="svg-btn"
                  onClick={() => {
                    changeLayout();
                  }}
                >
                  <path d="M160 0h-21.333A10.66 10.66 0 00128 10.667V128H10.667A10.66 10.66 0 000 138.667V160a10.66 10.66 0 0010.667 10.667H160A10.66 10.66 0 00170.667 160V10.667A10.66 10.66 0 00160 0zM458.667 128H341.333V10.667A10.66 10.66 0 00330.667 0h-21.333a10.66 10.66 0 00-10.667 10.667V160a10.66 10.66 0 0010.667 10.667h149.333A10.66 10.66 0 00469.334 160v-21.333A10.662 10.662 0 00458.667 128zM458.667 298.667H309.333a10.66 10.66 0 00-10.667 10.667v149.333a10.66 10.66 0 0010.667 10.667h21.333a10.66 10.66 0 0010.667-10.667V341.333h117.333a10.66 10.66 0 0010.667-10.667v-21.333a10.66 10.66 0 00-10.666-10.666zM160 298.667H10.667A10.66 10.66 0 000 309.333v21.333a10.66 10.66 0 0010.667 10.667H128v117.333a10.66 10.66 0 0010.667 10.667H160a10.66 10.66 0 0010.667-10.667V309.333A10.66 10.66 0 00160 298.667z"></path>
                </svg>
              )}

              <div onClick={RunningAllTestcases} className="run-btn">
                {running ? (
                  "Run Code"
                ) : (
                  <>
                    <div className="text-center">
                      <div
                        class="spinner-border d-flex justify-content-center text-warning spinner-border-sm"
                        role="status"
                      >
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <AceEditor
            mode={selectLanguage.toLowerCase()}
            theme={modes ? "solarized_dark" : "solarized_light"}
            value={code}
            className="codes"
            onChange={onChange}
            name="UNIQUE_ID_OF_DIV"
            width={"100%"}
            style={{ overflowX: "auto" }}
            fontSize={fontSize}
            // height={"50%"}
            setOptions={{
              enableBasicAutocompletion: true,
              enableLiveAutocompletion: true,
              enableSnippets: true,
            }}
          />
        </div>
        {/* this is the output area */}
        <div
          className="output_area p-0"
          style={{ width: "100%", height: "100%" }}
        >
          <div className="d-flex flex-row submit-code-compiler justify-content-between">
            <div className="d-flex align-items-stretch">
              <button
                className={
                  rawOutput === true
                    ? "selected-type-test borderchange"
                    : "unselect-type-test borderchange"
                }
                onClick={() => {
                  ChangeStatesofOutput(
                    setrawOutput,
                    settestcaseON,
                    true,
                    false
                  );
                }}
              >
                Raw Output
              </button>
              <button
                className={
                  testcaseON === true
                    ? "selected-type-test"
                    : "unselect-type-test"
                }
                onClick={() => {
                  ChangeStatesofOutput(
                    setrawOutput,
                    settestcaseON,
                    false,
                    true
                  );
                }}
              >
                Test Cases
              </button>
            </div>
            <div>
              <button
                className="submit-code-test"
                onClick={RunningAllTestcases}
              >
                {runningTestCases == false ? (
                  "Submit Code"
                ) : (
                  <>
                    <div className="text-center">
                      <div
                        class="spinner-border d-flex justify-content-center text-warning spinner-border-sm"
                        role="status"
                      >
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </>
                )}
              </button>
            </div>
          </div>
          <div className="p-2">
            <h2 className="output-heading">Output:</h2>
            {rawOutput === true && (
              <p>
                {showrawoutputtestcases && (
                  <>
                    {rawoutputsol.map((value, index) => {
                      return (
                        <>
                          <div className="" style={{ whiteSpace: "pre-line" }}>
                            <br />
                            <code>
                              -------Test Case {index + 1}-----------
                              <br />
                              <code style={{ whiteSpace: "pre-line" }}>
                                {" "}
                                {value.output}{" "}
                              </code>
                              <br />
                              -----------------------------
                            </code>
                          </div>
                        </>
                      );
                    })}
                  </>
                )}
              </p>
            )}

            {testcaseON === true && (
              <div>
                <TestCases
                  examcode={code}
                  questionsolved={questionsolved}
                  setquestionsolved={setquestionsolved}
                  scoringArray={scoringArray}
                  setscoringArray={setscoringArray}
                  examid={examid}
                  videoid={id}
                  results={results}
                  type="exam"
                  // courseid={courseid}
                  selectedLanguagetestcase={selectedLanguagetestcase}
                  solvedIndices={solvedIndices}
                  RunningAllTestcases={RunningAllTestcases}
                  selectLanguage={selectLanguage}
                  output={output}
                  testcases={SampleTestCases}
                />
              </div>
            )}
            <div className="output-jdoodle" style={{ opacity: "0" }}>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit.
              Veritatis consectetur libero sunt at error minima repellendus
              rerum impedit quia necessitatibus sint, quae eius? Id ipsum
              praesentium minima reprehenderit, minus obcaecati. Laborum nobis
              sunt minima commodi, vitae dolore. Sed asperiores corrupti
              voluptate quisquam odio officia non nisi, in omnis blanditiis, est
              magni facilis. Rem illo in, laborum deleniti magnam debitis
              voluptatem.
            </div>
          </div>
        </div>
        {/* // </Resizable> */}
      </div>
    </div>
  );
};

export default HybridCodingCompiler;

import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { BASE_URL } from "../env";
import { useState, useEffect } from "react";
import optionCities from "../cities";
import axios from "axios";
import PopupAd from "../Pages/PopupAd";
import displayRazorpay from "../MentorFormRazorpay";

import {
	BrowserRouter as Router,
	Route,
	Switch,
	useLocation,
	Redirect,
	useParams,
} from "react-router-dom";
import useMedia from "../componentsofcourse/useMedia";
// import useMedia from "../componentsofcourse/useMedia";
import "react-phone-number-input/style.css";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import { isValidPhoneNumber } from "react-phone-number-input";
import ReactPixel from "react-facebook-pixel";
// import useMedia from "../componentsofcourse/useMedia";
import AsyncSelect from "react-select/async";
import "../Assets/css/newstepform.css";
import { useHistory } from "react-router-dom";
export default function MentorForm(props) {
	const matches = useMedia("(min-width : 786px )");
	let history = useHistory();
	let referral_ = "";
	const [othersResume, setOthersResume] = useState(false);
	// const [plan, setPlan] = useState("");
	let loc = useLocation();
	const plan = loc.state.plan;
	// console.log(plan);
	if (!matches) {
		referral_ = loc.pathname.split("/masterclass/")[1];
		if (referral_ === undefined) {
			referral_ = loc.pathname.split("/masterclassform/")[1];
		}
		// console.log("rr", referral_);
	} else {
		referral_ = loc.pathname.split("/masterclassform/")[1];

		if (referral_ === undefined) {
			referral_ = loc.pathname.split("/masterclass/")[1];
		}
		// console.log("rr", referral_);
	}
	let arr = loc.search.split("=");
	let referral = arr[1];
	const webinarID = loc.pathname.split("/masterclassform/")[1];
	const [desc, setDesc] = useState(null);

	const {
		register,
		handleSubmit,
		watch,
		control,
		formState: { errors, isValid },
	} = useForm({ mode: "all" });
	const [collegeName, setCollegeName] = useState("");
	const [collegeData, setCollegeData] = useState([]);

	const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
	const options = {
		autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
		debug: false, // enable logs
	};

	useEffect(() => {
		ReactPixel.init("337209321107283", advancedMatching, options);
		ReactPixel.trackCustom("iitform");
	}, []);

	const [showInterested, setshowInterested] = useState(true);
	function CloseTheButton() {
		setshowInterested(false);
	}
	function PushTheUser() {
		setshowInterested(false);
	}

	function InterestedUser() {
		fetch(BASE_URL + "/userInterested", {
			method: "PATCH",
			headers: {
				"Content-Type": "application/json",
				authorization: "Bearer " + localStorage.getItem("token"),
			},
			body: JSON.stringify({
				interestedCourse: true,
			}),
		})
			.then((response) => {})
			.catch((err) => {
				// console.log(err);
			});
		PushTheUser();
	}

	const retCollegeData = (inputvalue) => {
		fetch(BASE_URL + `/getCollegeData?name=${inputvalue}`, {
			headers: { "Content-Type": "application/json" },
		})
			.then((res) => res.json())
			.then((data) => {
				var dataArray = [];
				// console.log(data);
				for (let i = 0; i < data.length; i++) {
					dataArray.push({
						label:
							data[i].CollegeName +
							", " +
							data[i].District +
							", " +
							data[i].State,
						value:
							data[i].CollegeName +
							", " +
							data[i].District +
							", " +
							data[i].State,
					});
				}
				setCollegeData(dataArray);
			});
		return collegeData;
	};
	const loadOptions = (inputValue, callback) => {
		callback(retCollegeData(inputValue));
	};

	function changeStates(onefun, onefunValue, secondFun, secondfunvalue) {
		onefun(onefunValue);
		secondFun(secondfunvalue);
	}

	const [firstForm, setFirstForm] = useState(true);
	const [secondForm, setSecondForm] = useState(false);
	const [thirdForm, setThirdForm] = useState(false);
	const [fourthForm, setFourthFrom] = useState(false);
	const [thanks, setthanks] = useState(false);
	const [isOthers, setIsOthers] = useState(false);

	const customStyles = {
		option: (provided) => ({
			...provided,
			textAlign: "left",
			fontSize: !matches && "0.7rem",
		}),
		container: (provided) => ({
			...provided,
			border: "none",
			padding: "1px",
		}),
		placeholder: (provided) => ({
			...provided,
			fontSize: !matches && "0.7rem",
		}),
	};

	useEffect(() => {
		// console.log(collegeName);
	}, [collegeName]);
	useEffect(() => {
		const collegeData = watch("CollegeName");

		setCollegeName(collegeData);
	});

	const onSubmit = async () => {
		const data = watch();
		data.YearofGrad = data.YearofGrad.value.toString();
		data.CollegeName = data.CollegeName.value;
		let newData = { ...data };

		// console.log(newData)
		// console.log(JSON.stringify(newData));
		// await fetch(BASE_URL + "/iitbfform", {
		//   method: "POST",
		//   headers: { "Content-Type": "application/json" },
		//   body: JSON.stringify(newData),
		// })
		//   .then((res) => res.json())
		//   .then((data) => {
		//     console.log("Response from firstForm", data);
		//   });
		// console.log("Data on Submit", data);
	};
	const onSubmitTotal = async () => {
		const data = watch();
		// console.log(data);
		// console.log(JSON.stringify(data))
		data.City = data.City.value;
		data.CollegeName = data.CollegeName.value;
		data.Department = data.Department.value;
		data.GraduationDegree = data.GraduationDegree.label;
		data.YearofGrad = data.YearofGrad.label;
		data.placement_time = data.placement_time.label;
		data.targeted_companies = data.targeted_companies.label;
		data.ConnectMentorTiming = data.ConnectMentorTiming.label;
		data.AddResume = data.AddResume[0];

		var newArr = [];

		for (let i = 0; i < data.interests.length; i++) {
			newArr.push(data.interests[i].value);
		}

		data.interests = newArr;

		const newData = { ...data };

		const formData = new FormData();

		for (let item in newData) {
			formData.append(item, newData[item]);
		}

		// await fetch(BASE_URL + "/MentorStudentConnect", {
		//   method: "POST",
		//   headers: { "Content-Type": "application/json" },
		//   body: JSON.stringify(formData),
		// })
		// .then((res) => res.json())
		axios
			.post(BASE_URL + "/MentorStudentConnect", formData)
			.then((data) => {
				// console.log("success", data);
			})
			.catch((err) =>{
				// console.log(err);
			});
		// fetch(BASE_URL.BASE_URL + "/signin", {
		//   method: "POST",
		//   body: JSON.stringify({
		//     id: 3,
		//     email: data.email,
		//     password: data.password,
		//     isGoogle: false,
		//   }),
		//   headers: { "content-Type": "application/json" },
		// })
		//   .then((response) => response.json())
		//   .then((data) => {
		//     if (Object.keys(data).find((element) => element === "token")) {
		//       localStorage.setItem("token", data.token);
		//       update_token(data.token);

		//       if (
		//         history.location.state != undefined &&
		//         Object.keys(history.location.state).find(
		//           (element) => element === "prev"
		//         )
		//       ) {
		//         setRoute(history.location.state.prev);
		//       } else {
		//         setRoute("/");
		//       }

		//       setRender(true);

		//       notifyPopup();
		//     } else {
		//       setError(true);
		//       setErrmsg(data.message);
		//       setLoad(false);
		//       pwdRef.current.value = "";
		//     }
		//   });

		// console.log("Data on Submit", data);
		// console.log(data);
	};
	// var number = document.getElementById('NoSessions');

	// // Listen for input event on numInput.
	// number.onkeydown = function(e) {
	//     if(!((e.keyCode > 95 && e.keyCode < 106)
	//       || (e.keyCode > 47 && e.keyCode < 58)
	//       || e.keyCode == 8)) {
	//         return false;
	//     }
	// }
  const [handleOrder, setHandleOrder] = useState(false);

  function handlePay() {
    // setLoader(true);

  //   let coupon_code;
  //   if (props.apply == false) {
  //     coupon_code = "";
  //   } else {
  //     if (code.current.value != "") {
  //       coupon_code = code.current.value;
  //     } else {
  //       coupon_code = "";
  //     }
  //   }

    displayRazorpay(
      // tierId,
      // coupon_code,
      // token,
      // setLoadT1,
      // setLoadT2,
      // setLoadT3,
      // setLoadT4,
      // setCoupon,
      // setHandlePayment,
      setHandleOrder,
      plan
      // handlePaymentPopup
  ) 
    
  //   .then((data) => {
  //     setData(data);
  //   });
  
  //   setCouponCode(coupon_code);
  //   props.onClose();
  }

	const graduation = [
		{ label: "CSE", value: "CSE" },
		{ label: "ECE", value: "ECE" },
		{ label: "IT", value: "IT" },
		{ label: "EEE", value: "EEE" },
		{ label: "MECH", value: "MECH" },
		{ label: "CIVIL", value: "CIVIL" },
		{ label: "Others", value: "Others" },
	];
	const targeted_companied_option = [
		{
			label: "FAANG (Facebook, Amazon, Apple, Netflix and Google)",
			value: "FAANG (Facebook, Amazon, Apple, Netflix and Google)",
		},
		{
			label: "Product based (Microsoft, Flipkart, etc)",
			value: "Product based (Microsoft, Flipkart, etc)",
		},
		{
			label: "Service based (TCS, Wipro, etc)",
			value: "Service based (TCS, Wipro, etc)",
		},
	];
	const time_option = [
		{
			label: "This week",
			value: "This week",
		},
		{
			label: "This month",
			value: "This month",
		},
		{
			label: "Next Month",
			value: "Next Month",
		},
	];
	const placement_time_option = [
		{
			label: "Less than 1 month",
			value: "Less than 1 month",
		},
		{
			label: "1-2 month",
			value: "1-2 month",
		},
		{
			label: "2-6 month",
			value: "2-6 month",
		},
		{
			label: "6+ months",
			value: "6+ months",
		},
	];
	const interests_option = [
		{
			label: "Data Science / Analytics",
			value: "Data Science / Analytics",
		},
		{
			label: "Software Development",
			value: "Software Development",
		},
		{
			label: "Machine Learning / Artificial Intelligence",
			value: "Machine Learning / Artificial Intelligence",
		},
		{
			label: "Full Stack Development ",
			value: "Full Stack Development ",
		},
		{
			label: "Web/App  Design ",
			value: "Web/App  Design ",
		},
	];
	const GraduationDegree = [
		{ label: "BE/BTech", value: "Bachelor of Engg./tech" },
		{ label: "Bsc", value: 1 },
		{ label: "BCA", value: 1 },
		{ label: "Others", value: 1 },
	];
	const year = () => {
		let date = new Date().getFullYear();
		let arr = [];
		for (let i = date + 5; i > 2016; i--) {
			arr.push({ value: i, label: i });
		}
		return arr;
	};

	const Degree = [
		{ label: "CSE" },
		{ label: "ECE" },
		{ label: "IT" },
		{ label: "EEE" },
		{ label: "MECH" },
	];
	const preparationlevel = [
		{
			label: "I have no idea where I am.",
			value: "I have no idea where I am.",
		},
		{
			label: "I Haven't started any placement preparation yet!",
			value: "I Haven't started any placement preparation yet!",
		},
		{
			label: "I do have theoretical knowledge but no hands-on experience",
			value: "I do have theoretical knowledge but no hands-on experience",
		},
		{
			label:
				"I have done some projects but have zero knowledge of the interview process",
			value:
				"I have done some projects but have zero knowledge of the interview process",
		},
		{
			label:
				"I am confident about my Coding & Aptitude skills but lack in Soft skills",
			value:
				"I am confident about my Coding & Aptitude skills but lack in Soft skills",
		},
		{
			label: "I am confident about my Aptitude Skills",
			value: "I am confident about my Aptitude Skills",
		},
		{
			label: "I am confident about my Coding Skills",
			value: "I am confident about my Coding Skills",
		},
	];
	const HelpingHand = [
		{
			label: "I need guidance with getting placed in FAANG companies",
			value: 1,
		},
		{
			label: "I need guidance with cracking Entrance test of Tech companies",
			value: 2,
		},
		{
			label: "I need help in getting practical exposure with good internships",
			value: 3,
		},
		{ label: "I need help to prepare for aptitude skills", value: 4 },
		{ label: "I need help in upskilling my softskills", value: 5 },
		{ label: "I need help with my interview preparation", value: 6 },
		{ label: "I need support in project based learning", value: 7 },
	];
	const [message, setMessage] = useState(true);

	async function isEven(email) {
		// console.log(String(email));
		await fetch(BASE_URL + "/liveVerify", {
			method: "POST",
			body: JSON.stringify({ email: email }),
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
			},
		})
			.then((response) => response.json())
			.then((data) => {
				// console.log(data.message)
				//  data.message
				if (data.message === true) {
					setMessage(true);
					// return true
				} else {
					// console.log("return false")
					// return false

					setMessage(false);
				}
			})
			.catch((err) => {
				// console.log(err);
			});
		// return false
	}
	return (
		<div>
			<div
				className={matches ? "App container row " : " App container row p-0"}
				style={{
					marginTop: "10px",
					textAlign: "center",
					fontFamily: "sans-serif",
				}}>
          {/* <button onClick={()=>handlePay()} >Pay</button> */}
				{matches ? (
					<div className='col-lg-6 col-md-4 col-sm-12 d-flex flex-column main_heading'>
						{/* <pre>{JSON.stringify(watch(), null, 2)}</pre>{" "} */}
						<h3
							className='display-6 d-flex justify-content-center mt-5'
							style={{ color: "#185adb", fontWeight: "500" }}>
							Unlock free content
						</h3>
						<lottie-player
							src='https://assets8.lottiefiles.com/packages/lf20_9rfdfjek.json'
							background='transparent'
							speed='1'
							// style="width: 300px; height: 300px;"
							style={{ width: "500px", height: "500px" }}
							loop
							autoplay></lottie-player>
					</div>
				) : (
					""
				)}

				<div className='col-lg-6 col-md-4 col-sm-12 my-5'>
					<section
						className='shadow p-4 rounded section_1 '
						style={{ display: firstForm === true ? "block" : "none" }}>
						<div className='form-group'>
							<h2
								style={{
									color: "#185adb",
									fontWeight: "600",
									letterSpacing: "0.2px",
									fontSize: !matches && "1.3rem",
								}}>
								Personal Information{" "}
							</h2>
							<label
								htmlFor='FirstName'
								className='d-flex justify-content-start mt-3 '
								style={{ textAlign: "left" }}>
								Full Name
							</label>
							<input
								id='FirstName'
								className='form-control border rounded bg-white'
								placeholder='Just to give your account a name'
								{...register("FullName", {
									required: true,
								})}
							/>
							{errors.FullName && (
								<span
									className='text-danger d-flex justify-content-start'
									style={{ fontSize: "0.8rem" }}>
									Please enter your Full name
								</span>
							)}
						</div>
						<div>
							<label
								htmlFor='Email'
								className='d-flex justify-content-start mt-3'
								style={{ textAlign: "left" }}>
								Email address
							</label>
							<input
								id='Email'
								className='form-control border rounded bg-white'
								placeholder='To update you with placement and other details'
								{...register("Email", {
									required: true,
									pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
									// validate: (value) => isEven(value),
								})}
							/>
							{errors.Email && (
								<span
									className='text-danger d-flex justify-content-start'
									style={{ fontSize: "0.8rem" }}>
									Please enter your Email Address properly
								</span>
							)}
							{message == false && (
								<span
									className='my-0 text-danger d-flex justify-content-start'
									style={{ fontSize: "0.8rem" }}>
									Your email domain is invalid
								</span>
							)}
						</div>

						<div>
							<label
								htmlFor='Password'
								className='d-flex justify-content-start mt-3'
								style={{ textAlign: "left" }}>
								Password
							</label>
							<input
								type='password'
								className='form-control border rounded bg-white'
								placeholder='Password'
								{...register("password", {
									required: true,
								})}
							/>
							{errors.password && (
								<small
									className='text-danger  d-flex justify-content-start'
									style={{ fontSize: "0.8rem" }}>
									Please enter your Password
								</small>
							)}
						</div>

						<div>
							<label
								className='d-flex justify-content-start mt-3'
								style={{ textAlign: "left" }}>
								Phone Number
							</label>
							<PhoneInputWithCountry
								name='PhoneNumber'
								control={control}
								defaultCountry='IN'
								placeholder='To share useful resources with you.'
								className='form-control border rounded bg-white'
								style={{ padding: "5px" }}
								rules={{ required: true, validate: isValidPhoneNumber }}
							/>

							{errors.PhoneNumber && (
								<small
									className='text-danger  d-flex justify-content-start'
									style={{ fontSize: "0.8rem" }}>
									Please enter your correct Phone Number
								</small>
							)}
						</div>
						<div>
							<label
								className='d-flex justify-content-start mt-3'
								style={{ textAlign: "left" }}>
								College Name
							</label>
							{/* <input
								type='Enter your college Name'
								className='form-control'
								placeholder='CollegeName'
								{...register("CollegeName", {
									required: true,
								})}
							/> */}
							<Controller
								name='CollegeName'
								control={control}
								rules={{ required: true }}
								render={({ field }) => (
									<AsyncSelect
										isClearable
										cacheOptions
										loadOptions={loadOptions}
										placeholder='To send you any off-campus updates'
										className='form-control'
										noOptionsMessage={() => "Start typing to find your college"}
										defaultOptions
										styles={customStyles}
										{...field}
									/>
								)}
							/>
						</div>
						<div>
							<label
								htmlFor='YearofGrad'
								className='d-flex justify-content-start mt-3'
								style={{ textAlign: "left" }}>
								Graduation Year
							</label>
							<Controller
								name='YearofGrad'
								control={control}
								rules={{ required: true }}
								render={({ field }) => (
									<Select
										id='YearofGrad'
										placeholder='So we know which year are you in'
										className='form-control '
										options={year()}
										{...field}
										styles={customStyles}
									/>
								)}
							/>
							{errors.YearofGrad && (
								<small
									className='text-danger  d-flex justify-content-start'
									style={{ fontSize: "0.8rem" }}>
									Please choose your Year of Graduation
								</small>
							)}
						</div>
						{/* <div>
              <label
                htmlFor="graduationdegree"
                className="d-flex justify-content-start mt-3"
                style={{ textAlign: "left" }}
              >
                Graduation Degree
              </label>
              <Controller
                name="GraduationDegree"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    id="YearofGrad"
                    placeholder="Enter your year graduation"
                    className="form-control "
                    options={GraduationDegree}
                    {...field}
                    styles={customStyles}
                  />
                )}
              />
              {errors.GraduationDegree && (
                <small
                  className="text-danger  d-flex justify-content-start"
                  style={{ fontSize: "0.8rem" }}
                >
                  Please choose your graduation degree
                </small>
              )}
            </div> */}
						<div className='form-check my-3'>
							<input
								type='checkbox'
								defaultChecked={true}
								{...register("Evidence", {
									required: true,
								})}
								className='form-check-input'
								id='exampleCheck1'
							/>
							<label className='form-check-label  ' htmlFor='exampleCheck1'>
								I agree to Cantilever Labs Terms of Service and the use of
								personal data as explained in Cantilever Labs{" "}
								<a href='https://www.cantileverlabs.com/policies#privacy'>
									{" "}
									terms and conditions{" "}
								</a>
							</label>
						</div>

						<button
							disabled={isValid !== true}
							className='btn btn-primary px-5 my-2'
							onClick={() => {
								changeStates(setFirstForm, false, setSecondForm, true);
								onSubmit();
							}}>
							Submit
						</button>
					</section>

					{secondForm === true && (
						<section
							className='shadow p-4 rounded section_2 mt-5'
							style={{ display: secondForm === true ? "block" : "none" }}>
							{/* <div>
                <label
                  htmlFor="GraduationDegree"
                  className="d-flex justify-content-start mt-3"
                  style={{ textAlign: "left" }}
                >
                  College Name
                </label>
                <input
                  id="GraduationDegree"
                  className="form-control "
                  placeholder="GraduationDegree"
                  {...register("GraduationDegree", {
                    required: true
                  })}
                />
                {errors.CollegeName && (
                  <small
                    className="text-danger  d-flex justify-content-start"
                    style={{ fontSize: "0.8rem" }}
                  >
                    Specify your College Name
                  </small>
                )}
              </div> */}
							<div>
								<h2
									style={{
										color: "#185adb",
										fontWeight: "600",
										letterSpacing: "0.2px",
										fontSize: !matches && "1.3rem",
									}}>
									{" "}
									College Details{" "}
								</h2>
								<label
									htmlFor='graduationdegree'
									className='d-flex justify-content-start mt-3'
									style={{ textAlign: "left" }}>
									Graduation Degree
								</label>
								<Controller
									name='GraduationDegree'
									control={control}
									rules={{ required: true }}
									render={({ field }) => (
										<Select
											id='YearofGrad'
											placeholder='Graduation Degree'
											className='form-control '
											options={GraduationDegree}
											{...field}
											styles={customStyles}
										/>
									)}
								/>

								{errors.GraduationDegree && (
									<small
										className='text-danger  d-flex justify-content-start'
										style={{ fontSize: "0.8rem" }}>
										Please choose your Graduation degree
									</small>
								)}
							</div>
							<div>
								<label
									htmlFor='Department'
									className='d-flex justify-content-start mt-3'
									style={{ textAlign: "left" }}>
									Department
								</label>
								<Controller
									name='Department'
									control={control}
									rules={{ required: true }}
									render={({ field }) => (
										<Select
											id='Department'
											placeholder='Enter your Department'
											className='form-control '
											options={graduation}
											{...field}
											styles={customStyles}
										/>
									)}
								/>
								{errors.Department && (
									<small
										className='text-danger  d-flex justify-content-start'
										style={{ fontSize: "0.8rem" }}>
										Please choose your Department
									</small>
								)}
							</div>

							<div>
								<label
									className='d-flex justify-content-start mt-3'
									style={{ textAlign: "left" }}>
									Enter your City
								</label>
								<Controller
									name='City'
									control={control}
									rules={{ required: true }}
									render={({ field }) => (
										<Select
											isSearchable
											id='City'
											placeholder="This is for our knowledge of student's hometown"
											className='form-control'
											options={optionCities}
											{...field}
											styles={customStyles}
										/>
									)}
								/>
							</div>
							<button
								disabled={isValid !== true}
								className='btn btn-primary px-5 my-2'
								onClick={() => {
									changeStates(setSecondForm, false, setThirdForm, true);
								}}>
								Next
							</button>
						</section>
					)}
					{thirdForm === true && (
						<section
							className='shadow p-4 rounded section_3 mt-5'
							style={{ display: thirdForm === true ? "block" : "none" }}>
							<div>
								<h2
									style={{
										color: "#185adb",
										fontWeight: "600",
										letterSpacing: "0.2px",
										fontSize: !matches && "1.3rem",
									}}>
									Career Details
								</h2>
								<label
									className='d-flex  mt-3 mb-1'
									style={{ textAlign: "left" }}>
									In what areas do you need our help?
								</label>
								<div className='form-check ms-3'>
									<input
										className='form-check-input'
										type='checkbox'
										placeholder='To acknowledge your needs and expectations'
										value='Complete Placement Preparation'
										name='In_what_areas_do_you_need_our_help'
										id='check1'
										{...register("HelpInArea", {
											// required: true,
										})}
									/>
									<label
										className='form-check-label'
										className='d-flex '
										htmlFor='check1'
										style={{ textAlign: "left" }}>
										Complete Placement Preparation
									</label>
								</div>
								<div className='form-check ms-3'>
									<input
										className='form-check-input'
										type='checkbox'
										value='Jobs'
										id='check2'
										{...register("HelpInArea")}
										name='In_what_areas_do_you_need_our_help'
									/>
									<label
										className='form-check-label'
										htmlFor='check2'
										className='d-flex '>
										Jobs
									</label>
								</div>
								<div className='form-check ms-3'>
									<input
										className='form-check-input'
										type='checkbox'
										value='Internships'
										id='check3'
										{...register("HelpInArea")}
										name='In_what_areas_do_you_need_our_help'
									/>
									<label
										className='form-check-label'
										htmlFor='check3'
										className='d-flex '>
										Internships
									</label>
								</div>
								<div className='form-check ms-3'>
									<input
										className='form-check-input'
										type='checkbox'
										value='Personalised Career Guidance'
										id='check4'
										{...register("HelpInArea")}
										name='In_what_areas_do_you_need_our_help'
									/>
									<label
										className='form-check-label'
										htmlFor='check4'
										className='d-flex '>
										Personalised Career Guidance
									</label>
								</div>
								<div className='form-check ms-3'>
									<input
										className='form-check-input'
										type='checkbox'
										value='Free Courses and toolkits'
										id='check5'
										{...register("HelpInArea", {
											register: true,
										})}
										name='In_what_areas_do_you_need_our_help'
									/>
									<label
										className='form-check-label'
										htmlFor='check5'
										className='d-flex '>
										Free Courses and toolkits
									</label>
								</div>
								<div className='form-check ms-3'>
									<input
										className='form-check-input'
										type='checkbox'
										value='Cracking Screening test of companies'
										id='check6'
										{...register("HelpInArea")}
										name='In_what_areas_do_you_need_our_help'
									/>
									<label
										className='form-check-label'
										htmlFor='check6'
										className='d-flex '
										style={{ textAlign: "leftpap" }}>
										Cracking Screening test of companies
									</label>
								</div>
								<div className='form-check ms-3'>
									<input
										className='form-check-input'
										type='checkbox'
										value='others'
										id='check7'
										{...register("HelpInArea")}
										name='In_what_areas_do_you_need_our_help'
										onChange={() => {
											setIsOthers((prev) => !prev);
										}}
									/>
									<label
										className='form-check-label'
										htmlFor='check7'
										className='d-flex '>
										Others
									</label>
								</div>
								{isOthers && (
									<>
										<label
											htmlFor='others_input'
											className='d-flex justify-content-start mt-3'
											style={{ textAlign: "left" }}>
											If others, please mention:-
										</label>
										<input
											type='text'
											className='form-control border rounded bg-white'
											id='others_input'
											placeholder='If others please mention'
											{...register("Others")}
										/>
									</>
								)}
								{errors.In_what_areas_do_you_need_our_help && (
									<small
										className='text-danger  d-flex justify-content-start'
										style={{ fontSize: "0.8rem" }}>
										Please choose the areas where you need our help
									</small>
								)}
								{/* {errors.Department && (
									<small
										className='text-danger  d-flex justify-content-start'
										style={{ fontSize: "0.8rem" }}>
										Please choose your Department
									</small>
								)} */}
								<label
									htmlFor='targeted_companies'
									className='d-flex justify-content-start mt-3'
									style={{ textAlign: "left" }}>
									Which companies are you targeting?
								</label>
								<Controller
									name='targeted_companies'
									control={control}
									rules={{ required: true }}
									render={({ field }) => (
										<Select
											id='targeted_companies'
											placeholder='To suggest courses that are aligned with your goals'
											className='form-control '
											options={targeted_companied_option}
											{...field}
											styles={customStyles}
										/>
									)}
								/>
								{errors.targeted_companies && (
									<small
										className='text-danger  d-flex justify-content-start'
										style={{ fontSize: "0.8rem" }}>
										Please choose your targeted Companies
									</small>
								)}
								<label
									htmlFor='placement_time'
									className='d-flex justify-content-start mt-3'
									style={{ textAlign: "left" }}>
									How much time do you have for your On-campus/Off-campus
									Placements?
								</label>
								<Controller
									name='placement_time'
									control={control}
									rules={{ required: true }}
									render={({ field }) => (
										<Select
											id='placement_time'
											placeholder='So we priortise and strategise according to time left '
											className='form-control '
											options={placement_time_option}
											{...field}
											styles={customStyles}
										/>
									)}
								/>
								{errors.placement_time && (
									<small
										className='text-danger  d-flex justify-content-start'
										style={{ fontSize: "0.8rem" }}>
										Please choose atleast one of the options
									</small>
								)}
								<label
									htmlFor='interests'
									className='d-flex justify-content-start mt-3'
									style={{ textAlign: "left" }}>
									Tell us about your interests.
								</label>
								<Controller
									name='interests'
									control={control}
									rules={{ required: true }}
									render={({ field }) => (
										<CreatableSelect
											id='interests'
											isMulti
											placeholder='Will help us launch special sessions for your interests'
											className='form-control'
											options={interests_option}
											{...field}
											styles={customStyles}
										/>
									)}
								/>
								{errors.interests && (
									<small
										className='text-danger  d-flex justify-content-start'
										style={{ fontSize: "0.8rem" }}>
										Please choose atleast one of the options
									</small>
								)}
							</div>

							<button
								disabled={isValid == false}
								className='btn btn-primary px-5 my-2'
								onClick={() => {
									changeStates(setThirdForm, false, setFourthFrom, true);
									// onSubmitTotal();
								}}>
								Submit
							</button>
						</section>
					)}
					{fourthForm === true && (
						<section
							className='shadow p-4 rounded section_3 mt-5'
							style={{ display: fourthForm === true ? "block" : "none" }}>
							<div>
								<h2
									style={{
										color: "#185adb",
										fontWeight: "600",
										letterSpacing: "0.2px",
										fontSize: !matches && "1.3rem",
									}}>
									Query form
								</h2>
								<label
									htmlFor='targeted_companies'
									className='d-flex justify-content-start mt-3'
									style={{ textAlign: "left" }}>
									Share your latest resume with the mentor for personalised
									feedback during the 1:1 session
								</label>
								<input
									type='file'
									{...register("AddResume", {
										required: true,
									})}
								/>
								{/* <Controller
                  name="targeted_companies"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      id="targeted_companies"
                      placeholder="To suggest courses that are aligned with your goals"
                      className="form-control "
                      options={targeted_companied_option}
                      {...field}
                      styles={customStyles}
                    />
                  )}
                /> */}
								{errors.targeted_companies && (
									<small
										className='text-danger  d-flex justify-content-start'
										style={{ fontSize: "0.8rem" }}>
										Please choose a file
									</small>
								)}
								<label
									className='d-flex  mt-3 mb-1'
									style={{ textAlign: "left" }}>
									In what areas do you need the mentor's help?
								</label>
								<div className='form-check ms-3'>
									<input
										className='form-check-input'
										type='checkbox'
										placeholder='To acknowledge your needs and expectations'
										value='Resume Building'
										name='In_what_areas_do_you_need_the_mentor_help'
										id='checkresume1'
										{...register("MentorHelp", {
											required: true,
										})}
									/>
									<label
										className='form-check-label'
										className='d-flex '
										htmlFor='check1'
										style={{ textAlign: "left" }}>
										Resume Building
									</label>
								</div>
								<div className='form-check ms-3'>
									<input
										className='form-check-input'
										type='checkbox'
										value='Career planning'
										name='In_what_areas_do_you_need_the_mentor_help'
										id='checkresume2'
										{...register("MentorHelp", {
											required: true,
										})}
									/>
									<label
										className='form-check-label'
										htmlFor='check2'
										className='d-flex '>
										Career planning
									</label>
								</div>
								<div className='form-check ms-3'>
									<input
										className='form-check-input'
										type='checkbox'
										value='Cracking Placements/Interview preparation'
										id='checkresume3'
										{...register("MentorHelp", {
											required: true,
										})}
										name='In_what_areas_do_you_need_the_mentor_help'
									/>
									<label
										className='form-check-label'
										htmlFor='check3'
										className='d-flex '>
										Cracking Placements/Interview preparation
									</label>
								</div>
								<div className='form-check ms-3'>
									<input
										className='form-check-input'
										type='checkbox'
										value='Company specific preparation strategy'
										id='checkresume4'
										{...register("MentorHelp", {
											required: true,
										})}
										name='In_what_areas_do_you_need_the_mentor_help'
									/>
									<label
										className='form-check-label'
										htmlFor='checkresume5'
										className='d-flex '>
										Company specific preparation strategy
									</label>
								</div>
								<div className='form-check ms-3'>
									<input
										className='form-check-input'
										type='checkbox'
										value='Overcome Interview fear'
										id='checkresume6'
										{...register("MentorHelp", {
											required: true,
										})}
										name='In_what_areas_do_you_need_the_mentor_help'
									/>
									<label
										className='form-check-label'
										htmlFor='checkresume7'
										className='d-flex '>
										Overcome Interview fear
									</label>
								</div>
								<div className='form-check ms-3'>
									<input
										className='form-check-input'
										type='checkbox'
										value='Understand my strengths & weaknesses'
										id='checkresume8'
										{...register("MentorHelp", {
											required: true,
										})}
										name='In_what_areas_do_you_need_the_mentor_help'
									/>
									<label
										className='form-check-label'
										htmlFor='check6'
										className='d-flex '
										style={{ textAlign: "leftpap" }}>
										Understand my strengths & weaknesses
									</label>
								</div>
								<div className='form-check ms-3'>
									<input
										className='form-check-input'
										type='checkbox'
										value='others'
										id='checkresume9'
										{...register("MentorHelp", {
											required: true,
										})}
										name='In_what_areas_do_you_need_the_mentor_help'
										onChange={() => {
											setOthersResume((prev) => !prev);
										}}
									/>
									<label
										className='form-check-label'
										htmlFor='checkresume9'
										className='d-flex '>
										Others
									</label>
								</div>
								{othersResume && (
									<>
										<label
											htmlFor='others_input'
											className='d-flex justify-content-start mt-3'
											style={{ textAlign: "left" }}>
											If others, please mention:-
										</label>
										<input
											type='text'
											className='form-control border rounded bg-white'
											id='others_input'
											placeholder='If others please mention'
											{...register("OthersForResume")}
										/>
									</>
								)}
								{errors.In_what_areas_do_you_need_our_help && (
									<small
										className='text-danger  d-flex justify-content-start'
										style={{ fontSize: "0.8rem" }}>
										Please choose the areas where you need our help
									</small>
								)}
								{/* {errors.Department && (
									<small
										className='text-danger  d-flex justify-content-start'
										style={{ fontSize: "0.8rem" }}>
										Please choose your Department
									</small>
								)} */}
								<label
									htmlFor='targeted_companies'
									className='d-flex justify-content-start mt-3'
									style={{ textAlign: "left" }}>
									When do you plan to connect with the mentor?
								</label>
								<Controller
									name='ConnectMentorTiming'
									control={control}
									rules={{ required: true }}
									render={({ field }) => (
										<Select
											id='targeted_companies'
											placeholder='To suggest suitable timings'
											className='form-control '
											options={time_option}
											{...field}
											styles={customStyles}
										/>
									)}
								/>
								{errors.targeted_companies && (
									<small
										className='text-danger  d-flex justify-content-start'
										style={{ fontSize: "0.8rem" }}>
										Please choose the timings
									</small>
								)}
								<label
									htmlFor='number_of_session'
									className='d-flex justify-content-start mt-3'
									style={{ textAlign: "left" }}>
									Number of sessions
								</label>
								<input
									id='NoSessions'
									min='1'
									// defaultValue="1"
									type='number'
									oninput='this.value = Math.abs(this.value)'
									className='form-control border rounded bg-white'
									placeholder='Number Of Sessions'
									{...register("NumberOfSessions", {
										required: true,
									})}
								/>
								{errors.targeted_companies && (
									<small
										className='text-danger  d-flex justify-content-start'
										style={{ fontSize: "0.8rem" }}>
										Please choose a number
									</small>
								)}
							</div>

							<button
								disabled={isValid == false}
								className='btn btn-primary px-5 my-2'
								onClick={() => {
									changeStates(setFourthFrom, false, setthanks, true);
									onSubmitTotal();
								}}>
								Submit
							</button>
						</section>
					)}

					{thanks === true && (
						<section className=' congratulations shadow p-4 rounded mt-5 '>
							{showInterested === true && (
								<PopupAd
									CloseTheButton={CloseTheButton}
									PushTheUser={PushTheUser}
									InterestedUser={InterestedUser}
								/>
							)}
							<div className='d-flex justify-content-center'>
								<lottie-player
									src='https://assets1.lottiefiles.com/packages/lf20_oUXj84.json'
									background='transparent'
									speed='1'
									style={{ width: "150px", height: "150px" }}
									autoplay></lottie-player>
							</div>
							<p> Thank you for registering with us </p>
						</section>
					)}
				</div>
			</div>
		</div>
	);
}

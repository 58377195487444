import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { BASE_URL } from "../../env";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import moment from "moment";
import "./NewblogfrontPage.css";
import useMedia from "../../componentsofcourse/useMedia";
import { Link } from "react-router-dom";
// import { floor } from "lodash";
import HelmetComp from "../../components/HelmetComp";
import ReactGA from "react-ga";
import logo from "../../Assets/img/cantileverlogoblack.png";
import BlogSkeleton from "../../components/BlogSkeleton";

const NewBlogFrontPage = () => {
  const [AllBlogs, SetAllBlogs] = useState([]);
  const [firstBlog, setFirstBlog] = useState([]);
  const [type, setType] = useState("");
  // const [body, setBody] = useState("yy");
  const [loaded, setLoaded] = useState(false);
  const [ReferenceBlogs, setReferenceBlogs] = useState([]);
  ReactGA.initialize("UA-198180460-1");
  const matches = useMedia("(min-width : 968px )");
  // const []
  // moment(testDate,'dd/mm/yyyy');
  useEffect(() => {
    fetch(BASE_URL + "/getAllBlogs")
      .then((response) => response.json())
      .then((data) => {
        SetAllBlogs(data?.blogs);
        setFirstBlog(data?.blogs[0]);
        setReferenceBlogs(data?.blogs);
        //   FilterAccordingToType()
        //   setFirstThreeBlogs()
        setLoaded(true);
      })
      .catch((err) => {});
  }, []);

  // useEffect(() => {
  // console.log(AllBlogs);
  // }, [AllBlogs]);

  function FilterAccordingToType() {
    // window.scrollTo(350,350)

    const filteredValue = ReferenceBlogs.filter((value) => {
      if (value.topic == type) {
        return value;
      }
    });
    // console.log(filteredValue);
    SetAllBlogs(filteredValue);
  }

  useEffect(() => {
    // SetAllBlogs(ReferenceBlogs)
    FilterAccordingToType();
  }, [type]);

  // function capitalizeFirstLetter(string) {
  // 	string = string.toLowerCase();
  // 	return string.charAt(0).toUpperCase() + string.slice(1);
  // }

  // function CapitalizeEveryLetter(string) {
  // 	string = string.toLowerCase();
  // 	const arr = string.split(" ");

  // 	//loop through each element of the array and capitalize the first letter.

  // 	for (var i = 0; i < arr.length; i++) {
  // 		arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  // 	}

  // 	//Join all the elements of the array back into a string
  // 	//using a blankspace as a separator
  // 	const str2 = arr.join(" ");
  // 	return str2;
  // }

  // function strip_html_tags(str) {
  // 	if (str === null || str === "") return false;
  // 	else str = str?.toString();
  // 	return str?.replace(/<[^>]*>/g, "");
  // }
  return (
    <>
      {/* <Navbar /> */}
      <HelmetComp
        info={{
          title: "Blogs",
          description: "Blog page of cantilever labs",
          // description: '',
          siteName: "https://www.cantileverlabs.com/newblogpages",
          summary: "Prepare yourself for the highest level.",
          pageUrl: window.location.href,
          imagUrl: { logo },
        }}
      />
      <div className="container-fluid my-5">
        {loaded ? (
          <>
            {matches == true && (
              <div className="heading-container-blogs container">
                <Link
                  to={`/blogs/${firstBlog.newid}`}
                  style={{ color: "#000" }}
                  className="firstblog"
                >
                  <div className="d-flex flex-row card shadow rounded">
                    <img
                      src={firstBlog.image}
                      alt=""
                      style={{ width: "500px", marginRight: "10px" }}
                    />
                    <div className="blockquote my-5">
                      <p
                        className="titlefortheblog pb-3"
                        style={{ fontFamily: "poppins", fontSize: "2rem" }}
                      >
                        {" "}
                        {firstBlog.title}{" "}
                      </p>
                      <p
                        className="truncate"
                        style={{
                          maxWidth: "500px",
                          fontSize: "1rem",
                          fontFamily: "mulish",
                          fontWeight: "600",
                          textAlign: "justify",
                        }}
                      >
                        {" "}
                        {/* {strip_html_tags(firstBlog?.body?.slice(0, 300))}{" "} */}
                      </p>
                    </div>
                  </div>
                </Link>
                <div className=" threeblog container d-flex my-5 flex-row">
                  {ReferenceBlogs?.slice(4, 7).map((value) => {
                    return (
                      <Link
                        to={`/blogs/${value.newid}`}
                        onClick={() => {
                          ReactGA.event({
                            category: "blog",
                            type: "Reference blogpage",
                            name: value.newid,
                            action: value.newid,
                          });
                        }}
                        className="card thiscards shadow "
                        style={{
                          marginLeft: "3em",
                          marginRight: "3em",
                          color: "black",
                          width: "22rem",
                        }}
                      >
                        <img
                          className="card-img-top imagefortheblog"
                          src={value.image}
                        />
                        <div className="card-body p-2">
                          <div className="card-title">
                            {" "}
                            <span className="titlefortheblog">
                              {" "}
                              <b style={{ fontSize: "1.3rem" }}>
                                {" "}
                                {value.title}{" "}
                              </b>{" "}
                            </span>{" "}
                          </div>
                          <div className="card-text d-flex flex-column">
                            <p
                              style={{
                                fontFamily: "sans-serif",
                                color: "#185aab",
                                fontSize: "1rem",
                              }}
                            >
                              {moment(value.date).format("MMMM Do YYYY")}
                            </p>
                          </div>
                        </div>
                      </Link>
                    );
                  })}
                </div>
              </div>
            )}
          </>
        ) : (
          <BlogSkeleton />
        )}
        <div className=" container-fluid  " style={{ marginTop: "10vw" }}>
          <div className="d-flex row flex-row justify-content-around align-items-center ">
            <span
              onClick={() => {
                ReactGA.event({
                  category: "Blog Category",
                  type: "Blog",
                  name: "Blog Category Web Development",
                  action: "Blog Category Web Development",
                });
                return setType("Web Development");
              }}
              style={{
                fontFamily: "poppins",
                cursor: "pointer",
              }}
              className={
                (type === "Web Development"
                  ? " p-3  hover-options-that-are-selected  options-that-are-selected "
                  : " p-3 hover-category text-dark  ") +
                "col-lg-3 col-6 font-fix "
              }
            >
              Web Development
            </span>
            <span
              onClick={() => {
                ReactGA.event({
                  category: "Blog Category",
                  type: "Blog",
                  name: "Blog Category Data Science",
                  action: "Blog Category Data Science",
                });
                return setType("Data Science");
              }}
              style={{
                fontFamily: "poppins",
                cursor: "pointer",
              }}
              className={
                (type === "Data Science"
                  ? " p-3 hover-category   text-dark options-that-are-selected "
                  : " p-3 hover-category text-dark ") +
                "col-lg-3 col-6 font-fix"
              }
            >
              Data Science
            </span>
            <span
              onClick={() => {
                ReactGA.event({
                  category: "Blog Category",
                  type: "Blog",
                  name: "Blog Category Career/Placement",
                  action: "Blog Category Career/Placement",
                });
                return setType("Career/Placement");
              }}
              style={{
                fontFamily: "poppins",

                cursor: "pointer",
              }}
              className={
                (type === "Career/Placement"
                  ? " p-3 hover-category text-dark options-that-are-selected  "
                  : " p-3 hover-category text-dark ") +
                "col-lg-3 col-6 font-fix"
              }
            >
              Career / Placement
            </span>
            <span
              onClick={() => {
                ReactGA.event({
                  category: "Blog Category",
                  type: "Blog",
                  name: "Blog Category Product Companies",
                  action: "Blog Category Product Companies",
                });
                return setType("Product Companies");
              }}
              style={{
                fontFamily: "poppins",

                cursor: "pointer",
              }}
              className={
                (type === "Product Companies"
                  ? " p-3 hover-category text-dark options-that-are-selected "
                  : " p-3 hover-category text-dark ") +
                "col-lg-3 col-6 font-fix"
              }
            >
              Product Companies
            </span>
          </div>
        </div>

        <div className="container allblogs row my-5">
          {AllBlogs.length > 1 ? (
            <>
              {" "}
              {AllBlogs.map((value) => {
                return (
                  <div className="col-lg-4 col-md-5 col-sm-3">
                    <Link
                      to={`/blogs/${value.newid}`}
                      onClick={() => {
                        ReactGA.event({
                          category: "blog",
                          type: "All blogpage",
                          name: value.newid,
                          action: value.newid,
                        });
                      }}
                      style={{ color: "#000" }}
                    >
                      <div className="card thiscards shadow ">
                        <img
                          className="card-img-top imagefortheblog"
                          src={value.image}
                          style={{ maxWidth: "100%", height: "auto" }}
                        />
                        <div
                          className="card-body "
                          style={{ maxHeight: "300px" }}
                        >
                          <div className="card-title">
                            {" "}
                            <span
                              className="titlefortheblog"
                              style={{
                                fontFamily: "poppins",
                                fontSize: "1.3rem",
                                fontWeight: 500,
                              }}
                            >
                              {" "}
                              <b> {value.title} </b>{" "}
                            </span>{" "}
                          </div>
                        </div>
                        <footer>
                          <p
                            className="px-3 py-2"
                            style={{
                              fontFamily: "sans-serif",
                              color: "#185aab",
                            }}
                          >
                            {moment(value.date).format("MMMM Do YYYY")}
                          </p>
                        </footer>
                      </div>
                    </Link>
                  </div>
                );
              })}{" "}
            </>
          ) : (
            <>
              <div className="d-flex justify-content-center align-items-center">
                <lottie-player
                  src="https://assets6.lottiefiles.com/packages/lf20_m6cuL6.json"
                  background="transparent"
                  speed="1"
                  style={{ width: "600px", height: "600px" }}
                  loop
                  autoplay
                ></lottie-player>
              </div>
            </>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default NewBlogFrontPage;

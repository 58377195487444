import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { BASE_URL } from '../../env';

const HybridExamDashboard = () => {
    const [HybridExam, setHybridExam] = useState()
    useEffect(() => {
        fetch(BASE_URL + "/fetchingtheexam", {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
          .then((data) => {
            console.log(data.data);
            setHybridExam(data.data);
          });
      }, []);

      return(
<div className="mid-border">
      <div className="container-fluid">
        <div className="dashboardExamHeading">All Exams:</div>
        <div className="row gx-3">
          {HybridExam ? (
            <>
              {HybridExam?.map((val, index) => {
                return (
                  <div className="col-md-4 col-sm-6 mb-3">
                    <div
                      className="card shadow rounded"
                      style={{ border: "none", position: "relative" }}
                    >
                      {/* {!NotLocked(val.startTime, val.endTime) && (
                        <div
                          className="d-flex justify-content-center align-items-center glass-morphism"
                          style={{
                            position: "absolute",
                            top: "0",
                            left: "0",
                            right: "0",
                            height: "100%",
                            width: "100%",
                            zIndex: "2",
                          }}
                        >
                          <LockIcon
                            style={{ transform: "scale(3)", color: "#15314B" }}
                          />
                          <br />
                        </div>
                      )}
                      {!NotLocked(val.startTime, val.endTime) && (
                        <span
                          className="d-flex justify-content-center"
                          style={{
                            position: "absolute",
                            // top: "0",
                            left: "0",
                            right: "0",
                            bottom: "0",
                            fontFamily: "Poppins-bold",
                            fontSize: "10px",
                            width: "100%",
                            zIndex: "2",
                          }}
                        >
                          {moment
                            .unix(val.startTime)
                            .format("MMM Do,YYYY h:mmA")}
                          &nbsp; to &nbsp;
                          {moment.unix(val.endTime).format("MMM Do,YYYY h:mmA")}
                        </span>
                      )} */}
                      <div className="dashboardExamCardHeader">{val.name}</div>
                      <div className="dashboardExamCardBody">
                        <p
                          className="dashboardExamCardBodyText"
                          style={{
                            fontSize: "14px",
                            fontFamily: "Nunito-Bold",
                          }}
                        >
                          Test-Duration : {val.totalDuration} minutes
                        </p>
                        <div>
                          <button type="button" className="btn dashboardExamCardBodyBtn">
                            <a
                              className="text-white"
                              onClick={(e) => {
                                // ReactGA.event({
                                //   category: "Dashboard",
                                //   type: "Coding Exam",
                                //   name: val.name,
                                //   action: val.name,
                                // });
                                // if (NotLocked(val.startTime, val.endTime)) {
                                // } else {
                                //   ToSetPopUp(e, index);
                                // }
                              }}
                              target="_blank"
                              href={`/hybridExam/${val._id}`}
                            //   href={
                            //     NotLocked(val.startTime, val.endTime)
                            //       ? "/codinginstructions" +
                            //         "?duration=" +
                            //         val.timer +
                            //         "&questions=" +
                            //         val.questions.length +
                            //         "&id=" +
                            //         val._id+"&name="+localStorage.getItem("username")+"&testname="+val.name
                            //       : ""
                            //   }
                              style={{
                                fontSize: "16px",
                                fontFamily: "Nunito-Bold",
                                padding: "4px 20px",
                              }}
                            >
                              Start Exam
                            </a>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <h1 className="text-white">Exam are loading....</h1>
          )}
        </div>
      </div>
      {/* {cannotStartExamPopUp && (
        <div
          style={{
            position: "absolute",
            zIndex: "20",
            backdropFilter: "brightness(0)",
            height: "200px",
            width: "550px",
            top: "50%",
            marginLeft: "-250px",
            marginTop: "-100px",
            left: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
            padding: "20px",
          }}
        >
          Exam will be active from &nbsp;
          {moment
            .unix(HybridExam[ExamIndex].startTime)
            .format("MMMM Do, YYYY h:mm:ss A")}
          &nbsp; to &nbsp;
          {moment
            .unix(HybridExam[ExamIndex].endTime)
            .format("MMMM Do, YYYY h:mm:ss A")}
          &emsp;
          <button
            // onClick={() => {
            //   setcannotStartExamPopUp(false);
            // }}
            className="btn btn-md btn-primary"
          >
            Okay
          </button>
        </div>
      )} */}
    </div>
  );
}

export default HybridExamDashboard

import React, { useState, useEffect } from "react";
import useMedia from "./useMedia";
import Slider from "react-slick";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-circular-progressbar/dist/styles.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import CourseCalendar from "./MycourseCalendar";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "../Assets/css/coursecalendar.css";
import ReactPlayer from "react-player";
import { Chart } from "react-google-charts";

SwiperCore.use([Pagination, Navigation]);

const MyCourseReportCard = ({
  mockprogressArray,
  mockpercentage,
  percentage,
  TotalVideos,
  progressArray,
  setMockChanged,
  courseID,
  totalToolkits,
  courseName,
  iframe,
  mode,
  in_progress_percentage,
  not_started_videos,
}) => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    cssEase: "linear",
  };
  const [images, setimages] = useState([]);
  const [currImg, setCurrImg] = useState([]);
  useEffect(() => {
    fetch("https://api.cantileverlabs.com/getimgdata", {
      headers: {
        "Content-Type": "application/json",
      },
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setimages(data.images);
        console.log(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    console.log(courseName);
    const newArray = images.filter((val) => {
      // console.log(val.array[0]);
      return val.courseName == courseName;
    });
    console.log(newArray);
    setCurrImg(newArray);
  }, [images, courseName]);

  /////////////////////////////////////////// chart data /////////////////////////////////////////////////////////////////

  const data = [
    ["Task", "Hours per Day"],
    ["Not Watched", not_started_videos],
    ["In Progress", in_progress_percentage],
    ["Completed", percentage],
  ];
  const options = {
    title: "My Progress",
    pieHole: 0.3,
    is3D: true,
  };
  // console.log(not_started_videos, in_progress_percentage, percentage);
  /////////////////////////////////////////// chart data /////////////////////////////////////////////////////////////////

  return (
    <div
      className="upper_part bg-white  py-0 "
      style={{ backgroundColor: "#EFEFEF" }}
    >
      <div className=" calender-text d-flex justify-content-center align-items-center text-dark mt-0 shadowing-dashboard">
        <h3
          style={{ marginBottom: "0px", padding: "12px 8px" }}
          className="options-title text-uppercase "
        >
          Explore More{" "}
        </h3>
      </div>

      <div className="  progress-text d-flex justify-content-center align-items-center mt-0 shadowing-dashboard">
        <h3
          style={{ marginBottom: "0px", padding: "12px 8px" }}
          className="options-title text-uppercase "
        >
          {" "}
          Learning Progress{" "}
        </h3>
      </div>
      <div className="  slider-text d-flex justify-content-center align-items-center mt-0 shadowing-dashboard">
        <h3
          style={{ marginBottom: "0px", padding: "12px 8px" }}
          className="options-title text-uppercase "
        >
          Upcoming Sessions
        </h3>
      </div>

      <div className="calender  me-3 my-auto d-flex justify-content-center flex-column  align-item-start">
        <Swiper
          modules={[Navigation, Pagination]}
          className="mySwiper"
          navigation
          pagination={{ clickable: true }}
          autoplay={{
            delay: 2000,
            disableOnInteraction: true,
          }}
          loop={true}
        >
          <SwiperSlide>
            <iframe
              width="560"
              height="340"
              src="https://www.youtube.com/embed/OqhT_f9pNpg"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </SwiperSlide>

          <SwiperSlide>
            <iframe
              width="560"
              height="340"
              src="https://www.youtube.com/embed/K-BAopyqsOg"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </SwiperSlide>

          <SwiperSlide>
            <iframe
              width="560"
              height="340"
              src="https://www.youtube.com/embed/Z_se9zAtaU4"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </SwiperSlide>

          <SwiperSlide>
            <iframe
              width="560"
              height="340"
              src="https://www.youtube.com/embed/Nwmzjo9ZT64"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </SwiperSlide>
        </Swiper>
      </div>

      <div className="circularprogressbar mx-auto my-auto d-flex justify-content-center flex-column align-item-center">
        <div className="">
          {(progressArray.length / TotalVideos) * 100 !== 0 ? <CircularProgressbar
            value={(progressArray.length / TotalVideos) * 100}
            text={`${Math.floor((progressArray.length / TotalVideos) * 100)}%`}
            strokeWidth={15}
            styles={{
              root: {},
              path: {
                stroke: `#74BF58`, //
                strokeLinecap: "butt",
                transition: "stroke-dashoffset 0.5s ease 0s",
              },
              trail: {
                stroke: "#90EE90",
                strokeLinecap: "round",
              },
              text: {
                fill: "#195adb",
                fontWeight: "800",
                fontSize: "12px",
              },
              background: {
                fill: "#3e98c7",
              },
            }}
          /> :<CircularProgressbar
            value={100}
            text={`0%`}
            strokeWidth={15}
            styles={{
              root: {},
              path: {
                stroke: `#B32D00`,
                strokeLinecap: "butt",
                transition: "stroke-dashoffset 0.5s ease 0s",
              },
              trail: {
                stroke: "#d6d6d6",
                strokeLinecap: "round",
              },
              text: {
                fill: "#195adb",
                fontWeight: "800",
                fontSize: "10px",
              },
              background: {
                fill: "#3e98c7",
              },
            }}
          /> }
          {/* <Chart
            chartType="PieChart"
            width="100%"
            height="400px"
            data={data}
            options={options}
            loader={<div>Loading Chart</div>}
          /> */}
        </div>
      </div>

      <div className="timer-slider ms-3 my-auto d-flex justify-content-center flex-column align-item-center ">
        <div className="">
          <Slider className="mt-4" {...settings}>
            {currImg.map((val) => {
              return <img src={val.imgURL} className="imgSize" alt="index" />;
            })}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default MyCourseReportCard;

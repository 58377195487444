import React, {
  useState,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
} from "react";
import ReactGA from "react-ga";
// import TiSocialFacebookCircular from 'react-icons/lib/ti/social-facebook-circular';
import GoogleLogin, { useGoogleLogin } from "react-google-login";
// import FacebookLogin from "react-facebook-login";
import BASE_URL from "../env";
import { ModalContext } from "../Context/ModalContext";
import { useHistory, Link, useLocation } from "react-router-dom";
import styles from "../Assets/css/signup.module.css";
import { AuthContext } from "../Context/AuthContext";
// import ReactNotification from "react-notifications-component";
import { Store } from "react-notifications-component";
import "../Assets/css/notify-popup-theme.css";
import Navbar from "../components/Navbar";
import "react-notifications-component/dist/theme.css";
import useMedia from "../componentsofcourse/useMedia";
import firebase from "../firebase";
import HelmetComp from "../components/HelmetComp";
// import facebooknewlogo from '../Assets/img/facebooknewlogo.png'

const SignIn = () => {
  ReactGA.initialize("UA-198180460-1");
  var provider = new firebase.auth.GoogleAuthProvider();
  let location = useLocation();
  const referralArray = location.search.split("=");
  const ref = referralArray[1];

  console.log(ref);
  const handleauthfirebase = () => {
    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        /** @type {firebase.auth.OAuthCredential} */
        var credential = result.credential;

        // This gives you a Google Access Token. You can use it to access the Google API.
        var token = credential.accessToken;
        // The signed-in user info.
        var user = result.user;

        firebaseresponsehandler(result);
        // ...
      })
      .catch((error) => {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;
        // ...
      });
  };

  const matches = useMedia("(min-width : 768px )");
  const { signIn, loaded } = useGoogleLogin({});
  // Facebook Login
  const [login, setLogin] = useState(false);
  const [data, setData] = useState({});
  const [picture, setPicture] = useState("");
  let { open, update_status } = useContext(ModalContext);
  let { update_token, set_id, token, set_email } = useContext(AuthContext);
  const [modal, setModal] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [load, setLoad] = useState(false);
  const [render, setRender] = useState(false);
  const [error, setError] = useState(false);
  const [errmsg, setErrmsg] = useState("");
  const [route, setRoute] = useState("");
  const pwdRef = useRef();
  const history = useHistory();
  const [FirstName, setFirstName] = useState("");
  const [LastName, setLastName] = useState("");
  const [EMAILG, setEMAILG] = useState("");
  const [PASSWORDG, setPASSWORDG] = useState("");
  var emails = "";
  var tok = "";
  var pass = "";
  var firstnames = "";
  var lastnames = "";
  var authmethod = "";
  let text = false;
  if (
    history.location.state != undefined &&
    Object.keys(history.location.state).find((element) => element === "fromPT")
  ) {
    text = true;
  }

  function handleEmail(event) {
    let e = event.target.value;
    setEmail(e);
  }

  const responseFacebook = async (response) => {
    if (response.name) {
      let tik = response?.id;
      emails = response.email;
      const fullName = response.name;
      const fullNameArray = response?.name.split(" ");
      //
      tok = tik;
      firstnames = fullNameArray[0];
      lastnames = fullNameArray[fullNameArray.length - 1];
      authmethod = "facebook";

      googleSignIn();
    }
  };

  function handlePassword(event) {
    let p = event.target.value;
    setPassword(p);
  }

  const notifyPopup = () => {
    Store.addNotification({
      title: "Success!",
      message: "You have been Logged in!",
      type: "success",
      background: "pink",
      insert: "bottom",
      container: "bottom-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true,
      },
    });
  };

  function responsehandler(response) {
    let tik = response?.tokenId;
    let res = response?.profileObj;
    emails = res.email;
    tok = tik;
    firstnames = res.givenName;
    lastnames = res.familyName;
    authmethod = "google";
    googleSignIn();
  }
  function firebaseresponsehandler(response) {
    let tik = response.credential.idToken;
    let res = response.additionalUserInfo.profile;
    emails = res.email;
    tok = tik;
    firstnames = res.given_name;
    lastnames = res.family_name;
    authmethod = "google";
    googleSignIn();

    // console.log(response);
  }
  async function googleSignIn() {
    setLoad(true);
    await fetch(BASE_URL.BASE_URL + "/signin", {
      method: "POST",
      body: JSON.stringify({
        id: 3,
        email: emails,
        password: tok,
        isGoogle: true,
      }),
      headers: { "content-Type": "application/json" },
    })
      .then((response) => response.json())
      .then(async (data) => {
        ReactGA.event({
          category: "Login",
          type: "Google",
          name: "Google sign in",
          action: "User signed in by Google",
        });
        set_email(data?.user?.email);

        if (Object.keys(data).find((element) => element === "token")) {
          localStorage.setItem("token", data.token);
          update_token(data.token);

          if (ref == "job-posting") {
            history.push("/jobs-for-freshers");
          } else {
            if (
              history.location.state != undefined &&
              Object.keys(history.location.state).find(
                (element) => element === "prev"
              )
            ) {
              setRoute("/profile");
            } else {
              setRoute("/profile");
            }
          }

          setRender(true);
          notifyPopup();
        } else {
          setError(true);
          await handleSignUp();
          setErrmsg(data.message);
          setLoad(true);
        }

        async function handleSignUp() {
          await fetch(BASE_URL.BASE_URL + "/signup", {
            method: "POST",
            headers: { "content-Type": "application/json" },
            body: JSON.stringify({
              firstName: firstnames,
              lastName: lastnames,
              email: emails,
              password: tok,
              authMethod: authmethod,
              source: "signup normal form",
            }),
          })
            .then((response) => response.json())
            .then((data) => {
              ReactGA.event({
                category: "SignUP",
                type: "Email",
                name: "Email and Password sign up",
                action: "User signed up by Email and Password",
              });

              if (data.type == "success") {
                setRender(true);
                googleSignIn();
              } else {
                setError(true);
                setErrmsg(data.message);
                setLoad(false);
                // pwdRef.current.value = "";
              }
            });
        }
        setLoad(false);
        //pwdRef.current.value = "";
      });
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setLoad(true);
    await fetch(BASE_URL.BASE_URL + "/signin", {
      method: "POST",
      body: JSON.stringify({
        id: 3,
        email: email,
        password: password,
        isGoogle: false,
      }),
      headers: { "content-Type": "application/json" },
    })
      .then((response) => response.json())
      .then((data) => {
        ReactGA.event({
          category: "Sign IN",
          type: "Email and password",
          name: "Email and password sign in",
          action: "User signed in by Email and password",
        });

        if (Object.keys(data).find((element) => element === "token")) {
          localStorage.setItem("token", data.token);
          update_token(data.token);

          if (ref == "job-posting") {
            history.push("/jobs-for-freshers");
          } else {
            if (
              history.location.state != undefined &&
              Object.keys(history.location.state).find(
                (element) => element === "prev"
              )
            ) {
              setRoute("/profile");
            } else {
              setRoute("/profile");
            }
          }

          setRender(true);
          notifyPopup();
        } else {
          setError(true);
          setErrmsg(data.message);
          setLoad(false);
          pwdRef.current.value = "";
        }
      });
  }

  useEffect(() => {
    update_status(!open);
    setModal();
  }, [modal]);
  useLayoutEffect(() => {
    // window.scrollTo(0, 0);
  });
  return (
    <div style={{ overflowX: "hidden" }}>
      {/* <Navbar /> */}
      <HelmetComp
        info={{
          title: "SignIn Page",
          // description: '',
          // siteName: '',
          // summary: '',
          // pageUrl: '',
          // imagUrl: ''
        }}
      />
      <div className="my-5">
        <div>
          <div class="row ">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
              <div className="container d-flex justify-content-center">
                {matches == true && (
                  <lottie-player
                    src="https://assets8.lottiefiles.com/packages/lf20_9rfdfjek.json"
                    background="transparent"
                    speed="1"
                    // style="width: 300px; height: 300px;"
                    style={{ width: "500px", height: "500px" }}
                    loop
                    autoplay
                  ></lottie-player>
                )}
              </div>
            </div>
            {/* <div
              className={`col-xl-7 col-lg-7 col-md-7 col-sm-6`}
            >
                {text === false ? (
                  <h1 onClick={notifyPopup}>Lets Learn! </h1>
                ) : (
                  <h1>Log In to continue </h1>
                )}

                <p>
                  Not a member{" "}
                  <a
                    href="/signup"
                    style={{ textDecoration: "underline", color: "blue" }}
                  >
                    Sign Up
                  </a>
                </p>
            </div> */}
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-5 my-5">
              {matches == false && (
                <h5 className="d-flex justify-content-center">
                  Please Enter your Credentials
                </h5>
              )}
              <form
                className="shadow py-4 px-5"
                style={{ borderRadius: "20px", filter: "dropshadow" }}
                onSubmit={handleSubmit}
              >
                <label htmlFor="email">Email Address:</label>
                <div class="form-group">
                  <input
                    onChange={handleEmail}
                    type="email"
                    className={"form-control my-2 p-2 " + styles.inputnew}
                    id="email"
                    placeholder="Email"
                    name="email"
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="pwd">Password:</label>
                  <input
                    ref={pwdRef}
                    onChange={handlePassword}
                    type="password"
                    className={"form-control  my-2 p-2 " + styles.inputnew}
                    style={{ marginBottom: "0" }}
                    id="pwd"
                    placeholder="Enter password"
                    name="pswd"
                    required
                  />
                </div>
                <Link
                  to="/forgotpassword"
                  onClick={() => {
                    ReactGA.event({
                      category: "Forgot Password",
                      type: "Forgot Password",
                      name: "Forgot Password sign in",
                      action: "User Forgot Password",
                    });
                  }}
                  style={{
                    display: "flex",
                    flex: "row",
                    justifyContent: "flex-end",
                    fontSize: "13px",
                    marginBottom: "15px",
                  }}
                >
                  Forgot Password?
                </Link>
                {error === true ? (
                  <div className={`${styles.formgroup} form-check ml-0 pl-0`}>
                    <div className="form-check-label" htmlFor="exampleCheck1">
                      {errmsg}
                    </div>
                  </div>
                ) : null}

                {load === false ? (
                  <>
                    {" "}
                    <button
                      type="submit"
                      style={{ display: "block", backgroundColor: "#185adb" }}
                      className={
                        styles.registerbtn + "  " + styles.newbuttonstyle
                      }
                    >
                      Login
                    </button>
                    <p
                      style={{ color: "gray" }}
                      className={styles.horizontalh + " my-4"}
                    >
                      <span
                        style={{ backgroundColor: "#ffffff" }}
                        className={styles.horizontal}
                      >
                        {" "}
                        or
                      </span>
                    </p>
                    <div className="pl-5 pl-5" onClick={handleauthfirebase}>
                      <button
                        disabled={false}
                        style={{
                          width: matches ? "100%" : "100%",
                          display: "block",
                        }}
                        class={styles.googlebutton + " my-3"}
                      >
                        <span
                          style={{ right: matches ? "20px" : null }}
                          class={styles.googlebuttonicon}
                        >
                          <svg
                            viewBox="0 0 366 372"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M125.9 10.2c40.2-13.9 85.3-13.6 125.3 1.1 22.2 8.2 42.5 21 59.9 37.1-5.8 6.3-12.1 12.2-18.1 18.3l-34.2 34.2c-11.3-10.8-25.1-19-40.1-23.6-17.6-5.3-36.6-6.1-54.6-2.2-21 4.5-40.5 15.5-55.6 30.9-12.2 12.3-21.4 27.5-27 43.9-20.3-15.8-40.6-31.5-61-47.3 21.5-43 60.1-76.9 105.4-92.4z"
                              id="Shape"
                              fill="#EA4335"
                            />
                            <path
                              d="M20.6 102.4c20.3 15.8 40.6 31.5 61 47.3-8 23.3-8 49.2 0 72.4-20.3 15.8-40.6 31.6-60.9 47.3C1.9 232.7-3.8 189.6 4.4 149.2c3.3-16.2 8.7-32 16.2-46.8z"
                              id="Shape"
                              fill="#FBBC05"
                            />
                            <path
                              d="M361.7 151.1c5.8 32.7 4.5 66.8-4.7 98.8-8.5 29.3-24.6 56.5-47.1 77.2l-59.1-45.9c19.5-13.1 33.3-34.3 37.2-57.5H186.6c.1-24.2.1-48.4.1-72.6h175z"
                              id="Shape"
                              fill="#4285F4"
                            />
                            <path
                              d="M81.4 222.2c7.8 22.9 22.8 43.2 42.6 57.1 12.4 8.7 26.6 14.9 41.4 17.9 14.6 3 29.7 2.6 44.4.1 14.6-2.6 28.7-7.9 41-16.2l59.1 45.9c-21.3 19.7-48 33.1-76.2 39.6-31.2 7.1-64.2 7.3-95.2-1-24.6-6.5-47.7-18.2-67.6-34.1-20.9-16.6-38.3-38-50.4-62 20.3-15.7 40.6-31.5 60.9-47.3z"
                              fill="#34A853"
                            />
                          </svg>
                        </span>
                        <span
                          style={{ marginRight: matches ? "10%" : null }}
                          class={styles.googlebuttontext}
                        >
                          Log in/Sign up with Google
                        </span>
                      </button>
                    </div>
                    {/* <FacebookLogin
                      cssClass={ "btnFacebook my-3"}
                      appId="1832196643618709"
                      autoLoad={false}
                      fields="name,email,picture"
                      scope="public_profile,user_friends,email"
                      callback={responseFacebook}
                      icon={"facebooknewlogo"}
                        
                    /> */}
                    <h5
                      className={
                        "d-flex justify-content-center " +
                        styles.donthaveaccount
                      }
                    >
                      Don't have an account?{" "}
                      <Link
                        className={styles.signupunderlineNew}
                        to="/signup"
                        style={{
                          textDecoration: "underline",
                          marginLeft: "4px",
                        }}
                      >
                        {" "}
                        Sign up
                      </Link>{" "}
                    </h5>
                  </>
                ) : (
                  <button
                    className={styles.registerbtn}
                    type="button"
                    style={{ display: "block" }}
                    disabled
                  >
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span className="sr-only">Loading...</span>
                  </button>
                )}
                {render === true ? history.push(route) : null}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SignIn;

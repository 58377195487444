import React, {
  useState,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
} from "react";
import { ModalContext } from "../Context/ModalContext";
import { useHistory } from "react-router-dom";
import styles from "../Assets/css/signup.module.css";
// import VerifyEmail from "./verifyEmail";
import Navbar from "../components/Navbar";
import { BASE_URL } from "../env";
import firebase from "../firebase";
import useMedia from "../componentsofcourse/useMedia";
import HelmetComp from "../components/HelmetComp";
// import { iitBemail } from "./iitbombayemail.js";
import ReactGA from "react-ga";
import { AuthContext } from "../Context/AuthContext";
import { Store } from "react-notifications-component";

const SignUp = () => {
  var db = firebase.database();
  const history = useHistory();
  const format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
  let { open, update_status } = useContext(ModalContext);
  const queryParams = new URLSearchParams(window.location.search);
  const refferal = queryParams.get("ref");
  const [fullName, setFullName] = useState("");
  // const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [load, setLoad] = useState(false);
  const [passLength, setPassLength] = useState(false);
  const [specialChar, setSpecialChar] = useState(false);
  const [passDetails, setPassDetails] = useState(false);
  const [render, setRender] = useState(false);
  const [error, setError] = useState(false);
  const [errmsg, setErrmsg] = useState("");
  const [iitbombaystudent, setiitB] = useState(false);
  const pwdRef = useRef();
  const matches = useMedia("(min-width : 768px )");
  let { update_token, set_id, token, set_email, _id } = useContext(AuthContext);
  const [route, setRoute] = useState("");

  const notifyPopup = () => {
    Store.addNotification({
      title: "Success!",
      message: "You have been Logged in!",
      type: "success",
      background: "pink",
      insert: "bottom",
      container: "bottom-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true,
      },
    });
  };

  function handleFullName(event) {
    let e = event.target.value;
    setFullName(e);
  }
  // function handleLastName(event) {
  //   let e = event.target.value;
  //   setLastName(e);
  // }
  function handleEmail(event) {
    let e = event.target.value;
    setEmail(e);
  }

  function handlePassword(event) {
    let p = event.target.value;
    if ((p.length >= 8 && format.test(p)) || p.length == 0) {
      setSpecialChar(false);
      setPassDetails(false);
      setPassLength(false);
    } else if (p.length != 0 && p.length < 8 && !format.test(p)) {
      setPassDetails(true);
      setPassLength(false);
      setSpecialChar(false);
    } else if (format.test(p)) {
      setPassLength(true);
      setSpecialChar(false);
      setPassDetails(false);
    } else {
      setSpecialChar(true);
      setPassDetails(false);
      setPassLength(false);
    }

    setPassword(p);
  }

  async function handleSubmit(event) {
    event.preventDefault();
    const user_data = {
      fullName: fullName,
      // lastName: lastName,
      email: email,
      sending_company_email: "support@cantileverlabs.com",
      data_ref: "SignUp Page",
    };

    setLoad(true);

    await fetch(BASE_URL + "/signup", {
      method: "POST",
      body: JSON.stringify({
         firstName: fullName,
        // lastName: lastName,
        email: email,
        password: password,
        iitbombaystudent: iitbombaystudent,
        sending_company_email: "support@cantileverlabs.com",
        subject: "Please Verify your Email",
        source: "signup normal form",
      }),
      headers: { "content-Type": "application/json" },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.type == "success") {
          fetch(BASE_URL + "/signin", {
            method: "POST",
            body: JSON.stringify({
              id: 3,
              email: email,
              password: password,
              isGoogle: false,
            }),
            headers: { "content-Type": "application/json" },
          })
            .then((response) => response.json())
            .then((data) => {
              //  alert(refferal)
              if (refferal == "codingexamination") {
                localStorage.setItem("emailID", email);
                //  alert("hello")
                history.push("/codingexam/61a88d87eb10df7fa06b6131");
                return;
              } else {
                setTimeout(() => {
                  EnrollTheUser();
                }, 2200);
              }

              ReactGA.event({
                category: "Sign IN",
                type: "Email and password",
                name: "Email and password sign in",
                action: "User signed in by Email and password",
              });

              if (Object.keys(data).find((element) => element === "token")) {
                localStorage.setItem("token", data.token);
                update_token(data.token);

                if (
                  history.location.state != undefined &&
                  Object.keys(history.location.state).find(
                    (element) => element === "prev"
                  )
                ) {
                  setRoute(history.location.state.prev);
                } else {
                  setRoute("/");
                }

                setRender(true);

                notifyPopup();

                history.push("/");
              } else {
                setError(true);
                setErrmsg(data.message);
                setLoad(false);
                pwdRef.current.value = "";
              }
            });
          setRender(true);
          setRender(false);
          setLoad(false);
          db.ref("Users")
            .push(user_data)
            .then(() => {
              // console.log("Data Submitted");
            })
            .catch((err) => {
              // console.log(err);
            });

          // history.push("/signin");
        } else {
          setError(true);
          setErrmsg(data.message);
          setLoad(false);
          pwdRef.current.value = "";
        }
      });
  }

  function EnrollTheUser() {
    fetch(BASE_URL + "/enroll/617cfc556a877ad850df2c86", {
      headers: { authorization: "Bearer " + localStorage.getItem("token") },
      method: "POST",
    })
      .then((res) => res.json())
      .then((data) => {
        // history.push("/mycourse/617cfc556a877ad850df2c86/");

        console.log(data);
        // console.log(data);

        history.push("/profile");
        // alert("choosen");
        // console.log(data)
      });
  }
  const [modal, setModal] = useState(false);

  useEffect(() => {
    update_status(!open);
    setModal();
  }, [modal]);
  useLayoutEffect(() => {
    // window.scrollTo(0, 0);
  });

  return (
    <>
      {/* <Navbar /> */}

      <HelmetComp
        info={{
          title: "SignUp Page",
          // description: '',
          // siteName: '',
          // summary: '',
          // pageUrl: '',
          // imagUrl: ''
        }}
      />
      <div>
        <div
          className={`container-fluid ${styles.background}`}
          style={{ height: "100vh" }}
        >
          <br />
          <br />
          <br />

          <div class="row signup-form">
            <div
              class={`col-xl-7 col-lg-7 col-md-7 col-sm-6 ${styles.signupText}`}
            >
              <div class={styles.signupTextdiv}>
                <br />
                {matches == true && (
                  <lottie-player
                    src="https://assets8.lottiefiles.com/packages/lf20_9rfdfjek.json"
                    background="transparent"
                    speed="1"
                    // style="width: 300px; height: 300px;"
                    style={{ width: "500px", height: "500px" }}
                    loop
                    autoplay
                  ></lottie-player>
                )}
                <p>
                  Already a member{" "}
                  <a
                    href="/login"
                    style={{ textDecoration: "underline", color: "blue" }}
                  >
                    Log In
                  </a>{" "}
                </p>
              </div>
            </div>
            <div class="col-xl-3 col-lg-4 col-md-4 col-sm-5">
              <form class={styles.formcontainer} onSubmit={handleSubmit}>
                <div class={styles.formgroup}>
                  <input
                    onChange={handleFullName}
                    type="text"
                    class={styles.formcontrol}
                    id="full_name"
                    placeholder="Full Name"
                    name="name"
                    required
                  />
                </div>
                {/* <div class={styles.formgroup}>
                  <input
                    onChange={handleLastName}
                    type="text"
                    class={styles.formcontrol}
                    id="last_name"
                    placeholder="Last Name"
                    name="name"
                    required
                  />
                </div> */}
                <div class={styles.formgroup}>
                  <input
                    onChange={handleEmail}
                    type="email"
                    class={styles.formcontrol}
                    id="email"
                    placeholder="Email"
                    name="email"
                    required
                  />
                </div>
                <div class={styles.formgroup}>
                  <input
                    ref={pwdRef}
                    onChange={handlePassword}
                    type="password"
                    class={styles.formcontrol}
                    id="pwd"
                    placeholder="Enter password"
                    name="pswd"
                    required
                  />
                </div>
                {passDetails === true ? (
                  <p style={{ color: "red" }}>
                    Password must be 8 digits long and contain one special
                    character
                  </p>
                ) : null}
                {passLength === true ? (
                  <p style={{ color: "red" }}>Password must be 8 digits long</p>
                ) : null}
                {specialChar === true ? (
                  <p style={{ color: "red" }}>
                    Password must contain atleast one special character
                  </p>
                ) : null}
                {error === true ? (
                  <div className={`${styles.formgroup} form-check ml-0 pl-0`}>
                    <div className="form-check-label" htmlFor="exampleCheck1">
                      {errmsg}
                    </div>
                  </div>
                ) : null}
                <div>
                  By clicking Register you agree to our{" "}
                  <a
                    href="/policies"
                    style={{ textDecoration: "underline", color: "blue" }}
                  >
                    Policy Terms
                  </a>
                </div>

                {load === false ? (
                  <button type="submit" class={styles.registerbtn}>
                    Sign Up
                  </button>
                ) : (
                  <button className={styles.registerbtn} type="submit" disabled>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span className="sr-only">Loading...</span>
                  </button>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SignUp;

import React from "react";
import style from "../../Assets/css/b2bTraining.module.css";
const BookSessions = () => {
  return (
    <>
      <div className="container-fluid p-5 ">
        <div className="container ">
          <div className="row ">
            <div className="col-lg-9 col-md-9 col-sm-12 mx-auto">
              <h3 style={{ fontWeight: "bolder" }}>
                Book <span className="text-warning">3 </span> Free <br />
                Training Sessions.
              </h3>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 mx-auto">
              <div className={`${style.btn_div} `} id="bookNow">
                <a href="#training">
                <button className={`btn p-2 ${style.black_btn} mr-5 `}>
                  Book Now
                  </button>
                  </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BookSessions;

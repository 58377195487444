import React, { useState, useEffect } from "react";
import clock from "../Assets/img/Masterclass/Icon ionic-md-time-white.png";
import calendar from "../Assets/img/Masterclass/Icon material-date-range-white.png";
import linkedinButton from "../Assets/img/Masterclass/Component 1.png";
import profileImg from "../Assets/img/Masterclass/Rectangle 1011.png";
import videoImg from "../Assets/img/Masterclass/Rectangle 1040.png";
import masterBanner from "../Assets/img/Masterclass/blogs.png";
import clockB from "../Assets/img/Masterclass/Icon ionic-md-time.png";
import calendarB from "../Assets/img/Masterclass/Icon material-today-black.png";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import styles from "../Assets/css/masterclasspage.module.css";
import styles1 from "../Assets/css/masterclass.module.css";
import Navbar from "../components/Navbar";
import { useParams } from "react-router-dom";
import { BASE_URL } from "../env";
import moment from "moment";
import { Link } from "react-router-dom";

function MasterClassPage() {
  let loc = useLocation();
  // console.log(loc.pathname.split("/masterclasspage/"));
  const webinarID = loc.pathname.split("/masterclasspage/")[1];
  // console.log(webinarID);
  const { webinar_id } = useParams();
  const history = useHistory();

  const [webinarData, setWebinarData] = useState([]);
  const [UpComingwebinarData, setUpComingwebinarData] = useState([]);
  useEffect(() => {
    fetch(BASE_URL + "/webinars/getAllWebinar", {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log("WEbinar Data", data);
        setUpComingwebinarData(data.response);
      });
  }, []);

  useEffect(() => {
    fetch(BASE_URL + "/webinars/getAllSingleWebinar/" + webinar_id, {
      method: "GET",
      headers: { "Content-Type": "application/json", webinar_id: webinar_id },
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log("WEbinar Data", data.response);
        setWebinarData(data.response);
      });
  }, []);

  return (
    <div>
      {/* <Navbar /> */}

      {webinarData?.map((item) => {
        return (
          <>
            <div class={`container-fluid ${styles.blogTop}`}>
              <h1 class={styles.borderTopH1}>{item.subject}</h1>
              <h2>
                A <i>Free Masterclass</i> By{" "}
                <span class={styles.nameSpan}>{item.speaker}</span>
              </h2>
              <hr class={styles.blog1Hr} />
              <div class={styles.dateRangeIconBlog}>
                <img src={calendar} alt="" class="img-fluid" />
                {moment(item.startTime).format("DD") +
                  " " +
                  moment(item.startTime).format("MMMM") +
                  " " +
                  moment(item.startTime).format("YYYY")}
              </div>
              <div class={styles.timeIconBlog}>
                <img src={clock} alt="" class="img-fluid" />
                {moment(item.startTime).format("hh:mm A") +
                  " - " +
                  moment(item.endTime).format("hh:mm A")}
              </div>
            </div>
            <br />
            <br />
            <div class={`container-fluid ${styles.blog1Div2}`}>
              <div class="row">
                <div
                  class={`col-xl-8 col-lg-8 col-md-8 col-sm-8 col-11 ${styles.blog1Div02}`}
                >
                  <h2 class={styles.blog1Div2H2}>{item.subject}</h2>
                  <span class={styles.boldData1}>Date:</span>{" "}
                  {moment(item.startTime).format("DD") +
                    " " +
                    moment(item.startTime).format("MMMM") +
                    " " +
                    moment(item.startTime).format("YYYY")}
                  <span class={styles.boldData}>Time:</span>{" "}
                  {moment(item.startTime).format("hh:mm A")}
                  <span class={styles.boldData}>Duration:</span>
                  <span class={styles.boldData}>Price:</span> Free
                  <span class={styles.boldData}>Venue:</span> Online
                </div>
                <div
                  class={`col-xl-3 col-lg-3 col-md-3 col-sm-3 col-11 ${styles.blog1Div2Right}`}
                >
                  <div class={styles.registerBtnDiv}>
                    <div>
                      <button
                        class={styles.registerBtn}
                        onClick={() => {
                          history.push(`/masterclassform/${webinarID}`);
                        }}
                      >
                        <b>REGISTER</b>
                      </button>
                    </div>
                    {/* <div class={styles.boldData}> 5673 have Enrolled</div> */}
                  </div>
                </div>
              </div>
            </div>
            <br />
            <br />
            <div class={`container-fluid ${styles.blog1Div3}`}>
              <div class="row">
                <div class="col-xl-6 col-11">
                  {item.about_the_webinar == "" ? (
                    ""
                  ) : (
                    <>
                      <h4>About the Master Class</h4>
                      <hr class={styles.blog1Hr2} />
                      <br />
                      <p>{item.about_the_webinar}</p>
                    </>
                  )}

                  {/* <p>
                    {" "}
                    <span class={styles.boldData1}>
                      Things you will take with you:
                    </span>{" "}
                    <br />
                    <ul className={styles.listData}>
                      <li>
                        Overview of the topic- On how to pace up your career,
                        disregarding your position in your professional front.
                      </li>
                      <li>
                        Information related to the domain, that everyone should
                        follow in order to climb the ladder of corporate
                        success!
                      </li>
                      <li>
                        Tips on how to improve themselves and scale up their
                        professional proforma.
                      </li>
                      <li>
                        Strategies to stand ahead of others and be the leader
                        you desire to be!
                      </li>
                    </ul>
                  </p> */}

                  <p>
                    {" "}
                    <span class={styles.boldData1}>
                      About Cantilever Lab:
                    </span>{" "}
                    <br />
                    At Cantilever Labs, we are dedicated to help our candidates
                    achieve their success with whatever means it takes, starting
                    from developing their programming skills from scratch to
                    preparing them for their final interviews by providing
                    one-to-one interview rounds,strong resume building kits and
                    many more. We also provide the best of experiential learning
                    ambience with extensive hours of teaching, hands-on
                    experience with practical exercises along with technical
                    training and mock interviews with HR’s from top companies
                    like Google, Microsoft, Amazon etc for a complete
                    preparation. Lastly, we give 100% placement assurance, in
                    any desired company.{" "}
                  </p>

                  <p>
                    {" "}
                    <span class={styles.boldData1}>
                      Our achievements so far:
                    </span>{" "}
                    <br />
                    <ul className={styles.listData}>
                      <li>
                        Provided placement guidance to top 10/11 highest earning
                        students in India.
                      </li>
                      <li>
                        Trained 24k+ job aspirants from IIT Bombay, IIT Madras,
                        JNTU, SNIST, GITAM last year.
                      </li>
                      <li>
                        7 out of 8 students from SNIST at Amazon are the
                        students placed by Cantilever Labs.
                      </li>
                      <li>
                        120+ students got placed in FAANG companies through our
                        placement preparation.
                      </li>
                    </ul>{" "}
                  </p>

                  {/* <p>
              {" "}
              <span class={styles.boldData1}>FAQ:</span> <br />{" "}
            </p> */}
                </div>
                <div class="col-xl-5 col-11">
                  <div class={`card ${styles.blogpostCard}`}>
                    <div class="d-flex">
                      <div>
                        <img
                          src={profileImg}
                          alt=""
                          class={`img-fluid ${styles.profileImgBlog}`}
                        />
                      </div>
                      {item.about_the_speaker == "" ? (
                        ""
                      ) : (
                        <div>
                          {" "}
                          {/* Name- He/She has (this many) years of experience in the
                        (domain) field. He/She is currently working as
                        (designation) at (name of the organization). Previously
                        he/she has worked at (name of the organization) as
                        (role). And has been an immense support and an asset in
                        the field. Name - Collaborated with Cantilever Lab to
                        give you this amazing Masterclass on (domain). Some of
                        the notable his/her employers include (list of
                        organization) */}
                          {item.about_the_speaker == "Nan"
                            ? ""
                            : item.about_the_speaker}
                          <br />
                          <span class={styles.boldData1}> {item.speaker}</span>
                          <br />
                          <a
                            class={styles.linkedinImg}
                            href={item.speaker_linkedin_profile}
                          >
                            <img src={linkedinButton} alt="" />
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                  <br />
                  <div>
                    <img src={item.image} alt="" class="img-fluid" />
                  </div>
                </div>
              </div>
            </div>
            <br />
            <br />
            <div class={`container-fluid ${styles.blueSectionDiv}`}>
              <div class="row">
                <div
                  class={`col-xl-11 col-lg-11 col-md-11 col-sm-11 col-11 ${styles.blueSection}`}
                ></div>
              </div>
            </div>
            <br />
            <br />
            <div class={`container-fluid ${styles.blog1Div3}`}>
              <div class="row">
                <div class="col-xl-11 xol-lg-11 col-md-11 col-sm-11">
                  <h4>Notes</h4>
                  <hr class={styles.blog1Hr3} />
                  <br />
                  <p>
                    <ul className={styles.listData}>
                      <li>
                        We promise to give you a brainstorming session. Hence,
                        we expect you to be attentive and interactive during the
                        entire session.
                      </li>
                      <li>
                        Please keep a pen and notebook while joining the
                        session, to take your important notes.
                      </li>
                    </ul>
                  </p>
                  <p>
                    {" "}
                    <span class={styles.boldData1}>Link:</span> <br />
                    <ul className={styles.listData}>
                      <li>
                        We are eagerly waiting to see on (date) at (time).
                      </li>
                      <br />
                      <li>Join us: (link to attend the session).</li>
                    </ul>
                  </p>

                  <p>
                    {" "}
                    <span class={styles.boldData1}>FAQ:</span> <br />
                    <ol className={styles.listData}>
                      <li>
                        <p>
                          What is the Masterclass?
                          <br />
                          The Masterclass is the online session organised by
                          Cantilever Labs from time to time, that is designed
                          specifically to provide you exclusive knowledge within
                          the encapsulated time
                        </p>
                      </li>
                      <br />
                      <li>
                        <p>
                          What are the advantages of attaining the Masterclass?
                          <br />
                          You will receive extensive domain knowledge along with
                          amazing vouchers and gifts that are released after the
                          sessions. Foremost, you will get to interact with our
                          speakers directly and learn from their experience.
                        </p>
                      </li>
                      <br />
                      <li>
                        <p>
                          What is the fee of joining the MasterClass?
                          <br />
                          Our Masterclass is absolutely free and anyone can be a
                          part of it.
                        </p>
                      </li>
                      <br />
                      <li>
                        <p>
                          What if we missed the sessions?
                          <br />
                          We would love to have you in our live class, yet in
                          case you couldn’t join our Masterclass, don’t worry
                          you can find the recordings on our website.
                        </p>
                      </li>
                      <br />
                    </ol>{" "}
                  </p>

                  {/* <p>
              {" "}
              <span class={styles.boldData1}>Links:</span> <br />
              but also the leap into electronic typesetting, remaining
              essentially unchanged. It was popularised in the 1960s with the
              release of Letraset sheets containing Lorem Ipsum passages, and
              more recently with desktop publishing software like Aldus
              PageMaker including versions of Lorem Ipsum.{" "}
            </p>

            <p>
              {" "}
              <span class={styles.boldData1}>FAQ:</span> <br />{" "}
            </p> */}
                </div>
              </div>
            </div>
          </>
        );
      })}

      {/* ---------------------------------UPCOMING CLASS------------------------------------------------------------------------------------------------- */}
      <div style={{ background: " rgba(81, 153, 251, 0.19)" }}>
        <div
          style={{ paddingTop: "5%", paddingLeft: "5%" }}
          class={`d-flex mx-5 my-4 ${styles.upcomingJ}`}
        >
          <h2>Upcoming Classes</h2>
          <Link to={"/masterclass"}>
            <button class="btn mx-5">More</button>
          </Link>
        </div>
        <div className="row justify-content-center">
          {UpComingwebinarData.map((item) => {
            return (
              <>
                <div
                  style={{
                    paddingRight: "2%",
                    paddingTop: "3%",
                    paddingBottom: "3%",
                    height: "fit-content",
                  }}
                  className={`col-xl-5 col-lg-5 col-md-7 mx-4 my-2 `}
                >
                  {" "}
                  <div className={`card ${styles1.cardsArchivedJ}`}>
                    <img
                      style={{
                        height: "40%",
                        width: "100%",
                        borderRadius: "10px 10px 0px 0px",
                      }}
                      src={item.image}
                      alt="Card image cap"
                    />
                    <div className={styles1.cardBody}>
                      <h5 className="card-title">{item.subject}</h5>
                      <p style={{ width: "60%" }} className="card-text">
                        {item.description}
                      </p>

                      <div className={`d-flex ${styles1.archivedButtonJ}`}>
                        <div className={`d-flex ${styles1.archivedIconJ} mt-4`}>
                          <div style={{ marginRight: "1.5vw" }}>
                            <p className="cal-J">
                              <img src={calendarB} alt="" />
                              {moment(item.startTime).format("DD") +
                                " " +
                                moment(item.startTime).format("MMMM") +
                                " " +
                                moment(item.startTime).format("YYYY")}
                            </p>
                          </div>
                          <div className="mr-5">
                            <p className="clock-J">
                              <img src={clockB} alt="" />
                              {moment(item.startTime).format("hh:mm A") +
                                " - " +
                                moment(item.endTime).format("hh:mm A")}
                            </p>
                          </div>
                        </div>

                        <div className=" mt-4">
                          <div className="mb-2">
                            <Link
                              to={"/masterclasses-placement/" + item.webinar_id}
                              className={`${styles.btn} ${styles.btnLight} shadow `}
                            >
                              REGISTER
                            </Link>
                          </div>
                          {/* <p
                  style={{
                    textAlign: "center",
                    fontSize: " 10px",
                    fontFamily: "Poppins-Black",
                  }}
                >
                  5673 have Enrolled
                </p> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default MasterClassPage;

import React from "react";
import DownloadFileBtn from "./DownloadFileBtn";

const ChartDetails = ({ name, green, yellow, red, link, noData, displayDownloadBtn }) => {
  return (
    <>
      {!noData ? (
        <div className="chartDetails">
          <ul className="chartList">
            <li className="chartItem">
              <div
                className="color"
                style={{ backgroundColor: "#FF5858" }}
              ></div>
              Total 00-50%<span>{red}</span>Students
            </li>
            <li className="chartItem">
              <div
                className="color"
                style={{ backgroundColor: "#FFB636" }}
              ></div>
              Total 50-75%<span>{yellow}</span>Students
            </li>
            <li className="chartItem">
              <div
                className="color"
                style={{ backgroundColor: "#24B47E" }}
              ></div>
              Total 75-100%<span>{green}</span>Students
            </li>
          </ul>
          {displayDownloadBtn ?  <DownloadFileBtn name={name} link={link}   /> : ''}
        </div>
      ): (
        <div>No Data to display</div>
      )}
    </>
  );
};

ChartDetails.defaultProps = {
  link: "",
  noData: false,
  displayDownloadBtn: true
};

export default ChartDetails;

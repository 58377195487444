import { useEffect, useState, useContext, useRef } from "react";
import { useParams, useHistory } from "react-router-dom";
import styles from "../Assets/css/quizz.module.css";
// import { BASE_URL } from "../env";
import configAxios from "../axios.config";
import { useTimer } from "react-timer-hook";
import { AuthContext } from "../Context/AuthContext";

import "react-notifications-component/dist/theme.css";
import { Store } from "react-notifications-component";
import "../Assets/css/quizznew.css";
import logo from "../Assets/img/cantilever-logo.png";
import moment from "moment";
// import { AnimatePresence, motion } from "framer-motion";
// import axios from "axios";
const QuizzQuestions = ({ expiryTimestamp, hidenavbar }) => {
  const { examId } = useParams();
  const [examArray, setExamsArray] = useState({});
  const [questionsArray, setQuestionsArray] = useState([]);
  const [index, setIndex] = useState(0);
  const [email, setEmail] = useState("");
  const [userId, setUserId] = useState("");
  const [userName, setuserName] = useState("");
  const [userDP, setuserDP] = useState("");
  const [scoreArray, setScoreArray] = useState([]);
  const [questions_Attempted, setQuestionsAttempted] = useState([]);
  const [unquestions_Attempted, setUnQuestionsAttempted] = useState([]);
  const [questions_review, setQuestionsReview] = useState([]);
  const authContextData = useContext(AuthContext);
  const [selectedId, setselectedId] = useState(null);
  const { token } = authContextData;
  const [showpopup, setshowpopup] = useState(false);
  const [show, setshow] = useState(false);
  const [MultipleChoiceArray, setMultipleChoiceArray] = useState([]);
  const [numberofTabs, setnumberofTabs] = useState(0);
  const [spinner, setSpinner] = useState(false);

  const questionWeight = useRef(1);
  const typeOfExam = useRef("");
  // const [timer, setTimer] = useState("");
  const variants = {
    open: { opacity: 1, x: 0 },
    closed: { opacity: 0, x: "-100%" },
  };
  const history = useHistory();

  useEffect(() => {
    hidenavbar(true);
    return () => {
      hidenavbar(false);
    };
  }, [hidenavbar]);

  useEffect(() => {
    window.addEventListener("beforeunload", alertUser);
    window.addEventListener("unload", handleEndConcert);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
      window.removeEventListener("unload", handleEndConcert);
      handleEndConcert();
    };
  }, []);

  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
    // setprompt(true);
  };

  const NotificationPopup = ({ title, message }) => {
    Store.addNotification({
      title,
      message,
      type: "danger",
      background: "pink",
      insert: "bottom",
      container: "bottom-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true,
      },
    });
  };

  // useEffect(() => {
  //   if (!examId) return;
  //   configAxios(localStorage.getItem("token"))
  //     .get(
  //       `/checkNumberOfAttemptsLeft/${examId}/${localStorage.getItem(
  //         "emailID"
  //       )}`
  //     )
  //     .then((data) => {
  //       if (!data.exam) {
  //       }
  //     })
  //     .catch((err) => {
  //       switch (err.response.status) {
  //         case 401:
  //           history.push("/quizexamsubmitted/" + examId);
  //           NotificationPopup({
  //             title: "Limit exceeded",
  //             message: "you have exhausted the number of attempts",
  //           });
  //           return;
  //         default:
  //           return;
  //       }
  //     });
  // }, [examId, history]);

  window.onload = function () {
    var reloading = sessionStorage.getItem("reloading");
    if (reloading) {
      sessionStorage.removeItem("reloading");
      // myFunction();
      submithandler();
    }
  };

  const handleEndConcert = async (e) => {
    sessionStorage.setItem("reloading", "true");
    // console.log(e);
  };

  useEffect(() => {
    configAxios(localStorage.getItem("token"))
      .get("/getQuizPractiseExamById/" + examId)
      .then(({ data }) => {
        console.log(data);
        typeOfExam.current = data.data.typeOfExam;
        setExamsArray(data.data);
        setQuestionsArray(shuffleArray(data.data.questions));
        setUnQuestionsAttempted(data.data.questions);
        var travelTime = moment().add(data.data.timer, "minutes").unix();
        localStorage.setItem("timer" + examId, travelTime);
        startTimer(data.data.timer);
        if (data.data.questionWeight) {
          questionWeight.current = data.data.questionWeight;
        }
      });

    configAxios(localStorage.getItem("token"))
      .get("/getProfile")
      .then(({ data }) => {
        setEmail(data?.user?.email);
        setUserId(data?.user?._id);
        setuserDP(data?.user?.profile_pic);
        setuserName(data?.user?.firstName + " " + data?.user?.lastName);
      })
      .catch((err) => {
        switch (err.response.status) {
          case 401:
            history.push("/login");
            NotificationPopup({
              title: "Unauthorized",
              message: "Please Login to attempt the exam",
            });
            return;
          default:
            return;
        }
      });
  }, []);

  const questions = questionsArray[index];
  const length = questionsArray.length;

  function shuffleArray(array) {
    for (var i = array.length - 1; i > 0; i--) {
      var j = Math.floor(Math.random() * (i + 1));
      var temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    return array;
  }

  const { seconds, minutes, hours, restart } = useTimer({
    expiryTimestamp,
    onExpire: () => {
      submithandler();
    },
  });
  const [timeSpent, settimeSpent] = useState("");
  const startTimer = (timer) => {
    const time = new Date();
    var travelTime_new = moment().unix();
    const newtimer = localStorage.getItem("timer" + examId) - travelTime_new;
    const minutes_timer = newtimer / 60;
    settimeSpent(Math.ceil(timer - minutes_timer));
    time.setSeconds(time.getSeconds() + 60 * minutes_timer);
    restart(time);
  };

  function Check(questionNos, options) {
    // console.log(questionNos,options)
    for (var singleValue of scoreArray) {
      if (
        singleValue.questionNo === questionNos &&
        singleValue.option === options
      ) {
        // console.log("true")
        return true;
      }
    }

    return false;
  }
  // const checkExistMutiple = (options) => {
  //   for (var singleValue of MultipleChoiceArray) {
  //     if (options == singleValue) {
  //       // console.log("true")
  //       return true;
  //     }
  //   }

  //   return false;
  // };
  const checkMultipleChoices = async (e, correctAnswer, questionId, option) => {
    if (questions_Attempted.includes(questionId)) {
    } else {
      setQuestionsAttempted([...questions_Attempted, questionId]);
    }
    if (MultipleChoiceArray.includes(option)) {
      setMultipleChoiceArray([
        MultipleChoiceArray.filter((val) => {
          return val != option;
        }),
      ]);
    } else {
      setMultipleChoiceArray([...MultipleChoiceArray, option]);
    }

    // console.log("MultipleChoiceArray", MultipleChoiceArray);

    if (correctAnswer.includes(option)) {
      // console.log("hello")
      const newarray = scoreArray.filter((value) => {
        return value.questionId !== questionId;
      });
      // logic for created the score object
      const newData = [
        ...newarray,
        {
          questionNo: currQues,
          score: MultipleChoiceArray.length / correctAnswer.length,
          questionId: questionId,
          correct: true,
          option,
        },
      ];
      setScoreArray(newData);
    } else {
      // console.log("ye chalra");
      const newarray = scoreArray.filter((value) => {
        return value.questionId !== questionId;
      });
      // logic for created the score object
      const newData = [
        ...newarray,
        {
          score: 0,
          questionId: questionId,
          correct: false,
          option,
          questionNo: currQues,
        },
      ];
      setScoreArray(newData);
    }
  };
  async function changeclass(e, correctAnswer, questionId, option) {
    e.preventDefault();
    setshow(!show);
    // console.log("change class")
    setselectedId(option);
    if (questions_Attempted.includes(questionId)) {
    } else {
      setQuestionsAttempted([...questions_Attempted, questionId]);
    }
    // console.log(correctAnswer)
    // console.log(option)
    correctAnswer.map((item) => {
      if (item == option) {
        // console.log("hello")
        const newarray = scoreArray.filter((value) => {
          return value.questionId !== questionId;
        });
        // logic for created the score object
        const newData = [
          ...newarray,
          {
            questionNo: currQues,
            score: 1,
            questionId: questionId,
            correct: true,
            option,
          },
        ];
        setScoreArray(newData);
      } else {
        // console.log("ye chalra");
        const newarray = scoreArray.filter((value) => {
          return value.questionId !== questionId;
        });
        // logic for created the score object
        const newData = [
          ...newarray,
          {
            score: 0,
            questionId: questionId,
            correct: false,
            option,
            questionNo: currQues,
          },
        ];
        setScoreArray(newData);
      }
    });
  }
  const addToReview = (_id) => {
    // console.log("questions_review", questions_review);

    if (questions_review.includes(_id)) {
      // console.log("questions_review", questions_review);

      return;
    } else {
      setQuestionsReview([...questions_review, _id]);
    }
  };

  const checkRegex = (ans) => {
    var urlRegex =
      /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
    if (urlRegex.test(ans)) {
      return true;
    } else {
      return false;
    }
  };

  const submithandler = () => {
    setSpinner(true);
    localStorage.removeItem("timer" + examId);
    let finalScore = 0;
    for (let i = 0; i < scoreArray.length; i++) {
      finalScore += scoreArray[i].score;
    }
    const submitData = {
      email: email,
      user: userId,
      questions_Attempted: questions_Attempted,
      examId: examId,
      score: scoreArray,
      review: questions_review,
      tabsopened: numberofTabs,
      finalScore,
      typeOfExam: typeOfExam.current
    };
    // console.log("Response data", submitData);
    console.log(typeOfExam.current);
    // configAxios(localStorage.getItem("token"))
    //   .post("/makeResult", submitData)
    //   .then(({ data }) => {
    //     history.push(`/quizexamsubmitted/${examId}`, {
    //       data,
    //     });
    //   })
    //   .catch(({ err }) => console.error(err))
    //   .finally(() => setSpinner(false));

    history.push("/profile");

    // fetch(BASE_URL + "/makeResult", {
    //   method: "POST",
    //   headers: { "Content-Type": "application/json" },
    //   body: JSON.stringify(submitData),
    // })
    //   .then((res) => res.json())
    //   .then((response) => {
    //     history.push(`/quizexamsubmitted/${examId}`);
    //   });
  };

  const [currQues, setcurrQues] = useState(1);
  const [ShowMenu, setShowMenu] = useState(true);

  return (
    <div id="main">
      {showpopup === true && (
        <div
          className={styles.popup_submission}
          style={{ backdropFilter: "blur(10px)" }}
        >
          <div className="d-flex flex-column">
            <span className={styles.popup_text}>
              are you sure that you want to submit your Exam?
            </span>
            <div className="d-flex flex-row justify-content-start">
              <button
                onClick={() => {
                  setshowpopup(false);
                }}
                className={styles.submission_quiz_button}
                style={{
                  background: "transparent",
                  border: "1px solid white",
                  color: "#fff",
                }}
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  submithandler();
                }}
                className={styles.submission_quiz_button}
              >
                {spinner ? (
                  <div className="spinner-border spinner-border-sm"></div>
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </div>
        </div>
      )}
      <div style={{ filter: showpopup ? "blur(10px)" : null }}>
        {showpopup == true && (
          <div
            className={styles.popup_submission}
            style={{ backdropFilter: "blur(10px)" }}
          >
            <div className="d-flex flex-column">
              <span className={styles.popup_text}>
                Are you sure, you want to submit your Exam?
              </span>
              <div className="d-flex flex-row justify-content-start">
                <button
                  onClick={() => {
                    setshowpopup(false);
                  }}
                  className={styles.submission_quiz_button}
                  style={{
                    background: "transparent",
                    border: "1px solid white",
                    color: "#fff",
                  }}
                >
                  No
                </button>
                <button
                  onClick={() => {
                    submithandler();
                  }}
                  className={styles.submission_quiz_button}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        )}
        <div style={{ filter: showpopup ? "blur(10px)" : null }}>
          {token != null ? (
            <>
              <div className={styles.quizPageNavbar}>
                <img src={logo} className={styles.quizPageLogo} alt="logo" />
                <div className={styles.quizPageNavbarRight}>
                  <div className={styles.quizPageProfileDetail}>
                    <img src={userDP} className={styles.quizPageProfilePic} />
                    <span> {userName}</span>
                  </div>
                  <div className={styles.quizPageTimer}>
                    {hours}:{minutes}:{seconds}
                  </div>
                </div>
              </div>
              <div className={styles.quizQuestionPage}>
                <div className="row gx-lg-5 gx-3">
                  <div className="col-lg-8 col-md-7 mb-0">
                    <div className={styles.quizQuestionPageLeft}>
                      <div className="row">
                        <div className="col-12 mb-0">
                          <div className={styles.quizQuestionPageQuestionNum}>
                            Question No. {currQues}
                          </div>
                          <hr />
                          <div style={{ marginBottom: "30px" }}>
                            <div className={styles.quizQuestionPageQuestion}>
                              {questions?.question}
                            </div>
                            {checkRegex(questions?.questionPic) ? (
                              <img
                                src={questions?.questionPic}
                                alt="question"
                                className={`img-fluid ${styles.quizQuestionPageQuestionPic}`}
                              />
                            ) : null}
                          </div>
                          {questions?.answers?.map((option, index) => {
                            return (
                              <>
                                {questions.answerSelectionType ===
                                  "single choice" && (
                                  <div
                                    key={index}
                                    className={
                                      Check(currQues, option)
                                        ? styles.quizPageOptionSelcted
                                        : styles.quizPageOption
                                    }
                                    onClick={(e) => {
                                      changeclass(
                                        e,
                                        questions.correctAnswer,
                                        questions._id,
                                        option
                                      );
                                    }}
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <input
                                      className={styles.quizPageOptionRadio}
                                      type="radio"
                                      value={option}
                                      name="options"
                                      id={index.toString()}
                                      checked={Check(currQues, option)}
                                    />
                                    <label
                                      htmlFor={index.toString()}
                                      style={{
                                        cursor: "pointer",
                                      }}
                                    >
                                      {checkRegex(option) ? (
                                        <img
                                          className="img-fluid"
                                          src={option}
                                          alt={option}
                                          style={{
                                            height: "100px",
                                          }}
                                        />
                                      ) : (
                                        option
                                      )}
                                    </label>
                                  </div>
                                )}
                              </>
                            );
                          })}
                        </div>
                      </div>
                      <div className="row mt-5">
                        <div className="col-12 d-sm-flex justify-content-between">
                          {questions_Attempted?.includes(questions?._id) ? (
                            <button
                              onClick={() => {
                                addToReview(questions._id);
                              }}
                              className={styles.quizPageRevBtn}
                            >
                              Review later
                            </button>
                          ) : (
                            <button className={styles.quizPageRevBtn}>
                              Review later
                            </button>
                          )}
                          <div className="rightside">
                            {index == 0 ? null : (
                              <button
                                onClick={(e) => {
                                  if (index == 0) {
                                  } else {
                                    setIndex(index - 1);
                                    setselectedId(null);
                                    setcurrQues(currQues - 1);
                                    setMultipleChoiceArray([]);
                                  }
                                }}
                                className={styles.quizPagePrevBtn}
                              >
                                <i class="fas fa-arrow-left"></i> Prev
                              </button>
                            )}
                            {index == length - 1 ? null : (
                              <button
                                onClick={(e) => {
                                  if (index == length - 1) {
                                  } else {
                                    setIndex(index + 1);
                                    setselectedId(null);
                                    setcurrQues(currQues + 1);
                                    setMultipleChoiceArray([]);
                                  }
                                }}
                                className={styles.quizPageNextBtn}
                              >
                                Next <i class="fas fa-arrow-right"></i>
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-5 mb-0 mt-md-0 mt-sm-3 mt-0">
                    <div className={styles.quizQuestionPageRight}>
                      <div className="content">
                        <div className={styles.quizQuestionPageQuizName}>
                          {examArray.examName}
                        </div>
                        <hr />
                        <ul className={styles.quizPageRightList}>
                          <li>
                            <span>
                              <span style={{ backgroundColor: "#E98D15" }}>
                                {unquestions_Attempted.length}
                              </span>
                            </span>
                            Total Questions
                          </li>
                          <li>
                            <span>
                              <span style={{ backgroundColor: "#24B47E" }}>
                                {questions_Attempted.length}
                              </span>
                            </span>
                            Answered Questions
                          </li>
                          <li>
                            <span>
                              <span style={{ backgroundColor: "#3E006E" }}>
                                {questions_review.length}
                              </span>
                            </span>
                            Questions to be Reviewed
                          </li>
                          <li>
                            <span>
                              <span>
                                {unquestions_Attempted.length -
                                  questions_Attempted.length}
                              </span>
                            </span>
                            Un-Answered Questions
                          </li>
                        </ul>
                        <div className={styles.quizQuestionPageQuizName}>
                          All Questions
                        </div>
                        <hr />
                        <div className="d-flex flex-wrap">
                          {questionsArray.map((item, pos) => {
                            return (
                              <>
                                <div
                                  style={{
                                    width: "30px",
                                    height: "30px",
                                    border: "1px solid #BFBFBF",
                                    padding: "3px 2px",
                                    margin: "4px 5px",
                                    borderRadius: "4px",
                                    cursor: "pointer",
                                    textAlign: "center",
                                  }}
                                  className={
                                    questions_review.includes(item._id)
                                      ? styles.reviewed
                                      : questions_Attempted.includes(item._id)
                                      ? styles.answered
                                      : questions_Attempted.includes(
                                          item._id
                                        ) == true &&
                                        questions_review.includes(item._id) ==
                                          true
                                      ? styles.both
                                      : "bg-#133d6a border border-light"
                                  }
                                  onClick={() => {
                                    setIndex(--pos);
                                    setcurrQues(++pos);
                                  }}
                                >
                                  {++pos}
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </div>
                      <div className="d-flex justify-content-end">
                        <button
                          className={styles.quizPageExamSubmitBtn}
                          onClick={() => {
                            setshowpopup(true);
                          }}
                        >
                          Submit Exam
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            history.push("/")
          )}
        </div>
      </div>
    </div>
  );
};

export default QuizzQuestions;

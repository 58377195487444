import React, { useEffect, useState, useRef, useContext } from "react";
import Footer from "../components/Footer";
import discord_png from "../Assets/img/discord_png.gif";
import Allexam from "./Allexam";
import style from "../Assets/css/mycourse.module.css";
import KnowledgeReferences from "./KnowledgeRefrences";
import MyCourseReportCard from "./MyCourseReportCard";
import PracticeSection from "./PracticeSection";
import { useHistory } from "react-router-dom";
import { InlineWidget } from "react-calendly";
import "../Assets/css/buttonBorder.css";
import References from "./References";
import TempPopup from "./TempPopup";
import firebase from "../firebase";
import useMedia from "./useMedia";
import ReactGA from "react-ga";
import { BASE_URL } from "../env";
import "react-circular-progressbar/dist/styles.css";
import TADoubtSession from "./TADoubtSession";
import Card from "react-bootstrap/Card";
import Typed from "typed.js";
import deloitte from "../Assets/img/expert-advice/Nimish/deloitte.png";
import bcg from "../Assets/img/expert-advice/Nimish/bcg.png";
import ey from "../Assets/img/expert-advice/Nimish/ey.png";
import kpmg from "../Assets/img/expert-advice/Nimish/kpmg.png";

import Group from "../Assets/img/expert-advice/group.png";
import Star from "../Assets/img/expert-advice/star.png";
// import Google from "../Assets/img/expert-advice/google.png";
// import Instagram from "../Assets/img/expert-advice/instagram.png";
// import LinkedIn from "../Assets/img/expert-advice/linkedin.png";
import CompanyLogo from "../Assets/img/expert-advice/pwc.svg";
import NimishPic from "../Assets/img/expert-advice/Nimish.png";

import {
  NavLink,
  useLocation,
  useParams,
} from "react-router-dom";
import AllQuestion from "./allQuestion";
import { AuthContext } from "../Context/AuthContext";
import Tilty from "react-tilty";
// import courseimg from "../Assets/img/coursimg.jpg";
// import Sidebar from "../components/Sidebar";
// import Notifications from "../components/Notifications";
// import useMedia from "./useMedia";
// import banner from "../Assets/img/tcs_dashboard.png";
// import Typed from 'typed.js';
// import { matches } from "dom-helpers";
//asdfasdfsadfjasdkl;fj

const MyCourse = (props) => {
  const analytics = firebase.analytics();
  ReactGA.initialize("UA-198180460-1");
  // const params = useParams();
  const location = useLocation();
  const ID = location.pathname.substring(10, 34);
  const [changed, setChanged] = useState(false);
  const [quizTime, setTime] = useState("");
  const [quizId, setId] = useState("");
  const [codingexam, setcodingexam] = useState(false);
  const { email } = useContext(AuthContext)
  // const [examresults, setexamresults] = useState([]);

  const [mockChanged, setMockChanged] = useState("1");
  const [toolkits, setToolkits] = useState([]);
  const [mocktoolkits, setMocktoolkits] = useState([]);
  const [username, setUsername] = useState("");
  const [useremail, setUseremail] = useState("");
  // const [progressArray,setProgressArray] =
  const [generalVideos, setGeneralVideos] = useState([]);
  // var courseID = "";
  // const [selectedcourse,setSelectedcourse] = useState([])
  var SelectedCourse = [];
  // const [progress, setProgress] = useState([]);
  const [progressArray, setProgressArray] = useState([]);
  const [MockProgressArray, setMockProgressArray] = useState([]);
  const [TotalVideos, setTotalVideos] = useState([]);
  const [percentage, setPercentage] = useState(0);

  const [mode, setMode] = useState(0);
  // const [mockPercentage, setMockPercentage] = useState(0);
  const [courseid, setCourseid] = useState("");

  const [videoRef, setVideoRef] = useState([]);
  const [chatSupport, setChatSupport] = useState(false);
  const [testLinks, setTestLinks] = useState([]);
  const [MockTab, setMockTab] = useState(false);
  const [RevisionTab, setRevisionTab] = useState(false);
  const [isAdmin, setisAdmin] = useState(false);
  const [areaOfinterest, setareaOfInterest] = useState("");
  const [in_progress, setIn_Progress] = useState([]);
  const [in_progress_percentage, setIn_Progress_Percentage] = useState(0);
  const [not_started_videos, setNotWatchedProgress] = useState([]);
  const [not_started_percentage, setNotWatchedPercentage] = useState(0);
  // const [selectedcourse,setSelectedcourse] = useState([])

  useEffect(()=>{
    console.log(email)
// fetch(BASE_URL + "/updateVisited",{
//           method:"POST",
//           headers:{
//             "Authorization":localStorage.getItem("token"),
//             "Content-Type":"application/json"
//           },
//           body: JSON.stringify({
//             // email: email,
//             courseid:ID
//           })
//         }).then((res)=>res.json())
//         .then((data)=>{
//           console.log(data);
//         })
  },[email])
  // var settings = {
  //   dots: false,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,

  //   autoplay: true,
  //   autoplaySpeed: 4000,
  //   cssEase: "linear",
  // };

  useEffect(() => {
    // console.log(props);
    if (props.location?.aboutProps !== undefined) {
      // console.log(props.location.aboutProps.name);
      setCourseid(ID);
    }

    fetch(BASE_URL + "/getProfile", {
      headers: { authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log("reffer", data.user.isAdmin);
        setisAdmin(data?.user?.isAdmin);
        setUsername(data?.user?.firstName);
        setUseremail(data?.user?.email);
        SelectedCourse = data.student.smallCourses.filter((value, index) => {
          return value.courseId == ID;
        });


        try {
            setareaOfInterest(data?.user?.areaOfIntrest[0]);
        } catch (TypeError) {
          setareaOfInterest("SOFTWARE DEVELOPMENT / CSE")
        }
      
      });
  }, [ID]);


  useEffect(() => {
    // alert(areaOfinterest)
  }, [areaOfinterest]);
  const el = useRef(null);

  useEffect(() => {
    // console.log(props);
    if (props.location?.aboutProps !== undefined) {
      // console.log(props.location.aboutProps.name);
      setCourseid(ID);
    }

    fetch(BASE_URL + "/getProfile", {
      headers: { authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then((res) => res.json())
      .then((data) => {
        setUsername(data?.user?.firstName);
        setUseremail(data?.user?.email);
        SelectedCourse = data.student.smallCourses.filter((value, index) => {
          return value.courseId == ID;
        });

        if (
          SelectedCourse[0].name ==
            "COMPLETE GUIDE TO PLACEMENT STANDARD COURSE" ||
          SelectedCourse[0].name ==
            "COMPLETE GUIDE TO PLACEMENT FASTTRACK COURSE" ||
          SelectedCourse[0].name ==
            "COMPLETE GUIDE TO PLACEMENT PREMIUM COURSE" ||
          SelectedCourse[0].name == "COMPLETE GUIDE TO PLACEMENT PRO COURSE"
        ) {
          setMockTab(true);
          setRevisionTab(true);
        } else {
          setMockTab(true);
          setRevisionTab(false);
        }

        // console.log(SelectedCourse[0].progressAssignments);
        setProgressArray(SelectedCourse[0].progress);
        setIn_Progress(SelectedCourse[0]?.in_progress);
        setMockProgressArray(SelectedCourse[0].progressAssignments);
        setTotalVideos(SelectedCourse[0].totalVideos);
        setPercentage((progressArray.length / TotalVideos) * 100);
        setIn_Progress_Percentage((in_progress.length / TotalVideos) * 100);
        setNotWatchedProgress(SelectedCourse[0]?.not_started);
        setNotWatchedPercentage(
          (progressArray.length / not_started_videos.length) * 100
        );
        setBookmarkArray(SelectedCourse[0].bookmarkVideo);
        // setMocktoolkits(SelectedCourse[0].totalToolkits)

        // console.log(percentage);
      });
  }, [ID, mockChanged, changed]);

  useEffect(() => {
    fetch(BASE_URL + "/quiz", {
      method: "GET",
      headers: { "content-type": "application/json" },
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log("id", data[0]._id);
        setTime(data[0].quizTime);
        setId(data[0].quizId);

        // console.log(quizId);
      });
  }, []);

  const [lengthOfVideos, setLengthOfVideos] = useState();
  const [description, setDescription] = useState("");
  const [whatyouget, setWhatyouget] = useState("");
  const [bigHeadingKnowledge, setBigHeadingKnowledge] = useState([]);
  const [sectionKnowledge, setSectionKnowledge] = useState([]);
  const [knowledgeCards, setKnowledgeCards] = useState([]);
  const [syllabus, setSyllabus] = useState();
  const [variation, setvariation] = useState(0);
  const [courseName, setcourseName] = useState("");

  const history = useHistory();

  useEffect(() => {
    // console.log(ID)
    fetch(BASE_URL + "/getCourse/" + ID, {
      headers: { authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then((res) => res.json())
      .then((dta) => {
        if (!dta.data) {
          history.push("/");
          return;
        }
        // console.log(dta.data);
        setdata(dta);

        setWhatyouget(dta.data[0].learning);
        setDescription(dta.data[0].description);
        // console.log(dta.data[0].description)
        setSyllabus(dta.data[0].syllabus);
        setSection(dta.data[0].videos);
        setBigHeadings(dta.data[0].videos);
        setMode(dta.data[0].mode);
        setLengthOfVideos(dta?.data[0]?.videos?.length);
        setSectionsKnowledge(dta.data[0].knowledgeCards);
        setbigHeadingsKnowledge(dta.data[0].knowledgeCards);
        setKnowledgeCards(dta.data[0].knowledgeCards);
        //mapKnowledge();
        setVideoRef(dta.data[0].generalVideos);
        setToolkits(dta.data[0].toolkits);
        setGeneralVideos(dta.data[0].generalVideos);
        setTestLinks(dta.data[0].testLinks);
        setVideos(dta.data[0].videos);

        setcourseName(dta.data[0].name);
        console.log(` The Course name u r accessing is ${dta.data[0].name}`);
        setMocktoolkits(dta.data[0].toolkits.length);
        setvariation(dta.data[0].variation);

        
        //mapKnowledge();
      })
      .catch((err) => {
        // console.log(err);
      });
  }, []);

  useEffect(() => {
    // alert("started")
    // console.log(TotalVideos);
    // console.log(progressArray.length);
    var percentage_watches = (progressArray.length / lengthOfVideos) * 100;
    // console.log(percentage_watches);
    var percentage_watches = Math.floor(percentage_watches);
    setPercentage(percentage_watches);
  }, [lengthOfVideos, progressArray]);

  let checkbox = {
    "Searching and Sorting": {
      "Linear Search": true,
      "Binary Search": true,
      "Bubble Sort": false,
      "Selection Sort": false,
      "Insertion Sort": true,
      "Count Sort": false,
      "Merge Sort": false,
      "Quick Sort": false,
    },
  };
  const [sections, setSections] = useState([]);
  const [videos, setVideos] = useState([]);
  const [bigHeading, setbigHeading] = useState([]);

  useEffect(() => {
    // console.log(bigHeading);
  }, [bigHeading]);
  function setBigHeadings(data) {
    const bigheadingss = data.map((value, index) => value.bigHeading);

    var uniqueHeading = bigheadingss.filter(onlyUnique);
    setbigHeading(uniqueHeading);
  }
  function setbigHeadingsKnowledge(data) {
    const bigheadingss = data.map((value, index) => value.bigHeading);
    // console.log(bigheadingss + " Knowledge");
    var uniqueHeading = bigheadingss.filter(onlyUnique);
    setBigHeadingKnowledge(uniqueHeading);
  }

  // let [test, setTest] = useState({
  //   "Searching and Sorting": {
  //     "Linear Search": true,
  //     "Binary Search": true,
  //     "Bubble Sort": false,
  //     "Selection Sort": false,
  //     "Insertion Sort": true,
  //     "Count Sort": false,
  //     "Merge Sort": false,
  //     "Quick Sort": false,
  //   },
  // });

  const [Data, setdata] = useState("");

  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }
  function setSection(data) {
    const sections = data.map((value, index) => value.section);
    var uniqueSection = sections.filter(onlyUnique);

    setSections(uniqueSection);
  }
  function setSectionsKnowledge(data) {
    const sections = data.map((value, index) => value.section);
    var uniqueSection = sections.filter(onlyUnique);

    setSectionKnowledge(uniqueSection);
  }

  function CapitalizeEveryLetter(string) {
    string = string?.toLowerCase();
    const arr = string?.split(" ");

    //loop through each element of the array and capitalize the first letter.

    for (var i = 0; i < arr?.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }

    //Join all the elements of the array back into a string
    //using a blankspace as a separator
    const str2 = arr?.join(" ");
    return str2;
  }

  const [mainArray, setMainArray] = useState([]);
  var arr = [];

  // function mapKnowledge() {
  //   bigHeadingKnowledge.forEach((element) => {
  //     var setS = new Set();
  //     knowledgeCards.forEach((v) => {
  //       if (element === v.bigHeading) {
  //         setS.add(v.section);
  //       }
  //     });
  //     var tmp = Array.from(setS);
  //     //console.log(tmp);
  //     arr.push({ bigHeading: element, subHeadings: tmp });
  //   });
  //   setMainArray(arr);
  //   // console.log(arr);
  // }

  // const [coursecontents, setCourseContents] = useState(true);
  // const [upcomingClass, setUpcoming] = useState(false);
  // let [LinearSearch, setLinearSearch] = useState(true);
  // let [BinarySearch, setBinarySearch] = useState(true);
  // let [BubbleSort, setBubbleSort] = useState(true);
  // let [InsertionSort, setInsertionSort] = useState(true);
  // let [SelectionSort, setSelectionSort] = useState(false);
  // let [MergeSort, setMergeSort] = useState(false);
  // let [CountSort, setCountSort] = useState(false);
  // let [QuickSort, setQuickSort] = useState(false);

  // let [count, setCount] = useState(4);

  // let [pro, setPro] = useState(
  //   (Object.keys(checkbox["Searching and Sorting"]).filter(
  //     (s) => checkbox["Searching and Sorting"][s]
  //   ).length *
  //     100) /
  //     8
  // );

  function OverViewData({ description }) {
    analytics.logEvent("Overview", {
      content: "Overview of the course",
    });
    ReactGA.event({
      category: "TCS Overview",
      action: "Overview of the course",
    });
    // console.log(description)
    // function createMarkup() {
    //   return {
    //     __html: "First &middot; Second",
    //   };
    // }
    return (
      <>
        <div dangerouslySetInnerHTML={{ __html: `${description}` }}></div>
      </>
    );
  }
  function CourseOverview({ description, whatyouget }) {
    // console.log(description)
    return (
      <>
        <div className="container ">
          <div className="row shadow border ">
            <h3
              className="py-3 px-5  text-light rounded"
              style={{ backgroundColor: "#00316e" }}
            >
              About the Course
            </h3>

            <div className="row my-5">
              <>
                <div className="col-sm-12 p-0">
                  <ul style={{ listStyle: "none" }}>
                    <li
                      style={{
                        fontSize: "1.0rem",
                        fontFamily: "DM Sans",
                      }}
                    >
                      <OverViewData description={description} />
                    </li>
                    <li className="" style={{ width: "100%" }}>
                      <div className="row ">
                        <div className="col-lg-10 col-md-10 col-sm-10 mt-5 mx-auto">
                          {/* {courseName == "QUANT FINANCE COURSE"
                            ? syllabus && (
                                <h2 style={{ display: "flex" }}>
                                  Knowledge Sessions
                                </h2>
                              )
                            : syllabus && <></>} */}
                          {courseName === "QUANT FINANCE COURSE" ? (
                            // <div>
                            //   <Table striped bordered hover size="sm">
                            //     <tbody>
                            //       <th>Sessions</th>
                            //       <th>Topic</th>
                            //       <tr>
                            //         <td>Session 1</td>
                            //         <td>Probability</td>
                            //       </tr>
                            //       <tr>
                            //         <td>Session 2 </td>
                            //         <td>Trading Markets</td>
                            //       </tr>
                            //       <tr>
                            //         <td>Session 3 </td>
                            //         <td>Statistics</td>
                            //       </tr>
                            //       <tr>
                            //         <td>Session 4</td>
                            //         <td>Markets</td>
                            //       </tr>
                            //       <tr>
                            //         <td>Session 5 </td>
                            //         <td>Statistics </td>
                            //       </tr>
                            //       <tr>
                            //         <td>Session 6 </td>
                            //         <td>Probability</td>
                            //       </tr>
                            //       <tr>
                            //         <td>Session 7</td>
                            //         <td>Data Structures and Algorithms</td>
                            //       </tr>
                            //       <tr>
                            //         <td>Session 8</td>
                            //         <td>Interviews </td>
                            //       </tr>
                            //       <tr>
                            //         <td>Session 9</td>
                            //         <td>Operating System</td>
                            //       </tr>
                            //       <tr>
                            //         <td>Session 10 </td>
                            //         <td>Networking</td>
                            //       </tr>
                            //       <tr>
                            //         <td>Session 11</td>
                            //         <td>Data Structures and Algorithms </td>
                            //       </tr>
                            //       <tr>
                            //         <td>Session 12</td>
                            //         <td>Python </td>
                            //       </tr>
                            //     </tbody>
                            //   </Table>
                            // </div>
                            ""
                          ) : (
                            <div
                              className="accordion row mt-3"
                              id="accordionExample"
                            >
                              {syllabus &&
                                syllabus.weeks.map((week, index) => {
                                  return (
                                    <div className="col-md-5 col-sm-6 ">
                                      <div
                                        className="accordion-item"
                                        style={{
                                          border: "1px solid #eeeeee",
                                          margin: "10px auto",
                                          borderRadius: "6px",
                                        }}
                                      >
                                        <h2
                                          className="accordion-header"
                                          id={`heading${index}`}
                                        >
                                          <button
                                            className={"accordion-button"}
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target={`#collapse${index}`}
                                            accordion-icon-active-color="#000"
                                            aria-expanded="true"
                                            aria-controls={`collapse${index}`}
                                            style={{
                                              boxShadow: "none",
                                              borderBottomLeftRadius: "none",
                                              borderBottomRightRadius: "none",
                                              color: "#fff",
                                              background: "#00316E",
                                              fontWeight: "600",
                                            }}
                                          >
                                            Week {index + 1}
                                          </button>
                                        </h2>
                                        <div
                                          id={`collapse${index}`}
                                          className="accordion-collapse show "
                                          aria-labelledby={`heading${index}`}
                                          data-bs-parent="#accordionExample"
                                        >
                                          <div
                                            className="accordion-body shadow "
                                            style={{ textAlign: "start" }}
                                          >
                                            {week.content.map((value) => {
                                              return (
                                                <>
                                                  <h5 className="mt-3">
                                                    {value.type}
                                                  </h5>
                                                  <ul>
                                                    {value.data.map((dat) => (
                                                      <li
                                                        style={{
                                                          display: "list-item",
                                                        }}
                                                      >
                                                        {dat}
                                                      </li>
                                                    ))}
                                                  </ul>
                                                </>
                                              );
                                            })}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          )}
                        </div>
                      </div>
                    </li>
                    <br />
                  </ul>
                </div>
              </>
            </div>
          </div>
        </div>
      </>
    );
  }

  // function Technical_section() {
  //   // console.log(toolkits);
  //   return (
  //     <>
  //       HELLO
  //       <div
  //         className="row my-2"
  //         onClick={() => {
  //           analytics.logEvent("Array_Card", {
  //             type: "card",
  //             name: "Array",
  //             content: "Array Card in tab section",
  //           });

  //           ReactGA.event({
  //             category: "TCS_Array_Card",
  //             action: "clicked on tcs array card",
  //             type: "card",
  //             name: "Array",
  //             content: "Array Card in tab section",
  //           });
  //         }}
  //       >
  //         {mainArray.map((value) => {
  //           return (
  //             <>
  //               {/* <div className="heading mb-4 bg-primary text-light py-3 px-2 rounded">
  //                 <h3 style={{ marginBottom: "0px" }} className="mx-5">
  //                   {" "}
  //                   {value.bigHeading}
  //                 </h3>
  //               </div> */}

  //               {value.subHeadings.map((item) => {
  //                 return (
  //                   <>
  //                     <h4 className="mx-5" style={{ fontWeight: "500" }}>
  //                       {item}{" "}
  //                     </h4>
  //                     <div
  //                       className="row d-flex justify-content-center  px-2  example "
  //                       style={{ overflowX: "scroll" }}
  //                     >
  //                       {knowledgeCards.map((videovalue) => {
  //                         if (
  //                           videovalue.bigHeading === value.bigHeading &&
  //                           videovalue.section === item
  //                         ) {
  //                           return (
  //                             <>
  //                               {/* { JSON.stringify(videovalue)} */}

  //                               <div
  //                                 className="card my-4 p-0"
  //                                 style={{
  //                                   width: "17rem",
  //                                   height: "14rem",
  //                                   marginRight: "94px",
  //                                 }}
  //                                 key={1}
  //                                 onClick={() => {
  //                                   analytics.logEvent("", {
  //                                     type: "card",
  //                                     name: "",
  //                                     content: "",
  //                                   });
  //                                 }}
  //                               >
  //                                 <div
  //                                   style={{
  //                                     padding: "56.25% 0 0 0",
  //                                     position: "relative",
  //                                   }}
  //                                 >
  //                                   <iframe
  //                                     src={videovalue.link}
  //                                     frameborder="0"
  //                                     allow="autoplay; fullscreen; picture-in-picture"
  //                                     allowfullscreen
  //                                     style={{
  //                                       position: "absolute",
  //                                       top: "0",
  //                                       left: "0",
  //                                       width: "100%",
  //                                       height: "100%",
  //                                     }}
  //                                     title="clocks"
  //                                   ></iframe>
  //                                 </div>

  //                                 <div className="card-body">
  //                                   <h6 className="card-title">
  //                                     {videovalue.name}
  //                                   </h6>
  //                                 </div>
  //                               </div>
  //                             </>
  //                           );
  //                         }
  //                       })}
  //                     </div>
  //                   </>
  //                 );
  //               })}
  //             </>
  //           );
  //         })}
  //       </div>
  //     </>
  //   );
  // }

  const [Name, setName] = useState();
  useEffect(() => {
    fetch(BASE_URL + "/getProfile", {
      headers: { authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then((res) => res.json())
      .then((data) => {
        setUsername(data?.user?.firstName);
        setUseremail(data?.user?.email);
        setName(data?.user?.firstName);
        // console.log(data?.user?.firstName);
        // console.log(data)
      });
  }, []);

  useEffect(() => {
    const typed = new Typed(el.current, {
      strings: [Name ? CapitalizeEveryLetter(Name) : ""], // Strings to display
      // Speed settings, try diffrent values untill you get good results
      startDelay: 0,
      typeSpeed: 100,

      showCursor: false,
      autoInsertCss: true,
      cursorChar: "|",
    });

    // Destropying
    return () => {
      typed.destroy();
    };
  }, [Name]);

  useEffect(() => {
    courseoverviewtrue();
  }, []);

  const [bookmarkArray, setBookmarkArray] = useState([]);
  const [courseoverview, setcourseoverview] = useState(false);
  const [coursecontent, setcoursecontent] = useState(true);
  const [reference, setreferences] = useState(false);
  const [onetoone, setonetoone] = useState(false);
  const [mockTest, setMockTest] = useState(false);
  const [bookmark, setBookmark] = useState(false);
  const [compiler, setcompiler] = useState(false);
  const [practicesection, setpracticesection] = useState(false);
  const [mockInterview, setMockInterView] = useState(false);
  // var onetoOne = document.getElementById("onetone");
  // var contentcourse = document.getElementById("contentcourse");
  // var overviewcourse = document.getElementById("overviewcourse");
  // var referencess = document.getElementById("references");

  useEffect(() => {}, [reference]);
  // function onetonetrue() {
  //   setpracticesection(false);
  //   setcompiler(false);
  //   setonetoone(true);
  //   setcoursecontent(false);
  //   setcourseoverview(false);
  //   setreferences(false);
  //   setBookmark(false);
  //   setMockTest(false);
  //   setMockInterView(false);
  //   setcodingexam(false)
  // }
  function setcodingexamtrue() {
  
  setpracticesection(false);
    setonetoone(false);
    setcoursecontent(false);
    setcourseoverview(false);
    setreferences(false);
    setBookmark(false);
    setMockTest(false);
    setMockInterView(false);
    setMockInterView(false);
    setcompiler(false);
    setcodingexam(true)
}
  function coursecontenttrue() {
    setpracticesection(false);
    setonetoone(false);
    setcoursecontent(true);
    setcourseoverview(false);
    setreferences(false);
    setBookmark(false);
    setMockTest(false);
    setMockInterView(false);
    setMockInterView(false);
    setcompiler(false);
        setcodingexam(false)

  }
  function compilertrue() {
    setpracticesection(false);
    setcompiler(true);
    setonetoone(false);
    setcoursecontent(false);
    setcourseoverview(false);
    setreferences(false);
    setBookmark(false);
    setMockTest(false);
    setMockInterView(false);
    setMockInterView(false);
        setcodingexam(false)

  }
  function practicesectiontrue() {
    setpracticesection(true);
    setcompiler(false);
    setonetoone(false);
    setcoursecontent(false);
    setcourseoverview(false);
    setreferences(false);
    setBookmark(false);
    setMockTest(false);
    setMockInterView(false);
    setMockInterView(false);
        setcodingexam(false)

  }
  function courseoverviewtrue() {
    setpracticesection(false);
    setonetoone(false);
    setcoursecontent(false);
    setcourseoverview(true);
    setreferences(false);
    setBookmark(false);
    setMockTest(false);
    setMockInterView(false);
    setcompiler(false);
        setcodingexam(false)

  }
  function referencestrue() {
    setpracticesection(false);
    setonetoone(false);
    setcoursecontent(false);
    setcourseoverview(false);
    setMockTest(false);
    setBookmark(false);
    setreferences(true);
    setMockInterView(false);
    setcompiler(false);
        setcodingexam(false)

  }

  function mocktestTrue() {
    setpracticesection(false);
    setonetoone(false);
    setcoursecontent(false);
    setcourseoverview(false);
    setreferences(false);
    setBookmark(false);
    setMockTest(true);
    setMockInterView(false);
    setcompiler(false);
        setcodingexam(false)

  }
  function bookmarkTrue() {
    setpracticesection(false);
    setonetoone(false);
    setcoursecontent(false);
    setcourseoverview(false);
    setreferences(false);
    setMockTest(false);
    setBookmark(true);
    setMockInterView(false);
    setcompiler(false);
        setcodingexam(false)

  }
  function MockInterviewTrue() {
    setpracticesection(false);
    setonetoone(false);
    setcoursecontent(false);
    setcourseoverview(false);
    setreferences(false);
    setMockTest(false);
    setBookmark(false);
    setMockInterView(true);
    setcompiler(false);
        setcodingexam(false)

  }
  //   function ReferenceTabTrue() {
  //     setonetoone(false);
  //     setcoursecontent(false);
  //     setcourseoverview(false);
  //     setreferences(false);
  //     setMockTest(false);
  //     setBookmark(false);
  //     setMockInterView(true);
  //   }
  const matches = useMedia("(min-width : 768px )");

  function closePopup() {
    setChatSupport((prev) => !prev);
  }
  const IITBMockInterView = (props) => {
    const { areaOfinterest } = props;
    const [urllink, seturllink] = useState([]);
    const [selectedTab, setSelectedTab] = useState("Sample");
  useEffect(() => {
      if (
        areaOfinterest === "SOFTWARE DEVELOPMENT / CSE" ||
        areaOfinterest === "Software Development MasterTrack"
      ) {
        seturllink([
          "https://calendly.com/mockinterview4/sd_mock_interview_saharsha_kanugo",
        ]);
      } else if (areaOfinterest === "PRODUCT MANAGEMENT(APM)") {
        seturllink(["https://calendly.com/mockinterview10/30min"]);
      } else if (areaOfinterest === "PRODUCT DEVELOPMENT") {
        seturllink([
          "https://calendly.com/cantileverlabs/ism-dhanbad-mock_interview-pm-ay",
          "https://calendly.com/cantileverlabs/mock_interview-project-management-d",
        ]);
      } else if (areaOfinterest === "OTHERS") {
        seturllink([
          "https://calendly.com/cantileverlabs/ism-dhanbad-mock_interview-pm-ay",
          "https://calendly.com/cantileverlabs/mock_interview-project-management-d",
        ]);
      } else if (areaOfinterest === "MARKETING") {
        seturllink([
          "https://calendly.com/cantileverlabs/ism-dhanbad-mock_interview-pm-ay",
          "https://calendly.com/cantileverlabs/mock_interview-project-management-d",
        ]);
      } else if (areaOfinterest === "FINANCE") {
        seturllink([
          "https://calendly.com/cantileverlabs/ism-dhanbad-mock_interview-pm-ay",
          "https://calendly.com/cantileverlabs/mock_interview-project-management-d",
        ]);
      } else if (areaOfinterest === "DATA SCIENCE") {
        seturllink([
          "https://calendly.com/mockinterview2/ds_mock_interview_with_giridhar",
        ]);
      } else if (areaOfinterest === "CORE INDUSTRY") {
        seturllink([
          "https://calendly.com/mockinterview5/core_mechanical_mock_interview_with_dilbag_singh",
        ]);
      } else if (areaOfinterest === "CONSULTING") {
        seturllink([
          "https://calendly.com/cantileverlabs/ism-dhanbad-mock_interview-pm-ay",
          "https://calendly.com/cantileverlabs/mock_interview-project-management-d",
        ]);
      } else if (areaOfinterest === "BUSINESS DEVELOPMENT") {
        seturllink([
          "https://calendly.com/cantileverlabs/ism-dhanbad-mock_interview-pm-ay",
          "https://calendly.com/cantileverlabs/mock_interview-project-management-d",
        ]);
      } else {
        seturllink([
          "https://calendly.com/cantileverlabs/ism-dhanbad-mock_interview-pm-ay",
          "https://calendly.com/cantileverlabs/mock_interview-project-management-d",
        ]);
      }
    }, [areaOfinterest]);

    useEffect(() => {
      // alert(urllink)
    }, [urllink]);
    const videoData = [
    {
      title_1: "INTERVIEW IIT JEE AIR 1",
      title_2: "CEO,CantileverLabs",
      text1: "VNIT",
      text2: "5/5",
      videoLink: "https://vimeo.com/603419095",
      heading: "MOCK INTERVIEW",
      src: "80 mins"
    },
    {
      title_1: "SOFTWARE DEVELOPEMENT",
      title_2: "DrinkPrime",
      text1: "BITS,Pilani",
      text2: "4/5",
      videoLink: "https://youtu.be/h97r6mDJDDE",
      heading: "SOFTWARE DEVELOPEMENT",
      src: "27 mins"
    },
    {
      title_1: "SOFTWARE DEVELOPEMENT",
      title_2: "ServiceNow",
      text1: "IIT Roorkee",
      text2: "4/5",
      videoLink: "https://youtu.be/xEjXIapvnsU",
      heading: "SOFTWARE DEVELOPEMENT",
      src: "25 mins"
    },
    {
      title_1: "SOFTWARE DEVELOPEMENT",
      title_2: "DrinkPrime",
      text1: "BITS,Pilani",
      text2: "4/5",
      videoLink: "https://youtu.be/GtdwDC9vK_Y",
      heading: "SOFTWARE DEVELOPEMENT",
      src: "29 mins"
    },
    {
      title_1: "DATA SCIENCE",
      title_2: "Vaaya Consulting",
      text1: "IIT Bombay",
      text2: "4/5",
      videoLink: "https://youtu.be/vp3eZgQufaU",
      heading: "DATA SCIENCE",
      src: "32 mins"
    },
    {
      title_1: "DATA SCIENCE",
      title_2: "Vaaya Consulting",
      text1: "IIT Bombay",
      text2: "4/5",
      videoLink: "https://youtu.be/-KQYjfedIeY",
      heading: "DATA SCIENCE",
      src: "28 mins"
    },
    {
      title_1: "DATA SCIENCE",
      title_2: "Vaaya Consulting",
      text1: "IIT Bombay",
      text2: "5/5",
      videoLink: "https://youtu.be/ul85OZzk_LA",
      heading: "DATA SCIENCE",
      src: "38 mins"
    },
    {
      title_1: "PRODUCT MANAGEMENT",
      title_2: "ClearTax",
      text1: "IIT Bombay",
      text2: "5/5",
      videoLink: "https://youtu.be/twCP3D3NdJA",
      heading: "PRODUCT MANAGEMENT",
      src: "90 mins"
    },
    {
      title_1: "PRODUCT MANAGEMENT",
      title_2: "ClearTax",
      text1: "IIT Bombay",
      text2: "4/5",
      videoLink: "https://youtu.be/FgOJ1LqR5OE",
      heading: "PRODUCT MANAGEMENT",
      src: "33 mins"
    },
    {
      title_1: "PRODUCT MANAGEMENT",
      title_2: "ClearTax",
      text1: "IIT Bombay",
      text2: "5/5",
      videoLink: "https://youtu.be/N1CXjETC5BM",
      heading: "PRODUCT MANAGEMENT",
      src: "90 mins"
    },
    {
      title_1: "CONSULTING",
      title_2: "MIQ",
      text1: "HCST",
      text2: "4/5",
      videoLink: "https://youtu.be/AonrDqZSjzw",
      heading: "CONSULTING",
      src: "35 mins"
    },
    {
      title_1: "BUSINESS DEVELOPMENT",
      title_2: "ClearTax",
      text1: "IIT Bombay",
      text2: "4/5",
      videoLink: "https://youtu.be/p2G0h-Vg8Tk",
      heading: "BUSINESS DEVELOPMENT",
      src: "41 mins"
    },
    {
      title_1: "BUSINESS DEVELOPMENT",
      title_2: "ClearTax",
      text1: "IIT Bombay",
      text2: "4/5",
      videoLink: "https://youtu.be/DrQLpr-zlNU",
      heading: "BUSINESS DEVELOPMENT",
      src: "35 mins"
    },
    {
      title_1: "BUSINESS DEVELOPMENT",
      title_2: "ClearTax",
      text1: "IIT Bombay",
      text2: "4/5",
      videoLink: "https://youtu.be/ALkVNqbQ3o8",
      heading: "BUSINESS DEVELOPMENT",
      src: "40 mins"
    },
    {
      title_1: "BUSINESS DEVELOPMENT",
      title_2: "ClearTax",
      text1: "IIT Bombay",
      text2: "4/5",
      videoLink: "https://youtu.be/bMyRmcRDrqI",
      heading: "BUSINESS DEVELOPMENT",
      src: "30 mins"
    },
    {
      title_1: "FINANCE",
      title_2: "Merilytics",
      text1: "IIT Roorkee",
      text2: "4/5",
      videoLink: "https://youtu.be/MIoBXFffw1M",
      heading: "FINANCE",
      src: "60 mins"
    },
    {
      title_1: "FINANCE",
      title_2: "Deshpande's Startup",
      text1: "IIT Bombay",
      text2: "4/5",
      videoLink: "https://youtu.be/nFAsTtWCQyk",
      heading: "FINANCE",
      src: "55 mins"
    },
    {
      title_1: "CORE - MECHANICAL",
      title_2: "Lenox India Private Limited",
      text1: "IIT Bombay",
      text2: "4/5",
      videoLink: "https://youtu.be/6jsGpwRWsN0",
      heading: "CORE - MECHANICAL",
      src: "30 mins"
    },
    {
      title_1: "CORE - MECHANICAL",
      title_2: "L&T",
      text1: "IIT Gwalior",
      text2: "4/5",
      videoLink: "https://youtu.be/lJK6KrDSKno",
      heading: "CORE - MECHANICAL",
      src: "30 mins"
    },
    {
      title_1: "CORE - MECHANICAL",
      title_2: "Lenox India Private Limited",
      text1: "IIT Bombay",
      text2: "4/5",
      videoLink: "https://youtu.be/QoHoKTh-fIE",
      heading: "CORE - MECHANICAL",
      src: "30 mins"
    },
    {
      title_1: "CORE- ELECTRICAL",
      title_2: "Qualcomm",
      text1: "BITS,Pilani",
      text2: "4/5",
      videoLink: "https://youtu.be/Ph2DlGAqD_A",
      heading: "CORE- ELECTRICAL",
      src: "36 mins"
    },
    {
      title_1: "CORE- ELECTRICAL",
      title_2: "Qualcomm",
      text1: "BITS,Pilani",
      text2: "4/5",
      videoLink: "https://youtu.be/mMITj0ERU1w",
      heading: "CORE- ELECTRICAL",
      src: "32 mins"
    },
    {
      title_1: "CORE- ELECTRICAL",
      title_2: "Qualcomm",
      text1: "BITS,Pilani",
      text2: "5/5",
      videoLink: "https://youtu.be/OiT2UqTgi48",
      heading: "CORE- ELECTRICAL",
      src: "33 mins"
    },
    {
      title_1: "CORE- ELECTRONICS",
      title_2: "THB India",
      text1: "IIT Bombay",
      text2: "5/5",
      videoLink: "https://youtu.be/kMCcwekwwXg",
      heading: "CORE- ELECTRONICS",
      src: "30 mins"
    },
    {
      title_1: "CORE- ELECTRONICS",
      title_2: "THB India",
      text1: "IIT Bombay",
      text2: "5/5",
      videoLink: "https://youtu.be/b8oksxMNCUY",
      heading: "CORE- ELECTRONICS",
      src: "30 mins"
    },
    {
      title_1: "CORE- ELECTRONICS",
      title_2: "THB India",
      text1: "IIT Bombay",
      text2: "5/5",
      videoLink: "https://youtu.be/25Whv6RkFok",
      heading: "CORE- ELECTRONICS",
      src: "30 mins"
    },
    {
      title_1: "CORE - CHEMICAL",
      title_2: "Byju’s",
      text1: "IIT Bombay",
      text2: "5/5",
      videoLink: "https://youtu.be/KX_BbvoaK0o",
      heading: "CORE - CHEMICAL",
      src: "35 mins"
    },
    {
      title_1: "CORE - CHEMICAL",
      title_2: "Byju’s",
      text1: "IIT Bombay",
      text2: "5/5",
      videoLink: "https://youtu.be/GXokIuF0SLM",
      heading: "CORE - CHEMICAL",
      src: "35 mins"
    },
    {
      title_1: "CORE - CHEMICAL",
      title_2: "Byju’s",
      text1: "IIT Bombay",
      text2: "5/5",
      videoLink: "https://youtu.be/EeY930g0La8",
      heading: "CORE - CHEMICAL",
      src: "35 mins"
    },
    {
      title_1: "CORE - AERONAUTICAL",
      title_2: "Tata Research Development & Design Centre",
      text1: "IIT Bombay",
      text2: "4/5",
      videoLink: "https://youtu.be/3x8W20ykm20",
      heading: "CORE - AERONAUTICAL",
      src: "30 mins"
    },
    {
      title_1: "CORE - AERONAUTICAL",
      title_2: "Tata Research Development & Design Centre",
      text1: "IIT Bombay",
      text2: "4/5",
      videoLink: "https://youtu.be/exr2MCbzfi8",
      heading: "CORE - AERONAUTICAL",
      src: "30 mins"
    },
    {
      title_1: "CORE - AERONAUTICAL",
      title_2: "Tata Research Development & Design Centre",
      text1: "IIT Bombay",
      text2: "4/5",
      videoLink: "https://youtu.be/zWGyl2E0XuM",
      heading: "CORE - AERONAUTICAL",
      src: "30 mins"
    },
    {
      title_1: "CORE - METALLURGY",
      title_2: "DrinkPrime",
      text1: "BITS,Pilani",
      text2: "5/5",
      videoLink: "https://youtu.be/h97r6mDJDDE",
      heading: "CORE - METALLURGY",
      src: "28 mins"
    }
  ];

    // const [uniqueheadingInterview, setuniqueinterviewHeading] = useState([]);

    // useEffect(() => {
    //   const array = videoData.map((value, index) => {
    //     return value.heading;
    //   });
    //   console.log(array);
    //   setsmallHeadings(array);
    // }, []);

    // function onlyUnique(value, index, self) {
    //   return self.indexOf(value) === index;
    // }
    // function setsmallHeadings(data) {
    //   console.log(data);
    //   // const smallheadingss = data.map((value, index) => value.bigHeading);
    //   //   console.log(smallheadingss  )
    //   var smalluniqueHeading = data.filter(onlyUnique);
    //   console.log(smalluniqueHeading);
    //   setuniqueinterviewHeading(smalluniqueHeading);
    // }

    const [videolink, setVideoLink] = useState("");
    const [showModal, setshowModal] = useState(false);

    function ClosePopup() {
      setshowModal(false);
    }
    return (
      <>
        {showModal && <TempPopup video={videolink} handleClose={ClosePopup} />}

        {/* <div
          className="heading mb-4 text-light py-3 px-2 rounded"
          style={{ backgroundColor: "#00316e" }}
        > */}
        {/* <h3 style={{ marginBottom: "0px" }} className="mx-5">
            Sample Mock Interviews
          </h3> */}
        {/* </div> */}

        <div className="d-flex justify-content-center">
          {/* <button
						type='button'
						class='btn btn-primary'
						onClick={() => {
							handleFilter("difficulty");
						}}>
						Group by Difficulty
					</button>
					<button
						type='button'
						class='btn btn-outline-primary'
						onClick={() => {
							handleFilter("category");
						}}>
						Group by Category
					</button> */}
          {selectedTab === "Sample" ? (
            <div className=" my-3">
              <button className="btn btn-primary mx-2">
                Sample Mock Interviews
              </button>
              <button
                onClick={() => {
                  setSelectedTab("Mock Interview");
                }}
                className="btn btn-outline-primary mx-2"
              >
                Book Your Mock Interviews
              </button>
              <button
                onClick={() => {
                  setSelectedTab("GetMentor");
                }}
                className="btn btn-outline-primary mx-2"
              >
             Get Personalized Mentorship
              </button>
            </div>
          ) : selectedTab === "Mock Interview" ? (
            <div className=" my-3">
              <button
                onClick={() => {
                  setSelectedTab("Sample");
                }}
                className="btn btn-outline-primary mx-2"
              >
                Sample Mock Interviews
              </button>
              <button className="btn btn-primary mx-2">
                Book Your Mock Interviews
              </button>
              <button
                onClick={() => {
                  setSelectedTab("GetMentor");
                }}
                className="btn btn-outline-primary mx-2"
              >
             Get Personalized Mentorship
              </button>
            </div>
          ):(
            <div className=" my-3">
              <button
                onClick={() => {
                  setSelectedTab("Sample");
                }}
                className="btn btn-outline-primary mx-2"
              >
                Sample Mock Interviews
              </button>
              <button className="btn btn-outline-primary mx-2"
               onClick={() => {
                setSelectedTab("Mock Interview");
              }}
              >
                Book Your Mock Interviews
              </button>
              <button
               
               
                className="btn btn-primary mx-2"
              >
             Get Personalized Mentorship
              </button>
            </div>
          )}
        </div>

        {selectedTab === "Sample" && (
          <div className="row">
            {videoData.map((itemvalue, index) => {
              return (
                <>
                  <div
                    class="courses-container col-lg-4 col-md-4 col-sm-4 col-xs-4 py-2"
                    style={{ transform: "scale(0.88)" }}
                  >
                    <div
                      class="course-dashboard my-3 py-2 shadow"
                      style={{ borderLeft: "5px solid #660080" }}
                    >
                      <div
                        class="course-dashboard-preview"
                        style={{ padding: "0px" }}
                      >
                        {/* <img
																		src={videovalue.imgurl}
																		alt=''
																		style={{ width: "220px", height: "220px" }}
																	/> */}
                        {/* <lottie-player src={videovalue.imgurl} background="transparent"  speed="1"
                                  style={{ width: "160px", height: "160px" }} loop autoplay>
                              </lottie-player> */}
                      </div>
                      <div class="course-info-dashboard">
                        <div class="progress-container-dashboard">
                          <span class="progress-text-dashboard ">
                            {itemvalue.src}
                          </span>
                        </div>
                        {/* <h6>Lecture {index + 1}</h6> */}
                        <h2 class="video-dashboard-title my-0">
                          {itemvalue.title_1}
                        </h2>
                        <br />
                        Mentor Profile:
                        <br />
                        <i class="fas fa-briefcase"></i> {itemvalue.title_2}
                        <br />
                        <i class="fas fa-graduation-cap"></i>
                        {itemvalue.text1}
                        <br />
                        <div style={{ position: "absolute", bottom: "10px" }}>
                          Student Rating: {itemvalue.text2}
                        </div>
                        <button
                          class="btn-course-dashboard btn btn-primary btn-sm"
                          onClick={() => {
                            // setModalShow(true);
                            // setvideoLink(videovalue.link);
                            // InProgress(videovalue._id);
                            setVideoLink(itemvalue.videoLink);
                            setshowModal(true);
                          }}
                        >
                          <i className="fas fa-play"></i> Play
                        </button>
                        {/* {variation !== 0 && (
                                    <a
                                      class="btn-course-dashboard-notes btn-outline-primary btn btn-sm "
                                      href={"videovalue.resourseOne"}
                                      target="blank"
                                    >
                                      Notes
                                    </a>
                                  )} */}
                        {/* { checkExistsorNot(progressArray,videovalue._id) ?<button onClick={()=>completedFunction(videovalue._id)}> Mark as Complete </button>: <button onClick={()=>unCheckFunction(videovalue._id)}>In Progress </button> }               */}
                        {/* <ul>
																		{videovalue.learningPoints
																			.slice(0, 3)
																			.map((singlePoint) => {
																				return (
																					<li className='courseDashboardpoints'>
																						{singlePoint}
																					</li>
																				);
																			})}
																	</ul> */}
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        )}

        {selectedTab === "GetMentor" && (
      <div className="" style={{width:"350px"}}>
      <Tilty max="0" scale="1.03">
        <div className="expert-boxwrapper pb-0" data-aos="fade-in">
          <div className="banner">
            <img src={NimishPic} className="img-fluid" alt="experts profile" style={{height:"70px"}} />
          </div>
          <div className="name mt-4">Nimish Nama</div>
          <div className="post">Consultant</div>
          <div className="company">
            <img src={CompanyLogo} className="img-fluid" alt="company logo" style={{height:"50px",width:"50px"}} />
          </div>
          <div className="mid py-1" style={{ transform: "scale(0.8)" }}>
            <div className="left-corner">
              <img src={Group} clasName="img-fluid" alt="group icon" />
              200+ <br />
              <span>student mentored</span>
            </div>
            <div className="right-corner">
              <img src={Star} clasName="img-fluid" alt="group icon" />
              4.7 <br />
              <span>200+ reviews</span>
            </div>
          </div>
          <div className="expertise">Expertise</div>
          <ul>
            <li>Resume Review</li>
            <li>Mock Interview</li>
            <li>Career Planning</li>
            <li>Case Studies</li>
            <li>cv building</li>
            <li>Guestimation</li>
            <li>Entrance Test</li>
          </ul>
          <div className="helping">Helping you get placed in :</div>
          <div className="companies py-0" style={{ paddingBottom: "0px" }}>
            <img src={ey} className="img-fluid shadow" style={{width:"60px", margin:"4px 6px"}} alt="company icon" />
            <img src={bcg} className="img-fluid shadow" style={{width:"60px", margin:"4px 6px"}} alt="company icon" />
            <img src={kpmg} className="img-fluid shadow" style={{width:"60px", margin:"4px 6px"}} alt="company icon" />
            <img src={deloitte} className="img-fluid shadow" style={{width:"60px", margin:"4px 6px"}} alt="company icon" />
          </div>
          <hr />
          <div className="foot">
            <div className="right mx-auto">
              
              <NavLink
                to={`/expert-profile/61adc3d38f07e587d353cd8f`}
                className="connect"
                onClick={() => {
                  window.scroll(0, 0);
                }}
              >
                Connect
              </NavLink>
            </div>
          </div>
        </div>
      </Tilty>
        </div>
        )}
        {selectedTab === "Mock Interview" && (
          <div>
            {/* <div
          className="heading mb-4 text-light py-3 px-2 rounded"
          style={{ backgroundColor: "#00316e" }}
        >
          <h3 style={{ marginBottom: "0px" }} className="mx-5">
            Book Your Interview with Industry Expert
          </h3>
        </div> */}
            <div
              style={{
                background:
                  "linear-gradient(to right, rgb(0, 49, 110), rgb(107, 129, 255))",
              }}
              className="row mx-auto  "
            >
              {urllink.map((value) => {
                return (
                  <div className="col-sm-4 mx-auto">
                    <InlineWidget
                      pageSettings={{
                        backgroundColor: "ffffff",
                        hideEventTypeDetails: false,
                        hideGdprBanner: true,
                        hideLandingPageDetails: false,
                        primaryColor: "00a2ff",
                        textColor: "4d5055",
                      }}
                      className="col-sm-4 shadow-lg"
                      prefill={{
                        customAnswers: {
                          a1: "a1",
                          a10: "a10",
                          a2: "a2",
                          a3: "a3",
                          a4: "a4",
                          a5: "a5",
                          a6: "a6",
                          a7: "a7",
                          a8: "a8",
                          a9: "a9",
                        },
                        date: new Date("2021-10-08T13:04:15.973Z"),
                        email: "test@test.com",
                        firstName: "Jon",
                        guests: ["janedoe@example.com", "johndoe@example.com"],
                        lastName: "Snow",
                        name: "Jon Snow",
                      }}
                      styles={{
                        height: "500px",
                      }}
                      url={value}
                      utm={{
                        utmCampaign: "Spring Sale 2019",
                        utmContent: "Shoe and Shirts",
                        utmMedium: "Ad",
                        utmSource: "Facebook",
                        utmTerm: "Spring",
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      {/* <Navbar /> */}
      {chatSupport ? <TempPopup handleClose={closePopup} /> : null}
      <div className={style.body} style={{ overflowX: "hidden" }}>
        <div className={`container-fluid ${style.dashboard}`}>
          <div className="row">
            <div
              className={`p-0 col-xl-12 col-lg-12 col-md-12 col-sm-12 ${style.courses_div}`}
              style={{ marginTop: "0px" }}
            >
              <div
                className={`d-flex flex-row justify-content-between`}
                style={{ backgroundColor: "rgb(70 152 255)" }}
              >
                <h2
                  className="ps-3 py-2"
                  style={{ fontFamily: "Mulish", color: "#fff" }}
                >
                  Hello{" "}
                  <span ref={el} style={{ fontFamily: "Poppins-SemiBold" }}>
                    {CapitalizeEveryLetter(Name)}
                  </span>
                  !
                </h2>
                <div className="my-auto me-1">
                  <a
                    className={style.joinDiscussionBtn}
                    class="btn"
                    rel="noreferrer"
                    href="https://discord.gg/pSH6AsBDuK"
                    target="_blank"
                    title="discord community"
                  >
                    <img
                      src={discord_png}
                      alt=""
                      className="img-responsive img-fluid mx-1"
                      style={{
                        width: "15%",
                        height: "15%",
                      }}
                    />
                    <span>
                      <b> Join Discord</b>
                    </span>
                  </a>
                </div>
              </div>

              <div
                className={matches ? "px-5 py-3" : "px-3"}
                style={{ backgroundColor: "#EFEFEF" }}
              >
                <MyCourseReportCard
                  percentage={percentage}
                  mockpercentage={0}
                  TotalVideos={lengthOfVideos}
                  progressArray={progressArray}
                  mockprogressArray={MockProgressArray}
                  setMockChanged={setMockChanged}
                  courseID={ID}
                  totalToolkits={mocktoolkits}
                  courseName={courseName}
                  mode={mode}
                  in_progress_percentage={in_progress_percentage}
                  not_started_videos={not_started_percentage}
                />
              </div>
              {/* MINE */}
              <div className="row my-3 mx-3">
                {matches ? (
                  <div
                    className="p-2 d-flex flex-row justify-content-around"
                    style={{ cursor: "pointer" }}
                  >
                    <span
                      id="overviewcourse"
                      onClick={courseoverviewtrue}
                      className={
                        courseoverview == true
                          ? style.blueclass + " p-3 col text-center "
                          : " p-3 col text-center  " + style.color
                      }
                      style={{
                        cursor: "pointer",
                        fontSize: "1.2rem",
                        fontWeight: "600",
                      }}
                    >
                      Overview
                    </span>
                    <span
                      id="contentcourse"
                      onClick={coursecontenttrue}
                      className={
                        coursecontent == true
                          ? style.blueclass + " p-3 col text-center  "
                          : " p-3 col text-center  " + style.color
                      }
                      style={{
                        cursor: "pointer",
                        fontSize: "1.2rem",
                        fontWeight: "600",
                      }}
                    >
                      Learning
                    </span>
                    <span
                      id="contentcourse"
                      onClick={setcodingexamtrue}
                      className={
                        codingexam == true
                          ? style.blueclass + " p-3 col text-center  "
                          : " p-3 col text-center  " + style.color
                      }
                      style={{
                        cursor: "pointer",
                        fontSize: "1.2rem",
                        fontWeight: "600",
                      }}
                    >
                      Coding Exam
                    </span>
                    {variation !== 3 && (
                      <span
                        id="contentcourse"
                        onClick={compilertrue}
                        className={
                          compiler == true
                            ? style.blueclass + " p-3 col text-center  "
                            : " p-3 col text-center  " + style.color
                        }
                        style={{
                          cursor: "pointer",
                          fontSize: "1.2rem",
                          fontWeight: "600",
                        }}
                      >
                        CL-Compiler
                      </span>
                    )}
                    <div> </div>
                    {(variation == 0 && variation !== 4) ? (
                      <span
                        id="practicesection"
                        onClick={practicesectiontrue}
                        className={
                          practicesection == true
                            ? style.blueclass + " p-3 col text-center "
                            : " p-3 col text-center  " + style.color
                        }
                        style={{
                          cursor: "pointer",
                          fontSize: "1.2rem",
                          fontWeight: "600",
                        }}
                      >
                        Daily Quiz
                      </span>
                    ) : (
                      <>
                      {variation !== 4 && <>
                        {variation !== 3 && (
                          <span
                            style={{
                              cursor: "pointer",
                              backgroundColor: reference && "#185adb",
                              color: reference && "white",
                              fontSize: "1.1rem",
                              fontWeight: "600"
                            }}
                            onClick={() => {
                              const signupUrl = `https://cantileverlabsexam.thinkexam.com/api/v1/?data={"publicKey":"QAZWSXEDCRFVTGBYtinksaas_1@pt","accessToken":"","requestUrl":"cantileverlabsexam.thinkexam.com","async":false,"apiUrl":"https://cantileverlabsexam.thinkexam.com","service":"Thinkexam","action":"newTestUser","parameter":{"name":"${username}","email":"${useremail}","mobile":"9876542211","password":"MTIzNDU2","courseName":"RK SHO1RT TE1RM2"},"returnType":"json"}`;
                              const signinUrl = `https://cantileverlabsexam.thinkexam.com/index.php?cmd=api/wp-login&api_data={"publicKey":"QAZWSXEDCRFVTGBYtinksaas_1@pt","accessToken":"","requestUrl":"cantileverlabsexam.thinkexam.com","async":false,"apiUrl":"https://cantileverlabsexam.thinkexam.com","service":"Thinkexam","action":"checkLogin","parameter":{"email":"${useremail}","password":"MTIzNDU2"},"returnType":"json"}`;

                              console.log("userName=", username);
                              fetch(signupUrl)
                                .then((res) => res.json())
                                .then((data) => {
                                  console.log(data);
                                  return data?.data?.message;
                                })
                                .then((msg) => {
                                  // console.log(useremail, "msg");
                                  window.location = signinUrl;
                                });
                            }}
                            className={
                              reference == true
                                ? style.blueclass + " p-3 col text-center  "
                                : " p-3 col text-center  " + style.color
                            }
                          >
                            Practice
                          </span>
                        )}

                        </>}
                      </>
                    )}
                    {(variation !== 0 && variation !== 4) && (
                      <span
                        id="practicesection"
                        onClick={practicesectiontrue}
                        className={
                          practicesection == true
                            ? style.blueclass + " p-3 col text-center "
                            : " p-3 col text-center  " + style.color
                        }
                        style={{
                          cursor: "pointer",
                          fontSize: "1.2rem",
                          fontWeight: "600",
                        }}
                      >
                        Daily Quiz
                      </span>
                    )}

                    {RevisionTab ? (
                      <>
                        {(variation !== 0 || variation !== 3 || variation !== 4) && (
                          <span
                            style={{
                              cursor: "pointer",
                              backgroundColor: bookmark && "#185adb",
                              color: bookmark && "white",
                              fontSize: "1.1rem",
                              fontWeight: "600"
                            }}
                            id="bookmark"
                            onClick={bookmarkTrue}
                            className={
                              bookmark == true
                                ? style.blueclass + " p-3 col text-center  "
                                : " p-3 col text-center  " + style.color
                            }
                          >
                            Doubt Solving
                          </span>
                        )}{" "}
                      </>
                    ) : null}
                    {(variation !== 3 && variation!== 4) && (
                      <span
                        style={{
                          cursor: "pointer",
                          backgroundColor: bookmark && "#185adb",
                          color: bookmark && "white",
                          fontSize: "1.1rem",
                          fontWeight: "600"
                        }}
                        id="bookmark"
                        onClick={MockInterviewTrue}
                        className={
                          mockInterview == true
                            ? style.blueclass + " p-3 col text-center  "
                            : " p-3 col text-center  " + style.color
                        }
                      >
                        Mock Interview
                      </span>
                    )}
                  </div>
                ) : (
                  <>
                    <div
                      className="p-2 d-flex flex-row justify-content-around"
                      style={{ cursor: "pointer" }}
                    >
                      <span
                        style={{
                          cursor: "pointer",
                          fontSize: "1.1rem",
                          fontWeight: "600"
                        }}
                        id="overviewcourse"
                        onClick={courseoverviewtrue}
                        className={
                          courseoverview == true
                            ? style.blueclass + " p-3 col text-center "
                            : " p-3 col text-center  " + style.color
                        }
                      >
                        Overview
                      </span>
                      <span
                        style={{
                          cursor: "pointer",
                          fontSize: "1.1rem",
                          fontWeight: "600"
                        }}
                        id="contentcourse"
                        onClick={coursecontenttrue}
                        className={
                          coursecontent === true
                            ? style.blueclass + " p-3 col text-center  "
                            : " p-3 col text-center  " + style.color
                        }
                      >
                        Learning
                      </span>
                      <span
                        style={{
                          cursor: "pointer",
                          backgroundColor: mockTest && "#185adb",
                          color: mockTest && "white",
                          fontSize: "1.1rem",
                          fontWeight: "600"
                        }}
                        id="mocktest"
                        onClick={mocktestTrue}
                        className={
                          mockTest == true
                            ? style.blueclass + " p-3 col text-center  "
                            : " p-3 col text-center  " + style.color
                        }
                      >
                        Quiz
                      </span>
                    </div>
                    <div
                      className="p-2 d-flex flex-row justify-content-around"
                      style={{ 
                        cursor: "pointer",
                        fontSize: "1.1rem",
                        fontWeight: "600"
                      }}
                    >
                      <span
                        style={{
                          cursor: "pointer",
                          backgroundColor: reference && "#185adb",
                          color: reference && "white",
                        }}
                        id="references"
                        onClick={referencestrue}
                        className={
                          reference == true
                            ? style.blueclass + " p-3 col text-center  "
                            : " p-3 col text-center  " + style.color
                        }
                      >
                        E-Books
                      </span>
                      {RevisionTab ? (
                        <span
                          style={{
                            cursor: "pointer",
                            backgroundColor: bookmark && "#185adb",
                            color: bookmark && "white",
                            fontSize: "1.1rem",
                            fontWeight: "600"
                          }}
                          id="bookmark"
                          onClick={bookmarkTrue}
                          className={
                            bookmark == true
                              ? style.blueclass + " p-3 col text-center  "
                              : " p-3 col text-center  " + style.color
                          }
                        >
                          Revision Sessions
                        </span>
                      ) : (
                        <span
                          style={{
                            cursor: "pointer",
                            backgroundColor: bookmark && "#185adb",
                            color: bookmark && "white",
                            fontSize: "1.1rem",
                            fontWeight: "600"
                          }}
                          id="bookmark"
                          onClick={MockInterviewTrue}
                          className={
                            bookmark == true
                              ? style.blueclass + " p-3 col text-center  "
                              : " p-3 col text-center  " + style.color
                          }
                        >
                          Mock Interviews
                        </span>
                      )}
                    </div>
                  </>
                )}

                <div
                  className={`col-xl-12 col-lg-12 col-md-12 col-sm-12 ${style.topics_covered}`}
                >
                  <div>
                    {coursecontent === true && (
                      <References
                        variation={variation}
                        adminornot={isAdmin}
                        setChanged={setChanged}
                        changed={changed}
                        courseID={ID}
                        progressArray={progressArray}
                        video={videos}
                        bigHeading={bigHeading}
                        sections={sections}
                        bookmarkArr={bookmarkArray}
                        userEmail={useremail}
                        userName={username}
                      />
                    )}
                    {courseoverview == true && (
                      <CourseOverview
                        whatyouget={whatyouget}
                        description={description}
                      />
                    )}

                    {
                      codingexam == true && <Allexam id={ ID} />
                    }
                    {reference == true && (
                      <>
                        <KnowledgeReferences
                          video={knowledgeCards}
                          bigHeading={bigHeadingKnowledge}
                          sections={sectionKnowledge}
                        />
                      </>
                    )}
                    {practicesection == true && (
                      <>
                        <PracticeSection courseID={ID} />
                      </>
                    )}
                    {compiler == true && (
                      <div className="my-5">
                        <AllQuestion courseID={ID} />
                      </div>
                    )}

                    {mockTest == true && (
                      <div>
                        <div className="container">
                          <div
                            className="py-3"
                            style={{ backgroundColor: "#00316E" }}
                          >
                            <div className="row">
                              <div
                                className={`col-sm-6 d-flex mx-auto ${style.btnAllignment}`}
                                style={{ fontSize: "1rem" }}
                              >
                                {/* <h5 className="text-white">Quiz Time: </h5> */}
                                <button
                                  className="mx-auto "
                                  style={{
                                    backgroundColor: "#00316E",
                                    color: "white",
                                    border: "none",
                                  }}
                                >
                                  Join the Quiz Now
                                </button>
                              </div>
                            </div>
                          </div>

                          <div
                            className="mx-auto row"
                            style={{ margin: "50px", width: "50%" }}
                          >
                            {variation !== 3 ? (
                              <div>
                                <Card className="text-center col-sm-4">
                                  <Card.Header>Quiz 1</Card.Header>
                                  <Card.Body>
                                    <Card.Title>LCM and HCF</Card.Title>

                                    <a
                                      class="btn btn-sm btn-primary"
                                      href="https://app.sli.do/event/dsnoopr8"
                                    >
                                      <button
                                        variant="primary"
                                        className="btn btn-primary"
                                      >
                                        Join Now
                                      </button>
                                    </a>
                                  </Card.Body>
                                  <Card.Footer className="text-muted">
                                    2 days ago
                                  </Card.Footer>
                                </Card>
                                <br />
                                <Card className="text-center col-sm-4">
                                  <Card.Header>Quiz 2</Card.Header>
                                  <Card.Body>
                                    <Card.Title>GK</Card.Title>

                                    <a
                                      class="btn btn-sm btn-primary"
                                      href="https://app.sli.do/event/dsnoopr8"
                                    >
                                      <button
                                        variant="primary"
                                        className="btn btn-primary"
                                      >
                                        Join Now
                                      </button>
                                    </a>
                                  </Card.Body>
                                  <Card.Footer className="text-muted">
                                    2 days ago
                                  </Card.Footer>
                                </Card>
                                <br />
                                <Card className="text-center col-sm-4">
                                  <Card.Header>Quiz 3</Card.Header>
                                  <Card.Body>
                                    <Card.Title>DSA</Card.Title>

                                    <a
                                      class="btn btn-sm btn-primary"
                                      href="https://app.sli.do/event/dsnoopr8"
                                    >
                                      <button
                                        variant="primary"
                                        className="btn btn-primary"
                                      >
                                        Join Now
                                      </button>
                                    </a>
                                  </Card.Body>
                                  <Card.Footer className="text-muted">
                                    2 days ago
                                  </Card.Footer>
                                </Card>
                                <br />
                                <Card className="text-center col-sm-4">
                                  <Card.Header>Quiz 4</Card.Header>
                                  <Card.Body>
                                    <Card.Title>Advanced DS</Card.Title>

                                    <a
                                      class="btn btn-sm btn-primary"
                                      href="https://app.sli.do/event/dsnoopr8"
                                    >
                                      <button
                                        variant="primary"
                                        className="btn btn-primary"
                                      >
                                        Join Now
                                      </button>
                                    </a>
                                  </Card.Body>
                                  <Card.Footer className="text-muted">
                                    2 days ago
                                  </Card.Footer>
                                </Card>
                                <br />
                                <Card className="text-center col-sm-4">
                                  <Card.Header>Quiz 5</Card.Header>
                                  <Card.Body>
                                    <Card.Title>Interview Questions</Card.Title>

                                    <a
                                      class="btn btn-sm btn-primary"
                                      href="https://app.sli.do/event/dsnoopr8"
                                    >
                                      <button
                                        variant="primary"
                                        className="btn btn-primary"
                                      >
                                        Join Now
                                      </button>
                                    </a>
                                  </Card.Body>
                                  <Card.Footer className="text-muted">
                                    2 days ago
                                  </Card.Footer>
                                </Card>
                              </div>
                            ) : (
                              <>
                                <h1 className="text-center">Coming soon</h1>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    )}

                    {bookmark == true && (
                      <div className="row flex d-flex">
                        <TADoubtSession />
                      </div>
                    )}
                    {mockInterview ? (
                      <IITBMockInterView areaOfinterest={areaOfinterest} />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default MyCourse;

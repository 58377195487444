import React, { useState, useEffect } from "react";
import resetImage from "../Assets/img/Rectangle 1052.png";
import icon_lock from "../Assets/img/Icon feather-lock.png";
import { Redirect, useParams } from "react-router-dom";
import Footer from "./Footer";
import styles from "../Assets/css/signup.module.css";
import { BASE_URL } from "../env";

const ResetPassword = () => {
  const format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

  const { _id, token } = useParams();
  const [password, setPassword] = useState("");
  const [cnfrm_password, setCnfrm_Password] = useState("");
  const [message, setMessage] = useState("");
  const [msg, setMsg] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [isOkay, setIsOkay] = useState(false);

  const [passLength, setPassLength] = useState(false);
  const [specialChar, setSpecialChar] = useState(false);
  const [passDetails, setPassDetails] = useState(false);

  const handlePassword = (event) => {
    let p = event.target.value;

    if ((p.length >= 8 && format.test(p)) || p.length == 0) {
      setSpecialChar(false);
      setPassDetails(false);
      setPassLength(false);
      setIsOkay(true);
    } else if (p.length != 0 && p.length < 8 && !format.test(p)) {
      setPassDetails(true);
      setPassLength(false);
      setSpecialChar(false);
      setIsOkay(false);
    } else if (format.test(p)) {
      setPassLength(true);
      setSpecialChar(false);
      setPassDetails(false);
      setIsOkay(false);
    } else {
      setSpecialChar(true);
      setPassDetails(false);
      setPassLength(false);
      setIsOkay(false);
    }
    setPassword(p);
  };

  async function handleSubmit() {
    if (isOkay) {
      if (password == cnfrm_password) {
        setMsg(false);
        await fetch(
          //"https://cantileverlabs.com/resetpassword/"
            BASE_URL + "/resetpassword/" +
            _id +
            "/" +
            token,

          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              password,
            }),
          }
        )
          .then((res) => res.json())
          .then((data) => {

            if (data.error) {
              setMsg(true);
              setMessage(data.error);
            } else {
              setMsg(true);
              setMessage(data.message + ", Please Login");
              setRedirect(true);
            }
          });
      } else {
        setMsg(true);
        setMessage("Both Passwords Didn't Matched");
        setIsOkay(false);
      }
    }
  }

  return (
    <>
      <div
        className="container-fluid background "
        style={{
          "@media (max-width:767px)": {
            height: "100%",
            alignContent: "center",
            margin: "auto 0",
          },
          height: "100vh",
          alignItems: "center",
          display: "flex",
          alignItems: "center",
          backgroundColor: "#f2f7fb",
        }}
      >
        <div className="container my-5">
          <div className="row align-items-center justify-items-center my-auto h-100">
            {/* <div className="col-lg-4 col-md-4 col-sm-12 mx-auto mt-5 my-auto text-center">
              <img
                src={resetImage}
                alt="reset-password"
                className="mt-5"
                style={{ width: "22rem" }}
              />
            </div> */}
            <div className="col-lg-4 col-md-4 col-sm-12 mx-auto">
              <div className="panel panel-default">
                <div className="panel-body">
                  <div className="text-center">
                    <h3>
                      <img src={icon_lock} className=" lock" />
                    </h3>
                    <h2 className="text-center">Password Reset</h2>
                    <p
                      style={{
                        position: "relative",
                        left: "10%",
                        // fontWeight: "bold",
                        width: "80% ",
                      }}
                    >
                      Enter your Email to receive a Link to reset your password
                    </p>
                    <div className="panel-body">
                      <div className="form-group">
                        <div className="input-group">
                          <input
                            id="password"
                            name="password"
                            placeholder="New Password"
                            type="password"
                            value={password}
                            required
                            style={{
                              width: "100%",
                              padding: "15px",
                              margin: "5px 0",
                              border: "2px solid #ededed",
                              borderRadius: "5px",
                            }}
                            onChange={handlePassword}
                          />
                        </div>
                      </div>

                      <div className="form-group">
                        <div className="input-group">
                          <input
                            id="confirm_password"
                            name="confirm_password"
                            placeholder="Confirm password"
                            style={{
                              width: "100%",
                              padding: "15px",
                              margin: "10px 0",
                              border: "2px solid #ededed",
                              borderRadius: "5px",
                            }}
                            type="password"
                            value={cnfrm_password}
                            required
                            onChange={(e) => {
                              setCnfrm_Password(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      {passDetails === true ? (
                        <p style={{ color: "red" }}>
                          Password must be 8 digits long and contain one special
                          character
                        </p>
                      ) : null}
                      {passLength === true ? (
                        <p style={{ color: "red" }}>
                          Password must be 8 digits long
                        </p>
                      ) : null}
                      {specialChar === true ? (
                        <p style={{ color: "red" }}>
                          Password must contain atleast one special character
                        </p>
                      ) : null}
                      {/* {msg === true ? (
                        <div
                          className={`${styles.formgroup} form-check ml-0 pl-0`}
                        >
                          <div
                            className="form-check-label"
                            htmlFor="exampleCheck1"
                          >
                            {message}
                          </div>
                        </div>
                      ) : null} */}
                      <button
                        className="btn btn-lg btn-warning btn-block"
                        style={{ width: "100%", borderRadius: "5px" }}
                        onClick={handleSubmit}
                      >
                        Reset
                      </button>
                      {redirect ? <Redirect push to="/login" /> : null}

                      {msg == true ? (
                        message === "Password Updated!, Please Login" ? (
                          <div className="form-group form-check m-3 pl-0">
                            <div
                              className="form-check-label p-3 bg-success rounded rounded-2 text-white"
                              htmlFor="exampleCheck1"
                            >
                              {message}
                            </div>
                            <Redirect to="/login" />
                          </div>
                        ) : (
                          <div className="form-group form-check m-3 pl-0">
                            <div
                              className="form-check-label p-3 bg-danger rounded rounded-2 text-white"
                              htmlFor="exampleCheck1"
                            >
                              {message}
                            </div>
                          </div>
                        )
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
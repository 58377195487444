import React, { useContext, useState, useEffect } from "react";
import Tick from "../Assets/img/course/tick.png";
import Cal from "../Assets/img/course/calendar.png";
import CpSyllabus from "../Context/CpSyllabus";
import styles from "../Assets/css/table.module.css";
import Placement_force from "../Assets/PDFs/placement_force.pdf";
import Placement_surge from "../Assets/PDFs/placement_surge.pdf";
import Placement_impact from "../Assets/PDFs/placement_impact.pdf";
import CalBlack from "../Assets/img/course/Icon material-today-black.png";
import clock from "../Assets/img/course/Icon ionic-md-time.png";
import line from "../Assets/img/course/Line 170.png";
import { Redirect, useHistory } from "react-router-dom";
import Syllabus from "./Syllabus";
import displayRazorpay from "../Razorpay";
// import displayCoupon from "../components/Coupons.js";
// import Coupon from "../components/Coupons.js";
import { AuthContext } from "../Context/AuthContext";
import Coupons1 from "../components/Coupons.js";
import Coupons2 from "../components/Coupons.js";
import Coupons3 from "../components/Coupons.js";
// import PaymentTest from "../components/PaymentTest.js";
import brochure from "../Assets/img/Competitive/download_brochure.png";
import { BASE_URL } from "../env";
import firebase from "../firebase";

var startPrice;

function PricingTable() {
  const analytics = firebase.analytics();
  let loaderT1 = false;
  let loaderT2 = false;
  let loaderT3 = false;
  const [showTier1, setShowTier1] = useState(false);
  const [showTier2, setShowTier2] = useState(false);
  const [showTier3, setShowTier3] = useState(false);

  const [pay1, setPay1] = useState(false);
  const [pay2, setPay2] = useState(false);
  const [pay3, setPay3] = useState(false);
  // const [razorpay, setRazorpay] = useState(false)
  // const [redirect, setRedirect] = useState(false);
  // const [courseId, setCourseId] = useState("");
  // const [tierId, setTierId] = useState("");

  let { token } = useContext(AuthContext);
  const [loadT1, setLoadT1] = useState(false);
  const [loadT2, setLoadT2] = useState(false);
  const [loadT3, setLoadT3] = useState(false);
  const [signIn, setSignIn] = useState(false);
  const history = useHistory();
  const [coupon, setCoupon] = useState(false);
  const [tierId, setTierId] = useState("");
  const [amount, setAmount] = useState("");
  const [success, showSuccess] = useState(false);
  let [course, setCourse] = useState("");
  let [tier, setTier] = useState("");
  const [userData, setUserData] = useState({});
  const [open, setOpen] = useState(false);
  const [email, setemail] = useState("");
  const [handlePayment, setHandlePayment] = useState(false);
  const [data, setData] = useState([]);
  const [couponCode, setCouponCode] = useState("");

  var Email_value = "";

  var valueJSON;

  useEffect(() => {
    // data
    //   .then((response) => response.json())
    //   .then((data) => {
    //
    //   });
  }, [data]);

  async function handlePayNow(route, tier) {
    if (route == "placementcourse") {
      setCourse("Placement Course");
    }
    if (!token) {
      setSignIn(true);
    } else {
      await fetch(BASE_URL + "/getFromRoute?route=" + route)
        .then((response) => response.json())
        .then((data) => {
          const tiers = data.course.types;
          const curr = tiers[tier];
          startPrice = curr.amount;

          setTierId(curr._id);
          setAmount(curr.amount);

          if (tier == "tier1") {
            setTier("Force");
            setPay1(true);
          } else if (tier == "tier2") {
            setTier("Surge");
            setPay2(true);
          } else {
            setTier("Impact");
            setPay3(true);
          }
        });
    }
    setCoupon(true);
  }
  function handlePaymentPopup(props) {
    showSuccess(true);
  }

  return (
    <div>
      {/* {coupon === true ? ( */}
      {handlePayment && (
        <Redirect
          to={{
            pathname: `/order/${data.receipt}`,
            state: {
              data: data,
              amount: amount,
              couponCode: couponCode,
            },
          }}
        />
      )}
      <Coupons1
        course={course}
        tier={tier}
        setCoupon={setCoupon}
        tierId={tierId}
        amount={startPrice}
        token={token}
        setLoadT1={setLoadT1}
        setLoadT2={setLoadT2}
        setLoadT3={setLoadT3}
        setHandlePayment={setHandlePayment}
        setData={setData}
        setCouponCode={setCouponCode}
        show={pay1}
        onClose={() => setPay1(false)}
      />
      <Coupons2
        course={course}
        tier={tier}
        setCoupon={setCoupon}
        tierId={tierId}
        amount={startPrice}
        token={token}
        setLoadT1={setLoadT1}
        setLoadT2={setLoadT2}
        setLoadT3={setLoadT3}
        setHandlePayment={setHandlePayment}
        setData={setData}
        setCouponCode={setCouponCode}
        show={pay2}
        onClose={() => setPay2(false)}
      />
      <Coupons3
        course={course}
        tier={tier}
        setCoupon={setCoupon}
        tierId={tierId}
        amount={startPrice}
        token={token}
        setLoadT1={setLoadT1}
        setLoadT2={setLoadT2}
        setLoadT3={setLoadT3}
        setHandlePayment={setHandlePayment}
        setData={setData}
        setCouponCode={setCouponCode}
        show={pay3}
        onClose={() => setPay3(false)}
      />

      {/* ) : null} */}

      {signIn === true ? (
        <Redirect
          to={{
            pathname: "/signin",
            state: { prev: "/placementcourse", fromPT: true },
          }}
        />
      ) : null}
      <div className="MOVEPRICING container justify-content-center my-5">
        <div style={{ textAlign: "center" }} className="my-5">
          <h2 style={{ fontWeight: "900" }}>Select your Package</h2>
        </div>

        <div className={styles.fordesktopviewJ}>
          <div
            style={{
              margin: "auto",
              textAlign: "center",
              height: "5vw",
              display: "flex",
            }}
            // row
            className={` bg-info ${styles.tableheadingJ} justify-content-center mx-0`}
          >
            <div
              style={{ textAlign: "left" }}
              className="col-xl-4 col-lg-4 col-md-4 col-sm-2 col-2 p-3 mx-1"
            >
              Features
            </div>
            <div
              style={{ textAlign: "center" }}
              className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-1 p-3 mx-1 "
            >
              Force
            </div>
            <div
              style={{ textAlign: "center" }}
              className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-1 p-3 mx-1 "
            >
              Surge
            </div>
            <div
              style={{ textAlign: "center" }}
              className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-1 p-3 mx-1 "
            >
              Impact
            </div>
          </div>

          <div
            style={{
              margin: "auto",
              textAlign: "center",
              height: "5vw",
              display: "flex",
            }}
            // row
            className={`justify-content-center bg-white ${styles.allrowsJ} mx-0 p-0`}
          >
            <div
              style={{
                textAlign: "left",
                // position: "relative",
                // height: "0.5vw",
              }}
              className={`col-xl-4 col-lg-4 col-md-4 col-sm-3 p-3 mx-xl-2 ${styles.featuresJ}`}
            >
              Learn C++ With OOPS
            </div>
            <div
              style={{ textAlign: "center" }}
              className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2"
            ></div>
            <div
              style={{ textAlign: "center" }}
              className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2"
            >
              <img src={Tick} alt="" />
            </div>
            <div
              style={{ textAlign: "center" }}
              className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2"
            >
              <img src={Tick} alt="" />
            </div>
          </div>

          <div
            style={{
              margin: "auto",
              textAlign: "center",
              height: "5vw",
              display: "flex",
            }}
            // row
            className={`justify-content-center ${styles.evenrowJ} ${styles.allrowsJ} mx-0`}
          >
            <div
              style={{ textAlign: "left" }}
              className={`col-xl-4 col-lg-4 col-md-4 col-sm-3 p-3 mx-xl-2 ${styles.featuresJ}`}
            >
              Basic & Advanced DSA
            </div>
            <div
              style={{ textAlign: "center" }}
              className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2 rows-border-left-J"
            >
              <img src={Tick} alt="" />
            </div>
            <div
              style={{ textAlign: "center" }}
              className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2 rows-border-left-J"
            >
              <img src={Tick} alt="" />
            </div>
            <div
              style={{ textAlign: "center" }}
              className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2 rows-border-left-J"
            >
              <img src={Tick} alt="" />
            </div>
          </div>

          <div
            style={{
              margin: "auto",
              textAlign: "center",
              height: "5vw",
              display: "flex",
            }}
            // row
            className={` justify-content-center bg-white ${styles.allrowsJ} mx-0`}
          >
            <div
              style={{ textAlign: "left" }}
              className={`col-xl-4 col-lg-4 col-md-4 col-sm-3 p-3 mx-xl-2 ${styles.featuresJ}`}
            >
              OS,DBMS Computer Networks
            </div>
            <div
              style={{ textAlign: "center" }}
              className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2 "
            >
              <img src={Tick} alt="" />
            </div>
            <div
              style={{ textAlign: "center" }}
              className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2"
            >
              <img src={Tick} alt="" />
            </div>
            <div
              style={{ textAlign: "center" }}
              className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2"
            >
              <img src={Tick} alt="" />
            </div>
          </div>

          <div
            style={{
              margin: "auto",
              textAlign: "center",
              height: "5vw",
              display: "flex",
            }}
            // row
            className={`justify-content-center ${styles.evenrowJ} ${styles.allrowsJ} mx-0`}
          >
            <div
              style={{ textAlign: "left" }}
              className={`col-xl-4 col-lg-4 col-md-4 col-sm-3 p-3 mx-xl-2 ${styles.featuresJ}`}
            >
              Aptitude
            </div>
            <div
              style={{ textAlign: "center" }}
              className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2 rows-border-left-J"
            >
              <img src={Tick} alt="" />
            </div>
            <div
              style={{ textAlign: "center" }}
              className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2 rows-border-left-J"
            >
              <img src={Tick} alt="" />
            </div>
            <div
              style={{ textAlign: "center" }}
              className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2 rows-border-left-J"
            >
              <img src={Tick} alt="" />
            </div>
          </div>
          <div
            style={{
              margin: "auto",
              textAlign: "center",
              height: "5vw",
              display: "flex",
            }}
            // row
            className={` justify-content-center bg-white ${styles.allrowsJ} mx-0`}
          >
            <div
              style={{ textAlign: "left" }}
              className={`col-xl-4 col-lg-4 col-md-4 col-sm-3 p-3 mx-xl-2 ${styles.featuresJ}`}
            >
              TA Support
            </div>
            <div
              style={{ textAlign: "center" }}
              className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2 "
            >
              <img src={Tick} alt="" />
            </div>
            <div
              style={{ textAlign: "center" }}
              className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2"
            >
              <img src={Tick} alt="" />
            </div>
            <div
              style={{ textAlign: "center" }}
              className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2"
            >
              <img src={Tick} alt="" />
            </div>
          </div>

          <div
            style={{
              margin: "auto",
              textAlign: "center",
              height: "5vw",
              display: "flex",
            }}
            //  row
            className={`justify-content-center ${styles.evenrowJ} mx-0`}
          >
            <div
              style={{ textAlign: "left" }}
              className={`col-xl-4 col-lg-4 col-md-4 col-sm-3  p-3 mx-xl-2 ${styles.featuresJ}`}
            >
              Solve questions with Experts
            </div>
            <div
              style={{ textAlign: "center" }}
              className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2 rows-border-left-J"
            >
              <p>50+ Questions</p>
            </div>
            <div
              style={{ textAlign: "center" }}
              className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2 rows-border-left-J"
            >
              <p>100+ Questions</p>
            </div>
            <div
              style={{ textAlign: "center" }}
              className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2 rows-border-left-J"
            >
              <p>150+ Questions</p>
            </div>
          </div>

          <div
            style={{
              margin: "auto",
              textAlign: "center",
              height: "5vw",
              display: "flex",
            }}
            // row
            className={` justify-content-center bg-white ${styles.allrowsJ} mx-0`}
          >
            <div
              style={{ textAlign: "left" }}
              className={`col-xl-4 col-lg-4 col-md-4 col-sm-3 p-3 mx-xl-2 ${styles.featuresJ}`}
            >
              1:1 Mentorship Support
            </div>
            <div
              style={{ textAlign: "center" }}
              className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2"
            ></div>
            <div
              style={{ textAlign: "center" }}
              className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2"
            ></div>
            <div
              style={{ textAlign: "center" }}
              className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2"
            >
              <img src={Tick} alt="" />
            </div>
          </div>

          <div
            style={{
              margin: "auto",
              textAlign: "center",
              height: "5vw",
              display: "flex",
            }}
            // row
            className={`justify-content-center  ${styles.allrowsJ} ${styles.evenrowJ} mx-0`}
          >
            <div
              style={{ textAlign: "left" }}
              className={`col-xl-4 col-lg-4 col-md-4 col-sm-3 p-3 mx-xl-2 ${styles.featuresJ}`}
            >
              Mock Interview Videos
            </div>
            <div
              style={{ textAlign: "center" }}
              className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2 rows-border-left-J"
            >
              <p>10+ Videos</p>
            </div>
            <div
              style={{ textAlign: "center" }}
              className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2 rows-border-left-J"
            >
              <p>20+ Videos</p>
            </div>
            <div
              style={{ textAlign: "center" }}
              className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2 rows-border-left-J"
            >
              <p>30+ Videos</p>
            </div>
          </div>

          <div
            style={{
              margin: "auto",
              textAlign: "center",
              height: "5vw",
              display: "flex",
            }}
            // row
            className={` justify-content-center bg-white ${styles.allrowsJ} mx-0`}
          >
            <div
              style={{ textAlign: "left" }}
              className={`col-xl-4 col-lg-4 col-md-4 col-sm-3 p-3 mx-xl-2 ${styles.featuresJ}`}
            >
              1:1 Mock Interview
            </div>
            <div
              style={{ textAlign: "center" }}
              className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2"
            ></div>
            <div
              style={{ textAlign: "center" }}
              className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2"
            >
              <p>2 Interviews</p>
            </div>
            <div
              style={{ textAlign: "center" }}
              className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2"
            >
              <p>4 Interviews</p>
            </div>
          </div>

          <div
            style={{
              margin: "auto",
              textAlign: "center",
              height: "5vw",
              display: "flex",
            }}
            // row
            className={`justify-content-center ${styles.evenrowJ} ${styles.allrowsJ} mx-0`}
          >
            <div
              style={{ textAlign: "left" }}
              className={`col-xl-4 col-lg-4 col-md-4 col-sm-3 p-3 mx-xl-2 ${styles.featuresJ}`}
            >
              1:1 Resume preparation
            </div>
            <div
              style={{ textAlign: "center" }}
              className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2 rows-border-left-J"
            ></div>
            <div
              style={{ textAlign: "center" }}
              className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2 rows-border-left-J"
            ></div>
            <div
              style={{ textAlign: "center" }}
              className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2 rows-border-left-J"
            >
              <img src={Tick} alt="" />
            </div>
          </div>

          <div
            style={{
              margin: "auto",
              textAlign: "center",
              height: "5vw",
              display: "flex",
            }}
            // row
            className={` justify-content-center bg-white ${styles.allrowsJ} mx-0`}
          >
            <div
              style={{ textAlign: "left" }}
              className={`col-xl-4 col-lg-4 col-md-4 col-sm-3 p-3 mx-xl-2 ${styles.featuresJ}`}
            >
              Soft skill sessions
            </div>
            <div
              style={{ textAlign: "center" }}
              className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2 rows-border-left-J"
            ></div>
            <div
              style={{ textAlign: "center" }}
              className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2 rows-border-left-J"
            >
              <p>3 sessions</p>
            </div>
            <div
              style={{ textAlign: "center" }}
              className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2 rows-border-left-J"
            >
              <p>6 sessions</p>
            </div>
          </div>
          <div
            style={{
              margin: "auto",
              textAlign: "center",
              height: "5vw",
              display: "flex",
            }}
            //row
            className={`justify-content-center ${styles.evenrowJ} mx-0`}
          >
            <div
              style={{ textAlign: "left" }}
              className={`col-xl-4 col-lg-4 col-md-4 col-sm-3 p-3 mx-xl-2 ${styles.featuresJ}`}
            >
              Months
            </div>
            <div
              style={{ textAlign: "center" }}
              className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2 rows-border-left-J"
            >
              <p>4 Months</p>
            </div>
            <div
              style={{ textAlign: "center" }}
              className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2 rows-border-left-J"
            >
              <p>5 Months</p>
            </div>
            <div
              style={{ textAlign: "center" }}
              className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2 rows-border-left-J"
            >
              <p>6 Months</p>
            </div>
          </div>

          <div
            style={{
              margin: "auto",
              textAlign: "center",
              height: "5vw",
              display: "flex",
            }}
            // row
            className=" justify-content-center bg-white mx-0"
          >
            <div
              style={{ textAlign: "left" }}
              className={`col-xl-4 col-lg-4 col-md-4 col-sm-3 p-3 mx-xl-2 ${styles.featuresJ}`}
            >
              Hours of Content
            </div>
            <div
              style={{ textAlign: "center" }}
              className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2 rows-border-left-J"
            >
              <p>80+ Hours</p>
            </div>
            <div
              style={{ textAlign: "center" }}
              className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2 rows-border-left-J"
            >
              <p>100+ Hours</p>
            </div>
            <div
              style={{ textAlign: "center" }}
              className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2 rows-border-left-J"
            >
              <p>130+ Hours</p>
            </div>
          </div>
          <div
            style={{
              margin: "auto",
              textAlign: "center",
              height: "5vw",
              display: "flex",
            }}
            // row
            className={`justify-content-center ${styles.evenrowJ} mx-0`}
          >
            <div
              style={{ textAlign: "left" }}
              className={`col-xl-3 col-lg-3 col-md-3 col-sm-3 p-3 mx-xl-2 ${styles.featuresJ}`}
            >
              Price
            </div>
            <div
              style={{ textAlign: "center" }}
              className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2"
            >
              <p
                style={{
                  fontWeight: "900",
                  fontSize: "1.5vw",
                  position: "relative",
                  left: "35%",
                }}
              >
                13,999/-
              </p>
            </div>
            <div
              style={{ textAlign: "center" }}
              className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2"
            >
              <p
                style={{
                  fontWeight: "900",
                  fontSize: "1.5vw",
                  position: "relative",
                  left: "35%",
                }}
              >
                19,999/-
              </p>
            </div>
            <div
              style={{ textAlign: "center" }}
              className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2"
            >
              <p
                style={{
                  fontWeight: "900",
                  fontSize: "1.5vw",
                  position: "relative",
                  left: "35%",
                }}
              >
                24,999/-
              </p>
            </div>
          </div>

          <div
            style={{
              margin: "auto",
              textAlign: "center",
              height: "5vw",
              display: "flex",
            }}
            // row
            className={`justify-content-center ${styles.payJ} bg-white mx-0`}
          >
            <div
              style={{ textAlign: "left" }}
              className="col-xl-4 col-lg-4 col-md-4 col-sm-3 p-3 mx-xl-2"
            ></div>
            <div
              onClick={() => {
                analytics.logEvent("Pay_Now_Force", {
                  type: "button",
                  name: "Pay Now Force button",
                  content: "Pay Now button on Placement course page",
                });
              }}
              style={{ textAlign: "center" }}
              className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2 rows-border-left-J"
            >
              {pay1 === false ? (
                <button
                  className={`btn btn-warning px-1 ${styles.sylbtnJ}`}
                  onClick={() => {
                    // loaderT1 = true;
                    // setLoadT1(true);
                    handlePayNow("placementcourse", "tier1");
                  }}
                  // onClick={() => setPay1(true)}
                >
                  Pay Now
                </button>
              ) : (
                <button className="register-btn" type="button" disabled>
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <span className="sr-only">Loading...</span>
                </button>
              )}
            </div>
            <div
              onClick={() => {
                analytics.logEvent("Pay_Now_Surge", {
                  type: "button",
                  name: "Pay Now Surge button",
                  content: "Pay Now Surge button on Placement course page",
                });
              }}
              style={{ textAlign: "center" }}
              className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2 rows-border-left-J"
            >
              {pay2 === false ? (
                <button
                  className={`btn btn-warning px-1 ${styles.sylbtnJ}`}
                  onClick={() => {
                    loaderT2 = true;
                    setLoadT2(true);
                    handlePayNow("placementcourse", "tier2");
                  }}
                  // onClick={() => setOpen(true)}
                >
                  Pay Now
                </button>
              ) : (
                <button className="register-btn" type="button" disabled>
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <span className="sr-only">Loading...</span>
                </button>
              )}
            </div>
            <div
              onClick={() => {
                analytics.logEvent("Pay_Now_Impact", {
                  type: "button",
                  name: "Pay Now Impact button",
                  content: "Pay Now Impact button on Placement course page",
                });
              }}
              style={{ textAlign: "center" }}
              className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2 rows-border-left-J"
            >
              {pay3 === false ? (
                <button
                  className={`btn btn-warning px-1 ${styles.sylbtnJ}`}
                  onClick={() => {
                    loaderT3 = true;
                    setLoadT3(true);
                    handlePayNow("placementcourse", "tier3");
                  }}
                >
                  Pay Now
                </button>
              ) : (
                <button className="register-btn" type="button" disabled>
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <span className="sr-only">Loading...</span>
                </button>
              )}
            </div>
          </div>

          <div
            style={{
              margin: "auto",
              textAlign: "center",
              height: "7vw",
              display: "flex",
            }}
            // row
            className={`justify-content-center ${styles.moneyBackJ}  ${styles.evenrowJ} mx-0`}
          >
            <div
              style={{ textAlign: "left" }}
              className="col-xl-3 col-lg-3 col-md-3 col-sm-3 p-3 mx-xl-2 "
            ></div>
            <div
              style={{
                textAlign: "center",
                position: "relative",
                left: "3%",
              }}
              className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2 d-flex"
            >
              <img src={Cal} alt="" />
              <p style={{ fontSize: "1vw", position: "relative", left: "2%" }}>
                7 day Money back Guarantee
              </p>
            </div>
            <div
              style={{ textAlign: "center", position: "relative", left: "3%" }}
              className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2 d-flex"
            >
              <img src={Cal} alt="" />
              <p style={{ fontSize: "1vw", position: "relative", left: "2%" }}>
                7 day Money back Guarantee
              </p>
            </div>
            <div
              style={{ textAlign: "center", position: "relative", left: "3%" }}
              className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2 d-flex"
            >
              <img src={Cal} alt="" />
              <p style={{ fontSize: "1vw", position: "relative", left: "2%" }}>
                7 day Money back Guarantee
              </p>
            </div>
          </div>

          <div
            style={{
              margin: "auto",
              textAlign: "center",
              height: "6vw",
              display: "flex",
            }}
            // row
            className={` justify-content-center ${styles.SyllabusJ} bg-white mx-0`}
          >
            <div
              style={{ textAlign: "left" }}
              className="col-xl-4 col-lg-4 col-md-4 col-sm-3 p-3 mx-xl-2"
            ></div>

            <div
              onClick={() => {
                analytics.logEvent("Syllabus_Force", {
                  type: "button",
                  name: "Syllabus Force button",
                  content: "Syllabus Force button on Placement course page",
                });
              }}
              style={{ textAlign: "center" }}
              className="col-xl-2 col-lg-2 col-md-2 col-sm-2 py-3 px-0 mx-xl-2 rows-border-left-J"
            >
              <a
                target="__blank"
                href="https://courses-pdfs.s3.ap-south-1.amazonaws.com/placement_force.pdf"
                style={{ textAlign: "center", width: "90%", marginLeft: "6px" }}
                className={`btn btn-outline-info ${styles.sylbtnJ}`}
              >
                Syllabus
              </a>
              {/* <CpSyllabus
                onClose={() => setShowTier1(false)}
                show={showTier1}
              />
             */}
            </div>

            <div
              onClick={() => {
                analytics.logEvent("Syllabus_Surge", {
                  type: "button",
                  name: "Syllabus Surge button",
                  content: "Syllabus surge button on Placement course page",
                });
              }}
              style={{ textAlign: "center" }}
              className="col-xl-2 col-lg-2 col-md-2 py-3 px-0 mx-xl-2 col-sm-2 rows-border-left-J"
            >
              {" "}
              <a
                target="__blank"
                href="https://courses-pdfs.s3.ap-south-1.amazonaws.com/placement_surge.pdf"
                style={{ textAlign: "center", width: "90%", marginLeft: "6px" }}
                className={`btn btn-outline-info ${styles.sylbtnJ}`}
              >
                Syllabus
              </a>
            </div>
            <div
              onClick={() => {
                analytics.logEvent("Syllabus_Impact", {
                  type: "button",
                  name: "Syllabus Impact button",
                  content: "Syllabus Impact button on Placement course page",
                });
              }}
              style={{ textAlign: "center" }}
              className="col-xl-2 col-lg-2 col-md-2 py-3 px-0 mx-xl-2 col-sm-2 rows-border-left-J"
            >
              <a
                target="__blank"
                href=" https://courses-pdfs.s3.ap-south-1.amazonaws.com/placement_impact.pdf"
                style={{ textAlign: "center", width: "90%" }}
                className={`btn btn-outline-info ${styles.sylbtnJ}`}
              >
                Syllabus
              </a>
            </div>
          </div>
          <a
            target="__blank"
            href="https://courses-pdfs.s3.ap-south-1.amazonaws.com/placement_course_brochure.pdf"
          >
            <div
              style={{
                display: "flex",
                borderRadius: "25px",
                width: "25%",
                height: "50px",
                background: "black",
                justifyContent: "center",
                paddingTop: "0.6%",
                margin: "auto",
                paddingLeft: "2%",
              }}
            >
              <p
                style={{
                  objectFit: "contain",
                  fontFamily: "Poppins",
                  fontSize: "1vw",
                  fontWeight: "bold",
                  color: "white",
                  margin: "auto",
                  whiteSpace: "nowrap",
                  paddinLeft: "2%",
                }}
              >
                Download Brochure
              </p>
              <img
                style={{
                  objectFit: "contain",
                  height: "1.8vw",
                  // padding: "0.7%",
                  paddingRight: "5%",
                  margin: "auto",
                }}
                src={brochure}
                alt=""
              />
            </div>
          </a>
        </div>
        {/* <CpSyllabus
          tiername="Force"
          onClose={() => setShowTier1(false)}
          show={showTier1}
        />
        <CpSyllabus
          tiername="Surge"
          onClose={() => setShowTier2(false)}
          show={showTier2}
        />
        <CpSyllabus
          tiername="Impact"
          onClose={() => setShowTier3(false)}
          show={showTier3}
        /> */}
      </div>

      <div className={`container-fluid ${styles.formobileviewJ}`}>
        <div className={styles.graymobileJ}>
          <div className="row justify-content-center mobiletableJ  my-4 ">
            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-8 col-9  mx-3 px-4 py-2 my-2">
              <div>
                <h3>Force</h3>
                <p>
                  Basic & Advanced DSA, Live Classes, 50+ Questions, TA Support
                </p>
              </div>

              <div>
                <div className={`d-flex ${styles.mobiletimeJ}`}>
                  <p className={styles.mobileclockJ}>
                    <img src={clock} alt="" /> 80+ Hours
                  </p>
                  <p className={styles.mobilecalJ}>
                    <img src={CalBlack} alt="" />4 Months
                  </p>
                </div>

                <div className={`d-flex ${styles.syllabusmobileJ}`}>
                  <div className={`${styles.pricemobileJ} mx-0`}>
                    {loadT1 === false ? (
                      <button
                        style={{
                          color: "white",
                          border: "none",
                          backgroundColor: "#00a2c8",
                          textAlign: "center",
                        }}
                        className={`btn btn-warning px-1 ${styles.sylbtnJ}`}
                        onClick={() => {
                          loaderT1 = true;
                          setLoadT1(true);
                          handlePayNow("placementcourse", "tier1");
                        }}
                      >
                        13,999/-
                      </button>
                    ) : (
                      <button
                        style={{
                          backgroundColor: "#00a2c8",
                        }}
                        className={`btn btn-warning px-1 ${styles.sylbtnJ}`}
                        type="button"
                        disabled
                      >
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span className="sr-only">Loading...</span>
                      </button>
                    )}
                  </div>
                  <a
                    target="__blank"
                    href={Placement_force}
                    style={{ textAlign: "center" }}
                    className={`btn btn-outline-info ${styles.sylbtnmob}`}
                  >
                    Syllabus
                  </a>
                </div>
              </div>

              <p className={styles.bluemobiletextJ}>
                7 day Money back Guarantee
              </p>
            </div>
            <div className="col-sm-2 col-1 blue-mobile-J">
              <img src={line} alt="" />

              {/* <a
                target="__blank"
                href={Placement_force}
                style={{ textAlign: "center" }}
                className="btn btn-outline-info syl-btn-mob"
              >
                Syllabus
              </a> */}
            </div>
          </div>
        </div>

        <div className={styles.graymobileJ}>
          <div
            className={`row justify-content-center ${styles.mobiletableJ} my-4`}
          >
            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-8 col-9  mx-3 px-4 py-2 my-2">
              <div>
                <h3>Surge</h3>
                <p>
                  Basic & Advanced DSA, Learn C++, 100+ Questions, Mock
                  Interview Videos
                </p>
              </div>

              <div className={`d-flex ${styles.mobiletimeJ}`}>
                <p className={styles.mobileclockJ}>
                  <img src={clock} alt="" />
                  100+ Hours
                </p>
                <p className={styles.mobilecalJ}>
                  <img src={CalBlack} alt="" />5 Months
                </p>
              </div>

              <div className={`d-flex ${styles.syllabusmobileJ}`}>
                <div className={`${styles.pricemobileJ} mx-0`}>
                  {loadT2 === false ? (
                    <button
                      style={{
                        color: "white",
                        border: "none",
                        backgroundColor: "#00a2c8",
                        textAlign: "center",
                      }}
                      onClick={() => {
                        loaderT2 = true;
                        setLoadT2(true);
                        handlePayNow("placementcourse", "tier2");
                      }}
                      className={`btn btn-warning px-1 ${styles.sylbtnJ}`}
                    >
                      19,999/-
                    </button>
                  ) : (
                    <button
                      style={{
                        backgroundColor: "#00a2c8",
                      }}
                      className={`btn btn-warning px-1 ${styles.sylbtnJ}`}
                      type="button"
                      disabled
                    >
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span className="sr-only">Loading...</span>
                    </button>
                  )}
                </div>
                <a
                  target="__blank"
                  href={Placement_surge}
                  style={{ textAlign: "center" }}
                  className={`btn btn-outline-info ${styles.sylbtnmob}`}
                >
                  Syllabus
                </a>
              </div>
              <p className={styles.bluemobiletextJ}>
                7 day Money back Guarantee
              </p>
            </div>

            <div className="col-sm-2 col-1 blue-mobile-J">
              <img src={line} alt="" />
            </div>
          </div>
        </div>

        <div className={styles.graymobileJ}>
          <div
            className={`row justify-content-center ${styles.mobiletableJ} my-4`}
          >
            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-8 col-9  mx-3 px-4 py-2 my-2">
              <div>
                <h3>Impact</h3>
                <p>
                  Basic & Advanced DSA, Learn C++, 150+ Questions, 1:1
                  Mentornship
                </p>
              </div>

              <div className={`d-flex ${styles.mobiletimeJ}`}>
                <p className={styles.mobileclockJ}>
                  <img src={clock} alt="" />
                  130+ Hours
                </p>
                <p className={styles.mobilecalJ}>
                  <img src={CalBlack} alt="" />6 Months
                </p>
              </div>

              <div className={`d-flex ${styles.syllabusmobileJ}`}>
                <div className={`${styles.pricemobileJ} mx-0`}>
                  {loadT3 === false ? (
                    <button
                      style={{
                        color: "white",
                        border: "none",
                        backgroundColor: "#00a2c8",
                        textAlign: "center",
                      }}
                      className={`btn btn-warning px-1 ${styles.sylbtnJ}`}
                      onClick={() => {
                        loaderT3 = true;
                        setLoadT3(true);
                        handlePayNow("placementcourse", "tier3");
                      }}
                    >
                      24,999/-
                    </button>
                  ) : (
                    <button
                      style={{
                        backgroundColor: "#00a2c8",
                      }}
                      className={`btn btn-warning px-1 ${styles.sylbtnJ}`}
                      type="button"
                      disabled
                    >
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span className="sr-only">Loading...</span>
                    </button>
                  )}
                </div>
                <a
                  target="__blank"
                  href={Placement_impact}
                  style={{ textAlign: "center" }}
                  className={`btn btn-outline-info ${styles.sylbtnmob}`}
                >
                  Syllabus
                </a>
              </div>
              <p className={styles.bluemobiletextJ}>
                7 day Money back Guarantee
              </p>
            </div>

            <div className="col-sm-2 col-1 blue-mobile-J">
              <img src={line} alt="" />
            </div>
          </div>
        </div>
        <a
          target="__blank"
          href="https://courses-pdfs.s3.ap-south-1.amazonaws.com/placement_course_brochure.pdf"
        >
          <div
            style={{
              display: "flex",
              borderRadius: "25px",
              width: "70%",
              height: "40px",
              background: "black",
              justifyContent: "center",
              margin: "auto",
              paddingTop: "0.6%",
              marginBottom: "5%",
            }}
          >
            <p
              style={{
                objectFit: "contain",
                fontFamily: "Poppins",
                fontSize: "medium",
                fontWeight: "bold",
                color: "white",
                margin: "auto",
                whiteSpace: "nowrap",
                paddinLeft: "2%",
              }}
            >
              Download Brochure
            </p>
            <img
              style={{
                objectFit: "contain",
                // height: "2.8vw",
                dingRight: "5%",
                margin: "auto",
              }}
              src={brochure}
              alt=""
            />
          </div>
        </a>

        {/* x */}
      </div>
    </div>
  );
}

export default PricingTable;

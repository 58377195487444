import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { BASE_URL } from "../env";
import Img1 from "../Assets/img/hybridexam/pic1.png";
import Img2 from "../Assets/img/hybridexam/pic2.png";
import Img3 from "../Assets/img/hybridexam/pic3.png";
import Img4 from "../Assets/img/hybridexam/pic4.png";
import "../Assets/scss/QuizResult.scss";
import axios from "axios";
import { decode } from "html-entities";

const ExamSubmitted = ({ hidenavbar }) => {
  const { id } = useParams("id");
  const history = useHistory();
  const [totalQuestion, setTotalQuestion] = useState([]);
  const [scoringArray, setScoringArray] = useState([]);
  const [testCases, setTestCases] = useState(0);
  const [outOfScore, setOutOfScore] = useState(0);
  const [score, setScore] = useState(0);

  useEffect(() => {
    hidenavbar(true);
    return () => {
      hidenavbar(false);
    };
  }, [hidenavbar]);

  useEffect(() => {
    const codingData = async () => {
      try {
        const res = await axios({
          method: "get",
          url: `${BASE_URL}/getCompilerTestResultByExamId/${id}`,
          headers: {
            "Content-type": "application/json",
            authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        if (res.data.examId.displayResult === false) {
          history.push("/profile");
        }
        setScore(res.data.totalScore);
        setTotalQuestion(res.data.examId.questions);
        setScoringArray(res.data.scoring);
        setOutOfScore(res.data.examId.outOfScore);
      } catch (error) {
        if (error.response.status === 403) {
          history.push("/profile");
        }
        console.log(error);
      }
    };
    codingData();
  }, [id]);

  useEffect(() => {
    totalQuestion.forEach((val) => {
      setTestCases((preVal) => {
        return preVal + val.testcases[0][1].length;
      });
    });
  }, [totalQuestion]);

  const percentage = (score / outOfScore) * 100;
  let CodeResult = "Suppp";
  if (percentage >= 75) {
    CodeResult = "Distinction";
  } else if (percentage >= 50) {
    CodeResult = "Good";
  } else if (percentage >= 30) {
    CodeResult = "Can do better";
  } else {
    CodeResult = "Should improve";
  }

  return (
    <>
      <div id="quizResult">
        <div className="headerBanner">Your Final Result</div>
        <div className="subHeader">
          <div className="row gx-3">
            <SubCard imgsrc={Img3} title="1st" text="Attempts" />
            <SubCard
              imgsrc={Img4}
              title={`${score}/${outOfScore}`}
              text="Marks Obtained"
            />
            <SubCard imgsrc={Img1} title={`${percentage}%`} text="Your Score" />
            <SubCard imgsrc={Img2} title={CodeResult} text="Result" />
          </div>
        </div>
        <div className="headerBanner mt-3 mb-1">Your Solution</div>
        {scoringArray.map((val, index) => {
          return (
            <>
              <div className="codingDetails" key={index}>
                <div className="codingDetailsTitle">
                  {
                    totalQuestion.filter(
                      (ques) => val.questionId === ques._id
                    )[0].name
                  }
                </div>
                <div className="codingDetailsText">{decode(val.code)}</div>
                <div className="codingDetailsDesc">
                  <div className="codingDetailsLeft">Test cases solved</div>
                  <div className="codingDetailsRight">
                    {val.testcasessolved}
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </div>
    </>
  );
};

const SubCard = (props) => {
  return (
    <>
      <div className="col-lg-3 col-sm-6 mb-0 subHeaderCards">
        <div className="subHeaderCard">
          <img src={props.imgsrc} className="img-fluid icon" alt="icon" />
          <div className="title">{props.title}</div>
          <div className="text">{props.text}</div>
        </div>
      </div>
    </>
  );
};

export default ExamSubmitted;

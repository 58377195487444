import { useEffect, useState } from "react";
import ProgressOverview from "./components/ProgressOverview";
import PowerBatch from "./components/PowerBatch";
import StudentScore from "./components/StudentScore";
import ChartDetails from "./components/ChartDetails";
import { useSelector } from "react-redux";
import configAxios from "../axios.config";
import { getColor } from "./helper";
import {
  AssignmentScoreData,
  AssignmentTopStudents,
  AssignmentTotalSubmission,
} from "./PowerBI_assignment_data";
import { ProgressData } from "./PowerBI_home_data";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const PowerAssignment = () => {
  const [totalAssignments, setTotalAssignments] = useState({
    total: 0,
    percent: 0,
  });

  const [totalSubmission, setTotalSubmission] = useState("");
  const [students, setStudents] = useState([]);
  const [data, setData] = useState({
    labels: ["Assignment Score"],
    datasets: [
      {
        label: "0-50%",
        data: [0],
        backgroundColor: "#FF5858",
        borderColor: "#FF5858",
        borderWidth: 1,
        borderRadius: 20,
        topLeft: 5,
      },
      {
        label: "50-75%",
        data: [0],
        backgroundColor: "#FFB636",
        borderColor: "#FFB636",
        borderWidth: 1,
        borderRadius: 20,
        topLeft: 5,
      },
      {
        label: "75-100%",
        data: [0],
        backgroundColor: "#24B47E",
        borderColor: "#24B47E",
        borderWidth: 1,
        borderRadius: 20,
        topLeft: 5,
      },
    ],
  });
  const selector = useSelector((reducers) => reducers.batchReducer);
  const examTypeSelector = useSelector((reducers) => reducers.examReducer);
  const examType = useSelector((reducers) => reducers.examReducer);

  useEffect(() => {
    if (!selector) return;

    const controller = new AbortController();
    if (ProgressData[selector]) {
      setTotalAssignments(ProgressData[selector].assignments);
    } else {
      configAxios(localStorage.getItem("token"))
        .get(`/assignmentsPercentageCovered/${selector}`, {
          signal: controller.signal,
        })
        .then((res) => {
          switch (res.status) {
            case 200:
              setTotalAssignments(res.data.returnObj.assignments);
              break;
            default:
              setTotalAssignments({ total: 0, percent: 0 });
          }
        });
    }

    if (AssignmentScoreData[selector]) {
      setData(AssignmentScoreData[selector]);
      return;
    }

    configAxios(localStorage.getItem("token"))
      .get("/powerbi/assignmentScoreInRange/" + selector, {
        signal: controller.signal,
      })
      .then(({ data }) => {
        setData((e) => {
          return {
            ...e,
            datasets: [
              {
                label: "0-50%",
                data: [data.green_mark],
                backgroundColor: "#FF5858",
                borderColor: "#FF5858",
              },
              {
                label: "50-75%",
                data: [data.red_mark],
                backgroundColor: "#FFB636",
                borderColor: "#FFB636",
              },
              {
                label: "75-100%",
                data: [data.yello_mark],
                backgroundColor: "#24B47E",
                borderColor: "#24B47E",
              },
            ],
          };
        });
      });
    return () => controller.abort();
  }, [selector]);

  useEffect(() => {
    if (!selector) return;
    const controller = new AbortController();
    if (examTypeSelector === "quiz") {
      if (AssignmentTotalSubmission[selector]) {
        setTotalSubmission(AssignmentTotalSubmission[selector].quiz);
        return;
      }
      configAxios(localStorage.getItem("token"))
        .get("/powerbi/getAverageQuizSubmissions/" + selector, {
          signal: controller.signal,
        })
        .then(({ data }) => {
          console.log(data);
          setTotalSubmission(data.percentage.toFixed(1));
        });
    } else if (examTypeSelector === "coding") {
      if (AssignmentTotalSubmission[selector]) {
        setTotalSubmission(AssignmentTotalSubmission[selector].coding);
        return;
      }
      configAxios(localStorage.getItem("token"))
        .get("/powerbi/getAverageCodingSubmissions/" + selector, {
          signal: controller.signal,
          params: {
            typeOfExam: "assignment",
          },
        })
        .then(({ data }) => {
          console.log(data);
          setTotalSubmission(data.percentage.toFixed(1));
        })
        .catch(({ response }) => console.log(response));
    }
    return () => controller.abort();
  }, [selector, examTypeSelector]);

  // fetch top student data...
  useEffect(() => {
    if (!selector) return;
    const controller = new AbortController();

    if (AssignmentTopStudents[selector]) {
      setStudents(AssignmentTopStudents[selector]);
      return;
    }
    configAxios(localStorage.getItem("token"))
      .get("/powerbi/getTopStudentsInQuiz/" + selector, {
        signal: controller.signal,
        params: {
          typeOfExam: "assignment",
        },
      })
      .then(({ data }) => {
        console.log(data.students);
        setStudents(data);
      });
    return () => controller.abort();
  }, [selector]);

  return (
    <>
      <div className='powerAssignment'>
        <div className='row'>
          <div className='col-xl-5 order-xl-2 mb-xl-0'>
            <PowerBatch />
            <div className='powerBiCard mt-4 pb-0' style={{backgroundColor:getColor(examType)}}>
              <div className='powerBiHeading'>Top Students in Assignment</div>
              <div className='row'>
                {students.map((item, index) => {
                  if (index > 14) {
                    return <></>;
                  }
                  return <StudentScore text={item[0]} count={item[1]} />;
                })}
              </div>
            </div>
          </div>
          <div className='col-xl-7 order-xl-1 mb-0'>
            <div className='powerBiCard assignmentOverview' style={{backgroundColor:getColor(examType)}}>
              <ProgressOverview
                color='#24B47E'
                progress={totalAssignments.percent.toFixed(1)}
                title='Assignments'
                text={totalAssignments.total}
              />
              <ProgressOverview
                color='#007AFF'
                progress={totalSubmission}
                title='Submission'
                text={totalSubmission}
              />
            </div>
            <div className='powerBiCard mt-4' style={{backgroundColor:getColor(examType)}}>
              <div className='powerBiHeading'>Assignment Score</div>
              <div className='row'>
                <div className='col-md-6 mb-0'>
                  <div className='assignmentScore'>
                    <Bar
                      data={data}
                      options={{
                        maintainAspectRatio: false,
                        plugins: {
                          legend: {
                            display: false,
                          },
                        },
                      }}
                    />
                  </div>
                </div>
                <div className='col-md-6 mb-0' style={{backgroundColor:getColor(examType)}}>
                  <ChartDetails name='Assignment Score' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// const labels = ["Assignment Score"];
// const Bdata = {
//   labels,
//   datasets: [
//     {
//       label: "0-50%",
//       data: [200],
//       backgroundColor: "#FF5858",
//       borderColor: "#FF5858",
//     },
//     {
//       label: "50-75%",
//       data: [500],
//       backgroundColor: "#FFB636",
//       borderColor: "#FFB636",
//     },
//     {
//       label: "75-100%",
//       data: [800],
//       backgroundColor: "#24B47E",
//       borderColor: "#24B47E",
//     },
//   ],
// };

export default PowerAssignment;

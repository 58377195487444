import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import axios from "axios";
import "../Assets/css/newmentorform.css";
import { BASE_URL } from "../env";
function NewMentorForm() {
  const optionYears = [
    { value: 1, label: 1 },
    { value: 2, label: 2 },
    { value: 3, label: 3 },
    { value: 4, label: 4 },
    { value: 5, label: 5 },
    { value: "Graduate", label: "Graduate" },
  ];
  const optionTimes = [
    {
      value: "Morning(10:00AM - 12:00PM)",
      label: "Morning(10:00AM - 12:00PM)",
    },
    {
      value: "Afternoon(12:00PM - 04:00PM)",
      label: "Afternoon(12:00PM - 04:00PM)",
    },
    {
      value: "Evening(04:00PM - 10:00PM)",
      label: "Evening(04:00PM - 10:00PM)",
    },
  ];
  const optionStart = [
    {
      value: "Immediately this week",
      label: "Immediately this week",
    },
    {
      value: "In this month",
      label: "In this month",
    },
    {
      value: "Next month",
      label: "Next month",
    },
  ];
  const optionProfiles = [
    {
      value: "Data Science",
      label: "Data Science",
    },
    {
      value: " Software Development",
      label: " Software Development",
    },
    {
      value: "Consulting",
      label: "Consulting",
    },
    {
      value: "Finance",
      label: "Finance",
    },
    {
      value: "Other",
      label: "Other",
    },
  ];
  const optionGains = [
    {
      value: "Resume Building",
      label: "Resume Building",
    },
    {
      value: "Career Planning",
      label: "Career Planning",
    },
    {
      value: "Cracking Placements/Interview preparation",
      label: "Cracking Placements/Interview preparation",
    },
    {
      value: "Company specific preparation strategy",
      label: "Company specific preparation strategy",
    },
    {
      value: "Improve Domain knowledge",
      label: "Improve Domain knowledge",
    },
    {
      value: "Redude Interview fear",
      label: "Redude Interview fear",
    },
    {
      value: "Understand my strength and weaknesses",
      label: "Understand my strength and weaknesses",
    },
    {
      value: "Other",
      label: "Other",
    },
  ];
  const [page, setPage] = useState(1);
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors, isValid },
  } = useForm();

  var step1 = (
    <div className="step1">
      <div className="form-group pt-2">
        <label for="fullName">Full Name*</label>
        <input
          type="text"
          className="form-control border rounded-3"
          style={{ backgroundColor: "white" }}
          {...register("fullName", {
            required: true,
          })}
        ></input>
        {errors.fullName && (
          <small
            className="text-danger d-flex justify-content-start"
            style={{ fontSize: "0.8rem" }}
          >
            Please enter your Full Name properly
          </small>
        )}
      </div>
      <div className="form-group pt-2">
        <label for="email">Email Id*</label>
        <input
          type="email"
          className="form-control border rounded-3"
          style={{ backgroundColor: "white" }}
          id="email"
          {...register("email", { required: true })}
        ></input>
        {errors.email && (
          <small
            className="text-danger  d-flex justify-content-start"
            style={{ fontSize: "0.8rem" }}
          >
            Please enter your email id properly
          </small>
        )}
      </div>
      <div className="form-group pt-2">
        <label for="phone">Phone Number*</label>
        <input
          type="number"
          className="form-control border rounded-3"
          style={{ backgroundColor: "white" }}
          id="phone"
          {...register("phone", { required: true })}
        ></input>
        {errors.phone && (
          <small
            className="text-danger  d-flex justify-content-start"
            style={{ fontSize: "0.8rem" }}
          >
            Please enter your phone number
          </small>
        )}
      </div>
      <div className="form-group pt-2">
        <label for="wphone">Whatsapp Number*</label>
        <input
          type="number"
          className="form-control border rounded-3"
          style={{ backgroundColor: "white" }}
          id="wphone"
          {...register("whatsappPhone", { required: true })}
        ></input>
        {errors.whatsappPhone && (
          <small
            className="text-danger  d-flex justify-content-start"
            style={{ fontSize: "0.8rem" }}
          >
            Please enter your Whatsapp Number properly
          </small>
        )}
      </div>
      <div className="form-group pt-2">
        <label for="employeeref">Employee reference</label>
        <input
          type="text"
          className="form-control border rounded-3"
          style={{ backgroundColor: "white" }}
          id="employeeref"
          {...register("EmployeeReference")}
        ></input>
      </div>
      <label for="study_year">Year of Passout*</label>
      <br />
      <input
        type="number"
        placeholder="Eg. 2021"
        className="form-control border rounded-3"
        style={{ backgroundColor: "white" }}
        id="study_year"
        {...register("study_year", { required: true })}
      ></input>
      {/* <Controller
        name="study_year"
        control={control}
        className="form-control border rounded-3"
        style={{ backgroundColor: "white" }}
        rules={{ required: true }}
        render={({ field }) => (
          <Select
            isSearchable
            id="study_year"
            options={optionYears}
            {...field}
            // styles={customStyles}
          />
        )}
      />
      {errors.study_year && (
        <small
          className="text-danger d-flex justify-content-start"
          style={{ fontSize: "0.8rem" }}
        >
          Please enter your study year
        </small>
      )} */}
      <br></br>

      <div className="text-center">
        <button
          // disabled={!isValid}
          type="button"
          class="btn btn-primary"
          onClick={() => {
            setPage(2);
          }}
        >
          Next {">"}
        </button>
      </div>
    </div>
  );

  var step2 = (
    <div className="step2">
      <label for="interested_profiles">
        Interested profiles (for which of these profiles would you like to have
        a mentorship session, you may choose more than one)
      </label>
      <br></br>
      <Controller
        name="interested_profiles"
        control={control}
        className="form-control"
        rules={{ required: true }}
        render={({ field }) => (
          <Select
            isSearchable
            id="interested_profiles"
            options={optionProfiles}
            isMulti
            {...field}
          />
        )}
      />
      <br></br>
      <label for="mentorship_gains">
        What do you plan to gain from this mentorship program?
      </label>
      <Controller
        name="mentorship_gains"
        control={control}
        className="form-control"
        rules={{ required: true }}
        render={({ field }) => (
          <Select
            isSearchable
            id="mentorship_gains"
            options={optionGains}
            isMulti
            {...field}
          />
        )}
      />

      <div className="form-group pt-2">
        <label for="gain">
          What steps have you already taken to improve on the objectives chosen
          in the above question? *
        </label>
        <input
          type="text"
          className="form-control border rounded-3"
          style={{ backgroundColor: "white" }}
          id="gain"
          {...register("stepsGain", { required: true })}
        ></input>
        {/* {errors.whatsappPhone && (
          <small
            className="text-danger  d-flex justify-content-start"
            style={{ fontSize: "0.8rem" }}
          >
            Please enter your Whatsapp Number properly
          </small>
        )} */}
      </div>
      <br></br>
      <label for="mentorship_start">
        When do you plan to take the mentorship program?
      </label>
      <Controller
        name="mentorship_start"
        control={control}
        className="form-control"
        rules={{ required: true }}
        render={({ field }) => (
          <Select
            isSearchable
            id="mentorship_start"
            options={optionStart}
            // styles={customStyles}
            {...field}
          />
        )}
      />
      <br></br>
      <label for="mentorship_time">
        What time you will be available to take your mentorship session?
      </label>
      <Controller
        name="mentorship_time"
        control={control}
        className="form-control"
        rules={{ required: true }}
        render={({ field }) => (
          <Select
            isSearchable
            id="mentorship_time"
            options={optionTimes}
            // styles={customStyles}
            {...field}
          />
        )}
      />
      <br></br>
      <div className="text-center">
        <button
          type="button"
          class="btn btn-primary"
          onClick={() => {
            setPage(3);
          }}
        >
          Next {">"}
        </button>
      </div>
    </div>
  );

  var step3 = (
    <div className="step3">
      <div className="form-group pt-2">
        <label for="dreamcompany">
          What company are you planning to join (dream company)? *
        </label>
        <input
          type="text"
          className="form-control border rounded-3"
          style={{ backgroundColor: "white" }}
          id="dreamcompany"
          {...register("Dream_Company")}
        ></input>
      </div>
      <br></br>
      <label>
        Please choose a suitable program duration and fees (40 minutes mentoring
        is preferred for getting a better career plan {"&"} insights)
      </label>
      <div class="form-check">
        <input
          class="form-check-input"
          type="radio"
          name="fees"
          id="rad1"
          value="500"
          {...register("fees")}
        />
        <label class="form-check-label" for="rad1">
          500 INR for 20 minutes - Speed Mentoring
        </label>
      </div>
      <div class="form-check">
        <input
          class="form-check-input"
          type="radio"
          name="fees"
          id="rad2"
          value="850"
          {...register("fees")}
        />
        <label class="form-check-label" for="rad2">
          850 INR for 40 minutes- Pro Mentoring
        </label>
      </div>
      <div class="form-check">
        <input
          class="form-check-input"
          type="radio"
          name="fees"
          id="rad3"
          value="1250"
          {...register("fees")}
        />
        <label class="form-check-label" for="rad3">
          1250 INR for 60 minutes- 360 degree mentoring
        </label>
      </div>

      <br></br>
      <div class="custom-file">
        <label class="custom-file-label">Upload your resume (only pdf, png, jpg or jpeg allowed less than 1mb) </label>
        <br></br>
        <input
          type="file"
          accept="image/jpeg,image/jpg,image/png,application/pdf"
          class="form-control-file"
          name="resume"
          {...register("resume")}
        />
      </div>
      <br></br>
      <div className="text-center">
        <button type="submit" class="btn btn-primary">
          Submit
        </button>
      </div>
    </div>
  );
  const onSubmit = (data) => {
    //  axios
    //       .post(BASE_URL + "/getMentorshipForm", data)
    //       .then((response) => {

    //         console.log(response);
    //       })
    //       .catch((err) => {
    //         console.log(err);
    //       })}
  
    data.resume = data.resume[0];
    for (let i = 0; i < data.interested_profiles.length; i++){
      data.interested_profiles[i] = data.interested_profiles[i].value
    }
    for (let i = 0; i < data.mentorship_gains.length; i++){
      data.mentorship_gains[i] = data.mentorship_gains[i].value;
    }
   data.mentorship_start = data.mentorship_start.value;
   data.mentorship_time = data.mentorship_time.value;
   
    const form_data = new FormData();
    for (let key in data) {
      form_data.append(key, data[key]);
    }
    axios
      .post(BASE_URL + "/getMentorshipForm", form_data)
      .then((res) => {
        // console.log(res);
        // console.log("data sent sucessfully");
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  return (
    <>
      {/* <Navbar></Navbar> */}
      <div className="container">
        <div
          className="row p-5 align-items-center"
          style={{ minHeight: "90vh" }}
        >
          <div
            className="col-sm-12 col-md-6 p-4 shadow "
            style={{ height: "fit-content", borderRadius: "16px" }}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              {
                {
                  1: step1,
                  2: step2,
                  3: step3,
                }[page]
              }
            </form>
          </div>
          <div className="col-sm-12 col-md-6"></div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}

export default NewMentorForm;

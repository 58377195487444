import React from "react";
import { Link } from "react-scroll";
import expect1 from "../Assets/img/MentorConnectHomepage/expect1.png";
import expect2 from "../Assets/img/MentorConnectHomepage/expect2.png";
import expect3 from "../Assets/img/MentorConnectHomepage/expect3.png";
import expect4 from "../Assets/img/MentorConnectHomepage/expect4.png";
import expect5 from "../Assets/img/MentorConnectHomepage/expect5.png";
import expect6 from "../Assets/img/MentorConnectHomepage/expect6.png";
import hw1 from "../Assets/img/MentorConnectHomepage/howworks1.png";
import hw2 from "../Assets/img/MentorConnectHomepage/howworks2.png";
import hw3 from "../Assets/img/MentorConnectHomepage/howworks3.png";
import hw4 from "../Assets/img/MentorConnectHomepage/howworks4.png";
import "../Assets/scss/MentorConnect.scss";

const MentorConnectHomepageTxt = () => {
  return (
    <>
      {/* Mentor Page Header Section */}
      <div id="mentorPageHeaderSection">
        <div className="container">
          <div className="row">
            <div className="col-md-7 portion">
              <div className="heading">Mentor Connect</div>
              <div className="text">
                Prepare yourself for the dynamic job market and interviews by
                getting your queries answered by experts directly.{" "}
                <br className="d-sm-block d-none" /> Get Interview preparation
                tips, guides on resumes and build a profile that stands out.
              </div>
              <Link to="expertSection" className="headBtn">
                Connect Now
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* Mentor Connect Second Section */}
      <div id="mentorConnectExpectFromMentor">
        <div className="container">
          <div className="row gx-sm-5">
            <div className="col-md-10 mx-auto">
              <div className="heading">
                What can you expect from <br className="d-sm-none" /> Mentor
                Connect?
              </div>
              <div className="subHeading">
                Our One-on-One mentoring sessions will help you to connect with
                the leading experts in the industry. Know about resume building,
                interview preparation strategy and more.
              </div>
            </div>
            {ExpectData.map((val, index) => {
              return (
                <ExpectationCard
                  key={index}
                  imgsrc={val.img}
                  heading={val.heading}
                  desc={val.content}
                />
              );
            })}
          </div>
        </div>
      </div>

      {/* Mentor Connect Second Section */}
      <div id="mentorConnectHowItWorkSection">
        <div className="container">
          <div className="row gx-sm-5">
            <div className="col-sm-10 mx-auto">
              <div className="heading">How it Works ?</div>
              <div className="subHeading">
                Choose an expert from different fields and directly connect with
                them via call or video to get expert guidance instantly
              </div>
            </div>
          </div>
          {/* Desktop view */}
          <div className="d-sm-block d-none">
            <div className="row justify-content-center">
              <div className="col-4 mb-0">
                <div className="head">Find an Expert</div>
                <div className="text">
                  Choose experts from different fields such as Data Science,
                  Software Developement, Machine Learning, Artificial
                  Intelligence etc.
                </div>
              </div>
              <div className="col-2 centerDiv">
                <div className="numb">01</div>
                <div className="line"></div>
              </div>
              <div className="col-4 mb-0 d-flex justify-content-center">
                <img src={hw1} className="img-fluid imgsrc" alt="icon" />
              </div>
              <div className="col-4 mb-0 d-flex justify-content-center">
                <img src={hw2} className="img-fluid imgsrc" alt="icon" />
              </div>
              <div className="col-2 centerDiv">
                <div className="numb">02</div>
                <div className="line"></div>
              </div>
              <div className="col-4 mb-0">
                <div className="head">Book your Sessions</div>
                <div className="text">
                  Choose a plan & timings that suits you the best. Book a
                  session. Once done, the expert will be available for the
                  counselling session.
                </div>
              </div>
              <div className="col-4 mb-0">
                <div className="head">Get expert guidance</div>
                <div className="text">
                  Connect to the expert via a call or video. Ask all your doubts
                  and receive career guidance in the live 1:1 session. Get
                  detailed and genuine feedback.
                </div>
              </div>
              <div className="col-2 centerDiv">
                <div className="numb">03</div>
                <div className="line"></div>
              </div>
              <div className="col-4 mb-0 d-flex justify-content-center">
                <img src={hw3} className="img-fluid imgsrc" alt="icon" />
              </div>
              <div className="col-4 mb-0 d-flex justify-content-center">
                <img src={hw4} className="img-fluid imgsrc" alt="icon" />
              </div>
              <div className="col-2 centerDiv">
                <div className="numb">04</div>
              </div>
              <div className="col-4 mb-0">
                <div className="head">Unlock Free Toolkits</div>
                <div className="text">
                  Get life-time access to your sessions recordings. Receive
                  access to Free Toolkits to build Resumes and crack Interviews.
                </div>
              </div>
            </div>
          </div>

          {/* Mobile view */}
          <div className="row d-sm-none">
            <div className="col-2 mb-0 d-flex align-items-center flex-column">
              <img src={hw1} className="img-fluid imgsrc" alt="icon" />
              <div className="line"></div>
            </div>
            <div className="col-10 mb-3">
              <div className="head">Find an Expert</div>
              <div className="text">
                Choose experts from different fields such as Data Science,
                Software Developement, Machine Learning, Artificial Intelligence
                etc.
              </div>
            </div>
            <div className="col-2 mb-0 d-flex align-items-center flex-column">
              <img src={hw2} className="img-fluid imgsrc" alt="icon" />
              <div className="line"></div>
            </div>
            <div className="col-10 mb-3">
              <div className="head">Book your Sessions</div>
              <div className="text">
                Choose a plan & timings that suits you the best. Book a session.
                Once done, the expert will be available for the counselling
                session.
              </div>
            </div>
            <div className="col-2 mb-0 d-flex align-items-center flex-column">
              <img src={hw3} className="img-fluid imgsrc" alt="icon" />
              <div className="line"></div>
            </div>
            <div className="col-10 mb-3">
              <div className="head">Get expert guidance</div>
              <div className="text">
                Connect to the expert via a call or video. Ask all your doubts
                and receive career guidance in the live 1:1 session. Get
                detailed and genuine feedback.
              </div>
            </div>
            <div className="col-2 mb-0 d-flex justify-content-center">
              <img src={hw4} className="img-fluid imgsrc" alt="icon" />
            </div>
            <div className="col-10 mb-3">
              <div className="head">Unlock Free Toolkits</div>
              <div className="text">
                Get life-time access to your sessions recordings. Receive access
                to Free Toolkits to build Resumes and crack Interviews.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const ExpectationCard = (props) => {
  return (
    <>
      <div className="col-md-6 mb-sm-5 mb-0">
        {/* Desktop View */}
        <div className="ExpectationCard d-sm-block d-none">
          <div className="row">
            <div className="col-2 mb-sm-3 mb-0">
              <img src={props.imgsrc} className="img-fluid" alt="icon" />
            </div>
            <div className="col-10 mb-sm-3 mb-0">
              <div className="heading">{props.heading}</div>
              <div className="text">{props.desc}</div>
            </div>
          </div>
        </div>
        {/* Mobile View */}
        <div className="ExpectationCard d-sm-none">
          <details>
            <summary>
              <div className="row">
                <div className="col-2 mb-sm-3 mb-0 d-flex align-items-center">
                  <img src={props.imgsrc} className="img-fluid" alt="icon" />
                </div>
                <div className="col-8 mb-sm-3 mb-0 d-flex align-items-center">
                  <div className="heading">{props.heading}</div>
                </div>
                <div className="col-2 mb-0 d-flex align-items-center">
                  <i class="fas fa-chevron-down"></i>
                </div>
              </div>
            </summary>
            <div className="row">
              <div className="col-12 mb-0">
                <div className="text">{props.desc}</div>
              </div>
            </div>
          </details>
        </div>
      </div>
    </>
  );
};

const ExpectData = [
  {
    heading: "Mock Interviews",
    content:
      "We will help you inculcate confidence for the interviews.  With our 1:1 Mock Interviews, nail your DREAM JOB!",
    img: expect1,
  },
  {
    heading: "1:1 Career Planning Sessions",
    content:
      "We help you to choose the right career according to your interests and guide you about the scope in that field.",
    img: expect2,
  },
  {
    heading: "Company Specific Preparation Strategy",
    content:
      "We have trained and placed more than 27500+ students in TOP Companies and Mentored more than 10K+ students/professionals to achieve their GOALS!   ",
    img: expect3,
  },
  {
    heading: "Get Trained for Campus Placements",
    content:
      "Campus Placements can be tough on you! We will connect you to industry experts from top companies so that you know the right preparation strategy.",
    img: expect4,
  },
  {
    heading: "Customized Resume Building",
    content:
      "Our MENTORS are a part of Elite Companies and Alumni of IITs & IIMs we have successfully curated a resume checklist keeping in mind the Industry requirements.",
    img: expect5,
  },
  {
    heading: "Improve domain knowledge",
    content:
      "We have experts from various domains, who have taken no less than 5000+ sessions and hold more than 5+ years of experience who can help you get vital information. ",
    img: expect6,
  },
];

export default MentorConnectHomepageTxt;

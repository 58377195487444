import React, { Fragment, useEffect, useState } from "react";
import "../Assets/css/Student.css";
import Pranjal from "../Assets/img/person/pranjal.png";
import Ayushi from "../Assets/img/person/ayushi.png";
import Praharsha from "../Assets/img/person/praharsha.png";
import Manasa from "../Assets/img/person/manasa.png";
import Manasvi from "../Assets/img/person/manasvi.png";
import Anisha from "../Assets/img/person/anisha.png";
// import ash from "../Assets/img/person/as.jpeg";
import av from "../Assets/img/person/Abhijeet Vichare.jpg";
import ap from "../Assets/img/person/AKASH PATIL.jpg";
import nr from "../Assets/img/person/NEHA RAMYA.jpg";
import sn from "../Assets/img/person/SAI NITISH.jpg";
// import sG from "../Assets/img/person/SIDDHARTHA GHOSH.jpg";
import amazon_logo from "../Assets/img/Home/company-logo3.png";
// import amazon_logo1 from "../Assets/img/Home/company-logo15.png";
import ff from "../Assets/img/Home/ff.png";
import fk from "../Assets/img/Home/flipkart.png";
import merilytics from "../Assets/img/Home/merilytics-logo.png";
import nomuraLogo from "../Assets/img/Home/Nomura_logo.png";
import exlLogo from "../Assets/img/Home/exl-logo.png";
import cognizantLogo from "../Assets/img/Home/cognizant-logo.png";
import capLogo from "../Assets/img/Home/capgemini-logo.png";
// import amazon_logo2 from "../Assets/img/Home/company-logo16.png";
// import amazon_logo3 from "../Assets/img/Home/company-logo17.png";
// import amazon_logo4 from "../Assets/img/Home/company-logo18.png";
import studentMob from "../Assets/img/Home/student-mob.png";

// import Media from "react-media";
import useMedia from "./useMedia";
import { Link } from "react-router-dom";

function Student() {
  const match = useMedia("(min-width : 768px )");

  const [ translate, setTranslate ] = useState(50);

  useEffect(() => {
      window.addEventListener('resize',() => {
        window.innerWidth > 1000 ? setTranslate(50): setTranslate(0); 
      })
  })

  const ayushi = (
    <div className="col-sm-6 col-md-4 col-lg-2" style={{ transform: "translateX(" + translate + "%)" }}>
      {" "}
      <div className="student-box">
        {" "}
        <img className="student-image " src={Ayushi} alt="" />
        <h2 className="student-name">Ayushi</h2>
        <img className="student-company-logo" src={fk} alt="" />
      </div>{" "}
    </div>
  );

  const abhijeet = (
    <div className="col-sm-6 col-md-4 col-lg-2" style={{ transform: "translateX(" + translate + "%)" }}>
      <div className="student-box">
        {" "}
        <img className="student-image" src={av} alt="" />
        <h2 className="student-name">Abhijeet</h2>
        <img
          className="student-company-logo"
          style={{ height: "50px", marginTop: "10px" }}
          src={merilytics}
          alt=""
        />
      </div>
    </div>
  );

  const neha = (
    <div className="col-sm-6 col-md-4 col-lg-2" style={{ transform: "translateX(" + translate + "%)" }}>
      <div className="student-box">
        {" "}
        <img className="student-image" src={nr} alt="" />
        <h2 className="student-name">Neha</h2>
        <img className="student-company-logo" src={amazon_logo} alt="" />
      </div>
    </div>
  );

  const akash = (
    <div className="col-sm-6 col-md-4 col-lg-2" style={{ transform: "translateX(" + translate + "%)" }}>
      <div className="student-box">
        {" "}
        <img className="student-image" src={ap} alt="" />
        <h2 className="student-name">Akash</h2>
        <img
          className="student-company-logo-nl"
          style={{ height: "25px", marginTop: "10px" }}
          src={nomuraLogo}
          alt=""
        />
      </div>
    </div>
  );

  const praharsha = (
    <div className="col-sm-6 col-md-4 col-lg-2" style={{ transform: "translateX(" + translate + "%)" }}>
      <div className="student-box">
        {" "}
        <img className="student-image" src={Praharsha} alt="" />
        <h2 className="student-name">Praharsha</h2>
        <img
          className="student-company-logo-ff"
          src={ff}
          style={{ height: "40px", marginTop: "10px" }}
          alt=""
        />
      </div>
    </div>
  );

  const pranjal = (
    <div className="col-sm-6 col-md-4 col-lg-2" style={{ transform: "translateX(" + translate + "%)" }}>
      <div className="student-box">
        {" "}
        <img className="student-image" src={Pranjal} alt="" />
        <h2 className="student-name">Pranjal</h2>
        <img
          className="student-company-logo"
          style={{ height: "50px" }}
          src={exlLogo}
          alt=""
        />
      </div>{" "}
    </div>
  );

  const manasvi = (
    <div className="col-sm-6 col-md-4 col-lg-2" style={{ transform: "translateX(" + translate + "%)" }}>
      <div className="student-box">
        {" "}
        <img className="student-image" src={Manasvi} alt="" />
        <h2 className="student-name">Manasvi</h2>
        <img
          className="student-company-logo-ff"
          style={{ height: "40px", marginTop: "10px" }}
          src={ff}
          alt=""
        />
      </div>{" "}
    </div>
  );

  const sai = (
    <div className="col-sm-6 col-md-4 col-lg-2" style={{ transform: "translateX(" + translate + "%)" }}>
      <div className="student-box">
        {" "}
        <img className="student-image" src={sn} alt="" />
        <h2 className="student-name">Sai</h2>
        <img
          className="student-company-logo-cl"
          src={cognizantLogo}
          style={{ height: "50px" }}
          alt=""
        />
      </div>{" "}
    </div>
  );

  const manasa = (
    <div className="col-sm-6 col-md-4 col-lg-2" style={{ transform: "translateX(" + translate + "%)" }}>
      <div className="student-box">
        {" "}
        <img className="student-image" src={Manasa} alt="" />
        <h2 className="student-name">Manasa</h2>
        <img
          className="student-company-logo-cg"
          style={{ height: "50px" }}
          src={capLogo}
          alt=""
        />
      </div>{" "}
    </div>
  );

  const anisha = (
    <div className="col-sm-6 col-md-4 col-lg-2" style={{ transform: "translateX(" + translate + "%)" }}>
      <div className="student-box">
        {" "}
        <img className="student-image" src={Anisha} alt="" />
        <h2 className="student-name">Anisha</h2>
        <img
          className="student-company-logo"
          src={exlLogo}
          style={{ height: "50px" }}
          alt=""
        />
      </div>{" "}
    </div>
  );

  const styles = {
    "@media(max-width:650px)": {
      mobRes1: {
        display: "flex",
      },
    },
  };
  return (
    <div style={{ overflowX: "hidden" }}>
      <div style={{ marginTop: "2rem" }}>
        {match ? (
          <div style={{ padding: "4%" }}>
            <div className="row">
              <Fragment className="col">{ayushi}</Fragment>
              <Fragment className="col">{abhijeet}</Fragment>
              <Fragment className="col">{neha}</Fragment>
              <Fragment className="col">{akash}</Fragment>
              <Fragment className="col">{praharsha}</Fragment>
            </div>
            <div className="row">
              <Fragment>{pranjal}</Fragment>
              <Fragment>{manasvi}</Fragment>
              <Fragment>{sai}</Fragment>
              <Fragment>{manasa}</Fragment>
              <Fragment>{anisha}</Fragment>
            </div>
          </div>
        ) : (
          <div>
            <img
              style={{
                margin: "auto",
                display: "block",
                height: "75vw",
                width: "76vw",
              }}
              src={studentMob}
            />
          </div>
        )}

        {/* <div className="mob-res1">
            <div className="col-sm-6 col-md-1-5 col-lg-1-5">
              {" "}
              <div className="student-box">
                {" "}
                <img className="student-image " src={ash} alt="" />
                <h2 className="student-name">Ayushi</h2>
                <img className="student-company-logo" src={fk} alt="" />
              </div>{" "}
            </div>
            <div className="col-sm-6 col-md-1-5 col-lg-1-5">
              <div className="student-box">
                {" "}
                <img className="student-image" src={av} alt="" />
                <h2 className="student-name">Abhijeet</h2>
                <img
                  className="student-company-logo"
                  style={{ height: "50px", marginTop: "10px" }}
                  src={merilytics}
                  alt=""
                />
              </div>
            </div>{" "}
          </div>
          <div className="mob-res1">
            <div className="col-sm-6 col-md-1-5 col-lg-1-5">
              <div className="student-box">
                {" "}
                <img className="student-image" src={nr} alt="" />
                <h2 className="student-name">Neha</h2>
                <img
                  className="student-company-logo"
                  src={amazon_logo}
                  alt=""
                />
              </div>{" "}
            </div>
            <div className="col-sm-6 col-md-1-5 col-lg-1-5">
              <div className="student-box">
                {" "}
                <img className="student-image" src={ap} alt="" />
                <h2 className="student-name">Akash</h2>
                <img
                  className="student-company-logo-nl"
                  style={{ height: "25px", marginTop: "10px" }}
                  src={nomuraLogo}
                  alt=""
                />
              </div>{" "}
            </div>
          </div>
          <div className="mob-res1">
            <div className="col-sm-6 col-md-1-5 col-lg-1-5">
              <div className="student-box">
                {" "}
                <img className="student-image" src={av} alt="" />
                <h2 className="student-name">Praharsha</h2>
                <img
                  className="student-company-logo-ff"
                  src={ff}
                  style={{ height: "40px", marginTop: "10px" }}
                  alt=""
                />
              </div>
            </div>
            <div className="row" style={{ padding: "4%" }}>
              <div className="col-sm-6 col-md-1-5 col-lg-1-5">
                <div className="student-box">
                  {" "}
                  <img className="student-image" src={av} alt="" />
                  <h2 className="student-name">Pranjal</h2>
                  <img
                    className="student-company-logo"
                    style={{ height: "50px" }}
                    src={exlLogo}
                    alt=""
                  />
                </div>{" "}
              </div>
            </div>
          </div>{" "}
          <div className="mob-res1">
            <div className="col-sm-6 col-md-1-5 col-lg-1-5">
              <div className="student-box">
                {" "}
                <img className="student-image" src={av} alt="" />
                <h2 className="student-name">Manasvi</h2>
                <img
                  className="student-company-logo-ff"
                  style={{ height: "40px", marginTop: "10px" }}
                  src={ff}
                  alt=""
                />
              </div>{" "}
            </div>
            <div className="col-sm-6 col-md-1-5 col-lg-1-5">
              <div className="student-box">
                {" "}
                <img className="student-image" src={sn} alt="" />
                <h2 className="student-name">Sai</h2>
                <img
                  className="student-company-logo-cl"
                  src={cognizantLogo}
                  style={{ height: "50px" }}
                  alt=""
                />
              </div>{" "}
            </div>
          </div>
          <div className="mob-res1">
            <div className="col-sm-6 col-md-1-5 col-lg-1-5">
              <div className="student-box">
                {" "}
                <img className="student-image" src={av} alt="" />
                <h2 className="student-name">Manasa</h2>
                <img
                  className="student-company-logo-cg"
                  style={{ height: "50px" }}
                  src={capLogo}
                  alt=""
                />
              </div>{" "}
            </div>
            <div className="col-sm-6 col-md-1-5 col-lg-1-5">
              <div className="student-box">
                {" "}
                <img className="student-image" src={av} alt="" />
                <h2 className="student-name">Anish</h2>
                <img
                  className="student-company-logo"
                  src={exlLogo}
                  style={{ height: "50px" }}
                  alt=""
                />
              </div>{" "}
            </div>
          </div> */}
        {/* </div> */}
      </div>
      <div class="container enroll p-3">
        <div class="enroll-inner">
          <div>
            <h4>OUR STUDENTS</h4>
          </div>
          <div>
            <h2>WANT TO BE THE NEXT ONE</h2>
          </div>
          <div>
            <button class="intern-btn" style={{ fontWeight: "bold",backgroundColor: "#185adb",color: "white" }}>
              <Link to="/placementcourse">Enroll Now</Link>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Student;

import React from "react";
import "../Assets/css/PressRecognition.css";
import useMedia from "../componentsofcourse/useMedia";

// import press from "../Assets/img/Home/press.png";
// import pressMob from "../Assets/img/Home/press-mob.png";

function PressRecognition() {
  const matches = useMedia("(min-width : 768px )");

  const styles = {
    header: {
      fontFamily: "Poppins,sans-serif",
      fontWeight: "bold",
      fontSize: "50px",
      textAlign: "center",
      marginBottom: "5%",
    },
    headerMob: {
      fontFamily: "Poppins,sans-serif",
      fontWeight: "bold",
      textAlign: "center",
      marginBottom: "10%",
    },
    body: {
      margin: "auto",
      display: "block",
      width: "90%",
      marginBottom: "5%",
    },

    bodyMob: {
      margin: "auto",
      display: "block",
      width: "65%",
      marginBottom: "10%",
    },
  };
  return (
    <div>
      {matches ? (
        <div>
          <div className="container-fluid">
            <div className=" mx-5 p-0 my-3">
              <h1 style={styles.header}>Press Recognitions</h1>
              {/* <hr className="hr-J" /> */}
            </div>

            <div>
              <img style={styles.body} src={'https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644061584401_press.png'} />
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="container-fluid">
            <div className=" mx-5 p-0 my-3">
              <h1 style={styles.headerMob}>
                Press <br />
                Recognitions
              </h1>
              {/* <hr className="hr-J" /> */}
            </div>

            <div>
              <img style={styles.bodyMob} src={'https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644062225583_press-mob.png'} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default PressRecognition;

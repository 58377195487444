import React, { useEffect, useState } from "react";
// import { useParams } from "react-router-dom";
import { BASE_URL } from "../env";
// import { Link } from "react-router-dom";
// import cantilevertransparent from "../Assets/img/cantilever-logo.png";
import moment from "moment";
import LockIcon from "@mui/icons-material/Lock";
import "../Assets/css/allexam.css";
import ReactGA from "react-ga";

const Allexam = ({ id }) => {
  //60fe75b89ff4cc7af8b1d35c
  //getExambycourse/:id
  //<Link to="/codingexam/"+value._id

  // const { id } = useParams();

  const [allExam, setallExam] = useState("");
  const [ExamIndex, setExamIndex] = useState("");
  const currTime = moment().unix();
  const [cannotStartExamPopUp, setcannotStartExamPopUp] = useState(false);

  useEffect(() => {
    ReactGA.initialize("UA-198180460-1");
  }, []);

  // ReactGA.event({
  //   category: "Mentors",
  //   type: "Mentor Linkedin",
  //   name: "Manish Kankani LinkedIn Profile",
  //   action: "Manish Kankani LinkedIn Profile",
  // });
  // const [yourscoring]
  function NotLocked(startTime, endTime) {
    // console.log(startTime);

    // console.log(endTime)
    if (startTime === undefined || endTime === undefined) {
      return true;
    }
    if (currTime >= startTime && currTime <= endTime) {
      return true;
    } else {
      return false;
    }
  }
  useEffect(() => {
    fetch(BASE_URL + "/getExambycourse/" + id, {
      method: "GET",
      "Content-type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log(data.message);
        setallExam(data.message);
      })
      .catch((err) => console.log(err));
  }, []);
  function ToSetPopUp(e, ind) {
    e.preventDefault();
    setExamIndex(ind);
    setcannotStartExamPopUp(true);
  }
  return (
    <div style={{ background: "", height: "" }}>
      <br />
      <div className="container">
        <h2 className="my-2">All Exams:</h2>
        <div className="row gx-5">
          {allExam ? (
            <>
              {allExam?.map((val, index) => {
                return (
                  <div className="col-lg-4 col-md-6">
                    <div
                      className="card shadow rounded  "
                      style={{ border: "none", position: "relative" }}
                    >
                      {!NotLocked(val.startTime, val.endTime) && (
                        <div
                          className="d-flex justify-content-center align-items-center glass-morphism"
                          style={{
                            position: "absolute",
                            top: "0",
                            left: "0",
                            right: "0",
                            height: "100%",
                            width: "100%",
                            zIndex: "2",
                          }}
                        >
                          <LockIcon
                            style={{ transform: "scale(3)", color: "#15314B" }}
                          />
                          <br />
                        </div>
                      )}
                      {!NotLocked(val.startTime, val.endTime) && (
                        <span
                          className="d-flex justify-content-center"
                          style={{
                            position: "absolute",
                            // top: "0",
                            left: "0",
                            right: "0",
                            bottom: "0",
                            fontFamily: "Poppins-bold",
                            fontSize: "10px",
                            width: "100%",
                            zIndex: "2",
                          }}
                        >
                          {moment
                            .unix(val.startTime)
                            .format("MMM Do,YYYY h:mmA")}
                          &nbsp; to &nbsp;
                          {moment.unix(val.endTime).format("MMM Do,YYYY h:mmA")}
                        </span>
                      )}
                      <div
                        className="card-header text-capitalize"
                        style={{
                          backgroundColor: "#001528",
                          clipPath: "polygon(0 0, 100% 0%, 100% 100%, 0 71%)",
                          minHeight: "150px",
                          color: "#fff",
                          fontFamily: "Nunito-ExtraBold",
                          fontSize: "32px",
                        }}
                      >
                        {val.name}
                      </div>
                      <div className="card-body">
                        <p
                          className="card-text"
                          style={{
                            fontSize: "14px",
                            fontFamily: "Nunito-Bold",
                          }}
                        >
                          Test-Duration : {val.timer} minutes
                        </p>
                        <div>
                          <button
                            type="button"
                            className="btn"
                            style={{
                              backgroundColor: "#15314B",
                            }}
                          >
                            <a
                              className="text-white"
                              onClick={(e) => {
                                ReactGA.event({
                                  category: "Dashboard",
                                  type: "Coding Exam",
                                  name: val.name,
                                  action: val.name,
                                });
                                if (NotLocked(val.startTime, val.endTime)) {
                                } else {
                                  ToSetPopUp(e, index);
                                }
                              }}
                              target="_blank"
                              href={
                                NotLocked(val.startTime, val.endTime)
                                  ? "/codinginstructions" +
                                    "?duration=" +
                                    val.timer +
                                    "&questions=" +
                                    val.questions.length +
                                    "&id=" +
                                    val._id
                                  : ""
                              }
                              style={{
                                fontSize: "16px",
                                fontFamily: "Nunito-Bold",
                                padding: "4px 20px",
                              }} rel="noreferrer"
                            >
                              Start Exam
                            </a>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <h1 className="text-white">Exam are loading....</h1>
          )}
        </div>
      </div>
      {cannotStartExamPopUp && (
        <div
          style={{
            position: "absolute",
            zIndex: "20",
            backdropFilter: "brightness(0)",
            height: "200px",
            width: "550px",
            top: "50%",
            marginLeft: "-250px",
            marginTop: "-100px",
            left: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
            padding: "20px",
          }}
        >
          Exam will be active from &nbsp;
          {moment
            .unix(allExam[ExamIndex].startTime)
            .format("MMMM Do, YYYY h:mm:ss A")}
          &nbsp; to &nbsp;
          {moment
            .unix(allExam[ExamIndex].endTime)
            .format("MMMM Do, YYYY h:mm:ss A")}
          &emsp;
          <button
            onClick={() => {
              setcannotStartExamPopUp(false);
            }}
            className="btn btn-md btn-primary"
          >
            Okay
          </button>
        </div>
      )}
    </div>
  );
};

export default Allexam;

import React, { useEffect } from "react";

const Zoomverify = ({ hidenavbar }) => {
  useEffect(() => {
    hidenavbar(true);
    return () => {
      hidenavbar(false);
    };
  }, [hidenavbar]);
  return <>118ff95d67c64a03aeebbf83e39062d0</>;
};

export default Zoomverify;

import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import style from "../Assets/css/newmasterclass.module.css";
import styles from "../Assets/css/tcs.module.css";
import useMedia from "../componentsofcourse/useMedia";
import SM from "../Imgsofcourse/images/Image 1.png";
import v1 from "../Imgsofcourse/images/v1.png";
import v2 from "../Imgsofcourse/images/v2.png";
import v3 from "../Imgsofcourse/images/v3.png";
import v4 from "../Imgsofcourse/images/v4.png";
import Testimonials from "../components/TestimonialsVersion2";
import { BASE_URL } from "../env";
import ReactGA from "react-ga";
import moment from "moment";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BannerComp from "../components/BannerComp";
import MasterclassComp from "../components/MasterclassComp";
// import { set } from "lodash";

function NewMasterClass() {
  ReactGA.initialize("UA-198180460-1");
  const [StudentData, setStudentData] = useState({});
  const matches = useMedia("(min-width : 768px )");
  useEffect(() => {
    fetch(BASE_URL + "/getProfile", {
      headers: { authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          // console.log(data.error);
        } else {
          setStudentData(data);
        }
      });
  }, []);

  return (
    <>
      {/* Navbar */}
      {/* <Navbar /> */}
      {/* Header */}
      <header className={style.headSection}>
        <div className={`container-fluid ${style.layout}`}>
          <div className="row">
            <div className="col-md-7 my-4 py-5 d-flex justify-content-center flex-column">
              <h2 className={style.headHeading}>MasterClass</h2>
              <p className={style.headDescription}>
                These career acceleration videos will provide you an
                unprecedented opportunity to learn and become job ready. So, ace
                your next interview by mastering multiple trending topics in
                various industries.
              </p>
            </div>
            <div className="col-md-5 d-flex justify-content-center align-items-end mb-0"></div>
          </div>
        </div>
      </header>

      {/* Second Section */}
      <MasterclassComp />

      {/* Banner */}
      <BannerComp />
      <h2
        className="text-center mt-5 mb-5"
        style={{ fontSize: "44px", fontWeight: "bold" }}
      >
        What Our Students Say
      </h2>
      {/* Testimonial Section Start */}
      <div className="" style={{ backgroundColor: "#185adb" }}>
        <br />
        <br />
        <div className="row" style={{ marginLeft: matches ? "12%" : "6%" }}>
          <div className="col-md-4 col-xs-12 col-12 col-12 justify-content-center pb-2">
            <img
              src={SM}
              className="img-fluid"
              style={{ marginLeft: matches == false ? "3%" : null }}
            />
          </div>
          <div
            style={{ textAlign: "left" }}
            className="col-md-6 col-xs-12 col-12 col-12"
          >
            <div>
              <h4 style={{ color: "white" }}>SARVESH MEHTANI</h4>
            </div>
            <div>
              <h5 style={{ color: "white" }}>IIT Bombay - Computer Science</h5>
            </div>
            <br />
            <div>
              <p style={{ color: "white" }}>
                Overall, I loved the fact that the whole process was tailored as
                per the individual's interests and goals which made it very
                effective in pointing out our areas of strength and the areas
                that we need to work on.
              </p>
            </div>

            <br />
            <br />
            <div>
              <button className={styles.sarveshbtn}>
                A.I.R 1, JEE ADVANCED 2017
              </button>
            </div>
          </div>
        </div>
      </div>
      <div style={{ overflowX: "hidden" }}>
        <Testimonials />
      </div>

      {/* Last Section */}
      <section className={style.lastSection}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h4 className={style.lastSectionHeading}>
                Enjoying MasterClass Videos? We have more in line for you
              </h4>
            </div>
          </div>
          <LastSection />
        </div>
      </section>

      {/* Footer */}
      <Footer />
    </>
  );
}

const CardLive = () => {
  const [webinarData, setWebinarData] = useState([]);
  useEffect(() => {
    fetch(BASE_URL + "/webinars/getAllWebinar", {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log("WEbinar Data", data.response);
        setWebinarData(data.response);
      });
  }, []);
  return (
    <>
      {webinarData.map((item) => {
        return (
          <div className="col-xl-5 col-lg-6">
            <div className={style.boxWrapper}>
              <img src={item.image} className={style.boxWrapperHeader} />
              <div className={style.boxWrapperBody}>
                <h4 className={style.boxWrapperHeading}>{item.subject}</h4>
                <p className={style.boxWrapperDesc}>{item.description}</p>
                <div className="row mt-3 justify-content-between">
                  <div className="col-sm-8 col-6 mb-0 d-sm-flex">
                    <p className={style.schedule}>
                      <i className="far fa-calendar-alt"></i>
                      {moment(item.startTime).format("DD") +
                        " " +
                        moment(item.startTime).format("MMMM") +
                        " " +
                        moment(item.startTime).format("YYYY")}
                    </p>
                    <p className={`mx-sm-3 ${style.schedule}`}>
                      <i className="far fa-clock"></i>
                      {moment(item.startTime).format("hh A") +
                        " - " +
                        moment(item.endTime).format("hh A")}
                    </p>
                  </div>
                  <div className="col-sm-4 col-6 mb-0">
                    <Link
                      to={`/masterclassform/` + item.webinar_id}
                      className={`btn ${style.boxWrapperRegister}`}
                    >
                      Register Now
                    </Link>
                    <div className={style.boxWrapperEnrolled}>
                      {/* 5673 Enrolled */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};
const CardArchieved = ({ StudentData }) => {
  const [webinarData, setWebinarData] = useState([]);
  const slider = React.useRef(null);

  const compareID = (id) => {
    for (let key in StudentData.student.masterclasses) {
      if (StudentData.student.masterclasses[key]._id == id) {
        return (
          <Link
            to={`/masterclass-standalone/` + id}
            className={`btn ${style.boxWrapperRegister}`}
          >
            Watch Now
          </Link>
        );
      }
    }

    return (
      <Link
        to={`/masterclass-standalone/` + id}
        className={`btn ${style.boxWrapperRegister}`}
      >
        Register Now
      </Link>
    );
  };

  useEffect(() => {
    fetch(BASE_URL + "/getAllOfflineMasterClass", {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log("WEbinar Data", data.message);
        setWebinarData(data.message);
      });
  }, []);

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className={`col-1 d-flex justify-content-center align-items-center`}>
        <i
          className={`fas fa-chevron-left ${style.movementArrow}`}
          onClick={() => slider?.current?.slickPrev()}
        ></i>
      </div>
      <div className="col-10">
        <Slider {...settings} ref={slider}>
          {webinarData.map((item) => {
            return (
              <div className="">
                <div className={style.boxWrapper}>
                  <img src={item.image} className={style.boxWrapperHeader} />
                  <div className={style.boxWrapperBody}>
                    <h4 className={style.boxWrapperHeading}>{item.subject}</h4>
                    <p className={style.boxWrapperDesc}>{item.description}</p>
                    <div className="row mt-3 justify-content-between">
                      <div className="col-lg-7 mb-0"></div>
                      <div className="col-lg-5 mb-0">
                        {StudentData?.student?.masterclasses.length ? (
                          compareID(item._id)
                        ) : (
                          <Link
                            to={{
                              pathname: "/masterclass-standalone/" + item._id,
                              state: { id: item._id },
                            }}
                            className={`${style.boxWrapperRegister}`}
                            onClick={() => {
                              window.scroll(0, 0);
                            }}
                          >
                            Explore Now
                          </Link>
                        )}

                        <div className={style.boxWrapperEnrolled}></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
      <div className={`col-1 d-flex justify-content-center align-items-center`}>
        <i
          className={`fas fa-chevron-right ${style.movementArrow}`}
          onClick={() => slider?.current?.slickNext()}
        ></i>
      </div>
    </>
  );
};

const Videos = (props) => {
  return (
    <>
      <div
        className="col-lg-3 col-sm-6"
        onClick={() => {
          ReactGA.event({
            category: ` ${props.from} Page`,
            type: "Nudges",
            name: props.heading,
            action: props.desc,
          });
        }}
      >
        <div className={style.card}>
          <div
            className={style.cardHeader}
            style={{ backgroundImage: `url(${props.url})` }}
          >
            <span>
              <h6 style={{ width: "265px" }} className={style.cardText}>
                {props.heading}
              </h6>
            </span>
          </div>
          <a style={{ color: "white" }} href={props.href}>
            {" "}
            <div className={style.cardBody}>{props.desc}</div>
          </a>
        </div>
      </div>
    </>
  );
};

const LastSection = ({ from }) => {
  return (
    <div className="row my-0">
      <Videos
        heading="Looking for jobs and internships?"
        desc="Apply Now"
        // href='/jobposting'
        from={from}
        href="/jobs-for-freshers"
        url={v1}
      />
      <Videos
        heading="Have less time to prepare?"
        desc="crash courses"
        href="/fasttrackcourse"
        from={from}
        url={v2}
      />
      <Videos
        heading="Get yourself ready to be placed"
        desc="Explore Now"
        href="/newcoursepage"
        from={from}
        url={v3}
      />
      <Videos
        heading="Explore our training at IIT Bombay"
        desc="Get Started"
        href="/iitbombay"
        from={from}
        url={v4}
      />
    </div>
  );
};

export default NewMasterClass;
export { Videos, LastSection };

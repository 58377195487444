import React, { useEffect, useState, useContext, useRef } from "react";
import { ModalContext } from "../../Context/ModalContext";
//import { Navigation } from "react-minimal-side-navigation";
import { AuthContext } from "../../Context/AuthContext";
import useMedia from "../../componentsofcourse/useMedia";
//import Modal from "react-modal";
import "../../Assets/css/navbar.css";
import { Link, useHistory } from "react-router-dom";
import MainLogo from "../../Assets/img/Home/cantileverlogo-cut.png";
import styles from "../../Assets/css/form.module.css";
// import { Dropdown } from "react-bootstrap";
// import context from "react-bootstrap/esm/AccordionContext";
// import profile_img from "../../Assets/img/Home/profile_img.png";
// import dashboard_img from "../../Assets/img/Home/dashboard.png";
// import logout_img from "../../Assets/img/Home/favicon.png";
import { Store } from "react-notifications-component";
import { DropdownComp } from "./../DropdownComp";
import youtube from "../../Assets/img/youtube.jpg";
import linkedin from "../../Assets/img/linkedin.jpg";
import instagram from "../../Assets/img/instagram.jpg";
// import facebook from "../../Assets/img/facebook.jpg";
//import 'react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css';
import AOS from "aos";
import "aos/dist/aos.css";
import { useLocation } from "react-router-dom";
import { BASE_URL } from "../../env";

//import { UserInformation } from "../App";
function B2bNavbar(props) {
  //const userinfo = useContext(UserInformation);
  const location = useLocation();
  let { open, update_current } = useContext(ModalContext);
  const [showClose, setShowClose] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [data, setData] = useState("");
  const [id, setId] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const history = useHistory();
  const [rotate, setrotate] = useState(false);
  const { token, update_token, set_Id } = useContext(AuthContext);

  //ids for the navlinks
  const ids = [
    "home",
    "aboutus",
    "book_a_free_session",
    "dropdown-basic-course",
    "dropdown-basic-profile",
    "login",
  ];

  //this is used for making the NavLink bold
  const handleClick = (event) => {
    for (let i = 0; i < ids.length; i++) {
      let x = document.getElementById(ids[i]);
      if (x != null && x.style.fontWeight === "600") {
        x.style.fontWeight = "lighter";
      }
    }
    if (event.target.id === "cp" || event.target.id === "gpr") {
      document.getElementById("dropdown-basic-course").style.fontWeight = "600";
    } else {
      event.target.style.fontWeight = "600";
      // console.log(event.target.style.fontWeight);
    }
  };
  //   useEffect(()=>{
  // let x = document.getElementById("home");
  //     x.style.fontWeight = "600";
  //   },[])
  // this is the use effect for fetching the user information
  useEffect(() => {
    fetch(BASE_URL + "/getProfile", {
      headers: { authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log(data);
        //console.log(userinfo)
        // userinfo.setuserinfo(data)

        // console.log(userinfo)
        if (data.error) {
          setFirstName("");
        } else {
          setData(data);
          //  userinfo.setuserinfo(data)
          setLoggedin(true);
          setId(data.user._id);
          //set_Id(data.user._id);
          setFirstName(data.user.firstName);
          setIsAdmin(data.user.isAdmin);
          //console.log(userinfo)
        }
      });
  }, [token]);
  //notify when user logged in or logged out
  const notifyPopup = () => {
    Store.addNotification({
      title: "Logged Out!",
      message: "You are Logged Out!",
      type: "danger",
      background: "pink",
      insert: "bottom",
      container: "bottom-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true,
      },
    });
  };
  //logout- clear the token from the localstorage
  const logout = () => {
    localStorage.clear();
    update_token("");
    setLoggedin(false);
    notifyPopup();
  };
  function Rotation() {
    setrotate(true);
    setTimeout(() => {
      setrotate(false);
    }, 2000);
  }
  //login button function
  // const renderLogin = () => {
  //   return [
  //     <h5 onClick={()=>{
  //        setrotate(true)
  //           setTimeout(() => {
  //             return window.location.reload();
  //           }, 1000);
  //           setTimeout(()=>{
  //             setrotate(false)
  //           },2000)
  //     }}>
  //       <NavLink
  //         id="login"
  //         to="/signin"

  //         onClick={handleClick}
  //         activeClassName="active"
  //       //  className={styles.navLink}
  //       //  style={{
  //         //  textDecorationLine: "none",
  //        //   marginRight: "15px",
  //        //   fontWeight: "lighter",
  //        //   paddingLeft: "3px",
  //        //   paddingBottom: "10px",
  //        // }}
  //        // className=" text-dark"
  //       >
  //         Login
  //       </NavLink>
  //     </h5>,
  //   ];
  // };

  //the profile design when we sign in
  // //admin sign in or not
  // const renderList = () => {
  //   return [
  //     <Dropdown style={{ bottom: "20%" }}>
  //       <Dropdown.Toggle

  //       >
  //       {/* profile design when we sign in */}
  //         <div>
  //           <img
  //             src={profile_img}
  //             slt="person"
  //             style={{
  //               borderRadius: "50%",
  //               width: "30px",
  //               height: "30px",
  //               marginRight: "6px",
  //             }}
  //           />
  //           <span id="dropdown-basic-profile" style={{ fontWeight: "lighter" }}>
  //             {firstName}
  //           </span>
  //         </div>
  //       </Dropdown.Toggle>
  //       <Dropdown.Menu>
  //         <Dropdown.Item>
  //           <Link
  //             onClick={handleClick}
  //             to={{
  //               pathname: `/user/${id}`,
  //               state: {
  //                 status: "dimple",
  //                 data: data,
  //               },
  //             }}
  //           >
  //             <div>
  //               <img
  //                 src={profile_img}
  //                 slt="person"
  //                 style={{
  //                   borderRadius: "50%",
  //                   width: "20px",
  //                   height: "20px",
  //                   marginRight: "4px",
  //                 }}
  //               />
  //               <span id="pro">Profile</span>
  //             </div>
  //           </Link>
  //         </Dropdown.Item>
  //         <Dropdown.Item>
  //           <NavLink
  //             to="/dashboard"
  //             id="gpr"
  //             onClick={handleClick}
  //             style={{ textDecoration: "none", color: "black" }}
  //           >
  //             <div>
  //               <img
  //                 src={dashboard_img}
  //                 style={{ width: "20px", height: "20px", marginRight: "4px" }}
  //               />
  //               <span id="db">Dashboard</span>
  //             </div>
  //           </NavLink>
  //         </Dropdown.Item>
  //         {isAdmin === true ? (
  //           <Dropdown.Item>
  //             <NavLink
  //               to="/addbook_a_free_session"
  //               onClick={handleClick}
  //               style={{ textDecoration: "none", color: "black" }}
  //             >
  //               <div>
  //                 <img
  //                   src={dashboard_img}
  //                   style={{
  //                     width: "20px",
  //                     height: "20px",
  //                     marginRight: "4px",
  //                   }}
  //                 />
  //                 <span id="cb">Create Blog</span>
  //               </div>
  //             </NavLink>
  //           </Dropdown.Item>
  //         ) : null}

  //         <Dropdown.Item>
  //           <NavLink
  //             to=""
  //             onClick={() => logout()}
  //             style={{ textDecoration: "none", color: "black" }}
  //           >
  //             <div >
  //               <img

  //                 src={logout_img}
  //                 style={{ width: "20px", height: "20px", marginRight: "4px" }}
  //               />
  //               <span id="lg">Logout</span>
  //             </div>
  //           </NavLink>
  //         </Dropdown.Item>
  //       </Dropdown.Menu>
  //     </Dropdown>,
  //   ];
  // };
  const [Competitive, setCompetitive] = useState(false);
  const [aboutus, setaboutus] = useState(false);
  const [Home, setHome] = useState(true);
  const [value, setvalue] = useState("home");
  const [loggedIn, setLoggedin] = useState(false);

  useEffect(() => {
    // console.log("route has been changed");
    var technology = "";

    {
      matches === true ? (technology = "web") : (technology = "mobile");
    }

    if (technology === "web") {
      var home = document.getElementById("home");
      var about_us = document.getElementById("aboutus");
      // var competitive_programming = document.getElementById("dropdown-basic");
      var book_a_free_session = document.getElementById("book_a_free_session");
      if (loggedIn === false) {
        var login = document.getElementById("login");
      }
      switch (value) {
        case "home":
          // console.log("hello home");
          home.style.fontWeight = "600";
          about_us.style.fontWeight = "lighter";
          // competitive_programming.style.fontWeight = "lighter";
          book_a_free_session.style.fontWeight = "lighter";
          if (loggedIn === false) {
            login.style.fontWeight = "lighter";
          }
          break;
        case "aboutus":
          // console.log("hello about");
          home.style.fontWeight = "lighter";
          about_us.style.fontWeight = "600";
          // competitive_programming.style.fontWeight = "lighter";
          book_a_free_session.style.fontWeight = "lighter";
          if (loggedIn === false) {
            login.style.fontWeight = "lighter";
          }
          break;
        case "book_a_free_session":
          // console.log("hello book_a_free_session");
          home.style.fontWeight = "lighter";
          about_us.style.fontWeight = "lighter";
          // competitive_programming.style.fontWeight = "lighter";
          book_a_free_session.style.fontWeight = "600";
          if (loggedIn === false) {
            login.style.fontWeight = "lighter";
          }
          // console.log("hello book_a_free_session");
          break;
        case "login":
          // console.log("hello login");
          home.style.fontWeight = "lighter";
          about_us.style.fontWeight = "lighter";
          // competitive_programming.style.fontWeight = "lighter";
          book_a_free_session.style.fontWeight = "lighter";
          if (loggedIn === false) {
            login.style.fontWeight = "600";
          }
          // console.log("login style is " + login.style.fontWeight);
          break;
        case "competitive":
          // console.log("hello competitive");
          home.style.fontWeight = "lighter";
          about_us.style.fontWeight = "lighter";
          // competitive_programming.style.fontWeight = "600";
          book_a_free_session.style.fontWeight = "lighter";
          if (loggedIn === false) {
            login.style.fontWeight = "lighter";
          }
          break;
      }
    }
  }, [location.pathname]);
  async function SetBolds(e) {
    // var idid = document.getElementById(e.target.id)
    // console.log(e.target.id);
    var idid = e.target.id;
    switch (idid) {
      case "home":
        setvalue("home");
        break;
      case "aboutus":
        setvalue("aboutus");
        break;
      case "book_a_free_session":
        setvalue("book_a_free_session");
        break;
      case "login":
        setvalue("login");
        break;
      // case "dropdown-basic":
      //   setvalue("competitive");
    }

    //  switch (idid) {
    //     case "homes":
    //       setHome(true);
    //       setaboutus(false);
    //       setCompetitive(false);

    //       break;

    //     default:
    //       break;
    //   }
  }

  // async function SetValueCompetitive() {
  //   setvalue("competitive");
  // }
  function LoginComponent() {
    return (
      <>
        <Link
          id="login"
          to="/login"
          onClick={(e) => {
            SetBolds(e);
          }}
          activeClassName="active"
          className="nav-links text-dark"
          style={{
            textDecorationLine: "none",
            marginRight: "15px",
            fontWeight: "lighter",
          }}
        >
          Login
        </Link>
      </>
    );
  }
  function LoggedInComponentWeb() {
    return (
      <>
        <ul className="row">
          <li className="row">
            {" "}
            design name
            <ul>
              <li className="row">Profile</li>

              <li className="row">Dashboard</li>
              <li className="row">Logout</li>
            </ul>
          </li>
        </ul>
      </>
    );
  }
  const [loggedinClick, setLoggedinClick] = useState(false);

  function LoggedInComponentMobile() {
    return (
      <>
        <div
          className="row d-flex justify-content-center p-4 btn-lg btn-white"
          onClick={() => setLoggedinClick(!loggedinClick)}
        >
          {" "}
          <div className="col-sm-9">
            {" "}
            {firstName} <i className="fas fa-caret-down"></i>
          </div>
        </div>
        {loggedinClick === true ? (
          <div data-aos="fade-right">
            <Link
              to={`/user/${id}`}
              onClick={() => {
                handlehamclick();
                Rotation();
              }}
              className="row d-flex justify-content-center p-4 btn-lg btn-light"
              style={{
                borderBottomLeftRadius: "0px",
                borderBottomRightRadius: "0px",
              }}
            >
              {" "}
              Profile
            </Link>
            <div style={{ backgroundColor: "#F9FAFB", width: "100%" }}>
              <div
                className="border mx-auto"
                style={{
                  width: "80%",
                  height: "3px",
                  backgroundColor: "black",
                  color: "black",
                }}
              ></div>
            </div>
            <Link
              to="/dashboard"
              onClick={() => {
                handlehamclick();
                Rotation();
              }}
              className="row d-flex justify-content-center p-4 btn-lg btn-light"
              style={{ borderRadius: "0px" }}
            >
              {" "}
              Dashboard
            </Link>
            <div style={{ backgroundColor: "#F9FAFB", width: "100%" }}>
              <div
                className="border mx-auto"
                style={{
                  width: "80%",
                  height: "3px",
                  backgroundColor: "black",
                  color: "black",
                }}
              ></div>
            </div>
            <Link
              to="/"
              onClick={() => {
                logout();
                handlehamclick();
              }}
              className="row d-flex justify-content-center p-4 btn-lg btn-light"
              style={{
                borderTopRightRadius: "0px",
                borderTopLeftRadius: "0px",
              }}
            >
              {" "}
              Log out
            </Link>
          </div>
        ) : (
          <></>
        )}
      </>
    );
  }

  const [clickcourse, setclickcourse] = useState(false);
  const [click, setclick] = useState(false);
  const [dropdown, setdropdown] = useState(false);
  const [LogoutDropDown, setLogoutDropdown] = useState(false);
  const matches = useMedia("(min-width : 768px )");

  const handlehamclick = () => setclick(!click);

  const closeMobileMenu = () => setclick(false);
  // open close the
  if (token === "" && loggedIn === true) {
    setLoggedin(false);
  }
  useEffect(() => {
    AOS.init();
  });
  useEffect(() => {
    if (open === true) {
      setShowClose(true);
    } else {
      setShowClose(false);
    }
  }, [open]);
  return (
    <>
      <nav
        className="navbar"
        style={{
          position: "sticky",
          top: "0px",
          zIndex: "1",
          background: "#F6F6EE",
        }}
      >
        <div
          className="menu-icon"
          onClick={handlehamclick}
          style={{ float: "left" }}
        >
          {/* <i className={click?'fas fa-times': 'fas fa-bars'}/> */}
          <div className={click ? "ham-btn open " : "ham-btn"}>
            <div className="ham-btn__burger"></div>
          </div>
        </div>
        <Link
          to="/"
          className="navbar-logo"
          style={{
            left: matches === false && "70%",
            bottom: matches === false && "40%",
          }}
        >
          <img
            className={rotate === true ? styles.rotateimg : null}
            onMouseOver={() => setrotate(true)}
            onMouseLeave={() => setrotate(false)}
            id="mainlogo"
            style={{
              width: "45px",
              marginBottom: loggedIn && matches === true ? "-10px" : null,
            }}
            src={MainLogo}
            width="30%"
          />
        </Link>

        <ul
          style={{
            paddingLeft: matches === false ? "0px" : null,
            marginBottom: 0,
          }}
          className={click ? "nav-menu active" : "nav-menu"}
        >
          {/* this code is for the mobile version */}

          {matches === false ? (
            <nav
              className="container-fluid"
              style={{
                zIndex: "1000000",
                backgroundColor: matches === false && "black",
                color: "white",
              }}
            >
              <ul
                className="container-fluid"
                style={{ overflowY: "scroll", height: window.innerHeight }}
              >
                <Link
                  to="#home"
                  onClick={() => {
                    handlehamclick();
                  }}
                  style={{ fontWeight: "bolder" }}
                  className="row d-flex justify-content-center p-4 btn-lg btn-white"
                >
                  HOME
                </Link>
                {/* <Link
                  to="/"
                  onClick={() => handlehamclick}
                  className="row d-flex justify-content-center font-weight-bold btn-lg btn-white"
                  style={{ fontWeight: "bolder" }}
                  onClick={() => setclickcourse(!clickcourse)}
                >
                  <div className="col-sm-12 pt-2">
                    COURSES <i className="fas fa-caret-down"></i>{" "}
                  </div>
                </Link> */}
                {clickcourse === true ? (
                  // <div data-aos="fade-right">
                  //   <Link
                  //     to="/cpcourse"
                  //     onClick={() => {
                  //       handlehamclick();
                  //     }}
                  //     className="row d-flex justify-content-center p-4 btn-lg btn-light"
                  //     style={{
                  //       cursor: "pointer",
                  //       border: "none",
                  //       borderBlockEndWidth: "1px",
                  //       borderBottomLeftRadius: "0px",
                  //       borderBottomRightRadius: "0px",
                  //       left: "30%",
                  //     }}
                  //   >
                  //     Competitive Programming
                  //   </Link>
                  //   <div style={{ backgroundColor: "#F9FAFB", width: "100%" }}>
                  //     <div
                  //       className="border mx-auto"
                  //       style={{
                  //         width: "80%",
                  //         height: "3px",
                  //         backgroundColor: "black",
                  //         color: "black",
                  //       }}
                  //     ></div>
                  //   </div>
                  //   <Link
                  //     to="/placementcourse"
                  //     onClick={() => {
                  //       handlehamclick();
                  //     }}
                  //     className="row d-flex justify-content-center p-4 btn-lg btn-light"
                  //     style={{
                  //       borderTopLeftRadius: "0px",
                  //       borderStyle: "0px",
                  //       borderTopRightRadius: "0px",
                  //     }}
                  //   >
                  //     Placement Course
                  //   </Link>
                  // </div>
                  "Why us"
                ) : (
                  <></>
                )}
                <div
                  onClick={() => {
                    handlehamclick();
                  }}
                >
                  <Link
                    to="/Aboutus"
                    className="row d-flex justify-content-center p-4 btn-lg btn-white"
                    style={{ fontWeight: "bolder" }}
                  >
                    ABOUT US
                  </Link>
                </div>
                <div>
                  <Link
                    activeClassName="active"
                    className="nav-links text-white p-4 btn-lg btn-white"
                    id="whyus"
                    style={{ fontWeight: "bolder", fontSize: "22px" }}
                  >
                    Why us
                  </Link>
                </div>

                {token === false ? (
                  <>
                    <div>
                      <Link
                        to="/login"
                        onClick={() => {
                          setInterval(() => {
                            window.location.reload();
                          }, 500);
                          handlehamclick();
                        }}
                        style={{ fontWeight: "bolder", fontSize: "1rem" }}
                        className="row d-flex justify-content-center p-4 btn-lg btn-white"
                      >
                        LOGIN
                      </Link>
                    </div>
                  </>
                ) : (
                  <div className="container">
                    <LoggedInComponentMobile />
                  </div>
                )}
                <br />
                <br />

                <div className="row">
                  <div className="col">
                    {" "}
                    <img
                      src={youtube}
                      style={{
                        borderRadius: "50%",
                        width: "40px",
                        height: "40px",
                      }}
                    />{" "}
                  </div>
                  <div className="col">
                    {" "}
                    <img
                      src={linkedin}
                      style={{
                        borderRadius: "50%",
                        width: "40px",
                        height: "40px",
                      }}
                    />{" "}
                  </div>
                  <div className="col">
                    {" "}
                    <img
                      src={instagram}
                      style={{
                        borderRadius: "50%",
                        width: "40px",
                        height: "40px",
                      }}
                    />{" "}
                  </div>
                </div>
                <Link className="row d-flex justify-content-center p-4 btn-lg btn-white"></Link>
                <Link className="row d-flex justify-content-center p-4 btn-lg btn-white"></Link>
              </ul>
            </nav>
          ) : (
            <>
              <li id="homes" className="nav-item" onClick={Rotation}>
                <Link
                  to="#home"
                  id="home"
                  className="nav-links text-dark"
                  onClick={(e) => SetBolds(e)}
                  activeClassName="active"
                  style={{
                    textDecorationLine: "none",
                    // marginRight: "15px",
                    fontWeight: "lighter",
                    fontSize: "1rem",
                  }}
                >
                  Home
                </Link>
              </li>
              {/* this will be the drop down */}
              {/* <li
                className="nav-item"
                onMouseEnter={() => setdropdown(true)}
                onMouseLeave={() => setdropdown(false)}
              >
                <Link
                  to="/"
                  className="nav-links"
                  id="dropdown-basic"
                  classnmae=" text-dark"
                  style={{ fontWeight: "lighter" }}
                  variant="success"
                >
                  Courses <i className="fas fa-caret-down"></i>
                </Link>
                {dropdown == true ? (
                  <>
                    <DropdownComp value_function={SetValueCompetitive} />
                  </>
                ) : null}
              </li> */}
              <li className="nav-item">
                <Link
                  activeClassName="active"
                  className="nav-links text-dark"
                  id="whyus"
                  style={{ fontSize: "1rem", padding: "0 1rem" }}
                >
                  Why us
                </Link>
              </li>
              <li className="nav-item" onClick={Rotation}>
                <Link
                  onClick={(e) => SetBolds(e)}
                  id="aboutus"
                  to="/aboutus"
                  activeClassName="active"
                  className="nav-links text-dark"
                  style={{
                    textDecorationLine: "none",
                    // marginRight: "15px",
                    fontWeight: "lighter",
                    fontSize: "1rem",
                    padding: "0 1rem",
                  }}
                >
                  About us
                </Link>
              </li>
              <li className="nav-item" onClick={Rotation}>
                <a
                  onClick={(e) => {
                    SetBolds(e);
                  }}
                  id="book_a_free_session"
                  href="#bookNow"
                  activeClassName="active"
                  className="nav-links text-dark"
                  style={{
                    textDecorationLine: "none",
                    // marginRight: "15px",
                    fontWeight: "lighter",
                    fontSize: "1rem",
                  }}
                >
                  Book 3 FREE Sessions
                </a>
              </li>

              <li className="nav-item">
                {loggedIn === true ? (
                  <>
                    <Link
                      to="/"
                      className="nav-links text-dark"
                      onClick={(e) => {
                        handleClick(e);
                        closeMobileMenu();
                      }}
                      activeClassName="active"
                      style={{
                        textDecorationLine: "none",
                        // marginRight: "15px",
                        fontWeight: "lighter",
                        fontSize: "1rem",
                      }}
                    >
                      <li
                        className="nav-item"
                        style={{ paddingTop: "0px", fontSize: "1rem" }}
                        onMouseEnter={() => setLogoutDropdown(true)}
                        onMouseLeave={() => setLogoutDropdown(false)}
                      >
                        <div
                          className="nav-links"
                          style={{ padding: "0 1rem", color: "black" }}
                        >
                          <i class="fas fa-user-circle"></i> {firstName}
                          <i className="fas fa-caret-down"></i>
                        </div>

                        {LogoutDropDown === true ? (
                          <>
                            <DropdownComp
                              props="logged in"
                              name={firstName}
                              LogoutMethd={() => logout()}
                              user_id={id}
                            />
                          </>
                        ) : null}
                      </li>
                    </Link>
                  </>
                ) : (
                  <>
                    <Link
                      onClick={(e) => {
                        SetBolds(e);
                      }}
                      id="login"
                      to="/login"
                      activeClassName="active"
                      className="nav-links text-dark"
                      style={{
                        textDecorationLine: "none",
                        // marginRight: "15px",
                        bottom: "45px",
                        fontWeight: "lighter",
                        fontSize: "1rem",
                      }}
                    >
                      Login
                    </Link>
                  </>
                )}
              </li>
            </>
          )}
          {/* This code is for the website versions */}
        </ul>
      </nav>
    </>
    // this is the working one
    // <div>
    //   <nav
    //     style={{
    //       backgroundColor: "white",
    //       boxShadow: "0px 3px 4px #00000029",
    //     }}
    //     className=" navbar navbar-expand-lg fixed-top"
    //   >
    //     <div
    //     className="container">
    //       <div className="navbar-brand float-left" href="#">
    //         <NavLink to="/" exact>
    //           <img
    //             className={rotate==true?styles.rotateimg:null}
    //             onMouseOver={()=>setrotate(true)}
    //             onMouseLeave ={()=>setrotate(false)}
    //             id="mainlogo"
    //             style={{ width: "45px" }}
    //             src={MainLogo} width="30%" />
    //         </NavLink>
    //       </div>
    //       {showClose === false ? (
    //         <button
    //           onClick={() => setShowClose(true)}
    //           className="navbar-toggler float-right"
    //           type="button"
    //           data-toggle="collapse"
    //           data-target="#navbarSupportedContent"
    //           aria-controls="navbarSupportedContent"
    //           aria-expanded="false"
    //           aria-label="Toggle navigation"
    //         >
    //           <span className="text-dark fa fa-bars"></span>
    //         </button>
    //       ) : null}

    //       {showClose === true ? (
    //         <button
    //           onClick={() => setShowClose(false)}
    //           className="navbar-toggler float-right"
    //           type="button"
    //           data-toggle="collapse"
    //           data-target="#navbarSupportedContent"
    //           aria-controls="navbarSupportedContent"
    //           aria-expanded="false"
    //           aria-label="Toggle navigation"
    //         >
    //           <i className="fa fa-times"></i>
    //         </button>
    //       ) : null}

    //       <div
    //         //style={{ background: showClose == true ? "white" : null }}
    //         className={`navbar-collapse justify-content-end ${
    //           open == false ? "collapse" : ""
    //         }`}
    //         id="navbarSupportedContent"
    //       >
    //         <ul
    //           className="navbar-nav"
    //          // style={{ paddingLeft: "1%", paddingTop: "2%" }}
    //         >
    //           <li className="nav-item" onClick={()=>{
    //                setrotate(true);
    //                   setTimeout(()=>{
    //                     setrotate(false);
    //                   },2000)
    //           }}>
    //             <h5>
    //               <NavLink
    //                 onClick={ handleClick}
    //                 id="home"
    //                 exact
    //                 to="/"
    //                 activeClassName="active"
    //                // className={styles.navLink}
    //                 style={{
    //                   // paddingLeft: "6px",
    //                   // paddingRight: "6px",
    //                   // paddingTop: "12px",
    //                  // paddingBottom: "0px",
    //                  // paddingLeft: "1px",
    //                  // paddingRight: "10px",
    //                  // textDecorationLine: "none",
    //                  // marginRight: "",
    //                  // fontWeight: "lighter",
    //                 }}
    //                 className=" text-dark"
    //               >
    //                 Home
    //               </NavLink>
    //             </h5>
    //           </li>
    //           <li className="nav-item">
    //             <Dropdown>
    //               <Dropdown.Toggle
    //                 id="dropdown-basic-course"
    //                 style={{
    //                   textDecorationLine: "none",
    //                  // paddingLeft: "1px",
    //                  // paddingTop: "0px",
    //                  // paddingBottom:"12px",
    //                 //  marginTop: "0px",
    //                   fontWeight: "lighter",
    //                   background: "white",
    //                   border: "none",
    //                  // paddingBottom: "16px",
    //                   fontSize: "1.20rem",
    //                   outline: "none",
    //                   boxShadow: "none",
    //                 }}
    //                 className=" text-dark"
    //                 variant="success"
    //               >
    //                 Courses
    //               </Dropdown.Toggle>

    //               <Dropdown.Menu>
    //                 <Dropdown.Item
    //                 onClick={()=>{
    //                       setrotate(true)
    //                       setTimeout(()=>{
    //                       setrotate(false)
    //                       },2000)}}
    //                 >
    //                   <NavLink
    //                     to="/cpcourse"
    //                     id="cp"
    //                     onClick={handleClick}
    //                     style={{ textDecoration: "none", color: "black" }}
    //                   >
    //                     Competitive Programming
    //                   </NavLink>
    //                 </Dropdown.Item>
    //                 <Dropdown.Item
    //                 onClick={()=>{
    //                   setrotate(true)

    //         setTimeout(()=>{
    //           setrotate(false)
    //         },2000)
    //                 }}
    //                 >
    //                   <NavLink
    //                     to="/placementcourse"
    //                     id="gpr"
    //                     onClick={handleClick}
    //                     style={{ textDecoration: "none", color: "black" }}
    //                   >
    //                     Get Placement Ready
    //                   </NavLink>
    //                 </Dropdown.Item>
    //               </Dropdown.Menu>
    //             </Dropdown>
    //           </li>
    //           <li className="nav-item"
    //           onClick={()=>{
    //             setrotate(true);
    //             setTimeout(()=>{
    //               setrotate(false);
    //             },2000)
    //           }}
    //           >
    //             <h5>
    //               <NavLink
    //                 onClick={handleClick}
    //                 id="aboutus"
    //                 to="/Aboutus"
    //                 activeClassName="active"
    //                 className={styles.navLink}
    //                 style={{
    //                   textDecorationLine: "none",
    //               //    marginRight: "1rem",
    //                   fontWeight: "lighter",
    //               //    paddingLeft: "1px",
    //                //   paddingBottom: "25px",
    //                 }}
    //                 className=" text-dark"
    //               >
    //                 About us
    //               </NavLink>
    //             </h5>
    //           </li>
    //           <li className="nav-item">
    //             <h5>
    //               <NavLink
    //                 onClick={handleClick}
    //                 id="book_a_free_session"
    //                 to="/book_a_free_session"
    //                 activeClassName="active"
    //                 className="nav-links"
    //                 style={{
    //                   textDecorationLine: "none",
    //                   fontWeight: "lighter",
    //                 }}
    //                 className=" text-dark"
    //               >
    //                 Blog
    //               </NavLink>
    //             </h5>
    //           </li>
    //           <li className="nav-item">
    //             {token ? renderList() : renderLogin()}
    //           </li>
    //         </ul>
    //       </div>
    //     </div>
    //   </nav>
    // </div>
  );
}

export default B2bNavbar;

import React from "react";

const DownloadFileBtn = ({ name , link}) => {
  return (
    <> 
      <a className="downloadHealth" href={link}>
     
        <span style={{fontWeight: 'bold'}}>{name}</span> - Health Report
        <img
          src="https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1655817057858_akar-icons_download.png"
          className="downloadIcon"
          alt="download btn"
        />
      </a>
    </>
  );
};

DownloadFileBtn.defaultProps = {
  link: ""
}

export default DownloadFileBtn;

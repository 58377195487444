import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import style from "../../Assets/css/b2bTraining.module.css";
//import check from "../../Assets/img/Group 7810.png";
import "../../Assets/css/business.css";
import { BASE_URL } from "../../env";
const B2BTraining = () => {
  const [checkBoxes, setCheckBoxes] = useState([]);
  const [checkboxCount, setCheckboxCount] = useState(0);
  const [text, setText] = useState(false);
  const input_val = useRef(null);
  // const API_ENDPOINT = `https://api.catileverlabs.com/`;

  const training_sessions = {
    resume_writing: "Art & Science of RESUME WRITING",
    impress_interviews: "Express to Impress Interviews",
    group_discussion: "Grab the Spotlight GROUP DISCUSSION",
    harvard_intern: "How to intern at Harvard, Stanford, MIT",
    cpp_timeComplexity: "C/C++ & Time Complexity",
    java_pythonCore: "Java, Python Core & Advanced",
    dataStructures_algo: "Data Structures & Algorithms",
    github_openSource: "GIT HUB, G SOC, Open Source Contribution",
    quantitative_ability: "Quantitative Ability",
    logical_reasoning: "Logical Reasoning",
    verbal_ability: "Verbal Ability",
    recruiters_linkedin: "How to Impress Recruiters on LinkedIn",
  };

  async function checkedBoxes() {
    let checkboxes;
    checkboxes = document.querySelectorAll(
      ".checkbox-options > div > div > input:checked"
    );
    let uncheckboxes = document.querySelectorAll(
      `.checkbox-options > div > div > input[type="checkbox"]:not(:checked)`
    );
    if (checkboxes.length === 3) {
      setText(true);
      uncheckboxes.forEach((checkbox) => {
        checkbox.setAttribute("disabled", "");
      });
    }
    if (checkboxes.length <= 2) {
      setText(false);
      uncheckboxes.forEach((checkbox) => {
        checkbox.removeAttribute("disabled");
      });
    }
    await setCheckboxCount(checkboxes.length);
    let checked_arr = [];
    checkboxes.forEach((checkbox) => {
      checked_arr.push(checkbox.attributes["id"].nodeValue);
    });
    await setCheckBoxes(checked_arr);
  }

  useEffect(() => {
    // console.log(checkboxCount);
  }, [checkboxCount]);

  return (
    <div id="training">
      <div className="container-fluid" style={{ margin: "8% 0 " }}>
        <div className="row justify-content-around my-0">
          <div
            className={`col-xl-2 col-lg-2 col-md-3 mr-3 ${style.firstPart_J}`}
          >
            <h4>
              Book <span>{3 - checkboxCount}</span> FREE training sessions
            </h4>
            <hr className={`${style.below_hd_J}`} />
            <p>Choose any 3 topics from the list</p>
            <br />
            {!text ? (
              <p className={`${style.bold_firstPart_J}`}>
                Meet our Trainers & Know our methodology
              </p>
            ) : (
              <p
                className={`${style.bold_firstPart_J}`}
                style={{ color: "#185adb" }}
              >
                You have chosen 3 fantastic training sessions !!
              </p>
            )}
          </div>

          <div className={`col-xl-8 col-lg-8 col-md-8 ${style.secondPart_J}`}>
            <div className="row justify-content-around justify-items-center">
              <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-12 d-flex flex-column align-items-center justify-content-center checkbox-options">
                <div className="text-center mx-auto">
                  <h4>SOFT SKILLS</h4>
                </div>
                <div
                  className={`btn ${style.white_buttons_J} p-2 my-4 d-flex justify-content-around`}
                >
                  <label htmlFor="resume_writing" className="mx-3">
                    <p>Art & Science of</p>
                    <p>RESUME WRITING</p>
                  </label>
                  <div className="round">
                    <input
                      type="checkbox"
                      id="resume_writing"
                      onChange={checkedBoxes}
                    />
                    <label htmlFor="resume_writing"></label>
                  </div>
                  {/* <input type="checkbox" id="resume_writing" style={{opacity: checkBoxes.includes("resume_writing")?"0":"1"}} onChange={checkedBoxes}/>
                  {
                    checkBoxes.includes("resume_writing") &&
                    <div className={`${style.tick_img_J}`}>
                      <img src={check} alt="" />
                    </div>
                  } */}
                </div>

                <div
                  className={`btn ${style.white_buttons_J} p-2 my-4 d-flex justify-content-around`}
                >
                  <label htmlFor="impress_interviews" className="mx-3">
                    <p>Express to </p>
                    <p>Impress Interviews</p>
                  </label>
                  <div className="round">
                    <input
                      type="checkbox"
                      id="impress_interviews"
                      onChange={checkedBoxes}
                    />
                    <label htmlFor="impress_interviews"></label>
                  </div>
                  {/* <input type="checkbox" id="impress_interviews" onChange={checkedBoxes}/>
                  {
                    checkBoxes.includes("impress_interviews") &&
                    <div className={`${style.tick_img_J}`}>
                      <img src={check} alt="" />
                    </div>
                  } */}
                </div>

                <div
                  className={`btn ${style.white_buttons_J} p-2 my-4 d-flex justify-content-around`}
                >
                  <label htmlFor="group_discussion" className="mx-3">
                    <p>Grab the Spotlight</p>
                    <p>GROUP DISCUSSION</p>
                  </label>
                  <div className="round">
                    <input
                      type="checkbox"
                      id="group_discussion"
                      onChange={checkedBoxes}
                    />
                    <label htmlFor="group_discussion"></label>
                  </div>
                  {/* <input type="checkbox" id="group_discussion" onChange={checkedBoxes}/>
                  {
                    checkBoxes.includes("group_discussion") &&
                    <div className={`${style.tick_img_J}`}>
                      <img src={check} alt="" />
                    </div>
                  } */}
                </div>

                <div
                  className={`btn ${style.white_buttons_J} p-2 my-4 d-flex justify-content-around`}
                >
                  <label htmlFor="harvard_intern" className="mx-3">
                    <p>How to Intern at</p>
                    <p>Harvard, Stanford, MIT</p>
                  </label>
                  <div className="round">
                    <input
                      type="checkbox"
                      id="harvard_intern"
                      onChange={checkedBoxes}
                    />
                    <label htmlFor="harvard_intern"></label>
                  </div>
                  {/* <input type="checkbox" id="harvard_intern" onChange={checkedBoxes}/>
                  {
                    checkBoxes.includes("harvard_intern") &&
                    <div className={`${style.tick_img_J}`}>
                      <img src={check} alt="" />
                    </div>
                  } */}
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-12 d-flex flex-column align-items-center justify-content-center checkbox-options">
                <div className="text-center mx-auto">
                  <h4 className={`${style.secondPart_J} `}>COMPUTER SCIENCE</h4>
                </div>
                <div
                  className={`btn ${style.white_buttons_J} p-2 my-4 d-flex justify-content-around`}
                >
                  <label className="mx-3" htmlFor="cpp_timeComplexity">
                    <p>C/C++ &</p>
                    <p>Time Complexity</p>
                  </label>
                  <div className="round">
                    <input
                      type="checkbox"
                      id="cpp_timeComplexity"
                      onChange={checkedBoxes}
                    />
                    <label htmlFor="cpp_timeComplexity"></label>
                  </div>
                  {/* <input type="checkbox" id="cpp_timeComplexity" onChange={checkedBoxes}/>
                  {
                    checkBoxes.includes("cpp_timeComplexity") &&
                    <div className={`${style.tick_img_J}`}>
                      <img src={check} alt="" />
                    </div>
                  } */}
                </div>

                <div
                  className={`btn ${style.white_buttons_J} p-2 my-4 d-flex justify-content-around`}
                >
                  <label className="mx-3" htmlFor="java_pythonCore">
                    <p>Java, Python</p>
                    <p>Core & Advanced</p>
                  </label>
                  <div className="round">
                    <input
                      type="checkbox"
                      id="java_pythonCore"
                      onChange={checkedBoxes}
                    />
                    <label htmlFor="java_pythonCore"></label>
                  </div>
                  {/* <input type="checkbox" id="java_pythonCore" onChange={checkedBoxes}/>
                  {
                    checkBoxes.includes("java_pythonCore") &&
                    <div className={`${style.tick_img_J}`}>
                      <img src={check} alt="" />
                    </div>
                  } */}
                </div>

                <div
                  className={`btn ${style.white_buttons_J} p-2 my-4 d-flex justify-content-around`}
                >
                  <label className="mx-3" htmlFor="dataStructures_algo">
                    <p>Data Structures</p>
                    <p> & Algorithms</p>
                  </label>
                  <div className="round">
                    <input
                      type="checkbox"
                      id="dataStructures_algo"
                      onChange={checkedBoxes}
                    />
                    <label htmlFor="dataStructures_algo"></label>
                  </div>
                  {/* <input type="checkbox" id="dataStructures_algo" onChange={checkedBoxes}/>
                  {
                    checkBoxes.includes("dataStructures_algo") &&
                    <div className={`${style.tick_img_J}`}>
                      <img src={check} alt="" />
                    </div>
                  } */}
                </div>

                <div
                  className={`btn ${style.white_buttons_J} p-2 my-4 d-flex justify-content-around`}
                >
                  <label htmlFor="github_openSource">
                    <p>GIT HUB, G SOC,</p>
                    <p>Open Source Contribution</p>
                  </label>
                  <div className="round">
                    <input
                      type="checkbox"
                      id="github_openSource"
                      onChange={checkedBoxes}
                    />
                    <label htmlFor="github_openSource"></label>
                  </div>
                  {/* <input type="checkbox" id="github_openSource" onChange={checkedBoxes}/>
                  {
                    checkBoxes.includes("github_openSource") &&
                    <div className={`${style.tick_img_J}`}>
                      <img src={check} alt="" />
                    </div>
                  } */}
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-12 d-flex flex-column align-items-center justify-content-center checkbox-options">
                <div className="text-center mx-auto">
                  <h4 className={`${style.secondPart_J} `}>APTITUDE</h4>
                </div>

                <div
                  className={`btn ${style.white_buttons_J} p-2 my-4 d-flex justify-content-around`}
                >
                  <label className="mx-3" htmlFor="quantitative_ability">
                    <p>Quantitative</p>
                    <p>Ability</p>
                  </label>
                  <div className="round">
                    <input
                      type="checkbox"
                      id="quantitative_ability"
                      onChange={checkedBoxes}
                    />
                    <label htmlFor="quantitative_ability"></label>
                  </div>
                  {/* <input type="checkbox" id="quantitative_ability" onChange={checkedBoxes}/>
                  {
                    checkBoxes.includes("quantitative_ability") &&
                    <div className={`${style.tick_img_J}`}>
                      <img src={check} alt="" />
                    </div>
                  } */}
                </div>

                <div
                  className={`btn ${style.white_buttons_J} p-2 my-4 d-flex justify-content-around`}
                >
                  <label className="mx-3" htmlFor="logical_reasoning">
                    <p>Logical </p>
                    <p>Reasoning</p>
                  </label>
                  <div className="round">
                    <input
                      type="checkbox"
                      id="logical_reasoning"
                      onChange={checkedBoxes}
                    />
                    <label htmlFor="logical_reasoning"></label>
                  </div>
                  {/* <input type="checkbox" id="logical_reasoning" onChange={checkedBoxes}/>
                  {
                    checkBoxes.includes("logical_reasoning") &&
                    <div className={`${style.tick_img_J}`}>
                      <img src={check} alt="" />
                    </div>
                  } */}
                </div>

                <div
                  className={`btn ${style.white_buttons_J} p-2 my-4 d-flex justify-content-around`}
                >
                  <label className="mx-3" htmlFor="verbal_ability">
                    <p>Verbal </p>
                    <p>Ability</p>
                  </label>
                  <div className="round">
                    <input
                      type="checkbox"
                      id="verbal_ability"
                      onChange={checkedBoxes}
                    />
                    <label htmlFor="verbal_ability"></label>
                  </div>
                  {/* <input type="checkbox" id="verbal_ability" onChange={checkedBoxes}/>
                  {
                    checkBoxes.includes("verbal_ability") &&
                    <div className={`${style.tick_img_J}`}>
                      <img src={check} alt="" />
                    </div>
                  } */}
                </div>

                <div
                  className={`btn ${style.white_buttons_J} p-2 my-4 d-flex justify-content-around`}
                >
                  <label className="mx-3" htmlFor="recruiters_linkedin">
                    <p>How to Impress</p>
                    <p>Recruiters on LinkedIn</p>
                  </label>
                  <div className="round">
                    <input
                      type="checkbox"
                      id="recruiters_linkedin"
                      onChange={checkedBoxes}
                    />
                    <label htmlFor="recruiters_linkedin"></label>
                  </div>
                  {/* <input type="checkbox" id="recruiters_linkedin" onChange={checkedBoxes}/>
                  {
                    checkBoxes.includes("recruiters_linkedin") &&
                    <div className={`${style.tick_img_J}`}>
                      <img src={check} alt="" />
                    </div>
                  } */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {checkboxCount > 0 ? (
          <div className={`${style.btn_div} `}>
            {/* <input 
            type="email" 
            placeholder="Email ID" 
            style={{
              padding: "0.3rem", 
              border: "3px solid #000", 
              width:"20%",
              borderRadius:"10px",
              marginLeft:"12%"
            }} 
            id="email_addressB2B"
            ref={input_val}
          /> */}
            <Link
              to={{
                  pathname: '/b2bform',
                  state: { checkBoxes: checkBoxes }
              }}

              type="button"
              
              className={`btn p-2 ${style.black_btn_J} mr-5 mb-5`}
            >
              Book 3 Free training sessions
            </Link>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
export default B2BTraining;

import React, { useRef, useState } from "react";
import styles from "../Assets/css/confusedform.module.css";

import sidePic from "../Assets/img/course/confused_illustration.png";
import rightArrow from "../Assets/img/course/arrow-right-black.png";
import validator from "validator";
import { BASE_URL } from "../env";

export function ConfusedForm(props) {
  const [fullName, setfullName] = useState("");
  const [phoneNumber, setphoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [query, setQuery] = useState(props.coursename);
  const [graduationYear, setGraduationYear] = useState("");
  const [msg, setMsg] = useState("");
  const [showMsg, setShowMsg] = useState(false);
  const [hide, setHide] = useState(false);
  const [loader, setLoader] = useState(false);

  async function handleSubmit(event) {
    event.preventDefault();
    if (
      validator.isEmail(email) &&
      validator.isNumeric(phoneNumber) &&
      phoneNumber.length === 10 &&
      validator.isNumeric(graduationYear)
    ) {
      setLoader(true);
      await fetch(BASE_URL + "/addQuery", {
        method: "POST",
        body: JSON.stringify({
          fullName,
          phoneNumber,
          email,
          query,
          graduationYear,
        }),
        headers: { "content-Type": "application/json" },
      })
        .then((response) => response.json())
        .then((data) => {
          setMsg(data.message);
          setShowMsg(true);
          setLoader(false);
          setHide(true);
        });
    } else {
      if (!validator.isEmail(email)) {
        setMsg("Email is not correct");
      } else if (
        !validator.isNumeric(phoneNumber) ||
        phoneNumber.length != 10
      ) {
        setMsg("Phone number is not correct");
      } else if (!validator.isNumeric(graduationYear)) {
        setMsg("Graduation year is not correct");
      }
      setShowMsg(true);
    }
  }

  return (
    <div>
      <div className={styles.cpoutercontainer}>
        <div className={styles.cpleftcontainer}>
          <img className={styles.cpsidePic} src={sidePic} alt="" />
          <div
            className={`${styles.cpconfusedtext}  ${styles.outerconfusedcontainer}`}
          >
            <h2 className={styles.cpconfusedtextprim}>Confused ?</h2>
            <p className={styles.cpconfusedtextsecond}>
              Get Expert guidance!!!
            </p>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img className={styles.cprightarrow} src={rightArrow} alt="" />
        </div>

        {hide === false ? (
          <div id="formRef" className={styles.cprightcontainer}>
            <div className={`${styles.cpgetincontain} col-lg-4 col-md-12`}>
              <div className={styles.inpcontain}>
                <input
                  onChange={(event) => setfullName(event.target.value)}
                  className={styles.yelloin}
                  placeholder="First Name"
                />
              </div>
              <div className={styles.inpcontain}>
                <input
                  onChange={(event) => setEmail(event.target.value)}
                  className={styles.yelloin}
                  placeholder="Email Address"
                />
              </div>
              <div className={styles.inpcontain}>
                <input
                  onChange={(event) => setphoneNumber(event.target.value)}
                  className={styles.yelloin}
                  placeholder="Phone Number"
                />
              </div>
              <div className={styles.inpcontain}>
                <input
                  onChange={(event) => setGraduationYear(event.target.value)}
                  className={styles.yelloin}
                  placeholder="Gradution Year"
                />
              </div>
              {showMsg === true ? (
                <div style={{ color: "red" }}> {msg} </div>
              ) : null}
              <div className={styles.inpcontain}>
                <input
                  onClick={handleSubmit}
                  className={styles.yelloinsub}
                  type="submit"
                  value={loader === false ? "Get In Touch" : "Loading..."}
                />
              </div>
            </div>
          </div>
        ) : (
          <p>We will get back to you.</p>
        )}
      </div>
    </div>
    // <div className="container-fluid outer-confused-container">
    //   <div>
    //     <div className="confused-container col-lg-8 col-md-12">
    //       <div className="cp-confused-text">
    //         <h2 className="confused-text-prim">Confused ?</h2>
    //         <p className="confused-text-second">Get Expert guidance!!!</p>
    //       </div>{" "}
    //       <img className="confusion-arrow" src={rightArrow} alt="" />
    //     </div>
    //   </div>
    //   <div>
    //     <div className="cp-getin-contain col-lg-4 col-md-12">
    //       <div className={styles.inpcontain}>
    //         <input className="yelloin" placeholder="First Name" />
    //       </div>
    //       <div className={styles.inpcontain}>
    //         <input className="yelloin" placeholder="Email Address" />
    //       </div>
    //       <div className={styles.inpcontain}>
    //         <input className="yelloin" placeholder="Phone Number" />
    //       </div>
    //       <div className={styles.inpcontain}>
    //         <input className="yelloin" placeholder="Gradution Year" />
    //       </div>
    //       <div className={styles.inpcontain}>
    //         <input className="yelloin-sub" type="submit" value="Get In Touch" />
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
}

export default ConfusedForm;

import React from "react";
import style from "../Assets/css/newmasterclass.module.css";
import img1 from "../Assets/img/IITBombay/Group 2015.svg";
import img2 from "../Assets/img/IITBombay/Group 2016(2).svg";
import img3 from "../Assets/img/IITBombay/Group 2017.svg";
import img4 from "../Assets/img/IITBombay/Group 2018.svg";
import useMedia from "../componentsofcourse/useMedia";

const BannerComp = () => {
  const matches = useMedia("(min-width : 768px )");

  const textBannerComp2 = [
    {
      img: img1,
    
    },
    {
      img: img2,
    },
    {
      img: img3,
     
    },
    {
      img: img4,
      
    },
  ];

  return (
    <section className="container-fluid my-5" style={{ background: "#F5F5F5" }}>
      <div
        className="row d-flex flex my-5"
        style={{ margin: "0rem 0", paddingTop: "6rem" }}
      >
        {textBannerComp2.map((oneBanner, index) => {
          return (
            <div
              className={"col-md-3 col-sm-6 mx-auto" }
              key={index}
              
            >
              <div
                className={ " py-3  mx-auto "}
                
              >
                <div className={  " mx-auto "}>
                  <img
                    src={oneBanner.img}
                    alt={oneBanner.title}
                   
                  />
                </div>
              </div>
             
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default BannerComp;

export const BannerCompOld = () => {
  return (
    <section className="container-fluid">
      <div className="row">
        <div className="col-md-3 col-sm-6">
          <div className={style.bannerBox}>
            <div className={style.bannerBoxHeading}>320+</div>

            <div className={style.bannerBoxText}>
              Students got placed @FAANG
            </div>
            <br />
          </div>
        </div>
        <div className="col-md-3 col-sm-6">
          <div className={style.bannerBox}>
            <div className={style.bannerBoxHeading}>1.5 Cr</div>
            <div className={style.bannerBoxText}>
              Highest Package received by one of our brightest students
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-6">
          <div className={style.bannerBox}>
            <div className={style.bannerBoxHeading}>24,000+</div>
            <div className={style.bannerBoxText} style={{ padding: "10px" }}>
              Students trained at IITs, SNIST, JNTU, GITAM, MEC, SIBM-H
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-6">
          <div className={style.bannerBox}>
            <div className={style.bannerBoxHeading}>4.9/5</div>
            <div className={style.bannerBoxText}>
              Rated by IITians & BITSians
            </div>
            <br />
          </div>
        </div>
      </div>
    </section>
  );
};

import React, { useRef } from "react";
import { createAvatar } from "@dicebear/avatars";
import * as style from "@dicebear/avatars-initials-sprites";

const StudentScore = ({ text, count }) => {
  let svg = useRef(
    createAvatar(style, {
      seed: text,
      chars: 1,
      fontSize: 50,
      translateX: 50,
      backgroundColor: "#24B47E",
    })
  );
  return (
    <>
      <div className="col-md-4 col-6 studentScore">
        <div
          className="profile"
          dangerouslySetInnerHTML={{ __html: svg.current }}
        ></div>
        <div className="profileDetails">
          <div className="name">{text}</div>
          {/* <div className="score" >{count}</div> */}
        </div>
      </div>
    </>
  );
};

export default StudentScore;

import React, { useRef, useEffect, useState } from "react";
import '../Assets/css/reactmodel.css'
import displayRazorpay from "../Razorpay";
import displayRazorpay2 from "../MiniCourseRazorpay";
import useMedia from "../componentsofcourse/useMedia";
// import Modal from './NewCouponModal.js';
import Modal from "react-modal";
import { useLocation } from 'react-router-dom';
import icon1 from "../Assets/img/cal_icon.png";
import icon2 from "../Assets/img/razorpay.png";
import icon3 from "../Assets/img/shield.png";
import dp from "../Assets/img/Ellipse.png";
import styles from "../Assets/css/NewCoupons.module.css";
import { BASE_URL } from "../env";

Modal.setAppElement("#root");

function NewCoupons(props) {

  const Location=useLocation()
  const course_begin_date="5 September";

  // console.log(props.courseId);
  // const BASE_URL = "http://localhost:5000";
  const before_mobile_view = useMedia("(min-width : 770px )");
  const size2 = useMedia("(min-width : 570px )");
  const size3 = useMedia("(min-width : 450px )");
  const size4 = useMedia("(min-width : 380px )");

  const [apply2, setApply2] = useState(false);

  const [modal, setModal] = useState(false);
  // const [apply, setApply] = useState(false);
  // var apply;
  const [name, setName] = useState(false);
  const [Id, setId] = useState(false);

  const setData = props.setData;
  const setCoupon = props.setCoupon;
  const tierId = props.tierId;
  const setCouponCode = props.setCouponCode;

  const token = props.token;
  const setLoadT1 = props.setLoadT1;
  const setLoadT2 = props.setLoadT2;
  const setLoadT3 = props.setLoadT3;
  const setLoadT4 = props.setLoadT4;

  const handlePaymentPopup = props.handlePaymentPopup;
  const startAmount = props.amount;
  // console.log(props.amount + "amount -premium");
  const setHandlePayment = props.setHandlePayment;

  const [couponApplied, setCouponApplied] = useState(false);

  let [amount, setAmount] = useState(startAmount);
  let [discountCount, setDiscountCount] = useState(0);

  let [applied, setApplied] = useState("Apply");
  let [msgApply, setMsgApply] = useState("Apply");
  const code = useRef("");
  const [handleOrder, setHandleOrder] = useState(false);
  const [loader, setLoader] = useState(false);

  function couponValidate(event) {
    event.preventDefault();
 let subtitle;
    let coupon_code = code.current.value;
    // console.log("coupon_code", coupon_code);
    if (coupon_code != "") {
      fetch(BASE_URL + "/getCoupon", {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ couponCode: coupon_code }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data?.message[0]?.couponCode !== undefined) {
            // console.log("Coupon", data.message[0].couponCode);
            const initialAmount = startAmount;
            let discount = data.message[0].percentage;
            // console.log("discount", discount);
            // amount = ;
            // amount = Math.ceil(amount);
            // discountCount = discountCount + 1;
            setDiscountCount(discountCount + 1);
            setAmount(
              Math.ceil(initialAmount - (initialAmount * discount) / 100)
            );
            // console.log("AMount after coupon", amount);

            setMsgApply("Applied");

            setCouponApplied(true);
          }
          else {
            alert("the coupon code is wrong")
          }
          // const coupons = data.coupons;

          // for (let coupon of coupons) {
          //   if (coupon_code == coupon.couponCode && coupon.numAllowed > 0) {

          //   } else if (coupon_code != coupon.couponCode) {
          //     setAmount(startAmount);

          //     setMsgApply("Not Valid");
          //   }
          // }
        });
    } else {
      setAmount(startAmount);
      setApplied("Apply");
    }
  }

  function handlePay(event) {
    event.preventDefault();
    setLoader(true);

    let coupon_code;
    if (props.apply == false) {
      coupon_code = "";
    } else {
      if (code.current.value != "") {
        coupon_code = code.current.value;
      } else {
        coupon_code = "";
      }
    }

    //

    // check =

    // displayRazorpay(
    //   tierId,
    //   coupon_code,
    //   token,
    //   setLoadT1,
    //   setLoadT2,
    //   setLoadT3,
    //   setCoupon,
    //   handlePaymentPopup,
    //   setData,
    //   setHandlePayment
    // );


if(props.course==="fasttrackcourse")   
{
  displayRazorpay2(
    tierId,
    coupon_code,
    token,
    setLoadT1,
    setLoadT2,
    setLoadT3,
    setLoadT4,
    setCoupon,
    setHandlePayment,
    setHandleOrder,
    props.courseId
    // handlePaymentPopup
) 
  
  .then((data) => {
    setData(data);
    // console.log(props.courseId)
  });

}
else
{
  displayRazorpay(
      tierId,
      coupon_code,
      token,
      setLoadT1,
      setLoadT2,
      setLoadT3,
      setLoadT4,
      setCoupon,
      setHandlePayment,
      setHandleOrder,
      props.courseId
      // handlePaymentPopup
  ) 
    
    .then((data) => {
      setData(data);
    });
  }
    setCouponCode(coupon_code);
    // async function fetchData() {
    //   let data = JSON.stringify(check);
    //   data = JSON.parse(data);
    //   return data;
    // }

    // setData(check);
    //
    //

    // .then((response) => response.json())
    // .then((data) => {
    //
    // });

    //
    props.onClose();
  }

  const customStyles = {
  content: {
    overflowY:"scroll"
  },
};

  const closeOnEscapeKeyDown = (e) => {
    if ((e.charCode || e.keyCode) === 27) {
      props.onClose();
    }
  };

  let subtitle;
  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.overflowY = 'scroll';
  }

  useEffect(() => {
    fetch(BASE_URL + "/getProfile", {
      headers: { authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log(data);
        setId(data?.user?._id);
        setName(data?.user?.firstName + " " + data?.user?.lastName);
      });
  }, []);

  useEffect(() => {
    document.body.addEventListener("keydown", closeOnEscapeKeyDown);
    return function cleanup() {
      document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
    };
  }, []);

  return (
    <div>
      {/* <button onClick={()=>setModal(true)}>open it</button> */}

      {/* <Modal 
          in={props.show}
          name={name}
          Id={Id}
          amount={props.amount}
          original_amount={props.original_amount}
          dialogClassName="custom-modal"
          unmountOnExit
          apply={props.apply}
          timeout={{ enter: 0, exit: 300 }}
          isOpen={props.show}
          onRequestClose={() => setModal(false)}
          handlePay={handlePay}
          couponValidate={couponValidate}
    /> */}
      {/* Modal.defaultStyles = {}  */}
      <Modal
        // onAfterOpen={afterOpenModal}
        className="modal_content_fix"
        amount={props.amount}
        original_amount={props.original_amount}
        apply={props.apply}
        original_amount={props.original_amount}
        unmountOnExit
        timeout={{ enter: 0, exit: 300 }}
        isOpen={props.show}
        style={customStyles}
        // onRequestClose={() => setModal(false)}
      >
        <form
          onSubmit={(e) => {
            handlePay(e);
          }}
        >
          <div
            className={
              props.apply || apply2
                ? styles.modal_div_withApply
                : styles.modal_div
            }
          >

            <button className={styles.newcrossbutton} style={{ float:"right",display:"block",marginRight:"0px"}} onClick={() => {
              return props.setshow(false)
            }
            }> X</button>   
            {/* <button style={{marginLeft:"60px"}} onClick={()=>setModal(false)}>Close it</button> */}
            <p className={styles.cyo}>Confirm your order</p>            
            {size2 ? (
              <div className="row">
                <div className="col-3">
                  <img src={dp} style={{ width: "50px", height: "50px" }} />
                </div>
                <div className="col-8">
                  <b style={{ fontSize: "20px" }}>{name}</b>
                  <br />
                  {Id}
                </div>
                <hr className={styles.NewCoupons_hr} />
              </div>
            ) : (
              <div className="row">
                <img src={dp} style={{ width: "80px", height: "50px" }} />
                <div className="col-12">
                  <b style={{ fontSize: "20px" }}>{name}</b>
                  <br />
                  {Id}
              
                  <hr className={styles.NewCoupons_hr} />
                </div>
              </div>
            )}

            {size3 ? (
              <div>
                <div className="row">
                  <div className={" col-8 " + styles.fastTrack}>{props.name}</div>
                  <div className={" col-4 " + styles.original_price}>
                    Rs.{props.original_amount}
                  </div>
                </div>

                <div className="row">
                  <div className={" col-8 " + styles.end_txt}>
               {/* {   Location.pathname==="/newcoursepage" &&
                    <p>
                      Course begins by{" "}
                      <span style={{ color: "red" }}>{course_begin_date}</span>{" "}
                    </p>
                    } */}
                  </div>
                  <div
                    className={" col-4 " + styles.disc_price}
                    style={{ marginTop: "-10px" }}
                  >
                    Rs. {props.amount}
                    {/* Rs 9,999/- */}
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <div className={styles.fastTrack}>{props.name}</div>
                <div className={styles.end_txt}>
              {/* {    Location.pathname==="/newcoursepage" &&
                  <p>
                    Course begins by{" "}
                    <span style={{ color: "red" }}>{course_begin_date}</span>{" "}
                  </p>} */}
                </div>
                <div className="row">
                  <div className={" col-6 " + styles.original_price}>
                    Rs.{props.original_amount}
                  </div>
                  <div
                    className={" col-6 " + styles.disc_price}
                    style={{ marginTop: "-10px" }}
                  >
                    Rs. {props.amount}
                    {/* Rs 9,999/- */}
                  </div>
                </div>
              </div>
            )}

            {/* {
              <p onClick={() => setApply2(!apply2)} className={styles.acc}>
                Apply coupon code
              </p>
            } */}
            {(props.apply || apply2) && (
              // <input
              //     type="text"
              //     placeholder="Coupon Code"
              //     style={{textAlign:"center",marginLeft:"20%",borderRadius:"10px"}}
              //     height="20px"
              //     width="400px" />

              <div class={`${styles.formgroup} d-flex`}>
                <input
                  type="text"
                  class={styles.formcontrol}
                  id="coupon-code"
                  placeholder="Apply Coupon Code"
                  name="coupon-code"
                  ref={code}
                  // value="Not Applied"
                  style={{ height: "8px", cursor: "pointer" }}
                />
                <div
                  onClick={() => {}}
                  class={styles.cancellink}
                  style={{ cursor: "pointer" }}
                  className="mx-2 my-auto"
                >
                  <h6
                    onClick={(e) => {
                      couponValidate(e);
                      // setMsgApply(true);
                    }}
                    style={{ marginTop: "0", cursor: "pointer" }}
                    class="cancel-link"
                  >
                    {msgApply}
                  </h6>

                </div>
              </div>
            )}

            {/* {props.apply && (
              <div class={styles.appliedlink}>
                <h6
                  onClick={() => {
                    // setApply(false);
                    setMsgApply("Apply");
                    setAmount(startAmount);
                  }}
				  style={{cursor:"pointer"}}
                >
                  Close
                </h6>
              </div>
            )} */}

            <div className="row">
              <hr className={styles.NewCoupons_hr} />
            </div>

            <h2 className={styles.cpn_ttl}>Total</h2>

            {size3 ? (
              <div className="row">
                <div className={" col-7 " + styles.end_txt}>
                  Price inclusive of 18% GST
                </div>
                <div
                  className={" col-5 " + styles.disc_price}
                  style={{ fontSize: "25px", marginTop: "-10px" }}
                >
                  Rs.
                  {couponApplied === false ? (
                    <b>{props.amount}/-</b>
                  ) : (
                    <b>{amount}/-</b>
                  )}
                  {/* Rs 9,999 */}
                </div>
              </div>
            ) : (
              <div>
                <div
                  className={" col-5 " + styles.disc_price}
                  style={{ fontSize: "25px", marginTop: "-10px" }}
                >
                  Rs.
                  {couponApplied === false ? (
                    <b>{props.amount}/-</b>
                  ) : (
                    <b>{amount}/-</b>
                  )}
                </div>
                <div className={styles.end_txt}>Price inclusive of 18% GST</div>
              </div>
            )}

            <button
              type="submit"
              className={
                before_mobile_view ? styles.proceed_btn : styles.mob_proceed_btn
              }
              onClick={() => {}}
              style={{ display: "block" }}
            >
              Proceed to pay
            </button>
            <br />

            {/* <img src={icon} />
            <p className={" col-5 " + styles.end_txt}>
              7 day Money back Guarantee
            </p> */}

            {size4 ? (
              <div className="row">
                <div className="col-4">
                  <img src={icon1} />
                  <p className={styles.end_txt}>7 day Money back Guarantee</p>
                </div>
                <div className="col-3">
                  <img src={icon2} />
                  <p className={styles.end_txt}>Powered byRazorPay</p>
                </div>
                <div className="col-5">
                  <img src={icon3} />
                  <p className={styles.end_txt}> 100% Safe &Secure Payment</p>
                </div>
              </div>
            ) : (
              <div className="row" style={{ textAlign: "center" }}>
                <div className="col-12">
                  <img src={icon1} />
                  <p className={styles.end_txt}>7 day Money back Guarantee</p>
                </div>
                <div className="col-12">
                  <img src={icon2} />
                  <p className={styles.end_txt}>Powered byRazorPay</p>
                </div>
                <div className="col-12">
                  <img src={icon3} />
                  <p className={styles.end_txt}> 100% Safe &Secure Payment</p>
                </div>
              </div>
            )}
          </div>
        </form>
      </Modal>
    </div>
  );
}

export default NewCoupons;

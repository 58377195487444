import React from "react";
import styles from "../Assets/css/cpcourse.module.css";
import useMedia from "../componentsofcourse/useMedia";
function Testimonials() {
  const matches = useMedia("(min-width : 768px )");
  return (
    <div>
      <h1
        style={{
          textAlign: "center",
          fontFamily: "Poppins-Bold",
          marginTop: "10%",
        }}
      >
        Testimonials
      </h1>
      <h1 style={{ textAlign: "center" }}>
        <span className="fa fa-arrow-circle-down"></span>
      </h1>
      <br />
      <div className="" style={{ backgroundColor: "#00A2C8" }}>
        <br />
        <br />
        <div className="row" style={{ marginLeft: matches ? "12%" : "6%" }}>
          <div className="col-md-4 col-xs-12 col-12 col-12 justify-content-center pb-2">
            <img
              src="https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644055344268_Image%201.png"
              className="img-fluid"
              style={{ marginLeft: matches == false ? "3%" : null }}
            />
          </div>
          <div
            style={{ textAlign: "left" }}
            className="col-md-6 col-xs-12 col-12 col-12"
          >
            <div>
              <h4 style={{ color: "white" }}>SARVESH MEHTANI</h4>
            </div>
            <div>
              <h5 style={{ color: "white" }}>IIT Bombay - Computer Science</h5>
            </div>
            <br />
            <div>
              <p style={{ color: "white" }}>
                Overall, I loved the fact that the whole process was tailored as
                per the individual's interests and goals which made it very
                effective in pointing out our areas of strength and the areas
                that we need to work on.
              </p>
            </div>

            <br />
            <br />
            <div>
              <button className={styles.sarveshbtn}>
                A.I.R 1, JEE ADVANCED 2017
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Testimonials;

import axios from "axios";
import { useState, useEffect } from "react";
import { BASE_URL } from "../env";
import { useParams } from "react-router-dom";

const RedirectView = () => {
    
    const [url, setUrl] = useState(null);
    const [loading, setLoading] = useState(true);

    const { shortUrlString } = useParams();

    useEffect(() => {
        const getRedirectUrl = async () => {
            const response = await axios.get(`${BASE_URL}/redirect/getLongUrl/${shortUrlString}`);
            // check if the response failed or not
            if (response.status === 200) {
                setUrl(response.data.longUrl);
                window.location.href = response.data.longUrl;
            }
            else {
                alert("Something went wrong");
            }
            setLoading(false);

        };
        getRedirectUrl();
    }, []);

    if (loading) {
        return <div>loading...</div>;
    }

    return <div>Redirecting to {url}</div>;
}

export default RedirectView;
import React, { useContext, useEffect, useState } from "react";
import Tick from "../Assets/img/course/tick.png";
import Cal from "../Assets/img/course/calendar.png";
import CpSyllabus from "../Context/CpSyllabus";
import CP_force from "../Assets/PDFs/cp_force.pdf";
import CP_surge from "../Assets/PDFs/cp_surge.pdf";
import CP_impact from "../Assets/PDFs/cp_impact.pdf";
import styles from "../Assets/css/table.module.css";
import CalBlack from "../Assets/img/course/Icon material-today-black.png";
import clock from "../Assets/img/course/Icon ionic-md-time.png";
import line from "../Assets/img/course/Line 170.png";
import { Redirect, useHistory } from "react-router-dom";
import Syllabus from "./Syllabus";
import displayRazorpay from "../Razorpay";
import displayCoupon from "../components/NewCoupons.js";
import { AuthContext } from "../Context/AuthContext";
//import { Link } from "react-router-dom";
import Coupons1 from "../components/NewCoupons.js";
import Coupons2 from "../components/NewCoupons.js";
import Coupons3 from "../components/NewCoupons.js";
import { Link } from "react-scroll";
import brochure from "../Assets/img/Competitive/download_brochure.png";
import { BASE_URL } from "../env";

function PricingTable({ prev }) {
	let loaderT1 = false;
	let loaderT2 = false;
	let loaderT3 = false;
	const [showTier1, setShowTier1] = useState(false);
	const [showTier2, setShowTier2] = useState(false);
	const [showTier3, setShowTier3] = useState(false);

	const [loadT1, setLoadT1] = useState(false);
	const [loadT2, setLoadT2] = useState(false);
	const [loadT3, setLoadT3] = useState(false);
	const [loadT4, setLoadT4] = useState(false);
	let { token } = useContext(AuthContext);
	const [signIn, setSignIn] = useState(false);
	const [coupon, setCoupon] = useState(false);
	const [tierId, setTierId] = useState("");
	const [amount, setAmount] = useState("");
	let [course, setCourse] = useState("");
	let [tier, setTier] = useState("");
	const [pay1, setPay1] = useState(false);
	const [pay2, setPay2] = useState(false);
	const [pay3, setPay3] = useState(false);
	const [userData, setUserData] = useState({});
	const [email, setemail] = useState("");
	const [handlePayment, setHandlePayment] = useState(false);
	const [data, setData] = useState([]);
	const [couponCode, setCouponCode] = useState("");

	var Email_value = "";

	var valueJSON;

	useEffect(() => {
		// data
		//   .then((response) => response.json())
		//   .then((data) => {
		//
		//   });
	}, [data]);

	async function GiverUserInfoSurge() {
		if (localStorage.getItem("token") == null) {
			return;
		} else {
			await fetch(BASE_URL + "/getProfile", {
				headers: { authorization: "Bearer " + localStorage.getItem("token") },
			})
				.then((res) => res.json())
				.then((data) => {
					// userinfo.setuserinfo(data)

					//
					if (data.error) {
						//   setFirstName("");
					} else {
						setUserData(data);

						//
						valueJSON = data;
						Email_value = valueJSON.user;

						//
					}
				});
			if (Email_value != null) {
				await fetch(
					//https://sheet.best/api/sheets/74fa6b6f-6a39-4741-a12a-4f39c65baf5e
					"https://sheet.best/api/sheets/74fa6b6f-6a39-4741-a12a-4f39c65baf5e",
					{
						method: "POST",
						mode: "cors",
						headers: {
							"Content-Type": "application/json",
						},
						body: JSON.stringify({
							email: Email_value.email,
							action: "surge",
							type: "competitive programming",
						}),
					}
				)
					.then((r) => r.json())
					.then((data) => {
						// The response comes here
					})
					.catch((error) => {
						// Errors are reported there
					});
			}
		}
	}

	async function GiverUserInfoImpact() {
		if (localStorage.getItem("token") == null) {
		} else {
			await fetch(BASE_URL + "/getProfile", {
				headers: { authorization: "Bearer " + localStorage.getItem("token") },
			})
				.then((res) => res.json())
				.then((data) => {
					// userinfo.setuserinfo(data)

					//
					if (data.error) {
						//   setFirstName("");
					} else {
						setUserData(data);

						valueJSON = data;
						Email_value = valueJSON.user;

						setUserData(data);
						setUserData(data);
						//

						//
					}
				});
			if (Email_value != null) {
				await fetch(
					//https://sheet.best/api/sheets/74fa6b6f-6a39-4741-a12a-4f39c65baf5e
					"https://sheet.best/api/sheets/74fa6b6f-6a39-4741-a12a-4f39c65baf5e",
					{
						method: "POST",
						mode: "cors",
						headers: {
							"Content-Type": "application/json",
						},
						body: JSON.stringify({
							email: Email_value.email,
							action: "Impact",
							type: "competitive programming",
						}),
					}
				)
					.then((r) => r.json())
					.then((data) => {
						// The response comes here
					})
					.catch((error) => {
						// Errors are reported there
					});
			}
		}
	}
	async function GiveUserInfoForce() {
		if (localStorage.getItem("token") == null) {
		} else {
			await fetch(BASE_URL + "/getProfile", {
				headers: { authorization: "Bearer " + localStorage.getItem("token") },
			})
				.then((res) => res.json())
				.then((data) => {
					// userinfo.setuserinfo(data)

					//
					if (data.error) {
						//   setFirstName("");
					} else {
						setUserData(data);

						//
						valueJSON = data;
						Email_value = valueJSON.user;
					}
				});
			if (Email_value != null) {
				await fetch(
					//https://sheet.best/api/sheets/74fa6b6f-6a39-4741-a12a-4f39c65baf5e
					"https://sheet.best/api/sheets/74fa6b6f-6a39-4741-a12a-4f39c65baf5e",
					{
						method: "POST",
						mode: "cors",
						headers: {
							"Content-Type": "application/json",
						},
						body: JSON.stringify({
							email: Email_value.email,
							action: "force",
							type: "competitive programming",
						}),
					}
				)
					.then((r) => r.json())
					.then((data) => {
						// The response comes here
					})
					.catch((error) => {
						// Errors are reported there
					});
			}
		}
	}
	async function handlePayNow(route, tier) {
		if (tier == "tier1") {
			setTier("Force");
			setPay1(true);
			loaderT1 = false;
			setLoadT4(false);

			setLoadT1(false);
		} else if (tier == "tier2") {
			setTier("Surge");
			setPay2(true);
			setLoadT4(false);

		} else {
			setTier("Impact");
			setPay3(true);
			setLoadT3(false);
			setLoadT4(false);
		}

		if (route == "cpcourse") {
			setCourse("Competetive Programming");
		}

		if (!token) {
			setSignIn(true);
		} else {
			await fetch(BASE_URL + "/getFromRoute?route=" + route)
				.then((response) => response.json())
				.then((data) => {
					{
						const tiers = data.course.types;
						const curr = tiers[tier];
						setTierId(curr._id);
						setAmount(curr.amount);
					}
				});
		}

		setCoupon(true);
	}

	return (
		<div>
			{/* {coupon === true ? ( */}
			{handlePayment && (
				<Redirect
					to={{
						pathname: `/order/${data.receipt}`,
						state: {
							data: data,
							amount: amount,
							couponCode: couponCode,
						},
					}}
				/>
			)}

			<Coupons1
				course={course}
				tier={tier}
				setCoupon={setCoupon}
				original_amount={"11999"}
				tierId={tierId}
				amount={amount}
				token={token}
				setLoadT1={setLoadT1}
				setLoadT2={setLoadT2}
				setLoadT3={setLoadT3}
				setLoadT4={setLoadT4}

				setHandlePayment={setHandlePayment}
				setData={setData}
				setCouponCode={setCouponCode}
				show={pay1}
				onClose={() => setPay1(false)}
			/>
			<Coupons2
				course={course}
				tier={tier}
				setCoupon={setCoupon}
				tierId={tierId}
				amount={amount}
				original_amount={"18999"}
				token={token}
				setLoadT1={setLoadT1}
				setLoadT2={setLoadT2}
				setLoadT3={setLoadT3}
				setLoadT4={setLoadT4}

				setHandlePayment={setHandlePayment}
				setData={setData}
				setCouponCode={setCouponCode}
				show={pay2}
				onClose={() => setPay2(false)}
			/>
			<Coupons3
				course={course}
				tier={tier}
				setCoupon={setCoupon}
				original_amount={"22999"}
				tierId={tierId}
				amount={amount}
				token={token}
				setLoadT1={setLoadT1}
				setLoadT2={setLoadT2}
				setLoadT3={setLoadT3}
				setLoadT4={setLoadT4}
				setHandlePayment={setHandlePayment}
				setData={setData}
				setCouponCode={setCouponCode}
				show={pay3}
				onClose={() => setPay3(false)}
			/>
			{/* ) : null} */}
			{signIn === true ? (
				<Redirect
					to={{
						pathname: "/signin",
						state: { prev: "/cpcourse", fromPT: true },
					}}
				/>
			) : null}

			<div
				id="ptable"
				className="container justify-content-center my-5 MOVEPRICING"
			>
				<div style={{ textAlign: "center" }} className="my-5">
					<h2 style={{ fontWeight: "900" }}>Select your Package</h2>
				</div>

				<div className={styles.fordesktopviewJ}>
					<div
						style={{
							margin: " auto",
							textAlign: "center",
							height: "5vw",
							display: "flex",
						}}
						// row
						className={` bg-info ${styles.tableheadingJ} justify-content-evenly mx-0`}
					>
						<div
							style={{ textAlign: "left" }}
							className="col-xl-3 col-lg-3 col-md-3 col-sm-2 col-2 p-3 "
						>
							Features
						</div>
						<div
							style={{
								display: "flex",
								flex: "row",
								justifyContent: "space-between",
							}}
						>
							<div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-1 p-3 mr-4">
								Force
							</div>
							<div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-1 p-3 mx-5 ">
								Surge
							</div>
							<div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-1 p-3 mr-4">
								Impact
							</div>
						</div>
					</div>

					<div
						style={{
							margin: "auto",
							textAlign: "center",
							height: "5vw",
							display: "flex",
						}}
						// row
						className={`justify-content-center bg-white ${styles.allrowsJ} mx-0 p-0`}
					>
						<div
							style={{
								textAlign: "left",
								// position: "relative",
								// height: "0.5vw",
							}}
							className={`col-xl-4 col-lg-4 col-md-4 col-sm-3 p-3 mx-xl-2 ${styles.featuresJ}`}
						>
							Basic & Advanced DSA
						</div>
						<div
							style={{ textAlign: "center" }}
							className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2"
						>
							<img src={Tick} alt="" />
						</div>
						<div
							style={{ textAlign: "center" }}
							className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2"
						>
							<img src={Tick} alt="" />
						</div>
						<div
							style={{ textAlign: "center" }}
							className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2"
						>
							<img src={Tick} alt="" />
						</div>
					</div>

					<div
						style={{
							margin: "auto",
							textAlign: "center",
							height: "5vw",
							display: "flex",
						}}
						// row
						className={`justify-content-center ${styles.evenrowJ} ${styles.allrowsJ}mx-0`}
					>
						<div
							style={{ textAlign: "left" }}
							className={`col-xl-4 col-lg-4 col-md-4 col-sm-3 p-3 mx-xl-2 ${styles.featuresJ}`}
						>
							Learn C++
						</div>
						<div
							style={{ textAlign: "center" }}
							className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2 rows-border-left-J"
						></div>
						<div
							style={{ textAlign: "center" }}
							className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2 rows-border-left-J"
						>
							<img src={Tick} alt="" />
						</div>
						<div
							style={{ textAlign: "center" }}
							className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2 rows-border-left-J"
						>
							<img src={Tick} alt="" />
						</div>
					</div>

					<div
						style={{
							margin: "auto",
							textAlign: "center",
							height: "5vw",
							display: "flex",
						}}
						// row
						className={` justify-content-center bg-white ${styles.allrowsJ} mx-0`}
					>
						<div
							style={{ textAlign: "left" }}
							className={`col-xl-4 col-lg-4 col-md-4 col-sm-3 p-3 mx-xl-2 ${styles.featuresJ}`}
						>
							TA Support
						</div>
						<div
							style={{ textAlign: "center" }}
							className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2 "
						>
							<img src={Tick} alt="" />
						</div>
						<div
							style={{ textAlign: "center" }}
							className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2"
						>
							<img src={Tick} alt="" />
						</div>
						<div
							style={{ textAlign: "center" }}
							className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2"
						>
							<img src={Tick} alt="" />
						</div>
					</div>

					<div
						style={{
							margin: "auto",
							textAlign: "center",
							height: "5vw",
							display: "flex",
						}}
						//  row
						className={`justify-content-center ${styles.evenrowJ}  mx-0`}
					>
						<div
							style={{ textAlign: "left" }}
							className={`col-xl-4 col-lg-4 col-md-4 col-sm-3  p-3 mx-xl-2 ${styles.featuresJ}`}
						>
							Solve Questions With Experts
						</div>
						<div
							style={{ textAlign: "center" }}
							className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2 rows-border-left-J"
						>
							<p>50+ Questions</p>
						</div>
						<div
							style={{ textAlign: "center" }}
							className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2 rows-border-left-J"
						>
							<p>80+ Questions</p>
						</div>
						<div
							style={{ textAlign: "center" }}
							className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2 rows-border-left-J"
						>
							<p>100+ Questions</p>
						</div>
					</div>

					<div
						style={{
							margin: "auto",
							textAlign: "center",
							height: "5vw",
							display: "flex",
						}}
						// row
						className={` justify-content-center bg-white ${styles.allrowsJ} mx-0`}
					>
						<div
							style={{ textAlign: "left" }}
							className={`col-xl-4 col-lg-4 col-md-4 col-sm-3 p-3 mx-xl-2 ${styles.featuresJ}`}
						>
							1:1 Mentorship Support
						</div>
						<div
							style={{ textAlign: "center" }}
							className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2"
						></div>
						<div
							style={{ textAlign: "center" }}
							className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2"
						></div>
						<div
							style={{ textAlign: "center" }}
							className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2"
						>
							<img src={Tick} alt="" />
						</div>
					</div>

					<div
						style={{
							margin: "auto",
							textAlign: "center",
							height: "5vw",
							display: "flex",
						}}
						// row
						className={`justify-content-center ${styles.evenrowJ} ${styles.allrowsJ} mx-0`}
					>
						<div
							style={{ textAlign: "left" }}
							className={`col-xl-4 col-lg-4 col-md-4 col-sm-3 p-3 mx-xl-2 ${styles.featuresJ}`}
						>
							Mock Interview Videos
						</div>
						<div
							style={{ textAlign: "center" }}
							className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2 rows-border-left-J"
						>
							<p>10+ Videos</p>
						</div>
						<div
							style={{ textAlign: "center" }}
							className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2 rows-border-left-J"
						>
							<p>20+ Videos</p>
						</div>
						<div
							style={{ textAlign: "center" }}
							className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2 rows-border-left-J"
						>
							<p>30+ Videos</p>
						</div>
					</div>

					<div
						style={{
							margin: "auto",
							textAlign: "center",
							height: "5vw",
							display: "flex",
						}}
						// row
						className={` justify-content-center bg-white ${styles.allrowsJ} mx-0`}
					>
						<div
							style={{ textAlign: "left" }}
							className={`col-xl-4 col-lg-4 col-md-4 col-sm-3 p-3 mx-xl-2 ${styles.featuresJ}`}
						>
							1:1 Mock Interview
						</div>
						<div
							style={{ textAlign: "center" }}
							className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2"
						></div>
						<div
							style={{ textAlign: "center" }}
							className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2"
						>
							<p>2 Interviews</p>
						</div>
						<div
							style={{ textAlign: "center" }}
							className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2"
						>
							<p>4 Interviews</p>
						</div>
					</div>

					<div
						style={{
							margin: "auto",
							textAlign: "center",
							height: "5vw",
							display: "flex",
						}}
						// row
						className={`justify-content-center ${styles.evenrowJ} ${styles.allrowsJ} mx-0`}
					>
						<div
							style={{ textAlign: "left" }}
							className={`col-xl-4 col-lg-4 col-md-4 col-sm-3 p-3 mx-xl-2 ${styles.featuresJ}`}
						>
							1:1 Resume preparation
						</div>
						<div
							style={{ textAlign: "center" }}
							className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2 rows-border-left-J"
						></div>
						<div
							style={{ textAlign: "center" }}
							className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2 rows-border-left-J"
						></div>
						<div
							style={{ textAlign: "center" }}
							className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2 rows-border-left-J"
						>
							<img src={Tick} alt="" />
						</div>
					</div>

					<div
						style={{
							margin: "auto",
							textAlign: "center",
							height: "5vw",
							display: "flex",
						}}
						//row
						className=" justify-content-center bg-white mx-0"
					>
						<div
							style={{ textAlign: "left" }}
							className={`col-xl-4 col-lg-4 col-md-4 col-sm-3 p-3 mx-xl-2 ${styles.featuresJ}`}
						>
							Soft skill sessions
						</div>
						<div
							style={{ textAlign: "center" }}
							className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2"
						></div>
						<div
							style={{ textAlign: "center" }}
							className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2"
						>
							<p>3 sessions</p>
						</div>
						<div
							style={{ textAlign: "center" }}
							className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2"
						>
							<p>6 sessions</p>
						</div>
					</div>

					<div
						style={{
							margin: "auto",
							textAlign: "center",
							height: "5vw",
							display: "flex",
						}}
						//row
						className={`justify-content-center ${styles.evenrowJ}  mx-0`}
					>
						<div
							style={{ textAlign: "left" }}
							className={`col-xl-4 col-lg-4 col-md-4 col-sm-3 p-3 mx-xl-2 ${styles.featuresJ}`}
						>
							Months
						</div>
						<div
							style={{ textAlign: "center" }}
							className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2 rows-border-left-J"
						>
							<p>3 Months</p>
						</div>
						<div
							style={{ textAlign: "center" }}
							className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2 rows-border-left-J"
						>
							<p>4 Months</p>
						</div>
						<div
							style={{ textAlign: "center" }}
							className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2 rows-border-left-J"
						>
							<p>6 Months</p>
						</div>
					</div>

					<div
						style={{
							margin: "auto",
							textAlign: "center",
							height: "5vw",
							display: "flex",
						}}
						// row
						className=" justify-content-center bg-white mx-0"
					>
						<div
							style={{ textAlign: "left" }}
							className={`col-xl-4 col-lg-4 col-md-4 col-sm-3 p-3 mx-xl-2 ${styles.featuresJ}`}
						>
							Hours of Contact
						</div>
						<div
							style={{ textAlign: "center" }}
							className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2 rows-border-left-J"
						>
							<p>80+ Hours</p>
						</div>
						<div
							style={{ textAlign: "center" }}
							className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2 rows-border-left-J"
						>
							<p>100+ Hours</p>
						</div>
						<div
							style={{ textAlign: "center" }}
							className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2 rows-border-left-J"
						>
							<p>130+ Hours</p>
						</div>
					</div>

					<div
						style={{
							margin: "auto",
							textAlign: "center",
							height: "5vw",
							display: "flex",
						}}
						// row
						className={`justify-content-center ${styles.evenrowJ}  mx-0`}
					>
						<div
							style={{ textAlign: "left" }}
							className={`col-xl-4 col-lg-4 col-md-4 col-sm-3 p-3 mx-xl-2 ${styles.featuresJ}`}
						>
							Price
						</div>
						<div
							style={{ textAlign: "center" }}
							className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2"
						>
							<p style={{ fontWeight: "900", fontSize: "1.5vw" }}>9999/-</p>
						</div>
						<div
							style={{ textAlign: "center" }}
							className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2"
						>
							<p style={{ fontWeight: "900", fontSize: "1.5vw" }}>14999/-</p>
						</div>
						<div
							style={{ textAlign: "center" }}
							className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2"
						>
							<p style={{ fontWeight: "900", fontSize: "1.5vw" }}>19999/-</p>
						</div>
					</div>

					<div
						style={{
							margin: "auto",
							textAlign: "center",
							height: "5vw",
							display: "flex",
						}}
						// row
						className={`justify-content-center ${styles.payJ} bg-white mx-0`}
					>
						<div
							style={{ textAlign: "left" }}
							className="col-xl-4 col-lg-4 col-md-4 col-sm-3 p-3 mx-xl-2"
						></div>
						<div
							style={{ textAlign: "center" }}
							className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2 rows-border-left-J"
						>
							{pay1 === false ? (
								<button
									style={{ textAlign: "center" }}
									className={`btn btn-warning px-1 ${styles.sylbtnJ}`}
									onClick={() => {
										loaderT1 = true;
										setLoadT1(true);
										handlePayNow("cpcourse", "tier1");
									}}
								>
									Pay Now
								</button>
							) : (
								<button className="register-btn" type="button" disabled>
									<span
										className="spinner-border spinner-border-sm"
										role="status"
										aria-hidden="true"
									></span>
									<span className="sr-only">Loading...</span>
								</button>
							)}
						</div>
						<div
							style={{ textAlign: "center" }}
							className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2 rows-border-left-J"
						>
							{pay2 === false ? (
								<button
									style={{ textAlign: "center" }}
									className={`btn btn-warning px-1 ${styles.sylbtnJ}`}
									onClick={() => {
										loaderT2 = true;
										setLoadT2(true);
										handlePayNow("cpcourse", "tier2");
									}}
								>
									Pay Now
								</button>
							) : (
								<button className="register-btn" type="button" disabled>
									<span
										className="spinner-border spinner-border-sm"
										role="status"
										aria-hidden="true"
									></span>
									<span className="sr-only">Loading...</span>
								</button>
							)}
						</div>
						<div
							style={{ textAlign: "center" }}
							className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2 rows-border-left-J"
						>
							{pay3 === false ? (
								<button
									style={{ textAlign: "center" }}
									className={`btn btn-warning px-1 ${styles.sylbtnJ}`}
									onClick={() => {
										loaderT3 = true;
										setLoadT3(true);
										handlePayNow("cpcourse", "tier3");
									}}
								>
									Pay Now
								</button>
							) : (
								<button className="register-btn" type="button" disabled>
									<span
										className="spinner-border spinner-border-sm"
										role="status"
										aria-hidden="true"
									></span>
									<span className="sr-only">Loading...</span>
								</button>
							)}
						</div>
					</div>

					<div
						style={{
							margin: "auto",
							textAlign: "center",
							height: "7vw",
							display: "flex",
						}}
						// row
						className={`justify-content-center ${styles.evenrowJ}  ${styles.moneyBackJ} mx-0`}
					>
						<div
							style={{ textAlign: "left" }}
							className="col-xl-4 col-lg-4 col-md-4 col-sm-3 p-3 mx-xl-2 "
						></div>
						<div
							style={{
								textAlign: "center",
							}}
							className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2 d-flex"
						>
							<img src={Cal} alt="" />
							<p style={{ fontSize: "1vw", position: "relative", left: "2%" }}>
								7 day Money back Guarantee
							</p>
						</div>
						<div
							style={{ textAlign: "center" }}
							className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2 d-flex"
						>
							<img src={Cal} alt="" />
							<p style={{ fontSize: "1vw", position: "relative", left: "2%" }}>
								7 day Money back Guarantee
							</p>
						</div>
						<div
							style={{ textAlign: "center" }}
							className="col-xl-2 col-lg-2 col-md-2 col-sm-2 p-3 mx-xl-2 d-flex"
						>
							<img src={Cal} alt="" />
							<p style={{ fontSize: "1vw", position: "relative", left: "2%" }}>
								7 day Money back Guarantee
							</p>
						</div>
					</div>

					<div
						style={{
							margin: "auto",
							textAlign: "center",
							height: "6vw",
							display: "flex",
						}}
						// row
						className={` justify-content-center ${styles.SyllabusJ} bg-white mx-0`}
					>
						<div
							style={{ textAlign: "left" }}
							className="col-xl-4 col-lg-4 col-md-4 col-sm-3 p-3 mx-xl-2"
						></div>
						<div
							style={{ textAlign: "center" }}
							className="col-xl-2 col-lg-2 col-md-2 col-sm-2 py-3 px-0 mx-xl-2 rows-border-left-J"
						>
							<a
								target="__blank"
								onClick={() => {
									GiveUserInfoForce();
								}}
								href="https://courses-pdfs.s3.ap-south-1.amazonaws.com/cp_force.pdf"
								style={{ textAlign: "center", width: "90%", marginLeft: "6px" }}
								className={`btn btn-outline-info ${styles.sylbtnJ}`}
							>
								Syllabus
							</a>
						</div>
						<div
							style={{ textAlign: "center" }}
							className="col-xl-2 col-lg-2 col-md-2 py-3 px-0 mx-xl-2 col-sm-2 rows-border-left-J"
						>
							{" "}
							<a
								target="__blank"
								href="https://courses-pdfs.s3.ap-south-1.amazonaws.com/cp_surge.pdf"
								onClick={() => {
									GiverUserInfoSurge();
								}}
								style={{ textAlign: "center", width: "90%", marginLeft: "6px" }}
								className={`btn btn-outline-info ${styles.sylbtnJ}`}
							>
								Syllabus
							</a>
						</div>
						<div
							style={{ textAlign: "center" }}
							className="col-xl-2 col-lg-2 col-md-2 py-3 px-0 mx-xl-2 col-sm-2 rows-border-left-J"
						>
							<a
								target="__blank"
								href="https://courses-pdfs.s3.ap-south-1.amazonaws.com/cp_impact.pdf"
								onClick={() => {
									GiverUserInfoImpact();
								}}
								style={{ textAlign: "center", width: "90%" }}
								className={`btn btn-outline-info ${styles.sylbtnJ}`}
							>
								Syllabus
							</a>
						</div>
					</div>
					<a
						target="__blank"
						href="https://courses-pdfs.s3.ap-south-1.amazonaws.com/cp_course_brochure.pdf"
					>
						<div
							style={{
								display: "flex",
								borderRadius: "25px",
								width: "25%",
								height: "50px",
								background: "black",
								justifyContent: "center",
								paddingTop: "0.6%",
								margin: "auto",
								paddingLeft: "2%",
							}}
						>
							<p
								style={{
									objectFit: "contain",
									fontFamily: "Poppins",
									fontSize: "1vw",
									fontWeight: "bold",
									color: "white",
									margin: "auto",
									whiteSpace: "nowrap",
									paddinLeft: "2%",
								}}
							>
								Download Brochure
							</p>
							<img
								style={{
									objectFit: "contain",
									height: "1.8vw",
									// padding: "0.7%",
									paddingRight: "5%",
									margin: "auto",
								}}
								src={brochure}
								alt=""
							/>
						</div>
					</a>
				</div>
				<CpSyllabus
					tiername="Force"
					onClose={() => setShowTier1(false)}
					show={showTier1}
				/>
				<CpSyllabus
					tiername="Surge"
					onClose={() => setShowTier2(false)}
					show={showTier2}
				/>
				<CpSyllabus
					tiername="Impact"
					onClose={() => setShowTier3(false)}
					show={showTier3}
				/>
			</div>

			<div className={`container-fluid ${styles.formobileviewJ}`}>
				<div className={styles.graymobileJ}>
					<div className="row justify-content-center mobiletableJ  my-4 ">
						<div className="col-xl-3 col-lg-3 col-md-3 col-sm-8 col-9  mx-3 px-4 py-2 my-2">
							<div>
								<h3>Force</h3>
								<p>
									Basic & Advanced DSA, Live Classes, 50+ Questions, TA Support
								</p>
							</div>

							<div>
								<div className={`d-flex ${styles.mobiletimeJ}`}>
									<p className={styles.mobileclockJ}>
										<img src={clock} alt="" /> 60+ Hours
									</p>
									<p className={styles.mobilecalJ}>
										<img src={CalBlack} alt="" />3 Months
									</p>
								</div>

								<div className={`d-flex ${styles.syllabusmobileJ}`}>
									<div
										className={`${styles.pricemobileJ} mx-0`}
										style={{ paddingTop: "0px" }}
									>
										{/* <button className={`btn btn-warning px-1 ${styles.sylbtnJ}`} onClick={() => { handlePayNow("cpcourse", "tier1") }}>9999/-</button> */}
										{loadT1 === false ? (
											<button
												style={{
													color: "white",
													border: "none",
													backgroundColor: "#00a2c8",
													textAlign: "center",
												}}
												className={`btn btn-warning px-1 ${styles.sylbtnJ}`}
												onClick={() => {
													loaderT1 = true;
													setLoadT1(true);
													handlePayNow("cpcourse", "tier1");
												}}
											>
												9999/-
											</button>
										) : (
											<button
												style={{
													backgroundColor: "#00a2c8",
												}}
												className={`btn btn-warning px-1 ${styles.sylbtnJ}`}
												type="button"
												disabled
											>
												<span
													className="spinner-border spinner-border-sm"
													role="status"
													aria-hidden="true"
												></span>
												<span className="sr-only">Loading...</span>
											</button>
										)}
									</div>

									<a
										target="__blank"
										href={CP_force}
										style={{
											textAlign: "center",
											width: "90%",
											marginLeft: "6px",
										}}
										className={`btn btn-outline-info ${styles.sylbtnmob}`}
									>
										Syllabus
									</a>
								</div>
							</div>

							<p className={styles.bluemobiletextJ}>
								7 day Money back Guarantee
							</p>
						</div>
						<div className="col-sm-2 col-1 blue-mobile-J">
							<img src={line} alt="" />
						</div>
					</div>
				</div>

				<div className={styles.graymobileJ}>
					<div
						className={`row justify-content-center ${styles.mobiletableJ} my-4`}
					>
						<div className="col-xl-3 col-lg-3 col-md-3 col-sm-8 col-9  mx-3 px-4 py-2 my-2">
							<div>
								<h3>Surge</h3>
								<p>
									Basic & Advanced DSA, Learn C++, 80+ Questions, Mock Interview
									Videos
								</p>
							</div>

							<div className={`d-flex ${styles.mobiletimeJ}`}>
								<p className={styles.mobileclockJ}>
									<img src={clock} alt="" />
									80+ Hours
								</p>
								<p className={styles.mobilecalJ}>
									<img src={CalBlack} alt="" />4 Months
								</p>
							</div>

							<div className={`d-flex ${styles.syllabusmobileJ}`}>
								<div
									className={`${styles.pricemobileJ} mx-0`}
									style={{ paddingTop: "0px" }}
								>
									{loadT2 === false ? (
										<button
											style={{
												color: "white",
												border: "none",
												backgroundColor: "#00a2c8",
												textAlign: "center",
											}}
											className={`btn btn-warning px-1 ${styles.sylbtnJ}`}
											onClick={() => {
												loaderT2 = true;
												setLoadT2(true);
												handlePayNow("cpcourse", "tier2");
											}}
										>
											14999/-
										</button>
									) : (
										<button
											style={{
												backgroundColor: "#00a2c8",
											}}
											className={`btn btn-warning px-1 ${styles.sylbtnJ}`}
											type="button"
											disabled
										>
											<span
												className="spinner-border spinner-border-sm"
												role="status"
												aria-hidden="true"
											></span>
											<span className="sr-only">Loading...</span>
										</button>
									)}
								</div>
								<a
									target="__blank"
									href={CP_surge}
									style={{
										textAlign: "center",
										width: "90%",
										marginLeft: "6px",
									}}
									className={`btn btn-outline-info ${styles.sylbtnmob}`}
								>
									Syllabus
								</a>
							</div>
							<p className={styles.bluemobiletextJ}>
								7 day Money back Guarantee
							</p>
						</div>

						<div className="col-sm-2 col-1 blue-mobile-J">
							<img src={line} alt="" />
						</div>
					</div>
				</div>

				<div className={styles.graymobileJ}>
					<div
						className={`row justify-content-center ${styles.mobiletableJ} my-4`}
					>
						<div className="col-xl-3 col-lg-3 col-md-3 col-sm-8 col-9  mx-3 px-4 py-2 my-2">
							<div>
								<h3>Impact</h3>
								<p>
									Basic & Advanced DSA, Learn C++, 100+ Questions, 1:1
									Mentornship
								</p>
							</div>

							<div className={`d-flex ${styles.mobiletimeJ}`}>
								<p className={styles.mobileclockJ}>
									<img src={clock} alt="" />
									100+ Hours
								</p>
								<p className={styles.mobilecalJ}>
									<img src={CalBlack} alt="" />6 Months
								</p>
							</div>

							<div className={`d-flex ${styles.syllabusmobileJ}`}>
								<div
									className={`${styles.pricemobileJ} mx-0`}
									style={{ paddingTop: "0px" }}
								>
									{loadT3 === false ? (
										<button
											style={{
												color: "white",
												border: "none",
												backgroundColor: "#00a2c8",
												textAlign: "center",
											}}
											className={`btn btn-warning px-1 ${styles.sylbtnJ}`}
											onClick={() => {
												loaderT3 = true;
												setLoadT3(true);
												handlePayNow("cpcourse", "tier3");
											}}
										>
											19999/-
										</button>
									) : (
										<button
											style={{
												backgroundColor: "#00a2c8",
											}}
											className={`btn btn-warning px-1 ${styles.sylbtnJ}`}
											type="button"
											disabled
										>
											<span
												className="spinner-border spinner-border-sm"
												role="status"
												aria-hidden="true"
											></span>
											<span className="sr-only">Loading...</span>
										</button>
									)}
								</div>
								<a
									target="__blank"
									href={CP_impact}
									style={{
										textAlign: "center",
										width: "90%",
										marginLeft: "6px",
									}}
									className={`btn btn-outline-info ${styles.sylbtnmob}`}
								>
									Syllabus
								</a>
							</div>
							<p className={styles.bluemobiletextJ}>
								7 day Money back Guarantee
							</p>
						</div>

						<div className="col-sm-2 col-1 blue-mobile-J">
							<img src={line} alt="" />
						</div>
					</div>
				</div>
				<a
					target="__blank"
					href="https://courses-pdfs.s3.ap-south-1.amazonaws.com/cp_course_brochure.pdf"
				>
					<div
						style={{
							display: "flex",
							borderRadius: "25px",
							width: "70%",
							height: "40px",
							background: "black",
							justifyContent: "center",
							margin: "auto",
							paddingTop: "0.6%",
							marginBottom: "5%",
						}}
					>
						<p
							style={{
								objectFit: "contain",
								fontFamily: "Poppins",
								fontSize: "medium",
								fontWeight: "bold",
								color: "white",
								margin: "auto",
								whiteSpace: "nowrap",
								paddinLeft: "2%",
							}}
						>
							Download Brochure
						</p>
						<img
							style={{
								objectFit: "contain",
								// height: "2.8vw",
								dingRight: "5%",
								margin: "auto",
							}}
							src={brochure}
							alt=""
						/>
					</div>
				</a>
			</div>

			{/* x */}
		</div>
	);
}

export default PricingTable;

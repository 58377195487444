var cities = [
  "Adoni",
  "Amaravati",
  "Anantapur",
  "Chandragiri",
  "Chittoor",
  "Dowlaiswaram",
  "Eluru",
  "Guntur",
  "Kadapa",
  "Kakinada",
  "Kurnool",
  "Machilipatnam",
  "Nagarjunakoṇḍa",
  "Rajahmundry",
  "Srikakulam",
  "Tirupati",
  "Vijayawada",
  "Visakhapatnam",
  "Vizianagaram",
  "Yemmiganur",
  "Pradesh",
  "Itanagar",
  "Assam",
  "Dhuburi",
  "Dibrugarh",
  "Dispur",
  "Guwahati",
  "Jorhat",
  "Nagaon",
  "Sibsagar",
  "Silchar",
  "Tezpur",
  "Tinsukia",
  "Bihar",
  "Ara",
  "Baruni",
  "Begusarai",
  "Bettiah",
  "Bhagalpur",
  "Sharif",
  "Gaya",
  "Buxar",
  "Chapra",
  "Darbhanga",
  "Dehri",
  "Nizamat",
  "Gaya",
  "Hajipur",
  "Jamalpur",
  "Katihar",
  "Madhubani",
  "Motihari",
  "Munger",
  "Muzaffarpur",
  "Patna",
  "Purnia",
  "Pusa",
  "Saharsa",
  "Samastipur",
  "Sasaram",
  "Sitamarhi",
  "Siwan",
  "Chandigarh",
  "Chhattisgarh",
  "Ambikapur",
  "Bhilai",
  "Bilaspur",
  "Dhamtari",
  "Durg",
  "Jagdalpur",
  "Raipur",
  "Rajnandgaon",
  "Silvassa",
  "Daman",
  "Diu",
  "Delhi",
  "Goa",
  "Madgaon",
  "Panaji",
  "Gujarat",
  "Ahmadabad",
  "Amreli",
  "Bharuch",
  "Bhavnagar",
  "Bhuj",
  "Dwarka",
  "Gandhinagar",
  "Godhra",
  "Jamnagar",
  "Junagadh",
  "Kandla",
  "Khambhat",
  "Kheda",
  "Mahesana",
  "Morvi",
  "Nadiad",
  "Navsari",
  "Okha",
  "Palanpur",
  "Patan",
  "Porbandar",
  "Rajkot",
  "Surat",
  "Surendranagar",
  "Valsad",
  "Veraval",
  "Haryana",
  "Ambala",
  "Bhiwani",
  "Chandigarh",
  "Faridabad",
  "Gurgaon",
  "Hansi",
  "Hisar",
  "Jind",
  "Kaithal",
  "Karnal",
  "Kurukshetra",
  "Panipat",
  "Pehowa",
  "Rewari",
  "Rohtak",
  "Sirsa",
  "Sonipat",
  "Bilaspur",
  "Chamba",
  "Dalhousie",
  "Dharmshala",
  "Hamirpur",
  "Kangra",
  "Kullu",
  "Mandi",
  "Nahan",
  "Shimla",
  "Una",
  "Anantnag",
  "Baramula",
  "Doda",
  "Gulmarg",
  "Jammu",
  "Kathua",
  "Punch",
  "Rajauri",
  "Srinagar",
  "Udhampur",
  "Jharkhand",
  "Bokaro",
  "Chaibasa",
  "Deoghar",
  "Dhanbad",
  "Dumka",
  "Giridih",
  "Hazaribag",
  "Jamshedpur",
  "Jharia",
  "Rajmahal",
  "Ranchi",
  "Saraikela",
  "Karnataka",
  "Badami",
  "Ballari",
  "Bangalore",
  "Belgavi",
  "Bhadravati",
  "Bidar",
  "Chikkamagaluru",
  "Chitradurga",
  "Davangere",
  "Halebid",
  "Hassan",
  "Kalaburagi",
  "Kolar",
  "Madikeri",
  "Mandya",
  "Mangaluru",
  "Mysuru",
  "Raichur",
  "Shivamogga",
  "Shravanabelagola",
  "Shrirangapattana",
  "Tumkuru",
  "Kerala",
  "Alappuzha",
  "Badagara",
  "Idukki",
  "Kannur",
  "Kochi",
  "Kollam",
  "Kottayam",
  "Kozhikode",
  "Mattancheri",
  "Palakkad",
  "Thalassery",
  "Thiruvananthapuram",
  "Thrissur",
  "Kargil",
  "Leh",
  "Balaghat",
  "Barwani",
  "Betul",
  "Bharhut",
  "Bhind",
  "Bhojpur",
  "Bhopal",
  "Burhanpur",
  "Chhatarpur",
  "Chhindwara",
  "Damoh",
  "Datia",
  "Dewas",
  "Dhar",
  "Guna",
  "Gwalior",
  "Hoshangabad",
  "Indore",
  "Itarsi",
  "Jabalpur",
  "Jhabua",
  "Khajuraho",
  "Khandwa",
  "Khargon",
  "Maheshwar",
  "Mandla",
  "Mandsaur",
  "Mhow",
  "Morena",
  "Murwara",
  "Narsimhapur",
  "Narsinghgarh",
  "Narwar",
  "Neemuch",
  "Nowgong",
  "Orchha",
  "Panna",
  "Raisen",
  "Rajgarh",
  "Ratlam",
  "Rewa",
  "Sagar",
  "Sarangpur",
  "Satna",
  "Sehore",
  "Seoni",
  "Shahdol",
  "Shajapur",
  "Sheopur",
  "Shivpuri",
  "Ujjain",
  "Vidisha",
  "Maharashtra",
  "Ahmadnagar",
  "Akola",
  "Amravati",
  "Aurangabad",
  "Bhandara",
  "Bhusawal",
  "Bid",
  "Buldana",
  "Chandrapur",
  "Daulatabad",
  "Dhule",
  "Jalgaon",
  "Kalyan",
  "Karli",
  "Kolhapur",
  "Mahabaleshwar",
  "Malegaon",
  "Matheran",
  "Mumbai",
  "Nagpur",
  "Nanded",
  "Nashik",
  "Osmanabad",
  "Pandharpur",
  "Parbhani",
  "Pune",
  "Ratnagiri",
  "Sangli",
  "Satara",
  "Sevagram",
  "Solapur",
  "Thane",
  "Ulhasnagar",
  "Virar",
  "Wardha",
  "Yavatmal",
  "Manipur",
  "Imphal",
  "Meghalaya",
  "Cherrapunji",
  "Shillong",
  "Mizoram",
  "Aizawl",
  "Lunglei",
  "Nagaland",
  "Kohima",
  "Mon",
  "Phek",
  "Wokha",
  "Zunheboto",
  "Odisha",
  "Balangir",
  "Baleshwar",
  "Baripada",
  "Bhubaneshwar",
  "Brahmapur",
  "Cuttack",
  "Dhenkanal",
  "Keonjhar",
  "Konark",
  "Koraput",
  "Paradip",
  "Phulabani",
  "Puri",
  "Sambalpur",
  "Udayagiri",
  "Puducherry",
  "Karaikal",
  "Mahe",
  "Puducherry",
  "Yanam",
  "Punjab",
  "Amritsar",
  "Batala",
  "Chandigarh",
  "Faridkot",
  "Firozpur",
  "Gurdaspur",
  "Hoshiarpur",
  "Jalandhar",
  "Kapurthala",
  "Ludhiana",
  "Nabha",
  "Patiala",
  "Rupnagar",
  "Sangrur",
  "Rajasthan",
  "Abu",
  "Ajmer",
  "Alwar",
  "Amer",
  "Barmer",
  "Beawar",
  "Bharatpur",
  "Bhilwara",
  "Bikaner",
  "Bundi",
  "Chittaurgarh",
  "Churu",
  "Dhaulpur",
  "Dungarpur",
  "Ganganagar",
  "Hanumangarh",
  "Jaipur",
  "Jaisalmer",
  "Jalor",
  "Jhalawar",
  "Jhunjhunu",
  "Jodhpur",
  "Kishangarh",
  "Kota",
  "Merta",
  "Nagaur",
  "Nathdwara",
  "Pali",
  "Phalodi",
  "Pushkar",
  "Madhopur",
  "Shahpura",
  "Sikar",
  "Sirohi",
  "Tonk",
  "Udaipur",
  "Sikkim",
  "Gangtok",
  "Gyalsing",
  "Lachung",
  "Mangan",
  "Arcot",
  "Chengalpattu",
  "Chennai",
  "Chidambaram",
  "Coimbatore",
  "Cuddalore",
  "Dharmapuri",
  "Dindigul",
  "Erode",
  "Kanchipuram",
  "Kanniyakumari",
  "Kodaikanal",
  "Kumbakonam",
  "Madurai",
  "Mamallapuram",
  "Nagappattinam",
  "Nagercoil",
  "Palayankottai",
  "Pudukkottai",
  "Rajapalaiyam",
  "Ramanathapuram",
  "Salem",
  "Thanjavur",
  "Tiruchchirappalli",
  "Tirunelveli",
  "Tiruppur",
  "Tuticorin",
  "Udhagamandalam",
  "Vellore",
  "Telangana",
  "Hyderabad",
  "Karimnagar",
  "Khammam",
  "Mahbubnagar",
  "Nizamabad",
  "Sangareddi",
  "Warangal",
  "Tripura",
  "Agartala",
  "Pradesh",
  "Agra",
  "Aligarh",
  "Amroha",
  "Ayodhya",
  "Azamgarh",
  "Bahraich",
  "Ballia",
  "Banda",
  "Banki",
  "Bareilly",
  "Basti",
  "Bijnor",
  "Bithur",
  "Budaun",
  "Bulandshahr",
  "Deoria",
  "Etah",
  "Etawah",
  "Faizabad",
  "Fatehgarh",
  "Fatehpur",
  "Sikri",
  "Ghaziabad",
  "Ghazipur",
  "Gonda",
  "Gorakhpur",
  "Hamirpur",
  "Hardoi",
  "Hathras",
  "Jalaun",
  "Jaunpur",
  "Jhansi",
  "Kannauj",
  "Kanpur",
  "Lakhimpur",
  "Lalitpur",
  "Lucknow",
  "Mainpuri",
  "Mathura",
  "Meerut",
  "Vindhyachal",
  "Moradabad",
  "Muzaffarnagar",
  "Partapgarh",
  "Pilibhit",
  "Prayagraj",
  "Bareli",
  "Rampur",
  "Saharanpur",
  "Sambhal",
  "Shahjahanpur",
  "Sitapur",
  "Sultanpur",
  "Tehri",
  "Varanasi",
  "Uttarakhand",
  "Almora",
  "Dun",
  "Haridwar",
  "Mussoorie",
  "Nainital",
  "Pithoragarh",
  "Bengal",
  "Alipore",
  "Duar",
  "Asansol",
  "Baharampur",
  "Bally",
  "Balurghat",
  "Bankura",
  "Baranagar",
  "Barasat",
  "Barrackpore",
  "Basirhat",
  "Bhatpara",
  "Bishnupur",
  "Budge",
  "Burdwan",
  "Chandernagore",
  "Darjiling",
  "Harbour",
  "Dum",
  "Durgapur",
  "Halisahar",
  "Haora",
  "Hugli",
  "Bazar",
  "Jalpaiguri",
  "Kalimpong",
  "Kamarhati",
  "Kanchrapara",
  "Kharagpur",
  "Bihar",
  "Kolkata",
  "Krishnanagar",
  "Malda",
  "Midnapore",
  "Murshidabad",
  "Navadwip",
  "Palashi",
  "Panihati",
  "Purulia",
  "Raiganj",
  "Santipur",
  "Shantiniketan",
  "Shrirampur",
  "Siliguri",
  "Siuri",
  "Tamluk",
  "Titagarh"
];

var optionCities = [];

for (var city of cities) {
  optionCities.push({ label: city, value: city });
}

// console.log("optionCities");"
export default optionCities;

import { useState, useEffect } from "react";
import { NavLink, useParams, useHistory } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";
import { useForm } from "react-hook-form";
// import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
// import Profile from "../Assets/img/expert-advice/profile-img.png";
import Group from "../Assets/img/expert-advice/group.png";
import Star from "../Assets/img/expert-advice/star.png";
import SmallStar from "../Assets/img/expert-advice/small-star.png";
// import Img1 from "../Assets/img/expert-advice/company1.png";
// import Img2 from "../Assets/img/expert-advice/company2.png";
// import Img3 from "../Assets/img/expert-advice/company3.png";
// import Img4 from "../Assets/img/expert-advice/company4.png";
// import Img5 from "../Assets/img/expert-advice/company5.png";
// import Img6 from "../Assets/img/expert-advice/company6.png";
// import Img7 from "../Assets/img/expert-advice/logo1.png";
// import Img8 from "../Assets/img/expert-advice/logo2.png";
// import Img9 from "../Assets/img/expert-advice/logo3.png";
// import Img10 from "../Assets/img/expert-advice/logo4.png";
// import Img11 from "../Assets/img/expert-advice/logo5.png";
// import Img12 from "../Assets/img/expert-advice/logo6.png";
// import Img13 from "../Assets/img/expert-advice/logo7.png";
// import Img14 from "../Assets/img/expert-advice/logo8.png";
// import Img15 from "../Assets/img/expert-advice/logo9.png";
// import Img16 from "../Assets/img/expert-advice/logo10.png";
// import Img17 from "../Assets/img/expert-advice/logo11.png";
// import Img18 from "../Assets/img/expert-advice/logo12.png";
// import Img19 from "../Assets/img/expert-advice/logo13.png";
// import Img20 from "../Assets/img/expert-advice/logo14.png";
import Verify from "../Assets/img/expert-advice/verify.png";
import ShareBtn from "../Assets/img/expert-advice/share-btn.png";
import Video from "../Assets/img/expert-advice/video.png";
import In from "../Assets/img/expert-advice/in.png";
import Experience from "../Assets/img/expert-advice/experience.png";
import style from "../Assets/css/expertprofile.module.css";
import styles from "../Assets/css/jobposting.module.css";
import { Button, CircularProgress } from "@mui/material";
// import { ExpertCard } from "./BookExpert";
import { BASE_URL } from "../env";
import axios from "axios";
// import NimishPic from "../Assets/img/expert-advice/Nimish.png";
import ReactGA from "react-ga";
import Whatsapp from "../Assets/img/job-component/whatsapp.png";
import Insta from "../Assets/img/job-component/insta.png";
import Linkedin from "../Assets/img/job-component/linkedin.png";
import Copy from "../Assets/img/job-component/copy.png";
import { Store } from "react-notifications-component";
import exp1 from "../Assets/img/expert-advice/Nimish/exp1.png";
import exp2 from "../Assets/img/expert-advice/Nimish/exp2.png";
import exp3 from "../Assets/img/expert-advice/Nimish/exp3.png";
import exp4 from "../Assets/img/expert-advice/Nimish/exp4.png";
import iitb from "../Assets/img/expert-advice/Nimish/iitc.png";
import iitc from "../Assets/img/expert-advice/Nimish/iitb.png";
import deloitte from "../Assets/img/expert-advice/Nimish/deloitte.png";
import bcg from "../Assets/img/expert-advice/Nimish/bcg.png";
import ey from "../Assets/img/expert-advice/Nimish/ey.png";
import kpmg from "../Assets/img/expert-advice/Nimish/kpmg.png";

function ExpertProfile() {
  // const { register, handleSubmit } = useForm();
  // const onSubmit = (data) => console.log("data is being run");
  // const [mentors, setMentors] = React.useState([]);
  const [share, setShare] = useState(false);
  let history = useHistory();
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors, isValid },
  } = useForm({ mode: "all" });
  const { id } = useParams();
  const [profileData, setProfileData] = useState(null);
  const [plan, setPlan] = useState("");
  const [isChecked, setisChecked] = useState(false);

  // useEffect(async () => {
  //   await fetch(BASE_URL + "/getMentors")
  //     .then((res) => res.json())
  //     .then((data) => {
  //       setMentors(data.mentors);
  //       // console.log(data.mentors);
  //     });
  // }, []);

  useEffect(() => {
    const checkProfile = async () => {
      const res = await axios({
        method: "GET",
        url: `${BASE_URL}/getMentor/${id}`,
      });
      // console.log(res.data);
      setProfileData(res.data);
    };
    checkProfile();
  }, []);

  const data = watch();

  const onSubmit = () => {
    setPlan(data.plan);
    history.push({
      pathname: "/mentorform",
      state: {
        plan: data.plan,
      },
    });
  };
  const notifyPopupCopied = () => {
    Store.addNotification({
      title: "Copied",
      message: "Copied to the Clipboard",
      type: "success",
      background: "pink",
      insert: "bottom",
      container: "bottom-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true,
      },
    });
  };
  const [postingData, setPostingData] = useState({
    dateAdded: "10",
    dueDate: "10",
    description: "",
    skillsRequired: "",
    hiring_process: ""
  });
  async function copyFunction() {
    ReactGA.event({
      category: "Company Job Posting",
      type: " Share in Company Job Posting ",
      name: postingData.companyName,
      action: `${postingData.companyName} in Company JOb posting`,
    });
    await navigator.clipboard
      .writeText(`${window.location.href}`)
      .then(() => {
        notifyPopupCopied();
      })
      .catch((err) => {
        // console.log(err);
      });
  }
  async function copyTextFunction() {
    await navigator.clipboard
      .writeText(`${window.location.href}`)
      .then(() => { })
      .catch((err) => { });
  }
  return (
    <>
      {
        profileData ?
          <>
            <div
              className={`container my-sm-4 py-md-5 py-3 ${style.header}`}
              style={{ marginBottom: "0.5rem !important" }}
            >
              {!share ? (
                ""
              ) : (
                <div className={` ${styles.socialIcons}`} style={{ zIndex: "1", right: "0" }}>
                  <a
                    onClick={copyTextFunction}
                    href="https://web.whatsapp.com"
                    className="mx-2"
                  >
                    <img
                      src={Whatsapp}
                      className={`img-fluid ${styles.socialIcon}`}
                      alt="whatsaap icon"
                    />
                  </a>
                  <a
                    onClick={copyTextFunction}
                    href="https://www.linkedin.com"
                    className="mx-2"
                  >
                    <img
                      src={Linkedin}
                      className={`img-fluid ${styles.socialIcon}`}
                      alt="linkedin icon"
                    />
                  </a>
                  <a
                    onClick={copyTextFunction}
                    href="https://www.instagram.com"
                    className="mx-2"
                  >
                    <img
                      src={Insta}
                      className={`img-fluid ${styles.socialIcon}`}
                      alt="insta icon"
                    />
                  </a>
                  <img
                    src={Copy}
                    className={`img-fluid ${styles.socialIcon}`}
                    alt="copy icon"
                    onClick={copyFunction}
                  />
                </div>
              )}
              <div className={style.shareBtn}>
                <img src={ShareBtn} className="img-fluid" alt="share btn" onClick={() => setShare(!share)} />
              </div>
              <div className="row m-0 justify-content-evenly">
                <div className="col-lg-4 col-md-6 d-flex justify-content-center flex-column align-items-center m-0">
                  <img
                    src={profileData.image}
                    className={`img-fluid ${style.expertProfile}`}
                    alt="expert's profile"
                  />
                  <div className={`d-md-none ${style.expertName}`}>
                    {profileData.fullName}
                    <img
                      src={Verify}
                      className="img-fluid mx-md-4 mx-2"
                      alt="verified icon"
                    />
                  </div>
                  <div className={`d-md-none ${style.expertField}`}>
                    {profileData.currentDesignation}
                  </div>
                  <div className={`${style.expertScoreCard}`}>
                    <div className={style.leftSection}>
                      <div className={style.upperSection}>
                        <img src={Star} className="img-fluid" alt="star logo" />
                        {profileData.rating || 4.9}
                      </div>
                      <div className={style.lowerSection}>{profileData.reviewsNumber} Reviews</div>
                    </div>
                    <div className={style.rightSection}>
                      <div className={style.upperSection}>
                        <img src={Group} className="img-fluid" alt="group logo" />
                        {profileData.studentsNumber}
                      </div>
                      <div className={style.lowerSection}>Students Mentored</div>
                    </div>
                  </div>
                  <Button className={`px-2 ${style.viewProfileBtn}`} href={profileData.linkedin} target="blank">
                    <img src={In} className="img-fluid mx-3" alt="linkedin icon" />
                    View Profile
                  </Button>
                </div>
                <div className={`col-lg-5 col-md-6 m-0 ${style.rightHeaderSection}`}>
                  <div className="d-md-block d-none">
                    <div className={style.expertName}>
                      {profileData.fullName}
                      <img
                        src={Verify}
                        className="img-fluid mx-md-4 mx-2"
                        alt="verified icon"
                      />
                    </div>
                    <div className={style.expertField}>{profileData.currentDesignation}</div>
                    <div className={`${style.companyLogo}`}><img className="img-fluid" alt="company logo" src={profileData.currentPlaced} /></div>
                  </div>
                  <div className={style.headerMidSection}>
                    <div className={style.headerMidLeftSection}>
                      <div className={style.headerMidSectionHeading}>experience</div>
                      <div className="d-flex">
                        <div className={`${style.companyLogo}`}>
                          <img src={exp1} className="img-fluid" alt="company logo" />
                        </div>
                        <div className={`${style.companyLogo}`}>
                          <img src={exp2} className="img-fluid" alt="company logo" />
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className={`${style.companyLogo}`}>
                          <img src={exp3} className="img-fluid" alt="company logo" />
                        </div>
                        <div className={`${style.companyLogo}`}>
                          <img src={exp4} className="img-fluid" alt="company logo" />
                        </div>
                      </div>
                    </div>
                    <div className={style.headerMidRightSection}>
                      <div className={style.headerMidSectionHeading}>education</div>
                      <div className={style.educationCol}>
                        <div className={`${style.companyLogo}`}>
                          <img src={iitb} className="img-fluid" alt="company logo" />
                        </div>
                        IIM CALCUTTA
                      </div>
                      <div className={style.educationCol}>
                        <div className={`${style.companyLogo}`}>
                          <img src={iitc} className="img-fluid" alt="company logo" />
                        </div>
                        IIT BOMBAY
                      </div>
                    </div>
                  </div>
                  <div className={style.headerFootSection}>
                    <div className={style.headerFootLeftSection}>
                      <img
                        src={Experience}
                        className={`img-fluid`}
                        alt="experience icon"
                      />
                      {profileData.experience}Y
                      <span>Experience</span>
                    </div>
                    <div className={style.headerFootRightSection}>
                      <img
                        src={Video}
                        className={`img-fluid`}
                        alt="experience icon"
                      />
                      108K
                      <span>Hours</span>
                    </div>
                  </div>
                  <Button className={`d-md-none px-2 ${style.viewProfileMobileBtn}`}>
                    <img src={In} className="img-fluid mx-3" alt="linkedin icon" />
                    View Profile
                  </Button>
                </div>
              </div>
            </div>

            <div className={`${style.subHeader}`} id="ExpertPlan">
              <div className={`container mb-4 py-3 `}>
                <div className="row g-0">
                  <div className="col-lg-2 mb-lg-0 mb-2 d-flex align-items-center">
                    <p className={style.planHeading}>Select a Plan</p>
                  </div>
                  <div className="col-lg-8 mb-lg-0 mb-2">
                    {/* <form onSubmit={handleSubmit(onSubmit)}> */}
                    <div className="row g-2">
                      <label for="speed" className="col-sm-4 mb-0">
                        <div className="col-sm-12 mb-0">
                          <div className={style.plans}>
                            <div className={style.minutes}>
                              4 <span></span>
                              <br />
                              <span>Hrs</span>
                            </div>
                            <div className={`${style.planDesc}`}>
                              Speed Mentoring
                              <br />
                              <span className={style.oldPrice}>₹8000</span>
                              <span className={style.newPrice}>₹6000</span>
                            </div>
                            <input
                              className={style.radioBtn}
                              type="radio"
                              id="speed"
                              name="plan"
                              value="speed"
                              {...register("plan")}
                            />
                          </div>
                        </div>
                      </label>
                      <label for="pro" className="col-sm-4 mb-0">
                        <div className="col-sm-12 mb-0">
                          <div className={style.plans}>
                            <div className={`${style.bestSeller}`}>best seller</div>
                            <div className={style.minutes}>
                              6<span></span>
                              <br />
                              <span>Hrs</span>
                            </div>
                            <div className={`${style.planDesc}`}>
                              Pro Mentoring
                              <br />
                              <span className={style.oldPrice}>₹10000</span>
                              <span className={style.newPrice}>₹8000</span>
                            </div>
                            <input
                              className={style.radioBtn}
                              type="radio"
                              name="plan"
                              value="pro"
                              id="pro"
                              {...register("plan")}
                            />
                          </div>
                        </div>
                      </label>
                      <label for="360" className="col-sm-4 mb-0">
                        <div className="col-sm-12 mb-0">
                          <div className={style.plans}>
                            <div className={style.minutes}>
                              8 <span></span>
                              <br />
                              <span>Hrs</span>
                            </div>
                            <div className={style.planDesc}>
                              360° Mentoring
                              <br />
                              <span className={style.oldPrice}>₹15000</span>
                              <span className={style.newPrice}>₹10000</span>
                            </div>
                            <input
                              className={style.radioBtn}
                              type="radio"
                              name="plan"
                              value="360"
                              id="360"
                              {...register("plan")}
                            />
                          </div>
                        </div>
                      </label>
                    </div>
                    {/* </form> */}
                  </div>
                  <div className="col-lg-2 mb-lg-0 d-flex justify-content-center align-items-lg-end flex-column m-0">
                    {data.plan != null ? (
                      id === "61adc3d38f07e587d353cd8f" ? (
                        <Button
                          className={style.connectEnabled}
                          href={
                            data.plan == "speed"
                              ? "https://rzp.io/l/hbNorKI6M"
                              : data.plan == "pro"
                                ? "https://rzp.io/l/kFNPjmbND1"
                                : data.plan == "360"
                                  ? "https://rzp.io/l/X32nRe1"
                                  : ""
                          }
                          target="blank"
                        >
                          Let's Connect
                        </Button>
                      ) : (
                        <Button className={style.connectEnabled} onClick={onSubmit}>
                          Let's Connect
                        </Button>
                      )
                    ) : (
                      <NavHashLink to={`/expert-profile/${id}#ExpertPlan`}>
                        <Button className={style.connectDisabled}>
                          Let's Connect
                        </Button>
                      </NavHashLink>
                    )}

                    <p className={`${style.seatLeft}`}>Only 3 seats left!</p>
                  </div>
                </div>
              </div>
            </div>

            <div className={`container my-0`}>
              <div className="row g-sm-5 g-0">
                {/* Left Sidebar */}
                <div className="col-lg-4 mb-0">
                  <div className="row">
                    <div className="col-12 px-sm-0">
                      <div className={style.box}>
                        <h1 className={`${style.descHead}`}>Domains</h1>
                        <ul className={style.fielsUl}>
                          {
                            profileData?.domain?.map((exp, ind) => <li key={ind}>{exp}</li>)
                          }
                        </ul>
                        <h1 className={`${style.descHead}`}>Expertise</h1>
                        <ul className={style.fielsUl}>
                          {
                            profileData?.expertise?.map((exp, ind) => <li key={ind}>{exp}</li>)
                          }
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Right Sidebar */}
                <div className="col-lg-8 mt-lg-5 mt-0">
                  <div className="row">
                    <div className={`col-12 px-sm-0`}>
                      <div className={style.box}>
                        <h4 className={`${style.descHead}`}>
                          Why have me as your Mentor?
                        </h4>
                        <ul className={style.profileDesc}>
                          <li>
                            Plan your Career <br />
                            <span>Cantilever Labs is the official placement.</span>
                          </li>
                          <li>
                            Get rid of interview fear <br />
                            <span>Cantilever Labs is the official placement.</span>
                          </li>
                          <li>
                            Build the perfect Resume <br />
                            <span>Cantilever Labs is the official placement.</span>
                          </li>
                          <li>
                            Gain Insights in Data Science <br />
                            <span>Cantilever Labs is the official placement.</span>
                          </li>
                        </ul>
                      </div>
                    </div>

                    {/* Placement Support */}
                    <div className="col-12 px-sm-0">
                      <div className={style.box}>
                        <h1 className={`${style.descHead}`}>
                          I Can Help You Get Placed At
                        </h1>
                        <div className="mt-4">
                          <img
                            src={exp1}
                            className={style.comLogo + " shadow "}
                            alt="company logo"
                          />
                          <img
                            src={exp2}
                            className={style.comLogo + " shadow "}
                            alt="company logo"
                          />
                          <img
                            src={exp3}
                            className={style.comLogo + " shadow "}
                            alt="company logo"
                          />
                          <img
                            src={exp4}
                            className={style.comLogo + " shadow "}
                            alt="company logo"
                          />
                          <img
                            src={ey}
                            className={style.comLogo + " shadow "}
                            alt="company logo"
                          />
                          <img
                            src={kpmg}
                            className={style.comLogo + " shadow "}
                            alt="company logo"
                          />
                          <img
                            src={deloitte}
                            className={style.comLogo + " shadow "}
                            alt="company logo"
                          />
                          <img
                            src={bcg}
                            className={style.comLogo + " shadow "}
                            alt="company logo"
                          />
                          {/* <img                      src={Img15}    className={style.comLogo}               alt="company logo" />
                    <img                      src={Img16}                      className={style.comLogo}             alt="company logo" />
                    <img                      src={Img17}                      className={style.comLogo}                      alt="company logo"  />
                    <img  src={Img18}  className={style.comLogo}                  alt="company logo" /> */}
                          {/* <img src={Img19} className={style.comLogo} alt="company logo" />
                    <img src={Img20} className={style.comLogo} alt="company logo" /> */}
                        </div>
                      </div>
                    </div>

                    {/* Intro Video */}
                    {/* <div className="col-12 px-sm-0">
                <div className={style.box}>
                  <h4 className={`${style.descHead}`}>Intro Video</h4>
                  <div className="row">
                    <div className="col-md-6">
                      <div className={style.video}></div>
                    </div>
                    <div className="col-md-6">
                      <div className={style.video}></div>
                    </div>
                  </div>
                </div>
              </div> */}

                    {/* Testimonial */}
                    <div className={`col-12 px-sm-0`}>
                      <div className={style.box}>
                        <h4 className={`${style.descHead} mb-5`}>
                          Here's what my students say
                        </h4>
                        <div className="row">
                          <div className="col-12">
                            {profileData?.testimonials?.map((pro) => (
                              <>
                                <div className="row">
                                  <div className="col-sm-3 col-4 mb-0 d-flex justify-content-md-center">
                                    <img
                                      src={pro.photo}
                                      className={`img-fluid ${style.wrapperImg}`}
                                      alt="profile"
                                    />
                                  </div>
                                  <div className="col-sm-9 col-8 mb-0">
                                    <div className={style.wrapperName}>{pro.name}</div>
                                    <div className={style.wrapperPlace}>{pro.collageName}</div>
                                    <div className={style.wrapperStar}>
                                      {
                                        [...Array(parseInt(pro?.stars))].map(() => <img src={SmallStar} className="img-fluid" alt="" />)
                                      }
                                    </div>
                                  </div>
                                  <div className="col-md-9 offset-md-3 mt-2">
                                    <div className={style.wrapperText}>
                                      {pro.text}
                                    </div>
                                  </div>
                                </div>
                                <hr />
                              </>))
                            }
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Profile Cards */}
                    {/* <div className={`col-12 ${style.partsHeading}`}>
                My Peers Who Can Guide You As Well
              </div>
              {mentors
                .map((mentor) => {
                  return (
                    <>
                      <div className="col-lg-6">
                        <ExpertCard data={mentor} />
                      </div>
                    </>
                  );
                })
                .slice(0, 2)} */}

                    {/* Alerts */}
                    <div className={`col-12 ${style.partsHeading}`}>
                      My Friends at Cantilever Labs Have More For You
                    </div>
                    <div className="col-12 px-sm-0">
                      <div className="row">
                        <div className="col-12">
                          <div className={style.box}>
                            <div className={style.descHead}>Internship Alert!</div>
                            <div className={style.alertText}>
                              Our Opportunity Board is having 50+ live Internships
                              from TOP companies like{" "}
                              <span>
                                Intel, American Express, InMobi, Gartner, AMD,
                                Postman, IBM, United Nations, Cvent, Bentley, Hike,
                              </span>{" "}
                              and many more are adding every day!
                            </div>
                            <div className="d-flex justify-content-end me-3 ">
                              <NavLink
                                to="/jobs-for-freshers"
                                onClick={() => {
                                  window.scroll(0, 0);
                                }}
                              >
                                <Button className={style.alertBtn}>Apply now</Button>
                              </NavLink>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 px-sm-0">
                          <div className={style.box}>
                            <div className={style.descHead}>Get Placement Ready</div>
                            <div className={style.alertText}>
                              We have successfully impacted 27,500+ students so far
                              who were a part of TOP 152+ colleges like -{" "}
                              <span>
                                IIT Bombay IIT Madras, BITS Pilani, JNTU-JHUB, SRM,
                                SYMBIOSIS, JNTU Hyderabad, GITAM, SNST, VJIT,
                                MAHINDRA,
                              </span>{" "}
                              and many more.
                            </div>
                            <div className="d-flex justify-content-end me-3">
                              <NavLink
                                to="/newcoursepage"
                                onClick={() => {
                                  window.scroll(0, 0);
                                }}
                              >
                                <Button className={style.alertBtn}>Enroll now</Button>
                              </NavLink>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </> : <CircularProgress
          sx={{ position: "absolute", top: "80%", left: "45%" }}
          color="warning"
        />
      }
    </>
  );
}

export default ExpertProfile;

import React from "react";
const Whyweb = (props) => {
	return (
		<div>
			<div
				className="container-fluid images-div justify-content-around"
				style={{ marginTop: "80px" }}
			>
				<h1 style={{ textAlign: "center", marginBottom: "50px" }}>
					{" "}
					<b>
						{" "}
						{props.headingname ? props.headingname : "Why Web development?"}
					</b>{" "}
				</h1>
				<div
					className="row"
					style={{
						marginTop: "80px",
						marginBottom: "40px",
						justifyContent: "center",
					}}
				>
					<div
						style={{
							height: "155px",
							borderRadius: "20px",
							boxShadow: "0px 10px 20px #0000002B",
						}}
						className="card col-md-3 p-2"
					>
						<h1 style={{ color: "#00A2C8" }}>
							<b>100+</b>
						</h1>
						<p>
							<b>
								Doubts solved<br></br> per hour
							</b>
						</p>
					</div>
					<div className="col-md-1"></div>
					<div
						style={{
							height: "130px",
							borderRadius: "20px",
							boxShadow: "0px 10px 20px #0000002B",
						}}
						className="card col-md-3 p-2"
					>
						<h1 style={{ color: "#00A2C8" }}>
							<b>98%</b>
						</h1>
						<p>
							<b>
								Doubt resolution with<br></br>min 4+ star ratings
							</b>
						</p>
					</div>
					<div className="col-md-1"></div>
					<div
						style={{
							height: "130px",
							borderRadius: "20px",
							boxShadow: "0px 10px 20px #0000002B",
						}}
						className="card col-md-3 p-2"
					>
						<h1 style={{ color: "#00A2C8" }}>
							<b>30 min</b>
						</h1>
						<p>
							<b>
								Average time for doubt<br></br>to get resolved
							</b>
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};
export default Whyweb;

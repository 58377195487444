import React, {useState, useEffect} from "react";
import Popup from "reactjs-popup";
import style from "../Assets/css/mycourse.module.css";
import { BASE_URL } from "../env";

function PracticeSection(props) {

  const [card_data, setCard_data] = useState([])
  const [morningData, setMorningdata] = useState([]);
//   const [afternoonData, setAfternoondata] = useState([]);
  const [eveningData, setEveningdata] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);

	const [probability, setprobablity] = useState([]);
	const [statistics, setstatistics] = useState([])
	const [arrays, setarray] = useState([])
	const [linkedlinst, setlinkedlist] = useState([])
	const [stack, setstack] = useState([])
	
  useEffect(() => {
      fetch(BASE_URL + "/getProfile", {
        headers: { authorization: "Bearer " + localStorage.getItem("token") },
      })
        .then((res) => res.json())
		  .then((data) => {
			
            setIsAdmin(data.user.isAdmin);
        });
  }, []);

  useEffect(() => {
	  fetch(BASE_URL + `/getSlido/${props.courseID}`, {
		  method: "GET",
		  headers: {
			  "Content-Type": "application/json",
			},
		})
		.then((res) => res.json())
		.then((data) => {
			setCard_data(data);
			setprobablity(data.filter((value)=> value.name.toUpperCase() == "PROBABILITY"))
			setstatistics(data.filter((value)=> value.name.toUpperCase() == "STATISTICS"))
			setarray(data.filter((value)=> value.name.toUpperCase() == "ARRAY"))
			setlinkedlist(data.filter((value)=> value.name.toUpperCase() == "LINKED LIST"))
			setstack(data.filter((value)=> value.name.toUpperCase() == "STACKS")) 
		});
  }, [])


	
	
	useEffect(() => {
		console.log(stack)
},[stack])
	useEffect(() => {
		const newDataM = card_data.filter((value) => value.timeFrame=== "morning")
		setMorningdata(newDataM)  	
		// const newDataA = card_data.filter((value) => value.timeFrame=== "afternoon")
		// setAfternoondata(newDataA)  	
		const newDataE = card_data.filter((value) => value.timeFrame=== "evening")
		setEveningdata(newDataE)  
	},[card_data])
  return (
		<>
			<div className='container-fluid'>
				<div className='row'>
					{/* <div className={`col-12 ${style.sectionStrip}`}>Morning</div> */}
					{morningData.map((data) => {
						return (
							<Card
								id={data._id}
								name={data.name}
								duration={data.duration}
								marks={data.marks}
								code={data.eventCode}
								date={data.test_date}
								link={data.iframeLink}
								timeframe={data.timeFrame}
								isAdmin={isAdmin}
							/>
						);
					})}
{/* 
					<div className={`col-12 ${style.sectionStrip}`}>Afternoon</div>
					{afternoonData.map((data) => {
						return (
							<Card
								name={data.name}
								duration={data.duration}
								marks={data.marks}
								code={data.eventCode}
								date={data.test_date}
								link={data.iframeLink}
							/>
						);
					})} */}
					{/* <div className={`col-12 ${style.sectionStrip}`}>Evening</div> */}
					{eveningData.map((data) => {
						return (
							<Card
								id={data._id}
								name={data.name}
								duration={data.duration}
								marks={data.marks}
								code={data.eventCode}
								date={data.test_date}
								link={data.iframeLink}
								timeframe={data.timeFrame}
								isAdmin={isAdmin}
							/>
						);
					})}
					{/* <PopUp /> */}
				</div>
			</div>
		</>
	);
}

const Card = ({name, duration,code,date,link,timeframe,isAdmin,id}) => {
const [popup, setpopup] = useState(false);
const DeleteHandler=()=>{
	return(
	fetch(BASE_URL + `/deleteSlido/${id}`, {
		method: "DELETE",
		headers: {"Content-Type": "application/json"},
	  })
	  .then((res) => res.json())
	  .then(res=>console.log(res))
	)}
  return (
    <>
      <div className="col-lg-4 col-sm-6" >
        <div className={`${style.practiceCard}`} onClick={()=>setpopup(true)}>
          <div className={`${style.practiceCardTestCode}`}><span> Test Code:{ code}</span>
			<span>  
			Date:{date.split("-")[2]}-{date.split("-")[1]}-{date.split("-")[0]}	  
		</span>
			  </div>
          <div className={`${style.practiceCardName}`}>{name}</div>
          <div className={`${style.practiceCardSplit}`} style={{textAlign:"center",display:"block"}}>
		  <div className={`${style.practiceCardDetails}`}> 
		<span style={{textTransform:"capitalize"}}>{timeframe} : </span>  ({ duration})
		  </div> 
          </div>
        </div>
	{	popup &&  <PopUp link={link} /> 	}
	  {isAdmin && <button onClick={DeleteHandler}>Delete</button>}
      </div>
    </>
  );
};

const PopUp = ({link}) => {
  return (
    <>
      <Popup position="center" modal open>
        <div className={`bg-white ${style.Popup}`}>
          <iframe

					  style={{width:"80vw",height:"80vh"}}
            src={link}
            width="700"
            height="400"
            title="test Card"
          ></iframe>
        </div>
      </Popup>
    </>
  );
};

export default PracticeSection;

import { useEffect, useState, useContext } from "react";
// import { ModalContext } from "../Context/ModalContext";
import { AuthContext } from "../Context/AuthContext";
import useMedia from "../componentsofcourse/useMedia";

import "../Assets/css/navbar.css";
import Avatar from "react-avatar";

import { Link, NavLink, useHistory, useLocation } from "react-router-dom";
import { Store } from "react-notifications-component";
import { DropdownComp, DropdownComp2, DropdownComp3 } from "./DropdownComp";
import { configAxiosLambda } from "../axios.config";

import AOS from "aos";
import "aos/dist/aos.css";

function Navbar() {
    const [LogoutDropDown, setLogoutDropdown] = useState(false);
    let location = useLocation();
    const [loggedIn, setLoggedin] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [data, setData] = useState("");
    const [id, setId] = useState("");
    const [isAdmin, setIsAdmin] = useState(false);
    const history = useHistory();
    const {
        token,
        update_token,
        set_email,
        set_Id,
        setuserid,
        setcoursesenrolled,
        set_name,
        setUserData,
    } = useContext(AuthContext);
    // const [NAME, setNAME] = useState("");
    const [iitbombaystudent, setIitbombaystudent] = useState(false);
    const [name, setname] = useState("");
    const [avatar, setavatar] = useState("");
    //ids for the navlinks
    const ids = [
        "home",
        "aboutus",
        "blog",
        "dropdown-basic-course",
        "dropdown-basic-profile",
        "login",
    ];

    //this is used for making the NavLink bold
    const handleClick = event => {
        for (let i = 0; i < ids.length; i++) {
            let x = document.getElementById(ids[i]);
            if (x != null && x.style.fontWeight === "600") {
                x.style.fontWeight = "lighter";
            }
        }
        if (event.target.id === "cp" || event.target.id === "gpr") {
            document.getElementById("dropdown-basic-course").style.fontWeight =
                "600";
        } else {
            event.target.style.fontWeight = "600";
        }
    };


    useEffect(() => {
        const controller = new AbortController();
        setLogoutDropdown(false);
        configAxiosLambda(localStorage.getItem("token"))
            .get("/getProfile", {
                signal: controller.signal,
            })
            .then(({ data }) => {
                setLoggedin(true);
                setname(data?.user?.firstName + " " + data?.user?.lastName);
                set_name(data?.user?.firstName + " " + data?.user?.lastName);
                setavatar(data?.user?.profile_pic);
                setuserid(data?.user?._id);
                set_Id(data?.user?._id);
                setcoursesenrolled(data?.student?.smallCourses);
                localStorage.setItem("emailID", data?.user?.email);
                setData(data);
                setId(data.user._id);
                set_email(data.user.email);
                setIitbombaystudent(data.user.iitbombaystudent);
                setFirstName(data.user.firstName);
                setIsAdmin(data.user.isAdmin);
                setUserData(data.user);
            })
            .catch(({ response }) => {
                switch (response.status) {
                    case 401:
                        localStorage.clear();
                        setLoggedin(false);
                        break;

                    default:
                }
            });

        return () => controller.abort();
    }, []);
    const notifyPopup = () => {
        Store.addNotification({
            title: "Logged Out!",
            message: "You are Logged Out!",
            type: "danger",
            background: "pink",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 3000,
                onScreen: true,
            },
        });
    };

    const logout = () => {
        localStorage.clear();
        update_token("");
        setLoggedin(false);
        history.push("/");
        notifyPopup();
    };

    const [loggedinClick, setLoggedinClick] = useState(false);
    const [drop, setDrop] = useState(false);

    function LoggedInComponentMobile() {
        return (
            <>
                <div className="container-fluid">
                    <Link
                        className="row d-flex justify-content-center p-4 btn-lg btn-white "
                        onClick={() => setLoggedinClick(!loggedinClick)}
                        style={{ textDecoration: "none" }}
                    >
                        {" "}
                        <div
                            onClick={() => setDrop(!drop)}
                            className="col-sm-12 mx-auto d-flex justify-content-center text-light"
                            style={{
                                fontWeight: "bold",
                                textDecoration: "none",
                                marginBottom: "0",
                            }}
                        >
                            {firstName.toUpperCase()}{" "}
                            {drop ? (
                                <i
                                    style={{
                                        marginLeft: "2%",
                                        marginTop: "2%",
                                    }}
                                    className="fas fa-caret-up"
                                ></i>
                            ) : (
                                <i
                                    style={{
                                        marginLeft: "2%",
                                        marginTop: "2%",
                                    }}
                                    className="fas fa-caret-down"
                                ></i>
                            )}
                        </div>
                    </Link>
                </div>
                {loggedinClick == true ? (
                    <div>
                        {isAdmin == true && (
                            <>
                                <Link
                                    to="/addblog"
                                    onClick={() => {
                                        handlehamclick();
                                    }}
                                    className="row d-flex justify-content-center p-4 btn-lg btn-light white"
                                    style={{
                                        borderRadius: "0px",
                                        color: "#000",
                                    }}
                                >
                                    {" "}
                                    Create Blog
                                </Link>
                            </>
                        )}

                        <Link
                            to="/"
                            onClick={() => {
                                logout();
                                handlehamclick();
                            }}
                            className="row d-flex justify-content-center p-4 btn-lg btn-light white"
                            style={{
                                borderTopRightRadius: "0px",
                                borderTopLeftRadius: "0px",
                                textDecoration: "none",
                                color: "#000!important",
                            }}
                        >
                            {" "}
                            Log out
                        </Link>
                    </div>
                ) : (
                    <></>
                )}
            </>
        );
    }

    const [click, setclick] = useState(false);
    const [dropdown, setdropdown] = useState(false);
    const [dropdown2, setdropdown2] = useState(false);
    const [dropdown3, setdropdown3] = useState(false);
    const [rotation, setrotation] = useState(true);
    const [coursesrotation, setcoursesrotation] = useState(true);
    const [coursesrotation2, setcoursesrotation2] = useState(true);
    const [coursesrotation3, setcoursesrotation3] = useState(true);
    const [clickcourse, setclickcourse] = useState(false);
    const [clickcourse2, setclickcourse2] = useState(false);
    const [clickcourse3, setclickcourse3] = useState(false);
    const [sidebar, setSidebar] = useState(false);
    const [jobpostselected, setJobpostselected] = useState(false);

    const matches = useMedia("(min-width : 1198px )");
    const matches_small = useMedia("(min-width : 1268px )");
    const handlehamclick = () => setclick(!click);
    const closeMobileMenu = () => setclick(false);
    const toggle = () => setSidebar(!sidebar);

    useEffect(() => {
        AOS.init();
    });

    let hash = 0;
    let i;
    /* eslint-disable no-bitwise */
    for (i = 0; i < name.length; i += 1) {
        hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.substr(-2);
    }
    return (
        <>
            {/* <Courselaunchingbanner /> */}

            {!matches ? (
                <>
                    <div
                        style={{
                            position: "sticky",
                            top: "0",
                            zIndex: "9999",
                            width: "100%",
                        }}
                    >
                        {/* <JoinTelegramBanner /> */}
                        <div
                            style={{
                                width: "100%",
                                height: "50px",
                                backgroundColor: "white",
                                transitionDuration: "0.5s",
                            }}
                            className="Row d-flex flex"
                        >
                            <div
                                onClick={() => {
                                    setSidebar(!sidebar);
                                }}
                                style={{ width: "10%", textAlign: "center" }}
                            >
                                <i
                                    className="fas fa-bars"
                                    style={{
                                        margin: "auto",
                                        padding: "3px",
                                        color: "black",
                                        transform:
                                            "translate(8px, 15px) scale(220%, 170%)",
                                        borderRadius: "3px",
                                    }}
                                ></i>
                            </div>
                            <div
                                style={{
                                    width: "90%",
                                    textAlign: "right",
                                    height: "100%",
                                }}
                            >
                                <img
                                    src="https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644088580619_cantileverlogoblack.png"
                                    style={{
                                        width: "170px",
                                        margin: "auto 0 auto auto",
                                    }}
                                    alt="Cantilever Labs"
                                />
                            </div>
                        </div>
                    </div>
                    <nav
                        className="container-fluid"
                        style={{
                            transitionDuration: "0.3s",
                            position: "fixed",
                            zIndex: "1000000",
                            backgroundColor: matches == false && "black",
                            color: "white",
                            top: "0",
                            width: "100%",
                            left: sidebar ? "0" : "-100%",
                        }}
                    >
                        <ul
                            className="container-fluid"
                            style={{
                                overflowY: "scroll",
                                height: window.innerHeight,
                            }}
                        >
                            <i
                                className="fas fa-times"
                                onClick={toggle}
                                style={{
                                    margin: "auto",
                                    padding: "3px",
                                    marginBottom: "25px",
                                    color: "white",
                                    transform:
                                        "translate(16px, 16px) scale(200%, 170%)",
                                    border: "1px solid white",
                                    borderRadius: "3px",
                                }}
                            ></i>
                            <Link
                                to="/"
                                style={{
                                    fontWeight: "bolder",
                                    textDecoration: "none",
                                }}
                                className="row d-flex justify-content-center p-4 btn-lg btn-white text-light"
                                onClick={toggle}
                            >
                                HOME
                            </Link>
                            <Link
                                className="row d-flex justify-content-center font-weight-bold btn-lg btn-white text-light"
                                style={{
                                    fontWeight: "bolder",
                                    textDecoration: "none",
                                    textAlign: "center",
                                }}
                                onClick={() => setclickcourse(!clickcourse)}
                            >
                                <div
                                    className="col-sm-12 pt-2"
                                    style={{
                                        marginBottom: "5px",
                                        marginLeft: "8px",
                                    }}
                                >
                                    COURSES{" "}
                                    <i
                                        id="COURSES"
                                        className="fas fa-caret-down"
                                        // onClick={toggle}
                                        style={{
                                            transform:
                                                "rotateX(" +
                                                (clickcourse ? "180" : "0") +
                                                "deg)",
                                        }}
                                    ></i>{" "}
                                </div>
                            </Link>
                            {clickcourse == true ? (
                                <div className="container" data-aos="fade-in">
                                    <Link
                                        to="/target-service-company"
                                        onClick={toggle}
                                        className="row d-flex justify-content-center p-4 btn-lg btn-light white"
                                        style={{
                                            borderTopLeftRadius: "0px",
                                            borderStyle: "0px",
                                            borderTopRightRadius: "0px",
                                            textDecoration: "none",
                                            color: "#000",
                                        }}
                                    >
                                        Target Service Companies
                                    </Link>
                                    <Link
                                        to="/target-product-company"
                                        onClick={toggle}
                                        className="row d-flex justify-content-center p-4 btn-lg btn-light white"
                                        style={{
                                            borderTopLeftRadius: "0px",
                                            borderStyle: "0px",
                                            borderTopRightRadius: "0px",
                                            textDecoration: "none",
                                            color: "#000",
                                        }}
                                    >
                                        Target Product Companies
                                    </Link>
                                    <Link
                                        to="/tcs"
                                        onClick={toggle}
                                        className="row d-flex justify-content-center p-4 btn-lg btn-light white"
                                        style={{
                                            borderTopLeftRadius: "0px",
                                            borderStyle: "0px",
                                            borderTopRightRadius: "0px",
                                            textDecoration: "none",
                                            color: "#000",
                                        }}
                                    >
                                        Tcs Nqt Preparation Course
                                    </Link>
                                    <Link
                                        to="/quant_finance"
                                        onClick={toggle}
                                        className="row d-flex justify-content-center p-4 btn-lg btn-light white"
                                        style={{
                                            cursor: "pointer",
                                            border: "none",
                                            borderBlockEndWidth: "1px",
                                            borderBottomLeftRadius: "0px",
                                            borderBottomRightRadius: "0px",
                                            left: "30%",
                                            textDecoration: "none",
                                            color: "#000!important",
                                        }}
                                    >
                                        Quant Finance
                                    </Link>
                                    <Link
                                        to="/newcoursepage"
                                        onClick={toggle}
                                        className="row d-flex justify-content-center p-4 btn-lg btn-light white"
                                        style={{
                                            borderTopLeftRadius: "0px",
                                            borderStyle: "0px",
                                            borderTopRightRadius: "0px",
                                            textDecoration: "none",
                                            color: "#000",
                                        }}
                                    >
                                        Get Placement Ready
                                    </Link>
                                    <Link
                                        to="/fasttrackcourse"
                                        onClick={toggle}
                                        className="row d-flex justify-content-center p-4 btn-lg btn-light white"
                                        style={{
                                            borderTopLeftRadius: "0px",
                                            borderStyle: "0px",
                                            borderTopRightRadius: "0px",
                                            textDecoration: "none",
                                            color: "#000",
                                        }}
                                    >
                                        Fast Track
                                    </Link>
                                </div>
                            ) : (
                                <></>
                            )}
                            <Link
                                className="row d-flex justify-content-center font-weight-bold btn-lg btn-white text-light"
                                style={{
                                    fontWeight: "bolder",
                                    textDecoration: "none",
                                    textAlign: "center",
                                }}
                                onClick={() => setclickcourse2(!clickcourse2)}
                            >
                                <div
                                    className="col-sm-12 pt-2"
                                    style={{
                                        marginBottom: "5px",
                                        marginLeft: "8px",
                                    }}
                                >
                                    Free Resources{" "}
                                    <i
                                        id="COURSES"
                                        className="fas fa-caret-down"
                                        onClick={toggle}
                                        style={{
                                            transform:
                                                "rotateX(" +
                                                (clickcourse2 ? "180" : "0") +
                                                "deg)",
                                        }}
                                    ></i>{" "}
                                </div>
                            </Link>
                            {clickcourse2 ? (
                                <div className="container" data-aos="fade-in">
                                    <Link
                                        to="/iitbombay"
                                        onClick={toggle}
                                        className="row d-flex justify-content-center p-4 btn-lg btn-light white"
                                        style={{
                                            borderTopLeftRadius: "0px",
                                            borderStyle: "0px",
                                            borderTopRightRadius: "0px",
                                            textDecoration: "none",
                                            color: "#000",
                                            textTransform: "none",
                                        }}
                                    >
                                        Training @ IIT Bombay
                                    </Link>
                                    <Link
                                        to="/masterclass-standalone/6145c73b9467cb8afc652b0f"
                                        className="row d-flex justify-content-center p-4 btn-lg btn-light white"
                                        style={{
                                            borderTopLeftRadius: "0px",
                                            borderStyle: "0px",
                                            borderTopRightRadius: "0px",
                                            textDecoration: "none",
                                            color: "#000",
                                            textTransform: "none",
                                        }}
                                        onClick={toggle}
                                    >
                                        MasterClass
                                    </Link>
                                    <Link
                                        onClick={() => {
                                            closeMobileMenu();
                                            toggle();
                                        }}
                                        to="/company-specific/61c598567a4a44b80a3e4cb9"
                                        className="row d-flex justify-content-center p-4 btn-lg btn-light white"
                                        style={{
                                            borderTopLeftRadius: "0px",
                                            borderStyle: "0px",
                                            borderTopRightRadius: "0px",
                                            textDecoration: "none",
                                            color: "#000",
                                            textTransform: "none",
                                        }}
                                    >
                                        Wipro
                                    </Link>
                                    <Link
                                        to="/company-specific/616690234d0ae87be0c5c062"
                                        className="row d-flex justify-content-center p-4 btn-lg btn-light white"
                                        style={{
                                            borderTopLeftRadius: "0px",
                                            borderStyle: "0px",
                                            borderTopRightRadius: "0px",
                                            textDecoration: "none",
                                            color: "#000",
                                            textTransform: "none",
                                        }}
                                        onClick={toggle}
                                    >
                                        Capgemini
                                    </Link>
                                </div>
                            ) : (
                                <></>
                            )}
                            {/* <div>
                <Link
                  to="/tcs"
                  className="row d-flex justify-content-center p-4 btn-lg btn-white text-light"
                  style={{ fontWeight: "bolder", textDecoration: "none" }}
                >
                  TCS NQT
                </Link>
              </div> */}

                            <Link
                                className="row d-flex justify-content-center font-weight-bold btn-lg btn-white text-light"
                                style={{
                                    fontWeight: "bolder",
                                    textDecoration: "none",
                                    textAlign: "center",
                                }}
                                onClick={() => setclickcourse3(!clickcourse3)}
                            >
                                <div
                                    className="col-sm-12 pt-2"
                                    style={{
                                        marginBottom: "5px",
                                        marginLeft: "8px",
                                    }}
                                >
                                    COLLEGES{" "}
                                    <i
                                        id="COURSES"
                                        className="fas fa-caret-down"
                                        style={{
                                            transform:
                                                "rotateX(" +
                                                (clickcourse3 ? "180" : "0") +
                                                "deg)",
                                        }}
                                        onClick={toggle}
                                    ></i>{" "}
                                </div>
                            </Link>
                            {clickcourse3 == true ? (
                                <div className="container" data-aos="fade-in">
                                    <Link
                                        to="/business"
                                        className="row d-flex justify-content-center p-4 btn-lg btn-light white"
                                        style={{
                                            borderTopLeftRadius: "0px",
                                            borderStyle: "0px",
                                            borderTopRightRadius: "0px",
                                            textDecoration: "none",
                                            color: "#000",
                                        }}
                                        onClick={toggle}
                                    >
                                        BUSINESS
                                    </Link>
                                    {iitbombaystudent === true ? (
                                        <Link
                                            to="/mycourse/615241263e86f30705c35a2e/"
                                            className="row d-flex justify-content-center p-4 btn-lg btn-light white"
                                            style={{
                                                borderTopLeftRadius: "0px",
                                                borderStyle: "0px",
                                                borderTopRightRadius: "0px",
                                                textDecoration: "none",
                                                color: "#000",
                                            }}
                                            onClick={toggle}
                                        >
                                            IITBombay COURSE
                                        </Link>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            ) : (
                                <></>
                            )}

                            <div>
                                <Link
                                    to="/jobs-for-freshers"
                                    className="row d-flex justify-content-center p-4 btn-lg btn-white text-light"
                                    style={{
                                        fontWeight: "bolder",
                                        textDecoration: "none",
                                    }}
                                >
                                    <div className="d-flex justify-content-center">
                                        <span style={{}}>Job/Internship</span>
                                    </div>
                                </Link>
                            </div>
                            {loggedIn && (
                                <div>
                                    <Link
                                        to="/profile"
                                        className="row d-flex justify-content-center p-4 btn-lg btn-white text-light"
                                        style={{
                                            fontWeight: "bolder",
                                            textDecoration: "none",
                                        }}
                                        onClick={toggle}
                                    >
                                        Classroom
                                    </Link>
                                </div>
                            )}
                            <div>
                                <Link
                                    to="/blog"
                                    className="row d-flex justify-content-center p-4 btn-lg btn-white text-light"
                                    style={{
                                        fontWeight: "bolder",
                                        textDecoration: "none",
                                    }}
                                    onClick={toggle}
                                >
                                    Blog
                                </Link>
                            </div>
                            {token === false ? (
                                <>
                                    <div>
                                        <Link
                                            to="/login"
                                            onClick={() => {
                                                setInterval(() => {
                                                    window.location.reload();
                                                }, 500);
                                                handlehamclick();
                                                toggle();
                                            }}
                                            style={{
                                                fontWeight: "bolder",
                                                textDecoration: "none",
                                            }}
                                            className="row d-flex justify-content-center p-4 btn-lg btn-white text-light"
                                        >
                                            LOGIN
                                        </Link>
                                    </div>
                                </>
                            ) : (
                                <div className="container">
                                    <LoggedInComponentMobile />
                                </div>
                            )}
                            <br />
                            <br />
                            <div className="row">
                                <div
                                    className="col-4"
                                    style={{ textAlign: "center" }}
                                >
                                    {" "}
                                    <a href="https://www.youtube.com/channel/UCJcAgJufyawKS_pQN34C9yQ">
                                        <img
                                            src="https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644088277059_youtube.jpg"
                                            alt="youtube link"
                                            style={{
                                                borderRadius: "50%",
                                                width: "40px",
                                                height: "40px",
                                            }}
                                            onClick={toggle}
                                        />{" "}
                                    </a>
                                </div>
                                <div
                                    className="col-4"
                                    style={{ textAlign: "center" }}
                                >
                                    {" "}
                                    <a href="https://www.linkedin.com/company/cantileverlabs/?originalSubdomain=in">
                                        <img
                                            src="https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644088375940_linkedin.jpg"
                                            alt="Linked link"
                                            style={{
                                                borderRadius: "50%",
                                                width: "40px",
                                                height: "40px",
                                            }}
                                            onClick={toggle}
                                        />{" "}
                                    </a>
                                </div>
                                <div
                                    className="col-4"
                                    style={{ textAlign: "center" }}
                                >
                                    {" "}
                                    <a href="https://www.instagram.com/cantilever_labs/?hl=en">
                                        <img
                                            src="https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644088467415_instagram.jpg"
                                            alt="Instagram link"
                                            style={{
                                                borderRadius: "50%",
                                                width: "40px",
                                                height: "40px",
                                            }}
                                            onClick={toggle}
                                        />{" "}
                                    </a>
                                </div>
                            </div>
                        </ul>
                    </nav>
                </>
            ) : (
                <div
                    style={{
                        position: "sticky",
                        top: "0px",
                        zIndex: "1",
                        width: "100%",
                    }}
                >
                    {/* <JoinTelegramBanner /> */}
                    <nav id="newnavbar" className="navbar ">
                        <Link
                            exact
                            to="/"
                            className="navbar-logo"
                            style={{
                                right: matches ? "10px" : null,
                                bottom: matches == false && "50%",
                            }}
                            onClick={toggle}
                        >
                            <img
                                // style={{ transform: "translateY(30%)" }}
                                id="mainlogo"
                                style={{
                                    width:
                                        matches == true
                                            ? matches_small === true
                                                ? "190px"
                                                : "200px"
                                            : "150px",
                                    transform: "translateY(30%)",
                                }}
                                src="https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644088580619_cantileverlogoblack.png"
                                alt=""
                            />
                        </Link>
                        <ul
                            style={{
                                paddingLeft: matches == false ? "0px" : null,
                                marginRight: "7%",
                                left: "0",
                                display: "flex",
                            }}
                            className={click ? "nav-menu active" : "nav-menu"}
                        >
                            <li
                                className="nav-item"
                                onMouseEnter={() => {
                                    setcoursesrotation(false);
                                    setdropdown(true);
                                }}
                                onMouseLeave={() => setdropdown(false)}
                            >
                                <span>
                                    <NavLink
                                        to="#"
                                        id="nav-links"
                                        activeClassName="selected"
                                        isActive={() =>
                                            [
                                                "/cpcourse",
                                                "/newcoursepage",
                                                "/tcs",
                                                "/interview-preparation-bundle",
                                            ].includes(location.pathname)
                                        }
                                        variant="success"
                                    >
                                        <span
                                            id="course"
                                            style={{ textAlign: "center" }}
                                        >
                                            Course{" "}
                                            <i
                                                style={{
                                                    transform:
                                                        dropdown == true
                                                            ? "rotate(180deg)"
                                                            : coursesrotation ==
                                                                  false &&
                                                              "rotate(0deg)",
                                                    animation:
                                                        dropdown == true
                                                            ? "rotateNew 0.3s linear"
                                                            : coursesrotation ==
                                                                  false &&
                                                              "rotateBack 0.3s linear",
                                                }}
                                                className={`fas fa-caret-down rotate`}
                                            ></i>
                                        </span>
                                    </NavLink>
                                </span>
                                {dropdown === true ? (
                                    <>
                                        <DropdownComp />
                                    </>
                                ) : null}
                            </li>
                            <li
                                className="nav-item"
                                onMouseEnter={() => {
                                    setcoursesrotation2(false);
                                    setdropdown2(true);
                                }}
                                onMouseLeave={() => setdropdown2(false)}
                            >
                                <span>
                                    <NavLink
                                        to="#"
                                        id="nav-links"
                                        activeClassName="selected"
                                        isActive={() =>
                                            [
                                                "/masterclass-standalone/6145c73b9467cb8afc652b0f",
                                                "/iitbombay",
                                                "/company-specific/616690234d0ae87be0c5c062",
                                                "/company-specific/6179424a8b184861a7b937eb",
                                            ].includes(location.pathname)
                                        }
                                        variant="success"
                                    >
                                        <span
                                            id="course"
                                            style={{ textAlign: "center" }}
                                        >
                                            Free Resources{" "}
                                            <i
                                                style={{
                                                    transform:
                                                        dropdown2 == true
                                                            ? "rotate(180deg)"
                                                            : coursesrotation2 ==
                                                                  false &&
                                                              "rotate(0deg)",
                                                    animation:
                                                        dropdown2 == true
                                                            ? "rotateNew 0.3s linear"
                                                            : coursesrotation2 ==
                                                                  false &&
                                                              "rotateBack 0.3s linear",
                                                }}
                                                className={`fas fa-caret-down rotate`}
                                            ></i>
                                        </span>
                                    </NavLink>
                                </span>
                                {dropdown2 ? (
                                    <>
                                        <DropdownComp2 />
                                    </>
                                ) : null}
                            </li>
                            <li
                                className="nav-item"
                                onMouseEnter={() => {
                                    setcoursesrotation2(false);
                                    setdropdown3(true);
                                }}
                                onMouseLeave={() => setdropdown3(false)}
                            >
                                <span>
                                    <NavLink
                                        to="#"
                                        id="nav-links"
                                        activeClassName="selected"
                                        isActive={() =>
                                            ["/business"].includes(
                                                location.pathname,
                                            )
                                        }
                                        variant="success"
                                    >
                                        <span
                                            id="course"
                                            style={{ textAlign: "center" }}
                                        >
                                            For Colleges
                                            <i
                                                style={{
                                                    transform:
                                                        dropdown3 == true
                                                            ? "rotate(180deg)"
                                                            : coursesrotation2 ==
                                                                  false &&
                                                              "rotate(0deg)",
                                                    animation:
                                                        dropdown3 == true
                                                            ? "rotateNew 0.3s linear"
                                                            : coursesrotation3 ==
                                                                  false &&
                                                              "rotateBack 0.3s linear",
                                                }}
                                                className={`fas fa-caret-down rotate`}
                                            ></i>
                                        </span>
                                    </NavLink>
                                </span>
                                {dropdown3 ? (
                                    <>
                                        <DropdownComp3
                                            iitbs={iitbombaystudent}
                                        />
                                    </>
                                ) : null}
                            </li>
                            {/* <li
                                onClick={() =>
                                    setJobpostselected(!jobpostselected)
                                }
                                className="nav-item"
                            >
                                <span>
                                    <NavLink
                                        id="nav-links"
                                        exact
                                        to="/jobs-for-freshers"
                                        activeClassName="selectedjobpostnav"
                                    >
                                        Jobs/Internships
                                    </NavLink>
                                </span>
                            </li> */}
                            <li className="nav-item">
                                <span>
                                    <NavLink
                                        exact
                                        to="/blog"
                                        id="nav-links"
                                        activeClassName="selected"
                                    >
                                        Blog
                                    </NavLink>
                                </span>
                            </li>
                            {loggedIn && (
                                <NavLink
                                    to="/profile"
                                    id="new-classroom-btn"
                                    className=" custom-btn-new btn-7 "
                                >
                                    <span>Classroom</span>
                                </NavLink>
                            )}
                            <li
                                className="nav-item"
                                style={{ marginLeft: "10px" }}
                            >
                                {loggedIn ? (
                                    <>
                                        <Link
                                            to="/"
                                            onClick={e => {
                                                handleClick(e);
                                                closeMobileMenu();
                                            }}
                                            onMouseEnter={() => {
                                                setrotation(false);
                                                setLogoutDropdown(true);
                                            }}
                                            onMouseLeave={() =>
                                                setLogoutDropdown(false)
                                            }
                                            activeClassName="selected"
                                            style={{
                                                textDecorationLine: "none",
                                                marginRight: "15px",
                                                fontWeight: "lighter",
                                            }}
                                            className=" text-dark"
                                        >
                                            <Link to="/" id="nav-link">
                                                {avatar !== "" ? (
                                                    <Avatar
                                                        name={name}
                                                        color={color}
                                                        src={avatar}
                                                        size="40"
                                                        round={true}
                                                        style={{
                                                            objectFit:
                                                                "contain",
                                                        }}
                                                    />
                                                ) : (
                                                    <Avatar
                                                        name={name}
                                                        color={color}
                                                        size="40"
                                                        round="20px"
                                                        textSizeRatio={1.75}
                                                        style={{
                                                            borderRadius: "50%",
                                                        }}
                                                    />
                                                )}
                                            </Link>
                                            {LogoutDropDown == true ? (
                                                <div
                                                    style={{
                                                        transform:
                                                            "translate(50px,-10px)",
                                                        marginLeft: "10px",
                                                        position: "absolute",
                                                    }}
                                                >
                                                    <DropdownComp
                                                        admin={isAdmin}
                                                        props="logged in"
                                                        token={token}
                                                        name={firstName}
                                                        LogoutMethd={() =>
                                                            logout()
                                                        }
                                                        user_id={id}
                                                        data={data}
                                                    />
                                                </div>
                                            ) : null}
                                        </Link>
                                    </>
                                ) : (
                                    <>
                                        <NavLink id="login" to="/login">
                                            <span
                                                className="login-class"
                                                style={{
                                                    textDecorationLine: "none",
                                                    marginRight: "15px",
                                                    bottom: "45px",
                                                    fontSize: "1rem",
                                                    border: "none",
                                                    borderRadius: "5px",
                                                    fontWeight: "600",
                                                    height: "42px",
                                                    padding: "14px 15px",
                                                    margin: "8px 5px",
                                                }}
                                            >
                                                Login
                                            </span>
                                        </NavLink>
                                    </>
                                )}
                            </li>
                        </ul>
                    </nav>
                </div>
            )}
        </>
    );
}

export default Navbar;

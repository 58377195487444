
import React from 'react'
import styles from '../Assets/css/personalinfo.module.css'
import cantileverlabslogo from '../Assets/img/cantileverlabslogo.png';
import vector5 from '../Assets/img/vector5.png'
import Navbar from '../components/Navbar';
import arrow1 from '../Assets/img/arrow1.png'
import youtubeMasterClass from '../Assets/img/YoutubeMasterClass.png';
import linkedinMasterClass from '../Assets/img/LinkedinMasterClass.png';
import facebookMasterClass from '../Assets/img/FacebookMasterclass.png';
import instaMasterClass from '../Assets/img/InstaMasterClass.png';

import { useState } from 'react';
const Personal = (props) => {
    const isFirefox = typeof InstallTrigger !== 'undefined';
   // const [error ,seterror] = useState([])
    const {firstName,Email,PhoneNumber,currentCity,setfirstName,setEmail,setPhoneNumber,setCurrentCity} = props.values
    var error = "";
    const [errors,seterror] = useState([])

    function CheckDetails(){
        seterror([[]])
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        var Re = /(\+91)?(-)?\s*?(91)?\s*?(\d{3})-?\s*?(\d{3})-?\s*?(\d{4})/

        if(Email !==""){
        if(re.test(String(Email)) ===false){
            seterror(errors=>[...errors,["Enter your email properly"]])
         }
        }
        if(Email === ""){
           // error+="Please enter your email/n"
            seterror(errors=>[...errors,["Please enter your email"]])
        }
        if(firstName === ""){
       //     seterror(...error,["Enter your first name properly"])
          //   error +="Enter your first Name properly/n";
          seterror(errors=>[...errors,["Please enter your first name"]])
            }
            if(PhoneNumber!==""){

                if (Re.test(String(PhoneNumber)) === false || PhoneNumber.length > 10) {

                    seterror(errors=>[...errors,["Enter your correct phone Number"]])
                }
            }
            if(PhoneNumber===""){
                seterror(errors=>[...errors,["Enter your phone Number"]])
            }
            if(currentCity === ""){
            //    error += "Enter the city/n";
                seterror(errors=>[...errors,["Please Enter the city"]])
            }

            if(currentCity!=="" && Email!=="" && firstName!=="" && PhoneNumber!=="" && PhoneNumber.length <= 10)
            {
                if(Re.test(String(PhoneNumber)) === true && PhoneNumber!=="" ){
									//    seterror(...error,["Enter your first name properly"])
									//  error +="Enter your proper phone number/n";
									if (re.test(String(Email)) === true) {
										seterror([[]]);
										return true;
									}
								}
            }else{

            }
    }


    return (
        <div className="container-fluid" style={{ paddingLeft: "0px", paddingRight: "0px" }} >
        <div class="container-fluid  justify-content-center">
        <div class={styles.formhdJ + " d-flex justify-content-center my-5 "} style={{paddingTop:"5%"}} >
            <img class="mr-3" src={vector5} alt=""/>
            <p >{props.Heading}</p>
        </div>

        <div class="container-fluid justify-content-center mb-3">
            <div class="row justify-content-center ">
                <div class={"col-xl-3 col-lg-3 col-md-5 col-sm-5 py-5 px-5 " + styles.form1J + " " + styles.purpleformJ}  >
                    {/* styles.plaintextJ normal text hain */}
                    <div className="row">
                        <p style={{fontSize:"1.2rem"}} className={styles.plaintextJ +  " p-0"}> {props.Heading}</p>
                    </div>
                    <div className="row">
                    <div class="mb-0 p-0">
												{/* 21st June, 2021  5:00PM - 6:00PM */}
                                           {  props.Date===undefined  ? <> {props.List.map((item,i)=>{
             										 return(
												<li key={i} style={{fontSize:"0.8rem",display: "block"}}>	&#10004; {item}</li>
													  )})} </>: <> 
                                           <span>  {props.Date} </span> <br />
                                           <span>  {props.Time} </span>
                                        <hr  style={{height:"2px",width:"70%"}}/>
                                            <span>By <b> { props.ByWhom} </b></span>
                                            <br />
                                            <span style={{ fontSize: "0.7rem" }}>{props.Who}</span>
                                            <br />
                                            <hr  style={{height:"2px",width:"70%"}}/>
                                    </>}
                                </div>
                                


                    </div>
                    {/* <div className="row">
                        <hr  style={{height:"2px",width:"70%"}}/>
                    </div> */}

                    {/* <div className="row">
                        <p style={{fontSize:"1rem"}}>by Vivek Jhaver</p>
                        <p style={{fontSize:"0.8rem"}}>SDE-2 Amazon</p>
                    </div>
                    <hr style={{height:"2px",width:"70%"}} />

                    <div className="row mb-2" >
                    <div style={{marginTop:"50%",marginLeft:"0px",padding:"0px"}} class={styles.formlogoJ + " justify-content-center"}>
                        <img src={cantileverlabslogo} alt=""/>
                    </div>
                    </div> */}
                    
                    <br />
                    <div className="row " style={{width:"100%"}} >
                        <div className="col-lg-3">
                            <a href="https://www.instagram.com/cantilever_labs/?hl=en"><img src={instaMasterClass} alt="" /></a>
                        </div>
                        <div className="col-lg-3">
                            <a href="https://www.facebook.com/Cantilever-labs-102916474752041/"><img src={facebookMasterClass} alt="" /></a>
                        </div>
                        <div className="col-lg-3">
                            <a href="https://www.linkedin.com/company/cantileverlabs/"><img src={linkedinMasterClass} alt=""  /></a>
                        </div>
                        <div className="col-lg-3">
                            <a href="https://www.youtube.com/channel/UCHSggA4W_L0nSzWrf_lnZpw"><img src={youtubeMasterClass} alt="" /></a>
                        </div>
                    </div>
                </div>

                <div style={{borderTopRightRadius:"5%",borderBottomRightRadius:"5%",backgroundColor:"white",boxShadow:"22px 40px 50px 43px rgba(120, 120, 120, 0.25)"}} class={"col-xl-6 col-lg-6 col-md-7 col-sm-7 " + styles.form2J + styles.secondpartformJ}>
                    <div class="mx-5">
                        <div class={styles.formhd2J + " mt-5"}>
                            <h2>Personal information</h2>
                           <div style={{width:"40%",backgroundColor:"black",height:"2px"}}></div>
                        </div>
                            <br />

                        <form class={styles.whiteformJ}>
                            <div>
                                <span class={styles.boldformTextJ}>First Name</span>
                                <br/>
                                <input class={styles.formcontrol1} value={firstName} onChange={(e)=>setfirstName(e.target.value)} type="text" name="name" placeholder="John"/>
                            </div>
                            <br/>
                            <div class="mt-1">
                                <span class={styles.boldformTextJ}>Email</span>
                                <br/>
                                <input class={styles.formcontrol1} value={Email} onChange={(e)=>setEmail(e.target.value)} type="email" name="email" placeholder="xyz@gmail.com"/>
                            </div>
                            <br/>
                            <div class="mt-1">
                                <span class={styles.boldformTextJ}>Phone Number</span>
                                <br/>
                                <input value={PhoneNumber} onChange={(e)=>setPhoneNumber(e.target.value)} class={styles.formcontrol1 + " " + styles.numInput}  type={isFirefox===true? "numeric":"number"} name="Phone" placeholder="Do not put country code"  />
                            </div>
                            <br/>
                            <div class="mt-1">
                                <span class={styles.boldformTextJ}>Current City</span>
                                <br/>
                                <input value={currentCity} onChange={(e)=>setCurrentCity(e.target.value)} class={styles.formcontrol1} type="text" name="city" placeholder="Enter your city name"/>
                            </div>

                            <div class="my-5">


                            { <>
                           {errors.length > 1 &&   <div className="alert alert-danger" role="alert">{errors.map((value,id)=>{

                                 return (
                                     <>

                                     <p key={id}>{value}</p>

                                     </>
                                 )
                             })}  </div> } </>}

                              </div>
                            <div class={styles.leftformtextJ + " "} style={{marginTop:"5%", marginBottom: "5%"}}>
                                <img onClick={()=>{
                                    if(CheckDetails()===true){ props.Personal_to_College()}

                                }} src={arrow1} alt=""/>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
    )
}

export default Personal

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import "../Assets/css/compiler.css";
import configAxios from "../axios.config";
import { useHistory } from "react-router-dom";

const TestCases = (props: ITestCasesProps) => {
  const {
    selectedLanguagetestcase,
    courseid,
    type,
    videoid,
    setquestionsolved,
    testcases,
    selectLanguage,
    hasScannerInput
  } = props;

  const { id }: { id: string } = useParams();
  const history = useHistory();
  const solvedIndices = useSelector(({ hybridCompiler }: IStore) => {
    return hybridCompiler.solvedIndices;
  })

  // const [showcd, setshowcd] = useState<Array<number>>([]);
  const [showTestcase, setshowTestcase] = useState<Array<number>>([]);
  const [testcasesAll, setTestcasesAll] = useState<Array<{
    code?: string;
    scannerInput?: string;
    name: string;
    output: string;
  }>>([]);

  function OpenTestCase(value) {
    for (let vlu of showTestcase) {
      if (vlu === value) {
        const newarray = showTestcase.filter((currentvalues) => {
          return vlu !== currentvalues;
        });
        setshowTestcase(newarray);
        return;
      }
    }
    setshowTestcase((prev) => [...prev, value]);
    return;
  }

  function CheckTestCaseOpen(showTestcase, index) {
    for (var value of showTestcase) {
      if (value === index) {
        return true;
      }
    }
  }

  useEffect(() => {
    if (!type) return;
    if (!videoid) return;
    if (!selectedLanguagetestcase) return;


    if (solvedIndices[videoid].length === selectedLanguagetestcase.length) {
      if (!courseid) return;
      // SolvedTheQuestionCorrectly();
      if (type !== "exam") {
        let token: string | null = localStorage.getItem("token")
        if (!token) {
          history.push('/login');
          alert("Please login to attempt the exam");
          return;
        }
        configAxios(token)
          .post<{ message: string }>(`/compiler-array/${courseid}/${id}`)
          .then(res => {
            if (res.status === 201) {
              alert(res.data.message);
            }
          })

      }
      if (type === "exam") {
        setquestionsolved((prev) => [...prev, videoid]);
      }
    }

    if (type === "exam") {
      // RemoveAndUpdate(videoid, solvedIndices.length);
    }
  }, [history, courseid, id, solvedIndices, selectedLanguagetestcase, videoid, type]);

  useEffect(() => {
    // console.log(testcases);
    // console.log(selectLanguage);
    const array = testcases?.filter((value) => {
      if (value[0].language === selectLanguage.toLowerCase()) {
        return value;
      }
    });
    console.log(array);
    if (array.length !== 0) {
      setTestcasesAll(array[0][1]);
    }
  }, [testcases, selectLanguage]);

  // useEffect(() => {
  //   if (!testcasesAll || !output) return;

  //   let res = String(output).replace(/\n/g, "");
  //   testcasesAll.forEach((val, i) => {
  //     const newn = String(val?.code);
  //     if (newn === res) {
  //       setshowcd((prev) => [...prev, i]);
  //     }
  //   });
  // }, [testcasesAll, output]);


  return (
    <>
      <div className="mb-2 test-case-title">Your Test cases </div>
      {testcasesAll?.map((value, index) => {
        return (
          <div
            className="d-flex flex-column"
            onClick={() => {
              OpenTestCase(index);
            }}
            style={{ whiteSpace: "pre-wrap", cursor: "pointer" }}
            key={index}
          >
            <div className="d-flex flex-row justify-content-between test-case">
              <span> {value.name} </span>
              {solvedIndices[videoid] && solvedIndices[videoid].includes(index) ? (
                <span style={{ transform: "scale(1.4)" }}>✅</span>
              ) : (
                <span style={{ transform: "scalte(1.4)" }}>
                  <i className="fas fa-times-circle" style={{ color: "red" }}></i>
                </span>
              )}
            </div>
            {CheckTestCaseOpen(showTestcase, index) && (
              <>
                <div className="py-5" style={{ background: "black" }}>
                  <code style={{ color: "white", whiteSpace: "pre-wrap" }} className="test-case-expected-output">
                    {" "}
                    Expected Output = {"\n"}{value.output}{" "}
                  </code>{" "}
                  <br />
                  <br />
                  <div className="px-3">
                    ------------------------------------------------ <br />
                    <code style={{ whiteSpace: "pre-wrap", color: "white" }} className="test-case-driver-code">
                      {/* Driver code: <br />
                      {value?.code} */}
                      {
                        hasScannerInput ? (
                          <>
                            Scanner Input: <br />
                            {value.scannerInput}
                          </>
                        ) : (
                          <>
                            Driver code: <br />
                            {value.code}
                          </>
                        )
                      }
                    </code>
                    <br />
                    ------------------------------------------------
                  </div>
                </div>
              </>
            )}
          </div>
        );
      })}
    </>
  );
};

export default TestCases;

/**
 *
 * @param {String} videoId
 */
export const changeVideoId = (videoId) => {
  return {
    type: "dashboard/videoId",
    payload: videoId,
  };
};

/**
 *
 * @param {String} title
 * @param {String} description
 * @param {String} profile_pic
 */
export const changeSelectedQuestion = (
  title,
  description,
  profile_pic,
  questionId
) => {
  return {
    type: "dashboard/selectedQuestion",
    payload: { title, description, profile_pic, questionId },
  };
};

export const codingAttemptsLeft = (data) => {
  return {
    type: "dashboard/codingAttemptsLeft",
    payload: data,
  };
};

export const quizAttemptsLeft = (data) => {
  return {
    type: "dashboard/quizAttemptsLeft",
    payload: data,
  };
};
/**
 *
 * @param {String} search
 */
export const stringSearch = (search) => {
  return {
    type: "dashboard/stringSearch",
    payload: search,
  };
};

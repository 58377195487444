import card1 from "../../Assets/img/Blogs/Blog1.png";
import card from "../../Assets/img/Blogs/blog_card_black.png";
import Blog_img1 from "../../Assets/img/Blogs/blog_img1.png";
import Blogs from "../../Assets/img/Blogs/blogs.png";
import styles from "../../Assets/css/blog.module.css";
import { useEffect, useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import useMedia from "../../componentsofcourse/useMedia";
import Footer from "../Footer";
import lodash from "lodash";
import ContentLoader from "react-content-loader";
import { BASE_URL } from "../../env";
// const BASE_URL = "http://localhost:5000";

export function SampleBlogs() {
  const [Blogs, setBlogs] = useState([]);

  const matches = useMedia("(min-width : 768px )");

  function strip_html_tags(str) {
    if (str === null || str === "") return false;
    else str = str.toString();
    return str.replace(/<[^>]*>/g, "");
  }

  useEffect(() => {
    fetch(BASE_URL + "/getAllBlogs")
      .then((response) => response.json())
      .then((data) => {
        setBlogs([]);
        for (var i = 0; i < (matches === true ? 4 : 3); i++) {
          let j = Math.floor(Math.random() * 10);
          if (j < data.blogs.length) {
            setBlogs((x) => {
              return [...x, data.blogs[j]];
            });
          } else {
            i = i - 1;
          }
        }
      })
      .catch((err) => {});
  }, [matches]);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "rgb(242, 247, 251)",
        paddingTop: "5%",
        paddingBottom: "5%",
      }}
    >
      {Blogs.map((item, index) => {
        return index == 0 ? (
          <>
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto">
                <Link
                  to={{
                    pathname: `/blogs/${item.newid}`,
                    state: {
                      data: item,
                    },
                  }}
                  style={{ textDecoration: "none" }}
                >
                  <div
                    className="rounded-3"
                    style={{
                      display: "flex",
                      // background: "white",
                      //   alignItems: "center",
                      marginTop: "-20%",
                      width: "100%",
                      margin: "auto",
                      // boxShadow: "rgb(0 0 0 / 35%) 0px 8px 10px",
                    }}
                  >
                    <img
                      src={item.image}
                      alt=""
                      style={{ maxWidth: "40%", maxHeight: "100%" }}
                    />
                    <div style={{ width: "60%" }}>
                      <h4
                        style={{
                          margin: "5%",
                          color: "#1c1c1c",
                          textDecoration: "none",
                          fontWeight: "bold",
                        }}
                      >
                        {matches ? item.title : item.title.slice(0, 8)}
                        {matches ? <span></span> : <span>...</span>}
                      </h4>
                      <p style={{ margin: "5%", color: "#718096" }}>
                        {matches
                          ? item.body.slice(3, 400)
                          : item.body.slice(3, 100)}
                        .......
                      </p>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "12%",

                          marginLeft: "5%",
                          marginRight: "5%",
                        }}
                      >
                        <p style={{ color: "#718096" }}>
                          {" "}
                          {moment(item.date).format("D MMMM YYYY")}
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </>
        ) : null;
      })}

      <div
        style={{
          display: "flex",
          margin: "auto",
          //justifyContent: "space-between",
          /* align-items: center; */
          width: "80vw",
          marginTop: "5%",
          marginBottom: "5%",
          color: "black",
          justifyContent: "space-around",
          overflowX: "hidden",
          // height: "80vh",
          overflowY: "hidden",
        }}
      >
        {Blogs.map((item, index) => {
          return index !== 0 ? (
            <>
              <>
                {" "}
                <Link
                  to={{
                    pathname: `/blogs/${item.newid}`,
                    state: {
                      data: item,
                    },
                  }}
                  style={{ textDecoration: "none", color: "#1c1c1c" }}
                >
                  <div
                    className={"mx-3 my-3 card " + styles.blogcards}
                    style={{
                      background: "transparent",
                      border: "none",
                      outline: "none",
                    }}
                  >
                    <img
                      class="card-img-top"
                      src={item.image}
                      alt="Card image cap"
                    ></img>
                    <div className="card-body">
                      <div className="card-title">
                        <h5>
                          {/* {matches
                            ? item.title.slice(0, 25) + "..."
                            : item.title.slice(0, 5)}
                          {matches ? <span></span> : <span>...</span>} */}
                          {item.title}
                        </h5>
                      </div>
                      {/* <p className="card-text">
                      {matches
                        ? strip_html_tags(item.body.substring(3, 150))
                        : item.body.substring(2, 100)}
                    </p> */}
                      {/* <Link></Link> */}
                      <div className="d-flex flex-row justify-content-between">
                        <p className="text-muted">
                          {" "}
                          {moment(item.date).format("D MMMM YYYY")}
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
              </>
              {/* <div
              class="mt-3"
              style={{
                backgroundColor: "white",
                boxShadow: "rgb(0 0 0 / 35%) 0px 8px 10px",
                marginRight: index === Blogs.length - 1 ? "0" : "5%",
                marginBottom: "5%",
                padding: "2%",
              }}
            >
              <img
                style={{
                  width: "100%",
                  // height: "30vh",
                  marginBottom: "10px"
                }}
                src={item.image}
                alt=""
              />
              <div>
                <h2
                  style={{
                    fontSize: "1.5rem",
                    width: "100%",
                    // height: "10vh",
                    marginBottom: "10px",
                  }}
                >
                  {matches ? item.title : item.title.slice(0, 15)}
                  {matches ? <span></span> : <span>...</span>}
                </h2>
              </div>
              <p style={{
                color: "#718096",
                // height: "18vh"
              }}>
                {matches ? item.body.substring(3, 250) : item.body.substring(2, 100)}
                ....
              </p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginLeft: "5%",
                  marginRight: "5%",
                }}
              >
                <p style={{ color: "#718096" }}>
                  {moment(item.date).format("D MMMM YYYY")}
                </p>
                <Link
                  to={{
                    pathname: `/blogs/${item._id}/${item.title}`,
                    state: {
                      data: item,
                    },
                  }}
                >
                  <b style={{ color: "#4285F4" }}>Read More </b>
                </Link>
              </div>
            </div> */}
            </>
          ) : null;
        })}
      </div>
    </div>
  );
}

export function Eventfun() {
  const matches = useMedia("(min-width : 800px )");

  var [blogs, setBlogs] = useState([]);
  var [blog1, setBlog1] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [load, setLoad] = useState(false);

  function formatDate(date) {
    const currentMonth = date.getMonth();
    const monthString = currentMonth >= 10 ? currentMonth : `0${currentMonth}`;
    const currentDate = date.getDate();
    const dateString = currentDate >= 10 ? currentDate : `0${currentDate}`;
    return `${date.getFullYear()}-${monthString}-${currentDate}`;
  }

  function formatDates(date) {
    var day = date.getDate();
    if (day < 10) {
      day = "0" + day;
    }
    var month = date.getMonth() + 1;
    if (month < 10) {
      month = "0" + month;
    }
    var year = date.getFullYear();
    return day + "/" + month + "/" + year;
  }

  function fun() {
    let sc = moment(blog1.date).format("dddd, MMMM Do YYYY, h:mm a");
  }

  function removeHTMLTags(str) {
    return str.replace(/<[^>]*>?/gm, "");
  }

  useEffect(() => {
    setIsLoading(true);
    fetch(BASE_URL + "/getAllBlogs")
      .then((response) => response.json())
      .then((data) => {
        setBlog1(data.blogs[data.blogs.length - 1]);
        setBlogs(data.blogs);
      })
      .catch((err) => {})
      .finally(() => {
        setLoad(true);
        setIsLoading(false);
      });
  }, []);

  return (
    <div>
      {load === true ? (
        <div>
          {matches ? (
            <div>
              <div
                style={{
                  display: "table",
                  paddingRight: "2%",
                  paddingLeft: "7%",
                }}
              >
                {/* .slice(0).reverse(). */}
                {blogs.map((blog) => (
                  <Link
                    to={{
                      pathname: `/blogs/${blog.newid}`,
                      state: {
                        data: blog,
                      },
                    }}
                    style={{ color: "#1c1c1c", textDecoration: "none" }}
                  >
                    <div
                      style={{
                        float: "left",
                        width: "50%",
                        // paddingTop: "5%",
                        padding: "4%",
                      }}
                    >
                      <img
                        style={{
                          width: "90%",
                          height: "20vw",
                          borderRadius: "10px",
                        }}
                        src={blog.image}
                        alt=""
                      />
                      <h3
                        style={{
                          paddingTop: "5%",
                          fontSize: "1.8vw",
                          fontFamily: "Poppins-Bold",
                        }}
                      >
                        {/* 13 things i’d Tell Any New Travler */}
                        {blog.title}
                      </h3>
                      <hr
                        style={{
                          width: "7vw",
                          height: "3px",
                          color: "#FFA902",
                          margin: "0.1rem",
                        }}
                      />
                      <p style={{ color: "#868383" }}>
                        {moment(blog.date).format("D MMMM YYYY")}
                        {/* 10 Nov, 2020 */}
                      </p>
                      {/* <p style={{ textAlign: "justify", width: "90%" }}>
                        {`${removeHTMLTags(blog.body).substring(0, 155)} ...`}
                      </p> */}
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          ) : (
            <div
              style={{
                position: "relative",
                paddingLeft: "8%",
                paddingRight: "8%",
              }}
              className="row"
            >
              {" "}
              {blogs.map((blog) => (
                <div
                  style={{
                    padding: "2%",
                  }}
                  className=" col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6"
                >
                  <Link
                    to={{
                      pathname: `/blogs/${blog.newid}`,
                      state: {
                        data: blog,
                      },
                    }}
                  >
                    <img
                      style={{
                        height: "25vw",
                        borderRadius: "10px",
                        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                      }}
                      src={blog.image}
                      className="img-fluid blogs-img"
                    />

                    <div
                      style={{
                        height: "10vw",
                        padding: "2%",
                        paddingLeft: "4%",
                        paddingRight: "4%",
                        marginTop: "-28.1%",
                        background: "rgba(0,0,0,.6)",
                        borderRadius: "10px",
                      }}
                      // className="blogs-text"
                    >
                      <p style={{ color: "white", fontSize: "1.3vw" }}>
                        <span
                          style={{
                            fontSize: "2ch",
                            // fontSize: "1vw",
                            fontWeight: "900",
                          }}
                        >
                          {/* Effects of New Vaccine Trails */}
                          {blog.title}
                        </span>

                        <br />
                        <span onClick={fun} style={{ fontSize: "2ch" }}>
                          {/* 10 Days ago . 5min Read */}
                          {/* {blog1.date} */}
                          {/* {moment(blog1.date).format("dddd, MMMM Do YYYY, h:mm a")} */}
                          {moment(blog.date).format("MMMM Do YYYY")}
                          {/* {blog1.date.toDateString()} */}
                        </span>
                      </p>
                    </div>
                  </Link>{" "}
                </div>
              ))}
            </div>
          )}
        </div>
      ) : (
        <div>
          <ContentLoader
            speed={1}
            width={1360}
            height={900}
            viewBox="0 0 1360 900"
            backgroundColor="#f2f2f4"
            foregroundColor="#c0c0c0"
          >
            <rect x="30" y="20" rx="8" ry="8" width="200" height="200" />
            <rect x="30" y="250" rx="0" ry="0" width="200" height="18" />
            <rect x="30" y="275" rx="0" ry="0" width="120" height="20" />
            <rect x="250" y="20" rx="8" ry="8" width="200" height="200" />
            <rect x="250" y="250" rx="0" ry="0" width="200" height="18" />
            <rect x="250" y="275" rx="0" ry="0" width="120" height="20" />
            <rect x="470" y="20" rx="8" ry="8" width="200" height="200" />
            <rect x="470" y="250" rx="0" ry="0" width="200" height="18" />
            <rect x="470" y="275" rx="0" ry="0" width="120" height="20" />
            <rect x="690" y="20" rx="8" ry="8" width="200" height="200" />
            <rect x="690" y="250" rx="0" ry="0" width="200" height="18" />
            <rect x="690" y="275" rx="0" ry="0" width="120" height="20" />
            <rect x="910" y="20" rx="8" ry="8" width="200" height="200" />
          </ContentLoader>
          <span className="sr-only">Loading...</span>
        </div>
      )}
    </div>
  );
}

export function BlogTopic({ topic }) {
  const matches = useMedia("(min-width : 800px )");

  const [blogs, setBlogs] = useState([]);
  const [blog1, setBlog1] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [load, setLoad] = useState(false);

  function formatDate(date) {
    const currentMonth = date.getMonth();
    const monthString = currentMonth >= 10 ? currentMonth : `0${currentMonth}`;
    const currentDate = date.getDate();
    const dateString = currentDate >= 10 ? currentDate : `0${currentDate}`;
    return `${date.getFullYear()}-${monthString}-${currentDate}`;
  }

  function formatDates(date) {
    var day = date.getDate();
    if (day < 10) {
      day = "0" + day;
    }
    var month = date.getMonth() + 1;
    if (month < 10) {
      month = "0" + month;
    }
    var year = date.getFullYear();
    return day + "/" + month + "/" + year;
  }

  function fun() {
    let sc = moment(blog1.date).format("dddd, MMMM Do YYYY, h:mm a");
  }

  function removeHTMLTags(str) {
    return str.replace(/<[^>]*>?/gm, "");
  }

  useEffect(() => {
    setIsLoading(true);
    fetch(BASE_URL + "/getAllBlogs")
      .then((response) => response.json())
      .then((data) => {
        setBlog1(data.blogs[data.blogs.length - 1]);
        setBlogs(data.blogs);
      })
      .catch((err) => {})
      .finally(() => {
        setLoad(true);
        setIsLoading(false);
      });
  }, []);
  return (
    <div>
      {blogs.filter((blog) => blog.topic === topic).length > 0 ? (
        load === true ? (
          <div>
            {matches ? (
              <div>
                <div
                  style={{
                    display: "table",
                    paddingRight: "2%",
                    paddingLeft: "7%",
                  }}
                >
                  {blogs.map((blog) => {
                    if (blog.topic === topic) {
                      return (
                        <div
                          style={{
                            float: "left",
                            width: "50%",
                            // paddingTop: "5%",
                            padding: "4%",
                          }}
                        >
                          <img
                            style={{
                              width: "90%",
                              height: "20vw",
                              borderRadius: "10px",
                            }}
                            src={blog.image}
                            alt=""
                          />
                          <h3
                            style={{
                              paddingTop: "5%",
                              fontSize: "1.8vw",
                              fontFamily: "Poppins-Bold",
                            }}
                          >
                            {/* 13 things i’d Tell Any New Travler */}
                            {blog.title}
                          </h3>
                          <hr
                            style={{
                              width: "7vw",
                              height: "3px",
                              color: "#FFA902",
                              margin: "0.1rem",
                            }}
                          />
                          <p style={{ color: "#868383" }}>
                            {moment(blog.date).format("D MMMM YYYY")}
                            {/* 10 Nov, 2020 */}
                          </p>
                          <p style={{ textAlign: "justify", width: "90%" }}>
                            {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sed
                et donec purus viverra. Sit justo velit, eu sed sollicitudin
                tempus,{" "} */}
                            {`${removeHTMLTags(blog.body).substring(
                              0,
                              155
                            )} ...`}
                          </p>
                          <Link
                            to={{
                              pathname: `/blogs/${blog.newid}`,
                              state: {
                                data: blog,
                              },
                            }}
                          >
                            <p style={{ color: "#4285F4" }}>Read More ......</p>
                          </Link>{" "}
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            ) : (
              <div
                style={{
                  position: "relative",
                  paddingLeft: "8%",
                  paddingRight: "8%",
                }}
                className="row"
              >
                {" "}
                {blogs.map((blog) => (
                  <div
                    style={{
                      padding: "2%",
                    }}
                    className=" col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6"
                  >
                    <Link
                      to={{
                        pathname: `/blogs/${blog.newid}`,
                        state: {
                          data: blog,
                        },
                      }}
                    >
                      <img
                        style={{
                          height: "25vw",
                          borderRadius: "10px",
                          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                        }}
                        src={blog.image}
                        className="img-fluid blogs-img"
                      />

                      <div
                        style={{
                          height: "10vw",
                          padding: "2%",
                          paddingLeft: "4%",
                          paddingRight: "4%",
                          marginTop: "-28.1%",
                          background: "rgba(0,0,0,.6)",
                          borderRadius: "10px",
                        }}
                        // className="blogs-text"
                      >
                        <p style={{ color: "white", fontSize: "1.3vw" }}>
                          <span
                            style={{
                              fontSize: "2ch",
                              // fontSize: "1vw",
                              fontWeight: "900",
                            }}
                          >
                            {/* Effects of New Vaccine Trails */}
                            {blog.title}
                          </span>

                          <br />
                          <span onClick={fun} style={{ fontSize: "2ch" }}>
                            {/* 10 Days ago . 5min Read */}
                            {/* {blog1.date} */}
                            {/* {moment(blog1.date).format("dddd, MMMM Do YYYY, h:mm a")} */}
                            {moment(blog.date).format("MMMM Do YYYY")}
                            {/* {blog1.date.toDateString()} */}
                          </span>
                        </p>
                      </div>
                    </Link>{" "}
                  </div>
                ))}
              </div>
            )}
          </div>
        ) : (
          <div>
            <ContentLoader
              speed={1}
              width={1360}
              height={900}
              viewBox="0 0 1360 900"
              backgroundColor="#f2f2f4"
              foregroundColor="#c0c0c0"
            >
              <rect x="30" y="20" rx="8" ry="8" width="200" height="200" />
              <rect x="30" y="250" rx="0" ry="0" width="200" height="18" />
              <rect x="30" y="275" rx="0" ry="0" width="120" height="20" />
              <rect x="250" y="20" rx="8" ry="8" width="200" height="200" />
              <rect x="250" y="250" rx="0" ry="0" width="200" height="18" />
              <rect x="250" y="275" rx="0" ry="0" width="120" height="20" />
              <rect x="470" y="20" rx="8" ry="8" width="200" height="200" />
              <rect x="470" y="250" rx="0" ry="0" width="200" height="18" />
              <rect x="470" y="275" rx="0" ry="0" width="120" height="20" />
              <rect x="690" y="20" rx="8" ry="8" width="200" height="200" />
              <rect x="690" y="250" rx="0" ry="0" width="200" height="18" />
              <rect x="690" y="275" rx="0" ry="0" width="120" height="20" />
              <rect x="910" y="20" rx="8" ry="8" width="200" height="200" />
            </ContentLoader>
            <span className="sr-only">Loading...</span>
          </div>
        )
      ) : (
        <marquee>
          <h1 style={{ margin: "auto", fontSize: "6vw" }}>Coming Soon!</h1>
        </marquee>
      )}
    </div>
  );
}

export function BlogMobile() {
  const matches = useMedia("(min-width : 800px )");

  const [blogs, setBlogs] = useState([]);
  const [blog1, setBlog1] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [load, setLoad] = useState(false);

  function formatDate(date) {
    const currentMonth = date.getMonth();
    const monthString = currentMonth >= 10 ? currentMonth : `0${currentMonth}`;
    const currentDate = date.getDate();
    const dateString = currentDate >= 10 ? currentDate : `0${currentDate}`;
    return `${date.getFullYear()}-${monthString}-${currentDate}`;
  }

  function formatDates(date) {
    var day = date.getDate();
    if (day < 10) {
      day = "0" + day;
    }
    var month = date.getMonth() + 1;
    if (month < 10) {
      month = "0" + month;
    }
    var year = date.getFullYear();
    return day + "/" + month + "/" + year;
  }

  function removeHTMLTags(str) {
    return str.replace(/<[^>]*>?/gm, "");
  }

  useEffect(() => {
    setIsLoading(true);
    fetch(BASE_URL + "/getAllBlogs")
      .then((response) => response.json())
      .then((data) => {
        setBlog1(data.blogs[data.blogs.length - 1]);
        setBlogs(data.blogs);
      })
      .catch((err) => {})
      .finally(() => {
        setLoad(true);
        setIsLoading(false);
      });
  }, []);
  return (
    <div>
      {load === true ? (
        <div>
          <div
            style={{
              position: "relative",
              // flexDirection: "column",
              // position: "relative",
              // paddingLeft: "8%",
              padding: "8%",
            }}
            // className="row"
          >
            {blogs.map((blog) => (
              <div
                style={{
                  paddingTop: "5%",
                }}
                // className=" col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6"
              >
                <img
                  style={{
                    width: "90%",
                    height: "28vw",
                    borderRadius: "10px",
                  }}
                  src={blog.image}
                  alt=""
                />
                <h3
                  style={{
                    paddingTop: "5%",
                    fontSize: "4vw",
                    fontFamily: "Poppins-Bold",
                  }}
                >
                  {/* 13 things i’d Tell Any New Travler */}
                  {blog.title}
                </h3>
                <hr
                  style={{
                    width: "7vw",
                    height: "3px",
                    color: "#FFA902",
                    margin: "0.1rem",
                  }}
                />
                <p style={{ color: "#868383" }}>
                  {moment(blog.date).format("D MMMM YYYY")}
                  {/* 10 Nov, 2020 */}
                </p>
                <p style={{ textAlign: "justify", width: "90%" }}>
                  {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sed
                et donec purus viverra. Sit justo velit, eu sed sollicitudin
                tempus,{" "} */}
                  {`${removeHTMLTags(blog.body).substring(0, 155)} ...`}
                </p>
                <Link
                  to={{
                    pathname: `/blogs/${blog.newid}`,
                    state: {
                      data: blog,
                    },
                  }}
                >
                  <p style={{ color: "#4285F4" }}>Read More ......</p>
                </Link>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div>
          <ContentLoader
            speed={1}
            width={1360}
            height={900}
            viewBox="0 0 1360 900"
            backgroundColor="#f2f2f4"
            foregroundColor="#c0c0c0"
          >
            <rect x="30" y="20" rx="8" ry="8" width="200" height="200" />
            <rect x="30" y="250" rx="0" ry="0" width="200" height="18" />
            <rect x="30" y="275" rx="0" ry="0" width="120" height="20" />
            <rect x="250" y="20" rx="8" ry="8" width="200" height="200" />
            <rect x="250" y="250" rx="0" ry="0" width="200" height="18" />
            <rect x="250" y="275" rx="0" ry="0" width="120" height="20" />
            <rect x="470" y="20" rx="8" ry="8" width="200" height="200" />
            <rect x="470" y="250" rx="0" ry="0" width="200" height="18" />
            <rect x="470" y="275" rx="0" ry="0" width="120" height="20" />
            <rect x="690" y="20" rx="8" ry="8" width="200" height="200" />
            <rect x="690" y="250" rx="0" ry="0" width="200" height="18" />
            <rect x="690" y="275" rx="0" ry="0" width="120" height="20" />
            <rect x="910" y="20" rx="8" ry="8" width="200" height="200" />
          </ContentLoader>
          <span className="sr-only">Loading...</span>
        </div>
      )}
    </div>
  );
}

export function BlogMobileTopic({ topic }) {
  const matches = useMedia("(min-width : 800px )");

  const [blogs, setBlogs] = useState([]);
  const [blog1, setBlog1] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [load, setLoad] = useState(false);

  function formatDate(date) {
    const currentMonth = date.getMonth();
    const monthString = currentMonth >= 10 ? currentMonth : `0${currentMonth}`;
    const currentDate = date.getDate();
    const dateString = currentDate >= 10 ? currentDate : `0${currentDate}`;
    return `${date.getFullYear()}-${monthString}-${currentDate}`;
  }

  function formatDates(date) {
    var day = date.getDate();
    if (day < 10) {
      day = "0" + day;
    }
    var month = date.getMonth() + 1;
    if (month < 10) {
      month = "0" + month;
    }
    var year = date.getFullYear();
    return day + "/" + month + "/" + year;
  }

  function removeHTMLTags(str) {
    return str.replace(/<[^>]*>?/gm, "");
  }

  useEffect(() => {
    setIsLoading(true);
    fetch(BASE_URL + "/getAllBlogs")
      .then((response) => response.json())
      .then((data) => {
        setBlog1(data.blogs[data.blogs.length - 1]);
        setBlogs(data.blogs);
      })
      .catch((err) => {})
      .finally(() => {
        setLoad(true);
        setIsLoading(false);
      });
  }, []);

  return (
    <div>
      {blogs.filter((blog) => blog.topic === topic).length > 0 ? (
        load === true ? (
          <div>
            <div
              style={{
                position: "relative",
                // flexDirection: "column",
                // position: "relative",
                // paddingLeft: "8%",
                padding: "8%",
              }}
              // className="row"
            >
              {blogs.map((blog) => {
                if (blog.topic === topic) {
                  return (
                    <div
                      style={{
                        paddingTop: "5%",
                      }}
                      // className=" col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6"
                    >
                      <img
                        style={{
                          width: "90%",
                          height: "28vw",
                          borderRadius: "10px",
                        }}
                        src={blog.image}
                        alt=""
                      />
                      <h3
                        style={{
                          paddingTop: "5%",
                          fontSize: "4vw",
                          fontFamily: "Poppins-Bold",
                        }}
                      >
                        {/* 13 things i’d Tell Any New Travler */}
                        {blog.title}
                      </h3>
                      <hr
                        style={{
                          width: "7vw",
                          height: "3px",
                          color: "#FFA902",
                          margin: "0.1rem",
                        }}
                      />
                      <p style={{ color: "#868383" }}>
                        {moment(blog.date).format("D MMMM YYYY")}
                        {/* 10 Nov, 2020 */}
                      </p>
                      <p style={{ textAlign: "justify", width: "90%" }}>
                        {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sed
                et donec purus viverra. Sit justo velit, eu sed sollicitudin
                tempus,{" "} */}
                        {`${removeHTMLTags(blog.body).substring(0, 155)} ...`}
                      </p>
                      <Link
                        to={{
                          pathname: `/blogs/${blog.newid}`,
                          state: {
                            data: blog,
                          },
                        }}
                      >
                        <p style={{ color: "#4285F4" }}>Read More ......</p>
                      </Link>
                    </div>
                  );
                }
              })}
            </div>
          </div>
        ) : (
          <div>
            <ContentLoader
              speed={1}
              width={1360}
              height={900}
              viewBox="0 0 1360 900"
              backgroundColor="#f2f2f4"
              foregroundColor="#c0c0c0"
            >
              <rect x="30" y="20" rx="8" ry="8" width="200" height="200" />
              <rect x="30" y="250" rx="0" ry="0" width="200" height="18" />
              <rect x="30" y="275" rx="0" ry="0" width="120" height="20" />
              <rect x="250" y="20" rx="8" ry="8" width="200" height="200" />
              <rect x="250" y="250" rx="0" ry="0" width="200" height="18" />
              <rect x="250" y="275" rx="0" ry="0" width="120" height="20" />
              <rect x="470" y="20" rx="8" ry="8" width="200" height="200" />
              <rect x="470" y="250" rx="0" ry="0" width="200" height="18" />
              <rect x="470" y="275" rx="0" ry="0" width="120" height="20" />
              <rect x="690" y="20" rx="8" ry="8" width="200" height="200" />
              <rect x="690" y="250" rx="0" ry="0" width="200" height="18" />
              <rect x="690" y="275" rx="0" ry="0" width="120" height="20" />
              <rect x="910" y="20" rx="8" ry="8" width="200" height="200" />
            </ContentLoader>
            <span className="sr-only">Loading...</span>
          </div>
        )
      ) : (
        <marquee>
          <h1 style={{ margin: "auto", fontSize: "6vw" }}>Coming Soon!</h1>
        </marquee>
      )}
    </div>
  );
}

export const events = [
  {
    id: 1,
    host_id: 1,
    img: (
      <div
        style={{
          position: "relative",
          paddingLeft: "8%",
          paddingRight: "8%",
        }}
        className="row"
      >
        <div
          style={{
            padding: "2%",
          }}
          className=" col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6"
        >
          <img src={Blogs} className="img-fluid blogs-img" />
          <div className="blogs-text">
            <p>
              <span style={{ fontSize: "12px", fontWeight: "900" }}>
                Effects of New Vaccine Trails
              </span>
              <br />
              <span style={{ fontSize: "10px" }}>10 Days ago . 5min Read</span>
            </p>
          </div>
        </div>
        <div
          style={{
            padding: "2%",
          }}
          className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6"
        >
          <img src={Blogs} className="img-fluid blogs-img" />
          <div className="blogs-text">
            <p>
              <span style={{ fontSize: "12px", fontWeight: "900" }}>
                2nd Wave and Its strains n Mutents
              </span>
              <br />
              <span style={{ fontSize: "10px" }}>10 Days ago . 5min Read</span>
            </p>
          </div>
        </div>
        <div
          style={{
            padding: "2%",
          }}
          className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6"
        >
          <img src={Blogs} className="img-fluid blogs-img" />
          <div className="blogs-text">
            <p>
              <span style={{ fontSize: "12px", fontWeight: "900" }}>
                Impacts of Covid on Labours (May Day Spl)
              </span>
              <br />
              <span style={{ fontSize: "10px" }}>10 Days ago . 5min Read</span>
            </p>
          </div>
        </div>
        <div
          style={{
            padding: "2%",
          }}
          className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6"
        >
          <img src={Blogs} className="img-fluid blogs-img" />
          <div className="blogs-text">
            <p>
              <span style={{ fontSize: "12px", fontWeight: "900" }}>
                Effects of New Vaccine Trails
              </span>
              <br />
              <span style={{ fontSize: "10px" }}>10 Days ago . 5min Read</span>
            </p>
          </div>
        </div>
        <div
          style={{
            padding: "2%",
          }}
          className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6"
        >
          <img src={Blogs} className="img-fluid blogs-img" />
          <div className="blogs-text">
            <p>
              <span style={{ fontSize: "12px", fontWeight: "900" }}>
                2nd Wave and Its strains n Mutents
              </span>
              <br />
              <span style={{ fontSize: "10px" }}>10 Days ago . 5min Read</span>
            </p>
          </div>
        </div>
        <div
          style={{
            padding: "2%",
          }}
          className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6"
        >
          <img src={Blogs} className="img-fluid blogs-img" />
          <div className="blogs-text">
            <p>
              <span style={{ fontSize: "12px", fontWeight: "900" }}>
                Impacts of Covid on Labours (May Day Spl)
              </span>
              <br />
              <span style={{ fontSize: "10px" }}>10 Days ago . 5min Read</span>
            </p>
          </div>
        </div>
      </div>
    ),
  },
];

export const eventsMob = [
  {
    id: 1,
    host_id: 1,
    img: (
      <div
        style={{
          display: "grid",
          alignContent: "space-around",
          justifyContent: "space-between",
        }}
        className="row"
      >
        <div style={{}} className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6">
          <img src={Blogs} className="img-fluid blogs-img" />
          <div className="blogs-text-mobile">
            <p>
              <span style={{ fontSize: "10px", fontWeight: "900" }}>
                Effects of New Vaccine Trails
              </span>
              <br />
              <span style={{ fontSize: "8px" }}>10 Days ago . 5min Read</span>
            </p>
          </div>
        </div>
        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6">
          <img src={Blogs} className="img-fluid blogs-img" />
          <div className="blogs-text-mobile">
            <p>
              <span style={{ fontSize: "10px", fontWeight: "900" }}>
                2nd Wave and Its strains n Mutents
              </span>
              <br />
              <span style={{ fontSize: "8px" }}>10 Days ago . 5min Read</span>
            </p>
          </div>
        </div>
        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6">
          <img src={Blogs} className="img-fluid blogs-img" />
          <div className="blogs-text-mobile">
            <p>
              <span style={{ fontSize: "10px", fontWeight: "900" }}>
                Impacts of Covid on Labours (May Day Spl)
              </span>
              <br />
              <span style={{ fontSize: "8px" }}>10 Days ago . 5min Read</span>
            </p>
          </div>
        </div>
        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6">
          <img src={Blogs} className="img-fluid blogs-img" />
          <div className="blogs-text-mobile">
            <p>
              <span style={{ fontSize: "10px", fontWeight: "900" }}>
                Effects of New Vaccine Trails
              </span>
              <br />
              <span style={{ fontSize: "8px" }}>10 Days ago . 5min Read</span>
            </p>
          </div>
        </div>
        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6">
          <img src={Blogs} className="img-fluid blogs-img" />
          <div className="blogs-text-mobile">
            <p>
              <span style={{ fontSize: "10px", fontWeight: "900" }}>
                2nd Wave and Its strains n Mutents
              </span>
              <br />
              <span style={{ fontSize: "8px" }}>10 Days ago . 5min Read</span>
            </p>
          </div>
        </div>
        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6">
          <img src={Blogs} className="img-fluid blogs-img" />
          <div className="blogs-text-mobile">
            <p>
              <span style={{ fontSize: "10px", fontWeight: "900" }}>
                Impacts of Covid on Labours (May Day Spl)
              </span>
              <br />
              <span style={{ fontSize: "8px" }}>10 Days ago . 5min Read</span>
            </p>
          </div>
        </div>
      </div>
    ),
  },
];

export const users = [
  {
    id: 33,
    name: "James",
  },
  {
    id: 81,
    name: "Greg",
  },
  { id: 4, name: "Raffi" },
  {
    id: 18,
    name: "Louis",
  },
  {
    id: 2,
    name: "George",
  },
];

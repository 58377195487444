import React, { useState, useEffect } from "react";
import resetImage from "../Assets/img/Rectangle 1052.png";
import icon_lock from "../Assets/img/Icon feather-lock.png";
import { BASE_URL } from "../env";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [errmsg, setErrorMessage] = useState("");
  const [message, setMessage] = useState("");
  const [msg, setMsg] = useState(false);
  const [error, setError] = useState(false);
  const sending_company_email = "support@cantileverlabs.com";
  const subject = " Reset Your Password";
  const link = "https://cantileverlabs.com/resetpassword"; //https://www.cantileverlabs.com/resetpassword
  async function handleSubmit() {
    if (email == "") {
      setError(true);
      setErrorMessage("Email is Required!");
      return;
    } else {
      setError(false);
      await fetch(BASE_URL + "/forgotpassword", {
        //BASE_URL+//forgotpassword
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email,
          link,
          sending_company_email,
          subject,
        }),
      })
        .then((res) => res.json())
        .then((data) => {

          if (data.error) {
            setError(true);
            setErrorMessage(data.error);
          } else if (data.message) {
            setMsg(true);
            setMessage(
              "A link has been sent to your Email to Reset the Password!"
            );
            document.querySelector(".input-group").style.display = "none";
          }
        });
    }
  }

  return (
    <>
      <div
        className="container-fluid background  "
        style={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          overflowX: "hidden",
          backgroundColor: "#f2f7fb",
          "@media screen and (max-width:767px)": {
            height: "100%",
            alignItems: "center",
          },
        }}
      >
        <div className="container my-auto h-100">
          <div className="row align-items-center justify-content-center my-auto h-100">
            <div className="col-lg-4 col-md-4 col-sm-12 mx-auto ">
              <img
                src={resetImage}
                alt="reset-password"
                className="my-4"
                style={{ width: "22rem" }}
              />
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 mx-auto ">
              <div className="panel panel-default">
                <div className="panel-body">
                  <div className="text-center">
                    <h3>
                      <img className="lock" src={icon_lock} />
                    </h3>
                    <h2 className="text-center">Forgot Password?</h2>
                    <p>Provide Email to Receive Reset Link</p>
                    <div className="panel-body">
                      <form className=" mx-auto d-block">
                        <div className="form-group ">
                          <div className="input-group">
                            <input
                              style={{
                                height: "50px",
                              }}
                              id="email"
                              placeholder="Email Address"
                              className="form-control"
                              type="email"
                              value={email}
                              required
                              onChange={(e) => {
                                setEmail(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                      </form>
                      <br />
                      {error === true ? (
                        <div className="form-group form-check text-white bg-danger p-3 mb-3 rounded rounded-3">
                          {errmsg}
                        </div>
                      ) : null}
                      <div className="text-center" style={{ width: "100%" }}>
                        {msg === true ? (
                          <div className="form-group form-check m-3 pl-0">
                            <div
                              className="text-dark"
                              htmlFor="exampleCheck1"
                              style={{
                                position: "relative",
                                // right: "3%",
                                left: "6%",
                                width: "75%",
                              }}
                            >
                              {message}
                            </div>
                          </div>
                        ) : (
                          <button
                            className="btn btn-lg btn-warning"
                            style={{ width: "100%", borderRadius: "5px" }}
                            onClick={() => {
                              handleSubmit();
                            }}
                            type="submit"
                          >
                            Send
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;

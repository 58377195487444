import React from "react";
import TCSBANNERPOP from "../Assets/img/TCSBANNERPOP.png";
import useMedia from "../componentsofcourse/useMedia";
import "../Assets/css/popuptcs.css";
import img from "../Assets/img/20ff.png";
const PopupAd = ({ CloseTheButton, InterestedUser, PushTheUser }) => {
  // const matches = useMedia("(min-width : 720px )");
  const matches  = useMedia("(min-width : 786px )")

  return (
    <>
      <div className="popup_box">
        <span></span>
        <span></span>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-5 popup_banner_left">
              <img src={img} className="img-fluid popup_banner_height" alt="" />
              <div className="placement_course">
                <strong >on all</strong> <br className="d-sm-block d-none" /> <br /> placement courses
              </div>
            </div>
            <div className="col-sm-7 popup_banner_right">
             { matches && <div className="popup_banner_hurry-up">hurry up</div>}
              {/* <div className="only_seat_left">
                Only <strong>100 seats</strong> left!!!
              </div> */}
              <div className="popup_banner_text">
                Connect with an expert to know more about the courses.
                <br />
                <br />
<p className="popup_banner_text" style={{color:"red"}}> Hurry Up! Only 100 seats left</p>
              </div>
              <div className="popup_banner_btns">
                <button
                  className="popup_bannet_yes_btn"
                  onClick={() => InterestedUser()}
                >
                  YES
                </button>
                <button
                  className="popup_bannet_close_btn"
                  onClick={() => {
                    CloseTheButton();
                  }}
                >
                  CLOSE
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PopupAd;


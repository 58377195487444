import cantileverLight from "../../Assets/img/cantileverlogoblack.png";
import cantilevertransparent from "../../Assets/img/CLLogo.png";
import { useEffect, useRef, useState, FC } from "react";
import Countdown from "../../components/Countdown";
import { NavLink } from "react-router-dom";
import { useParams, useHistory } from "react-router-dom";
import TestCases from "../TestCases";
import AceEditor from "react-ace";
import { BASE_URL } from "../../env";
import "../../Assets/css/compiler.css";
import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/mode-c_cpp";
import "ace-builds/src-noconflict/mode-csp";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/theme-merbivore_soft";
import "ace-builds/src-noconflict/theme-solarized_dark";
import "ace-builds/src-noconflict/theme-solarized_light";
import "ace-builds/src-noconflict/ext-language_tools";
import { useDispatch, useSelector } from "react-redux";
import { changeSolvedIndices } from "../../app/hybridExam/actions/action";
import { motion } from "framer-motion";
import HelmetComp from "../../components/HelmetComp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import configAxios, { configAxiosLambda, lambdaCompiler } from "../../axios.config";
import { faMoon, faSun } from '@fortawesome/free-solid-svg-icons';
import ReactJoyride, { STATUS, ACTIONS, EVENTS } from "react-joyride";
import SplitPane from 'split-pane-react';
import CustomInputs from "../../Pages/NewHybridExam_v2/components/CustomInputs";
import 'split-pane-react/esm/themes/default.css';

const Compiler = ({ hidenavbar }) => {
  const { id, courseid } = useParams();
  const query = new URLSearchParams(window.location.search);
  const assignmentTypeId = query.get("assignmentTypeId");
  const questionType = query.get("questionType");
  const parentExamId = query.get("parentExamId");
  const courseId = query.get("courseId");
  const history = useHistory();
  const [modes, setModes] = useState(true);
  const [resize, setResize] = useState(true);
  const [code, setcode] = useState<string>("");
  const [testcaseON, settestcaseON] = useState(false);
  const [rawOutput, setrawOutput] = useState(true);
  const [running, setrunning] = useState(true);
  const [questionName, setquestionName] = useState<string>("");
  const [loaded, setLoaded] = useState(false);
  const [sampleInput, setSamepleInput] = useState<string>("");
  const [sampleOutput, setSampleOutput] = useState<string>("");
  const [runningSubmissions, setRunningSubmissions] = useState<boolean>(false);
  const [weightTimes, setweightTimes] = useState<Array<number>>([]);
  const fontSizes = ["12px", "14px", "16px", "18px", "20px", "22px"];
  const [fontSize, setFontSize] = useState("16px");
  const [showlanguagedropdown, setshowlanguagedropdown] = useState(false);
  const [showfontsizedropdown, setfontsizedropdown] = useState(false);
  const [Languagevalues, setLaguageValues] = useState<Array<string>>([]);
  const [activeTab, setActiveTab] = useState<"question" | "solution">("question");
  const [showPopUp, setshowPopUp] = useState(false);
  const [hints, setHints] = useState<Array<string> | null>(null);
  const [question_id, setquestion_id] = useState("");
  const [displaySubmitButton, setDisplaySubmitButton] = useState(false);
  const [solutionBlurred, setSolutionBlurred] = useState<boolean>(true);
  const [leftPaneSize, setLeftPaneSize] = useState(["50%", "50%"]);
  const [rightPaneSize, setRightPaneSize] = useState(["50%", "50%"]);
  const [verticalPaneSize, setVerticalPaneSize] = useState(["50%", "50%"]);
  const [displayDemo, setDisplayDemo] = useState<boolean>(false);
  const [scannerInput, setScannerInput] = useState<boolean>(false);
  const [demoIndex, setDemoIndex] = useState<number>(0);
  const [displayCustomInput, setDisplayCustomInput] = useState<boolean>(false);
  const [customOut, setCustomOut] = useState<Array<{
    passed: boolean;
    output: string;
  }>>([]);
  const [rawoutputsol, setrawoutputsol] = useState<
    Array<{
      testcaseNo: number;
      output: string;
      passed: boolean;
      error: boolean;
    }>
  >([]);
  const [solution, setSolution] = useState<
    Array<{
      language: language_values;
      codeSolutions: Array<string>;
      _id: string;
    }>
  >([]);
  const [description, setDescription] = useState({
    __html: "First &middot; Second",
  });
  const [SampleTestCases, setSampleTestCases] = useState<
    Array<
      [
        {
          language: "java" | "c" | "python3" | "c++";
        },
        Array<{
          code: string;
          name: string;
          output: string;
        }>
      ]
    >
  >([]);
  const [questionCode, setquestionCode] = useState<
    Array<{
      language: string;
      code: string;
    }>
  >([]);
  const [selectLanguage, setSelectedLanguage] = useState<
    "Java" | "Python3" | "C++" | "C" | undefined
  >();
  const [selectedLanguageTestCases, setSelectedLanguageTestCases] = useState<
    Array<{
      code?: string;
      name: string;
      output: string;
      scannerInput?: string;
    }>
  >([]);
  const [popUpData, setPopUpData] = useState<{
    approveText: string;
    disApproveText: string;
    onApprove: () => unknown;
    onDisApprove: () => unknown;
    popUpText: string;
  }>({
    approveText: "Yes",
    disApproveText: "No",
    onApprove: () => { },
    onDisApprove: () => { },
    popUpText: "",
  });

  const dispatch = useDispatch();
  const userInput = useRef<string>("");
  const refArray = useRef<Array<number>>([]);
  const submitScoreArray = useRef<Array<number>>([]);
  const solvedIndices = useSelector(({ hybridCompiler }: IStore) => {
    return hybridCompiler.solvedIndices;
  });

  console.log(solvedIndices, "solved Indices")

  const r = document.querySelector(":root") as HTMLElement;
  function mytheme_func() {
    if (!r) return;

    r.style.setProperty("--inside-color", modes ? "#001528" : "#F6F9FC");
    r.style.setProperty(
      "--inside-solution-color",
      modes ? "#15314b" : "#FBF1D3"
    );
    r.style.setProperty("--input-color-code", modes ? "#15314b" : "#fff");
    r.style.setProperty("--border-color-code", modes ? "#02203c" : "yellow");
    r.style.setProperty("--outer-bgcolor-code", modes ? "#02203c" : "#E0E0E0");
    r.style.setProperty("--text-color", modes ? "#fff" : "#001528");
    r.style.setProperty(
      "--scrollbar-thumb",
      modes ? "rgb(247, 243, 243, 60%)" : "rgba(133, 133, 133, 0.6)"
    );
  }
  mytheme_func();

  function getLanguage(
    lang: "c" | "c++" | "java" | "python3"
  ): language_values {
    switch (lang) {
      case "java":
        return "Java";

      case "python3":
        return "Python3";

      case "c":
        return "C";

      default:
        return "C++";
    }
  }

  function onChange(newValue: string) {
    localStorage.setItem(id + "yes" + selectLanguage, newValue);
    setcode(newValue);
  }

  useEffect(() => {
    hidenavbar(true);
    return () => {
      hidenavbar(false);
    };
  }, [hidenavbar]);

  useEffect(() => {
    configAxios(localStorage.getItem("token"))
      .get<{
        success: boolean;
        questions: {
          _id: string;
          name: string;
          description: string;
          sampleInput: string;
          sampleOutput: string;
          questionCode: Array<{
            language: string;
            code: string;
          }>;
          solutions?: Array<{
            language: language_values;
            codeSolutions: Array<string>;
            _id: string;
          }>;
          hints?: Array<string>;
          questionWeigh: number;
          testcases: Array<
            [
              {
                language: "java" | "c" | "python3" | "c++";
              },
              Array<{
                code: string;
                name: string;
                output: string;
              }>
            ]
          >;
          outOfScore: number;
          category: string;
          languages: languageValues;
          createdAt: string;
          updatedAt: string;
          company: Array<unknown>;
          hasScannerInputs: boolean;
          hasPrivateTestCases: boolean;
        };
      }>(`/GetOneQuestion/${id}`)
      .then(({ data }) => {
        setquestion_id(data.questions._id);
        var objectArray = {};
        objectArray[data.questions._id] = [];
        dispatch(changeSolvedIndices(objectArray));
        setquestionName(data.questions.name);
        setDescription({ __html: data.questions.description });
        setSamepleInput(data.questions.sampleInput);
        setSampleOutput(data.questions.sampleOutput);
        setHints(data.questions.hints || null);
        setDisplaySubmitButton(data.questions.hasPrivateTestCases);
        setScannerInput(data.questions.hasScannerInputs)
        let question_solution = data.questions.solutions;
        if (question_solution) {
          setSolution(question_solution);
        }

        if (data.questions.testcases.length !== 0) {
          for (let i = 0; i < data.questions.testcases.length; i++) {
            const lang = getLanguage(data.questions.testcases[i][0].language);
            if (!Languagevalues.includes(lang)) {
              setLaguageValues((prev) => [...prev, lang]);
            }
          }
          if (!selectLanguage) {
            setSelectedLanguage(
              getLanguage(data.questions.testcases[0][0].language)
            );
          }
        }

        setSampleTestCases(data.questions.testcases);

        setquestionCode(data.questions.questionCode);
        let array: Array<number> = [];
        for (var i = 0; i < data.questions.questionWeigh; i++) {
          array.push(i);
        }
        setweightTimes(array);
        setLoaded(true);
      })
      .catch((err) => console.error(err.response.data));
  }, [id, selectLanguage]);

  useEffect(() => {
    if (!selectLanguage) return;
    for (let i = 0; i < SampleTestCases.length; i++) {
      if (SampleTestCases[i][0].language === selectLanguage.toLowerCase()) {
        setSelectedLanguageTestCases(SampleTestCases[i][1]);
      }
    }
  }, [SampleTestCases]);

  function FinishExam() {
    const totalTestCases = selectedLanguageTestCases.length;
    const solvedTestCases = solvedIndices[question_id].length;
    const totalScore = solvedTestCases * 10;
    if (questionType === "cs") {
      configAxios(localStorage.getItem("token"))
        .post("/ps/submit-coding-exam/" + id, {
          courseId: courseid,
          score: {
            totalScore: totalScore,
            testcasessolved: solvedTestCases,
            totalTestCases: totalTestCases,
            code: code,
            language: selectLanguage,
          },
        })
        .then(({ data }) => {
          alert("Your response is submitted");
          window.close();
        })
        .catch((err) => {
          if (err.response.status === 401) {
            alert("You are not logged in");
            const url = window.location.pathname + window.location.search;
            history.push(`/login?redirect=${url}`);
          } else {
            alert("Unable to submit response");
          }
        });
    } else {
      fetch(BASE_URL + "/pd-online/submit-coding-exam/" + id, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: JSON.stringify({
          examId: id,
          parentExamId: parentExamId,
          courseId: courseId,
          assignmentType: assignmentTypeId,
          score: {
            totalTestCases: totalTestCases,
            testcasessolved: solvedTestCases,
            totalScore: totalScore,
            code: code,
            language: selectLanguage,
          },
        }),
      })
        .then((response) => response.json())
        .then((result) => {
          // close the tab
          alert("Code Submitted Successfully");
          window.close();
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  }

  function ResetWholeCode() {
    if (!selectLanguage) return;
    setTimeout(() => {
      let questionsCodes = questionCode.filter((values) => {
        return values.language === selectLanguage.toLowerCase();
      });
      setcode(questionsCodes[0]?.code);
    }, 1000);
  }
  useEffect(() => {
    if (!selectLanguage) return;
    const array = SampleTestCases?.filter((value) => {
      if (value[0].language === selectLanguage.toLowerCase()) {
        return value;
      }
    });
    if (array?.length !== 0) {
      setSelectedLanguageTestCases(array[0][1]);
    }
  }, [SampleTestCases, selectLanguage]);

  useEffect(() => {
    if (!id) return;
    if (!selectLanguage) return;
    setTimeout(() => {
      let questionsCodes = questionCode.filter((values) => {
        return values.language === selectLanguage.toLowerCase();
      });

      if (!localStorage.getItem(id + "yes" + selectLanguage)) {
      }
      if (
        localStorage.getItem(id + "yes" + selectLanguage) === null ||
        localStorage.getItem(id + "yes" + selectLanguage) === undefined
      ) {
        setcode(questionsCodes[0]?.code);
      } else {
        // setcode(questionsCodes[0]?.code);
        setcode(localStorage.getItem(id + "yes" + selectLanguage) || "");
      }
    }, 200);
  }, [questionCode, selectLanguage, id]);

  const themeMode = (e) => {
    setModes((prev) => !prev);
  };

  // const style = {
  //   borderRadius: "15px",
  // };
  function DropDownStyle({ value, selectLanguage, type }) {
    return (
      <div
        onClick={() => {
          if (type === "language") {
            setSelectedLanguage(value);
            setshowlanguagedropdown(false);
          } else {
            setFontSize(value);
            setfontsizedropdown(false);
          }
        }}
        className={
          selectLanguage !== value
            ? "options-value-dropdown"
            : "options-value-dropdown selected-language-active"
        }
      >
        <span className={"single_option_dropdown"}>{value}</span>
      </div>
    );
  }

  function SubmitCodeResponse(length) {
    fetch(BASE_URL + "/createCoding", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userid: localStorage.getItem("emailID"),
        TimeTaken: "",
        MemoryTaken: "",
        CodeSent: code,
        TestCasesSolved: length,
        QuestionID: id,
      }),
    })
      .then((res) => res.json())
      .catch((data) => {
        console.error(data);
      });
  }

  function submitAndCheckCode(code: string, language: language_values) {
    setCustomOut([]);
    setrawoutputsol([]);
    setRunningSubmissions(true);
    let encoded_code = new Buffer(code).toString("base64");

    configAxiosLambda(localStorage.getItem("token") || "").post<{
      testcasesPassOrFail: Array<{
        status: "pass" | "fail";
        marks?: number;
      }>
    }>("/run-testcases", {
      questionId: id,
      language: language,
      code: encoded_code
    }).then(({ data }) => {
      setrawoutputsol((prev) => {
        submitScoreArray.current = [];
        return data.testcasesPassOrFail.map((val, index) => {
          if (val.status === "pass" && val.marks) submitScoreArray.current.push(val.marks);
          return {
            testcaseNo: index + 1,
            output: "",
            passed: val.status === "pass",
            error: false,
            private: true
          }
        })
      })
      configAxios(localStorage.getItem("token") || "").post("/difficulty-progress/submit-coding-data", {
        questionId: id,
        language,
        code,
        marks: submitScoreArray.current.reduce((a, b) => a + b, 0),
        viewedSolution: !solutionBlurred
      })
      if (submitScoreArray.current.length === data.testcasesPassOrFail.length) {
        // handle exam submission
      }
    }).catch(err => {
      console.log(err);
    }).finally(() => setRunningSubmissions(false));

  }

  async function executeCustomInput() {
    setrunning(false);
    setrawoutputsol([]);
    let lang: "c" | "cpp" | "java" | "python" = "c";

    if (selectLanguage === "C++") lang = "cpp";
    else if (selectLanguage === "Java") lang = "java";
    else if (selectLanguage === "C") lang = "c";
    else lang = "python";

    let data = {
      code: new Buffer(code).toString("base64"),
      language: lang,
      arguments: [],
      input: userInput.current
    }
    let compilerData = await lambdaCompiler.post<{ encripted_token: string, output: string }>('/compile', data).catch(({ response }) => {
      if (response.status === 400) {
        const code_output = Buffer.from(
          response.data.error,
          "base64",
        ).toString("utf8");

        setCustomOut([{
          output: code_output,
          passed: false
        }]);
      }
    }).finally(() => setrunning(true));

    if (compilerData) {

      let code_output = Buffer.from(
        compilerData.data.output,
        "base64",
      ).toString("utf8");

      setCustomOut([{
        output: code_output,
        passed: true
      }])
    }
  }

  async function execute_Code(
    testcasecode,
    expected_output,
    index,
    language: "Java" | "Python3" | "C++" | "C" | undefined,
    scannerInput: string | undefined
  ) {
    let lang: "c" | "cpp" | "java" | "python" = "c";
    setrunning(false);
    setrawOutput(true);
    settestcaseON(false);
    if (language === "C++") lang = "cpp";
    else if (language === "Java") lang = "java";
    else if (language === "C") lang = "c";
    else lang = "python";

    let encoded_code = new Buffer(testcasecode).toString("base64");
    var newdata: {
      code: string;
      language: "c" | "cpp" | "java" | "python";
      arguments: Array<any>;
      input: string;
    } = {
      code: encoded_code,
      language: lang,
      arguments: [],
      input: scannerInput || "",
    };
    console.log(newdata)
    setrunning(false);

    await lambdaCompiler
      .post("/compile", newdata)
      .then(({ data }) => {
        const code_output = Buffer.from(data.output, "base64").toString(
          "utf8"
        );
        CheckTestCase(code_output, expected_output, index);
      })
      .catch((error) => {
        if (error.response.status === 400) {
          const code_output = Buffer.from(
            error.response.data.error,
            "base64"
          ).toString("utf8");

          console.log(code_output);
          CheckTestCase(code_output, expected_output, index);
        }
      })
      .finally(() => setrunning(true));
  }

  let newArray = [];
  function CheckTestCase(
    output: string,
    expected_output: string,
    index: number
  ) {
    let foo = output.replace(/\r?\n|\r/g, "");
    let doo = String(expected_output).replace(/\r?\n|\r/g, "");
    foo = foo.replace(/ /g, "");

    let passed = false;

    if (foo === doo) {
      refArray.current.push(index);
      passed = true;
    } else {
      let newstring1 = "";
      doo = doo.replace(/ /g, "");

      for (var i = 0; i < doo.length; i++) {
        if (doo[i] === undefined) {
          continue;
        }
        if (doo[i] === " ") {
          continue;
        } else {
          newstring1 += doo[i];
        }
      }
      if (newstring1 === foo) {
        refArray.current.push(index);
        passed = true;
      }
    }
    setrawoutputsol((prev) => [
      ...prev,
      {
        testcaseNo: index,
        output,
        passed,
        error: false,
      },
    ]);
    SubmitCodeResponse(newArray.length);
    var newarr = solvedIndices;
    newarr[id] = refArray.current;
    dispatch(changeSolvedIndices(newarr));
  }

  async function RunningAllTestcases() {
    setrawoutputsol([]);
    refArray.current = [];
    for (var i = 0; i < selectedLanguageTestCases.length; i++) {
      let testcasecode_new: string = "";
      if (scannerInput) {
        testcasecode_new = code;
      } else {
        testcasecode_new = code + "\n" + selectedLanguageTestCases[i].code;
      }

      await execute_Code(
        testcasecode_new,
        selectedLanguageTestCases[i].output,
        i,
        selectLanguage,
        selectedLanguageTestCases[i].scannerInput
      );
    }
    setTimeout(() => {
      setrawOutput(true);
      settestcaseON(false);
    }, 2000);
  }
  function changeLayout() {
    setResize(prev => {
      if (prev) {
        setVerticalPaneSize(["0%", "100%"]);
        setRightPaneSize(["100%", "0%"]);
      } else {
        setVerticalPaneSize(["50%", "50%"]);
        setRightPaneSize(["50%", "50%"]);
      }

      return !prev;
    });
  }

  window.addEventListener("keydown", (event) => {
    if (event.code === "KeyV" && event.ctrlKey) {
      event.preventDefault();
    } else if (event.code === "KeyC" && event.ctrlKey) {
      event.preventDefault();
    }
  });

  return !loaded ? (
    <>
      <LoaderCompiler />
    </>
  ) : (
    <div className="maincontainer">
      <HelmetComp
        info={{
          title: `${questionName} - Cantilever Labs`,
        }}
      />
      <ReactJoyride
        steps={[
          {
            target: ".question-panel > p:nth-child(1)",
            content: "Click here to view the question",
            disableBeacon: true
          },
          {
            target: ".question_description",
            content: "Read the question description carefully. It contains the problem statement and the constraints.",
            disableBeacon: true
          },
          {
            target: ".codes",
            content: <p>Write the logic of your code here.<br /> <strong>Modifying the existing code snippet may lead to wrong output.</strong></p>,
            disableBeacon: true
          },
          {
            target: ".codes",
            content: "Need a better understanding on how the code is compiled?",
            locale: {
              next: "YES"
            },
            disableBeacon: true
          },
          {
            target: ".test-case-title + div",
            content: "Click this bar to view the entire test case code",
            disableBeacon: true
          },
          {
            target: ".test-case-title + div > div:nth-child(2) .test-case-driver-code",
            content: "The highlighted code is concatenated with the solution written above to match the desired ouput.",
            placement: "left-end",
            disableBeacon: true
          },
          {
            target: ".test-case-title + div > div:nth-child(2) .test-case-expected-output",
            content: "This is the expected output",
            placement: "left-end",
            disableBeacon: true
          },
          {
            target: ".run-btn",
            content: "Click the button to run the code",
            disableBeacon: true
          },
          {
            target: ".output-container",
            content: "Output of the code can be view here. Output in color red indicates that the test case has failed.",
            disableBeacon: true
          },
          {
            target: ".question-panel > p:nth-child(2)",
            content: "Click the button to view the solution",
            disableBeacon: true
          },
        ]}
        continuous={true}
        stepIndex={demoIndex}
        spotlightClicks={true}
        disableOverlayClose={true}
        showProgress={true}
        run={displayDemo}
        callback={(data) => {
          const { status, action, type } = data;

          if (type === EVENTS.TARGET_NOT_FOUND) {
            setDemoIndex(prev => prev - 1);
            return;
          }


          if (EVENTS.STEP_AFTER === type && ACTIONS.PREV === action) {
            // controls the index on back button
            console.log("Prev button clicked")
            setDemoIndex(prev => prev - 1);
          }
          else if (EVENTS.STEP_AFTER === type && ACTIONS.NEXT === action) {
            // controls the index on next button
            console.log("Next button clicked")
            setDemoIndex(prev => prev + 1);
          }
          else if (status === STATUS.FINISHED || status === STATUS.SKIPPED) {
            setDemoIndex(0);
            setDisplayDemo(false);
          }

          if (ACTIONS.CLOSE === action) {
            setDemoIndex(0);
            setDisplayDemo(false);
          }

        }}
      />
      {showPopUp && (
        <div
          style={{
            position: "absolute",
            zIndex: "20",
            backdropFilter: "brightness(0)",
            height: "200px",
            width: "500px",
            top: "50%",
            marginLeft: "-250px",
            marginTop: "-100px",
            left: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
            padding: "20px",
          }}
        >
          {popUpData.popUpText} &emsp;
          <button
            onClick={() => {
              popUpData.onApprove();
              setshowPopUp(false);
            }}
            className="btn btn-md btn-success"
          >
            {popUpData.approveText}
          </button>{" "}
          &emsp;
          <button
            onClick={() => {
              popUpData.onDisApprove();
              setshowPopUp(false);
            }}
            className="btn btn-md btn-danger"
          >
            {popUpData.disApproveText}
          </button>
        </div>
      )}
      <div
        style={{
          filter: showPopUp ? "blur(2px)" : "",
        }}
        // className={layout === true ? "compiler" : "compiler_two"}
        className={"compiler"}
      >
        <div className="options">
          <NavLink to="/">
            <img
              src={modes ? cantilevertransparent : cantileverLight}
              width="auto"
              height="45px"
              alt="logo"
              className="pt-1"
            />
          </NavLink>
          <div className="mid d-flex align-items-center">
            <div
              className={
                showlanguagedropdown
                  ? "select-language-code"
                  : "select-language-code tooltip-animation "
              }
              data-tooltip="Programming Language"
            >
              <p
                className="selected-language-code "
                onClick={() => setshowlanguagedropdown(!showlanguagedropdown)}
                style={{ marginBottom: "0px", width: "130px" }}
              >
                {selectLanguage}
                <i
                  style={{
                    transform:
                      showlanguagedropdown === true
                        ? "rotate(180deg)"
                        : "rotate(0deg)",
                  }}
                  className="fas fa-caret-down animation"
                ></i>
              </p>
              {showlanguagedropdown === true && (
                <div className="dropdown-value">
                  {Languagevalues.map((value) => (
                    <DropDownStyle
                      value={value}
                      selectLanguage={selectLanguage}
                      type={"language"}
                    />
                  ))}
                </div>
              )}
            </div>
            <div
              className={
                showfontsizedropdown
                  ? "select-language-code d-sm-block d-none"
                  : "select-language-code tooltip-animation d-sm-block d-none"
              }
              data-tooltip="Editor Font Size"
            >
              <p
                className="selected-language-code "
                onClick={() => setfontsizedropdown(!showfontsizedropdown)}
                style={{
                  marginBottom: "0px",
                  whiteSpace: "nowrap",
                }}
              >
                {fontSize}{" "}
                <i
                  style={{
                    transform:
                      showfontsizedropdown === true
                        ? "rotate(180deg)"
                        : "rotate(0deg)",
                  }}
                  className="fas fa-caret-down animation"
                ></i>
              </p>
              {showfontsizedropdown === true && (
                <div className="dropdown-value">
                  {fontSizes.map((value) => (
                    <DropDownStyle
                      value={value}
                      selectLanguage={fontSize}
                      type={"font"}
                    />
                  ))}
                </div>
              )}
            </div>
            <div className="d-sm-block d-none">
              <Countdown />
            </div>
          </div>
          <div className="d-flex flex-row align-items-center">
            {(assignmentTypeId || questionType) && (
              <motion.button
                onClick={() => {
                  setPopUpData({
                    approveText: "Yes",
                    disApproveText: "No",
                    onApprove: FinishExam,
                    onDisApprove: () => { },
                    popUpText: "Are you sure you want to submit the exam?",
                  });
                  setshowPopUp(true);
                }}
                whileTap={{ scale: 0.9 }}
                whileHover={{
                  scale: 1.1,
                }}
                // z index 20
                style={{
                  zIndex: "20",
                }}
                data-tooltip="Click to submit the code"
                className="submit-coding-examination px-auto"
              >
                Submit Code
              </motion.button>
            )}
            {/* TODO: Testing the demo feature on the platform */}
            {/* {
              !assignmentTypeId && !questionType && (
                <motion.button
                  onClick={() => setDisplayDemo(true)}
                  whileTap={{ scale: 0.9 }}
                  whileHover={{
                    scale: 1.1,
                  }}
                  style={{
                    zIndex: "20",
                  }}
                  data-tooltip="Get demo of the platform"
                  className="submit-coding-examination px-auto"
                >
                  Get Demo
                </motion.button>
              )
            } */}
            {/* <div className="select-language-code px-4 mx-2 mode-btn" data-tooltip="Report Bug">
              <FontAwesomeIcon icon={faBug} color={modes ? "#ffffff" : "#000000"} size="lg" />
            </div> */}
            <div className="select-language-code px-4 mx-2 mode-btn" onClick={themeMode} data-tooltip="Toggle Theme">
              {/** @ts-ignore */}
              <FontAwesomeIcon icon={modes ? faSun : faMoon} color={modes ? "#ffffff" : "#000000"} size="lg" />
            </div>
          </div>
        </div>

        <SplitPane
          className="compiler-components-container"
          sizes={verticalPaneSize}
          split="vertical"
          resizerSize={15}
          performanceMode={true}
          // @ts-ignore
          onChange={setVerticalPaneSize}
        >
          <SplitPane
            className="question"
            minSize={1}
            sizes={leftPaneSize}
            split="horizontal"
            resizerSize={15}
            // @ts-ignore
            onChange={setLeftPaneSize}
            performanceMode={true}
          >
            <div className="question-description-container">
              <div className="question-panel">
                <p className="question-panel-container">
                  <input
                    type="radio"
                    id="question"
                    name="left_pane_display"
                    value="question"
                    onChange={(e) => setActiveTab("question")}
                    checked={activeTab === "question"}
                  />
                  <label htmlFor="question" className="your-question-code">
                    Question
                  </label>
                </p>
                <p className="question-panel-container">
                  <input
                    type="radio"
                    id="solution"
                    name="left_pane_display"
                    value="solution"
                    onChange={(e) => setActiveTab("solution")}
                    checked={activeTab === "solution"}
                  />
                  <label htmlFor="solution" className="your-question-code">
                    Solution
                  </label>
                </p>
              </div>
              {activeTab === "question" ? (
                <div style={{ padding: "20px", flexGrow: 1, overflowY: "auto" }}>
                  <h4 className="question_heading">
                    {questionName}
                    {weightTimes.map((index) => {
                      return (
                        <>
                          <div className="stars">
                            <i
                              className="fas fa-star newvalue"
                              key={index}
                              style={{
                                color: "yellow",
                              }}
                            ></i>
                          </div>
                        </>
                      );
                    })}
                  </h4>
                  <p
                    className="question_description"
                    dangerouslySetInnerHTML={description}
                    style={{
                      overflowY: "auto",
                    }}
                  ></p>
                  <div className="sample-input-question">
                    <h4>Sample Input</h4>
                    <div className="input-area-question">{sampleInput}</div>
                    <h4>Sample Output</h4>
                    <div className="input-area-question">
                      {sampleOutput.replace(/ /g, "\u00A0")}
                    </div>
                  </div>
                </div>
              ) : (
                <SolutionWindow
                  solution={solution}
                  selectLanguage={selectLanguage}
                  modes={modes}
                  onGiveUp={() => {
                    setshowPopUp(true);
                    setPopUpData({
                      approveText: "Yes",
                      disApproveText: "No",
                      onApprove: () => setSolutionBlurred(false),
                      onDisApprove: () => { },
                      popUpText: "Are you sure you want to give up?",
                    });
                  }}
                  blurred={solutionBlurred}
                  hints={hints}
                />
              )}
            </div>
            <div className="test-cases-container">
              <div
                className="output_area p-0"
                style={{ width: "100%" }}
              >
                <div className="d-flex flex-row submit-code-compiler justify-content-start" style={{ gap: "3px" }}>
                  <div className="d-flex align-items-stretch">
                    <button
                      className={
                        !displayCustomInput
                          ? "selected-type-test"
                          : "unselect-type-test"
                      }
                      onClick={() => setDisplayCustomInput(false)}
                    >
                      Test Cases
                    </button>
                  </div>
                  <div>
                    {scannerInput && <div className="d-flex align-items-stretch">
                      <button
                        className={
                          displayCustomInput
                            ? "selected-type-test"
                            : "unselect-type-test"
                        }
                        onClick={() => setDisplayCustomInput(true)}
                      >
                        Custom Input
                      </button>
                    </div>}
                  </div>
                </div>
                <div className="p-2 test-case-output-container">
                  <div>
                    {/* @ts-ignore */}
                    {!displayCustomInput ? <TestCases
                      videoid={id}
                      courseid={courseid}
                      selectedLanguagetestcase={selectedLanguageTestCases}
                      selectLanguage={selectLanguage}
                      testcases={SampleTestCases}
                      hasScannerInput={scannerInput}
                    /> : <CustomInputs onChange={(input, output) => {
                      if (input) {
                        userInput.current = input;
                      }
                    }} />}
                    {/* <TestCases
                      videoid={id}
                      courseid={courseid}
                      selectedLanguagetestcase={selectedLanguageTestCases}
                      selectLanguage={selectLanguage}
                      testcases={SampleTestCases}
                      hasScannerInput={scannerInput}
                    /> */}
                  </div>
                </div>
              </div>
            </div>
          </SplitPane>
          <SplitPane
            className="question"
            sizes={rightPaneSize}
            minSize={1}
            split="horizontal"
            resizerSize={15}
            // @ts-ignore
            onChange={setRightPaneSize}
            performanceMode={true}
          >
            <div className={"code"} style={{
              display: "flex",
              flexDirection: "column",
            }}>
              <div className="run-panel d-flex justify-content-between align-items-stretch">
                <p className="your-solution-code">Your solution</p>

                <div
                  style={{
                    width: "100%",
                    justifyContent: "end",
                    gap: "1rem",
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <div
                    style={{
                      cursor: "pointer",
                      color: modes ? "#fff" : "#001528",
                    }}

                    className="svg-btn"
                    data-tooltip="Reset Code"
                    onClick={() => {
                      ResetWholeCode();
                    }}
                  >
                    <span className="rotate-icon" data-tooltip="Reset">
                      <i
                        style={{ transform: "scale(1.8)" }}
                        className="fas fa-undo w-full"
                      ></i>
                    </span>
                  </div>
                  {resize ? (
                    <div
                      style={{
                        cursor: "pointer",
                        color: modes ? "#fff" : "#001528",
                        display: "inline",
                        height: "2.5rem",
                        width: "2.5rem",
                      }}
                      className="svg-btn"
                      data-tooltip="Full Screen"
                    >
                      <svg
                        width="100%"
                        height="100%"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 469.333 469.333"
                        fill="currentColor"
                        onClick={() => {
                          changeLayout();
                        }}
                      >
                        <path d="M160 0H10.667A10.66 10.66 0 000 10.667V160a10.66 10.66 0 0010.667 10.667H32A10.66 10.66 0 0042.667 160V42.667H160A10.66 10.66 0 00170.667 32V10.667A10.66 10.66 0 00160 0zM458.667 0H309.333a10.66 10.66 0 00-10.667 10.667V32a10.66 10.66 0 0010.667 10.667h117.333V160a10.66 10.66 0 0010.667 10.667h21.333A10.66 10.66 0 00469.333 160V10.667A10.66 10.66 0 00458.667 0zM458.667 298.667h-21.333a10.66 10.66 0 00-10.667 10.667v117.333H309.333a10.66 10.66 0 00-10.667 10.667v21.333a10.66 10.66 0 0010.667 10.667h149.333a10.66 10.66 0 0010.667-10.667V309.333a10.66 10.66 0 00-10.666-10.666zM160 426.667H42.667V309.333A10.66 10.66 0 0032 298.666H10.667A10.662 10.662 0 000 309.333v149.333a10.66 10.66 0 0010.667 10.667H160a10.66 10.66 0 0010.667-10.667v-21.333A10.66 10.66 0 00160 426.667z"></path>
                      </svg>
                    </div>
                  ) : (
                    <div
                      className="svg-btn"
                      style={{
                        cursor: "pointer",
                        color: modes ? "#fff" : "#001528",
                        display: "inline",
                        height: "2.5rem",
                        width: "2.5rem",
                      }}
                      data-tooltip="Exit Full Screen"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 469.333 469.333"
                        fill="currentColor"
                        onClick={() => {
                          changeLayout();
                        }}
                      >
                        <path d="M160 0h-21.333A10.66 10.66 0 00128 10.667V128H10.667A10.66 10.66 0 000 138.667V160a10.66 10.66 0 0010.667 10.667H160A10.66 10.66 0 00170.667 160V10.667A10.66 10.66 0 00160 0zM458.667 128H341.333V10.667A10.66 10.66 0 00330.667 0h-21.333a10.66 10.66 0 00-10.667 10.667V160a10.66 10.66 0 0010.667 10.667h149.333A10.66 10.66 0 00469.334 160v-21.333A10.662 10.662 0 00458.667 128zM458.667 298.667H309.333a10.66 10.66 0 00-10.667 10.667v149.333a10.66 10.66 0 0010.667 10.667h21.333a10.66 10.66 0 0010.667-10.667V341.333h117.333a10.66 10.66 0 0010.667-10.667v-21.333a10.66 10.66 0 00-10.666-10.666zM160 298.667H10.667A10.66 10.66 0 000 309.333v21.333a10.66 10.66 0 0010.667 10.667H128v117.333a10.66 10.66 0 0010.667 10.667H160a10.66 10.66 0 0010.667-10.667V309.333A10.66 10.66 0 00160 298.667z"></path>
                      </svg>
                    </div>
                  )}

                  <div
                    style={{
                      cursor: "pointer",
                      color: modes ? "#fff" : "#001528",
                    }}

                    data-tooltip="Run Code"
                    onClick={() => {
                      // setrawoutputsol([])
                      displayCustomInput ? executeCustomInput() : RunningAllTestcases();
                    }}
                    className="run-btn svg-btn"
                  >
                    {running ? (
                      "Run Code"
                    ) : (
                      <>
                        <div className="text-center">
                          <div
                            className="spinner-border d-flex justify-content-center text-warning spinner-border-sm"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              {selectLanguage && (
                <AceEditor
                  mode={
                    selectLanguage.toLowerCase() === "python3"
                      ? "python"
                      : selectLanguage.toLowerCase() === "c++" ||
                        selectLanguage.toLowerCase() === "c"
                        ? "c_cpp"
                        : selectLanguage.toLowerCase()
                  }
                  theme={modes ? "solarized_dark" : "solarized_light"}
                  value={code ? code : undefined}
                  className="codes"
                  onChange={onChange}
                  name="UNIQUE_ID_OF_DIV"
                  width={"100%"}
                  fontSize={fontSize}
                  height={"100%"}
                  setOptions={{
                    enableBasicAutocompletion: true,
                    enableLiveAutocompletion: true,
                    enableSnippets: true,
                  }}
                />
              )}
            </div>

            <div className="test-cases-container">

              <div
                className="output_area p-0"
                style={{ width: "100%" }}
              >
                <div className="d-flex flex-row submit-code-compiler justify-content-between">
                  <div className="d-flex align-items-stretch">
                    <button
                      className={
                        "selected-type-test borderchange"
                      }
                    >
                      Raw Output
                    </button>
                  </div>
                  <div className="submit-code-button">
                    {
                      displaySubmitButton && (
                        <button
                          disabled={runningSubmissions}
                          style={{
                            height: "100%"
                          }}
                          className="submit-coding-examination"
                          data-tooltip="Submit Your Code"
                          onClick={() => {
                            if (code && selectLanguage) submitAndCheckCode(code, selectLanguage)
                          }}
                        >
                          {
                            runningSubmissions ? (
                              <div className="text-center">
                                <div
                                  className="spinner-border d-flex justify-content-center spinner-border-sm"
                                  role="status"
                                  style={{
                                    color: "#185ADB"
                                  }}
                                >
                                  <span className="visually-hidden">Loading...</span>
                                </div>
                              </div>
                            ) : "Submit Code"
                          }
                        </button>
                      )
                    }
                  </div>
                </div>
                <div className="p-2 test-case-output-container">
                  <h2 className="output-heading">Output:</h2>
                  <p className="output-container">
                    {rawoutputsol.map((value, index) => {
                      return (
                        <>
                          <div
                            className=""
                            style={{
                              whiteSpace: "pre-line",
                            }}
                          >
                            <br />
                            <code
                              style={{
                                color: value.passed ? "green" : "#d63384",
                              }}
                            >
                              -------Test Case {index + 1}
                              -----------
                              <br />
                              <code
                                style={{
                                  whiteSpace: "pre-line",
                                  color: value.passed ? "green" : "#d63384",
                                }}
                              >
                                {" "}
                                {value.output.replace(/ /g, "\u00A0")}{" "}
                              </code>
                              <br />
                              -----------------------------
                            </code>
                          </div>
                        </>
                      );
                    })}
                    {
                      customOut.length > 0 && customOut.map((value, index) => {
                        return (
                          <>
                            <div
                              className=""
                              style={{
                                whiteSpace: "pre-line",
                              }}
                            >
                              <br />
                              <code
                                style={{
                                  color: value.passed ? "green" : "#d63384",
                                }}
                              >
                                ------- Running Custom Test Case -------
                                <br />
                                <code
                                  style={{
                                    whiteSpace: "pre-line",
                                    color: value.passed ? "green" : "#d63384",
                                  }}
                                >
                                  {" "}
                                  {value.output.replace(/ /g, "\u00A0")}{" "}
                                </code>
                                <br />
                                -----------------------------
                              </code>
                            </div>
                          </>
                        )
                      })
                    }
                  </p>
                </div>
              </div>
            </div>
          </SplitPane>
        </SplitPane>

      </div>
    </div>
  );
};

const SolutionWindow: FC<{
  solution: Array<{
    language: language_values;
    codeSolutions: Array<string>;
    _id: string;
  }>;
  selectLanguage?: language_values;
  modes: boolean;
  onGiveUp: () => unknown;
  blurred: boolean;
  hints: Array<string> | null;
}> = ({ solution, selectLanguage, modes, onGiveUp, blurred, hints }) => {
  let sol: Array<string> = [];
  if (selectLanguage) {
    solution.forEach((val) => {
      if (val.language === selectLanguage) {
        sol = val.codeSolutions;
      }
    });
  }
  // changes
  const [idx, setIdx] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const containerDiv = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerDiv.current &&
        !containerDiv.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  let len = hints ? hints.length : 0;

  // end changes

  return (
    <div className="solution-window-parent">
      {blurred && sol.length > 0 && (
        <div className="popup-solution">
          <button className="give-up-btn" onClick={onGiveUp}>
            GIVE UP
          </button>
          {hints && hints.length > 0 && (
            <>
              <span>or</span>
              <span className="hint-container">
                <button
                  className="hint-button"
                  onClick={() => setIsOpen(!isOpen)}
                >
                  Hint
                </button>
                <div
                  className={isOpen ? "container" : "container hideContainer"}
                  ref={containerDiv}
                >
                  <div className="header">
                    <h2 className="headerH2">
                      Hint <span>{`(${idx + 1}/${len})`}</span>{" "}
                    </h2>
                    <div className="arrows">
                      <button
                        className={idx > 0 ? "left" : "left disabled"}
                        onClick={() => setIdx((prev) => prev - 1)}
                      >
                        {`<`}
                      </button>
                      <button
                        className={idx < len - 1 ? "right" : "right disabled"}
                        onClick={() => setIdx((prev) => prev + 1)}
                      >
                        {`>`}
                      </button>
                    </div>
                  </div>
                  <div className="header_content">{hints[idx]}</div>
                </div>
              </span>
            </>
          )}
        </div>
      )}
      <div
        className="solution-window"
        style={{
          filter: blurred && sol.length > 0 ? "blur(5px)" : undefined,
        }}
      >
        {sol.length === 0 && <div>No Solutions found</div>}
        {selectLanguage &&
          sol.map((val, index) => (
            <div style={{ marginTop: "1rem" }}>
              <span>Solution {index + 1}</span>
              <AceEditor
                mode={
                  selectLanguage.toLowerCase() === "python3"
                    ? "python"
                    : selectLanguage.toLowerCase() === "c++" ||
                      selectLanguage.toLowerCase() === "c"
                      ? "c_cpp"
                      : selectLanguage.toLowerCase()
                }
                theme={modes ? "solarized_dark" : "solarized_light"}
                value={val}
                className="solution_codes"
                name="solution_editor"
                width={"100%"}
                fontSize={16}
                readOnly={true}
                showGutter={false}
                highlightActiveLine={false}
                focus={false}
                height={"250px"}
              />
            </div>
          ))}
      </div>
    </div>
  );
};

SolutionWindow.defaultProps = {
  blurred: true,
};

const LoaderCompiler = () => {
  return (
    <div className="maincontainer-loader">
      <div className="box-wrapper">
        <div id="box1">
          <div id="box1-1"></div>
          <div id="box1-2">
            <div id="a"></div>
            <div id="a"></div>
            <div id="a"></div>
          </div>
          <div id="box1-1"></div>
        </div>
        <div id="box3">
          <div id="box4"></div>
          <div className="middle-column">
            <div id="box5"></div>
            <div id="box6"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Compiler;

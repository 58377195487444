import React from "react";
import style from "../../Assets/css/b2bTestimonial.module.css";

const b2bTestimonials = () => {
  const rec_2 =
    "https://cantileverlabs-gradient-images.s3.ap-south-1.amazonaws.com/Sanjeed.jpeg";
  const rec_1 =
    "https://cantileverlabs-gradient-images.s3.ap-south-1.amazonaws.com/Ashish.jpeg";
  const rec_3 =
    "https://cantileverlabs-gradient-images.s3.ap-south-1.amazonaws.com/Anudeep.jpeg";
  return (
    <>
      <div class={`container-fluid ${style.section1_J}`}>
        <div>
          <div
            class={`row justify-content-around ${style.MNC_J} mx-xl-5 mx-lg-5 mx-md-5 mx-sm-2`}
          >
            <div
              class={`col-xl-7 col-lg-7 p-5 mb-4 ${style.second_div_J}`}
              style={{ textAlign: "justify" }}
            >
              {/* <br /> */}
              <div class="d-flex my-auto">
                {/* <div class={`${style.yellow_J} mr-3`}></div> */}
                <h2>
                  <span style={{ color: "#fec846", fontSize: "6em" }}>''</span>
                </h2>
                <b>
                  <span
                    style={{
                      fontSize: "2.5em",
                      margin: "0",
                      padding: "0",
                      lineHeight: "45px",
                    }}
                  >
                    Convenient.{" "}
                  </span>
                  process with Cantilever taking up a lot of the challenging
                  tasks of scheduling events and personal interviews.{" "}
                  <p class={`${style.p_bold_J} ml-4 my-4`}>
                    <span style={{ lineHeight: "45px" }}>ASHISH BIJLANI</span>{" "}
                    Placement Cell,{" "}
                    <strong style={{ fontSize: "0.9rem" }}>IIT Madras</strong>
                  </p>
                </b>
                {/* <h4>
                From a student's perspective, the entire process felt quite robust, exhaustive & aligned with the placement process. One of the things I really appreci- ated,  all through the group sessions was the emphasis on getting across the recruiter’s  point of view. This really helped align responses, be it the resume or interview,  by enabling evaluation of preparation by putting ourselves on the other side  of the table.
                </h4> */}{" "}
              </div>
            </div>
            <div
              class={`col-xl-4 col-lg-4 p-5 mb-4 ${style.first_div_J} ${style.first_img_J} `}
            >
              <img
                src={rec_1}
                class={`img-responsive ${style.first_img_J}`}
                alt=""
              />
            </div>
          </div>

          <div
            class={`row justify-content-around image-left ${style.MNC_J} mx-xl-5 mx-lg-5 mx-md-5 mx-sm-2`}
          >
            <div
              class={`col-xl-4 col-lg-4 p-5 mb-4 ${style.first_div_J} ${style.first_img_J}`}
            >
              <img
                src={rec_2}
                class={`img-responsive ${style.first_img_J}`}
                alt=""
              />
            </div>

            <div
              class={`col-xl-6 col-lg-6 p-5 mb-4 ${style.second_div_J}`}
              style={{ textAlign: "justify" }}
            >
              {/* <br /> */}
              <div class="d-flex my-auto">
                {/* <div class={`${style.yellow_J} mr-3`}></div> */}
                <h2>
                  <span style={{ color: "#fec846", fontSize: "6em" }}>''</span>
                </h2>
                <b>
                  <span
                    style={{
                      fontSize: "2.5em",
                      margin: "0",
                      padding: "0",
                      lineHeight: "45px",
                    }}
                  >
                    Helpful.{" "}
                  </span>
                  The student community at IIT Madras are very satisﬁed with
                  their high quality service and drive.
                  <p class={`${style.p_bold_J} ml-4 my-4`}>
                    <span>SANJEED </span>| HEAD -Career Development Cell,{" "}
                    <strong style={{ fontSize: "0.9rem" }}>IIT Madras</strong>
                  </p>
                </b>
                {/* <h4>
                From a student's perspective, the entire process felt quite robust, exhaustive & aligned with the placement process. One of the things I really appreci- ated,  all through the group sessions was the emphasis on getting across the recruiter’s  point of view. This really helped align responses, be it the resume or interview,  by enabling evaluation of preparation by putting ourselves on the other side  of the table.
                </h4> */}{" "}
              </div>
            </div>
          </div>

          <div
            class={`row justify-content-around ${style.MNC_J} mx-xl-5 mx-lg-5 mx-md-5 mx-sm-2`}
          >
            <div
              class={`col-xl-7 col-lg-7 p-5 mb-4 ${style.second_div_J}`}
              style={{ textAlign: "justify" }}
            >
              {/* <br /> */}
              <div class="d-flex my-auto">
                {/* <div class={`${style.yellow_J} mr-3`}></div> */}
                <h2>
                  <span style={{ color: "#fec846", fontSize: "6em" }}>''</span>
                </h2>
                <b>
                  <span
                    style={{
                      fontSize: "2.5em",
                      margin: "0",
                      padding: "0",
                      lineHeight: "45px",
                    }}
                  >
                    Value driven.{" "}
                  </span>
                  Cantilever's quality service & drive to genuinely help
                  students set it apart from other competitors. It's team is
                  responsive to feedback & is agile in implementation
                  <p class={`${style.p_bold_J} ml-4 my-4`}>
                    <span>ANUDEEP PEELA </span>Placement Manager,{" "}
                    <strong style={{ fontSize: "0.9rem" }}>IIT Bombay</strong>
                  </p>
                </b>
                {/* <h4>
                From a student's perspective, the entire process felt quite robust, exhaustive & aligned with the placement process. One of the things I really appreci- ated,  all through the group sessions was the emphasis on getting across the recruiter’s  point of view. This really helped align responses, be it the resume or interview,  by enabling evaluation of preparation by putting ourselves on the other side  of the table.
                </h4> */}{" "}
              </div>
            </div>
            <div
              class={`col-xl-4 col-lg-4 p-5 mb-4 ${style.first_div_J} ${style.first_img_J}`}
            >
              <img
                src={rec_3}
                class={`img-responsive ${style.first_img_J}`}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default b2bTestimonials;

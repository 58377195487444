export const AssignmentScoreData = {
  "62d63c31bc0f60e6ac1694a6": {
    labels: ["Assignment Score"],
    datasets: [
      {
        label: "0-50%",
        data: [18],
        backgroundColor: "#FF5858",
        borderColor: "#FF5858",
      },
      {
        label: "50-75%",
        data: [33],
        backgroundColor: "#FFB636",
        borderColor: "#FFB636",
      },
      {
        label: "75-100%",
        data: [29],
        backgroundColor: "#24B47E",
        borderColor: "#24B47E",
      },
    ],
  },
  "62d63901bc0f60e6ac1694a4": {
    labels: ["Assignment Score"],
    datasets: [
      {
        label: "0-50%",
        data: [9],
        backgroundColor: "#FF5858",
        borderColor: "#FF5858",
      },
      {
        label: "50-75%",
        data: [26],
        backgroundColor: "#FFB636",
        borderColor: "#FFB636",
      },
      {
        label: "75-100%",
        data: [25],
        backgroundColor: "#24B47E",
        borderColor: "#24B47E",
      },
    ],
  },
  "62d63c40bc0f60e6ac1694a7": {
    labels: ["Assignment Score"],
    datasets: [
      {
        label: "0-50%",
        data: [20],
        backgroundColor: "#FF5858",
        borderColor: "#FF5858",
      },
      {
        label: "50-75%",
        data: [39],
        backgroundColor: "#FFB636",
        borderColor: "#FFB636",
      },
      {
        label: "75-100%",
        data: [16],
        backgroundColor: "#24B47E",
        borderColor: "#24B47E",
      },
    ],
  },
  "62d63bb5bc0f60e6ac1694a5": {
    labels: ["Assignment Score"],
    datasets: [
      {
        label: "0-50%",
        data: [18],
        backgroundColor: "#FF5858",
        borderColor: "#FF5858",
      },
      {
        label: "50-75%",
        data: [13],
        backgroundColor: "#FFB636",
        borderColor: "#FFB636",
      },
      {
        label: "75-100%",
        data: [33],
        backgroundColor: "#24B47E",
        borderColor: "#24B47E",
      },
    ],
  },
};

export const AssignmentTopStudents = {
  "62d63c31bc0f60e6ac1694a6": [
    ["Raghu Tamboli", 400],
    ["Sunder Chaudhri", 400],
    ["Sneha Gadhavi", 395],
    ["Nirav Mhasalkar", 393],
    ["Nirav Mhasalkar", 381],
    ["Harshal Misra", 374],
    ["Jagdish Devi", 366],
    ["Nithin Sharma", 365],
    ["Rajiv Jain", 200],
    ["Jay Kumar", 905],
    ["Imran Shaha", 901],
    ["Jagdish Devi", 366],
    ["Nithin Sharma", 365],
    ["Rajiv Jain", 200],
    ["Jay Kumar", 905],
    ["Imran Shaha", 901],
  ],
  "62d63901bc0f60e6ac1694a4": [
    ["Trishna Mishra", 529],
    ["Sanjit Das", 525],
    ["Riya Kulkarni", 522],
    ["Gauri Mishra", 522],
    ["Chandra Sharma", 519],
    ["Gopal Begam", 515],
    ["Bharat Patel", 511],
    ["Ranjeet Nagarkar", 505],
    ["Diksha Ahmed", 189],
    ["Jay Kumar", 905],
    ["Imran Shaha", 901],
    ["Nithin Sharma", 365],
    ["Rajiv Jain", 200],
    ["Jay Kumar", 905],
    ["Imran Shaha", 901],
  ],
  "62d63c40bc0f60e6ac1694a7": [
    ["Gowri Ahmed", 415],
    ["Punit Vemulakonda", 411],
    ["Shubham Das", 408],
    ["Chandana Kaur", 403],
    ["Amita Joshi", 399],
    ["Shresth Mhasalkar", 399],
    ["Raghu Kaur", 387],
    ["Archana Choudhary", 380],
    ["Pankaj Bachchan", 375],
    ["Jay Kumar", 905],
    ["Imran Shaha", 901],
    ["Nithin Sharma", 365],
    ["Rajiv Jain", 200],
    ["Jay Kumar", 905],
    ["Imran Shaha", 901],
  ],
  "62d63bb5bc0f60e6ac1694a5": [
    ["Amita Jain", 935],
    ["Jayanthi Das", 929],
    ["Vasuda Kumar", 925],
    ["Lina Chaudhary", 920],
    ["Siddhi Singh", 919],
    ["Niraj Patel", 917],
    ["Swait Begam", 905],
    ["Gurmeet Patil", 911],
    ["Rashmi Gupta", 906],
    ["Jay Kumar", 905],
    ["Imran Shaha", 901],
    ["Nithin Sharma", 365],
    ["Rajiv Jain", 200],
    ["Jay Kumar", 905],
    ["Imran Shaha", 901],

  ],
};

export const AssignmentTotalSubmission = {
  "62d63c31bc0f60e6ac1694a6": {
    coding: 83,
    quiz: 85
  },
  "62d63901bc0f60e6ac1694a4": {
    coding: 72,
    quiz: 74
  },
  "62d63c40bc0f60e6ac1694a7": {
    coding: 77,
    quiz: 92
  },
  "62d63bb5bc0f60e6ac1694a5": {
    coding: 66,
    quiz: 69.3
  },
};

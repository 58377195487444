import DifficultyCards from './DifficultyCards'
import { useSelector } from "react-redux";
import "../Assets/css/DiffSection.scss";
function DifficultySection(props) {
  const {
    easyquestions,
    mediumquestions,
    hardquestions
  } = props;

  const difficulty_selector = useSelector(({ dashboard }) => dashboard.difficultyLevel)
  return (
    <div className='row diffcultyCards'>
    {
      (difficulty_selector === "any" || difficulty_selector === "easy") && easyquestions && easyquestions.map((val) => {
        return <DifficultyCards 
          key={val._id}
          level="Easy"
          category={val.category ? val.category : null}
          name={val.name}
          company={val.company ? val.company : []}
          link={`/compiler/${val._id}/16`}
        />
      })
    }
    {
      (difficulty_selector === "any" || difficulty_selector === "medium") && mediumquestions && mediumquestions.map((val, index) => {
        return <DifficultyCards 
          key={val._id}
          level="Medium"
          category={val.category ? val.category : null}
          name={val.name}
          company={val.company ? val.company : []}
          link={`/compiler/${val._id}/16`}
        />
      })
    }
    {
      (difficulty_selector === "any" || difficulty_selector === "hard") && hardquestions && hardquestions.map((val, index) => {
        return <DifficultyCards 
          key={val._id}
          level="Hard"
          category={val.category ? val.category : null}
          name={val.name}
          company={val.company ? val.company : []}
          link={`/compiler/${val._id}/16`}
        />
      })
    }
    </div>
  )
}

export default DifficultySection
import React from "react";
import useMedia from "../componentsofcourse/useMedia";

import HL1 from "../Imgsofcourse/images/Group 7401.png";
import HL2 from "../Imgsofcourse/images/Group 7404.png";
import HL3 from "../Imgsofcourse/images/Group 7406.png";
import HL4 from "../Imgsofcourse/images/Group 7402.png";
import HL5 from "../Imgsofcourse/images/Group 7405.png";
import HL6 from "../Imgsofcourse/images/Group 7407.png";

const Highlights = () => {

    const matches = useMedia('(min-width : 768px )' );

    return (
        <div>
        <h1 style={{fontWeight:'bold' , textAlign:'center'}}>Highlights</h1>
        <br/>
        <br/>

        {
        matches ?

        <div style={{ paddingLeft :'10%'}} className="container-fluid">
        <br/>
        <br/>
        <div className="row">
            <div className="col-md-4 col-xs-6 col-6">
                <table>
                    <tr>
                        <td><img src={HL1} alt="" className="img-fluid"/></td>
                        <td>&nbsp;&nbsp;&nbsp;</td>
                        <td><p className="highlights_text">Learn at the comfort of <br/> your HOME</p></td>
                    </tr>
                </table>
            </div>
            <div className="col-md-4 col-xs-6 col-6">
                <table >
                    <tr>
                        <td><img src={HL2} alt="" className="img-fluid"/></td>
                        <td>&nbsp;&nbsp;&nbsp;</td>
                        <td><p className="highlights_text">Learn from <br/> Industry Experts</p></td>
                    </tr>
                </table>
            </div>
            <div className="col-md-4 col-xs-6 col-6">
                <table>
                    <tr>
                        <td><img src={HL3} alt="" className="img-fluid"/></td>
                        <td>&nbsp;&nbsp;&nbsp;</td>
                        <td><p className="highlights_text">Beginner<br/> friendly</p></td>
                    </tr>
                </table>
            </div>

            <div className="col-12"> </div><div className="col-12"> </div>
            <div className="col-12"> </div> <div className="col-12"> </div>

            <div className="col-md-4 col-xs-6 col-6">
                <table>
                    <tr>
                        <td><img src={HL4} alt="" className="img-fluid"/></td>
                        <td>&nbsp;&nbsp;&nbsp;</td>
                        <td><p className="highlights_text">Live online classes<br/> to boost productivity</p></td>
                    </tr>
                </table>
            </div>
            <div className="col-md-4 col-xs-6 col-6">
                <table>
                    <tr>
                        <td><img src={HL5} alt="" className="img-fluid"/></td>
                        <td>&nbsp;&nbsp;&nbsp;</td>
                        <td><p className="highlights_text" style={{marginLeft: "20px"}}>Solve 50+<br/> Questions</p></td>
                    </tr>
                </table>
            </div>
            <div className="col-md-4 col-xs-6 col-6">
                <table>
                    <tr>
                        <td><img src={HL6} alt="" className="img-fluid"/></td>
                        <td>&nbsp;&nbsp;&nbsp;</td>
                        <td><p className="highlights_text">Doubt<br/> Assistance</p></td>
                    </tr>
                </table>
            </div>

        </div>
        </div>

        :

        <div className="container">
        <div className="row">
            <div className="col-md-4 col-xs-6 col-6">
                <img src={HL1} alt="" className="img-fluid"/>
                       <p className="highlights_text_mobile">Learn at the comfort <br/> of your HOME</p>

            </div>
            <div className="col-md-4 col-xs-6 col-6">
                <img src={HL2} alt="" className="img-fluid"/>
                        <p className="highlights_text_mobile">Learn from<br/> Industry Experts</p>

            </div>
            <div className="col-md-4 col-xs-6 col-6">
                <img src={HL3} alt="" className="img-fluid"/>
                        <p className="highlights_text_mobile">Beginner <br/> friendly</p>

            </div>



            <div className="col-md-4 col-xs-6 col-6">
                <img  src={HL4} alt="" className=""/>
                      <p className="highlights_text_mobile">Live online classes <br/> to boost productivity</p>

            </div>
            <div className="col-md-4 col-xs-6 col-6">
                <img src={HL5} alt="" className="img-fluid"/>
                        <p className="highlights_text_mobile">Life Time access of <br/> Lectures & Resources</p>

            </div>
            <div className="col-md-4 col-xs-6 col-6">
               <img src={HL6} alt="" className="img-fluid"/>
                        <p className="highlights_text_mobile">Placement <br/> assistance</p>

            </div>

        </div>
        </div>
        }

    </div>
  );
};

export default Highlights;
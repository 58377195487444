import React from 'react'
import MOBILEPOP from '../Assets/img/MOBILEPOP.png'
const Mobilepopup = () => {
    return (
        <>
        <div data-aos="zoom-in-up"  data-duration="3000" className=" shadow-lg rounded py-3 " style={{ position: "fixed",bottom:"0%",zIndex:"30" }}>
            {/* <img src={MOBILEPOP} alt="" style={{width:"300px"}} /> */}
            </div>
            </>
    )
}

export default Mobilepopup

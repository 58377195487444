import { useState } from "react";
import { useTimer } from "react-timer-hook";

const Countdown = ({ expiryTimestamp }) => {
  const [show, setShow] = useState(true);
  const [timing, setTiming] = useState(45);
  const { seconds, minutes, hours, restart } = useTimer({
    expiryTimestamp,
  });
  const InputEvent = (event) => {
    setTiming(event.target.value);
  };
  const ButtonPressed = () => {
    setShow(!show);
  };

  return (
    <>
      <div
        className={
          show
            ? "select-language-code tooltip-animation"
            : "select-language-code"
        }
        data-tooltip="Timer | Stopwatch"
        style={{ width: "120px" }}
      >
        <p className="selected-language-code timercontainer">
          <div className="d-flex" onClick={ButtonPressed}>
            <svg
              fill="currentColor"
              viewBox="0 0 323.569 323.569"
              xmlns="http://www.w3.org/2000/svg"
              className="_3sRQZbRpTpCxpcGmMEqpdt"
              style={{ height: "20px", margin: "0 12px 0 0" }}
            >
              <path d="M161.785 53.928c-74.459 0-134.821 60.361-134.821 134.821S87.325 323.57 161.785 323.57s134.821-60.361 134.821-134.821c-.085-74.424-60.397-134.736-134.821-134.821zm13.482 241.74v-12.545c0-7.446-6.036-13.482-13.482-13.482s-13.482 6.036-13.482 13.482v12.538c-48.792-6.221-87.21-44.638-93.431-93.431H67.41c7.446 0 13.482-6.036 13.482-13.482s-6.036-13.482-13.482-13.482H54.872c6.221-48.792 44.638-87.21 93.431-93.431v12.538c0 7.446 6.036 13.482 13.482 13.482s13.482-6.036 13.482-13.482V81.836c48.795 6.218 87.216 44.636 93.437 93.431h-12.545c-7.446 0-13.482 6.036-13.482 13.482s6.036 13.482 13.482 13.482h12.538c-6.217 48.795-44.636 87.216-93.43 93.437z"></path>
              <path d="M175.267 183.166v-34.863c0-7.446-6.036-13.482-13.482-13.482s-13.482 6.036-13.482 13.482v40.446a13.487 13.487 0 003.95 9.532l26.964 26.964c5.356 5.173 13.891 5.024 19.064-.332 5.046-5.225 5.046-13.507 0-18.732zM134.821 26.964h53.928c7.446 0 13.482-6.036 13.482-13.482S196.195 0 188.749 0h-53.928c-7.446 0-13.482 6.036-13.482 13.482s6.036 13.482 13.482 13.482zM314.314 74.348L276.185 36.22c-5.331-5.198-13.867-5.09-19.065.241-5.105 5.236-5.105 13.588 0 18.824l38.129 38.129c5.198 5.331 13.734 5.439 19.065.241s5.439-13.734.241-19.065a10.994 10.994 0 00-.241-.242zM66.449 55.285c5.331-5.198 5.439-13.734.241-19.065s-13.734-5.439-19.065-.241c-.081.079-.162.16-.241.241L9.256 74.348c-5.331 5.198-5.44 13.734-.242 19.065s13.734 5.44 19.065.242c.082-.08.162-.16.242-.242z"></path>
            </svg>
            <div style={{ whiteSpace: "nowrap" }}>
              <span>{hours}</span>:<span>{minutes}</span>:<span>{seconds}</span>
            </div>
          </div>
          <div
            className="timeCard"
            style={{ display: show ? "none" : "block" }}
          >
            <div className="timeContainer">
              <div className="timerCross">
                <i className="fas fa-times" onClick={ButtonPressed}></i>
              </div>
              <p className="timerHeading">Timer</p>
              <p className="timerText">
                <span>{hours}</span>:<span>{minutes}</span>:
                <span>{seconds}</span>
              </p>
              <form>
                <label htmlFor="inputTime" className="timerLabel">
                  Minutes
                </label>
                <input
                  type="number"
                  min="0"
                  max="45"
                  name="timing"
                  value={timing}
                  onChange={InputEvent}
                  className="timerInputField"
                />
              </form>
              <div className="timerBtn">
                <div
                  className="timerLeft"
                  onClick={() => {
                    const time = new Date();
                    time.setSeconds(time.getSeconds() + 60 * timing);
                    restart(time);
                  }}
                >
                  Start
                </div>
                <div
                  className="timerRight"
                  onClick={() => {
                    const time = new Date();
                    time.setSeconds(time.getSeconds() + 0 * timing);
                    restart(time);
                  }}
                >
                  Reset
                </div>
              </div>
            </div>
          </div>
        </p>
      </div>
    </>
  );
};

Countdown.defaultProps = {
  expiryTimestamp: undefined
}

export default Countdown;

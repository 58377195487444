import React, { useState, useContext, useEffect, useLayoutEffect } from "react";
import { ModalContext } from "../Context/ModalContext";
import Main from "../componentsofcourse/Main";
import ConfusedForm from "../componentsofcourse/ConfusedForm";
import SM from "../Imgsofcourse/images/Image 1.png";
import Whyweb from "../componentsofcourse/whywebdev";
import Test from "../componentsofcourse/Test";
import Assistance from "../Assets/img/Competitive/Assistance.png";
import PlaceTable from "../componentsofcourse/PlaceTable";
import placeImg from "../Assets/img/course/pb-img.png";
import amazon from "../Assets/img/Placement/amazon.png";
import google from "../Assets/img/Placement/Google.png";
import microsoft from "../Assets/img/Placement/microsoft.png";
import linkedin from "../Assets/img/Placement/linkedin_color.png";
import adobe from "../Assets/img/Placement/adobe.png";
import flipkart from "../Assets/img/Placement/flipkart_color.png";
import IconStar from "../Assets/img/course/icon_star.png";
import IconUsers from "../Assets/img/course/icon_users.png";
import IconCalendar from "../Assets/img/course/icon_calender.png";
import IconHands from "../Assets/img/course/icon_hands.png";
import Highlights from "../componentsofcourse/Highlights";
import Process from "../componentsofcourse/Process";
import CpCompany from "../componentsofcourse/CpCompany";
import MoreQueries from "../components/MoreQueries";
import Manish from "../Assets/img/Competitive/Image_30.png";
import Vivek from "../Assets/img/Competitive/Vivek.png";
import Bajaj from "../Assets/img/Competitive/Bajaj.png";
import Linkedin from "../Assets/img/Competitive/linkedin.png";
import amazon_app from "../Assets/img/Competitive/amazon_app.png";
import google_app from "../Assets/img/Competitive/google_app.png";
import ms_app from "../Assets/img/Competitive/ms_app.png";
import certificate from "../Assets/img/Competitive/certificate.png";
import useMedia from "../componentsofcourse/useMedia";
import BatchStart from "../components/BatchStart";
import Footer from "../components/Footer";
import CpCourseCards from "./cpCourseCards";
import PlacementCourseImages from "./PlacementCourseImages";
import CPMentors from "./CPMentors";
import CPcertificate from "./CPcertificate";
import Testimonials from "./Testimonials";
import Navbar from "../components/Navbar";
import ReactPixel from 'react-facebook-pixel';
import firebase from "../firebase";

function Placement() {
const analytics = firebase.analytics();
  const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
  const options = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: false, // enable logs
  };
   useEffect(() => {
    ReactPixel.init("337209321107283", advancedMatching, options);
    ReactPixel.trackCustom("PLACEMENT_PAGE");
  },[])
  const parameters = {
    header: (
      <div>
        Get
        <br />
        Placement Ready
      </div>
    ),
    matter: (
      <div>
        This online Placement preparation course is an ultimate online placement
        training. Here you will learn to write most efficient programmes and
        equip yourself to get solutions for the complex codes. Get ready for the
        competitions like ACM-ICPC, Google Codejam and more. You will also learn
        all the things that you need for placements which includes aptitude
        preparation and core computer science topics like Operating Systems ,
        DBMS and Computer Networks . You will also be getting mock Interviews .
        After completing this course you will be fully ready for any placement .
      </div>
    ),
    mainImage: placeImg,

    icons: {
      IconStar,
      IconUsers,
      IconCalendar,
      //   IconHands,
    },
    techImg: {
      0: amazon,
      1: google,
      2: microsoft,
      3: linkedin,
      4: adobe,
      5: flipkart,
    },
    iconText: {
      0: "4.1",
      1: "5362",
      2: "3 Weeks",
      //   3: "100% internship guarantee",
    },
  };

  const highlightsParameters = {
    text: {
      0: (
        <div>
          Learn at the comfort of <br /> your HOME
        </div>
      ),
      1: (
        <div>
          Learn from <br /> Industry Experts
        </div>
      ),
      2: (
        <div>
          {" "}
          Get
          <br /> Interview Ready
        </div>
      ),
      3: (
        <div>
          {" "}
          Mock
          <br /> Questions
        </div>
      ),
      4: (
        <div>
          {" "}
          Solve 100+
          <br /> Questions
        </div>
      ),
      5: (
        <div>
          Doubt
          <br /> Assistance
        </div>
      ),
    },
    mobText: {
      0: "Learn at the comfort of your home",
      1: "Learn from Industry Experts",
      2: "Get Interview Ready",
      3: "Mock Interviews",
      4: "Solve 100+ Questions",
      5: "Doubt Assistance",
    },
  };
  const matches = useMedia("(min-width : 768px )");
  let { open, update_status } = useContext(ModalContext);
  const [modal, setModal] = useState(false);
  useEffect(() => {
    update_status(!open);
    setModal();
  }, [modal]);
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      {/* <Navbar /> */}
      <div style={{ overflowX: "hidden" }}>
        <Main parameters={parameters} />
        <Highlights parameters={highlightsParameters} />
        {/* Job Assistance */}
        <br />
        <br />
        <PlacementCourseImages />
        {/* Meet your Mentors */}
        <CPMentors />
        {/* First set of Cards  */}
        <CpCourseCards set={1} set1={2} />
        {/* Certificate of Completion */}
        <CPcertificate m={matches} />
        {/* Testimonials */}
        <Testimonials />
        <Test />
        {/* Pricing Table */}
        <PlaceTable prev={"/placementcourse"} />
        <BatchStart />
        {/* Form */}
        <ConfusedForm coursename={"placementcourse"} />
        {/* Companies Card */}
        <br />
        <br />
        <CpCourseCards set={2} set1={"Why prepare for top companies"} />
        {/* <Whyweb headingname="Why Prepare for top companies" /> */}
        <CpCompany />
        <Process />
        <MoreQueries />
        <Footer />
      </div>
    </>
  );
}

export default Placement;

import React, { useEffect, useState } from "react";
// import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { ExpertCard, SubscribeSection } from "./BookExpert";
// import { ExpertSmallCard } from "./ExpertProfile.js";
import { Button } from "@mui/material";
import "../Assets/css/experts.css";
import { BASE_URL } from "../env";
import axios from "axios";

function Experts() {
  const [mentors, setMentors] = React.useState([]);
  const [param, setParam] = React.useState({});
  useEffect(() => {
    fetch(BASE_URL + "/getMentors")
      .then((res) => res.json())
      .then((data) => {
        setMentors(data.mentors);
        // console.log(data.mentors);
      });
  }, []);

  const [formData, setFormData] = useState({
    company: null,
    domain: null,
    skills: null,
    categories: null,
  });
  const handleSearch = async () => {
    await axios
      .get(BASE_URL + "/getMentors", null, {
        params: param,
      })
      .then((res) => setMentors(res.data.mentors))
      .catch((err) => {
        // console.log(err);
      });
    // console.log(param);
  };
  useEffect(() => {
    for (let i in formData) {
      if (formData[i] != null) {
        setParam({ ...param, [i]: formData[i] });
      }
    }
  }, [formData]);

  return (
    <>
      {/* Navbar Section */}
      {/* <Navbar /> */}

      {/* Filter Section */}
      <section className="experts-filter-section">
        <div className="container my-4 pt-md-4">
          <div className="row">
            <div className="col-lg-2 experts-filter-text ml-auto">
              Filter by :{" "}
            </div>
            <div className="col-lg-2 col-sm-6">
              <select
                className="form-select"
                value={formData.company}
                onChange={(e) => {
                  setFormData({ ...formData, company: e.target.value });
                }}
              >
                <option selected hidden>
                  Company
                </option>
                <option value="1">amazon</option>
                <option value="2">accenture</option>
                <option value="3">deloitte</option>
                <option value="4">flipkart</option>
                <option value="5">google</option>
              </select>
            </div>
            <div className="col-lg-2 col-sm-6">
              <select
                className="form-select"
                value={formData.domain}
                onChange={(e) => {
                  setFormData({ ...formData, domain: e.target.value });
                }}
              >
                <option selected hidden>
                  Domain
                </option>
                <option value="1">backend developer</option>
                <option value="2">business analyst</option>
                <option value="3">data analyst</option>
                <option value="4">frontend developer</option>
                <option value="5">UI/UX developer</option>
              </select>
            </div>
            <div className="col-lg-2 col-sm-6">
              <select
                className="form-select"
                value={formData.skills}
                onChange={(e) => {
                  setFormData({ ...formData, skills: e.target.value });
                }}
              >
                <option selected hidden>
                  Expert Skill
                </option>
                <option value="1">aptitude preparation</option>
                <option value="2">competitive coding</option>
                <option value="3">DSA</option>
                <option value="4">industry preparation</option>
                <option value="5">industry experience</option>
              </select>
            </div>
            <div className="col-lg-2 col-sm-6">
              <select
                className="form-select"
                value={formData.categories}
                onChange={(e) => {
                  setFormData({ ...formData, categories: e.target.value });
                }}
              >
                <option selected hidden>
                  Categories
                </option>
                <option value="1">B School</option>
                <option value="2">Business Entrepreneurship</option>
                <option value="3">Freelance</option>
                <option value="4">Graduation</option>
                <option value="5">Internship</option>
                <option value="6">Job</option>
                <option value="7">Other Experience</option>
                <option value="8">Startup Entrepreneurship</option>
                <option value="9">Post Graduation</option>
              </select>
            </div>
            <div className="col-2">
              <Button className="experts-filter-btn" onClick={handleSearch}>
                Search
              </Button>
            </div>
          </div>
        </div>
      </section>

      {/* Expert Card Section */}
      <section className="experts-list-section">
        <div className="container-fluid my-4 px-sm-5 ">
          <div className="row g-5">
            {mentors.map((mentor) => {
              return (
                <>
                  <div className="col-lg-4 col-md-6" data-aos="fade-up">
                    <ExpertCard data={mentor} />
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </section>

      {/* Subscribe Section */}
      <div className="subs-back">
        <SubscribeSection />
      </div>

      {/* Footer Section */}
      <Footer />
    </>
  );
}

export default Experts;

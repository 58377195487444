
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import { useState, useEffect } from "react";
import optionCities from "./cities.js";
import { BASE_URL } from "../env.js";

// const BASE_URL="http://localhost:5000"
function OfflineWebinarForm() {

 const customStyles = {
    option: (provided) => ({
      ...provided,
      textAlign: "left"
    }),
    container: (provided) => ({
      ...provided,
      border: "none",
      padding: "1px"
    })
  };


  const [message, setMessage] = useState(false);
   async function isEven (email) {
    // console.log(String(email));
    await fetch(BASE_URL + "/liveVerify",
      {
        method:"POST",
        body: JSON.stringify({ email:email }),
        headers: {'Content-Type': 'application/json',
        'Accept': 'application/json'},
      }
    ).then((response) => response.json())
      .then((data) => {
        // console.log(data.message)
        //  data.message
        if (data.message === true) {
          setMessage(true)
          // return true
        } else {
          // console.log("return false")
          // return false

          setMessage(false)
        }
      }).catch((err) => {
      // console.log(err)
    })
    // return false

   
  };
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors, isValid }
  } = useForm({ mode: "all" });

  function changeStates(onefun, onefunValue, secondFun, secondfunvalue) {
    onefun(onefunValue);
    secondFun(secondfunvalue);
    // alert("hello");
  }

  const [firstForm, setFirstForm] = useState(true);
  const [secondForm, setSecondForm] = useState(false);
  const [thirdForm, setThirdForm] = useState(false);
  const [fourthForm, setFourthFrom] = useState(false);
  const [fifthForm, setfifthForm] = useState(false);
  const [thanks, setthanks] = useState(false);

  const onSubmit = () => {
    const data = watch();
    // console.log(data);
  };
  const onSubmitTotal = () => {
    const data = watch();
    // console.log(data);
  };

  const CareerInterest = [
    { label: "Data Science", value: 1 },
    { label: "Software Development", value: 2 },
    { label: "Machine Learning", value: 3 },
    { label: "Artificial Intelligence", value: 4 },
    { label: "Product Development", value: 5 },
    { label: "Full Stack Development", value: 6 },
    { label: "Cyber Security", value: 7 }
  ];

  const primaryLookingfor = [
    { label: "Test Series", value: 1 },
    { label: "Competitive Coding", value: 2 },
    { label: "Interview Preparation", value: 3 },
    { label: "Problem Solving", value: 4 },
    { label: "Free Toolkits", value: 5 },
    { label: "Resume Building", value: 6 },
    { label: "Boosting Softskills", value: 7 },
    { label: "Industry Expert Webinars", value: 8 },
    { label: "Project Based Learning", value: 9 },
    { label: "Internships / Jobs", value: 10 },
    { label: "Certificates", value: 11 },
    { label: "Live session", value: 11 },
    { label: "Recorded session", value: 11 },
    { label: "All of the above", value: 12 }
  ];
  const DreamCompany = [
    { label: "TCS", value: 1 },
    { label: "Amazon", value: 2 },
    { label: "Google", value: 3 },
    { label: "Oracle", value: 4 },
    { label: "Accenture", value: 5 },
    { label: "Infosys", value: 6 },
    { label: "Wipro", value: 7 },
    { label: "Cognizant", value: 8 },
    { label: "FAANG", value: 9 }
  ];

  const graduation = [
    { label: "CSE", value: "CSE" },
    { label: "ECE", value: "ECE" },
    { label: "IT", value: "IT" },
    { label: "EEE", value: "EEE" },
    { label: "MECH", value: "MECH" }
  ];
  const GraduationDegree = [
    { label: "BE/BTech", value: "Bachelor of Engg./tech" },
    { label: "Bsc", value: 1 },
    { label: "BCA", value: 1 },
    { label: "BBA", value: 1 },
    { label: "B.com", value: 1 },
    { label: "MBA", value: 1 },
    { label: "Others", value: 1 }
  ];
  const year = () => {
    let date = new Date().getFullYear();
    let arr = [];
    for (let i = date + 9; i > 2000; i--) {
      arr.push({ value: i, label: i });
    }
    return arr;
  };
  const preparationlevel = [
    {
      label: "I have no idea where I am.",
      value: "I have no idea where I am."
    },
    {
      label: "I Haven't started any placement preparation yet!",
      value: "I Haven't started any placement preparation yet!"
    },
    {
      label: "I do have theoretical knowledge but no hands-on experience",
      value: "I do have theoretical knowledge but no hands-on experience"
    },
    {
      label:
        "I have done some projects but have zero knowledge of the interview process",
      value:
        "I have done some projects but have zero knowledge of the interview process"
    },
    {
      label:
        "I am confident about my Coding & Aptitude skills but lack in Soft skills",
      value:
        "I am confident about my Coding & Aptitude skills but lack in Soft skills"
    },
    {
      label: "I am confident about my Aptitude Skills",
      value: "I am confident about my Aptitude Skills"
    },
    {
      label: "I am confident about my Coding Skills",
      value: "I am confident about my Coding Skills"
    }
  ];
  const HelpingHand = [
    {
      label: "I need guidance with getting placed in FAANG companies",
      value: 1
    },
    {
      label: "I need guidance with cracking Entrance test of Tech companies",
      value: 2
    },
    {
      label: "I need help in getting practical exposure with good internships",
      value: 3
    },
    { label: "I need help to prepare for aptitude skills", value: 4 },
    { label: "I need help in upskilling my softskills", value: 5 },
    { label: "I need help with my interview preparation", value: 6 },
    { label: "I need support in project based learning", value: 7 }
  ];
  function returnFalse(){
    return false
  }
  function returnTrue(value) {
    // console.log(value)
    return true
  }
  

  // console.log(errors.Email);
  return (
      <div style={{overflowX:"hidden"}}>
          <div className="d-flex justify-content-center">
      <h1 className="display-5 d-flex justify-content-center mt-5">
        Super Charge your prep for TCS{" "}
      </h1>
      </div>
      <div className="App container row " style={{ marginTop: "10px" }}>
        <div className="col-lg-6 col-md-4 col-sm-12 d-flex justify-content-center">
          {/* <pre>{JSON.stringify(watch(), null, 2)}</pre>{" "} */}
          <lottie-player
            src="https://assets8.lottiefiles.com/packages/lf20_9rfdfjek.json"
            background="transparent"
            speed="1"
            // style="width: 300px; height: 300px;"
            style={{ width: "500px", height: "500px" }}
            loop
            autoplay
          ></lottie-player>
        </div>
        <div className="col-lg-6 col-md-4 col-sm-12 my-5">
          <section
            className="shadow p-4 rounded section_1 "
            style={{ display: firstForm === true ? "block" : "none" }}
          >
            <div className="form-group">
              <label
                htmlFor="FirstName"
                className="d-flex justify-content-start mt-3"
                style={{ textAlign: "left" }}
              >
                First Name
              </label>
              <input
                id="FirstName"
                className="form-control"
                placeholder="First Name"
                {...register("FirstName", {
                  required: true
                })}
              />
              {errors.FirstName && (
                <span
                  className="text-danger d-flex justify-content-start"
                  style={{ fontSize: "0.8rem" }}
                >
                  Please enter your first name
                </span>
              )}
            </div>
            <div>
              <label
                htmlFor="Email"
                className="d-flex justify-content-start mt-3"
                style={{ textAlign: "left" }}
              >
                Email address
              </label>
              <input
                id="email"
                className="form-control"
                placeholder="Enter your email"
                {...register("email", {
                  required: true,
                  pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  validate: value => isEven(value)  
                  
                })}
              />
        {errors.email && <span className="text-danger d-flex justify-content-start " style={{fontSize:"0.8rem"}}>Your email pattern is invalid</span>}
{/*console.log(errors)*/}

              {message == false && <span className="my-0 text-danger d-flex justify-content-start"
                  style={{ fontSize: "0.8rem" }}>Your email domain is invalid</span>  }
  

              


              {/* {errors.Email && (
                <span
                  className="text-danger d-flex justify-content-start"
                  style={{ fontSize: "0.8rem" }}
                >
                  Please enter your email properly
                </span>
              )} */}
            </div>

            <div>
              <label
                htmlFor="Password"
                className="d-flex justify-content-start mt-3"
                style={{ textAlign: "left" }}
              >
                Password
              </label>
              <input
                type="password"
                className="form-control"
                placeholder="Password"
                {...register("password", {
                  required: true
                })}
              />
              {errors.password && (
                <small
                  className="text-danger  d-flex justify-content-start"
                  style={{ fontSize: "0.8rem" }}
                >
                  Please enter your password
                </small>
              )}
            </div>
            <div>
              <label
                htmlFor="FirstName"
                className="d-flex justify-content-start mt-3"
                style={{ textAlign: "left" }}
              >
                Phone Number
              </label>
              <input
                id="Number"
                className="form-control "
                placeholder="Phone Number"
                type="Number"
                {...register("PhoneNumber", {
                  required: true
                })}
              />
              {errors.PhoneNumber && (
                <small
                  className="text-danger  d-flex justify-content-start"
                  style={{ fontSize: "0.8rem" }}
                >
                  Please enter your PhoneNumber
                </small>
              )}
            </div>
            <div>
              <label
                className="d-flex justify-content-start mt-3"
                style={{ textAlign: "left" }}
              >
                Enter your city
              </label>
              <Controller
                name="City"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                    <Select
                        styles={customStyles}
                    isSearchable
                    id="City"
                    placeholder="Enter your City"
                    className="form-control "
                    options={optionCities}
                    {...field}
                  />
                )}
              />
            </div>
            <div class="form-check my-3">
              <input
                type="checkbox"
                defaultChecked={true}
                {...register("Evidence", {
                  required: true
                })}
                class="form-check-input"
                id="exampleCheck1"
              />
              <label class="form-check-label  " for="exampleCheck1">
                I agree to Cantilever Labs Terms of Service and the use of
                personal data as explained in Cantilever Labs{" "}
                <a href="/"> terms and conditions </a>
              </label>
            </div>

            <button
              disabled={(isValid !== true || message == false)}
              className="btn btn-primary px-5 my-2"
              onClick={() => {
                changeStates(setFirstForm, false, setSecondForm, true);
                onSubmit();
              }}
            >
              Submit{" "}
            </button>
          </section>

          {secondForm === true && (
            <section
              className="shadow p-4 rounded section_2 mt-5"
              style={{ display: secondForm === true ? "block" : "none" }}
            >
              <div>
                <label
                  htmlFor="Collegename"
                  className="d-flex justify-content-start mt-3"
                  style={{ textAlign: "left" }}
                >
                  College Name
                </label>
                <input
                  id="Collegename"
                  className="form-control "
                  placeholder="College Name"
                  {...register("CollegeName", {
                    required: true
                  })}
                />
                {errors.CollegeName && (
                  <small
                    className="text-danger  d-flex justify-content-start"
                    style={{ fontSize: "0.8rem" }}
                  >
                    Specify your College Name
                  </small>
                )}
              </div>
              <div>
                <label
                  htmlFor="graduationdegree"
                  className="d-flex justify-content-start mt-3"
                  style={{ textAlign: "left" }}
                >
                  Graduation Degree
                </label>
                <Controller
                  name="GraduationDegree"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                    styles={customStyles}
                      id="YearofGrad"
                      placeholder="Enter your year graduation"
                      className="form-control "
                      options={GraduationDegree}
                      {...field}
                    />
                  )}
                />

                {errors.GraduationDegree && (
                  <small
                    className="text-danger  d-flex justify-content-start"
                    style={{ fontSize: "0.8rem" }}
                  >
                    Please choose your graduation degree
                  </small>
                )}
              </div>
              <button
                disabled={isValid !== true}
                className="btn btn-primary px-5 my-2"
                onClick={() => {
                  changeStates(setSecondForm, false, setThirdForm, true);
                }}
              >
                Submit
              </button>
            </section>
          )}
          {thirdForm === true && (
            <section
              className="shadow p-4 rounded section_3 mt-5"
              style={{ display: thirdForm === true ? "block" : "none" }}
            >
              <div>
                <label
                  htmlFor="Department"
                  className="d-flex justify-content-start mt-3"
                  style={{ textAlign: "left" }}
                >
                  Department
                </label>
                <Controller
                  name="Department"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                      <Select
                          styles={customStyles}
                      id="Department"
                      placeholder="Enter your year graduation"
                      className="form-control "
                      options={graduation}
                      {...field}
                    />
                  )}
                />
                {errors.Department && (
                  <small
                    className="text-danger  d-flex justify-content-start"
                    style={{ fontSize: "0.8rem" }}
                  >
                    Please choose your Department
                  </small>
                )}
              </div>
              <div>
                <label
                  htmlFor="YearofGrad"
                  className="d-flex justify-content-start mt-3"
                  style={{ textAlign: "left" }}
                >
                  Graduation Year
                </label>
                <Controller
                  name="YearofGrad"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                    styles={customStyles}
                      id="YearofGrad"
                      placeholder="Enter your year graduation"
                      className="form-control "
                      options={year()}
                      {...field}
                    />
                  )}
                />
                {errors.YearofGrad && (
                  <small
                    className="text-danger  d-flex justify-content-start"
                    style={{ fontSize: "0.8rem" }}
                  >
                    Please choose your Year of Graduation
                  </small>
                )}
              </div>
              <button
                disabled={isValid == false}
                className="btn btn-primary px-5 my-2"
                onClick={() => {
                  changeStates(setThirdForm, false, setFourthFrom, true);
                }}
              >
                Submit
              </button>
            </section>
          )}
          {fourthForm === true && (
            <section
              className="shadow p-4 rounded section_4 mt-5"
              style={{ display: fourthForm == true ? "block" : "none" }}
            >
              <div>
                <label
                  className="d-flex justify-content-start mt-3"
                  style={{ textAlign: "left" }}
                >
                  What is your level of preparation?
                </label>
                <Controller
                  name="PreparationLevel"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                    styles={customStyles}
                      isMulti
                      placeholder="Level of your preparation"
                      className="form-control "
                      options={preparationlevel}
                      {...field}
                    />
                  )}
                />
                {errors.PreparationLevel && (
                  <small
                    className="text-danger  d-flex justify-content-start"
                    style={{ fontSize: "0.8rem" }}
                  >
                    Please choose atleast one option
                  </small>
                )}
              </div>
              <div>
                <label
                  className="d-flex justify-content-start mt-3"
                  style={{ textAlign: "left" }}
                >
                  How can we become a helping hand?
                </label>
                <Controller
                  name="HelpingHand"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                      <Select
                          styles={customStyles}
                      isMulti
                      placeholder="How can we become an helping hand?"
                      className="form-control "
                      options={HelpingHand}
                      {...field}
                    />
                  )}
                />
                {errors.HelpingHand && (
                  <small
                    className="text-danger  d-flex justify-content-start"
                    style={{ fontSize: "0.8rem" }}
                  >
                    Please choose atleast one option
                  </small>
                )}
              </div>
              <div>
                <label
                  className="d-flex justify-content-start mt-3"
                  style={{ textAlign: "left" }}
                >
                  What are you primarily looking for?
                </label>
                <Controller
                  name="primarilyLooking"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                    styles={customStyles}
                      isMulti
                      placeholder="What are you primarily looking for?"
                      className="form-control "
                      options={primaryLookingfor}
                      {...field}
                    />
                  )}
                />
                {errors.primarilyLooking && (
                  <small
                    className="text-danger  d-flex justify-content-start"
                    style={{ fontSize: "0.8rem" }}
                  >
                    Please choose atleast one option
                  </small>
                )}
              </div>
              <button
                disabled={isValid !== true}
                className="btn btn-primary px-5 my-2"
                onClick={() => {
                  changeStates(setFourthFrom, false, setfifthForm, true);
                }}
              >
                Submit
              </button>
            </section>
          )}
          {fifthForm === true && (
            <section
              className="shadow p-4 rounded section_5 mt-5"
              style={{ display: fifthForm === true ? "block" : "none" }}
            >
              <div>
                <label
                  className="d-flex justify-content-start mt-3"
                  style={{ textAlign: "left" }}
                >
                  What is your Dream company?
                </label>
                <Controller
                  name="DreamCompany"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                      <Select
                          styles={customStyles}
                      isMulti
                      placeholder="What is your Dream company?"
                      className="form-control "
                      options={DreamCompany}
                      {...field}
                    />
                  )}
                />
                {errors.DreamCompany && (
                  <small
                    className="text-danger  d-flex justify-content-start"
                    style={{ fontSize: "0.8rem" }}
                  >
                    Please choose atleast one option
                  </small>
                )}
              </div>
              <div>
                <label
                  className="d-flex justify-content-start mt-3"
                  style={{ textAlign: "left" }}
                >
                  Career Interest
                </label>
                <Controller
                  name="CareerInterest"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                      <Select
                        styles={customStyles}
                      isMulti
                      placeholder="What is your career interest"
                      className="form-control "
                      options={CareerInterest}
                      {...field}
                    />
                  )}
                />
                {errors.CareerInterest && (
                  <small
                    className="text-danger  d-flex justify-content-start"
                    style={{ fontSize: "0.8rem" }}
                  >
                    Please choose atleast one option
                  </small>
                )}
              </div>

              <button
                disabled={isValid == false}
                className="btn btn-primary px-5 my-2"
                onClick={() => {
                  changeStates(setfifthForm, false, setthanks, true);
                  onSubmitTotal();
                }}
              >
                Submit
              </button>
            </section>
          )}
          {thanks === true && (
            <section className=" congratulations shadow p-4 rounded mt-5 ">
              <div className="d-flex justify-content-center">
                <lottie-player
                  src="https://assets1.lottiefiles.com/packages/lf20_oUXj84.json"
                  background="transparent"
                  speed="1"
                  style={{ width: "150px", height: "150px" }}
                  autoplay
                ></lottie-player>
                          </div>
                          <div className="d-flex justify-content-center">
                              <p className=""> Congratulations for completing this form </p>
                              </div>
            </section>
          )}
        </div>
      </div>
    </div>
  );
}


export default OfflineWebinarForm

import React, {useState} from "react";
import "../Interview/InterviewStyle/DashboardInterview.scss";
import { useParams, useHistory, } from "react-router-dom";
import configAxios from "../../axios.config";
function DashboardInterview() {
  const [nameData, setNameData] = useState([])
  let { id } = useParams
  let history = useHistory();
  function NextPage(){
    history.push('/interviewpractice')
  }
 
 
  configAxios(localStorage.getItem("token"))
  .get(`/openai/get-interview-types/`)
  .then(({ data })=>{
    // console.log(data.types[0].typeName)
    setNameData(data.types[0].typeName)
  })
  .catch((err)=>{
    console.log(err);
  })



  return (
    <>
      <div id="DashboardInterview">
        <div className="HRInterview" onClick={NextPage}>
          <div className="interviewTitle">{nameData}</div>
          <div className="interviewImg">
            <img className="HrImage" src="https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1671432278595_image%2024258.png" alt="HrImg" />
          </div>
        </div>
        <div className="TechnicalInterview" onClick={NextPage}>
          <div className="TechnicalTitle">Technical</div>
          <div className="TechnicalImg"><img className="TechImage" src="https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1671432313716_image%2024259.png" alt="Techimg" /></div>
        </div>
      </div>
    </>
  );
}

export default DashboardInterview;

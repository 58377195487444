import { useContext, useState, useEffect, useLayoutEffect } from "react";
// import HeroSection from "../components/HeroSection";
import MeetOurPartners from "../components/MeetPartners";
import Course from "../components/course";
// import CollegeCollab2 from "../components/CollegeCollab2";
import Internship from "../components/Internship";
import Masterclass from "../components/Masterclass";
import MoreQueries from "../components/MoreQueries";
import { ModalContext } from "../Context/ModalContext";
// import Student from "../componentsofcourse/Student";
// import Mentors from "../componentsofcourse/Mentors";
import Awards from "../components/Awards";
import Footer from "../components/Footer";
import PressRecognition from "../components/PressRecognition";
// import Navbar from "../components/Navbar";
// import NewNavbar from "../components/NewNavbar";
import { BASE_URL, propertyId, tawkId } from "../env";
// import Test from "../componentsofcourse/Test";
// import Mentor from "../componentsofcourse/Mentor";
import Maintestimonial from "./Maintestimonial";
import Testimonial from "../components/TestimonialsVersion2";
import NewHeroSection from "./NewHeroSection";
// import Slider from "../componentsofcourse/Slider";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";
import TawkTo from "tawkto-react";
import B2BNewMentors from "../components/b2bcomponents/dist/B2BNewMentors";

function Home() {
  ReactGA.initialize("UA-198180460-1");
  let { open, update_status } = useContext(ModalContext);
  const [modal, setModal] = useState(false);

  const [isloggedin, setisloggedin] = useState(false);
  const [isInterested, setisInterested] = useState();

  const [nevershow, setnevershow] = useState(false);
  useEffect(() => {
    if (localStorage.getItem("token") !== undefined) {
      setisloggedin(true);
      // alert(true)
      fetch(BASE_URL + "/getProfile", {
        headers: { authorization: "Bearer " + localStorage.getItem("token") },
      })
        .then((res) => res.json())
        .then((data) => {
          // console.log(data.user);
          setisInterested(data?.user?.interestedCourse);
          // setData(data);
          // setLoggedin(true);
          // setId(data.user._id);
          // // set_Id(data.user._id);
          // setFirstName(data.user.firstName);
          // setIsAdmin(data.user.isAdmin);
        });
    }
  }, []);

  useEffect(() => {
    var tawk = new TawkTo(propertyId, tawkId);

    tawk.onStatusChange((status) => {
      console.log(status);
    });
  }, []);

  const [showTheUser, setshowTheUser] = useState(false);
  const [showTheOfflineUser, setshowTheOfflineUser] = useState(false);
  // const [sh]
  useEffect(() => {
    if (!localStorage.getItem("token")) {
      setTimeout(() => {
        setshowTheOfflineUser(true);
      }, 10000);
    } else {
      if (isInterested == false) {
        if (isloggedin) {
          setTimeout(() => {
            setshowTheUser(true);
          }, 10000);
        }
      }

      // alert(isloggedin)
      // setshowTheUser(true)
    }
    // alert(isloggedin)
  }, [isInterested]);

  // function InterestedUser() {
  //   fetch(BASE_URL + "/userInterested", {
  //     method: "PATCH",
  //     headers: {
  //       "Content-Type": "application/json",
  //       authorization: "Bearer " + localStorage.getItem("token"),
  //     },
  //     body: JSON.stringify({
  //       interestedCourse: true,
  //     }),
  //   })
  //     .then((response) => {
  //       // console.log(response);
  //       // signin("final");
  //       // PushTheUser();
  //     })
  //     .catch((err) => {
  //       // console.log(err);
  //     });
  //   PushTheUser();
  // }

  // function CloseTheButton() {
  //   // alert('close')
  //   setshowTheUser(false);
  //   setshowTheOfflineUser(false);
  //   setnevershow(true);
  // }
  // function PushTheUser() {
  //   // alert('close')
  //   setshowTheUser(false);
  //   setshowTheOfflineUser(false);
  // }
  
  useEffect(() => {
    update_status(!open);
    setModal();
  }, [modal]);
  useLayoutEffect(() => {
    // window.scrollTo(0, 0);
  });
  const mentors = [
    {
      linkdin:"https://www.linkedin.com/in/manishkankani/",
      mentorImg:"https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1679397069561_manish.jpeg",
      name: "Manish Kankani",
      description: "Google Tech Lead",
      role: "IIT Roorkee",
      companyImg: "https://cantileverlabs-gradient-images.s3.ap-south-1.amazonaws.com/company+logos/IMG-20220923-WA0007.jpg",
    },
    {
      linkdin:"https://www.linkedin.com/in/vivek-jhaver-8853a261/",
      mentorImg:"https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1679396258661_vivek.jpeg",
      name: "Vivek Jhaver",
      description: "SDE-2, Amazon",
      role: "VNIT Nagpur",
      companyImg: "https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1679404461526_Amazon_logo.svg.png",
    },
    {
      linkdin:"https://www.linkedin.com/in/sureshsan/",
      mentorImg:"https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1679396313039_suresh.jpeg",
      name: "Suresh Santhanam",
      description: "Software Eng.Microsoft",
      role: "IIIT Hyderabad",
      companyImg: "https://cantileverlabs-gradient-images.s3.ap-south-1.amazonaws.com/company+logos/IMG-20220923-WA0003.jpg",
    },
    {
      linkdin:"https://www.linkedin.com/in/vivek-jhunjhunwala/",
      mentorImg:"https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1679396370162_vivek1.jpeg",
      name: "Vivek",
      description: "Partner At Quadeye",
      role: "IIT Bombay",
      companyImg: "https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1679395536858_logos.jpg",
    },
    {
      linkdin:"https://www.linkedin.com/in/sanjay-nair-/",
      mentorImg:"https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1679396399927_sanjay.jpeg",
      name: "Sanjay Nair",
      description: "Associate McKinsey",
      role: "ISB Hyderabad",
      companyImg: "https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1679395848011_McKinsey-Logo.png",
    },
    {
      linkdin:"https://www.linkedin.com/in/amitvaid/",
      mentorImg:"https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1679396431218_amit.jpeg",
      name: "Amit Vaid",
      description: "voice President State Street",
      role: "VTU Bangalore",
      companyImg: "https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1679395702709_purepng.com-state-street-logologobrand-logoiconslogos-251519938342d6s4u.png",
    },
    {
      linkdin:"https://www.linkedin.com/in/avanish-mundhra-6a938848/?originalSubdomain=in",
      mentorImg:"https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1679396461896_avanish.jpeg",
      name: "Avanish Mundra",
      description: "CEO - Cantilever Labs",
      role: "VTU",
      companyImg: "https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1679394595457_1644088580619_cantileverlogoblack.png",
    },
    {
      linkdin:"https://www.linkedin.com/in/drishti-mamtani-572338159/",
      mentorImg:"https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1679396484799_drishti.jpeg",
      name: "Drishti Mamtani",
      description: "Software Engineer Google",
      role: "BITS'21",
      companyImg: "https://cantileverlabs-gradient-images.s3.ap-south-1.amazonaws.com/company+logos/IMG-20220923-WA0007.jpg",
    },
    {
      linkdin:"https://www.linkedin.com/in/farheen-bano/",
      mentorImg:"https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1679396502605_fareen.jpeg",
      name: "Fareen Bano",
      description: "SDE-2 PayPal",
      role: "IIIT Bombay",
      companyImg: "https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1679395406081_Paypal-logo.png",
    },
  ];
  return (
    <>
      {/* <NewNavbar /> */}
      {/* <Navbar /> */}
      {/* <JoinTelegramBanner /> */}
      {/* <JoinTelegramBanner/> */}
      <Helmet>
        <meta name="og_title" property="og:title" content="Cantilever Labs" />
        <meta name="og_type" property="og:type" content="website" />
        <meta
          name="og_url"
          property="og:url"
          content="https://www.cantileverlabs.com/"
        />
        <meta
          name="og_site_name"
          property="og:site_name"
          content="cantilever labs"
        />
        <meta
          name="og_description"
          property="og:description"
          content="Train your skillset. Thrive to be the best. - With Cantilever Labs"
        />
        <meta
          name="og_image"
          property="og:image"
          content="https://d8it4huxumps7.cloudfront.net/uploads/images/150x150/60c9c4b8da242_logo.jpg?d=110x110"
        />
        <meta name="twitter:card" property="twitter:card" content="summary" />
        <meta
          name="twitter:url"
          property="twitter:url"
          content="https://www.cantileverlabs.com"
        />
        <meta
          name="twitter:site"
          property="twitter:site"
          content="https://www.cantileverlabs.com"
        />
        <meta
          name="twitter:title"
          property="twitter:title"
          content="Cantilever labs"
        />
        <meta
          name="twitter:description"
          property="twitter:description"
          content="Train your skillset. Thrive to be the best. - With Cantilever Labs"
        />
        <meta
          name="og_image"
          property="og:image"
          content="https://d8it4huxumps7.cloudfront.net/uploads/images/150x150/60c9c4b8da242_logo.jpg?d=110x110"
        />
        <meta
          name="msapplication-TileImage"
          content="https://d8it4huxumps7.cloudfront.net/uploads/images/150x150/60c9c4b8da242_logo.jpg?d=110x110"
        />
        <meta
          property="og:image:type"
          content="https://d8it4huxumps7.cloudfront.net/uploads/images/150x150/60c9c4b8da242_logo.jpg?d=110x110"
        />
        <meta
          name="twitter:image"
          property="twitter:image"
          content="https://d8it4huxumps7.cloudfront.net/uploads/images/150x150/60c9c4b8da242_logo.jpg?d=110x110"
        />
      </Helmet>
      {/* <PopupAd /> */}
      {/* {showTheUser == true && nevershow == false && (
        <TelegramPopup setShowModel={CloseTheButton} />
      )}

      {showTheOfflineUser == true && nevershow == false && (
        <TelegramPopup setShowModel={CloseTheButton} />
      )} */}
      <div style={{ overflowX: "hidden" }}>
        {/* <HeroSection /> */}
        <NewHeroSection />
        {/* <Slider/> */}
        <MeetOurPartners />
        <Course />
        <Maintestimonial />
        {/* <Test/> */}
        <Testimonial />
        {/* <CollegeCollab2 /> */}
        <Internship />
        {/* <Student /> */}
        <br />

        <br />
        <h1
          style={{ fontFamily: "Poppins-Bold" }}
          className="text-black text-center"
        >
          Meet Your Mentors
        </h1>
        <h4
          style={{ fontFamily: "Poppins" }}
          className="text-black text-center"
        >
          Turning students into professionals
        </h4>
        <br />

        <br />
        <div
          className='row mewMentor'>
          {/* <B2BMentorsNew /> */}
          {mentors.map((val, i) => {
            return (
              <>
                <B2BNewMentors
                mentorLinkdin={val.linkdin}
                mentorsLogo={val.mentorImg}
                  mentorName={val.name}
                  mentorDesc={val.description}
                  mentorCompany={val.role}
                  companyImage={val.companyImg}
                />
              </>
            );
          })}
        </div>
        {/* <Mentor />  */}
        <Awards /> 
        <PressRecognition /> 
        <Masterclass /> 
        <MoreQueries />
        <Footer />
      </div>
    </>
  );
}
export default Home;

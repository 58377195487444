import React, { Component } from "react";
import styles from "../../Assets/css/Blog2.css";

class Tabs extends Component {
  state = {
    selected: this.props.selected || 0,
  };

  handleChange(index) {
    this.setState({ selected: index });
  }

  render() {
    return (
      <div
        style={{ position: "relative", alignItems: "center", bottom: "9vw" }}
      >
        <div style={{ background: "#f2f7fb" }}>
          <ul
            style={{
              display: "inline-block",
              // paddingTop: "1%",
              textAlign: "center",
              justifyContent: "center",
              margin: "0",
              width: "100%",
              background: "#f2f7fb",
            }}
          >
            {this.props.children.map((elem, index) => {
              let style = index === this.state.selected ? "selected" : "";
              return (
                <li
                  key={index}
                  className={style}
                  style={{ padding: "25px" }}
                  onClick={() => this.handleChange(index)}
                >
                  {elem.props.title}
                </li>
              );
            })}
          </ul>
        </div>

        <div className={styles.tab}>
          {this.props.children[this.state.selected]}
        </div>
      </div>
    );
  }
}

export default Tabs;

import { useState } from "react";
import BannerComp from "../components/BannerComp";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import style from "../Assets/css/quant-finance.module.css";
import useMedia from "../componentsofcourse/useMedia";
import { LastSection } from "./NewMasterClass";
import img1 from "../Assets/newcoursepage_images/mentor.svg";
import img2 from "../Assets/newcoursepage_images/training.svg";
import img3 from "../Assets/newcoursepage_images/mock test.svg";
import img4 from "../Assets/newcoursepage_images/interview.svg";
import Tilty from "react-tilty";
import mentr1 from "../Assets/newcoursepage_images/mentors/ayush.svg";
import mentr2 from "../Assets/newcoursepage_images/mentors/tulsi.svg";
import mentr3 from "../Assets/newcoursepage_images/mentors/avanishMundra.svg";
import mentr4 from "../Assets/newcoursepage_images/mentors/sanchitJain.svg";
import ReactGA from "react-ga";
import ds from "../Assets/img/DATA STRUCTURE/1.png";
import network from "../Assets/img/DATA STRUCTURE/3.png";
import py from "../Assets/img/DATA STRUCTURE/4.png";
import prob from "../Assets/img/DATA STRUCTURE/5.png";
import tm from "../Assets/img/DATA STRUCTURE/7.png";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL } from "../env";
import { Link, useLocation } from 'react-router-dom';

function QuantFinancePg() {
  const matches = useMedia("(min-width : 768px )");
  const location = useLocation();
  // const [allowed, setallowed] = useState(false);
  // const [modalShow, setModalShow] = useState(false);

  return (
    <div style={{ backgroundColor: "#f5f5f5" }}>
      {/* <Navbar /> */}
      <div className={style.headImg}>
        <div className={style.headImgText}>
          {/* <div style={{paddingTop:"30vw"}}> */}
          <h1
            className={style.headImgTextTop}
            style={{ marginBottom: "3vw", marginTop: "-5vw" }}
          >
            Quant Finance Course
          </h1>

          {matches ? (
            <>
              <p
                className={style.headImgTextBottom}
                style={matches ? { fontSize: "28px", marginBottom: "2vw" } : {}}
              >
                Build a foundation for your placement for the roles like Data
                Analyst, Financial Analyst etc. with this crash course on Quant
                Finance. Master a wide range of theoretical knowledge underlying
                quant finance & learn how to apply it in practice.
              </p>
            </>
          ) : (
            ""
          )}
          {/* <Modal /> */}
          <Link to='/register' onClick={() => {
            localStorage.setItem('userlocation', location.pathname);
          }}>
          <button className={style.headImgBtn}>Get Started</button>
          </Link>
        </div>
      </div>

      <div style={{ marginTop: "50px" }}>
        <div className={style.quant}>
          <img src="https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644325407825_q_c.png"
          alt="quant" className={style.desc} />

          <h2 className={style.quantText}>Course Description</h2>
          <p className={style.content}>
            Using this Quant Finance Course, designed by industry experts, you
            can master the principles of resilient quant finance skills which
            integrate mathematical, statistical, and computer science tools with
            finance theory and professional business skills in a completely
            online and collaborative setting. You will be positioned to excel in
            today’s highly collaborative, fast-paced, professional environment.
            <br />
            <br />
            Our educators teach skills that are applicable to the majority of
            industries, such as evaluating statistics and doing econometric
            modeling. Graduates with knowledge of quant finance can work for
            general manufacturing and service firms as well as positions in
            securities, banking, and financial management. In addition to these
            basics, the comprehensive program also equips students with basic
            presentation skills required in a professional business setting.
          </p>
        </div>
        {/* <BannerComp from="IIT Bombay" /> */}
        <br />
        <br />
        <h1 className={style.course}>Course Offerings</h1>
        <div>
          <div style={{ margin: "0px 20px" }}>
            <div className="row d-flex justify-content-center">
              <Tilty
                axis="X"
                glare="false"
                max="45"
                perspective={1000}
                easing="cubic-bezier(.03,.98,.52,.99)"
                className="col-sm Tilt  p-4 shadow"
                style={{
                  transformStyle: "preserve-3d",
                  backgroundColor: "#FAFAFA",
                  margin: "1vh 2vw",
                  borderRadius: "1rem",
                  padding: "0.5rem",
                }}
              >
                <div
                  style={{
                    margin: "5%",
                    transform: "translateZ(30px)",
                    textAlign: "center",
                  }}
                >
                  <img
                    src={img1}
                    alt="mentor"
                    style={{
                      backgroundColor: "#E1E1E1",
                      padding: "2%",
                      borderRadius: "10px",
                      width: "70px",
                      height: "70px",
                    }}
                  />
                  <br />
                  <br />
                  <h3 style={{ color: "#185ADB", fontWeight: "600" }}>40+</h3>
                  <p style={{ fontWeight: "900", fontSize: "1.2rem" }}>
                    Hours of
                    <br /> Content
                  </p>
                  <br />
                </div>
              </Tilty>
              <Tilty
                axis="X"
                glare="false"
                max="45"
                perspective={1000}
                easing="cubic-bezier(.03,.98,.52,.99)"
                className="col-sm Tilt  p-4 shadow"
                style={{
                  transformStyle: "preserve-3d",
                  backgroundColor: "#FAFAFA",
                  margin: "1vh 3vw",
                  borderRadius: "1rem",
                  padding: "0.5rem",
                }}
              >
                <div
                  style={{
                    margin: "5%",
                    transform: "translateZ(30px)",
                    textAlign: "center",
                  }}
                >
                  <img
                    src={img2}
                    alt="training"
                    style={{
                      backgroundColor: "#E1E1E1",
                      padding: "2%",
                      borderRadius: "10px",
                      width: "70px",
                      height: "70px",
                    }}
                  />
                  <br />
                  <br />
                  <h3 style={{ color: "#185ADB", fontWeight: "600" }}>18</h3>
                  <p style={{ fontWeight: "900", fontSize: "1.2rem" }}>
                    Knowledge Session
                  </p>
                  <br />
                </div>
              </Tilty>
              <Tilty
                axis="X"
                glare="false"
                max="45"
                perspective={1000}
                easing="cubic-bezier(.03,.98,.52,.99)"
                className="col-sm Tilt  p-4 shadow"
                style={{
                  transformStyle: "preserve-3d",
                  backgroundColor: "#FAFAFA",
                  margin: "1vh 3vw",
                  borderRadius: "1rem",
                  padding: "0.5rem",
                }}
              >
                <div
                  style={{
                    margin: "5%",
                    transform: "translateZ(30px)",
                    textAlign: "center",
                  }}
                >
                  <img
                    src={img3}
                    alt={img3}
                    style={{
                      backgroundColor: "#E1E1E1",
                      padding: "2%",
                      borderRadius: "10px",
                      width: "70px",
                      height: "70px",
                    }}
                  />
                  <br />
                  <br />
                  <h3 style={{ color: "#185ADB", fontWeight: "600" }}>2</h3>

                  <p style={{ fontWeight: "900", fontSize: "1.2rem" }}>
                    Off-Campus Opportunites
                  </p>
                  <br />
                </div>
              </Tilty>
              <Tilty
                axis="X"
                glare="false"
                max="45"
                perspective={1000}
                easing="cubic-bezier(.03,.98,.52,.99)"
                className="col-sm Tilt  p-4 shadow"
                style={{
                  transformStyle: "preserve-3d",
                  backgroundColor: "#FAFAFA",
                  margin: "1vh 3vw",
                  borderRadius: "1rem",
                  padding: "0.5rem",
                }}
              >
                <div
                  style={{
                    margin: "5%",
                    transform: "translateZ(30px)",
                    textAlign: "center",
                  }}
                >
                  <img
                    src={img4}
                    alt={img4}
                    style={{
                      backgroundColor: "#E1E1E1",
                      padding: "2%",
                      borderRadius: "10px",
                      width: "70px",
                      height: "70px",
                    }}
                  />
                  <br />
                  <br />
                  <h3 style={{ color: "#185ADB", fontWeight: "600" }}>1</h3>

                  <p style={{ fontWeight: "900", fontSize: "1.2rem" }}>
                    Mock <br />
                    Interview
                  </p>
                  <br />
                </div>
              </Tilty>
            </div>
          </div>
        </div>
        <div>
          <h1 className={style.course}>Course Curriculum</h1>
          <div className={style.cards}>
            <div className={style.card}>
              <img src={ds} alt="ds" />
            </div>
            <div className={style.card}>
              <img src="https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644326470726_operating%20system2.png" alt="os" />
            </div>
            <div className={style.card}>
              <img src={network} alt="networking" />
            </div>
            <div className={style.card}>
              <img src={py} alt="python" />
            </div>
          </div>
          <div className={style.cards}>
            <div className={style.card}>
              <img src={prob} alt="probability" />
            </div>
            <div className={style.card}>
              <img src="https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644328236178_6.png" alt="statistics" />
            </div>
            <div className={style.card}>
              <img src={tm} alt="Trading Markets" />
            </div>
            <div className={style.card}>
              <img src="https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644326812781_trading%20psychology8.png" alt="Trading Psychology" />
            </div>
          </div>
        </div>

        <div className="container text-center my-3">
          <h1 className={style.course}>Our Expert Mentors</h1>
          <p className={style.cons}>Transforming Students Into Professionals</p>
          <br />
          <div className="my-auto">
            <div className={style.card + "container row"}>
              <div className="col-md-3" style={{ borderRadius: "20px" }}>
                <a
                  href="https://www.linkedin.com/in/ayush003/"
                  target="_blank"
                  onClick={() => {
                    ReactGA.event({
                      category: "Mentors",
                      type: "Mentor Linkedin",
                      name: "Manish Kankani LinkedIn Profile",
                      action: "Manish Kankani LinkedIn Profile",
                    });
                  }}
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <img src={mentr1} width="110%" alt="no img" />
                </a>
              </div>
              <div className="col-md-3" style={{ borderRadius: "20px" }}>
                <a
                  href="https://www.linkedin.com/in/tulasi-chandana-340287178/"
                  target="_blank"
                  onClick={() => {
                    ReactGA.event({
                      category: "Mentors",
                      type: "Mentor Linkedin",
                      name: "Vivek Jhaver LinkedIn Profile",
                      action: "Vivek Jhaver LinkedIn Profile",
                    });
                  }}
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <img src={mentr2} width="110%" alt="no img" />
                </a>
              </div>
              <div className="col-md-3" style={{ borderRadius: "20px" }}>
                <a
                  href="https://www.linkedin.com/in/avanish-mundhra-6a938848/"
                  target="_blank"
                  onClick={() => {
                    ReactGA.event({
                      category: "Mentors",
                      type: "Mentor Linkedin",
                      name: "Sureshsan  LinkedIn Profile",
                      action: "Sureshan  LinkedIn Profile",
                    });
                  }}
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <img src={mentr3} width="110%" alt="no img" />
                </a>
              </div>
              <div className="col-md-3" style={{ borderRadius: "20px" }}>
                <a
                  href="https://www.linkedin.com/in/jsanchit1103"
                  target="_blank"
                  onClick={() => {
                    ReactGA.event({
                      category: "Mentors",
                      type: "Mentor Linkedin",
                      name: "Vivek Jhaver LinkedIn Profile",
                      action: "Vivek Jhaver LinkedIn Profile",
                    });
                  }}
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <img src={mentr4} width="110%" alt="no img" />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div style={{ marginTop: "-50px" }}>
            <h1
              className={style.course}
              style={{ textAlign: "center", marginBottom: "-100px" }}
            >
              Our Success Story
            </h1>
            <div style={{ margin: "0px 40px" }}>
              <BannerComp from="IIT Bombay" />
            </div>
          </div>

          <section
            className={style.lastSection}
            style={{ marginTop: "-100px" }}
          >
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <h4 className={style.lastSectionHeading}>
                    Explore more of our offerings
                  </h4>
                </div>
              </div>

              <LastSection from="IIT Bombay" />
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
}

const Modal = () => {
  const [next, setNext] = useState(false);
  const [data, setData] = useState({
    firstName: "",
    email: "",
    phoneNumber: "",
    College: "",
  });

  const PostData = async () => {
    try {
      const res = await axios({
        method: "POST",
        data: data,
        url: `${BASE_URL}/quantFinancePost`,
      });
      setNext(true);
    } catch (error) {
      toast("Something went wrong", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const InputEvent = (event) => {
    const { name, value } = event.target;

    setData((preVal) => {
      return {
        ...preVal,
        [name]: value,
      };
    });
  };

  const formSubmit = (e) => {
    e.preventDefault();
    PostData();
  };
  return (
    <>
      <Popup
        trigger={<button className={style.headImgBtn}>Get Started</button>}
        position="center"
        modal
      >
        {(close) => (
          <div className={style.Popup}>
            <i
              className={`fas fa-times ${style.closeButton}`}
              onClick={() => {
                close();
              }}
            ></i>
            {next ? (
              <>
                <div className={style.thanks}>
                  <img src="https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644328062854_tick.png" className="img-fluid" alt="tick icon" />
                  <div className={style.thanksText}>Thank You</div>
                </div>
              </>
            ) : (
              <>
                <div className={style.heading}>Get Started</div>
                <form onSubmit={formSubmit}>
                  <input
                    type="text"
                    className={`form-control ${style.popupForm}`}
                    name="firstName"
                    value={data.firstName}
                    onChange={InputEvent}
                    placeholder="Fullname"
                    required
                  />
                  <input
                    type="email"
                    className={`form-control ${style.popupForm}`}
                    name="email"
                    value={data.email}
                    onChange={InputEvent}
                    placeholder="Email"
                    required
                  />
                  <input
                    type="tel"
                    className={`form-control ${style.popupForm}`}
                    name="phoneNumber"
                    value={data.phoneNumber}
                    onChange={InputEvent}
                    placeholder="Phone"
                    required
                  />
                  <input
                    type="text"
                    className={`form-control ${style.popupForm}`}
                    name="College"
                    value={data.College}
                    onChange={InputEvent}
                    placeholder="College"
                    required
                  />
                  <button type="submit" className={`btn ${style.submitBtn}`}>
                    Submit
                  </button>
                </form>
              </>
            )}
          </div>
        )}
      </Popup>
    </>
  );
};

export default QuantFinancePg;

import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import style from "../Assets/css/MasterLinkedIn.module.css";
import { BASE_URL } from "../env";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import PlayBtn from "../Assets/img/IITBombay/play.png";
import { useHistory } from "react-router-dom";

const MasterLinkedIn = (props) => {
  let history = useHistory();
  const handleClick = async (id) => {
    await fetch(BASE_URL + "/addMasterClassInStudent/" + id, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((data) => {
        document.location.reload();
        // console.log("Response from second form", data);
      });
  };

  // console.log(props.canRegister);
  const { id } = useParams();
  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");
  const [video_link, setVideoLink] = useState("");
  const [duration, setDuration] = useState("");
  const [endTime, setEndTime] = useState("");
  useEffect(() => {
    fetch(BASE_URL + "/getOfflineMasterClass/" + id, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((data) => {
        //  console.log("WEbinar Data particular", data);
        setSubject(data.message.subject);
        setDescription(data.message.description);
        setVideoLink(data.message.video_link);
        setDuration(data.message.duration);
        setEndTime(data.message.endTime);
      });
  });
  return (
    <div className={" container my-5 " + style.mainDiv}>
      <div className="row">
        <div className="col-12">
          <h1 className={style.heading}>{subject}</h1>
          <p className={style.timings}>
            Duration: {duration} Price:Free Venue:Online
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          {props.allowed ? (
            <ReactPlayer url={video_link} controls width="100%" />
          ) : (
            <div
              style={{
                backgroundColor: "black",
                height: "120%",
                width: "80%",
                textAlign: "center",
              }}
            >
              <div
                // to={{
                // 	pathname: "/OfflineMasterClass",
                // 	state: { offlineId: id },
                // }}
                onClick={() => {
                  handleClick(id);
                }}
              >
                <img
                  src={PlayBtn}
                  className="img-fluid"
                  style={{
                    position: "absolute",
                    marginTop: "90px",
                  }}
                  alt="play icon"
                />
              </div>
            </div>
          )}
        </div>
        <div className={`col-md-6 ${style.rightdiv}`}>
          <h1 className={`${style.rightheading}`}>About the Master Class</h1>
          <p className={style.righttxt}>{description}</p>
          {!props.allowed &&
            // alert(props.canRegister)
            (props.canRegister ? (
              <div
                // to={{
                // 	pathname: "/OfflineMasterClass",
                // 	state: { offlineId: id },
                // 	}}
                onClick={() => {
                  handleClick(id);
                }}
                className={style.registerbtn}
              >
                Register by a Click
              </div>
            ) : (
              <Link
                to={{
                  pathname: "/OfflineMasterClass/" + id,
                }}
                className={style.registerbtn}
              >
                Register Now
              </Link>
            ))}
        </div>
      </div>
    </div>
  );
};

export default MasterLinkedIn;

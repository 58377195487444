import React, { useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import styles from "../Assets/css/CpSyllabus.module.css";

const CpSyllabus = (props) => {
  const styles = {
    modal: {
      position: "fixed",
      left: "0",
      top: "0",
      right: "0",
      bottom: "0",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      //   opacity: "0",
      transition: "all 0.3s ease-in-out",
      pointerEvents: "none",
    },
  };

  const closeOnEscapeKeyDown = (e) => {
    if ((e.charCode || e.keyCode) === 27) {
      props.onClose();
    }
  };

  useEffect(() => {
    document.body.addEventListener("keydown", closeOnEscapeKeyDown);
    return function cleanup() {
      document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
    };
  }, []);

  return (
    <CSSTransition
      in={props.show}
      unmountOnExit
      timeout={{ enter: 0, exit: 300 }}
    >
      <div className={styles.modal} onClick={props.onClose} >
        <div className="modal-content" onClick={(e) => e.stopPropagation()} style={{ width: "700px" }}>
          <div class="container justify-content-center my-5">
            <div
              style={{ height: "500px", overflow: "auto" }}
              className="container"
            >
              <div
                style={{ width: "auto" }}
                className="row  my-3 justify-content-center "
              >
                <div
                  style={{ textAlign: "left", width: "60%" }}
                  className="syllabus-heading-J col-xl-4 col-lg-4 col-md-4"
                >
                  <h2>{props.tiername ? props.tiername : "Force"}</h2>
                  <p>Basic & Advanced DSA</p>
                </div>
              </div>

              <div className="row my-3 justify-content-center ">
                <div
                  style={{ textAlign: "left", width: "60%" }}
                  className="gray-J col-xl-4 col-lg-4 col-md-4"
                >
                  <ul style={{ listStyleType: "none" }}>
                    <h4>Basics</h4>
                    <li>Basics of Competitive Coding</li>
                    <li>Various type of errors</li>
                    <li>Basic of Recursion</li>
                    <li>Time Complexity</li>
                    <li>Space Complexity</li>
                  </ul>
                </div>
              </div>

              <div className="row my-3 justify-content-center ">
                <div
                  style={{ textAlign: "left", width: "60%" }}
                  className="gray-J col-xl-4 col-lg-4 col-md-4"
                >
                  <ul style={{ listStyleType: "none" }}>
                    <h4>STL</h4>
                    <li>Vectors</li>
                    <li>Maps</li>
                    <li>Pairs</li>
                    <li>List</li>
                    <li>STL algorithms</li>
                  </ul>
                </div>
              </div>

              <div className="row my-3 justify-content-center ">
                <div
                  style={{ textAlign: "left", width: "60%" }}
                  className="gray-J col-xl-4 col-lg-4 col-md-4"
                >
                  <ul style={{ listStyleType: "none" }}>
                    <h4>Searching and Sorting</h4>
                    <li>Linear Search</li>
                    <li>Binary Search</li>
                    <li>Bubble Sort</li>
                    <li>Selection Sort</li>
                    <li>Insertion Sort</li>
                    <li>Count Sort</li>
                    <li>Merge Sort</li>
                    <li>Quick Sort</li>
                  </ul>
                </div>
              </div>

              <div className="row my-3 justify-content-center ">
                <div
                  style={{ textAlign: "left", width: "60%" }}
                  className="gray-J col-xl-4 col-lg-4 col-md-4"
                >
                  <ul style={{ listStyleType: "none" }}>
                    <h4>DSA</h4>
                    <li>Arrays</li>
                    <li>Strings</li>
                    <li>Linked List</li>
                    <li>Stacks</li>
                    <li>Queues</li>
                    <li>Trees</li>
                    <li>Heaps</li>
                    <li>Graphs</li>
                    <li>Fenwick Trees</li>
                    <li>Segment Trees</li>
                  </ul>
                </div>
              </div>

              <div className="row my-3 justify-content-center ">
                <div
                  style={{ textAlign: "left", width: "60%" }}
                  className="gray-J col-xl-4 col-lg-4 col-md-4"
                >
                  <ul style={{ listStyleType: "none" }}>
                    <h4>Programming Techniques and Methods</h4>
                    <li>2 Pointer Approach</li>
                    <li>Greedy Programming</li>
                    <li>Dynamic Programming</li>
                    <li>Recursion</li>
                    <li>Bit Masking</li>
                    <li>Dp with Bitmasking</li>
                    <li>Game Theory</li>
                    <li>Number Theory</li>
                    <li>Computational Geometry</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="modal-header">
            <h4 className="modal-title">
              <h2>Force</h2>
              <p>Basic & Advanced DSA</p>
            </h4>
          </div> */}
          {/* <div className="modal-body">
          <div className="row my-3 justify-content-center ">
            <div
              style={{ textAlign: "left" }}
              className="gray-J col-xl-4 col-lg-4 col-md-4"
            >
              <ul style={{ listStyleType: "none" }}>
                <h4>Basics</h4>
                <li>Basics of Competitive Coding</li>
                <li>Various type of errors</li>
                <li>Basic of Recursion</li>
                <li>Time Complexity</li>
                <li>Space Complexity</li>
              </ul>
            </div>
            </div>
          </div> */}
          {/* <div className="modal-footer">
            <button onClick={props.onClose} className="button">
              Close
            </button>
          </div> */}
        </div>
      </div>
    </CSSTransition>
  );
};

export default CpSyllabus;

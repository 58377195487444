import DiffCategory from './DiffCategory'

function CategorySection({category}) {
  return (
    <div className='row assignmentCards'>
    {
      category && category.map((val, index) => {
        return <DiffCategory 
          key={val._id}
          link={`/compiler/${val._id}/16`}
          name={val.name}
          category={val.category}
          level={val.questionWeigh}
          company={val.company ? val.company : []}
        />
      })
    }
</div>
  )
}

export default CategorySection
import { createContext, ReactNode, useEffect, useState } from "react";
import configAxios, { configAxiosLambda } from "../../axios.config";
import { useHistory } from "react-router-dom";

interface IDashboardContextProps {
  children: ReactNode;
}

interface IDashboardData {
  courseId: undefined | string;
  tab: number;
  setTab: React.Dispatch<React.SetStateAction<number>>;
  unlockedComponent: Array<any>;
  setCourseId: React.Dispatch<React.SetStateAction<string | undefined>>;
  perName: string;
  profileImg: string;
  cantileverLogo: string;
}

export const DashboardDataContext = createContext<IDashboardData>({
  courseId: undefined,
  tab: 1,
  setTab: () => 1,
  unlockedComponent: [],
  setCourseId: () => undefined,
  perName: "",
  profileImg: "",
  cantileverLogo: ""
});

export default function DashboardContext({ children }: IDashboardContextProps) {
  const [tab, setTab] = useState(1);
  const [unlockedComponent, setUnlockedComponent] = useState([]);
  const [companyLogo, setCompanyLogo] = useState("");
  const [cantileverLogo, setCantileverLogo] = useState("");
  const [profileImg, setProfileImg] = useState("");
  const [perName, setPerName] = useState("");
  const [courseId, setCourseId] = useState<undefined | string>();
  const [dashboardOverview, setDashboardOverview] = useState({
    topics: undefined,
    objective: undefined,
    takeaway: undefined,
    courseLevel: undefined,
    name: "",
    schedule: undefined,
    dateAdded: undefined,
    courseDeadline: undefined,
    progressBarType: "static"
  });
  const [toolkits, setToolKits] = useState([]);
  const [goPoints, setGoPoints] = useState(0);
  // const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    const controller = new AbortController();
    configAxiosLambda(localStorage.getItem("token") || "")
      .get("/getProfile", {
        signal: controller.signal,
      })
      .then(({ data }) => {
        setPerName(`${data?.user?.firstName} ${data?.user?.lastName}`);
        setProfileImg(data?.user?.profile_pic);
        setGoPoints(data.user.goPoints);
      });

    return () => controller.abort();
  }, []);

  useEffect(() => {
    if (!courseId || !history) return;
    const controller = new AbortController();

    configAxios(localStorage.getItem("token"))
      .get(`/getCourse/${courseId}`, {
        signal: controller.signal,
      })
      .then(({ data }) => {
        if (data.enrolled === false) return history.push("/profile");
        let course_data = data.data[0];
        let {
          topics,
          objective,
          takeaway,
          courseLevel,
          name,
          schedule,
          dateAdded,
          courseDeadline,
          unlockedComponents,
          completedSessions,
          totalSessions,
          progressBarType
        } = course_data;
        let overview_data = {
          topics,
          objective,
          takeaway,
          courseLevel,
          name,
          schedule,
          dateAdded,
          courseDeadline,
          completedSessions,
          totalSessions,
          progressBarType
        };
        setUnlockedComponent(unlockedComponents);
        setToolKits(course_data.NewToolkits);
        setDashboardOverview(overview_data);

        setCompanyLogo(course_data.collegeLogo);
        setCantileverLogo(
          "https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1654930840181_logo.png"
        );
      })
      .catch(({ response }) => console.error(response));

    return () => controller.abort();
  }, [courseId, history]);

  return (
    <DashboardDataContext.Provider
      value={{
        tab,
        setTab,
        unlockedComponent,
        // @ts-ignore
        toolkits,
        companyLogo,
        cantileverLogo,
        dashboardOverview,
        goPoints,
        perName,
        profileImg,
        courseId,
        setCourseId,
      }}
    >
      {children}
    </DashboardDataContext.Provider>
  );
}

import styles from "../Assets/css/newcoursepage.module.css";
import arrow from "../Assets/newcoursepage_images/Arrow.png";
import { useState, useEffect } from "react";

const Frequentques = () => {
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  const [show5, setShow5] = useState(false);
  return (
    <>
      <div
        className={`container ${styles.quescont}`}
        style={{ margin: "0" }}
      >
        <div
          style={{
            margin: "2% 2vw",
            textAlign: "left",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
          }}
        >
          <div style={{width:"90%"}}>
          <h6 className={styles.heading} style={{ fontSize: "220%" }}>
            Will I get full access to the course after purchasing?
          </h6>
          </div>
          <div style={{width:"10%"}}>
          <img
            className={show === false ? styles.btnArrow : styles.btnArrow2}
            src={arrow}
            onClick={() => {
              setShow5(false);
              setShow3(false);
              setShow2(false);
              setShow4(false);
              setShow(() => {
                return !show;
              });
            }}
            alt="open"
          />
          </div>
        </div>

        {show === true ? (
          <div className={styles.content}>
            <p style={{ fontSize: "180%" }}>
              Yes, you will have complete access to the course and get the
              maximum benefit out of our course program.
            </p>
          </div>
        ) : (
          " "
        )}
      </div>

      <div
        className={`container ${styles.quescont}`}
        style={{ margin: "1vh 0" }}
      >
        <div
          style={{
            margin: "2% 2vw",
            textAlign: "left",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
          }}
        >
          <div style={{width:"90%"}}>
          <h6 className={styles.heading} style={{ fontSize: "220%" }}>
            What is the validity of the course?
          </h6>
          </div>
          <div style={{width:"10%"}}>
          <img
            className={show2 === false ? styles.btnArrow : styles.btnArrow2}
            src={arrow}
            onClick={() => {
              setShow5(false);
              setShow(false);
              setShow3(false);
              setShow4(false);
              setShow2(() => {
                return !show2;
              });
            }}
            alt="open"
          />
          </div>
        </div>

        {show2 === true ? (
          <div className={styles.content}>
            <p style={{ fontSize: "150%" }}>
             This course is valid for 1 year.
            </p>
          </div>
        ) : (
          " "
        )}
      </div>

      <div
        className={`container ${styles.quescont}`}
        style={{ margin: "1vh 0" }}
      >
        <div
          style={{
            margin: "2% 2vw",
            textAlign: "left",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
          }}
        >
          <div style={{width:"90%"}}>
          <h6 className={styles.heading} style={{ fontSize: "220%" }}>
            Which is the best course out of the available options?
          </h6>
          </div>
          <div style={{width:"10%"}}>
          <img
            className={show3 === false ? styles.btnArrow :styles.btnArrow2}
            src={arrow}
            onClick={() => {
              setShow5(false);
              setShow(false);
              setShow2(false);
              setShow4(false);
              setShow3(() => {
                return !show3;
              });
            }}
            alt="open"
          />
          </div>
        </div>

        {show3 === true ? (
          <div className={styles.content}>
            <p style={{ fontSize: "150%" }}>
              If you have less time before the interviews you can choose the
              fast track course or standard course. However, if you want a
              complete package to help you get a job then you can choose pro or
              premium courses that are specifically designed to crack tough tech
              interviews.
            </p>
          </div>
        ) : (
          " "
        )}
      </div>

      <div
        className={`container ${styles.quescont}`}
        style={{ margin: "1vh 0" }}
      >
        <div
          style={{
            margin: "2% 2vw",
            textAlign: "left",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
          }}
        >
          <div style={{width:"90%"}}>
          <h6 className={styles.heading} style={{ fontSize: "220%" }}>
            Do you provide doubt support?
          </h6>
          </div>
          <div style={{width:"10%"}}>
          <img
            className={show4 === false ? styles.btnArrow : styles.btnArrow2}
            src={arrow}
            onClick={() => {
              setShow5(false);
              setShow(false);
              setShow2(false);
              setShow3(false);
              setShow4(() => {
                return !show4;
              });
            }}
            alt="open"
          />
        </div>
</div>
        {show4 === true ? (
          <div className={styles.content}>
            <p style={{ fontSize: "150%" }}>
             Yes. We have an expert team to clarify all your doubts. The
              assistant will connect with you via chat or call to clarify your
              doubts.
            </p>
          </div>
        ) : (
          " "
        )}
      </div>
      <div
        className={`container ${styles.quescont}`}
        style={{ margin: "1vh 0" }}
      >
        <div
          style={{
            margin: "2% 2vw",
            textAlign: "left",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
          }}
        >
          <div style={{width:"90%"}}>
          <h6 className={styles.heading} style={{ fontSize: "220%" }}>
            Can non-cs branch students also join this course?
          </h6>
          </div>
          <div style={{width:"10%"}}>
          <img
            className={show5 === false ? styles.btnArrow : styles.btnArrow2}
            src={arrow}
            onClick={() => {
              setShow4(false);
              setShow(false);
              setShow2(false);
              setShow3(false);
              setShow5(() => {
                return !show5;
              });
            }}
            alt="open"
          />
        </div>
              </div>
        {show5 === true ? (
          <div className={styles.content}>
            <p style={{ fontSize: "150%" }}>
              Yes, every branch student can join this course and become
              proficient at coding and aptitude. These courses are not branch
              specific.
            </p>
          </div>
        ) : (
          " "
        )}
      </div>
    </>
  );
};

export default Frequentques;

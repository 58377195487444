import React from 'react'
import Navbar from '../components/Navbar'
import { useForm } from "react-hook-form";
import { Store } from "react-notifications-component";
import { BASE_URL } from '../env';
import NewConfusedForm from './NewConfusedForm';

// const BASE_URL = "http://localhost:5000"
const ContactForm = () => {
    const { register, handleSubmit, watch, formState: { errors },reset  } = useForm();
    const onSubmit = data => {
        // console.log(data)
    
        fetch(BASE_URL + "/contactformnew",
            {
                method: "POST",
                body: JSON.stringify(data ),
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
            }).then((res) => res.json())
            .then((dta) => {
                // console.log(dta)
                // reset(result);
                if (dta.status == "success") {
                    // alert("the data has been sent")
                    reset()
                    notifyPopup()
                }
            }).catch((err) => {
                // console.log(err);
            })
    }
    const notifyPopup = () => {
		Store.addNotification({
			title: "Success",
			message: "Your data has been sent",
			type: "danger",
			background: "pink",
			insert: "bottom",
			container: "bottom-right",
			animationIn: ["animate__animated", "animate__fadeIn"],
			animationOut: ["animate__animated", "animate__fadeOut"],
			dismiss: {
				duration: 3000,
				onScreen: true,
			},
		});
	};
    return (
        <>
            {/* <Navbar /> */}
            <NewConfusedForm/>
        {/* <form className="container my-5 card shadow p-5" onSubmit={handleSubmit(onSubmit)}>
            <h4 className="display-5 my-3" style={{fontSize:"2.8rem"}}>Contact us</h4>
            <div className="form-group my-3">
                <label htmlFor="firstName">Enter your First name</label>
                <input {...register("firstName",{required:true})}  type="text"      placeholder="Enter your First Name" className="form-control" id="firstName" />
            </div>
            <div className="form-group my-3">
                <label htmlFor="lastName">Enter your Last name</label>
                <input {...register("lastName",{required:true})} type="text"    placeholder="Enter your Last Name" className="form-control" id="lastName" />

            </div>
            <div className="form-group my-3">
                <label htmlFor="emailID">Enter your email ID</label>
                <input {...register("email",{required:true})} id="email" type="text"    placeholder="Enter your email address" className="form-control" />
            </div>
            <div className="form-group my-3">
                <label htmlFor="phonenumber">Phone Number</label>
                <input {...register("phoneNumber",{required:true})} id="phonenumber" type="text"    placeholder="Enter your phone number" className="form-control" />
            </div>
            <div className="form-group my-3">
                <label htmlFor="collegename">College Name</label>
                <input {...register("collegeName",{required:true})} id="collegename" type="text"    placeholder="Enter your college Name" className="form-control" />
            </div>
            <div className="form-group my-3">
                <label htmlFor="yearofStudy">Year of Study</label>
                <input {...register("yearOfStudy",{required:true})} id="yearofStudy" type="text"    placeholder="Year of study" className="form-control" />
            </div>
            <div className="form-group my-3">
                <label htmlFor="fatheroccupation">Father occupation</label>
                <input {...register("fatherOccupation",{required:true})} id="fatheroccupation" type="text"      placeholder="Father's occupation" className="form-control" />
            </div>
            <div className="form-group my-3">
                <label htmlFor="query">What is your Query?</label>
                <textarea {...register("query",{required:true})} id="query" type="text"    placeholder="Enter your Query" className="form-control" />
            </div>
                <input  type="submit" className="btn btn-lg btn-primary p-2" value="Submit" />
               
            </form> */}

            </>
    )
}

export default ContactForm

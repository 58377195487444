import React, { useState, useEffect } from "react";
import styles from "../Assets/css/masterclass.module.css";
import clock from "../Assets/img/Masterclass/Icon ionic-md-time.png";
import calendar from "../Assets/img/Masterclass/Icon material-today-black.png";
import masterBanner from "../Assets/img/Masterclass/blogs.png";
import Navbar from "../components/Navbar";
import { BASE_URL } from "../env";
import moment from "moment";
import { Link } from "react-router-dom";

function MasterClass() {
  const [webinarData, setWebinarData] = useState([]);
  useEffect(() => {
    fetch(BASE_URL + "/webinars/getAllWebinar", {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log("WEbinar Data", data);
        setWebinarData(data.response);
      });
  }, []);

  return (
    <>
      {/* <Navbar /> */}
      <div style={styles.body}>
        <div className={`container-fluid ${styles.blueBackJ}`}></div>
        <div className={`container p-0 mt-5 ${styles.archivedJ}`}>
          <h2>Webinars</h2>
        </div>
        <div
          className={`container-fluid justify-content-center ${styles.archivedJ} my-5`}
        >
          <div>
            <div className="row justify-content-center">
              {/* <span style={{ padding: "1%" }}> */}
              {webinarData?.map((item, index) => {
                return (
                  <>
                    <div
                      style={{
                        paddingRight: "2%",
                        paddingTop: "3%",
                        paddingBottom: "3%",
                        height: "fit-content",
                        // boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                      }}
                      className={`col-xl-5 col-lg-4 col-md-6 mx-4 my-2 `}
                    >
                      <div className={`card ${styles.cardsArchivedJ}`}>
                        <img
                          style={{
                            height: "40%",
                            width: "100%",
                            borderRadius: "10px 10px 0px 0px",
                          }}
                          //   className={`card-img-top ${styles.cardsArchivedJ}`}
                          src={item.image}
                          alt="Card image cap"
                        />
                        <div className={styles.cardBody}>
                          <h5 className="card-title">{item.subject}</h5>
                          <p style={{ width: "60%" }} className="card-text">
                            {item?.description}
                          </p>

                          <div className={`d-flex ${styles.archivedButtonJ}`}>
                            <div
                              className={`d-flex ${styles.archivedIconJ} mt-4`}
                            >
                              <div style={{ marginRight: "1.5vw" }}>
                                <p className="cal-J">
                                  <img src={calendar} alt="" />
                                  {moment(item.startTime).format("DD") +
                                    " " +
                                    moment(item.startTime).format("MMMM") +
                                    " " +
                                    moment(item.startTime).format("YYYY")}
                                </p>
                              </div>
                              <div className="mr-5">
                                <p className="clock-J">
                                  <img src={clock} alt="" />
                                  {moment(item.startTime).format("hh:mm A") +
                                    " " +
                                    moment(item.endTime).format("hh:mm A")}
                                </p>
                              </div>
                            </div>

                            <div className=" mt-4">
                              <div className="mb-2">
                                <Link
                                  to={"/masterclasspage/" + item.webinar_id}
                                  style={{
                                    color: "#212529",
                                  }}
                                  className={`${styles.btn} ${styles.btnLight} shadow `}
                                >
                                  Register
                                </Link>
                              </div>
                              <p
                                style={{
                                  textAlign: "center",
                                  fontSize: " 10px",
                                  fontWeight: "900",
                                }}
                              >
                                {/* 5673 have Enrolled */}
                              </p>
                            </div>
                            {/* <p
                              style={{
                                textAlign: "center",
                                fontSize: " 10px",
                                fontFamily: "Poppins-Black",
                              }}
                            >
                              5673 have Enrolled
                            </p> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MasterClass;

import React, { useState, useEffect } from "react";
import "../Assets/css/eventpage.css";
import EventPCard from "../components/EventPCard";
import EvenetImage from "../Assets/img/event-image.png";
import Footer from "../components/Footer";
import Card from "react-bootstrap/Card";
import eh from "../Assets/img/event-head.png";
import { BASE_URL } from "../env";

const EventPage = () => {
  const [cards, setCards] = useState([]);

  useEffect(() => {
    fetch(BASE_URL + "/getAllEvent", {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log(data);
        // setCards(data.events);
        // setWebinarData(data.message);
      });
  }, []);
  useEffect(() => {
    fetch(BASE_URL + "/getEvent/621385c296cf7c2a5c798c2f", {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log(data);
        setCards(data);
        // setWebinarData(data.message);
      });
  }, []);
  console.log(cards);
  return (
    <>
      <div className="EventPage">
        <div className="eventpageheader">
          <img src={EvenetImage} alt="event image" />
        </div>

        <div className="eventpage-tasks">
          <button type="button" class="btn btn-warning">
            Upcoming Events
          </button>
          <button type="button" class="btn btn-light">
            Events Library
          </button>
        </div>
        <div className="events-card">
          {/* {cards.map((val, index) => {
              return (
                <EventCard
                key={index}
                imgsrc={val.banner}
                title={value.title}
                registered={value.registered}
                slots={value.slots}
                />
              );
            })} */}
          <EventPCard />
          <EventPCard />
          <EventPCard />
          <EventPCard />
          <EventPCard />
          <EventPCard />
          <EventPCard />
          <EventPCard />
          <EventPCard />
        </div>
        <Footer />
      </div>
    </>
  );
};

const EventCard = (props) => {
  return (
    <div style={{ flex: "30%" }}>
      <Card
        style={{ width: "18rem", marginBottom: "100px", marginLeft: "20px" }}
        className="event-cardmain"
      >
        <Card.Img variant="top" src={eh} />
        <div class="top-right">57 Slots Remaining</div>
        <Card.Body>
          {/* <Card.Text>Growth Hacks of CEO</Card.Text> */}
          <Card.Title className="eventcard-title">
            Growth Hacks of CEO
          </Card.Title>
          <Card.Text>
            <button className="statusbtn">Free</button>
          </Card.Text>
        </Card.Body>
        <Card.Text className="eventpage-cardmore">
          <div>
            <button className="eventmorebutton">Know More</button>
          </div>
          <div className="event-cardfooter">2076 Students Registered</div>
        </Card.Text>
        {/* <Card.Text className="event-cardfooter">
          2076 Students Registered
        </Card.Text> */}
      </Card>
    </div>
  );
};

export default EventPage;

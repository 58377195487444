import React from "react";
import {
  Link,
  BrowserRouter as Router,
  Route,
  Switch,
  useLocation,
  Redirect,
} from "react-router-dom";
import styles from "../Assets/css/tcs.module.css";
import img_1 from "../Assets/tcs_digital_images/cantilever-logo-final-black.png";
import img_2 from "../Assets/tcs_digital_images/Group 8243.png";
import img_3 from "../Assets/tcs_digital_images/Group.png";
import img_4 from "../Assets/tcs_digital_images/Vector.png";
import img_5 from "../Assets/tcs_digital_images/Vector1.png";
import img_6 from "../Assets/tcs_digital_images/Vector2.png";
import img_7 from "../Assets/tcs_digital_images/Group1.png";
import img_8 from "../Assets/tcs_digital_images/share.png";
import img_9 from "../Assets/tcs_digital_images/Icon feather-instagram.png";
import img_10 from "../Assets/tcs_digital_images/Icon awesome-linkedin.png";
import img_11 from "../Assets/tcs_digital_images/Icon awesome-facebook-square.png";
import img_12 from "../Assets/tcs_digital_images/message.png";
import Footer from "../components/Footer";
import { Store } from "react-notifications-component";
import useMedia from "../componentsofcourse/useMedia";
import ReactPixel from "react-facebook-pixel";
import tick from "../Assets/tcs_digital_images/tick.png";

import SM from "../Imgsofcourse/images/Image 1.png";
import styles1 from "../Assets/css/cpcourse.module.css";
import Navbar from "../components/Navbar";

import Testimonials from "../components/TestimonialsVersion2";
import { useEffect } from "react";
import TCSmobile from "./TCSmobile";
import NewWebinarMobile from "./NewWebinarMobile";
import PopupAd from "./PopupAd";
import { useState } from "react";
import Mobilepopup from "../componentsofcourse/Mobilepopup";
import { BASE_URL } from "../env";
import Newstepform from "./Newstepform";
const NewMasterClassLanding = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const refferal = queryParams.get("referral");
  const matches = useMedia("(min-width : 998px )");
  const matches_middle = useMedia("(min-width : 968px )");

  const [showBanner, setShowBanner] = useState(false);

  const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
  const options = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: false, // enable logs
  };
  const notifyPopup = () => {
    Store.addNotification({
      title: "Success!",
      message: "Copied",
      type: "success",
      background: "pink",
      insert: "bottom",
      container: "bottom-right",
      animationIn: ["animate_animated", "animate_fadeIn"],
      animationOut: ["animate_animated", "animate_fadeOut"],
      dismiss: {
        duration: 2000,
        onScreen: true,
      },
    });
  };
  let loc = useLocation();
  // console.log(loc);
  const arr = loc.pathname.split("/masterclass/")[1];
  let referral_ = arr;

  function ADDTCSCourse() {
    fetch(BASE_URL + "/webinars/updatewebinar", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
      body: JSON.stringify({
        TCSOption: true,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        // console.log(response);
        // signin("final");
        //  alert("got the new details")
        // console.log("Enroll hogaya bacha");
        enrollTheUser();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    if (matches == false) {
      // alert(matches)
      window.scroll(1000, 1000);
    }
    // if(matches == false)
    // window.scroll(750,735)
  }, [matches]);
  useEffect(() => {
    ReactPixel.init("337209321107283", advancedMatching, options);
    ReactPixel.trackCustom("Quant_finance");
  }, []);

  function myFunction() {
    let Text = "https://www.cantileverlabs.com/tcs";
    let inputElement = document.createElement("input");
    inputElement.setAttribute("value", Text);
    document.body.appendChild(inputElement);
    inputElement.select();
    document.execCommand("copy");
    inputElement.parentNode.removeChild(inputElement);
    notifyPopup();
  }
  function enrollTheUser() {
    fetch(BASE_URL + "/enroll/61012f44eb1f466b74f1459d", {
      headers: { authorization: "Bearer " + localStorage.getItem("token") },
      method: "POST",
    })
      .then((res) => res.json())
      .then((data) => {
        // history.push("/mycourse/61012f44eb1f466b74f1459d/");
        // console.log(data);
        // console.log(data)
      });
  }
  return (
    <div style={{ overflowX: "hidden" }}>
      {/* <Navbar /> */}
      {/* <div className="d-flex justify-content-start">
      <PopupAd/>
      </div> */}
      <div className="d-flex justify-content-center" onClick={() => {}}>
        {showBanner == true && matches == false && <Mobilepopup />}
      </div>
      <div className="container-fluid" style={{ overflowX: "unset" }}>
        <div className="row">
          <div
            className={`col-xl-11 col-lg-11 col-md-12 col-sm-12 mx-4 ${styles.topDiv}`}
          >
            <div className={`d-flex ${styles.top}`}>
              <Link to="/" style={{ marginLeft: matches == false && "10%" }}>
                <div style={{ cursor: "pointer" }}></div>
              </Link>
            </div>
          </div>
        </div>

        <br />
        <br />
        <div className="row ">
          <div
            className={` col-xl-11 col-lg-11 col-md-12 col-sm-12 ${styles.topDiv}`}
          >
            <div>
              <div className="row mx-1">
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 mx-auto order-lg-first order-last ">
                  <h1
                    style={{
                      fontSize: matches == false && "32px",
                      marginBottom: matches == false && "0px",
                    }}
                    className={` ${styles.prepare}`}
                  >
                    <span
                      className={styles.TCScrack}
                      style={{
                        float: "left",
                        textAlign: "left",
                        marginBottom: "1rem",
                        fontWeight: "600",
                      }}
                    >
                      {" "}
                      How to Crack Technical Interviews of Top Service Based
                      Companies
                    </span>
                  </h1>

                  <h3 className="mt-4">
                    <p>TCS, WIPRO, Infosys Capgemini and Many More...</p>
                  </h3>

                  <h3 className="mt-4">
                    {/* Join us to land a Package of <strong>5 LPA </strong> */}
                  </h3>
                  {/* <h3>
                    
                      Goldman Sachs , Infosys , TCS , Wipro , Accenture
                    </strong>
                  </h3> */}
                  <br />

                  <div></div>

                  <div>
                    <p
                      data-aos="fade-right"
                      data-duration="2000"
                      style={{ fontWeight: "700", fontSize: "1.2em" }}
                    >
                      What will you get?
                      <br />
                      <img
                        src={tick}
                        style={{
                          width: "30px",
                          height: "30px",
                          marginRight: "6px",
                        }}
                      />
                      Strategy to Ace Technical Interviews
                      <br />
                      <img
                        src={tick}
                        style={{
                          width: "30px",
                          height: "30px",
                          marginRight: "6px",
                        }}
                      />
                      Have a Question? Ask our Experts
                      <br />
                      {/* <img
												src={tick}
												style={{
													width: "30px",
													height: "30px",
													marginRight: "6px",
												}}
											/>
											Free Previous years Question Bank */}
                      {/* <span className={styles.TCScrack}>
                        {" "}
                        ( DIGITAL + NINJA )
                      </span>{" "} */}
                    </p>
                    {/* <h5
                      style={{ fontSize: matches == false && "20.4px" }}
                      className={styles.grab1}
                    >
                      Grab the Exclusive Course worth Rs 4999 for absolutely
                      <span className={styles.TCS}> FREE !</span>
                    </h5> */}
                    {matches == false && (
                      <>
                        {/* <img
                        src={img_2}
                        alt=""
                        className={`${styles.head_img} img-fluid img-responsive`}
                      /> */}
                        <lottie-player
                          src="https://assets1.lottiefiles.com/packages/lf20_vo0a1yca.json"
                          background="transparent"
                          speed="1"
                          style={{ width: "350px", height: "350px" }}
                          loop
                          autoplay
                        ></lottie-player>
                      </>
                    )}
                  </div>
                  <br />
                  <div className={styles.btnDiv}>
                    {matches == true ? (
                      <a href={"/masterclassform/" + referral_}>
                        <button
                          className={styles.animbutton}
                          type="submit"
                          style={{
                            display: "block",
                            marginLeft: matches == false && "13%",
                            fontSize: matches == false && "1.3rem",
                            padding: matches == false && "4px",
                            width: matches == false && "230px",
                          }}
                          className={styles.enrollBtn}
                        >
                          Register for FREE
                        </button>
                      </a>
                    ) : (
                      <>
                        <button
                          onClick={() => {
                            window.scroll(735, 735);
                          }}
                          type="submit"
                          style={{
                            display: "block",
                            marginLeft: matches == false && "13%",
                            fontSize: matches == false && "1.3rem",
                            padding: matches == false && "4px",
                            width: matches == false && "230px",
                          }}
                          className={styles.enrollBtn}
                        >
                          Enroll Now
                        </button>
                      </>
                    )}
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 order-lg-last ">
                  {matches == true && (
                    <div className="mb-5">
                      {/* <img
                      src={img_2}
                      alt=""
                      className={`${styles.head_img} img-fluid img-responsive`}
                    /> */}
                      <lottie-player
                        src="https://assets1.lottiefiles.com/packages/lf20_vo0a1yca.json"
                        background="transparent"
                        speed="1"
                        style={{
                          width: matches_middle ? "700px" : "500px",
                          height: "500px",
                        }}
                        loop
                        autoplay
                      ></lottie-player>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br /> <br />
      <div
        className={`${styles.secondDiv} `}
        style={{ padding: "30px", backgroundColor: "#EDF2FD" }}
      >
        {matches == false && (
          <div className="container-fluid bg-light rounded py-2 p-0">
            <Newstepform />
          </div>
        )}
        <div className="row justify-content-around ">
          <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 mx-3 my-auto align-items-center">
            <div
              data-aos="fade-left"
              className=" d-flex my-3 flex-row justify-content-start "
            >
              <div className="">
                <div className={styles.secondDiv2WhiteBg}>
                  <img className={styles.secondDiv2Img1} src={img_4} alt="" />
                </div>
              </div>

              <div className="">
                <p className={styles.secondDiv2P} style={{ color: "#000" }}>
                  24k+ Students trained from IITs,BITS,JNTUCEH,SNIST,GITAM &
                  more
                </p>
              </div>
            </div>
            <div
              data-aos="fade-left"
              className=" d-flex my-3 flex-row justify-content-start my-3"
            >
              <div className="">
                <div className={styles.secondDiv2WhiteBg}>
                  <img className={styles.secondDiv2Img2} src={img_5} alt="" />
                </div>
              </div>
              <div className=" ">
                <p className={styles.secondDiv2P} style={{ color: "#000" }}>
                  Join our community of 10K+ plus students across Hyderabad and
                  24K+ across India
                </p>
              </div>
            </div>

            <div
              data-aos="fade-left"
              className=" d-flex my-3 flex-row justify-content-start my-3"
            >
              <div className="">
                <div className={styles.secondDiv2WhiteBg}>
                  <img className={styles.secondDiv2Img3} src={img_6} alt="" />
                </div>
              </div>
              <div className=" ">
                <p className={styles.secondDiv2P} style={{ color: "#000" }}>
                  Learn from IIT mentors and Industry Experts
                </p>
              </div>
            </div>
            {/* 
            <div className=" d-flex my-3 flex-row justify-content-start my-2">
              <div className="">
                <div className={styles.secondDiv2WhiteBg}>
                  <img className={styles.secondDiv2Img4} src={img_7} alt="" />
                </div>
              </div>
              <div className=" ">
                <p className={styles.secondDiv2P}>
                  Handpicked content exclusively for TCS Digital
                </p>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <div
        className={`container-fluid justify-content-center ${styles.thirdDiv} text-center`}
      >
        <div className="row py-3">
          <div className="col-lg-6 col-md-4 col-sm-12 mx-auto ">
            <h2 className={styles.thirdDivHd}>320+</h2>
            <p className={styles.thirdDivP}>
              Students got placed
              <br />
              @FAANG Companies
            </p>
            <br />
            <br />
          </div>
          <div className="col-lg-6 col-md-4 col-sm-12 mx-auto">
            <h2 className={styles.thirdDivHd}>24,000+</h2>
            <p className={styles.thirdDivP}>
              Students trained from
              <br />
              IITs, BITS, NITs, SRM, GITAM, JNTUH
            </p>
            <br />
            {/* <br /> */}
          </div>
          <div className="col-lg-6 col-md-4 col-sm-12 mx-auto">
            <h2 className={styles.thirdDivHd}>4.9</h2>
            <p className={styles.thirdDivP}>
              Average rating given
              <br />
              to our courses by the students
            </p>
          </div>
          {matches == true && (
            <div className="col-lg-6 col-md-4 col-sm-12 mx-auto">
              <h2 className={styles.thirdDivHd}>1.5 Cr</h2>
              <p className={styles.thirdDivP}>
                7 Students placed
                <br />
                with the highest package in India
              </p>
              <br />
              <br />
              {/* <h2 className={styles.thirdDivHd}>10+</h2>
              <p className={styles.thirdDivP}>Free Tool kits</p> */}
            </div>
          )}
        </div>
      </div>
      <div className="d-flex justify-content-center p-4">
        {matches == true ? (
          <a
            href={"/masterclassform/" + referral_}
            style={{ textDecoration: "none" }}
          >
            <button
              type="submit"
              style={{
                display: "block",
                // fontSize:"3rem",
                fontSize: matches == false ? "1rem" : "28px",
                width: matches == true && "400px",
              }}
              className={styles.downenrollbtn + " btn px-5 "}
            >
              Enroll Now
            </button>
          </a>
        ) : (
          <button
            onClick={() => {
              window.scroll(735, 735);
            }}
            type="submit"
            style={{
              display: "block",
              // fontSize:"3rem",
              fontSize: matches == false ? "1rem" : "28px",
              width: matches == true && "400px",
            }}
            className={styles.downenrollbtn + " btn px-5 "}
          >
            Enroll Now
          </button>
        )}
      </div>
      {/*Maintestimonial*/}
      <h2 className="text-center mt-5" style={{ fontSize: "24px" }}>
        What Our Students Say
      </h2>
      <br />
      <br />
      <div className="" style={{ backgroundColor: "#185adb" }}>
        <br />
        <br />
        <div className="row" style={{ marginLeft: matches ? "12%" : "6%" }}>
          <div className="col-md-4 col-xs-12 col-12 col-12 justify-content-center pb-2">
            <img
              src={SM}
              className="img-fluid"
              style={{ marginLeft: matches == false ? "3%" : null }}
            />
          </div>
          <div
            style={{ textAlign: "left" }}
            className="col-md-6 col-xs-12 col-12 col-12"
          >
            <div>
              <h4 style={{ color: "white" }}>SARVESH MEHTANI</h4>
            </div>
            <div>
              <h5 style={{ color: "white" }}>IIT Bombay - Computer Science</h5>
            </div>
            <br />
            <div>
              <p style={{ color: "white" }}>
                Overall, I loved the fact that the whole process was tailored as
                per the individual's interests and goals which made it very
                effective in pointing out our areas of strength and the areas
                that we need to work on.
              </p>
            </div>

            <br />
            <br />
            <div>
              <button className={styles1.sarveshbtn}>
                A.I.R 1, JEE ADVANCED 2017
              </button>
            </div>
          </div>
        </div>
      </div>
      <div style={{ overflowX: "hidden" }}>
        <Testimonials />
      </div>
      <div className="d-flex justify-content-center p-4">
        {matches == true ? (
          <a
            href={"/masterclassform/" + referral_}
            style={{ textDecoration: "none" }}
          >
            <button
              type="submit"
              style={{
                display: "block",
                // fontSize:"3rem",
                fontSize: matches == false ? "1rem" : "28px",
                width: matches == true && "400px",
              }}
              className={styles.downenrollbtn + " btn px-5 "}
            >
              Enroll Now
            </button>
          </a>
        ) : (
          <button
            onClick={() => {
              window.scroll(735, 735);
            }}
            type="submit"
            style={{
              display: "block",
              // fontSize:"3rem",
              fontSize: matches == false ? "1rem" : "28px",
              width: matches == true && "400px",
            }}
            className={styles.downenrollbtn + " btn px-5 "}
          >
            Enroll Now
          </button>
        )}
      </div>
      <div
        className={`${styles.fourthDiv} justify-content-center align-items-center`}
      >
        <div className="container">
          <div className={styles.fourthDiv1}>
            <h3 className={styles.fourthDivHd}>Share it with your Friends</h3>
          </div>

          <div className="row justify-content-center">
            <div
              className={`col-xl-4 col-lg-4 col-md-4 col-sm-4 ${styles.fourthDiv2}`}
            >
              <div className="d-flex flex-row justify-content-evenly mx-5">
                <a href="https://www.instagram.com/direct/new">
                  <img src={img_9} alt="" style={{ width: "28px" }} />
                </a>

                <a href="https://www.linkedin.com/shareArticle?url=https://www.cantileverlabs.com/tcs&title=TCS+DIGITAL&summary=TCS+DIGITAL&source=https://www.cantileverlabs.com">
                  <img src={img_10} alt="" style={{ width: "28px" }} />
                </a>

                <a href="https://www.facebook.com/sharer/sharer.php?u=https://www.cantileverlabs.com/tcs">
                  <img src={img_11} alt="" style={{ width: "28px" }} />
                </a>
                <a
                  href="mailto:support@cantileverlabs.com?subject=I wanted you to see this site &amp;body=Check out this site https://www.cantileverlabs.com/tcs"
                  title="Share by Email"
                >
                  <img
                    src={img_12}
                    alt=""
                    style={{ width: "34px", fontWeight: "bold" }}
                  />
                </a>

                <Link to="/tcs" onClick={myFunction}>
                  {/* <i class="fas fa-copy"></i */}
                  <i class="far fa-copy fa-2x" style={{ color: "white" }}></i>
                  {/* <img src={img_8} alt="" style={{ width: "26px",height:"30px" }} /> */}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default NewMasterClassLanding;

import React, { useContext, useState, useEffect, useLayoutEffect } from "react";
import { ModalContext } from "../Context/ModalContext";
import Whyus1 from "../Assets/img/Aboutus/whyus_1.png";
import Whyus2 from "../Assets/img/Aboutus/whyus_2.png";
import Whyus3 from "../Assets/img/Aboutus/whyus_3.png";
import Whyus4 from "../Assets/img/Aboutus/whyus_4.png";
import Curve from "../Assets/img/Aboutus/curve.png";
// import Rakesh from "../Assets/img/Aboutus/rakesh.png";
import Rakesh from "../Assets/img/Aboutus/rakeshLink.png";
// import Vijay from "../Assets/img/Aboutus/vijay.png";
import Vijay from "../Assets/img/Aboutus/vijayLink.png";
// import Avanish from "../Assets/img/Aboutus/avanish.png";
import Avanish from "../Assets/img/Aboutus/avanishLink.png";
import Teamimg from "../Assets/img/Aboutus/team.png";
import Clipboard from "../Assets/img/Aboutus/clipboard.png";
import SimpleCircle from "../Assets/img/Aboutus/Icon simple-circle.png";
import Placementimg from "../Assets/img/Aboutus/placementimg.png";
import Vision from "../Assets/img/Aboutus/vision.png";
import Mission from "../Assets/img/Aboutus/mission.png";
import Arrow from "../Assets/img/Aboutus/arrow.png";
import styles from "../Assets/css/aboutus.module.css";
import MoreQueries from "../components/MoreQueries";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import HelmetComp from "../components/HelmetComp";

const Aboutus = () => {
  let { open, update_status } = useContext(ModalContext);

  const [modal, setModal] = useState(false);
  useEffect(() => {
    update_status(!open);
    setModal();
  }, [modal]);
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      {/* <Navbar /> */}
      <HelmetComp info={{
        title: 'About Us',
        // description: 'About Us desc',
        // siteName: 'About Us siteName',
        // summary: 'About Us summary',
        // pageUrl: 'About Us PageUrl',
        // imagUrl: 'About Us imageUrl'
      }} />
      <div style={{ overflowX: "hidden" }}>
        <div className={styles.containerfluid}>
          <div className={`row ${styles.aboutus} d-flex align-items-center`}>
            <div
              className={`col-12 ${styles.overlay} d-flex flex-column align-items-center justify-content-center`}
            >
              <h2 className={`${styles.img_heading} row-4`}>About us</h2>
              <img src={Curve} className={`row-4 ${styles.curve}`}></img>
              <p
                style={{ color: "white", padding: "0 18%" }}
                className={styles.img_paragraph}
              >
                <b>Cantilever Labs </b>is driven by the{" "}
                <i style={{ fontWeight: "600" }}>passion</i> to build win-win
                platform that assists students to secure their dream jobs and
                support corporates to find the{" "}
                <i style={{ fontWeight: "bold", fontSize: "20px" }}>
                  right candidates.
                </i>
              </p>
            </div>
          </div>
          <div
            className={`container-fluid mb-0 p-3 p-xl-4 p-md-3 p-sm-3 p-2 ${styles.blue} d-flex flex-column align-items-center`}
          >
            <div className="row my-3">
              <div
                style={{ textAlign: "left" }}
                className={`col-lg-2 p-3 col-md-6 col-sm-12 col-12 mb-3 ${styles.bluevisionmission}  d-flex flex-column text-right`}
              >
                <img
                  src={Vision}
                  alt="icon simple"
                  className="mb-4 icons"
                  style={{ width: "180px", height: "174px" }}
                />
              </div>
              <div
                style={{ textAlign: "left", paddingLeft: "5%" }}
                className={`col-lg-4 col-md-5 col-sm-12 col-12 mb-3 ${styles.bluevisionmission}  d-flex flex-column justify-content-center`}
              >
                <h2 style={{ fontWeight: "bold", textAlign: "left" }}>
                  Vision
                </h2>
                <p
                  className="pb-0 mb-0"
                  style={{
                    fontWeight: "lighter",
                    color: "#b0e2ee",
                    textAlign: "left",
                  }}
                >
                  We impart relevant training to{" "}
                </p>
                <p
                  className="pb-0 mb-0"
                  style={{ fontWeight: "lighter", color: "#b0e2ee" }}
                >
                  graduating students for Core subjects,
                </p>
                <p
                  className="pb-0 mb-0"
                  style={{ fontweight: "lighter", color: "#b0e2ee" }}
                >
                  Non-Core subjects, Aptitude and Soft Skills.
                </p>
              </div>
              <div
                style={{ textAlign: "left" }}
                className={`col-lg-2 p-3 col-md-6 col-sm-12 col-12 mb-3 ${styles.bluevisionmission} d-flex flex-column justify-content-center`}
              >
                <img
                  src={Mission}
                  alt="group"
                  className="mb-4 icons "
                  style={{ width: "180px", height: "174px" }}
                />
              </div>
              <div
                style={{
                  textAlign: "left",
                  alignContent: "center",
                  paddingLeft: "5%",
                }}
                className={`col-lg-4 col-md-6 col-sm-12 col-12 mb-3 ${styles.bluevisionmission} d-flex flex-column justify-content-center`}
              >
                <h2 style={{ fontWeight: "bold" }}>Mission</h2>
                <p
                  className="pb-0 mb-0"
                  style={{ fontWeight: "lighter", color: "#b0e2ee" }}
                >
                  Reach and impact the lives of students and job-seekers,
                  bridging the connection of dreams into corporates
                </p>
              </div>
            </div>
          </div>
        </div>
        <h1 className={styles.heading}>Why us?</h1>
        <br />
        <div className={styles.containerfluid}>
          <div className="row justify-content-flex">
            <div className="col-xl-6 p-5 mb-5" style={{ textAlign: "left" }}>
              <h2 className={styles.subheading}>
                we pave the road to your success
              </h2>
              <br />
              <p className={styles.why_paragraph}>
                A roadmap just for you: We provide with the best of experiential
                learning ambience and pre-hand experience with practical
                exercises for complete preparation, with 100% assurance for
                placements in any desired company. We work towards building a
                confident personality that would crack any entrance interviews,
                alongside with intellectual aptitude skills, quantitative
                skills, Verbal and soft-skills, Profile-building with radical
                thinking to crack any question with varied level of difficulty.
                With extensible hours of training and proficient methods of
                teaching, technical training, mock interviews with HR's of top
                companies like Google, Microsoft, Amazon etc. The candidate will
                be prepped, brushed and full-fledged equipped for any interview
                in a product-based company or competitive environment.
              </p>
            </div>
            <div className="col-xl-6 p-5 mb-5">
              <img src={Whyus1} className="img-responsive" alt="" />
            </div>
          </div>
          <div className="row justify-content-around image-left">
            <div className="col-xl-6 p-5 mb-5 first-div">
              <img src={Whyus2} className={styles.imgresponsive} alt="" />
            </div>
            <div
              className={`col-xl-6 p-5 mb-5 ${styles.seconddiv}`}
              style={{ textAlign: "left" }}
            >
              <h2 className={styles.subheading}>Personalisation</h2>
              <br />
              <p>
                We focus on making our teaching as personalised as possible
                based on the client analysis of requirements. Personalised
                lesson plans, schedules to learn at your own pace to land the
                internship that you desire. Technical skills, aptitude skills,
                competitive programming, now personalised, just for you. To
                equip your desired talents with a 100% field-proficiency. With a
                wide range of oppurtunities and options to choose from in this
                roller coaster to your corporate success.
              </p>
            </div>
          </div>
          <div className="row justify-content-around">
            <div className="col-xl-6 p-5 mb-5" style={{ textAlign: "left" }}>
              <h2 className={styles.subheading}>we are Customer Centric</h2>
              <br />
              <p>
                Our primary focus is the client and their goals, we work on the
                overall personality development of the candidate. Whilst
                focusing on technical development and Verbal skills based on the
                clients level of interest, we imbibe core values and confidence
                development through the technical courses designed for you, that
                will aid in building the path to a placement in comapanies like
                Google, Microsoft, Amazon, etc.
              </p>
            </div>
            <div className="col-xl-6 p-5 mb-5">
              <img src={Whyus3} className={styles.imgresponsive} alt="" />
            </div>
          </div>
          <div className={`row justify-content-around ${styles.imageleft}`}>
            <div className={`col-xl-6 p-5 mb-5 ${styles.firstdiv}`}>
              <img src={Whyus4} className={styles.imgresponsive} alt="" />
            </div>
            <div
              className={`col-xl-6 p-5 mb-5 ${styles.seconddiv}`}
              style={{ textAlign: "left" }}
            >
              <h2 className={styles.subheading}>Industry Exposure</h2>
              <br />
              <p>
                With the help of experienced faculties from IIT's and IIM's, our
                linchpin is the efficient course material and study patterns for
                all courses, with aiding the candidate in networking through
                exposure in practical fields and projects in real-time.
                Mock-interviews and internships at top companies, help in
                creating an impactful presence of the candidate in the assigned
                position in a wide environment of contacts. Landing internships
                and jobs in highly reputed brands and companies through our
                placement preparation, just a click away!
              </p>
            </div>
          </div>
        </div>

        <div
          className={`${styles.containerfluid} justify-content-center mb-0 p-3 p-xl-4 p-md-3 p-sm-3 p-2 ${styles.blue}`}
        >
          <div className="row my-3">
            <div
              style={{ textAlign: "center" }}
              className={`col-lg-4 col-md-4 col-sm-12 col-12 mb-3 ${styles.blueimg}`}
            >
              <img
                src={SimpleCircle}
                alt="icon simple"
                className="mb-4 icons"
              />
              <h6 style={{ fontFamily: "Poppins-Bold" }}>
                Cantilever Labs expertise
              </h6>
              <p className="pb-0 mb-0" style={{ fontFamily: "Poppins-Light" }}>
                At Cantilever Labs, We build a candidate from the programming
                skills to the final rounds of interview, inclusive of Mock GD's,
                one-on-one interviews, resume making kits etc. We maintain a
                concrete experience of learning and building a professional
                experience for everyone.
              </p>
            </div>
            <div
              style={{ textAlign: "center" }}
              className={`col-lg-4 col-md-4 col-sm-12 col-12 mb-3 ${styles.blueimg} flex-column d-flex align-items-center`}
            >
              <img src={Placementimg} alt="group" className="mb-4 icons" />
              <h6 style={{ fontFamily: "Poppins-Bold" }}>
                Placement Training for Students
              </h6>
              <p
                className="pb-0 mb-0 my-1"
                style={{ fontFamily: "Poppins-Light" }}
              >
                Better test experience with advance analytic tools for skill
                analysis of the student, to find perfection in execution of
                skills during the placement rounds of any company.
              </p>
            </div>
            <div
              style={{ textAlign: "center" }}
              className={`col-lg-4 col-md-4 col-sm-12 col-12 mb-3 ${styles.blueimg}`}
            >
              <img
                src={Clipboard}
                alt="material-assignment"
                className="mb-4 icons"
              />
              <h6 style={{ fontFamily: "Poppins-Bold" }}>
                Practice Assignments
              </h6>
              <p className="pb-0 mb-0" style={{ fontFamily: "Poppins-Light" }}>
                We impart core subject training and non-subject training with
                Verbal, Quantitative, Analysis and Aptitude skills over a period
                of time. We imbibe goal-centered study plans and schedules for
                preparation.
              </p>
            </div>
          </div>
        </div>
        {/*<div className="container-fluid justify-content-center mb-0 p-3 p-xl-4 p-md-3 p-sm-3 p-2 " >
                <h1 className="mb-5" style={{ fontWeight: "bold" }}>Recg. &amp;  Awards</h1>
                <div className="row justify-content-around">
                    <div className="card-body p-3 mb-5 inner-list rounded col col-xl-2 col-lg-2 col-md-3 col-sm-3 col-7">
                        <img className="img_rec" src={WhiteRectangle} alt="Rectangle" />
                    </div>
                    <div className="card-body p-3 mb-5 rounded col col-xl-2 col-lg-2 col-md-3 col-sm-3 col-7">
                        <img className="img_rec" src={WhiteRectangle} alt="Rectangle" />
                    </div>
                    <div className="card-body p-3 mb-5 rounded col col-xl-2 col-lg-2 col-md-3 col-sm-3 col-7">
                        <img className="img_rec" src={WhiteRectangle} alt="Rectangle" />
                    </div>
                    <div className="card-body p-3 mb-5 rounded col col-xl-2 col-lg-2 col-md-3 col-sm-3 col-7 ">
                        <img className="img_rec" src={WhiteRectangle} alt="Rectangle" />
                    </div>
                </div>
                <div className="row justify-content">
                    <div className="card-body p-3 mb-5 inner-list rounded col col-xl-2 col-lg-2 col-md-3 col-sm-3 col-7 ">
                        <img className="img_rec" src={WhiteRectangle} alt="Rectangle" />
                    </div>
                    <div className="card-body p-3 mb-5 rounded col col-xl-2 col-lg-2 col-md-3 col-sm-3 col-7">
                        <img className="img_rec" src={WhiteRectangle} alt="Rectangle" />
                    </div>
    </div>
            </div>*/}

        {/* <div className="container-fluid mb-5 p-3 p-xl-4 p-md-3 p-sm-3 p-2 ">
          <div
            className="col-xl-2 col-lg-2 col-md-3 col-sm-3 team align-items-center"
            style={{ display: "block", margin: "auto" }}
          >
            <h1 className={styles.teamheading}>Team</h1>
            <div>
              <img
                src={Teamimg}
                alt=""
                className={`${styles.imgresponsive}
   ${styles.imgteam}`}
              />
            </div>
          </div>
          <div className="row justify-content-around d-flex align-items-center">
            <div className="col-xl-2 col-lg-4 col-md-4 col-sm-12  person">
              <div className={styles.persondiv}>
                <a
                  href="https://www.linkedin.com/in/avanish-mundhra-6a938848/"
                  target="_blank"
                >
                  <img
                    src={Avanish}
                    className={`${styles.imgresponsive}
${styles.personimg}`}
                    alt=""
                  />
                </a>
              </div>
              <br />
              <div className={styles.persontext}>
                <h5 className={styles.h5}>Avanish Mundhra</h5>
                <h6 className={styles.designation}>FOUNDER & CEO</h6>
              </div>
            </div> */}
            {/* <div className="col-xl-2 col-lg-4 col-md-4 col-sm-12 person">
              <div className={styles.persondiv}>
                <a
                  href="https://www.linkedin.com/in/vijaykaushikcl/"
                  target="_blank"
                >
                  <img
                    src={Vijay}
                    className={`${styles.imgresponsive}
${styles.personimg}`}
                    alt=""
                  />
                </a>
              </div>
              <br />
              <div className={styles.persontext}>
                <h5 className={styles.h5}> Vijay Kaushik</h5>
                <h6 className={styles.designation}>CO FOUNDER & COO</h6>
              </div>
            </div> */}
            {/* <div className="col-xl-2 col-lg-4 col-md-4 col-sm-12  person">
              <div className={styles.persondiv}>
                <a
                  href="https://www.linkedin.com/in/rakeshpatel21/"
                  target="_blank"
                >
                  <img
                    src={Rakesh}
                    className={`${styles.imgresponsive}
${styles.personimg}`}
                    alt=""
                  /> */}

                  {/* <i
                  className="fa fa-linkedin-square fa-3x"
                  aria-hidden="true"
                  style={{
                    color: "#2867B2",
                    width: "15px",
                    height: "15px",
                  }}
                ></i> */}
                {/* </a>
              </div>
              <br />
              <div className={styles.persontext}>
                <h5 className={styles.h5}>Rakesh Kumar Patel</h5>
                <h6 className={styles.designation}>CO FOUNDER & CMO</h6>
              </div>
            </div>
          </div>
        </div> */}

        <div
          className={`row justify-content-center ${styles.exciteddiv} p-3 p-xl-4 p-md-3 p-sm-3 p-2`}
        >
          <div
            className={`${styles.excitedcontainer} container justify-content-center ${styles.blue} p-3 p-xl-4 p-md-3 p-sm-3 p-2 mb-3 col-xl-5 col-md-7 col-sm-9 col-9`}
          >
            {" "}
            <h2 style={{ textAlign: "center" }}>Excited to Join Us</h2>
            <p style={{ textAlign: "center" }}>we are hiring</p>
            <div className="row justify-content-center">
              <button
                type="button"
                className={`btn btn-light ${styles.google_form_button}`}
              >
                <span className={`${styles.left} text-dark`}>
                  Fill the google form
                </span>
                <span className={styles.right}>
                  <img src={Arrow} alt="arrow" />
                </span>
              </button>
            </div>
          </div>
        </div>
        <MoreQueries />
        <Footer />
      </div>
    </>
  );
};

export default Aboutus;

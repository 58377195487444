import { data } from "browserslist";
import { combineReducers } from "redux";

export const videoIdReducer = (state = "", action) => {
  switch (action.type) {
    case "dashboard/videoId":
      return action.payload;

    default:
      return state;
  }
};

const qAndAComments = {
  title: "",
  description: "",
  profile_pic: "",
};
export const QandAReducer = (state = qAndAComments, action) => {
  switch (action.type) {
    case "dashboard/selectedQuestion":
      return action.payload;

    default:
      return state;
  }
};

export const assignmentQuizReducer = (state = "", action) => {
  switch (action.type) {
    case "dashboard/quizType/technical":
      return "technical";

    case "dashboard/quizType/aptitude":
      return "aptitude";

    case "dashboard/quizType/all":
      return "all";

    default:
      return state;
  }
};

export const dashboardDifficulty = (state = "any", action) => {
  switch (action.type) {
    case "dashboard/difficultyLevel/easy":
      return "easy";

    case "dashboard/difficultyLevel/medium":
      return "medium";

    case "dashboard/difficultyLevel/hard":
      return "hard";

    case "dashboard/difficultyLevel/any":
      return "any";

    default:
      return state;
  }
};

export const codingAttemptsLeftReducer = (state = 0, action) => {
  switch (action.type) {
    case "dashboard/codingAttemptsLeft":
      return action.payload;

    default:
      return state;
  }
};
export const quizAttemptsLeftReducer = (state = 0, action) => {
  switch (action.type) {
    case "dashboard/quizAttemptsLeft":
      return action.payload;

    default:
      return state;
  }
};

export const stringSearchReducer = (state = "", action) => {
  switch (action.type) {
    case "dashboard/stringSearch":
      return action.payload;

    default:
      return state;
  }
};

export const dashboard = combineReducers({
  videoId: videoIdReducer,
  qAndAQuestion: QandAReducer,
  assignmentType: assignmentQuizReducer,
  difficultyLevel: dashboardDifficulty,
  codingAttemptsLeft: codingAttemptsLeftReducer,
  quizAttemptsLeft: quizAttemptsLeftReducer,
  stringSearch: stringSearchReducer,
});

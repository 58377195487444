import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { BASE_URL } from "../../env";
// import CompilerCodingExam from "../../componentsofcourse/CompilerCodingExam";
import "../../Assets/css/exam.css";
import { AnimatePresence, motion } from "framer-motion";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HybridCodingCompiler from "./HybridCodingCompiler";
// import { AuthContext } from "../../Context/AuthContext";
const HybridExamm = ({ codingExams, scoringArray, setscoringArray }) => {
  const [timing, setTiming] = useState(1);
  const [showExamCompiler, setshowExamCompiler] = useState(false);
  const [showQuestions, setshowQuestions] = useState(true);
  const [showPopup, setshowPopup] = useState(false);
  const Languagevalues = ["C++", "Java", "Python3"];
  const [selectLanguage, setSelectedLanguage] = useState(Languagevalues[1]);
  const [currQuesId, setcurrQuesId] = useState("");
  const [prompt, setprompt] = useState(false);
  const [name, setname] = useState("");
  const [email, set_email] = useState("");
  const [user_id, set_Id] = useState("");

  useEffect(() => {
    fetch(BASE_URL + "/getProfile", {
      headers: { authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then((res) => res.json())
      .then((data) => {
        setname(data?.user?.firstName + " " + data?.user?.lastName);
        set_email(data?.user?.email);
        set_Id(data?.user?._id);
        localStorage.setItem("emailID", data?.user?.email);
      });
  }, []);

  useEffect(() => {
    const email = localStorage.getItem("emailID");
    if (email == undefined) {
      history.push("/signup?ref=codingexamination");
    }
  }, []);

  // useEffect(() => {
  //   window.addEventListener("beforeunload", alertUser);
  //   window.addEventListener("unload", handleEndConcert);
  //   return () => {
  //     window.removeEventListener("beforeunload", alertUser);
  //     window.removeEventListener("unload", handleEndConcert);
  //     handleEndConcert();
  //   };
  // }, []);

  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
    setprompt(true);
  };

  // const handleEndConcert = async (e) => {
  // const time = new Date();
  // console.log()
  // localStorage.setItem()
  // localStorage.setItem("timer"+id,`${hourss},${minutess}`)

  // console.log(e);
  // history.push('/examsubmitted')
  // };

  const { id } = "60fe75ce9ff4cc7af8b1d372";
  const [code, setcode] = useState("");
  const [questionsolved, setquestionsolved] = useState([]);
  const [solvedIndices, setsolvedIndices] = useState([]);
  const history = useHistory();

  // function FinishExam() {
  //   fetch(BASE_URL + "/createCompilerTestResults", {
  //     method: "POST",
  //     headers: {
  //       "content-type": "application/json",
  //       authorization: "Bearer " + localStorage.getItem("token"),
  //     },
  //     body: JSON.stringify({
  //       email: localStorage.getItem("emailID"),
  //       examId: id,
  //       scoring: scoringArray,
  //       timeTaken: "",
  //     }),
  //   })
  //     .then((response) => response.json())
  //     .then((result) => {
  //       console.log("done" + result);

  //       history.push({
  //         pathname: "/examsubmitted",
  //         search: "?query=abc",
  //         state: {
  //           result: {
  //             email: localStorage.getItem("emailID"),
  //             examId: id,
  //             scoring: scoringArray,
  //             timeTaken: newtimer,
  //           },
  //         },
  //       });
  //     })
  //     .catch((error) => console.log("error", error));
  // }

  useEffect(() => {
    fetch(
      BASE_URL +
        "/checkNumberofattempts/" +
        id +
        "/" +
        localStorage.getItem("emailID"),
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.exam == false) {
          history.push("/examdone");
        } else {
          fetch(BASE_URL + "/getExam/" + id, {
            method: "GET",
            "Content-type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          })
            .then((res) => res.json())
            .then((data) => {
              setshowPopup(true);
            })
            .catch((err) => console.log(err));
        }
      });
  }, [email]);

  function CheckSolvedorNot(id) {
    // if()
    for (var singleid of questionsolved) {
      if (singleid == id) {
        return true;
      }
    }
    return false;
  }

  function CheckExistorNot(id) {
    if (id == _id) {
      return true;
    } else {
      return false;
    }
  }

  const [_id, setchoosenQuestion] = useState(
    codingExams?.questions ? codingExams?.questions[0] : ""
  );

  function ChooseQuestion(id) {
    setsolvedIndices([]);

    let myPromise = new Promise(function (myResolve, myReject) {
      // "Producing Code" (May take some time)
      // alert("this runs")
      localStorage.setItem(_id + selectLanguage, code);
      myResolve(); // when successful
      myReject(); // when error
    });
    // if (localStorage.getItem(_id+selectLanguage) === null) {
    myPromise.then(
      function (value) {
        setcurrQuesId(id);
        setchoosenQuestion(id);
      },
      function (error) {
        alert("error");
        /* code if some error */
      }
    );

    // setchoosenQuestion(id);
  }

  return (
    <div>
      <div
        className="d-flex flex-row flex-grow gx-1  "
        style={{ filter: showPopup === true && "blur(2px)" }}
      >
        <AnimatePresence>
          <motion.div
            // transition={{opacity:0}}
            className={
              showQuestions ? " w-50 trans-width  " : " trans-width w-10 "
            }
            style={{
              backgroundColor: "#02203c",
              paddingRight: "1px",
              marginBottom: "1px",
            }}
          >
            <motion.div
              className=""
              style={{ backgroundColor: "#001528", position: "relative" }}
            >
              <button
                style={{
                  float: "right",
                  top: "50vh",
                  border: "none",
                  background: "none",
                  fontSize: "25px",
                  marginLeft: "10px",
                  position: "relative",
                  // top: "50%",
                  left: "13px",
                  zIndex: "33",
                }}
                onClick={() => {
                  setshowQuestions(!showQuestions);
                }}
              >
                <i
                  class={
                    showQuestions
                      ? " animating-toggle-exam shadow fas fa-chevron-right text-white "
                      : "  animating-toggle-exam-closed shadow fas fa-chevron-right text-white  "
                  }
                ></i>
              </button>
            </motion.div>

            <div className="my-auto mt-4">
              {codingExams?.questions?.map((val, index) => {
                return (
                  <button
                    onClick={() => {
                      ChooseQuestion(val);
                    }}
                    className={
                      !CheckExistorNot(val)
                        ? " py-2 exam-question "
                        : " py-2 exam-question selected-exam-question "
                    }
                    style={{
                      backgroundColor: CheckSolvedorNot(val) ? "#1c1c " : null,
                      cursor: "pointer",
                      color: "white",
                      fontSize: "18px",
                    }}
                  >
                    {showQuestions ? (
                      <span>
                        {val}
                        {CheckSolvedorNot(val) && (
                          <div className="" style={{ float: "right" }}>
                            {" "}
                            <CheckCircleIcon />
                          </div>
                        )}
                      </span>
                    ) : (
                      <span>
                        {++index}{" "}
                        {CheckSolvedorNot(val) && (
                          <div className="" style={{ float: "right" }}>
                            {" "}
                            <CheckCircleIcon />
                          </div>
                        )}
                      </span>
                    )}
                  </button>
                );
              })}
            </div>
          </motion.div>
        </AnimatePresence>
        <div
          className={showQuestions ? "" : ""}
          style={{ marginBottom: "1px", paddingLeft: "0px" }}
        >
          <HybridCodingCompiler
            solvedIndices={solvedIndices}
            setsolvedIndices={setsolvedIndices}
            questionsolved={questionsolved}
            setquestionsolved={setquestionsolved}
            scoringArray={scoringArray}
            setscoringArray={setscoringArray}
            examid={id}
            Languagevalues={Languagevalues}
            code={code}
            id={_id}
            selectLanguage={selectLanguage}
            setSelectedLanguage={setSelectedLanguage}
            setcode={setcode}
            totalTime={timing}
          />
        </div>
      </div>
    </div>
  );
};

export default HybridExamm;

const batchNameInitial = null;


export const batchReducer = (state = batchNameInitial, action) => {

    switch(action.type){

        case "powerbi/batchName":
            return action.payload;

        default:
            return state;
    }
}

export const examType = (state = "coding", action) => {

    switch(action.type){

        case "powerbi/examType":
            return action.payload;

        default:
            return state;
    }
}

const examDataState = {
    assignments: {
        total: 0,
        count: 0,
        percent: 0
    },
    tests: {
        total: 0,
        count: 0,
        percent: 0
    }
}

export const examData = (state=examDataState, action) => {
    switch(action.type){

        case "powerbi/examData":
            return action.payload

        default:
            return state;
    }
}
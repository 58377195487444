import { useState, useEffect, useContext } from "react";

import Footer from "./Footer";
import style from "../Assets/css/profile.module.css";
import Navbar from "./Navbar";
import logo from "../Assets/img/Home/cantileverlogo-cut.png";
import defaultImage from "../Assets/img/Ellipse.png";
import personImage from "../Assets/img/Icon material-account-circle.png";
import courseImage from "../Assets/img/Icon material-library-books.png";
import homeImage from "../Assets/img/Icon metro-home.png";
import resource from "../Assets/img/Icon material-class.png";
import logout_img from "../Assets/img/Home/favicon.png";
import { store } from "react-notifications-component";
import { AuthContext } from "../Context/AuthContext";
import styles from "./Coupons.module.css";
import { Link, useHistory } from "react-router-dom";
import useMedia from "../componentsofcourse/useMedia";
import gradient from "../Assets/img/Ellipse.png";
import { BASE_URL } from "../env";
import Logo from "../Assets/img/smallLogo.jpeg";
// import Sidebar from "./Sidebar";
// import Notifications from "./Notifications";
import HelmetComp from "./HelmetComp";
function Profile(props) {
  if (props.location.state) {
    var data = props.location.state.data;
  }
  const [profile, setProfile] = useState(data);

  const { token, update_token } = useContext(AuthContext);
  const [course, setCourses] = useState([
    // id: "1",
    // courseName: "Comptetive Programming",
    // videos: [],
    // subscribedDate: "",
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetch(BASE_URL + "/getProfile", {
      headers: { authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log(data.student.smallCourses);
        setCourses(data.student.smallCourses);
      });
  }, []);
  const history = useHistory(0);
  const editHandler = () => {
    props.history.push({
      pathname: "/edit",
      state: {
        data: profile,
      },
    });
  };

  const notifyPopup = (backend_message, type) => {
    store.addNotification({
      title: backend_message,
      message: backend_message,
      type: type,
      background: "pink",
      insert: "bottom",
      container: "bottom-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true,
      },
    });
  };
  const matches = useMedia("(min-width : 768px )");
  const logout = () => {
    localStorage.clear();
    update_token("");
    notifyPopup("You are Logged Out!!", "danger");
    history.push("/");
  };
  useEffect(() => {
    fetch(BASE_URL + "/getProfile", {
      headers: { authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then((res) => res.json())

      .then((data) => {
        // console.log(data);

        setProfile(data);
      });
  }, [token]);

  const deleteUser = () => {
    fetch(BASE_URL + "/deleteUser", {
      method: "POST",
      headers: { authorization: "Bearer " + localStorage.getItem("token") },
      body: JSON.stringify({ _id: profile.user._id }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.message) {
          // console.log("User Deleted", data.message);
          props.history.push({
            pathname: "/",
            state: {
              data: null,
            },
          });
          localStorage.clear();
          update_token("");
        }
        if (data.error) {
          // console.log("User Error", data.error);
        }
      })
      .catch((err) => {
        // console.log("error in fetch", err);
      });
  };
  const d = profile ? (profile.user ? profile.user.lastLoginDate : null) : null;
  const loginDate = new Date(d);
  const sign_date = profile
    ? profile.user
      ? profile.user.signUp_date
      : null
    : null;
  const signUp_date = new Date(sign_date);
  function formatDate(date) {
    var day = date.getDate();
    if (day < 10) {
      day = "0" + day;
    }
    var month = date.getMonth() + 1;
    if (month < 10) {
      month = "0" + month;
    }
    var year = date.getFullYear();
    return day + "/" + month + "/" + year;
  }

  return (
    <>
      {/* <Navbar /> */}
      <HelmetComp
        info={{
          title: "Classroom",
          // description: '',
          // siteName: '',
          // summary: '',
          // pageUrl: '',
          // imagUrl: ''
        }}
      />
      <div
        className={styles.newfonts}
        style={{ overflowX: "hidden", backgroundColor: "#ECF0F1" }}
      >
        <div
          className={"container-fluid background " + styles.scrollbarY}
          style={{
            height: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            className="col-xl-12 col-lg-12 col-md-12 col-sm-6 signup-text mx-auto"
            style={{ backgroundColor: "#fff" }}
          >
            <div className="page-content page-container" id="page-content">
              <div className="padding">
                <div className="row">
                  {/* <Sidebar /> */}
                  <div className="col-xl-12 col-lg-9 col-md-9 col-sm-6 my-5">
                    <div
                      className={style.middle_content}
                      style={{
                        width: "80%",
                        marginLeft: matches == true ? "10%" : "0px",
                      }}
                    >
                      {/* <div className="row justify-content-center">
                        <div className="col-xl-7 col-lg-7 col-md-7 col-sm-6"> */}
                      <div>
                        <h4 className={style.profile_hd_J}>My Classroom</h4>
                      </div>
                      {/*  */}
                      <div
                        className={
                          "container rounded  my-3 " + styles.profile_box_1_J
                        }
                        style={{ backgroundColor: "#fff" }}
                      >
                        <div className="row">
                          <div className="courses my-5">
                            {course.length == 0 ? (
                              <p>
                                {" "}
                                You are not enrolled in any of the courses{" "}
                              </p>
                            ) : (
                              <>
                                {course.map((value, index) => {
                                  return (
                                    <div className="my-4">
                                      <div
                                        className={
                                          "card shadow row p-4 " +
                                          styles.gradient
                                        }
                                      >
                                        <div className="col">
                                          <span>
                                            <img
                                              style={{
                                                width: "65px",
                                                height: "65px",
                                              }}
                                              src={Logo}
                                              alt=""
                                            />

                                            <span
                                              className={style.underline}
                                              style={{
                                                fontSize: "20px",
                                                marginLeft: "3%",
                                                fontFamily: "'DM Sans'",
                                                color: "black",
                                              }}
                                            >
                                              {/* {value?.name} */}
                                              <span
                                                dangerouslySetInnerHTML={{
                                                  __html: `${value?.name}`,
                                                }}
                                              ></span>
                                              <div
                                                className={"" + styles.borders}
                                                style={{ marginLeft: "12%" }}
                                              ></div>
                                              {/* <hr style={{border:"4px solid #180ADB",width:"10%",marginLeft:"9%"}} /> */}
                                            </span>
                                          </span>
                                        </div>
                                        <div className="col">
                                          <br />
                                          <div className="d-flex flex-row justify-content-between my-3">
                                            <span
                                              className={
                                                styles.newfonts + " text-muted"
                                              }
                                              style={{
                                                fontFamily: "DM Sans",
                                                color: "grey",
                                              }}
                                            >
                                              <br />

                                              <span
                                                style={{
                                                  fontFamily: "DM Sans",
                                                  fontWeight: "500",
                                                  color: "black",
                                                }}
                                              ></span>
                                            </span>
                                            <Link
                                              to={{
                                                pathname: `/course-page/${value.courseId}/`,
                                                aboutProps: {
                                                  name: `${value.courseId}`,
                                                },
                                              }}
                                              className="btn btn-primary p-2"
                                            >
                                              VIEW COURSE
                                            </Link>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <Notifications /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Profile;

{
  /* <div
                        className={`${style.profile_box_1_J} my-5 p-5`}
                        style={{}}
                      >
                        <div className="row justify-content-between">
                          <div
                            className={`${style.profile_box_part_J} col-xl-2 col-lg-2 col-md-10 mr-5`}
                          >
                            <img
                              className={`${style.profile_img_J}`}
                              src={
                                profile
                                  ? profile.user
                                    ? profile.user.profile_pic
                                    : defaultImage
                                  : defaultImage
                              }
                              alt=""
                            />
                          </div>
                          <div
                            className={`${style.profile_box_part_J} pl-5 col-xl-8 col-lg-8 col-md-10 ml-xl-5 ml-lg-5 ml-md-0`}
                          >
                            <div className="row">
                              <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 mt-4">
                                <h4 className={`${style.profile_hd1_J} mr-5`}>
                                  {profile
                                    ? profile.user
                                      ? profile.user.firstName +
                                        " " +
                                        profile.user.lastName
                                      : null
                                    : null}
                                </h4>
                              </div>

                              <div className="ml-xl-5 ml-lg-0 col-xl-2 col-lg-3 col-md-2 col-sm-2 mt-4">
                                <button
                                  className={`btn btn-outline-primary ${style.profile_button_J}`}
                                  onClick={editHandler}
                                >
                                  Edit
                                </button>
                              </div>
                            </div>
                            <div className="my-2 ml-4">
                              <p className={style.profile_p_J}>
                                {profile
                                  ? profile.student
                                    ? profile.student.description
                                    : null
                                  : null}
                              </p>
                            </div>
                            <div className="mt-4">
                              <p className={style.profile_p1_J}>
                                {profile
                                  ? profile.user
                                    ? profile.user.email
                                    : null
                                  : null}
                              </p>
                              <p className={style.profile_p1_J}>
                                {profile
                                  ? profile.student
                                    ? profile.student.phoneNumber
                                    : null
                                  : null}
                              </p>
                            </div>
                            <div className="row mt-5">
                              {profile
                                ? profile.student
                                  ? profile.student.skills.map((item) => {
                                      return (
                                        <div
                                          className={`btn shadow mx-2 mx-sm-1 p-1 p-sm-2  ${style.profile_skills_J} my-2 col-xl-2 col-lg-2 col-md-3 col-sm-5`}
                                        >
                                          {item}
                                        </div>
                                      );
                                    })
                                  : null
                                : null}
                            </div>
                          </div>
                        </div>
                      </div> */
}

import React, { useEffect, useState } from "react";
import style from "../Assets/css/myCourseVideoCard.module.css";
import play_button from "../Assets/img/play_btn.png";
import DwnldBlue from "../Assets/img/dwnld-blue.png";
import DwnldPurple from "../Assets/img/dwnld-purple.png";
import tick from "../Assets/img/completed_tick.png";
import BookmarkSvg from "../Assets/img/white-star.svg";
import UnBookmarkSvg from "../Assets/img/yellow-star.svg";
import TempPopup from "./TempPopup";
import firebase from "../firebase";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL } from "../env";
import "../Assets/css/references.css";

const References = ({
  video,
  bigHeading,
  sections,
  changed,
  progressArray,
  courseID,
  setChanged,
  bookmarkArr,
  userEmail,
  userName,
  adminornot,
  variation,
}) => {
  const [currentname, setcurrentName] = useState("");
  const [currentDescription, setcurrentDescription] = useState("");
  const [currentlink, setcurrentlink] = useState("");
  const [currentsection, setcurrentsection] = useState("");
  const [currentbigHeading, setcurrentbigHeading] = useState("");
  const [currentresourceTwo, setcurrentresourceTwo] = useState("");
  const [currentresourceOne, setcurrentresourceOne] = useState("");
  const [currentimg, setcurrentimg] = useState("");
  const [currentlearningpoints, setcurrentlearningpoints] = useState([]);
  const [newcurrentlearningpointstring, addnewcurrentlearningpointstring] =
    useState("");
  const analytics = firebase.analytics();
  const [showModal, setModalShow] = useState(false);
  const [videoLink, setvideoLink] = useState("");
  const [num, setNum] = useState(0);
  const [isAdmin, setIsAdmin] = useState(false);

  var headingsAndSections = [];
  var arr = [];
  const [mainArray, setMainArray] = useState([]);
  function checkExistsorNot(progressArray, videoID) {
    for (var id of progressArray) {
      if (id == videoID) {
        return true;
      }
    }
  }
  function checkExists(bookmarkArray, videoID) {
    for (var id of bookmarkArray) {
      if (id == videoID) {
        return true;
      }
    }
  }

  const completedFunction = async (videoId) => {
    await axios({
      method: "POST",
      data: {},
      headers: { authorization: "Bearer " + localStorage.getItem("token") },
      url: `${BASE_URL}/progress/${courseID}/${videoId}`,
    });
    setChanged(!changed);
  };
  const InProgress = async (videoId) => {
    await axios({
      method: "POST",
      data: {},
      headers: { authorization: "Bearer " + localStorage.getItem("token") },
      url: `${BASE_URL}/in-progress/${courseID}/${videoId}`,
    });
    setChanged(!changed);
  };

  const unCheckFunction = async (videoId) => {
    await axios({
      method: "POST",
      data: {},
      headers: { authorization: "Bearer " + localStorage.getItem("token") },
      url: `${BASE_URL}/uncheckVideo/${courseID}/${videoId}`,
    });
    console.warn(
      `id are : couse ${courseID}, video ${videoId}, token ${localStorage.getItem(
        "token"
      )}`
    );
    setChanged(!changed);
  };
  const bookmarkFunction = async (videoId) => {
    await axios({
      method: "POST",
      data: {},
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token"),
      },
      url: `${BASE_URL}/bookmark/${courseID}/${videoId}`,
    });
    notify();
    setChanged(!changed);
  };

  useEffect(() => {
    // alert(adminornot)
    fetch(BASE_URL.BASE_URL + "/getProfile", {
      headers: {
        "Content-Type": "application/json",
        authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        // alert(data.user.isAdmin)
        setIsAdmin(data.user.isAdmin);
        if (data.error) {
        } else {
        }
      });
  }, []);
  const notify = () => {
    // alert("bookmark added")
    toast("📑 Added as a Bookmark!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const unbookmarkFunction = async (videoId) => {
    await axios({
      method: "POST",
      data: {},
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token"),
      },
      url: `${BASE_URL}/unbookmark/${courseID}/${videoId}`,
    });
    setChanged(!changed);
  };
  useEffect(() => {
    headingsAndSections = video.map((value, index) => {
      return { bigHeading: value.bigHeading, section: value.section };
    });
    bigHeading.forEach((element) => {
      var setS = new Set();
      video.forEach((v) => {
        if (element === v.bigHeading) {
          setS.add(v.section);
        }
      });
      var tmp = Array.from(setS);

      arr.push({ bigHeading: element, subHeadings: tmp });
    });
    setMainArray(arr);
    // console.log(arr);
    // console.log(progressArray)
  }, []);

  function closePopup() {
    setModalShow((prev) => !prev);
  }
  const [uploadlink, setuploadlink] = useState("");
  const [uploadName, setuploadName] = useState("");
  const [Uploadsection, setUploadSection] = useState("");
  const [UploadbigHeading, setUploadbigHeading] = useState("");
  const [uploadDescription, setuploadDescription] = useState("");
  const [resourceOne, setResourceOne] = useState("");
  const [resourceTwo, setResourceTwo] = useState("");
  const [imgUrl, setImgurl] = useState("");
  const [addcurrentimg, setaddcurrentimg] = useState("");
  const [addvideourl, setvideourl] = useState("");
  const [addname, setaddname] = useState("");
  const [addsection, setaddsection] = useState("");
  const [addbigheading, setaddbigheading] = useState("");
  const [adddescription, setadddescription] = useState("");
  const [addresourceOne, setaddresourceOne] = useState("");
  const [addresourceTwo, setaddresourcetwo] = useState("");
  const [addlearngingpoints, setaddlearningpoints] = useState("");

  useEffect(() => {
    // console.log(currentlearningpoints)
    let learningpointarray = currentlearningpoints.join(",");
    // console.log(learningpointarray)
    addnewcurrentlearningpointstring(learningpointarray);
  }, [currentlearningpoints]);

  const [currentvideoID, setcurrentvideoID] = useState("");
  const [deleteVideoID, setdeleteVideoID] = useState("");
  function deleteVideo() {
    const body = {
      _id: courseID,
      videoId: deleteVideoID,
    };
    fetch(BASE_URL + "/deletevideo", {
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  const SubmitVideoObject = async () => {
    let array = addlearngingpoints.split(";");
    console.log(array);
    let something_data = {
      course_id: courseID,
      videos: {
        learningPoints: array,
        imgurl: addcurrentimg,
        link: addvideourl,
        section: addsection,
        resourseOne: addresourceOne,
        resourseTwo: addresourceTwo,
        bigHeading: addbigheading,
        name: addname,
        description: adddescription,
      },
    };

    await fetch(BASE_URL + "/updateVideoObject", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(something_data),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
      })
      .catch((err) => {
        console.log(err);
      });
    console.log(something_data);
  };
  const AddForm = () => {
    return (
      <>
        <h1>Upload any video</h1>
        <div className="border border-3 d-flex flex-column p-5">
          <div className="form-control">
            <label>Image</label>
            <input
              value={addcurrentimg}
              type="text"
              id="link"
              placeholder="link"
              onChange={(e) => {
                setaddcurrentimg(e.target.value);
              }}
            />
          </div>
          <div className="form-control">
            <label htmlFor="">video URL</label>
            <input
              value={addvideourl}
              type="text"
              id="link"
              placeholder="link"
              onChange={(e) => {
                setvideourl(e.target.value);
              }}
            />
          </div>
          <div className="form-control">
            <label>Name</label>
            <input
              value={addname}
              type="text"
              id="name"
              placeholder="name"
              onChange={(e) => {
                setaddname(e.target.value);
              }}
            />
          </div>
          <div className="form-control">
            <label>Section</label>
            <input
              value={currentsection}
              type="text"
              id="section"
              placeholder="section"
              onChange={(e) => {
                setcurrentsection(e.target.value);
              }}
            />
          </div>
          <div className="form-control">
            <label>Big Heading</label>
            <input
              value={addbigheading}
              type="text"
              id="bigHeading"
              placeholder="bigHeading"
              onChange={(e) => {
                setaddbigheading(e.target.value);
              }}
            />
          </div>

          <div className="form-control">
            <label>Description</label>
            <input
              value={adddescription}
              type="text"
              id="description"
              placeholder="description"
              onChange={(e) => {
                setadddescription(e.target.value);
              }}
            />
          </div>
          <div className="form-control">
            <label>Resource One</label>
            <input
              value={addresourceOne}
              type="text"
              id="resourceOne"
              placeholder="resourceOne"
              onChange={(e) => {
                setaddresourceOne(e.target.value);
              }}
            />
          </div>

          <div className="form-control">
            <label>Resource two</label>
            <input
              value={addresourceTwo}
              type="text"
              id="resourceTwo"
              placeholder="resourceTwo"
              onChange={(e) => {
                setaddresourcetwo(e.target.value);
              }}
            />
          </div>

          <div className="form-control">
            <label htmlFor="">
              {" "}
              Learning points Note: add semi colon between each points
            </label>
            <input
              value={addlearngingpoints}
              type="text"
              id="resourceTwo"
              placeholder="resourceTwo"
              onChange={(e) => {
                setaddlearningpoints(e.target.value);
              }}
            />
          </div>

          <button
            onClick={() => {
              SubmitVideoObject();
            }}
          >
            Submit
          </button>
        </div>
      </>
    );
  };

  const [showupdateform, setshowupdateform] = useState(false);
  const [currentvideourl, setcurrentvideourl] = useState("");
  async function SubmitEditVideo() {
    let learningpointsarraynew = newcurrentlearningpointstring.split(",");

    console.log(learningpointsarraynew);

    let data_update = {
      _id: courseID,
      videoId: currentvideoID,
      point: learningpointsarraynew,
      imgurl: currentimg,
      link: currentvideourl,
      section: currentsection,
      resourseOne: currentresourceOne,
      resourseTwo: currentresourceTwo,
      bigHeading: currentbigHeading,
      name: currentname,
      description: currentDescription,
    };

    await fetch(BASE_URL + "/updatevideo", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data_update),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function UpdateForm() {
    //this is a string not an array
    // console.log(learningpointarray)
    return (
      <>
        <h1>Update {currentname} video</h1>
        <div className="border border-3 d-flex flex-column p-5">
          <label>Image</label>
          <input
            value={currentimg}
            type="text"
            id="link"
            placeholder="link"
            onChange={(e) => {
              setcurrentimg(e.target.value);
            }}
          />
          <label>Image</label>
          <input
            value={currentvideourl}
            type="text"
            id="link"
            placeholder="link"
            onChange={(e) => {
              setcurrentvideourl(e.target.value);
            }}
          />
          <label>Name</label>
          <input
            value={currentname}
            type="text"
            id="name"
            placeholder="name"
            onChange={(e) => {
              setcurrentName(e.target.value);
            }}
          />
          <label>Section</label>
          <input
            value={currentsection}
            type="text"
            id="section"
            placeholder="section"
            onChange={(e) => {
              setcurrentsection(e.target.value);
            }}
          />
          <label>Big Heading</label>
          <input
            value={currentbigHeading}
            type="text"
            id="bigHeading"
            placeholder="bigHeading"
            onChange={(e) => {
              setcurrentbigHeading(e.target.value);
            }}
          />
          <label>Description</label>
          <input
            value={currentDescription}
            type="text"
            id="description"
            placeholder="description"
            onChange={(e) => {
              setcurrentDescription(e.target.value);
            }}
          />
          <label>Resource One</label>
          <input
            value={currentresourceOne}
            type="text"
            id="resourceOne"
            placeholder="resourceOne"
            onChange={(e) => {
              setcurrentresourceOne(e.target.value);
            }}
          />
          <label>Resource two</label>
          <input
            value={currentresourceTwo}
            type="text"
            id="resourceTwo"
            placeholder="resourceTwo"
            onChange={(e) => {
              setcurrentresourceTwo(e.target.value);
            }}
          />
          <div class="form-control">
            <label htmlFor="">
              Learning points NOTE:add comma after every point
            </label>
            <input
              type="text"
              value={newcurrentlearningpointstring}
              onChange={(e) => {
                addnewcurrentlearningpointstring(e.target.value);
              }}
            />
          </div>
          <button
            onClick={() => {
              SubmitEditVideo();
            }}
          >
            Submit
          </button>
        </div>
      </>
    );
  }

  return (
    <>
      {showModal ? (
        <TempPopup video={videoLink} handleClose={closePopup} />
      ) : null}

      {mainArray.map((value) => {
        return (
          <>
            <ToastContainer
              theme="dark"
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
            <div
              className={`shadow heading mb-4 text-light py-3 px-2 rounded `}
              style={{ backgroundColor: "#00316e" }}
            >
              <h3 style={{ marginBottom: "0px" }} className="mx-5">
                {" "}
                {value.bigHeading}
              </h3>
            </div>

            {value.subHeadings.map((item) => {
              return (
                <>
                  <h4 className="mx-5" style={{ fontWeight: "600" }}>
                    {item}
                  </h4>
                  <div
                    className="row  py-4  d-flex justify-content-start example "
                    style={{ overflowX: "scroll" }}
                  >
                    {video.map((videovalue, index) => {
                      if (
                        videovalue.bigHeading === value.bigHeading &&
                        videovalue.section === item
                      ) {
                        return (
                          <>
                            <div
                              class="courses-container col-lg-4 col-md-4 col-sm-4 col-xs-4 py-2"
                              style={{ transform: "scale(0.98)" }}
                            >
                              <div
                                class="course-dashboard my-3 py-2 shadow"
                                style={{
                                  borderLeft:
                                    videovalue.bigHeading ===
                                    "Interview Preparation"
                                      ? "5px solid #660080 "
                                      : "5px solid #eea47fff",
                                }}
                              >
                                <div
                                  class="course-dashboard-preview"
                                  style={{ padding: "0px" }}
                                >
                                  {/* <img
																		src={videovalue.imgurl}
																		alt=''
																		style={{ width: "220px", height: "220px" }}
																	/> */}
                                  {/* <lottie-player src={videovalue.imgurl} background="transparent"  speed="1"
                                  style={{ width: "160px", height: "160px" }} loop autoplay>
                              </lottie-player> */}
                                </div>
                                <div class="course-info-dashboard">
                                  <div class="progress-container-dashboard">
                                    <span class="progress-text-dashboard">
                                      {videovalue.resourseTwo}
                                    </span>
                                  </div>
                                  {/* <h6>Lecture {index + 1}</h6> */}
                                  <h2 class="video-dashboard-title my-0">
                                    {videovalue.name}
                                  </h2>
                                  <button
                                    class="btn-course-dashboard btn btn-primary btn-sm"
                                    onClick={() => {
                                      setModalShow(true);
                                      setvideoLink(videovalue.link);
                                      InProgress(videovalue._id);
                                    }}
                                  >
                                    <i className="fas fa-play"></i> Play
                                  </button>
                                  {variation !== 0 && (
                                    <a
                                      class="btn-course-dashboard-notes btn-outline-primary btn btn-sm "
                                      href={videovalue.resourseOne}
                                      target="blank"
                                    >
                                      Notes
                                    </a>
                                  )}
                                  {adminornot && (
                                    <>
                                      <button
                                        onClick={() => {
                                          setdeleteVideoID(videovalue._id);
                                          deleteVideo();
                                        }}
                                      >
                                        Delete this video
                                      </button>
                                    </>
                                  )}
                                  {!checkExistsorNot(
                                    progressArray,
                                    videovalue._id
                                  ) ? (
                                    <button
                                      className={`btn btn-outline-primary newbtn-card btn-sm`}
                                      onClick={() => {
                                        completedFunction(videovalue._id);
                                        if (num > 0) {
                                        }
                                      }}
                                      style={{ fontSize: "1rem" }}
                                    >
                                      Mark as complete
                                    </button>
                                  ) : (
                                    <button
                                      className={`btn btn-outline-primary newbtn-card btn-sm`}
                                      onClick={() => {
                                        unCheckFunction(videovalue._id);
                                        if (num > 0) {
                                        }
                                      }}
                                    >
                                      In Progress
                                    </button>
                                  )}
                                  {/* { checkExistsorNot(progressArray,videovalue._id) ?<button onClick={()=>completedFunction(videovalue._id)}> Mark as Complete </button>: <button onClick={()=>unCheckFunction(videovalue._id)}>In Progress </button> }               */}
                                  {adminornot && (
                                    <>
                                      <button
                                        class=""
                                        onClick={() => {
                                          setcurrentimg(videovalue.imgurl);
                                          setcurrentlearningpoints(
                                            videovalue.learningPoints
                                          );
                                          setcurrentvideourl(videovalue.link);
                                          setcurrentName(videovalue.name);
                                          setcurrentsection(videovalue.section);
                                          setcurrentbigHeading(
                                            videovalue.bigHeading
                                          );
                                          setcurrentDescription(
                                            videovalue.description
                                          );
                                          setcurrentresourceOne(
                                            videovalue.resourseOne
                                          );
                                          setcurrentresourceTwo(
                                            videovalue.resourseTwo
                                          );
                                          setcurrentvideoID(videovalue._id);
                                          setshowupdateform(true);
                                          window.scrollTo(
                                            0,
                                            document.body.scrollHeight - 1300
                                          );
                                        }}
                                      >
                                        Change The Edit Button
                                      </button>
                                    </>
                                  )}
                                  {/* <ul>
																		{videovalue.learningPoints
																			.slice(0, 3)
																			.map((singlePoint) => {
																				return (
																					<li className='courseDashboardpoints'>
																						{singlePoint}
																					</li>
																				);
																			})}
																	</ul> */}
                                </div>
                              </div>
                            </div>{" "}
                          </>
                        );
                      }
                    })}
                  </div>
                </>
              );
            })}
          </>
        );
      })}
      {/* {adminornot ? <AddForm /> : null} */}
      {adminornot ? AddForm() : null}

      {/* {adminornot && <UpdateForm />} */}
      {adminornot && UpdateForm()}
      {/* {bigHeading.map((value,index)=> <p> {value}</p>)} */}
    </>
  );
};

export default References;

import React from "react";
import { Link } from "react-router-dom";

const MoreQueries = () => {
  return (
    <div style={{ overflowX: "hidden" }}>
      <div
        style={{ backgroundColor: "#F3C054", padding: "40px" }}
        className="container-fluid"
      >
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-12 col-lg-6">
              <h2 style={{ fontFamily: "Poppins", textAlign: "center"}} className="float-left">
                More Queries?
              </h2>
            </div>
            <div className="col-md-6 col-sm-12 col-lg-6" style={{            display: "flex",
                  justifyContent: "center"}}>
              <Link
                to="/contactusform"
                style={{
                  backgroundColor: "#1D1D1D",
                  padding: "2% 15% 2%",
                  borderRadius: "12px",
                  fontSize: "20px",

                }}
                className="text-white btn btn-success float-right"
              >
                {" "}
                CONTACT{" "}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MoreQueries;

import React, { useState } from 'react'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { BASE_URL } from '../../env'

const AllHybridExaminationNew = () => {

    const [alldata, setalldata] = useState([])
    
    useEffect(() => {
        fetch(BASE_URL + "/getallexams", {
            headers: {
                "Content-type": "application/json"
            }
        }).then((res) => res.json())
            .then((data) => {
                setalldata(data.exams)
            })  
    },[])
    return (
        <div>

            

            {alldata.map((value, index) => {
                return <>
                    
                    <Link to={"/maininstructions/"+value._id}> {value.nameoftheExam}</Link>
                    

                </>
            })}
        </div>
    )
}

export default AllHybridExaminationNew

import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const RedirectAndSetToken = () => {
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    const token = searchParams.get("token");
    const next_url = searchParams.get("next-url");

    if (typeof token === "string") {
      localStorage.setItem("token", token);
    }

    const updated_url = new URL(next_url || window.location.host);

    for (const [key, value] of searchParams.entries()) {
      if (key === "token" || key === "next-url") continue;
      updated_url.searchParams.set(key, value);
    }

    setTimeout(() => {
      window.location.href = updated_url.toString();
    }, 1000);
  }, [location]);

  return <div>Redirecting...</div>;
};

export default RedirectAndSetToken;

import { useState, useEffect, useRef } from "react";
import Editicon from "../../Assets/img/edit.svg";
import { NavLink } from "react-router-dom";
import Editor from "@monaco-editor/react";
import { ToastContainer, toast } from "react-toastify";
import "../../Assets/css/newCompiler.css";
import HelmetComp from "../HelmetComp";
import { Dropdown, Offcanvas, Form } from "react-bootstrap";
import { lambdaCompiler } from "../../axios.config";

const NewCompiler = ({ hidenavbar }) => {
    const [modes, setModes] = useState(true);
    var [code, setCode] = useState(
        localStorage.getItem("code") !== null
            ? localStorage.getItem("code")
            : "",
    );
    const [show, setShow] = useState(false);
    const [CMDArgs, setCMDArgs] = useState("");
    const [spinner, setSpinner] = useState(false);
    const [userInput, setUserInput] = useState("");
    const [compiledData, setcompiledData] = useState("Compiled code here...");
    const [language, setLanguage] = useState(
        localStorage.getItem("language") !== null
            ? localStorage.getItem("language")
            : "cpp",
    );
    const [FileName, setFileName] = useState("Main");
    const downloadButtonRef = useRef(null);
    const fileName = useRef(null);

    useEffect(() => {
        hidenavbar(true);
        return () => {
            hidenavbar(false);
        };
    }, [hidenavbar]);

    /**
     *
     * @param {string} language
     */
    function getFileType(language) {
        switch (language) {
            case "python":
                return "py";

            default:
                return language;
        }
    }

    useEffect(() => {
        const filename = `${FileName}.${getFileType(language)}`;
        let file = new Blob([code], { type: filename });
        downloadButtonRef.current.href = URL.createObjectURL(file);
        downloadButtonRef.current.download = filename;
    }, [code, language, FileName]);

    const r = document.querySelector(":root");
    function themeMode() {
        r.style.setProperty("--header-color", modes ? "#1f1f1f" : "#dfe4ea");
        r.style.setProperty("--body-color", modes ? "#121212" : "#a4b0be");
        r.style.setProperty("--success-color", modes ? "#008000" : "#008000");
        r.style.setProperty("--head-color", modes ? "#000000" : "#2f3542");
        r.style.setProperty("--white-color", modes ? "#fff" : "#000");
        r.style.setProperty("--compile-color", modes ? "#1E1E1E" : "#58969F");
        r.style.setProperty(
            "--compiledown-color",
            modes ? "#1E1E1E" : "#9AC1E7",
        );
    }
    themeMode();

    function editFileName() {
        fileName.current.select();
    }

    function ExecuteCode() {
        setSpinner(true);
        let lang = language;
        if (language === "java") {
            const regex = /public\sclass\s(\w+)/;
            if (!regex.test(code)) {
                setSpinner(false);
                setcompiledData(`No "public class" found to execute`);
                return;
            }
            code = code.replace(/public\sclass\s(\w+)/, "public class Main");
        }

        let encoded_code = new Buffer(code).toString("base64");

        var newdata = {
            code: encoded_code,
            language: lang,
            input: userInput,
            arguments: [],
        };

        // console.log(encoded_code);

        let output = "";
        lambdaCompiler
            .post("/compile", newdata)
            .then(({ data }) => {
                output = Buffer.from(data.output, "base64").toString("utf8");
                setcompiledData(output);
                // if (data.stdout !== null) {
                //     output = Buffer.from(data.stdout, "base64").toString(
                //         "utf8",
                //     );
                //     setcompiledData(output);
                // } else if (data.compile_output !== null) {
                //     output = Buffer.from(
                //         data.compile_output,
                //         "base64",
                //     ).toString("utf8");
                //     setcompiledData(output);
                // } else {
                //     output = Buffer.from(data.stderr, "base64").toString(
                //         "utf8",
                //     );
                //     setcompiledData(output);
                // }
            })
            .catch(err => {
                if (err.response.status === 400) {
                    output = Buffer.from(
                        err.response.data.error,
                        "base64",
                    ).toString("utf8");
                    setcompiledData(output);
                }
            })
            .finally(() => {
                setSpinner(false);
            });
    }
    const saveData = () => {
        localStorage.setItem("code", code);
        localStorage.setItem("language", language);
        toast.success("Code saved successfully.", {
            position: "bottom-left",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    const handleClose = () => setShow(false);

    return (
        <>
            <HelmetComp
                info={{
                    title: "Cantilever Labs || Compiler",
                }}
            />
            <div id="compiler">
                <ToastContainer />
                <header className="header">
                    <NavLink to="/">
                        <img
                            src={
                                modes
                                    ? "https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644050775219_CLLogo.png"
                                    : "https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644052709864_cantileverlogoblack.png"
                            }
                            className="img-fluid logo"
                            alt="cantilever labs logo"
                        />
                    </NavLink>
                    <div className="btns">
                        <Dropdown className="mx-3">
                            <Dropdown.Toggle
                                variant="success"
                                id="dropdown-basic"
                            >
                                Settings
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => setShow(true)}>
                                    Command line arguments
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <Offcanvas show={show} onHide={handleClose}>
                            <Offcanvas.Header closeButton>
                                <Offcanvas.Title>
                                    Command line arguments
                                </Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <Form.Label>Arguments</Form.Label>
                                <Form.Control
                                    type="text"
                                    placement="arguments"
                                    className="border border-dark"
                                    value={CMDArgs}
                                    onChange={e => setCMDArgs(e.target.value)}
                                />
                                <Form.Text
                                    className={
                                        CMDArgs.length > 500
                                            ? "text-danger"
                                            : "text-muted"
                                    }
                                >
                                    {CMDArgs.length}/500
                                </Form.Text>
                            </Offcanvas.Body>
                        </Offcanvas>

                        <div
                            className="mode"
                            onClick={() => {
                                setModes(!modes);
                            }}
                        >
                            {modes ? (
                                <i class="fas fa-sun"></i>
                            ) : (
                                <i class="fas fa-moon"></i>
                            )}
                        </div>
                        <a className="saveBtn" ref={downloadButtonRef} href="/">
                            Download File
                        </a>
                    </div>
                </header>
                <div className="compilerContainer">
                    <div className="leftContainer">
                        <img
                            src="https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644053523648_pic1.png"
                            className={language === "c" ? "iconActive" : "icon"}
                            alt="c icon"
                            onClick={() => {
                                setCode("// some comment");
                                setLanguage("c");
                            }}
                            title="C"
                        />
                        <img
                            src="https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644218625842_pic2.png"
                            className={
                                language === "cpp" ? "iconActive" : "icon"
                            }
                            alt="c++ icon"
                            onClick={() => {
                                setCode("// some comment");
                                setLanguage("cpp");
                            }}
                            title="C++"
                        />
                        <img
                            src="https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644218713459_pic3.png"
                            className={
                                language === "java" ? "iconActive" : "icon"
                            }
                            alt="java icon"
                            onClick={() => {
                                let java_boilerplate =
                                    "public class Main {\n\tpublic static void main(String[] args){\n\t}\n}";
                                setCode(java_boilerplate);
                                setLanguage("java");
                            }}
                            title="Java"
                        />
                        <img
                            src="https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644218843575_pic4.png"
                            className={
                                language === "python" ? "iconActive" : "icon"
                            }
                            alt="python icon"
                            onClick={() => {
                                setCode("# some comment");
                                setLanguage("python");
                            }}
                            title="Python"
                        />
                    </div>
                    <div className="midContainer">
                        <div className="head">
                            <div className="leftTitle">
                                {" "}
                                {language} Compiler{" "}
                            </div>
                            <div className="rightSide">
                                <i
                                    className="fas fa-redo"
                                    onClick={() => {
                                        setCode("");
                                    }}
                                    title="clear"
                                ></i>
                                <i
                                    class="far fa-save"
                                    title="save code"
                                    onClick={saveData}
                                ></i>
                                <button
                                    className="rubBtn"
                                    disabled={
                                        code === "// some comment" ||
                                        code === "# some comment" ||
                                        code === ""
                                            ? "disabled"
                                            : ""
                                    }
                                    style={{
                                        cursor:
                                            code === "// some comment" ||
                                            code === "# some comment" ||
                                            code === ""
                                                ? "no-drop"
                                                : "pointer",
                                    }}
                                    onClick={ExecuteCode}
                                >
                                    {spinner ? (
                                        <div className="spinner-border spinner-border-sm"></div>
                                    ) : (
                                        "Run Code"
                                    )}
                                </button>
                            </div>
                        </div>
                        <Editor
                            defaultLanguage="c"
                            defaultValue="// some comment"
                            className="editor"
                            language={language}
                            value={code}
                            onChange={event => setCode(event)}
                            theme={modes ? "vs-dark" : "light"}
                        />
                    </div>
                    <div className="rightContainer">
                        <div className="upper">
                            <div className="head">
                                <div className="rawBtn">Output</div>
                                <div className="rawBtn">
                                    <span
                                        style={{
                                            color: "rgb(171, 171, 171)",
                                            fontSize: "0.8rem",
                                        }}
                                    >
                                        File Name:
                                    </span>
                                    &nbsp;&nbsp;
                                    <input
                                        type="text"
                                        placeholder="Enter File Name..."
                                        className="cl-compiler-file-name-input"
                                        value={`${FileName}`}
                                        onChange={e =>
                                            setFileName(e.target.value)
                                        }
                                        ref={fileName}
                                    />
                                    <img
                                        src={Editicon}
                                        alt="edit file"
                                        onClick={editFileName}
                                    />
                                </div>
                            </div>
                            <div className="compilerBody">{compiledData}</div>
                        </div>
                        <div className="lower">
                            <div className="head">
                                <div className="rawBtn">Standard Input</div>
                            </div>
                            <div className="userInput">
                                <textarea
                                    onChange={event => {
                                        setUserInput(event.target.value);
                                    }}
                                    className="form-control"
                                    value={userInput}
                                    cols="40"
                                    rows="8"
                                ></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default NewCompiler;

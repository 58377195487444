import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { BASE_URL } from "../env";
import axios from "axios";

const RegisterPopup = ({ email, show, setshow }) => {
  const [warning, setWarning] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [timer, settimer] = useState(true);
  const [otp, setotp] = useState("");
  const [step, setStep] = useState(1);
  const [reset, setreset] = useState(false);
  const [times, settimes] = useState("");
  const time = new Date();
  time.setSeconds(time.getSeconds() + 300);

  const GenerateOtp = async () => {
    setreset(!reset);
    settimer(true);
    try {
      await axios({
        method: "Post",
        url: `${BASE_URL}/generateotp`,
        data: {
          email: email,
        },
        headers: {
          "Content-type": "application/json",
        },
      });
    } catch (error) {
      console.log("something went wrong");
    }
  };

  // const SignUpUser = async () => {
  //   try {
  //     await axios({
  //       method: "Post",
  //       url: `${BASE_URL}/signupnew`,
  //       data: {
  //         email: email,
  //       },
  //       headers: {
  //         "Content-type": "application/json",
  //       },
  //     });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const VerifyOtp = async (e) => {
    e.preventDefault();
    setSpinner(true);
    try {
      await axios({
        method: "Post",
        url: `${BASE_URL}/verifyotp`,
        data: {
          otp: otp,
          email: email,
        },
        headers: {
          "Content-type": "application/json",
        },
      });
      // SignUpUser();
      setStep(2);
      setSpinner(false);
    } catch (error) {
      setWarning(true);
      setSpinner(false);
      setTimeout(() => {
        setWarning(false);
      }, 6000);
      setotp("");
    }
  };

  useEffect(() => {
    if (show) {
      // if (timedifference <= 0) {
      //   clearInterval(intervalnew);
      // }
      let timerplus = 60000 * 2;
      let timetostart = new Date(new Date().getTime() + timerplus).getTime();
      let timedifference = timetostart - new Date().getTime();
      var intervalnew = setInterval(() => {
        if (timedifference >= 0) {
          let minutes = Math.floor((timedifference / (1000 * 60)) % 60);
          let seconds = Math.floor((timedifference / 1000) % 60);
          let timeleft = minutes + ":" + seconds;
          settimes(timeleft);
          timedifference = timedifference - 1000;
        } else {
          settimer(false);
          clearInterval(intervalnew);
        }
      }, 1000);
    }
    return () => clearInterval(intervalnew);
  }, [show, reset]);

  return (
    <>
      {show ? (
        step === 1 ? (
          <>
            <div className="loginPopup">
              <div className="popupCard">
                <i class="fas fa-times" onClick={() => setshow(false)}></i>
                <div className="title">
                  Verify Email <br />
                  <span>We've sent an OTP to your email address</span>
                </div>
                <form onSubmit={VerifyOtp}>
                  <p className={warning ? "d-block" : "d-none"}>Please Enter correct OTP</p>
                  <div className="email">{email}</div>
                  <div class="form-floating">
                    <input value={otp} onChange={(e) => setotp(e.target.value)} type="text" class="form-control" id="floatingInput" placeholder="0000" required />
                    <label for="floatingInput">Enter your OTP</label>
                  </div>
                  <button type="sumit" className="submitBtn" disabled={spinner ? "disabled" : ""}>
                    {spinner ? <div className="spinner-border spinner-border-sm"></div> : "Verify"}
                  </button>
                </form>
                {timer ? (
                  <p>Resend OTP in {times}</p>
                ) : (
                  <div className="resendLink" onClick={GenerateOtp}>
                    Resend OTP
                  </div>
                )}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="loginPopup">
              <div className="popupCard">
                <i class="fas fa-times" onClick={() => setshow(false)}></i>
                <img src="	https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1645175092526_verify.png" alt="lock icon" className="lockIcon" />
                <div className="content">
                  <div className="congratsMsg">Congratulations !</div>
                  <div className="subText">Your Email ID is Successfully Verified</div>
                  <div className="text">Proceed to Set up your Password</div>
                </div>
                <NavLink
                  to={{
                    pathname: "/login-post",
                    state: {
                      email: email,
                    },
                  }}
                  className="submitBtn"
                >
                  Continue
                </NavLink>
              </div>
            </div>
          </>
        )
      ) : (
        ""
      )}
    </>
  );
};

export default RegisterPopup;

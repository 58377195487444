import React from "react";
import { NavLink } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "../Assets/scss/Register.scss";

const LoginTestimonial = () => {
  const slider = React.useRef(null);
  var settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 5000,
  };
  return (
    <>
      <div className="loginTestimonial">
        <NavLink to="/" className="d-lg-block d-none">
          <img
            src="https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644050775219_CLLogo.png"
            alt="Cantilever Labs logo"
            className="canti-logo"
          />
        </NavLink>
        <div className="testiMid">
          <div className="title">
            our <span>students</span> <br />
            success stories.
          </div>
          <Slider ref={slider} {...settings}>
            {TestiData.map((val, index) => {
              return (
                <div className="testimonial" key={index}>
                  <div className="testiUpper">
                    <img src={val.imgsrc} alt="avatar" className="avatarIcon" />
                    <div className="testiDetail">
                      <div className="testiName">{val.name}</div>
                      <div className="testiDesignation">{val.designation}</div>
                    </div>
                  </div>
                  <div className="testiLower">{val.comment}</div>
                </div>
              );
            })}
          </Slider>
          <div className="testiArrows">
            <i
              className="fas fa-long-arrow-alt-left"
              onClick={() => slider?.current?.slickPrev()}
            ></i>
            <i
              className="fas fa-long-arrow-alt-right"
              onClick={() => slider?.current?.slickNext()}
            ></i>
          </div>
        </div>
      </div>
    </>
  );
};

const TestiData = [
  {
    imgsrc:
      "https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644209505670_Image%201.png",
    name: "SARVESH MEHTANI",
    designation: "Optiver Amsterdam",
    comment:
      "Overall, I loved the fact that the whole process was tailored as per the individual's interests and goals which made it very effective in pointing out our areas of strength and the areas that we need to work on.",
  },
  {
    imgsrc:
      "https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644210574232_ayushi.png",
    name: "AYUSHI SHARMA",
    designation: "FLIPKART",
    comment:
      "The training did not only offer me data based reflection but also gave me an insight into improvement to the informative sessions by extremely qualified resource persons.",
  },
  {
    imgsrc:
      "https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644215381591_NEHA%20RAMYA.jpg",
    name: "NEHA RAMYA",
    designation: "AMAZON",
    comment:
      "Technical trainer was a professional trainer. His guidance was very much a different experience which not only made us learn new things but also made us to be specialised in the work we did.",
  },
  {
    imgsrc:
      "https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644210651298_Abhijeet%20Vichare.jpg",
    name: "ABHIJEET VICHARE",
    designation: "MERILYTICS",
    comment:
      "I was mentored by Cantilever Labs during our placement season at BITS. The Aptitude preparation really helped me. Case studies were amazing and so were guesstimates.",
  },
  {
    imgsrc:
      "https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644215085839_MUSKAAN%20KOCHAR.jpg",
    name: "MUSKAAN KOCHAR",
    designation: "AMAZON",
    comment:
      "The training sessions have been crisp and insightful. It covered aspects which are often overlooked or considered too obvious to be mentioned.",
  },
  {
    imgsrc:
      "https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644215145062_SAI%20NITISH.jpg",
    name: "SAI NITISH",
    designation: "Cognizant",
    comment:
      "The trainers are highly qualified. They taught us aptitude, core and verbal. I personally liked aptitude and core subjects. The trainers clarified each and every doubt. Daily assessments helped me a lot on my incremental improvement and helped in keeping an eye on the overall performance of students.",
  },
];

export default LoginTestimonial;

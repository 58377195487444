import { useRef } from "react";
import { useDispatch } from "react-redux";
import { stringSearch } from "../../app/newDashboard/actions/action";
import "../Assets/css/DiffNav.scss";
import lableIcon from "../../Assets/img/labelIcon.svg";
import searchImg from "../Assets/img/search.svg";
function DifficultyNav({ tab, func }) {
  // const [toggler, setToggler] = useState(false);
  const dispatch = useDispatch();
  const inputRef = useRef();

  function filterLevel(level) {
    dispatch({ type: "dashboard/difficultyLevel/" + level });
  }

  return (
    <>
      <div className='diffcultyList' style={{ backgroundColor: "#007AFF" }}>
        <div className='diffItems'>
          <div className='diffcultyItem'>
            <img
              src={lableIcon}
              className='barIcon img-fluid'
              alt='bars'
            />
          </div>
          <div
            className={`diffcultyItem ${
              tab === 0 ? "diffcultyActiveItem" : ""
            }`}
            onClick={() => {
              func(0);
              dispatch(stringSearch(""));
              // setToggler(false);
            }}>
            {" "}
            Difficulty <i class='fas fa-caret-down'></i>
            <ul className='diffcultydropdownList'>
              <li
                className='diffcultydropdownItem'
                onClick={() => filterLevel("easy")}>
                Easy
              </li>
              <li
                className='diffcultydropdownItem'
                onClick={() => filterLevel("medium")}>
                Medium
              </li>
              <li
                className='diffcultydropdownItem'
                onClick={() => filterLevel("hard")}>
                Hard
              </li>
              <li
                className='diffcultydropdownItem'
                onClick={() => filterLevel("any")}>
                All
              </li>
            </ul>
          </div>
          <div
            className={`diffcultyItem ${
              tab === 1 ? "diffcultyActiveItem" : ""
            }`}
            onClick={() => {
              func(1);
              // setToggler(false);
            }}>
            {" "}
            Category <i class='fas fa-caret-down'></i>
            <ul className='diffcultydropdownList'>
              <li
                className='diffcultydropdownItem'
                onClick={() => dispatch(stringSearch("array"))}>
                Array
              </li>
              <li
                className='diffcultydropdownItem'
                onClick={() => dispatch(stringSearch("string"))}>
                String
              </li>
              <li
                className='diffcultydropdownItem'
                onClick={() => dispatch(stringSearch("recursion"))}>
                Recursion
              </li>
              <li
                className='diffcultydropdownItem'
                onClick={() => dispatch(stringSearch("linked list"))}>
                Linked List
              </li>
              <li
                className='diffcultydropdownItem'
                onClick={() => dispatch(stringSearch("stack"))}>
                Stack
              </li>
              <li
                className='diffcultydropdownItem'
                onClick={() => inputRef.current.focus()}>
                ... more
              </li>
            </ul>
          </div>
          {/* <div
            className={`diffcultyItem ${
              tab === 2 ? "diffcultyActiveItem" : ""
            }`}
            onClick={() => {
              func(2);
              dispatch(stringSearch(""));
              // setToggler(false);
            }}>
            {" "}
            Companies
          </div> */}
        </div>
        <div className='diffSearchItem'>
          <div className='diffcultyItem2'>
            <img
              style={{ padding: "0", width: "30px" }}
              src={searchImg}
              width={100}
              height={24}
              alt='search'
            />
            <input
              className='diffcultyspecificSearch'
              style={{
                background: "#007AFF",
                border: "none",
                fontWeight: "bold",
                color: "#FFFFFF",
              }}
              ref={inputRef}
              autoFocus
              type='text'
              placeholder='Search'
              aria-label='Search'
              onChange={(e) => dispatch(stringSearch(e.target.value))}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default DifficultyNav;

import firebase from 'firebase'

var firebaseconfig = {
  apiKey: "AIzaSyBaOet008eBxLbgjfvaG5fRztyRZoIEgXc",
  authDomain: "cantilever-lab.firebaseapp.com",
  databaseURL: "https://cantilever-lab-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "cantilever-lab",
  storageBucket: "cantilever-lab.appspot.com",
  messagingSenderId: "268216348097",
  appId: "1:268216348097:web:61315ac1d87eae083a9340",
  measurementId: "G-V4MWFQFSB6",
};

firebase.initializeApp(firebaseconfig);

// const messaging = firebase.messaging();

// export const requestFirebaseNotificationPermission = () =>
//   new Promise((resolve, reject) => {
//     messaging
//       .requestPermission()
//       .then(() => messaging.getToken())
//       .then((firebaseToken) => {
//         resolve(firebaseToken);
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//   });


export default firebase;




import { useEffect, useState, useRef } from "react";
import Logo from "../Assets/img/cantilever-logo.png";
import "../Assets/css/codinginstruction.css";
import configAxios from "../axios.config";
import { Modal, Form, Button } from "react-bootstrap";
import { useHistory, NavLink } from "react-router-dom";

const CodingInstruction = ({ hidenavbar }) => {
  const [isPassword, setIsPassword] = useState();
  const [examData, setExamData] = useState({});
  const [show, setShow] = useState(false);
  const [courseId, setCourseId] = useState("");
  const history = useHistory();
  const queryParams = useRef(new URLSearchParams(window.location.search));
  const password = useRef();
  const durations = queryParams.current.get("duration");
  const questions = queryParams.current.get("questions");
  const id = queryParams.current.get("id");
  const testname = queryParams.current.get("testname");

  const [isBlackboxDetected, setIsBlackboxDetected] = useState(false);

  useEffect(() => {
    // Your detection logic goes here
    // For demonstration, let's assume 'blackbox' adds a class to the body
    const checkForPlugin = () => {
      if (document.getElementsByClassName("blackbox-overlay").length > 0) {
        setIsBlackboxDetected(true);
        clearInterval(interval);
      }
    };
    const interval = setInterval(checkForPlugin, 1000);
    return () => clearInterval(interval);
  }, []);

  function showPopup() {
    setShow(true);
  }

  useEffect(() => {
    let examId = queryParams.current.get("id");
    configAxios(localStorage.getItem("token"))
      .get("/getExam/" + examId)
      .then(({ data }) => {
        const examData = data.message;
        setExamData(examData);
        setCourseId(examData.courseid);
        if (examData.isPassword === true || examData.isPassword === false) {
          setIsPassword(examData.isPassword);
        } else {
          setIsPassword(false);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    hidenavbar(true);
    return () => {
      hidenavbar(false);
    };
  }, [hidenavbar]);

  const instructions = [
    "This is a timed test. Please make sure you are not interrupted during the test, as the timer cannot be paused once started.",
    "Please ensure you have a stable internet connection",
  ];

  function authenticateUser() {
    let route = queryParams.current.get("route");
    configAxios(localStorage.getItem("token"))
      .post("/verify-exams/checkPassword", {
        password: password.current.value,
        examId: id,
      })
      .then(({ data }) => {
        history.push(`/codingexam/${id}`, {
          authenticated: true,
        });
      })
      .catch((err) => {
        switch (err.response.status) {
          case 400:
            alert("Wrong Password");
            break;

          default:
            console.error(err);
        }
      });
  }

  if (isBlackboxDetected) {
    return (
      <div
        className="warning"
        style={{
          fontSize: "1.5rem",
          fontWeight: "bold",
          padding: "1rem",
        }}
      >
        We've detected the Blackbox plugin, which may interfere with your
        experience on our site. Please consider disabling it while using our
        platform.
      </div>
    );
  }

  return (
    <>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Enter Exam Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Label>Enter Password</Form.Label>
          <Form.Control
            name="oldPassword"
            type="text"
            placeholder="Exam Password"
            ref={password}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Close
          </Button>
          <Button
            // onSubmit={onHandleSubmit}
            variant="primary"
            onClick={authenticateUser}
          >
            Continue
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="d-grid place-content-center"></div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-5 instructionPageLeft">
            <img src={Logo} className="instructionPageLogo" alt="logo" />
            <div className="instructionPageTitle">{testname}</div>
            <div className="row">
              <div className="col-6">
                <div className="instructionPageSubTitle">
                  <i className="fas fa-clock"></i>Test Duration
                </div>
                <div className="instructionPageSubText">{durations} mins</div>
              </div>
              <div className="col-6">
                <div className="instructionPageSubTitle">
                  <i className="fas fa-file-alt"></i>No. of questions
                </div>
                <div className="instructionPageSubText">{questions}</div>
              </div>
            </div>
          </div>
          <div className="col-xl-7 instructionPageRight">
            <div className="instructionPageRightTitle">Instructions:</div>
            <ol className="instructionPageRightList">
              {instructions.map((value) => {
                return <li>{value}</li>;
              })}
              {examData?.proctoring?.video && <li>Camera should be turned on</li>}
            </ol>
            {isPassword === true && (
              <div onClick={showPopup} className="instructionPageBtn">
                Enter Password
              </div>
            )}
            {isPassword === false && (
              <NavLink to={"/codingexam/" + id} className="instructionPageBtn">
                Continue
              </NavLink>
            )}
            {isPassword === undefined && (
              <div className="instructionPageBtn">
                <div className="spinner-border spinner-border-sm">
                  Please wait
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CodingInstruction;

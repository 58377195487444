import React, { useState, useEffect } from "react";
import image from "../Assets/img/reciept.jpeg";
import axios from "axios";

const RecieptDone = () => {
  const [ipData, setIpData] = useState("");
  const Url = "https://api.cantileverlabs.com/getIp";
  //   const Url = "http://localhost:5000/getIp";
  useEffect(async () => {
    const data = await axios("https://api64.ipify.org?format=jsonp");

    setIpData(data.data);
    fetch(Url, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ data: data.data }),
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log(data);
      });
  }, []);
  return (
    <div className="text-center">
      <img
        src={image}
        alt={"Paid to Avanish Mundhra at his mail"}
        preview={"Paid to Avanish Mundhra at his mail"}
      />
    </div>
  );
};

export default RecieptDone;

import { useState} from "react"
import "../Interview/InterviewStyle/TopicForm.scss";
import AiGenrate from "./AiGenrate";
import configAxios from "../../axios.config";
const TopicForm = () => {
  const [modalShow, setModalShow] = useState(false)
  const [questiondata, setQuestionData] = useState([])
  configAxios(localStorage.getItem("token"))
  .get(`/openai/get-interview-questions/639d8655822c668dcb1d7aa5`)
  .then(({ data })=>{
    console.log(data.questions);
    setQuestionData(data.questions)
  })
  .catch((err)=>{
    console.log(err);
  })

  return (
    <>
      <div id='topicFormPage'>
       {questiondata.map((val, i)=>{
        return(
          <div className='topicLabel' key={i}>
          <h1 className='labelHeading'>{val.question}</h1>
          <div className='topicFormInput'>
            <input
              type='text'
              className='formInputField'
              placeholder='Your input here...'
            />{" "}
          </div>
        </div>
        )
       })}
        <div className="topicBtn">
          <button className='topicFormButton'  variant="primary" onClick={() => setModalShow(true)}>Next</button>
          <AiGenrate show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </div>
    </>
  );
};

export default TopicForm;

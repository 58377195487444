import { combineReducers } from "redux";

const language = "C";

export const languageReducer = (state = language, action) => {
    switch (action.type) {
        case "hybridcompiler/language":
            return action.payload;

        default:
            return state;
    }
};

export const indicesReducer = (state = [], action) => {
    switch (action.type) {
        case "hybridcompiler/solvedIndices":
            return action.payload;

        default:
            return state;
    }
};
const languageValues = ["C", "C++", "Java", "Python3"];

export const languageValuesReducer = (state = languageValues, action) => {
    switch (action.type) {
        case "hybridcompiler/cpp":
            return ["C++"];

        case "hybridcompiler/python":
            return ["Python3"];

        case "hybridcompiler/java":
            return ["Java"];

        case "hybridcompiler/c":
            return ["C"];

        case "hybridcompiler/languageValues":
            return action.payload;

        default:
            return state;
    }
};

/**
 *
 * @param {Number} state
 * @returns Number
 */
export const examActiveTabReducer = (state = 0, action) => {
    switch (action.type) {
        case "hybridExam/main-instructions":
            return 0;

        case "hybridExam/quiz-instructions":
            return 1;

        case "hybridExam/quiz":
            return 2;

        case "hybridExam/coding-instructions":
            return 3;

        case "hybridExam/coding":
            return 4;

        default:
            return state;
    }
};

const timerState = {
    hours: 0,
    seconds: 0,
    minutes: 0,
};
export const examTimerReducer = (state = timerState, action) => {
    switch (action.type) {
        case "hybridExam/timer":
            return action.payload;

        default:
            return state;
    }
};

const quizExamData = {
    score: [],
    review: [],
    examId: "",
    questions_Attempted: [],
    finalScore: 0,
};
const quizExamReducer = (state = quizExamData, action) => {
    switch (action.type) {
        case "hybridExam/quizExam":
            return action.payload;

        default:
            return state;
    }
};

const codingExamData = {
    examId: "",
    scoring: [],
    totalScore: 0,
    noOftabs: 0,
    timeTaken: "",
};
const codingExamReducer = (state = codingExamData, action) => {
    switch (action.type) {
        case "hybridExam/codingExam":
            return action.payload;

        default:
            return state;
    }
};

export const hybridCompiler = combineReducers({
    language: languageReducer,
    solvedIndices: indicesReducer,
    languageValues: languageValuesReducer,
    activeTab: examActiveTabReducer,
    examTimer: examTimerReducer,
    quizExamData: quizExamReducer,
    codingExamData: codingExamReducer,
});

import cantileverLight from "../../Assets/img/cantileverlogoblack.png";
import cantilevertransparent from "../../Assets/img/CLLogo.png";
import TestCases from "./TestCases";
import CustomInputs from "./components/CustomInputs";
import { useEffect, useState, useRef, Dispatch, SetStateAction } from "react";
import { motion } from "framer-motion";
import AceEditor from "react-ace";
import "../../Assets/css/compiler.css";
import "ace-builds/src-noconflict/mode-csp";
import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/mode-c_cpp";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/theme-merbivore_soft";
import "ace-builds/src-noconflict/theme-solarized_dark";
import "ace-builds/src-noconflict/theme-solarized_light";
import { useDispatch, useSelector } from 'react-redux';
// import { useParams } from "react-router-dom";
import {
  changeLanguage,
  changeSolvedIndices,
  changeLanguageValues,
} from "../../app/hybridExam/actions/action";
import { lambdaCompiler, configAxiosLambda } from "../../axios.config";
import SplitPane from "split-pane-react";
import 'split-pane-react/esm/themes/default.css';

interface IQuestionCode {
  code: string,
  language: "c++" | "java" | "python3" | "c"
}

interface INewHybridCompilerCoding {
  id: string;
  questionsolved: Array<string>;
  setquestionsolved: (id: string) => void;
  FinishExam: () => Promise<unknown>;
  setScoringArray: Dispatch<SetStateAction<Array<IScoringArray>>>;
  code: codeData;
  setcode: React.Dispatch<React.SetStateAction<codeData | undefined>>;
  questions: Array<IQuestionData>;
  scoringArray: Array<IScoringArray>;
  updateCodingData: () => void;
  modes: boolean;
  toggleMode: () => void;
}

const NewHybridCompilerCoding = (props: INewHybridCompilerCoding) => {
  const {
    id: codingQuestionId,
    code,
    setcode,
    setScoringArray,
    FinishExam,
    setquestionsolved,
    questions,
    scoringArray,
    updateCodingData,
    modes,
    toggleMode
  } = props;
  const solvedIndices = useSelector(({ hybridCompiler }: { hybridCompiler: { solvedIndices: Object[] } }) => {
    return hybridCompiler.solvedIndices;
  });
  const Languagevalues = useSelector(({ hybridCompiler }: IStore) => hybridCompiler.languageValues)
  const selectLanguage = useSelector(({ hybridCompiler }: IRootState) => hybridCompiler.language);
  const dispatch = useDispatch();
  const refArray = useRef<Array<number>>([]);
  const submitScoreArray = useRef<Array<number>>([]);
  const mainContainerDiv = useRef<HTMLDivElement>(null);
  const userInput = useRef<string>("");
  // const { id: hybridExamId } = useParams();


  const [description, setDescription] = useState({
    __html: "First &middot; Second",
  });
  const fontSizes = ["12px", "14px", "16px", "18px", "20px", "22px"];
  const [selectedLanguagetestcase, setselectedlanguagetestcase] = useState<Array<{
    code?: string,
    name: string,
    output: string;
    scannerInput?: string;
  }>>([]);
  const [showlanguagedropdown, setshowlanguagedropdown] = useState(false);
  const [showfontsizedropdown, setfontsizedropdown] = useState(false);
  const [SampleTestCases, setSampleTestCases] = useState<Array<testCase>>([]);
  const [questionCode, setquestionCode] = useState<Array<IQuestionCode>>([]);
  const [questionName, setquestionName] = useState<string>("");
  const [sampleOutput, setSampleOutput] = useState("");
  const [sampleInput, setSamepleInput] = useState("");
  const [finished, setFinished] = useState(false);
  const [fontSize, setFontSize] = useState("16px");
  const [running, setrunning] = useState(true);
  const [resize, setResize] = useState(true);
  const [output, setOutput] = useState<string | null>("");
  const [verticalPaneSize, setVerticalPaneSize] = useState(["50%", "50%"]);
  const [leftPaneSize, setLeftPaneSize] = useState(["50%", "50%"]);
  const [rightPaneSize, setRightPaneSize] = useState(["50%", "50%"]);
  const [displaySubmitButton, setDisplaySubmitButton] = useState(false);
  const [showPopUp, setshowPopUp] = useState(false);
  const [runningSubmissions, setRunningSubmissions] = useState<boolean>(false);
  const [scannerInput, setScannerInput] = useState<boolean>(false);
  const [displayCustomInput, setDisplayCustomInput] = useState<boolean>(false);
  const [rawoutputsol, setrawoutputsol] = useState<Array<{
    testcaseNo: number;
    output: string;
    passed: boolean;
    error: boolean;
    private?: boolean;
  }>>([]);
  const [customOut, setCustomOut] = useState<Array<{
    passed: boolean;
    output: string;
  }>>([]);

  const r = document.querySelector(":root") as HTMLElement;

  function mytheme_func() {
    if (!r) return;

    r.style.setProperty("--inside-color", modes ? "#001528" : "#F6F9FC");
    r.style.setProperty("--input-color-code", modes ? "#15314b" : "#cbd7f5");
    r.style.setProperty("--border-color-code", modes ? "#02203c" : "yellow");
    r.style.setProperty("--outer-bgcolor-code", modes ? "#02203c" : "#E0E0E0");
    r.style.setProperty("--selected-option-code", modes ? "#e0e0e0" : "#E0E0E0");
    r.style.setProperty("--text-color", modes ? "#fff" : "#001528");
  }
  mytheme_func();


  function onChange(newValue: string) {
    setcode((prev) => {
      if (!prev) return prev;
      prev[codingQuestionId][selectLanguage] = newValue;
      return { ...prev };
    });
  }

  document.addEventListener("contextmenu", (event) => {
    event.preventDefault();
  });

  window.addEventListener("keydown", (event) => {
    if (event.code === "KeyV" && event.ctrlKey) {
      event.preventDefault();
    } else if (event.code === "KeyC" && event.ctrlKey) {
      event.preventDefault();
    }
  });
  document.addEventListener("contextmenu", (event) => event.preventDefault());

  useEffect(() => {
    updateCodingData();
  }, [scoringArray])

  useEffect(() => {
    if (!codingQuestionId && codingQuestionId === "") return;

    const [selected_question] = questions.filter((question) => question._id === codingQuestionId)
    setquestionName(selected_question.name);
    setDescription({
      __html: selected_question.description
    })
    setSamepleInput(selected_question.sampleInput)
    setSampleOutput(selected_question.sampleOutput)
    setSampleTestCases(selected_question.testcases)
    setquestionCode(selected_question.questionCode)
    setDisplaySubmitButton(selected_question.hasPrivateTestCases);
    setScannerInput(selected_question.hasScannerInputs);

    if (selected_question.languages && selected_question.languages.length !== 0) {
      dispatch(changeLanguageValues(selected_question.languages))
      dispatch(changeLanguage(selected_question.languages[0]))
    } else {
      dispatch(changeLanguageValues(["C", "C++", "Java", "Python3"]))
      dispatch(changeLanguage("C"));
    }
  }, [codingQuestionId, questions, dispatch]);

  useEffect(() => {
    const array = SampleTestCases?.filter(value => value[0].language === selectLanguage.toLowerCase());
    if (array?.length !== 0) {
      setselectedlanguagetestcase(array[0][1]);
    }
  }, [SampleTestCases, selectLanguage]);

  function RestartTheCode() {
    let questionsCodes = questionCode.filter(
      (values) => values.language === selectLanguage.toLowerCase()
    );
    // setcode(questionsCodes[0]?.code);
    setcode((prev) => {
      if (!prev) return prev;
      prev[codingQuestionId][selectLanguage] = questionsCodes[0]?.code;

      return { ...prev };
    })
  }

  function handleCopyPaste(e) {
    e.preventDefault();
  }

  function DropDownStyle({ value, selectLanguage, type }) {
    return (
      <div
        onClick={() => {
          if (type === "language") {
            dispatch(changeLanguage(value))
            setshowlanguagedropdown(false);
          } else {
            setFontSize(value);
            setfontsizedropdown(false);
          }
        }}
        className={
          selectLanguage !== value
            ? "options-value-dropdown"
            : "options-value-dropdown selected-language-active"
        }
      >
        <span className={"single_option_dropdown"}>{value}</span>
      </div>
    );
  }

  function submitAndCheckCode(code: string, id: string, lang: language_values) {
    setCustomOut([]);
    setrawoutputsol([]);
    setRunningSubmissions(true);
    let encoded_code = new Buffer(code).toString('base64');
    configAxiosLambda(localStorage.getItem("token") || "").post<{
      testcasesPassOrFail: Array<{
        status: "pass" | "fail";
        marks?: number;
      }>
    }>("/run-testcases", {
      questionId: id,
      language: lang,
      code: encoded_code
    }).then(({ data }) => {
      setrawoutputsol((prev) => {
        submitScoreArray.current = [];
        return data.testcasesPassOrFail.map((val, index) => {
          if (val.status === "pass" && val.marks) submitScoreArray.current.push(val.marks);
          return {
            testcaseNo: index + 1,
            output: "",
            passed: val.status === "pass",
            error: false,
            private: true
          }
        })
      })
      if (submitScoreArray.current.length === data.testcasesPassOrFail.length) {
        setquestionsolved(codingQuestionId);
      }
      RemoveAndUpdate();
    }).catch(err => {
      console.log(err);
    }).finally(() => setRunningSubmissions(false));
  }

  async function executeCustomInput() {
    setrunning(false);
    setrawoutputsol([]);
    let lang: "c" | "cpp" | "java" | "python" = "c";

    if (selectLanguage === "C++") lang = "cpp";
    else if (selectLanguage === "Java") lang = "java";
    else if (selectLanguage === "C") lang = "c";
    else lang = "python";

    let data = {
      code: Buffer.from(code[codingQuestionId][selectLanguage], 'utf-8').toString('base64'),
      language: lang,
      arguments: [],
      input: userInput.current
    }
    let compilerData = await lambdaCompiler.post<{ encripted_token: string, output: string }>('/compile', data).catch(({ response }) => {
      if (response.status === 400) {
        const code_output = Buffer.from(
          response.data.error,
          "base64",
        ).toString("utf8");

        setCustomOut([{
          output: code_output,
          passed: false
        }]);
      }
    }).finally(() => setrunning(true));

    if (compilerData) {

      let code_output = Buffer.from(
        compilerData.data.output,
        "base64",
      ).toString("utf8");

      setCustomOut([{
        output: code_output,
        passed: true
      }])
    }
  }

  async function execute_Code(testcasecode: string, expected_output: string, index: number) {
    let lang: "c" | "cpp" | "java" | "python" = "c";
    setrunning(false);

    if (selectLanguage === "C++") lang = "cpp";
    else if (selectLanguage === "Java") lang = "java";
    else if (selectLanguage === "C") lang = "c";
    else lang = "python";

    // encodes code writen by user to BASE64
    let encoded_code = new Buffer(testcasecode).toString('base64');

    let input_string = selectedLanguagetestcase[index].scannerInput;

    var newdata: {
      code: string;
      language: "c" | "cpp" | "java" | "python";
      arguments: Array<any>;
      input: string;
    } = {
      code: encoded_code,
      language: lang,
      arguments: [],
      input: scannerInput ? (input_string ? input_string : "") : "",
    };

    await lambdaCompiler.post('/compile', newdata)
      .then(({ data }) => {
        const code_output = Buffer.from(
          data.output,
          "base64",
        ).toString("utf8");
        CheckTestCase(code_output, expected_output, index);

      }).catch(({ response }) => {
        if (response.status === 400) {
          const code_output = Buffer.from(
            response.data.error,
            "base64",
          ).toString("utf8");
          CheckTestCase(code_output, expected_output, index);
        }
      }).finally(() => setrunning(true))
  }

  /**
   * TODO: Set your custom test case marks here
   */
  function GetScore(): number {
    if (!displaySubmitButton) {
      return (refArray.current.length * 10);
    }

    let marks = 0;
    submitScoreArray.current.forEach(val => marks += val);

    return marks;
  }

  function TestcasesSolvedNumber(): number {
    if (!displaySubmitButton) {
      return refArray.current.length;
    }

    return submitScoreArray.current.length;
  }

  /**
   * ? Removes and updates score from `scoringArray` state
   */
  function RemoveAndUpdate() {

    const calcScore = {
      language: selectLanguage,
      code: code[codingQuestionId][selectLanguage],
      score: GetScore(),
      testcasessolved: TestcasesSolvedNumber(),
      questionId: codingQuestionId,
      markPerTestcase: 10
    };
    setScoringArray(prev => {
      let [codingScore] = prev.filter(val => val.questionId === codingQuestionId);

      if (!codingScore) {
        prev.push(calcScore);
      } else {
        codingScore.language = calcScore.language;
        codingScore.code = calcScore.code;
        codingScore.score = calcScore.score;
        codingScore.testcasessolved = calcScore.testcasessolved;
        codingScore.markPerTestcase = calcScore.markPerTestcase;
      }

      return [...prev];
    })
  }

  /**
   * 
   * Check test cases and set output
   */
  function CheckTestCase(output: string, expected_output: string, index: number) {
    setOutput(output);

    let foo = output.replace(/\r?\n|\r/g, "");
    let doo = String(expected_output).replace(/\r?\n|\r/g, "");
    foo = foo.replace(/ /g, "");

    let passed: boolean = false;

    if (foo === doo) {
      refArray.current.push(index);
      passed = true;
    } else {
      let newstring1 = "";
      doo = doo.replace(/ /g, "");

      for (var i = 0; i < doo.length; i++) {
        if (doo[i] === undefined) {
          continue;
        }
        if (doo[i] === " ") {
          continue;
        } else {
          newstring1 += doo[i];
        }
      }
      if (newstring1 === foo) {
        refArray.current.push(index);
        passed = true;
      }
    }
    setrawoutputsol((prev) => [...prev, {
      testcaseNo: index + 1,
      output,
      passed,
      error: false
    }])
    var newarr: Object[] = solvedIndices;
    newarr[codingQuestionId] = refArray.current;
    dispatch(changeSolvedIndices(newarr));
    if (!displaySubmitButton) {
      ChangeCompletedArrays();
    }
  }

  function ChangeCompletedArrays(): void {
    if (refArray.current.length === selectedLanguagetestcase.length) {
      setquestionsolved(codingQuestionId);
    }
    RemoveAndUpdate();
  }

  async function RunningAllTestcases() {
    setrawoutputsol([]);
    refArray.current = [];
    for (let i: number = 0; i < selectedLanguagetestcase.length; i++) {
      let testcasecode_new = "";
      if (scannerInput) {
        testcasecode_new = code[codingQuestionId][selectLanguage];
      } else {
        testcasecode_new = code[codingQuestionId][selectLanguage] + selectedLanguagetestcase[i].code;
      }

      await execute_Code(testcasecode_new, selectedLanguagetestcase[i].output, i);
    }

    setrunning(true);
    // setshowrawoutputtestcases(true);
  }

  function changeLayout() {
    setResize(prev => {
      if (prev) {
        setVerticalPaneSize(["0%", "100%"]);
        setRightPaneSize(["100%", "0%"]);
      } else {
        setVerticalPaneSize(["50%", "50%"]);
        setRightPaneSize(["50%", "50%"]);
      }

      return !prev;
    });
  }
  return (
    <div className="maincontainer" ref={mainContainerDiv}>
      {showPopUp && (
        <>
          <div
            style={{
              position: "absolute",
              zIndex: 20,
              backdropFilter: "brightness(0)",
              height: "200px",
              width: "500px",
              top: "50%",
              transform: "translate(-50%, -50%)",
              left: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "white",
              padding: "20px",
            }}
            className="new-hybrid-compiler-popup"
          >
            {!finished && <>
              Are you sure you want to submit the exam? &emsp;
              <button
                onClick={() => {
                  setFinished(true);
                  FinishExam().finally(() => {
                    setshowPopUp(false);
                    setFinished(false)
                  });
                }}
                className="btn btn-md btn-success"
                disabled={finished}
              >
                Yes
              </button>
              &emsp;
              <button
                onClick={() => {
                  setshowPopUp(false);
                }}
                className="btn btn-md btn-danger"
                disabled={finished}
              >
                No
              </button>
            </>}
            {
              finished && (
                "Submitting your response. Please wait..."
              )
            }
          </div>
          {
            showPopUp && finished && (<div className="loader-border-hybrid-compiler"></div>)
          }
        </>
      )}
      <div
        style={{ paddingLeft: "0px", filter: showPopUp ? "blur(2px)" : "" }}
        className={"compiler"}
      >
        <div className="options">
          <img
            src={modes ? cantilevertransparent : cantileverLight}
            width="auto"
            height="42px"
            alt="logo"
            className="pt-1"
          />
          <div className="mid d-flex align-items-center">
            <div
              className={
                showlanguagedropdown
                  ? "select-language-code"
                  : "select-language-code tooltip-animation "
              }
              style={{
                backgroundColor: modes ? "#15314b" : "#fff",
              }}
              data-tooltip="Programming Language"
            >
              <p
                className="selected-language-code "
                onClick={() => setshowlanguagedropdown(!showlanguagedropdown)}
                style={{ marginBottom: "0px", width: "135px" }}
              >
                {selectLanguage}
                <i
                  style={{
                    transform:
                      showlanguagedropdown
                        ? "rotate(180deg)"
                        : "rotate(0deg)",
                  }}
                  className="fas fa-caret-down animation"
                ></i>
              </p>
              {showlanguagedropdown === true && (
                <div className="dropdown-value">
                  {Languagevalues.map((value, index) => (
                    <DropDownStyle
                      key={index}
                      value={value}
                      selectLanguage={selectLanguage}
                      type={"language"}
                    />
                  ))}
                </div>
              )}
            </div>
            <div
              className={
                showfontsizedropdown
                  ? "select-language-code d-sm-block d-none"
                  : "select-language-code tooltip-animation d-sm-block d-none"
              }
              data-tooltip="Editor Font Size"
              style={{
                backgroundColor: modes ? "#15314b" : "#fff",
              }}
            >
              <p
                className="selected-language-code "
                onClick={() => setfontsizedropdown(!showfontsizedropdown)}
                style={{ marginBottom: "0px" }}
              >
                {fontSize}{" "}
                <i
                  style={{
                    transform:
                      showfontsizedropdown
                        ? "rotate(180deg)"
                        : "rotate(0deg)",
                  }}
                  className="fas fa-caret-down animation"
                ></i>
              </p>
              {showfontsizedropdown && (
                <div className="dropdown-value">
                  {fontSizes.map((value, index) => (
                    <DropDownStyle
                      key={index}
                      value={value}
                      selectLanguage={fontSize}
                      type={"font"}
                    />
                  ))}
                </div>
              )}
            </div>
            <CodeTimer modes={modes} />
          </div>
          <div className="d-flex flex-row align-items-center">
            <motion.button
              onClick={() => {
                setshowPopUp(true);
              }}
              whileTap={{ scale: 0.9 }}
              className="submit-coding-examination px-auto"
              data-tooltip="Submit Test"
            >
              Finish Test
            </motion.button>
            <div className="mode-btn" data-tooltip="Toggle Theme">
              <div
                className="select-language-code px-4 mx-2"
                onClick={toggleMode}
                style={{
                  backgroundColor: modes ? "#15314b" : "#fff",
                }}
              >
                {modes ? <i className="fas fa-sun"></i> : <i className="fas fa-moon"></i>}
              </div>
            </div>
          </div>
        </div>

        <SplitPane
          className="compiler-components-container"
          sizes={verticalPaneSize}
          split="vertical"
          resizerSize={15}
          performanceMode={true}
          // @ts-ignore
          onChange={setVerticalPaneSize}
        >

          <SplitPane
            className="question"
            minSize={1}
            sizes={leftPaneSize}
            split="horizontal"
            resizerSize={15}
            // @ts-ignore
            onChange={setLeftPaneSize}
            performanceMode={true}
          >
            <div className="question-description-container">
              <div className="question-panel">
                <p className="your-question-code" style={{ width: "fit-content", backgroundColor: "#001528" }} >
                  Question
                </p>
              </div>
              <div style={{ padding: "20px", flexGrow: 1, overflowY: "auto" }}>
                <h4 className="question_heading">{questionName}</h4>
                <p
                  className="question_description"
                  dangerouslySetInnerHTML={description}
                  style={{
                    overflowY: "auto",
                  }}
                ></p>
                <div className="sample-input-question">
                  <h4>Sample Input</h4>
                  <div className="input-area-question">{sampleInput}</div>
                  <h4>Sample Output</h4>
                  <div className="input-area-question">
                    {sampleOutput.replace(/ /g, "\u00A0")}
                  </div>
                </div>
              </div>
            </div>
            <div className="test-cases-container pr-2">
              <div
                className="output_area p-0"
                style={{ width: "100%" }}
              >
                <div className="d-flex flex-row submit-code-compiler justify-content-start" style={{ gap: "3px" }}>
                  <div className="d-flex align-items-stretch">
                    <button
                      className={
                        !displayCustomInput
                          ? "selected-type-test"
                          : "unselect-type-test"
                      }
                      onClick={() => setDisplayCustomInput(false)}
                    >
                      Test Cases
                    </button>
                  </div>
                  {scannerInput && <div className="d-flex align-items-stretch">
                    <button
                      className={
                        displayCustomInput   // do not replace this ternary operator. It will be useful in future (I hope ...)
                          ? "selected-type-test"
                          : "unselect-type-test"
                      }
                      onClick={() => setDisplayCustomInput(true)}
                    >
                      Custom Input
                    </button>
                  </div>}
                  {/* <div></div> */}
                </div>
                <div className="p-2 test-case-output-container">
                  <div>
                    {!displayCustomInput ? <TestCases
                      setquestionsolved={setquestionsolved}
                      videoid={codingQuestionId}
                      type="exam"
                      selectedLanguagetestcase={selectedLanguagetestcase}
                      selectLanguage={selectLanguage}
                      output={output}
                      testcases={SampleTestCases}
                      hasScannerInput={scannerInput}
                    /> : <CustomInputs onChange={(input, output) => {
                      if (input) {
                        userInput.current = input;
                      }
                    }} />}
                  </div>
                </div>
              </div>
            </div>
          </SplitPane>

          <SplitPane
            className="question"
            sizes={rightPaneSize}
            minSize={1}
            split="horizontal"
            resizerSize={15}
            // @ts-ignore
            onChange={setRightPaneSize}
            performanceMode={true}
          >
            <div className={"code"}>
              <div className="run-panel d-flex justify-content-between align-items-stretch">
                <p className="your-solution-code">Your solution</p>

                <div
                  style={{
                    width: "100%",
                    justifyContent: "end",
                    gap: "1rem",
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <div
                    style={{
                      cursor: "pointer",
                      color: modes ? "#fff" : "#001528",
                    }}

                    className="svg-btn"
                    data-tooltip="Reset Code"
                    onClick={() => {
                      RestartTheCode();
                    }}
                  >
                    <span className="rotate-icon" data-tooltip="Reset">
                      <i
                        style={{ transform: "scale(1.8)" }}
                        className="fas fa-undo w-full"
                      ></i>
                    </span>
                  </div>
                  {resize ? (
                    <div
                      style={{
                        cursor: "pointer",
                        color: modes ? "#fff" : "#001528",
                        display: "inline",
                        height: "2.5rem",
                        width: "2.5rem",
                      }}
                      className="svg-btn"
                      data-tooltip="Full Screen"
                    >
                      <svg
                        width="100%"
                        height="100%"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 469.333 469.333"
                        fill="currentColor"
                        onClick={() => {
                          changeLayout();
                        }}
                      >
                        <path d="M160 0H10.667A10.66 10.66 0 000 10.667V160a10.66 10.66 0 0010.667 10.667H32A10.66 10.66 0 0042.667 160V42.667H160A10.66 10.66 0 00170.667 32V10.667A10.66 10.66 0 00160 0zM458.667 0H309.333a10.66 10.66 0 00-10.667 10.667V32a10.66 10.66 0 0010.667 10.667h117.333V160a10.66 10.66 0 0010.667 10.667h21.333A10.66 10.66 0 00469.333 160V10.667A10.66 10.66 0 00458.667 0zM458.667 298.667h-21.333a10.66 10.66 0 00-10.667 10.667v117.333H309.333a10.66 10.66 0 00-10.667 10.667v21.333a10.66 10.66 0 0010.667 10.667h149.333a10.66 10.66 0 0010.667-10.667V309.333a10.66 10.66 0 00-10.666-10.666zM160 426.667H42.667V309.333A10.66 10.66 0 0032 298.666H10.667A10.662 10.662 0 000 309.333v149.333a10.66 10.66 0 0010.667 10.667H160a10.66 10.66 0 0010.667-10.667v-21.333A10.66 10.66 0 00160 426.667z"></path>
                      </svg>
                    </div>
                  ) : (
                    <div
                      className="svg-btn"
                      style={{
                        cursor: "pointer",
                        color: modes ? "#fff" : "#001528",
                        display: "inline",
                        height: "2.5rem",
                        width: "2.5rem",
                      }}
                      data-tooltip="Exit Full Screen"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 469.333 469.333"
                        fill="currentColor"
                        onClick={() => {
                          changeLayout();
                        }}
                      >
                        <path d="M160 0h-21.333A10.66 10.66 0 00128 10.667V128H10.667A10.66 10.66 0 000 138.667V160a10.66 10.66 0 0010.667 10.667H160A10.66 10.66 0 00170.667 160V10.667A10.66 10.66 0 00160 0zM458.667 128H341.333V10.667A10.66 10.66 0 00330.667 0h-21.333a10.66 10.66 0 00-10.667 10.667V160a10.66 10.66 0 0010.667 10.667h149.333A10.66 10.66 0 00469.334 160v-21.333A10.662 10.662 0 00458.667 128zM458.667 298.667H309.333a10.66 10.66 0 00-10.667 10.667v149.333a10.66 10.66 0 0010.667 10.667h21.333a10.66 10.66 0 0010.667-10.667V341.333h117.333a10.66 10.66 0 0010.667-10.667v-21.333a10.66 10.66 0 00-10.666-10.666zM160 298.667H10.667A10.66 10.66 0 000 309.333v21.333a10.66 10.66 0 0010.667 10.667H128v117.333a10.66 10.66 0 0010.667 10.667H160a10.66 10.66 0 0010.667-10.667V309.333A10.66 10.66 0 00160 298.667z"></path>
                      </svg>
                    </div>
                  )}

                  <div
                    style={{
                      cursor: "pointer",
                      color: modes ? "#fff" : "#001528",
                    }}

                    data-tooltip="Run Code"
                    onClick={() => {
                      if (displayCustomInput) {
                        executeCustomInput();
                      } else {
                        RunningAllTestcases();
                      }
                    }}
                    className="run-btn svg-btn"
                  >
                    {running ? (
                      "Run Code"
                    ) : (
                      <>
                        <div className="text-center">
                          <div
                            className="spinner-border d-flex justify-content-center text-warning spinner-border-sm"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              {selectLanguage && (
                <AceEditor
                  mode={
                    selectLanguage.toLowerCase() === "python3"
                      ? "python"
                      : selectLanguage.toLowerCase() === "c++" ||
                        selectLanguage.toLowerCase() === "c"
                        ? "c_cpp"
                        : selectLanguage.toLowerCase()
                  }
                  theme={modes ? "solarized_dark" : "solarized_light"}
                  value={
                    code[codingQuestionId] ? (code[codingQuestionId][selectLanguage] ? code[codingQuestionId][selectLanguage] : "") : ""
                  }
                  className="codes"
                  onChange={onChange}
                  name="UNIQUE_ID_OF_DIV"
                  width={"100%"}
                  onCopy={handleCopyPaste}
                  onPaste={handleCopyPaste}
                  fontSize={fontSize}
                  height={"85%"}
                  setOptions={{
                    enableBasicAutocompletion: true,
                    enableLiveAutocompletion: true,
                    enableSnippets: true,
                  }}
                />
              )}
            </div>

            <div className="test-cases-container" style={{
              paddingLeft: "10px"
            }}>

              <div
                className="output_area p-0"
                style={{ width: "100%" }}
              >
                <div className="d-flex flex-row submit-code-compiler justify-content-between">
                  <div className="d-flex align-items-stretch">
                    <button
                      className={
                        "selected-type-test borderchange"
                      }
                    >
                      Raw Output
                    </button>
                  </div>
                  <div className="submit-code-button">
                    {displaySubmitButton && (
                      <button
                        disabled={runningSubmissions}
                        style={{
                          height: "100%"
                        }}
                        className="submit-coding-examination"
                        data-tooltip="Submit Your Code"
                        onClick={() => submitAndCheckCode(code[codingQuestionId][selectLanguage], codingQuestionId, selectLanguage)}
                      >
                        {
                          runningSubmissions ? (
                            <div className="text-center">
                              <div
                                className="spinner-border d-flex justify-content-center spinner-border-sm"
                                role="status"
                                style={{
                                  color: "#185ADB"
                                }}
                              >
                                <span className="visually-hidden">Loading...</span>
                              </div>
                            </div>
                          ) : "Submit Code"
                        }
                      </button>
                    )}
                  </div>
                </div>
                <div className="p-2 test-case-output-container">
                  <h2 className="output-heading">Output:</h2>
                  <p className="output-container">
                    {rawoutputsol && rawoutputsol.map((value, index) => {
                      return (
                        <>
                          <div
                            className=""
                            style={{
                              whiteSpace: "pre-line",
                            }}
                          >
                            <br />
                            <code
                              style={{
                                color: value.passed ? "green" : "#d63384",
                              }}
                            >
                              -------{value.private ? "Private " : ""}Test Case {index + 1}
                              -----------
                              <br />
                              <code
                                style={{
                                  whiteSpace: "pre-line",
                                  color: value.passed ? "green" : "#d63384",
                                }}
                              >
                                {" "}
                                {value.output.replace(/ /g, "\u00A0")}{" "}
                              </code>
                              <br />
                              -----------------------------
                            </code>
                          </div>
                        </>
                      );
                    })}
                    {
                      customOut.length > 0 && customOut.map((value, index) => {
                        return (
                          <>
                            <div
                              className=""
                              style={{
                                whiteSpace: "pre-line",
                              }}
                            >
                              <br />
                              <code
                                style={{
                                  color: value.passed ? "green" : "#d63384",
                                }}
                              >
                                ------- Running Custom Test Case -------
                                <br />
                                <code
                                  style={{
                                    whiteSpace: "pre-line",
                                    color: value.passed ? "green" : "#d63384",
                                  }}
                                >
                                  {" "}
                                  {value.output.replace(/ /g, "\u00A0")}{" "}
                                </code>
                                <br />
                                -----------------------------
                              </code>
                            </div>
                          </>
                        )
                      })
                    }
                  </p>
                </div>
              </div>
            </div>
          </SplitPane>
        </SplitPane>

      </div>
    </div >
  );
};

const CodeTimer = ({ modes }: { modes: boolean }) => {
  const {
    hours,
    minutes,
    seconds
  } = useSelector(({ hybridCompiler }: IRootState) => hybridCompiler.examTimer)

  return (
    <div
      className="select-language-code tooltip-animation"
      data-tooltip="Test Timer"
      style={{
        width: "150px",
        cursor: "auto",
        padding: "7px 0",
        backgroundColor: modes ? "#15314b" : "#fff"
      }}
    >
      <svg
        fill="currentColor"
        viewBox="0 0 323.569 323.569"
        xmlns="http://www.w3.org/2000/svg"
        className="_3sRQZbRpTpCxpcGmMEqpdt"
        style={{ height: "20px", margin: "0 12px 0 0" }}
      >
        <path d="M161.785 53.928c-74.459 0-134.821 60.361-134.821 134.821S87.325 323.57 161.785 323.57s134.821-60.361 134.821-134.821c-.085-74.424-60.397-134.736-134.821-134.821zm13.482 241.74v-12.545c0-7.446-6.036-13.482-13.482-13.482s-13.482 6.036-13.482 13.482v12.538c-48.792-6.221-87.21-44.638-93.431-93.431H67.41c7.446 0 13.482-6.036 13.482-13.482s-6.036-13.482-13.482-13.482H54.872c6.221-48.792 44.638-87.21 93.431-93.431v12.538c0 7.446 6.036 13.482 13.482 13.482s13.482-6.036 13.482-13.482V81.836c48.795 6.218 87.216 44.636 93.437 93.431h-12.545c-7.446 0-13.482 6.036-13.482 13.482s6.036 13.482 13.482 13.482h12.538c-6.217 48.795-44.636 87.216-93.43 93.437z"></path>
        <path d="M175.267 183.166v-34.863c0-7.446-6.036-13.482-13.482-13.482s-13.482 6.036-13.482 13.482v40.446a13.487 13.487 0 003.95 9.532l26.964 26.964c5.356 5.173 13.891 5.024 19.064-.332 5.046-5.225 5.046-13.507 0-18.732zM134.821 26.964h53.928c7.446 0 13.482-6.036 13.482-13.482S196.195 0 188.749 0h-53.928c-7.446 0-13.482 6.036-13.482 13.482s6.036 13.482 13.482 13.482zM314.314 74.348L276.185 36.22c-5.331-5.198-13.867-5.09-19.065.241-5.105 5.236-5.105 13.588 0 18.824l38.129 38.129c5.198 5.331 13.734 5.439 19.065.241s5.439-13.734.241-19.065a10.994 10.994 0 00-.241-.242zM66.449 55.285c5.331-5.198 5.439-13.734.241-19.065s-13.734-5.439-19.065-.241c-.081.079-.162.16-.241.241L9.256 74.348c-5.331 5.198-5.44 13.734-.242 19.065s13.734 5.44 19.065.242c.082-.08.162-.16.242-.242z"></path>
      </svg>
      {hours}: {minutes}: {seconds}
    </div>
  )
}

export default NewHybridCompilerCoding;

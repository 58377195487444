// @ts-nocheck
import cantileverLight from "../../Assets/img/cantileverlogoblack.png";
import cantilevertransparent from "../../Assets/img/CLLogo.png";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import TestCases from "../../componentsofcourse/TestCases";
import { useEffect, useState, useRef } from "react";
import { Resizable } from "re-resizable";
import { motion } from "framer-motion";
import { BASE_URL } from "../../env";
import AceEditor from "react-ace";
import "../../Assets/css/exam.css";
import "../../Assets/css/compiler.css";
import "ace-builds/src-noconflict/mode-csp";
import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/mode-c_cpp";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/theme-merbivore_soft";
import "ace-builds/src-noconflict/theme-solarized_dark";
import "ace-builds/src-noconflict/theme-solarized_light";
import { useDispatch, useSelector } from 'react-redux';
import {
  changeLanguage,
  changeSolvedIndices,
  changeLanguageValues
} from "../../app/hybridExam/actions/action";
import { compilerAxios } from "../../axios.config";

interface IQuestionCode {
  code: string,
  language: "c++" | "java" | "python" | "c"
}

interface IQuestionCollection {
  _id: string,
  questionCode: Array<IQuestionCode>
  testcases: Array<testCase>,
  outOfScore: number,
  name: string,
  description: string,
  sampleInput: string,
  sampleOutput: string,
  company: string,
  questionWeigh: 0 | 1 | 2 | 3 | 4 | 5
  languages?: Array<"C" | "C++" | "Java" | "Python3">
}

const NewHybridCompilerCoding = (props: any) => {
  const {
    id,
    code,
    setcode,
    selectLanguage,
    hours,
    minutes,
    seconds,
    scoringArray,
    setscoringArray,
    FinishExam,
    // questionsolved,
    setquestionsolved,
  } = props;

  const fontSizes = ["12px", "14px", "16px", "18px", "20px", "22px"];
  const [selectedLanguagetestcase, setselectedlanguagetestcase] = useState<Array<{
    code: string,
    name: string
    output: string
  }>>([]);
  const [showrawoutputtestcases, setshowrawoutputtestcases] = useState<boolean>(false);
  const [showlanguagedropdown, setshowlanguagedropdown] = useState(false);
  const [showfontsizedropdown, setfontsizedropdown] = useState(false);
  // const [runningTestCases, setrunningTestCases] = useState(false);
  const [SampleTestCases, setSampleTestCases] = useState<Array<testCase>>([]);
  // const [bufferedoutput, setbufferedoutput] = useState({});
  const [spinnerShow, setSpinnerShow] = useState(false);
  const [questionCode, setquestionCode] = useState<Array<IQuestionCode>>([]);
  const [rawoutputsol, setrawoutputsol] = useState<Array<{
    testcaseNo: number,
    output: string | null
  }>>([]);
  const [testcaseON, settestcaseON] = useState(false);
  const [weightTimes, setweightTimes] = useState<Array<number>>([]);
  const [questionName, setquestionName] = useState<string>("");
  const [sampleOutput, setSampleOutput] = useState("");
  const [sampleInput, setSamepleInput] = useState("");
  const [rawOutput, setrawOutput] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const [fontSize, setFontSize] = useState("16px");
  const [running, setrunning] = useState(true);
  const [resize, setResize] = useState(true);
  // const [results, setresults] = useState([]);
  const [modes, setModes] = useState(true);
  const [output, setOutput] = useState<string | null>("");
  const Languagevalues = useSelector(({ hybridCompiler }: IStore) => hybridCompiler.languageValues)
  const dispatch = useDispatch();
  const [description, setDescription] = useState({
    __html: "First &middot; Second",
  });
  const refArray = useRef<Array<number>>([]);

  const r = document.querySelector(":root") as HTMLElement;
  function mytheme_func() {
    if (!r) return;

    r.style.setProperty("--inside-color", modes ? "#001528" : "#F6F9FC");
    r.style.setProperty("--input-color-code", modes ? "#15314b" : "#fff");
    r.style.setProperty("--border-color-code", modes ? "#02203c" : "yellow");
    r.style.setProperty("--outer-bgcolor-code", modes ? "#02203c" : "#E0E0E0");
    r.style.setProperty("--text-color", modes ? "#fff" : "#001528");
  }
  mytheme_func();

  function onChange(newValue: string) {
    setcode(newValue);
  }
  document.addEventListener("contextmenu", (event) => {
    event.preventDefault();
  });
  window.addEventListener("keydown", (event) => {
    if (event.code === "KeyV" && event.ctrlKey) {
      event.preventDefault();
    } else if (event.code === "KeyC" && event.ctrlKey) {
      event.preventDefault();
    }
  });
  document.addEventListener("contextmenu", (event) => event.preventDefault());

  useEffect(() => {
    if (!id) return;


    fetch(BASE_URL + "/GetOneQuestion/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data: { questions: IQuestionCollection }) => {
        setquestionName(data.questions.name);
        setDescription({ __html: data.questions.description });
        setSamepleInput(data.questions.sampleInput);
        setSampleOutput(data.questions.sampleOutput);
        setSampleTestCases(data.questions.testcases);
        setquestionCode(data.questions.questionCode);
        if (data.questions.languages && data.questions.languages.length > 0) {
          dispatch(changeLanguageValues(data.questions.languages));
          dispatch(changeLanguage(data.questions.languages[0]));
        } else {

          dispatch(changeLanguageValues(["C", "C++", "Java", "Python3"]));
        }
        let array: Array<number> = [];
        for (var i = 0; i < data.questions.questionWeigh; i++) {
          array.push(i);
        }
        setweightTimes(array);
      });
  }, [id, dispatch]);

  useEffect(() => {
    const array = SampleTestCases?.filter((value) => {
      if (value[0].language === selectLanguage.toLowerCase()) {
        return value;
      }
    });
    if (array?.length !== 0) {
      setselectedlanguagetestcase(array[0][1]);
    }
  }, [SampleTestCases, selectLanguage]);

  function RestartTheCode() {
    let questionsCodes = questionCode.filter(
      (values) => values.language === selectLanguage.toLowerCase()
    );
    setcode(questionsCodes[0]?.code);
  }
  useEffect(() => {
    let questionsCodes = questionCode.filter((values) => {
      return values.language === selectLanguage.toLowerCase();
    });

    if (localStorage.getItem(id + selectLanguage) === null) {
      setcode(questionsCodes[0]?.code);
    } else {
      setcode(localStorage.getItem(id + selectLanguage));
    }
  }, [questionCode, selectLanguage]);

  // function ResetCode() {
  //   setTimeout(() => {
  //     let questionsCodes = questionCode.filter((values) => {
  //       return values.language === selectLanguage.toLowerCase();
  //     }, 1000);

  //     if (localStorage.getItem(id + selectLanguage) === null) {
  //       setcode(questionsCodes[0]?.code);
  //     } else {
  //       setcode(localStorage.getItem(id + selectLanguage));
  //     }
  //   }, 1000);
  // }

  const themeMode = () => {
    setModes(!modes);
  };
  function ChangeStatesofOutput(function1, function2, value1, value2) {
    function1(value1);
    function2(value2);
  }

  const style = {
    borderRadius: "15px",
  };
  function DropDownStyle({ value, selectLanguage, type }) {
    return (
      <div
        onClick={() => {
          if (type === "language") {
            // setSelectedLanguage(value);
            dispatch(changeLanguage(value))
            setshowlanguagedropdown(false);
          } else {
            setFontSize(value);
            setfontsizedropdown(false);
          }
        }}
        className={
          selectLanguage !== value
            ? "options-value-dropdown"
            : "options-value-dropdown selected-language-active"
        }
      >
        <span className={"single_option_dropdown"}>{value}</span>
      </div>
    );
  }

  async function execute_Code(testcasecode: string, expected_output: string, index: number) {
    // var lang =
    //   selectLanguage.toLowerCase() === "c++"
    //     ? "cpp"
    //     : selectLanguage.toLowerCase();
    let lang = 2;
    setrunning(false);
    ChangeStatesofOutput(setrawOutput, settestcaseON, false, true);

    if (selectLanguage === "C++") lang = 54;
    else if (selectLanguage === "Java") lang = 62;
    else if (selectLanguage === "C") lang = 50;
    else lang = 71;

    var newdata = {
      source_code: testcasecode,
      language_id: lang,
      number_of_runs: "1",
      expected_output: null,
      cpu_time_limit: "10",
      cpu_extra_time: "1",
      wall_time_limit: "5",
      memory_limit: "128000",
      stack_limit: "64000",
      max_processes_and_or_threads: "60",
      enable_per_process_and_thread_time_limit: false,
      enable_per_process_and_thread_memory_limit: false,
      max_file_size: "500",
      enable_network: true,
    };

    if (selectLanguage === "C" || selectLanguage === "c") {
      newdata["compiler_options"] = ["-lm"];
    }

    await compilerAxios.post<ICompilerOutput>('/submissions?wait=true', newdata)
      .then(({ data }) => {
        console.log(data);
        setrunning(true);
        if (data.stdout !== null) {
          setrawoutputsol((prev) => [...prev, { testcaseNo: index + 1, output: data.stdout }])
          setOutput(data.stdout);
          CheckTestCase(data.stdout, expected_output, index);
        }
        else {
          if (data.compile_output !== null) {
            console.log("timer end");
            setrawoutputsol((prev) => [...prev, {
              testcaseNo: index + 1,
              output: data.stdout
            }])
            setOutput(data.stdout);
            CheckTestCase(data.stdout, expected_output, index);
          }
          else {
            setrawoutputsol(prev => [
              ...prev,
              {
                testcaseNo: index + 1,
                output: data.stderr
              }
            ])
            setOutput(data.stderr);
            CheckTestCase(data.stderr, expected_output, index);
          }
        }
      }).catch(err => {
        console.log(err.response)
        setrunning(true);
      })
    console.timeLog("timer")
    // await axios(config).then(({ data }) => {
    //   if (data?.stdout !== null) {
    //     setbufferedoutput({
    //       testcaseNo: index++,
    //       output: data.stdout,
    //     });
    //     setOutput(data.stdout);
    //     CheckTestCase(data.stdout, expected_output, index);
    //     setrunning(true);
    //   } else {
    //     if (data.compile_output !== null) {
    //       console.timeEnd("timer end");
    //       setbufferedoutput({
    //         testcaseNo: index++,
    //         output: data.compile_output,
    //       });
    //       setOutput(data.compile_output);
    //       CheckTestCase(data.compile_output, expected_output, index);
    //       setrunning(true);
    //     } else {
    //       setbufferedoutput({
    //         testcaseNo: index++,
    //         output: data.stderr,
    //       });
    //       setOutput(data.stderr);
    //       CheckTestCase(data.stderr, expected_output, index);
    //       setrunning(true);
    //     }
    //   }
    // });
  }
  // async function execute_Code_run() {
  //   const lang =
  //     selectLanguage.toLowerCase() === "c++"
  //       ? "cpp"
  //       : selectLanguage.toLowerCase();
  //   const answerArray = [];
  //   setrunning(false);
  //   ChangeStatesofOutput(setrawOutput, settestcaseON, true, false);

    // var data = JSON.stringify({
    //   data: {
    //     script: code,
    //     stdin: "",
    //     language: lang,
    //     versionIndex: "0",
    //   },
    // });
    // // console.log(data);
    // await fetch(BASE_URL + "/jdoodle", {
    //   method: "POST",
    //   headers: { "Content-Type": "application/json" },
    //   body: data,
    // })
    //   .then((res) => res.json())
    //   .then((data) => {
    //     // console.log(data);
    //     setOutput(data.output);
    //     // CheckTestCase(data.output,expected_output,index)
    //     setrunning(true);
    //   })
    //   .catch((err) => {
    //     // console.log(err);
    //   });
  // }

  function GetScore(array) {
    let score = 0;
    for (var value of array) {
      score += 10;
    }
    return score;
  }

  function TestcasesSolvedNumber(array) {
    let tc_solved = 0;
    for (var value of array) {
      tc_solved += 1;
    }
    return tc_solved;
  }
  function RemoveAndUpdate(id, testcasesSolved) {
    const newArray = scoringArray.filter((value) => value.questionId !== id);
    let newdata = [
      ...newArray,
      {
        questionId: id,
        testcasessolved: TestcasesSolvedNumber(testcasesSolved),
        score: GetScore(testcasesSolved),
        code: code,
      },
    ];
    setscoringArray(newdata);
  }

  function CheckTestCase(output, expected_output, index) {
    let foo = output.replace(/\r?\n|\r/g, "");
    let doo = String(expected_output).replace(/\r?\n|\r/g, "");
    foo = foo.replace(/ /g, "");

    // for (var i = 0; i < foo.length; i++) {
    //   if (!foo[i]) {
    //     continue;
    //   }
    //   if (foo[i] === " ") {
    //     continue;
    //   }
    //   else {
    //     if (foo[i] === doo[i]) {
    //       testcasetrue = true;
    //     }
    //     newstring += foo[i];
    //   }
    // }
    if (foo === doo) {
      refArray.current.push(index);
    } else {
      let newstring1 = "";
      doo = doo.replace(/ /g, "");

      for (var i = 0; i < doo.length; i++) {
        if (doo[i] === undefined) {
          continue;
        }
        if (doo[i] === " ") {
          continue;
        } else {
          // if (doo[i] == doo[i]) {
          //   testcasetrue = true;
          // }
          newstring1 += doo[i];
        }
      }
      if (newstring1 === foo) {
        refArray.current.push(index);
      }
    }
    console.log(foo, doo);
    console.log(refArray.current);

    dispatch(changeSolvedIndices(refArray.current));
    ChangeCompletedArrays(refArray.current);
  }

  function ChangeCompletedArrays(new_arr) {
    // setsolvedIndices(newArray);
    if (new_arr.length === selectedLanguagetestcase.length) {
      // SolvedTheQuestionCorrectly();
      // alert("hello")
      setquestionsolved((prev) => [...prev, id]);
    }
    RemoveAndUpdate(id, new_arr);
  }

  async function RunningAllTestcases() {
    setrawoutputsol([]);
    // setrunningTestCases(true);
    refArray.current = [];
    console.log("test cases length", selectedLanguagetestcase.length);
    for (let i: number = 0; i < selectedLanguagetestcase.length; i++) {
      // console.log(selectedLanguagetestcase[i].code);

      const testcasecode_new = code + selectedLanguagetestcase[i].code;
      // console.log(testcasecode_new)

      await execute_Code(testcasecode_new, selectedLanguagetestcase[i].output, i);
      // console.log(selectedLanguagetestcase[i].output);
      // alert("hello")
      //   if (solvedIndices.length == selectedLanguagetestcase.length) {
      //   // SolvedTheQuestionCorrectly();
      //     setquestionsolved([...questionsolved, id]);
      // }
    }

    console.timeEnd("timer")

    setrunning(true);
    //       if (solvedIndices.length == selectedLanguagetestcase.length) {
    //   // SolvedTheQuestionCorrectly();
    //     setquestionsolved([...questionsolved, id]);
    // }



    setshowrawoutputtestcases(true);
    setTimeout(() => {
      // ChangeStatesofOutput(setrawOutput, settestcaseON, true, false);
      // setrunningTestCases(false);
      //  alert(false)
    }, 2000);
    // setrunningTestCases(false)
    //  console.log(value)
  }
  const [layout, setlayout] = useState(true);
  function changeLayout() {
    setlayout(!layout);
    setResize(!resize);
  }
  const [showPopUp, setshowPopUp] = useState(false);
  return (
    <div className="maincontainer">
      {showPopUp && (
        <div
          style={{
            position: "absolute",
            zIndex: 20,
            backdropFilter: "brightness(0)",
            height: "200px",
            width: "500px",
            top: "50%",
            marginLeft: "-250px",
            marginTop: "-100px",
            left: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
            padding: "20px",
          }}
        >
          Are you sure you want to submit the exam? &emsp;
          <button
            onClick={() => {
              FinishExam();
              setshowPopUp(false);
              setDisabled(true);
              setSpinnerShow(true);
            }}
            className="btn btn-md btn-success"
            disabled={disabled}
          >
            {spinnerShow ? (
              <span className="spinner-border spinner-border-sm"></span>
            ) : (
              "Yes"
            )}
          </button>
          &emsp;
          <button
            onClick={() => {
              setshowPopUp(false);
            }}
            className="btn btn-md btn-danger"
          >
            No
          </button>
        </div>
      )}
      <div
        style={{ paddingLeft: "0px", filter: showPopUp ? "blur(2px)" : "" }}
        className={layout == true ? "compiler" : "compiler_two"}
      >
        <div className="options">
          <img
            src={modes ? cantilevertransparent : cantileverLight}
            width="auto"
            height="42px"
            alt="logo"
            className="pt-1"
          />
          <div className="mid d-flex align-items-center">
            <div
              className={
                showlanguagedropdown
                  ? "select-language-code"
                  : "select-language-code tooltip-animation "
              }
              data-tooltip="Programming Language"
            >
              <p
                className="selected-language-code "
                onClick={() => setshowlanguagedropdown(!showlanguagedropdown)}
                style={{ marginBottom: "0px", width: "135px" }}
              >
                {selectLanguage}
                <i
                  style={{
                    transform:
                      showlanguagedropdown == true
                        ? "rotate(180deg)"
                        : "rotate(0deg)",
                  }}
                  className="fas fa-caret-down animation"
                ></i>
              </p>
              {showlanguagedropdown === true && (
                <div className="dropdown-value">
                  {Languagevalues.map((value) => (
                    <DropDownStyle
                      value={value}
                      selectLanguage={selectLanguage}
                      type={"language"}
                    />
                  ))}
                </div>
              )}
            </div>
            <div
              className={
                showfontsizedropdown
                  ? "select-language-code d-sm-block d-none"
                  : "select-language-code tooltip-animation d-sm-block d-none"
              }
              data-tooltip="Editor Font Size"
            >
              <p
                className="selected-language-code "
                onClick={() => setfontsizedropdown(!showfontsizedropdown)}
                style={{ marginBottom: "0px" }}
              >
                {fontSize}{" "}
                <i
                  style={{
                    transform:
                      showfontsizedropdown == true
                        ? "rotate(180deg)"
                        : "rotate(0deg)",
                  }}
                  className="fas fa-caret-down animation"
                ></i>
              </p>
              {showfontsizedropdown == true && (
                <div className="dropdown-value">
                  {fontSizes.map((value) => (
                    <DropDownStyle
                      value={value}
                      selectLanguage={fontSize}
                      type={"font"}
                    />
                  ))}
                </div>
              )}
            </div>
            <div
              className="select-language-code tooltip-animation"
              data-tooltip="Test Timer"
              style={{ width: "150px", cursor: "auto", padding: "7px 0" }}
            >
              <svg
                fill="currentColor"
                viewBox="0 0 323.569 323.569"
                xmlns="http://www.w3.org/2000/svg"
                className="_3sRQZbRpTpCxpcGmMEqpdt"
                style={{ height: "20px", margin: "0 12px 0 0" }}
              >
                <path d="M161.785 53.928c-74.459 0-134.821 60.361-134.821 134.821S87.325 323.57 161.785 323.57s134.821-60.361 134.821-134.821c-.085-74.424-60.397-134.736-134.821-134.821zm13.482 241.74v-12.545c0-7.446-6.036-13.482-13.482-13.482s-13.482 6.036-13.482 13.482v12.538c-48.792-6.221-87.21-44.638-93.431-93.431H67.41c7.446 0 13.482-6.036 13.482-13.482s-6.036-13.482-13.482-13.482H54.872c6.221-48.792 44.638-87.21 93.431-93.431v12.538c0 7.446 6.036 13.482 13.482 13.482s13.482-6.036 13.482-13.482V81.836c48.795 6.218 87.216 44.636 93.437 93.431h-12.545c-7.446 0-13.482 6.036-13.482 13.482s6.036 13.482 13.482 13.482h12.538c-6.217 48.795-44.636 87.216-93.43 93.437z"></path>
                <path d="M175.267 183.166v-34.863c0-7.446-6.036-13.482-13.482-13.482s-13.482 6.036-13.482 13.482v40.446a13.487 13.487 0 003.95 9.532l26.964 26.964c5.356 5.173 13.891 5.024 19.064-.332 5.046-5.225 5.046-13.507 0-18.732zM134.821 26.964h53.928c7.446 0 13.482-6.036 13.482-13.482S196.195 0 188.749 0h-53.928c-7.446 0-13.482 6.036-13.482 13.482s6.036 13.482 13.482 13.482zM314.314 74.348L276.185 36.22c-5.331-5.198-13.867-5.09-19.065.241-5.105 5.236-5.105 13.588 0 18.824l38.129 38.129c5.198 5.331 13.734 5.439 19.065.241s5.439-13.734.241-19.065a10.994 10.994 0 00-.241-.242zM66.449 55.285c5.331-5.198 5.439-13.734.241-19.065s-13.734-5.439-19.065-.241c-.081.079-.162.16-.241.241L9.256 74.348c-5.331 5.198-5.44 13.734-.242 19.065s13.734 5.44 19.065.242c.082-.08.162-.16.242-.242z"></path>
              </svg>
              {hours}: {minutes}: {seconds}
            </div>
          </div>
          <div className="d-flex flex-row align-items-center">
            <motion.button
              onClick={() => {
                setshowPopUp(true);
              }}
              whileTap={{ scale: 0.9 }}
              className="submit-coding-examination px-auto"
              data-tooltip="Submit Test"
            >
              Finish Test
            </motion.button>
            {modes ? (
              <div className="mode-btn" data-tooltip="Light Theme">
                <div
                  className="select-language-code px-4 mx-2"
                  onClick={themeMode}
                >
                  <i className="fas fa-sun"></i>
                </div>
              </div>
            ) : (
              <div className="mode-btn" data-tooltip="Dark Theme">
                <div
                  className="select-language-code px-4 mx-2"
                  onClick={themeMode}
                >
                  <i className="fas fa-moon"></i>
                </div>
              </div>
            )}
          </div>
        </div>

        {layout == true && (
          <Resizable
            className="question"
            // handleClasses="handle"
            style={style}
            // handleWrapperClassName="handle"
            // handleComponent={<ResizableComponent/>}
            enable={{
              top: false,
              right: true,
              bottom: false,
              left: false,
              topRight: false,
              bottomRight: false,
              bottomLeft: false,
              topLeft: false,
            }}
          >
            <div className="question-panel">
              <p className="your-question-code">Your question</p>
            </div>
            <div style={{ padding: "20px" }}>
              <h4 className="question_heading">{questionName}</h4>
              <p
                className="question_description"
                dangerouslySetInnerHTML={description}
              ></p>
              <div className="sample-input-question">
                <h4>Sample Input</h4>
                <div className="input-area-question">{sampleInput}</div>
                <h4>Sample Output</h4>
                <div className="input-area-question">{sampleOutput}</div>
              </div>
            </div>
          </Resizable>
        )}

        {/* this is the code */}
        <div className={"code"}>
          <div className="run-panel d-flex justify-content-between align-items-stretch">
            <p className="your-solution-code">Your solution</p>

            <div className="d-flex align-items-center">
              <div
                style={{
                  transform: "scale(1.2)",
                  marginRight: "8px",
                  cursor: "pointer",
                  color: modes ? "#fff" : "#001528",
                }}
                onClick={() => {
                  RestartTheCode();
                }}
              >
                <RotateLeftIcon />
              </div>
              {resize ? (
                <div className="svg-btn" data-tooltip="Full Screen">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 469.333 469.333"
                    fill="currentColor"
                    onClick={() => {
                      changeLayout();
                    }}
                  >
                    <path d="M160 0H10.667A10.66 10.66 0 000 10.667V160a10.66 10.66 0 0010.667 10.667H32A10.66 10.66 0 0042.667 160V42.667H160A10.66 10.66 0 00170.667 32V10.667A10.66 10.66 0 00160 0zM458.667 0H309.333a10.66 10.66 0 00-10.667 10.667V32a10.66 10.66 0 0010.667 10.667h117.333V160a10.66 10.66 0 0010.667 10.667h21.333A10.66 10.66 0 00469.333 160V10.667A10.66 10.66 0 00458.667 0zM458.667 298.667h-21.333a10.66 10.66 0 00-10.667 10.667v117.333H309.333a10.66 10.66 0 00-10.667 10.667v21.333a10.66 10.66 0 0010.667 10.667h149.333a10.66 10.66 0 0010.667-10.667V309.333a10.66 10.66 0 00-10.666-10.666zM160 426.667H42.667V309.333A10.66 10.66 0 0032 298.666H10.667A10.662 10.662 0 000 309.333v149.333a10.66 10.66 0 0010.667 10.667H160a10.66 10.66 0 0010.667-10.667v-21.333A10.66 10.66 0 00160 426.667z"></path>
                  </svg>
                </div>
              ) : (
                <div className="svg-btn" data-tooltip="Exit Full Screen">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 469.333 469.333"
                    fill="currentColor"
                    onClick={() => {
                      changeLayout();
                    }}
                  >
                    <path d="M160 0h-21.333A10.66 10.66 0 00128 10.667V128H10.667A10.66 10.66 0 000 138.667V160a10.66 10.66 0 0010.667 10.667H160A10.66 10.66 0 00170.667 160V10.667A10.66 10.66 0 00160 0zM458.667 128H341.333V10.667A10.66 10.66 0 00330.667 0h-21.333a10.66 10.66 0 00-10.667 10.667V160a10.66 10.66 0 0010.667 10.667h149.333A10.66 10.66 0 00469.334 160v-21.333A10.662 10.662 0 00458.667 128zM458.667 298.667H309.333a10.66 10.66 0 00-10.667 10.667v149.333a10.66 10.66 0 0010.667 10.667h21.333a10.66 10.66 0 0010.667-10.667V341.333h117.333a10.66 10.66 0 0010.667-10.667v-21.333a10.66 10.66 0 00-10.666-10.666zM160 298.667H10.667A10.66 10.66 0 000 309.333v21.333a10.66 10.66 0 0010.667 10.667H128v117.333a10.66 10.66 0 0010.667 10.667H160a10.66 10.66 0 0010.667-10.667V309.333A10.66 10.66 0 00160 298.667z"></path>
                  </svg>
                </div>
              )}

              <div onClick={RunningAllTestcases} className="run-btn">
                {running ? (
                  "Run Code"
                ) : (
                  <>
                    <div className="text-center">
                      <div
                        className="spinner-border d-flex justify-content-center text-warning spinner-border-sm"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          {/* {console.log(selectLanguage)} */}
          <AceEditor
            mode={
              selectLanguage.toLowerCase() === "python3"
                ? "python"
                : selectLanguage.toLowerCase() === "c++" ||
                  selectLanguage.toLowerCase() === "c"
                  ? "c_cpp"
                  : selectLanguage.toLowerCase()
            }
            theme={modes ? "solarized_dark" : "solarized_light"}
            value={code}
            className="codes"
            onChange={onChange}
            name="UNIQUE_ID_OF_DIV"
            width={"100%"}
            style={{ overflowX: "auto" }}
            fontSize={fontSize}
            // height={"50%"}
            setOptions={{
              enableBasicAutocompletion: true,
              enableLiveAutocompletion: true,
              enableSnippets: true,
            }}
          />
        </div>
        {/* this is the output area */}
        <div
          className="output_area p-0"
          style={{ width: "100%", height: "100%" }}
        >
          <div className="d-flex flex-row submit-code-compiler justify-content-between">
            <div className="d-flex align-items-stretch">
              <button
                className={
                  rawOutput === true
                    ? "selected-type-test borderchange"
                    : "unselect-type-test borderchange"
                }
                onClick={() => {
                  ChangeStatesofOutput(
                    setrawOutput,
                    settestcaseON,
                    true,
                    false
                  );
                }}
              >
                Raw Output
              </button>
              <button
                className={
                  testcaseON === true
                    ? "selected-type-test"
                    : "unselect-type-test"
                }
                onClick={() => {
                  ChangeStatesofOutput(
                    setrawOutput,
                    settestcaseON,
                    false,
                    true
                  );
                }}
              >
                Test Cases
              </button>
            </div>
            <div>
              <button
                className="submit-code-test"
                onClick={RunningAllTestcases}
              >
                {running ? (
                  "Submit Code"
                ) : (
                  <>
                    <div className="text-center">
                      <div
                        className="spinner-border d-flex justify-content-center text-warning spinner-border-sm"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </>
                )}
              </button>
            </div>
          </div>
          <div className="p-2">
            <h2 className="output-heading">Output:</h2>
            {rawOutput === true && (
              <p>
                {showrawoutputtestcases && (
                  <>
                    {rawoutputsol.map((value, index) => {
                      return (
                        <>
                          <div className="" style={{ whiteSpace: "pre-line" }}>
                            <br />
                            <code>
                              -------Test Case {index + 1}-----------
                              <br />
                              <code style={{ whiteSpace: "pre-line" }}>
                                {" "}
                                {value.output}{" "}
                              </code>
                              <br />
                              -----------------------------
                            </code>
                          </div>
                        </>
                      );
                    })}
                  </>
                )}
              </p>
            )}

            {testcaseON === true && (
              <div>
                <TestCases
                  // running={running}
                  setquestionsolved={setquestionsolved}
                  videoid={id}
                  // results={results}
                  type="exam"
                  selectedLanguagetestcase={selectedLanguagetestcase}
                  selectLanguage={selectLanguage}
                  output={output}
                  testcases={SampleTestCases}
                />
              </div>
            )}
            <div className="output-jdoodle" style={{ opacity: "0" }}>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit.
              Veritatis consectetur libero sunt at error minima repellendus
              rerum impedit quia necessitatibus sint, quae eius? Id ipsum
              praesentium minima reprehenderit, minus obcaecati. Laborum nobis
              sunt minima commodi, vitae dolore. Sed asperiores corrupti
              voluptate quisquam odio officia non nisi, in omnis blanditiis, est
              magni facilis. Rem illo in, laborum deleniti magnam debitis
              voluptatem.
            </div>
          </div>
        </div>
        {/* // </Resizable> */}
      </div>
    </div>
  );
};

export default NewHybridCompilerCoding;

import React, { useState } from "react";

import { useDispatch } from "react-redux";
import { examType } from "../app/actions/batchActions";

const PowerHeader = ({ func, tab }) => {
  const [toggler, setToggler] = useState(false);
  const dispatch = useDispatch();

  return (
    <>
      <div className="powerHeader">
        <img
          src="https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1655718193305_cantilever-logo-final-black.png"
          className="cantileverLogo"
          alt="cantilever logo"
        />

        {/* <div>
          <div>
            <img
              className="companyLogoImg"
              src="https://cantilever-college-logo-b2b.s3.ap-south-1.amazonaws.com/GCET_logo.png"
              alt="Company Logo"
            />
          </div>
        </div> */}

        <div
          className="hamburgerToggler d-lg-none"
          onClick={() => {
            setToggler(!toggler);
          }}
        >
          <i className="fas fa-bars"></i>
        </div>
        <ul
          className={`navbar-list ${toggler ? "d-block" : "d-lg-flex d-none"}`}
        >
          <li className="text-center my-2 d-lg-none">
            <img
              src="https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1655718193305_cantilever-logo-final-black.png"
              className="cantileverLogo"
              alt="cantilever logo"
            />
          </li>
          <li
            className={`navbar-item mt-lg-0 mt-4 ${
              tab === 0 ? "navbar-active" : ""
            }`}
            onClick={() => {
              func(0);
              setToggler(false);
            }}
          >
            Home
          </li>
          <li
            className={`navbar-item ${tab === 1 ? "navbar-active" : ""}`}
            onClick={() => {
              func(1);
              setToggler(false);
            }}
          >
            Attendance
          </li>
          <li
            className={`navbar-item ${tab === 2 ? "navbar-active" : ""}`}
            onClick={() => {
              func(2);
              setToggler(false);
            }}
          >
            Assignments
            {/* <i class="fas fa-caret-down"></i> */}
            {/* <ul className="dropdownList">
              <li className="dropdownItem">Quiz</li>
              <li className="dropdownItem">Coding</li>
              <li className="dropdownItem">Apptitude</li>
            </ul> */}
          </li>
          <li
            className={`navbar-item ${tab === 3 ? "navbar-active" : ""}`}
            onClick={() => {
              func(3);
              setToggler(false);
            }}
          >
            Tests
            {/* <i class="fas fa-caret-down"></i>
            <ul className="dropdownList">
              <li className="dropdownItem">Quiz</li>
              <li className="dropdownItem">Coding</li>
              <li className="dropdownItem">Apptitude</li>
            </ul> */}
          </li>
          <li
            className={`navbar-item ${tab === 4 ? "navbar-active" : ""}`}
            onClick={() => {
              func(4);
              setToggler(false);
            }}
          >
            Overview
          </li>
          <li className={`navbar-item ${tab === 5 ? "navbar-active" : ""}`}>
            <select
              className="coding-select"
              name="exam-type"
              id="exam-type"
              onChange={(event) => dispatch(examType(event.target.value))}
            >
              <option value="coding">Coding </option>
              <option value="quiz">Quiz</option>
              <option value="hybrid">Hybrid</option>
            </select>
          </li>
        </ul>
      </div>
    </>
  );
};

export default PowerHeader;

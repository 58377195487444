import React, { useEffect, useState } from "react";
import { BASE_URL } from "../env";
import "../Assets/css/DashboardComp.css";
import { useParams } from "react-router-dom";
import { divide } from "lodash";
const Quizz = ({ hidenavbar }) => {
  const [examsArray, setExamsArray] = useState([]);
  const { id } = useParams();
  useEffect(() => {
    fetch(BASE_URL + "/getQuizAllEXam", {
      headers: { "content-type": "application/json" },
    })
      .then((res) => res.json())
      .then((data) => {
        setExamsArray(
          data.data.filter((val) => {
            return val.courseId === id;
          })
        );
      });
  }, []);

  useEffect(() => {
    hidenavbar(true);
    return () => {
      hidenavbar(false);
    };
  }, []);
  return (
    <>
      <div className="QuizDashboardHeader">Cantilever Quiz Portal</div>
      <div className="row">
        {examsArray.map((item) => {
          return (
            <>
              <div className="col-md-6">
                <div className="quizDashboardCard">
                  <div className="quizDashboardCardBody">
                    <div className="quizDashboardCardSubTitle">
                      {item.examName}
                    </div>
                    <div className="quizDashboardCardTitle">
                      {item.description}
                    </div>
                    <div className="quizDashboardCardText">
                      Solved By : {item.examAttempted} Students
                    </div>
                  </div>
                  <div className="quizDashboardCardFooter">
                    <div className="quizDashboardCardTTime">
                      <i class="far fa-clock"></i> {item.timer} mins
                    </div>
                    <a
                      href={`/quizinstructions?duration=${item.timer}&questions=${item.questions.length}&id=${item._id}`}
                      className="quizDashboardCardTBtn"
                      target="blank"
                    >
                      Take Exam
                    </a>
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </div>
    </>
  );
};

export default Quizz;

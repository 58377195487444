import React from "react";
import certificate from "../Assets/img/Competitive/certificate.png";
import styles from "../Assets/css/cpcourse.module.css";
function CPcertificate(props) {
  let C = props.m ? "container row" : "row";
  function HandleImg(e) {
    return (
      <div class="col-xl-7 col-lg-7 col-md-8 col-sm-12 col-12 certi-div">
        <img src={certificate} alt="" class="img-fluid" />
      </div>
    );
  }
  function HandleHeading(e) {
    return (
      <div
        className={`col-xl-5 col-lg-5 col-md-4 col-sm-12 col-12 text-center ${styles.certidivcontent}`}
      >
        <h1>
          Industry recognized <br /> certificate
        </h1>
      </div>
    );
  }
  return (
    <div class="container-fluid certificate-div">
      <br />
      <br />
      <div class={C}>
        {props.m ? HandleImg() : HandleHeading()}
        {props.m ? HandleHeading() : HandleImg()}
      </div>
    </div>
  );
}

export default CPcertificate;

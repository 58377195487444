import React, { useState } from "react";
import { BASE_URL } from "../env";
import axios from "axios";
import { Button } from "react-bootstrap";
import { store } from "react-notifications-component";

function PopupComponent(props) {
  const [picture, setPicture] = useState("");

  function HandlePicture(e) {
    e.preventDefault();
    setPicture(e.target.files[0]);
  }

  const notifyPopup = (backend_message, type) => {
    store.addNotification({
      title: backend_message,
      message: backend_message,
      type: type,
      background: "pink",
      insert: "bottom",
      container: "bottom-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true,
      },
    });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    const pict = picture;
    const fd = new FormData();
    fd.append("user_profilepic", picture);

    try {
      await axios
        .post(BASE_URL + "/editPicture", fd, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          props.setTrigger(false);
          notifyPopup(res.data.message, "success");
        });
    } catch (err) {
      if (err.response && err.response.data) {
        notifyPopup("Error in Updating!", "danger");
        props.setTrigger(false);
      }
    }
  };

  return props.trigger ? (
    <div
      style={{
        position: "fixed",
        top: "0",
        left: "0",
        width: "42%",
        height: "46vh",
        marginLeft: "20%",
        marginTop: "10%",
        borderRadius: "1.5rem",
        backgroundColor: "rgb(0,0,0,0.2)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        boxShadow:
          "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset",
      }}
    >
      <div
        className="popup-inner"
        style={{
          position: "relative",
          padding: "32px",
          width: "100%",
          borderRadius: "1.5rem",
          height: "48vh",
          maxWidth: "640px",
          backgroundColor: "#ececec", 
        }}
      >
        <Button
          style={{
            position: "absolute",
            zIndex: "5",
            top: "16px",
            backgroundColor: "rgba(0,0,0,.1)",
            right: "16px",
            color: "black",
          }}
          onClick={() => {
            props.setTrigger(false);
          }}
          variant="light"
        >
          X
        </Button>
        <div
          style={{
            marginTop: "40px",
            padding: "20px",
            border: "2px solid rebeccapurple",
          }}
        >
          <p>Please Upload Your Picture Here</p>
          <input type="file" onChange={HandlePicture} required />
          <br />
          <br />
          <Button
            style={{
              position: "relative",
              marginTop: "5%",
              left: "35%",
            }}
            onClick={submitHandler}
            variant="success"
          >
            Upload
          </Button>
        </div>
      </div>
    </div>
  ) : (
    ""
  );
}

export default PopupComponent;

import React, { useEffect, useState } from "react";
import firebase from "../firebase";
import ReactGA from "react-ga";
import { BASE_URL } from "../env";
import VideoPlayer from "react-video-js-player";
import Typed from "typed.js";
import useMedia from "./useMedia";
import style from "../Assets/css/myCourseVideoCard.module.css";
import play_button from "../Assets/img/play_btn.png";
import group_8726 from "../Assets/img/Group 8726.png";
import group_8727 from "../Assets/img/Group 8727.png";
import tick from "../Assets/img/completed_tick.png";
import bookmark_png from "../Assets/img/white-star.svg";
import brain from "../Assets/img/Group 8726.png";
import bulb from "../Assets/img/Group 8727.png";
import "../Assets/css/references.css";
// import Typed from 'react-typed';

const KnowledgeReferences = ({ video, bigHeading, sections }) => {
  // console.log(video)
  // console.log(bigHeading)
  // console.log(sections)

  const matches = useMedia("(min-width : 768px )");
  const analytics = firebase.analytics();

  // const [videos, setVideos] = useState([]);
  // const [Data, setdata] = useState("");
  // const [toolKits, setToolKits] = useState([]);

  // Create reference to Store the Typed instance itself
  var headingsAndSections = [];
  var arr = [];
  const [knowledge, setKnowledge] = useState([]);
  useEffect(() => {
    ReactGA.initialize("UA-198180460-1");
    // console.log(props);
    // setVideos(props.videos);
    // fetch(BASE_URL + "/smallCourses/getAllcourses", {
    //   headers: { authorization: "Bearer " + localStorage.getItem("token") },
    // })
    //   .then((res) => res.json())
    //   .then((data) => {
    //     setdata(data);
    //     console.log("Data from reference.js", data);
    //     data.data.map((item) => {
    //       setToolKits(item.toolkits);
    //     });
    //   });

    // console.log(JSON.stringify(video));
    // const array = video.map((value,index)=>{{value.bigHeading,value.section}})
    headingsAndSections = video.map((value, index) => {
      return { bigHeading: value.bigHeading, section: value.section };
    });
    // console.log(headingsAndSections);
    // console.log(bigHeading);
    // console.log(sections);

    bigHeading.forEach((element) => {
      var setS = new Set();
      video.forEach((v) => {
        if (element === v.bigHeading) {
          setS.add(v.section);
        }
      });
      var tmp = Array.from(setS);
      //console.log(tmp);
      arr.push({ bigHeading: element, subHeadings: tmp });
    });
    setKnowledge(arr);
    // console.log(arr);
  }, []);
  // useEffect(() => {
  //   const options = {
  //     strings: ["    videos ", "    content "],
  //     loop: true,
  //     strings: ["    videos will be ", "    content will be"],
  //     loop: true,
  //     showCursor: false,
  //     typeSpeed: 50,
  //     backSpeed: 50,
  //   };

  //   // elRef refers to the <span> rendered below
  //   typed.current = new Typed(el.current, options);

  //   return () => {
  //     // Make sure to destroy Typed instance during cleanup
  //     // to prevent memory leaks
  //     typed.current.destroy();
  //   };
  // }, []);

  return (
    <>
      {knowledge.map((value) => {
        return (
          <>
            <div className="heading mb-4 text-light py-3 px-2 rounded"  style={{backgroundColor:"#00316e"}}>
              <h3 style={{ marginBottom: "0px" }} className="mx-5">
                {" "}
                {value.bigHeading}
              </h3>
            </div>

            {value.subHeadings.map((item) => {
              return (
                <>
                  <h4 className="mx-5" style={{ fontWeight: "700" }}>
                    {item}
                  </h4>
                  <div
                    className="row  my-5 example "
                    style={{ overflowX: "scroll", }}
                  >
                    {video.map((videovalue) => {
                      if (
                        videovalue.bigHeading == value.bigHeading &&
                        videovalue.section == item
                      ) {
                        return (
                          <>
                            {/* { JSON.stringify(videovalue)} */}
                            <a
                              href={videovalue.link}
                              target="blank"
                              className={` ${style.course_card} col-lg-4 px-4 col-md-4 col-sm-6 my-4 mx-auto`}
                              onClick={() => {
                                analytics.logEvent("Win_the_interview_Card", {
                                  type: "card",
                                  name: "Win_the_interview",
                                  content:
                                    "Win the interview card in interview kit section",
                                });

                                ReactGA.event({
                                  category: "Win_the_interview_Card",
                                  type: "card",
                                  name: "Win_the_interview",
                                  action:
                                    "Win the interview card in interview kit section",
                                });
                              }}
                            >
                              <div
                                className={
                                   style.card_background_blue
                                }
                                key={1}
                                onClick={() => {
                                  analytics.logEvent("", {
                                    type: "card",
                                    name: "",
                                    content: "",
                                  });
                                }}
                              >
                                <div className={`${style.upper_section}`}>
                                  
                                  <div className={`${style.bookmark}`}>
                                    <img src={bookmark_png} />
                                  </div>
                                </div>
                                <div>
                                  <div className={`${style.middle_section}`}>
                                    <h5 className={`${style.course_name}`}>
                                      {videovalue.name}
                                    </h5>
                                    {/* <a
                                      className={`${style.play_btn}`}
                                      href={videovalue.link}
                                      target="blank"
                                    >
                                      <img
                                        src={
                                          item ==
                                            "PROGRAMMING & DSA (Knowledge Card)" ||
                                          item == "APTITUDE (Knowledge Card)"
                                            ? brain
                                            : bulb
                                        }
                                      />
                                    </a> */}
                                  </div>

                                  <div className={`${style.white_band}`} >
                                    <div
                                      className={`${style.white_band_inner_section}`}
                                    >
                                      <div>
                                        <div
                                          className={`${style.white_band_inner_section_btn}`}>
                                          
                                          <button
                                            className={` ${style.white_band_btn_mark_e_book}`}
                                            // onClick={() => {
                                            //   completedFunction(videovalue._id);
                                            // }}
                                          >
                                            Download
                                          </button>
                                          {/* <button
                                            className={` ${style.white_band_btn_mark_e_book}`}
                                            // onClick={() => {
                                            //   completedFunction(videovalue._id);
                                            // }}
                                          >
                                            Mark as complete
                                          </button>
                                          <button
                                            className={` ${style.white_band_btn_reffered_e_book}`}
                                          >
                                            Mark for Revision
                                          </button> */}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </a>
                          </>
                        );
                      }
                    })}
                  </div>
                </>
              );
            })}
          </>
        );
      })}

      {/* {bigHeading.map((value,index)=> <p> {value}</p>)} */}
    </>
  );
};

export default KnowledgeReferences;

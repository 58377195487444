import React, { useState } from "react";
import Media from "react-media";
// import MainLogo from "../Assets/img/misc/whitelogo.png";
import { Link } from "react-router-dom";
import submit from "../Assets/img/submit.svg";
import style from "../Assets/css/signup.module.css";
import { BASE_URL } from "../env";
import useMedia from "../componentsofcourse/useMedia";
function Footer() {
  const [email, setEmail] = useState("");
  const [msg, setMsg] = useState("");
  const [mess, showMsg] = useState(false);
  const matches = useMedia("(min-width : 768px )");

  async function handleSubmit(e) {
    e.preventDefault();

    const wait = await fetch(BASE_URL + "/masterclass/addQuery", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        query: "Get In Touch",
      }),
    })
      .then((response) => response.json())
      .then((response2) => {
        setMsg(response2.message);
        showMsg(true);
      })
      .catch((err) => {
        setMsg("ERROR!! in sending data please check your internet connection");
        showMsg(true);
      });
  }
  return (
    // <div style={{backgroundColor:'black'}}>

    // <div className="container">
    // <br/>
    // <br/>

    // <Media query="(max-width: 650px)">
    //   {(matches) => {
    //     return matches ? (

    //     <div  className="row text-white">
    //         <div className="col-lg-4 col-sm-12 col-12">
    //             <h6 className="font-weight-bold "><b>Useful Links</b></h6>
    //             <a href='/policies#privacy' style={{textDecorationLine:"none"}}><h6>Privacy Policy</h6></a>
    //             <a href='/policies#terms' style={{textDecorationLine:"none"}}><h6>Terms & Conditions</h6></a>
    //             <a href='/policies#pricing' style={{textDecorationLine:"none"}}><h6>Pricing and refund</h6></a>
    //         </div>
    //         <div className="col-lg-4 col-sm-12 col-12">
    //         <h6 className="font-weight-bold"><b>Our Services</b></h6>
    //             <h6>Masterclasses</h6>
    //             <h6>Placement trainings</h6>
    //             <h6>Certified trainings</h6>
    //         </div>
    //         <div className="col-lg-4 col-sm-12 col-12">
    //         <h6 className="font-weight-bold "><b>Location</b></h6>
    //             <h6>T-HUB Catalyst Building</h6>
    //             <h6>IIIT Hyderabad Campus </h6>
    //             <h6>Email - info@cantileverlabs.com</h6>
    //         </div>
    //     </div>

    //     ):(

    //     <div style={{textAlign:'left'}} className="row text-white">
    //         <div className="col-lg-4 col-sm-12 col-12">
    //             <h6 className="font-weight-bold "><b>Useful Links</b></h6>
    //             <a href='/policies#privacy' style={{textDecorationLine:"none"}}><h6>Privacy Policy</h6></a>
    //             <a href='/policies#terms' style={{textDecorationLine:"none"}}><h6>Terms & Conditions</h6></a>
    //             <a href='/policies#pricing' style={{textDecorationLine:"none"}}><h6>Pricing and refund</h6></a>
    //         </div>
    //         <div className="col-lg-4 col-sm-12 col-12">
    //         <h6 className="font-weight-bold"><b>Our Services</b></h6>
    //             <h6>Masterclasses</h6>
    //             <h6>Placement trainings</h6>
    //             <h6>Certified trainings</h6>
    //         </div>
    //         <div className="col-lg-4 col-sm-12 col-12">
    //         <h6 className="font-weight-bold "><b>Location</b></h6>
    //             <h6>T-HUB Catalyst Building</h6>
    //             <h6>IIIT Hyderabad Campus </h6>
    //             <h6>Email - info@cantileverlabs.com</h6>
    //         </div>
    //     </div>

    //     );
    //   }}
    // </Media>

    //     <div className="text-white " style={{ border:'3px solid white', borderRight:'none',borderLeft:'none'}}>
    //     <br/>
    //     <div className="row" >
    //         <div className="col-lg-4 col-sm-12 col-12 ">
    //             <h6 style={{fontSize:'32px' }}>Follow us </h6>
    //         </div>
    //         <div className="col-lg-4 col-sm-12 col-12">
    //         <a href="https://www.instagram.com/cantilever_labs/" style={{fontSize:'32px',margin:'5px'}}><span className="text-white fa fa-instagram ml-5 ">  </span></a>
    //         <a href="https://www.linkedin.com/company/cantileverlabs/" style={{fontSize:'32px',margin:'5px'}}><span className="text-white fa fa-linkedin ml-5">   </span></a>
    //         <a href="https://www.facebook.com/Cantilever-labs-102916474752041/" style={{fontSize:'32px',margin:'5px'}}><span className="text-white fa fa-facebook-square ml-5"></span></a>
    //         <a href="https://www.youtube.com/channel/UCHSggA4W_L0nSzWrf_lnZpw" style={{fontSize:'32px',margin:'5px'}}><span className="text-white fa fa-youtube ml-5"></span></a>
    //         </div>
    //

    //     </div>
    //     </div>

    //     <br/>
    //     <p className="text-white" style={{textAlign:"center"}}> <span className="fa fa-copyright"></span>  copright <b> 2018 Cantilever Labs</b></p>
    //     <br/>
    // </div>
    // </div>

    <div className="container-fluid p-5 pb-2" style={{ background: "#1d1d1d" }}>
      <div class="row">
        {/* ----------------------------------        column-1          ------------------------------- */}

        <div className="col-lg-4 col-md-3 col-sm-12 mx-auto">
          <div className="">
            <img
              width="80%"
              src="https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644090010549_whitelogo.png"
              alt="logo of Cantilever Labs"
              className="img-fluid"
            />
          </div>
          <p className="text-left text-white mt-5 mb-0">
            &#169; copyright <b>2018 Cantilever Labs</b>
            <p
              style={{ color: "grey" }}
              style={{ width: "50%" }}
              className={style.horizontalh}
            ></p>
          </p>

          <p className="text-left text-white my-1">
            T-HUB Catalyst Building
            <br />
            IIIT Hyderabad Campus
            <br />
            Email -
            <a href="mailto:info@cantileverlabs.com">info@cantileverlabs.com</a>
          </p>
        </div>

        {/* ----------------------------------        column-2 /Company         ------------------------------- */}

        {matches == true ? (
          <>
            {" "}
            <div className="col-lg-2 col-md-3 col-sm-12 mx-auto text-white">
              <h5 className="font-weight-bold my-4">
                <b>Company</b>
              </h5>
              <div className="d-flex flex-column ">
                <h6 className="my-3">
                  <a href="/aboutus" style={{ textDecorationLine: "none" }}>
                    About Us
                  </a>
                </h6>
                {/* <h6 className="my-3">
                  <a
                    href="/policies#privacy"
                    style={{ textDecorationLine: "none" }}
                  >
                    Testimonials
                  </a>
                </h6> */}
                <h6 className="my-3">
                  <a
                    href="/jobs-for-freshers"
                    style={{ textDecorationLine: "none" }}
                  >
                    Jobs/Internships
                  </a>
                </h6>
                <h6 className="my-3">
                  <a href="/blog" style={{ textDecorationLine: "none" }}>
                    Blogs
                  </a>
                </h6>
                {/* <h6 className="my-3">
                  <a
                    href="/masterclasses"
                    style={{ textDecorationLine: "none" }}
                  >
                    Webinar
                  </a>
                </h6> */}
              </div>
            </div>
            {/* ----------------------------------        column-3 /Support         ------------------------------- */}
            <div className="col-lg-2 col-md-3 col-sm-12 mx-auto text-white">
              <h5 className="font-weight-bold my-4">
                <b>Support</b>
              </h5>
              <div className="d-flex flex-column justify-content-around ">
                {/* <h6 className="my-3">
                  <a
                    href="https://us6.list-manage.com/contact-form?u=14ad39c02ad3d8d36fdc53550&form_id=13c76439cc990bf05c7e83fb8f5c0ebb"
                    style={{ textDecorationLine: "none" }}
                  >
                    Contact Us
                  </a>
                </h6> */}
                <h6 className="my-3">
                  <a
                    href="/policies#privacy"
                    style={{ textDecorationLine: "none" }}
                  >
                    Privacy Policy
                  </a>
                </h6>
                {/* <h6 className="my-3">
                  <a
                    href="https://us6.list-manage.com/contact-form?u=14ad39c02ad3d8d36fdc53550&form_id=13c76439cc990bf05c7e83fb8f5c0ebb"
                    style={{ textDecorationLine: "none" }}
                  >
                    Help Center
                  </a>
                </h6> */}
                <h6 className="my-3">
                  <a href="/sitemap" style={{ textDecorationLine: "none" }}>
                    Sitemap
                  </a>
                </h6>
              </div>
            </div>{" "}
          </>
        ) : (
          <>
            <div className="d-flex flex-row justify-content-between">
              <div className="d-flex flex-column">
                <h5 className="text-light">Company</h5>
                <a href="/aboutus" className="my-2 text-light">
                  About us
                </a>
                <a href="/" className="my-2 text-light">
                  Testimonials
                </a>
                <a href="/jobs-for-freshers" className="my-2 text-light">
                  Jobs/Internships
                </a>
                <a href="/blog" className="my-2 text-light">
                  Blogs
                </a>
              </div>
              <div className="d-flex flex-column">
                <h5 className="text-light">Support</h5>
                <a
                  href="https://us6.list-manage.com/contact-form?u=14ad39c02ad3d8d36fdc53550&form_id=13c76439cc990bf05c7e83fb8f5c0ebb"
                  className="my-2 text-light"
                >
                  Contact us
                </a>
                <a href="/policies#privacy" className="my-2 text-light">
                  Privacy Policy
                </a>
                <a
                  href="https://us6.list-manage.com/contact-form?u=14ad39c02ad3d8d36fdc53550&form_id=13c76439cc990bf05c7e83fb8f5c0ebb"
                  className="my-2 text-light"
                >
                  Help center
                </a>
                <a href="/sitemap" className="my-2 text-light">
                  Sitemap
                </a>
              </div>
            </div>
          </>
        )}

        {/* ----------------------------------        column-4  /Get in touch        ------------------------------- */}

        <div className="col-lg-3 col-md-3 col-sm-12 mx-auto text-white">
          <h5 className="font-weight-bold my-3">
            <b>Get in Touch</b>
          </h5>
          <div className="d-flex flex-column justify-content-center my-4">
            {mess === false ? (
              <div
                className="d-flex flex-row p-2 "
                style={{
                  background: "white",
                  borderRadius: "7px",
                }}
              >
                <input
                  onChange={(event) => setEmail(event.target.value)}
                  className="footer-input"
                  type="email"
                  placeholder="Your Email ID"
                  color="white"
                  style={{
                    border: "none",
                    outline: "none",
                    margin: "0",
                    color: "grey",
                    background: "transparent",
                    flex: "1",
                  }}
                  required
                />
                {/* icon will come here */}
                <button
                  className="btn flex-2 "
                  style={{ outline: "none", border: "none" }}
                  onClick={handleSubmit}
                >
                  <img
                    src={submit}
                    style={{
                      opacity: email === "" ? 0.7 : 1,
                    }}
                    alt="Submit"
                    className="img-fluid"
                  />
                </button>
              </div>
            ) : (
              <p>{msg}</p>
            )}
            <h5 className="font-weight-bold mt-4">
              <b>Follow Us</b>
            </h5>
            <div className="d-flex flex-row justify-content-between p-2">
              <a
                href="https://www.instagram.com/cantilever_labs/"
                style={{ fontSize: "32px", margin: "5px" }}
              >
                <span className="text-white fa fa-instagram ml-5 "> </span>
              </a>
              <a
                href="https://www.linkedin.com/company/cantileverlabs/"
                style={{ fontSize: "32px", margin: "5px" }}
              >
                <span className="text-white fa fa-linkedin ml-5"> </span>
              </a>
              <a
                href="https://www.facebook.com/Cantilever-labs-102916474752041/"
                style={{ fontSize: "32px", margin: "5px" }}
              >
                <span className="text-white fa fa-facebook-square ml-5"></span>
              </a>
              <a
                href="https://www.youtube.com/channel/UCHSggA4W_L0nSzWrf_lnZpw"
                style={{ fontSize: "32px", margin: "5px" }}
              >
                <span className="text-white fa fa-youtube-play ml-5"></span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Footer;

import React from "react";

function B2BNewMentors({ mentorName,mentorDesc,mentorCompany,companyImage,mentorsLogo,mentorLinkdin }) {
  return (
         <div className='col-sm-3 mx-2 shadow rounded'>
         <a href={mentorLinkdin}>
         <div className='mentorInfo row'>
            <div className='col-4 mentorimg'>
              <img
                className="mentorImg"
                src={mentorsLogo}
                width='100%'
                alt='no img'
              />
            </div>
            <div className='col-8 info'>
              <div className="infoName">{mentorName}</div>
              <div className="infoDescription">{mentorDesc}</div>
              <div className="infoCompany">{mentorCompany}</div>
              <div className="infoComponyLogo">
              <img
                    className="companylogoImg"
                    src={companyImage}
                    alt={`logo`}
                  />
              </div>
            </div>
          </div>
         </a>
        </div>
  
    
    
  
  );
}

export default B2BNewMentors;

import React, { useState, useEffect } from "react";
import { CSSTransition } from "react-transition-group";

import useMedia from "../componentsofcourse/useMedia";
import tick_img from "../Assets/img/tick_img.png";
// import "../Assets/css/PaymentPopup.css";
import styles from "../Assets/css/PaymentPopup.module.css";
import { Link } from "react-scroll";
import Confetti from "react-confetti";
import { BASE_URL } from "../env";

function PP(props) {
  const matches = useMedia("(min-width : 768px )");

  const [modalIsOpen, setmodalIsOpen] = useState(false);
  const [coupon, setCoupon] = useState(false);
  const [discountAmount, setDiscountAmount] = useState(0);
  var d = new Date(parseInt(props.date));
  const couponCode = props.couponCode;
  //
  useEffect(() => {
    if (couponCode != "") {
      fetch(BASE_URL + "/getAllCoupons")
        .then((response) => response.json())
        .then((data) => {
          const coupons = data.coupons;
          for (let coupon of coupons) {
            if (couponCode == coupon.couponCode) {
              let discount = coupon.percentage;
              setDiscountAmount(
                Math.ceil(
                  props.cost / 100 - ((props.cost / 100) * discount) / 100
                )
              );
              setCoupon(true);
            }
          }
        });
    }
  }, []);

  function formatDate(date) {
    var day = date.getDate();
    if (day < 10) {
      day = "0" + day;
    }
    var month = date.getMonth() + 1;
    if (month < 10) {
      month = "0" + month;
    }
    var year = date.getFullYear();
    return day + "/" + month + "/" + year;
  }

  const closeOnEscapeKeyDown = (e) => {
    if ((e.charCode || e.keyCode) === 27) {
      props.onClose();
    }
  };

  useEffect(() => {
    document.body.addEventListener("keydown", closeOnEscapeKeyDown);
    return function cleanup() {
      document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
    };
  }, []);
  return (
    <div>
      <Confetti
        height={window.innerHeight - 10}
        width={window.innerWidth - 10}
      />
      <CSSTransition
        in={props.show}
        //   onClose={() => setmodalIsOpen(false)}
        unmountOnExit
        timeout={{ enter: 0, exit: 300 }}
      >
        <div className={styles.modal} onClick={props.onClose}>
          <div
            className="pp-modal-content"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="container justify-content-center my-5">
              <div style={{}} className="container">
                {matches ? (
                  <div className="row">
                    <div
                      onClick={props.onClose}
                      style={{
                        position: "absolute",
                        borderRadius: "50%",
                        top: "1%",
                        left: "89%",
                        width: "40px",
                        height: "40px",
                        background: "white",
                        // border: "1px solid black",
                      }}
                    >
                      <div
                        style={{
                          position: "relative",
                          bottom: "-12%",
                          width: " 100%",
                          textAlign: "center",
                        }}
                      >
                        <i className="fa fa-times fa-lg"></i>
                      </div>
                    </div>
                    <h5>
                      <span className="payment-text-left">Order Summary</span>
                      {/* <span className="payment-text-right">{props.cost}</span> */}
                    </h5>
                    <hr />
                    <img
                      style={{ width: "25%", marginBottom: "3%" }}
                      src={tick_img}
                      alt="tick mark"
                      className="mx-auto d-block"
                    />
                    <br />
                    <div>
                      <h5 className="text-center">Payment Successful</h5>
                    </div>
                    <br />
                    <br />
                    <hr />
                    <div style={{ color: "black" }}>
                      <p>
                        <span className="payment-text-left">
                          Transaction Id
                        </span>
                        <span className="payment-text-right">
                          {props.order_id}
                        </span>
                      </p>
                      <br />
                      <p>
                        <span className="payment-text-left">Customer Name</span>
                        <span className="payment-text-right">{props.name}</span>
                      </p>
                      <br />
                      <p>
                        <span className="payment-text-left">Order Date</span>
                        <span className="payment-text-right">
                          {formatDate(d)}
                        </span>
                      </p>

                      {coupon && (
                        <div>
                          <p>
                            <br />
                            <span className="payment-text-left">
                              Initial Cost
                            </span>
                            <span className="payment-text-right">
                              {/* {props.cost / 100} */}
                              {props.initialAmount}
                            </span>
                          </p>

                          <p>
                            <br />
                            <span className="payment-text-left">Discount</span>
                            <span className="payment-text-right">
                              {props.cost / 100}
                            </span>
                          </p>
                        </div>
                      )}
                      <br />
                    </div>
                    <hr />
                    <h5 style={{ fontWeight: "bold" }}>
                      <span className="payment-text-left">Total</span>
                      <span className="payment-text-right">
                        {props.cost / 100} /-
                      </span>
                    </h5>
                    <p
                      style={{ textAlign: "left" }}
                      className="payment-text-left"
                    >
                      Including GST
                    </p>
                    <h5>
                      <span className="payment-text-left">
                        {" "}
                        <a
                          style={{ borderRadius: "5px" }}
                          onClick={() => {
                            window.print();
                          }}
                          className="btn btn-block payment-btn-blue"
                        >
                          Print
                        </a>
                      </span>
                      <span className="payment-text-right">
                        {" "}
                        <a
                          style={{ borderRadius: "5px" }}
                          href="https://develop.d383ve0guq9s95.amplifyapp.com/#MOVEHERE"
                          className="btn btn-block payment-btn-blue"
                        >
                          Go to Course
                        </a>
                      </span>
                    </h5>
                    {/* <div style={{ display: "flex" }}>
                      {" "}
                      <div className="col-6">
                        <a
                          style={{ borderRadius: "5px" }}
                          href=""
                          className="btn btn-block payment-btn-blue"
                        >
                          Print
                        </a>
                      </div>
                      <div className="col-6">
                        <a
                          style={{ borderRadius: "5px" }}
                          href=""
                          className="btn btn-block payment-btn-blue"
                        >
                          Go to Course
                        </a>
                      </div>
                    </div> */}
                  </div>
                ) : (
                  <div className="row">
                    <div
                      onClick={props.onClose}
                      style={{
                        position: "absolute",
                        borderRadius: "50%",
                        top: "1%",
                        left: "85%",
                        width: "40px",
                        height: "40px",
                        background: "white",
                        // border: "1px solid black",
                      }}
                    >
                      <div
                        style={{
                          position: "relative",
                          bottom: "-12%",
                          width: " 100%",
                          textAlign: "center",
                        }}
                      >
                        <i className="fa fa-times fa-lg"></i>
                      </div>
                    </div>
                    <h5>
                      <span className="payment-text-left">Order Summary</span>
                      <span className="payment-text-right">53863</span>
                    </h5>
                    <hr />
                    <img
                      style={{ width: "25%" }}
                      src={tick_img}
                      alt="tick mark"
                      className="mx-auto d-block"
                    />
                    <br />
                    <div>
                      <h5 className="text-center">Payment Successful</h5>
                    </div>
                    <br />
                    <br />
                    <hr />
                    <div style={{ color: "black" }}>
                      <p>
                        <span className="payment-text-left">
                          Transaction Id
                        </span>
                        <span className="payment-text-right">52863</span>
                      </p>
                      <br />
                      <p>
                        <span className="payment-text-left">Customer Id</span>
                        <span className="payment-text-right">52863</span>
                      </p>
                      <br />
                      <p>
                        <span className="payment-text-left">Order Date</span>
                        <span className="payment-text-right">52863</span>
                      </p>
                      <br />
                      <p>
                        <span className="payment-text-left">Cost</span>
                        <span className="payment-text-right">52863</span>
                      </p>
                      <br />
                      <p>
                        <span className="payment-text-left">Discount</span>
                        <span className="payment-text-right">52863</span>
                      </p>
                      <br />
                    </div>

                    <hr />
                    <h5 style={{ fontWeight: "bold" }}>
                      <span
                        style={{ fontWeight: "bold" }}
                        className="payment-text-left"
                      >
                        Total
                      </span>
                      <span className="payment-text-right">9999/-</span>
                    </h5>
                    <p
                      style={{ textAlign: "left" }}
                      className="payment-text-left"
                    >
                      Including GST
                    </p>
                    <h5>
                      <span className="payment-text-left">
                        {" "}
                        <a
                          style={{ borderRadius: "5px" }}
                          onClick={() => {
                            window.print();
                          }}
                          className="btn btn-block payment-btn-blue"
                        >
                          Print
                        </a>
                      </span>
                      <span className="payment-text-right">
                        {" "}
                        <a
                          style={{ borderRadius: "5px" }}
                          // to="MOVEHERE"
                          href="https://develop.d383ve0guq9s95.amplifyapp.com/#MOVEHERE"
                          className="btn btn-block payment-btn-blue"
                        >
                          Go to Course
                        </a>
                      </span>
                    </h5>
                    {/* <div className="col-6">
                      <a
                        style={{ borderRadius: "5px" }}
                        href=""
                        className="btn btn-block payment-btn-blue"
                      >
                        Print
                      </a>
                    </div>
                    <div className="col-6">
                      <a
                        style={{ borderRadius: "5px" }}
                        href=""
                        className="btn btn-block payment-btn-blue"
                      >
                        Go to Course
                      </a>
                    </div> */}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
      </CSSTransition>
    </div>
  );
}

export default PP;

export const batchName = (name) => {
  return {
    type: "powerbi/batchName",
    payload: name,
  };
};

export const examType = (type) => {
  return {
    type: "powerbi/examType",
    payload: type,
  };
};

export const examDataState = (payload) => {
  return {
    type: "powerbi/examData",
    payload,
  };
};

import React, { createContext, useState } from 'react'

export const ModalContext = createContext()

function ModalContextProvider(props){

  const [open, setOpen] = useState(false)

  const update_status = (value) => {
    setOpen(value)

  }

  return (
    <ModalContext.Provider value={{ open, update_status }}>
      { props.children }
    </ModalContext.Provider>
  )
}

export default ModalContextProvider
import React from "react"
import Date_img from "../Assets/img/course/date_img.png";
import useMedia from "../componentsofcourse/useMedia";

const BatchStart = () => {
    const matches = useMedia('(min-width : 768px )' );
    return(
        <div style={{backgroundColor:'#00a2c8',width:"100%",padding:"0",margin:"0"}}>
                <div className="container-fluid">
                    <div className="row" style={{ marginLeft: matches ?  "20%": "0"}}>
                        <div className="col-lg-5 col-md-5 col-xs-12 col-sm-12 col-12">
                            <h2 style={{fontSize:'30px',fontWeight: "600", marginTop: "10%"}} className="text-white text-center">Batch Starts From</h2>
                        </div>
                        <div className="col-lg-5 col-md-5 col-xs-12 col-sm-12 d-flex justify-content-center" >
                            <img src={Date_img} className="img-fluid" style={{width: "30%",marginRight:matches?"140px":null}} />
                        </div>
                    </div>
                </div>
        </div>

    )
}
export default BatchStart;
import React, { useState, useEffect, useContext } from "react";
import { NavLink, Link } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ScrollspyNav from "react-scrollspy-nav";
import { BASE_URL } from "../env";
import axios from "axios";
import "../Assets/scss/CompanySpecific.scss";
import { AuthContext } from "../Context/AuthContext";
import { useParams } from "react-router-dom";
import EventsBanner from "../components/EventsBanner";
import HelmetComp from "../components/HelmetComp";
import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";

function CompanySpecific() {
  ReactGA.initialize("UA-198180460-1");
  const [tab, setTab] = useState(1);
  const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
  const optionss = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: false, // enable logs
  };
  const [showData, setshowData] = useState(true);
  const [specificData, setSpecificData] = useState({});
  const [allData, setAllData] = useState({});
  const [allowed, setallowed] = useState(false);
  const { email } = useContext(AuthContext);
  useEffect(() => {
    function CheckLoginOrNot() {
      if (localStorage.getItem("token") !== null) {
        return setallowed(true);
      } else {
        return setallowed(false);
      }
    }
    CheckLoginOrNot();
  }, []);
  // useEffect(() => {
  //   if (email !== "") {
  //     fetch(BASE_URL + "/getData/" + email, {
  //       method: "GET",
  //       headers: { "content-type": "application/json" },
  //     })
  //       .then((res) => res.json())
  //       .then((data) => {
  //         if (data.message == "Doesn't Exists!") {
  //           setallowed(false);
  //         } else {
  //           setallowed(true);
  //         }
  //       });
  //     return () => {};
  //   }
  // }, [email]);
  const [newDataId, setnewDataId] = useState("");
  const { id } = useParams();
  useEffect(() => {
    if (id === "616690234d0ae87be0c5c062" || id === "") {
      setshowData(true);
    }
  }, [id]);
  useEffect(() => {
    const CompanySpecificData = async () => {
      const res = await axios({
        method: "GET",
        url: `${BASE_URL}/getCompanyPage/${id}`,
      });
      setSpecificData(res.data.data[0]);
      setAllData(res.data);
    };

    CompanySpecificData();
  }, [newDataId, id]);

  useEffect(() => {
    ReactPixel.init("337209321107283", advancedMatching, optionss);
    ReactPixel.trackCustom(specificData.page_title);
  }, [specificData]);

  function replaceURLs(message) {
    if (!message) return;

    var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    return message.replace(urlRegex, function (url) {
      var hyperlink = url;
      if (!hyperlink.match("^https?://")) {
        hyperlink = "http://" + hyperlink;
      }
      return (
        '<a href="' +
        hyperlink +
        '" target="_blank" rel="noopener noreferrer">' +
        url +
        "</a>"
      );
    });
  }
  const LeftSideBar = () => {
    const Navigation = (props) => {
      return (
        <>
          <li>
            <NavLink
              to={props.link}
              className="companyLink"
              activeClassName="selectedname_companySpecific"
              onClick={() => {
                ReactGA.event({
                  category: "Left side bar Companies",
                  action: "Company Clicked",
                  label: props.name,
                });

                window.scroll(0, 0);

                // if(props.link)
                console.log(props);

                if (props.link.split("/company-specific/")[1].startsWith("6")) {
                  setshowData(true);
                } else {
                  setshowData(false);
                }
              }}
            >
              {props.name}
            </NavLink>
          </li>
        </>
      );
    };
    return (
      <>
        <div className="leftSideBar">
          <ul>
            {SpecificCompanies.map((val, index) => {
              return (
                <Navigation
                  ind={index}
                  key={index}
                  link={val.link}
                  name={val.name}
                />
              );
            })}
          </ul>
        </div>
      </>
    );
  };

  const TabData = () => {
    if (tab === 1) {
      return (
        <>
          <p className="text">{specificData?.section_7?.topics[0]?.content}</p>
          <ul>
            {specificData?.section_7?.topics[0]?.link.map((val) => {
              return <li className="text">{val}</li>;
            })}
          </ul>
          <p className="text">
            {specificData?.section_7?.topics[0]?.detail[0]} <br />
            {specificData?.section_7?.topics[0]?.detail[1]} <br />
            {specificData?.section_7?.topics[0]?.detail[2]} <br />
          </p>
        </>
      );
    } else if (tab === 2) {
      return (
        <>
          <p className="text">{specificData?.section_7?.topics[1]?.content}</p>
          <ul>
            {specificData?.section_7?.topics[1]?.link.map((val) => {
              return <li className="text">{val}</li>;
            })}
          </ul>
          <p className="text">
            {specificData?.section_7?.topics[1]?.detail[0]} <br />
            {specificData?.section_7?.topics[1]?.detail[1]} <br />
            {specificData?.section_7?.topics[1]?.detail[2]} <br />
          </p>
        </>
      );
    } else if (tab === 3) {
      return (
        <>
          <p className="text">{specificData?.section_7?.topics[2]?.content}</p>
          <ul>
            {specificData?.section_7?.topics[2]?.link.map((val) => {
              return <li className="text">{val}</li>;
            })}
          </ul>
          <p className="text">
            {specificData?.section_7?.topics[2]?.detail[0]} <br />
            {specificData?.section_7?.topics[2]?.detail[1]} <br />
            {specificData?.section_7?.topics[2]?.detail[2]} <br />
          </p>
        </>
      );
    } else {
      return (
        <>
          <p className="text">{specificData?.section_7?.topics[3]?.content}</p>
          <ul>
            {specificData?.section_7?.topics[3]?.link.map((val) => {
              return <li className="text">{val}</li>;
            })}
          </ul>
          <p className="text">
            {specificData?.section_7?.topics[3]?.detail[0]} <br />
            {specificData?.section_7?.topics[3]?.detail[1]} <br />
            {specificData?.section_7?.topics[3]?.detail[2]} <br />
          </p>
        </>
      );
    }
  };
  return (
    <>
      <HelmetComp
        info={{
          title: showData ? `${specificData.page_title}` : "",
          // description: '',
          // siteName: '',
          // summary: '',
          // pageUrl: '',
          // imagUrl: ''
        }}
      />
      {/* <Navbar /> */}
      <div id="companySpecificPage" className="container-fluid px-0">
        <div className="row">
          <div className="col-12 mb-0">
            <div className="banner">
              <h1 className="bannerHeading">
                {showData ? `${specificData.page_title}` : ""}
              </h1>
            </div>
          </div>
          <div className="col-lg-3">
            <LeftSideBar />
          </div>
          {showData ? (
            <>
              <div className="col-lg-6">
                <div
                  className="midSection"
                  data-bs-spy="scroll"
                  data-bs-target="#rightSideBar"
                  data-bs-offset="200"
                  tabindex="0"
                >
                  <section
                    id="section1"
                    onClick={() => {
                      ReactGA.event({
                        category: specificData?.page_title,
                        action: "Section reached",
                        label: specificData?.section_1?.heading,
                      });
                    }}
                  >
                    <div className="heading pt-0">
                      {specificData?.section_1?.heading}
                    </div>
                    <p className="text">{specificData?.section_1?.body}</p>
                    {allowed ? (
                      <iframe
                        className="video"
                        onClick={() => {
                          ReactGA.event({
                            category: specificData?.page_title,
                            action: "Video Clicked",
                            label: specificData?.section_1?.heading,
                            value: specificData?.section_1?.link.split("/")[3],
                          });
                        }}
                        src={`https://www.youtube.com/embed/${
                          specificData?.section_1?.link.split("/")[3]
                        }`}
                        // src="https://youtu.be/pHhXK0_mNJQ"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    ) : (
                      <Link
                        to={{
                          pathname: "/register",
                          state: { from: "Capgemini" },
                        }}
                      >
                        <div
                          className="video"
                          onClick={() => {
                            ReactGA.event({
                              category: specificData?.page_title,
                              action: "Video signup Clicked",
                              label: specificData?.section_1?.heading,
                              value:
                                specificData?.section_1?.link.split("/")[3],
                            });
                          }}
                          style={{
                            backgroundImage: `url(${specificData?.section_1?.imgUrl})`,
                          }}
                        >
                          <i class="fas fa-play"></i>
                        </div>
                      </Link>
                    )}
                  </section>
                  <section
                    id="section2"
                    onClick={() => {
                      ReactGA.event({
                        category: specificData?.page_title,
                        action: "Section reached",
                        label: specificData?.section_2?.heading,
                      });
                    }}
                  >
                    <div className="heading">
                      {specificData?.section_2?.heading}
                    </div>
                    <p
                      className="text"
                      dangerouslySetInnerHTML={{
                        __html: replaceURLs(specificData?.section_2?.body),
                      }}
                    ></p>
                    {allowed ? (
                      <iframe
                        className="video"
                        onClick={() => {
                          ReactGA.event({
                            category: specificData?.page_title,
                            action: "Video Clicked",
                            label: specificData?.section_2?.heading,
                            value: specificData?.section_2?.link.split("/")[3],
                          });
                        }}
                        src={`https://www.youtube.com/embed/${
                          specificData?.section_2?.link.split("/")[3]
                        }`}
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    ) : (
                      <Link
                        to={{
                          pathname: "/register",
                          state: { from: "Capgemini" },
                        }}
                      >
                        <div
                          className="video"
                          onClick={() => {
                            ReactGA.event({
                              category: specificData?.page_title,
                              action: "Video signup Clicked",
                              label: specificData?.section_2?.heading,
                              value:
                                specificData?.section_2?.link.split("/")[3],
                            });
                          }}
                          style={{
                            backgroundImage: `url("${specificData?.section_2?.imgUrl}")`,
                          }}
                        >
                          <i class="fas fa-play"></i>
                        </div>
                      </Link>
                    )}
                  </section>
                  <section
                    id="section3"
                    onClick={() => {
                      ReactGA.event({
                        category: specificData?.page_title,
                        action: "Section reached",
                        label: specificData?.section_3?.heading,
                      });
                    }}
                  >
                    <div className="heading">
                      {specificData?.section_3?.heading}
                    </div>

                    {specificData?.section_3?.cards.map((value) => {
                      return (
                        <>
                          <div className="roleCard">
                            <div className="head">{value.heading}</div>
                            <div className="text">{value?.body}</div>
                          </div>
                        </>
                      );
                    })}
                    {/* <div className="head">
                        {specificData?.section_3?.cards[0]?.heading}
                      </div>
                      <div className="text">
                        {specificData?.section_3?.cards[0]?.body}
                      </div>
                     */}
                  </section>
                  <section
                    id="section4"
                    onClick={() => {
                      ReactGA.event({
                        category: specificData?.page_title,
                        action: "Section reached",
                        label: specificData?.section_4?.heading,
                      });
                    }}
                  >
                    <div className="heading">
                      {specificData?.section_4?.heading}
                    </div>
                    <ul>
                      {specificData?.section_4?.bullets.map((val, ind) => {
                        return (
                          <li className="text" key={ind}>
                            {val.point}
                          </li>
                        );
                      })}
                    </ul>
                  </section>
                  <section
                    id="section5"
                    onClick={() => {
                      ReactGA.event({
                        category: specificData?.page_title,
                        action: "Section reached",
                        label: specificData?.section_1?.heading,
                      });
                    }}
                  >
                    <div className="heading">
                      {specificData?.section_5?.heading}
                    </div>
                    <ul>
                      {specificData?.section_5?.cards.map((val) => {
                        return (
                          <>
                            <li className="text">
                              {val?.heading} - {val?.body}
                            </li>
                          </>
                        );
                      })}
                    </ul>
                  </section>
                  <EventsBanner />
                  <section
                    id="section6"
                    onClick={() => {
                      ReactGA.event({
                        category: specificData?.page_title,
                        action: "Section reached",
                        label: specificData?.section_6?.heading,
                      });
                    }}
                  >
                    <div className="heading">
                      {specificData?.section_6?.heading}
                    </div>
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            {specificData?.section_6?.cards.map((val) => {
                              return (
                                <th className="tableHead">{val?.body[0]}</th>
                              );
                            })}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            {specificData?.section_6?.cards.map((val) => {
                              return (
                                <td className="tableText">{val?.body[1]}</td>
                              );
                            })}
                          </tr>
                          <tr>
                            {specificData?.section_6?.cards.map((val) => {
                              return (
                                <td className="tableText">{val?.body[2]}</td>
                              );
                            })}
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </section>
                  <section
                    id="section7"
                    onClick={() => {
                      ReactGA.event({
                        category: specificData?.page_title,
                        action: "Section reached",
                        label: specificData?.section_7?.heading,
                      });
                    }}
                  >
                    <div className="heading">
                      {specificData?.section_7?.heading}
                    </div>
                    <div className="row">
                      <div className="col-12 navigationBar">
                        <ul>
                          <li
                            onClick={() => setTab(1)}
                            className={
                              tab === 1 ? "eventActive" : "eventInactive"
                            }
                          >
                            {specificData?.section_7?.topics[0]?.topic_heading}
                          </li>
                          <li
                            onClick={() => setTab(2)}
                            className={
                              tab === 2 ? "eventActive" : "eventInactive"
                            }
                          >
                            {specificData?.section_7?.topics[1]?.topic_heading}
                          </li>
                          <li
                            onClick={() => setTab(3)}
                            className={
                              tab === 3 ? "eventActive" : "eventInactive"
                            }
                          >
                            {specificData?.section_7?.topics[2]?.topic_heading}
                          </li>
                          <li
                            onClick={() => setTab(4)}
                            className={
                              tab === 4 ? "eventActive" : "eventInactive"
                            }
                          >
                            {specificData?.section_7?.topics[3]?.topic_heading}
                          </li>
                        </ul>
                      </div>
                      <div className="col-12 mb-0">
                        <TabData />
                      </div>
                      <div className="col-12 mb-0">
                        <p
                          className="clickableLink"
                          onClick={() => window.scroll(0, 0)}
                        >
                          {specificData?.lines?.line1?.line}
                          <NavLink
                            to={
                              specificData?.lines?.line1?.link ||
                              "/newcoursepage"
                            }
                          >
                            {" "}
                            Click here
                          </NavLink>
                        </p>
                      </div>
                    </div>
                  </section>

                  <section
                    id="section8"
                    onClick={() => {
                      ReactGA.event({
                        category: specificData?.page_title,
                        action: "Section reached",
                        label: specificData?.section_8?.heading,
                      });
                    }}
                  >
                    <div className="heading">
                      {specificData?.section_8?.heading}
                    </div>
                    <p className="text">{specificData?.section_8?.content}</p>
                    <ul>
                      {specificData?.section_8?.bullets.map((val) => {
                        return (
                          <li
                            className="text"
                            dangerouslySetInnerHTML={{
                              __html: replaceURLs(val),
                            }}
                          ></li>
                        );
                      })}
                    </ul>
                    <p
                      className="clickableLink"
                      onClick={() => window.scroll(0, 0)}
                    >
                      {specificData?.lines?.line2?.line}
                      {/* Have a brief idea how to prepare for the interview, */}
                      <NavLink
                        to={
                          specificData?.lines?.line2?.link ||
                          "/blogs/cantilever_labs_hows_to_speak_about_strength_and_weaknesses_in_interview"
                        }
                      >
                        &nbsp;Click here
                      </NavLink>
                    </p>
                    {allowed ? (
                      <iframe
                        className="video"
                        src={`https://www.youtube.com/embed/${
                          specificData?.section_8?.link.split("/")[3]
                        }`}
                        // src="https://www.youtube.com/embed/uPZ9Gwyk2so"
                        onClick={() => {
                          ReactGA.event({
                            category: specificData?.page_title,
                            action: "Video Clicked",
                            label: specificData?.section_8?.heading,
                            value: specificData?.section_8?.link.split("/")[3],
                          });
                        }}
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    ) : (
                      <Link
                        to={{
                          pathname: "/register",
                          state: { from: "Capgemini" },
                        }}
                      >
                        <div
                          className="video"
                          onClick={() => {
                            ReactGA.event({
                              category: specificData?.page_title,
                              action: "Video signup Clicked",
                              label: specificData?.section_8?.heading,
                              value:
                                specificData?.section_8?.link.split("/")[3],
                            });
                          }}
                          style={{
                            backgroundImage: `url(${specificData?.section_8?.imgUrl})`,
                          }}
                        >
                          <i class="fas fa-play"></i>
                        </div>
                      </Link>
                    )}
                  </section>
                  <section
                    id="section9"
                    onClick={() => {
                      ReactGA.event({
                        category: specificData?.page_title,
                        action: "Section reached",
                        label: specificData?.section_9?.heading,
                      });
                    }}
                  >
                    <div className="heading">
                      {" "}
                      {specificData?.section_9?.heading}
                    </div>
                    <div className="row">
                      <div className="col-md-4 col-sm-6">
                        <div className="cardSet">
                          <div className="heading">
                            {specificData?.section_9?.cards[0].content}
                          </div>
                          <ul>
                            {specificData?.section_9?.cards[0].bullets.map(
                              (val) => {
                                return <li>{val}</li>;
                              }
                            )}
                          </ul>
                          <div
                            className="readBtn"
                            onClick={() => {
                              ReactGA.event({
                                category: specificData?.page_title,
                                action: "Card Clicked",
                                label: specificData?.section_9?.heading,
                                value:
                                  specificData?.section_9?.cards[0].content,
                              });
                            }}
                          >
                            {allowed ? (
                              <a
                                target={allowed ? "blank" : ""}
                                href={specificData?.section_9?.cards[0]?.link}
                              >
                                Read more
                              </a>
                            ) : (
                              <Link
                                to={{
                                  pathname: "/register",
                                  state: { from: "Capgemini" },
                                }}
                              >
                                Read more
                              </Link>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <div className="cardSet">
                          <div className="heading">
                            {specificData?.section_9?.cards[1]?.content}
                          </div>
                          <ul>
                            {specificData?.section_9?.cards[1]?.bullets.map(
                              (val) => {
                                return <li>{val}</li>;
                              }
                            )}
                          </ul>
                          <div
                            className="readBtn"
                            onClick={() => {
                              ReactGA.event({
                                category: specificData?.page_title,
                                action: "Card Clicked",
                                label: specificData?.section_9?.heading,
                                value:
                                  specificData?.section_9?.cards[1].content,
                              });
                            }}
                          >
                            {allowed ? (
                              <a
                                target={allowed ? "blank" : ""}
                                href={specificData?.section_9?.cards[1]?.link}
                              >
                                Read more
                              </a>
                            ) : (
                              <Link
                                to={{
                                  pathname: "/register",
                                  state: { from: "Capgemini" },
                                }}
                              >
                                Read more
                              </Link>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <div className="cardSet">
                          <div className="heading">
                            {specificData?.section_9?.cards[2]?.content}
                          </div>
                          <ul>
                            {specificData?.section_9?.cards[2]?.bullets.map(
                              (val) => {
                                return <li>{val}</li>;
                              }
                            )}{" "}
                          </ul>
                          <div
                            className="readBtn"
                            onClick={() => {
                              ReactGA.event({
                                category: specificData?.page_title,
                                action: "Card Clicked",
                                label: specificData?.section_9?.heading,
                                value:
                                  specificData?.section_9?.cards[0].content,
                              });
                            }}
                          >
                            {allowed ? (
                              <a
                                target={allowed ? "blank" : ""}
                                href={specificData?.section_9?.cards[2]?.link}
                              >
                                Read more
                              </a>
                            ) : (
                              <Link
                                to={{
                                  pathname: "/register",
                                  state: { from: "Capgemini" },
                                }}
                              >
                                Read more
                              </Link>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
              <div className="col-lg-3">
                <RightSideBar />
              </div>
            </>
          ) : (
            <div className="d-flex justify-content-center col-lg-9 ">
              <lottie-player
                src="https://assets6.lottiefiles.com/packages/lf20_m6cuL6.json"
                background="transparent"
                speed="1"
                style={{ width: "600px", height: "250px" }}
                loop
                autoplay
              ></lottie-player>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}

const SpecificCompanies = [
  {
    link: "/company-specific/61c598567a4a44b80a3e4cb9",
    name: "Wipro (Hiring now)",
  },
  {
    link: "/company-specific/616690234d0ae87be0c5c062",
    name: "Capgemini",
  },
  {
    link: "/company-specific/6179424a8b184861a7b937eb",
    name: "Infosys",
  },
  {
    link: "/company-specific/Cognizant",
    name: "Cognizant",
  },
  {
    link: "/company-specific/Accenture",
    name: "Accenture",
  },
  {
    link: "/company-specific/IBM",
    name: "IBM",
  },
  {
    link: "/company-specific/Amazon",
    name: "Amazon",
  },
  {
    link: "/company-specific/Tech Mahindra",
    name: "Tech Mahindra",
  },
  {
    link: "/company-specific/HCL",
    name: "HCL",
  },

  {
    link: "/company-specific/Mindtree",
    name: "Mindtree",
  },
  {
    link: "/company-specific/Microsoft",
    name: "Microsoft",
  },
  {
    link: "/company-specific/Goldman Sachs",
    name: "Goldman Sachs",
  },
];

const PageData = [
  {
    desc: "Description",
    link: "#section1",
  },
  {
    desc: "Hiring Process",
    link: "#section2",
  },
  {
    desc: "Roles & CTC",
    link: "#section3",
  },
  {
    desc: "Eligibility",
    link: "#section4",
  },
  {
    desc: "Rounds",
    link: "#section5",
  },
  {
    desc: "Exam Pattern",
    link: "#section6",
  },
  {
    desc: "Syllabus",
    link: "#section7",
  },
  {
    desc: "Interview",
    link: "#section8",
  },
  {
    desc: "Previous Years Sample Papers",
    link: "#section9",
  },
];

const RightSideBar = () => {
  const Navigation = (props) => {
    return (
      <>
        <ScrollspyNav
          scrollTargetIds={[
            "section1",
            "section2",
            "section3",
            "section4",
            "section5",
            "section6",
            "section7",
            "section8",
            "section9",
          ]}
          offset={-250}
          activeNavClass="is-active"
        >
          <li className="pageDescription">
            <a href={props.link}>{props.desc}</a>
          </li>
        </ScrollspyNav>
      </>
    );
  };
  return (
    <>
      <div className="rightSideBar" id="rightSideBar">
        <ul>
          {PageData.map((val, index) => {
            return <Navigation key={index} desc={val.desc} link={val.link} />;
          })}
        </ul>
      </div>
    </>
  );
};

export default CompanySpecific;

import React from "react";
import Assistance from "../Assets/img/Competitive/Assistance.png";
import useMedia from "../componentsofcourse/useMedia";
function CPCourseImages() {
  const matches = useMedia("(min-width : 768px )");
  return (
    <div
      className="container-fluid mt-5"
      style={{ backgroundColor: "#00a2c8" }}
    >
      {matches ? <br /> : null}
      <div className="row">
        <div className="col-md-4 col-xs-12 col-12 col-12">
          <img
            src={Assistance}
            className="img-fluid"
            style={{ marginTop: "-50px", marginBottom: "-20px" }}
          />
        </div>
        <div className="col-md-5 col-xs-12 col-12 col-12">
          <br />
          <br />
          <h1 style={{ color: "white", fontFamily: "Poppins-SemiBold" }}>
            100% Job Assistance
          </h1>
          <br />
          <a
            href="#ptable"
            style={{
              fontSize: "20px",
              borderRadius: "25px",
              width: "40%",
              fontFamily: "Poppins-Bold",
              marginBottom: matches == false ? "10%" : "10%",
            }}
            className="btn btn-warning text-white p-2"
          >
            Enroll Now
          </a>
        </div>
      </div>
    </div>
  );
}

export default CPCourseImages;

import CompaniesCards from "./CompaniesCards";
import "../Assets/css/DiffCards.scss";
function CompaniesSection({ category }) {
  return (
    <div className="row diffcultyCards">
      {category &&
        category.map((val) => {
          return (
            <CompaniesCards
              key={val._id}
              link={`/compiler/${val._id}/16`}
              name={val.name}
              category={val.category}
              level={val.questionWeigh}
              company={val.company ? val.company : []}
            />
          );
        })}
    </div>
  );
}

export default CompaniesSection;

import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
// import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Tilty from "react-tilty";
import "../Assets/css/bookExpert.css";
import Front from "../Assets/img/expert-advice/front.png";
import Back from "../Assets/img/expert-advice/back.png";
import Img1 from "../Assets/img/expert-advice/category.svg";
import Img2 from "../Assets/img/expert-advice/enquiry.svg";
import Img3 from "../Assets/img/expert-advice/pig.svg";
import Expert1 from "../Assets/img/expert-advice/expert-1.png";
import Profile from "../Assets/img/expert-advice/profile-img.png";
import Group from "../Assets/img/expert-advice/group.png";
import Star from "../Assets/img/expert-advice/star.png";
import Google from "../Assets/img/expert-advice/google.png";
import Instagram from "../Assets/img/expert-advice/instagram.png";
import LinkedIn from "../Assets/img/expert-advice/linkedin.png";
import CompanyLogo from "../Assets/img/expert-advice/company-logo.png";
import Down1 from "../Assets/img/expert-advice/down-1.png";
import Down2 from "../Assets/img/expert-advice/down-2.png";
import Search from "../Assets/img/expert-advice/search.png";
import Question from "../Assets/img/expert-advice/question.png";
import Pay from "../Assets/img/expert-advice/pay.png";
import { Button } from "@mui/material";
import { BASE_URL } from "../env";
import AOS from "aos";
// import axios from "axios";

function BookExpert() {
  const [mentors, setMentors] = useState([]);
  useEffect(() => {
    AOS.init({
      offset: 50,
      duration: 800,
      easing: "ease-in-sine",
    });
  }, []);
  useEffect(() => {
    fetch(BASE_URL + "/getMentors")
      .then((res) => res.json())
      .then((data) => {
        setMentors(data.mentors);
        // console.log(data.mentors);
      });
  });
  return (
    <>
      {/* Navbar */}
      {/* <Navbar /> */}

      {/* Header Section */}
      <header id="expert-page">
        <div className="container py-5">
          <div className="row">
            <div className="col-md-10 col-lg-7 d-flex justify-content-center flex-column">
              <h2 className="expert-head-heading">expert online</h2>
              <ul className="expert-head-list">
                <li>
                  <strong>Online Consultation</strong> — ask a question from any
                  specialist online in minutes. Ask professionals on the go,
                  wherever you are. Choose by language.
                </li>
                <li>
                  <strong>Get Free Quote</strong> — post your Enquiry to many
                  specialists and they can get in touch with competitive prices
                  and an explanation of how they can help.
                </li>
              </ul>
              <p className="expert-head-text">I would like to…</p>
              <div className="expert-head-btn">
                <Button className="btn-first">consult online</Button>
                <Button className="btn-second">Get free quotes</Button>
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* How It Work Section */}
      <section className="how-work-section">
        <div className="container py-5">
          <div className="row">
            <div className="col-12">
              <div className="how-work-section-heading my-4">How it works?</div>
            </div>
            <div className="col-lg-5 how-work-section-img">
              <img src={Back} className="img-fluid how-back" alt="" />
              <Tilty
                className="tilty"
                axis="X"
                perspective="800"
                speed="3000"
                max="0"
                scale="1.06"
              >
                <img src={Front} className="img-fluid how-front" alt="" />
              </Tilty>
            </div>
            <div className="col-lg-5 offset-lg-2 offset-sm-0 offset-1">
              <div className="row">
                <div className="col-sm-9" data-aos="fade-up">
                  <Work
                    heading="Find an Expert"
                    imgsrc={Search}
                    desc="Select by category and language, which specialist you are sending your Question to."
                  />
                </div>
                <div className="col-sm-3" data-aos="fade-up">
                  <img
                    src={Down1}
                    className="img-fluid down-arrow-img"
                    alt="down arrow"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-3 order-sm-1 order-2" data-aos="fade-up">
                  <img
                    src={Down2}
                    className="img-fluid down-arrow-img"
                    alt="down arrow"
                  />
                </div>
                <div className="col-sm-9 order-sm-2 order-1" data-aos="fade-up">
                  <Work
                    heading="Send a Question"
                    imgsrc={Question}
                    desc="Describe in detail the task or problem you are dealing with."
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-9" data-aos="fade-up">
                  <Work
                    heading="Pay for a Service"
                    imgsrc={Pay}
                    desc="Pay safely and our Expert will contact you in minutes."
                  />
                </div>
                <div className="col-sm-3"></div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Cards Section */}
      <section className="card-section">
        <div className="container py-5">
          <div className="row">
            <div className="col-12 mb-5">
              <div className="card-section-heading">Our Mentors</div>
            </div>
            <Card
              title="Initial Consultation"
              subtitle="We will send your Question to multiple Experts and you will be
              contacted by the best one for your case within minutes."
              price="£25"
              desc="Consult online (by phone or email)"
              btn="Book now"
            />
            <Card
              title="Get a Quote"
              subtitle="Post your Enquiry to many specialists and they can get in touch with competitive prices and an explanation of how they can help."
              price="Free"
              desc="Choose specialist by the best price in minutes"
              btn="Send now"
            />
            <Card
              title="Choose the Expert"
              subtitle="You can find Experts available on our site lookout at services they offer and make a direct booking. Choose by category, price and reviews."
              price="Free"
              desc="Post your Enquiry to your chosen service supplier and get in touch"
              btn="Start now"
            />
          </div>
        </div>
      </section>

      {/* Expert Section */}
      <section className="expert-card">
        <div className="container py-5">
          <div className="row">
            <div className="col-12 mb-5">
              <div className="expert-cards-heading">Check our Mentors</div>
            </div>
          </div>
          <div className="row">
            {mentors
              .map((mentor) => {
                return (
                  <>
                    <div className="col-lg-4 col-md-6" data-aos="fade-up">
                      <ExpertCard data={mentor} />
                    </div>
                  </>
                );
              })
              .slice(0, 3)}
          </div>
          <div className="row">
            <div className="col-12">
              <NavLink
                to="/experts"
                className="expert-section-explore-more-btn"
                onClick={() => window.scroll(0, 0)}
                data-aos="fade-right"
              >
                Explore more {">>"}
              </NavLink>
            </div>
          </div>
        </div>
      </section>

      {/* Benefits Section */}
      <section className="benefit-section">
        <div className="container py-5">
          <div className="row">
            <div className="col-12">
              <div className="benefit-section-heading-why">Why us?</div>
            </div>
            <div className="col-12 benefit-section-circle-head">
              <div className="benefit-section-circle"></div>
            </div>
            <div className="col-md-6"></div>
            <div className="col-md-6 benefit-section-card-first">
              <div className="benefit-section-card-heading">
                Problem solving
              </div>
              <div className="benefit-section-card-text">
                Initial consultations are intended for those who do not know
                where to start to solve the problem. Whether it's an
                immigration, buying/selling a house, criminal defence or traffic
                law.
              </div>
            </div>
            <div className="col-12 benefit-section-circle-head">
              <div className="benefit-section-circle"></div>
            </div>
            <div className="col-md-6 benefit-section-card-second">
              <div className="benefit-section-card-heading">Cheap</div>
              <div className="benefit-section-card-text">
                This is the cheapest way to get an advice compared to an expert
                office consultation, which costs an average of £300 plus travel
                and queuing costs.
              </div>
            </div>
            <div className="col-md-6"></div>
            <div className="col-12 benefit-section-circle-head">
              <div className="benefit-section-circle"></div>
            </div>
            <div className="col-md-6"></div>
            <div className="col-md-6 benefit-section-card-third">
              <div className="benefit-section-card-heading">Fast</div>
              <div className="benefit-section-card-text">
                It is the fastest way to get an advice.Book online within
                minutes at home, at work or wherever you are.
              </div>
            </div>
            <div className="col-12 benefit-section-btn">
              <Button href="" className="benefit-section-book-appointment-btn">
                book appointment
              </Button>
            </div>
          </div>
        </div>
      </section>

      {/* Get Free Quote Section */}
      <section className="free-quote-section">
        <div className="container my-5">
          <div className="row">
            <div className="col-12">
              <div className="free-quote-section-heading my-4">
                Get Free Quote
              </div>
              <div className="free-quote-section-text mb-5">
                Send your Enquiry to multiple specialists and they can get in
                touch with a Quote.
              </div>
            </div>
            <QuoteDesc
              imgsrc={Img1}
              header="Choose Category"
              desc="Select by category, country or language, which specialist you are sending your price enquiry to."
            />
            <QuoteDesc
              imgsrc={Img2}
              header="Send an Enquiry"
              desc="It's always free to send an Enquiry. Describe in detail the task or problem you are dealing with."
            />
            <QuoteDesc
              imgsrc={Img3}
              header="Get Quote"
              desc="Get as many quotes as you want from the experts you choose. Compare prices, choose the best offer and save money."
            />
            <div className="col-12 my-5 d-flex justify-content-center">
              <Button className="free-quote-section-send-btn">Send now</Button>
            </div>
          </div>
        </div>
      </section>

      {/* Clients Section */}
      <section className="clients-section">
        <div className="container py-5">
          <div className="row">
            <div className="col-12">
              <div className="clients-section-heading my-4">
                Our clients love us
              </div>
            </div>
            <Client
              imgsrc={Expert1}
              desc="Lorem ipsum dolor sit amet consectetur adipisicing elit. Ex
              voluptatem quibusdam vitae? Officiis vero laborum quam rerum ea
              temporibus quas voluptas expedita numquam tenetur, a ratione
              voluptatem natus velit, quis aspernatur consequuntur, optio veniam
              eveniet earum."
              name="Lorem, ipsum"
              post="Lorem ipsum dolor sit amet."
            />
            <Client
              imgsrc={Expert1}
              desc="Lorem ipsum dolor sit amet consectetur adipisicing elit. Ex
              voluptatem quibusdam vitae? Officiis vero laborum quam rerum ea
              temporibus quas voluptas expedita numquam tenetur, a ratione
              voluptatem natus velit, quis aspernatur consequuntur, optio veniam
              eveniet earum."
              name="Lorem, ipsum"
              post="Lorem ipsum dolor sit amet."
            />
            <Client
              imgsrc={Expert1}
              desc="Lorem ipsum dolor sit amet consectetur adipisicing elit. Ex
              voluptatem quibusdam vitae? Officiis vero laborum quam rerum ea
              temporibus quas voluptas expedita numquam tenetur, a ratione
              voluptatem natus velit, quis aspernatur consequuntur, optio veniam
              eveniet earum."
              name="Lorem, ipsum"
              post="Lorem ipsum dolor sit amet."
            />
            <Client
              imgsrc={Expert1}
              desc="Lorem ipsum dolor sit amet consectetur adipisicing elit. Ex
              voluptatem quibusdam vitae? Officiis vero laborum quam rerum ea
              temporibus quas voluptas expedita numquam tenetur, a ratione
              voluptatem natus velit, quis aspernatur consequuntur, optio veniam
              eveniet earum."
              name="Lorem, ipsum"
              post="Lorem ipsum dolor sit amet."
            />
          </div>
        </div>
      </section>

      {/* Subscribe Section */}
      <SubscribeSection />

      <Footer />
    </>
  );
}

/*  Component for How It Work Section  */
const Work = (props) => {
  return (
    <>
      <div class="how-work-section-card">
        <div className="heading">{props.heading}</div>
        <div className="inner-card">
          <img
            src={props.imgsrc}
            className="img-fluid how-work-section-img"
            alt="work desc"
          />
          <div className="desc">{props.desc}</div>
        </div>
      </div>
    </>
  );
};

/*  Component for Card  */
const Card = (props) => {
  return (
    <>
      <div className="col-lg-4" data-aos="fade-up">
        <div className="card-boxwrapper" data-aos="fade-in">
          <div className="title">{props.title}</div>
          <div className="subtitle">{props.subtitle}</div>
          <div className="price">{props.price}</div>
          <div className="desc">{props.desc}</div>
          <Button className="btn">{props.btn}</Button>
        </div>
      </div>
    </>
  );
};

/*  Component for Expert Card  */
const ExpertCard = (props) => {
  return (
    <>
      <Tilty max="0" scale="1.03">
        <div className="expert-boxwrapper pb-0" data-aos="fade-in">
          <div className="banner">
            <img src={Profile} className="img-fluid" alt="experts profile" />
          </div>
          <div className="name mt-4">{props.data.fullName}</div>
          <div className="post">{props.data.currentDesignation}</div>
          <div className="company">
            <img src={CompanyLogo} className="img-fluid" alt="company logo" />
          </div>
          <div className="mid py-1" style={{ transform: "scale(0.8)" }}>
            <div className="left-corner">
              <img src={Group} clasName="img-fluid" alt="group icon" />
              200+ <br />
              <span>student mentored</span>
            </div>
            <div className="right-corner">
              <img src={Star} clasName="img-fluid" alt="group icon" />
              4.5 <br />
              <span>200+ reviews</span>
            </div>
          </div>
          <div className="expertise">Expertise</div>
          <ul>
            <li>resume building</li>
            <li>career planning</li>
            <li>entrance test</li>
            <li>domain knowledge</li>
            <li>cv building</li>
          </ul>
          <div className="helping">Helping you get placed in :</div>
          <div className="companies py-0" style={{ paddingBottom: "0px" }}>
            <img src={Google} className="img-fluid" alt="company icon" />
            <img src={Instagram} className="img-fluid" alt="company icon" />
            <img src={LinkedIn} className="img-fluid" alt="company icon" />
          </div>
          <hr />
          <div className="foot">
            <div className="left">
              Starting from <br />
              <span>17 / minute</span>
            </div>
            <div className="right">
              <NavLink
                to={`/expert-profile/${props.data._id}`}
                className="connect"
                onClick={() => {
                  window.scroll(0, 0);
                }}
              >
                Connect
              </NavLink>
            </div>
          </div>
        </div>
      </Tilty>
    </>
  );
};

/*  Component for Quote Desc  */
const QuoteDesc = (props) => {
  return (
    <>
      <div class="col-md-4 quote-section-card p-md-4 p-3" data-aos="fade-up">
        <img src={props.imgsrc} width="80" />
        <div class="header">{props.header}</div>
        <div class="desc">{props.desc}</div>
      </div>
    </>
  );
};

/*  Component for Client  */
const Client = (props) => {
  return (
    <>
      <div className="col-md-6 my-4">
        <div className="row client-boxwrapper" data-aos="fade-up">
          <div className="col-3">
            <img
              src={props.imgsrc}
              className="img-fluid"
              alt="client showcase"
            />
          </div>
          <div className="col-9">
            <div className="desc">{props.desc}</div>
            <div className="name">{props.name}</div>
            <div className="post">{props.post}</div>
          </div>
        </div>
      </div>
    </>
  );
};

/*  Component for Subscribe Section  */
const SubscribeSection = () => {
  const [data, setData] = useState({
    email: "",
  });

  const formSubmit = (e) => {
    e.preventDefault();
    alert(`Thank you for subscribe`);
  };
  return (
    <>
      <section className="subscribe-section py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mb-0">
              <p className="subscribe-section-text">
                Don't miss the latest news. Subscribe to our newsletter!
              </p>
            </div>
            <div className="col-md-6 mb-0">
              <form onSubmit={formSubmit} className="subscribe-section-form">
                <input
                  type="email"
                  className="input-field"
                  name="email"
                  required
                  value={data.email}
                  onChange={(e) => {
                    setData({ email: e.target.value });
                  }}
                  placeholder="Enter your email"
                />
                <Button type="submit" className="btn-subscribe">
                  Subscribe
                </Button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BookExpert;
export { ExpertCard, SubscribeSection };

import React, { useContext, useState, useEffect } from "react";
import style from "../Assets/css/Iteration.module.css";
import useMedia from "../componentsofcourse/useMedia";
import i1 from "../Assets/img/Iteration/i1.png";
import i2 from "../Assets/img/Iteration/i2.png";
import i3 from "../Assets/img/Iteration/i3.png";
import tick from "../Assets/img/Iteration/tick_icon.png";
import grey_1 from "../Assets/img/Iteration/grey_1.png";
import grey_2 from "../Assets/img/Iteration/grey_2.png";
import download from "../Assets/img/Iteration/download.png";
// import { AuthContext } from "../Context/AuthContext";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import ReactGA from "react-ga";
import { Redirect } from "react-router-dom";
import TCS from "../Assets/img/Iteration/TCS.png";
import Wipro from "../Assets/img/Iteration/Wipro.png";
import Inf from "../Assets/img/Iteration/Infosys.png";
import Cog from "../Assets/img/Iteration/Cognizant.png";
import HCL from "../Assets/img/Iteration/HCL.png";
import Gemini from "../Assets/img/Iteration/Capgemini.png";

import Facebook from "../Assets/img/Iteration/Facebook.png";
import Amazon from "../Assets/img/Iteration/Amazon.png";
import Flipkart from "../Assets/img/Iteration/Flipkart.png";
import Microsoft from "../Assets/img/Iteration/Microsoft.png";
import Netflix from "../Assets/img/Iteration/Netflix.png";
import Google from "../Assets/img/Iteration/Google.png";

import Coupons1 from "../components/NewCoupons.js";
import Coupons2 from "../components/NewCoupons.js";
import Coupons3 from "../components/NewCoupons.js";
import Coupons4 from "../components/NewCoupons.js";

import { AuthContext } from "../Context/AuthContext";
import { BASE_URL, FASTTRACK, PREMIUM, PRO, STANDARD } from "../env";
import { useHistory, useLocation } from "react-router-dom";

// import

const Iteration = () => {
	ReactGA.initialize("UA-198180460-1");
	let loaderT1 = false;
	let loaderT2 = false;
	let loaderT3 = false;
	let loaderT4 = false;
	const [Email, setEmail] = useState("");
	const [phone, setphone] = useState("");
	const [name, setname] = useState("");
	const [userId, setuserID] = useState("");
	useEffect(() => {
		fetch(BASE_URL + "/getProfile", {
			headers: { authorization: "Bearer " + localStorage.getItem("token") },
		})
			.then((res) => res.json())
			.then((data) => {
				if (data.error) {
				} else {
					setEmail(data.user.email);
					setphone(data.user.mobileNumber);
					setname(data.user.firstName);
					setuserID(data.user._id);
				}
			});
	}, []);

	const history = useHistory();
	const location = useLocation();

	// const [showTier1, setShowTier1] = useState(false);
	// const [showTier2, setShowTier2] = useState(false);
	// const [showTier3, setShowTier3] = useState(false);
	// const [showTier4, setShowTier4] = useState(false);

	const [loadT1, setLoadT1] = useState(false);
	const [loadT2, setLoadT2] = useState(false);
	const [loadT3, setLoadT3] = useState(false);
	const [loadT4, setLoadT4] = useState(false);

	let { token, email } = useContext(AuthContext);
	const [signIn, setSignIn] = useState(false);
	const [coupon, setCoupon] = useState(false);
	const [tierId, setTierId] = useState("");
	const [amount, setAmount] = useState("");
	let [course, setCourse] = useState("");
	let [tier, setTier] = useState("");

	const [pay1, setPay1] = useState(false);
	const [pay2, setPay2] = useState(false);
	const [pay3, setPay3] = useState(false);
	const [pay4, setPay4] = useState(false);
	const [apply, setApply] = useState(false);

	// const history = useHistory();

	// const [userData, setUserData] = useState({});
	// const [email, setemail] = useState("");

	const [handlePayment, setHandlePayment] = useState(false);
	const [data, setData] = useState([]);
	const [couponCode, setCouponCode] = useState("");

	const matches = useMedia("(min-width : 1001px )");
	const before_mobile_view = useMedia("(min-width : 770px )");

	const [free, setFree] = useState(true);
	const [standard, setStandard] = useState(false);
	const [pro, setPro] = useState(false);
	const [premium, setPremium] = useState(false);

	function freeHandler() {
		ReactGA.event({
			category: "New Course Page Tracks",
			page: "Newcoursepage",
			type: "Button",
			name: "New Course Page Fast Track",
			action: "New Course Page Fast Track",
		});
		setFree(true);
		setPremium(false);
		setPro(false);
		setStandard(false);
		return;
	}

  function ProHandler() {
    ReactGA.event({
			category: "New Course Page Tracks",
			page: "Newcoursepage",
			type: "Button",
			name: "New Course Page Pro Track",
			action: "New Course Page Pro Track",
		});
		setFree(false);
		setPremium(false);
		setPro(true);
		setStandard(false);
	}

  function StandardHandler() {
    ReactGA.event({
			category: "New Course Page Tracks",
			page: "Newcoursepage",
			type: "Button",
			name: "New Course Page Standard Track",
			action: "New Course Page Standard Track",
		});
		setFree(false);
		setPremium(false);
		setPro(false);
		setStandard(true);
	}

	const notify = () => {
		// alert("bookmark added")
		toast("Congratulation 🎉 🎉 You are enrolled into the course ", {
			position: "top-right",
			autoClose: 10000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
	};
	const notify_1 = () => {
		// alert("bookmark added")
		toast("You must be logged in 🔅 ", {
			position: "top-right",
			autoClose: 10000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
	};
  function FastTrackFreeEnroll() {
    ReactGA.event({
													category: "New Course Page Tracks",
													page: "Newcoursepage",
													type: "Button",
													name: "Try Now Fast Track",
													action: "Try Now on New Course Page Fast Track",
												})
		if (email !== undefined) {
			fetch(BASE_URL + "/enroll/61436752c9bf76c1dbcabeda", {
				headers: { authorization: "Bearer " + localStorage.getItem("token") },
				method: "POST",
			})
				.then((res) => res.json())
				.then((data) => {
					// alert("you are enrolled into the courseS")
					if (data.error == "You must be logged in") {
						notify_1();
					} else {
						notify();
						SendData("fast track course");
					}
				});
			// notify()
		} else {
			notify_1();
		}

		// alert('fast track')
	}
  function StandardFreeEnroll() {
    ReactGA.event({
			category: "New Course Page Tracks",
			page: "Newcoursepage",
			type: "Button",
			name: "Try Now Standard Track",
			action: "Try Now on New Course Page Standard Track",
		});
		if (email !== undefined) {
			fetch(BASE_URL + "/enroll/61433967d1963b0d7a28234a", {
				headers: { authorization: "Bearer " + localStorage.getItem("token") },
				method: "POST",
			})
				.then((res) => res.json())
				.then((data) => {
					// alert("you are enrolled into the courseS")
					if (data.error == "You must be logged in") {
						notify_1();
					} else {
						notify();
						SendData("standard course enroll");
					}
				});
		} else {
			notify_1();
		}

		// alert('standard free course')
	}
  function ProFreeEnroll() {
    ReactGA.event({
			category: "New Course Page Tracks",
			page: "Newcoursepage",
			type: "Button",
			name: "Try Now Pro Track",
			action: "Try Now on New Course Page Pro Track",
		});
		if (email !== undefined) {
			fetch(BASE_URL + "/enroll/61433dded1963b0d7a28234c", {
				headers: { authorization: "Bearer " + localStorage.getItem("token") },
				method: "POST",
			})
				.then((res) => res.json())
				.then((data) => {
					if (data.error == "You must be logged in") {
						notify_1();
					} else {
						SendData("pro course");
						notify();
					}
					// alert("you are enrolled into the courseS")
					// console.log(data)
					// notify()
				});
		}
		// alert('pro free course')
	}

	function sendusertoprofile() {
		// setTimeout

		setTimeout(() => {
			history.push("user/" + userId);
		}, 2000);
	}
	//sending data to the backend of the users that are enrolling into the free
	function SendData(value) {
		var today = new Date();
		var dd = String(today.getDate()).padStart(2, "0");
		var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
		var yyyy = today.getFullYear();
		today = mm + "/" + dd + "/" + yyyy;

		fetch(BASE_URL + "/trailusersdata", {
			headers: {
				"Content-Type": "application/json",
			},
			method: "POST",
			body: JSON.stringify({
				email: Email,
				phone: phone,
				name: name,
				date: today,
				course: value,
			}),
		}).then((data) => {
			if (data.error == false) {
				alert("you can move from here");
			}
		});

		sendusertoprofile();
	}
  function PremiumFreeEnroll() {
    ReactGA.event({
			category: "New Course Page Tracks",
			page: "Newcoursepage",
			type: "Button",
			name: "Try Now Premium Track",
			action: "Try Now on New Course Page Premium Track",
		});
		//61433f0bd1963b0d7a28234f
		if (email !== undefined) {
			fetch(BASE_URL + "/enroll/61433f0bd1963b0d7a28234f", {
				headers: { authorization: "Bearer " + localStorage.getItem("token") },
				method: "POST",
			})
				.then((res) => res.json())
				.then((data) => {
					// alert("you are enrolled into the courseS")
					if (data.error == "You must be logged in") {
						notify_1();
					} else {
						notify();
						SendData("premium course enroll");
					}
				});
		} else {
			notify_1();
		}
		// alert('premium free enroll')
	}

	function PremiumHandler() {
    setFree(false);
    ReactGA.event({
			category: "New Course Page Tracks",
			page: "Newcoursepage",
			type: "Button",
			name: "New Course Page Premium Track",
			action: "New Course Page Premium Track",
		});
		setPremium(true);
		setPro(false);
		setStandard(false);
	}

	async function handlePayNow(route, tier) {
		if (tier === "tier1") {
			// setTier("Force");
			setPay1(true);
			// loaderT1 = false;
			// setLoadT1(false);
		} else if (tier === "tier2") {
			// setTier("Surge");
			setPay2(true);
		} else if (tier === "tier3") {
			// setTier("Impact");
			setPay3(true);
			// setLoadT3(false);
		} else if (tier === "tier4") {
			// setTier("Surge");
			setPay4(true);
			// setLoadT4(false);
		}

		if (route === "cpcourse") {
			setCourse("Competetive Programming");
		}

		if (!token) {
			setSignIn(true);
			localStorage.setItem('userlocation', location.pathname);
		} else {
			await fetch(BASE_URL + "/getFromRoute?route=" + route)
				.then((response) => response.json())
				.then((data) => {
					// console.log(data);
					const tiers = data.course.types;
					const curr = tiers[tier];
					// console.log(curr);
					setTierId(curr._id);
					setAmount(curr.amount);
				});
		}

		setCoupon(true);
	}

	// const course_begin_date="5 September";
	return (
		<div style={{ overflowX: "hidden" }}>
			<ToastContainer
				theme='dark'
				position='top-right'
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
			<Coupons1
				course={course}
				tier={tier}
				setCoupon={setCoupon}
				tierId={tierId}
				amount={amount}
				// amount={"4,999"}
				original_amount={"10000"}
				name={"Fast Track"}
				token={token}
				setLoadT1={setLoadT1}
				setLoadT2={setLoadT2}
				setLoadT3={setLoadT3}
				setLoadT4={setLoadT4}
				apply={apply}
				setHandlePayment={setHandlePayment}
				courseId={`${FASTTRACK}`}
				setData={setData}
				setCouponCode={setCouponCode}
				show={pay1}
				setshow={setPay1}
				onClose={() => setPay1(false)}
			/>
			<Coupons2
				course={course}
				tier={tier}
				setCoupon={setCoupon}
				tierId={tierId}
				amount={amount}
				// amount={"9,999"}
				original_amount={"15000"}
				name={"Standard"}
				token={token}
				setLoadT1={setLoadT1}
				setLoadT2={setLoadT2}
				setLoadT3={setLoadT3}
				setLoadT4={setLoadT4}
				courseId={`${STANDARD}`}
				apply={apply}
				setHandlePayment={setHandlePayment}
				setData={setData}
				setCouponCode={setCouponCode}
				show={pay2}
				setshow={setPay2}
				onClose={() => setPay2(false)}
			/>
			<Coupons3
				course={course}
				tier={tier}
				setCoupon={setCoupon}
				original_amount={"25000"}
				name={"Pro"}
				tierId={tierId}
				amount={amount}
				// amount={"14,999"}
				token={token}
				courseId={`${PRO}`}
				setLoadT1={setLoadT1}
				setLoadT2={setLoadT2}
				setLoadT3={setLoadT3}
				setLoadT4={setLoadT4}
				apply={apply}
				setHandlePayment={setHandlePayment}
				setData={setData}
				setCouponCode={setCouponCode}
				show={pay3}
				setshow={setPay3}
				onClose={() => setPay3(false)}
			/>
			<Coupons4
				course={course}
				tier={tier}
				setCoupon={setCoupon}
				tierId={tierId}
				original_amount={"35000"}
				name={"Premium"}
				amount={amount}
				// amount={"19,999"}
				token={token}
				apply={apply}
				setLoadT1={setLoadT1}
				setLoadT2={setLoadT2}
				setLoadT3={setLoadT3}
				setLoadT4={setLoadT4}
				courseId={`${PREMIUM}`}
				setHandlePayment={setHandlePayment}
				setData={setData}
				setCouponCode={setCouponCode}
				show={pay4}
				setshow={setPay4}
				onClose={() => setPay4(false)}
			/>

			{signIn === true ? (
				<Redirect
					to="/register"
				/>
			) : null}

			{matches ? (
				<div
					className='row'
					style={{ marginBottom: "20px", marginTop: "20px" }}>
					<div className={" col-sm-3 " + style.headings}>
						<div
							className={free ? style.free : style.free_off}
							style={{ marginBottom: "-35px" }}>
							{/* {free ? (
                <p className={style.course_begin}>
                  Course begins by <b style={{ color: "red" }}>{course_begin_date} </b>
                </p>
              ) : null} */}

							<p
								onClick={freeHandler}
								className={
									free ? style.heading_on_font : style.heading_off_font
								}
								style={
									// free ? { paddingLeft: "11%" } :
									{ padding: "10%" }
								}>
								Fast Track
							</p>
						</div>

						<div
							className={standard ? style.standard : style.standard_off}
							style={{ marginBottom: "-35px" }}>
							{/* {standard ? (
                <p className={style.course_begin}>
                  Course begins by <b style={{ color: "red" }}>{course_begin_date}</b>
                </p>
              ) : null} */}

							<p
								onClick={() => StandardHandler()}
								className={
									standard ? style.heading_on_font : style.heading_off_font
								}
								style={
									// standard ? { paddingLeft: "11%" } :
									{ padding: "11%" }
								}>
								Standard
							</p>
						</div>

						<div
							className={pro ? style.pro : style.pro_off}
							style={{ marginBottom: "-35px" }}>
							{/* {pro ? (
                <p className={style.course_begin}>
                  Course begins by <b style={{ color: "red" }}>{course_begin_date}</b>
                </p>
              ) : null} */}

							<p
								onClick={() => ProHandler()}
								className={pro ? style.heading_on_font : style.heading_off_font}
								style={
									// pro ? { paddingLeft: "11%" } :
									{ padding: "11%" }
								}>
								{" "}
								Pro
							</p>
						</div>

						<div
							className={premium ? style.premium : style.premium_off}
							style={{ marginBottom: "-40px" }}>
							{/* {premium ? (
                <p className={style.course_begin}>
                  Course begins by <b style={{ color: "red" }}>{course_begin_date}</b>
                </p>
              ) : null} */}

							<p
								onClick={() => PremiumHandler()}
								className={
									premium ? style.heading_on_font : style.heading_off_font
								}
								style={
									// premium ? { paddingLeft: "11%" } :
									{ padding: "11%" }
								}>
								Premium
							</p>
						</div>
					</div>

					<div
						className={" col-sm-8 " + style.content}
						style={{ padding: "40px" }}>
						{free ? (
							<div>
								<div className='row'>
									<div className='col-4'>
										<p className={style.head_txt}>Targeted companies</p>
									</div>
									<div className='col-4'></div>
									<div className='col-3'>
										<p className={style.head_txt}>Average Package</p>
									</div>
								</div>

								<div className='row'>
									<div className='col-8'>
										<img
											src={Inf}
											className={style.logos}
											alt='Company Logos'
										/>
										<img
											src={Wipro}
											className={style.logos}
											alt='Company Logos'
										/>
										<img
											src={Gemini}
											className={style.logos}
											alt='Company Logos'
										/>
										<img
											src={TCS}
											className={style.logos}
											alt='Company Logos'
										/>
										<img
											src={Cog}
											className={style.logos}
											alt='Company Logos'
										/>
										<img
											src={HCL}
											className={style.logos}
											alt='Company Logos'
										/>
									</div>
									<div className='col-2'>
										<p
											className={style.head_txt}
											style={{
												color: "#FF8726",
												fontSize: "35px",
												margin: "8px",
											}}>
											{" "}
											3 - 5 LPA
										</p>
									</div>
									<hr style={{ color: "blue", border: "1px solid #185ADB" }} />
								</div>

								<div
									className='row'
									style={{ paddingTop: "25px", marginBottom: "-40px" }}>
									<div className='col-sm-8'>
										<div className='row'>
											<div className=' col-sm-3 '>
												<p>
													{" "}
													<img src={i1} alt='Company Logos' />
													&nbsp;<b>30</b> <br /> &emsp;&nbsp;DAYS
												</p>
											</div>

											<div className='col-sm-3'>
												<p>
													{" "}
													<img src={i2} alt='Company Logos' />
													&nbsp;<b>40+</b> <br />
													&emsp;&nbsp;&nbsp;HOURS
												</p>
											</div>

											<div className='col-sm-3'>
												<p>
													{" "}
													<img src={i3} alt='Company Logos' />
													&nbsp;<b>120+</b> <br />{" "}
													&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ASSIGNMENTS
												</p>
											</div>
										</div>
									</div>

									<div className='col-sm-4'>
										<a
											target='blank'
											href='https://courses-upload-bucket.s3.ap-south-1.amazonaws.com/COMPLETE+GUIDE+TO+PLACEMENT+/Fast-track+Course+Syllabus.pdf'>
											<button className={style.curriculum}>
												Know Your Curriculum&emsp;
												<img src={download} />
											</button>
										</a>
									</div>
								</div>

								<h4 style={{ fontWeight: "bold" }}>Fast Track Course</h4>

								<p className={style.ftc}>
									An efficient crash course to boost your confidence and
									increase your performance before the interviews.
								</p>

								<h4 style={{ fontWeight: "bold" }}>What's included?</h4>

								<div className='d-flex flex-row'>
									<div className='  ' style={{}}>
										<img src={tick} alt='Company Logos' />
									</div>
									<div className={"px-2 " + style.wyr}>
										24+ Aptitude sessions with tips and tricks
									</div>
								</div>

								<div className='d-flex flex-row'>
									<div className=' ' style={{}}>
										<img src={tick} alt='Company Logos' />
									</div>
									<div className={" px-2  " + style.wyr}>
										Mock tests to analyze performance.
									</div>
								</div>

								<div className='d-flex flex-row'>
									<div className='' style={{}}>
										<img src={tick} />
									</div>
									<div className={" px-2  " + style.wyr}>
										Expert doubt support.
									</div>
								</div>

								<div className='d-flex flex-row'>
									<div className=' ' style={{}}>
										<img src={tick} />
									</div>
									<div className={"px-2  " + style.wyr}>
										1-year access to the course.
									</div>
								</div>

								<div className='d-flex flex-row'>
									<div className=' ' style={{}}>
										<img src={tick} alt='Company Logos' />
									</div>
									<div className={"px-2  " + style.wyr}>
										DSA Study material curated by experts.
									</div>
								</div>

								<hr />

								<div className='row'>
									<div className='col-sm-8'>
										<h4
											style={{
												color: "#83ADFF",
												textDecoration: "line-through",
											}}>
											Rs 10000/-
										</h4>
										<h4
											style={{
												color: "blue",
												fontWeight: "800",
												fontSize: "30px",
											}}>
											Rs 7499/-
										</h4>
										<button
											className={style.applyBtn}
                      onClick={() => {
                     ReactGA.event({
													category: "New Course Page Tracks",
													page: "Newcoursepage",
													type: "Button",
													name: "Apply Coupon Fast Track",
													action: "Apply Coupon on New Course Page Fast Track",
												})
												loaderT1 = true;
												setLoadT1(true);
												setApply(true);
												handlePayNow("newcoursepage", "tier1");
											}}>
											Apply Coupon
										</button>
										<div style={{ height: "25px", marginBottom: "30px" }}></div>
										<p style={{ color: "#8B8B8B", fontWeight: "600" }}>
											<img src={grey_1} alt='Company Logos' />
											EMI Options &emsp; <img src={grey_2} />
											&nbsp;7 Day refund policy
										</p>
									</div>

									<div className='col-sm-4'>
										<button
											// className={`btn btn-warning px-1 ${styles.sylbtnJ}`}
                      onClick={() => {
                 ReactGA.event({
													category: "New Course Page Tracks",
													page: "Newcoursepage",
													type: "Button",
													name: "Enroll Now Fast Track",
													action: "Enroll Now on New Course Page Fast Track",
												})
												loaderT1 = true;
												setLoadT1(true);
												handlePayNow("newcoursepage", "tier1");
												setApply(false);
											}}
											className={style.enrollnow}>
											Enroll
										</button>
										<br />

										<button
											className={style.trynow}
											onClick={FastTrackFreeEnroll}>
											Try Free
										</button>
									</div>
								</div>
							</div>
						) : null}

						{standard ? (
							<div>
								<div className='row'>
									<div className='col-4'>
										<p className={style.head_txt}>Targeted companies</p>
									</div>
									<div className='col-4'></div>
									<div className='col-3'>
										<p className={style.head_txt}>Average Package</p>
									</div>{" "}
								</div>

								<div className='row'>
									<div className='col-8'>
										<img
											src={Inf}
											className={style.logos}
											alt='Company Logos'
										/>
										<img
											src={Wipro}
											className={style.logos}
											alt='Company Logos'
										/>
										<img
											src={Gemini}
											className={style.logos}
											alt='Company Logos'
										/>
										<img
											src={TCS}
											className={style.logos}
											alt='Company Logos'
										/>
										<img
											src={Cog}
											className={style.logos}
											alt='Company Logos'
										/>
										<img
											src={HCL}
											className={style.logos}
											alt='Company Logos'
										/>
									</div>
									<div className='col-4'>
										<p
											className={style.head_txt}
											style={{
												color: "#FF8726",
												fontSize: "35px",
												margin: "8px",
											}}>
											{" "}
											5 - 7 LPA
										</p>
									</div>
									<hr style={{ color: "blue", border: "1px solid #185ADB" }} />
								</div>

								<div
									className='row'
									style={{ paddingTop: "25px", marginBottom: "-40px" }}>
									<div className='col-sm-8'>
										<div className='row'>
											<div className={" col-sm-3"}>
												<p>
													{" "}
													<img src={i1} alt='Company Logos' />
													&nbsp;<b>45 </b>
													<br />
													&emsp; DAYS
												</p>
											</div>

											<div className='col-sm-3'>
												<p>
													{" "}
													<img src={i2} alt='Company Logos' />
													&nbsp;<b>80+ </b> <br />
													&emsp;&nbsp;&nbsp;HOURS
												</p>
											</div>

											<div className='col-sm-4'>
												<p>
													{" "}
													<img src={i3} alt='Company Logos' />
													&nbsp;<b>120+</b> <br />
													&emsp;&nbsp;&nbsp;ASSIGNMENTS
												</p>
											</div>
										</div>
									</div>

									<div className='col-sm-4'>
										<a
											target='blank'
											href='https://courses-upload-bucket.s3.ap-south-1.amazonaws.com/COMPLETE+GUIDE+TO+PLACEMENT+/Standard+Course+Syllabus.pdf'>
											<button className={style.curriculum}>
												Know Your Curriculum&emsp;
												<img src={download} alt='Company Logos' />
											</button>
										</a>
									</div>
								</div>

								<h4 style={{ fontWeight: "bold" }}>Standard Course</h4>

								<p className={style.ftc}>
									Prepare for the placements with the comprehensive standard
									course and get an edge over others.
								</p>

								<h4 style={{ fontWeight: "bold" }}>What's included?</h4>

								<div className='flex-row d-flex'>
									<div className=' ' style={{}}>
										<img src={tick} alt='Company Logos' />
									</div>
									<div className={"px-2  " + style.wyr}>
										Excel at competitive coding with our DSA video lectures
									</div>
								</div>
								<div className='flex-row d-flex'>
									<div className=' ' style={{}}>
										<img src={tick} alt='Company Logos' />
									</div>
									<div className={"px-2  " + style.wyr}>
										24+ Aptitude sessions with tips and tricks
									</div>
								</div>
								<div className='flex-row d-flex'>
									<div className=' ' style={{}}>
										<img src={tick} alt='Company Logos' />
									</div>
									<div className={"px-2  " + style.wyr}>
										Expert-curated mock tests and performance reports.
									</div>
								</div>

								<div className='flex-row d-flex'>
									<div className=' ' style={{}}>
										<img src={tick} alt='Company Logos' />
									</div>
									<div className={"px-2  " + style.wyr}>
										Quick doubt support.
									</div>
								</div>

								<div className='flex-row d-flex'>
									<div className=' ' style={{}}>
										<img src={tick} alt='Company Logos' />
									</div>
									<div className={"px-2  " + style.wyr}>
										Top-quality study material.
									</div>
								</div>

								<hr />

								<div className='row'>
									<div className='col-sm-8'>
										<h4
											style={{
												color: "#83ADFF",
												textDecoration: "line-through",
											}}>
											Rs 15000/-
										</h4>
										<h4
											style={{
												color: "blue",
												fontWeight: "800",
												fontSize: "30px",
											}}>
											Rs 12999/-
										</h4>
										<button
											className={style.applyBtn}
                      onClick={() => {
                        ReactGA.event({
													category: "New Course Page Tracks",
													page: "Newcoursepage",
													type: "Button",
													name: "Apply Coupon Standard Track",
													action: "Apply Coupon on New Course Page Standard Track",
												});
												loaderT2 = true;
												setLoadT2(true);
												setApply(true);
												handlePayNow("newcoursepage", "tier2");
											}}>
											Apply Coupon
										</button>
										<div style={{ height: "25px", marginBottom: "40px" }}></div>
										<p style={{ color: "#8B8B8B", fontWeight: "600" }}>
											<img src={grey_1} />
											EMI Options &emsp; <img src={grey_2} />
											&nbsp;7 Day refund policy
										</p>
									</div>

									<div className='col-sm-4'>
										<button
											className={style.enrollnow}
											style={{ textAlign: "center" }}
											// className={`btn btn-warning px-1 ${styles.sylbtnJ}`}
                      onClick={() => {
                        ReactGA.event({
													category: "New Course Page Tracks",
													page: "Newcoursepage",
													type: "Button",
													name: "Enroll Now Standard Track",
													action: "Enroll Now on New Course Page Standard Track",
												});
												loaderT2 = true;
												setLoadT2(true);
												handlePayNow("newcoursepage", "tier2");
											}}>
											Enroll
										</button>
										<br />
										<button
											className={style.trynow}
											onClick={StandardFreeEnroll}>
											Try Free
										</button>
									</div>
								</div>
							</div>
						) : null}
						{pro ? (
							<div>
								<div className='row'>
									<div className='col-4'>
										<p className={style.head_txt}>Targeted companies</p>
									</div>
									<div className='col-4'></div>
									<div className='col-3'>
										<p className={style.head_txt}>Average Package</p>
									</div>{" "}
								</div>

								<div className='row'>
									<div className='col-8'>
										<img
											src={Facebook}
											className={style.logos}
											// style={{ marginTop: "-5px" }}
										/>
										<img
											src={Flipkart}
											className={style.logos}
											alt='Company Logos'
										/>
										<img
											src={Microsoft}
											className={style.logos}
											alt='Company Logos'
										/>
										<img
											src={Amazon}
											className={style.logos}
											alt='Company Logos'
										/>
										<img
											src={Netflix}
											className={style.logos}
											alt='Company Logos'
										/>
										<img
											src={Google}
											className={style.logos}
											alt='Company Logos'
										/>
									</div>
									<div className='col-4'>
										<p
											className={style.head_txt}
											style={{
												color: "#FF8726",
												fontSize: "35px",
												margin: "8px",
											}}>
											{" "}
											7 - 10 LPA
										</p>
									</div>
									<hr style={{ color: "blue", border: "1px solid #185ADB" }} />
								</div>

								<div
									className='row'
									style={{ paddingTop: "25px", marginBottom: "-40px" }}>
									<div className='col-sm-8'>
										<div className='row'>
											<div className={" col-sm-3"}>
												<p>
													{" "}
													<img src={i1} alt='Company Logos' />
													&nbsp;<b>60</b>
													<br />
													&emsp; DAYS
												</p>
											</div>

											<div className='col-sm-3'>
												<p>
													{" "}
													<img src={i2} alt='Company Logos' />
													&nbsp;<b>100+ </b>
													<br />
													&emsp;&nbsp;&nbsp;HOURS
												</p>
											</div>

											<div className='col-sm-4'>
												<p>
													{" "}
													<img src={i3} alt='Company Logos' />
													&nbsp;<b>150+ </b>
													<br />
													&emsp; &nbsp;ASSIGNMENTS
												</p>
											</div>
										</div>
									</div>

									<div className='col-sm-4'>
										<a
											target='blank'
											href='https://courses-upload-bucket.s3.ap-south-1.amazonaws.com/COMPLETE+GUIDE+TO+PLACEMENT+/PRO+COURSE+SYLLABUS.pdf'>
											<button className={style.curriculum}>
												Know Your Curriculum&emsp;
												<img src={download} />
											</button>
										</a>
									</div>
								</div>

								<h4 style={{ fontWeight: "bold" }}>Pro Course</h4>

								<p className={style.ftc}>
									Become a master at coding and aptitude with our 2 month long
									PRO course.
								</p>

								<h4 style={{ fontWeight: "bold" }}>What's included?</h4>

								<div className='flex-row d-flex'>
									<div className=' ' style={{}}>
										<img src={tick} />
									</div>
									<div className={"px-2  " + style.wyr}>
										24+ sessions on Aptitude.
									</div>
								</div>

								<div className='flex-row d-flex'>
									<div className=' ' style={{}}>
										<img src={tick} alt='Company Logos' />
									</div>
									<div className={"px-2  " + style.wyr}>
										15+ coding sessions on DSA and programming.
									</div>
								</div>

								<div className='flex-row d-flex'>
									<div className=' ' style={{}}>
										<img src={tick} alt='Company Logos' />
									</div>
									<div className={"px-2  " + style.wyr}>
										Mock tests to analyze your performance.
									</div>
								</div>

								<div className='flex-row d-flex'>
									<div className=' ' style={{}}>
										<img src={tick} alt='Company Logos' />
									</div>
									<div className={"px-2  " + style.wyr}>
										1:1 Mock interviews with top industry experts.
									</div>
								</div>

								<div className='flex-row d-flex'>
									<div className=' ' style={{}}>
										<img src={tick} alt='Company Logos' />
									</div>
									<div className={"px-2  " + style.wyr}>
										5+ soft skills sessions for HR interview.
									</div>
								</div>

								<div className='flex-row d-flex'>
									<div className=' ' style={{}}>
										<img src={tick} alt='Company Logos' />
									</div>
									<div className={"px-2 " + style.wyr}>
										Mentorship on building a profile on LinkedIn.
									</div>
								</div>

								<hr />

								<div className='row'>
									<div className='col-sm-8'>
										<h4
											style={{
												color: "#83ADFF",
												textDecoration: "line-through",
											}}>
											Rs 25000/-
										</h4>
										<h4
											style={{
												color: "blue",
												fontWeight: "800",
												fontSize: "30px",
											}}>
											Rs 19999/-
										</h4>
										<button
											className={style.applyBtn}
                      onClick={() => {
                        ReactGA.event({
													category: "New Course Page Tracks",
													page: "Newcoursepage",
													type: "Button",
													name: "Apply Coupon Fast Track",
													action: "Apply Coupon Now on New Course Page Pro Track",
												});
												loaderT3 = true;
												setLoadT3(true);
												setApply(true);
												handlePayNow("newcoursepage", "tier3");
											}}>
											Apply Coupon
										</button>
										<div style={{ height: "25px", marginBottom: "20px" }}></div>
										<p style={{ color: "#8B8B8B", fontWeight: "600" }}>
											<img src={grey_1} alt='Company Logos' />
											EMI Options &emsp; <img src={grey_2} />
											&nbsp;7 Day refund policy
										</p>
									</div>

									<div className='col-sm-4'>
										<button
											className={style.enrollnow}
											style={{ textAlign: "center" }}
											// className={`btn btn-warning px-1 ${styles.sylbtnJ}`}
                      onClick={() => {
                        ReactGA.event({
													category: "New Course Page Tracks",
													page: "Newcoursepage",
													type: "Button",
													name: "Enroll Now Pro Track",
													action: "Enroll Now on New Course Page Pro Track",
												});
												loaderT3 = true;
												setLoadT3(true);
												handlePayNow("newcoursepage", "tier3");
											}}>
											Enroll
										</button>
										<br />
										<button className={style.trynow} onClick={ProFreeEnroll}>
											Try Free
										</button>
									</div>
								</div>
							</div>
						) : null}
						{premium ? (
							<div>
								<div className='row'>
									<div className='col-4'>
										<p className={style.head_txt}>Targeted companies</p>
									</div>
									<div className='col-4'></div>
									<div className='col-3'>
										<p className={style.head_txt}>Average Package</p>
									</div>{" "}
								</div>

								<div className='row'>
									<div className='col-8'>
										<img
											src={Facebook}
											className={style.logos}
											alt='Company Logos'
											// style={{ marginTop: "-5px" }}
										/>
										<img
											src={Flipkart}
											className={style.logos}
											alt='Company Logos'
										/>
										<img
											src={Microsoft}
											className={style.logos}
											alt='Company Logos'
										/>

										<img
											src={Amazon}
											className={style.logos}
											alt='Company Logos'
										/>
										<img
											src={Netflix}
											className={style.logos}
											alt='Company Logos'
										/>
										<img
											src={Google}
											className={style.logos}
											alt='Company Logos'
										/>
									</div>
									<div className='col-4'>
										<p
											className={style.head_txt}
											style={{
												color: "#FF8726",
												fontSize: "35px",
												margin: "8px",
											}}>
											{" "}
											10 - 15 LPA
										</p>
									</div>
									<hr style={{ color: "blue", border: "1px solid #185ADB" }} />
								</div>

								<div
									className='row'
									style={{ paddingTop: "25px", marginBottom: "-40px" }}>
									<div className='col-sm-8'>
										<div className='row'>
											<div className={" col-sm-3"}>
												<p>
													{" "}
													<img src={i1} alt='Company Logos' />
													&nbsp;<b>90 </b>
													<br />
													&emsp; DAYS
												</p>
											</div>

											<div className='col-sm-3'>
												<p>
													{" "}
													<img src={i2} alt='Company Logos' />
													&nbsp;<b>120+ </b>
													<br />
													&emsp;&nbsp; HOURS
												</p>
											</div>

											<div className='col-sm-4'>
												<p>
													{" "}
													<img src={i3} alt='Company Logos' />
													&nbsp;<b>200+ </b>
													<br /> &emsp;&nbsp;&nbsp;ASSIGNMENTS
												</p>
											</div>
										</div>
									</div>

									<div className='col-sm-4'>
										<a
											target='blank'
											href='https://courses-upload-bucket.s3.ap-south-1.amazonaws.com/COMPLETE+GUIDE+TO+PLACEMENT+/Premium+Course+Syllabus.pdf'>
											<button className={style.curriculum}>
												Know Your Curriculum&emsp;
												<img src={download} alt='Company Logos' />
											</button>
										</a>
									</div>
								</div>

								<h4 style={{ fontWeight: "bold" }}>Premium Course</h4>

								<p className={style.ftc}>
									An All-in-one course, tailor-made to help you crack the
									toughest tech interviews and build practical skills with
									hands-on projects.
								</p>

								<h4 style={{ fontWeight: "bold" }}>What's included?</h4>
								<div className='flex-row d-flex'>
									<div className=' ' style={{}}>
										<img src={tick} alt='Company Logos' />
									</div>
									<div className={"px-2 " + style.wyr}>
										Advanced DSA for top companies.
									</div>
								</div>

								<div className='flex-row d-flex'>
									<div className=' ' style={{}}>
										<img src={tick} alt='Company Logos' />
									</div>
									<div className={"px-2  " + style.wyr}>
										24+ aptitude sessions.
									</div>
								</div>

								<div className='flex-row d-flex'>
									<div className=' ' style={{}}>
										<img src={tick} alt='Company Logos' />
									</div>
									<div className={"px-2  " + style.wyr}>
										Learn LinkedIn profile building and networking.
									</div>
								</div>
								<div className='flex-row d-flex'>
									<div className=' ' style={{}}>
										<img src={tick} alt='Company Logos' />
									</div>
									<div className={"px-2 " + style.wyr}>
										1:1 resume sessions and mock interview sessions
									</div>
								</div>
								<div className='flex-row d-flex'>
									<div className=' ' style={{}}>
										<img src={tick} alt='Company Logos' />
									</div>
									<div className={"px-2 " + style.wyr}>
										Soft skills sessions and GD to crack HR round.
									</div>
								</div>
								<div className='flex-row d-flex'>
									<div className=' ' style={{}}>
										<img src={tick} alt='Company Logos' />
									</div>
									<div className={"px-2  " + style.wyr}>
										Mock tests with a performance report.
									</div>
								</div>

								<hr />

								<div className='row'>
									<div className='col-sm-8'>
										<h4
											style={{
												color: "#83ADFF",
												textDecoration: "line-through",
											}}>
											Rs 35000/-
										</h4>
										<h4
											style={{
												color: "blue",
												fontWeight: "800",
												fontSize: "30px",
											}}>
											Rs 29999/-
										</h4>
										<button
											className={style.applyBtn}
                      onClick={() => {
                        ReactGA.event({
													category: "New Course Page Tracks",
													page: "Newcoursepage",
													type: "Button",
													name: "Apply Coupon  Premium Track",
													action: "Apply Coupon on New Course Page Premium Track",
												});
												loaderT4 = true;
												setLoadT4(true);
												setApply(true);
												handlePayNow("newcoursepage", "tier4");
											}}>
											Apply Coupon
										</button>
										<div style={{ height: "25px" }}></div>
										<p style={{ color: "#8B8B8B", fontWeight: "600" }}>
											<img src={grey_1} alt='Company Logos' />
											EMI Options &emsp;{" "}
											<img src={grey_2} alt='Company Logos' />
											&nbsp;7 Day refund policy
										</p>
									</div>

									<div className='col-sm-4'>
										<button
											className={style.enrollnow}
											style={{ textAlign: "center" }}
											// className={`btn btn-warning px-1 ${styles.sylbtnJ}`}
                      onClick={() => {
                        ReactGA.event({
													category: "New Course Page Tracks",
													page: "Newcoursepage",
													type: "Button",
													name: "Enroll Now Premium Track",
													action: "Enroll Now on New Course Page Premium Track",
												});
												loaderT4 = true;
												setLoadT4(true);
												handlePayNow("newcoursepage", "tier4");
											}}>
											Enroll
										</button>
										<br />
										<button
											className={style.trynow}
											onClick={PremiumFreeEnroll}>
											Try Free
										</button>
									</div>
								</div>
							</div>
						) : null}
					</div>
				</div>
			) : (
				// -------------------------------------------------------------------------------
				// -------------------------------------------------------------------------------
				// -------------------------------------------------------------------------------
				// -------------------------------------------------------------------------------
				// -------------------------------------------------------------------------------

				// -------------------------------------------------------------------------------
				// -------------------------------------------------------------------------------
				// -------------------------------------------------------------------------------
				// -------------------------------------------------------------------------------
				// -------------------------------------------------------------------------------

				<div>
					{before_mobile_view ? (
						<div>
							<div
								className={" row " + style.headings}
								style={{ paddingLeft: "10px" }}>
								<div
									className={
										free ? style.free + " col-3 " : style.free_off + " col-3 "
									}
									style={{ width: "25%", padding: "1px", height: "100px" }}>
									{/* {free ? (
                    <p
                      style={{
                        display: "inline",
                        color: "#8B8B8B",
                        backgroundColor: "#DAE5FB",
                        fontSize: "10px",
                      }}
                    >
                      Course begins by <b style={{ color: "red" }}>{course_begin_date}</b>
                    </p>
                  ) : null} */}

									<p
										onClick={freeHandler}
										style={
											// free
											//   ? {
											//       paddingLeft: "5%",
											//       fontSize: "20px",
											//       marginTop: "-20px",
											//     }
											//   :
											{ padding: "10%" }
										}>
										Fast Track
									</p>
								</div>

								<div
									className={
										standard
											? style.standard + " col-3 "
											: style.standard_off + " col-3 "
									}
									style={{ width: "24%", padding: "0px", height: "100px" }}>
									{/* {standard ? (
                    <p
                      style={{
                        display: "inline",
                        color: "#8B8B8B",
                        backgroundColor: "#DAE5FB",
                        fontSize: "9px",
                      }}
                    >
                      Course begins by <b style={{ color: "red" }}>{course_begin_date}</b>
                    </p>
                  ) : null} */}

									<p
										onClick={StandardHandler}
										style={
											// standard
											//   ? {
											//       paddingLeft: "5%",
											//       fontSize: "20px",
											//       marginTop: "-20px",
											//     }
											//   :
											{ padding: "10%" }
										}>
										Standard
									</p>
								</div>

								<div
									className={
										pro ? style.pro + " col-3 " : style.pro_off + " col-3 "
									}
									style={{ width: "22%", padding: "0px", height: "100px" }}>
									{/* {pro ? (
                    <p
                      style={{
                        display: "inline",
                        color: "#8B8B8B",
                        backgroundColor: "#DAE5FB",
                        fontSize: "8px",
                      }}
                    >
                      Course begins by <b style={{ color: "red" }}>{course_begin_date}</b>
                    </p>
                  ) : null} */}

									<p
										onClick={ProHandler}
										style={
											// pro
											//   ? {
											//       paddingLeft: "5%",
											//       fontSize: "20px",
											//       marginTop: "-20px",
											//     }
											//   :
											{ padding: "10%" }
										}>
										Pro
									</p>
								</div>

								<div
									className={
										premium ? style.premium : style.premium_off + " col-3 "
									}
									style={{ width: "24%", padding: "0px", height: "100px" }}>
									{/* {premium ? (
                    <p
                      style={{
                        display: "inline",
                        color: "#8B8B8B",
                        backgroundColor: "#DAE5FB",
                        fontSize: "9px",
                      }}
                    >
                      Course begins by <b style={{ color: "red" }}>{course_begin_date}</b>
                    </p>
                  ) : null} */}

									<p
										onClick={PremiumHandler}
										style={
											// premium
											//   ? {
											//       paddingLeft: "5%",
											//       fontSize: "20px",
											//       marginTop: "-20px",
											//     }
											//   :
											{ padding: "10%" }
										}>
										Premium
									</p>
								</div>
							</div>

							<div className={style.content}>
								{free ? (
									<div>
										<div className='row'>
											<div className='col-4'>
												<p className={style.head_txt}>Targeted companies</p>
											</div>
											<div className='col-4'></div>
											<div className='col-3'>
												<p className={style.head_txt}>Average Package</p>
											</div>
										</div>

										<div className='row'>
											<div className='col-8'>
												<img
													src={Inf}
													className={style.logos_mob}
													alt='Company Logos'
												/>
												<img
													src={Wipro}
													className={style.logos_mob}
													alt='Company Logos'
												/>
												<img
													src={Gemini}
													className={style.logos_mob}
													alt='Company Logos'
												/>
												<img
													src={TCS}
													className={style.logos_mob}
													alt='Company Logos'
												/>
												<img
													src={Cog}
													className={style.logos_mob}
													alt='Company Logos'
												/>
												<img
													src={HCL}
													className={style.logos_mob}
													alt='Company Logos'
												/>
											</div>
											<div className='col-2'>
												<p
													className={style.head_txt}
													style={{
														color: "#FF8726",
														fontSize: "25px",
														marginLeft: "35px",
													}}>
													{" "}
													3 - 5 LPA
												</p>
											</div>
											<hr style={{ color: "blue" }} />
										</div>

										<div className='row'>
											<div className='col-sm-8'>
												<div className='row'>
													<div className={" col-sm-3"}>
														<p>
															{" "}
															<img src={i1} alt='Company Logos' />
															&nbsp;30 <br /> &emsp;&nbsp;&nbsp;DAYS
														</p>
													</div>

													<div className='col-sm-4'>
														<p>
															{" "}
															<img src={i2} alt='Company Logos' />
															&nbsp;40+ <br /> &emsp;&emsp;HOURS
														</p>
													</div>

													<div className='col-sm-5'>
														<p>
															{" "}
															<img src={i3} alt='Company Logos' />
															&nbsp;120+ <br /> &nbsp; &nbsp; ASSIGNMENTS
														</p>
													</div>
												</div>
											</div>

											<div className='col-sm-4'>
												<a
													target='blank'
													href='https://courses-upload-bucket.s3.ap-south-1.amazonaws.com/COMPLETE+GUIDE+TO+PLACEMENT+/Fast-track+Course+Syllabus.pdf'>
													<p className={style.curriculum}>
														Know Your Curriculum&emsp;
														<img src={download} alt='Company Logos' />
													</p>
												</a>
											</div>
										</div>

										<h4 style={{ fontWeight: "bold" }}>Fast Track Course</h4>

										<p className={style.ftc}>
											An efficient crash course to boost your confidence and
											increase your performance before the interviews.
										</p>

										<h4 style={{ fontWeight: "bold" }}>What's included?</h4>

										<div className='flex-row d-flex'>
											<div className='col-1' style={{}}>
												<img src={tick} alt='Company Logos' />
											</div>
											<div className={" col-10 " + style.wyr}>
												24+ Aptitude sessions with tips and tricks
											</div>
										</div>

										<div className='flex-row d-flex'>
											<div className='col-1' style={{}}>
												<img src={tick} alt='Company Logos' />
											</div>
											<div className={" col-10 " + style.wyr}>
												Mock tests to analyze performance.
											</div>
										</div>

										<p className={style.wyr}>
											<img
												src={tick}
												style={{ marginRight: "10px" }}
												alt='Company Logos'
											/>
											Expert doubt support.
										</p>
										<p className={style.wyr}>
											<img
												src={tick}
												style={{ marginRight: "10px" }}
												alt='Company Logos'
											/>
											1-year access to the course.
										</p>
										<p className={style.wyr}>
											<img
												src={tick}
												style={{ marginRight: "10px" }}
												alt='Company Logos'
											/>
											DSA Study material curated by experts.
										</p>

										<hr />

										<div className='row'>
											<div className='col-sm-8'>
												<h4
													style={{
														color: "#83ADFF",
														textDecoration: "line-through",
													}}>
													Rs 10000/-
												</h4>
												<h4 style={{ color: "blue", fontWeight: "800" }}>
													Rs 7499/-
												</h4>
												<button
													className={style.applyBtn}
                            onClick={() => {
                            ReactGA.event({
															category: "New Course Page Tracks",
															page: "Newcoursepage",
															type: "Button",
															name: "Apply Coupon Fast Track",
															action: "Apply Coupon on New Course Page Fast Track",
														});
														loaderT1 = true;
														setLoadT1(true);
														setApply(true);
														handlePayNow("newcoursepage", "tier1");
													}}>
													Apply Coupon
												</button>
												<br />

												<div style={{ height: "25px" }}></div>
												<p style={{ color: "#8B8B8B", fontWeight: "600" }}>
													<img src={grey_1} />
													EMI Options &emsp; <img src={grey_2} />
													&nbsp;7 Day refund policy
												</p>
											</div>

											<div className='col-sm-4'>
												<button
													className={style.enrollnow}
                            onClick={() => {
                            ReactGA.event({
															category: "New Course Page Tracks",
															page: "Newcoursepage",
															type: "Button",
															name: "Enroll Now Fast Track",
															action:
																"Enroll Now on New Course Page Fast Track",
														});
														loaderT1 = true;
														setLoadT1(true);
														handlePayNow("newcoursepage", "tier1");
													}}>
													Enroll
												</button>
												<br />
												<button className={style.trynow} onClick={() =>{ReactGA.event({
													category: "New Course Page Tracks",
													page: "Newcoursepage",
													type: "Button",
													name: "Try Now Fast Track",
													action: "Try Now on New Course Page Fast Track",
												})}}>Try free</button>
												{/* <button className={style.trynow} >Try Free</button> */}
											</div>
										</div>
									</div>
								) : null}

								{standard ? (
									<div>
										<div className='row'>
											<div className='col-4'>
												<p className={style.head_txt}>Targeted companies</p>
											</div>
											<div className='col-4'></div>
											<div className='col-3'>
												<p className={style.head_txt}>Average Package</p>
											</div>
										</div>

										<div className='row'>
											<div className='col-8'>
												<img src={Inf} className={style.logos_mob} />
												<img src={Wipro} className={style.logos_mob} />
												<img src={Gemini} className={style.logos_mob} />
												<img src={TCS} className={style.logos_mob} />
												<img src={Cog} className={style.logos_mob} />
												<img src={HCL} className={style.logos_mob} />
											</div>
											<div className='col-2'>
												<p
													className={style.head_txt}
													style={{
														color: "#FF8726",
														fontSize: "25px",
														marginLeft: "35px",
													}}>
													{" "}
													5 - 7 LPA
												</p>
											</div>
											<hr style={{ color: "blue" }} />
										</div>

										<div className='row'>
											<div className='col-sm-8'>
												<div className='row'>
													<div className={" col-sm-3"}>
														<p>
															{" "}
															<img src={i1} />
															&nbsp;30 <br />
															&emsp;&nbsp;&nbsp;DAYS
														</p>
													</div>

													<div className='col-sm-4'>
														<p>
															{" "}
															<img src={i2} />
															&nbsp;40+ <br />
															&emsp;&emsp; HOURS
														</p>
													</div>

													<div className='col-sm-5'>
														<p>
															{" "}
															<img src={i3} />
															&nbsp;120+ <br /> &emsp;ASSIGNMENTS
														</p>
													</div>
												</div>
											</div>

											<div className='col-sm-4'>
												<a
													target='blank'
													className={style.curriculum}
													href='https://courses-upload-bucket.s3.ap-south-1.amazonaws.com/COMPLETE+GUIDE+TO+PLACEMENT+/Standard+Course+Syllabus.pdf'>
													Know Your Curriculum&emsp;
													<img src={download} />
												</a>
											</div>
										</div>

										<h4 style={{ fontWeight: "bold" }}>Fast Track Course</h4>

										<p className={style.ftc}>
											Prepare for the placements with the comprehensive standard
											course and get an edge over others.
										</p>

										<h4 style={{ fontWeight: "bold" }}>What's included?</h4>
										<div className='row'>
											<div className='col-1' style={{}}>
												<img src={tick} />
											</div>
											<div className={" col-10 " + style.wyr}>
												Excel at competitive coding with our DSA video lectures.
											</div>
										</div>
										<div className='row'>
											<div className='col-1' style={{}}>
												<img src={tick} />
											</div>
											<div className={" col-10 " + style.wyr}>
												24+ Aptitude sessions with tips and tricks.
											</div>
										</div>
										<p className={style.wyr}>
											<img src={tick} style={{ marginRight: "12px" }} />
											Expert-curated mock tests and performance reports.
										</p>

										<div className='row'>
											<div className='col-1' style={{}}>
												<img src={tick} />
											</div>
											<div className={" col-10 " + style.wyr}>
												Quick doubt support.
											</div>
										</div>

										<div className='row'>
											<div className='col-1' style={{}}>
												<img src={tick} />
											</div>
											<div className={" col-10 " + style.wyr}>
												Top-quality study material.
											</div>
										</div>

										<hr />

										<div className='row'>
											<div className='col-sm-8'>
												<h4
													style={{
														color: "#83ADFF",
														textDecoration: "line-through",
													}}>
													Rs 15000/-
												</h4>
												<h4 style={{ color: "blue", fontWeight: "800" }}>
													Rs 12999/-
												</h4>
												<button
													className={style.applyBtn}
                            onClick={() => {
                            ReactGA.event({
															category: "New Course Page Tracks",
															page: "Newcoursepage",
															type: "Button",
															name: "Apply Coupon Standard Track",
                              action: "New Course Page Apply Coupon Standard Track",
														});
														loaderT2 = true;
														setLoadT2(true);
														setApply(true);
														handlePayNow("newcoursepage", "tier2");
													}}>
													Apply Coupon
												</button>
												<div style={{ height: "25px" }}></div>
												<p style={{ color: "#8B8B8B", fontWeight: "600" }}>
													<img src={grey_1} />
													EMI Options &emsp; <img src={grey_2} />
													&nbsp;7 Day refund policy
												</p>
											</div>

											<div className='col-sm-4'>
												<button
													className={style.enrollnow}
                            onClick={() => {
                            ReactGA.event({
															category: "New Course Page Tracks",
															page: "Newcoursepage",
															type: "Button",
															name: "Enroll Now Standard Track",
															action:
																"Enroll Now on New Course Page Standard Track",
														});
														loaderT2 = true;
														setLoadT2(true);
														handlePayNow("newcoursepage", "tier2");
													}}>
													Enroll
												</button>
												<br />
												{/* <button className={style.trynow}>Try Free</button> */}
											</div>
										</div>
									</div>
								) : null}
								{pro ? (
									<div>
										<div className='row'>
											<div className='col-4'>
												<p className={style.head_txt}>Targeted companies</p>
											</div>
											<div className='col-4'></div>
											<div className='col-3'>
												<p className={style.head_txt}>Average Package</p>
											</div>
										</div>

										<div className='row'>
											<div className='col-8'>
												<img
													src={Facebook}
													className={style.faang}
													style={{ marginTop: "-5px" }}
												/>
												<img src={Flipkart} className={style.faang} />
												<img src={Microsoft} className={style.faang} />

												<img src={Amazon} className={style.faang} />
												<img src={Netflix} className={style.faang} />
												<img src={Google} className={style.faang} />
											</div>
											<div className='col-2'>
												<p
													className={style.head_txt}
													style={{
														color: "#FF8726",
														fontSize: "25px",
														marginLeft: "35px",
													}}>
													{" "}
													7 - 10 LPA
												</p>
											</div>
											<hr style={{ color: "blue" }} />
										</div>

										<div className='row'>
											<div className='col-sm-8'>
												<div className='row'>
													<div className={" col-sm-3"}>
														<p>
															{" "}
															<img src={i1} />
															&emsp;60 <br /> DAYS
														</p>
													</div>

													<div className='col-sm-3'>
														<p>
															{" "}
															<img src={i2} />
															&emsp;100+ <br /> HOURS
														</p>
													</div>

													<div className='col-sm-3'>
														<p>
															{" "}
															<img src={i3} />
															&emsp;150+ <br /> ASSIGNMENTS
														</p>
													</div>
												</div>
											</div>

											<div className='col-sm-4'>
												<a
													target='blank'
													className={style.curriculum}
													href='https://courses-upload-bucket.s3.ap-south-1.amazonaws.com/COMPLETE+GUIDE+TO+PLACEMENT+/PRO+COURSE+SYLLABUS.pdf'>
													Know Your Curriculum&emsp;
													<img src={download} />
												</a>
											</div>
										</div>

										<h4 style={{ fontWeight: "bold" }}>Pro Course</h4>

										<p className={style.ftc}>
											Become a master at coding and aptitude with our 2 month
											long PRO course.
										</p>

										<h4 style={{ fontWeight: "bold" }}>What's included?</h4>

										<div className='row'>
											<div className='col-1' style={{}}>
												<img src={tick} />
											</div>
											<div className={" col-10 " + style.wyr}>
												24+ sessions on Aptitude.
											</div>
										</div>
										<p className={style.wyr}>
											<img src={tick} style={{ marginRight: "10px" }} />
											15+ coding sessions on DSA and programming.
										</p>
										<p className={style.wyr}>
											<img src={tick} style={{ marginRight: "10px" }} />
											Mock tests to analyze your performance.
										</p>
										<div className='row'>
											<div className='col-1' style={{}}>
												<img src={tick} />
											</div>
											<div className={" col-10 " + style.wyr}>
												1:1 Mock interviews with top industry experts.
											</div>
										</div>

										<div className='row'>
											<div className='col-1' style={{}}>
												<img src={tick} />
											</div>
											<div className={" col-10 " + style.wyr}>
												5+ soft skills sessions for HR interview.
											</div>
										</div>

										<p className={style.wyr}>
											<img src={tick} style={{ marginRight: "10px" }} />
											Mentorship on building a profile on LinkedIn.
										</p>

										<hr />

										<div className='row'>
											<div className='col-sm-8'>
												<h4
													style={{
														color: "#83ADFF",
														textDecoration: "line-through",
													}}>
													Rs 25000/-
												</h4>
												<h4 style={{ color: "blue", fontWeight: "800" }}>
													Rs 19999/-
												</h4>
												<button
													className={style.applyBtn}
                            onClick={() => {
                            ReactGA.event({
															category: "New Course Page Tracks",
															page: "Newcoursepage",
															type: "Button",
															name: "Apply Coupon PRO Track",
															action:
																"Apply Coupon on New Course Page PRO Track",
														});
														loaderT3 = true;
														setLoadT3(true);
														setApply(true);
														handlePayNow("newcoursepage", "tier3");
													}}>
													Apply Coupon
												</button>
												<div style={{ height: "25px" }}></div>
												<p style={{ color: "#8B8B8B", fontWeight: "600" }}>
													<img src={grey_1} />
													EMI Options &emsp; <img src={grey_2} />
													&nbsp;7 Day refund policy
												</p>
											</div>

											<div className='col-sm-4'>
												<button
													className={style.enrollnow}
                            onClick={() => {
                            ReactGA.event({
															category: "New Course Page Tracks",
															page: "Newcoursepage",
															type: "Button",
															name: "Enroll Now Pro Track",
															action:
																"Enroll Now on New Course Page Pro Track",
														});
														loaderT3 = true;
														setLoadT3(true);
														handlePayNow("newcoursepage", "tier3");
													}}>
													Enroll
												</button>
												<br />
												{/* <button className={style.trynow}>Try Free</button> */}
											</div>
										</div>
									</div>
								) : null}

								{premium ? (
									<div>
										<div className='row'>
											<div className='col-4'>
												<p className={style.head_txt}>Targeted companies</p>
											</div>
											<div className='col-4'></div>
											<div className='col-3'>
												<p className={style.head_txt}>Average Package</p>
											</div>
										</div>

										<div className='row'>
											<div className='col-8'>
												<img
													src={Facebook}
													className={style.faang}
													style={{ marginTop: "-5px" }}
												/>
												<img src={Flipkart} className={style.faang} />
												<img src={Microsoft} className={style.faang} />

												<img src={Amazon} className={style.faang} />
												<img src={Netflix} className={style.faang} />
												<img src={Google} className={style.faang} />
											</div>
											<div className='col-2'>
												<p
													className={style.head_txt}
													style={{
														color: "#FF8726",
														fontSize: "25px",
														marginLeft: "35px",
													}}>
													{" "}
													10 - 15 LPA
												</p>
											</div>
											<hr style={{ color: "blue" }} />
										</div>

										<div className='row'>
											<div className='col-sm-8'>
												<div className='row'>
													<div className={" col-sm-3"}>
														<p>
															{" "}
															<img src={i1} />
															&emsp;90 <br /> DAYS
														</p>
													</div>

													<div className='col-sm-3'>
														<p>
															{" "}
															<img src={i2} />
															&emsp;120+ <br /> HOURS
														</p>
													</div>

													<div className='col-sm-3'>
														<p>
															{" "}
															<img src={i3} />
															&emsp;200+ <br /> ASSIGNMENTS
														</p>
													</div>
												</div>
											</div>

											<div className='col-sm-4'>
												<a
													href='https://courses-upload-bucket.s3.ap-south-1.amazonaws.com/COMPLETE+GUIDE+TO+PLACEMENT+/Premium+Course+Syllabus.pdf'
													className={style.curriculum}
													style={{ fontSize: "10px" }}
													target='blank'>
													Know Your Curriculum&emsp;
													<img src={download} />
												</a>
											</div>
										</div>

										<h4 style={{ fontWeight: "bold" }}>Premium Course</h4>

										<p className={style.ftc}>
											An All-in-one course, tailor-made to help you crack the
											toughest tech interviews and build practical skills with
											hands-on projects.
										</p>

										<h4 style={{ fontWeight: "bold" }}>What's included?</h4>

										<div className='row'>
											<div className='col-1' style={{}}>
												<img src={tick} />
											</div>
											<div className={" col-10 " + style.wyr}>
												Advanced DSA for top companies.
											</div>
										</div>

										<p className={style.wyr}>
											<img src={tick} style={{ marginRight: "10px" }} />
											24+ aptitude sessions.
										</p>
										<div className='row'>
											<div className='col-1' style={{}}>
												<img src={tick} />
											</div>
											<div className={" col-10 " + style.wyr}>
												Learn LinkedIn profile building and networking.
											</div>
										</div>

										<div className='row'>
											<div className='col-1' style={{}}>
												<img src={tick} />
											</div>
											<div className={" col-10 " + style.wyr}>
												1:1 resume sessions and mock interview sessions
											</div>
										</div>

										<div className='row'>
											<div className='col-1' style={{}}>
												<img src={tick} />
											</div>
											<div className={" col-10 " + style.wyr}>
												Soft skills sessions and GD to crack HR round.
											</div>
										</div>

										<div className='row'>
											<div className='col-1' style={{}}>
												<img src={tick} />
											</div>
											<div className={" col-10 " + style.wyr}>
												Mock tests with a performance report.
											</div>
										</div>

										<hr />

										<div className='row'>
											<div className='col-sm-8'>
												<h4
													style={{
														color: "#83ADFF",
														textDecoration: "line-through",
													}}>
													Rs 35000/-
												</h4>
												<h4 style={{ color: "blue", fontWeight: "800" }}>
													Rs29999/-
												</h4>
												<button
													className={style.applyBtn}
                            onClick={() => {
                            ReactGA.event({
															category: "New Course Page Tracks",
															page: "Newcoursepage",
															type: "Button",
															name: "Apply Coupon Premium Track",
															action:
																"Apply Coupon on New Course Page Premium Track",
														});
														loaderT4 = true;
														setLoadT4(true);
														setApply(true);
														handlePayNow("newcoursepage", "tier4");
													}}>
													Apply Coupon
												</button>
												<div style={{ height: "25px" }}></div>
												<p style={{ color: "#8B8B8B", fontWeight: "600" }}>
													<img src={grey_1} />
													EMI Options &emsp; <img src={grey_2} />
													&nbsp;7 Day refund policy
												</p>
											</div>

											<div className='col-sm-4'>
												<button
													className={style.enrollnow}
                            onClick={() => {
                            ReactGA.event({
															category: "New Course Page Tracks",
															page: "Newcoursepage",
															type: "Button",
															name: "Enroll Now Premium Track",
															action:
																"Enroll Now on New Course Page Premium Track",
														});
														loaderT4 = true;
														setLoadT4(true);
														handlePayNow("newcoursepage", "tier4");
													}}>
													Enroll
												</button>
												<br />
												{/* <button className={style.trynow}>Try Free</button> */}
											</div>
										</div>
									</div>
								) : null}
							</div>
						</div>
					) : (
						// -------------------------------------------------------------------------------
						// -------------------------------------------------------------------------------
						// -------------------------------------------------------------------------------
						// -------------------------------------------------------------------------------
						// -------------------------------------------------------------------------------

						// -------------------------------------------------------------------------------
						// -------------------------------------------------------------------------------
						// -------------------------------------------------------------------------------
						// -------------------------------------------------------------------------------
						// -------------------------------------------------------------------------------

						<div style={{ marginTop: "15px", marginLeft: "10px" }}>
							<div>
								{" "}
								{/* Mobile headings */}
								<div
									className='row'
									style={{ marginLeft: "10px", marginRight: "10px" }}>
									<div
										className={
											free
												? " col-5 " + style.mobile_headings_on_2
												: " col-5 " + style.mobile_headings_off_2
										}>
										<p onClick={() => freeHandler()}>Fast Track</p>
									</div>

									<div
										className={
											standard
												? " col-5 " + style.mobile_headings_on_2
												: " col-5 " + style.mobile_headings_off_2
										}>
										<p onClick={() => StandardHandler()}> Standard</p>
									</div>
								</div>
								<div
									className='row'
									style={{ marginLeft: "10px", marginRight: "10px" }}>
									<div
										className={
											pro
												? " col-5 " + style.mobile_headings_on_2
												: " col-5 " + style.mobile_headings_off_2
										}>
										<p onClick={() => ProHandler()}> Pro</p>
									</div>

									<div
										className={
											premium
												? " col-5 " + style.mobile_headings_on_2
												: " col-5 " + style.mobile_headings_off_2
										}>
										<p onClick={() => PremiumHandler()}>Premium</p>
									</div>
								</div>
							</div>

							<div className={style.mob_content}>
								{free ? (
									<div>
										{/* <p className={style.course_begin}>
                      Course begins by <b style={{ color: "red" }}>{course_begin_date}</b>
                    </p> */}

										<p className={style.mob_head_txt + " py-4 "}>
											Targeted companies
										</p>

										<img src={Inf} className={style.mob_logos} />
										<img src={Wipro} className={style.mob_logos} />
										<img src={Gemini} className={style.mob_logos} />
										<img src={TCS} className={style.mob_logos} />
										<img src={Cog} className={style.mob_logos} />
										<img src={HCL} className={style.mob_logos} />

										<p className={style.mob_head_txt}>Average Package</p>

										<p
											className={style.mob_head_txt}
											style={{
												color: "orange",
												fontSize: "35px",
												marginTop: "-10px",
											}}>
											{" "}
											3 - 5 LPA
										</p>

										<hr style={{ color: "blue" }} />

										<div className='row'>
											<div className={" col-4 "}>
												<p className={style.duration_mob}>
													{" "}
													<img src={i1} />
													<b>&nbsp;30</b>
													<br /> DAYS
												</p>
											</div>

											<div className='col-4'>
												<p className={style.duration_mob}>
													{" "}
													<img src={i2} />
													<b>&nbsp;40+</b> <br /> HOURS{" "}
												</p>
											</div>

											<div className='col-4'>
												<p className={style.duration_mob}>
													{" "}
													<img src={i3} />
													<b>&nbsp;90+</b> ASSIGNMENTS
												</p>
											</div>
										</div>

										<div style={{ paddingLeft: "20px" }}>
											<h4 style={{ fontWeight: "bold" }}>Fast Track Course</h4>
											<p
												style={{
													color: "black",
													textAlign: "left",
													paddingRight: "10px",
												}}>
												An efficient crash course to boost your confidence and
												increase your performance before the interviews.
											</p>

											<h4 style={{ fontWeight: "bold" }}>What's included?</h4>

											<div className={" flex-row d-flex " + style.wyr_div}>
												<img src={tick} style={{ maxHeight: "20px" }} />

												<div className={style.wyr}>
													24+ Aptitude sessions with tips and tricks.
												</div>
											</div>

											<div className={" flex-row d-flex " + style.wyr_div}>
												<img src={tick} style={{ maxHeight: "20px" }} />

												<div className={style.wyr}>
													Mock tests to analyze performance.
												</div>
											</div>

											<div className={" flex-row d-flex " + style.wyr_div}>
												<img src={tick} style={{ maxHeight: "20px" }} />

												<div className={style.wyr}>Expert doubt support.</div>
											</div>

											<div className={" flex-row d-flex " + style.wyr_div}>
												<img src={tick} style={{ maxHeight: "20px" }} />

												<div className={style.wyr}>
													1-year access to the course.
												</div>
											</div>

											<div className={" flex-row d-flex " + style.wyr_div}>
												<img src={tick} style={{ maxHeight: "20px" }} />

												<div className={style.wyr}>
													DSA Study material curated by experts.
												</div>
											</div>

											<div style={{ margin: "24px auto" }}>
												<a
													className={style.curriculum}
													target='blank'
													href='https://courses-upload-bucket.s3.ap-south-1.amazonaws.com/COMPLETE+GUIDE+TO+PLACEMENT+/Fast-track+Course+Syllabus.pdf'>
													Know Your Curriculum&emsp;
													<img src={download} />
												</a>
											</div>

											<hr style={{ marginBottom: " 34px" }} />

											<h4
												style={{
													color: "#83ADFF",
													textDecoration: "line-through",
												}}>
												Rs 10000/-
											</h4>
											<h4 style={{ color: "blue" }} className={style.price}>
												Rs 7499/-
											</h4>

											<button
												className={style.applyBtn}
												style={{ display: "inline" }}
                            onClick={() => {
                          ReactGA.event({
														category: "New Course Page Tracks",
														page: "Newcoursepage",
														type: "Button",
														name: "Apply Coupon  Fast Track",
														action: "Apply Coupon on New Course Page Fast Track",
													});
													loaderT1 = true;
													setLoadT1(true);
													setApply(true);
													handlePayNow("newcoursepage", "tier1");
												}}>
												Apply Coupon
											</button>

											<p style={{ color: "#8B8B8B" }}>
												<img src={grey_1} style={{ marginRight: " 5px" }} />
												EMI Options &emsp;
												<img src={grey_2} style={{ marginRight: " 5px" }} />7
												Day refund policy
											</p>

											<div className='row'>
												<div
													className={" col-6 " + style.enrollnow}
													style={{ marginLeft: "20%" }}>
													<button
														className={style.enrollnow_txt}
														style={{ display: "inline" }}
                                onClick={() => {
                              ReactGA.event({
																category: "New Course Page Tracks",
																page: "Newcoursepage",
																type: "Button",
																name: "Enroll Now Fast Track",
																action:
																	"Enroll Now on New Course Page Fast Track",
															});
															loaderT1 = true;
															setLoadT1(true);
															handlePayNow("newcoursepage", "tier1");
														}}>
														Enroll
													</button>
												</div>

												<div
													className={" col-6 " + style.trynow}
													style={{ marginLeft: "20%" }}>
													<button
														className={style.enrollnow_txt}
														style={{
															display: "inline",
															background: "none",
															color: "#185adb",
														}}
														onClick={FastTrackFreeEnroll}>
														Try Free
													</button>
												</div>
											</div>
										</div>
									</div>
								) : null}

								{standard ? (
									<div>
										{/* <p className={style.course_begin}>
                      Course begins by <b style={{ color: "red" }}>{course_begin_date}</b>
                    </p> */}

										<p className={style.mob_head_txt + " py-4 "}>
											Targeted companies
										</p>

										<img src={Inf} className={style.mob_logos} />
										<img src={Wipro} className={style.mob_logos} />
										<img src={Gemini} className={style.mob_logos} />
										<img src={TCS} className={style.mob_logos} />
										<img src={Cog} className={style.mob_logos} />
										<img src={HCL} className={style.mob_logos} />

										<p className={style.mob_head_txt}>Average Package</p>

										<p
											className={style.mob_head_txt}
											style={{
												color: "orange",
												fontSize: "35px",
												marginTop: "-10px",
											}}>
											{" "}
											5 - 7 LPA
										</p>

										<hr style={{ color: "blue" }} />

										<div className='row'>
											<div className={" col-4 "}>
												<p className={style.duration_mob}>
													{" "}
													<img src={i1} />
													<b>&nbsp;45 </b> <br />
													DAYS
												</p>
											</div>

											<div className='col-4'>
												<p className={style.duration_mob}>
													{" "}
													<img src={i2} />
													<b>&nbsp;80+ </b> <br />
													HOURS
												</p>
											</div>

											<div className='col-4'>
												<p className={style.duration_mob}>
													{" "}
													<img src={i3} />
													<b>&nbsp;120+</b> ASSIGNMENTS
												</p>
											</div>
										</div>

										<div style={{ paddingLeft: "20px" }}>
											<h4 style={{ fontWeight: "bold" }}>Standard Course</h4>
											<p
												style={{
													color: "black",
													textAlign: "left",
													paddingRight: "10px",
												}}>
												Prepare for the placements with the comprehensive
												standard course and get an edge over others.
											</p>

											<h4 style={{ fontWeight: "bold" }}>What's included?</h4>

											<div className={" flex-row d-flex " + style.wyr_div}>
												<img src={tick} style={{ maxHeight: "20px" }} />

												<div className={style.wyr}>
													Excel at competitive coding with our DSA video
													lectures.
												</div>
											</div>

											<div className={" flex-row d-flex " + style.wyr_div}>
												<img src={tick} style={{ maxHeight: "20px" }} />

												<div className={style.wyr}>
													24+ Aptitude sessions with tips and tricks.
												</div>
											</div>

											<div className={" flex-row d-flex " + style.wyr_div}>
												<img src={tick} style={{ maxHeight: "20px" }} />

												<div className={style.wyr}>
													Expert-curated mock tests and performance reports.
												</div>
											</div>

											<div className={" flex-row d-flex " + style.wyr_div}>
												<img src={tick} style={{ maxHeight: "20px" }} />
												<div className={style.wyr}>Quick doubt support.</div>
											</div>

											<div className={" flex-row d-flex " + style.wyr_div}>
												<img src={tick} style={{ maxHeight: "20px" }} />
												<div className={style.wyr}>
													Top-quality study material.
												</div>
											</div>

											<div style={{ margin: "24px auto" }}>
												<a
													href='https://courses-upload-bucket.s3.ap-south-1.amazonaws.com/COMPLETE+GUIDE+TO+PLACEMENT+/Standard+Course+Syllabus.pdf'
													target='blank'
													className={style.curriculum_mob}>
													Know Your Curriculum&emsp;
													<img src={download} />
												</a>
											</div>

											<hr style={{ marginBottom: " 34px" }} />

											<h4
												style={{
													color: "#83ADFF",
													textDecoration: "line-through",
												}}>
												Rs 15000/-
											</h4>
											<h4 style={{ color: "blue" }} className={style.price}>
												Rs 12999/-
											</h4>

											<button
												className={style.applyBtn}
												style={{ display: "inline" }}
                            onClick={() => {
                          ReactGA.event({
														category: "New Course Page Tracks",
														page: "Newcoursepage",
														type: "Button",
														name: "Apply Coupon Standard Track",
														action: "Apply Coupon on New Course Page Standard Track",
													});
													loaderT2 = true;
													setLoadT2(true);
													setApply(true);
													handlePayNow("newcoursepage", "tier2");
												}}>
												Apply Coupon
											</button>

											<p style={{ color: "#8B8B8B" }}>
												<img src={grey_1} style={{ marginRight: " 5px" }} />
												EMI Options &emsp;
												<img src={grey_2} style={{ marginRight: " 5px" }} />7
												Day refund policy
											</p>

											<div className='row'>
												<div
													className={" col-6 " + style.enrollnow}
													style={{ marginLeft: "20%" }}>
													<button
														className={style.enrollnow_txt}
                                onClick={() => {
                              ReactGA.event({
																category: "New Course Page Tracks",
																page: "Newcoursepage",
																type: "Button",
																name: "Enroll Now Standard Track",
																action:
																	"Enroll Now on New Course Page Standard Track",
															});
															loaderT2 = true;
															setLoadT2(true);
															handlePayNow("newcoursepage", "tier2");
														}}
														style={{ display: "inline" }}>
														Enroll
													</button>
												</div>

												<div
													className={" col-6 " + style.trynow}
													style={{ marginLeft: "20%" }}>
													<button
														className={style.enrollnow_txt}
														style={{
															display: "inline",
															background: "none",
															color: "#185adb",
														}}
														onClick={StandardFreeEnroll}>
														Try Free
													</button>
												</div>
											</div>
										</div>
									</div>
								) : null}
								{pro ? (
									<div>
										{/* <p className={style.course_begin}>
                      Course begins by <b style={{ color: "red" }}>{course_begin_date}</b>
                    </p> */}

										<p className={style.mob_head_txt + " py-4 "}>
											Targeted companies
										</p>
										<img
											src={Facebook}
											className={style.faang}
											style={{ marginTop: "-5px" }}
										/>
										<img src={Flipkart} className={style.faang} />
										<img src={Microsoft} className={style.faang} />

										<img src={Amazon} className={style.faang} />
										<img src={Netflix} className={style.faang} />
										<img src={Google} className={style.faang} />

										<p className={style.mob_head_txt}>Average Package</p>

										<p
											className={style.mob_head_txt}
											style={{
												color: "orange",
												fontSize: "35px",
												marginTop: "-10px",
											}}>
											{" "}
											7 - 10 LPA
										</p>

										<hr style={{ color: "blue" }} />

										<div className='flex-row d-flex'>
											<div className={" col-4 "}>
												<p className={style.duration_mob}>
													{" "}
													<img src={i1} />
													<b>&nbsp;60</b>
													<br /> DAYS
												</p>
											</div>

											<div className='col-4'>
												<p className={style.duration_mob}>
													{" "}
													<img src={i2} />
													<b>&nbsp;100+ </b>
													<br /> HOURS
												</p>
											</div>

											<div className='col-4'>
												<p className={style.duration_mob}>
													{" "}
													<img src={i3} />
													<b>&nbsp;150+ </b>
													<br /> ASSIGNMENTS
												</p>
											</div>
										</div>

										<div style={{ paddingLeft: "20px" }}>
											<h4 style={{ fontWeight: "bold" }}>Pro Course</h4>
											<p
												style={{
													color: "black",
													textAlign: "left",
													paddingRight: "10px",
													fontSize: "15px",
												}}>
												Become a master at coding and aptitude with our 2 month
												long PRO course.
											</p>

											<h4 style={{ fontWeight: "bold" }}>What's included?</h4>
											<div className={" flex-row d-flex " + style.wyr_div}>
												<img src={tick} style={{ maxHeight: "20px" }} />
												<div className={style.wyr}>
													24+ sessions on Aptitude.
												</div>
											</div>

											<div className={" flex-row d-flex " + style.wyr_div}>
												<img src={tick} style={{ maxHeight: "20px" }} />
												<div className={style.wyr}>
													15+ coding sessions on DSA and programming.
												</div>
											</div>

											<div className={" flex-row d-flex " + style.wyr_div}>
												<img src={tick} style={{ maxHeight: "20px" }} />
												<div className={style.wyr}>
													Mock tests to analyze your performance.
												</div>
											</div>

											<div className={" flex-row d-flex " + style.wyr_div}>
												<img src={tick} style={{ maxHeight: "20px" }} />
												<div className={style.wyr}>
													1:1 Mock interviews with top industry experts.
												</div>
											</div>

											<div className={" flex-row d-flex " + style.wyr_div}>
												<img src={tick} style={{ maxHeight: "20px" }} />
												<div className={style.wyr}>
													5+ soft skills sessions for HR interview.
												</div>
											</div>

											<div className={" flex-row d-flex " + style.wyr_div}>
												<img src={tick} style={{ maxHeight: "20px" }} />
												<div className={style.wyr}>
													Mentorship on building a profile on LinkedIn.
												</div>
											</div>

											<div style={{ margin: "24px auto" }}>
												<a
													href='https://courses-upload-bucket.s3.ap-south-1.amazonaws.com/COMPLETE+GUIDE+TO+PLACEMENT+/PRO+COURSE+SYLLABUS.pdf'
													className={style.curriculum}
													style={{ fontSize: "10px" }}
													target='blank'>
													Know Your Curriculum&emsp;
													<img src={download} />
												</a>
											</div>

											<hr style={{ marginBottom: " 34px" }} />

											<h4
												style={{
													color: "#83ADFF",
													textDecoration: "line-through",
												}}>
												Rs 25000/-
											</h4>
											<h4 style={{ color: "blue" }} className={style.price}>
												Rs 19999/-
											</h4>

											<button
												className={style.applyBtn}
												style={{ display: "inline" }}
												onClick={() => {
                          loaderT3 = true;
                          ReactGA.event({
														category: "New Course Page Tracks",
														page: "Newcoursepage",
														type: "Button",
														name: "Apply Coupon Pro Track",
														action: "Apply Coupon on New Course Page Pro Track",
													});
													setLoadT3(true);
													setApply(true);
													handlePayNow("newcoursepage", "tier3");
												}}>
												Apply Coupon
											</button>

											<p style={{ color: "#8B8B8B" }}>
												<img src={grey_1} style={{ marginRight: " 5px" }} />
												EMI Options &emsp;
												<img src={grey_2} style={{ marginRight: " 5px" }} />7
												Day refund policy
											</p>

											<div className='row'>
												<div
													className={" col-6 " + style.enrollnow}
													style={{ marginLeft: "20%" }}>
													<button
														className={style.enrollnow_txt}
														onClick={() => {
                              loaderT3 = true;
                              ReactGA.event({
																category: "New Course Page Tracks",
																page: "Newcoursepage",
																type: "Button",
																name: "Enroll Now Pro Track",
																action:
																	"Enroll Now on New Course Page Pro Track",
															});
															setLoadT3(true);
															handlePayNow("newcoursepage", "tier3");
														}}
														style={{ display: "inline" }}>
														Enroll
													</button>
												</div>

												<div
													className={" col-6 " + style.trynow}
													style={{ marginLeft: "20%" }}>
													<button
														className={style.enrollnow_txt}
														style={{
															display: "inline",
															background: "none",
															color: "#185adb",
														}}
														onClick={ProFreeEnroll}>
														Try Free
													</button>
												</div>
											</div>
										</div>
									</div>
								) : null}
								{premium ? (
									<div>
										{/* <p className={style.course_begin}>
                      Course begins by <b style={{ color: "red" }}>{course_begin_date}</b>
                    </p> */}

										<p className={style.mob_head_txt + " py-4 "}>
											Targeted companies
										</p>

										<img
											src={Facebook}
											className={style.faang}
											style={{ marginTop: "-5px" }}
										/>
										<img src={Flipkart} className={style.faang} />
										<img src={Microsoft} className={style.faang} />

										<img src={Amazon} className={style.faang} />
										<img src={Netflix} className={style.faang} />
										<img src={Google} className={style.faang} />

										<p className={style.mob_head_txt}>Average Package</p>

										<p
											className={style.mob_head_txt}
											style={{
												color: "orange",
												fontSize: "35px",
												marginTop: "-10px",
											}}>
											{" "}
											10 - 15 LPA
										</p>

										<hr style={{ color: "blue" }} />

										<div className='row'>
											<div className={" col-4 "}>
												<p className={style.duration_mob}>
													{" "}
													<img src={i1} />
													<b>&nbsp;90 </b>
													<br /> DAYS
												</p>
											</div>

											<div className='col-4'>
												<p className={style.duration_mob}>
													{" "}
													<img src={i2} />
													<b>&nbsp;120+ </b>
													<br /> HOURS
												</p>
											</div>

											<div className='col-4'>
												<p className={style.duration_mob}>
													{" "}
													<img src={i3} />
													<b>&nbsp;200+ </b>
													<br /> ASSIGNMENTS
												</p>
											</div>
										</div>

										<div style={{ paddingLeft: "20px" }}>
											<h4 style={{ fontWeight: "bold" }}>Premium Course</h4>
											<p
												style={{
													color: "black",
													textAlign: "left",
													paddingRight: "10px",
												}}>
												An All-in-one course, tailor-made to help you crack the
												toughest tech interviews and build practical skills with
												hands-on projects.
											</p>

											<h4 style={{ fontWeight: "bold" }}>What's included?</h4>
											<div className={" flex-row d-flex " + style.wyr_div}>
												<img src={tick} style={{ maxHeight: "20px" }} />
												<div className={style.wyr}>
													Advanced DSA for top companies.
												</div>
											</div>
											<div className={" flex-row d-flex " + style.wyr_div}>
												<img src={tick} style={{ maxHeight: "20px" }} />
												<div className={style.wyr}>24+ aptitude sessions.</div>
											</div>
											<div className={" flex-row d-flex " + style.wyr_div}>
												<img src={tick} style={{ maxHeight: "20px" }} />
												<div className={style.wyr}>
													Learn LinkedIn profile building and networking.
												</div>
											</div>
											<div className={" flex-row d-flex " + style.wyr_div}>
												<img src={tick} style={{ maxHeight: "20px" }} />
												<div className={style.wyr}>
													1:1 resume sessions and mock interview sessions
												</div>
											</div>
											<div className={" flex-row d-flex " + style.wyr_div}>
												<img src={tick} style={{ maxHeight: "20px" }} />
												<div className={style.wyr}>
													Soft skills sessions and GD to crack HR round.
												</div>
											</div>
											<div className={" flex-row d-flex " + style.wyr_div}>
												<img src={tick} style={{ maxHeight: "20px" }} />
												<div className={style.wyr}>
													Mock tests with a performance report{" "}
												</div>
											</div>

											<div style={{ margin: "24px auto" }}>
												<a
													href='https://courses-upload-bucket.s3.ap-south-1.amazonaws.com/COMPLETE+GUIDE+TO+PLACEMENT+/Premium+Course+Syllabus.pdf'
													className={style.curriculum}
													target='blank'>
													Know Your Curriculum&emsp;
													<img src={download} />
												</a>
											</div>

											<hr style={{ marginBottom: " 34px" }} />

											<h4
												style={{
													color: "#83ADFF",
													textDecoration: "line-through",
												}}>
												Rs 35000/-
											</h4>
											<h4 style={{ color: "blue" }} className={style.price}>
												Rs 29999/-
											</h4>

											<button
												className={style.applyBtn}
												style={{ display: "inline" }}
												onClick={() => {
													loaderT4 = true;
                          setLoadT4(true);
                          ReactGA.event({
														category: "New Course Page Tracks",
														page: "Newcoursepage",
														type: "Button",
														name: "Apply Coupon  Premium Track",
														action: "Apply Coupon on New Course Page Premium Track",
													});
													setApply(true);
													handlePayNow("newcoursepage", "tier4");
												}}>
												Apply Coupon
											</button>

											<p style={{ color: "#8B8B8B" }}>
												<img src={grey_1} style={{ marginRight: " 5px" }} />
												EMI Options &emsp;
												<img src={grey_2} style={{ marginRight: " 5px" }} />7
												Day refund policy
											</p>
											<div className='row'>
												<div
													className={" col-6 " + style.enrollnow}
													style={{ marginLeft: "20%" }}>
													<button
														className={style.enrollnow_txt}
														onClick={() => {
															loaderT4 = true;
                              setLoadT4(true);
                              ReactGA.event({
																category: "New Course Page Tracks",
																page: "Newcoursepage",
																type: "Button",
																name: "Enroll Now Premium Track",
																action:
																	"Enroll Now on New Course Page Premium Track",
															});
															handlePayNow("newcoursepage", "tier4");
														}}
														style={{ display: "inline" }}>
														Enroll
													</button>
												</div>

												<div
													className={" col-6 " + style.trynow}
													style={{ marginLeft: "20%" }}>
													<button
														className={style.enrollnow_txt}
														style={{
															display: "inline",
															background: "none",
															color: "#185adb",
														}}
														onClick={PremiumFreeEnroll}>
														Try Free
													</button>
												</div>
											</div>
										</div>
									</div>
								) : null}
							</div>
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default Iteration;

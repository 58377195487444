import React, { useState, useEffect } from "react";
import EventBanner from "../Assets/img/banner3.png";
import MentorImg from "../Assets/img/Ellipse25.png";
import DiscordLogo from "../Assets/img/discord_white_logo.png";
import CalendarIcon from "../Assets/img/Calendar.png";
import ClockIcon from "../Assets/img/Clock.png";
import ZoomIcon from "../Assets/img/Zoom_Icon.png";
import RightShiftIcon from "../Assets/img/rightShift_icon.png";
import LeftShiftIcon from "../Assets/img/leftShift_icon.png";
import LeftIcon from "../Assets/img/left_icon.png";
import RightIcon from "../Assets/img/right_icon.png";
import starIcon from "../Assets/img/star4_icon.png";
import GoogleImg from "../Assets/img/google_image.png";
import EventPCard from "../components/EventPCard"
import Footer from "../components/Footer";
import "../Assets/css/specificEventPage.css"
import { useParams} from "react-router-dom";
import { BASE_URL } from "../env";

const SpecificEventPage = () => {
    const { eventId } = useParams();
    const [event,SetEvent] = useState({});

    useEffect(() => {
        fetch(BASE_URL + "/getEvent/"+eventId, {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => res.json())
          .then((data) => {
            SetEvent(data.event);
          });
      }, []);
    
    return (
        <>
            <div className="eventBanner">
                <img src={EventBanner} alt="event image"></img>
            </div>

            <div className="event-type">
                <h3 className='text-t'>Strategy to Crack Product Companies</h3>
                <div className='timerBoxes'>
                    <div className="timer-box1">2 H</div>
                    <div className="timer-box2">23 D</div>
                    <div className="timer-box3">56 M</div>
                    <div className="timer-box4">12 S</div>
                </div>
                <button className="event-type-btn">
                    FREE EVENT
                </button>
            </div>
            <div className="eventTasks">
                <div className='event-date-time'>
                    <div className='event-date'>
                        <img className='events-icon' src={CalendarIcon} alt=""></img>
                        14 FEB 2022
                    </div>
                    <div className='event-time'>
                        <img className='events-icon' src={ClockIcon} alt=""></img>
                        12:00 to 2:00 PM
                    </div>
                    <div className='event-platform'>
                        <img className='events-icon' src={ZoomIcon} alt=""></img>
                        ZOOM
                    </div>
                </div>
                <div className='event-btns'>
                    <button className='event-register-btn'>REGISTER NOW</button>
                    <button className='join-cannel-btn'>
                        <img className='Eventpage-discordImage' src={DiscordLogo} alt=""></img>
                        JOIN CHANNEL</button>
                </div>
            </div>
            <div className='eventDetails'>
                <div className='abouttheEvent'>
                    <div className='aboutEvent-header'>
                        <h4 className='aboutEvent-header-text'>About The Event</h4>
                    </div>
                    <p className='aboutEvent-text'>
                        Mastering Caching is undoubtedly one of the most imperative concepts one<br />
                        needs to learn on their upskilling journey. This will take you one step closer<br />
                        to becoming a solid engineer.
                        <br />
                        <br />
                        Understanding what cache memory is, how it works, and its fundamentals<br />
                        can benefit you in several ways. Learning how to cache data is important<br />
                        because it helps speed up application performance and increase efficiency.
                        <br />
                        <br />
                        In this 10 minute video, Anshuman Singh, Ex-Tech Lead at Facebook will<br />
                        take you through the basics and will provide you with a clearer<br />
                        understanding of Caching Memory. Register Now!
                    </p>
                </div>

                <div className='learning-takeaways'>
                    <div className='learning-takeaways-header'>
                        <h4 className='learning-takeaways-header-text'>Learning Takeaways</h4>
                    </div>
                    <div className="lt-points">
                        <p> <img className='star4' src={starIcon} alt="" />
                            Get a Detailed Action Plan</p>
                        <p><img className='star4' src={starIcon} alt="" />
                            Understand the Psycology</p>
                        <p><img className='star4' src={starIcon} alt="" />
                            SWOT Analysis</p>
                        <p><img className='star4' src={starIcon} alt="" />
                            Doubt Solving</p>
                        <p><img className='star4' src={starIcon} alt="" />
                            How to build Best Resume</p>
                        <p><img className='star4' src={starIcon} alt="" />
                            Projects  </p>
                    </div>
                </div>
            </div>
            <div className='mentor-info'>
                <div className='aboutMentor-header'>

                    <h4 className='aboutEvent-header-text'>About The Mentor</h4>
                </div>
                <p className='aboutEvent-text'>
                    Hi I’m Mario Lugi, an Experienced software engineer with a passion for<br />
                    developing innovative programs that expedite the efficiency and <br />
                    effectiveness of organizational success. Well-versed in technology and<br />
                    writing code to create systems that are reliable and user-friendly. Skilled<br />
                    leader who has the proven ability to motivate, educate, and manage.
                </p>
                <div className='back-ellipse'>
                </div>
                <div className='mentors-detail'>
                    <img className='mentorPhoto' src={MentorImg} alt=""></img>
                    <h3 className='mentorsName'>MARIO LUGI</h3>
                    <p className='mentorsRole'>SDE</p>
                    <img className='mentorsCompany' src={GoogleImg} alt=""></img>
                </div>
            </div>
            <div className='exporeMoreEvents'>
                <div className='box-line'></div>
                <div className='exporeMoreEvents-header'>
                    <h3 className='exporeMoreEvents-header-text'>Explore More Events</h3>
                </div>
                <div className='eventsP-card'>
                    <EventPCard />
                    <EventPCard />
                    <EventPCard />
                </div>
                {/* <div className='left-right-icons'>
                    <img className='leftIcon' src={LeftIcon} alt=""></img>
                    <img className='rightIcon' src={RightIcon} alt=""></img>
                </div> */}
            </div>

            <div className='watchPastEvents'>
                <div className='box-line2'>
                    <div className='pastEvents-header'>
                        <h3 className='pastEvents-header-text'>Watch Past Events</h3>
                    </div>
                </div>
                <div className='eventsP2-card'>
                    <EventPCard />
                    <EventPCard />
                    <EventPCard />
                </div>
                {/* <div className='left-right-icons'>
                    <img src={LeftIcon} alt=""></img>
                    <img src={RightIcon} alt=""></img>
                </div> */}

            </div>

            <div className='studentStories'>
                <div className='box-line3'>
                    <div className='pastEvents-header'>
                        <h3 className='pastEvents-header-text'>Our Student Say</h3>
                    </div>
                </div>
                <div className='studentStories-box'>
                    <img className='leftShift' src={LeftShiftIcon} alt=""></img>

                    <div className='studentStory-container'>
                        <img className='studentStory-image' src={MentorImg} alt=""></img>
                        <div className='studentStory-details'>
                            <h4 className='studentStory-name'>Hannah Smitth</h4>
                            <p className='studentStory-description'>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nibh
                                mauris, nec turpis orci lectus maecenas. Suspendisse sed magna
                                eget nibh in turpis. Consequat duis diam lacus arcu. Faucibus venenatis
                                felis id augue sit cursus pellentesque enim Lorem ipsum dolor sit
                                amet, consectetur adipiscing elit. Cursus nibh mauris, nec turpis
                                orci lectus maecenas. Suspendisse
                            </p>
                        </div>
                    </div>
                    <img className='rightShift' src={RightShiftIcon} alt=""></img>
                </div>
            </div>
            <div className='soecificEventPage-footer'>
                <Footer />
            </div>


        </>
    )
}
export default SpecificEventPage;
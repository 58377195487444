import React, { useEffect, useState } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import "../../Assets/css/codinginstruction.css";
import logo from "../../Assets/img/cantilever-logo.png";
import { useTimer } from "react-timer-hook";
import { BASE_URL } from "../../env";

const CodingInstruction = ({ hidenavbar }) => {
  const location = useLocation();
  const history = useHistory();
  let id = location?.state?.id;
  const [codingTimer, setcodingTimer] = useState("");
  const [codingQuestion, setCodingQuestions] = useState("");
  const [codingInstruction, setCodingInstructions] = useState([]);
  const time = new Date();
  time.setSeconds(time.getSeconds() + 120);

  useEffect(() => {
    hidenavbar(true);
    return () => {
      hidenavbar(false);
    };
  }, []);

  function SetLevelOfExamination() {
    fetch(BASE_URL + "/setlevelofexam/" + id, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
      body: JSON.stringify({
        level: "4",
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
      });
  }

  function GetLevelOfExamination() {
    fetch(BASE_URL + "/getlevelofexam/" + id, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((data) => {
        // alert("hello + 2")

        console.log(data);
        if (data.level.level == "3") {
          SetLevelOfExamination();
        } else {
          console.log(data);
          CheckExamLevelAndSendUser(data.level.level);
        }
        console.log(data);
      });
  }

  function CheckExamLevelAndSendUser(level) {
    if (level == "1") {
      return;
    }

    if (level == "2") {
      // history.push({ pathname: "/quiz-instructions/1", state: { id: id } })
      return;
    }

    if (level == "3") {
      return;
    }
    if (level == "4") {
      return;
    }
    if (level == "5") {
      history.push({ pathname: "/coding-exam/10", state: { id: id } });
    }

    if (level == "6") {
      history.push(`/exam-result/${id}`);
    }
  }

  useEffect(() => {
    GetLevelOfExamination();
    fetch(BASE_URL + "/getparticularexamCode/" + id, {
      headers: {
        "Content-type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setcodingTimer(data?.exam?.Codeexam?.timer);
        setCodingQuestions(data?.exam?.Codeexam.questions?.length);
        setCodingInstructions(data?.exam?.CodeInstruction);
      });
  }, []);

  const MyTimer = ({ expiryTimestamp }) => {
    const { seconds, minutes } = useTimer({
      expiryTimestamp,
      onExpire: () => {
        history.push({ pathname: "/coding-exam/10", state: { id: id } });
      },
    });
    const styles = {
      fontFamily: "Nunito-Bold",
      fontSize: "20px",
      position: "absolute",
      top: "30px",
      right: "40px",
      color: "#263238",
    };

    return (
      <div style={styles}>
        <span>{minutes}</span>:<span>{seconds}</span>
      </div>
    );
  };

  return (
    <>
      <div className="d-grid place-content-center"></div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-5 instructionPageLeft">
            <img src={logo} className="instructionPageLogo" alt="logo" />
            <div className="instructionPageTitle">Coding Exam Instructions</div>
            <div className="row">
              <div className="col-6">
                <div className="instructionPageSubTitle">
                  <i class="fas fa-clock"></i>Test Duration
                </div>
                <div className="instructionPageSubText">{codingTimer} mins</div>
              </div>
              <div className="col-6">
                <div className="instructionPageSubTitle">
                  <i class="fas fa-file-alt"></i>No. of questions
                </div>
                <div className="instructionPageSubText">{codingQuestion}</div>
              </div>
            </div>
          </div>
          <div className="col-xl-7 instructionPageRight">
            <div className="instructionPageRightTitle">Instructions:</div>
            <ol className="instructionPageRightList">
              {codingInstruction.map((value) => {
                return <li>{value}</li>;
              })}
            </ol>
            <MyTimer expiryTimestamp={time} />
            <Link
              to={{ pathname: "/coding-exam/10", state: { id: id } }}
              className="instructionPageBtn"
            >
              Continue
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default CodingInstruction;

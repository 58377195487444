import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { BASE_URL } from "../env";
import configAxios from "../axios.config";
import axios from "axios";
import Img1 from "../Assets/img/hybridexam/pic1.png";
import Img2 from "../Assets/img/hybridexam/pic2.png";
import Img3 from "../Assets/img/hybridexam/pic3.png";
import Img4 from "../Assets/img/hybridexam/pic4.png";
import "../Assets/scss/QuizResult.scss";

// In which part of the HTML metadata is contained?
// "643a886403252e7e70c5ccc8"

interface IQuestionSchema {
  answerSelectionType: string;
  answers: Array<string>;
  correctAnswer: Array<string>;
  explanation: string;
  marks: number;
  messageForCorrectAnswer: string;
  messageForIncorrectAnswer: string;
  question: string;
  questionPic: string;
  questionType: string;
  timer: string;
  topic: string;
  _id: string
}

interface IResultResponse {
  data: {
    _id: string;
    email: string;
    examId: {
      courseId: string;
      attempts: number;
      displayResult: boolean;
      questions: Array<IQuestionSchema>;
      randomQuestionLength?: number;
      outOfScore: number;
    }
    finalScore: number;
    questions_Attempted: Array<string>;
    review: Array<string>;
    score: Array<{
      option: string;
      questionId: string;
      questionNo: string;
      score: number;
      _id: string;
    }>
  }
}

const QuizExamSubmitted = () => {
  const [totalQuestion, setTotalQuestion] = useState<number | null>(null);
  const [scoreArray, setScoreArray] = useState<Array<{
    option: string;
    questionId: string;
    questionNo: string;
    score: number;
    _id: string;
  }>>([]);
  const [quizScore, setQuizScore] = useState(0);
  const [quizReviewLength, setQuizReviewLength] = useState(0);
  const [quizAttemptLength, setQuizAttemptLength] = useState(0);
  const [question, setQuestion] = useState<Array<IQuestionSchema>>([]);
  const { id } = useParams();
  useEffect(() => {
    // const email = localStorage.getItem("emailID");
    configAxios(localStorage.getItem("token")).get<IResultResponse>(`/getlatestquizdata/${id}`).then(({ data }) => {
      setTotalQuestion(data.data.examId.outOfScore);
      setQuizScore(data.data.finalScore);
      let random_ques_length = data.data.examId.randomQuestionLength;
      if (random_ques_length) {
        let attempted_questions = [...data.data.review, ...data.data.questions_Attempted]
        let total_ques = data.data.examId.questions.filter(val => attempted_questions.includes(val._id))
        setQuestion(total_ques);
      } else {
        setQuestion(data.data.examId.questions);
      }
      setScoreArray(data.data.score);
      setQuizReviewLength(data.data.review.length);
      setQuizAttemptLength(data.data.questions_Attempted.length);
    }).catch(err => console.error(err.response))
  }, [id]);

  function decodeHTMLEntities(val: string): string {
    let txt = new DOMParser().parseFromString(val, 'text/html')
    return txt.documentElement.textContent || "";
  }

  function ShowCorrectAnswer(answer: string, questionId): boolean {

    const singleQuestion = question.filter((value) => value._id === questionId);
    // const answers = singleQuestion[0].answers;
    const correctanswer = singleQuestion[0].correctAnswer;
    if (correctanswer[0] == answer) {
      return true;
    } else {
      return false;
    }
  }
  function CheckusersAnswerForCorrectOne(questionId: string, answer: string): boolean {
    const usersAnswer = scoreArray.filter(
      (value) => value.questionId == questionId
    );

    // to check if the user has attempted the answer or not
    if (usersAnswer.length == 1) {
      if (decodeHTMLEntities(usersAnswer[0].option) == answer) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  function CheckusersAnswerforWrongOne(questionId, answer): boolean {
    const userAnswer = scoreArray.filter(
      (value) => value.questionId == questionId
    );
    if (userAnswer.length == 1) {
      if (decodeHTMLEntities(userAnswer[0].option) == answer) {
        //option choosen by the user
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }


  let percentage: number = Math.trunc((quizScore / (totalQuestion ? totalQuestion : 0)) * 100);
  percentage = isNaN(percentage) ? 0 : percentage
  let QuizResult = "Suppp";
  if (percentage >= 75) {
    QuizResult = "Distinction";
  } else if (percentage >= 50) {
    QuizResult = "Good";
  } else if (percentage >= 30) {
    QuizResult = "Can do better";
  } else {
    QuizResult = "Should improve";
  }

  return (
    <>
      <div id="quizResult">
        <div className="headerBanner">Your Final Result</div>
        <div className="subHeader">
          <div className="row gx-3">
            <SubCard imgsrc={Img3} title="1st" text="Attempts" />
            <SubCard
              imgsrc={Img4}
              title={totalQuestion ? `${quizScore}/${totalQuestion}` : `Loading...`}
              text="Marks Obtained"
            />
            <SubCard imgsrc={Img1} title={`${percentage}%`} text="Your Score" />
            <SubCard imgsrc={Img2} title={QuizResult} text="Result" />
          </div>
        </div>
        <div className="row quizDetails mt-3">
          <div className="col-6 detailQues">Total questions</div>
          <div className="col-6 detailAns">{totalQuestion}</div>
          <div className="col-6 detailQues">Correct questions</div>
          <div className="col-6 detailAns">{quizScore}</div>
          <div className="col-6 detailQues">Mark for review</div>
          <div className="col-6 detailAns">{quizReviewLength}</div>
          <div className="col-6 detailQues">Unattempted questions</div>
          <div className="col-6 detailAns">
            {totalQuestion ? totalQuestion - quizAttemptLength : 0}
          </div>
          <div className="col-6 detailQues">Incorrect questions</div>
          <div className="col-6 detailAns">{totalQuestion ? totalQuestion - quizScore : 0}</div>
        </div>
        <div className="headerBanner mt-3 mb-1">Your answers</div>
        {/* checkscore = check if the answer is attempted or not  */}
        {question.map((value, index) => {
          return (
            <div className="quesDetails" key={value._id}>
              <div className="questionHeading">Question {index + 1}</div>
              <code className="codeQuestion">{value.question}</code>
              {value.answers.map((value1, index1) => {
                return (
                  <div className="quizAnswer">
                    <span className="quizAnswerNum">{index1 + 1}.</span>
                    <>
                      {ShowCorrectAnswer(value1, value._id) ? (
                        <>
                          {CheckusersAnswerForCorrectOne(
                            value._id,
                            value1
                          ) ? (
                            <>
                              <span className="userCorrectAnswer">
                                {value1} <i className="fas fa-check-double"></i>
                              </span>
                            </>
                          ) : (
                            <>
                              <span className="correctAnswer">
                                {value1} <i className="fas fa-check"></i>
                              </span>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          {CheckusersAnswerforWrongOne(
                            value._id,
                            value1
                          ) ? (
                            <>
                              <span className="wrongAnswerChoose">
                                {value1} <i className="fas fa-times"></i>
                              </span>
                            </>
                          ) : (
                            <>{value1}</>
                          )}
                        </>
                      )}
                    </>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </>
  );
};

const SubCard = (props) => {
  return (
    <>
      <div className="col-lg-3 col-sm-6 mb-0 subHeaderCards">
        <div className="subHeaderCard">
          <img src={props.imgsrc} className="img-fluid icon" alt="icon" />
          <div className="title">{props.title}</div>
          <div className="text">{props.text}</div>
        </div>
      </div>
    </>
  );
};

const AttemptSequence = [
  "1st",
  "2nd",
  "3rd",
  "4th",
  "5th",
  "6th",
  "7th",
  "8th",
  "9th",
  "10th",
  "11th",
  "12th",
  "13th",
  "14th",
  "15th",
  "16th",
  "17th",
  "18th",
  "19th",
  "20th",
  "21st",
  "22nd",
  "23rd",
  "24th",
  "25th",
  "26th",
  "27th",
  "28th",
  "29th",
  "30th",
  "31st",
];

export default QuizExamSubmitted;

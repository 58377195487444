import { React, useState } from "react";

import useMedia from "../componentsofcourse/useMedia";
// import event_1 from "../Assets/img/Home/event_1.png";
// import event_2 from "../Assets/img/Home/event_2.png";
// import upcoming_guest from "../Assets/img/Home/upcoming_guest.png";
// import live_event from "../Assets/img/Home/live_event.png";
// import Line_62 from "../Assets/img/Home/Line_62.png";
import { BASE_URL } from "../env";
import ReactGA from 'react-ga';

const Masterclass = () => {
  const matches = useMedia("(min-width : 768px )");
  const [email, setEmail] = useState("");
  const [mess, showMsg] = useState(false);
  const [msg, setMsg] = useState(" ");
  const [color, setColor] = useState("");

  async function handleSubmit(e) {
    e.preventDefault();
ReactGA.initialize("UA-198180460-1");
    ReactGA.event({
                                  category: "HomePage",
                                  type: "Button",
                                  name: "Get_Notified_Home_page",
                                  action:
                                    "The user has clicked the Notified button",
                                });

    const wait = await fetch(BASE_URL + "/masterclass/addQuery", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        query: "Recieve Master Class Updates",
      }),
    })
      .then((response) => response.json())
      .then((response2) => {
        setMsg(response2.message);
        showMsg(true);

        setColor("green");
      })
      .catch((err) => {
        setMsg("ERROR!! in sending data please check your internet connection");
        showMsg(true);

        setColor("red");
      });
  }

  return (
    <div style={{ backgroundColor: "#F2F2F2", overflowX: "hidden" }}>
      {/*
            <div className="container p-2" >
            <p style={{marginLeft: "2%"}}><b>Free</b></p>
            <h3 style={{marginLeft: "2%"}}><b>MASTERCLASS</b></h3>
                <div className="row">
                    <div className="col-md-4 col-xs-12 col-12 col-12">
                        <div style={{backgroundColor:'white' , borderRadius:'20px'}}>
                            <br/>
                            <br/>
                            <img className="mx-auto d-block" width="50%" src={upcoming_guest} alt="next event guest" />
                            <br/><br/>
                            <p className="text-center"><b>Vivek Jhaver</b></p>
                            <p className="text-center"><i>Ui designer</i></p>
                            <p className="text-center"><i>Amazon</i></p>
                            <br/>
                            <br/>
                        </div>
                    </div>
                    <div className="col-md-8 col-xs-12 col-12 col-12">
                        <h3 style={{textAlign:'left', wordSpacing: '4px',marginLeft:"20px"}}><b>WHY IS EMPATHY MAPPING IMPORTANT <br /> IN UI</b></h3>
                        <br/>
                        <p style={{textAlign:'left' , fontSize:'20px', letterSpacing: '2px',marginLeft:"20px"}}>
                        know the core values of user <br/>
                        research and important uses of<br/>
                        empathy mapping & get updated
                        </p>
                        <div className="row" style={{marginTop:"40px"}}>
                            <div className="col-md-6 col-xs-12 col-12 col-12">
                                <table style={{marginLeft:"10px"}}>
                                    <tr>
                                        <td ><h3><b>SUN</b></h3></td>
                                        <td style={{paddingLeft:'20px'}}><h3><b>APR</b></h3></td>
                                        <td style={{paddingLeft:'20px'}}><h3><b>05</b></h3></td>
                                    </tr>
                                    <tr>
                                        <td><h3><b>DAY</b></h3></td>
                                        <td><h3><b> 25</b></h3></td>
                                        <td><h3><b>PM</b></h3></td>
                                    </tr>
                                    <tr>
                                        <td>
                                        </td>
                                    </tr>
                                </table>
                                <a href="" style={{ fontSize:'18px' , borderRadius:'25px' , width:'60%' ,  fontWeight:'bold' , marginLeft:'-35%'}} className="btn btn-warning text-white " >REGISTER</a>
                            </div>
                             <div className="col-md-6 col-xs-12 col-12 col-12">
                                <img className="mx-auto d-block" width="60%" src={live_event} alt="live event" />
                            </div>
                        </div>
                    </div>
                </div>
                </div> */}

      {/*  media query */}
      {matches ? (
        <div>
          <div
            className="container-fluid"
            style={{ backgroundColor: "#185ADB" }}
          >
            <br />
            <br />
            <h4 className="text-center text-white">
              Our previous MASTERCLASSES
            </h4>
            <br />
            <br />
            <div className="container">
              <div className="row">
                <div className="col-5">
                  <iframe
                    className="mx-auto d-block"
                    width="100%"
                    height="250px"
                    style={{ borderRadius: "15px" }}
                    src="https://www.youtube.com/embed/fqmyHS8SznQ"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
                <div className="col">
                  <img src={'https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644063619188_Line_62.png'} className="mx-auto d-block" />
                </div>
                <div className="col-5">
                  <iframe
                    className="mx-auto d-block"
                    width="100%"
                    height="250px"
                    style={{ borderRadius: "15px" }}
                    src="https://www.youtube.com/embed/OqhT_f9pNpg"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </div>
            <br />
            <br />
          </div>
        </div>
      ) : (
        <div>
          <div
            className="container-fluid"
            style={{ backgroundColor: "#00A2C8" }}
          >
            <br />
            <br />
            <br />
            <h4 className="text-center text-white">
              Our previous MASTERCLASSES
            </h4>
            <br />
            <br />
            <div className="">
              {/* <img width="100%" src={event_1} alt="kick start to crack product companies" /> */}
              <iframe
                className="mx-auto d-block"
                width="90%"
                height="220px"
                style={{ borderRadius: "15px" }}
                src="https://www.youtube.com/embed/fqmyHS8SznQ"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
            <br />
            <br />
            <div className="">
              <div
                className="mx-auto d-block"
                style={{ borderBottom: "6px solid white", width: "80%" }}
              ></div>
            </div>
            <br />
            <br />
            <div className="">
              {/*  <img width="100%" src={event_2} alt="kick start to crack product companies" /> */}
              <iframe
                className="mx-auto d-block"
                width="90%"
                height="220px"
                style={{ borderRadius: "15px" }}
                src="https://www.youtube.com/embed/OqhT_f9pNpg"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>

            <br />
            <br />
          </div>
        </div>
      )}
      <div className="text-center">
        <br />
        <br />
        <h4>Stay UPDATED with our MASTERCLASSES</h4>
        <br />
        {mess === false ? (
          <div>
            <input
              onChange={(event) => setEmail(event.target.value)}
              style={{
                color: "grey",
                borderRadius: "36px",
                width: "35%",
                height: "40px",
                border: "none",
                textAlign: "center",
              }}
              className="text-dark"
              type="email"
              placeholder="Type your email ID"
            />
            <br />
            <br />
            <button
              style={{
                padding: "10px",
                width: "220px",
                fontSize: "20px",
                borderRadius: "36px",
                backgroundColor: "#185adb",
                // color: "white",
              }}
              onClick={handleSubmit}
              className=" btn  text-white"
            >
              Get Notified
            </button>
          </div>
        ) : (
          <p style={{ color: color }}>{msg}</p>
        )}

        <br />
        <br />
      </div>
    </div>
  );
};

export default Masterclass;

import React from "react";
import "../Assets/css/boost.css";
import Media from "react-media";
import Slider from "../componentsofcourse/Slider";
import useMedia from  "../componentsofcourse/useMedia"
import companyLogo3 from "../Assets/img/companylogos/company-logo3.png";
import companyLogo4 from "../Assets/img/companylogos/company-logo4.png";
import companyLogo7 from "../Assets/img/companylogos/company-logo7.png";
import companyLogo8 from "../Assets/img/companylogos/company-logo8.png";
import companyLogo11 from "../Assets/img/companylogos/company-logo11.png";
import companyLogo18 from "../Assets/img/companylogos/company-logo18.png";
import Board from "../Assets/img/Home/board.png";
// import Group_7433 from "../Assets/img/Home/Group_7433.png";
// import Group_7434 from "../Assets/img/Home/Group_7434.png";
// import Group_7435 from "../Assets/img/Home/Group_7435.png";
import Group_7442 from "../Assets/img/Home/Group_7442.png";

const MeetPartners = () => {
  const styles = {
    mobImgs: {
      display: "flex",
      "flex-direction": "row",
      "flex-wrap": "wrap",
      position: "relative",
      left: "4%",
      margin: "24px 0 24px 0",
    },
    mobImg: {
      position: "relative",
      width: "30%",
    },
  };
  const matches = useMedia("(max-width : 575px )");
  return (
    <div>
      <div className="body mx-auto">
    <br/>
        {/* <div className={styles.HeadingCont}> */}
          <h1
            
            style={{ fontSize: `${matches ? "1.4rem" : ""}`,fontWeight: "bold",marginTop:"2rem" }}
          >
            Our Alumni Placed At Leading Companies
          </h1>
        

          <Slider />
       
        {/* </div> */}
        {/* <Media query="(max-width: 650px)">
          {(matches) => {
            return matches ? (
              <div>
                <div style={styles.mobImgs}>
                  <img style={styles.mobImg} src={companyLogo3} alt="Company Logos" />
                  <img style={styles.mobImg} src={companyLogo8} alt="Company Logos" />
                  <img style={styles.mobImg} src={companyLogo4} alt="Company Logos" />
                  <img style={styles.mobImg} src={companyLogo7} alt="Company Logos" />
                  <img style={styles.mobImg} src={companyLogo11} alt="Company Logos" />
                  <img style={styles.mobImg} src={companyLogo18} alt="Company Logos" />
                </div>
              </div>
            ) : (
              <div className="container">
                <div className="row">
                  <div className="col-lg-2 col-md-2 col-xs-4 col-sm-4 col-4">
                    <img
                      src={companyLogo3}
                      className="img-fluid mx-auto d-block"
                      alt="Company Logos"
                    />
                  </div>
                  <div className="col-lg-2 col-md-2 col-xs-4 col-sm-4 col-4">
                    <img
                      src={companyLogo8} alt="Company Logos"
                      className="img-fluid mx-auto d-block"
                    />
                  </div>
                  <div className="col-lg-2 col-md-2 col-xs-4 col-sm-4 col-4">
                    <img
                      src={companyLogo4} alt="Company Logos"
                      className="img-fluid mx-auto d-block"
                    />
                  </div>
                  <div className="col-lg-2 col-md-2 col-xs-4 col-sm-4 col-4">
                    <img
                      src={companyLogo7} alt="Company Logos"
                      className="img-fluid mx-auto d-block"
                    />
                  </div>
                  <div className="col-lg-2 col-md-2 col-xs-4 col-sm-4 col-4">
                    <img
                      src={companyLogo11} alt="Company Logos"
                      className="img-fluid mx-auto d-block"
                    />
                  </div>
                  <div className="col-lg-2 col-md-2 col-xs-4 col-sm-4 col-4">
                    <img
                      src={companyLogo18} alt="Company Logos"
                      className="img-fluid mx-auto d-block"
                    />
                  </div>
                </div>
              </div>
            );
          }}
        </Media> */}
      </div>
      <div style={{ backgroundColor: "#FAFAFA" }} className="row">
     
        <div class="container-fluid boost-career">
          <div class="row">
            <div class="col-xl-12 col-sm-12 p-4 text-center align-items-center">
              <h1 style={{ textAlign: "center" }}>
                {" "}
                <b>BOOST</b> your <i>career</i>{" "}
              </h1>
            </div>
            <div class="row row-boost" style={{ width:"80%" ,margin:"0 50px"}}>
              <div
                style={{ marginLeft: "12px", marginRight: "12px" }}
                class="col-xl-2 col-lg-3 col-md-3 part-1"
              >
                <div>
                  <img src={'https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644210106841_Group_7435.png'} alt="Company Logos"/>
                </div>
                <div>
                  <h4>
                    <b>Learn</b>{" "}
                  </h4>
                  <p>
                    Learn from <b>industry experts</b> & get Doubts clarified in
                    less than <b>30min</b>{" "}
                  </p>
                </div>
              </div>
              <div
                style={{ marginLeft: "12px", marginRight: "12px" }}
                class="col-xl-2 col-lg-3 col-md-3 part-2"
              >
                <div>
                  <img src={'https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644210033208_Group_7434.png'} alt="Company Logos" />
                </div>
                <div>
                  <h4>
                    <b>Code</b>
                  </h4>
                  <p>
                    Hands on <b>Project</b> based learning programs in a{" "}
                    <b> community</b> based format
                  </p>
                </div>
              </div>
              <div
                style={{ marginLeft: "12px", marginRight: "12px" }}
                class="col-xl-2 col-lg-3 col-md-3 part-3"
              >
                <div>
                  <img src={'https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644209974026_Group_7433.png'} alt="Company Logos" />
                </div>
                <div>
                  <h4>
                    <b> Upskill</b>
                  </h4>
                  <p>
                    Get <b>certified</b> , Get <b>Internship</b> Land up in{" "}
                    <b>dream job </b>{" "}
                  </p>
                </div>
              </div>
              <div
                class="col-xl-5 col-lg-3 col-md-3 boost-img-div"
                style={{ marginBottom: "-16px" }}
              >
                <lottie-player
            src="https://assets5.lottiefiles.com/packages/lf20_istdhmqj.json"
            background="transparent"
            speed=".9"
            style={{ height: "400px",width:"850px" }}
            autoplay
            loop
          ></lottie-player>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MeetPartners;

import React, { useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import BlogHeadImg from "../Assets/img/newblog-head.jpg";
import "../Assets/css/newBlog.css";
import HelmetComp from "../components/HelmetComp";
function NewBlog() {
  const [data, setData] = useState({
    name: "",
    email: "",
    website: "",
    comment: "",
  });

  const InputEvent = (event) => {
    const { name, value } = event.target;

    setData((preVal) => {
      return {
        ...preVal,
        [name]: value,
      };
    });
  };

  const formSubmit = (e) => {
    e.preventDefault();
    alert(`Thank you for your message. We will response you asap ${data.name}`);
  };
  return (
    <>
      {/* <Navbar /> */}
      <div className="container my-5">
        <div className="row">
          <div className="col-12 top-btns">
            <div className="top-btn">
              <a href="">CodeStudio</a>
            </div>
            <div className="top-btn">
              <a href="">General</a>
            </div>
          </div>

          <div className="col-12">
            <h2 className="blog-page-heading">
              Cantilever Labs 2021: All You <br /> Must Know About This Tech
              Event
            </h2>
          </div>
          <div className="col-12">
            <div className="social-icons">
              <a
                href="https://www.instagram.com/cantilever_labs/"
                className="icon"
              >
                <span className="fa fa-instagram"></span>
              </a>
              <a
                href="https://www.linkedin.com/company/cantileverlabs/"
                className="icon"
              >
                <span className="fa fa-linkedin"></span>
              </a>
              <a
                href="https://www.facebook.com/Cantilever-labs-102916474752041/"
                className="icon"
              >
                <span className="fa fa-facebook-square"></span>
              </a>
            </div>
          </div>
          <div className="col-12">
            <p className="blog-head-date">August 12, 2021</p>
          </div>
          <div className="col-12">
            <img src={BlogHeadImg} className="img-fluid" alt="new blog head" />
          </div>
          <div className="col-md-8">
            <div className="table-box">
              <p>Table of Contents</p>
              <a href="">Introduction </a>
              <br />
              <a href="">About Cantilever Labs</a>
              <br />
              <a href="">Eligibility Criteria for Cantilever Labs 2021</a>
              <br />
              <a href="">Cantilever Labs Contest Rules</a>
              <br />
              <a href="">Exam Pattern</a>
              <br />
              <a href="">Prerequisites (Languages) </a>
              <br />
              <a href="">Registration for Cantilever Labs 2021</a>
              <br />
              <a href="">Preparation Tips </a>
              <br />
              <a href="">Frequently Asked Questions</a>
              <br />
              <a href="">Key Takeaways</a>
              <br />
            </div>
          </div>
          <div className="col-md-8">
            <h4 className="blog-heading">Introduction</h4>
            <p className="blog-text">
              Aiming to recognise emergent coding talents in India,
              <strong>Cantilever Labs</strong>, India’s biggest tech hunt, is
              back with more exciting opportunities and prizes up to worth INR
              4.2 lakhs. <strong>Cantilever Labs</strong> 2021 is specially
              designed for all the tech junkies out there to showcase their
              coding talent and to identify the best programming talents across
              India.
              <br />
              <br />
              <strong>Cantilever Labs</strong> is hosted by TechGig.com, India’s
              number one platform for IT enthusiasts and coders who can provide
              end-to-end solutions to all IT-related problems.
              <br />
              <br />
              If you are a tech whiz and want to mark your presence at
              Cantilever Labs 2021, it’s time to take your first step with
              Coding Ninjas and engage yourself in finding practical solutions
              to complex problems. With over 20,000 students from 300 top
              colleges enrolled in our courses.
              <br />
              <br />
              Cantilever Labs dedicatedly works towards preparing you for the
              best job opportunities in IT. If you have the courage and
              commitment to explore and learn to code, Cantilever Labs is the
              one-stop platform that can empower you to shape your career, the
              way you’ve dreamt it.
              <br />
              <br />
              Enrol now at Cantilever Labs, and take your first step towards
              success!
              <br />
              <br />
            </p>
            <h4 className="blog-heading">About Cantilever Labs</h4>
            <p className="blog-text">
              Cantilever Labs is a unique concept where the best coders from all
              over the country meet under one roof, get a chance to present
              their ideas on a national platform, participate in various
              contests, and win attractive prizes, along with an opportunity to
              get interviewed by top companies.
              <br />
              <br />
              Is that all? No! Cantilever Labs 2021 also awards ‘The Best Coder’
              recognition at the national level.
              <br />
              <br />
              Do not sleep on this chance to ride this exciting roller coaster
              towards your dream IT job. Begin your journey with Coding Ninjas
              with one of our free online programming courses. Together, let us
              give your career trajectory that much-needed first push!
              <br />
              <br />
            </p>
            <h4 className="blog-heading">
              Eligibility Criteria for Cantilever Labs 2021
            </h4>
            <p className="blog-text">
              <strong>Cantilever Labs</strong> is open to:
              <br />
              <br />
              <ul>
                <li>
                  Final-year engineering students from all streams or students
                  from MCA colleges, approved by AICTE and UGC respectively
                </li>
                <li>Third-year engineering students from all streams</li>
                <li>
                  The contest is valid for engineering students who reside in
                  India.
                </li>
              </ul>
              <br />
              You can participate in Cantilever Labs 2021 if you come from these
              branches of study as well:
              <br />
              <br />
              <ul>
                <li>Instrumentations</li>
                <li>Electronics</li>
                <li>Electrical</li>
                <li>Mechatronics</li>
                <li>Computer Science, or any other branch</li>
              </ul>
              <br />
              Besides, there is no participant fee or money deposits at any
              stage of the event!
              <br />
              <br />
            </p>
            <h4 className="blog-heading">Cantilever Labs Contest Rules</h4>
            <p className="blog-text">
              <ul>
                <li>
                  <strong>Cantilever Labs</strong> 2021 will have two rounds:
                  Round 1 and Round 2.
                </li>
                <li>
                  Each round will have two types of participation:
                  <ul style={{ listStyleType: "disc" }}>
                    <li>Intra-college Round</li>
                    <li>Open Round</li>
                  </ul>
                </li>
                <li>
                  In the Intra-college Round, participants from the same college
                  will compete against each other to win and mark their place in
                  Round 2.
                </li>
                <li>
                  Participants from the respective colleges need to register
                  themselves via intra-college round registration.
                </li>
                <li>
                  In an open round, participants from all other colleges compete
                  to reach Round 2.
                </li>
                <li>
                  In case of multiple participant profiles, he/she will be
                  disqualified then and there from the event.
                </li>
              </ul>
            </p>
          </div>
          <div className="col-md-8">
            <h4 className="blog-form-heading">Leave a Reply</h4>
            <p className="blog-form-desc">
              <i>Your email address will not be published</i>. Required fields
              are marked *
            </p>
            <form onSubmit={formSubmit} className="row">
              <div className="col-12">
                <div className="form-group">
                  <label className="sr-only">Comment </label>
                  <textarea
                    className="form-control p-3"
                    rows="8"
                    name="comment"
                    required
                    value={data.comment}
                    onChange={InputEvent}
                    placeholder="Leave your comment..."
                  ></textarea>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label className="sr-only">Name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    required
                    value={data.name}
                    onChange={InputEvent}
                    placeholder="Name"
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label className="sr-only">Email</label>
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    required
                    value={data.email}
                    onChange={InputEvent}
                    placeholder="Email"
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label className="sr-only">Website</label>
                  <input
                    type="text"
                    className="form-control"
                    name="website"
                    required
                    value={data.website}
                    onChange={InputEvent}
                    placeholder="Website"
                  />
                </div>
              </div>
              <div className="col-12 m-0">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="gridCheck"
                  />
                  <label className="form-check-label" for="gridCheck">
                    Save my name, email, and website in this browser for the
                    next time I comment.
                  </label>
                </div>
              </div>
              <div className="col-12">
                <button type="submit" className="btn-submit">
                  Post Comment
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default NewBlog;

import { Tooltip } from "@mui/material";
import "../Assets/css/DiffSection.scss";
const companies = {
  Amazon:
    "https://cantileverlabs-gradient-images.s3.ap-south-1.amazonaws.com/company+logos/amazon_PNG13.png",
  Facebook:
    "https://cantileverlabs-gradient-images.s3.ap-south-1.amazonaws.com/company+logos/facebook_logo.png",
  TCS: "https://cantileverlabs-gradient-images.s3.ap-south-1.amazonaws.com/company+logos/TCS_LOGO.png",
  Wipro:
    "https://cantileverlabs-gradient-images.s3.ap-south-1.amazonaws.com/company+logos/IMG-20220923-WA0006.jpg",
  Microsoft:
    "https://cantileverlabs-gradient-images.s3.ap-south-1.amazonaws.com/company+logos/IMG-20220923-WA0003.jpg",
  Google:
    "https://cantileverlabs-gradient-images.s3.ap-south-1.amazonaws.com/company+logos/IMG-20220923-WA0007.jpg",
};

function DifficultyCards(props) {
  const { level, category, name, link, company } = props;

  function getButtonStyle(level) {
    switch (level) {
      case "Medium":
        return {
          background: "rgba(255, 194, 1, 0.3)",
          color: "rgb(255, 194, 1)",
          fontWeight: "bold",
        };

      case "Hard":
        return { background: "##fd2222" };

      default:
        return { background: "#79d04d" };
    }
  }
  return (
    <a
      className="col-md-4 col-sm-6 mb-md-4 mb-2"
      href={link}
      target="_blank"
      rel="noreferrer"
    >
      <div className="diffcultyCard">
        <div className="diffcultyCardHead">
          <div className="diffcultyCodingName">{name}</div>
          <div className="diffcultyResult"></div>
        </div>
        <hr />
        <div className="diffcultyCardFoot">
          <div className="diffcultyBtn" style={getButtonStyle(level)}>
            {level}
          </div>
          {category && (
            <div
              className="diffcultyBtn font-weight-bold"
              style={{ background: "#9F9F9F" }}
            >
              {category}
            </div>
          )}
        </div>
        <div className="diffcultyCardBody">
          <ul className="diffcultyType">
            {company.map((val, index) => {
              return (
                <li
                  key={val._id}
                  className="diffcultyTypeItem"
                >
                  <Tooltip title={val.name}>
                  <img
                    className=" mt-1"
                    src={val.logo}
                    alt={`${val.name} logo`}
                    height={30}
                    width={30}
                    style={{
                      objectFit: "contain",
                    }}
                  />
                  </Tooltip>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </a>
  );
}

export default DifficultyCards;

import React, { useState, useEffect, useContext } from "react";

// import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";

import style from "../Assets/css/IterationPart2.module.css";
import useMedia from "../componentsofcourse/useMedia";

import tick from "../Assets/img/Iteration/tick_icon.png";
import grey_1 from "../Assets/img/Iteration/grey_1.png";
import grey_2 from "../Assets/img/Iteration/grey_2.png";
import ReactGA from "react-ga";
import duration from "../Assets/img/Iteration/duration.svg";
import { BASE_URL } from "../env";

import g_cart from "../Assets/img/Iteration/green_cart.png";
import cart_icon from "../Assets/img/Iteration/cart_icon.png";

import Coupons1 from "../components/NewCoupons.js";
import { useHistory, useLocation } from "react-router-dom";

import { AuthContext } from "../Context/AuthContext";
import download from "../Assets/img/Iteration/download.png";
import addedToCart from "../Assets/img/Iteration/addedToCart.png";
import cart_greeencart from "../Assets/img/Iteration/cart_greencart.png";
import avail_discount from "../Assets/img/Iteration/avail_discount.png";

import { Link } from "react-scroll";

const IterationPart2 = () => {
	let loaderT1 = false;
	ReactGA.initialize("UA-198180460-1");

	const history = useHistory();
	const location = useLocation();

	const [loadT1, setLoadT1] = useState(false);
	const [loadT2, setLoadT2] = useState(false);
	const [loadT3, setLoadT3] = useState(false);
	const [loadT4, setLoadT4] = useState(false);

	let { token } = useContext(AuthContext);

	const [signIn, setSignIn] = useState(false);
	const [coupon, setCoupon] = useState(false);
	const [tierId, setTierId] = useState("");

	let [tier, setTier] = useState("");

	const [pay, setPay] = useState(false);
	const [apply, setApply] = useState(false);

	const [data, setData] = useState([]);
	const [couponCode, setCouponCode] = useState("");

	const matches = useMedia("(min-width : 1201px )");
	const before_mobile_view = useMedia("(min-width : 770px )");
	const before_mobile_cart_view = useMedia("(min-width : 1000px )");

	const [addd, setAddd] = useState(true);
	const [prog_dsa, setProg_dsa] = useState(true);
	const [apti, setApti] = useState(false);
	const [iprep, setIprep] = useState(false);
	const [company_mock, setCompany_mock] = useState(false);
	const [mpsolving, setmpsolving] = useState(false);

	const [cart, setCart] = useState(false);

	const [cart_items_price, setCart_items_price] = useState([]);
	const [cart_items_price1, setCart_items_price1] = useState([]);
	const [cart_items_prog_dsa, setcart_items_prog_dsa] = useState(false);
	const [cart_items_apti, setCart_items_apti] = useState(false);
	const [cart_items_iprep, setCart_items_iprep] = useState(false);
	const [cart_items_mpsolving, setCart_items_mpsolving] = useState(false);
	const [cart_items_cm, setCart_items_cm] = useState(false);

	const [cart_item_tier, setCart_item_tier] = useState([]);
	const [totalAmount, setTotalAmount] = useState();

	const [tierIds, settierIds] = useState([]);

	useEffect(() => {
		fetch(BASE_URL + "/getFromRoute?route=fasttrackcourse")
			.then((response) => response.json())
			.then((data) => {
				// console.log(data);
				const tiers = data.course.types;
				const curr = tiers[tier];
				settierIds(data.course.types);
			});
	}, []);

	useEffect(() => {
		// console.log(cart_item_tier);

		fetch(`${BASE_URL}/razorpayCart`, {
			method: "POST",
			headers: {
				"Content-type": "application/json",
				Authorization: `Bearer ${localStorage.getItem("token")}`,
			},
			body: JSON.stringify({
				courseIds: cart_item_tier,
			}),
		})
			.then((res) => res.json())
			.then((data) => {
				setTotalAmount(data.amount / 100);
			})
			.catch((err) => {
				// console.log(err)
			});
	}, [cart_item_tier]);

	function addToCart(val, tier) {
		if (!token) {
			history.push("/register");
			localStorage.setItem('userlocation', location.pathname);
		}

		setCart_item_tier((prev) => [...prev, tier]);

		// if (cart_items_price.length > 2) {
		// setCart_items_price1(cart_items_price1.concat(val));
		// }

		// if (cart_items_price.length <= 2) {
		setCart_items_price(cart_items_price.concat(val));
		// }

		setCart(true);
	}

	let sum_cart = cart_items_price.length + cart_items_price1.length;
	let disc_cart = (sum_cart - 1) * 500;
  function prog_dsaHandler() {
    ReactGA.event({
			category: "Crash course page",
			type: "Crash course page",
			name: "Crash course Programming and DSA ",
			action: "Crash course Programming and DSA ",
		});
		setProg_dsa(true);
		setmpsolving(false);
		setIprep(false);
		setApti(false);
		setCompany_mock(false);
	}

  function IprepHandler() {
     ReactGA.event({
				category: "Crash course page",
				type: "Crash course page",
				name: "Crash course Interview Preparation ",
				action: "Crash course Interview Preparation",
			});
		setProg_dsa(false);
		setmpsolving(false);
		setIprep(true);
		setApti(false);
		setCompany_mock(false);
	}

  function AptiHandler() {
     ReactGA.event({
				category: "Crash course page",
				type: "Crash course page",
				name: "Crash course Aptitude",
				action: "Crash course Aptitude",
			});
		setProg_dsa(false);
		setmpsolving(false);
		setIprep(false);
		setApti(true);
		setCompany_mock(false);
	}

  function mpsolvingHandler() {
     ReactGA.event({
				category: "Crash course page",
				type: "Crash course page",
				name: "Crash course Master Problem Solving ",
				action: "Crash course Master Problem Solving",
			});
		setProg_dsa(false);
		setmpsolving(true);
		setIprep(false);
		setApti(false);
		setCompany_mock(false);
	}

  function Company_mockHandler() {
     ReactGA.event({
				category: "Crash course page",
				type: "Crash course page",
				name: "Crash course Mock Test",
				action: "Crash course Mock Test",
			});
		setProg_dsa(false);
		setmpsolving(false);
		setIprep(false);
		setApti(false);
		setCompany_mock(true);
	}

	useEffect(() => {
		if (sum_cart === 0) {
			setCart(false);
		}
	}, [sum_cart]);

	function removeFromCart(ind, val) {
		if (sum_cart === 0) {
			setCart(false);
		}

		// cart_items_price1.splice(ind, 1);
		setCart_item_tier((prev) => {
			return prev.filter((value) => {
				return value !== cart_item_tier[ind];
			});
		});

		setCart_items_price((prev) => {
			return prev.filter((value) => {
				return value !== val;
			});
		});

		if (val === "Programming and DSA") {
			setcart_items_prog_dsa(false);
		} else if (val === "Aptitude") {
			setCart_items_apti(false);
		} else if (val === "Interview preparation") {
			setCart_items_iprep(false);
		} else if (val === "Mock Test") {
			setCart_items_cm(false);
		} else if (val === "Master Problem Solving") {
			setCart_items_mpsolving(false);
		}
	}

	async function handlePayNow(route, tier) {
		if (!token) {
			setSignIn(true);
		} else {
			await fetch(BASE_URL + "/getFromRoute?route=" + route)
				.then((response) => response.json())
				.then((data) => {
					{
						const tiers = data.course.types;
						const curr = tiers[tier];
						setTierId(curr._id);
						// console.log(curr._id)
					}
				})
				.catch((err) => {
					// console.log(err)
				});
		}

		setCoupon(true);
	}

	const addedToCartImg = (
		<img src={addedToCart} className={style.addedToCart} />
	);

	return (
		<div
			className='container'
			style={{ overflow: "hidden", paddingLeft: "4vw" }}>
			{/* {console.log(tierId)} */}
			<Coupons1
				course='fasttrackcourse'
				setCoupon={setCoupon}
				tierId={tierId}
				amount={totalAmount}
				original_amount={3500 * sum_cart}
				token={token}
				courseId={cart_item_tier}
				setLoadT1={setLoadT1}
				name={"Cart Total"}
				setLoadT2={setLoadT2}
				setLoadT3={setLoadT3}
				setLoadT4={setLoadT4}
				apply={apply}
				// setHandlePayment={setHandlePayment}
				setData={setData}
				setCouponCode={setCouponCode}
				// show={pay1}
				show={pay}
				setshow={setPay}
				onClose={() => setPay(false)}
			/>

			<div className={matches ? " row " : ""} style={{ marginBottom: "20px" }}>
				{matches ? (
					<div className={" col-sm-3 "}>
						<div
							className={
								prog_dsa
									? cart_items_prog_dsa
										? style.prog_dsa
										: " d-flex " + style.prog_dsa
									: cart_items_prog_dsa
									? style.prog_dsa_off
									: " d-flex " + style.prog_dsa_off
							}
							style={{ marginBottom: "-30px" }}
							onClick={prog_dsaHandler}>
							{cart_items_prog_dsa && <div>{addedToCartImg}</div>}
							<p
								className={
									prog_dsa
										? " my-auto " + style.heading_on_font
										: " my-auto " + style.heading_off_font
								}
								style={
									prog_dsa
										? { marginLeft: "0px", fontSize: "1.3rem" }
										: { marginLeft: "0px", fontSize: "1.1rem" }
								}>
								{/* {cart_items_prog_dsa && (
                  <img
                    src={g_cart}
                    alt="Image"
                    className={style.cart_img}
                    style={{ display: "inline" }}
                  />
                )} */}
								Programming and DSA
							</p>
						</div>

						<div
							className={
								apti
									? cart_items_apti
										? style.apti
										: " d-flex " + style.apti
									: cart_items_apti
									? style.apti_off
									: " d-flex " + style.apti_off
							}
							style={{ marginBottom: "-30px" }}
							onClick={() => AptiHandler()}>
							{cart_items_apti && <div>{addedToCartImg}</div>}
							<p
								className={
									apti
										? " my-auto " + style.heading_on_font
										: " my-auto " + style.heading_off_font
								}>
								Aptitude
							</p>
						</div>

						<div
							className={
								iprep
									? cart_items_iprep
										? style.iprep
										: " d-flex " + style.iprep
									: cart_items_iprep
									? style.iprep_off
									: " d-flex " + style.iprep_off
							}
							style={{ marginBottom: "-30px" }}
							onClick={() => IprepHandler()}>
							{cart_items_iprep && <div>{addedToCartImg}</div>}
							<p
								className={
									iprep
										? " my-auto " + style.heading_on_font
										: " my-auto " + style.heading_off_font
								}>
								Interview preparation
							</p>
						</div>

						<div
							className={
								company_mock
									? cart_items_cm
										? style.apti
										: " d-flex " + style.apti
									: cart_items_cm
									? style.apti_off
									: " d-flex " + style.apti_off
							}
							style={{ marginBottom: "-30px" }}
							onClick={Company_mockHandler}>
							{cart_items_cm && <div>{addedToCartImg}</div>}
							<p
								className={
									company_mock
										? " my-auto " + style.heading_on_font
										: " my-auto " + style.heading_off_font
								}>
								Mock Test
							</p>
						</div>

						<div
							className={
								mpsolving
									? cart_items_mpsolving
										? style.mpsolving
										: " d-flex " + style.mpsolving
									: cart_items_mpsolving
									? style.mpsolving_off
									: " d-flex " + style.mpsolving_off
							}
							onClick={() => mpsolvingHandler()}>
							{cart_items_mpsolving && <div>{addedToCartImg}</div>}
							<p
								className={
									mpsolving
										? " my-auto " + style.heading_on_font
										: " my-auto " + style.heading_off_font
								}
								style={
									mpsolving ? { fontSize: "1.1rem" } : { fontSize: "1rem" }
								}>
								Master Problem Solving
							</p>
						</div>
					</div>
				) : before_mobile_view ? (
					<div>
						<div className={" row "} style={{ paddingLeft: "10px" }}>
							<div
								className={
									prog_dsa
										? cart_items_prog_dsa
											? style.prog_dsa
											: " d-flex " + style.prog_dsa
										: cart_items_prog_dsa
										? style.prog_dsa_off
										: " d-flex " + style.prog_dsa_off
								}
								style={{ width: "32%", padding: "1px", height: "100px" }}
								onClick={prog_dsaHandler}>
								{cart_items_prog_dsa && <div>{addedToCartImg}</div>}

								<p>Programming and DSA</p>
							</div>

							<div
								className={
									apti
										? cart_items_apti
											? style.mpsolving
											: " d-flex " + style.apti
										: cart_items_apti
										? style.apti_off
										: " d-flex " + style.apti_off
								}
								style={{ width: "34%", padding: "0px", height: "100px" }}
								onClick={AptiHandler}>
								{cart_items_apti && <div>{addedToCartImg}</div>}

								<p>Aptitude</p>
							</div>

							<div
								className={
									iprep
										? cart_items_iprep
											? style.iprep
											: " d-flex " + style.iprep
										: cart_items_iprep
										? style.iprep_off
										: " d-flex " + style.iprep_off
								}
								style={{ width: "32%", padding: "0px", height: "100px" }}
								onClick={IprepHandler}>
								{cart_items_iprep && <div>{addedToCartImg}</div>}
								<p>Interview preparation</p>
							</div>
						</div>

						<div className={" row "} style={{ paddingLeft: "10px" }}>
							<div
								className={
									company_mock
										? cart_items_cm
											? style.apti
											: " d-flex " + style.apti
										: cart_items_cm
										? style.apti_off
										: " d-flex " + style.apti_off
								}
								style={{ width: "34%", padding: "0px", height: "100px" }}
								onClick={Company_mockHandler}>
								{cart_items_cm && <div>{addedToCartImg}</div>}

								<p>Mock Test</p>
							</div>

							<div
								className={
									mpsolving
										? cart_items_mpsolving
											? style.mpsolving
											: " d-flex " + style.mpsolving
										: cart_items_mpsolving
										? style.mpsolving_off
										: " d-flex " + style.mpsolving_off
								}
								style={{ width: "32%", padding: "0px", height: "100px" }}
								onClick={mpsolvingHandler}>
								{cart_items_mpsolving && <div>{addedToCartImg}</div>}

								<p>Master Problem Solving</p>
							</div>
						</div>
					</div>
				) : (
					<div>
						{" "}
						{/* Mobile headings */}
						<div className='row'>
							<div
								className={
									prog_dsa
										? " col-5 " + style.mobile_headings_on_2
										: " col-5 " + style.mobile_headings_off_2
								}
								onClick={() => prog_dsaHandler()}>
								{cart_items_prog_dsa && <div>{addedToCartImg}</div>}

								<p className={style.mobile_p}>Programming and DSA</p>
							</div>

							<div
								className={
									apti
										? " col-5 " + style.mobile_headings_on_2
										: " col-5 " + style.mobile_headings_off_2
								}
								onClick={() => AptiHandler()}>
								{cart_items_apti && <div>{addedToCartImg}</div>}
								<p className={style.mobile_p}> Aptitude</p>
							</div>
						</div>
						<div className='row'>
							<div
								className={
									iprep
										? " col-5 " + style.mobile_headings_on_2
										: " col-5 " + style.mobile_headings_off_2
								}
								onClick={() => IprepHandler()}>
								{cart_items_iprep && <div>{addedToCartImg}</div>}

								<p className={style.mobile_p}> Interview preparation</p>
							</div>

							<div
								className={
									company_mock
										? " col-5 " + style.mobile_headings_on_2
										: " col-5 " + style.mobile_headings_off_2
								}
								onClick={() => Company_mockHandler()}>
								{cart_items_cm && <div>{addedToCartImg}</div>}
								<p className={style.mobile_p}>Mock Test</p>
							</div>
						</div>
						<div
							className='row'
							style={{ marginLeft: "10px", marginRight: "10px" }}>
							<div className='col-2'></div>
							<div
								className={
									mpsolving
										? " col-7 " + style.mobile_headings_on_2
										: " col-7 " + style.mobile_headings_off_2
								}
								onClick={() => mpsolvingHandler()}>
								{cart_items_mpsolving && <div>{addedToCartImg}</div>}
								<p className={style.mobile_p}>Master Problem Solving</p>
							</div>
						</div>
					</div>
				)}

				<div
					className={
						matches
							? " col-sm-8 " + style.content
							: before_mobile_view
							? " col-sm-12 " + style.content
							: style.mob_content
					}
					style={{ padding: "40px" }}>
					{prog_dsa ? (
						<div>
							<div className='row my-auto'>
								<div className={before_mobile_view ? "col-sm-8" : "col-sm-12"}>
									<img src={duration} width='100%' height='85%' />
								</div>

								<div className={before_mobile_view ? "col-sm-4" : "col-sm-12"}>
									<a
										target='blank'
										href='https://courses-upload-bucket.s3.ap-south-1.amazonaws.com/New+Fast+track+(Mini+Courses)+Syllabus/C%2B%2B+_+DSA.pdf'>
										<button className={style.curriculum}>
											Know Your Curriculum &nbsp;
											<img src={download} />
										</button>
									</a>
								</div>
							</div>

							<h4 style={{ fontWeight: "bold" }}>Course Description</h4>

							<p
								className={
									before_mobile_view ? style.ftc : style.ftc + style.desc_p
								}>
								Prepare for technical interviews and coding rounds with our
								expert mentors. Learn DSA and Programming and get ready to land
								into your dream jobs.
							</p>

							<h4 style={{ fontWeight: "bold" }}>What you receive?</h4>

							<div className='d-flex flex-row'>
								<div className='  ' style={{}}>
									<img src={tick} />
								</div>
								<div className={"px-2 " + style.wyr}>
									Get ready for technical interviews and competitive coding
									tests
								</div>
							</div>

							<div className='d-flex flex-row'>
								<div className=' ' style={{}}>
									<img src={tick} />
								</div>
								<div className={" px-2  " + style.wyr}>
									Clear your basics of DSA and programming
								</div>
							</div>

							<div className='d-flex flex-row'>
								<div className='' style={{}}>
									<img src={tick} />
								</div>
								<div className={" px-2  " + style.wyr}>
									Get free mock tests with detailed solutions
								</div>
							</div>

							<div className='d-flex flex-row'>
								<div className=' ' style={{}}>
									<img src={tick} />
								</div>
								<div className={"px-2  " + style.wyr}>
									Frequent Topic-wise test for your cognitive enhancements
								</div>
							</div>

							<div className='d-flex flex-row'>
								<div className=' ' style={{}}>
									<img src={tick} />
								</div>
								<div className={"px-2  " + style.wyr}>
									Get similar patterns from previous year papers.
								</div>
							</div>

							<hr />

							<div className='row'>
								<div className={before_mobile_view ? "col-sm-8" : "col-sm-6"}>
									<h4
										style={{
											color: "#83ADFF",
											textDecoration: "line-through",
										}}>
										₹ 5999/-
									</h4>
									<h4
										style={{
											color: "blue",
											fontWeight: "800",
											fontSize: "30px",
										}}>
										₹ 3,500/-
									</h4>

									{/* <button
                    className={style.applyBtn}
                      onClick={() => {
                    //     loaderT1 = true;
                    //     setLoadT1(true);
                        setApply(true)
                        // setPay(true)
                    //     handlePayNow("cpcourse", "tier1");
                     }}
                  >
                    Apply Coupon
                  </button> */}

									<div style={{ height: "25px", marginBottom: "30px" }}></div>
									<p style={{ color: "#8B8B8B", fontWeight: "600" }}>
										<img src={grey_1} />
										EMI Options &emsp; <img src={grey_2} />
										&nbsp;7 Day refund policy
									</p>
								</div>
								<div className='col-sm-4'>
									{cart_items_prog_dsa ? (
										<button className={style.enrolled}>Added to Cart</button>
									) : (
										<Link to='second'>
											<button
												onClick={() => {
													addToCart("Programming and DSA", tierIds.tier1._id);
                            setcart_items_prog_dsa(true);
                             ReactGA.event({
																category: "Crash course page",
																type: "Crash course page",
																name: "Add to Cart Programming and DSA ",
																action:
																	"Crash course Add to Cart Programming and DSA ",
															});

													// setCart_item_tier((prev) => {
													//   prev.push("tier1");
													//   return prev;
													// });
													// setCart_item_tier(cart_item_tier.splice(-1))

													//   loaderT1 = true;
													//   setLoadT1(true);
													//   handlePayNow("cpcourse", "tier1");
													setApply(false);
												}}
												className={style.enrollnow}>
												Add to Cart
											</button>
										</Link>
									)}
								</div>
							</div>
						</div>
					) : null}

					{apti ? (
						<div>
							<div className='row my-auto'>
								<div className={before_mobile_view ? "col-sm-8" : "col-sm-12"}>
									<img src={duration} width='100%' height='85%' />
								</div>
								<div className={before_mobile_view ? "col-sm-4" : "col-sm-12"}>
									<a
										target='blank'
										href='https://courses-upload-bucket.s3.ap-south-1.amazonaws.com/New+Fast+track+(Mini+Courses)+Syllabus/Aptitude.pdf'>
										<button className={style.curriculum}>
											Know Your Curriculum &nbsp;
											<img src={download} />
										</button>
									</a>
								</div>
							</div>
							<h4 style={{ fontWeight: "bold" }}>Course Description</h4>

							<p
								className={
									before_mobile_view ? style.ftc : style.ftc + style.desc_p
								}>
								Enhance your quantitative, verbal, logical reasoning and data
								interpretation aptitude with the lectures from our experienced
								mentors. And get ready to crack every aptitude test out there.
							</p>

							<h4 style={{ fontWeight: "bold" }}>What you receive?</h4>

							<div className='d-flex flex-row'>
								<div className='  ' style={{}}>
									<img src={tick} />
								</div>
								<div className={"px-2 " + style.wyr}>
									Lectures by experts on Quant, Verbal, DI and Logical
									Reasoning.
								</div>
							</div>

							<div className='d-flex flex-row'>
								<div className=' ' style={{}}>
									<img src={tick} />
								</div>
								<div className={" px-2  " + style.wyr}>
									Videos covering all the necessary domains in aptitude
								</div>
							</div>

							<div className='d-flex flex-row'>
								<div className='' style={{}}>
									<img src={tick} />
								</div>
								<div className={" px-2  " + style.wyr}>
									Ebooks with formulas, tips and tricks for cracking aptitude
									rounds.
								</div>
							</div>

							<div className='d-flex flex-row'>
								<div className=' ' style={{}}>
									<img src={tick} />
								</div>
								<div className={"px-2  " + style.wyr}>
									Complete mock tests to gauge your preparation level
								</div>
							</div>

							<hr />

							<div className='row'>
								<div className={before_mobile_view ? "col-sm-8" : "col-sm-6"}>
									<h4
										style={{
											color: "#83ADFF",
											textDecoration: "line-through",
										}}>
										₹ 5999/-
									</h4>
									<h4
										style={{
											color: "blue",
											fontWeight: "800",
											fontSize: "30px",
										}}>
										₹ 3,500/-
									</h4>

									<div style={{ height: "25px", marginBottom: "30px" }}></div>
									<p style={{ color: "#8B8B8B", fontWeight: "600" }}>
										<img src={grey_1} />
										EMI Options &emsp; <img src={grey_2} />
										&nbsp;7 Day refund policy
									</p>
								</div>
								<div className='col-sm-4'>
									{cart_items_apti ? (
										<button className={style.enrolled}>Added to Cart</button>
									) : (
										<Link to='second'>
											<button
                          onClick={() => {
                             ReactGA.event({
																category: "Crash course page",
																type: "Crash course page",
																name: "Add to Cart Aptitude ",
																action: "Crash course Add to Cart Aptitude ",
															});
													addToCart("Aptitude", tierIds.tier2._id);
													setCart_items_apti(true);
													setApply(false);
												}}
												className={style.enrollnow}>
												Add to Cart
											</button>
										</Link>
									)}
								</div>
							</div>
						</div>
					) : null}

					{iprep ? (
						<div>
							<div className='row my-auto'>
								<div className={before_mobile_view ? "col-sm-8" : "col-sm-12"}>
									<img src={duration} width='100%' height='85%' />
								</div>
								<div className={before_mobile_view ? "col-sm-4" : "col-sm-12"}>
									<a
										target='blank'
										href='https://courses-upload-bucket.s3.ap-south-1.amazonaws.com/New+Fast+track+(Mini+Courses)+Syllabus/Interview+Preparation.pdf'>
										<button className={style.curriculum}>
											Know Your Curriculum &nbsp;
											<img src={download} />
										</button>
									</a>
								</div>
							</div>

							<h4 style={{ fontWeight: "bold" }}>Course Description</h4>

							<p
								className={
									before_mobile_view ? style.ftc : style.ftc + style.desc_p
								}>
								A complete guide to crack interviews of top companies. Get
								complete guidance from resume preparation to mock interviews and
								land into your dream company.
							</p>

							<h4 style={{ fontWeight: "bold" }}>What you receive?</h4>

							<div className='d-flex flex-row'>
								<div className='  ' style={{}}>
									<img src={tick} />
								</div>
								<div className={"px-2 " + style.wyr}>
									Learn to make effective resumes, cover letters and linkedin
									profile.
								</div>
							</div>

							<div className='d-flex flex-row'>
								<div className=' ' style={{}}>
									<img src={tick} />
								</div>
								<div className={" px-2  " + style.wyr}>
									Learn soft skills to communicate effectively in the
									professional world.
								</div>
							</div>

							<div className='d-flex flex-row'>
								<div className='' style={{}}>
									<img src={tick} />
								</div>
								<div className={" px-2  " + style.wyr}>
									Sessions on how to tackle interview questions
								</div>
							</div>

							<div className='d-flex flex-row'>
								<div className=' ' style={{}}>
									<img src={tick} />
								</div>
								<div className={"px-2  " + style.wyr}>
									Mock interviews & 1-on-1 mentoring sessions with real-life
									interviewers.
								</div>
							</div>

							<div className='d-flex flex-row'>
								<div className=' ' style={{}}>
									<img src={tick} />
								</div>
								<div className={"px-2  " + style.wyr}>
									Resume building templates and feedback on your resume
								</div>
							</div>

							<hr />

							<div className='row'>
								<div className={before_mobile_view ? "col-sm-8" : "col-sm-6"}>
									<h4
										style={{
											color: "#83ADFF",
											textDecoration: "line-through",
										}}>
										₹ 5999/-
									</h4>
									<h4
										style={{
											color: "blue",
											fontWeight: "800",
											fontSize: "30px",
										}}>
										₹ 3,500/-
									</h4>

									<div style={{ height: "25px", marginBottom: "30px" }}></div>
									<p style={{ color: "#8B8B8B", fontWeight: "600" }}>
										<img src={grey_1} />
										EMI Options &emsp; <img src={grey_2} />
										&nbsp;7 Day refund policy
									</p>
								</div>
								<div className='col-sm-4'>
									{cart_items_iprep ? (
										<button className={style.enrolled}>Added to Cart</button>
									) : (
										<Link to='second'>
											<button
                          onClick={() => {
                             
													addToCart("Interview preparation", tierIds.tier3._id);
													setCart_items_iprep(true);
                            setApply(false);
                             ReactGA.event({
																category: "Crash course page",
																type: "Crash course page",
																name: "Add to Cart Interview Preparation",
																action:
																	"Crash course Add to Cart Interview Preparation ",
															});
												}}
												className={style.enrollnow}>
												Add to Cart
											</button>
										</Link>
									)}
								</div>
							</div>
						</div>
					) : null}

					{company_mock ? (
						<div>
							<div className='row my-auto'>
								<div className={before_mobile_view ? "col-sm-8" : "col-sm-12"}>
									<img src={duration} width='100%' height='85%' />
								</div>
								<div className={before_mobile_view ? "col-sm-4" : "col-sm-12"}>
									<a
										target='blank'
										href='https://courses-upload-bucket.s3.ap-south-1.amazonaws.com/New+Fast+track+(Mini+Courses)+Syllabus/Mock+tests.pdf'>
										<button className={style.curriculum}>
											Know Your Curriculum &nbsp;
											<img src={download} />
										</button>
									</a>
								</div>
							</div>

							<h4 style={{ fontWeight: "bold" }}>Course Description</h4>

							<p
								className={
									before_mobile_view ? style.ftc : style.ftc + style.desc_p
								}>
								Practice and enhance your aptitude skills with our mock tests
								designed according to aptitude tests conducted by various
								companies.
							</p>

							<h4 style={{ fontWeight: "bold" }}>What you receive?</h4>

							<div className='d-flex flex-row'>
								<div className='  ' style={{}}>
									<img src={tick} />
								</div>
								<div className={"px-2 " + style.wyr}>
									Mock tests with similar pattern as aptitude tests of tech
									giants
								</div>
							</div>

							<div className='d-flex flex-row'>
								<div className=' ' style={{}}>
									<img src={tick} />
								</div>
								<div className={" px-2  " + style.wyr}>
									Tests covering complete syllabus of aptitude rounds.
								</div>
							</div>

							<div className='d-flex flex-row'>
								<div className='' style={{}}>
									<img src={tick} />
								</div>
								<div className={" px-2  " + style.wyr}>
									Ebooks with formulas, tips and tricks for cracking aptitude
									rounds.
								</div>
							</div>

							<div className='d-flex flex-row'>
								<div className=' ' style={{}}>
									<img src={tick} />
								</div>
								<div className={"px-2  " + style.wyr}>
									30 mock tests exhaustively covering all the topics of
									Aptitude.
								</div>
							</div>

							<hr />

							<div className='row'>
								<div className={before_mobile_view ? "col-sm-8" : "col-sm-6"}>
									<h4
										style={{
											color: "#83ADFF",
											textDecoration: "line-through",
										}}>
										₹ 5999/-
									</h4>
									<h4
										style={{
											color: "blue",
											fontWeight: "800",
											fontSize: "30px",
										}}>
										₹ 3,500/-
									</h4>

									<div style={{ height: "25px", marginBottom: "30px" }}></div>
									<p style={{ color: "#8B8B8B", fontWeight: "600" }}>
										<img src={grey_1} />
										EMI Options &emsp; <img src={grey_2} />
										&nbsp;7 Day refund policy
									</p>
								</div>
								<div className='col-sm-4'>
									{cart_items_cm ? (
										<button className={style.enrolled}>Added to Cart</button>
									) : (
										<Link to='second'>
											<button
                          onClick={() => {
                             ReactGA.event({
																category: "Crash course page",
																type: "Crash course page",
																name: "Add to Cart Mock Test",
																action: "Crash course Add to Cart Mock Test ",
															});
													addToCart("Mock Test", tierIds.tier4._id);
													setCart_items_cm(true);
													setApply(false);
												}}
												className={style.enrollnow}>
												Add to Cart
											</button>
										</Link>
									)}
								</div>
							</div>
						</div>
					) : null}

					{mpsolving ? (
						<div>
							<div className='row my-auto'>
								<div className={before_mobile_view ? "col-sm-8" : "col-sm-12"}>
									<img src={duration} width='100%' height='85%' />
								</div>
								<div className={before_mobile_view ? "col-sm-4" : "col-sm-12"}>
									<a
										target='blank'
										href='https://courses-upload-bucket.s3.ap-south-1.amazonaws.com/New+Fast+track+(Mini+Courses)+Syllabus/Master+problem+solving.pdf'>
										<button className={style.curriculum}>
											Know Your Curriculum &nbsp;
											<img src={download} />
										</button>
									</a>
								</div>
							</div>

							<h4 style={{ fontWeight: "bold" }}>Course Description</h4>

							<p
								className={
									before_mobile_view ? style.ftc : style.ftc + style.desc_p
								}>
								Become a Proficient problem solver by practicing top questions
								asked in leading Product & Service based companies.
							</p>

							<h4 style={{ fontWeight: "bold" }}>What you receive?</h4>

							<div className='d-flex flex-row'>
								<div className='  ' style={{}}>
									<img src={tick} />
								</div>
								<div className={"px-2 " + style.wyr}>
									Programming Mock tests from Product & Service based companies
								</div>
							</div>

							<div className='d-flex flex-row'>
								<div className=' ' style={{}}>
									<img src={tick} />
								</div>
								<div className={" px-2  " + style.wyr}>
									Get similar patterns from previous year papers.
								</div>
							</div>

							<div className='d-flex flex-row'>
								<div className='' style={{}}>
									<img src={tick} />
								</div>
								<div className={" px-2  " + style.wyr}>
									Ebooks with formulas, tips and tricks for cracking aptitude
									rounds.
								</div>
							</div>

							<div className='d-flex flex-row'>
								<div className=' ' style={{}}>
									<img src={tick} />
								</div>
								<div className={"px-2  " + style.wyr}>
									Ebook covering Programming and DSA topics.
								</div>
							</div>

							<hr />

							<div className='row'>
								<div className={before_mobile_view ? "col-sm-8" : "col-sm-6"}>
									<h4
										style={{
											color: "#83ADFF",
											textDecoration: "line-through",
										}}>
										₹ 5999/-
									</h4>
									<h4
										style={{
											color: "blue",
											fontWeight: "800",
											fontSize: "30px",
										}}>
										₹ 3,500/-
									</h4>

									<div style={{ height: "25px", marginBottom: "30px" }}></div>
									<p style={{ color: "#8B8B8B", fontWeight: "600" }}>
										<img src={grey_1} />
										EMI Options &emsp; <img src={grey_2} />
										&nbsp;7 Day refund policy
									</p>
								</div>
								<div className='col-sm-4'>
									{cart_items_mpsolving ? (
										<button className={style.enrolled}>Added to Cart</button>
									) : (
										<Link to='second'>
											<button
                          onClick={() => {
                             ReactGA.event({
																category: "Crash course page",
																type: "Crash course page",
																name: "Add to Cart Master Problem Solving ",
																action:
																	"Crash course Add to Cart Master Problem Solving",
															});
													addToCart(
														"Master Problem Solving",
														tierIds.tier5._id
													);
													setCart_items_mpsolving(true);
												}}
												className={style.enrollnow}>
												Add to Cart
											</button>
										</Link>
									)}
								</div>
							</div>
						</div>
					) : null}
				</div>
			</div>

			{/* { cart &&*/}
			<div id='second'></div>

			{/* } */}

			{/* {cart && ( */}

			<div
				className={" row shadow " + style.cart}
				style={cart ? {} : { display: "none" }}>
				<div id='second' className=' d-flex align-items-center '>
					{" "}
					{/* id second is used, when we click on add to cart, it navigates t cart  */}
					<h1
						className={style.cart_heading}
						style={
							cart ? {} : { fontSize: "0px", lineHeight: "1px", border: "none" }
						}>
						<img
							alt='cart'
							src={cart_icon}
							style={
								cart
									? { height: "40px", width: "40px", margin: "10px" }
									: { display: "none" }
							}
						/>
						Your Cart
					</h1>
				</div>
				<div
					className={
						before_mobile_cart_view
							? " col-6 " + style.cart_left
							: " col-12 " + style.cart_left
					}
					style={before_mobile_cart_view ? {} : { border: "none" }}>
					<div className={style.cart_left_item_in_div}>
						{cart_items_price.map(function (value, index) {
							return (
								<div
									key={index}
									className={" d-flex my-4 mx-1 " + style.cart_left_item}
									style={
										before_mobile_cart_view
											? {}
											: before_mobile_view
											? { paddingTop: "1.5rem" }
											: {}
									}>
									<div className='col-2'>
										<img
											src={cart_greeencart}
											className={style.cart_img}
											style={
												before_mobile_cart_view ? { marginTop: "8px" } : {}
											}
										/>
									</div>
									<div
										className={
											before_mobile_cart_view
												? " col-6 my-2 " + style.cart_left_item_txt
												: " col-4 " + style.cart_left_item_txt
										}>
										{value}
									</div>
									<div
										className={
											before_mobile_view
												? " col-3 my-2 " + style.cart_left_item_price
												: " col-4 " + style.cart_left_item_price
										}>
										₹ 3,500/-
									</div>
									<button
										className='col-1 btn text-danger'
										onClick={() => removeFromCart(index, value)}
										style={{ display: "inline", fontWeight: "bolder" }}>
										x
									</button>
								</div>
							);
						})}
					</div>
				</div>

				<div
					className={
						before_mobile_view
							? before_mobile_cart_view
								? " col-5 " + style.cart_right
								: " col-8 " + style.cart_right
							: " col-12 " + style.cart_right
					}>
					<div className={" row my-4  " + style.cart_subttl}>
						<div className='col-7'>Subtotal</div>
						<div className='col-4'>₹{3500 * sum_cart}</div>
					</div>

					<div
						className=' d-flex align-items-center justify-content-center '
						style={{ height: "30px", marginTop: "0px", marginBottom: "40px" }}>
						{sum_cart < 2 ? (
							<img src={avail_discount} className={style.avail_disc} />
						) : (
							<div
								className={
									" d-flex align-items-center justify-content-center " +
									style.you_are_saving_div
								}>
								{" "}
								<span className='my-auto'>You are saving ₹{disc_cart}</span>
							</div>
						)}
					</div>

					{before_mobile_view ? (
						<div>
							<h2 className={style.cart_total}>Total</h2>
							<div className='row'>
								<div className={" col-7 " + style.end_txt}>
									Price inclusive of 18% GST
								</div>
								<div
									className={" col-5 " + style.disc_price}
									style={{
										fontSize: "25px",
										fontWeight: "600",
										marginTop: "-10px",
										color: "#185ADB",
										fontFamily: "sans-serif",
										textAlign: "center",
									}}>
									₹ {totalAmount}/-
									{/* Rs 9,999 */}
								</div>
							</div>

							<p
								className={style.applyBtn}
								onClick={() => {
									setApply(true);
									setPay(true);
									loaderT1 = true;
									setLoadT1(true);
									handlePayNow("fasttrackcourse", "tier1");
								}}
								style={{ border: "none", background: "none" }}>
								Apply Coupon
							</p>
							<div
								style={{
									// background:
									//   "linear-gradient(90deg, rgba(244, 7, 7, 0.5) 28.22%, rgba(244, 7, 7, 0) 100%), #FF8726",
									background: "#00599D",
									padding: "15px",
									color: "white",
									textAlign: "center",
									borderRadius: "20px",
									marginLeft: "8%",
									marginRight: "8%",
									fontWeight: "600",
									fontSize: "20px",
									cursor: "pointer",
								}}
								onClick={() => {
									// setApply(true)
									setPay(true);
									loaderT1 = true;
									setLoadT1(true);
									handlePayNow("fasttrackcourse", "tier1");
								}}>
								PROCEED TO PAY
							</div>
						</div>
					) : (
						<div className={style.mob_total_cart_div}>
							<h1>Total</h1>

							<div className='row'>
								<div
									className='col-6'
									style={{ color: "#8B8B8B", fontSize: "14px" }}>
									Price inclusive of 18% GST
								</div>
								<div
									className='col-6'
									style={{
										color: "#185ADB",
										fontSize: "1.2rem",
										fontWeight: "800",
										fontFamily: "sans-serif",
									}}>
									₹ {totalAmount}/-
								</div>
							</div>

							<div className='row'>
								<div className='col-5'>
									<p
										className={style.applyBtn}
										onClick={() => {
											setApply(true);
											setPay(true);
											loaderT1 = true;
											setLoadT1(true);
											handlePayNow("fasttrackcourse", "tier1");
										}}>
										Apply Coupon
									</p>
								</div>
								{/* <div className="col-6"> */}
								<div
									className='rounded-pill col-6 '
									style={{
										backgroundColor: "#00599d",
										color: "white",
										textAlign: "center",
										height: "2rem",
										padding: "0.6em",
									}}>
									<p
										className='col-sm my-auto'
										onClick={() => {
											setPay(true);
											loaderT1 = true;
											setLoadT1(true);
											handlePayNow("fasttrackcourse", "tier1");
										}}>
										PROCEED TO PAY
									</p>
								</div>
								{/* </div> */}
							</div>
						</div>
					)}
				</div>
			</div>
			{/* )} */}
		</div>
	);
};

export default IterationPart2;

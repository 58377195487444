import { BASE_URL } from "../env";
import { useEffect, useState } from "react";
import "../Assets/css/allquestion.css";
import { Link } from "react-router-dom";
import ReactGA from "react-ga";
const AllQuestion = ({ courseID }) => {
  // const [id, setId] = useState("");
  const [question, setQuestion] = useState([]);
  const [easyquestions, setEasyquestions] = useState([]);
  const [mediumquestions, setMediumQuestions] = useState([]);
  const [hardquestions, setHardQuestions] = useState([]);
  const [arrayQuestions, setArrayQuestions] = useState([]);
  const [queueQuestions, setQueueQuestions] = useState([]);
  const [graphQuestions, setGraphQuestions] = useState([]);
  const [recursionQuestions, setRecursionQuestions] = useState([]);
  const [stackQuestions, setStackQuestions] = useState([]);
  const [hashQuestions, setHashQuestions] = useState([]);
  const [treesQuestions, setTreesQuestions] = useState([]);
  const [stringsQuestions, setStringsQuestions] = useState([]);
  const [sortingQuestions, setSortingQuestions] = useState([]);
  const [searchingQuestions, setSearchingQuestions] = useState([]);
  const [mathematicalQuestions, setMathematicalQuestions] = useState([]);
  const [queueStackQuestions, setQueueStackQuestions] = useState([]);
  const [linkedListQuestions, setLinkedListQuestions] = useState([]);
  const [TCS, setTCS] = useState([]);
  const [Wipro, setWipro] = useState([]);
  const [Amazon, setAmazon] = useState([]);
  const [Facebook, setFacebook] = useState([]);
  const [Microsoft, setMicrosoft] = useState([]);
  const [Google, setGoogle] = useState([]);
  const [Capgemini, setCapgemini] = useState([]);
  const [Cognizant, setCognizant] = useState([]);
  const [Accenture, setAccenture] = useState([]);
  const [selectedButton, setSelectedButton] = useState("category");
  // 5 waale karlo hard    3-4 waale medium 1-2 waale easy
  useEffect(() => {
    fetch(BASE_URL + "/GetAllQuestion")
      .then((response) => response.json())
      .then((data) => {
        setQuestion(data?.questions);
      });
  }, []);
  //Adding google analytics events
  useEffect(() => {
    ReactGA.initialize("UA-198180460-1");
  }, []);
  // alert(courseID)
  useEffect(() => {
    setEasyquestions(
      question.filter((value) => {
        return value.questionWeigh <= 2;
      })
    );
    setMediumQuestions(
      question.filter((value) => {
        return value.questionWeigh >= 3 && value.questionWeigh <= 4;
      })
    );
    setHardQuestions(
      question.filter((value) => {
        return value.questionWeigh === 5;
      })
    );
    setArrayQuestions(
      question.filter((value) => {
        return value.category === "Array";
      })
    );
    setSearchingQuestions(
      question.filter((value) => {
        return value.category === "Searching";
      })
    );
    setStringsQuestions(
      question.filter((value) => {
        return value.category === "Strings";
      })
    );
    setQueueQuestions(
      question.filter((value) => {
        return value.category === "Queue";
      })
    );
    setQueueStackQuestions(
      question.filter((value) => {
        return value.category === "Queue Stack";
      })
    );
    setHashQuestions(
      question.filter((value) => {
        return value.category === "Hash";
      })
    );
    setGraphQuestions(
      question.filter((value) => {
        return value.category === "Graph";
      })
    );
    setSortingQuestions(
      question.filter((value) => {
        return value.category === "Sorting";
      })
    );
    setMathematicalQuestions(
      question.filter((value) => {
        return value.category === "Mathematical";
      })
    );
    setStackQuestions(
      question.filter((value) => {
        return value.category === "Stack";
      })
    );
    setRecursionQuestions(
      question.filter((value) => {
        return value.category === "Recursion";
      })
    );
    setLinkedListQuestions(
      question.filter((value) => {
        return value.category === "Linked List";
      })
    );
    setAccenture(
      question.filter((value) => {
        return value.company === "Accenture";
      })
    );
    setCognizant(
      question.filter((value) => {
        return value.company === "Cognizant";
      })
    );
    setCapgemini(
      question.filter((value) => {
        return value.company === "Capgemini";
      })
    );
    setGoogle(
      question.filter((value) => {
        return value.company === "Google";
      })
    );
    setMicrosoft(
      question.filter((value) => {
        return value.company === "Microsoft";
      })
    );
    setFacebook(
      question.filter((value) => {
        return value.company === "Facebook";
      })
    );
    setAmazon(
      question.filter((value) => {
        return value.company === "Amazon";
      })
    );
    setWipro(
      question.filter((value) => {
        return value.compamy === "Wipro";
      })
    );
    setTCS(
      question.filter((value) => {
        return value.company === "TCS";
      })
    );
    setTreesQuestions(
      question.filter((value) => {
        return value.category === "Trees";
      })
    );
  }, [question]);
  useEffect(() => {
    console.log(hardquestions);
  }, [hardquestions]);

  const handleButtons = () => {
    switch (selectedButton) {
      case "difficulty": {
        return (
          <>
            <button
              type="button"
              class="btn btn-primary"
              onClick={() => {
                handleFilter("difficulty");
              }}
            >
              Group by Difficulty
            </button>
            <button
              type="button"
              class="btn btn-outline-primary"
              onClick={() => {
                handleFilter("category");
              }}
            >
              Group by Category
            </button>
            <button
              type="button"
              class="btn btn-outline-primary"
              onClick={() => {
                handleFilter("companies");
              }}
            >
              Group by Companies
            </button>
          </>
        );
      }
      case "companies": {
        return (
          <>
            <button
              type="button"
              class="btn btn-outline-primary"
              onClick={() => {
                handleFilter("difficulty");
              }}
            >
              Group by Difficulty
            </button>
            <button
              type="button"
              class="btn btn-outline-primary"
              onClick={() => {
                handleFilter("category");
              }}
            >
              Group by Category
            </button>
            <button
              type="button"
              class="btn btn-primary"
              onClick={() => {
                handleFilter("companies");
              }}
            >
              Group by Companies
            </button>
          </>
        );
      }
      default: {
        return (
          <>
            <button
              type="button"
              class="btn btn-outline-primary"
              onClick={() => {
                handleFilter("difficulty");
              }}
            >
              Group by Difficulty
            </button>
            <button
              type="button"
              class="btn btn-primary"
              onClick={() => {
                handleFilter("category");
              }}
            >
              Group by Category
            </button>
            <button
              type="button"
              class="btn btn-outline-primary"
              onClick={() => {
                handleFilter("companies");
              }}
            >
              Group by Companies
            </button>
          </>
        );
      }
    }
  };

  const handleFilter = (filter) => {
    setSelectedButton(filter);
  };

  const renderCategories = () => {
    return (
      <>
        <div className="col-xs-4 col-sm-4 col-md-5 col-lg-4">
          <div className="d-flex flex-column">
            <span className="Level_Text">Array</span>
            {arrayQuestions.map((val, i) => {
              return (
                <div>
                  <Link
				     onClick={(e) => {
                      ReactGA.event({
                        category: "Dashboard",
                        type: "CL-Compiler",
                        name: val.name,
                        action: val.name,
                      });
                    }}
                    style={{ cursor: "pointer" }}
                    to={"/compiler/" + val._id + "/" + courseID}
                  >
                    <div className="shadow question-card-compiler easy my-2 d-flex justify-content-start align-item-center">
                      <span style={{ transform: "scale(1.6)" }}>
                        {" "}
                        <i class="fas fa-circle"></i>{" "}
                      </span>
                      <span className="mx-4 question_name"> {val.name} </span>
                      <span className="green"></span>
                    </div>
                  </Link>{" "}
                </div>
              );
            })}
          </div>
        </div>
        <div className="col-xs-4 col-sm-4 col-md-5 col-lg-4">
          <div className="d-flex flex-column">
            <span className="Level_Text">Graph</span>
            {graphQuestions.map((val, i) => {
              return (
                <div>
                  <Link
                    onClick={(e) => {
                      ReactGA.event({
                        category: "Dashboard",
                        type: "CL-Compiler",
                        name: val.name,
                        action: val.name,
                      });
                    }}
                    style={{ cursor: "pointer" }}
                    to={"/compiler/" + val._id + "/" + courseID}
                  >
                    <div className="shadow question-card-compiler easy my-2 d-flex justify-content-start align-item-center">
                      <span style={{ transform: "scale(1.6)" }}>
                        {" "}
                        <i class="fas fa-circle"></i>{" "}
                      </span>
                      <span className="mx-4 question_name"> {val.name} </span>
                      <span className="green"></span>
                    </div>
                  </Link>{" "}
                </div>
              );
            })}
          </div>
        </div>
        <div className="col-xs-4 col-sm-4 col-md-5 col-lg-4">
          <div className="d-flex flex-column">
            <span className="Level_Text">Linked List</span>
            {linkedListQuestions.map((val, i) => {
              return (
                <div>
                  <Link
				     onClick={(e) => {
                      ReactGA.event({
                        category: "Dashboard",
                        type: "CL-Compiler",
                        name: val.name,
                        action: val.name,
                      });
                    }}
                    style={{ cursor: "pointer" }}
                    to={"/compiler/" + val._id + "/" + courseID}
                  >
                    <div className="shadow question-card-compiler easy my-2 d-flex justify-content-start align-item-center">
                      <span style={{ transform: "scale(1.6)" }}>
                        {" "}
                        <i class="fas fa-circle"></i>{" "}
                      </span>
                      <span className="mx-4 question_name"> {val.name} </span>
                      <span className="green"></span>
                    </div>
                  </Link>{" "}
                </div>
              );
            })}
          </div>
        </div>
        <div className="col-xs-4 col-sm-4 col-md-5 col-lg-4">
          <div className="d-flex flex-column">
            <span className="Level_Text">Queue Stack</span>
            {queueStackQuestions.map((val, i) => {
              return (
                <div>
                  <Link
				     onClick={(e) => {
                      ReactGA.event({
                        category: "Dashboard",
                        type: "CL-Compiler",
                        name: val.name,
                        action: val.name,
                      });
                    }}
                    style={{ cursor: "pointer" }}
                    to={"/compiler/" + val._id + "/" + courseID}
                  >
                    <div className="shadow question-card-compiler easy my-2 d-flex justify-content-start align-item-center">
                      <span style={{ transform: "scale(1.6)" }}>
                        {" "}
                        <i class="fas fa-circle"></i>{" "}
                      </span>
                      <span className="mx-4 question_name"> {val.name} </span>
                      <span className="green"></span>
                    </div>
                  </Link>{" "}
                </div>
              );
            })}
          </div>
        </div>

        <div className="col-xs-4 col-sm-4 col-md-5 col-lg-4">
          <div className="d-flex flex-column">
            <span className="Level_Text">Sorting</span>
            {sortingQuestions.map((val, i) => {
              return (
                <div>
                  <Link
				     onClick={(e) => {
                      ReactGA.event({
                        category: "Dashboard",
                        type: "CL-Compiler",
                        name: val.name,
                        action: val.name,
                      });
                    }}
                    style={{ cursor: "pointer" }}
                    to={"/compiler/" + val._id + "/" + courseID}
                  >
                    <div className="shadow question-card-compiler easy my-2 d-flex justify-content-start align-item-center">
                      <span style={{ transform: "scale(1.6)" }}>
                        {" "}
                        <i class="fas fa-circle"></i>{" "}
                      </span>
                      <span className="mx-4 question_name"> {val.name} </span>
                      <span className="green"></span>
                    </div>
                  </Link>{" "}
                </div>
              );
            })}
          </div>
        </div>
        <div className="col-xs-4 col-sm-4 col-md-5 col-lg-4">
          <div className="d-flex flex-column">
            <span className="Level_Text">Strings</span>
            {stringsQuestions.map((val, i) => {
              return (
                <div>
                  <Link
				     onClick={(e) => {
                      ReactGA.event({
                        category: "Dashboard",
                        type: "CL-Compiler",
                        name: val.name,
                        action: val.name,
                      });
                    }}
                    style={{ cursor: "pointer" }}
                    to={"/compiler/" + val._id + "/" + courseID}
                  >
                    <div className="shadow question-card-compiler easy my-2 d-flex justify-content-start align-item-center">
                      <span style={{ transform: "scale(1.6)" }}>
                        {" "}
                        <i class="fas fa-circle"></i>{" "}
                      </span>
                      <span className="mx-4 question_name"> {val.name} </span>
                      <span className="green"></span>
                    </div>
                  </Link>{" "}
                </div>
              );
            })}
          </div>
        </div>
        <div className="col-xs-4 col-sm-4 col-md-5 col-lg-4">
          <div className="d-flex flex-column">
            <span className="Level_Text">Trees</span>
            {treesQuestions.map((val, i) => {
              return (
                <div>
                  <Link
				     onClick={(e) => {
                      ReactGA.event({
                        category: "Dashboard",
                        type: "CL-Compiler",
                        name: val.name,
                        action: val.name,
                      });
                    }}
                    style={{ cursor: "pointer" }}
                    to={"/compiler/" + val._id + "/" + courseID}
                  >
                    <div className="shadow question-card-compiler easy my-2 d-flex justify-content-start align-item-center">
                      <span style={{ transform: "scale(1.6)" }}>
                        {" "}
                        <i class="fas fa-circle"></i>{" "}
                      </span>
                      <span className="mx-4 question_name"> {val.name} </span>
                      <span className="green"></span>
                    </div>
                  </Link>{" "}
                </div>
              );
            })}
          </div>
        </div>
        <div className="col-xs-4 col-sm-4 col-md-5 col-lg-4">
          <div className="d-flex flex-column">
            <span className="Level_Text">Hash</span>
            {hashQuestions.map((val, i) => {
              return (
                <div>
                  <Link
				     onClick={(e) => {
                      ReactGA.event({
                        category: "Dashboard",
                        type: "CL-Compiler",
                        name: val.name,
                        action: val.name,
                      });
                    }}
                    style={{ cursor: "pointer" }}
                    to={"/compiler/" + val._id + "/" + courseID}
                  >
                    <div className="shadow question-card-compiler easy my-2 d-flex justify-content-start align-item-center">
                      <span style={{ transform: "scale(1.6)" }}>
                        {" "}
                        <i class="fas fa-circle"></i>{" "}
                      </span>
                      <span className="mx-4 question_name"> {val.name} </span>
                      <span className="green"></span>
                    </div>
                  </Link>{" "}
                </div>
              );
            })}
          </div>
        </div>
        <div className="col-xs-4 col-sm-4 col-md-5 col-lg-4">
          <div className="d-flex flex-column">
            <span className="Level_Text">Stacks</span>
            {stackQuestions.map((val, i) => {
              return (
                <div>
                  <Link
				     onClick={(e) => {
                      ReactGA.event({
                        category: "Dashboard",
                        type: "CL-Compiler",
                        name: val.name,
                        action: val.name,
                      });
                    }}
                    style={{ cursor: "pointer" }}
                    to={"/compiler/" + val._id + "/" + courseID}
                  >
                    <div className="shadow question-card-compiler easy my-2 d-flex justify-content-start align-item-center">
                      <span style={{ transform: "scale(1.6)" }}>
                        {" "}
                        <i class="fas fa-circle"></i>{" "}
                      </span>
                      <span className="mx-4 question_name"> {val.name} </span>
                      <span className="green"></span>
                    </div>
                  </Link>{" "}
                </div>
              );
            })}
          </div>
        </div>

        <div className="col-xs-4 col-sm-4 col-md-5 col-lg-4">
          <div className="d-flex flex-column">
            <span className="Level_Text">Queue</span>
            {queueQuestions.map((val, i) => {
              return (
                <div>
                  <Link
				     onClick={(e) => {
                      ReactGA.event({
                        category: "Dashboard",
                        type: "CL-Compiler",
                        name: val.name,
                        action: val.name,
                      });
                    }}
                    style={{ cursor: "pointer" }}
                    to={"/compiler/" + val._id + "/" + courseID}
                  >
                    <div className="shadow question-card-compiler easy my-2 d-flex justify-content-start align-item-center">
                      <span style={{ transform: "scale(1.6)" }}>
                        {" "}
                        <i class="fas fa-circle"></i>{" "}
                      </span>
                      <span className="mx-4 question_name"> {val.name} </span>
                      <span className="green"></span>
                    </div>
                  </Link>{" "}
                </div>
              );
            })}
          </div>
        </div>

        <div className="col-xs-4 col-sm-4 col-md-5 col-lg-4">
          <div className="d-flex flex-column">
            <span className="Level_Text">Searching</span>
            {searchingQuestions.map((val, i) => {
              return (
                <div>
                  <Link
				     onClick={(e) => {
                      ReactGA.event({
                        category: "Dashboard",
                        type: "CL-Compiler",
                        name: val.name,
                        action: val.name,
                      });
                    }}
                    style={{ cursor: "pointer" }}
                    to={"/compiler/" + val._id + "/" + courseID}
                  >
                    <div className="shadow question-card-compiler easy my-2 d-flex justify-content-start align-item-center">
                      <span style={{ transform: "scale(1.6)" }}>
                        {" "}
                        <i class="fas fa-circle"></i>{" "}
                      </span>
                      <span className="mx-4 question_name"> {val.name} </span>
                      <span className="green"></span>
                    </div>
                  </Link>{" "}
                </div>
              );
            })}
          </div>
        </div>
        <div className="col-xs-4 col-sm-4 col-md-5 col-lg-4">
          <div className="d-flex flex-column">
            <span className="Level_Text">Mathematical</span>
            {mathematicalQuestions.map((val, i) => {
              return (
                <div>
                  <Link
				     onClick={(e) => {
                      ReactGA.event({
                        category: "Dashboard",
                        type: "CL-Compiler",
                        name: val.name,
                        action: val.name,
                      });
                    }}
                    style={{ cursor: "pointer" }}
                    to={"/compiler/" + val._id + "/" + courseID}
                  >
                    <div className="shadow question-card-compiler easy my-2 d-flex justify-content-start align-item-center">
                      <span style={{ transform: "scale(1.6)" }}>
                        {" "}
                        <i class="fas fa-circle"></i>{" "}
                      </span>
                      <span className="mx-4 question_name"> {val.name} </span>
                      <span className="green"></span>
                    </div>
                  </Link>{" "}
                </div>
              );
            })}
          </div>
        </div>
        <div className="col-xs-4 col-sm-4 col-md-5 col-lg-4">
          <div className="d-flex flex-column">
            <span className="Level_Text">Recursion</span>
            {recursionQuestions.map((val, i) => {
              return (
                <div>
                  <Link
				     onClick={(e) => {
                      ReactGA.event({
                        category: "Dashboard",
                        type: "CL-Compiler",
                        name: val.name,
                        action: val.name,
                      });
                    }}
                    style={{ cursor: "pointer" }}
                    to={"/compiler/" + val._id + "/" + courseID}
                  >
                    <div className="shadow question-card-compiler easy my-2 d-flex justify-content-start align-item-center">
                      <span style={{ transform: "scale(1.6)" }}>
                        {" "}
                        <i class="fas fa-circle"></i>{" "}
                      </span>
                      <span className="mx-4 question_name"> {val.name} </span>
                      <span className="green"></span>
                    </div>
                  </Link>{" "}
                </div>
              );
            })}
          </div>
        </div>
      </>
    );
  };

  const renderCompanies = () => {
    return (
      <>
        <div className="col-xs-4 col-sm-4 col-md-5 col-lg-4">
          <div className="d-flex flex-column">
            <span className="Level_Text">TCS</span>
            {TCS.map((val, i) => {
              return (
                <div>
                  <Link
				     onClick={(e) => {
                      ReactGA.event({
                        category: "Dashboard",
                        type: "CL-Compiler",
                        name: val.name,
                        action: val.name,
                      });
                    }}
                    style={{ cursor: "pointer" }}
                    to={"/compiler/" + val._id + "/" + courseID}
                  >
                    <div className="shadow question-card-compiler easy my-2 d-flex justify-content-start align-item-center">
                      <span style={{ transform: "scale(1.6)" }}>
                        {" "}
                        <i class="fas fa-circle"></i>{" "}
                      </span>
                      <span className="mx-4 question_name"> {val.name} </span>
                      <span className="green"></span>
                    </div>
                  </Link>{" "}
                </div>
              );
            })}
          </div>
        </div>
        {/* <div className='col-xs-4 col-sm-4 col-md-5 col-lg-4'>
					<div className='d-flex flex-column'>
						<span className='Level_Text'>Wipro</span>
						{Wipro.map((val, i) => {
							return (
								<div>
									<Link
										style={{ cursor: "pointer" }}
										to={"/compiler/" + val._id + "/" + courseID}>
										<div className='shadow question-card-compiler easy my-2 d-flex justify-content-start align-item-center'>
											<span style={{ transform: "scale(1.6)" }}>
												{" "}
												<i class='fas fa-circle'></i>{" "}
											</span>
											<span className='mx-4 question_name'> {val.name} </span>
											<span className='green'></span>
										</div>
									</Link>{" "}
								</div>
							);
						})}
					</div>
				</div> */}
        <div className="col-xs-4 col-sm-4 col-md-5 col-lg-4">
          <div className="d-flex flex-column">
            <span className="Level_Text">Amazon</span>
            {Amazon.map((val, i) => {
              return (
                <div>
                  <Link
				     onClick={(e) => {
                      ReactGA.event({
                        category: "Dashboard",
                        type: "Coding Exam",
                        name: val.name,
                        action: val.name,
                      });
                    }}
                    style={{ cursor: "pointer" }}
                    to={"/compiler/" + val._id + "/" + courseID}
                  >
                    <div className="shadow question-card-compiler easy my-2 d-flex justify-content-start align-item-center">
                      <span style={{ transform: "scale(1.6)" }}>
                        {" "}
                        <i class="fas fa-circle"></i>{" "}
                      </span>
                      <span className="mx-4 question_name"> {val.name} </span>
                      <span className="green"></span>
                    </div>
                  </Link>{" "}
                </div>
              );
            })}
          </div>
        </div>
        <div className="col-xs-4 col-sm-4 col-md-5 col-lg-4">
          <div className="d-flex flex-column">
            <span className="Level_Text">Facebook</span>
            {Facebook.map((val, i) => {
              return (
                <div>
                  <Link
				     onClick={(e) => {
                      ReactGA.event({
                        category: "Dashboard",
                        type: "Coding Exam",
                        name: val.name,
                        action: val.name,
                      });
                    }}
                    style={{ cursor: "pointer" }}
                    to={"/compiler/" + val._id + "/" + courseID}
                  >
                    <div className="shadow question-card-compiler easy my-2 d-flex justify-content-start align-item-center">
                      <span style={{ transform: "scale(1.6)" }}>
                        {" "}
                        <i class="fas fa-circle"></i>{" "}
                      </span>
                      <span className="mx-4 question_name"> {val.name} </span>
                      <span className="green"></span>
                    </div>
                  </Link>{" "}
                </div>
              );
            })}
          </div>
        </div>
        <div className="col-xs-4 col-sm-4 col-md-5 col-lg-4">
          <div className="d-flex flex-column">
            <span className="Level_Text">Microsoft</span>
            {Microsoft.map((val, i) => {
              return (
                <div>
                  <Link
				     onClick={(e) => {
                      ReactGA.event({
                        category: "Dashboard",
                        type: "Coding Exam",
                        name: val.name,
                        action: val.name,
                      });
                    }}
                    style={{ cursor: "pointer" }}
                    to={"/compiler/" + val._id + "/" + courseID}
                  >
                    <div className="shadow question-card-compiler easy my-2 d-flex justify-content-start align-item-center">
                      <span style={{ transform: "scale(1.6)" }}>
                        {" "}
                        <i class="fas fa-circle"></i>{" "}
                      </span>
                      <span className="mx-4 question_name"> {val.name} </span>
                      <span className="green"></span>
                    </div>
                  </Link>{" "}
                </div>
              );
            })}
          </div>
        </div>

        <div className="col-xs-4 col-sm-4 col-md-5 col-lg-4">
          <div className="d-flex flex-column">
            <span className="Level_Text">Google</span>
            {Google.map((val, i) => {
              return (
                <div>
                  <Link
				     onClick={(e) => {
                      ReactGA.event({
                        category: "Dashboard",
                        type: "Coding Exam",
                        name: val.name,
                        action: val.name,
                      });
                    }}
                    style={{ cursor: "pointer" }}
                    to={"/compiler/" + val._id + "/" + courseID}
                  >
                    <div className="shadow question-card-compiler easy my-2 d-flex justify-content-start align-item-center">
                      <span style={{ transform: "scale(1.6)" }}>
                        {" "}
                        <i class="fas fa-circle"></i>{" "}
                      </span>
                      <span className="mx-4 question_name"> {val.name} </span>
                      <span className="green"></span>
                    </div>
                  </Link>{" "}
                </div>
              );
            })}
          </div>
        </div>

        <div className="col-xs-4 col-sm-4 col-md-5 col-lg-4">
          <div className="d-flex flex-column">
            <span className="Level_Text">Capgemini</span>
            {Capgemini.map((val, i) => {
              return (
                <div>
                  <Link
				     onClick={(e) => {
                      ReactGA.event({
                        category: "Dashboard",
                        type: "Coding Exam",
                        name: val.name,
                        action: val.name,
                      });
                    }}
                    style={{ cursor: "pointer" }}
                    to={"/compiler/" + val._id + "/" + courseID}
                  >
                    <div className="shadow question-card-compiler easy my-2 d-flex justify-content-start align-item-center">
                      <span style={{ transform: "scale(1.6)" }}>
                        {" "}
                        <i class="fas fa-circle"></i>{" "}
                      </span>
                      <span className="mx-4 question_name"> {val.name} </span>
                      <span className="green"></span>
                    </div>
                  </Link>{" "}
                </div>
              );
            })}
          </div>
        </div>
        <div className="col-xs-4 col-sm-4 col-md-5 col-lg-4">
          <div className="d-flex flex-column">
            <span className="Level_Text">Cognizant</span>
            {Cognizant.map((val, i) => {
              return (
                <div>
                  <Link
				     onClick={(e) => {
                      ReactGA.event({
                        category: "Dashboard",
                        type: "Coding Exam",
                        name: val.name,
                        action: val.name,
                      });
                    }}
                    style={{ cursor: "pointer" }}
                    to={"/compiler/" + val._id + "/" + courseID}
                  >
                    <div className="shadow question-card-compiler easy my-2 d-flex justify-content-start align-item-center">
                      <span style={{ transform: "scale(1.6)" }}>
                        {" "}
                        <i class="fas fa-circle"></i>{" "}
                      </span>
                      <span className="mx-4 question_name"> {val.name} </span>
                      <span className="green"></span>
                    </div>
                  </Link>{" "}
                </div>
              );
            })}
          </div>
        </div>
        <div className="col-xs-4 col-sm-4 col-md-5 col-lg-4">
          <div className="d-flex flex-column">
            <span className="Level_Text">Accenture</span>
            {Accenture.map((val, i) => {
              return (
                <div>
                  <Link
				     onClick={(e) => {
                      ReactGA.event({
                        category: "Dashboard",
                        type: "Coding Exam",
                        name: val.name,
                        action: val.name,
                      });
                    }}
                    style={{ cursor: "pointer" }}
                    to={"/compiler/" + val._id + "/" + courseID}
                  >
                    <div className="shadow question-card-compiler easy my-2 d-flex justify-content-start align-item-center">
                      <span style={{ transform: "scale(1.6)" }}>
                        {" "}
                        <i class="fas fa-circle"></i>{" "}
                      </span>
                      <span className="mx-4 question_name"> {val.name} </span>
                      <span className="green"></span>
                    </div>
                  </Link>{" "}
                </div>
              );
            })}
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="container mx-auto">
      <div class="d-flex justify-content-evenly my-3">{handleButtons()}</div>

      {selectedButton === "difficulty" && (
        <div className="row">
          <div className="col-xs-4 col-sm-4 col-md-5 col-lg-4">
            <div className="d-flex flex-column">
              <span className="Level_Text">Easy Level</span>

              {easyquestions.map((val, i) => {
                return (
                  <div>
                    <Link
					   onClick={(e) => {
                      ReactGA.event({
                        category: "Dashboard",
                        type: "Coding Exam",
                        name: val.name,
                        action: val.name,
                      });
                    }}
                      style={{ cursor: "pointer" }}
                      to={"/compiler/" + val._id + "/" + courseID}
                    >
                      <div className="shadow question-card-compiler easy my-2 d-flex justify-content-start align-item-center">
                        <span style={{ transform: "scale(1.6)" }}>
                          {" "}
                          <i class="fas fa-circle"></i>{" "}
                        </span>
                        <span className="mx-4 question_name"> {val.name} </span>
                        <span className="green"></span>
                      </div>
                    </Link>{" "}
                  </div>
                );
              })}
            </div>
          </div>
          {/* questions that are for medium level */}
          <div className="col-xs-4 col-sm-4 col-md-5 col-lg-4">
            <div className="d-flex flex-column">
              <span className="Level_Text">Medium Level</span>
              {mediumquestions.map((val, i) => {
                return (
                  <div>
                    <Link
					   onClick={(e) => {
                      ReactGA.event({
                        category: "Dashboard",
                        type: "Coding Exam",
                        name: val.name,
                        action: val.name,
                      });
                    }}
                      style={{ cursor: "pointer" }}
                      to={"/compiler/" + val._id + "/" + courseID}
                    >
                      <div className="shadow question-card-compiler medium my-2 d-flex justify-content-start align-item-center">
                        <span style={{ transform: "scale(1.6)" }}>
                          {" "}
                          <i class="fas fa-circle"></i>{" "}
                        </span>
                        <span className="mx-4 question_name"> {val.name} </span>
                        <span className="green"></span>
                      </div>
                    </Link>{" "}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="col-xs-4 col-sm-4 col-md-5 col-lg-4">
            <div className="d-flex flex-column">
              <span className="Level_Text">Hard Level</span>

              {hardquestions.map((val, i) => {
                return (
                  <div>
                    <Link
					   onClick={(e) => {
                      ReactGA.event({
                        category: "Dashboard",
                        type: "Coding Exam",
                        name: val.name,
                        action: val.name,
                      });
                    }}
                      style={{ cursor: "pointer" }}
                      to={"/compiler/" + val._id + "/" + courseID}
                    >
                      <div className="shadow question-card-compiler hard my-2 d-flex justify-content-start align-item-center">
                        <span style={{ transform: "scale(1.6)" }}>
                          {" "}
                          <i class="fas fa-circle"></i>{" "}
                        </span>
                        <span className="mx-4 question_name"> {val.name} </span>
                        <span className="green"></span>
                      </div>
                    </Link>{" "}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}

      {selectedButton === "category" && (
        <div class="row">{renderCategories()}</div>
      )}

      {selectedButton === "companies" && (
        <div class="row">{renderCompanies()}</div>
      )}
    </div>
  );
};
export default AllQuestion;

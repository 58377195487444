import React, { useState, useContext, useEffect, useLayoutEffect } from "react";
import { ModalContext } from "../Context/ModalContext";
import useMedia from "../componentsofcourse/useMedia";
import SM from "../Imgsofcourse/images/Image 1.png";
import ConfusedForm from "../componentsofcourse/ConfusedForm";
import CpHero from "../components/CpHero";
import PricingTable from "../componentsofcourse/PricingTable";
import Process from "../componentsofcourse/Process";
import Highlights from "../componentsofcourse/Highlights";
import Middle from "../componentsofcourse/middle";
import Test from "../componentsofcourse/Test";
import Whyweb from "../componentsofcourse/whywebdev";
import certificate from "../Assets/img/Competitive/certificate.png";
import Assistance from "../Assets/img/Competitive/Assistance.png";
import Manish from "../Assets/img/Competitive/Image_30.png";
import Vivek from "../Assets/img/Competitive/Vivek.png";
import Bajaj from "../Assets/img/Competitive/Bajaj.png";
import linkedin from "../Assets/img/Competitive/linkedin.png";
import amazon_app from "../Assets/img/Competitive/amazon_app.png";
import google_app from "../Assets/img/Competitive/google_app.png";
import ms_app from "../Assets/img/Competitive/ms_app.png";
import CpCourseCards from "./cpCourseCards";
import Mentors from "./CPMentors";
import CPcertificate from "./CPcertificate";
import CpCompany from "../componentsofcourse/CpCompany";
import Internship from "../components/Internship";
import MoreQueries from "../components/MoreQueries";
import Main from "../componentsofcourse/Main";
import Footer from "../components/Footer";
import compImg from "../Assets/img/Competitive/Group_7625.png";
import placeImg from "../Assets/img/course/pb-img.png";
import amazon from "../Assets/img/Placement/amazon.png";
import google from "../Assets/img/Placement/Google.png";
import microsoft from "../Assets/img/Placement/microsoft.png";
import linkedin1 from "../Assets/img/Placement/linkedin_color.png";
import adobe from "../Assets/img/Placement/adobe.png";
import flipkart from "../Assets/img/Placement/flipkart_color.png";
import IconStar from "../Assets/img/course/icon_star.png";
import IconUsers from "../Assets/img/course/icon_users.png";
import IconCalendar from "../Assets/img/course/icon_calender.png";
import IconHands from "../Assets/img/course/icon_hands.png";
import BatchStart from "../components/BatchStart";
import CPCourseImages from "./CPCourseImages";
import Testimonials from "./Testimonials";
import Navbar from "../components/Navbar";
import ReactPixel from "react-facebook-pixel";
import NewConfusedForm from "../componentsofcourse/NewConfusedForm";

const CPcourse = () => {
  const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
  const options = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: false, // enable logs
  };
  let { open, update_status } = useContext(ModalContext);
  const [modal, setModal] = useState(false);
  useEffect(() => {
    update_status(!open);
    setModal();
  }, [modal]);
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });


  useEffect(() => {
    ReactPixel.init("337209321107283", advancedMatching, options);
    ReactPixel.trackCustom("COMPETITIVE_PROGRAMMING_PAGE");
  },[])
  const matches = useMedia("(min-width : 768px )");

  const parameters = {
    header: (
      <div>
        Learn
        <br />
        Competitive Programming
      </div>
    ),
    matter: (
      <div>
        This online Competitive Programming course is an ultimate online
        competitive coding training. Here you will learn to write most efficient
        programmes and equip yourself to get solutions for the complex codes.
        Get ready for the competitions like ACM-ICPC, Google Code Jam and more.
        This online course will cover all the basic to advanced concepts that
        you will require to solve complex questions.
      </div>
    ),
    mainImage: compImg,

    icons: {
      IconStar,
      IconUsers,
      IconCalendar,
      //   IconHands,
    },
    techImg: {
      0: amazon,
      1: google,
      2: microsoft,
      3: linkedin1,
      4: adobe,
      5: flipkart,

      //   4: google,
    },
    iconText: {
      0: "4.1",
      1: "5362",
      2: "3 Weeks",
      //   3: "100% internship guarantee",
    },
  };

  const highlightsParameters = {
    text: {
      0: (
        <div>
          Learn at the comfort of <br /> your HOME
        </div>
      ),
      1: (
        <div>
          Learn from <br /> Industry Experts
        </div>
      ),
      2: (
        <div>
          {" "}
          Beginner
          <br /> friendly
        </div>
      ),
      3: (
        <div>
          {" "}
          Live online classes
          <br /> to boost productivity
        </div>
      ),
      4: (
        <div>
          {" "}
          Solve 50+
          <br /> Questions
        </div>
      ),
      5: (
        <div>
          Doubt
          <br /> Assistance
        </div>
      ),
    },
    mobText: {
      0: "Learn at the comfort of <br /> your HOME",
      1: "Learn from Industry Experts",
      2: "Beginner Friendly",
      3: "Live online classes to boost productivity",
      4: "Solve 50+ Questions",
      5: "Doubt Assistance",
    },
  };

  const [name, setName] = useState("cpcourse");

  return (
    <>
      {/* <Navbar /> */}

      <div
        class="container-fluid"
        style={{ overflowX: "hidden" }}
        style={{ overflowX: "hidden", paddingLeft: "0px", paddingRight: "0px" }}
      >
        <Main parameters={parameters} />
        <Highlights parameters={highlightsParameters} />
        {/* <CpHero /> */}
        <CPCourseImages />

        {/* <Highlights /> */}
        <br />
        <br />
        <Mentors />
        <CpCourseCards set={1} set1={"Why Learn Competitive Programing"} />
        <CPcertificate m={matches} />
        <Testimonials />
        <Test />
        <PricingTable />
        <BatchStart />
        {/* <ConfusedForm coursename={"cpcourse"} set={1} /> */}
        <NewConfusedForm/>
        <CpCourseCards set={2} set1={"Why Learn Competitive Programming"} />
        <CpCompany />
        <Process />
        {/* <Middle /> */}
        <MoreQueries />
        <Footer />
      </div>
    </>
  );
};
export default CPcourse;

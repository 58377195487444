import { Tooltip } from "@mui/material";
import "../Assets/css/DiffCards.scss";
function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
}

function getMultipleRandom(arr, num) {
  const shuffled = [...arr].sort(() => 0.5 - Math.random());

  return shuffled.slice(0, num);
}

const companies = {
  Amazon:
    "https://cantileverlabs-gradient-images.s3.ap-south-1.amazonaws.com/company+logos/amazon_PNG13.png",
  Facebook:
    "https://cantileverlabs-gradient-images.s3.ap-south-1.amazonaws.com/company+logos/facebook_logo.png",
  TCS: "https://cantileverlabs-gradient-images.s3.ap-south-1.amazonaws.com/company+logos/TCS_LOGO.png",
  Wipro:
    "https://cantileverlabs-gradient-images.s3.ap-south-1.amazonaws.com/company+logos/IMG-20220923-WA0006.jpg",
  Microsoft:
    "https://cantileverlabs-gradient-images.s3.ap-south-1.amazonaws.com/company+logos/IMG-20220923-WA0003.jpg",
  Google:
    "https://cantileverlabs-gradient-images.s3.ap-south-1.amazonaws.com/company+logos/IMG-20220923-WA0007.jpg",
};

function DiffCategory(props) {
  const { name, category, level, link, company } = props;

  function getDifficultyStatus(level) {
    if (level <= 2) {
      return "Easy";
    } else if (level === 3) {
      return "Medium";
    } else {
      return "Hard";
    }
  }
  return (
    <a
      className="col-md-4 col-sm-6 mb-md-4 mb-2"
      href={link}
      target="_blank"
      rel="noreferrer"
    >
      <div className="diffcultyCard">
        <div className="diffcultyCardHead">
          <div className="diffcultyCodingName">{name}</div>
          <div className="diffcultyResult"></div>
        </div>
        <hr />
        <div className="diffcultyCardFoot">
          <Tooltip title={getDifficultyStatus(level)}>
          <div className="diffcultyBtn" style={{ background: "#D9EAB3", color:"#7FBA00" }}>
            {getDifficultyStatus(level)}
          </div>
          </Tooltip>
          <Tooltip title={category}>
          <div
            className="diffcultyBtn"
            style={{
              background: "#494343",
            }}
          >
            {category}
          </div>
          </Tooltip>
           
        </div>
        <div className="diffcultyCardBody">
          <ul className="diffcultyType">
            {company.map((val, index) => {
              return (
                <li
                  key={index}
                  className="diffcultyTypeItem"
                >
                  <img
                    className=" mt-1"
                    src={val.logo}
                    alt={`${val.name} logo`}
                    height={30}
                    width={30}
                    style={{
                      objectFit: "contain",
                    }}
                  />
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </a>
    // </a>
  );
}

export default DiffCategory;

import React from "react";
import ReactDOM from "react-dom";
import useMedia from "./useMedia";
import Process1 from "../Assets/img/course/process_1.png";
import Process2 from "../Assets/img/course/process_2.png";
import Process3 from "../Assets/img/course/process_3.png";
import Process4 from "../Assets/img/course/process_4.png";
import Numbers from "../Assets/img/course/numbers.png";

function Process() {
  const matches = useMedia("(min-width : 768px )");

  return (
    <div className="container" style={{ justifyItems: "center" }}>
      <h1
        className="text-center"
        style={{ fontWeight: "bold", fontSize: "3rem" }}
      >
        The Process
      </h1>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />

      {/* { matches ? "hello" : "world"}

{
           matches ?
           <div className="container">
            <div className="row">
            <div className="col-4">
            <div className="row">
                <div className="col-md-5 col-xs-12 col-12">

                </div>
                <div className="col-md-7 col-xs-12 col-12">
                    <p style={{paddingTop:'35%'}} className="text-right font-weight-bold">
                    Attend live classes
                    get your doubt clarified
                    in less that 30 mins
                    </p>
                    <br/>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <img className="img-fluid float-right" src={Process2} alt="Work on assignments and projects under guidance of experts community" />
                    <br/>
                </div>
            </div>
            <div className="row">
                <div className="col-md-5 col-xs-12 col-12">

                </div>
                <div className="col-md-7 col-xs-12 col-12">
                    <p style={{paddingTop:'55%'}} className="text-right font-weight-bold">
                    Get Ready with Resume
                    and Experience Mock Interviews
                    With Industry Experts
                    </p>
                    <br/>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <img className="img-fluid float-right" src={Process4} alt="Get a Guarantee Internship and support for Placement Build great Connections" />
                </div>
            </div>

        </div>

            <div className="col-4">
                <img className="mx-auto d-block" src={Numbers} alt="steps of process"/>
            </div>


        <div className="col-4">
            <div className="row">
                <div className="col">
                    <img className="img-fluid"  src={Process1} alt="Attend live classes get your doubt clarified in less that 30 mins" />
                    <br/>
                </div>
            </div>
            <div className="row">
                <div className="col-md-7 col-xs-12 col-12">
                    <p style={{paddingTop:'35%'}} className="text-left font-weight-bold">
                    Work on assignments and
                    projects under guidance of
                    experts community
                    </p>
                    <br/>
                </div>
                <div className="col-md-5 col-xs-12 col-12">

                </div>
            </div>
            <div className="row">
                <div className="col">
                    <img  className="img-fluid"  src={Process3} alt="Get Ready with Resume and Experience Mock Interviews With Industry Experts" />
                    <br/>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <p style={{paddingTop:'35%'}} className="text-left font-weight-bold">
                    Get a Guarantee Internship
                    and support for Placement
                    Build great Connections
                    </p>
                    <br/>
                </div>

            </div>
        </div>
        </div>
        </div>
        :
        <div className="container-fluid">
            <div className="row">
                <div className="col-3">
                    <img height="100%" src={Numbers} alt="steps of process"/>
                </div>
                <div className="col-9">
                    <p className="font-weight-bold">
                    Attend live classes get your doubt clarified  in less that 30 mins
                    </p>
                    <br/>
                    <br/>
                    <img className="img-fluid"  src={Process1} alt="Attend live classes get your doubt clarified in less that 30 mins" />
                    <br/>

                    <br/><br/>
                    <p className="font-weight-bold">
                    Work on assignments and projects under guidance of experts community
                    </p>
                    <br/>
                    <img className="img-fluid"  src={Process2} alt="Attend live classes get your doubt clarified in less that 30 mins" />

                    <br/><br/><br/>
                    <p className="font-weight-bold">
                    Get Ready with Resume and Experience Mock Interviews With Industry Experts
                    </p>
                    <br/><br/>
                    <img className="img-fluid"  src={Process3} alt="Attend live classes get your doubt clarified in less that 30 mins" />
                    <br/><br/><br/>
                    <p className="font-weight-bold">
                    Get a Guarantee Internship and support for Placement Build great Connections
                    </p>

                </div>
            </div>
            <div className="row">
                <div className="col-3">
                </div>
                <div className="col-9">
                    <br/>
                    <img className="img-fluid"  src={Process4} alt="Attend live classes get your doubt clarified in less that 30 mins" />
                <br/>

                </div>
            </div>

        </div>
        }
        <br/>
        <br/>
        <br/>
        <br/>
            <h2 className="text-center">The Process</h2>
        <br/>
        <br/>
        <br/>

         { matches ? "hello" : "world"} */}

      {matches ? (
        <div className="container">
          <div className="row">
            <div className="col-4">
              <div className="row">
                <div className="col-md-3 col-xs-12 col-12"></div>
                <div className="col-md-9 col-xs-12 col-12">
                  <p
                    style={{
                      textAlign: "right",
                      marginTop: "-2%",
                      fontWeight: "600",
                    }}
                    className="font-weight-bold"
                  >
                    Attend live sessions from Industry experts and get your
                    doubt clarified from TA
                  </p>
                  <br />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <img
                    className="img-fluid float-right"
                    src={Process2}
                    alt="Work on assignments and projects under guidance of experts community"
                  />
                  <br />
                </div>
              </div>
              <div className="row">
                <div className="col-md-3 col-xs-12 col-12"></div>
                <div className="col-md-9 col-xs-12 col-12">
                  <p
                    style={{
                      marginTop: "47%",
                      textAlign: "right",
                      fontWeight: "600",
                    }}
                    className="text-right font-weight-bold"
                  >
                    Get Ready with Resume and Experience Mock Interviews With
                    Industry Experts
                  </p>
                  <br />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <img
                    className="img-fluid float-right"
                    src={Process4}
                    alt="Get a Guarantee Internship and support for Placement Build great Connections"
                  />
                </div>
              </div>
            </div>

            <div className="col">
              <img
                className="mx-auto d-block"
                src={Numbers}
                alt="steps of process"
              />
            </div>

            <div className="col-4">
              <div className="row">
                <div className="col">
                  <img
                    className="img-fluid"
                    style={{ marginTop: "-25%" }}
                    src={Process1}
                    alt="Attend live classes get your doubt clarified in less that 30 mins"
                  />
                  <br />
                </div>
              </div>
              <div className="row">
                <div className="col-md-11 col-xs-12 col-12">
                  <p
                    style={{
                      marginTop: "37%",
                      textAlign: "left",
                      fontWeight: "600",
                    }}
                    className="text-left font-weight-bold"
                  >
                    Work on assignments and projects under guidance of experts
                    community
                  </p>
                  <br />
                </div>
                <div className="col-md-1 col-xs-12 col-12"></div>
              </div>
              <div className="row">
                <div className="col">
                  <img
                    className="img-fluid"
                    src={Process3}
                    alt="Get Ready with Resume and Experience Mock Interviews With Industry Experts"
                  />
                  <br />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <p
                    style={{
                      marginTop: "40%",
                      textAlign: "left",
                      fontWeight: "600",
                    }}
                    className="text-left font-weight-bold"
                  >
                    Get certificate of course completion and get 100% job
                    assistance
                  </p>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container-fluid">
          <div className="row">
            <div className="col-3">
              <img height="100%" src={Numbers} alt="steps of process" />
            </div>
            <div className="col-9">
              <p className="font-weight-bold">
                Attend live classes get your doubt clarified in less that 30
                mins
              </p>
              <br />
              <br />
              <img
                className="img-fluid"
                src={Process1}
                alt="Attend live classes get your doubt clarified in less that 30 mins"
              />
              <br />

              <br />
              <br />
              <p className="font-weight-bold">
                Work on assignments and projects under guidance of experts
                community
              </p>
              <br />
              <img
                className="img-fluid"
                src={Process2}
                alt="Attend live classes get your doubt clarified in less that 30 mins"
              />

              <br />
              <br />
              <br />
              <p className="font-weight-bold">
                Get Ready with Resume and Experience Mock Interviews With
                Industry Experts
              </p>
              <br />
              <br />
              <img
                className="img-fluid"
                src={Process3}
                alt="Attend live classes get your doubt clarified in less that 30 mins"
              />
              <br />
              <br />
              <br />
              <p className="font-weight-bold">
                Get certificate of course completion and get 100% job assistance
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-3"></div>
            <div className="col-9">
              <br />
              <img
                className="img-fluid"
                src={Process4}
                alt="Attend live classes get your doubt clarified in less that 30 mins"
              />
              <br />
            </div>
          </div>
        </div>
      )}
      <br />

      <button
        style={{
          backgroundColor: "#FFC600",
          padding: "15px 100px 15px",
          borderRadius: "12px",
          fontSize: "20px",
          border: "none",
          marginBottom: "10%",
        }}
        className="text-white btn btn-success mx-auto d-block font-weight-bold"
      >
        {" "}
        <a
          className="anchor white"
          style={{ textDecoration: "none", color: "white" }}
          href="https://us6.list-manage.com/contact-form?u=14ad39c02ad3d8d36fdc53550&form_id=13c76439cc990bf05c7e83fb8f5c0ebb"
        >
          {" "}
          Request a Call
        </a>{" "}
      </button>
    </div>
  );
}

export default Process;

import React, { useState, useContext, useEffect } from "react";
import styles from "../Assets/css/createposting.module.css";
// import "react-quill/dist/quill.snow.css";
import { Store } from "react-notifications-component";
import { AuthContext } from "../Context/AuthContext";
// import { Redirect } from "react-router-dom";
import { BASE_URL } from "../env";
import { useHistory } from "react-router-dom";

const notifyPopup = () => {
  Store.addNotification({
    title: "Success!",
    message: "Job Posting Successful",
    type: "success",
    background: "pink",
    insert: "bottom",
    container: "bottom-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 3000,
      onScreen: true,
    },
  });
};

export default function JobPostings() {
  const [errorMessage, setErrorMessage] = useState();
  const [successState, setSuccessState] = useState(false);
  const { token, update_token } = useContext(AuthContext);
  const [isAdmin, setIsAdmin] = useState(true);
  const history = useHistory();

  useEffect(() => {
    fetch(BASE_URL + "/getProfile", {
      headers: { authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log(data);
        if (data.error) {
          setIsAdmin(false);
        } else {
          setIsAdmin(data.user.isAdmin);
        }
      });
  });

  if (!isAdmin) history.push("/signin");

  const createPosting = (e) => {
    const API_ENDPOINT = "https://api.cantileverlabs.com/";
    // const API_ENDPOINT = "http://localhost:5000/";
    setErrorMessage("");
    setSuccessState(false);
    const inputID = document.querySelectorAll(
      ".postings-container > div > label"
    );
    const data = new FormData();
    for (let i = 0; i < inputID.length; i++) {
      let element_name = inputID[i].htmlFor;
      data.append(element_name, getElement(element_name));
    }
    // console.log(document.getElementById("jobType").value);
    data.append("jobType", document.getElementById("jobType").value);
    data.append("InternalJob", document.getElementById("InternalJob").value);
    data.append("priority", document.getElementById("priority").value);
    data.append(
      "hiring_process",
      document.getElementById("hiring_process").value
    );

    console.log(data);
    fetch(`${BASE_URL}/createJobPosting`, {
      method: "POST",

      body: data,
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log(data);
        if (data.success) {
          // console.log("job posting success");
          setSuccessState(true);
          // console.log(inputID.length);
          for (let i = 0; i < 12; i++) {
            inputID[i].nextElementSibling.value = "";
          }
          notifyPopup();
        }
      })
      .catch((err) => {
        // console.log(err)
      });
  };

  function getElement(id) {
    let inputData = document.getElementById(id);
    if (inputData.validity.valid) {
      if (id === "companyLogo") {
        return inputData.files[0];
      }
      if (id === "dateAdded" || id === "dueDate") {
        let time_string = new Date(inputData.value).getTime();
        return time_string;
      }
      if (id === "Fulltime" || id === "Remote") {
        if (inputData.value === "true") return true;
        if (inputData.value === "false") return false;
      }

      return inputData.value;
    }
    const error_div = inputData.attributes["placeholder"].nodeValue;
    setErrorMessage(`${error_div} is a required field`);
    return null;
  }

  return (
    <div>
      <div className={`${styles.formcontainer} postings-container`}>
        <div className={styles.blogText}>
          <h1>Add Job Posting</h1>
        </div>
        <div>
          <label htmlFor="companyName">
            Company Name<span style={{ color: "red" }}>*</span> :
          </label>
          <input
            type="text"
            id="companyName"
            required
            placeholder="Company Name"
            className={styles.formcontrol}
          />
        </div>
        <div className={styles.textareadiv}>
          <label htmlFor="aboutCompany">
            About Company<span style={{ color: "red" }}>*</span> :
          </label>
          <textarea
            type="text"
            id="aboutCompany"
            required
            placeholder="About Company"
          ></textarea>
        </div>
        <div>
          <label htmlFor="companyLogo" className="pl-3 pt-3">
            Company Logo :
          </label>
          <input
            type="file"
            id="companyLogo"
            placeholder="Company Logo"
            accept="image/*"
            style={{ backgroundColor: "#e2e6e9", width: "15em" }}
          />
        </div>
        <div className={`mt-3`}>
          <label htmlFor="positionName">
            Position Name<span style={{ color: "red" }}>*</span> :
          </label>
          <input
            type="text"
            id="positionName"
            required
            placeholder="Position Name"
            className={styles.formcontrol}
          />
        </div>
        <div>
          <label htmlFor="dateAdded">
            Date Added<span style={{ color: "red" }}>*</span> :
          </label>
          <input
            type="date"
            id="dateAdded"
            required
            placeholder="Date added"
            className={styles.dateinput}
          />
        </div>
        <div>
          <label htmlFor="dueDate">Last date to apply :</label>
          <input
            type="date"
            id="dueDate"
            placeholder="Last date"
            className={styles.dateinput}
          />
        </div>
        <div>
          <label htmlFor="links">
            Link<span style={{ color: "red" }}>*</span> :
          </label>
          <input
            type="text"
            id="links"
            placeholder="Application form link"
            className={styles.formcontrol}
            required
          />
        </div>
        <div className={styles.textareadiv}>
          <label htmlFor="skillsRequired">
            Skills required<span style={{ color: "red" }}>*</span> :
          </label>
          <textarea
            type="text"
            id="skillsRequired"
            required
            placeholder="Skills"
          ></textarea>
        </div>
        <div className={`${styles.textareadiv} mt-3`}>
          <label htmlFor="description">
            Description<span style={{ color: "red" }}>*</span> :
          </label>
          <textarea
            type="text"
            id="description"
            required
            placeholder="Description"
          ></textarea>
        </div>
        <div className={`${styles.textareadiv} mt-3`}>
          <label htmlFor="description">
            Hiring Process<span style={{ color: "red" }}>*</span> :
          </label>
          <textarea
            type="text"
            id="hiring_process"
            required
            placeholder="Hiring Process"
          ></textarea>
        </div>
        <div className="mt-3">
          <label htmlFor="Location">
            Location <span style={{ color: "red" }}>*</span>:
          </label>
          <input
            type="text"
            id="Location"
            placeholder="Location"
            className={styles.formcontrol}
            required
          />
        </div>
        <div>
          <label htmlFor="payRange">Pay range :</label>
          <input
            type="text"
            id="payRange"
            placeholder="Pay Range"
            className={styles.formcontrol}
          />
        </div>
        <div>
          <label htmlFor="vacancies">Vacancies :</label>
          <input
            type="text"
            id="vacancies"
            placeholder="Vacancies"
            className={styles.formcontrol}
          />
        </div>
        <div>
          <label htmlFor="Fulltime">Fulltime :</label>
          <select name="Fulltime" id="Fulltime">
            <option value="true">True</option>
            <option value="false">False</option>
          </select>
        </div>

        <div>
          <label htmlFor="Remote">Remote :</label>
          <select name="Remote" id="Remote">
            <option value="true">True</option>
            <option value="false">False</option>
          </select>
        </div>

        <div>
          <select name="InternalJob" id="InternalJob">
            <option value="none"> Internal Job</option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        </div>

        <div>
          <select name="jobType" id="jobType">
            <option value="none">----------</option>
            <option value="business">Business Development</option>
            <option value="software_development">Software Development</option>
            <option value="fullstack">Full Stack Development</option>
            <option value="frontend">Frontend Development</option>
            <option value="backend">Backend Development</option>
            <option value="content_writing">Content Writing</option>
            <option value="marketing">Marketing</option>
            <option value="graphic_design">Graphic Designing</option>
            <option value="apprenticeship">Apprenticeship</option>
            <option value="AI">Artificial Intelligence</option>
            <option value="CA">Chartered Accountant</option>
            <option value="management_trainee">Management Trainee</option>
            <option value="campus_ambassador">Campus Ambassador</option>
            <option value="HR">Human Resources</option>
            <option value="finance">Finance</option>
            <option value="socialMedia_marketing">
              Social Media Marketing
            </option>
            <option value="product_management">Product Management</option>
          </select>
        </div>

        <div>
          <select name="priority" id="priority">
            <option value="none">Select Priority</option>
            <option value="1">Top Companies</option>
            <option value="2">Medium Companies</option>
            <option value="3">3rd tier Companies</option>
          </select>
        </div>
        <button onClick={createPosting} class={styles.registerbtn}>
          Submit
        </button>
        {<div>{errorMessage}</div>}
        {successState && <div>Job Posting Successful</div>}
      </div>
      <br />
    </div>
  );
}

import { useState, useEffect } from "react";
import Editor from "@monaco-editor/react";
import { get } from "lodash";

interface ICustomInputs {
    onChange: (input: string | undefined, expectedOutput: string | undefined) => void;
}

function CustomInputs({ onChange }: ICustomInputs) {
    const [expectedOutput, setExpectedOutput] = useState<string | undefined>("");
    const [input, setInput] = useState<string | undefined>("");

    useEffect(() => {
        onChange(input, expectedOutput);
    }, [input, expectedOutput, onChange])

    function handleEditorMount(monaco) {
        // console.log(monaco.editor.defineTheme);
        monaco.editor.defineTheme('editor-theme', {
            base: 'vs',
            inherit: true,
            rules: [],
            colors: {
                'editor.background': '#15314b',
                'editor.foreground': '#ffffff',
                "editorCursor.foreground": "#ff0000",
                "editor.selectionBackground": 'rgb(255, 255, 255)',
            },
        })
    }
    return (
        <div style={{ marginBottom: "5px" }}>
            <div>Your Input: </div>
            <Editor
                height={"8rem"}
                width={"100%"}
                theme="editor-theme"
                options={{
                    lineNumbers: "off",
                    minimap: { enabled: false },
                }}
                beforeMount={handleEditorMount}
                onChange={setInput}
                value={input}
            />
            <br />
            {/* <div>Expected Output: </div>
            <Editor
                height={"5rem"}
                width={"100%"}
                theme="editor-theme"
                options={{
                    lineNumbers: "off",
                    minimap: { enabled: false },
                }}
                beforeMount={handleEditorMount}
                onChange={setExpectedOutput}
                value={expectedOutput}
            /> */}
        </div>
    )
}

export default CustomInputs;
// import "./styles.css";
import Headingnewcoursepage from "../componentsofcourse/Headingnewcoursepage"
import useMedia from "../componentsofcourse/useMedia";
import { useForm } from "react-hook-form";
import { BASE_URL } from "../env";
import { Store } from "react-notifications-component";
export default function NewConfusedForm() {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset
  } = useForm();

  const onSubmit = (data) => {
    // console.log(data)
  
    fetch(BASE_URL + "/confusedresults/new",
      {
        method:"POST",
        body: JSON.stringify(data),
        headers: { "content-Type": "application/json" },
      }
    ).then((response) => response.json())
      .then((data) => {
        // console.log(data)
        reset()
        notifyPopup()
    })
  };
   const notifyPopup = () => {
		Store.addNotification({
			title: "Success",
			message: "Your data has been sent",
			type: "danger",
			background: "pink",
			insert: "bottom",
			container: "bottom-right",
			animationIn: ["animate__animated", "animate__fadeIn"],
			animationOut: ["animate__animated", "animate__fadeOut"],
			dismiss: {
				duration: 3000,
				onScreen: true,
			},
		});
	};

  const matches = useMedia("(max-width : 575px )");
  return (
    <div className="App">
      <div className="container row my-5 d-flex justify-content-start">
        <div className="col-lg-6 col-md-5 col-sm-6 col-xs-4 col-xs-8 col-xxs-12 ">
          <div className="d-flex justify-content-center">
            <lottie-player
              src="https://assets8.lottiefiles.com/private_files/lf30_VuL9i1.json"
              background="transparent"
              speed="1"
              loop
              autoplay
              style={{ height: "300px", width: "300px" }}
            ></lottie-player>
          </div>
          
           
            <Headingnewcoursepage heading="Confused?"/>
       

          <h4 style={{textAlign: "center"}}>Get Expert Guidance!! </h4>
        </div>

        <div
          className={`col-lg-6 col-md-5 col-sm-6 col-xs-4 col-xs-8 col-xxs-12 card shadow ${matches?"my-3":"my-5"} `}
          style={{
            height: "370px",
            width: "400px",
            borderRadius: "10px",
            // marginLeft: "5vw"
          }}
        >
          <form onSubmit={handleSubmit(onSubmit)} className="p-3">
            {/* register your input into the hook by invoking the "register" function */}
            <input
              class="form-control my-3 p-2 "
              placeholder="First Name"
              {...register("FirstName", { required: true })}
            />

            {/* include validation with required or other standard HTML validation rules */}
            <input
              class="form-control my-3 p-2"
              placeholder="Last Name"
              {...register("LastName", { required: true })}
            />
            <input
              class="form-control my-3 p-2"
              placeholder="Phone Number"
              {...register("PhoneNumber", { required: true })}
            />
            <input
              class="form-control my-3 p-2"
              placeholder="Graduation Year"
              type="number"
              {...register("GraduationYear", {
                required: true,
              })}
            />
            <input
              class="form-control my-3 p-2"
              placeholder="What is your query?"
              type="text"
              {...register("Query", {
                required: true
              })}
            />
            {/* {errors.GraduationYear && <span>Graduation less rehna </span>} */}

            <button
              style={{ borderRadius: "8px" }}
              class="btn btn-primary btn-block px-4"
              type="submit"
            >
              {" "}
              Get in Touch
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

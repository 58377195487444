import configAxios from "../../axios.config";
import { useDispatch, useSelector } from "react-redux";
import { batchName } from "../app/actions/batchActions";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getColor } from "../helper";
const PowerBatch = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const selector = useSelector((reducers) => reducers.batchReducer);
  const examType = useSelector((reducers) => reducers.examReducer);
  const {
    data: batches,
    isError,
    error,
    isLoading,
  } = useQuery(
    "batchNames",
    () => {
      return configAxios(localStorage.getItem("token")).get(
        "/powerbi/get-courses/" + id
      );
    },
    {
      onSuccess: ({ data }) => {
        console.log(data);
        if (data.course.courses.length > 0) {
          dispatch(batchName(data.course.courses[0]._id));
        }
      },
      enabled: !!id
    }
  );
  if (isError) {
    console.log(error);
    return <></>;
  }

  function getBatchName(name) {
    switch (name) {
      case "62d63c31bc0f60e6ac1694a6":
        return "Section 3";

      case "62d63901bc0f60e6ac1694a4":
        return "Section 1";

      case "62d63c40bc0f60e6ac1694a7":
        return "Section 4";

      case "62d63bb5bc0f60e6ac1694a5":
        return "Section 2";

      default:
        return null;
    }
  }

  return (
    <>
      <div className="allBatches powerBiCard" style={{backgroundColor:getColor(examType)}}>
        <div className="powerBiHeading">Batch</div>
        <ul className="batchList">
          {isLoading && <div>Please wait ...</div>}
          {!isLoading &&
            batches.data.course.courses.map((batch) => {
              return (
                <div key={batch._id}>
                  <input
                    type="radio"
                    name="batch_name"
                    value={batch._id}
                    id={batch._id}
                    onChange={(e) => dispatch(batchName(e.target.value))}
                    checked={selector === batch._id}
                  />
                  <label htmlFor={batch._id} className="batchItem">
                    {getBatchName(batch._id)
                      ? getBatchName(batch._id)
                      : batch.name}
                  </label>
                </div>
              );
            })}
        </ul>
      </div>
    </>
  );
};

export default PowerBatch;

import React from "react";
import TCS from "./Assets/img/NewTab/tcs.jpg";
import "./QuestionCommon.css";

const QuestionsCommon = () => {
  return (
    <div className="main-tab">
      <div className="nav-knowledge">
        <div>
          {" "}
          <img className="tcsk" src={TCS} alt="TCS" />
          <span id="Paragraphu">TCS Code VITA & TCS Code NINJA</span>
        </div>

        <span id="Paragraphu" style={{ color: "red", marginRight: "30px" }}>
          Previously Asked Questions{" "}
        </span>
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-evenly",
          //   marginTop: "-20px",
        }}
      >
        <div className="boxCommon">
          <p>TOP 200</p>
          <p>
            {" "}
            <b style={{ fontWeight: "bold", fontSize: "20px" }}>
              APTITUDE QUESTIONS
            </b>
          </p>
          <div>
            <button className="BtnQuestions">SOLVE, IT</button>
          </div>
        </div>
        <div className="boxCommon">
          <p>TOP 200</p>
          <p>
            {" "}
            <b>APTITUDE QUESTIONS</b>
          </p>
          <div>
            <button className="BtnQuestions">SOLVE, IT</button>
          </div>
        </div>
        <div className="boxCommon">
          <p>TOP 200</p>
          <p>
            {" "}
            <b>APTITUDE QUESTIONS</b>
          </p>
          <div>
            <button className="BtnQuestions">SOLVE, IT</button>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-evenly",
          //   marginTop: "-90px",.
        }}
      >
        <div className="boxCommon">
          <p>TOP 200</p>
          <p>
            {" "}
            <b>APTITUDE QUESTIONS</b>
          </p>
          <div>
            <button className="BtnQuestions">SOLVE, IT</button>
          </div>
        </div>
        <div className="boxCommon">
          <p>TOP 200</p>
          <p>
            {" "}
            <b>APTITUDE QUESTIONS</b>
          </p>
          <div>
            <button className="BtnQuestions">SOLVE, IT</button>
          </div>
        </div>
        <div className="boxCommon">
          <p>TOP 200</p>
          <p>
            {" "}
            <b>APTITUDE QUESTIONS</b>
          </p>
          <div>
            <button className="BtnQuestions">SOLVE, IT</button>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
    </div>
  );
};

export default QuestionsCommon;

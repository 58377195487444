// module.exports = { BASE_URL: "https://api.cantileverlabs.com" };
module.exports = {
  BASE_URL: process.env.REACT_APP_BASE_URL,
  BASE_URL_MICRO: process.env.REACT_APP_BASE_URL_MICRO,
  // BASE_URL_MICRO: "http://localhost:4000",
  CODING_SUBMISSION_URL: process.env.REACT_APP_CODING_SUBMISSION_URL,
  PREMIUM: "60ffc6dd7250eb86e86a98b0",
  STANDARD: "6123382bd1b6d9c3023a46fa",
  FASTTRACK: "612b97f0e9c5c70099b9a347",
  PRO: "611fef7d3e1121f7b2a0249a",
  propertyId: "60feb128d6e7610a49ad0b71",
  tawkId: "1fbhe8267",
};

import React, { useState } from "react";
// import styles from "./Blog2.css";
// import "./Blog2.css";
import styles from "../../Assets/css/blog.module.css";
// import card1 from "../Assets/img/Blogs/Blog1.png";
import Tabs from "./Tabs";
import Panel from "./Panel";
import BlogDisp2 from "../../Assets/img/Home/favicon.png";
import BlogDisp from "../../Assets/img/Home/favicon.png";
import {
  BlogMobile,
  BlogMobileTopic,
  BlogTopic,
  Eventfun,
  SampleBlogs,
} from "./data";
import useMedia from "../../componentsofcourse/useMedia";
import { Dropdown, DropdownButton } from "react-bootstrap";
const myInfluencers = [33, 81];

const userId = 4;
// const space = <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>;
function Blog2({ events }) {
  const matches = useMedia("(min-width : 768px )");
  const [selected, setSelected] = useState("Latest");

  const handleSelectDrop = (e) => {
    // setValue(e);
    setSelected(String(e));
  };
  return (
    <div>
        <SampleBlogs />
      {matches ? (
        <div>
          <br />
          <br />
          <br />
          <br />
          <Tabs>
            {/* <ul className="d-flex justify-content-center"> */}
            <Panel title="Latest">
              {/* <div className="container justify-content-center">
            <div
              //   style={{ display: "flex", flexDirection: "row" }}
              className="row justify-content-center"
            > */}
              {/* {events
            .filter((event) => event.host_id !== userId)
            .map((event) => {
              return <div key={event.id}>{event.img}</div>;
            })} */}
              <Eventfun />
              {/* </div> */}
              {/* </div> */}
              {/* </div> */}
            </Panel>
            <Panel title="Web Development">
              {/* {events
            .filter((event) => event.host_id !== userId)
            .map((event) => {
              return <div key={event.id}>{event.img}</div>;
            })} */}

              <BlogTopic topic="Web Development" />
            </Panel>
            <Panel title="Data Science">
              <BlogTopic topic="Data Science" />
            </Panel>
            <Panel title="Career/Placement">
              {/* {events.map((event) => {
            return <div key={event.id}>{event.img}</div>;
          })} */}
              <BlogTopic topic="Career/Placement" />
            </Panel>
            <Panel title="Product Companies">
              {/* <Eventfun title="Product Companies" /> */}
              <BlogTopic topic="Product Companies" />
            </Panel>
            {/* </ul> */}
          </Tabs>
        </div>
      ) : (
        <div className="blogDropdown" style={{ color: "black" }}>
          <br />
          <DropdownButton
            // className={styles["dropdown-toggle"]}
            // className={`${styles["#dropdown-menu-align-right"]} ${styles["dropdown-toggle"]} `}
            // id={styles["#dropdown-menu-align-right"]}
            // id={styles["dropdown-menu-align-right"]}
            style={{
              margin: "1.5%",
              marginLeft: "10%",
              borderRadius: "6px",
              // color: "black",
              // opacity: "0.8",
              backgroundColor: "#EBEBEB",
              width: "fit-content",
              paddingRight: "8px",
              color: "black",
            }}
            variant="grey"
            alignRight
            title={selected}
            // title="Choose the Topic of Blog"
            id="dropdown-menu-align-right"
            onSelect={handleSelectDrop}
          >
            {selected !== "Latest" && (
              <Dropdown.Item eventKey="Latest">Latest</Dropdown.Item>
            )}
            {selected !== "Web Development" && (
              <Dropdown.Item eventKey="Web Development">
                Web Development
              </Dropdown.Item>
            )}
            {selected !== "Data Science" && (
              <Dropdown.Item style={{ color: "black" }} eventKey="Data Science">
                Data Science
              </Dropdown.Item>
            )}
            {selected !== "Career/Placement" && (
              <Dropdown.Item eventKey="Career/Placement">
                Career/Placement
              </Dropdown.Item>
            )}
            {selected !== "Product Companies" && (
              <Dropdown.Item eventKey="Product Companies">
                Product Companies
              </Dropdown.Item>
            )}
          </DropdownButton>

          {selected === "Latest" && (
            <div>
              <BlogMobile />
            </div>
          )}
          {selected === "Web Development" && (
            <div>
              <BlogMobileTopic topic="Web Development" />
            </div>
          )}
          {selected === "Data Science" && (
            <div>
              <BlogMobileTopic topic="Data Science" />
            </div>
          )}
          {selected === "Career/Placement" && (
            <div>
              <BlogMobileTopic topic="Career/Placement" />
            </div>
          )}
          {selected === "Product Companies" && (
            <div>
              <BlogMobileTopic topic="Product Companies" />
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Blog2;

import { useState, useEffect } from 'react';
import logo from "../../Assets/img/cantilever-logo.png";
import styles from "../../Assets/css/quizz.module.css";
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { updateQuizData } from "../../app/hybridExam/actions/action";
import { updateQuizProgress, getExamProgress, completeQuizExam } from './UtilsAPI';

interface IQuizExam {
  userDP: string;
  userName: string;
  userId: string;
  email: string;
  questionsArray: Array<IQuizQuestion>;
  unquestions_Attempted: Array<IQuizQuestion>;
  examData: {
    attempts: number;
    category: string | undefined;
    company: string | undefined;
    courseId: string;
    description: string;
    examName: string;
    questions: Array<IQuizQuestion>;
    timer: string;
    typeOfExam: string;
    _id: string;
  }
}

function QuizExam(props: IQuizExam) {
  const {
    userDP,
    userName,
    questionsArray,
    unquestions_Attempted,
    examData
  } = props;

  const [showpopup, setshowpopup] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [spinnerShow, setSpinnerShow] = useState<boolean>(false);
  const [currQues, setcurrQues] = useState(1);
  const [questions_Attempted, setQuestionsAttempted] = useState<Array<string>>([]);
  const [scoreArray, setScoreArray] = useState<Array<{
    questionId: string;
    score: number;
    correct: boolean;
    questionNo: number;
    option: string;
  }>>([]);
  const [index, setIndex] = useState(0);
  const [questions_review, setQuestionsReview] = useState<Array<string>>([]);
  const dispatch = useDispatch();
  const [quizProgress, setQuizProgress] = useState<Array<{
    questionId: string;
    selectedAnswer: string;
  }>>([]);

  const {
    hours,
    minutes,
    seconds
  } = useSelector(({ hybridCompiler }: IRootState) => hybridCompiler.examTimer)

  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    getExamProgress(id)
      .then(({ examProgress }: any) => {
        const score = examProgress.quizResult.score.map((item: any) => {
          delete item._id;
          return item;
        }
        );
        const review = examProgress.quizResult.review.map((item: any) => {
          delete item._id;
          return item;
        });
        const quizResult = {
          score: score,
          review: review,
          examId: examProgress.quizResult.examId,
          questions_Attempted: examProgress.quizResult.questions_Attempted,
          finalScore: examProgress.quizResult.finalScore
        }
        dispatch(updateQuizData(quizResult))
        const tempScore: Array<any> = [];
        examProgress.quizResult.score.forEach((val) => {
          // update the question number
          val.questionNo = questionsArray.findIndex((ques) => ques._id === val.questionId) + 1;
          tempScore.push(val);
        })
        setScoreArray(tempScore);
        if (examProgress.quizProgress) {
          let quiz_progress = (examProgress.quizProgress.map(val => {
            let temp_obj = { ...val };
            delete temp_obj._id
            return temp_obj;
          }));
          setQuizProgress(quiz_progress);;
        }
        setQuestionsAttempted(examProgress.quizResult.questions_Attempted);
      })
      .catch((err) => {
        console.log(err);
      });

  }, [examData._id])

  const convertToMinutes = (hours: number, minutes: number, seconds): number => {
    let total_minutes = hours * 60 + minutes + seconds / 60;
    return parseFloat(total_minutes.toFixed(2));
  }

  useEffect(() => {

    let finalScore = 0;
    let temp_score: Array<{
      questionId: string;
      score: number;
      correct?: boolean;
      questionNo: number;
      option: string;
    }> = [...scoreArray];

    temp_score.forEach(val => {
      finalScore = finalScore + val.score;
      delete val["correct"];
    })
    let quizExamData = {
      score: temp_score,
      review: questions_review,
      examId: examData._id,
      questions_Attempted,
      finalScore
    }
    if (quizProgress.length !== 0) {
      let time_rem = (convertToMinutes(hours, minutes, seconds))
      updateQuizProgress(quizProgress, quizExamData, id, time_rem);
    }
    dispatch(updateQuizData(quizExamData))

  }, [questions_Attempted, scoreArray, questions_review, dispatch, examData, id, quizProgress])

  function Check(questionNos, options) {
    // console.log(questionNos,options)
    for (var singleValue of scoreArray) {
      if (
        singleValue.questionNo === questionNos &&
        singleValue.option === options
      ) {
        // console.log("true")
        return true;
      }
    }

    return false;
  }

  /**
   * * marks key for custom marks
   */
  async function changeclass(e, correctAnswer: Array<string>, questionId: string, option: string, marks: number) {
    if (!questions_Attempted.includes(questionId)) {
      setQuestionsAttempted((prev) => [...prev, questionId]);
    }

    correctAnswer.forEach((item) => {
      if (item === option) {
        setScoreArray((prev) => {
          const newarray = prev.filter((value) => value.questionId !== questionId)

          // logic for created the score object
          return [
            ...newarray,
            {
              questionNo: currQues,
              score: 1 * marks,
              questionId: questionId,
              correct: true,
              option
            }
          ]
        });

        setQuizProgress((prev) => {
          const newarray = prev.filter((value) => value.questionId !== questionId)

          // logic for created the score object
          return [
            ...newarray,
            {
              questionId: questionId,
              selectedAnswer: option
            }
          ]
        });
      } else {
        setScoreArray((prev) => {
          const newarray = prev.filter((value) => value.questionId !== questionId)

          // logic for created the score object
          return [
            ...newarray,
            {
              score: 0,
              questionId: questionId,
              correct: false,
              option,
              questionNo: currQues,
            }
          ]
        });

        setQuizProgress((prev) => {
          const newarray = prev.filter((value) => value.questionId !== questionId)

          // logic for created the score object
          return [
            ...newarray,
            {
              questionId: questionId,
              selectedAnswer: option
            }
          ]
        });
      };
    });
  }
  const addToReview = (_id: string) => {
    console.log("questions_review", questions_review);

    if (questions_review.includes(_id)) {
      setQuestionsReview((prev) => {
        return prev.filter((val) => val !== _id)
      })

      return;
    } else {
      setQuestionsReview((prev) => [...prev, _id]);
    }
  };

  const checkRegex = (ans) => {
    var urlRegex =
      /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
    if (urlRegex.test(ans)) {
      return true;
    } else {
      return false;
    }
  };


  function submithandler() {
    /**
     * TODO: Record the data and dispatch the state of the tab
     */
  }

  return (
    <div id="main">
      {showpopup === true && (
        <div
          className={styles.popup_submission}
          style={{ backdropFilter: "blur(10px)" }}
        >
          <div className="d-flex flex-column">
            <span className={styles.popup_text}>
              are you sure that you want to submit your Exam?
            </span>
            <div className="d-flex flex-row justify-content-start">
              <button
                onClick={() => {
                  setshowpopup(false);
                }}
                className={styles.submission_quiz_button}
                style={{
                  background: "transparent",
                  border: "1px solid white",
                  color: "#fff",
                }}
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  let time_rem = (convertToMinutes(hours, minutes, seconds));
                  completeQuizExam(id, time_rem);
                  dispatch({ type: "hybridExam/coding-instructions" })
                }}
                className={styles.submission_quiz_button}
                disabled={disabled}
              >
                {spinnerShow ? (
                  <span className="spinner-border spinner-border-sm"></span>
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </div>
        </div>
      )}
      <div style={{ filter: showpopup ? "blur(10px)" : undefined }}>
        {showpopup === true && (
          <div
            className={styles.popup_submission}
            style={{ backdropFilter: "blur(10px)" }}
          >
            <div className="d-flex flex-column">
              <span className={styles.popup_text}>
                Are you sure, you want to submit your Exam?
              </span>
              <div className="d-flex flex-row justify-content-start">
                <button
                  onClick={() => {
                    setshowpopup(false);
                  }}
                  className={styles.submission_quiz_button}
                  style={{
                    background: "transparent",
                    border: "1px solid white",
                    color: "#fff",
                  }}
                >
                  No
                </button>
                <button
                  onClick={() => {
                    // submithandler();
                    dispatch({ type: "hybridExam/coding" });
                  }}
                  className={styles.submission_quiz_button}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        )}
        <div style={{ filter: showpopup ? "blur(10px)" : undefined }}>
          {localStorage.getItem("token") ? (
            <>
              <div className={styles.quizPageNavbar}>
                <img src={logo} className={styles.quizPageLogo} alt="logo" />
                {/* <button
                  className='btn btn-primary'
                  onClick={() => dispatch({ type: "hybridExam/coding" })}
                >
                  Coding Exam
                </button> */}
                <div className={styles.quizPageNavbarRight}>
                  <div className={styles.quizPageProfileDetail}>
                    <img src={userDP} className={styles.quizPageProfilePic} alt="profile-pic" />
                    <span> {userName}</span>
                  </div>
                  <TimerBar />
                </div>
              </div>
              <div className={styles.quizQuestionPage}>
                <div className="row gx-lg-5 gx-3">
                  <div className="col-lg-8 col-md-7 mb-0">
                    <div className={styles.quizQuestionPageLeft}>
                      <div className="row quizQuestionPageLeftContainer" style={{ flexGrow: 1, height: "100%", overflowY: "auto" }}>
                        <div className="row mb-0" style={{
                          flexDirection: "column",
                          flexGrow: 1,
                        }}>
                          <div className={styles.quizQuestionPageQuestionNum}>
                            <span>Question No. {currQues}</span>
                            <span style={{color: "red"}}>{questionsArray[index].marks} Mark</span>
                          </div>
                          <hr className="my-2" />
                          <div style={{ marginBottom: "30px", flexGrow: 1 }}>
                            <pre style={{ "whiteSpace": "pre-wrap" }} className={styles.quiztextContainer}>
                              {questionsArray[index] && questionsArray[index].question}
                            </pre>
                            {questionsArray[index] && checkRegex(questionsArray[index].questionPic) ? (
                              <img
                                src={questionsArray[index].questionPic}
                                alt="question"
                                className={`img-fluid ${styles.quizQuestionPageQuestionPic}`}
                              />
                            ) : null}
                          </div>
                          <div className="mt-auto quiz-questions-options">
                            {questionsArray[index] && questionsArray[index].answers.map((option, n_index) => {
                              return (
                                <>
                                  {questionsArray[index].answerSelectionType ===
                                    "single choice" && (
                                      <div
                                        key={n_index}
                                        className={
                                          Check(currQues, option)
                                            ? styles.quizPageOptionSelcted
                                            : styles.quizPageOption
                                        }
                                        onClick={(e) => {
                                          changeclass(
                                            e,
                                            questionsArray[index].correctAnswer,
                                            questionsArray[index]._id,
                                            option,
                                            questionsArray[index].marks
                                          );
                                        }}
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <input
                                          className={styles.quizPageOptionRadio}
                                          type="radio"
                                          value={option}
                                          name="options"
                                          id={n_index.toString()}
                                          checked={Check(currQues, option)}
                                        />
                                        <label
                                          htmlFor={n_index.toString()}
                                          style={{
                                            cursor: "pointer",
                                          }}
                                        >
                                          {checkRegex(option) ? (
                                            <img
                                              className="img-fluid"
                                              src={option}
                                              alt={option}
                                              style={{
                                                height: "100px",
                                              }}
                                            />
                                          ) : (
                                            option
                                          )}
                                        </label>
                                      </div>
                                    )}
                                </>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 d-sm-flex justify-content-between" style={{ marginBottom: 0 }}>
                          {questionsArray[index] && questions_Attempted?.includes(questionsArray[index]._id) ? (
                            <button
                              onClick={() => {
                                addToReview(questionsArray[index]._id);
                              }}
                              className={styles.quizPageRevBtn}
                            >
                              {
                                questions_review.includes(questionsArray[index]._id)
                                  ? <>Remove Review</>
                                  : <>Review later</>
                              }
                            </button>
                          ) : (
                            <button
                              className={styles.quizPageRevBtn}
                              disabled={true}
                            >
                              Review later
                            </button>
                          )}
                          <div className="rightside">
                            {index === 0 ? null : (
                              <button
                                onClick={(e) => {
                                  if (index === 0) {
                                  } else {
                                    setIndex(index - 1);
                                    setcurrQues((prev) => prev - 1);
                                    // setMultipleChoiceArray([]);
                                  }
                                }}
                                className={styles.quizPagePrevBtn}
                              >
                                <i className="fas fa-arrow-left"></i> Prev
                              </button>
                            )}
                            {index === questionsArray.length - 1 ? null : (
                              <button
                                onClick={(e) => {
                                  if (index === questionsArray.length - 1) {
                                  } else {
                                    setIndex(index + 1);
                                    setcurrQues(prev => prev + 1);
                                    // setMultipleChoiceArray([]);
                                  }
                                }}
                                className={styles.quizPageNextBtn}
                              >
                                Next <i className="fas fa-arrow-right"></i>
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-5 mb-0 mt-md-0 mt-sm-3 mt-0">
                    <div className={styles.quizQuestionPageRight}>
                      <div className="content">
                        <div className={styles.quizQuestionPageQuizName}>
                          {examData.examName}
                        </div>
                        <hr />
                        <ul className={styles.quizPageRightList}>
                          <li>
                            <span>
                              <span style={{ backgroundColor: "#E98D15" }}>
                                {unquestions_Attempted.length}
                              </span>
                            </span>
                            Total Questions
                          </li>
                          <li>
                            <span>
                              <span style={{ backgroundColor: "#24B47E" }}>
                                {questions_Attempted.length}
                              </span>
                            </span>
                            Answered Questions
                          </li>
                          <li>
                            <span>
                              <span style={{ backgroundColor: "#3E006E" }}>
                                {questions_review.length}
                              </span>
                            </span>
                            Questions to be Reviewed
                          </li>
                          <li>
                            <span>
                              <span>
                                {unquestions_Attempted.length -
                                  questions_Attempted.length}
                              </span>
                            </span>
                            Un-Answered Questions
                          </li>
                        </ul>
                        <div className={styles.quizQuestionPageQuizName}>
                          All Questions
                        </div>
                        <hr />
                        <div className="d-flex flex-wrap">
                          {questionsArray.map((item, pos) => {
                            return (
                              <>
                                <div
                                  style={{
                                    width: "30px",
                                    height: "30px",
                                    border: "1px solid #BFBFBF",
                                    padding: "3px 2px",
                                    margin: "4px 5px",
                                    borderRadius: "4px",
                                    cursor: "pointer",
                                    textAlign: "center",
                                  }}
                                  className={
                                    questions_review.includes(item._id)
                                      ? styles.reviewed
                                      : questions_Attempted.includes(item._id)
                                        ? styles.answered
                                        : questions_Attempted.includes(
                                          item._id
                                        ) === true &&
                                          questions_review.includes(item._id) ==
                                          true
                                          ? styles.both
                                          : "bg-#133d6a border border-light"
                                  }
                                  onClick={() => {
                                    setIndex(--pos);
                                    setcurrQues(++pos);
                                  }}
                                >
                                  {++pos}
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </div>
                      <div className="d-flex justify-content-end">
                        <button
                          className={styles.quizPageExamSubmitBtn}
                          onClick={() => {
                            setshowpopup(true);
                          }}
                        >
                          Submit Section 1
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            history.push("/")
          )}
        </div>
      </div>
    </div>
    // <>Hello World</>
  );
}

const TimerBar = () => {

  const {
    hours,
    minutes,
    seconds
  } = useSelector(({ hybridCompiler }: IRootState) => hybridCompiler.examTimer)

  return (
    <div className={styles.quizPageTimer}>
      {hours}:{minutes}:{seconds}
    </div>
  )
}

export default QuizExam
import React from "react";
import useMedia from "../componentsofcourse/useMedia";
import success_img from "../Assets/img/success_vector.svg";
import styles from "../Assets/css/signup.module.css";
import style from "../Assets/css/TCSform.module.css";
import PopupAd from "./PopupAd";
import Mobilepopup from "../componentsofcourse/Mobilepopup";
import { BASE_URL } from "../env";
import { useHistory } from "react-router-dom";
export default function ThanksPage({ chooseTCSForm, type }) {
  const matches = useMedia("(min-width : 768px )");
  const history = useHistory();

  function CloseTheButton() {
    history.push("/mycourse/6114d578f6cd9f0e9e8a811d");
  }
  function PushTheUser() {
    history.push("/mycourse/6114d578f6cd9f0e9e8a811d");
  }
  function InterestedUser() {
    fetch(BASE_URL + "/userInterested", {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: JSON.stringify({
        interestedCourse: true,
      }),
    })
      .then((response) => {
        // console.log(response);
        // signin("final");
        // PushTheUser();
      })
      .catch((err) => {
        // console.log(err);
      });
    PushTheUser();
  }
  return (
    <div style={{ overflowX: "hidden" }}>
      {matches ? (
        // <div
        //   className="container-fluid p-5 my-auto mx-auto my-auto"
        //   style={{ borderRadius: "15px" }}
        // >
        //   {type === "master" && <div className="d-flex justify-content-center">
        //     <PopupAd type="master" chooseTCSForm={chooseTCSForm} />
        //   </div>}
        //   <div className="container d-flex flex-column justify-content-center align-items-center">
        //     <img src={success_img} alt="success" style={{ width: "170px" }} />
        //     <h4 className="text-primary">Congratulations.</h4>
        //     <p
        //       style={{ color: "gray", width: "10%", color: "#111" }}
        //       className={styles.horizontalh + " my-3"}
        //     ></p>
        //     <p className="text-center f_w_600">
        //       <strong>
        //         Thank you for Registering with us.
        //         <br />
        //       </strong>
        //     </p>
        //     <a
        //       href="/"
        //       className={`${style.next_btn} btn btn-primary`}
        //       style={{ color: "white", textDecoration: "none" }}
        //     >
        //       Go Home
        //     </a>
        //   </div>
        // </div>
        <PopupAd
          CloseTheButton={CloseTheButton}
          PushTheUser={PushTheUser}
          InterestedUser={InterestedUser}
        />
      ) : (
   
        <Mobilepopup />
      )}
    </div>
  );
}

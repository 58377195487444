import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import styles from "../Assets/css/newcoursepage.module.css";
import useMedia from "../componentsofcourse/useMedia";
import style from "../Assets/css/iitbombay.module.css";
import ReactGA from "react-ga";
import IITBVideoComp from "../components/IITBVideocomp";
import Testimonials from "../components/TestimonialsVersion2";
import styles1 from "../Assets/css/cpcourse.module.css";
import firebase from "../firebase";
import { AuthContext } from "../Context/AuthContext";
import { LastSection } from "./NewMasterClass";
import { VerticallyCenteredModal } from "../components/IITBVideocomp";
import { useContext, useEffect, useState } from "react";
import { BASE_URL } from "../env";
import HelmetComp from "../components/HelmetComp";
import BannerComp from "../components/BannerComp";
import useGoogleOptimize from '@react-hook/google-optimize'

const IITBombay = (prop) => {
// 	 const queryParams = new URLSearchParams(window.location.search);
//   const club = queryParams.get("club");
	const [variationModel,setVariationModel] = useState(false);
	const [variation,setvariation] = useState();
	const variant_main = useGoogleOptimize('MMDBJTjLSPGFGfRbf2MUBQ', [false, true])
  	
	let variant;

	useEffect( async ()=>{
		
			if (window.dataLayer) {
			  await window.dataLayer.push({ event: "optimize.activate" });
			}
			// this.intervalId = setInterval(() => {
			  if (window.google_optimize !== undefined) {
				variant = window.google_optimize.get("MMDBJTjLSPGFGfRbf2MUBQ");
				console.log(variant)
				
			//   })
			// setVariationModel(variant)
			setvariation(variant)
			  }
			  console.log("main variant " + variant_main);
	},[])

	// console.log(var)
useEffect(()=>{
	console.log("variation model = ", variationModel)
	if(variation == 1){
		setVariationModel(true)
	}else{
		setVariationModel(false)
	}
},[variation])	

	useEffect(() => {
		
		// alert(typeof(scrollTheUser))
		if (scrollTheUser === "1") {
			// alert("hello")
			window.scrollTo(2250,2250)	
		}
		
	},[])
	  
	
	ReactGA.initialize("UA-198180460-1");
  const { email } = useContext(AuthContext);
  const matches = useMedia("(min-width : 768px )");
  const matches2 = useMedia("(min-width : 1200px )");

  // console.log("email from" + email);
  const analytics = firebase.analytics();
  const queryParams = new URLSearchParams(window.location.search);
  const refferal = queryParams.get("referral");
  const scrollTheUser = queryParams.get("scroll");
  const club = queryParams.get("club");

//   console.log(club)
  const [modalShow, setModalShow] = useState(false);
  const [allowed, setallowed] = useState(false);
  useEffect(() => {
    if (email !== "") {
      fetch(BASE_URL + "/getData/" + email, {
        method: "GET",
        headers: { "content-type": "application/json" },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.message == "Doesn't Exists!") {
            // console.log(data)
            setallowed(false);
          } else {
            // console.log(data)
            setallowed(true);
          }
        });

      return () => {};
    }
  }, [email]);

  return (
		<div style={{ overflow: "hidden" }}>
			{/* <Navbar /> */}
			<HelmetComp
				info={{
					title: "IIT Bombay",
					description: "Prepare at IIT Bombay",
					siteName: "cantilever labs",
					summary: "IIT Bombay summary",
					pageUrl: "https://www.cantileverlabs.com/iitbombay",
					imagUrl:
						"https://d8it4huxumps7.cloudfront.net/uploads/images/150x150/60c9c4b8da242_logo.jpg?d=110x110",
				}}
			/>
			{allowed == false && <>
			<VerticallyCenteredModal
					show={variationModel}
					hide={() => setVariationModel(false)}
				/> 
				</>}
			{/* className={` ${styles.bgimg}`} */}
			<div>
				<div className={style.headImg}>
					<div className={style.headImgText}>
						<h1
							className={style.headImgTextTop}
							style={{ marginBottom: "1vw" }}>
							Training @ {prop.name}
						</h1>
						{matches ? (
							<>
								<p
									className={style.headImgTextBottom}
									style={
										matches ? { fontSize: "24px", marginBottom: "1vw" } : {}
									}>
									Get yourself the same kind of training as an IITian gets from
									Cantilever Labs <br />
									and become Placement ready to land your dream job.
								</p>
								<p className='m-0' style={{fontFamily:'"Mulish-Bold", "sans-serif"'}}>
									<span
										className='py-2'
										style={{
											fontWeight: "800",
											fontSize: "28px",
											color: "#fff",
										}}>
										1300
									</span>{" "}
									Students Trained
								</p>
								<p className='m-0'  style={{fontFamily:'"Mulish-Bold", "sans-serif"'}}>
									<span
										className='py-2'
										style={{
											fontWeight: "800",
											fontSize: "28px",
											color: "#fff",
										}}>
										1.5 Cr
									</span>{" "}
									Highest Package
								</p>
								<p className='m-0 d-flex'  style={{fontFamily:'"Mulish-Bold", "sans-serif"'}}>
									<span
										className='py-1'
										style={{
											fontWeight: "800",
											fontSize: "28px",
											color: "#fff",
										}}>
										4.9
									</span>
									<div
										className='d-flex flex '
										style={{ padding: "10px 0 0 5px" }}>
										<img src="https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644061979803_star.png" alt='star' height='28px' />
										<img src="https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644061979803_star.png" alt='star' height='28px' />
										<img src="https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644061979803_star.png" alt='star' height='28px' />
										<img src="https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644061979803_star.png" alt='star' height='28px' />
										<img src="https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644061979803_star.png" alt='star' height='28px' />
									</div>
								</p>
								{allowed ? (
									<button
										className={style.headImgBtn + " mt-4"}
										style={{ background: "#3CC13B", cursor: "default", fontFamily:'"Mulish-Bold", "sans-serif"' }}>
										Enrolled
									</button>
								) : (
									<button
										className={style.headImgBtn + " mt-4"}
										onClick={() => {
											ReactGA.event({
												category: "IITBombay Page",
												// type: "Button",
												// name: "IIT Bombay Unlock Now Button",
												action: "IIT Bombay Unlock Now Button",
											});
											setModalShow(true);
										}}
										style={{ background: "#1877F2", cursor: "pointer", fontFamily:'"Mulish-Bold", "sans-serif"'}}>
										Enroll Now
									</button>
								)}
							</>
						) : (
							""
						)}
						{/* <button className={style.headImgBtn}>Enroll Now</button> */}
					</div>
					{/* <img src={shareBtn} className={style.shareBtn} /> */}
					{/* <img src={head} className={style.headImg} /> */}
				</div>
				<IITBVideoComp allowed={allowed} />
				<div className={` mx-0 d-flex flex-row ` + style.userResearchSection}>
					{matches ? (
						<div>
							<img
								src="https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644061804179_business.png"
								alt='Resume'
								style={{ transform: "translateY(-18%)" }}
								width='600px'
							/>
						</div>
					) : (
						""
					)}
					<div
						style={{
							background: "#1877F2",
							width: "100%",
							marginLeft: matches ? "-15%" : "",
						}}>
						<div
							style={{
								paddingLeft: matches2 ? "18rem" : matches ? "12rem" : "1rem",
								paddingTop: "3rem",
								paddingBottom: "3rem",
							}}>
							<h1
								style={{
									fontFamily: "Mulish",
									fontStyle: "normal",
									fontWeight: "bold",
									fontSize: "54px",
									lineHeight: "68px",
								}}>
								Resume Binder
							</h1>

							<div className=''>
								<p style={{ textAlign: "left", fontSize: "1rem" }}>
									This high quality toolkit contains the resume of
									<br />
									<span
										className='py-2'
										style={{
											fontFamily: "Mulish",
											fontStyle: "normal",
											fontWeight: "800",
											fontSize: "45px",
										}}>
										{" "}
										200+ IITians{" "}
									</span>
									<br />
									compiled to help you prepare the best one for yourself.{" "}
								</p>
							</div>
							{allowed ? (
								<a href='https://iitbombay.s3.ap-south-1.amazonaws.com/Resume+Binder.pdf' target="blank">
									<button
										onClick={() => {
											ReactGA.event({
												category: "IITBombay Page",
												type: "Download PDF",
												name: "Resume binder Toolkit",
												action: "Resume binder toolkit IIT Bombay",
											});
										}}
										className={style.userResearchSectionBtn}>
										<img src="https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644061372289_download.png" style={{ marginRight: "10%" }} />
										Download PDF
									</button>
								</a>
							) : (
								<button
									onClick={() => {
										ReactGA.event({
											category: "IITBombay Page",
											type: "Download PDF",
											name: "Resume binder Toolkit",
											action: "Resume binder toolkit IIT Bombay",
										});
									}}
									className={style.userResearchSectionBtn + " text-center"}
									// style={{backgroundColor:"white",color:"blue"}}
								>
									<img src="https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644061372289_download.png" style={{ marginRight: "10%" }} />
									Download PDF
								</button>
							)}
						</div>
					</div>
				</div>

				<div className={` mx-0 d-flex flex-row  ` + style.userResearchSection}>
					{/* <div className="col-lg-1 col-md-0"></div> */}

					<div
						style={{
							backgroundColor: "#1877F2",
							marginRight: matches2 ? "-15%" : "0",
							width: "100%",
						}}>
						<div
							style={{
								paddingLeft: matches2 ? "15rem" : matches ? "5rem" : "2rem",
								paddingTop: "3rem",
								paddingBottom: "3rem",
							}}>
							<h1
								className={styles.heading}
								style={{
									fontFamily: "Mulish",
									fontStyle: "normal",
									fontWeight: "bold",
									fontSize: "54px",
									lineHeight: "68px",
								}}>
								Placement Toolkit
							</h1>

							<div className=''>
								<p style={{ textAlign: "left", fontSize: "1rem" }}>
									Here is definitive step-by-step Placement <br />
									ToolKit which helped
									<br />
									<span
										className='py-2'
										style={{
											fontFamily: "Mulish",
											fontStyle: "normal",
											fontWeight: "800",
											fontSize: "45px",
										}}>
										5000+ Students
									</span>
									<br />
									to get placed at their dream company
								</p>
							</div>

							{allowed ? (
								<a href='https://iitbombay.s3.ap-south-1.amazonaws.com/Placement+Toolkit.pdf' target="blank">
									<button
										onClick={() => {
											ReactGA.event({
												category: "IITBombay Page",
												type: "Download PDF",
												name: "Placement Toolkit",
												action: " Placement toolkit IIT Bombay",
											});
										}}
										className={style.userResearchSectionBtn}>
										<img src="https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644061372289_download.png" style={{ marginRight: "10%" }} />
										Download PDF
									</button>
								</a>
							) : (
								<button
									onClick={() => setModalShow(true)}
									className={style.userResearchSectionBtn}>
									<img src="https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644061372289_download.png" style={{ marginRight: "10%" }} />
									Download PDF
								</button>
							)}
						</div>
					</div>
					{matches ? (
						<div className=''>
							<img
								src="	https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644061653480_job.png"
								alt='Placement'
								style={{ transform: "translateY(-18%)" }}
								width='600px'
							/>
						</div>
					) : (
						""
					)}
				</div>
			  <VerticallyCenteredModal
				  	club={club}
					show={modalShow}
					hide={() => setModalShow(false)}
				/>


				<BannerComp from="IIT Bombay" />



				{/*Maintestimonial*/}
				<h2 className='text-center mt-5' style={{ fontSize: "44px" }}>
					What Our Students Say
				</h2>
				<br />
				<br />
				<div style={{ background: "#1877F2" }}>
					<br />
					<br />
					<div className='row' style={{ marginLeft: matches ? "12%" : "6%" }}>
						<div className='col-md-4 col-xs-12 col-12 col-12 justify-content-center pb-2'>
							<img
								src="https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644055344268_Image%201.png"
								className='img-fluid'
								style={{ marginLeft: matches == false ? "3%" : null }}
							/>
						</div>
						<div
							style={{ textAlign: "left" }}
							className='col-md-6 col-xs-12 col-12 col-12'>
							<div>
								<h4 style={{ color: "white" }}>SARVESH MEHTANI</h4>
							</div>
							<div>
								<h5 style={{ color: "white" }}>
									IIT Bombay - Computer Science
								</h5>
							</div>
							<br />
							<div>
								<p style={{ color: "white" }}>
									Overall, I loved the fact that the whole process was tailored
									as per the individual's interests and goals which made it very
									effective in pointing out our areas of strength and the areas
									that we need to work on.
								</p>
							</div>

							<br />
							<br />
							<div>
								<button className={styles1.sarveshbtn}>
									A.I.R 1, JEE ADVANCED 2017
								</button>
							</div>
						</div>
					</div>
				</div>
				<div style={{ overflowX: "hidden" }}>
					<Testimonials />
				</div>

				<section
					className={style.lastSection}
			>
					<div className='container'>
						<div className='row'>
							<div className='col-12'>
								<h4 className={style.lastSectionHeading}>
									Explore more of our offerings
								</h4>
							</div>
						</div>
						<LastSection from = "IIT Bombay"/>
					</div>
				</section>
			</div>

			<Footer />
		</div>
	);
};
export default IITBombay;

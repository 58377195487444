import React from "react";
// import Media from "react-media";
//import HL1 from '../Imgsofcourse/images/Group 7401.png'
//import HL2 from '../Imgsofcourse/images/Group 7404.png'
//import HL3 from '../Imgsofcourse/images/Group 7406.png'
//import HL4 from '../Imgsofcourse/images/Group 7402.png'
//import HL5 from '../Imgsofcourse/images/Group 7405.png'
//import HL6 from '../Imgsofcourse/images/Group 7407.png'
// import IG from "../Imgsofcourse/images/Group 88.png";
// import IG1 from "../Imgsofcourse/images/Group 87.png";
// import IG2 from "../Imgsofcourse/images/Group 86.png";
// import IG3 from "../Imgsofcourse/images/Group 85.png";
// import YellowBlob from "../Assets/img/course/yellow_blob_1.png";
// import SM from "../Imgsofcourse/images/Image 1.png";
import "./Main.css";
// import Test from "./Test";

const Middle = () => {
    return (
        <div className="">
            <script src="https://unpkg.com/ionicons@5.4.0/dist/ionicons.js"></script>
            {/* <h1>Highlights</h1>
           <ul className="d-flex row justify-content-around no-bullets" style={{margin:"0px",padding:"0px",width:"100%"}}>

        <li className="p-3 mb-5 inner-list rounded col col-xl-3 col-lg-5 col-md-5 col-sm-8 col-10 mx-3">
            <div className="row">
                <div className="col-sm-6">
                    <img src={HL1} alt="" className="img-responsive" />
                </div>
                <div className="col-sm-6 picture-text">
                    <p>Learn at the comfort of your HOME</p>
                </div>
            </div>
        </li>
        <li className="p-3 mb-5 inner-list rounded col col-xl-3 col-lg-5 col-md-5 col-sm-8 col-10 mx-3">
            <div className="row">
                <div className="col-sm-6">
                    <img src={HL2} alt="" className="img-responsive" />
                </div>
                <div className="col-sm-6 picture-text">
                    <p>100% Guarantee Internship</p>
                </div>
            </div>
        </li>
        <li className="p-3 mb-5 inner-list rounded col col-xl-3 col-lg-5 col-md-5 col-sm-8 col-10 mx-3">
            <div className="row">
                <div className="col-sm-6">
                    <img src={HL3} alt="" className="img-responsive" />
                </div>
                <div className="col-sm-6 picture-text">
                    <p>Quick TA Support</p>
                </div>
            </div>
        </li>

    <li className="p-3 mb-5 inner-list rounded col col-xl-3 col-lg-5 col-md-5 col-sm-8 col-10 mx-3">
        <div className="row">
            <div className="col-sm-6">
                <img src={HL4} alt="" className="img-responsive" />
            </div>
            <div className="col-sm-6 picture-text">
                <p>Live online classNamees to boost productivity</p>
            </div>
        </div>
    </li>
        <li className="p-3 mb-5 inner-list rounded col col-xl-3 col-lg-5 col-md-5 col-sm-8 col-10 mx-3">
            <div className="row">
                <div className="col-sm-6">
                    <img src={HL5} alt="" className="img-responsive" />
                </div>
                <div className="col-sm-6 picture-text">
                    <p>Life Time access of Lectures and Resources</p>
                </div>
            </div>
        </li>
        <li className="p-3 mb-5 inner-list rounded col col-xl-3 col-lg-5 col-md-5 col-sm-8 col-10 mx-3">
            <div className="row">
                <div className="col-sm-6">
                    <img src={HL6} alt="" className="img-responsive" />
                </div>
                <div className="col-sm-6 picture-text">
                    <p>Placement assistance</p>
                </div>
            </div>
        </li>
</ul>
<br /><br />*/}
            {/* <div style={{ display: "flex" }}> */}



            {/*
      <div style={{ marginTop: "100px" }} className="intern-guarantee">
        <div className="row" style={{ display: "flex",flexDirection:"row" }}>
          <div className="col-md-3 intern-img-div">
            <img
              src={IG}
              alt=""
              className=" intern-img"
              style={{ height: "120%", marginBottom: "-30px" }}
            />
          </div>
          <div className="col-md-9 intern">
            <div className="intern-text">100% Internship Guarantee</div>
            <div>
              <button className="intern-btn text-white">
                <b>
                  {" "}
                  <a
                    className="anchorwhite"
                    href="https://forms.gle/E9qkNRh2FQLZzicZ7"
                    style={{ textDecorationLine: "none", color: "white" }}
                  >
                    {" "}
                    Enroll Now
                  </a>
                </b>
              </button>
            </div>
          </div>
        </div>

    </div>
</div>
<h1 style={{textAlign:"center"}}>Testimonials</h1>
    <h1 style={{textAlign:"center"}}><span  className="fa fa-arrow-circle-down"></span></h1>
    <br />
    <div style={{backgroundColor: '#00A2C8'}}>
    <br/>
    <br/>
    <div className="row">
        <div className="col-md-2 col-xs-12 col-12 col-12">

        </div>
        <div className="col-md-4 col-xs-12 col-12 col-12">
            <img src={SM} className="img-fluid" />
        </div>
        <div style={{textAlign:'left'}} className="col-md-6 col-xs-12 col-12 col-12">
                <div><h4 style={{color:"white"}}>SARVESH MEHTANI</h4></div>
                    <div><h5 style={{color:"white"}}>IIT Bombay - Computer Science</h5></div><br/>
                    <div><p style={{color:"white"}}>Overall, I loved the fact that the whole process was tailored as<br />
                    per the individual's interests and goals which made it very<br />
                    effective in pointing out our areas of strength and the areas that<br />
                    we need to work on.</p></div>

                    <div><button className="sarvesh-btn">A.I.R 1, JEE ADVANCED 2017</button></div>
                </div>

        </div>
      </div>
      {/*<h1 class="package-heading">Select your package</h1>
    <br /><br />
    <div class="container-fluid " style={{width: '95%', marginLeft: '2%'}}>
        <div class="row package">
            <div class="card col-md-4 col-lg-4">
                <div class="card-body impact">
                    <h2 class="card-title package-title"><b>Force</b></h2>
                    <br /><br />
                    <ul class="list-group list-group-flush" style={{textAlign: 'left'}}>
                      <li class="list-group-item border-bottom-0 p-1"><ion-icon name="chevron-forward-circle"></ion-icon><b>Web Dev with Node Js</b></li>
                      <li class="list-group-item border-bottom-0 p-1"><ion-icon name="chevron-forward-circle"></ion-icon><b>course usp</b></li>
                      <li class="list-group-item border-bottom-0 p-1"><ion-icon name="chevron-forward-circle"></ion-icon><b>Live Online Classes</b></li>
                      <li class="list-group-item border-bottom-0 p-1"><ion-icon name="chevron-forward-circle"></ion-icon><b>Interactive Support System</b></li>
                      <li class="list-group-item border-bottom-0 p-1"><ion-icon name="chevron-forward-circle"></ion-icon><b>Life time access of Lectures &amp; Resources</b></li>
                    </ul>
                    <br />
                   <button class="syllabus-btn" style={{marginLeft: '35%'}}>Syllabus</button>
                   <br />
                   <div class="row">
                       <div class="col-sm-3 duration" style={{ textAlign: 'left' }}>
                                        <h5>3</h5> Months
                       </div>
                       <div class=" col-sm-3 duration " style={{ textAlign: 'left' }}>
                            <h5>15</h5> Assignments
                       </div>
                       <div class=" col-sm-4 duration" style={{ textAlign: 'left' }}>
                            <h5>60+ </h5>Hours of content
                       </div>
                   </div>
                   <div class="row px-4">
                       <div class="xl-6 price col-sm-4"><b>9999/-</b></div>
                       <div class="xl-6 pay-now col-sm-8"><button class="pay-now-btn">Pay Now</button></div>
                   </div>
                  </div>
            </div>
<div class="vl" style={{width: '0%'}}></div>

            <div class=" card col-md-3 col-lg-4">
                <div class="card-body">
                    <h2 class="card-title package-title"><b>Surge</b></h2>
                    <br /><br />
                    <ul class="list-group list-group-flush" style={{textAlign: 'left'}}>
                      <li class="list-group-item border-bottom-0 p-1"><ion-icon name="chevron-forward-circle"></ion-icon><b>Force +</b></li>
                      <li class="list-group-item border-bottom-0 p-1"><ion-icon name="chevron-forward-circle"></ion-icon><b>React Js</b></li>
                    <li class="list-group-item border-bottom-0 p-1"><ion-icon name="chevron-forward-circle"></ion-icon><b>Live Online Classes</b></li>
                      <li class="list-group-item border-bottom-0 p-1"><ion-icon name="chevron-forward-circle"></ion-icon><b>30 Min Doubt Clarification</b></li>
                      <li class="list-group-item border-bottom-0 p-1"><ion-icon name="chevron-forward-circle"></ion-icon><b>Resume preparation</b></li>
                    </ul>
                    <br />
                    <button class="syllabus-btn">Syllabus</button>
                   <br />
                   <div class="row">
                       <div class="col-sm-3 duration" style={{ textAlign: 'left' }}>
                            <h5>4/5</h5> Months
                       </div>
                       <div class="col-sm-3 duration" style={{ textAlign: 'left' }}>
                            <h5>20</h5> Assignments
                       </div>
                       <div class="col-sm-4 duration" style={{ textAlign: 'left' }}>
                            <h5>120+</h5>Hours of content
                       </div>
                   </div>
                   <div class="row px-4">
                       <div class="price col-sm-4"><b>1499/-</b></div>
                       <div class="xl-6 pay-now col-sm-8"><button class="pay-now-btn">Pay Now</button></div>
                   </div>
                  </div>
            </div>


<div class="vl" style={{width: '0%'}}></div>
            <div class=" card col-md-3 col-lg-3">
                <div class="card-body">
                    <h2 class="card-title package-title"><b>Impact</b>
                    <button class="btn btn-primary">Most popular</button></h2>

                    <br /><br />
                    <ul class="list-group list-group-flush" style={{textAlign: 'left'}}>
                      <li class="list-group-item border-bottom-0 p-1"><ion-icon name="chevron-forward-circle"></ion-icon><b>Force ++</b></li>
                      <li class="list-group-item border-bottom-0 p-1"><ion-icon name="chevron-forward-circle"></ion-icon><b>React Js</b></li>
                      <li class="list-group-item border-bottom-0 p-1"><ion-icon name="chevron-forward-circle"></ion-icon><b>Guaranteed Internship</b></li>
                        <li class="list-group-item border-bottom-0 p-1"><ion-icon name="chevron-forward-circle"></ion-icon><b>24/7 support</b></li>
                        <li class="list-group-item border-bottom-0 p-1"><ion-icon name="chevron-forward-circle"></ion-icon><b>Placement Assistance</b></li>
                    </ul>
                    <br />
                    <button class="syllabus-btn">Syllabus</button>
                   <br />
                   <div class="row">
                       <div class="col-sm-3 duration" style={{textAlign: 'left'}}>
                            <h5>3</h5> Months
                       </div>
                       <div class="col-sm-3 duration" style={{textAlign: 'left'}}>
                            <h5>15</h5> Assignments
                       </div>
                       <div class="col-sm-4 duration" style={{textAlign: 'left'}}>
                            <h5>60+ </h5>Hours of content
                       </div>
                   </div>
                   <div class="row">
                       <div class="col-sm-5 price"><b>9999/-</b></div>
                       <div class="col-sm-7 pay-now"><button class="pay-now-btn">Pay Now</button></div>
                   </div>
                  </div>


*/}
        </div>
    );
};
export default Middle;

import React, {useEffect, useState} from "react";

// import IG from "../Imgsofcourse/images/Group 88.png";
import "../Assets/css/section.css";
import Group_88 from "../Assets/img/Home/Group_88.png";
import Group_7453 from "../Assets/img/Home/Group_7453.png";
import Group_7468 from "../Assets/img/Home/Group_7468.png";
import Group_7469 from "../Assets/img/Home/Group_7469.png";
import Group_7470 from "../Assets/img/Home/Group_7470.png";
import Group_7471 from "../Assets/img/Home/Group_7471.png";
import Group_7472 from "../Assets/img/Home/Group_7472.png";
import Group_7473 from "../Assets/img/Home/Group_7473.png";
import Group_7474 from "../Assets/img/Home/Group_7474.png";
import Group_7475 from "../Assets/img/Home/Group_7475.png";
import Group_7476 from "../Assets/img/Home/Group_7476.png";
import Group_7477 from "../Assets/img/Home/Group_7477.png";
import Student from "../componentsofcourse/Student";
import { Link, useLocation } from "react-router-dom";

const Internship = () => {
  const styles = {};
  const [login, setlogin] = useState(false);
  useEffect(() => {
    function CheckLoginOrNot() {
      if (localStorage.getItem("token") !== null) {
        // alert(localStorage.getItem("token"))
        return setlogin(true);
      } else {
        // return false;
        // alert(false);
        return setlogin(false);
      }
    }
    CheckLoginOrNot();
  }, []);

  const location = useLocation();

  return (
    <div>
      {/*  <!--100% INTERNSHIP GUARANTEED--> */}

      {/* <div className="intern-guarantee">
    <div className="row">
        <div className="col-md-3 intern-img-div">
            <img src={IG} alt="" className=" intern-img" style={{height:"120%",marginBottom:"-30px"}}/>
        </div>
        <div className="col-md-9 intern">
            <div className="intern-text">100% Internship Guarantee</div>
            <div><button className="intern-btn text-white"><b> <a className="anchorwhite" href="https://forms.gle/E9qkNRh2FQLZzicZ7" style={{textDecorationLine:"none", color: "white"}}> Enroll Now</a></b></button></div>
        </div>
    </div>
</div> */}
      {/*  */}
      <div
        //   className="container-fluid"
        style={{ backgroundColor: "#185ADB" }}
      >
        <div
          //   className="row"
          style={{
            margin: "auto",
            textAlign: "center",
            display: "flex",
            flexDirection: "row",
            paddingBottom: "4%",
            // flexWrap: "wrap",
          }}
        >
          <div className="col-md-1 col-xs-12 "></div>
          <div className="col-md-4 col-xs-12 ">
            <img
              src={'https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1644065107704_Group%2088.png'}
              className="img-fluid-mob"
              style={{
                marginTop: "-30px",
                marginBottom: "-30px",
                maxWidth: "80%",
                height: "auto",
              }}
            />
          </div>
          <div style={{ margin: "auto" }} className="col-md-5 col-xs-12">
            <br />
            <br />
            <h1 style={{ color: "white", fontWeight: "600" }}>
              Searching for a Job/Internship?
            </h1>
            <br />
            <Link
              to={login ? "/jobs-for-freshers" : "/register"}
              style={{
                fontSize: "20px",
                borderRadius: "25px",
                width: "40%",
                fontWeight: "bold",
                backgroundColor: "#fff",
                color: "#1c1c1c",
              }}
              className="btn"
              onClick={ () => { 
                localStorage.setItem('userlocation', location.pathname);
              }}
            >
              Explore Now
            </Link>
          </div>
          <div className="col-md-2 col-xs-12"></div>
        </div>
      </div>

      {/* <div
        style={{ display: "flex", flexDirection: "row", height: "300px" }}
        className="intern-guarantee"
      >
        <div style={{ width: "60%" }}>
          {" "}
          <img
            style={{
              position: "relative",
              width: "40vw",
              height: "125%",
              left: "15%",
              bottom: "25%",
            }}
            src={IG}
          />
        </div>
        <div
          style={{
            width: "50%",
            alignItems: "center",
            position: " relative",
            // position: "relative",
            // top: "15%",
          }}
        >
          <div>
            <div className="col-md-5 col-xs-12 col-12 col-12">
              <h1 style={{ color: "white", fontWeight: "bold" }}>
                100% Internship Guarantee
              </h1>
              <br />
            </div>
            <button
              style={{ position: "relative", left: "9%" }}
              className="intern-btn text-white"
            >
              <b>
                {" "}
                <a
                  className="anchorwhite"
                  href="https://forms.gle/E9qkNRh2FQLZzicZ7"
                  style={{ textDecorationLine: "none", color: "white" }}
                >
                  {" "}
                  Enroll Now
                </a>
              </b>
            </button>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Internship;

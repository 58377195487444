import React, { useState, useEffect } from "react";
import { BASE_URL } from "../env";
import { useParams } from "react-router-dom";
import HybridExamm from "../components/HybridExam/HybridExamm";
import HybridQuizQuestions from "../components/HybridExam/HybridQuizQuestions";
import "../Assets/css/hybridExam.css";
import { useTimer } from "react-timer-hook";
import moment from "moment";

const HybridExam = ({ expiryTimestamp, hidenavbar }) => {
  const { id } = useParams();
  const { seconds, minutes, hours, restart } = useTimer({
    // expiryTimestamp,
    // onExpire: () => {
    // submithandler();
    // },
  });
  const [codingScoreArr, setcodingScoreArr] = useState([]);
  const [quizScoreArr, setquizScoreArr] = useState([]);
  const [currCodingExam, setcurrCodingExam] = useState("");
  const [codingExams, setcodingExams] = useState("");
  const [currQuiz, setcurrQuiz] = useState("");
  const [Quizzes, setQuizzes] = useState("");
  const [show, setShow] = useState(0);
  const [HybridExamId, setHybridExamId] = useState("");

  useEffect(() => {
    hidenavbar(true);
    return () => {
      hidenavbar(false);
    };
  }, []);

  const startTimer = (timer) => {
    if (localStorage.getItem("timer" + HybridExamId) === null) {
      const time = new Date();
      time.setSeconds(time.getSeconds() + 60 * Number(timer));
      restart(time);
    } else {
      const time = new Date();
      var travelTime_new = moment().unix();
      const newtimer =
        localStorage.getItem("timer" + HybridExamId) - travelTime_new;
      const minutes_timer = newtimer / 60;
      // settimeSpent(Math.ceil(timer - minutes_timer));
      time.setSeconds(time.getSeconds() + 60 * minutes_timer);
      restart(time);
    }
  };
  const [showpopup, setshowpopup] = useState(false);
  const [totalTime, settotalTime] = useState("");
  const [currUserName, setcurrUserName] = useState("");
  const [currUserEmail, setcurrUserEmail] = useState("");
  useEffect(() => {
    fetch(BASE_URL + "/getProfile", {
      headers: { authorization: "Bearer " + localStorage.getItem("token") },
    })
      .then((res) => res.json())
      .then((data) => {
        setcurrUserName(data.user.firstName + " " + data.user.lastName);
        setcurrUserEmail(data.user.email);
      });
    fetch(BASE_URL + "/fetchingtheexam/" + id, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((data) => {
        startTimer(data.data.totalDuration);
        settotalTime(data.data.totalDuration);
        setcodingExams(data.data.codingExams);
        setQuizzes(data.data.quizExams);
        setHybridExamId(data._id);
      });
  }, []);
  const submitHybridExam = (e) => {
    e.preventDefault();
    // console.log({
    //   studentName: currUserName,
    //   studentEmail: currUserEmail,
    //   timeTaken: totalTime - (hours * 60 + minutes),
    //   HybridExamId: id,
    //   codingExamScore: codingScoreArr,
    //   quizExamScore: quizScoreArr,
    // });
    fetch(BASE_URL + "/hybridexamresult/createresult", {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        studentName: currUserName,
        studentEmail: currUserEmail,
        timeTaken: totalTime - (hours * 60 + minutes),
        hybridExamId: id,
        codingExamScore: codingScoreArr,
        quizExamScore: quizScoreArr,
      }),
    })
      .then((res) => res.json())
      // .then((data) => console.log(data))
      .catch((err) => console.log(err));
  };
  const ExamShowSection = () => {
    if (show === 0) {
      return (
        <>
          <div className="hybridExamCardSection">
            <div className="row">
              <div className="col-12 mb-0">
                <div className="hybridExamPageHeading">Coding Exam</div>
              </div>
              {codingExams
                ? codingExams?.map((val, index) => {
                    return (
                      <div className="col-md-4">
                        <div
                          key={index}
                          className="hybridExamCodingCards"
                          onClick={() => {
                            setcurrCodingExam(val);
                            setShow(1);
                          }}
                        >
                          {val?.name}
                        </div>
                      </div>
                    );
                  })
                : ""}
              <div className="col-12 mb-0">
                <div className="hybridExamPageHeading">Quiz</div>
              </div>
              {Quizzes
                ? Quizzes?.map((val, index) => {
                    return (
                      <div className="col-md-4">
                        <div
                          key={index}
                          className="hybridExamQuizCards"
                          onClick={() => {
                            setcurrQuiz(val);
                            setShow(2);
                          }}
                        >
                          {val?.examName}
                        </div>
                      </div>
                    );
                  })
                : ""}
            </div>
          </div>
        </>
      );
    } else if (show === 1) {
      return (
        <>
          <HybridExamm
            codingExams={currCodingExam}
            scoringArray={codingScoreArr}
            setscoringArray={setcodingScoreArr}
          />
        </>
      );
    } else {
      return (
        <>
          <HybridQuizQuestions
            quizExams={currQuiz}
            scoreArray={quizScoreArr}
            setScoreArray={setquizScoreArr}
          />
        </>
      );
    }
  };

  return (
    <div>
      {showpopup == true && (
        <div
          className="popup_submission"
          style={{ backdropFilter: "blur(10px)" }}
        >
          <div className="d-flex flex-column">
            <span className="popup_text">
              {show === 0
                ? "are you sure that you want to submit your Exam?"
                : show === 1
                ? "Please submit your code before leaving"
                : "Please submit your test before leaving"}
            </span>
            <div className="d-flex flex-row justify-content-start">
              <button
                onClick={() => {
                  setshowpopup(false);
                }}
                className="submission_quiz_button"
                style={{
                  background: "transparent",
                  border: "1px solid white",
                  color: "#fff",
                }}
              >
                Cancel
              </button>
              <button
                onClick={(e) => {
                  if (show === 0) {
                    submitHybridExam(e);
                  }
                  setshowpopup(false);
                  if (show != 0) {
                    setShow(0);
                  }
                }}
                className="submission_quiz_button"
              >
                {show === 0 ? "Submit" : "Already Done"}
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="hybridExamNavbar">
        <div className="hybridExamNavbarLeftSidebar">
          <i
            className="fas fa-arrow-left"
            style={{ display: show === 0 ? "none" : "" }}
            onClick={() => {
              setshowpopup(true);
            }}
          ></i>
        </div>
        <div className="hybridExamNavbarRightSidebar d-flex">
          {show === 0 && (
            <button
              className="btn btn-sm btn-primary mx-2"
              onClick={() => setshowpopup(true)}
            >
              Submit
            </button>
          )}
          <div className="hybridExamNavbarTimer">
            {hours}:{minutes}:{seconds}
          </div>
        </div>
      </div>
      {ExamShowSection()}
    </div>
  );
};

export default HybridExam;

import React from "react";
import "./Main.css";
import useMedia from "./useMedia";
import YellowBlob from "../Assets/img/course/yellow_blob_1.png";
import CourseMain from "../Assets/img/course/course_main.png";
import NodeImg from "../Assets/img/course/node_icon.png";
import MongoImg from "../Assets/img/course/mongo_icon.png";
import GithubImg from "../Assets/img/course/github_icon.png";
import AwsImg from "../Assets/img/course/aws_icon.png";
import ReactImg from "../Assets/img/course/react_icon.png";
import IconStar from "../Assets/img/course/icon_star.png";
import IconCalendar from "../Assets/img/course/icon_calender.png";
import IconUsers from "../Assets/img/course/icon_users.png";
import IconHands from "../Assets/img/course/icon_hands.png";
import firebase from "../firebase";
import ReactGA from "react-ga";
import Mixpanel from "mixpanel";
import { Link } from "react-scroll";
const Main = (props) => {
  var Mixpanel = require("mixpanel");
  const matches = useMedia("(min-width : 768px )");
  const analytics = firebase.analytics();
  ReactGA.initialize("UA-198180460-1");
  var mixpanel = Mixpanel.init("94843cc064761c99542d0dac6d2787cf");
  let header = props.header;
  //   let header = props.header.replace(/(?:\r\n|\r|\n)/g, "<br>");
  return (
    <div>
      {matches ? (
        <div
          style={{ textAlign: "left", marginTop: "10%" }}
          className="row container"
        >
          <div className="col-lg-7 col-xl-7 col-sm-12 col-md-6 col-12">
            <h1 style={{ fontWeight: "bold", textAlign: "left" }}>
              {/* Learn Web  <br /> Development */}
              {props.parameters.header}
            </h1>
            <br />
            <p style={{ fontSize: "1vw" }}>
              {/* Learn full stack HTML/CSS, MySQL,
              <br />
              PHP development and build an e-commerce website
              <br />
              from scratch! */}

              {props.parameters.matter}
            </p>

            {/* <div>
                            <button class="cp-btn">
                                <img
                                    style={{ width: "25px" }}
                                    src={props.parameters.icons.IconStar}
                                    alt=""
                                />{" "}
                                {props.parameters.iconText[0]}
                            </button>
                            <button class="cp-btn">
                                <img
                                    style={{ width: "25px" }}
                                    src={props.parameters.icons.IconUsers}
                                    alt=""
                                />{" "}
                                {props.parameters.iconText[1]}
                            </button>
                            <button class="cp-btn">
                                <img
                                    style={{ width: "25px" }}
                                    src={props.parameters.icons.IconCalendar}
                                    alt=""
                                />{" "}
                                {props.parameters.iconText[2]}
                            </button>
                            {props.parameters.icons.IconHands && (
                                <button class="cp-btn">
                                    <img
                                        style={{ width: "25px" }}
                                        src={props.parameters.icons.IconHands}
                                        alt=""
                                    />{" "}
                                    {props.parameters.iconText[3]}
                                </button>
                            )}
                        </div>
                            */}
            <br />
            <img
              style={{ height: "30px", marginRight: "20px" }}
              src={props.parameters.techImg[0]}
            ></img>
            <img
              style={{ height: "30px", marginRight: "20px" }}
              src={props.parameters.techImg[1]}
            ></img>
            <img
              style={{ height: "30px", marginRight: "20px" }}
              src={props.parameters.techImg[2]}
            ></img>
            <img
              style={{ height: "30px", marginRight: "20px" }}
              src={props.parameters.techImg[3]}
            ></img>
            <img
              style={{ height: "30px", marginRight: "20px" }}
              src={props.parameters.techImg[4]}
            ></img>
            {props.parameters.techImg[5] && (
              <img
                style={{ height: "30px", marginRight: "20px" }}
                src={props.parameters.techImg[5]}
              ></img>
            )}
            <div
              class="enroll-btn-div"
              style={{ marginTop: "6%" }}
              onClick={() => {
                analytics.logEvent("Enroll_Now_Placement", {
                  type: "button",
                  name: "Enroll Now button",
                  content: "Enroll Now button on Placement course page",
                });
                ReactGA.event({
                  category: "TCS_Enroll_Now_Placement",
                  action: "clicked on TCS_Enroll_Now_Placement",
                  type: "button",
                  name: "Enroll Now button",
                  action: "Enroll Now button on Placement course page",
                });
              }}
            >
              <Link
                style={{ cursor: "pointer" }}
                to="MOVEPRICING"
                className="enrollbtn"
              >
                <b>Enroll Now</b>
              </Link>
            </div>
          </div>

          <div className="col-lg-5 col-xl-5 col-sm-12 col-md-6 col-12">
            <img
              width="80%"
              style={{
                // margin: "auto",
                marginLeft: "30%",
                height: "80%",
                width: "60%",
              }}
              className="img-fluid"
              src={props.parameters.mainImage}
            />
          </div>
        </div>
      ) : (
        <div
          style={{ textAlign: "left", marginTop: "10%" }}
          className="row container"
        >
          {/*
                    <div className="col-lg-6 col-xl-6 col-sm-12 col-md-6 col-12">
                        <img width="80%" className="img-fluid" src={props.parameters.mainImage} />
                    </div>
                    */}
          <div className="col-lg-6 col-xl-6 col-sm-12 col-md-6 col-12">
            <h1 style={{ textAlign: "left", fontWeight: "bold" }}>
              {/* Learn Web <br /> Development */}
              {props.parameters.header}
            </h1>
            <br />
            <p>
              {/* Learn full stack HTML/CSS, MySQL,
              <br />
              PHP development and build an e-commerce website
              <br />
              from scratch! */}
              {props.parameters.matter}
            </p>
            {/*
                        <div>
                            <button class="cp-btn">
                                <img width="50%" src={props.parameters.icons.IconStar} alt="" />{" "}
                                <span style={{ fontSize: "13px" }}>{props.parameters.iconText[0]}</span>
                            </button>
                            <button class="cp-btn">
                                <img src={props.parameters.icons.IconUsers} alt="" />{" "}
                                <span style={{ fontSize: "13px" }}>{props.parameters.iconText[1]}</span>
                            </button>
                            <button class="cp-btn">
                                <img src={props.parameters.icons.IconCalendar} alt="" />{" "}
                                <span style={{ fontSize: "13px" }}>{props.parameters.iconText[2]}</span>
                            </button>
                            <br />
                            <br />
                            {props.parameters.iconText[3] && <button class="cp-btn">
                                <img src={props.parameters.icons.IconHands} alt="" />{" "}
                                <span style={{ fontSize: "13px" }}>
                                {props.parameters.iconText[3]}
                </span>{" "}
                            </button>}
                        </div>
            */}
            <br />
            <img
              style={{ height: "30px", margin: "20px" }}
              src={props.parameters.techImg[0]}
            ></img>
            <img
              style={{ height: "30px", margin: "20px" }}
              src={props.parameters.techImg[1]}
            ></img>
            <img
              style={{ height: "30px", margin: "20px" }}
              src={props.parameters.techImg[2]}
            ></img>
            <img
              style={{ height: "30px", margin: "20px" }}
              src={props.parameters.techImg[3]}
            ></img>
            <img
              style={{ height: "30px", margin: "20px" }}
              src={props.parameters.techImg[4]}
            ></img>

            <div
              class="enroll-btn-div mb-5"
              onClick={() => {
                analytics.logEvent("Enroll_Now_Placement", {
                  type: "button",
                  name: "Enroll Now button",
                  content: "Enroll Now button on Placement course page bottom",
                });

                ReactGA.event({
                  category: "TCS_Enroll_Now_Placement",
                  action: "clicked on TCS_Enroll_Now_Placement at bottom",
                  type: "button",
                  name: "Enroll Now button",
                  action: "Enroll Now button on Placement course page bottom",
                });
              }}
            >
              <Link
                to="MOVEPRICING"
                className="enrollbtn"
                style={{ color: "white" }}
              >
                <a style={{ color: "white" }}>
                  <b>Enroll Now</b>
                </a>
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default Main;

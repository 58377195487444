import React, { useState } from "react";
//import styles from '../Assets/css/personalinfo.module.css'
// import cantileverlabslogo from "../Assets/img/cantileverlabslogo.png";
import uparrow from "../Assets/img/uparrow.png";
import downarrow from "../Assets/img/downarrow.png";
import Navbar from "../components/Navbar";

import Personal from "./Personal.js";
import CollegeDetails from "./CollegeDetails";
import Others from "./Others";
import useMedia from "./useMedia";

import styles from "../Assets/css/mobilemaster.module.css";
import { useEffect } from "react";
import { Store } from "react-notifications-component";
// import { Redirect } from "react-router-dom";
import MasterclassSuccess from "./MasterclassSuccess";
import { BASE_URL } from "../env";
import ReactPixel from "react-facebook-pixel";
const Personalinformation = (props) => {
  const queryParams = new URLSearchParams(window.location.search);
  const refferal = queryParams.get("referral");
  const webinar_id = queryParams.get("webinar");
  const matches = useMedia("(min-width : 768px )");
  const [terms, setterms] = useState(true);
  const [collegeError, setCollegeError] = useState([]);
  const [firstName, setfirstName] = useState("");
  const [Email, setEmail] = useState("");
  const [PhoneNumber, setPhoneNumber] = useState("");
  const [currentCity, setCurrentCity] = useState("");
  const [Personalinfo, setpersonalinfo] = useState(true);
  const [collegeDetails, setCollegeDetails] = useState(false);
  const [otherss, setOthers] = useState(false);
  const [mainerror, setMainerror] = useState([[]]);
  const [mainerrorweb, setmainerrorWeb] = useState([[]]);
  //College Details
  const [graduation, setgraduation] = useState("Bachelor of Engg./tech");
  const [department, setdepartment] = useState("CSE");
  const [year, setyear] = useState("");
  const [semester, setSemester] = useState("");
  const [CollegeName, setCollegeName] = useState("");
  const [personalError, setpersonalError] = useState([]);
  const [otherError, setOtherError] = useState([]);
  const [City, setCity] = useState("");
  const [errors, setError] = useState([]);
  const [winningSuccess, setWinningSuccess] = useState(false);
  const [personalOn, setpersonalOn] = useState(true);
  const [collegeOn, setcollegeOn] = useState(false);
  const [othersOn, setothersOn] = useState(false);
  const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
  const options = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: false, // enable logs
  };


  const notifyPopup = () => {
    Store.addNotification({
      title: "Success!",
      message: "We got your response!!",
      type: "success",
      background: "pink",
      insert: "bottom",
      container: "bottom-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true,
      },
    });
  };

  function HandleTheSubmit(e) {
    e.preventDefault();
  }
  function Citys(value) {
    setCity(value);
  }
  function Graduation(value) {
    setgraduation(value);
  }
  function Department(value) {
    setdepartment(value);
  }
  function Year(value) {
    setyear(value);
  }
  function Semester(value) {
    setSemester(value);
  }
  function College(value) {
    setCollegeName(value);
  }
  function Graduation(value) {
    setgraduation(value);
  }

  //var error ="";
  //others component
  const [employecode, setemployecode] = useState("");
  const [radio, setradio] = useState("");
  const [question, setquestion] = useState("Test Series");

  const [yes, setyes] = useState(false);

  useEffect(()=>{
    // alert(question)
  },[question])
  const [checkboxvalue, setcheckboxvalue] = useState({
    ProductDevelopment: false,
    SoftwareDevelopment: false,
    DataScience: false,
    MachineLearningAndArtificialIntelligence: false,
    CyberSecurity: false,
    FullStackDevelopment: false,
    others: false,
  });
  const {
    ProductDevelopment,
    SoftwareDevelopment,
    DataScience,
    MachineLearningAndArtificialIntelligence,
    CyberSecurity,
    FullStackDevelopment,
    others,
  } = checkboxvalue;

  //final submit
  var interestArray = [];
  async function HandleSubmit(e) {
    interestArray = [];
    e.preventDefault();
    setError([]);
    for (const [key, value] of Object.entries(checkboxvalue)) {
      if (value === true) {
        interestArray.push(key);
      }
    }

    if (HandleValidation() === true) {
      for (const [key, value] of Object.entries(checkboxvalue)) {
        if (value === true) {
          interestArray.push(key);
        }
      }
    }
  }
  function setQuestion(value) {
    setquestion(value);
  }
  function Setemploye(value) {
    setemployecode(value);
  }
  function setRadio(value) {
    setradio(value);
  }
  useEffect(() => {
    if (winningSuccess === false) {
      if (mainerrorweb.length === 1) {
        for (const [key, value] of Object.entries(checkboxvalue)) {
          if (value === true) {
            interestArray.push(key);
          }
        }
        //
        if (firstName !== "") {
          // console.log("pixel");
          ReactPixel.init("337209321107283", advancedMatching, options);
          ReactPixel.trackCustom("TCSformFilled", {
            email: Email,
            webinarId: webinar_id,
            firstName: firstName,
            mobileNumber: PhoneNumber,
            currentCity: currentCity,
            yearOfStudy: year === "" ? "graduated" : year,
            semester: semester,
            graduationDegree: graduation,
            department: department,
            collegeName: CollegeName,
            areaOfInterest: interestArray,
            referalCode: employecode,
            questions: question,
          });

          fetch(BASE_URL + "/webinars/Addregistration", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              email: Email,
              webinarId: webinar_id,
              firstName: firstName,
              mobileNumber: PhoneNumber,
              currentCity: currentCity,
              yearOfStudy: year === "" ? "graduated" : year,
              semester: semester,
              graduationDegree: graduation,
              department: department,
              collegeName: CollegeName,
              areaOfInterest: interestArray,
              referalCode: employecode,
              questions: question,
            }),
          })
            .then((response) => response.json())
            .then((response) => {
              notifyPopup();
              SetSuccesState();
              // console.log(response)
              //   window.open("/", "_self");
            })
            .catch((err) => {});
        } else {
        }
      }
    }
  }, [mainerrorweb]);
  // This is for the desktop/webview
  function HandleValidation() {
    const weberror = "";
    setError([]);
    setmainerrorWeb([[]]);
    var count = 0;
    Object.values(checkboxvalue).forEach((val) => {
      if (val === false) {
        count += 1;
      }
    });
    var Obj = Object.keys(checkboxvalue).length;
    if (terms === false) {
      setmainerrorWeb((mainerrorweb) => [
        ...mainerrorweb,
        "please accept our terms and conditions",
      ]);
      setError((errors) => [
        ...errors,
        "please accept our terms and conditions",
      ]);
    }
    if (count === Obj) {
      setmainerrorWeb((mainerrorweb) => [
        ...mainerrorweb,
        "please select any one area of interest",
      ]);
      weberror = "some value";
      setError((errors) => [
        ...errors,
        "please select any one area of interest",
      ]);
    } else {
    }

    if (terms === false && count === Obj) {
      return false;
    }

    if (errors.length === 0) {
      return true;
    }
  }

  function PersonalToCollegeDetails() {
    setpersonalinfo(false);
    setCollegeDetails(true);
  }
  function collegeDetailsToOther() {
    setCollegeDetails(false);
    setOthers(true);
  }
  function othersToCollegeDetials() {
    setOthers(false);
    setCollegeDetails(true);
  }
  function CollegeDetailsToPersonal() {
    setCollegeDetails(false);
    setpersonalinfo(true);
  }
  function setfirstNames(value) {
    setfirstName(value);
  }
  function setEMails(values) {
    setEmail(values);
  }
  function setPhoneNumbers(value) {
    setPhoneNumber(value);
  }
  function setCurrentcitys(values) {
    setCurrentCity(values);
  }

  useEffect(() => {
    ReactPixel.init("337209321107283", advancedMatching, options);
    ReactPixel.trackCustom("TCSWebsiteFormcantilever");
  }, []);
  function PersonalErrorHandling() {
    setpersonalError([]);
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var Re = /(\+91)?(-)?\s*?(91)?\s*?(\d{3})-?\s*?(\d{3})-?\s*?(\d{4})/;

    if (Email !== "") {
      if (re.test(String(Email)) === false) {
        setpersonalError((personalError) => [
          ...personalError,
          ["Enter your email properly"],
        ]);
      }
    }
    if (Email === "") {
      setpersonalError((personalError) => [
        ...personalError,
        ["Please enter your email"],
      ]);
    }

    if (firstName === "") {
      setpersonalError((personalError) => [
        ...personalError,
        ["Please enter your first name"],
      ]);
    }
    if (PhoneNumber !== "") {
      if (Re.test(String(PhoneNumber)) == false || PhoneNumber.length > 10) {
        setpersonalError((personalError) => [
          ...personalError,
          ["Enter your correct phone Number"],
        ]);
      }
    }
    if (PhoneNumber === "") {
      setpersonalError((personalError) => [
        ...personalError,
        ["Enter your phone Number"],
      ]);
    }

    if (currentCity === "") {
      setpersonalError((personalError) => [
        ...personalError,
        ["Please Enter the city in the "],
      ]);
    }

    if (
      currentCity !== "" &&
      Email !== "" &&
      firstName !== "" &&
      PhoneNumber !== "" &&
      PhoneNumber.length <= 10
    ) {
      if (Re.test(String(PhoneNumber)) === true && PhoneNumber !== "") {
        //    seterror(...error,["Enter your first name properly"])
        //  error +="Enter your proper phone number/n";
        if (re.test(String(Email)) === true) {
          setpersonalError([]);
          return true;
        }
      }
    } else {
      //  alert("hello")
    }
  }
  function CollegeDetailss() {
    setCollegeError([[]]);
    if (graduation === "") {
      setCollegeError((collegeError) => [
        ...collegeError,
        ["please select the graduation"],
      ]);
      //
    }
    if (department === "") {
      setCollegeError((collegeError) => [
        ...collegeError,
        ["please select the department"],
      ]);
      //
    }
    if (yes === false) {
      if (year === "") {
        setCollegeError((collegeError) => [
          ...collegeError,
          ["please enter the year"],
        ]);
        //
      }
      if (year > 4) {
        setCollegeError((collegeError) => [
          ...collegeError,
          ["please enter a valid year"],
        ]);
        //
      }

      if (semester === "") {
        setCollegeError((collegeError) => [
          ...collegeError,
          ["please enter the semester"],
        ]);
        //
      }
    }
    if (terms === false) {
      setCollegeError((collegeError) => [
        ...collegeError,
        ["Please Accept to our terms and conditions"],
      ]);
    }
    if (CollegeName === "") {
      setCollegeError((collegeError) => [
        ...collegeError,
        ["please enter your college name"],
      ]);
    }
    if (City === "") {
      setCollegeError((collegeError) => [
        ...collegeError,
        ["please enter the city name"],
      ]);
    }
    if (
      (graduation !== "") &
        (department !== "") &
        (year !== "") &
        (semester !== "") &&
      CollegeName !== "" &&
      City !== "" &&
      terms === true
    ) {
      // ye edit karru just for reference
      setCollegeError([]);
      return true;
    }
    //
    if (collegeError === [[]]) {
      setCollegeError([]);
      return true;
    }
  }

  useEffect(() => {
    setemployecode(refferal);
  }, [refferal]);
  useEffect(() => {
    if (collegeError.length === 1) {
      setCollegeError([]);
    }
    if (
      firstName !== "" &&
      Email !== "" &&
      PhoneNumber !== "" &&
      currentCity !== ""
    ) {
      if (
        collegeError.length === 0 &&
        personalError.length === 0 &&
        mainerror.length === 0
      ) {
        for (const [key, value] of Object.entries(checkboxvalue)) {
          if (value === true) {
            interestArray.push(key);
          }
        }
        ReactPixel.init("337209321107283", advancedMatching, options);
        ReactPixel.trackCustom("TCSformFilled", {
          email: Email,
          webinarId: webinar_id,
          firstName: firstName,
          mobileNumber: PhoneNumber,
          currentCity: currentCity,
          yearOfStudy: year === "" ? "graduated" : year,
          semester: semester,
          graduationDegree: graduation,
          department: department,
          collegeName: CollegeName,
          areaOfInterest: interestArray,
          referalCode: employecode,
          questions: question,
        });
        // console.log("recieve hoo raha hain yaha tak ")
        fetch(BASE_URL + "/webinars/Addregistration", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: Email,
            webinarId: webinar_id,
            firstName: firstName,
            mobileNumber: PhoneNumber,
            currentCity: currentCity,
            yearOfStudy: year === "" ? "graduated" : year,
            semester: semester,
            graduationDegree: graduation,
            department: department,
            collegeName: CollegeName,
            areaOfInterest: interestArray,
            referalCode: employecode,
            questions: question,
          }),
        })
          .then((response) => response.json())
          .then((response) => {
            notifyPopup();
            SetSuccesState();
            // console.log(response);
            window.open("/", "_self");
          })
          .catch((err) => {});
      }
    }
  }, [mainerror, collegeError, personalError]);
  async function OthersValidate(e) {
    var count = 0;
    Object.values(checkboxvalue).forEach((val) => {
      if (val === false) {
        count += 1;
      }
    });
    var Obj = Object.keys(checkboxvalue).length;

    if (count === Obj) {
      setOtherError(["please select any one area of interest"]);
      setMainerror(["select any one option"]);
      return false;
    } else {
      setOtherError([]);
      setMainerror([]);
    }
    //cantileverla
    if (setOtherError.length === 0) {
      setOtherError([]);
      return true;
    } else {
    }
  }

  function SetSuccesState() {
    setOthers(false);
    setWinningSuccess(true);
  }
  function checkAllValues() {
    if (
      collegeError.length === 0 &&
      personalError.length === 1 &&
      mainerror.length === 0
    ) {
    }
  }
  async function HandleAllErrors(e) {
    e.preventDefault();
    for (const [key, value] of Object.entries(checkboxvalue)) {
      if (value == true) {
        interestArray.push(key);
      }
    }

    setMainerror([]);
    setOtherError([]);
    setCollegeError([]);
    setpersonalError([]);

    PersonalErrorHandling();
    CollegeDetailss();
    await OthersValidate();

    //No collegeerror = 0 No personalError = 1 No main error 0
    if (OthersValidate() === true) {
      if (
        collegeError.length === 0 &&
        personalError.length === 1 &&
        mainerror.length === 0
      ) {
        //  alert("there are no errors in this document")
        // alert("college errors "+collegeError.length);
        // alert("personal errors " +personalError.length);
        // alert("main error" +mainerror.length)
      } else {
      }
    }
    checkAllValues();
  }

  return (
    <>
      {/* <Navbar /> */}
      {matches === true ? (
        <>
          {Personalinfo === true && (
            <Personal
              values={{
                firstName: firstName,
                Email: Email,
                PhoneNumber: PhoneNumber,
                currentCity: currentCity,
                setfirstName: setfirstNames,
                setEmail: setEMails,
                setPhoneNumber: setPhoneNumbers,
                setCurrentCity: setCurrentcitys,
              }}
              Personal_to_College={PersonalToCollegeDetails}
              List={props.List}
              Heading={props.Heading}
              Date={props.Date}
              Time={props.Time}
              ByWhom={props.ByWhom}
              Who={props.Who}
            />
          )}
          {collegeDetails === true && (
            <CollegeDetails
              College_to_others={collegeDetailsToOther}
              College_to_personal={CollegeDetailsToPersonal}
              values={{
                graduation: graduation,
                department: department,
                year: year,
                semester: semester,
                CollegeName: CollegeName,
                City: City,
                HandleTheSubmit: HandleTheSubmit,
                setgraduation: Graduation,
                setCity: Citys,
                setdepartment: Department,
                setyear: Year,
                setSemester: Semester,
                setCollegeName: College,
              }}
              Date={props.Date}
              Time={props.Time}
              ByWhom={props.ByWhom}
              Who={props.Who}
              List={props.List}
              Heading={props.Heading}
            />
          )}
          {otherss === true && (
            <Others
              Others_to_College={othersToCollegeDetials}
              values={{
                employecode: employecode,
                radio: radio,
                question: question,
                HandleSubmit: HandleSubmit,
                setquestion: setQuestion,
                setradio: setRadio,
                setemployecode: Setemploye,
                errors: errors,
                checkboxvalue: checkboxvalue,
                setcheckboxvalue: setcheckboxvalue,
                terms: terms,
                setterms: setterms,
              }}
              Date={props.Date}
              Time={props.Time}
              ByWhom={props.ByWhom}
              Who={props.Who}
              List={props.List}
              Heading={props.Heading}
            />
          )}
          {winningSuccess === true && <MasterclassSuccess />}
          {/* <Personal/> */}
          {/* <CollegeDetails/> */}{" "}
        </>
      ) : (
        <>
          {" "}
          {winningSuccess === false ? (
            <div class="container-fluid styles.mainmobJ justify-content-center">
              <div class="row justify-content-center ">
                <div
                  class={
                    "col-sm-7 " +
                    styles.purpleformMJ +
                    " pb-5 px-5 " +
                    styles.form1MJ
                  }
                  style={{ paddingTop: "15%" }}
                >
                  <h5 style={{ fontWeight: "500" }}>{props.Heading}</h5>
                  <div>
                    <ol class="mb-0 p-0">
                      {/* 21st June, 2021  5:00PM - 6:00PM */}
                      {props.Date === undefined ? (
                        <>
                          {" "}
                          {props.List.map((item, i) => {
                            if (i === 2) {
                              console.info(i);
                            }
                            return (
                              <li
                                key={i}
                                style={{ fontSize: "0.8rem", display: "block" }}
                              >
                                &#10004; {item}{" "}
                              </li>
                            );
                          })}{" "}
                        </>
                      ) : (
                        <>
                          <hr style={{ border: "1px solid white" }} />
                          {props.Date} {props.Time}
                          <hr style={{ border: "1px solid white" }} />
                        </>
                      )}
                    </ol>
                  </div>
                  <div
                    className="container d-flex justify-content-center bg-light my-2  "
                    style={{ borderRadius: "12px" }}
                  >
                    <span className="p-1" style={{ color: "blue" }}>
                      243 Students Registered
                    </span>
                  </div>
                </div>
                <form onSubmit={HandleAllErrors}>
                  <div class={"col-12 my-3 " + styles.secondpartformMJ}>
                    <div
                      className={"shadow container " + styles.rounded}
                      style={{ paddingTop: "3%" }}
                    >
                      <div
                        onClick={() => setpersonalOn(!personalOn)}
                        class={
                          " d-flex justify-content-between " + styles.heightanim
                        }
                        style={{ border: "none", height: "12px" }}
                      >
                        <span className={styles.newboldmobile}>
                          Personal information
                        </span>
                        <img
                          onClick={() => setpersonalOn(!personalOn)}
                          src={personalOn ? downarrow : uparrow}
                          className="mx-3"
                          alt=""
                          height="24px"
                          width="24px"
                        />
                      </div>
                      <div
                        style={{
                          maxHeight: personalOn === false && "0px",
                          overflow: personalOn === false && "hidden",
                          opacity: personalOn === false && "0",
                        }}
                        class={
                          styles.formcenterMJ +
                          " mt-4 " +
                          styles.animinout +
                          " "
                        }
                      >
                        <form class={styles.whiteformMJ + " pt-2"}>
                          <div>
                            <span class={styles.boldformTextMJ}>
                              First Name
                            </span>
                            <br />
                            <input
                              value={firstName}
                              onChange={(e) => {
                                setfirstName(e.target.value);
                              }}
                              class={styles.formcontrol1}
                              type="text"
                              name="name"
                              placeholder="John"
                              style={{ width: "90%" }}
                            />
                          </div>
                          <br />
                          <div class="mt-1">
                            <span class={styles.boldformTextMJ}>Email</span>
                            <br />
                            <input
                              value={Email}
                              onChange={(e) => {
                                setEmail(e.target.value);
                              }}
                              class={styles.formcontrol1}
                              type="email"
                              name="email"
                              placeholder="xyz@gmail.com"
                              style={{ width: "90%" }}
                            />
                          </div>
                          <br />
                          <div class="mt-1">
                            <span class={styles.boldformTextMJ}>
                              Phone Number
                            </span>
                            <br />
                            <input
                              value={PhoneNumber}
                              onChange={(e) => setPhoneNumber(e.target.value)}
                              class={
                                styles.formcontrol1 + " " + styles.numInput
                              }
                              type="number"
                              name="Phone"
                              placeholder="Do not put country code"
                              style={{ width: "90%" }}
                            />
                          </div>
                          <br />
                          <div class="mt-1">
                            <span class={styles.boldformTextMJ}>
                              Current City
                            </span>
                            <br />
                            <input
                              value={currentCity}
                              onChange={(e) => {
                                setCurrentCity(e.target.value);
                              }}
                              class={styles.formcontrol1}
                              type="text"
                              name="city"
                              placeholder="Enter your city name"
                              style={{ width: "90%" }}
                            />
                            <div className="d-flex justify-content-end px-4 py-3">
                              <button
                                onClick={(e) => {
                                  e.preventDefault();
                                  setpersonalOn(false);
                                }}
                                className=" btn btn-primary "
                                style={{
                                  borderRadius: "5px",
                                  fontSize: "0.9em",
                                  fontWeight: "light",
                                  backgroundColor: "#185adb",
                                  letterSpacing: "1px",
                                  padding: "0.4em 1.3em",
                                }}
                              >
                                Done
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                    <div
                      className={"shadow container mt-4 " + styles.rounded}
                      style={{ paddingTop: "3%" }}
                    >
                      <div
                        onClick={() => setcollegeOn(!collegeOn)}
                        class={
                          " d-flex justify-content-between " + styles.heightanim
                        }
                        style={{ border: "none", height: "12px" }}
                      >
                        <span className={styles.newboldmobile}>
                          College Details
                        </span>
                        <img
                          onClick={() => setcollegeOn(!collegeOn)}
                          src={collegeOn ? downarrow : uparrow}
                          className="mx-3"
                          alt=""
                          height="24px"
                          width="24px"
                        />
                      </div>
                      <div
                        style={{
                          maxHeight: collegeOn === false && "0px",
                          overflow: collegeOn === false && "hidden",
                          opacity: collegeOn === false && "0",
                        }}
                        class={
                          styles.formcenterMJ +
                          " mt-4 " +
                          styles.animinout +
                          " "
                        }
                      >
                        <div class="pt-2">
                          <span class={styles.boldformTextMJ}>
                            Graduation Degree
                          </span>
                          <br />

                          <div class="mt-2" data-role="fieldcontain">
                            <select
                              style={{
                                width: "58%",
                                marginLeft: "2px",
                                marginTop: "2px",
                              }}
                              onChange={(e) => {
                                setgraduation(e.target.value);
                              }}
                            >
                              <option value="Bachelor of Engg./tech">
                                Bachelor of Engg./tech
                              </option>
                              <option value="Bachelor of Commerce">
                                Bachelor of Commerce
                              </option>
                              <option value="PGDM/MBA">PGDM/MBA</option>
                              <option value="BBA">BBA</option>
                              <option value="Others">Others</option>
                            </select>
                          </div>
                          <br />

                          <div class="mt-1">
                            <span class={styles.boldformTextMJ}>
                              Department
                            </span>
                            <br />

                            <div class="mt-2">
                              <select
                                style={{
                                  width: "60%",
                                  marginLeft: "2px",
                                  marginTop: "2px",
                                }}
                                id="dropdown"
                                onChange={(e) => {
                                  setdepartment(e.target.value);
                                }}
                              >
                                <option value="CSE">CSE</option>
                                <option value="ECE">ECE</option>
                                <option value="EEE">EEE</option>
                                <option value="IT">IT</option>
                                <option value="MECH">MECH</option>
                                <option value="CIVIL">CIVIL</option>
                                <option value="OTHERS">OTHERS</option>
                              </select>
                              <br />
                            </div>
                          </div>
                          <br />

                          <div class="mt-1">
                            <span class={styles.boldformTextMJ}>Graduated</span>
                            <div className="row">
                              <div className="col">
                                <br />
                                <input
                                  style={{ marginRight: "5px" }}
                                  checked={yes === true}
                                  type="radio"
                                  name="yes"
                                  id="yes"
                                  onChange={() => setyes(!yes)}
                                />
                                <label
                                  style={{ marginLeft: "4%" }}
                                  className="ml-2"
                                  for="yes"
                                >
                                  yes
                                </label>
                              </div>
                              <div className="col">
                                <br />
                                <input
                                  style={{ marginRight: "5px" }}
                                  type="radio"
                                  name="bo"
                                  id="no"
                                  checked={yes === false}
                                  onChange={() => setyes(!yes)}
                                />
                                <label
                                  style={{ marginLeft: "4%" }}
                                  className="ml-2"
                                  for="no"
                                >
                                  no
                                </label>
                              </div>
                            </div>
                          </div>

                          <br />
                          {yes === false && (
                            <div class="">
                              <span class={styles.boldformTextJ}>
                                Year of study &#38; Semester:{" "}
                              </span>
                              <br />
                              <br />
                              <div className="row">
                                <div
                                  className="col-lg-3"
                                  style={{
                                    float: "left",
                                    marginLeft: "0px",
                                    paddingLeft: "0px",
                                  }}
                                >
                                  <input
                                    value={year}
                                    onChange={(e) => setyear(e.target.value)}
                                    style={{ float: "left", padding: "3px" }}
                                    className="card shadow pr-1 pl-1"
                                    style={{ width: "40%" }}
                                    type="number"
                                    name=""
                                    id=""
                                  />
                                </div>
                                <div
                                  className="col-lg-3"
                                  style={{
                                    float: "left",
                                    marginLeft: "0px",
                                    paddingLeft: "0px",
                                  }}
                                >
                                  <input
                                    value={semester}
                                    onChange={(e) =>
                                      setSemester(e.target.value)
                                    }
                                    className="card shadow pr-1 pl-1"
                                    style={{ width: "40%", padding: "2px" }}
                                    type="number"
                                    name=""
                                    id=""
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>

                        <div class={"mt-2 " + styles.whiteformMJ}>
                          <span class={styles.boldformTextJ}>College Name</span>

                          <br />

                          <input
                            className={styles.formcontrol1}
                            value={CollegeName}
                            onChange={(e) => setCollegeName(e.target.value)}
                            style={{
                              width: "80%",
                              marginLeft: "1px",
                              marginTop: "2%",
                              marginBottom: "3%",
                            }}
                            type="text"
                            name="city"
                            placeholder="Enter your college name"
                          />

                          <br />
                          <span class={styles.boldformTextJ}>Current City</span>
                          <br />
                          <input
                            className={styles.formcontrol1}
                            value={City}
                            onChange={(e) => setCity(e.target.value)}
                            style={{
                              width: "80%",
                              marginTop: "2%",
                            }}
                            type="text"
                            name="city"
                            placeholder="Enter your college city"
                          />
                        </div>
                        <div className="d-flex justify-content-end px-3 py-3">
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              setcollegeOn(false);
                            }}
                            className=" btn btn-primary "
                            style={{
                              borderRadius: "5px",
                              fontSize: "0.9em",
                              fontWeight: "light",
                              backgroundColor: "#185adb",
                              letterSpacing: "1px",
                              padding: "0.4em 1.3em",
                            }}
                          >
                            Done
                          </button>
                        </div>
                      </div>
                    </div>

                    <div
                      className={"shadow container mt-4 " + styles.rounded}
                      style={{ paddingTop: "3%" }}
                    >
                      <div
                        onClick={() => setothersOn(!othersOn)}
                        class={
                          " d-flex justify-content-between " + styles.heightanim
                        }
                        style={{ border: "none", height: "12px" }}
                      >
                        <span className={styles.newboldmobile}>Others</span>
                        <img
                          onClick={() => setothersOn(!othersOn)}
                          src={othersOn ? downarrow : uparrow}
                          className="mx-3"
                          alt=""
                          height="24px"
                          width="24px"
                        />
                      </div>
                      <div
                        style={{
                          maxHeight: othersOn === false && "0px",
                          overflow: othersOn === false && "hidden",
                          opacity: othersOn === false && "0",
                        }}
                        class={
                          styles.formcenterMJ +
                          " mt-4 " +
                          styles.animinout +
                          " "
                        }
                      >
                        <div
                          class={styles.whiteformMJ}
                          onSubmit={OthersValidate}
                        >
                          <div class=" pt-2">
                            <span class={styles.boldformTextMJ}>
                              Area Of Interest
                            </span>
                            <br />
                            <div class="row d-flex mt-2">
                              <div class=" ml-3 mb-2">
                                <input
                                  style={{ marginRight: "5px" }}
                                  type="checkbox"
                                  name="radio-1"
                                  id="radio-1"
                                  value="Product Development"
                                  checked={ProductDevelopment == true}
                                  onChange={function (e) {
                                    setcheckboxvalue({
                                      ...checkboxvalue,
                                      ProductDevelopment: !ProductDevelopment,
                                    });
                                  }}
                                />
                                <label class={styles.radiotextJ} for="radio-1">
                                  Product Development
                                </label>
                              </div>
                              <div class=" ml-3 mb-2 ">
                                <input
                                  style={{ marginRight: "5px" }}
                                  type="checkbox"
                                  name="radio-1"
                                  id="radio-2"
                                  value="Software Development"
                                  checked={
                                    checkboxvalue.SoftwareDevelopment == true
                                  }
                                  onChange={function (e) {
                                    setcheckboxvalue({
                                      ...checkboxvalue,
                                      SoftwareDevelopment: !SoftwareDevelopment,
                                    });
                                  }}
                                />
                                <label class={styles.radiotextJ} for="radio-2">
                                  Software developmet
                                </label>
                              </div>
                              <div class=" ml-3 mb-2 ">
                                <input
                                  style={{ marginRight: "5px" }}
                                  type="checkbox"
                                  name="radio-1"
                                  id="radio-3"
                                  value="Data science"
                                  checked={checkboxvalue.DataScience == true}
                                  onChange={function (e) {
                                    setcheckboxvalue({
                                      ...checkboxvalue,
                                      DataScience: !DataScience,
                                    });
                                  }}
                                />
                                <label class={styles.radiotextJ} for="radio-3">
                                  Datascience
                                </label>
                              </div>
                              <div class=" ml-3 mb-2 ">
                                <input
                                  style={{ marginRight: "5px" }}
                                  type="checkbox"
                                  name="radio-1"
                                  id="radio-4"
                                  value="Machine learning and Artificial Intelligence"
                                  checked={
                                    checkboxvalue.MachineLearningAndArtificialIntelligence ==
                                    true
                                  }
                                  onChange={function (e) {
                                    setcheckboxvalue({
                                      ...checkboxvalue,
                                      MachineLearningAndArtificialIntelligence: !MachineLearningAndArtificialIntelligence,
                                    });
                                  }}
                                />
                                <label class={styles.radiotextJ} for="radio-4">
                                  M.L and A.I{" "}
                                </label>
                              </div>
                              <div class=" ml-3 mb-2 ">
                                <input
                                  style={{ marginRight: "5px" }}
                                  type="checkbox"
                                  name="radio-1"
                                  id="radio-5"
                                  value="Cyber Security"
                                  checked={checkboxvalue.CyberSecurity == true}
                                  onChange={function (e) {
                                    setcheckboxvalue({
                                      ...checkboxvalue,
                                      CyberSecurity: !CyberSecurity,
                                    });
                                  }}
                                />
                                <label class={styles.radiotextJ} for="radio-5">
                                  Cyber Security{" "}
                                </label>
                              </div>
                              <div class=" ml-3 mb-2 ">
                                <input
                                  style={{ marginRight: "5px" }}
                                  type="checkbox"
                                  name="radio-1"
                                  id="radio-6"
                                  checked={
                                    checkboxvalue.FullStackDevelopment == true
                                  }
                                  onChange={function (e) {
                                    setcheckboxvalue({
                                      ...checkboxvalue,
                                      FullStackDevelopment: !FullStackDevelopment,
                                    });
                                  }}
                                />
                                <label class={styles.radiotextJ} for="radio-6">
                                  Full Stack Development
                                </label>
                              </div>
                              <div class=" ml-3 mb-2 ">
                                <input
                                  style={{ marginRight: "5px" }}
                                  type="checkbox"
                                  style={{ marginRight: "5px" }}
                                  name="radio-1"
                                  id="radio-7"
                                  checked={checkboxvalue.others == true}
                                  id="radio-7"
                                  value="Others"
                                  onChange={function (e) {
                                    setcheckboxvalue({
                                      ...checkboxvalue,
                                      others: !others,
                                    });
                                  }}
                                />
                                <label class={styles.radiotextJ} for="radio-7">
                                  Others{" "}
                                </label>
                              </div>
                            </div>
                            <br />
                            <div>
                              <span class={styles.boldformTextMJ}>
                                {" "}
                               What are you Primarily looking for ?
                              </span>
                              <br />

                              <select value={question} onChange={(e)=>{setQuestion(e.target.value)
                              
                              
                              }}>

                                <option value="Test Series">Test Series</option>
                                <option value="Problem-solving sessions">Problem-solving sessions</option>
                                <option value="Free Toolkits">Free Toolkits</option>
                                <option value="Resume Building session ">Resume Building session</option>
                                <option value="Soft skills-building session">Soft skills-building session</option>
                              </select>
                              <div className="d-flex justify-content-end px-4 py-3">
                                <button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setothersOn(false);
                                  }}
                                  className=" btn btn-primary "
                                  style={{
                                    borderRadius: "5px",
                                    fontSize: "0.9em",
                                    fontWeight: "light",
                                    backgroundColor: "#185adb",
                                    letterSpacing: "1px",
                                    padding: "0.4em 1.3em",
                                  }}
                                >
                                  Done
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                <div class="my-1 px-3">
                  {mainerror.length > 0 && (
                    <>
                      {" "}
                      {mainerror.map((main, index) => {
                        return (
                          <>
                            {" "}
                            <p>{main}</p>{" "}
                          </>
                        );
                      })}{" "}
                    </>
                  )}
                  {collegeError.length > 0 && (
                    <>
                      {collegeError.map((value, index) => {
                        return (
                          <>
                            <p>{value}</p>
                          </>
                        );
                      })}
                    </>
                  )}
                  {personalError.length > 0 && (
                    <>
                      {personalError.map((value, index) => {
                        return (
                          <>
                            <p>{value}</p>
                          </>
                        );
                      })}{" "}
                    </>
                  )}
                </div>
                <div
                  style={{ float: "left" }}
                  class={styles.leftformtextMJ + " my-2 px-4"}
                >
                  <p>
                    {" "}
                    <input
                      type="checkbox"
                      id="term"
                      checked={terms === true}
                      onChange={(e) => {
                        setterms(!terms);
                      }}
                    />{" "}
                    You agree to our
                    <a href="/policies/#terms" target="__blank">
                      <span class={styles.purpleboldMJ}>
                        {" "}
                        Terms & Conditions
                      </span>
                    </a>{" "}
                  </p>

                  <div class="d-flex mt-2">
                    {/* <button class={"btn " + styles.buttonformMJ + " p-2 mx-2"}>BACK</button> */}
                    <button
                      class={"btn " + styles.buttonformMJ + " p-2"}
                      onClick={HandleAllErrors}
                    >
                      SUBMIT
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <MasterclassSuccess />
          )}{" "}
        </>
      )}
    </>
  );
};

export default Personalinformation;

import React from 'react'
import styles from '../Assets/css/personalinfo.module.css';
import Navbar from '../components/Navbar.js'
import Vector5 from '../Assets/img/vector5.png'
import youtubeMasterClass from '../Assets/img/YoutubeMasterClass.png';
import linkedinMasterClass from '../Assets/img/LinkedinMasterClass.png';
import facebookMasterClass from '../Assets/img/FacebookMasterclass.png';
import instaMasterClass from '../Assets/img/InstaMasterClass.png';

import cantileverlabslogo from '../Assets/img/cantileverlabslogo.png';
import { useState } from 'react';
const Others = (props) => {

    const {employecode,
        radio,
        question,
        HandleSubmit,
        setquestion,
        setradio,
        setemployecode,errors,checkboxvalue,setcheckboxvalue,terms,setterms} = props.values

    const {
         ProductDevelopment,
        SoftwareDevelopment,
        DataScience,
        MachineLearningAndArtificialIntelligence,
        CyberSecurity,
        FullStackDevelopment,
        others,
    } = checkboxvalue
    return (

        <div  class="container-fluid justify-content-center ">
            <div class="container-fluid justify-content-center ">
        <div>
            <div class={styles.formhdJ + " d-flex justify-content-center my-5"} style={{paddingTop:"5%"}}>
                <img class="mr-2" src={Vector5} alt=""/>
                <p style={{marginLeft: "3%"}}> {props.Heading}</p>
            </div>

            <div class="container-fluid justify-content-center">
                <div class="row justify-content-center ">
                    <div class={"col-xl-3 col-lg-3 col-md-5 col-sm-5 purple-form-J py-5 px-5 " +  styles.form1J + " " + styles.purpleformJ}>
                    
                    <div className="row">
                        <p style={{fontSize:"1.2rem"}} className={styles.plaintextJ +  " p-0"}>{props.Heading}</p>
                    </div>
                    <div className="row">
                    <div class="mb-0 p-0">
												{/* 21st June, 2021  5:00PM - 6:00PM */}
                                           {  props.Date===undefined ? <> {props.List.map((item,i)=>{
             										 return(
												<li key={i} style={{fontSize:"0.8rem",display: "block"}}>	&#10004; {item}</li>
													  )})} </>: <> 
                                           <span>  {props.Date} </span> <br />
                                           <span>  {props.Time} </span>
                                        <hr  style={{height:"2px",width:"70%"}}/>
                                            <span>By <b> { props.ByWhom} </b></span>
                                            <br />
                                            <span style={{ fontSize: "0.7rem" }}>{props.Who}</span>
                                            <br />
                                            <hr  style={{height:"2px",width:"70%"}}/>
                                    </>}
                                </div>

                    </div>
                    {/* <div className="row">
                        <p style={{fontSize:"1.2rem"}} className={styles.plaintextJ}>How to crack jobs in top
                        product companies</p>
                    </div>
                    <div className="row">
                        <p>21st June 2021 <br />5:00PM - 6:00PM</p>

                    </div>
                    <div className="row">
                        <hr  style={{height:"2px",width:"70%"}}/>
                    </div>

                    <div className="row">
                        <p style={{fontSize:"1rem"}}>by Vivek Jhaver</p>
                        <p style={{fontSize:"0.8rem"}}>SDE-2 Amazon</p>
                    </div>
                    <hr style={{height:"2px",width:"70%"}} /> */}


                    <div className="row mb-2" >
                    <div style={{marginTop:"50%",marginLeft:"0px",padding:"0px"}} class={styles.formlogoJ + " justify-content-center"}>
                        <img src={cantileverlabslogo} alt=""/>
                    </div>
                    </div>
                    <br />
                    <div className="row " style={{width:"100%"}} >
                        <div className="col-lg-3">
                            <a href="https://www.instagram.com/cantilever_labs/?hl=en"><img src={instaMasterClass} alt="" /></a>
                        </div>
                        <div className="col-lg-3">
                            <a href="https://www.facebook.com/Cantilever-labs-102916474752041/"><img src={facebookMasterClass} alt="" /></a>
                        </div>
                        <div className="col-lg-3">
                            <a href="https://www.linkedin.com/company/cantileverlabs/"><img src={linkedinMasterClass} alt=""  /></a>
                        </div>
                        <div className="col-lg-3">
                            <a href="https://www.youtube.com/channel/UCHSggA4W_L0nSzWrf_lnZpw"><img src={youtubeMasterClass} alt="" /></a>
                        </div>
                    </div>
                    </div>

                    <div class={"col-xl-7 col-lg-6 col-md-7 col-sm-7 " + styles.secondpartformJ  +" " + styles.form2J}>
                        <div class="mx-5">
                            <div class={styles.formhd2J +" mt-5"}>
                                <h2>Others</h2>
                                        <div style={{width: "40%", backgroundColor: "black", height: "2px"}}></div>
                            </div>

                            <form class={styles.whiteformJ} onSubmit={HandleSubmit}>
                                <div>
                                    <span class={styles.boldformTextJ}>Area Of Interest</span>
                                    <br/>
                                    <div class="row">
                                            <div className="col-lg-4">
                                                    <input type="checkbox" name="radio-1" id="radio-1"
                                                        value="Product Development"
                                                        checked={ProductDevelopment === true}
                                                        onChange={function (e) {
                                                    setcheckboxvalue({ ...checkboxvalue, ProductDevelopment: !ProductDevelopment })
                                                    }} />
                                            <label  class={styles.radiotextJ} for="radio-1" style={{marginLeft:"2%"}} >Product Development</label>
                                            </div>

                                        <div className="col-lg-8">
                                                    <input type="checkbox" name="radio-1" id="radio-2"
                                                        value="Software Development" checked={checkboxvalue.SoftwareDevelopment === true}
                                                        onChange={function (e) {
                                                       setcheckboxvalue({ ...checkboxvalue, SoftwareDevelopment: !SoftwareDevelopment })
                                                    }} />
                                                <label class={styles.radiotextJ} for="radio-2" style={{marginLeft:"2%"}}>Software  developmet</label>
                                                </div>
                                        </div>

                                       <div className="row">
                                       <div className="col-lg-4">
                                                    <input type="checkbox" name="radio-1" id="radio-3"
                                                        value="Data science" checked={checkboxvalue.DataScience === true} onChange={function (e) {
                                                      setcheckboxvalue({ ...checkboxvalue, DataScience: !DataScience })
                                                    }}  />
                                                <label class={styles.radiotextJ} for="radio-3" style={{marginLeft:"2%"}}>Datascience</label>
                                        </div>

                                            <div className="col-lg-8">
                                                    <input type="checkbox" name="radio-1" id="radio-4"
                                                        value="Machine learning and Artificial Intelligence" checked={checkboxvalue.MachineLearningAndArtificialIntelligence == true} onChange={function (e) {
                                                        setcheckboxvalue({ ...checkboxvalue, MachineLearningAndArtificialIntelligence: !MachineLearningAndArtificialIntelligence })
                                                    }}  />
                                            <label class={styles.radiotextJ} for="radio-4" style={{marginLeft:"2%"}}>Machine Learning and  Artificial Intelligence </label>
                                            </div>
                                                </div>
                                        <div className="row">
                                        <div className="col-lg-4">
                                            <input type = "checkbox"  name="radio-1"   id="radio-5" value="Cyber Security" checked={checkboxvalue.CyberSecurity === true} onChange={function(e){
                                                        setcheckboxvalue({ ...checkboxvalue, CyberSecurity: !CyberSecurity })
                                                    }} />
                                                <label class={styles.radiotextJ} for="radio-5" style={{marginLeft:"2%"}}>Cyber Security </label>
                                                </div>
                                        <div className="col-lg-8">

                                        <input type = "checkbox"  name="radio-1"   id="radio-6" value="choice-6" checked={checkboxvalue.FullStackDevelopment === true} onChange={function(e){
                                                         setcheckboxvalue({ ...checkboxvalue, FullStackDevelopment: !FullStackDevelopment })
                                                    }} />
                                         <label class={styles.radiotextJ} for="radio-6" style={{marginLeft:"2%"}}>Full Stack Development</label>
                                        </div>
                                        </div>
                                            <div className="row">
                                            <div className="col-lg-4">
                                                    <input type="checkbox" name="radio-1" checked={checkboxvalue.others === true} id="radio-7" value="Others" onChange={function (e) {
                                                        setcheckboxvalue({ ...checkboxvalue, others: !others })
                                                    }} />
                                            <label class={styles.radiotextJ} for="radio-7" style={{marginLeft:"2%"}}>Others </label>
                                            </div>
                                            </div>


                                </div>
                                <br/>
                                <div class="mb-2">
                                    <span class={styles.boldformTextJ}>Enter Employee Refer code if any</span>
                                    <br/>
                                    <input value={employecode} onChange={(e)=>{return setemployecode(e.target.value)}} class={styles.formcontrol1} type="text" name="city" placeholder="Type code here"/>
                                </div>
                                <br />
                                <br />




                                <div class="my-2">
                                    <span class={styles.boldformTextJ}> What are you primarily looking for?</span>
                                    <br/>
                                    {/* <input value={question} onChange={(e)=>setquestion(e.target.value)} class={styles.formcontrol1} type="text" name="city" placeholder=" Enter Your Question"/> */}
                                    <select value={question} onChange={(e)=>setquestion(e.target.value)}>

                                <option value="Test Series">Test Series</option>
                                <option value="Problem-solving sessions">Problem-solving sessions</option>
                                <option value="Free Toolkits">Free Toolkits</option>
                                <option value="Resume Building session ">Resume Building session</option>
                                <option value="Soft skills-building session">Soft skills-building session</option>
                              </select>
                                </div>

                                <div class="my-5">
                                    {errors.length > 0 && <>
                                    <div className="alert alert-danger" role="alert">
                                                    {errors.map((value, index) => {
                                           return <p> {value} </p>
                                       })}
                                    </div>
                                     </>}
                                </div>


                                <div class={styles.leftformtextJ +" my-5"} style={{height:"10%",float:"left"}}>
                                    <p className="text-muted"style={{marginBottom:"0px"}}>
                                    <input type="checkbox" checked={terms === true} onChange={(e)=>setterms(!terms)} id="" /> By Clicking submit you agree to our</p>
                                    <a href="/policies/#terms" target="__blank"><p  class={styles.purpleboldJ } style={{margin:"0",padding:"0px"}} >Terms & Conditions</p></a>
                                    <br />
                                    <div class="d-flex">
                                        <button onClick={()=>{props.Others_to_College()}} class={styles.buttonformJ +" btn P-2 mx-2"}>BACK</button>
                                        <button class={styles.buttonformJ +" btn P-2"}>SUBMIT</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
        </div>

    )
}

export default Others

import React from "react";
import linkedin1 from "../Assets/img/Placement/linkedin_color.png";
import adobe from "../Assets/img/Placement/adobe.png";
import flipkart from "../Assets/img/Placement/flipkart_color.png";
import Manish from "../Assets/img/Competitive/Image_30.png";
import Vivek from "../Assets/img/Competitive/Vivek.png";
import Bajaj from "../Assets/img/Competitive/Bajaj.png";
import linkedin from "../Assets/img/Competitive/linkedin.png";
import amazon_app from "../Assets/img/Competitive/amazon_app.png";
import google_app from "../Assets/img/Competitive/google_app.png";
import ms_app from "../Assets/img/Competitive/ms_app.png";
import styles from "../Assets/css/cpcourse.module.css";

function CPMentors() {
  let array = [
    {
      name: "Manish Kankani",
      college: "IIT, Roorkee",
      company: "Google Tech Lead",
      img: google_app,
      nameImg: Manish,
      linkedIn: "https://www.linkedin.com/in/manishkankani/",
    },
    {
      name: "Vivek Jhaver",
      college: "VNIT",
      company: "SDE-2, Amazon",
      img: amazon_app,
      nameImg: Vivek,
      linkedIn: " https://www.linkedin.com/in/vivek-jhaver-8853a261/",
    },
    {
      name: "Suresh Santhanam Mohan",
      college: "IIIT Hyderabad",
      company: "Software Engineer at Microsoft",
      img: ms_app,
      nameImg: Bajaj,
      linkedIn: "https://www.linkedin.com/in/sureshsan/",
    },
  ];
  return (
    <div className="container-fluid">
      <div className="row">
        <br />
        <h1
          style={{
            fontFamily: "Poppins-Bold",
            textAlign: "center",
            marginBottom: "5%",
            marginTop: "5%",
          }}
        >
          Meet Your Mentors
        </h1>
        <div className="container">
          <div className="row" style={{ margin: "2% 7% 2%" }}>
            {array.map((item) => (
              <div className="col-md-4 col-sm-12">
                <div
                  className="text-center"
                  style={{
                    backgroundColor: "#f6f6f6",
                    borderTopLeftRadius: "25px",
                    borderTopRightRadius: "25px",
                    justifyContent: "space-between",
                  }}
                >
                  <br />
                  <img
                    src={item.nameImg}
                    width="30%"
                    className="mx-auto d-block"
                    style={{ marginBottom: "2%" }}
                  />
                  <h5>{item.name}</h5>
                  <p style={{ fontFamily: "Poppins-Regular" }}>
                    {item.college}
                  </p>
                  <b style={{ fontFamily: "Poppins-Bold" }}>{item.company}</b>
                  <br />
                  <br />
                  <img src={item.img} className="img-fluid mx-auto d-block" />
                  <br />
                  <a
                    style={{ textDecoration: "none" }}
                    href={item.linkedIn}
                    target="_blank"
                  >
                    <table className={styles.mentors_bottom} width="100%">
                      <tr>
                        <td>
                          <div
                            className={styles.mentors_bottom_text}
                            style={{ borderRadius: "25px" }}
                          >
                            Linked{" "}
                            <span
                              style={{ padding: "2px" }}
                              className="fa fa-linkedin "
                            ></span>
                          </div>
                        </td>
                        <td></td>
                      </tr>
                    </table>
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CPMentors;
{
  /*<div className="col-md-4 col-xs-6 col-6">
              <div
                style={{
                  backgroundColor: "#f6f6f6",
                  borderTopLeftRadius: "25px",
                  borderTopRightRadius: "25px",
                  borderEndEndRadius: "25px",
                  borderEndStartRadius: "25px",
                }}
                className="text-center"
              >
                <br />
                <img src={Vivek} width="30%" className="mx-auto d-block" />
                <h5>Vivek Jhaver</h5>
                <p>VNIT</p>

                <b>SDE-2, Amazon</b>
                <br />
                <br />
                <img src={amazon_app} className="img-fluid mx-auto d-block" />
                <br />

                <a
                  style={{ textDecoration: "none" }}
                  href=" https://www.linkedin.com/in/vivek-jhaver-8853a261/"
                  target="_blank"
                >
                  <table className="mentors_bottom" width="100%">
                    <tr>
                      <td>
                        <div className="mentors_bottom_text">
                          Linked{" "}
                          <span
                            style={{ padding: "2px" }}
                            className="fa fa-linkedin "
                          ></span>
                        </div>
                      </td>
                      <td></td>
                    </tr>
                  </table>
                </a>
              </div>
            </div>

            <div className="col-md-4 col-xs-6 col-6">
              <div
                style={{
                  backgroundColor: "#f6f6f6",
                  borderTopLeftRadius: "25px",
                  borderTopRightRadius: "25px",
                  borderEndEndRadius: "25px",
                  borderEndStartRadius: "25px",
                }}
                className="text-center"
              >
                <br />
                <img src={Bajaj} width="30%" className="mx-auto d-block" />
                <h5>Suresh Santhanam Mohan</h5>

                <p>IIIT Hyderabad</p>
                <b>Software Engineer at Microsoft</b>

                <br />
                <br />

                <img src={ms_app} className="img-fluid mx-auto d-block" />
                <br />
                <a
                  style={{ textDecoration: "none" }}
                  href="https://www.linkedin.com/in/sureshsan/"
                  target="_blank"
                >
                  <table className="mentors_bottom" width="100%">
                    <tr>
                      <td>
                        <div className="mentors_bottom_text">
                          Linked{" "}
                          <span
                            style={{ padding: "2px" }}
                            className="fa fa-linkedin "
                          ></span>
                        </div>
                      </td>
                      <td></td>
                    </tr>
                  </table>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>*/
}

import "../component/styled/newcompStyle/FlipCard.scss";
import { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import configAxios from "../../axios.config";

/**
 *
 * @param {Array} array
 * @returns Array
 */
function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

function FlipCard({ hidenavbar }) {
  const { gameId } = useParams();
  const [cardData, setCardData] = useState([]);
  const [images, setImages] = useState();

  useEffect(() => {
    configAxios(localStorage.getItem("token"))
      .get("/get-flipcard-game/" + gameId)
      .then(({ data }) => {
        let questions = data.questions;
        let images = data.images;
        setCardData(shuffleArray(questions));
        setImages(shuffleArray(images));
      })
      .catch((err) => console.log(err.response));
  }, [gameId]);

  useEffect(() => {
    if (!hidenavbar) return;

    hidenavbar(true);
    return () => {
      hidenavbar(false);
    };
  }, [hidenavbar]);

  function disableCard(index) {
    setCardData((prev) => {
      prev[index].disabled = true;
      return [...prev];
    });
  }

  function setNameOnCard(index, name, status) {
    setCardData((prev) => {
      prev[index]["name"] = name;
      switch (status) {
        case "right":
          prev[index]["icon"] = status;
          break;
        case "wrong":
          prev[index]["icon"] = status;
          break;

        default:
      }

      return [...prev];
    });
  }
  return (
    <div className='parent-flip'>
      <div className='row'>
        <div className='col-xl-12'>
          <div className='row' style={{ justifyContent: "center" }}>
            {images &&
              cardData.map((val, key) => {
                if (key >= 49) return <></>;
                return (
                  <PopupCard
                    imgProp={images[key]}
                    index={key}
                    key={val._id}
                    {...val}
                    disableCard={disableCard}
                    setNameOnCard={setNameOnCard}
                  />
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
}

const PopupCard = ({
  index,
  imgProp,
  disabled,
  disableCard,
  answer,
  questionName: question,
  name,
  setNameOnCard,
  icon,
}) => {
  const [counter, setCounter] = useState(0);
  const [solution, setSolution] = useState("");
  const [showAnswer, setShowAnswer] = useState(false);
  const userName = useRef();

  useEffect(() => {
    console.log(showAnswer);
  }, [showAnswer]);

  function displaySolution() {
    setSolution(answer);
  }

  return (
    <div class='flipContainer'>
      <input
        type='checkbox'
        id={`card-${index}`}
        disabled={disabled}
        onClick={() => {
          if (counter === 0) setCounter((prev) => ++prev);
          else disableCard(index);
        }}
      />
      <div className='background-overlay'></div>
      <label className='card-container' for={`card-${index}`}>
        {!name && (
          <div className='card-flip'>
            <div class='card front'>
              <img className='frontImg' src={imgProp} alt='img' />
            </div>
            <div class='card back'>
              {/* <div class='card-block'> */}
              <div className='CrossButton'>
                  <img
                    src='https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1671002674469_icons8-cross-48.png'
                    alt='College Logo'
                    className='CrossButton'
                  />
                </div>
                <div className='QuestionmarkDiv'>
                  <img
                    src='https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1670926964039_image%2024243.png'
                    alt='College Logo'
                    className='QuestionMark'
                  />
                </div>
                {/* <h4 class="card-title flipcardTitle">Question</h4> */}
                <div class='card-subtitle mb-2 flipcardSubTitle'>
                  {question}
                </div>
                <div className='row backDownInfo'>
                  <div className='col-9'>
                    <div className='leftBackInfo'>
                      <button
                        className='AnswerBtn'
                        onClick={() => setShowAnswer(true)}>
                        Solution
                      </button>
                      <textarea
                        className='fliptextAns'
                        name='flipAnswer'
                        cols='100'
                        rows='3'
                        value={showAnswer ? answer : ""}
                        readOnly={true}
                      />
                    </div>
                  </div>
                  <div className='col-3'>
                    <div className='rightSideInfo'>
                      <div className='RightWrongButton'>
                        <button
                          className='rightWrongBtn'
                          style={{ backgroundColor: "#7FBA00" }}
                          onClick={() =>
                            setNameOnCard(
                              index,
                              userName.current.value,
                              "right"
                            )
                          }>
                          Right
                        </button>
                        <button
                          className='rightWrongBtn'
                          style={{ backgroundColor: "#FB0404" }}
                          onClick={() =>
                            setNameOnCard(
                              index,
                              userName.current.value,
                              "wrong"
                            )
                          }>
                          Wrong
                        </button>
                      </div>
                      <input className='userName' ref={userName} type='text' />
                    </div>
                  </div>
                </div> 
              {/* </div> */}
              
            </div>
          </div>
        )}
        {name && (
          <div className='disabled-card'>
            {name}{" "}
            <div className='RightWrongImg'>
              <img
                className='RightImg'
                src={
                  icon === "right"
                    ? "https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1671878851157_25231.png"
                    : "https://cantileverlabs-quiz-answer-images.s3.ap-south-1.amazonaws.com/1673449039138_image%2024265.png"
                }
                alt=''
              />
            </div>
          </div>
        )}
      </label>
    </div>
  );
};

PopupCard.defaultProps = {
  disabled: false,
  answer: "",
};

export default FlipCard;

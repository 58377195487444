import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import CollegeCollabComp from "../components/CollegeCollabComp";
import styles from "../Assets/css/newcoursepage.module.css";
import style from "../Assets/css/b2b_section_1.module.css";
import img_8153 from "../Assets/img/Group 8153.png";
// import img_8550 from "../Assets/img/Group 8550.svg";
import img_8154 from "../Assets/img/Group 8154.png";
import img_8155 from "../Assets/img/Group 8155.png";
import Slider from "./Slider";
import useMedia from "./useMedia";
import NewConfusedForm from "./NewConfusedForm.js";
import Maintestimonial from "../Pages/Maintestimonial";
//import Testimonials from "../componentsofcourse/Test";
import Testimonial from "../components/TestimonialsVersion2";
import CourseFeature from "./courseFeature";
// import Headingnewcoursepage from "./Headingnewcoursepage";
import im1 from "../Assets/newcoursepage_images/journey/1.png";
import im2 from "../Assets/newcoursepage_images/journey/2.png";
import im3 from "../Assets/newcoursepage_images/journey/3.png";
import im4 from "../Assets/newcoursepage_images/journey/4.png";
import rating from "../Assets/newcoursepage_images/rating.png";
import Mentor from "./Mentor";
import Iteration from "../Pages/Iteration";
// import stenroll from "../Assets/newcoursepage_images/stenroll.png";
// import school from "../Assets/newcoursepage_images/material-school.png";
import assignment from "../Assets/newcoursepage_images/assignment.png";
import video from "../Assets/newcoursepage_images/video.png";
import img1 from "../Assets/newcoursepage_images/bannerbg.png";
import Frequentques from "./Frequentques";
import HelmetComp from "../components/HelmetComp";
import ReactGA from "react-ga";
import { useEffect } from "react";
const Newcoursepage = () => {

	useEffect(() => {
		ReactGA.initialize("UA-198180460-1");
	},[])


	const matches = useMedia("(max-width : 575px )");
	return (
		<div style={{ overflowX: "hidden" }}>
			{/* <Navbar /> */}
			<HelmetComp
				info={{
					title: "Get Placement Ready",
					// description: '',
					// siteName: '',
					// summary: '',
					// pageUrl: '',
					// imagUrl: ''
				}}
			/>
			{/* className={` ${styles.bgimg}`} */}
			<div>
				<div
					className={`container-fluid row justify-content-center align-items-center m-0 ${styles.bgimg}`}
					style={{ height: "100%", overflowX: "hidden" }}>
					<div className='container col-sm' style={{ margin: "0 8vw" }}>
						<h1 className={styles.heading}>Get Placement Ready</h1>
						<br />
						<p className={styles.subheading}>
							A complete course designed for engineering students and aspiring
							graduates to help them land their dream jobs in leading tech.
							companies like Microsoft, Amazon, Google, TCS, Infosys, Wipro &
							many more.
						</p>

						<div className='row justify-content-start align-items-center '>
							<div className='col-sm' style={{ margin: "0" }}>
								<img src={rating} alt='no img'></img>

								<div className='col-sm' style={{ margin: "0 " }}>
									<div className='d-flex  my-4  justify-content-start align-items-center flex-wrap '>
										<div
											className={
												" d-flex justify-content-center  align-items-center  my-1"
											}
											style={{ margin: "0 2rem 0  0" }}>
											<div style={{ margin: "0 15px" }}>
												<img src={assignment} alt={assignment} />
											</div>
											<div>
												<b>500+</b> <br /> ASSIGNMENTS
											</div>
										</div>

										<div
											className={
												" d-flex justify-content-center  align-items-center my-1"
											}
											style={{ margin: "0 2rem 0  0" }}>
											<div style={{ margin: "0 15px" }}>
												<img src={video} alt={video} />
											</div>
											<div>
												<b>200+</b> <br /> HOURS OF LECTURES
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className={`${styles.xtramargine}`} style={{ color: "white" }}>
							<a
								onClick={() => {
									ReactGA.event({
										category: "GetPlacementReady",
										type: "Button",
										name: "Get_Started_placement_page",
										action: "The user has clicked the Notified button",
									});
								}}
								href='#scrl'
								className={`${styles.neonbutton} rounded-pill`}
								style={{ border: "1px solid #185adb" }}>
								Get Started
							</a>
							{/* <a
                className={`btn btn-block shadow btn-lg round btn-dark text-light rounded-pill`}
                style={{
                  backgroundColor: "#ff7300",
                  color: "white",
                  border: "#ff7300",
                  width:"20rem"
                }}
                href="#scrl"
              >
                Get Started
              </a> */}
						</div>
					</div>
					<div
						className={`col-sm ${styles.dNone}`}
						style={{ margin: "4rem 0" }}>
						<div className='row container'>
							<img src={img1} alt={img1} />
						</div>
					</div>
				</div>
			</div>
			{/* </div> */}
			<br /> <br /> <br /> <br />
			<div className='container-fluid'>
				<div className={styles.HeadingCont}>
					<h1
						className={styles.heading}
						style={{ fontSize: `${matches ? "1.4rem" : ""}` }}>
						Our Alumni Placed At Leading Companies
					</h1>
					<br />

					<Slider />
				</div>
				{/* banner*/}
				<br />
				<div
					className={`container-fluid ${style.training_description_div} `}
					style={{ backgroundColor: "#185ADB", margin: "2rem 0" }}>
					<div className='row '>
						<div
							className={`col-xl-6 col-lg-8 col-md-10 col-sm-10 col-12 ${style.description_inner_div} `}>
							<div className={`d-flex ${style.description_divv} `}>
								<div className={`${style.center_div}`}>
									<img
										src={img_8153}
										alt=''
										className={`img-fluid ${style.icons_imgs}`}
									/>
									<h1 className={`${style.des_no}`}>25,000+</h1>
									<h6 className={`${style.des_text}`}>Students Trained</h6>
								</div>
								<div className={`${style.section_vl}`}></div>
								<div className={`${style.center_div}`}>
									<img
										src={img_8154}
										alt=''
										className={`img-fluid ${style.icons_imgs}`}
									/>
									<h1 className={`${style.des_no}`}>94%</h1>
									<h6 className={`${style.des_text}`}>Placements Achieved</h6>
								</div>
								<div className={`${style.section_vl}`}></div>
								<div className={`${style.center_div}`}>
									<img
										src={img_8155}
										alt=''
										className={`img-fluid ${style.icons_imgs}`}
									/>
									<h1 className={`${style.des_no}`}>140+</h1>
									<h6 className={`${style.des_text}`}>Colleges Collaborated</h6>
								</div>
							</div>
						</div>
					</div>
				</div>
				<br />
				<br />
				{/* <Headingnewcoursepage heading="Course Features" />{" "} */}
				{/*subheading="why us" */}
				<CourseFeature />
				<br />
				<br />
				<br />
				<div className='container text-center my-3'>
					<br />
					<br />
					<h1
						className={styles.heading}
						style={{ fontSize: `${matches ? "1.4rem" : ""}` }}>
						Our Expert Mentors
					</h1>
					<p style={{ fontSize: `${matches ? "1rem" : "1.3rem"}` }}>
						Transforming Students Into Professionals
					</p>

					{/* <img src={img_8550} className="text-center" /> */}

					{/* subheading="Course instructors" */}
					<br />
					<Mentor />
					<br />
					<br />
					<br />
					<br />
					<br />
				</div>
				<div className={styles.HeadingCont}>
					<h1
						className={styles.heading}
						style={{ fontSize: `${matches ? "1.4rem" : ""}` }}>
						Our Success Stories
					</h1>
					<br />
				</div>
				{/* <Headingnewcoursepage heading="Our Success Stories" /> */}
				{/* subheading="what our students say about us" */}
				<Maintestimonial />
				<div style={{ overflowX: "hidden" }}>
					<Testimonial />
				</div>
				<div className={styles.HeadingCont}>
					<h1
						className={styles.heading}
						style={{ fontSize: `${matches ? "1.3rem" : ""}` }}>
						Trusted By Top Colleges Across India
					</h1>
					<br />
					{/* <Headingnewcoursepage heading="Trusted By Top Colleges Across India" /> */}
					<CollegeCollabComp />
					<br />
					<br />
					<br />
					<br />
					<br />
				</div>
				<div className='container' id='scrl'>
					<div className={styles.HeadingCont}>
						<h1
							className={styles.heading}
							style={{ fontSize: `${matches ? "1.4rem" : ""}` }}>
							Explore Our Courses
						</h1>
						<br />
					</div>
					{/* <Headingnewcoursepage heading="Explore Our Courses" /> */}
					{/* subheading="course structure" */}
					<Iteration />
				</div>
				<div className='container-fluid mx-auto'>
					<NewConfusedForm />
				</div>
				<br />
				<br /> <br />
				<div className={styles.HeadingCont}>
					<h1
						className={styles.heading}
						style={{ fontSize: `${matches ? "1.4rem" : ""}` }}>
						Roadmap To Be Followed
					</h1>
					<br />
				</div>
				{/* <Headingnewcoursepage heading="Roadmap To Be Followed" /> */}
				{/* subheading="Course journey" */}
				<div
					className='container-sm'
					style={{ height: "100%", overflow: "hidden" }}>
					<div className='d-flex justify-content-start flex-sm-column'>
						<div
							data-aos='fade-up-right'
							data-aos-duration='1500'
							style={{ width: "50%" }}>
							<img src={im1} style={{ width: "60%" }} alt={im1} />
							<p
								style={{
									fontWeight: "600",
									fontSize: "1.2em",
									width: "100%",
								}}>
								Attend Live sessions from Industry experts and get your doubts
								clarified from TA
							</p>
						</div>
					</div>
					<div className='d-flex justify-content-end'>
						<div
							data-aos='fade-up-left'
							data-aos-duration='1500'
							style={{ width: "50%" }}>
							<img
								src={im2}
								style={{ width: "60%", height: "auto" }}
								alt={im2}
							/>
							<p
								style={{
									fontWeight: "600",
									fontSize: "1.2em",
								}}>
								Work on assignments and projects under guidance of experts
								community
							</p>
						</div>
					</div>
					<div className='d-flex justify-content-start'>
						<div
							data-aos='fade-up-right'
							data-aos-duration='1500'
							style={{ width: "50%" }}>
							<img
								src={im3}
								style={{ width: "60%", height: "auto" }}
								alt={im3}
							/>
							<p
								style={{
									fontWeight: "600",
									fontSize: "1.2em",
								}}>
								Get Ready with Resume and Experience Mock Interviews with
								Industry Experts
							</p>
						</div>
					</div>
					<div className='d-flex justify-content-end'>
						<div
							data-aos='fade-up-left'
							data-aos-duration='1500'
							style={{ width: "50%" }}>
							<img
								src={im4}
								style={{ width: "65%", height: "auto" }}
								alt={im4}
							/>
							<p
								style={{
									fontWeight: "600",
									fontSize: "1.2em",
								}}>
								Get Placement support and appear for Company Interviews
							</p>
						</div>
					</div>
				</div>
				<br />
				<br />
				<br />
				<br />
				<div className={styles.HeadingCont}>
					<h1
						className={styles.heading}
						style={{ fontSize: `${matches ? "1.4rem" : ""}` }}>
						Frequently Asked Questions
					</h1>
					<br />
				</div>
				{/* <Headingnewcoursepage heading="Frequently Asked Questions" /> */}
				<div className='row m-0'>
					<div
						className={`${matches ? "col-11" : "col-7"} mx-auto ${
							styles.HeadingCont
						}`}>
						<Frequentques />
					</div>
					<br />
					<br />
					<br />
				</div>
				{/* <div className={styles.HeadingCont}>
          <div className={styles.moneyback}>
            GET 30 DAYS MONEY BACK GUARANTEE
          </div>
        </div> */}
				<br />
				<br />
			</div>
			<Footer />
		</div>
	);
};
export default Newcoursepage;

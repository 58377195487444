import React from 'react'
import '../Assets/css/newhomepage.css'
import CountUp from 'react-countup';
const HomePage = () => {
    return (
        <div className="d-grid place-content-center mt-5">
            {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
  <path fill="#0099ff" fill-opacity="0.5" d="M0,320L80,304C160,288,320,256,480,213.3C640,171,800,117,960,90.7C1120,64,1280,64,1360,64L1440,64L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"></path>
</svg> */}
            
            <div className="hero-section-newhomepage ">
                <div className="d-flex flex-row  justify-content-between p-3 " >
                    <div className="d-flex flex-column px-5 " style={{borderRight:"2px solid white"}} >
                        <span className="number-heading one-heading text-white"><CountUp end={152} duration={2} />+</span>
                        <span className="text-social-presence text-white" > College
Collaborations</span>
                    </div>
                    <div className="d-flex flex-column px-5" style={{borderRight:"2px solid white"}}>
                        <span className="number-heading two-heading text-white"><CountUp end={35000} duration={1} />+</span>
                        <span className="text-social-presence text-white">Students Trained</span>
                    </div>
                    <div className="d-flex flex-column px-5" style={{borderRight:"2px solid white"}}>
                        <span className="number-heading three-heading text-white"><CountUp end={15} duration={1} /> LPA</span>
                        <span className="text-social-presence text-white">Average Package</span>
                    </div>
                    <div className="d-flex flex-column px-5" >
                        <span className="number-heading four-heading text-white">94.7</span>
                        <span className="text-social-presence text-white">Placement
Rate</span>
                    </div>
                </div>
            </div>
            

            

            {/* <div className="herosection-outside">
                <h1>Helo</h1>
            </div> */}
        </div>
    )
}

export default HomePage

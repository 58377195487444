import { hybridCompiler } from "./hybridExam/reducers/reducers";
import { dashboard } from "./newDashboard/reducers/reducer"
import { createStore, combineReducers } from "redux";

const reducers = combineReducers({
    hybridCompiler, dashboard
})


export const store = createStore(
    reducers
);
import { Link, Redirect, useHistory } from "react-router-dom";
import Blogs from "./Pages/Blogs";
import { useEffect, useState } from "react";
// import PaymentPopup from "./Pages/PaymentPopup";
import { BASE_URL } from "./env";
// const BASE_URL = "http://localhost:5000";

export function Test() {
  let history = useHistory();

  const redirect = () => {
    history.push("/");
  };
  return (
    <div>
      <button onClick={redirect}>Redirect</button>
    </div>
  );
}

function PP(redirect, coupon_code, startAmount) {
  const newURL = "/order/" + data.receipt;
  // <PaymentPopup
  //   data={data}
  //   startAmount={startAmount}
  //   couponCode={coupon_code}
  // />;
  var newWindow = window.open(newURL);
  // var newWindow = window.open();
  newWindow.data = data;
  newWindow.couponCode = coupon_code;
  newWindow.startAmount = startAmount;
  newWindow.location.href = newURL;
}

function loadRazorPay() {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}
let data;
// const PopupFire = (coupon_code, startAmount, response) => {
const PopupFire = () => {
  //
  // const newURL = "/order/" + data.receipt;
  // var newWindow = window.open(newURL);
  // newWindow.data = data;
  // newWindow.couponCode = coupon_code;
  // newWindow.startAmount = startAmount;
  return data;
  // return ("_"
  //   <PaymentPopup
  //     name={data.name}
  //     email={data.email}
  //     order_id={response.razorpay_order_id}
  //     cost={data.amount}
  //     date={data.date_purchased}
  //     mod={true}
  //   />
  // );
};
async function displayRazorpay(
  tierId,
  coupon_code,
  token,
  setLoadT1,
  setLoadT2,
  setLoadT3,
  setLoadT4,
  setCoupon,
  setHandleOrder,
  setHandlePayment,
  courseId
) {
  const res = await loadRazorPay();
  if (!res) {
    alert("offline");
  } else {
    if (coupon_code === "") {
      data = await fetch(BASE_URL + "/razorpay", {
        method: "POST",
        headers: {
          Authorization: token,
          "content-type": "application/json",
        },
        body: JSON.stringify({
          courseId: tierId,
        }),
      });
    } else {
      data = await fetch(BASE_URL + "/razorpay", {
        method: "POST",
        headers: {
          Authorization: token,
          "content-type": "application/json",
        },
        body: JSON.stringify({
          courseId: tierId,
          couponCode: coupon_code,
        }),
      });
    }
    setLoadT1(false);
    setLoadT2(false);
    setLoadT3(false);
    setLoadT4(false);
    setCoupon(false);

    if (data.status == 200) {
      data = await data.json();

      const options = {
        key: "rzp_live_aD9j5WLBGtdQxt", // Enter the Key ID generated from the Dashboard
        amount: data.amount.toString(), // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        currency: data.currency,
        name: "Cantilever Labs",
        description: "course transaction",
        image:
          "https://media-exp1.licdn.com/dms/image/C510BAQEgcV3sgE1PIA/company-logo_200_200/0/1552289011007?e=2159024400&v=beta&t=FO8loLVwC5qoHmYkk-gR-mv7vC36LPG17yZkxOFl6Go",
        order_id: data.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        handler: async function (response) {
          // const newURL = "/order/" + data.receipt;
          // var newWindow = window.open(newURL);
          // newWindow.data = data;
          // newWindow.couponCode = coupon_code;
          // newWindow.startAmount = startAmount;
          // setHandlePayment(true);
          setHandleOrder(true);
          // console.log(tierId);
          fetch(BASE_URL + "/enroll/" + courseId, {
            headers: {
              authorization: "Bearer " + localStorage.getItem("token"),
            },
            method: "POST",
          })
            .then((res) => res.json())
            .then((data) => {});
        },
        prefill: {
          name: data.name,
          email: data.email,
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#2E5077",
        },
      };
      var paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } else {
      data = await data.json();
      alert(data.error + " please try again");
    }
  }
  // PP(redirect, coupon_code, startAmount);
  // const newURL = "/order/" + data.receipt;
  // const newURL2 = `/order/${data.receipt}?${data.amount}?${startAmount}?${name}?${data.email}?${data.id}?${data.date_purchased}?${coupon_code}`;

  // var newWindow = window.open(newURL);
  // newWindow.location.href = newURL;
  // newWindow.couponCode = coupon_code;

  // newWindow.startAmount = startAmount;
  // const receipt = data.receipt;

  //

  // return data;

  return data;
}

export default displayRazorpay;

import { EditorStyled } from "./styled/EditorStyled";
import MonacoEditor from "@monaco-editor/react";

interface IEditor {
  selectedTab: string;
  language: "html" | "css" | "javascript";
  onChange: (value: string | undefined) => void;
  val: string;
  closeTab: any;
  tabIndex: string;
  setActiveTab: any
}

export default function Editor({
  language,
  onChange,
  val,
  selectedTab,
  closeTab,
  tabIndex,
  setActiveTab
}: IEditor) {
  return (
    <EditorStyled>
      <div
        style={selectedTab === language ? { backgroundColor: "white" } : {}}
        className="editor-labels"
      >
        {" "}
        {language}
        <button
          name={tabIndex}
          onClick={(e) => {
            closeTab(e)
            setActiveTab('')
          }}
          className="editor-labels-btn"
        >
          x
        </button>
      </div>
      <MonacoEditor
        height="100%"
        width="100%"
        language={language}
        theme="vs-dark"
        value={val}
        options={{
          fontSize: 16,
          fontFamily: "Fira Code",
          minimap: {
            enabled: false,
          },
          scrollbar: {
            vertical: "hidden",
            horizontal: "hidden",
          },
        }}
        onChange={onChange}
      />
    </EditorStyled>
  );
}
